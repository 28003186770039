import React, { Component } from 'react';

import { Upload } from 'antd';
import {
  PictureFilled
} from '@ant-design/icons';
import classNames from 'classnames'
const { Dragger } = Upload;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class renderImageDragAnt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitValue: false,
      previewImage: '',
      model: {
        src: props.input.value ? (this.props.fullpath ? props.input.value : '/storage/images/' + props.input.value) : 'https://via.placeholder.com/120x150?text=%2Badd'
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    // if(!this.state.isInitValue&&nextProps.input.value){
    // if(this.props.input.value!=nextProps.input.value){
    //     this.setState({
    //         // isInitValue: true,
    //         model: {
    //             src: nextProps.fullpath ? nextProps.input.value : '/storage/images/'+nextProps.input.value
    //         }
    //     })
    // }
  }
  handleModelChange = (value) => {
    let model = Object.assign({}, value)
    if (!model.src) {
      // model.src = 'https://via.placeholder.com/120x150?text=%2Badd'
      // this.setState({ model: model, isInitValue: true });
      this.props.input.onChange('');
    } else {
      // this.setState({ model: model, isInitValue: true });
      if (this.props.fullpath) {
        this.props.input.onChange(model.src);
      } else {
        this.props.input.onChange(model['data-name']);
      }

    }

  }
  render() {
    const { multiple, width, height, display, meta: { error, invalid, valid }, input } = this.props;
    const { previewImage } = this.state;
    let imgSize = display;
    if (!display) {
      imgSize = width && width > 100 ? { width: 100, height: (100 * height / width) } : { width: 100, height: 100 }
    }
    const props = {
      style: { height: 215 },
      name: 'file',
      multiple: multiple,
      onRemove: file => {
        const index = input.value?.indexOf(file)
        const newFileList = input.value.slice()
        newFileList.splice(index, 1)
        input.onChange(newFileList)
        this.setState({
          previewImage: '',
        })
      },
      beforeUpload: async file => {
        input.onChange(multiple ? [...input.value, file] : [file])
        const previewImage = await getBase64(file);
        this.setState({
          previewImage: previewImage,
        });
        return false;
      },
      customRequest: ({ file, onSuccess }) => {
        onSuccess();
      },
      fileList: input.value,
    };
    return (

      <div className="w-100">
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            {previewImage ? <img src={previewImage} style={{ width: 80 }} alt="preview" /> : <PictureFilled style={{ fontSize: 80, color: '#ccc' }} />}
          </p>
          <button type="button" className={classNames("btn mb-2", { 'btn-secondary': valid, 'btn-danger': invalid })}>Add Image</button>
          <p className="ant-upload-hint">
            or drop images to upload
          </p>
          {invalid && <hr className="border-danger m-0 position-absolute" style={{ width: '90%', left: '5%', bottom: 0 }} />}
          {error && <span className="invalid-feedback">{error}</span>}
        </Dragger>
        {error && <span className="invalid-feedback d-block">{error}</span>}
      </div>
    );
  }
};

export default renderImageDragAnt