import OrderList from "scenes/Orders/scenes/List";
import OrderRisk from "scenes/Orders/scenes/Risk";
import OrderDetail from "scenes/Orders/scenes/Detail";
import CreateOrder from "scenes/Orders/scenes/Create";
import ArchivedOrder from "./scenes/ArchivedOrder";
import OrderItemsRemoved from "scenes/Orders/scenes/ItemsRemoved";
import ManagementTracking from "scenes/Orders/scenes/Tracking";
// import ReportProductSell from "scenes/Orders/scenes/ReportProductSell";
import OrderManualPayment from "scenes/Orders/scenes/ManualPayment";
import OrderManualPaymentDetail from "scenes/Orders/scenes/ManualPaymentDetail";
import OrderIssue from "scenes/Orders/scenes/Issue";
import Create_Issue from "scenes/Orders/scenes/Create_Issues";
import ReportSyncTracking from "scenes/Orders/scenes/ReportSyncTracking";
import ReportSyncTrackingDetail from "scenes/Orders/scenes/ReportSyncTrackingDetail";
import OrderImportProgress from "scenes/Orders/scenes/ImportProgress";
import OrderReportInkgo from "scenes/Orders/scenes/ReportInkgo";
import SyncOrder from "scenes/Orders/scenes/SyncOrder";
import ImportOrder from "scenes/Orders/scenes/ImportOrder";
import StockStatus from "scenes/Orders/scenes/StockStatus";
import ImportProgressDetail from "scenes/Orders/scenes/ImportProgressDetail";
import MatchProduct from "scenes/Orders/scenes/MatchProduct";
import Invoice from "scenes/Orders/scenes/Invoice";
import ChatWithSupport from "scenes/Orders/scenes/ChatWithSupport";
import DetailIssue from "scenes/Orders/scenes/DetailIssue";
import DuplicateOrderFBA from "./scenes/DuplicateOrderFBA";
import CreateOrderLabel from "./scenes/CreateLableOrder";
import ConvertOrderLabel from "./scenes/ConvertOrderLabel";
import { UpdateIssue } from "./scenes/Create_Issues/Update";
import PaymentOrder from "./scenes/PaymentOrder";

export default [
  {
    key: "orders.list",
    name: "Orders",
    component: OrderList,
    path: "/orders/list",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.risk",
    name: "Order at risk",
    component: OrderRisk,
    path: "/orders/risk",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.deleted-order",
    name: "Archived Orders",
    component: ArchivedOrder,
    path: "/orders/deleted-order",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.create",
    name: "Create order",
    component: CreateOrder,
    path: "/orders/create",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["createOrderPermission"],
  },
  {
    key: "orders.create-label-order",
    name: "Create platform's label order",
    component: CreateOrderLabel,
    path: "/orders/create-label-order",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["createOrderPermission"],
  },
  {
    key: "orders.make-payment",
    name: "Make payment",
    component: PaymentOrder,
    path: "/orders/make-payment",
    icon: null,
    template: "default",
    isPublic: false,
    // permissions: ["createOrderPermission"],
  },
  {
    key: "orders",
    name: "Detail order",
    component: OrderDetail,
    path: "/orders/:id(\\d+)",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.duplicate",
    name: "Duplicate FBA order",
    component: DuplicateOrderFBA,
    path: "/orders/:id/duplicate",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.convert-order-label",
    name: "Convert to label order",
    component: ConvertOrderLabel,
    path: "/orders/:id/convert-order-label",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.removed_items",
    name: "Items removed",
    component: OrderItemsRemoved,
    path: "/orders/removed-items",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.tracking",
    name: "Order's tracking",
    component: ManagementTracking,
    path: "/orders/tracking",
    icon: null,
    template: "default",
    isPublic: true,
  },
  // {
  //   key: "statistics.report-product-sell",
  //   name: "Report product sales",
  //   component: ReportProductSell,
  //   path: "/statistics/report-product-sell",
  //   icon: null,
  //   template: "default",
  //   isPublic: false,
  // },
  {
    key: "Billing",
    name: "Billing",
    component: OrderManualPayment,
    path: "/billing",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "billing.payment",
    name: "Payments",
    component: OrderManualPayment,
    path: "/billing/payment",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "billing.payment",
    name: "Payment",
    component: OrderManualPaymentDetail,
    path: "/billing/payment/:id",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.issue",
    name: "Issues",
    component: OrderIssue,
    path: "/orders/order-issues",
    icon: null,
    template: "default",
    isPublic: true,
  },

  {
    key: "orders.issue",
    name: "Create Issue",
    component: Create_Issue,
    path: "/orders/order-issues/create",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.invoice",
    name: "Invoice",
    component: Invoice,
    path: "/orders/:id/invoice",
    icon: null,
    template: "default",
    isPublic: true,
  },
  // {
  //   key: "orders.invoice",
  //   name: "Invoice",
  //   component: Invoice,
  //   path: "/billing/transactions/:id1/:id",
  //   icon: null,
  //   template: "default",
  //   isPublic: true,
  // },

  {
    key: "orders.logs.sync_tracking",
    name: "Sync tracking log detail",
    component: ReportSyncTrackingDetail,
    path: "/logs/sync-tracking/:id",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.import-backgrounds",
    name: "Import order logs",
    component: OrderImportProgress,
    path: "/logs/import-order",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.import-progress",
    name: "Import order detail",
    component: ImportProgressDetail,
    path: "/logs/import-orders/:id",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.report-inkgo",
    name: "Orders Inkgo Report",
    component: OrderReportInkgo,
    path: "/orders/report-inkgo",
    icon: null,
    template: "default",
    isPublic: false,
  },
  {
    key: "sync.orders",
    name: "Sync Order",
    component: SyncOrder,
    path: "/orders/sync",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["syncOrderPermission"],
  },
  {
    key: "import.orders",
    name: "Import order",
    component: ImportOrder,
    path: "/orders/import",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["importOrderPermission"],
  },
  {
    key: "orders.stock-status",
    name: "Inventory",
    component: StockStatus,
    path: "/orders/stock-status",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "match.product",
    name: "Match Product",
    component: MatchProduct,
    path: "/orders/match-product",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["matchOrderPermission"],
  },
  {
    key: "orders.order-issues",
    name: "Issue",
    component: ChatWithSupport,
    path: "/orders/order-issues-rating/:id",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.order-issues.detail",
    name: "Issue Detail",
    component: DetailIssue,
    path: "/orders/order-issues/:id",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "orders.order-issues.update",
    name: "Update Issue",
    component: UpdateIssue,
    path: "/orders/order-issues/:id/edit",
    icon: null,
    template: "default",
    isPublic: true,
  },
];
