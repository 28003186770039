import { takeLatest, call, put, all } from "redux-saga/effects";
import {
  DATA_FEED_AMAZON_DETAIL_REQUEST,
  DATA_FEED_AMAZON_DETAIL_SUCCESS,
  DATA_FEED_AMAZON_DETAIL_FAIL,
  DATA_FEED_AMAZON_EDIT_REQUEST,
  DATA_FEED_AMAZON_EDIT_SUCCESS,
  DATA_FEED_AMAZON_EDIT_FAIL,
} from "./constants";

import { updateAmazon, getDetail } from "apis/feed_forms";

function* updateDataFeedAmazonSaga(action) {
  try {
    const { id, data_feed } = action;
    const response = yield call(updateAmazon, id, data_feed);

    if (response.code === "success") {
      yield all([
        put({ type: DATA_FEED_AMAZON_EDIT_SUCCESS, ...response }),
      ]);
    } else {
      yield put({ type: DATA_FEED_AMAZON_EDIT_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: DATA_FEED_AMAZON_EDIT_FAIL, error })]);
  }
}

function* getDetailSaga(action) {
  try {
    const { id } = action;
    const response = yield call(getDetail, id);

    if (response.code === "success") {
      yield all([put({ type: DATA_FEED_AMAZON_DETAIL_SUCCESS, ...response })]);
    } else {
      yield put({ type: DATA_FEED_AMAZON_DETAIL_FAIL, response });
    }
  } catch (error) {
    yield all([put({ type: DATA_FEED_AMAZON_DETAIL_FAIL, error })]);
  }
}

function* updateDataFeedAmazonWatcher() {
  yield all([
    takeLatest(DATA_FEED_AMAZON_EDIT_REQUEST, updateDataFeedAmazonSaga),
    takeLatest(DATA_FEED_AMAZON_DETAIL_REQUEST, getDetailSaga),
  ]);
}

export default updateDataFeedAmazonWatcher;
