import React from 'react';
import { Dropdown, Spin, Table } from 'antd';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import { MoreActionStore } from 'scenes/Campaigns/scenes/List/styled';
import { ContextMenuWrapper } from 'scenes/BillingPaymentAccount/v2/styled';
import getMomentInstance from 'utils/moment';
import { getOptionLabel, options_active_invitation_staff } from 'utils/options';
import { useDispatch } from 'react-redux';
import {
  deleteInvitationAccount,
  listInvatitionAccountAction,
  resendInvitationAction,
} from 'scenes/Settings/scenes/Account/actions';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useState } from 'react';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import { GMButton } from 'components/Button';
import { ConfirmModalInnerWrapper } from 'components/Modal/ConfirmDialog/styled';
import { ReactComponent as IconInfo } from 'images/icons/info-circle.svg';
import { Link } from 'react-scroll';

export default ({ props, accounts, loading, deleteInvitationLoading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [record2Remove, setRecord2Remove] = useState(null);

  const fetchData = ({ page = 1, perPage = 10, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    dispatch(listInvatitionAccountAction(params));
  };
  const columns = [
    {
      title: <b>Email</b>,
      dataIndex: 'email',
      align: 'left',
      key: 'email',
    },
    {
      title: <b>Status</b>,
      dataIndex: 'statusEmailInvite',
      align: 'center',
      key: 'statusEmailInvite',
      render: (value) => getOptionLabel(options_active_invitation_staff, value),
    },
    {
      title: <b>Invited date</b>,
      dataIndex: 'time',
      align: 'center',
      key: 'time',
      render: (text) => getMomentInstance(text, 0, true),
    },
    {
      title: <b></b>,
      key: 'action',
      align: 'center',
      render: (_, record) => (
        // <div>
        //   <MoreActionStore>
        //     <Dropdown
        //       placement="topLeft"
        //       overlay={
        //         <ContextMenuWrapper className="p-3">
        //           <div className="delete cursor-pointer">
        //             <div onClick={() => setRecord2Remove(record)}>
        //               Remove invitation
        //             </div>
        //           </div>
        //           <div className="cursor-pointer">
        //               Resend invitation
        //           </div>
        //         </ContextMenuWrapper>
        //       }
        //       trigger={['click']}
        //     >
        //       <MoreAction className="context-menu" />
        //     </Dropdown>
        //   </MoreActionStore>
        // </div>
        <div>
          <MoreActionStore>
            <Dropdown
              placement="topLeft"
              overlay={
                <ContextMenuWrapper className="p-3">
                  <Link onClick={() => setRecord2Remove(record)}>
                    Remove invitation
                  </Link>
                  {record?.statusEmailInvite === 'Expired' && (
                    <div className="delete cursor-pointer">
                      <div onClick={() => resendInvitation(record)}>
                        Resend invitation
                      </div>
                    </div>
                  )}
                </ContextMenuWrapper>
              }
              trigger={['click']}
            >
              <MoreAction className="context-menu" />
            </Dropdown>
          </MoreActionStore>
        </div>
      ),
    },
  ];
  const resendInvitation = (record) => {
    dispatch(resendInvitationAction({ email: record?.email }));
  };

  return (
    <>
      <div className="tab-transaction">
        <Spin spinning={loading || deleteInvitationLoading}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={accounts?.accountProfiles}
            loading={accounts?.loading}
            scroll={{ x: 1000 }}
            pagination={{
              showTotal: (total) => `Total ${total} items`,
              total: accounts?.pagination?.total,
              pageSize: 10,
              current: accounts?.pagination?.currentPage,
              showSizeChanger: false,
              onChange: (page, perPage) =>
                fetchData({
                  ...accounts?.params,
                  page,
                  perPage,
                }),
            }}
          />
        </Spin>
      </div>
      {record2Remove !== null ? (
        <ConfirmDialog
          ctas={
            <>
              <GMButton
                type="button"
                color="SecondaryGray"
                disabled={deleteInvitationLoading}
                onClick={() => setRecord2Remove(null)}
              >
                Cancel
              </GMButton>

              <GMButton
                type="button"
                color="PrimaryError"
                disabled={deleteInvitationLoading}
                onClick={() =>
                  dispatch(
                    deleteInvitationAccount(record2Remove?.id, () =>
                      setRecord2Remove(null)
                    )
                  )
                }
              >
                Remove
              </GMButton>
            </>
          }
          {...props}
        >
          <ConfirmModalInnerWrapper>
            <div className="icon">
              <div className="wrapper">
                <IconInfo />
              </div>
            </div>
            <div className="content">
              <h4 className="header">Remove invitation?</h4>
              <div className="msg">
                {record2Remove?.email} hasn't accepted your invitation yet. He
                or she will not be able to join Gearment via this invitation.
              </div>
            </div>
          </ConfirmModalInnerWrapper>
        </ConfirmDialog>
      ) : null}
    </>
  );
};
