import React from 'react';

const useDelayInput = ({
  shouldTrim,
  onChange,
  onDelay,
  delay = 500,
  regexp,
}) => {
  const [timer, setTimer] = React.useState(null);

  const handleChange = (e) => {
    const _value = e.target.value;
    if (regexp && !regexp.test(_value)) {
      return;
    }
    onChange(_value);

    if (!delay || delay === 0) {
      const trimmedValue = shouldTrim ? _value.trim() : _value;
      shouldTrim && onChange(trimmedValue);
      onDelay(trimmedValue);
      return;
    }
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      const trimmedValue = shouldTrim ? _value.trim() : _value;
      shouldTrim && onChange(trimmedValue);
      onDelay(trimmedValue);
    }, delay);

    setTimer(newTimer);
  };
  return { handleChange };
};

export default useDelayInput;
