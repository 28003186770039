import React, { Component } from "react";
import { Checkbox, Col } from "antd";
import GMRow from "components/GMLayout/GMRow";

class RenderCampaignCheckboxGroup extends Component {
  handleChange = (value) => {};
  render() {
    const {
      input,
      options,
      colSpan,
      meta: { asyncValidating, error },
    } = this.props;
    return (
      <div className={asyncValidating ? "async-validating" : ""}>
        {/* <CheckboxGroup
            {...rest}     
            value={input.value || []}
            onChange={(value)=>input.onChange(value)}
        /> */}
        <Checkbox.Group
          value={input.value || []}
          style={{ width: "100%" }}
          onChange={(value) => {
            input.onChange(value);
          }}
        >
          <GMRow>
            {options.map((item) => (
              <Col key={item.id} span={colSpan || 12}>
                <Checkbox
                  className="d-flex align-items-center mb-2 ant-checkbox-wrapper-lg"
                  value={item.id}
                >
                  <div className={"d-flex justify-content-between align-items-center"}>
                    {item?.productCampaigns && item?.productCampaigns[0] && item?.productCampaigns[0]?.designLink && 
                    <img alt="" className="img-fluid" style={{width: "120px"}} src={item?.productCampaigns && item?.productCampaigns[0] && item?.productCampaigns[0]?.designLink}/>}
                    <h6 className="ml-2">{item.name}</h6>
                  </div>
                </Checkbox>
              </Col>
            ))}
          </GMRow>
        </Checkbox.Group>
        {error && <span className="invalid-feedback d-block">{error}</span>}
      </div>
    );
  }
}

export default RenderCampaignCheckboxGroup;
