import { takeLatest, call, put, all, select } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import {
    getList,
    archive
  } from "apis/image_design";
function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getList, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}

function* archiveImagesSaga(action) {
  try {
    const { data } = action;
    const response = yield call(archive, data);
    const {image_library} = select();
    yield all([
      put({ type: TYPE.ARCHIVE.SUCCESS, errors: response }),
      put({
        type: TYPE.LIST.REQUEST,
        params: image_library?.list?.listImages?.params || { page: 1, perPage: 50 },
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.ARCHIVE.ERROR, error })]);
  }
} 

function* ImageLibraryWatcher() {
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga);
  yield takeLatest(TYPE.ARCHIVE.REQUEST, archiveImagesSaga) 
}

export default ImageLibraryWatcher