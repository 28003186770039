import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, submit } from 'redux-form'
import { renderDropdownDateRange } from 'utils/Form'

const validate = (values) => {
    const errors = {};
    if(!values.date_range){
        errors.date_range = true
    }else if(!values.date_range[0] || !values.date_range[1]){
        errors.date_range = true
    }
    return errors;
};

class index extends Component {
    render() {
        const {handleSubmit} = this.props
        return (
            <form onSubmit={handleSubmit} className="form-inline align-items-center justify-content-end">
                <Field 
                    name="date_range"
                    component={renderDropdownDateRange}
                    allowClear={false}
                    allowEmpty={false}
                    onChange={() =>  
                        setTimeout(() => {
                            this.props.submit()
                        }, 0) 
                    }
                />
            </form>
        )
    }
}

index = reduxForm({
    form: 'formFilterAnalytics',  
    validate,
    enableReinitialize: true,
})(index)

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) =>({
    submit: function() {
        dispatch(submit('formFilterAnalytics'))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
