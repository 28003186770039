import { actionType as TYPE } from "./actions";

const initialState = {
  report_inkgos: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        report_inkgos: {
          ...initialState.report_inkgos,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        report_inkgos: {
          ...state.report_inkgos,
          loading: false,
          data: action.data.inkgo,
          total: action.data.pagination ? action.data.pagination.total : 0,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        report_inkgos: {
          ...state.report_inkgos,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
