import React, { Component } from "react";

import { connect } from "react-redux";
import { reduxForm, change } from "redux-form";
import { Drawer } from "antd";
import validate from "./validate";
import AdvancedFilter from "./AdvancedFilter";
import CloseIcon from "images/x2.svg";
import { ReactComponent as FilterIcon } from 'images/icons/filter-lines.svg';

class index extends Component {
  state = {
    advance_search: false,
  };
  onCloseAdvancedSearch = () => {
    this.setState({
      advance_search: false,
    });
  };
  onOpenAdvancedSearch = () => {
    this.setState({
      advance_search: true,
    });
  };
  render() {
    const {
      onClearFilter,
      changeFieldValue,
      handleAdvancedSubmit,
      initialValues,
      trackings,
    } = this.props;
    return (
      <>
        <div className="filter-section">
          <div
            className="filter-button"
            onClick={this.onOpenAdvancedSearch}
          >
            <FilterIcon className="filter" />
            Filters
          </div>
        </div>
        <Drawer
          title={<b className="Filter">Filter</b>}
          placement="right"
          className="custom-filter"
          closable={true}
          onClose={this.onCloseAdvancedSearch}
          visible={this.state.advance_search}
          closeIcon={<img src={CloseIcon} style={{ width: "20px" }} alt="close icon" />}
          width={this.props.is_mobile ? "80%" : "480px"}
        >
          <AdvancedFilter
            onCloseAdvancedSearch={this.onCloseAdvancedSearch}
            onSubmit={handleAdvancedSubmit}
            changeFieldValue={changeFieldValue}
            initialValues={initialValues}
            onClearFilter={onClearFilter}
            trackings={trackings}
          />
        </Drawer>
      </>
    );
  }
}

index = reduxForm({
  form: "formFilterTracking",
  validate,
  enableReinitialize: true,
})(index);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change("formFilterTracking", field, value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
