import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "./components/form";
import { Button, Spin } from "antd";
import BackToIcon from "images/chevron-down.svg";
import { getDetailGiftMessage, updateGiftMessage } from "./actions";
import { getList as getListStore } from "../../../Stores/scenes/List/actions";
import getMomentInstance from "utils/moment";
import { Wrapper } from "scenes/general.styled";
import GMHelmet from "components/GMHelmet";
import { postfix } from "utils/options";

export class index extends Component {

  componentDidMount() {
    this.props.getDetailGiftMessage(this.props.match.params.id);
    this.props.getListStore({});
  }

  onEditFormSubmit = ({ storeIds, timeStart, timeEnd, approve, ...values }) => {
    this.props.updateGiftMessage(this.props.match.params.id, {
      ...values,
      allStore: values.allStore ? 'yes' : 'no',
      approve: approve ? 1 : 0,
      storeIds: values.allStore ? [] : (storeIds || []),
      timeStart: timeStart,
      timeEnd: timeEnd,
    });
  };
  render() {
    const {
      detailGiftMessage,
      updateGiftMessageLoading,
      pageName,
      stores,
      profile,
    } = this.props;

    const initialValues = {
      storeIds: detailGiftMessage.data.storeId ? [detailGiftMessage.data.storeId] : [],
      approve: parseInt(detailGiftMessage.data.approve) === 1,
      timeEnd: detailGiftMessage.data.timeEnd || getMomentInstance(null, 0, true, false, ['day'], null, 1, 'day'),
      timeStart: detailGiftMessage.data.timeStart || getMomentInstance(null, 0, true, false, ['day']),
      date_range: [detailGiftMessage.data.timeEnd ? getMomentInstance(null, detailGiftMessage.data.timeEnd) : getMomentInstance(null, 0, false, false, ['day'], null, 1, 'day'),
      detailGiftMessage.data.timeStart ? getMomentInstance(null, detailGiftMessage.data.timeStart) : getMomentInstance(null, 0, false, false, ['day'])],
      bgHex: detailGiftMessage.data.bgHex || "#ffffff",
      colorHex: detailGiftMessage.data.colorHex || "#212b48",
      message: detailGiftMessage.data.message,
      imgLogo: detailGiftMessage.data.imgLogo
    };
    return (
      <Wrapper
        pageName={`${pageName} #${this.props.match.params.id}`}
        breadcrumDetails={{
          [this.props.match.params.id]: this.props.match.params.id && `#${this.props.match.params.id}`,
        }}
      >
        {this.props.match.params.id ? (
          <GMHelmet
            overrideTitle={`Edit gift message #${this.props.match.params.id || ''}` + postfix}
          />
        ) : null}
        <Spin spinning={detailGiftMessage.loading || updateGiftMessageLoading}>
          <Form
            storesList={stores.data}
            profile={profile}
            initialValues={initialValues}
            onSubmit={this.onEditFormSubmit}
          ></Form>
        </Spin>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  updateGiftMessageLoading: state.gift_message.edit.update.loading,
  detailGiftMessage: state.gift_message.edit.detail,
  stores: state.store.list.stores,
  profile: state.auth.info.profile,
});

const mapDispatchToProps = (dispatch) => ({
  getDetailGiftMessage: (id) => {
    dispatch(getDetailGiftMessage(id));
  },
  updateGiftMessage: (id, dataToUpdate) => {
    dispatch(updateGiftMessage(id, dataToUpdate));
  },
  getListStore: (options) => {
    dispatch(getListStore(options));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
