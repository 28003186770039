import React, { useState } from 'react';
import { FlipCardComp } from './FlipCard.styled';
import classNames from 'classnames';

export default ({
  front = null,
  back = null,
  onClick = () => {},
  className = '',
  animation = true,
  ...rest
}) => {
  const [isFlip, setFlip] = useState(0);
  const clickHandler = (evt) => {
    onClick(evt);
    clearTimeout(isFlip);
    setFlip(
      setTimeout(() => {
        setFlip(0);
      }, 5000)
    );
  };
  return (
    <FlipCardComp
      // ref={(r) => r?.scrollIntoView()}
      className={classNames(className, {
        flipped: isFlip,
        'no-animate': !animation,
      })}
      {...rest}
      onClick={clickHandler}
    >
      <div className="inner">
        {front && <div class="front">{front}</div>}
        {back && <div class="back">{back}</div>}
      </div>
    </FlipCardComp>
  );
};
