import { GRAY_500, GRAY_800, GRAY_900, PRIMARY_500 } from 'components/colors';
import PageContainer from 'components/PageContainer';
import styled from 'styled-components';

export const Wrapper = styled(PageContainer)`
  .topcta .ctas {
      display: flex;
      gap: 12px;
  }
  .cta-createnew {
    text-align: right;
    margin-bottom: 16px;
  }
  table svg {
    stroke: ${GRAY_500};
    width: 20px;
    height: 20px;
    &:hover {
      stroke: ${GRAY_800};
    }
  }
  svg {
    width: 20px;
    margin-right: 3px;
    margin-bottom: 3px;
  }
  table .edit {
    max-height: 100%;
    max-width: 100%;
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px dashed #d0d5dd;
    border-radius: 8px;
    padding: 4px;
  }
  .tabs {
    .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      background-color: transparent;
      color: ${PRIMARY_500}!important;
      border-bottom: 4px solid ${PRIMARY_500};
    }
    .ant-tabs-tab .ant-tabs-tab-btn {
      &:hover {
        color: ${PRIMARY_500};
      }
    }
    .ant-tabs-tab {
      &,
      &-btn,
      & &-btn,
      &-active,
      &-active &-btn {
        padding: 0;
        margin-right: 0;
        margin-right: 20px;
        align-items: unset;
      }
      &-btn,
      & &-btn {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: ${GRAY_500};
        align-items: unset;
      }
      &-btn,
      &-active &-btn,
      & &-btn {
        height: 36px;
        margin-bottom: -1px;
        padding: 0 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .tab-transaction {
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: ${GRAY_900};
        margin-top: 20px;
        span {
          display: block;
          float: left;
          line-height: 44px;
          font-size: 14px;
        }
        &:after {
          content: "";
          display: table;
          clear: both;
        }
      }
    }
    .ant-table-wrapper {
      margin-top: 32px;
    }
    .tab-transaction {
      padding: 0 8px;
    }
  }
  .ant-tag {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    border-radius: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    padding: 0 8px!important;
    box-sizing: content-box;
    margin: 0!important;
    b {
      font-weight: 500;
      text-transform: capitalize;
    }
  }
`;
