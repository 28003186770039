import styled from 'styled-components';

export default styled.div`
  padding: 8px;

  .next-button {
    max-width: 100%;
    margin-top: 12px;
    width: 666px;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 12px;
  }
  .api {
    max-width: 100%;
    margin-top: 12px;
    width: 666px;
    height: 44px;

    /* Primary/500 */

    background: #169dd8;
    /* Primary/600 */

    border: 1px solid #117ba9;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }
`;
