import { actionType } from "./actions";
import { notification } from "antd";

const initialState = {
  loading: false,
  serverSuccessInfo: null,
  serverErrorInfo: null,
  verifiedToken: {
    tokenValid: null,
  },
};

function ForgetReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.RESET.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.RESET.SUCCESS:
      return {
        ...state,
        loading: false,
        serverSuccessInfo: action.payload,
      };
    case actionType.RESET.ERROR:
      return {
        ...state,
        serverErrorInfo: action.error,
        loading: false,
      };
    //TOKENS:
    case actionType.VERIFY_TOKEN.REQUEST:
      return {
        ...state,
        loading: true,
        verifiedToken: {
          ...initialState.verifiedToken,
          tokenValid: null,
        },
      };
    case actionType.VERIFY_TOKEN.SUCCESS:
      return {
        ...state,
        loading: false,
        verifiedToken: {
          tokenValid: true,
        },
      };
    case actionType.VERIFY_TOKEN.ERROR:
      return {
        ...state,
        loading: false,

        verifiedToken: {
          tokenValid: false,
        },
      };
    default:
      return state;
  }
}

export default ForgetReducer;
