export const actionType = {
  FORGET: {
    REQUEST: "AUTH.FORGET.REQUEST",
    SUCCESS: "AUTH.FORGET.SUCCESS",
    ERROR: "AUTH.FORGET.ERROR",
  },
};
export function forgetPassword(data) {
  return {
    type: actionType.FORGET.REQUEST,
    data,
  };
}
export function forgotSuccessAction(data) {
  return {
    type: actionType.FORGET.SUCCESS,
    data,
  };
}
