import axios from "utils/request";

const prefix = "/paypal";

export const getListPaypal = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}`,
  });
};
export const setDefaultPaymentMethod = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/payout-request/set-default`,
  });
};

export const getDefaultGateway = () => {
  return axios({
    method: "GET",
    url: `/payout-request/default`,
  });
};

export const redirectPaypal = () => {
  return axios({
    method: "GET",
    url: `${prefix}/add-link`,
    is_json_content_type: true,
  });
};

export const redirectLianLian = () => {
  return axios({
    method: "GET",
    url: `/lianlian/generate-url`,
    is_json_content_type: true,
  });
};

export const cancelPaypal = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}/cancel`,
    is_json_content_type: true,
  });
};

export const removePaypal = (id) => {
  return axios({
    method: "DELETE",
    url: `${prefix}/${id}`,
    is_json_content_type: true,
  });
};

export const getPaypalInfoFromToken = (data) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}/process`,
    is_json_content_type: true,
  });
};

export const loginPayoneer = () => {
  return axios({
    method: "GET",
    url: `/payout-setting/login-link`,
    is_json_content_type: true,
  });
};

export const registerPayoneer = () => {
  return axios({
    method: "GET",
    url: `/payout-setting/register-link`,
    is_json_content_type: true,
  });
};

export const detailPayoneer = () => {
  return axios({
    method: "GET",
    url: `/payout-setting`,
    is_json_content_type: true,
  });
};

export const getListPayoneer = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `/payout-setting/payoneer-id`,
    is_json_content_type: true,
  });
};

export const cancelPayoneer = () => {
  return axios({
    method: "DELETE",
    url: `/payout-setting/payoneer-id`,
    is_json_content_type: true,
  });
};

export const submitRegisterPayoneer = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/payoneer/register-callback`,
  });
};

export const topUpPaypal = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/paypal/deposit`,
    is_json_content_type: true,
  });
};

export const payoneerCallback = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/payoneer/callback`,
  });
};

export const lianlianCallback = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/lianlian/connect`,
  });
};
