import { removeCookie } from 'utils/helper';
import { actionType as TYPE } from './actions';
import { notification } from 'antd';
import { EBAY_INTEGRATE, SHOPIFY_INTEGRATE } from 'web.config';

const initialState = {
  loading: false,
  getLinkEbay: {
    data: {},
    loading: false,
  },
  getLinkShopbase: {
    data: {},
    loading: false,
  },
  getShopifyLink: {
    data: {},
    loading: false,
  },
  getEtsyLink: {
    data: {},
    loading: false,
  },
  getAmazonLink: {
    data: {},
    loading: false,
  },
  createAmazonStore: {
    data: {},
    loading: false,
  },
  checkConnectWithShopify: {
    data: {},
    loading: false,
  },
  getUrlFromShopify: {
    data: {},
    loading: false,
  },
  connectStoreShopify: {
    data: {},
    loading: false,
  },
};

function PostCreateReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.CREATE_STORE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.CREATE_STORE.SUCCESS:
      notification.success({
        message: 'Create store successfully.',
        duration: 5,
      });
      return {
        ...state,
        loading: false,
      };
    case TYPE.CREATE_STORE.FAIL:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Create store failed.',
        duration: 5,
      });
      return {
        ...state,
        loading: false,
      };
    //Create ebay
    case TYPE.CREATE_EBAY_STORE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.CREATE_EBAY_STORE.SUCCESS:
      notification.success({
        message: 'Create store successfully.',
        duration: 5,
      });
      localStorage.removeItem('EBAY_INTEGRATE');
      removeCookie(EBAY_INTEGRATE);
      return {
        ...state,
        loading: false,
      };
    case TYPE.CREATE_EBAY_STORE.FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Create store failed.',
        duration: 5,
      });
      return {
        ...state,
        loading: false,
      };
    //Create shopbase shopbase
    case TYPE.CREATE_SHOPBASE_STORE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.CREATE_SHOPBASE_STORE.SUCCESS:
      notification.success({
        message: 'Create store successfully.',
        duration: 5,
      });
      return {
        ...state,
        loading: false,
      };
    case TYPE.CREATE_SHOPBASE_STORE.FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Create store failed.',
        duration: 5,
      });
      return {
        ...state,
        loading: false,
      };
    //Create  etsy
    case TYPE.CREATE_ETSY_STORE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.CREATE_ETSY_STORE.SUCCESS:
      notification.success({
        message: 'Create store successfully.',
        duration: 5,
      });
      return {
        ...state,
        loading: false,
      };
    case TYPE.CREATE_ETSY_STORE.FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Create store failed.',
        duration: 5,
      });
      return {
        ...state,
        loading: false,
      };

    //Get link Ebay
    case TYPE.GET_EBAY_LINK.REQUEST:
      return {
        ...state,
        getLinkEbay: {
          ...initialState.getLinkEbay,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.GET_EBAY_LINK.SUCCESS:
      return {
        ...state,
        getLinkEbay: {
          ...state.getLinkEbay,
          loading: false,
          data: action.data && action.data ? action.data : [],
        },
      };
    case TYPE.GET_EBAY_LINK.FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Create store failed.',
        duration: 5,
      });
      return {
        ...state,
        getLinkEbay: {
          ...state.getLinkEbay,
          loading: false,
        },
      };
    //Get link Amazon
    case TYPE.GET_AMAZON_LINK.REQUEST:
      return {
        ...state,
        getAmazonLink: {
          ...initialState.getAmazonLink,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.GET_AMAZON_LINK.SUCCESS:
      return {
        ...state,
        getAmazonLink: {
          ...state.getAmazonLink,
          loading: false,
          data: action.data && action ? action : [],
        },
      };
    case TYPE.GET_AMAZON_LINK.FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Create store failed.',
        duration: 5,
      });
      return {
        ...state,
        getAmazonLink: {
          ...state.getAmazonLink,
          loading: false,
        },
      };
    //Connect Amazon
    case TYPE.CREATE_AMAZON_STORE.REQUEST:
      return {
        ...state,
        createAmazonStore: {
          ...initialState.createAmazonStore,
          loading: true,
        },
      };
    case TYPE.CREATE_AMAZON_STORE.SUCCESS:
      notification.success({
        message: 'Create store successfully.',
        duration: 5,
      });
      return {
        ...state,
        createAmazonStore: {
          ...state.createAmazonStore,
          loading: false,
        },
      };
    case TYPE.CREATE_AMAZON_STORE.FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Create store failed.',
        duration: 5,
      });

      return {
        ...state,
        createAmazonStore: {
          ...state.createAmazonStore,
          loading: false,
        },
      };

    //Get link Etsy
    case TYPE.GET_ETSY_LINK.REQUEST:
      return {
        ...state,
        getEtsyLink: {
          ...initialState.getEtsyLink,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.GET_ETSY_LINK.SUCCESS:
      return {
        ...state,
        getEtsyLink: {
          ...state.getEtsyLink,
          loading: false,
          data: action.data && action.data ? action.data : [],
        },
      };
    case TYPE.GET_ETSY_LINK.FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Create store failed.',
        duration: 5,
      });
      return {
        ...state,
        getEtsyLink: {
          ...state.getEtsyLink,
          loading: false,
        },
      };
    // get shopify link
    case TYPE.GET_SHOPIFY_LINK.REQUEST:
      return {
        ...state,
        getShopifyLink: {
          ...initialState.getShopifyLink,
          loading: true,
        },
      };
    case TYPE.GET_SHOPIFY_LINK.SUCCESS:
      return {
        ...state,
        getShopifyLink: {
          ...state.getShopifyLink,
          loading: false,
          data: action.data && action.data ? action.data : [],
        },
      };
    case TYPE.GET_SHOPIFY_LINK.FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Create store failed.',
        duration: 5,
      });
      return {
        ...state,
        getShopifyLink: {
          ...state.getShopifyLink,
          loading: false,
        },
      };

    //Step 1: Check connect with Shopify:
    case TYPE.CHECK_CONNECT_SHOPIFY.REQUEST:
      return {
        ...state,
        checkConnectWithShopify: {
          ...initialState.checkConnectWithShopify,
          loading: true,
        },
      };
    case TYPE.CHECK_CONNECT_SHOPIFY.SUCCESS:
      // localStorage.setItem('isCheckConnectSuccessful', true);
      return {
        ...state,
        checkConnectWithShopify: {
          ...state.checkConnectWithShopify,
          loading: false,
          data: action.data && action.data ? action.data : [],
        },
      };
    case TYPE.CHECK_CONNECT_SHOPIFY.FAIL:
      notification.warn({
        message: action && action?.clientMsg ? action.clientMsg : '',
        duration: 5,
      });
      return {
        ...state,
        checkConnectWithShopify: {
          ...state.checkConnectWithShopify,
          loading: false,
        },
      };
    //Step 2: Get URL Shopify:
    case TYPE.GET_URL_SHOPIFY.REQUEST:
      return {
        ...state,
        getUrlFromShopify: {
          ...initialState.getUrlFromShopify,
          loading: true,
        },
      };
    case TYPE.GET_URL_SHOPIFY.SUCCESS:
      localStorage.setItem('isGetUrlSuccessful', true);
      return {
        ...state,
        getUrlFromShopify: {
          ...state.getUrlFromShopify,
          loading: false,
          data: action.data && action.data ? action.data : [],
        },
      };
    case TYPE.GET_URL_SHOPIFY.FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : '',
        duration: 5,
      });
      return {
        ...state,
        getUrlFromShopify: {
          ...state.getUrlFromShopify,
          loading: false,
        },
      };
    //Step 3: Connect with Shopify:
    case TYPE.CONNECT_SHOPIFY.REQUEST:
      localStorage.removeItem('isGetUrlSuccessful');
      return {
        ...state,
        connectStoreShopify: {
          ...initialState.connectStoreShopify,
          loading: true,
        },
      };
    case TYPE.CONNECT_SHOPIFY.SUCCESS:
      /**Use for connect Shopify from app Shopify */
      // localStorage.removeItem('isCheckConnectSuccessful');
      /**Use for connect Shopify from app Shopify */
      localStorage.removeItem('SHOPIFY_INTEGRATE');

      localStorage.removeItem('isGetUrlSuccessful');

      action.data?.status === 'error'
        ? notification.warning({
            message:
              action && action.data?.clientMsg ? action.data?.clientMsg : '',
            duration: 5,
          })
        : notification.success({
            message:
              action && action.data?.clientMsg ? action.data?.clientMsg : '',
            duration: 5,
          });

      return {
        ...state,
        connectStoreShopify: {
          ...state.connectStoreShopify,
          loading: false,
          data: action,
        },
      };
    case TYPE.CONNECT_SHOPIFY.FAIL:
      // localStorage.removeItem('isCheckConnectSuccessful');
      localStorage.removeItem('isGetUrlSuccessful');

      notification.warn({
        message:
          action && action?.data?.clientMsg ? action?.data?.clientMsg : '',
        duration: 5,
      });

      return {
        ...state,
        connectStoreShopify: {
          ...state.connectStoreShopify,
          loading: false,
        },
      };

    //Get link shopbase
    case TYPE.GET_SHOPBASE_LINK.REQUEST:
      return {
        ...state,
        getLinkShopbase: {
          ...initialState.getLinkShopbase,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.GET_SHOPBASE_LINK.SUCCESS:
      return {
        ...state,
        getLinkShopbase: {
          ...state.getLinkShopbase,
          loading: false,
          data: action.data && action.data ? action.data : [],
        },
      };
    case TYPE.GET_SHOPBASE_LINK.FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Create store failed.',
        duration: 5,
      });
      return {
        ...state,
        getLinkShopbase: {
          ...state.getLinkShopbase,
          loading: false,
        },
      };

    default:
      return state;
  }
}

export default PostCreateReducer;
