import React, { useEffect } from "react";
import { Space, Switch, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;
export default ({
  input,
  suffix,
  prefix,
  defaultValue = null,
  isShowConfirmPopup = false,
  confirmTitle = "",
  confirmContent = "",
}) => {
  useEffect(() => {
    if (Boolean(input.value) === true && isShowConfirmPopup) {
      confirm({
        title: confirmTitle,
        icon: <ExclamationCircleOutlined />,
        content: confirmContent,
        onOk() {
          input.onChange(true);
        },
        onCancel() {
          input.onChange(false);
        },
      });
    }
  }, [input.value]);
  const checked =
    defaultValue !== null ? defaultValue : input.value ? true : false;
  return (
    <Space className="cursor-pointer">
      {prefix && (
        <span onClick={() => input.onChange(input.value ? false : true)}>
          {prefix}
        </span>
      )}
      <Switch
        checked={checked}
        onChange={(e) => input.onChange(e)}
        className={checked ? "activeSwitch" : ""}
      />
      {suffix && (
        <span onClick={() => input.onChange(input.value ? false : true)}>
          {suffix}
        </span>
      )}
    </Space>
  );
};
