import React, { useState } from "react";
import { Spin } from "antd"
import { exportTransactionInvoice } from "apis/transactions";
import { exportOrderInvoice } from "apis/orders";
import print from 'print-js'
import { GMButton } from "components/Button";
import { Wrapper } from "./RenderPrintPDF.styled";

export default ({ id = 0, type }) => {
  const [loading, setLoading] = useState(false);
  const handleOnPrintPDF = () => {
    if (id > 0) {
      setLoading(true);
      if (type === "printTransactionInvoice") {
        exportTransactionInvoice(id)
          .then(({ code, data }) => {
            setLoading(false);
            if (code === 'success' && data?.url) {
              print(data?.url);
            }
          }).catch(error => {
            setLoading(false);
          })
      } else if (type === 'printOrderInvoice') {
        exportOrderInvoice(id)
          .then(({ code, data }) => {
            setLoading(false);
            if (code === 'success' && data?.url) {
              print(data?.url);
            }
          }).catch(error => {
            setLoading(false);
          })
      }
    }
  }
  return (
    <Wrapper>
      <Spin spinning={loading} tip={"Please wait while we are processing your request."} size={"small"}>
        <GMButton className="btn btn-primary d-block text-center" onClick={handleOnPrintPDF}>Print</GMButton>
      </Spin>
    </Wrapper>
  )
}