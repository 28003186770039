import React, { Component } from "react";
import { connect } from "react-redux";
import FormFulfillment from "./components/form";
import { Spin } from "antd";
import { updateAutoCharge, getListCustomerConfig } from "./actions";
import { Wrapper } from "./styled";
export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKeyTag: "1"
    };
  }

  componentDidMount() {
    this.props.getListCustomerConfig();
    this.props.getListCustomerConfig();
  }

  updateAutoCharge = (value) => {
    let dataToUpdateAutoCharge = {
      configs: {
        autoSyncTracking: value.autoSyncTracking ? 1 : 0,
        autoCharge: value.autoCharge ? 1 : 0,
        defaultWallet: value.defaultWallet ? 1 : 0,
        autoApprove: value.autoApprove ? 1 : 0,
        amazonConfirmShipment: value.amazonConfirmShipment ? 1 : 0,
        amazonAutoReplaceTracking: value.amazonAutoReplaceTracking ? 1 : 0,
        autoMatchProductSku: value.autoMatchProductSku ? 1 : 0,
        autoBuyTracking: value.autoBuyTracking ? 1 : 0,
        enableReceiveTrackingEmail: value.enableReceiveTrackingEmail ? 1 : 0,
      },
    };
    this.props.updateAutoCharge(dataToUpdateAutoCharge);
  };
  render() {
    const {
      listCustomerConfig,
      updateAutoChargeLoading,
      pageName,
    } = this.props;
    const initialAutoCharge = {
      autoSyncTracking:
        listCustomerConfig?.data?.auto_sync_tracking?.value === "1",
      autoCharge: listCustomerConfig?.data.auto_charge?.value === "1",
      defaultWallet: listCustomerConfig?.data?.default_wallet?.value === "1",
      autoApprove: listCustomerConfig?.data?.auto_approve?.value === "1",
      amazonConfirmShipment:
        listCustomerConfig?.data?.amazon_confirm_shipment?.value === "1",
      amazonAutoReplaceTracking:
        listCustomerConfig?.data?.amazon_auto_replace_tracking?.value === "1",
      autoMatchProductSku:
        listCustomerConfig?.data?.auto_match_product_sku?.value === "1",
      autoBuyTracking:
        listCustomerConfig?.data?.auto_buy_tracking?.value === "1",
      enableReceiveTrackingEmail:
        listCustomerConfig?.data?.enable_receive_tracking_email?.value === "1",
    };
    return (
      <Wrapper pageName={pageName}>
        <Spin
          spinning={listCustomerConfig.loading || updateAutoChargeLoading}
        >
          <FormFulfillment
            is_mobile={this.props.is_mobile}
            profile={this.props.profile}
            invoice={listCustomerConfig}
            initialValues={initialAutoCharge}
            onSubmit={this.updateAutoCharge}
          />
        </Spin>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  updateAutoChargeLoading: state.general.edit.updateAutoCharge.loading,
  listCustomerConfig: state.general.edit.listCustomerConfig,
});

const mapDispatchToProps = (dispatch) => ({
  updateAutoCharge: (dataToUpdateAutoCharge) => {
    dispatch(updateAutoCharge(dataToUpdateAutoCharge));
  },
  getListCustomerConfig: () => {
    dispatch(getListCustomerConfig());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
