import Analytics from './index'

export default [
    // {
    //     key: 'analytics',
    //     name: 'Analytics',
    //     component: Analytics,
    //     path: '/analytics',
    //     template: 'default',  
    //     isPublic: true
    // }
]