import { 
    takeLatest, 
    call, 
    put, 
    all 
} from 'redux-saga/effects'
import {
    actionType as TYPE
} from './actions'

import axios from 'utils/request'
  
function detailAPI(id) {
    return axios({
        method: 'get',
        url: `/schedule-campaigns/${id}?with[]=storeIntegration`
    })
}

function* detailSaga(action) {
    try {
        const { id } = action
        const response = yield call(detailAPI, id)
        
        if(response.code === 'success'){
            yield all([
                put({type: TYPE.DETAIL.SUCCESS, ...response}),
            ])
        }else{
          yield put({type: TYPE.DETAIL.ERROR, response})
        }
    } catch (error) {
        yield all([
            put({type: TYPE.DETAIL.ERROR, error})
        ])
    }
}
  
function* watcher() {
    yield all([
        takeLatest(TYPE.DETAIL.REQUEST, detailSaga),
    ])
}
  
  export default watcher