export const orderTabs = {
    "All orders": {
        "title": "All orders",
        "value": null
    },
    "Pending": {
        "title": "Pending",
        "value": 1139,
        "queryString": "?status=0"
    },
    "Processing": {
        "title": "Processing",
        "value": 168,
        "queryString": "?status=1"
    },
    "On-hold": {
        "title": "On-hold",
        "value": 11,
        "queryString": "?status=on-hold"
    },
    "Completed": {
        "title": "Completed",
        "value": 11,
        "queryString": "?status=2"
    },
    "Canceled": {
        "title": "Canceled",
        "value": 2,
        "queryString": "?status=3"
    },
    "Order matched": {
        "title": "Order matched",
        "value": null,
        "queryString": "?isImport=1"
    },
    "Order unmatched": {
        "title": "Order unmatched",
        "value": null,
        "queryString": "?isImport=2"
    },
    "Order shipping": {
        "title": "Order shipping",
        "value": 0,
        "queryString": "?tracking=1"
    },
    "Last updated": {
        "title": "Last updated",
        "value": null,
        "queryString": "?sortBy=updatedAt&sortType=desc"
    }
};