export const ORDER_DETAIL_REQUEST = 'ORDER_DETAIL_REQUEST';
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS';
export const ORDER_DETAIL_FAIL = 'ORDER_DETAIL_FAIL';

export const ORDER_UPDATE_ADDRESS_REQUEST = 'ORDER_UPDATE_ADDRESS_REQUEST';
export const ORDER_UPDATE_ADDRESS_SUCCESS = 'ORDER_UPDATE_ADDRESS_SUCCESS';
export const ORDER_UPDATE_ADDRESS_FAIL = 'ORDER_UPDATE_ADDRESS_FAIL';

export const UPDATE_GIFT_MESSAGE_REQUEST = 'UPDATE_GIFT_MESSAGE_REQUEST';
export const UPDATE_GIFT_MESSAGE_SUCCESS = 'UPDATE_GIFT_MESSAGE_SUCCESS';
export const UPDATE_GIFT_MESSAGE_FAIL = 'UPDATE_GIFT_MESSAGE_FAIL';

export const UPDATE_SHIPPING_SERVICE_REQUEST =
  'UPDATE_SHIPPING_SERVICE_REQUEST';
export const UPDATE_SHIPPING_SERVICE_SUCCESS =
  'UPDATE_SHIPPING_SERVICE_SUCCESS';
export const UPDATE_SHIPPING_SERVICE_FAIL = 'UPDATE_SHIPPING_SERVICE_FAIL';

export const DUPLICATE_ORDER_REQUEST = 'DUPLICATE_ORDER_REQUEST';
export const DUPLICATE_ORDER_SUCCESS = 'DUPLICATE_ORDER_SUCCESS';
export const DUPLICATE_ORDER_FAIL = 'DUPLICATE_ORDER_FAIL';

export const LOCK_ORDER_REQUEST = 'LOCK_ORDER_REQUEST';
export const LOCK_ORDER_SUCCESS = 'LOCK_ORDER_SUCCESS';
export const LOCK_ORDER_FAIL = 'LOCK_ORDER_FAIL';

export const LIST_DUPLICATED_ORDER_REQUEST = 'LIST_DUPLICATED_ORDER_REQUEST';
export const LIST_DUPLICATED_ORDER_SUCCESS = 'LIST_DUPLICATED_ORDER_SUCCESS';
export const LIST_DUPLICATED_ORDER_FAIL = 'LIST_DUPLICATED_ORDER_FAIL';

export const MARK_AS_VALID_ADDRESS_REQUEST = 'MARK_AS_VALID_ADDRESS_REQUEST';
export const MARK_AS_VALID_ADDRESS_SUCCESS = 'MARK_AS_VALID_ADDRESS_SUCCESS';
export const MARK_AS_VALID_ADDRESS_FAIL = 'MARK_AS_VALID_ADDRESS_FAIL';

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAIL = 'CANCEL_ORDER_FAIL';