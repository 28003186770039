import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import { createDataFeedGoogle } from "./actions";
import BackToIcon from "../../../../images/chevron-down.svg";
import Form from "./components/form";
import feed_google from "assets/images/feed_google.png";
import arrow from "assets/images/arrow.png";
import google_icon from "assets/images/google_icon.png";
import { Wrapper } from "scenes/DataFeed/styled";

class Google extends Component {
  handleSubmit = (values) => {
    this.props.createDataFeedGoogle({
      ...values,
      default: values.default ? 1 : 0,
      status: values.status ? 1 : 0,
    });
  };
  render() {
    const { pageName } = this.props;
    return (
      <Wrapper
        pageName={pageName}
      >
        <div className="text-center mb-3">
          <img src={feed_google} height="90" alt="google-icon" />
          <img src={arrow} width="40" className="mx-4" alt="arrow-icon" />
          <img src={google_icon} height="70" alt="google-icon" />
        </div>
        <Form
          onSubmit={this.handleSubmit}
          initialValues={{
            default: 0,
            status: 1,
            googleColorSize: "yes",
            googleIdentifierExists: "yes",
          }}
        />
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.data_feed.google.loading,
  }),
  (dispatch) => ({
    createDataFeedGoogle: (data) => {
      dispatch(createDataFeedGoogle(data));
    },
  })
)(Google);