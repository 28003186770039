import { isValidEmail } from "utils/helper";
const validate = (values) => {
    const errors = {};
    if(!values.feedFormId || values.feedFormId == 0){
        errors.feedFormId = 'Please choose a feed form';
    }
    if(values.email && !isValidEmail(values.email)){
        errors.email = "Please enter a valid email.";
    }
    if (!values.listCampaignsToExport || values.listCampaignsToExport.length == 0) {
        errors.listCampaignsToExport = [{ products: 'Please select a style product to export' }];
    } else {
        const conditionArrayErrors = [];
        for (let index = 0; index < values.listCampaignsToExport.length; index++) {
          const element = values.listCampaignsToExport[index];
          const conditionErrors = {};
          if (!element.products || !Array.isArray(element.products) || element.products.length == 0) {
            conditionErrors.products = "Please select a style product to export";
          }
          if (!element.colors || !Array.isArray(element.colors) || element.colors.length == 0) {
            conditionErrors.colors = "Please select a color.";
          }
          
          if (!element.sizes || !Array.isArray(element.sizes) || element.sizes.length == 0) {
            conditionErrors.sizes = "Please select a size.";
          }
          conditionArrayErrors[index] = conditionErrors;
        }
        if (conditionArrayErrors.length) {
          errors.listCampaignsToExport = conditionArrayErrors;
        }
      }
    return errors;
};

export default validate;