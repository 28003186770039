import { notification } from "antd";
import { actionType as TYPE } from "./actions";

const initialState = {
  update: {
    loading: false,
  },
  detail: {
    loading: false,
    data: {},
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.GIFTMESSAGE_DETAIL.REQUEST:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          loading: true,
        },
      };
    case TYPE.GIFTMESSAGE_DETAIL.SUCCESS:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          data: action?.data?.gift || {},
          loading: false,
        },
      };
    case TYPE.GIFTMESSAGE_DETAIL.ERROR:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
        },
      };

    case TYPE.GIFTMESSAGE_UPDATE.REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      };
    case TYPE.GIFTMESSAGE_UPDATE.SUCCESS:
      notification.success({
        message: "Update gift message successfully.",
        duration: 5,
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
        },
      };
    case TYPE.GIFTMESSAGE_UPDATE.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Update gift message failed.",
        duration: 5,
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
