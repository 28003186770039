import React, { Component } from 'react';

class IframeBuilder extends Component {
    componentDidMount() {
        this.iframe_builder.addEventListener('load', this.onLoad);
    }
    componentWillUnmount() {
        window.removeEventListener("message", this.receiveMessage);
    }
    
    receiveMessage = event =>{
        // if (event.origin !== "http://example.com")
        //     return;
        if(typeof event.data === 'string'){
            const data = JSON.parse(event.data);
            switch(data.type){
                case 'save':
                    this.props.onSave(data.value)
                break;
                case 'cancel':
                    this.props.onCancel()
                break;
                default:
                    // nothing to do
            }
        }
    }
    onLoad =()=>{
        this.iframe_builder.contentWindow.postMessage(JSON.stringify({ type: 'init', value: this.props.value}), "*");
    
        window.addEventListener("message", this.receiveMessage, false);
    }

    render() {
        return (
            <iframe width="100%" height="100%" style={{border: 'none'}} ref={ele => this.iframe_builder = ele} src={`/cms/content_builder/${this.props.builderType}`}></iframe> 
        );
    }
}

export default IframeBuilder;