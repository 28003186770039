import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Divider, Table, Space, notification } from 'antd';
import nophotoImg from 'images/noPhoto.png';
import SelectProductsSize from 'utils/Form/Search/SelectProductsSize';
import SelectProduct from 'utils/Form/Search/SelectProduct';
import { GMButton } from 'components/Button';
import money from 'utils/money';
import SelectProductsColor from 'utils/Form/Search/SelectProductsColor';
import RenderPreviewDesign from 'utils/Form/renderPreviewDesign';
import renderInputText from 'utils/Form/renderInputText';
import { ReactComponent as TrashIcon } from 'images/icons/trash-01.svg';
import { ReactComponent as AddIcon } from 'images/icons/plus.svg';
import { renderSelectAnt } from 'utils/Form';
import { Wrapper, WrapperColumn, WrapperIcon } from './styled';
import UploadImageCreateOrder from '../UploadImageCreateOrder';
import GMNotify from 'components/Notification';

export default class renderCreateOrderItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      listPrintType: {},
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const {
      fields,
      meta: { error },
      data,
      changeField,
      exproducts,
      cusId,
    } = this.props;
    const { selectedRowKeys, listPrintType } = this.state;
    const columns = [
      {
        title: 'Product name',
        dataIndex: 'brand',
        align: 'center',
        width: 150,
        key: 'brand',
        render: (text, record, i) => {
          let sidePrint = listPrintType[`${record?.productId}_${i}`];
          return (
            <WrapperColumn>
              <Field
                name={`${fields.name}[${i}].productId`}
                component={SelectProduct}
                data={exproducts}
                classNameContainer="mb-4"
                onChoose={(id, data) => {
                  const listSidePrint = [];
                  if (data?.listSidePrint) {
                    Object.keys(data?.listSidePrint).forEach((item) => {
                      listSidePrint.push({
                        value: item,
                        label: data.listSidePrint[item],
                      });
                    });
                  }
                  if (listSidePrint?.length > 0) {
                    listSidePrint.unshift({
                      value: 'normal',
                      label: 'Normal',
                    });
                  }
                  this.setState((state) => {
                    return {
                      listPrintType: {
                        [`${id}_${i}`]: listSidePrint,
                        ...state.listPrintType,
                      },
                    };
                  });
                  if (typeof changeField === 'function') {
                    changeField(`${fields.name}[${i}].colorId`, 0);
                    changeField(`${fields.name}[${i}].sizeId`, 0);
                    changeField(`${fields.name}[${i}].colorHex`, null);
                    changeField(`${fields.name}[${i}].sidePrint`, null);
                    changeField(`${fields.name}[${i}].type`, data?.type);
                    changeField(
                      `${fields.name}[${i}].productDisplayName`,
                      data?.brand
                    );
                    changeField(
                      `${fields.name}[${i}].printLocation`,

                      data?.printLocation
                    );
                    changeField(
                      `${fields.name}[${i}].basePrice`,
                      data.basePrice
                    );
                    changeField(`${fields.name}[${i}].display`, data.display);
                    changeField(
                      `${fields.name}[${i}].mockup`,
                      data.frontImg || ''
                    );
                  }
                }}
                placeholder="-- Select Product --"
              />
              <Field
                name={`${fields.name}[${i}].colorId`}
                component={SelectProductsColor}
                expId={fields.get(i).productId}
                classNameContainer="mb-4"
                placeholder="-- Select color --"
                onChoose={(id, data) => {
                  if (typeof changeField === 'function' && data) {
                    changeField(
                      `${fields.name}[${i}].colorHex`,
                      `#${data.value2}`
                    );
                    changeField(`${fields.name}[${i}].sizeId`, 0);
                  }
                }}
              />
              <Field
                name={`${fields.name}[${i}].sizeId`}
                component={SelectProductsSize}
                expId={fields.get(i).productId}
                classNameContainer="mb-4"
                colorId={fields.get(i).colorId}
                checkStockAvailability={true}
                placeholder="-- Select size --"
                onChoose={(data, currOption) => {
                  if (typeof changeField === 'function' && currOption) {
                    changeField(`${fields.name}[${i}].mockup`, currOption?.mockup);
                    changeField(`${fields.name}[${i}].basePrice`,currOption?.priceShow);
                  }
                }}
              />
              <Field
                name={`${fields.name}[${i}].quantity`}
                classNameContainer={'mb-4'}
                component={renderInputText}
                allowClear={true}
                type="number"
                placeholder={'Quantity'}
                isNumberInputQuantity={true}
                addonBefore="Quantity"
              />
              {sidePrint && Array.isArray(sidePrint) && sidePrint.length > 0 && (
                <Field
                  name={`${fields.name}[${i}].sidePrint`}
                  inputProps={{ className: 'mb-4' }}
                  component={renderSelectAnt}
                  options={sidePrint}
                  placeholder="-- Placements --"
                  onChange={(id) => {
                    if (id === 'whole') {
                      changeField(
                        `${fields.name}[${i}].printLocation`,
                        'front'
                      );
                      changeField(`${fields.name}[${i}].designBackId`, '');
                      changeField(`${fields.name}[${i}].designBack`, '');
                    } else {
                      changeField(
                        `${fields.name}[${i}].printLocation`,
                        exproducts?.find(
                          (ep) => ep.id * 1 === data?.[i]?.productId * 1
                        )?.printLocation
                      );
                    }
                  }}
                />
              )}
            </WrapperColumn>
          );
        },
      },
      {
        title: 'Mockup',
        dataIndex: 'mockup',
        key: 'mockup',
        align: 'center',
        width: 250,
        render: (text, record) => {
          return (
            <img
              src={record.mockup || nophotoImg}
              width="100"
              style={{
                backgroundColor: record.colorHex
                  ? `${record.colorHex}`
                  : 'transparent',
              }}
              alt="mockup"
            />
          );
        },
      },
      {
        title: 'Design',
        key: 'designFront',
        align: 'center',
        dataIndex: 'designFront',
        width: 250,
        render: (text, record, i) => {
          const colorString = record.colorHex
            ? `${record.colorHex}`
            : 'transparent';
          return (
            <Space>
              {record?.productId !== '0' ? (
                <div>
                  {record?.printLocation?.includes('front,back') ? (
                    <div className="d-flex columns">
                      <div>
                        <Field
                          name={`${fields.name}[${i}].designFront`}
                          changeDesignFront={(value) => {
                            changeField(
                              `${fields.name}[${i}].designFront`,
                              value
                            );
                            value !== '' &&
                              notification.success({
                                message:
                                  'Assign design for order item - side front successfully.',
                                duration: 5,
                              });
                          }}
                          changeDesignBack={(value) => {
                            changeField(
                              `${fields.name}[${i}].designBack`,
                              value
                            );
                            value !== '' &&
                              notification.success({
                                message:
                                  'Assign design for order item - side back successfully.',
                                duration: 5,
                              });
                          }}
                          changeDesignFrontId={(value) =>
                            changeField(
                              `${fields.name}[${i}].designFrontId`,
                              value
                            )
                          }
                          changeDesignBackId={(value) =>
                            changeField(
                              `${fields.name}[${i}].designBackId`,
                              value
                            )
                          }
                          component={UploadImageCreateOrder}
                          printLocation={record.printLocation}
                          cusId={cusId}
                          isCreatingOrder={true}
                        />

                        {record?.productDisplay * 1 || record?.display * 1 ? (
                          <RenderPreviewDesign
                            isCreatingOrder={true}
                            colorHex={colorString}
                            exProductId={record.productId}
                            colorId={record.colorId}
                            sidePrint={'front'}
                            sizeId={record.sizeId}
                            design={record.designFront}
                            typePrint={record.sidePrint}
                            productName={record.productDisplayName}
                            printLocation={record.printLocation}
                          />
                        ) : null}
                      </div>
                      <div>
                        <Field
                          name={`${fields.name}[${i}].designBack`}
                          changeDesignFront={(value) => {
                            changeField(
                              `${fields.name}[${i}].designFront`,
                              value
                            );
                            value !== '' &&
                              notification.success({
                                message:
                                  'Assign design for order item - side front successful',
                                duration: 5,
                              });
                          }}
                          changeDesignBack={(value) => {
                            changeField(
                              `${fields.name}[${i}].designBack`,
                              value
                            );
                            value !== '' &&
                              notification.success({
                                message:
                                  'Assign design for order item - side back successful',
                                duration: 5,
                              });
                          }}
                          changeDesignFrontId={(value) =>
                            changeField(
                              `${fields.name}[${i}].designFrontId`,
                              value
                            )
                          }
                          changeDesignBackId={(value) =>
                            changeField(
                              `${fields.name}[${i}].designBackId`,
                              value
                            )
                          }
                          component={UploadImageCreateOrder}
                          printLocation={record.printLocation}
                          cusId={cusId}
                          isCreatingOrder={true}
                        />
                        {record?.productDisplay * 1 || record?.display * 1 ? (
                          <RenderPreviewDesign
                            isCreatingOrder={true}
                            colorHex={colorString}
                            exProductId={record.productId}
                            colorId={record.colorId}
                            sidePrint={'back'}
                            sizeId={record.sizeId}
                            typePrint={record.sidePrint}
                            design={record.designBack}
                            productName={record.productDisplayName}
                            printLocation={record.printLocation}
                          />
                        ) : null}
                      </div>
                    </div>
                  ) : record?.printLocation?.includes('front') ? (
                    <div>
                      <Field
                        name={`${fields.name}[${i}].designFront`}
                        changeDesignFront={(value) => {
                          changeField(
                            `${fields.name}[${i}].designFront`,
                            value
                          );
                          value !== '' &&
                            notification.success({
                              message:
                                'Assign design for order item - side front successfully.',
                              duration: 5,
                            });
                        }}
                        changeDesignFrontId={(value) =>
                          changeField(
                            `${fields.name}[${i}].designFrontId`,
                            value
                          )
                        }
                        component={UploadImageCreateOrder}
                        printLocation={record.printLocation}
                        cusId={cusId}
                        isCreatingOrder={true}
                      />
                      {record?.productDisplay * 1 || record?.display * 1 ? (
                        <RenderPreviewDesign
                          isCreatingOrder={true}
                          colorHex={colorString}
                          exProductId={record.productId}
                          colorId={record.colorId}
                          sidePrint={'front'}
                          sizeId={record.sizeId}
                          design={record.designFront}
                          typePrint={record.sidePrint}
                          productName={record.productDisplayName}
                          printLocation={record.printLocation}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      <Field
                        name={`${fields.name}[${i}].designBack`}
                        changeDesignBack={(value) => {
                          changeField(`${fields.name}[${i}].designBack`, value);
                          value !== '' &&
                            notification.success({
                              message:
                                'Assign design for order item - side back successful',
                              duration: 5,
                            });
                        }}
                        changeDesignBackId={(value) =>
                          changeField(
                            `${fields.name}[${i}].designBackId`,
                            value
                          )
                        }
                        component={UploadImageCreateOrder}
                        printLocation={record.printLocation}
                        cusId={cusId}
                        isCreatingOrder={true}
                      />
                      {record?.productDisplay * 1 || record?.display * 1 ? (
                        <RenderPreviewDesign
                          isCreatingOrder={true}
                          colorHex={colorString}
                          exProductId={record.productId}
                          colorId={record.colorId}
                          sidePrint={'back'}
                          sizeId={record.sizeId}
                          typePrint={record.sidePrint}
                          design={record.designBack}
                          productName={record.productDisplayName}
                          printLocation={record.printLocation}
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </Space>
          );
        },
      },
      {
        title: 'Price',
        dataIndex: 'basePrice',
        align: 'center',
        width: 120,
        key: 'basePrice',
        render: (text) => {
          return money(text);
        },
      },
      {
        title: '',
        key: 'action',
        width: 100,
        render: (text, record, i) => {
          return (
            <div>
              {fields.length >= 2 && (
                <WrapperIcon className="text-center cursor-pointer">
                  <TrashIcon
                    onClick={() => {
                      fields.remove(i);
                    }}
                  />
                </WrapperIcon>
              )}
            </div>
          );
        },
      },
    ];
    return (
      <Wrapper>
        <div className="d-flex row justify-content-between align-items-center px-3 design-product">
          <div>
            <b>
              Products information {fields.length ? `(${fields.length})` : ''}
            </b>
          </div>
          <GMButton
            type="button"
            color={'SecondaryGray'}
            onClick={() =>
              fields.push({
                key: fields.length,
                productId: '0',
                colorId: '0',
                sizeId: '0',
                quantity: 1,
                price: 0,
                designFront: '',
                designBack: '',
                mockup: '',
                designFrontId: '',
                designBackId: '',
                sidePrint: '',
                printLocation: '',
                typeProduct: '',
              })
            }
          >
            <b className="add-icon">
              <AddIcon className=" mr-2" />
              Add item
            </b>
          </GMButton>
        </div>
        <div className="mb-4">
          <GMNotify
            type="desc"
            desc={
              <div className="desc">
                An extra fee will be applied for the second-side print of <b>Apparel</b> and <b>Ceramic Ornament</b> products.
              </div>
            }
          />
        </div>

        <Table
          pagination={false}
          columns={columns}
          bordered
          size={'middle'}
          dataSource={data}
          key={`key`}
          scroll={{ x: 1000 }}
          rowClassName={(record, index) => (index % 2 !== 0 ? '' : '')}
        />
        {error && <span className="invalid-feedback d-block">{error}</span>}
        <Divider className="my-0" />
      </Wrapper>
    );
  }
}
