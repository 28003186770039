import {
  BASE_WHITE,
  GRAY_200,
  GRAY_500,
  GRAY_600,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_50,
  PRIMARY_500,
  PRIMARY_700,
} from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-select-selection-placeholder {
    color: black;
  }
`;
