import { GRAY_300, SHADOW_XS } from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`

  .platform {
    .ant-select-selector {
      max-width: 100%;
      max-height: 100%;
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      border-radius: 8px;
      margin-left: 10px;
    }
    .ant-select-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
  }
  .ant-collapse {
    border-radius: 12px;
    border-bottom: red;
  }
  .ant-collapse-header {
    border-radius: 12px;
    border-bottom: transparent;
    background-color: white;
    height: 72px;

  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
    border-radius: 12px;
  }
  .ant-collapse-content-box {
    background-color: transparent;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: white;
    
  }
  .ant-collapse-content.ant-collapse-content-active {
    background-color: transparent;
    border-radius: 12px;

  }
  .ant-collapse > .ant-collapse-item {
    overflow: hidden;
  }
`;
