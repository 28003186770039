/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { reduxForm } from 'redux-form';
import validate from './validate';
import { ReactComponent as HelpIcon } from 'images/icons/help-circle.svg';
import RenderAuthenticationSlider from 'utils/Form/RenderAuthenticationSlider';
import { GMCopyright } from 'info';
import { GMTextbox } from 'components/InputFields';
import PasswordConditional from 'components/PasswordConditional';
import { GMButton } from 'components/Button';
import { ReactComponent as GMLogo } from 'images/logo_new_login.svg';
import { GlowingSVG } from 'components/SVG';
import { WARNING_100, WARNING_50, WARNING_500 } from 'components/colors';
// import Success from './Success';
import { ReactComponent as ClockSnoozeIcon } from 'images/icons/clock-snooze.svg';
import { ReactComponent as ArrowLeft } from 'images/icons/arrow-left.svg';

const VerifyStaffAccount = (props) => {
  const { handleSubmit, emailCus, tokenValid } = props;

  const registerContent = (
    <>
      {tokenValid ? (
        <div>
          {' '}
          <div>
            <h3 className="title">Create an account</h3>
            <p className="message">
              You’ve been invited by {emailCus?.emailCus} to become a Staff Member. Please
              fill in information below to continue.
            </p>
          </div>
          <div>
            <div className="form-group">
              <GMTextbox
                name="firstName"
                placeholder="First name"
                label="First name"
                maxLength="256"
                showError
              />
            </div>
            <div className="form-group">
              <GMTextbox
                name="lastName"
                placeholder="Last name"
                label="Last name"
                maxLength="256"
                showError
              />
            </div>
            <div className="form-group">
              <GMTextbox
                name="email"
                placeholder="Email"
                label="Email"
                defaultValue={emailCus?.emailStaff}
                disabled
              />
            </div>
            <div className="form-group">
              <GMTextbox
                name="password"
                placeholder="Password"
                maxLength="50"
                label={
                  <>
                    Password
                    <Tooltip
                      title={<PasswordConditional />}
                      placement="topLeft"
                    >
                      <HelpIcon className="help-tooltip" />
                    </Tooltip>
                  </>
                }
                type="password"
                message="Use 8 or more characters with a mix of letters, numbers & symbols and does not contain email address"
                showError
              />
            </div>
            <div className="form-group">
              <GMTextbox
                name="confirmPassword"
                placeholder="Confirm password"
                label="Confirm password"
                maxLength="50"
                type="password"
                showError
              />
            </div>
            <div className="form-group term-condition">
              By continuing, you agree to Gearment's{' '}
              <a
                target="_blank"
                href="https://gearment.com/p/terms-of-services/"
                rel="noopener noreferrer"
              >
                Term Of Services
              </a>{' '}
              and acknowledge you've read our{' '}
              <a
                href="https://gearment.com/p/privacy-policy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policies
              </a>
              .
            </div>
            <div className="mt-2 login-form-button">
              <GMButton
                type="submit"
                className="btn btn-primary button-Login w-100 text-uppercase font-weight-bold"
              >
                Create new account
              </GMButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className="text-center">
            <GlowingSVG
              backgroundColor={WARNING_100}
              borderColor={WARNING_50}
              svgStrokeColor={WARNING_500}
            >
              <ClockSnoozeIcon />
            </GlowingSVG>
          </div>
          <h2 className="text-center header">Invalid verification link</h2>
          <p className="text-center message">
            The verification link is no longer valid.
          </p>

          <div className="text-center back2login">
            <Link to="/auth/login" className="back-cta">
              <ArrowLeft />
              Back to log in
            </Link>
          </div>
        </div>
      )}
    </>
  );
  return (
    <div className="container-fluid">
      <div className="row align-items-stretch justify-content-center">
        <div className="col-lg-6 col-md-12 col-xs-12 left-form">
          <div className="signup-form m-auto min-vh-100 d-flex align-items-center px-0  pb-8">
            <form onSubmit={handleSubmit} className="w-100">
              <div className="mb-3">
                {tokenValid && (
                  <div className="text-center">
                    <GMLogo className="img-fluid logo" />
                  </div>
                )}
                {registerContent}
                {/* {serverSuccess?.code !== 'success' ? (
                  registerContent
                ) : (
                  <Success
                    emailAddress={
                      serverSuccess?.data?.customer?.email ||
                      serverSuccess?.email
                    }
                  />
                )} */}
              </div>
            </form>
          </div>
          {GMCopyright}
        </div>
        <div className="col-md-6 right-authentication-section">
          <div className="authentication-slider min-vh-100 d-flex align-items-center justify-content-center">
            <RenderAuthenticationSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(
  reduxForm({
    form: 'formVerifyStaff',
    enableReinitialize: true,
    validate,
  })(VerifyStaffAccount)
);
