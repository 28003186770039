import { notification } from 'antd';
import { LOGIN } from './actions';
import { STATUS_LOGIN } from 'constants/common';

const initialState = {
  loading: false,
  success: false,
  error: {},
  resendData: {},
};

let timeOut = localStorage.getItem('timeOut');
function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN.request:
      return {
        ...state,
        loading: true,
        success: false,
        error: {},
      };
    case LOGIN.success:
      localStorage.removeItem('isCheckConnectSuccessful');
      notification.success({
        message:
          action && action.data?.clientMsg
            ? action.data?.clientMsg
            : 'Login successfully',
        duration: 2,
      });
      return {
        ...state,
        loading: false,
        success: true,
        error: {},
      };
    case LOGIN.social:
      return {
        ...state,
        loading: false,
        success: false,
        error: {},
      };
    case LOGIN.resend_email:
      return {
        ...state,
        resendData: action.data, // uid/pwd
      };
    case LOGIN.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: action?.error?.[0] || '',
      };
    default:
      return state;
  }
}

export default AuthReducer;
