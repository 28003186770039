export const actionType = {
    LIST: {
        REQUEST: 'MOCKUP.LIST.LIST.REQUEST',
        SUCCESS: 'MOCKUP.LIST.LIST.SUCCESS',
        ERROR: 'MOCKUP.LIST.LIST.ERROR',
    }
};

export function getList(params) {
    return {
        type: actionType.LIST.REQUEST,
        params
    }
}