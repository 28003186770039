export const actionType = {
  LIST_ACCOUNT: {
    REQUEST: 'SETTING.ACCOUNT.LIST.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.LIST.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.LIST.ERROR',
  },
  REPORT_DASHBOARD: {
    REQUEST: 'SETTING.REPORT_DASHBOARD.REQUEST',
    SUCCESS: 'SETTING.REPORT_DASHBOARD.SUCCESS',
    ERROR: 'SETTING.REPORT_DASHBOARD.ERROR',
  },
  DETAIL_ACCOUNT: {
    REQUEST: 'SETTING.ACCOUNT.DETAIL.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.DETAIL.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.DETAIL.ERROR',
  },
  UPDATE_ACCOUNT: {
    REQUEST: 'SETTING.ACCOUNT.UPDATE.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.UPDATE.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.UPDATE.ERROR',
  },
  CREATE_ACCOUNT: {
    REQUEST: 'SETTING.ACCOUNT.CREATE.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.CREATE.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.CREATE.ERROR',
  },
  LIST_REPORT: {
    REQUEST: 'SETTING.ACCOUNT.LIST_REPORT.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.LIST_REPORT.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.LIST_REPORT.ERROR',
  },
  EXPORT_ORDER: {
    REQUEST: 'SETTING.ACCOUNT.EXPORT_ORDER.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.EXPORT_ORDER.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.EXPORT_ORDER.ERROR',
  },
  DETAIL_REPORT: {
    REQUEST: 'SETTING.ACCOUNT.DETAIL_REPORT.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.DETAIL_REPORT.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.DETAIL_REPORT.ERROR',
  },
  DELETE_ACCOUNT: {
    REQUEST: 'SETTING.ACCOUNT.DELETE_ACCOUNT.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.DELETE_ACCOUNT.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.DELETE_ACCOUNT.ERROR',
  },
  EXPORT_REPORT_ACCOUNT: {
    REQUEST: 'SETTING.ACCOUNT.EXPORT_REPORT.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.EXPORT_REPORT.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.EXPORT_REPORT.ERROR',
  },
  EXPORT_DETAIL_REPORT_ACCOUNT: {
    REQUEST: 'SETTING.ACCOUNT.EXPORT_DETAIL_REPORT.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.EXPORT_DETAIL_REPORT.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.EXPORT_DETAIL_REPORT.ERROR',
  },
  LIST_INVITATION_ACCOUNT: {
    REQUEST: 'SETTING.ACCOUNT.LIST_INVITATION_ACCOUNT.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.LIST_INVITATION_ACCOUNT.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.LIST_INVITATION_ACCOUNT.ERROR',
  },
  DELETE_INVITATION_ACCOUNT: {
    REQUEST: 'SETTING.ACCOUNT.DELETE_INVITATION_ACCOUNT.REQUEST',
    SUCCESS: 'SETTING.ACCOUNT.DELETE_INVITATION_ACCOUNT.SUCCESS',
    ERROR: 'SETTING.ACCOUNT.DELETE_INVITATION_ACCOUNT.ERROR',
  },
  RESEND_INVITAION:{
    REQUEST: 'SETTING.RESEND_INVITAION.REQUEST',
    SUCCESS: 'SETTING.RESEND_INVITAION.SUCCESS',
    ERROR: 'SETTING.RESEND_INVITAION.ERROR',
  }
};

export function listAccount(params) {
  return {
    type: actionType.LIST_ACCOUNT.REQUEST,
    params,
  };
}
export function listInvatitionAccountAction(params) {
  return {
    type: actionType.LIST_INVITATION_ACCOUNT.REQUEST,
    params,
  };
}

export function detailAccount(id) {
  return {
    type: actionType.DETAIL_ACCOUNT.REQUEST,
    id,
  };
}

export function updateAccount(id, dataToUpdate) {
  return {
    type: actionType.UPDATE_ACCOUNT.REQUEST,
    id,
    dataToUpdate,
  };
}

export function createAccount(dataToCreate) {
  return {
    type: actionType.CREATE_ACCOUNT.REQUEST,
    dataToCreate,
  };
}

export function resendInvitationAction(data) {
  return {
    type: actionType.RESEND_INVITAION.REQUEST,
    data,
  };
}


export function listReport(params) {
  return {
    type: actionType.LIST_REPORT.REQUEST,
    params,
  };
}
//Order report dashboard
export function orderReportDashboard(params) {
  return {
    type: actionType.REPORT_DASHBOARD.REQUEST,
    params,
  };
}
//
export function exportOrders(id, params) {
  return {
    type: actionType.EXPORT_ORDER.REQUEST,
    id,
    params,
  };
}

export function detailReport(id, params) {
  return {
    type: actionType.DETAIL_REPORT.REQUEST,
    id,
    params,
  };
}
export function deleteAccount(id, cb = undefined) {
  return {
    type: actionType.DELETE_ACCOUNT.REQUEST,
    id,
    cb
  };
}

export function deleteInvitationAccount(id, cb = undefined) {
  return {
    type: actionType.DELETE_INVITATION_ACCOUNT.REQUEST,
    id,
    cb,
  };
}

export function exportReport(params) {
  return {
    type: actionType.EXPORT_REPORT_ACCOUNT.REQUEST,
    params,
  };
}

export function exportDetailReport(id, params) {
  return {
    type: actionType.EXPORT_DETAIL_REPORT_ACCOUNT.REQUEST,
    id,
    params,
  };
}
