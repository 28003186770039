import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import validate from './validate';
import { renderSwitchAnt, renderSelectAnt } from 'utils/Form';
import { GMTextbox } from 'components/InputFields';
import { Wrapper } from '../styled';
import { GMButton } from 'components/Button';
import { WrapperUpdateButton } from '../../styled';
import { ReactComponent as WooIcon } from 'images/Logo_store/full/woo.svg';

let index = ({
  handleSubmit,
  onCancel,
  accounts,
  currAction,
  profile,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit} className="mx-2">
      <WrapperUpdateButton>
        <div className="text-left">
          <WooIcon />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Store name</b>
          </label>
          <GMTextbox name="showName" showError />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Url store</b>
          </label>
          <GMTextbox name="url" showError />

          <span className="text-muted">
            Ex: https://domainname.com or http://domainname.com
          </span>
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Consumer Key</b>
          </label>
          <GMTextbox name="storeApiKey" showError />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Consumer Secret</b>
          </label>
          <GMTextbox name="storeSecretKey" showError />

          <a
            href="https://account.gearment.com/sellerv2/assets/files/GET_API_KEY_Wordpress.docx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faCode} /> <b>Quick guide Get API Key</b>
          </a>
        </div>
        {parseInt(profile.primaryId) === 0 && (
          <div className="form-group">
            <label className="col-form-label">
              <b>Staff Account</b>
            </label>
            <Wrapper>
              <Field
                name="staffId"
                component={renderSelectAnt}
                placeholder="-- Select staff --"
                mode={'multiple'}
                options={accounts.map((item) => ({
                  value: item.id.toString(),
                  label: item.name,
                }))}
              />
            </Wrapper>
          </div>
        )}
        <div className="form-group">
          <div className="d-flex align-items-center">
            <GMTextbox name="autoSync" showError component={renderSwitchAnt} />

            <label htmlFor="store_auto_sync" className="ml-3 col-form-label">
              Automatic synchronization when new orders arrive.{' '}
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="d-flex align-items-center">
            <GMTextbox name="status" showError component={renderSwitchAnt} />

            <label htmlFor="store_active" className="ml-3 col-form-label">
              Active store
            </label>
          </div>
        </div>
        <div>
          <GMButton className="saveBtn" size="lg">
            {currAction === 'create' ? 'Connect store' : 'Update store'}
          </GMButton>
        </div>
      </WrapperUpdateButton>
    </form>
  );
};

index = reduxForm({
  form: 'formWordpressStore',
  enableReinitialize: true,
  validate,
})(index);

export default connect()(index);
