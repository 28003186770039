import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import {
  createIssue,
  listIssueCategory,
  listOrderToCreateIssue,
  setLoadingAction,
} from './actions';
import FormCreateIssue from './components/form';
import { Wrapper } from './styled';
import GenerateIssueFromOrderID from './components/GenerateIssueFromOrderID';
import { checkOrderForCreatingIssue } from 'apis/order-issues';
import { change } from 'redux-form';

class Create_Issues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serverErrors: null,
      isPreset: false,
    };
  }
  componentDidMount() {
    this.props.listIssueCategory();
    let state = { ...this.props.history.location.state };
    let { listOrderId = [] } = state;
    if (listOrderId.length === 0)
      listOrderId = sessionStorage.getItem('listOrderIdToCreateIssue')
        ? sessionStorage.getItem('listOrderIdToCreateIssue').split(',')
        : [];
    if (listOrderId && Array.isArray(listOrderId) && listOrderId.length > 0) {
      this.props.listOrderToCreateIssue({ orders: listOrderId });
      sessionStorage.setItem('listOrderIdToCreateIssue', listOrderId);
      delete state.listOrderId;
      this.props.history.replace({ ...this.props.history.location, state });
    }
  }

  handleSubmit = (values) => {
    if (values.listIssues) {
      const dataToCreate = {
        fulfillmentId: [],
        orderId: [],
        issueCategoryId: [],
        issueDetail: [],
        issueImage: [],
        issuePriority: [],
        issueSolution: [],
        itemEffect: [],
      };
      let isAdded = 0;
      for (let i = 0; i < values.listIssues.length; i++) {
        if (
          `${values.listIssues[i]['fulfillmentId']}`.trim().length > 0 &&
          `${values.listIssues[i]['orderId']}`.trim().length > 0 &&
          `${values.listIssues[i]['category']}`.trim().length > 0 &&
          `${values.listIssues[i]['detail']}`.trim().length > 0 &&
          `${values.listIssues[i]['itemEffect']}`.trim().length > 0 &&
          `${values.listIssues[i]['priority']}`.trim().length > 0 &&
          `${values.listIssues[i]['solution']}`.trim().length > 0
        ) {
          dataToCreate.fulfillmentId.push(
            values.listIssues[i]['fulfillmentId']
          );
          dataToCreate.orderId.push(values.listIssues[i]['orderId']);
          dataToCreate.issueCategoryId.push(values.listIssues[i]['category']);
          dataToCreate.issueDetail.push(values.listIssues[i]['detail']);
          dataToCreate.itemEffect.push(values.listIssues[i]['itemEffect']);
          dataToCreate.issuePriority.push(values.listIssues[i]['priority']);
          dataToCreate.issueSolution.push(values.listIssues[i]['solution']);
          const links = values.listIssues[i]['links'];
          dataToCreate.issueImage.push(links);
          isAdded++;
        }
      }
      if (isAdded > 0) {
        this.props.createIssue(dataToCreate);
      }
    }
  };
  submitGenerateIssueFormHandler = (ids) => {
    this.setState({ serverErrors: null });
    this.props.dispatch(setLoadingAction(true));
    checkOrderForCreatingIssue({ orders: ids })
      .then((response) => {
        const data = response?.data || {};
        const serverErrors = data?.errors || [];
        const orders = data?.orders || [];
        if (serverErrors.length > 0) {
          this.setState({ serverErrors });
        }

        const clnLst = [...this.props.reduxListIssues];
        orders.forEach((order) => {
          const temp = this.props.reduxListIssues.find(
            (issue) => issue?.orderId === order?.id
          );
          if (temp) {
            return;
          }

          clnLst.push({
            fulfillmentId: order?.name,
            orderId: order?.id,
            links: [''],
          });
        });
        if (clnLst.length !== this.props.reduxListIssues.length) {
          this.props.dispatch(change('formCreateIssue', `listIssues`, clnLst));
        }
      })
      .catch((error) => {
        this.setState({ serverErrors: null });
      })
      .finally(() => {
        this.props.dispatch(setLoadingAction(false));
      });
  };
  appendEmptyIssue = () => {
    const clnLst = [...this.props.reduxListIssues, { links: [' '], fulfillmentId: ' ' }];
    this.props.dispatch(change('formCreateIssue', `listIssues`, clnLst));
  };
  render() {
    const {
      createIssueLoading,
      listIssueCategoryLoading,
      listIssueCategoryData,
      createLoading,
      pageName,
      listOrderToCreateIssueData,
    } = this.props;
    const initialValues = {
      listIssues: [],
    };
    const temp = [];
    if (
      listOrderToCreateIssueData.data &&
      listOrderToCreateIssueData.data.length > 0
    ) {
      for (
        let index = 0;
        index < listOrderToCreateIssueData.data.length;
        index++
      ) {
        temp.push({
          fulfillmentId: listOrderToCreateIssueData.data[index]['name'],
          orderId: listOrderToCreateIssueData.data[index]['id'],
          category: '',
          detail: '',
          itemEffect: '',
          priority: '',
          solution: '',
          links: [''],
        });
      }
    } else {
      temp.push({
        fulfillmentId: '',
        orderId: '',
        category: '',
        detail: '',
        itemEffect: '',
        priority: '',
        solution: '',
        links: [''],
      });
    }
    initialValues.listIssues = this.state.isPreset ? temp.filter((item) => {
      return item?.fulfillmentId?.trim()?.length > 0;
    }) : temp;
    const errorMessage =
      this.state?.serverErrors && this.state?.serverErrors?.length > 0
        ? this.state?.serverErrors?.map((error) => {
          return (
            <li>
              <span className="error">{error?.clientMsg}</span>
            </li>
          );
        })
        : null;
    return (
      <Wrapper pageName={pageName} noContainer>
        <Spin
          spinning={
            createIssueLoading ||
            listIssueCategoryLoading ||
            createLoading ||
            listOrderToCreateIssueData.loading
          }
        >
          <div className="issue-item">
            <GenerateIssueFromOrderID
              onSubmit={this.submitGenerateIssueFormHandler}
              cb={v => this.setState({ isPreset: v })}
              errorElem={
                errorMessage ? (
                  <ul className="error-list">{errorMessage}</ul>
                ) : null
              }
            />
          </div>
          <FormCreateIssue
            listIssueCategory={listIssueCategoryData}
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            appendEmptyIssue={this.appendEmptyIssue}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    createIssueLoading: state.order.createIssue.create.loading,
    listIssueCategoryData: state.order.createIssue.listIssueCategory.data,
    listIssueCategoryLoading: state.order.createIssue.listIssueCategory.loading,
    listOrderToCreateIssueData: state.order.createIssue.listOrderToCreateIssue,
    reduxListIssues: state.form?.formCreateIssue?.values?.listIssues || [],
    createLoading: state.order?.createIssue?.create?.loading || false,
  }),
  (dispatch) => ({
    createIssue: (dataToCreate) => {
      dispatch(createIssue(dataToCreate));
    },
    listIssueCategory: () => {
      dispatch(listIssueCategory());
    },
    listOrderToCreateIssue: (data) => {
      dispatch(listOrderToCreateIssue(data));
    },
    dispatch,
  })
)(Create_Issues);
