import React, { memo, useEffect, useState } from "react";
import { GMSelect, GMTextbox } from "components/InputFields";
import { getStates } from "apis/countries";
import { Spin } from "antd";
import { InputWrapper } from "./styled";

const cCache = {};

const usAddingState = [
  "Armed Forces of the Americas - AA",
  "Armed Forces of Europe - AE",
  "Armed Forces of the Pacific - AP",
  "Guam - GU ",
  "Virgin Islands - VI",
  "Puerto Rico - PR",
];

const GMStateSelector = ({ country = "us", ...props }) => {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  // get the list
  const tmpFnc = async (c) => {
    const _c = country?.toLowerCase() || "us";
    const states = await getStates(_c);
    if (_c === "us" && states?.data?.length > 0) {
      usAddingState.forEach((v, i) => {
        const splittedInfo = v.split(' - ')
        const item2Add = {
            "id": states.data.length + 1,
            "name": splittedInfo[0].trim(),
            "code": splittedInfo[1].trim(),
            "countryCode": "US",
            "tax": null
          };
        states.data.push(item2Add);
      });
    }
    return states;
  };
  const getList = async () => {
    setLoading(true);
    const c = country?.toLowerCase() || "us";
    const states = cCache[c] ? cCache[c] : await tmpFnc(c);
    cCache[c] = states;
    setCountries(
      (states?.data || []).map((s) => ({
        value: s?.code,
        label: `${s?.name} - ${s?.code}`
      }))
    );
    setLoading(false);
  };
  useEffect(() => {
    getList();
  }, [country]);

  if (countries.length <= 0) {
    return <GMTextbox {...props} />;
  }

  return (
    <InputWrapper>
      <Spin spinning={loading}>
        {/* <InputWrapper {...props} options={countries} /> */}
        <GMSelect {...props} options={countries} />
      </Spin>
    </InputWrapper>
  );
};

export default memo(GMStateSelector);
