import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import { getNotificationDetail } from "./actions";
import getMomentInstance from "utils/moment";
export class index extends Component {
  componentDidMount() {
    this.props.getNotificationDetail(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.getNotificationDetail(this.props.match.params.id);
    }
  }

  render() {
    const { detailNotification, pageName } = this.props;
    return (
      <>
        <div className="Rectangle-848 px-3 py-1">
          <div className="Orders p-2">
            {pageName} {`#${this.props.match.params.id}`}
          </div>
        </div>
        <div className="m-3 p-3" style={{ backgroundColor: "white" }}>
          <Spin spinning={detailNotification.loading}>
            <div className="table-responsive">
              <table className="table table-borderless table-sm">
                <tbody>
                  <tr>
                    <b
                      style={{ width: 200 }}
                      className="px-4 d-flex align-items-start justify-content-start"
                    >
                      Title
                    </b>
                    <td className="px-4">
                      {detailNotification.data.name || ""}
                    </td>
                  </tr>
                  <tr>
                    <b style={{ width: 200 }} className="px-4">
                      Content
                    </b>
                    <td className="px-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: (
                            detailNotification.data.content || ""
                          ).replace(/(<? *script)/gi, "illegalscript"),
                        }}
                      ></div>
                    </td>
                  </tr>
                  <tr>
                    <b style={{ width: 200 }} className="px-4">
                      Time created
                    </b>
                    <td className="px-4">
                      {detailNotification.data.createdAt
                        ? getMomentInstance(
                            detailNotification.data.createdAt,
                            0,
                            true
                          )
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Spin>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    detailNotification: state.notification.detail.detail,
  }),
  (dispatch) => ({
    getNotificationDetail: (id) => {
      dispatch(getNotificationDetail(id));
    },
  })
)(index);