import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from './components/form';
import { Modal, Spin } from 'antd';
import { getUselabelCreate, getListStoreUserlabel } from './actions';
import { Wrapper } from 'scenes/general.styled';
import GMNotify from 'components/Notification';
import { GMButton } from 'components/Button';
import { GlobalStyle, WrapperModal } from './styled';

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        showModal: null,
      },
    };
  }
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    this.props.getListStoreUserlabel({ ...params });
  };

  componentDidMount() {
    this.fetchData(this.state.initial_filter_values);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
      this.fetchData(this.state.initial_filter_values);
    }
  }
  deleteUserlabel = (id) => {
    this.props.deleteUserlabel(id);
  };

  onCreateFormSubmit = () => {
    let values = this.props.valueForm.values;
    const dataToCreate = {
      company: values.company ? values.company : '',
      phone: values.phone ? values.phone : '',
      addressOne: values.addressOne ? values.addressOne : '',
      addressTwo: values.addressTwo ? values.addressTwo : '',
      city: values.city ? values.city : '',
      state: values.state ? values.state : '',
      code: values.code ? values.code : '',
      country: values.country ? values.country : '',
      allStore: values.allStore ? 'yes' : 'no',
      storeIds: values.storeIds || [],
      default: values.default,
    };
    this.props.getUselabelCreate(dataToCreate);
  };

  checkSpecialAddress = (values) => {
    const dataToCreate = {
      company: values.company ? values.company : '',
      phone: values.phone ? values.phone : '',
      addressOne: values.addressOne ? values.addressOne : '',
      addressTwo: values.addressTwo ? values.addressTwo : '',
      city: values.city ? values.city : '',
      state: values.state ? values.state : '',
      code: values.code ? values.code : '',
      country: values.country ? values.country : '',
      allStore: values.allStore ? 'yes' : 'no',
      storeIds: values.storeIds || [],
      default: values.default,
    };
    const specialAddress = [
      '14801 able ln, ste 102, huntington beach, ca, 92647, us',
      '15561 product ln ste d1, huntington beach, ca, 92649, us',
      '6182  winslow drive, hunting beach, ca, 92647, us',
      '10811 vine crest dr, 100, houston, tx, 77086, us',
      '14801 able ln, ste 102, huntington beach, ca, 92647, united states',
      '15561 product ln ste d1, huntington beach, ca, 92649, united states',
      '6182  winslow drive, hunting beach, ca, 92647, united states',
      '10811 vine crest dr, 100, houston, tx, 77086, united states',
    ];

    let address = `${values.addressOne.trim()}, ${values.addressTwo.trim()}, ${values.city.trim()}, ${values.state.trim()}, ${values.code.trim()}, ${values.country.trim()}`.toLowerCase();
    let address1 = `${values.addressOne.trim()}, ${values.city.trim()}, ${values.state.trim()}, ${values.code.trim()}, ${values.country.trim()}`.toLowerCase();

    let found = specialAddress.includes(address);
    let found1 = specialAddress.includes(address1);

    if (found || found1) {
      this.setState({ showModal: true });
    } else {
      this.props.getUselabelCreate(dataToCreate);
    }
  };

  render() {
    const { loading, pageName, list_store } = this.props;
    const initialValues = {
      company: '',
      phone: '',
      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      code: '',
      country: 'United States',
      allStore: false,
      default: 'yes',
      storeIds: [],
    };
    return (
      <Wrapper pageName={pageName}>
        <GMNotify
          type="infor"
          title={<div className="title">Notification</div>}
          desc={<div className="desc">Only addresses in US are accepted</div>}
        />

        <Spin spinning={loading || list_store.loading}>
          <Form
            initialValues={initialValues}
            onSubmit={this.checkSpecialAddress}
            listStore={list_store.data}
          />
        </Spin>
        <WrapperModal>
          {' '}
          <Modal
            visible={this.state.showModal}
            title={<b>Confirm return address</b>}
            footer={null}
            onCancel={() => this.setState({ showModal: null })}
          >
            <div>
              You agree that your design upload will not violate any rights of
              any third party, including copyright, trademark, privacy,
              personality, or other personal or proprietary right. You further
              agree that your uploads will not contain libelous or otherwise
              unlawful, abusive, or obscene material, or contain any computer
              virus or other malware that could in any way affect the operation
              of the Service or any related website. You may not use a false
              e‑mail address, pretend to be someone other than yourself, or
              otherwise mislead us or third parties as to the origin of any
              comments. You are solely responsible for any upload you make and
              their accuracy. We take no responsibility and assume no liability
              for any comments posted by you or any third party.
            </div>
            <div className="text-right mt-3">
              <GMButton
                color={'SecondaryGray'}
                onClick={() => this.setState({ showModal: null })}
              >
                Cancel
              </GMButton>
              <GMButton className="ml-2" onClick={this.onCreateFormSubmit}>
                Accept
              </GMButton>
            </div>
          </Modal>
        </WrapperModal>
        <GlobalStyle />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.userlabel.create.userlableCreate.loading,
  list_store: state.userlabel.create.listStoreUserlabel,
  valueForm: state.form.formCreateUserlabel,
});

const mapDispatchToProps = (dispatch) => ({
  getUselabelCreate: (dataToCreate) => {
    dispatch(getUselabelCreate(dataToCreate));
  },
  getListStoreUserlabel: (options) => {
    dispatch(getListStoreUserlabel(options));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
