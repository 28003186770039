import { actionType } from "./actions";
import { notification } from "antd";
  
const initialState = {
    order: {
        data: {},
        loading: false,
    },
    duplicateOrder: {
        loading: false
    } 
};

function DuplicateOrderFbaReducer(state = initialState, action) {
    switch (action.type) {
        //Order Detail
        case actionType.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.REQUEST:
            return {
                ...state,
                order: {
                    ...state.order,
                    loading: true
                },
            };
        case actionType.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.SUCCESS:
            return {
                ...state,
                order: {
                    ...state.order,
                    loading: false,
                    data: action.data.order,
                },
            };
        case actionType.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.ERROR:
            notification.warn({
                message: "Not found order to duplicate order FBA.",
                duration: 5
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    loading: true
                },
            };
            case actionType.DUPLICATE_FBA_ORDER.REQUEST:
                return {
                    ...state,
                    duplicateOrder: {
                        ...initialState.duplicateOrder,
                        loading: true
                    },
                };
            case actionType.DUPLICATE_FBA_ORDER.SUCCESS:
                notification.success({message: action?.clientMsg || 'Duplicate order FBA successfully.', duration: 5})
                return {
                    ...state,
                    duplicateOrder: {
                        ...initialState.duplicateOrder,
                        loading: false
                    },
                };
            case actionType.DUPLICATE_FBA_ORDER.ERROR:
                notification.warn({
                    message: action.error?.[0]?.['clientMsg'] || 'Duplicate order FBA failed.',
                    duration: 5
                });
                return {
                    ...state,
                    duplicateOrder: {
                        ...initialState.duplicateOrder,
                        loading: false
                    },
                };    
        default:   
            return state;
    }
}

export default DuplicateOrderFbaReducer;
