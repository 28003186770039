export const actionType = {
  REQUEST_TOPUP: {
    REQUEST: 'REQUEST_TOPUP.REQUEST',
    SUCCESS: 'REQUEST_TOPUP.SUCCESS',
    ERROR: 'REQUEST_TOPUP.ERROR',
  },
  REQUEST_TOPUP_PAYPAL: {
    REQUEST: 'REQUEST_TOPUP_PAYPAL.REQUEST',
    SUCCESS: 'REQUEST_TOPUP_PAYPAL.SUCCESS',
    ERROR: 'REQUEST_TOPUP_PAYPAL.ERROR',
  },
  LIST_PAYPAL: {
    REQUEST: "LIST_PAYPAL.REQUEST",
    SUCCESS: "LIST_PAYPAL.SUCCESS",
    ERROR: "LIST_PAYPAL.ERROR",
  },
};
export function sendRequestTopUp(data) {
  return {
    type: actionType.REQUEST_TOPUP.REQUEST,
    data,
  };
}

export function sendRequestTopUpPaypalInstant(data) {
  return {
    type: actionType.REQUEST_TOPUP_PAYPAL.REQUEST,
    data,
  };
}
export function getListPaypal(params) {
  return {
    type: actionType.LIST_PAYPAL.REQUEST,
    params,
  };
}
