import { actionType as TYPE } from './actions';
import { notification } from 'antd';
const initialState = {
  listOrderToMatchProduct: {
    data: [],
    loading: false,
  },
  saveMatchProduct: {
    loading: false,
    total: 0,
    listOrderItemsSuccess: [],
    listOrderItemsError: [],
  },
  selectedOrderItems: [],
  remove: {
    loading: false,
    data: 0,
  },
  deleteDesign: {
    loading: false,
    data: [],
    errors: [],
  },
};

function MatchProductReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST_ORDER_TO_MATCH_PRODUCT.REQUEST:
      if (!!action?.data?.cb) {
        return {
          ...state,
          listOrderToMatchProduct: {
            ...state.listOrderToMatchProduct,
            loading: true,
            // listOrderItemsError: [],
            // listOrderItemsSuccess: [],
          },
          saveMatchProduct: { ...initialState.saveMatchProduct },
        };
      }
      return {
        ...state,
        listOrderToMatchProduct: {
          ...initialState.listOrderToMatchProduct,
          loading: true,
          // listOrderItemsError: [],
          // listOrderItemsSuccess: [],
        },
        saveMatchProduct: { ...initialState.saveMatchProduct },
      };
    case TYPE.LIST_ORDER_TO_MATCH_PRODUCT.SUCCESS:
      let data = action.data &&
        action.data.listOrderToMatch &&
        !(
          action.data.listOrderToMatch[0] &&
          action.data.listOrderToMatch[0]['code']
        )
        ? action.data.listOrderToMatch
        : [];
      if (!!action?.isCb) {
        data = [...(state?.listOrderToMatchProduct?.data || []), ...data];
      }
      return {
        ...state,
        listOrderToMatchProduct: {
          ...initialState.listOrderToMatchProduct,
          data,
          loading: false,
        },
      };
    case TYPE.LIST_ORDER_TO_MATCH_PRODUCT.ERROR:
      notification.warn({
        message:
          action?.error?.[0]?.['msg'] || 'List orders to match product failed.',
        duration: 2,
      });
      return {
        ...state,
        listOrderToMatchProduct: {
          ...initialState.listOrderToMatchProduct,
          data: [],
          loading: false,
        },
      };

    case TYPE.SAVE_MATCH_PRODUCT.REQUEST:
      return {
        ...state,
        saveMatchProduct: {
          ...state.saveMatchProduct,
          loading: true,
        },
      };
    case TYPE.SAVE_MATCH_PRODUCT.SUCCESS:
      let tempData2 = [...state.saveMatchProduct.listOrderItemsSuccess];
      let tempData3 = [...state.saveMatchProduct.listOrderItemsError];
      if (action.errors) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'success') {
            notification.success({
              message:
                action?.errors?.[index]?.msg || 'Match product successfully.',
              duration: 3,
            });
            tempData2.push(action.errors[index].orderItemId);
            // console.log(tempData2,"tempDatasss2")
          } else {
            notification.warn({
              message: action?.errors?.[index]?.msg || 'Match product failed.',
              duration: 3,
            });
            tempData3.push(action.errors[index].orderItemId);
            // console.log(tempData3,"tempData333")
          }
        }
      }

      return {
        ...state,
        saveMatchProduct: {
          ...state.saveMatchProduct,
          errors: action?.errors || [],
          loading: false,
          listOrderItemsSuccess: tempData2,
          listOrderItemsError: tempData3,
        },
      };
    case TYPE.SAVE_MATCH_PRODUCT.ERROR:
      // notification.error({ message: 'Match product failed.', duration: 2 });
      // return state;
      return {
        ...state,
        saveMatchProduct: {
          ...state.saveMatchProduct,
          loading: false,
          listOrderItemsError: [],
          listOrderItemsSuccess: [],
        },
      };
    case TYPE.RESET_MATCH_PRODUCT_DATA.REQUEST:
      return {
        ...state,
        saveMatchProduct: {
          ...state.saveMatchProduct,
          listOrderItemsSuccess: [],
        },
      };
    case TYPE.SET_SELECTED_ORDER_ITEMS.REQUEST:
      return {
        ...state,
        selectedOrderItems: action.selectedOrderItems || [],
      };
    case TYPE.DELETE_DESIGN.REQUEST:
      return {
        ...state,
        deleteDesign: {
          ...state.deleteDesign,
          loading: true,
        },
      };
    case TYPE.DELETE_DESIGN.SUCCESS:
      const tempData = [];
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'delete_design_success') {
            tempData.push({
              orderItemId: action?.errors?.[index]?.orderItemId,
              sidePrint: action?.errors[index]?.sidePrint,
            });
            notification.success({
              message: action?.errors?.[index]?.msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action?.errors?.[index]?.msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        deleteDesign: {
          ...state.deleteDesign,
          data: tempData,
          loading: false,
        },
      };
    case TYPE.DELETE_DESIGN.ERROR:
      notification.error({ message: 'Delete design failed.', duration: 2 });
      return {
        ...state,
        deleteDesign: {
          ...state.deleteDesign,
          loading: false,
        },
      };
    case TYPE.RESET_DELETE_DESIGN_DATA.REQUEST:
      return {
        ...state,
        deleteDesign: {
          ...state.deleteDesign,
          loading: false,
        },
      };
    //Remove item in match product
    case TYPE.REMOVE_ITEM_MATCH_PRODUCT.REQUEST:
      return {
        ...state,
        remove: {
          ...state.remove,
          loading: true,
        },
      };
    case TYPE.REMOVE_ITEM_MATCH_PRODUCT.SUCCESS:
      notification.success({
        message: action?.clientMsg || 'Deleted order item successfully.',
        duration: 2,
      });
      return {
        ...state,
        remove: {
          ...state.remove,
          data: action?.data?.orderItemId || 0,
          loading: false,
        },
      };
    case TYPE.REMOVE_ITEM_MATCH_PRODUCT.ERROR:
      notification.warn({
        message:
          action?.error?.[0]?.['clientMsg'] || 'Deleted order item failed.',
        duration: 2,
      });
      return {
        ...state,
        remove: {
          ...state.remove,
          loading: false,
        },
      }; //

    case TYPE.RESET_REMOVE_ORDER_ITEM_DATA.REQUEST:
      return {
        ...state,
        remove: {
          ...state.remove,
          data: 0,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default MatchProductReducer;
