import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import FormProcessSyncOrder from './components/FormProcessSyncOrder';
import { syncOrder } from './actions';
import { Wrapper } from './styled';
import { reset } from 'redux-form';

class SyncOrder extends Component {
  handleSubmit = (values) => {
    const dataToSync = {
      platformId: values?.platformId || '',
      storeId: values.storeId || '',
      fromDate:
        values.date_range && values.date_range[0]
          ? values.date_range[0].format('YYYY-MM-DD')
          : '',
      toDate:
        values.date_range && values.date_range[1]
          ? values.date_range[1].format('YYYY-MM-DD')
          : '',
    };
    this.props.syncOrder(dataToSync);
    this.props.resetForm('FormSyncOrder');
  };

  render() {
    const { stores, pageName, syncOrderLoading } = this.props;
    const initialValues = {
      storeId: '0',
      platformId: '0',
    };

    return (
      <Wrapper pageName={pageName}>
        <Spin spinning={stores.loading || syncOrderLoading}>
          <FormProcessSyncOrder
            stores={stores.data}
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            storeLoading={stores.loading}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    stores: state.store.list.stores,
    syncOrderLoading: state.order.syncOrder.syncOrder.loading,
  }),
  (dispatch) => ({
    syncOrder: (data) => {
      dispatch(syncOrder(data));
    },
    resetForm: (formName) => {
      dispatch(reset(formName));
    },
  })
)(SyncOrder);
