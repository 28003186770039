import {
  ERROR_25,
  ERROR_300,
  ERROR_600,
  ERROR_700,
  GRAY_200,
  GRAY_400,
  GRAY_500,
  GRAY_600,
  GRAY_900,
} from "components/colors";
import { BREAKPOINTS } from "components/const";
import styled from "styled-components";

export const Wrapper = styled.div`
  .signup-form {
    max-width: 480px;
    width: 100%;
    padding-top: 60px;
  }
  .error-list {
    background: ${ERROR_25};
    border: 1px solid ${ERROR_300};
    border-radius: 12px;
    padding: 16px 18px;
    width: 100%;
    list-style: none;
    li {
      position: relative;
      padding-left: 34px;
      span {
        display: block;
        &.error {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: ${ERROR_700};
        }
        &.detail {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${ERROR_700};
        }
      }
    }
    svg {
      stroke: ${ERROR_600};
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .logo {
    width: 242px;
    height: 48px;
  }

  h3.title {
    margin-top: 32px;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: ${GRAY_900};
  }

  p.message {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${GRAY_500};
    margin-top: 12px;
  }

  .help-tooltip {
    width: 16px;
    height: 16px;
    stroke: ${GRAY_400};
    margin-left: 6px;
    display: inline-block;
  }

  .login-form-button {
    button {
      margin-bottom: 0;
    }
  }

  .cta-group {
    margin-top: 32px;
    a {
      width: 100%;
      display: inline-block;
      text-align: center;
    }
  }

  div.back-cta { 
    text-align: center;
    a {
      display: inline-block;
      color: ${GRAY_600};
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      svg {
        width: 20px;
        height: 20px;
        stroke: ${GRAY_600};
        margin-right: 12px;
      }
    }
  }

  .social-login-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid ${GRAY_200};
    padding-top: 16px;
    margin-top: 16px;

    &:hover {
      background-color: transparent !important;
    }
    .button-social {
      border-radius: 8px;
      border: solid 1px #d0d5dd;
      background-color: #fff;
      height: 44px;
      line-height: 44px;
      margin-bottom: 24px;
      display: inline-block;
      width: 234px;
      max-width: 100%;
    }
    .social-login-icon {
      width: 24px;
      height: 24px;
      line-height: 1;
      margin-top: -5px;
    }
  }

  .form-group.term-condition {
    margin-top: 24px;
  }
  .right-authentication-section {
    display: none;
    @media only screen and (min-width: ${BREAKPOINTS[992]}) {
      display: block;
    }
  }
`;
