import { combineReducers } from "redux";
import list from "./scenes/List/reducer";
import detail from "./scenes/Detail/reducer";
import invoice from "./scenes/InvoiceTransactions/reducer";


const reducer = combineReducers({
  list,
  detail,
  invoice
});

export default reducer;
