import { isValidEmail } from "utils/helper";

const validate = (values) => {
    const errors = {};
    if(!values.fullName || values.fullName.replace(/\s+/g, "").length === 0){
        errors.fullName = "Please enter staff's name.";
    }
    if(!values.email){
        errors.email = "Please enter staff's email.";
    }else if(!isValidEmail(values.email)){
        errors.email = "Email is invalid.";
    }
    return errors;
};

export default validate;