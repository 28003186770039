import { GRAY_100, GRAY_300, GRAY_400, GRAY_600 } from 'components/colors';
import styled, { createGlobalStyle } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 809px;
  max-height: 3508px;
  /* border: ${GRAY_100} solid 1px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 10px;

  .title {
    font-weight: 600;
    font-size: 30px;
    /* display: flex;
    justify-content: center; */
  }
  .desc {
    max-width: 674px;
    font-weight: 400;
    color: ${GRAY_600};
    font-size: 14px;
  }
  .infor {
    margin-top: 10px;
    .item {
      margin-right: 12px;
      font-weight: 600;
      min-width: 40%;
      color:${GRAY_600};
    }
    .input {
      max-width: 564px;
    }
   
  }
  canvas {
    border: 1px solid ${GRAY_300};
    /* width: 500px; */
    height: 350px;
    min-width: 564px;
    border-radius: 12px;
  }
`;
export const GlobalStyle = createGlobalStyle`
height: 40%;
/* .ant-modal-body{
  height: 400px;
} */
`;
