/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Dropdown } from 'antd';
import { GMButton } from 'components/Button';
import { ReactComponent as UpIcon } from 'images/icons/chevron-up.svg';
import { ContextMenuWrapper, WrapperRenderMoreAction } from './styled';

function RenderMoreActions({
  selectedRowKeys,
  ignoreItemCheck = false,
  rowSelection = [],
  actions = [],
  title = [],
  classNameContainer = '',
}) {
  if (!ignoreItemCheck && selectedRowKeys?.length <= 0) {
    return null;
  }
  return (
    <div>
      {selectedRowKeys?.length > 0 && (
        <Dropdown
          trigger="click"
          placement="topCenter"
          dropdownAlign={{ offset: [-40, 4] }}
          getPopupContainer={(trigger) => trigger.parentNode}
          overlay={
            <ContextMenuWrapper>
              {actions?.map((actionItem, actionIndex) => (
                <a
                  key={actionIndex + '_actions'}
                  href=""
                  onClick={(evt) => {
                    evt.preventDefault();
                    actionItem.onSelect();
                    return false;
                  }}
                >
                  <span>{actionItem.text}</span>
                </a>
              ))}
              {rowSelection?.selectionActions?.map(
                (selectionItem, selectionIndex) => (
                  <a
                    key={selectionIndex + '_rowSelection'}
                    href=""
                    onClick={(evt) => {
                      evt.preventDefault();
                      selectionItem.onSelect();
                      return false;
                    }}
                  >
                    <span>{selectionItem.text}</span>
                  </a>
                )
              )}
            </ContextMenuWrapper>
          }
          arrow
        >
          <GMButton type="button" color="SecondaryGray" size="sm">
            More actions
            <UpIcon />
          </GMButton>
        </Dropdown>
      )}
    </div>
  );
}

export default React.memo(RenderMoreActions);
