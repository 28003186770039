import PageContainer from "components/PageContainer";
import {
  BASE_WHITE,
  GRAY_200,
  SHADOW_SM_0_06,
  SHADOW_SM_0_1,
} from "components/colors";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  iframe {
    width: 100%;
    height: calc(100vh - 250px);
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_200};
    box-shadow: 0px 1px 3px ${SHADOW_SM_0_1}, 0px 1px 2px ${SHADOW_SM_0_06};
    border-radius: 12px;
    padding: 8px;
  }
`;