import React from "react";
import TransactionList from "scenes/Transactions/scenes/List";
import Detail from "scenes/Transactions/scenes/Detail";
import icon from "images/transaction.svg";
import Invoice from "scenes/Transactions/scenes/InvoiceTransactions";
import OrderManualPayment from "scenes/Orders/scenes/ManualPayment";

export default [
  // {
  //   key: "statistics.transactions",
  //   name: "Transactions",
  //   component: TransactionList,
  //   path: "/statistics/transactions",
  //   icon: <img src={icon} height={21}  alt="icon"/>,
  //   template: "default",
  //   isPublic: false,
  //   permissions: ["viewTransactionPermission"],
  // },
  {
    key: "statistics.transactions.detail",
    name: "Transaction",
    component: Detail,
    path: "/billing/transactions/:id/detail",
    icon: <img src={icon} height={21} alt="icon" />,
    template: "default",
    isPublic: false,
    permissions: ["viewTransactionPermission"],
  },
  {
    key: "statistics.transactions.invoice",
    name: "Invoice",
    component: Invoice,
    path: "/billing/transactions/:id/invoice",
    icon: <img src={icon} height={21} alt="icon" />,
    template: "default",
    isPublic: false,
    permissions: ["viewTransactionPermission"],
  },
];
