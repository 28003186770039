import axios from 'utils/request'

const prefix = '/shipments';
const prefix_buyTraking = '/customer-config/buy-tracking';

export const getList =(params={})=> {
    return axios({
        method: 'GET',
        params,
        url: `${prefix}`
    })
}

export const getDetail =(id)=> {
    return axios({
        method: 'GET',
         url: `${prefix}/${id}`
    })
}



export const getConfigBuyTracking = () => {
  return axios({
    method: 'GET',
    url: `${prefix_buyTraking}`,
  });
};

export const updateConfigBuyTracking = (data) => {
  return axios({
    method: 'PUT',
    url: `${prefix_buyTraking}`,
    data,
  });
};
