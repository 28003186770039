import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Affix } from 'antd';
import { getInvoiceTransaction } from './actions';
import { getListCustomerConfig } from '../../../Settings/scenes/General/actions';
import Logo from 'images/Logo_store/full/gearment.svg';
import money from 'utils/money';
import { getMomentInstance2 } from 'utils/moment';
import BackToIcon from 'images/chevron-down.svg';
import RenderPrintPDF from 'utils/Form/RenderPrintPDF';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import GMHelmet from 'components/GMHelmet';
import { postfix } from 'utils/options';

class Invoice extends Component {
  componentDidMount() {
    this.props.getInvoiceTransaction(this.props.match.params.id);
    this.props.getCutomerConfig();
  }

  render() {
    const {
      invoice,
      pageName,
      listCustomerConfig,
      listCustomerConfigLoading,
    } = this.props;
    return (
      <>
        <GMHelmet
          overrideTitle={`${pageName} #${this.props.match.params.id}` + postfix}
        />
        <Wrapper
          pageName={`${pageName} #${this.props.match.params.id}`}
          breadcrumDetails={{
            [this.props.match.params.id]:
              this.props.match.params.id &&
              `#${this.props.match.params.id || ''}`,
            // [this.props.match.params.id + ' ']: this.props.match.params.id && `#${this.props.match.params.id || ''}`,
          }}
          filter={
            <GMButton
              className="BackToButton d-flex align-items-center"
              onClick={() => {
                this.props.history.push('/billing/payment');
              }}
              color="SecondaryGray"
            >
              <img src={BackToIcon} className="back-icon" />
              <p className="m-0 ml-2">Back to Transactions</p>
            </GMButton>
          }
        >
          <Spin
            spinning={invoice.loading || listCustomerConfigLoading}
            tip={"Please wait a moment. We're processing your request."}
          >
            <div className="invoice" ref={(el) => (this.componentRef = el)}>
              <div className="row">
                <div className="col-md-6 col-xl-8">
                  <div className="mb-4">
                    <img src={Logo} alt={'Gearmnet Logo'} width={200} />
                    <h5 className="mt-2">Gearment LLC</h5>
                  </div>
                  <div className="mb-4">
                    <p>
                      Address:{' '}
                      {invoice.data?.company?.companyStreet1
                        ? invoice.data.company.companyStreet1
                        : ''}
                    </p>
                    {invoice.data?.company?.companyStreet2 && (
                      <p>{invoice.data.company.companyStreet2}</p>
                    )}
                    <p>
                      {invoice.data?.company?.companyCity
                        ? invoice.data.company.companyCity + ', '
                        : ''}
                      {invoice.data?.company?.companyState
                        ? invoice.data.company.companyState + ', '
                        : ''}
                      {invoice.data?.company?.companyPostalCode
                        ? invoice.data.company.companyPostalCode + ', '
                        : ''}
                    </p>
                    <p>{invoice?.data?.default_country_checkout || ''}</p>
                  </div>
                  <div>
                    <p>Phone: {invoice.data?.company?.companyPhone || ''}</p>
                    <p>Email: info@gearment.com</p>
                    <p>Website: https://gearment.com</p>
                  </div>
                  <div>
                    <h5>Invoice To</h5>
                    <p>
                      Seller store:{' '}
                      {listCustomerConfig?.invoice_store_name?.value || ''}
                    </p>
                    <p>
                      Full Name:{' '}
                      {listCustomerConfig?.invoice_first_name?.value
                        ? `${listCustomerConfig?.invoice_first_name?.value} `
                        : ''}
                      {listCustomerConfig?.invoice_last_name?.value || ''}
                    </p>
                    <p>
                      Address:
                      {listCustomerConfig?.invoice_address?.value
                        ? `${listCustomerConfig?.invoice_address?.value} `
                        : ''}
                      {listCustomerConfig?.invoice_extended_address?.value
                        ? `${listCustomerConfig?.invoice_extended_address?.value} `
                        : ''}
                      {listCustomerConfig?.invoice_city?.value
                        ? `${listCustomerConfig?.invoice_city?.value}, `
                        : ''}
                      {listCustomerConfig?.invoice_state?.value
                        ? `${listCustomerConfig?.invoice_state?.value}, `
                        : ''}
                      {listCustomerConfig?.invoice_postalcode?.value
                        ? `${listCustomerConfig?.invoice_postalcode?.value}, `
                        : ''}
                      {listCustomerConfig?.invoice_country_name?.value
                        ? `${listCustomerConfig?.invoice_country_name?.value} `
                        : ''}
                    </p>
                    <p>
                      Email: {listCustomerConfig?.invoice_email?.value || ''}
                    </p>
                    <p>
                      Phone: {listCustomerConfig?.invoice_phone?.value || ''}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4">
                  <div className="mb-3">
                    <h3 className="text-uppercase">
                      INVOICE #{this.props.match.params.id}
                    </h3>
                    <p>
                      Date:{' '}
                      {invoice?.data?.createdAt
                        ? getMomentInstance2({
                            unixDateTime: invoice.data.createdAt,
                            isDateTimFormat: true,
                          })
                        : ''}
                    </p>
                  </div>
                  <div className="p-4 border rounded">
                    <h5>Payment details</h5>
                    <div className="table-responsive">
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ width: '55%' }}>Transaction Code: </td>
                            <td>
                              <b>{invoice?.data?.name || ''}</b>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '55%' }}>Subtotal: </td>
                            <td>
                              {invoice?.data?.price?.subTotal
                                ? money(invoice.data.price.subTotal)
                                : money(0)}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: '55%' }}>Shipping fee: </td>
                            <td>
                              {invoice?.data?.price?.feeTotal
                                ? money(invoice.data.price.feeTotal)
                                : money(0)}
                            </td>
                          </tr>
                          {invoice?.data?.price?.surchargeFeeTotal ? (
                            <tr>
                              <td style={{ width: '55%' }}>
                                Peak season surcharge:{' '}
                              </td>
                              <td>
                                {invoice?.data?.price?.surchargeFeeTotal
                                  ? money(
                                      invoice?.data?.price?.surchargeFeeTotal
                                    )
                                  : money(0)}
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td style={{ width: '55%' }}>Tax: </td>
                            <td>
                              {invoice?.data?.price?.vatTotal
                                ? money(invoice.data.price.vatTotal)
                                : money(0)}
                            </td>
                          </tr>
                          {invoice?.data.paymentMethod === 'Paypal' && (
                            <>
                              <tr>
                                <td style={{ width: '55%' }}>
                                  Paypal's transaction fee:{' '}
                                </td>
                                <td>
                                  {money(
                                    invoice?.data.transactionFee?.fee || 0
                                  )}
                                </td>
                              </tr>
                            </>
                          )}
                          <tr>
                            <td style={{ width: '55%' }}>Total charge: </td>
                            <td>
                              <b>
                                {invoice?.data?.amount
                                  ? money(invoice?.data?.amount)
                                  : money(0)}
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive mt-3 mt-sm-0">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th width="10" className="text-uppercase">
                        #
                      </th>
                      <th width="10" className="text-uppercase">
                        Order
                      </th>
                      <th width="20" className="text-uppercase">
                        Item
                      </th>
                      <th width="20" className="text-uppercase">
                        Product
                      </th>
                      <th width="5" className="text-uppercase">
                        Quantity
                      </th>
                      <th width="10" className="text-uppercase">
                        Tracking code
                      </th>
                      <th width="10" className="text-uppercase">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(invoice?.data?.orders || []).map((item) => {
                      return (
                        <tr>
                          <td>#{item.ord_id}</td>
                          <td>
                            <Link to={`/orders/${item.ord_id}`}>
                              {item.ord_name}
                            </Link>
                          </td>
                          <td>{item.var_title} </td>
                          <td style={{ width: '15%' }} className="text-center">
                            {item.image_url && (
                              <img
                                style={{ width: '50%' }}
                                src={item.image_url}
                                alt=""
                              />
                            )}
                          </td>
                          <td>{item.ordi_quantity}</td>
                          <td>{item.ordx_tracking_number}</td>
                          <td>{money(item.ordi_total)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-md-7 col-xl-8"></div>
                <div className="col-md-5 col-xl-4">
                  <div className="table-responsive">
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td style={{ width: '50%' }}>Sub - Total Amount: </td>
                          <td>
                            {invoice?.data?.price?.subTotal
                              ? money(invoice?.data?.price?.subTotal)
                              : money(0)}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: '50%' }}>Shipping fee: </td>
                          <td>
                            {invoice?.data?.price?.feeTotal
                              ? money(invoice?.data?.price?.feeTotal)
                              : money(0)}
                          </td>
                        </tr>
                        {invoice?.data?.price?.surchargeFeeTotal ? (
                          <tr>
                            <td style={{ width: '50%' }}>
                              Peak season surcharge:{' '}
                            </td>
                            <td>
                              {invoice?.data?.price?.surchargeFeeTotal
                                ? money(invoice?.data?.price?.surchargeFeeTotal)
                                : money(0)}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td style={{ width: '50%' }}>Tax: </td>
                          <td>
                            {invoice?.data?.price?.vatTotal
                              ? money(invoice?.data?.price?.vatTotal)
                              : money(0)}
                          </td>
                        </tr>
                        {invoice?.data.transactionFee?.fee && (
                          <tr>
                            <td style={{ width: '50%' }}>
                              Paypal's transaction fee:{' '}
                            </td>
                            <td>
                              {money(invoice?.data.transactionFee?.fee || 0)}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td style={{ width: '50%' }}>Grand Total: </td>
                          <td>
                            <b>
                              {invoice?.data?.amount
                                ? money(invoice.data.amount)
                                : money(0)}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Affix offsetBottom={0}>
              <RenderPrintPDF
                type={'printTransactionInvoice'}
                id={this.props.match.params.id}
              />
            </Affix>
          </Spin>
        </Wrapper>
      </>
    );
  }
}

export default connect(
  (state) => ({
    invoice: state.transaction.invoice.getInvoiceTransaction,
    listCustomerConfigLoading: state.setting.general.listCustomerConfig.loading,
    listCustomerConfig: state.setting.general.listCustomerConfig.data,
  }),
  (dispatch) => ({
    getInvoiceTransaction: (id) => {
      dispatch(getInvoiceTransaction(id));
    },
    getCutomerConfig: (id) => {
      dispatch(getListCustomerConfig(id));
    },
  })
)(Invoice);
