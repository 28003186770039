import React from 'react';
import {
  defaultTitle,
  listMetaTitleOptions,
  listSpecialCustomer,
  list_shipping_method,
  list_shipping_method_fba,
  list_shipping_services,
  metaInfo,
  options_backend_type,
  postfix,
} from 'utils/options';
import getMomentInstance from './moment';
import md5 from 'md5';
import Resizer from 'react-image-file-resizer';
import silverIcon from 'images/lv-silver.png';
import goldIcon from 'images/lv-gold.png';
import diamondIcon from 'images/lv-diamond.png';
import bronzeIcon from 'images/lv-bronze.png';
import { BASE_BLACK, BASE_WHITE } from 'components/colors';
export const isValidDomain = (domain) => {
  return /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(
    domain
  );
};

export const toFixed = (num, defaultNum = 0, fixed = 2) => {
  const parsedNum = !isNaN(Number(parseFloat(num)))
    ? parseFloat(num)
    : defaultNum;
  return !!fixed
    ? parsedNum.toFixed(fixed).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    : `${parsedNum}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function appendGflareDomain(url) {
  const rgx = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/;
  return rgx.test(url) ? url : 'https://cdn.geaflare.com' + url;
}

export const processMockup = (
  boxSize,
  artworkSize,
  sampleSize,
  typeProduct,
  typePrint,
  isFrontOrBack
) => {
  let thumbWidth = 0,
    thumbHeight = 0,
    width = 0,
    height = 0,
    left2 = 0,
    left = 0;
  if (
    (parseInt(typePrint) === 5 && parseInt(typeProduct) === 2) ||
    parseInt(typeProduct) === 5
  ) {
    thumbHeight = Math.round((sampleSize.h * boxSize.h) / 100);
    let aspectRatio = artworkSize?.w / artworkSize?.h;
    thumbWidth = Math.round(thumbHeight * aspectRatio);

    width = Math.round((thumbWidth / ((boxSize.w * sampleSize.w) / 100)) * 100);
    height = Math.round(
      (thumbHeight / ((boxSize.h * sampleSize.h) / 100)) * 100
    );
    if (isFrontOrBack === 'Front') {
      left2 = Math.round(100 - width) / 2 < 0 ? 0 : Math.round(100 - width) / 2;
    } else if (isFrontOrBack === 'Back') {
      left2 = Math.round(100 - width);
    }
    left = (boxSize.w * sampleSize.w * left2) / 10000;
  } else if (
    parseInt(typePrint) === 2 ||
    parseInt(typePrint) === 3 ||
    parseInt(typePrint) === 4
  ) {
    return {
      w2: 100,
      h2: 100,
      w: (sampleSize.w * boxSize.w) / 100,
      h: (sampleSize.h * boxSize.h) / 100,
      x: 0,
      x2: 0,
    };
  } else {
    if (artworkSize?.w >= artworkSize?.h) {
      thumbWidth = Math.round((sampleSize.w * boxSize.w) / 100);
      let maxWidth = thumbWidth;
      let aspectRatio = artworkSize.h / artworkSize.w;
      thumbHeight = Math.round(thumbWidth * aspectRatio);
      while (thumbHeight > maxWidth) {
        thumbWidth -= 10;
        thumbHeight = Math.round(thumbWidth * aspectRatio);
      }
      while (thumbHeight > Math.round((sampleSize.h * boxSize.h) / 100)) {
        thumbWidth -= 10;
        thumbHeight = Math.round(thumbWidth * aspectRatio);
      }
    } else {
      thumbHeight = Math.round((sampleSize.h * boxSize.h) / 100);
      let maxHeight = thumbHeight;
      let aspectRatio = artworkSize?.w / artworkSize?.h;
      thumbWidth = Math.round(thumbHeight * aspectRatio);
      while (thumbWidth > maxHeight) {
        thumbHeight -= 10;
        thumbWidth = Math.round(thumbHeight * aspectRatio);
      }
      while (thumbWidth > Math.round((sampleSize.w * boxSize.w) / 100)) {
        thumbHeight -= 10;
        thumbWidth = Math.round(thumbHeight * aspectRatio);
      }
    }
    width = Math.round((thumbWidth / ((boxSize.w * sampleSize.w) / 100)) * 100);
    height = Math.round(
      (thumbHeight / ((boxSize.h * sampleSize.h) / 100)) * 100
    );
    left2 = Math.round((100 - width) / 2);
    left = (boxSize.w * sampleSize.w * left2) / 10000;
  }
  return {
    w: thumbWidth,
    h: thumbHeight,
    w2: width,
    h2: height,
    x: left,
    x2: left2,
  };
};

export const getSelectedMenuKeys = (
  pathname,
  ignoreID = true,
  separator = '/'
) => {
  const arr = pathname.split(separator).filter((i) => i);
  if (arr.length > 0) {
    return arr.reduce((acc, cur, i) => {
      if (!!parseInt(cur) && ignoreID) {
        return acc;
      }
      if (acc.length > 0) {
        acc.push(`${acc[acc.length - 1]}.${cur}`);
      } else {
        acc.push(cur);
      }
      return acc;
    }, []);
  }
  return [arr.slice(0, arr.length - 1).join('.')];
};

export const getMailboxAddress = (emailAddress) => {
  const splitt = (emailAddress + '').split('@');
  if (splitt.length !== 2) {
    return null;
  }
  const aDomain = splitt?.[1];
  switch (aDomain) {
    case 'yahoo.com':
    case 'google.com':
      return `https://mail.${aDomain}`;
    case 'gmail.com':
      return `https://mail.google.com`;
    default:
      return `https://${aDomain}`;
  }
};

export const isValidEmail = (email) => {
  return email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
};

export const censorEmail = (email) => {
  const splitt = `${email}`.split('@');
  let slc0 = '',
    slc1 = '',
    slc = splitt?.[0];
  if (splitt?.[0]?.length > 6) {
    slc0 = splitt?.[0]?.slice(0, 3);
    slc1 = splitt?.[0]?.slice(-3);
    slc = [slc0, slc1].join('***');
  } else {
    slc = splitt?.[0]?.slice(0, 3) + '***';
  }
  return [slc, splitt?.[1]].join('@');
};

export const censorToken = (email = '') => {
  let slc0 = '',
    slc1 = '',
    slc = '';
  if (email.length > 6) {
    slc0 = email?.slice(0, 3);
    slc1 = email?.slice(-3);
    slc = [slc0, slc1].join('***');
  } else {
    slc = email?.slice(0, 3) + '***';
  }
  return slc;
};

export const shorten3dots = (txt = '', count = 4) => {
  let slc0 = '',
    slc1 = '',
    slc = txt;
  if (txt.length > count * 2) {
    slc0 = txt?.slice(0, count);
    slc1 = txt?.slice(-count);
    slc = [slc0, slc1].join('...');
  }
  return slc;
};

export const isValidPassword = (password) => {
  return password.match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}|[\]\\;':",./<>?])[A-Za-z\d~!@#$%^&*()_+`\-={}|[\]\\;':",./<>?]{8,}$/
  );
};

export const isValidUrl = (url) => {
  return url.match(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  );
};

export const isValidImage = (imageUrl) => {
  return imageUrl.match(/^.*['png'| 'jpg']$/);
};

export const isValidDiscountCode = (discountCode = '') => {
  return discountCode.length > 9 && discountCode.length <= 15;
};

export const leadingZero = (num) => {
  return num < 10 && num >= 0 ? '0' + num : num;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getStaffName = (listStaff, arrStaffId) => {
  const arrStaffName = [];
  for (let staff in listStaff) {
    if (arrStaffId.include(staff.id.toString())) {
      arrStaffName.push(staff.name);
    }
  }
  return arrStaffName;
};

export const getStoreName = (store) => {
  return store && store.showName ? store.showName : store.name || '';
};

export const getShippingMethodName = (order, returnText = false) => {
  let shippingService = null;
  if (parseInt(order?.platformId) === 17) {
    shippingService = list_shipping_services.find(
      (item) => order?.subtypeInsertMethod === item.value
    );
  } else {
    const currListShippingMethod = getListShippingMethod(order);
    shippingService = currListShippingMethod.find(
      (item) => order.shippingMethod === item.value
    );
  }
  if (!returnText) {
    return shippingService ? shippingService.label : 'N/A';
  } else {
    return shippingService ? shippingService.name : 'N/A';
  }
};

export const getListShippingMethod = (order) => {
  return parseInt(order.platformId) === 9
    ? list_shipping_method_fba
    : list_shipping_method;
};

export const compareTime = (dateTime, amountTime, unit) => {
  const currMoment = getMomentInstance();
  const beforeMoment = getMomentInstance(
    dateTime,
    null,
    null,
    null,
    null,
    null,
    amountTime,
    'seconds'
  );
  return currMoment.diff(beforeMoment, 'seconds') > 0;
};

export const getStoreTypeName = (key) => {
  const storeType = options_backend_type.find((item) => item.value === key);
  return storeType ? storeType.name : '';
};

export const getListShippingSerivceInMatchProduct = (orderItem, order) => {
  let listShippingMethod = [];
  if (orderItem && order) {
    if (parseInt(order.platformId) === 9) {
      listShippingMethod = [
        { value: '3', label: <b>FBA Amazon</b> },
        { value: '4', label: <b>Pickup at warehouse</b> },
      ];
    } else {
      listShippingMethod = [
        {
          value: '0',
          label: <b>Standard shipping (7-10 business days)</b>,
          labelInternational: <b>Standard shipping (14-21 business days)</b>,
        },
        {
          value: '2',
          label: <b>Ground shipping (3-5 business days)</b>,
        },
        {
          value: '1',
          label: <b>Fast shipping (2-3 business days)</b>,
        },
      ];
    }
  }
  return listShippingMethod.map((sm) => ({
    ...sm,
    labelInternational: sm.labelInternational || sm.label,
  }));
};

export const getSizeImage = (url, callback) => {
  var img = new Image();
  img.src = url;
  img.onload = function () {
    callback(this.width, this.height);
  };
};

export const createFolderName = (cusId) => {
  return md5(`${cusId}Security3F`);
};

export const createFileName = (extension, prefix, cusId) => {
  const currTimeString = new Date().getTime().toString();
  return `${prefix}${currTimeString.substring(
    -3
  )}${cusId}_${randomHelper()}.${extension}`;
};

export const randomHelper = (length = 6) => {
  let result = '';
  const characters =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const resizeImage = (
  file,
  width,
  height,
  outputType = 'base64',
  fileType = 'PNG'
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      fileType,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      outputType
    );
  });

export const getCusTierInfo = (cusTierLevel, type = 'text') => {
  let result = '';
  switch (parseInt(cusTierLevel)) {
    case 0:
      if (type === 'text') {
        result = 'Sale price';
      }

      if (type === 'image') {
        result = (
          <img className={'img-fluid'} src={bronzeIcon} width={'70'} alt="" />
        );
      }
      break;
    case 1:
      if (type === 'text') {
        result = 'Sale price old';
      }

      if (type === 'image') {
        result = (
          <img className={'img-fluid'} src={bronzeIcon} width={'70'} alt="" />
        );
      }
      break;
    case 2:
      if (type === 'text') {
        result = 'Bronzen';
      }

      if (type === 'image') {
        result = (
          <img className={'img-fluid'} src={bronzeIcon} width={'70'} alt="" />
        );
      }
      break;
    case 4:
      if (type === 'text') {
        result = 'Silver';
      }

      if (type === 'image') {
        result = (
          <img className={'img-fluid'} src={silverIcon} width={'70'} alt="" />
        );
      }
      break;
    case 3:
      if (type === 'text') {
        result = 'Gold';
      }

      if (type === 'image') {
        result = (
          <img className={'img-fluid'} src={goldIcon} width={'70'} alt="" />
        );
      }
      break;
    case 5:
      if (type === 'text') {
        result = 'Diamond';
      }

      if (type === 'image') {
        result = (
          <img className={'img-fluid'} src={diamondIcon} width={'70'} alt="" />
        );
      }
      break;
    default:
      result = 'N/A';
  }

  return result;
};

export const getPlatformForImportOrderProgress = (type) => {
  switch (type) {
    case 0:
      return 'Gearment file';
    case 1:
      return 'BigCommerce';
    case 2:
      return 'Wordpress';
    case 3:
      return 'Etsy';
    case 4:
      return 'Presta shop';
    case 5:
      return 'eBay';
    case 6:
      return 'Amazon';
    case 7:
      return 'Inkgo 2';
    case 8:
      return 'Inkgo 3';
    case 9:
      return 'eBay 2';
    case 10:
      return 'eBay 3';
    case 11:
      return 'Inkgo';
    case 12:
      return 'Variant Gearment';
    case 13:
      return 'Etsy 2';
    case 14:
      return 'Shopify';
    default:
      return '';
  }
};

export const checkRoutePermission = (
  profile,
  routeKey,
  routePermissions,
  logged,
  isPublic
) => {
  if (logged) {
    if (isPublic === true) return true;
    if (
      routeKey?.indexOf('setting.account') !== -1 ||
      routeKey?.indexOf('billings.payment-method') !== -1 ||
      routeKey?.indexOf('shipping-delivery') !== -1 ||
      routeKey?.indexOf('billings.legal-info') !== -1 ||
      routeKey?.indexOf('billing.return-address') !== -1
    ) {
      if (parseInt(profile?.primaryId) !== 0) return false;
      return true;
    } else if (routeKey === 'orders.report_product') {
      if (
        parseInt(profile?.primaryId) !== 0 &&
        !listSpecialCustomer.includes(profile?.id)
      ) {
        return false;
      }
      return true;
    } else if (routeKey === 'orders.report-inkgo') {
      if (![1, 60, 2415].includes(profile?.id)) {
        return false;
      }
      return true;
    } else if (routeKey === 'invoices') {
      if (parseInt(profile?.cusUseInvoice) !== 1) {
        return false;
      }
      return true;
    } else if (parseInt(profile?.primaryId) === 0) {
      return true;
    } else {
      if (routePermissions && Array.isArray(routePermissions)) {
        for (let index = 0; index < routePermissions.length; index++) {
          if (
            parseInt(profile['fullPermission']) !== 1 &&
            parseInt(profile[routePermissions[index]]) !== 1
          ) {
            return false;
          }
        }
      }
      return true;
    }
  } else if (routeKey.includes('auth.')) {
    return true;
  } else if (routeKey?.includes('callback_shopify.')) {
    //Log out
    return 5;
  } else if (
    (routeKey?.includes('callback_shopify.') ||
      routeKey?.includes('callback_shopify.')) &&
    parseInt(profile?.primaryId) !== 0
  ) {
    //Permission for staff
    return 6;
  }
  return false;
};

export const checkFunctionalPermission = (profile, permissionName) => {
  if (parseInt(profile?.['primaryId']) === 0) {
    return true;
  } else if (
    parseInt(profile?.[permissionName]) === 1 ||
    parseInt(profile['fullPermission']) === 1
  ) {
    return true;
  }
  return false;
};

export const getMetaTitle = (pathName) => {
  const pN = pathName;
  const filterArr =
    metaInfo?.filter((i) => {
      return i.url.test(pN);
    }) || [];
  let currentMeta = {
    title: defaultTitle,
  };
  if (filterArr.length > 0) {
    currentMeta =
      filterArr?.reduce(function (a, b) {
        return a?.url?.toLocaleString().length > b?.url?.toLocaleString().length
          ? a
          : b;
      }) || {};
  }
  if ((currentMeta.title || '').indexOf(postfix) === -1) {
    currentMeta.title = currentMeta.title + postfix;
  }
  return currentMeta;
};

export const getStyleOfMainLayout = (pathName) => {
  if (
    pathName.includes('orders/create') ||
    pathName.includes('create-label-order') ||
    (pathName.includes('orders') && pathName.includes('duplicate')) ||
    pathName.includes('convert-order-label') ||
    pathName.includes('/orders/order-issues/create')
  ) {
    return {
      maxWidth: '100%',
      minHeight: '100vh',
    };
  }
  return {
    maxWidth: '100%',
    minHeight: '100vh',
  };
};

export const generateOrderCode = (orderId = '', maxlength = 0) => {
  const strLen = orderId.length;
  const subStr = maxlength - strLen;
  let len = '';
  for (let i = 1; i <= subStr; i++) len = len + '0';
  return len + orderId;
};

export const addMarginTopToCheckBoxOfAntdTable = (
  elementName = '',
  selectedRowKeys = []
) => {
  const checkboxElement = document?.getElementsByClassName(elementName)?.[0];
  if (
    Array.isArray(selectedRowKeys) &&
    selectedRowKeys.length > 0 &&
    checkboxElement &&
    checkboxElement?.style?.marginTop !== '47px'
  ) {
    // checkboxElement.style.marginTop = '47px';
  }
  if (
    Array.isArray(selectedRowKeys) &&
    selectedRowKeys.length === 0 &&
    checkboxElement
  ) {
    checkboxElement.style.marginTop = '0px';
  }
};

export const hidePartialOfEmail = (email) => {
  return email?.replace(/(.{4})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += '*';
    }
    return gp2;
  });
};
export const formatCreditCardNumber = (cardNumber, gapNo) => {
  let newStr = ' ';
  let len = cardNumber.length;
  for (let i = 0; i < len; i++) {
    newStr = newStr + cardNumber[i];
    while (newStr.length % (gapNo + 1) === 0) {
      newStr = newStr + ' ';
    }
  }
  return newStr.trim(' ');
};

export const getBraintreeMessage = (originalMessage) => {
  return originalMessage
    .replace(
      'Do Not Honor',
      `The customer's bank is unwilling to accept the transaction. 
  The customer will need to contact their bank for more details 
  regarding this generic decline.`
    )
    .replace(
      'No Account',
      `The submitted card number is not on file with the card-issuing bank. 
  The customer will need to contact their bank.`
    )
    .replace(
      'No Such Issuer',
      `The customer’s bank is requesting that the merchant (you) call to obtain a 
  special authorization code in order to complete this transaction. 
  This can result in a lengthy process – we recommend obtaining a new payment method instead.`
    );
};

export const clearFilter = (changeFieldValue, params) => {
  for (let [key, value] of Object.entries(params)) {
    changeFieldValue(key, value);
  }
};

export function getStore(storeName, profileId = false) {
  const _sn = storeName + (profileId ? '_' + profileId : '');
  const tmp = localStorage.getItem(_sn);
  if (tmp) {
    try {
      return JSON.parse(tmp);
    } catch (error) {
      return tmp;
    }
  } else {
    return tmp;
  }
}

export function setStore(storeName, value, profileId = false) {
  const _sn = storeName + (profileId ? '_' + profileId : '');
  return localStorage.setItem(_sn, JSON.stringify(value));
}

export function invertHex(hex) {
  return (Number(`0x1${hex}`) ^ 0xffffff).toString(16).substr(1).toUpperCase();
}

export function pickTextColorBasedOnBgColor(
  bgColor,
  lightColor = '#FFFFFF',
  darkColor = '#000000'
) {
  const getLuminance = function (hexColor) {
    var color =
      hexColor.charAt(0) === '#' ? hexColor.substring(1, 7) : hexColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) =>
      col <= 0.03928 ? col / 12.92 : ((col + 0.055) / 1.055) ** 2.4
    );

    return 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  };

  var L = getLuminance(bgColor);
  var L1 = getLuminance(lightColor);
  var L2 = getLuminance(darkColor);

  return L > Math.sqrt((L1 + 0.05) * (L2 + 0.05)) - 0.05
    ? darkColor
    : lightColor;
}

export const dummyFunction = () => { };

export const gtmtrack = (data2push) => {
  if (typeof window?.dataLayer?.push === 'function') {
    // eslint-disable-next-line no-unused-expressions
    window?.dataLayer?.push(data2push);
  }
};

export function setCookie(name, value, days = 99) {
  const assign = name + '=' + escape(value) + ';';
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString() + ';';
  const path = 'path=/;';
  let domain =
    'domain=.' +
    (window?.location?.hostname?.match(/[^\.]*\.[^.]*$/)?.[0] ||
      window?.location?.hostname) +
    ';';
  if (/^\d+(\.\d+){3}$/.test(window?.location?.hostname)) {
    domain = 'domain=' + window?.location?.hostname + ';';
  }
  document.cookie = assign + expires + path + domain;
}

export function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function removeCookie(name) {
  setCookie(name, undefined, 0);
}

export const download = (link) => {
  const a = document.createElement('A');
  a.href = link;
  a.download = link.substr(link.lastIndexOf('/') + 1);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const pagCal = ({ currentPage, itemPerPage, totalItem, padding = 4 }) => {
  const pageCount = Math.ceil(totalItem / itemPerPage);
  const tmp = [];
  const dummyElem = {
    dummy: !!1,
  };
  for (let i = 1; i <= pageCount; i++) {
    tmp.push({
      page: i,
      isCurrent: i === currentPage,
    });
  }
  if (pageCount <= padding * 2) {
    return tmp;
  }
  if (currentPage < padding) {
    return [
      ...tmp.slice(0, padding),
      { ...dummyElem },
      ...tmp.slice(-1),
    ];
  } else if (currentPage >= 2 && currentPage < pageCount - padding) {
    return [
      ...tmp.slice(0, 1),
      { ...dummyElem },
      ...tmp.slice(Math.ceil(currentPage - padding / 2), currentPage - 1 + padding),
      { ...dummyElem },
      ...tmp.slice(-1),
    ];
  } else if(currentPage === pageCount - padding) {
    return [
      ...tmp.slice(0, 1),
      { ...dummyElem },
      ...tmp.slice(Math.ceil(currentPage - padding / 2), currentPage - 2 + padding),
      { ...dummyElem },
      ...tmp.slice(-1),
    ];
  } else if (currentPage > pageCount - padding - 1) {
    return [
      ...tmp.slice(0, 1),
      { ...dummyElem },
      ...tmp.slice(-padding - 1),
    ];

  }
  
  return tmp;
}

export const getClientIp = async () => {
  const response = await fetch("https://geolocation-db.com/json/")
  const data = await response.json()
  console.log(data.IPv4)
  return data.IPv4;
}
window.pagCal = pagCal

window.setCookie = setCookie;
window.getCookie = getCookie;
