import { combineReducers } from 'redux'
import list from './scenes/List/reducer'
// import edit from './scenes/Edit/reducer'
import amazon from './scenes/Amazon/reducer'
import google from './scenes/Google/reducer'
import amazon_edit from './scenes/AmazonEdit/reducer'
import google_edit from './scenes/GoogleEdit/reducer'

// import del from './scenes/Delete/reducer'

const reducer = combineReducers({
    list,
    // edit,
    amazon,
    amazon_edit,
    google,
    google_edit,
})

export default reducer