export const actionType = {
    DETAIL: {
        REQUEST: 'BULK.DETAIL.REQUEST',
        SUCCESS: 'BULK.DETAIL.SUCCESS',
        ERROR: 'BULK.DETAIL.ERROR',
    },
    SET_BULK_IMAGE: 'SET_BULK_IMAGE'
};

export function getDetailBulk(id, storeId){
    return {
        type: actionType.DETAIL.REQUEST,
        id, storeId
    }
}

export function setBulkImage(image){
    return {
        type: actionType.SET_BULK_IMAGE,
        image
    }
}
