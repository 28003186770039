import React, { Component } from "react";
import { Checkbox, Col } from "antd";
import GMRow from "components/GMLayout/GMRow";

class renderCheckboxGroup extends Component {
  handleChange = (value) => {};
  render() {
    const {
      input,
      options,
      colSpan,
      meta: { asyncValidating, error, submitFailed },
      onChooseFullPermisison = null,
    } = this.props;
    return (
      <div className={asyncValidating ? "async-validating" : ""}>
        {/* <CheckboxGroup
            {...rest}     
            value={input.value || []}
            onChange={(value)=>input.onChange(value)}
        /> */}
        <Checkbox.Group
          value={input.value || []}
          style={{ width: "100%" }}
          onChange={(value) => {
            input.onChange(value);
            if (onChooseFullPermisison) {
              onChooseFullPermisison(input.name, value);
            }
          }}
        >
          <GMRow>
            {options.map((item) => (
              <Col key={item.value} span={colSpan || 12} className="mb-2">
                <Checkbox
                  className="d-flex align-items-start mb-2 ant-checkbox-wrapper-lg mb-2"
                  value={item.value}
                >
                  {item.label}
                </Checkbox>
              </Col>
            ))}
          </GMRow>
        </Checkbox.Group>
        {(error && submitFailed) && (
          <span className="invalid-feedback d-block">{error}</span>
        )}
      </div>
    );
  }
}

export default renderCheckboxGroup;
