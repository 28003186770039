import { ISSUE_UPDATE_REQUEST, ISSUE_DETAIL_REQUEST, ISSUE_DETAIL_SET } from "./constants";

export function updateIssue(id, dataToUpdate) {
  return {
    type: ISSUE_UPDATE_REQUEST,
    id,
    dataToUpdate,
  };
}
export function getDetailIssue(id) {
  return {
    type: ISSUE_DETAIL_REQUEST,
    id,
  };
}
export function setDetailIssueAction(data) {
  return {
    type: ISSUE_DETAIL_SET,
    data,
  };
}
