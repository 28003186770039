import React, { Component, Fragment } from "react";
import classnames from "classnames";
import { DatePicker } from "antd";
import { default_config } from "utils/options";

const settingSystem = localStorage.getItem("system_setting")
  ? JSON.parse(localStorage.getItem("system_setting"))
  : default_config;
class FieldRangeDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      endOpen: false,
    };
    this.endRef = React.createRef();
  }
  // state = {
  //   endOpen: false,
  // };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  disabledStartDate = (startValue) => {
    const {
      min,
      input: { value },
    } = this.props;
    if (!startValue || !value[1]) {
      if (startValue && min) {
        return startValue.valueOf() > min.valueOf();
      }
      // return false;
    }
    return false;
    // return startValue.valueOf() > value[1].valueOf() || (min && startValue.valueOf() < min.valueOf());
  };

  disabledEndDate = (endValue) => {
    const {
      max,
      input: { value },
    } = this.props;
    if (!endValue || !value[0]) {
      if (endValue && max) {
        return endValue.valueOf() < max.valueOf();
      }
      // return false;
    }
    return false;
    // return endValue.valueOf() <= value[0].valueOf() || (min && endValue.valueOf() < min.valueOf());
  };
  handleChangeStart = (v) => {
    const { range, input } = this.props;
    const start_date = v && v.startOf("day");
    let end_date = input.value[1];
    if (start_date && end_date && end_date.diff(start_date, "days") < 0) {
      input.onChange([start_date, start_date.clone().endOf("day")]);
      return;
    }
    if (
      range &&
      start_date &&
      end_date &&
      end_date.diff(start_date, "days") > range
    ) {
      input.onChange([start_date, start_date.clone().add(range, "days")]);
    } else if (!start_date && end_date && range) {
      input.onChange([end_date.clone().subtract(range, "days"), end_date]);
    } else {
      input.onChange([start_date, end_date]);
    }
    this.endRef.current.focus();
  };
  handleChangeEnd = (v) => {
    const { range, input } = this.props;

    let start_date = input.value[0];
    const end_date = v && v.endOf("day");

    if (start_date && end_date && end_date.diff(start_date, "days") < 0) {
      input.onChange([end_date.clone().startOf("day"), end_date]);
      return;
    }
    if (
      range &&
      start_date &&
      end_date &&
      end_date.diff(start_date, "days") > range
    ) {
      input.onChange([end_date.clone().subtract(range, "days"), end_date]);
    } else if (start_date && !end_date && range) {
      input.onChange([start_date, start_date.clone().add(range, "days")]);
    } else {
      input.onChange([start_date, end_date]);
    }
  };
  render() {
    const { endOpen } = this.state;
    const {
      disabled = false,
      input,
      startPlaceholder = "",
      endPlaceholder = "",
      labelStart = "",
      labelEnd = "",
      size = "",
      classContainer = "",
      startClass = "",
      classNameStart = "",
      classNameEnd = "",
      endClass = "",
      meta: { invalid, error, submitFailed},
    } = this.props;
    return (
      <Fragment>
        <div className={classnames(classNameStart, classContainer)}>
          {labelStart && <label className="col-form-label">{labelStart}</label>}
          <DatePicker
            disabled={disabled}
            disabledDate={this.disabledStartDate}
            format={settingSystem.dateFormat}
            size={size || "large"}
            value={input.value[0]}
            placeholder={startPlaceholder || "From"}
            onChange={this.handleChangeStart}
            onOpenChange={this.handleStartOpenChange}
            style={{ width: "100%" }}
            className={startClass}
          />
          {(invalid && !input.value[0] && submitFailed) && (
            <div className="position-relative">
              <hr
                className="border-danger m-0 position-absolute"
                style={{ width: "100%", left: "0%", top: -1 }}
              />
            </div>
          )}
        </div>
        <div className={classnames(classNameEnd, classContainer)}>
          {labelEnd && <label className="col-form-label">{labelEnd}</label>}
          <DatePicker
            disabled={disabled}
            ref={this.endRef}
            disabledDate={this.disabledEndDate}
            format={settingSystem.dateFormat}
            size={size || "large"}
            value={input.value[1]}
            placeholder={endPlaceholder || "To"}
            onChange={this.handleChangeEnd}
            // onChange={(v)=>input.onChange([input.value[0], v && v.endOf('day')])}
            open={endOpen}
            onOpenChange={this.handleEndOpenChange}
            style={{ width: "100%" }}
            className={endClass}
          />
          {(invalid && !input.value[1] && submitFailed) && (
            <div className="position-relative">
              <hr
                className="border-danger m-0 position-absolute"
                style={{ width: "100%", left: "0%", top: -1 }}
              />
            </div>
          )}
        </div>
        {(error && submitFailed) && (
          <div className="col-12">
            <span className="invalid-feedback">{error}</span>
          </div>
        )}
      </Fragment>
    );
  }
}

export default FieldRangeDate;
