import styled, { createGlobalStyle } from "styled-components";
import PageContainer from "components/PageContainer";
import {
  BASE_BLACK,
  BASE_WHITE,
  ERROR_100,
  ERROR_600,
  GRAY_100,
  GRAY_200,
  GRAY_300,
  GRAY_400,
  GRAY_50,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_500,
  PRIMARY_600,
  SHADOW_LG_0_03,
  SHADOW_LG_0_08,
  SHADOW_XS,
  SUCCESS_100,
  SUCCESS_500,
  SUCCESS_600
} from "components/colors";

export const Wrapper = styled(PageContainer)`
  svg {
    &.plusicon {
      width: 20px;
      height: 20px;
      stroke: ${BASE_WHITE};
      margin-right: 12px;
    }
  }
  table {
    .ant-table-selection-extra {
      display: none;
    }
    svg {
      stroke: ${GRAY_500};
      margin-right: 8px;
    }
    .icon svg {
      margin-right: 0;
    }
  }
  td .tags {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    gap: 4px;
    svg {
      width: 16px;
      height: 16px;
      stroke: ${GRAY_400};
      &.active {
        stroke: ${SUCCESS_500};
      }
    }
  }
  .addnew {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .filter {
    &-section {
      display: flex;
    }
    &-button {
      line-height: 44px;
      height: 44px;
      background: ${BASE_WHITE};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      border-radius: 8px;
      margin-left: 12px;
      padding: 0 18px;
      vertical-align: middle;
      cursor: pointer;
      svg.filter {
        stroke: ${GRAY_700};
        margin: 0;
        margin-right: 8px;
      }
    }
  }
  .ant-tabs-nav {
    margin-top: 0;
  }
  .gmtextbox {
    width: 100%;
    max-width: 400px;
  }
  .ant-tabs.ant-tabs-top {
    .ant-tabs-nav {
      &::before {
        border-bottom: 1px solid ${GRAY_200};
      }
      .ant-tabs-tab {
        font-size: 0;
        margin-right: 20px;
        .ant-tabs-tab-btn {
          padding-bottom: 10px;
          color: ${GRAY_500};
          height: auto;
          padding: 0 4px;
          padding-bottom: 12px;
          font-size: 16px;
        }
        &-active {
          .ant-tabs-tab-btn {
            padding: 0 4px;
            color: ${PRIMARY_500};
            font-weight: 600;
            background-color: unset;
            padding-bottom: 12px;
          }
        }
      }
      .ant-tabs-ink-bar {
        border-bottom: 2px solid ${PRIMARY_500};
      }
    }
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 16px 8px;
  }
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 16px 8px;
  }

  .table table {
    thead th {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      border: none;
      height: 44px;
      vertical-align: middle;
      background-color: ${GRAY_50};
      border-bottom: 1px solid ${GRAY_200};
    }
    tbody td {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      border-bottom: 1px solid ${GRAY_200};
      border-top: none;
      vertical-align: top;
      padding: 16px 8px;
      padding-left: 8px !important;
      padding-right: 8px !important;
      b {
        font-weight: 600;
      }
      .price {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #101828;
      }
      .icon-wrapper {
        width: 40px;
        height: 40px;
        background: ${PRIMARY_100};
        border-radius: 20px;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }
    .icon-wrapper {
      width: 40px;
      height: 40px;
      background: ${PRIMARY_100};
      border-radius: 20px;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .ant-tag,
    .tag {
      height: 22px;
      border: none;
      border-radius: 16px;
      line-height: 150%;
      padding: 2px 8px;
    }
    .shipping {
      display: flex;
      align-items: center;
      gap: 12px;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
      &.secondary span {
        background-color: ${PRIMARY_100};
        stroke: ${PRIMARY_600};
      }
      &.red span {
        background-color: ${ERROR_100};
        stroke: ${ERROR_600};
      }
      &.success span {
        background-color: ${SUCCESS_100};
        stroke: ${SUCCESS_600};
      }
      &.dark span {
        background-color: ${GRAY_700};
        stroke: ${BASE_WHITE};
      }
    }
  }

  .topcta .ctas {
    display: flex;
    flex-direction: row;
    gap: 12px;

    .moreactions {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
`;

export const GlobalStyle = createGlobalStyle`
  .ant-dropdown-arrow {
    display: none;
  }
`;

export const ContextMenuWrapper = styled.div`
  background: ${BASE_WHITE};
  border: 1px solid ${GRAY_200};
  box-shadow: 0px 12px 16px -4px ${SHADOW_LG_0_08},
    0px 4px 6px -2px ${SHADOW_LG_0_03};
  border-radius: 8px;
  padding: 6px;
  a {
    height: 38px;
    border-radius: 6px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    color: ${GRAY_700};
    &:hover {
      background: ${GRAY_100};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: ${GRAY_700};
      &.sync {
        fill: ${BASE_BLACK};
      }
    }
  }
`;

export const FilterWrapper = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker {
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
  }
`;
