import { BASE_WHITE, ERROR_25, ERROR_300, ERROR_500, ERROR_600, ERROR_700, GRAY_300, GRAY_700, GRAY_900, SHADOW_XS } from "components/colors";
import { BREAKPOINTS } from "components/const";
import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  .error-list {
    background: ${ERROR_25};
    border: 1px solid ${ERROR_300};
    border-radius: 12px;
    padding: 16px 18px;
    width: 100%;
    list-style: none;
    li {
      position: relative;
      padding-left: 12px;
      &:before {
        background-color: ${ERROR_600};
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 50%;
        margin-top: -2.5px;
      }
      span {
        display: block;
        &.error {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: ${ERROR_700};
        }
        &.detail {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${ERROR_700};
        }
      }
    }
  }
  .issue-item {
    padding: 32px;
    width: 100%;
    background: ${BASE_WHITE};
    border-radius: 16px;
    margin-top: 16px;
    @media only screen and (min-width: ${BREAKPOINTS.xl}) {
      margin-top: 32px;
    }
  }

  .cta-blk {
    margin-top: 16px;
    @media only screen and (min-width: ${BREAKPOINTS.xl}) {
      margin-top: 32px;
    }
    button {
      svg {
        width: 20px;
        margin-right: 8px;
      }
    }
  }

  .blk-right {
    float: right;
    button {
      margin-left: 24px;
    }
  }
  .title {
    h4 {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: ${GRAY_900};
      display: inline-block;
    }
    .cta-remove {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: ${ERROR_500};
      float: right;
      vertical-align: top;
      cursor: pointer;
    }
  }
  .gap24 {
    gap: 24px;
    flex-wrap: nowrap;
    flex-direction: row;
    .fixed-cell {
      &-50p,
      &-25p {
        display: flex;
      }
      &-50p {
        width: 50%;
      }
      &-25p {
        width: 25%;
      }
    }
  }
  .gmtextbox {
    width: 100%;
  }
  .fz0 {
    font-size: 0;
  }
  textarea {
    resize: none;
    height: 128px;
    padding-top: 12px;
    font-size: 16px;
  }
  .link {
    &2remove,
    &2add {
      margin-left: 8px;
      width: 44px;
      height: 44px;
      padding: 0;
      text-align: center;
      display: inline-block;
      background: ${BASE_WHITE};
      svg {
        width: 20px;
      }
      &.disabled {
        visibility: hidden;
        pointer-events: none;
      }
    }
    &2remove {
      border-color: ${ERROR_700};
      svg {
        stroke: ${ERROR_700};
      }
    }
    &2add {
      border-color: ${GRAY_300};
      svg {
        stroke: ${GRAY_700};
      }
    }
  }
  .t24 {
    margin-top: 24px;
  }
  .link {
    .input-group {
      flex-wrap: nowrap;
    }
    .link-row {
      display: flex;
      width: 100%;
      margin-top: 16px;
    }
  }
  .links label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_700};
    padding: 0;
  }
  .border-danger {
    display: none;
  }
`;
