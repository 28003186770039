import React, { useState, useEffect } from 'react';
import { isInteger } from 'lodash';
import { withRouter } from 'react-router';
import { GMButton } from 'components/Button';
import { getStore } from 'utils/helper';
import { GMModal } from 'components/Modal/styled.modal';

export const CONFIG_USER_LABEL_DONT_SHOW = 'Not2ShowConfigLabelWarningStore';

function RenderConfigUserLabelWarningModal({ profile = null, ...props }) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (
      isInteger(parseInt(profile?.id)) &&
      parseInt(profile?.id) > 0 &&
      ![1004, 3].includes(parseInt(profile?.id)) &&
      parseInt(profile?.primaryId) === 0
    ) {
      setVisible(!!profile?.displayWarningAddress);
      // if (profile?.displayWarningAddress) {
      //   setVisible(true);
      // } else {
      //   setVisible(false);
      // }
    }
  }, [profile?.id]);

  if (getStore(CONFIG_USER_LABEL_DONT_SHOW, profile?.id) || !visible) {
    return null;
  }
  return (
    <GMModal
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      title={<h4 className="text-danger">Warning</h4>}
      className="warning-modal"
      closable={false}
    >
      <p>
        All users <b>MUST</b> fill in the Return Address in the account setting
        with their own address in order to fulfill orders on Gearment site.
        Users who use Gearment’s address as their Return Address may have all
        their current orders put <b>ON HOLD</b> until they edit the Return
        Address or must contact the Support Team to clarify this update.
      </p>
      <p>
        All designs using other parties’ Trademarks or Copyright material
        without the rightful owner’s or party’s permission or license are
        strictly prohibited on Gearment application. Gearment will detect and
        take down any Trademarks or Copyright violation uploads. The account
        uploads such designs may be banned permanently from using our service if
        not taking the immediate action.
      </p>
      <p>
        {/* <Checkbox
          onChange={(v) => {
            setStore(CONFIG_USER_LABEL_DONT_SHOW, !!v?.target?.checked, profile?.id);
          }}
        >
          Don’t show again
        </Checkbox> */}
      </p>
      <div className="ctas">
        <GMButton
          className="btn btn-danger"
          onClick={() => props.history.push('/billing/return-address')}
        >
          Create Return Address
        </GMButton>
      </div>
    </GMModal>
  );
}

export default withRouter(React.memo(RenderConfigUserLabelWarningModal));
