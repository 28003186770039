import React, { useState, useEffect } from "react";
import { Spin, Select } from "antd";
import { getColors } from "apis/ex_products";
import classNames from "classnames";
import { invertHex, pickTextColorBasedOnBgColor } from "utils/helper";
import { GMTag } from "scenes/general.styled";

function SelectMultipleProductsTagColor({
  fields,
  invalid,
  meta: { error, submitFailed },
  size,
  expId = 0,
  classNameContainer = "",
  setColors = null,
  selectedColorIds = [],
  setDefaultColor
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (expId) {
      setLoading(true);
      getColors(expId)
        .then(({ code, data }) => {
          setLoading(false);
          if (code === "success") {
            setOptions(data.optionColors || []);
            if (typeof setColors === 'function' && Array.isArray(data.optionColors) && data.optionColors.length > 0) {
              setColors(data.optionColors);
              setDefaultColor(data?.optionColors?.[0]?.id);
            }
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setOptions([]);
    }
  }, [expId]);

  const tagRender = (props) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const cl = options.find(o => o.id * 1 === 1 * value);
    return (
      <GMTag
        color={"#" + cl?.value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        textColor={pickTextColorBasedOnBgColor("#" + cl?.value)}
        borderColor={"#" + invertHex(cl?.value)}
        style={{ marginRight: 3, marginTop: 3 }}
      >
        {cl?.name}
      </GMTag>
    );
  };
  return (
    <Spin spinning={loading}>
      <div className={classNames("position-relative", classNameContainer)}>
        <Select
          mode="multiple"
          showArrow
          tagRender={tagRender}
          value={selectedColorIds}
          style={{ width: '100%' }}
          options={options.map(i => ({ label: i?.name, value: i?.id }))}
          onSelect={cid => fields.push(cid)}
          onDeselect={cid => fields.remove(selectedColorIds.indexOf(cid))}
        />
        {invalid && (
          <hr
            className="border-danger m-0 position-absolute"
            style={{ width: "90%", left: "5%", top: size === "middle" ? 31 : 39 }}
          />
        )}
        {(error && submitFailed) && (
          <span className="invalid-feedback">{error}</span>
        )}
      </div>
    </Spin>
  );
};
export default React.memo(SelectMultipleProductsTagColor);
