import { put, takeEvery } from 'redux-saga/effects'

import { notification, Modal } from 'antd';
import {replace} from "connected-react-router";
function* alertWatcher(){
  yield takeEvery('*', function* logger({response, error,...props}) {
    if( response ){
      if( response.status && response.message){
        notification['success']({
          message: response.message,
        });
      }else if( !response.status && response.message){
        yield put({type: 'SHARE.ALERT.ERROR', data_response: response})
        notification['error']({
          message: response.message,
        });
        if(response.code === 401){
          yield put(replace('/auth/login'));
        }
      }
      else if(Array.isArray(response) && response[0] && response[0]['code'] === 'auth_failed'){
        yield put(replace('/auth/login'));
        localStorage.removeItem('api_token_key');
      }
    }
    // else if(error){
    //   yield put({type: 'SHARE.ALERT.ERROR', data_error: error})
    //   if(error.message){
    //     if(error?.type === 'modal'){
    //       Modal.error({
    //         title: error.message
    //       });
    //     }else{
    //       notification['error']({
    //         message: error.message,
    //       });
    //     }
    //   }else if(Array.isArray(error) && error[0] && error[0]['code'] === 'auth_failed'){
    //     yield put(replace('/auth/login'));
    //   }
    // }
  })
}

export default alertWatcher