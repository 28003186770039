import React from "react";

import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import FieldSelect from "utils/fields/FieldSelect";
import { options_delivery, options_active_webhooks } from "utils/options";
import { renderInputText } from "utils/Form";
import validate from "./validate";
import { GMButton } from "components/Button";
const FormCreateWebhooks = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <div className="form-group">
          <h5 className="font-weight-bold">
            Apply configuration gift message for store
          </h5>
          <Field
            name="name_webhooks"
            component={renderInputText}
            maxLength={"50"}
          />
        </div>
        <div className="form-group">
          <h5 className="font-weight-bold">
            Delivery URL
          </h5>
          <Field
            name="url_webhooks"
            component={renderInputText}
            maxLength={"50"}
          />
        </div>
        <div className="form-group">
          <h5 className="font-weight-bold">Topic</h5>
          <Field
            name="topic_webhooks"
            component={FieldSelect}
            options={options_delivery}
            size="large"
            defaultValue={options_delivery[0]["value"]}
            className="w-100 line-height-50px"
          />
        </div>
        <div className="form-group">
          <h5 className="font-weight-bold">Status</h5>
          <div className="d-flex align-items-center">
            <Field
              name="status_webhooks"
              component={FieldSelect}
              options={options_active_webhooks}
              size="large"
              defaultValue={options_active_webhooks[0]["value"]}
              className="w-100 line-height-50px"
            />
          </div>
        </div>
      </div>
      <div className="text-center d-flex align-items-center justify-content-end">
        <GMButton>
          Save gift message
        </GMButton>
      </div>
    </form>
  );
};

FormCreateWebhooks.propTypes = {};

const selector = formValueSelector("FormCreateWebhooks");
const mapStateToProps = (state) => ({
  topic: selector(state, "topic") || "",
  formCreateWebhooks: state.form.formCreateWebhooks,
  name_webhooks: selector(state, "name_webhooks") || "",
  url_webhooks: selector(state, "url_webhooks") || "",
  topic_webhooks:
    selector(state, "topic_webhooks") || options_delivery[0]["value"],
  status_webhooks:
    selector(state, "status_webhooks") || options_active_webhooks[0]["value"],
});
const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change("FormCreateWebhooks", field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "FormCreateWebhooks",
    enableReinitialize: true,
    validate,
  })(FormCreateWebhooks)
);
