import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Spin } from "antd";
import { createDataFeedAmazon } from "./actions";
import BackToIcon from "../../../../images/chevron-down.svg";
// import { getDropdownCategory } from '../Categories/actions'
// import { getList } from '../Options/scenes/ListStaff/actions'
// import { getSuggestions } from '../../../Tags/actions'
import Form from "./components/form";
import feed_amazon from "assets/images/feed_amazon.png";
import arrow from "assets/images/arrow.png";
import amazon_icon from "assets/images/amazon_icon.png";
import { Wrapper } from "./styled";
class Amazon extends Component {
  componentDidMount() {
    // this.props.getDropdownCategory()
    // this.props.getListOptionGroups()
  }

  // getTagsSuggestions =(keyword) => {
  //     if(keyword && keyword.length > 2){
  //         this.props.getSuggestions(keyword)
  //     }
  // }

  handleSubmit = (values) => {
    this.props.createDataFeedAmazon({
      ...values,
      default: values.default ? 1 : 0,
      status: values.status ? 1 : 0,
      custom: values.custom || [],
    });
  };

  render() {
    const { loading, pageName } = this.props;
    return (
      <Wrapper
        pageName={pageName}
      >
        <Spin spinning={loading}>
          <div className="text-center mb-3">
            <img src={feed_amazon} height="90" alt="amazon-icon" />
            <img src={arrow} width="40" className="mx-4" alt="arrow-icon" />
            <img src={amazon_icon} height="70" alt="amazon-icon" />
          </div>
          <Form
            onSubmit={this.handleSubmit}
            initialValues={{
              default: 0,
              status: 1,
              amzFulfillmentLatency: 10,
              amzQuantity: 100,
              custom: [
                {
                  name: "",
                  key: "",
                  value: "",
                },
              ],
            }}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    // category_share: state.data_feed.category.share,
    // tag_share: state.tag.share,
    // option_groups: state.data_feed.option_group.list,
    loading: state.data_feed.amazon.loading,
  }),
  (dispatch) => ({
    createDataFeedAmazon: (data) => {
      dispatch(createDataFeedAmazon(data));
    },
    // getDropdownCategory: ()=>{
    //     dispatch(getDropdownCategory())
    // },
    // getSuggestions: (keyword)=>{
    //     dispatch(getSuggestions(keyword))
    // },
    // getListOptionGroups: ()=>{
    //     dispatch(getList())
    // }
  })
)(Amazon);
