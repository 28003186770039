import React, { useState, useEffect } from 'react'
import { Select } from 'antd';
import { getList } from 'apis/countries'

const { Option } = Select;

export default ({ input, mode="default", invalid, error, size, onChoose, inputProps={}}) => {
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        getList().then(({code, data}) => {
            setLoading(false)
            if(code === 'success'){
                setOptions(data.map(item=>({...item, value: item.id, label: item.countryName})))
            }
        })
        .catch(error => { 
            setLoading(false)
        })
    }, [])
    const onSelect = data => {
      if(mode === 'default' && typeof onChoose === 'function'){
        onChoose(data, options.find(item=> parseInt(item.value) === parseInt(data)))
      }
      input.onChange(data === undefined ? (mode === 'default' ? null : []) : data)
    };
    const value = mode === 'default' ? input.value.toString() : input.value
    return (
        <div className="position-relative">
            <Select
                loading={loading}
                showSearch
                mode={mode}
                value={value || (mode === 'default' ? undefined : [])}
                style={{ width: '100%' }}
                size={size || 'large'}
                onChange={onSelect}
                optionFilterProp="children"
                {...inputProps}
            >
                {options.map(item=>(
                  <Option key={item.value}>{item.label}</Option>
                ))}
            </Select>
            {loading && <span className="text-primary">Loading data ...</span>}
            {error && <span className="invalid-feedback">{error}</span>}
      </div>
    )
}
