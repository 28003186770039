import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { getDetail, update } from './actions';
import { getListExproduct } from '../Create/actions';
import Form from './components/form';
import { Wrapper } from './styled';
import { postfix } from 'utils/options';
import GMHelmet from 'components/GMHelmet';

class Edit extends Component {
  componentDidMount() {
    this.props.getDetail(this.props.match.params.id);
    this.props.getListExproduct({ action: 'mockup'});
  }

  handleSubmit = ({ id, name, status, expId }) => {
    this.props.update(id, {
      status: status ? 1 : 0,
      expId,
      name,
    });
  };

  render() {
    const { updating, detail, exproducts, pageName } = this.props;
    return (
      <Wrapper
        pageName={pageName}
        breadcrumDetails={{
          [detail.data.id]: detail.data.id && `Mockup #${detail.data.id || ''}`,
        }}
      >
        {detail.data.id ? (
          <GMHelmet
            overrideTitle={`Edit mockup #${detail.data.id || ''}` + postfix}
          />
        ) : null}
        <div>
          <Spin spinning={updating || detail.loading}>
            <div>
              <Form
                initialValues={{
                  ...detail.data,
                  expId: `${detail?.data?.expId || 0}`,
                  status: parseInt(detail?.data?.status) === 1,
                }}
                onSubmit={this.handleSubmit}
                exproducts={exproducts}
              />
            </div>
          </Spin>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    exproducts: state.mockup.create.exproducts,
    updating: state.mockup.edit.updating,
    detail: state.mockup.edit.detail,
  }),
  (dispatch) => ({
    update: (id, params) => {
      dispatch(update(id, params));
    },
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
    getListExproduct: (params) => {
      dispatch(getListExproduct(params));
    },
  })
)(Edit);
