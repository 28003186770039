import styled from 'styled-components';

export const ButtonGM = styled.div`
  border: ${(p) => p.border};
  border-radius: ${(p) => p.borderRadius};
  background-color: ${(p) => p.backgroundColor};
  width: ${(p) => p.width};
  color: ${(p) => p.textColor};
  padding: ${(p) => p.padding};
  display: ${(p) => p.display};
  justify-content: ${(p) => p.justifyContent};
  align-items: ${(p) => p.alignItems};
  cursor: pointer
`;
