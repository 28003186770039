export const actionType = {
    UPDATE: {
        REQUEST: 'USERS.RESET_PASSWORD.UPDATE.REQUEST',
        SUCCESS: 'USERS.RESET_PASSWORD.UPDATE.SUCCESS',
        ERROR: 'USERS.RESET_PASSWORD.UPDATE.ERROR',
        CLEAR_MESSAGE: 'USERS.RESET_PASSWORD.CLEAR_MESSAGE',
    },
}
export function clearMessage() {
  return {
    type: actionType.UPDATE.CLEAR_MESSAGE,
    payload: {
        serverErrorInfo: null,
        serverSuccessInfo: null,
    },
  };
}
export const update = params => ({
    type: actionType.UPDATE.REQUEST,
    params
})