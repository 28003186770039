export const actionType = {
    FIND_ORDER_TO_DUPLICATE_FBA_ORDER: {
        REQUEST: 'ORDER.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.REQUEST',
        SUCCESS: 'ORDER.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.SUCCESS',
        ERROR: 'ORDER.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.ERROR',
    },
    DUPLICATE_FBA_ORDER: {
        REQUEST: 'ORDER.DUPLICATE_FBA.REQUEST',
        SUCCESS: 'ORDER.DUPLICATE_FBA.SUCCESS',
        ERROR: 'ORDER.DUPLICATE_FBA.ERROR',
    }
};

    
export function getDetail(id) {
    return {
        type: actionType.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.REQUEST,
        id
    }
}

export function duplicateFBAOrder(data){
    return {
        type: actionType.DUPLICATE_FBA_ORDER.REQUEST,
        data
    }
}



    