import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { listCountry } from 'scenes/Users/scenes/Profile/actions';
import { GMTextbox } from 'components/InputFields';
import { GlobalStyle, Wrapper } from './styled';
import { GMButton } from 'components/Button';
import { Signature } from 'utils/Form';
import validate from './validate';
import { Modal } from 'antd';
import getMomentInstance from 'utils/moment';
import { ReactComponent as Eye } from 'images/icons/eye.svg';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      enableForm: true,
    };
  }
  componentDidMount() {
    this.props.listCountry();
  }

  handleOk = () => {
    this.setState({ isOpenModal: false });
  };

  handleCancel = () => {
    this.setState({ isOpenModal: false });
  };
  openModal = (event) => {
    event.preventDefault();
    this.setState({ isOpenModal: true });
  };
  enableForm = () => {
    this.setState({ enableForm: false });
  };
  render() {
    const {
      handleSubmit,
      profile,
      getTemplate,
      getViewIndemnification,
    } = this.props;

    return (
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <div className="desc">
            <div>
              This Agreement is designed to outline the terms and conditions
              under which you agree to indemnify and hold harmless Gearment Inc.
              against any claims related to copyright and intellectual property
              rights arising from the use of your designs, artworks, or any
              other intellectual property in connection with Gearment Inc.'s
              print-on-demand services.
            </div>
            <div>
              {getViewIndemnification?.data?.createdAt ? (
                <div className="my-2">
                  <b>
                    You have completed, signed and submitted this document on{' '}
                    {getMomentInstance(
                      getViewIndemnification?.data?.createdAt
                    ).format('MM/DD/YYYY')}
                  </b>
                  . You can click{' '}
                  <a
                    href={getViewIndemnification?.data.pdfLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <u>here</u>
                  </a>{' '}
                  to view it.
                </div>
              ) : (
                <div className="my-2">
                  Please complete, sign and submit this document as proof of
                  your agreement{' '}
                  <a onClick={this.openModal} href="">
                    <u>view template</u>
                  </a>
                  .
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="infor">
              <span className="item">Date</span>
              <GMTextbox
                className="input"
                name="createdAt"
                defaultValue={`${getMomentInstance(
                  new Date(),
                  0,
                  false,
                  true
                )} (UTC/GMT-8)`}
                disabled
              />
            </div>
            <div className="infor">
              <span className="item">Seller's name</span>
              <GMTextbox
                maxLength={255}
                name="name"
                className="input"
                placeholder="Enter your name"
                showError
                disabled={
                  getViewIndemnification?.data?.createdAt ? true : false
                }
              />
            </div>
            <div className="infor">
              <span className="item">Seller's address</span>
              <GMTextbox
                maxLength={255}
                name="address"
                showError
                placeholder="Enter your address"
                className="input"
                disabled={
                  getViewIndemnification?.data?.createdAt ? true : false
                }
              />
            </div>
            <div className="infor">
              <span className="item">Authorized signature</span>
              <GMTextbox
                maxLength={255}
                name="signatureUrl"
                component={Signature}
                className="input"
                profile={profile}
                showError
                placeholder="Provide your authorized signature"
                enableForm={
                  getViewIndemnification?.data?.createdAt ? true : false
                }
              />
            </div>
            <div className="infor">
              <span className="item">Print name & Title</span>
              <GMTextbox
                maxLength={255}
                name="printNameAndTitle"
                className="input"
                placeholder="Enter your print name and title"
                showError
                disabled={
                  getViewIndemnification?.data?.createdAt ? true : false
                }
              />
            </div>
          </div>
          {/* {getViewIndemnification?.data?.createdAt ? (
            <div>
              {this.state.enableForm === true && (
                <div className="d-flex justify-content-end bg-white pt-3 text-white">
                  <GMButton
                    color={'SecondaryGray'}
                    onClick={this.enableForm}
                    type="button"
                  >
                    Re-submit
                  </GMButton>
                </div>
              )}
              {this.state.enableForm === false && (
                <div className="d-flex justify-content-end bg-white pt-3 text-white">
                  <GMButton>Preview</GMButton>
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-end bg-white pt-3 text-white">
              <GMButton>Preview</GMButton>
            </div>
          )} */}
          {getViewIndemnification?.data?.createdAt ? (
            <div></div>
          ) : (
            <div className="d-flex justify-content-start bg-white pt-3 text-white">
              <GMButton>
                <Eye className="mr-2" />
                Preview
              </GMButton>
            </div>
          )}
        </form>
        <Modal
          header={null}
          centered
          destroyOnClose={true}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          visible={this.state.isOpenModal}
          width={900}
          okText={'Close'}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div>
            {getTemplate?.length !== 0 && (
              <span
                dangerouslySetInnerHTML={{
                  __html: getTemplate || '',
                }}
              ></span>
            )}
          </div>
        </Modal>
        <GlobalStyle />
      </Wrapper>
    );
  }
}

const selector = formValueSelector('FormIndemnification');
const FormIndemnification = reduxForm({
  form: 'FormIndemnification',
  enableReinitialize: true,
  validate,
})(index);
export default connect(
  (state) => ({
    countries: state.user.profile.listCountry,
    sign: selector(state, 'sign') || '',
  }),
  (dispatch) => ({
    listCountry: () => {
      dispatch(listCountry());
    },
  })
)(FormIndemnification);
