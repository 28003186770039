import axios from 'utils/request';

const prefix = '/feed-forms';

export const getList = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}`,
  });
};

export const createAmazon = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/amazon`,
    is_json_content_type: true,
  });
};

export const createGoogle = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/google`,
  });
};

export const updateAmazon = (id, data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/${id}/amazon`,
    is_json_content_type: true,
  });
};

export const updateGoogle = (id, data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/${id}/google`,
  });
};

export const create = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}`,
  });
};

export const getDetail = (id) => {
  return axios({
    method: 'GET',
    // params,
    url: `${prefix}/${id}`,
    is_json_content_type: true,
  });
};

export const update = (id, data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/${id}`,
  });
};

export const destroy = (id) => {
  return axios({
    method: 'POST',
    url: `${prefix}/${id}/delete`,
  });
};
