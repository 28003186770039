import React from 'react';

import { ReactComponent as ClearIcon } from 'images/icons/x-close.svg';
import { ReactComponent as SearchIcon } from 'images/icons/search-md.svg';
import { FilterWrapper } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { GMTextbox } from 'components/InputFields';
import { change, Field, reduxForm, submit } from 'redux-form';
import { debounce } from 'lodash';
import { renderDropdownDateRange } from 'utils/Form';
import { useCallback } from 'react';

const FilterForm = ({
  placeholder,
  handleSubmit,
  maxLength2Search,
  showFilter = false,
  afterAddon = null,
  customInputs = null,
  filterCustomInputs = null,
  tooltip = null,
}) => {
  const searchValue =
    useSelector((state) => state?.form?.paymentFormFilter?.values?.search) ||
    '';
  const dispatch = useDispatch();
  const debounceAutoSubmit = useCallback(debounce((evt, value = '') => {
    if (value.length >= (maxLength2Search || 3) || value.length === 0) {
      dispatch(submit('paymentFormFilter'));
    }
  }, 700), []);

  return (
    <FilterWrapper>
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          handleSubmit(evt);
          return false;
        }}
        className="d-flex flex-row"
      >
        <GMTextbox
          name="search"
          className="id-search"
          placeholder={placeholder}
          maxLength={256}
          iconBefore={<SearchIcon />}
          iconAfter={
            <>
              {searchValue.length > 0 ? (
                <ClearIcon
                  className="clear"
                  onClick={() => {
                    dispatch(change('paymentFormFilter', 'search', null));
                    debounceAutoSubmit();
                  }}
                />
              ) : null}
              {tooltip}
            </>
          }
          onChange={debounceAutoSubmit}
        />
        {showFilter ? (
          <>
            <div className="ml-2">
              <Field
                name="date_range"
                className="date-range"
                component={renderDropdownDateRange}
                allowClear={true}
                allowEmpty={true}
                onChange={() => {
                  setTimeout(() => {
                    dispatch(submit('paymentFormFilter'));
                  }, 0);
                }}
              />
            </div>
            {filterCustomInputs}
          </>
        ) : null}
        {customInputs}
        {afterAddon}
      </form>
    </FilterWrapper>
  );
};

export default reduxForm({
  form: 'paymentFormFilter',
  enableReinitialize: true,
})(FilterForm);
