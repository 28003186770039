import {
  BASE_WHITE,
  GRAY_300,
  PRIMARY_25,
  PRIMARY_500,
} from "components/colors";
import styled from "styled-components";
import Slick from "react-slick";

export const CounterWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 48px;
  display: flex;
  justify-content: center;
  .timer {
    &-container {
      display: flex;
      justify-content: center;
      align-content: center;
      text-transform: uppercase;
      color: #fff;
    }
    &-block, &-separator {
      
    }
    &-separator {
      height: 48px;
      gap: 6px;
      display: flex;
      gap: 6px;
      padding: 0 16px;
      flex-direction: column;
      justify-content: center;
      &::before, &::after {
        background-color: ${BASE_WHITE};
        width: 6px;
        height: 6px;
        border-radius: 100px;
        display: block;
        content: " ";
      }
    }
    &-block {
      gap: 10px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      line-height: 24px;
      &-numbers {
        display: flex;
        gap: 6px;
      }
    }
    &-number {
      width: 40px;
      height: 48px;
      border-radius: 8px;
      background-color: #267EE1;
      font-size: 30px;
      font-weight: 600;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const Slider = styled(Slick)`
  background-color: ${PRIMARY_25};
  .item {
    padding-top: 80px;
    padding-bottom: 40px;
    &-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &_description {
      max-width: 448px;
      width: 100%;
      text-align: center;
      h4 {
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 8px;
        color: #101828;
      }
      p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #475467;
      }
    }
  }
  &.slick-slider {
    width: 100%;
    height: 100%;
    .slick-dots {
      width: 100%;
      clear: both;
      font-size: 0;
      text-align: center;
      li {
        display: inline-block;
        margin: 0 6px;
        button {
          background-color: ${GRAY_300};
          width: 8px;
          height: 8px;
          border-radius: 99px;
          font-size: 0;
          &:before {
            display: none;
          }
        }
        &.slick-active button {
          background-color: ${PRIMARY_500};
        }
      }
    }
  }
`;
