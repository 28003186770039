import { combineReducers } from "redux";
import profile from "./scenes/Profile/reducer";
import change_password from "./scenes/ChangePassword/reducer";
import change_email from "./scenes/ChangeEmail/reducer";
import reset_password from "./scenes/ResetPassword/reducer";

const reducer = combineReducers({
  profile,
  change_password,
  change_email,
  reset_password
});

export default reducer;
