import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { dummyFunction } from 'utils/helper';
import { GMButton } from 'components/Button';
import { GlobalStyle } from './styled';
import classNames from 'classnames';

export default ({
  handleOk = dummyFunction,
  handleCancel = dummyFunction,
  isOpen = true,
  title = '',
  customContent = undefined,
  children,
  ctas = undefined,
  customCtaText = {},
}) => {
  return (
    <>
      <Modal
        centered
        title={null}
        destroyOnClose={true}
        className={classNames('gm-confirm-dialog', {
          'custom-content': customContent || children,
        })}
        visible={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {typeof (customContent || children) === 'string' ? (
          customContent || children
        ) : (
          <>
            {title && <h4 className="title">{title}</h4>}
            <div className="content">{customContent || children}</div>
          </>
        )}
        <div className="footer">
          {ctas || (
            <>
              <GMButton color="SecondaryGray" onClick={handleCancel}>
                {customCtaText.cancel || 'Cancel'}
              </GMButton>
              <GMButton onClick={handleOk}>{customCtaText.ok || 'Ok'}</GMButton>
            </>
          )}
        </div>
      </Modal>
      <GlobalStyle />
    </>
  );
};
