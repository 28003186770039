import { BASE_WHITE, GRAY_50 } from "components/colors";
import styled from "styled-components";

export const IconWrapper = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  border: 1px solid ${GRAY_50};
  background: ${BASE_WHITE};
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
  }
`;
