import {
  BASE_WHITE,
  GRAY_200,
  GRAY_300,
  GRAY_600,
  GRAY_700,
  SHADOW_XS,
} from 'components/colors';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
.modal-after-submit{
  .ant-modal-body{
  flex-direction: column;
  display: flex;
  justify-content: center; 
  align-items: center;
  .done{svg{
    width:120px;
    height:120px;
  }}
}


}


.title{
  font-weight: 600;
  font-size: 16px;
}
.content{
  font-weight: 400;
  font-size: 14px;
}

    .gm-confirm-dialog.ant-modal {
      /* display: flex;
      justify-content: center; */
      .ant-modal-content {
        width: 420px;
        background: ${BASE_WHITE};
        border: 1px solid ${GRAY_300};
        border-radius: 12px;
        overflow: hidden;
        .ant-modal-close {
          display: none;
        }
        .footer {
          display: flex;
          justify-content: center;
          gap: 12px;
        
        }
      }
  
      .ant-modal-content .ant-modal-body {
        padding: 16px;
        .ant-spin-nested-loading > div > .ant-spin {
          position: absolute;
        }
      }
    }
  `;
export const ConfirmModalInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  .icon .wrapper {
    display: flex;
    width: 40px;
    height: 40px;
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_200};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    svg {
      stroke: ${GRAY_700};
    }
  }
  h4.header {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_700};
    margin-bottom: 4px;
  }
  .msg {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_600};
  }
  .content {
    margin-bottom: 12px;
  }
`;
