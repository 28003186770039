import React, { Component } from 'react';

import { Menu, Dropdown, Button, DatePicker } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import getMomentInstance, { getMomentInstance2 } from '../moment';
import { change } from 'redux-form';
import moment from 'moment';

const { RangePicker } = DatePicker;

const options = [
  {
    text: 'Today',
    start: getMomentInstance(null, 0, false, false, ['day']),
    end: getMomentInstance(null, 0, false, false, null, ['day']),
  },
  {
    text: 'Last 7 Days',
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ['day'],
      null,
      null,
      null,
      6,
      'day'
    ),
    end: getMomentInstance(null, 0, false, false, null, ['day']),
  },
  {
    text: 'Last 14 Days',
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ['day'],
      null,
      null,
      null,
      13,
      'day'
    ),
    end: getMomentInstance(null, 0, false, false, null, ['day']),
  },
  {
    text: 'Last 30 Days',
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ['day'],
      null,
      null,
      null,
      29,
      'day'
    ),
    end: getMomentInstance(null, 0, false, false, null, ['day']),
  },
  {
    text: 'Last 45 Days',
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ['day'],
      null,
      null,
      null,
      44,
      'day'
    ),
    end: getMomentInstance(null, 0, false, false, null, ['day']),
  },
  {
    text: 'Last 60 Days',
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ['day'],
      null,
      null,
      null,
      59,
      'day'
    ),
    end: getMomentInstance(null, 0, false, false, null, ['day']),
  },
];
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      dates: [],
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleClickDateOption = (dates) => {
    this.setState({ dates: dates });
    const {
      input: { name, onChange },
      meta: { dispatch, form },
    } = this.props;
    dispatch(change(form, name, dates));
    this.toggle();
    if (typeof onChange === 'function') {
      onChange(dates);
    }
  };

  disabledDate = (current) => {
    const range = 60 - 1;
    const currentDate = getMomentInstance2({
      instance: moment(),
      start: ['day'],
    });
    const isCurrentDate =
      current && current.startOf('day').isSameOrBefore(currentDate);
    let inRange = true;
    if (this.state.dates?.[0] && !this.state.dates?.[1]) {
      // from
      inRange =
        inRange &&
        moment(this.state.dates[0]).add(range, 'days').isSameOrAfter(current);
    }
    if (!this.state.dates?.[0] && this.state.dates?.[1]) {
      // to
      inRange =
        inRange &&
        moment(this.state.dates[1])
          .subtract(range, 'days')
          .isSameOrBefore(current);
    }
    return !(isCurrentDate && inRange);
  };

  render() {
    const {
      input: { value },
      format = 'YYYY/MM/DD',
      toText = ' to ',
      allowClear = true,
      allowEmpty = true,
    } = this.props;
    const { dropdownOpen } = this.state;

    const startDateSelected = value ? value[0] : null;
    const endDateSelected = value ? value[1] : null;
    const dateRangeText =
      (startDateSelected ? startDateSelected.format(format) : 'Select date') +
      (startDateSelected && startDateSelected ? toText : '') +
      (endDateSelected ? endDateSelected.format(format) : '');
    const optionSelected = options.find(
      ({ start, end }) =>
        start.isSame(startDateSelected, 'day') &&
        end.isSame(endDateSelected, 'day')
    );

    const menu = (
      <Menu selectedKeys={optionSelected ? [optionSelected.text] : ['custom']}>
        {options.map(({ text, start, end }) => {
          return (
            <Menu.Item
              onClick={() => this.handleClickDateOption([start, end])}
              key={text}
            >
              {text}
            </Menu.Item>
          );
        })}
        <Menu.Divider />
        <Menu.Item key="custom">
          <RangePicker
            allowClear={allowClear}
            allowEmpty={allowEmpty}
            value={value}
            onChange={(dates) => this.handleClickDateOption(dates || [])}
            onCalendarChange={(dates) => {
              this.setState({ dates });
            }}
            disabledDate={this.disabledDate}
            onOpenChange={(state) => {
              const {
                input: { name, value },
                meta: { dispatch, form },
              } = this.props;
              if (state) {
                this.bkDates = value;
                this.setState({ dates: [] });
                dispatch(change(form, name, []));
              } else if (!this.state.dates?.[0] && !this.state.dates?.[1]) {
                dispatch(change(form, name, this.bkDates));
              }
            }}
          />
        </Menu.Item>
      </Menu>
    );

    return (
      <div className={this.props.className}>
        {dropdownOpen && (
          <div
            className="position-fixed"
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent',
            }}
            onClick={() => this.setState({ dropdownOpen: false })}
          />
        )}
        <Dropdown visible={dropdownOpen} overlay={menu}>
          <Button
            block
            onClick={this.toggle}
            icon={<CalendarOutlined />}
            style={this.props.noStyle ? undefined : { color: '#667085', fontSize: '16px' }}
          >
            {dateRangeText}
          </Button>
        </Dropdown>
      </div>
    );
  }
}
index.propTypes = {};
export default index;
