import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import { authorization } from "apis/general_settings";
import { updateInvoiceConfig, updateReturnAddress } from "apis/customer_config";
import { getDefaultGateway } from "apis/payment_menthod";
function* defaultGatewaySettingSaga(action) {
  try {
    const response = yield call(getDefaultGateway);
    if (response.code === "success") {
      yield all([put({ type: TYPE.DEFAULT_GATEWAY_SET.SUCCESS, response })]);
    } else {
      yield put({ type: TYPE.DEFAULT_GATEWAY_SET.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.DEFAULT_GATEWAY_SET.ERROR, error })]);
  }
}

function* authorizationSaga() {
  try {
    const response = yield call(authorization);
    if (response.code === "success") {
      yield put({ type: TYPE.AUTHORIZATION.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.AUTHORIZATION.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.AUTHORIZATION.ERROR, error })]);
  }
}
function* updateInvoiceConfigsSaga(action) {
  try {
    const { dataToUpdate } = action;
    const response = yield call(updateInvoiceConfig, dataToUpdate);

    if (response.code === "success") {
      yield all([put({ type: TYPE.UPDATE_INVOICE_CONFIG.SUCCESS, ...response })]);
    } else {
      yield put({ type: TYPE.UPDATE_INVOICE_CONFIG.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.UPDATE_INVOICE_CONFIG.ERROR, error })]);
  }
}

function* updateReturnAddressSaga(action) {
  try {
    const { dataToUpdateReturnAddress } = action;
    const response = yield call(updateReturnAddress, dataToUpdateReturnAddress);

    if (response.code === "success") {
      yield all([put({ type: TYPE.UPDATE_RETURN_ADDRESS.SUCCESS, ...response })]);
    } else {
      yield put({ type: TYPE.UPDATE_RETURN_ADDRESS.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.UPDATE_RETURN_ADDRESS.ERROR, error })]);
  }
}

function* getSettingListWatcher() {
  yield takeLatest(TYPE.DEFAULT_GATEWAY_SET.REQUEST, defaultGatewaySettingSaga);
  yield takeLatest(
    TYPE.UPDATE_INVOICE_CONFIG.REQUEST,
    updateInvoiceConfigsSaga
  );
  yield takeLatest(TYPE.UPDATE_RETURN_ADDRESS.REQUEST, updateReturnAddressSaga);
  yield takeLatest(TYPE.AUTHORIZATION.REQUEST, authorizationSaga);
}

export default getSettingListWatcher;
