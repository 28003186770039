import React, { Component} from "react";
import { Upload, Spin } from "antd";
import { PictureFilled, CloseOutlined } from "@ant-design/icons";
import { notification } from "antd";
import axios from "axios";
import { getPresignedUrlS3 } from "apis/image_design";
import {
  createFileName,
  createFolderName,
  getSizeImage,
  resizeImage,
} from "../helper";
import { S3_CDN } from "web.config";
import { uploading_message } from "../options";
import getMomentInstance from "../moment";
class renderImageAntS3V2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      currStore = 0,
      changeField2 = null,
      changeField3 = null,
      changeField4 = null,
      data = {},
      imgWidth = "100px",
      children,
      paramName = "file",
      prefix = "",
      url,
      iconSize,
      multiple,
      width,
      height,
      typeUpload = "avatar",
      meta: { error, submitFailed},
      hideResize,
      input,
      showUploadList,
      cusId = 0,
      prefixName = "artwork",
      customPrefix = "tmp",
      showRemoveIcon = false,
      handleOnRemove = null,
    } = this.props;
    const props = {
      accept: ".png",
      listType: "picture-card",
      showUploadList: showUploadList,
      name: paramName,
      multiple: multiple ? true : false,
      action: url,
      headers: {
        // common: {
        Authorization: `Bearer ${localStorage.getItem("api_token_key")}`,
        storeid: currStore,
        // }
      },
      data,
      beforeUpload: (file) => {
        if (file.type !== "image/png") {
          notification.warn({
            className: "notic1",
            message: `${file.name} is not a png file.`,
          });
        }
        if (file.size >2e8) {
          notification.warn({
            className: "notic1",
            message: `${file.name} is larger than 200MB.`,
          });
        }
        return file.type === "image/png" && file.size <=2e8;
      },
      customRequest: async ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials,
      }) => {
        const folderName = createFolderName(cusId);
        const extension = file.name.split(".").pop();
        const fileName = createFileName(extension || "png", prefixName, cusId);
        const fileType = `image/${extension}`;
        const keyToCreatePresignedUrl = `${customPrefix}/${folderName}/${getMomentInstance(
          null,
          0,
          false,
          true
        )}/${fileName}`;
        const response = await getPresignedUrlS3(
          keyToCreatePresignedUrl,
          currStore
        );
        if (response && response.code === "success") {
          axios
            .put(response.data.url, file, {
              headers: {
                "Content-Type": fileType,
              },
            })
            .then((response) => {
              if (parseInt(response.status ) === 200) {
                onSuccess(
                  {
                    code: "success",
                    url: `${S3_CDN}/${keyToCreatePresignedUrl}`,
                    folderName: folderName,
                    fileName: fileName,
                    fileType: fileType,
                    extension: extension,
                  },
                  file
                );
              } else {
                onError();
              }
            });
        }
      },
      onChange: (info) => {
        const { status, response, originFileObj } = info.file;
        if (status === "uploading") {
          this.setState({ loading: true });
        }
        if (status === "done" && response.code === "success") {
          getSizeImage(response.url, async (width, height) => {
            const resizedImage = await resizeImage(
              originFileObj,
              650,
              Math.round((650 * height) / width),
              "file",
              response.extension.toUpperCase()
            );
            const keyToCreatePresignedUrl2 = `${customPrefix}/${
              response.folderName
            }/${getMomentInstance(null, 0, false, true)}/thumbnail/${
              response.fileName
            }`;
            const response2 = await getPresignedUrlS3(
              keyToCreatePresignedUrl2,
              currStore
            );
            if (response2 && response2.code === "success") {
              axios
                .put(response2.data.url, resizedImage, {
                  headers: {
                    "Content-Type": response.fileType,
                  },
                })
                .then((response3) => {
                  if (parseInt(response3.status) === 200) {
                    notification.success({
                      className: "notic2",
                      message: `${info.file.name} file uploaded successfully.`,
                    });
                    input.onChange(`${S3_CDN}/${keyToCreatePresignedUrl2}`);
                    if (
                      typeUpload === "upload-artwork" &&
                      changeField2 &&
                      changeField3 &&
                      changeField4
                    ) {
                      getSizeImage(
                        `${S3_CDN}/${keyToCreatePresignedUrl2}`,
                        (width2, height2) => {
                          changeField2({
                            w: width2,
                            h: height2,
                          });
                          changeField3(0);
                          changeField4(
                            {
                              w: width2,
                              h: height2,
                            },
                            `${S3_CDN}/${keyToCreatePresignedUrl2}`
                          );
                        }
                      );
                    }
                  } else {
                    notification.error({
                      className: "notic1",
                      message: `${info.file.name} file upload failed.`,
                    });
                  }
                  this.setState({ loading: false });
                });
            }
          });
        } else if (status === "error") {
          notification.error({
            className: "notic1",
            message: `${info.file.name} file upload failed.`,
          });
          // message.error(`${info.file.name} file upload failed.`);
          this.setState({ loading: false });
        }
      },
    };
    return (
      <div className="w-100 upload-w-100">
        <Upload {...props}>
          <Spin spinning={this.state.loading} tip={uploading_message}>
            {input.value ? (
              <div>
                <img src={prefix + input.value} style={{ width: imgWidth }} alt=""/>
                {showRemoveIcon && handleOnRemove && (
                  <div
                    onClick={(event) => {
                      event.preventDefault();
                      handleOnRemove();
                    }}
                    style={{ fontSize: 20, color: "red" }}
                  >
                    <CloseOutlined />
                  </div>
                )}
              </div>
            ) : (
              <PictureFilled
                style={{
                  fontSize: iconSize || 80,
                  color: "#ccc",
                }}
              />
            )}
          </Spin>
          {children}
        </Upload>
        {(error && submitFailed) && <span className="invalid-feedback d-block">{error}</span>}
        {width && height && !hideResize && (
          <span className="d-block mb-2">{`Hình ảnh sẽ được resize về kích thước ${width} x ${height}`}</span>
        )}
      </div>
    );
  }
}

export default renderImageAntS3V2;
