import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Button } from "antd";
import { giftMessageCreate } from "./actions";
import Form from "./components/form";
import BackToIcon from "images/chevron-down.svg";
import { getList as getListStore } from "../../../Stores/scenes/List/actions";
import getMomentInstance from "utils/moment";
import { Wrapper } from "scenes/general.styled";

class Create extends Component {
  componentDidMount() {
    this.props.getListStore({});
  }

  handleSubmit = ({ storeIds, timeStart, timeEnd, approve, ...values }) => {
    this.props.giftMessageCreate({
      ...values,
      allStore: values.allStore ? 'yes' : 'no',
      approve: approve ? 1 : 0,
      storeIds: values.allStore ? [] : (storeIds || []),
      timeStart: timeStart,
      timeEnd: timeEnd,
    });
  };
  render() {
    const { creating, profile, stores } = this.props;
    return (
      <Wrapper
        pageName="Create Gift Message"
      >
        <Spin spinning={creating}>
          <Form
            storesList={stores.data}
            onSubmit={this.handleSubmit}
            profile={profile}
            initialValues={{
              storeIds: [],
              approve: true,
              timeEnd: getMomentInstance(null, 0, true, false, ['day'], null, 1, 'day'),
              timeStart: getMomentInstance(null, 0, true, false, ['day']),
              date_range: [getMomentInstance(null, 0, false, false, ['day'], null, 1, 'day'), getMomentInstance(null, 0, false, false, ['day'])],
              bgHex: "#ffffff",
              colorHex: "#212b48",
              message: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting",
              imgLogo: "https://d33jplnp2vyxjf.cloudfront.net/imgLogo/32c320b4e93b8505c4be028ca4986063/2021/05/31/artwork162245189257060_ha5YPQ.png"
            }}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    profile: state.auth.info.profile,
    creating: state.gift_message.create.loading,
    stores: state.store.list.stores,

  }),
  (dispatch) => ({
    giftMessageCreate: (params) => {
      dispatch(giftMessageCreate(params));
    },
    getListStore: (options) => {
      dispatch(getListStore(options));
    },
  })
)(Create);
