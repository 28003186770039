import { actionType as TYPE } from "./actions";
import { notification } from "antd";
const initialState = {
  notifications: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  notificationsRead: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  processing: false,
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        notifications: {
          ...initialState.notifications,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          data: action.data.notifications,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
        },
      };
    case TYPE.READ.REQUEST:
      return {
        ...state,
        notificationsRead: {
          ...initialState.notificationsRead,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.READ.SUCCESS:
      return {
        ...state,
        notificationsRead: {
          ...state.notificationsRead,
          loading: false,
          data: action?.data?.notifications || [],
          total:
            action?.data?.pagination?.total ||
            (action?.data?.notifications || []).length,
        },
      };
    case TYPE.READ.ERROR:
      return {
        ...state,
        notificationsRead: {
          ...state.notificationsRead,
          loading: false,
        },
      };

    case TYPE.MARK_AS_READ.REQUEST:
      return {
        ...state,
        processing: true,
      };
    case TYPE.MARK_AS_READ.SUCCESS:
      notification.success({
        message: "Mark all notifications as read successfully.",
        duration: 5,
      });
      return {
        ...state,
        processing: false,
      };
    case TYPE.MARK_AS_READ.ERROR:
      return {
        ...state,
        processing: false,
      };
    default:
      return state;
  }
}

export default ListReducer;
