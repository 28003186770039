import React from 'react';
import Wrapper, { InputWrapper } from './Textbox.styled';
import { Field } from 'redux-form';
import classNames from 'classnames';
import { ReactComponent as IconEye } from 'images/icons/eye.svg';
import { ReactComponent as IconEyeOff } from 'images/icons/eye-off.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

const Textbox = ({
  type,
  value,
  className,
  showError = false,
  textarea = false,
  message,
  input,
  defaultValue,
  placeholder,
  label,
  meta,
  prefix,
  maxLength,
  ...rest
}) => {
  const [isShowPassword, setShowPassword] = useState(false);  
  const inputElement = useRef();
  const { error, touched, submitFailed } = meta;
  const toggleShowpassword = () => {
    setShowPassword((oldValue) => !oldValue);
  };
  const EyeSwitch = !isShowPassword ? IconEye : IconEyeOff;
  useEffect(() => {
    if (!(input.value || defaultValue) && inputElement.current) {
      inputElement.current.value = '';
    }
  }, [input.value, defaultValue]);
  return (
    <>
      <InputWrapper>
        {!textarea ? (
          <input
            type={isShowPassword ? 'text' : type || 'text'}
            prefix={prefix}
            maxLength={maxLength}
            ref={inputElement}
            placeholder={placeholder || label}
            {...input}
            {...rest}
            value={input.value || defaultValue}
            className={classNames(className, {
              error: touched && submitFailed && showError && error,
            })}
          />
        ) : (
          <textarea
            type={isShowPassword ? 'text' : type || 'text'}
            prefix={prefix}
            {...input}
            {...rest}
            placeholder={placeholder || label}
            value={input.value || defaultValue}
            className={classNames(className, {
              error: touched && submitFailed && showError && error,
            })}
          ></textarea>
        )}
        {type === 'password' ? (
          <EyeSwitch className="show-password" onClick={toggleShowpassword} />
        ) : null}
      </InputWrapper>
      {touched && submitFailed && showError && error ? (
        <span className="error-msg">{error}</span>
      ) : message ? (
        <span className="message">{message}</span>
      ) : null}
    </>
  );
};
export default ({
  label,
  component,
  className,
  addonBefore = null,
  addonAfter = null,
  iconBefore = null,
  iconAfter = null,
  maxLength,
  ...rest
}) => {
  return (
    <Wrapper className={classNames(className, 'gmtextbox')}>
      {label ? (
        <div className="label">
          <label htmlFor={rest.id || rest.name}>{label}</label>
        </div>
      ) : null}
      <div
        className={classNames('input', {
          'has-icon-before': iconBefore,
          'has-icon-after': iconAfter,
        })}
      >
        {iconBefore && <span className="icon-before">{iconBefore}</span>}
        <Field component={component || Textbox} label={label} {...rest}  maxLength={maxLength}/>
        {iconAfter && <span className="icon-after">{iconAfter}</span>}
      </div>
    </Wrapper>
  );
};
