import React from 'react';
import { Redirect } from 'react-router-dom'
import UserProfile from './scenes/Profile'
import UserChangePassword from './scenes/ChangePassword'
import UserResetPassword from './scenes/ResetPassword'
import UserChangeEmail from "./scenes/ChangeEmail"
export default [
    {   
        key: 'users',
        name: 'user',
        component: ()=><Redirect exact to='/users/list' /> ,
        path: '/users',
        group: 'user',
        icon: null,
        template: 'default',  
        children: [
            {
                key: 'users.list',
                name: 'List',
                component: () => {return <div> LIST </div>},
                path: '/users/list',
                group: 'user',
                icon: null,
                template: 'default',  
                isPublic: true
            },
            {
                key: 'users.profile',
                name: 'Profile',
                component: UserProfile,
                path: '/profile',
                group: 'user',
                icon: null,
                template: 'default',  
                isPublic: true
            },
            {
                key: 'users.profile',
                name: 'Profile',
                component: UserProfile,
                path: '/settings/profile',
                group: 'user',
                icon: null,
                template: 'default',  
                isPublic: true
            },
            {
                key: 'users.change-password',
                name: 'Change Password',
                component: UserChangePassword,
                path: '/change-password',
                group: 'user',
                icon: null,
                template: 'default',
                isPublic: true  
            },
            {
                key: 'users.change-email',
                name: 'Change Email',
                component: UserChangeEmail,
                path: '/change-email',
                group: 'user',
                icon: null,
                template: 'default',  
                isPublic: true
            },
            {
                key: 'users.reset-password',
                name: 'Reset Password',
                component: UserResetPassword,
                path: '/reset-password',
                group: 'user',
                icon: null,
                template: 'default',
                isPublic: true
            },
        ]
    },
]