import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Badge, Menu, Dropdown } from "antd";
import { withTranslation } from "react-i18next";
import {
  getListNotiRead,
  markAsRead,
} from "scenes/Notifications/scenes/List/actions";
import { getNotificationDetail } from "scenes/Notifications/scenes/Detail/actions";
import { Link, withRouter } from "react-router-dom";
import { ReactComponent as BellIcon } from "images/icons/bell-01.svg";
import { ReactComponent as MonitorIcon } from "images/icons/monitor-01.svg";
import { ReactComponent as CheckIcon } from "images/icons/check.svg";
import getMomentInstance from "utils/moment";
import { GMNotifyMenu } from "./styled";
import { ReactComponent as MoreAction } from "images/icons/dots-vertical.svg";
import { ContextMenuWrapper } from "scenes/general.styled";
const numOfNewNotification = 6;
class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countUnread: 0,
    };
  }
  componentDidMount() {
    this.props.getListNotiRead({
      status: 0,
      page: 1,
      perPage: numOfNewNotification,
      sortBy: "id",
      sortType: "desc",
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.notificationsRead.total !==
        this.props.notificationsRead.total ||
      (prevProps.detailNotificationLoading === false &&
        this.props.detailNotificationLoading === true)
    ) {
      this.setState({ countUnread: this.props.notificationsRead.total });
    }
    if (prevProps.processing === true && this.props.processing === false) {
      this.setState({ countUnread: 0 });
    }
  }
  handleNotify = (id) => {
    this.setState({ countUnread: this.state.countUnread - 1 || 0 });
    this.props.history.push(`/notification/${id}`);
  };
  render() {
    const { notificationsRead, classBtn } = this.props;
    return (
      <Dropdown
        trigger={["click"]}
        className="rounded-Dropdown"
        overlay={
          <GMNotifyMenu>
            <div className="title">
              <span>Notifications</span>
              <Dropdown
                placement="bottomRight"
                overlay={
                  <ContextMenuWrapper>
                    <a
                      href=""
                      onClick={(evt) => {
                        evt.preventDefault();
                        this.props.markAsRead();
                        return false;
                      }}
                    >
                      <CheckIcon /> Mark all as read
                    </a>
                    <a
                      href=""
                      onClick={(evt) => {
                        evt.preventDefault();
                        this.props.history.push("/notification");
                        return false;
                      }}
                    >
                      <MonitorIcon /> Open notifications
                    </a>
                  </ContextMenuWrapper>
                }
                trigger={["click"]}
              >
                <MoreAction />
              </Dropdown>
            </div>
            {notificationsRead.data.length > 0 ? (
              <>
                {notificationsRead.data.map(
                  (notificationItem, notificationIndex) => (
                    <GMNotifyMenu.Item
                      key={`${notificationIndex + 1}`}
                      className="notification-item d-flex align-items-center"
                      onClick={() => this.handleNotify(notificationItem.id)}
                    >
                      <span>
                        {notificationItem.name}
                        {/* <p className="text-muted my-0">
                          {getMomentInstance(
                            notificationItem.createdAt,
                            0,
                            true
                          )}
                        </p> */}
                      </span>
                    </GMNotifyMenu.Item>
                  )
                )}
              </>
            ) : (
              <GMNotifyMenu.Item
                key={`${numOfNewNotification + 2}`}
                className="notification-item d-flex align-items-center"
              >
                No new notifications{" "}
              </GMNotifyMenu.Item>
            )}
          </GMNotifyMenu>
        }
      >
        <button className={classnames("btn btn-bell header-color", classBtn)}>
          <Badge count={this.state.countUnread}>
            <BellIcon className="notify-icon" />
          </Badge>
        </button>
      </Dropdown>
    );
  }
}
const mapStateToProps = (state) => ({
  notificationsRead: state.notification.list.notificationsRead,
  processing: state.notification.list.processing,
  detailNotificationLoading: state.notification.detail.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getListNotiRead: (options) => {
      dispatch(getListNotiRead(options));
    },
    markAsRead: () => {
      dispatch(markAsRead());
    },
    getNotificationDetail: (id) => {
      dispatch(getNotificationDetail(id));
    },
  };
};

export default React.memo(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withTranslation()(NotificationDropdown))
  )
);
