import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Modal, Table, Spin, Avatar} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLock, faUnlock} from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import {getOptionLabel, options_payment_status} from 'utils/options';
import money from 'utils/money';
import FormEditAddress from '../../../Detail/components/FormEditAddress';
import {unapproveOrder, archiveOrder, approveOrder, createIssue, paymentOrder} from '../../actions';
import {updateGiftMessage, updateShippingService, duplicateOrder, lockOrder, getDetail, updateAddress} from '../../../Detail/actions';
import {getShippingMethodName} from "utils/helper";
import RenderShippingInfo from 'utils/Form/renderShippingInfo';
import getMomentInstance from 'utils/moment'
import {ExclamationCircleOutlined} from "@ant-design/icons";
import RenderOrderStatus from 'utils/Form/RenderOrderStatus';
import RenderEditGiftMessage from 'utils/Form/RenderEditGiftMessage';
const {confirm} = Modal;
class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: null,
            listShippingService: []
        };
    }

    componentDidMount() {
        this.props.getDetail(this.props.orderIdToQuickView)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.orderIdToQuickView !== prevProps.orderIdToQuickView){
            this.props.getDetail(this.props.orderIdToQuickView)
        }
    }

    handleUpdateAddress = ({
                               cusName,
                               cusPhone,
                               cusCompany,
                               cusAddress,
                               cusCountry,
                               cusPostalCode,
                               cusAddress2,
                               cusCity,
                               cusState,
                               cusProvince,
                               countryIsoCode,
                               iossNumber,
                               iossNumberTotal
                           }) => {
        this.props.updateAddress(this.props.orderIdToQuickView, {
            cusName,
            cusPhone,
            cusCompany,
            cusAddress,
            cusCountry,
            cusPostalCode,
            cusAddress2,
            cusCity,
            cusState,
            cusProvince,
            countryIsoCode,
            iossNumber,
            iossNumberTotal
        })
    }
    handleChangeShippingService = (values) => {
        this.props.updateShippingService(this.props.orderIdToQuickView, {shippingMethod: values.shippingMethod || ''});
    }

    handleUpdateGiftMessage = (values) => {
        this.props.updateGiftMessage(this.props.orderIdToQuickView, {note: values, orderId: this.props.orderIdToQuickView});
    }

    archiveOrder = () => {
        this.props.archiveOrder({orders: [this.props.orderIdToQuickView]})
    }

    duplicateOrder = () => {
        this.props.duplicateOrder({id: this.props.orderIdToQuickView})
    }

    lockOrder = () => {
        this.props.lockOrder(this.props.orderIdToQuickView);
    }

    approveOrder = () => {
        this.props.archiveOrder({orders: [this.props.orderIdToQuickView]})
    }

    unapproveOrder = () => {
        this.props.unapproveOrder({orders: [this.props.orderIdToQuickView]})
    }

    paymentOrder = () => {
        this.props.paymentOrder({orders: [this.props.orderIdToQuickView]})
    }

    createIssue = () => {
        const redirectToCreateIssue  = () => {
            this.props.history.push('/orders/order-issues/create', {listOrderId: [this.props.orderIdToQuickView]})
        }
        confirm({
            title: 'Do you want to create issue for these orders?',
            icon: <ExclamationCircleOutlined/>,
            content: 'You won\'t be able to revert this!',
            onOk() {
                //this.props.createIssue({orders: ids})
                redirectToCreateIssue()
            },
            onCancel() {
            },
        });
    }
    viewInvoice = () => {
        this.props.history.push(`/orders/${this.props.orderIdToQuickView}/invoice`)
    }

    render() {
        const {detail, updateAddressLoading, updateGiftMessageLoading, duplicateOrderLoading, lockOrderLoading} = this.props
        const {showModal} = this.state
        const columns = [
            {
                title: <b>MOCKUP</b>,
                dataIndex: 'mainUrl',
                align: 'center',
                key: 'mainUrl',
                render: (text, record) => {
                    return <img width="50" src={text} alt="" style={{backgroundColor: record.hexColor ? `#${record.hexColor}` : '#25282B'}}/>
                }
            },
            {
                title: <b>DESIGN FRONT</b>,
                dataIndex: 'frontDesignUrl',
                key: 'frontDesignUrl',
                render: (src, record) => (
                    src ? (
                        <Avatar shape="square" size={64} src={src}/>
                    ) : (
                        <Avatar shape="square" size={64}>NO DESIGN</Avatar>
                    )
                )
            },
            {
                title: <b>DESIGN BACK</b>,
                dataIndex: 'backDesignUrl',
                key: 'backDesignUrl',
                render: (src, record) => (
                    src ? (
                        <Avatar shape="square" size={64} src={src}/>
                    ) : (
                        <Avatar shape="square" size={64}>NO DESIGN</Avatar>
                    )
                )
            },
            {
                title: <b>COLOR</b>,
                dataIndex: 'content',
                key: 'content',
                align: "center",
                render: value => <div>
                    {value?.name_color}
                </div>,
            },
            {
                title: <b>SIZE</b>,
                dataIndex: 'content',
                key: 'content',
                align: "center",
                render: value => <div>
                    {value?.name_size}
                </div>,
            },
            {
                title: <b>QUANTITY</b>,
                dataIndex: 'quantity',
                align: "center",
                key: 'quantity',
            },
            {
                title: <b>PRICE</b>,
                dataIndex: "price",
                key: "price",
                render: (value, record) => {
                    return (
                        <>
                            <div>{money((value + record.extraCost + record.printCost))}</div>
                            {(record?.originalPrice || 0) > 0 && <div className={'text-danger line-through'}>
                                <del>{money(record?.originalPrice || 0)}</del>
                            </div>}
                        </>
                    )
                },
            }
        ];
        return (
            <Spin spinning={detail.loading || updateGiftMessageLoading || duplicateOrderLoading || lockOrderLoading}>
                <div className="container-fluid">
                    <h1 className="h3 mb-2 font-weight-bold">
                        Order Detail: #{detail.data.name || detail.data.id}
                    </h1>
                    <div className="border pb-3">
                        <div className="d-flex my-3 px-4 justify-content-between align-items-center">
                            <div>
                                Created date: {getMomentInstance(detail.data.createdAt, 0 , true)}
                            </div>
                        </div>
                        <div className="bg-light px-4 py-3 mb-4">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mr-5"><span
                                    className="mr-2">Status</span> <RenderOrderStatus order={detail?.data} isShowError={false}/>
                                </li>
                                <li className="list-inline-item mr-5"><span
                                    className="mr-2">Payment</span> {getOptionLabel(options_payment_status, detail.data.paymentStatus)}
                                </li>
                                <li className="list-inline-item mr-5">Locked {detail.data.locked ? <span
                                    className="rounded text-white ml-2 py-1 px-2 bg-success"><FontAwesomeIcon
                                    icon={faLock}/></span> : <span
                                    className="rounded text-white ml-2 py-1 px-2 bg-secondary"><FontAwesomeIcon
                                    icon={faUnlock}/></span>}</li>
                                <li className="list-inline-item mr-5">Store name: {detail?.data?.storeIntegration?.showName
                                || (detail?.data?.platform?.icon ? <img alt="" src={detail?.data?.platform?.icon} className="img-fluid" width="35px"/> : 'N?A') }</li>
                                <li className="list-inline-item mr-5">Time: {detail.data.createdAt && detail.data.createdAt ? getMomentInstance(detail.data.createdAt, null , true) : 'N/A'}</li>
                                <li className="list-inline-item mr-5">Approve time: {detail.data.isApproved && detail.data.approvedAt ? getMomentInstance(detail.data.approvedAt, null , true) : 'N/A'}</li>
                                {(detail.data.profit > 0) && <li className="list-inline-item mr-5">Profit: {money(detail.data.profit)}</li>}
                                <li className="list-inline-item mr-5">Invoice: {detail.data.transactionId > 0 ? <Link to={`/transactions/${detail.data.transactionId}/detail`}>View invoice</Link> : 'N/A'}</li>
                                {(detail.data.transactionRefund > 0) && <li className="list-inline-item mr-5">Refund transaction: <Link to={`/billing/transactions/${detail.data.transactionRefund}/detail`}>View transaction</Link></li>}
                                {(detail.data.originalOrder && detail.data.originalOrder.order && detail.data.originalOrder.order.name) && <li className="list-inline-item mr-5">Duplicate from: <Link to={`/orders/${detail.data.originalOrder.orderOriginalId}`}>{detail.data.originalOrder.order.name}</Link></li>}
                            </ul>
                        </div>
                        <div className="px-4">
                            <div className="row">
                                <div className="col-md-12 col-xl-8 mb-3">
                                    {parseInt(detail?.data?.orderMatch?.isImport ) === 1 ? <div className="border border-bottom-0 mb-4">
                                        <Table
                                            pagination={false}
                                            rowKey="id"
                                            columns={columns}
                                            dataSource={detail.data.orderItems || []}
                                            scroll={{x: 1000}}
                                            summary={() => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className="bg-light py-2 border-0" colSpan={4}>
                                                                <span className="mr-2">Payment</span> {getOptionLabel(options_payment_status, detail.data.paymentStatus)}
                                                            </td>
                                                            <td className="bg-light py-2 border-0 font-weight-bold"
                                                                colSpan={2}>Sub-total
                                                                ({(detail.data.orderItems || []).length} items)
                                                            </td>
                                                            <td className="bg-light py-2 font-weight-bold"> {money(detail.data.subTotal || (detail.data.orderItems || []).reduce((a, {quantity, basePrice}) => a + quantity * basePrice, 0))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="bg-light py-2"
                                                                colSpan={4}></td>
                                                            <td className="bg-light py-2 border-0 font-weight-bold" colSpan={2}>Shipping Fee</td>
                                                            <td className="bg-light py-2 font-weight-bold border-0">{money(detail.data.shippingFee)}</td>
                                                        </tr>
                                                        {(detail.data?.totalVat) > 0 && <tr>
                                                            <td className="bg-light py-2" colSpan={4}></td>
                                                            <td className="bg-light py-2 font-weight-bold border-0" colSpan={2}>Tax</td>
                                                            <td className="bg-light py-2 font-weight-bold border-0">{money(detail.data.totalVat)}</td>
                                                        </tr>}
                                                        {(detail.data?.totalDiscount || 0)  > 0 &&<tr>
                                                            <td className="bg-light py-2" colSpan={4}></td>
                                                            <td className="bg-light py-2 font-weight-bold border-0" colSpan={2}>Discount</td>
                                                            <td className="bg-light py-2 font-weight-bold border-0">{money(detail.data.totalDiscount)}</td>
                                                        </tr>}
                                                        {(detail.data?.orderHandleFee) &&
                                                        <tr>
                                                            <td className="bg-light py-2" colSpan={4}></td>
                                                            <td className="bg-light py-2 font-weight-bold border-0" colSpan={2}>Handle Fee</td>
                                                            <td className="bg-light py-2 font-weight-bold border-0">{money(detail.data?.orderHandleFee || 0)}</td>
                                                        </tr>}
                                                        {(detail.data?.orderExtend?.giftMessagePrice) &&
                                                        <tr>
                                                            <td className="bg-light py-2" colSpan={4}></td>
                                                            <td className="bg-light py-2 font-weight-bold border-0" colSpan={2}>Gift Message Fee</td>
                                                            <td className="bg-light py-2 font-weight-bold border-0">{money(detail.data?.orderExtend?.giftMessagePrice || 0)}</td>
                                                        </tr>}
                                                        <tr>
                                                            <td className="bg-light py-2" colSpan={4}></td>
                                                            <td className="bg-light py-2 font-weight-bold" colSpan={2}>Total</td>
                                                            <td className="bg-light py-2 font-weight-bold text-info font-weight-bold">{money(detail.data.total)}</td>
                                                        </tr>
                                                    </>
                                                );
                                            }}
                                        />
                                    </div> : <h5 className="text-danger">Converting data</h5>}
                                    <div>
                                        <h5 className="mb-2 font-weight-bold">Tracking Information</h5>
                                        <p>Shipping service: {getShippingMethodName(detail.data)}</p>
                                        {(detail.data.orderExtra && detail.data.orderExtra.content && detail.data.orderExtra.content.trackingNumber) && <p>Tracking number: {detail.data.orderExtra.content.trackingNumber}</p>}
                                    </div>
                                </div>
                                <div className="col-md-12 col-xl-4 mb-3">
                                    <div className="mb-4 p-2 border rounded">
                                        <RenderShippingInfo order={detail.data} _this={this}/>
                                    </div>
                                    <div className="mb-4 p-2 border rounded">
                                        <h5 className="mb-2 font-weight-bold">Gift Message</h5>
                                        <RenderEditGiftMessage order={detail.data}/>
                                    </div>
                                    {(detail?.data?.platformId && detail?.data?.platformId > 0) && <div>
                                        {(parseInt(detail.data.isApproved) === 0 && parseInt(detail.data?.orderMatch?.isImport) === 1 && parseInt(detail.data.transactionId)  === 0) && <button onClick={this.archiveOrder.bind(this, detail.data.id)}
                                                                                                                                                 className="btn btn-primary w-100 mb-2">APPROVE ORDER
                                        </button>}
                                        {(parseInt(detail.data.locked ) === 0 && parseInt(detail.data.isApproved) === 1 && parseInt(detail.data.paymentStatus) === 0 && parseInt(detail.data.transactionId) === 0) && <button onClick={this.unapproveOrder}
                                                                                                                                                                                className="btn btn-primary w-100 mb-2">UNAPPROVE ORDER
                                        </button>}
                                        {(parseInt(detail.data?.orderMatch?.isImport) !== 2 && parseInt(detail.data.verifiedAddress) === 1 && parseInt(detail.data.paymentStatus) === 100 && parseInt(detail.data.transactionId) === 0) && <button onClick={this.paymentOrder}
                                                                                                                                                                                          className="btn btn-primary mb-2 w-100">PAYMENT ORDER
                                        </button>}
                                        {(parseInt(detail.data.paymentStatus) === 1 && parseInt(detail.data.locked) === 1) && <button onClick={this.viewInvoice}
                                                                                                                className="btn btn-primary w-100 mb-2">VIEW INVOICE
                                        </button>}
                                        {(parseInt(detail.data.platformId) !== 0 && parseInt(detail.data.locked) === 0 && parseInt(detail.data.verifiedAddress) === 1 && parseInt(detail.data.transactionId) !== 0
                                            && parseInt(detail.data.paymentStatus) === 0 && parseInt(detail.data?.orderMatch?.isImport) !== 2 &&
                                            parseInt(detail.data.isApproved) === 0 && parseInt(detail.data.unlockedAt) !== 0) && <button onClick={this.lockOrder}
                                                                                                                  className="btn btn-primary w-100 mb-2">LOCK ORDER
                                        </button>}
                                        {(detail.data.orderExtra && parseInt(detail.data.orderExtra.isIssue) === 0 && parseInt(detail.data.transactionId) !== 0)&&<button onClick={this.createIssue}
                                                                                                                                                    className="btn btn-primary w-100 mb-2">CREATE ISSUE
                                        </button>}
                                        {(parseInt(detail?.data?.platformId) !== 17) && <button onClick={this.duplicateOrder} className="btn btn-primary w-100">DUPLICATE ORDER</button>}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    footer={null}
                    visible={showModal === 'edit_address'}
                    onCancel={() => this.setState({showModal: null})}
                    width={800}
                >
                    <Spin spinning={updateAddressLoading}>
                        <FormEditAddress 
                            onSubmit={this.handleUpdateAddress}
                            onCancel={() => this.setState({showModal: null})} 
                            initialValues={{
                                ...detail.data, 
                                cusCountry: `${detail.data.cusCountry}`,
                                iossNumber: detail?.data?.orderExtra?.iossNumber || '',
                                iossNumberTotal: detail?.data?.orderExtra?.iossNumberTotal || 0
                        }}/>
                    </Spin>
                </Modal>
            </Spin>
        );
    }
}

export default connect(
    state => ({
        detail: state.order.detail.orderDetail,
        histories: state.order.detail.histories,
        updateAddressLoading: state.order.detail.updateAddress.loading,
        updateGiftMessageLoading: state.order.detail.updateGiftMessage.loading,
        updateShippingServiceLoading: state.order.detail.updateShippingService.loading,
        duplicateOrderLoading: state.order.detail.duplicateOrder.loading,
        lockOrderLoading: state.order.detail.lockOrder.loading
    }),
    dispatch => ({
        getDetail: (id) => {
            dispatch(getDetail(id))
        },
        updateAddress: (id, params) => {
            dispatch(updateAddress(id, params))
        },
        unapproveOrder: (data) => {
            dispatch(unapproveOrder(data))
        },
        archiveOrder: (data) => {
            dispatch(archiveOrder(data))
        },
        duplicateOrder: (data) => {
            dispatch(duplicateOrder(data))
        },
        updateGiftMessage: (id, data) => {
            dispatch(updateGiftMessage(id, data));
        },
        updateShippingService: (id, data) => {
            dispatch(updateShippingService(id, data));
        },
        lockOrder: (id) => {
            dispatch(lockOrder(id));
        },
        createIssue: (data) => {
            dispatch(createIssue(data))
        },
        paymentOrder: (data) => {
            dispatch(paymentOrder(data))
        },
        approveOrder: (data) => {
            dispatch(approveOrder(data))
        }
    })
)(Detail);