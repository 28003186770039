import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Table } from 'antd';
import BackToIcon from 'images/chevron-down.svg';
import { getDetail } from './actions';
import {
  getOptionLabel,
  export_product_status,
  options_mail_status,
} from 'utils/options';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getMomentInstance from 'utils/moment';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { Wrapper } from './styled';

class Edit extends Component {
  componentDidMount() {
    this.props.getDetail(this.props.match.params.id);
  }

  render() {
    const { detail, pageName } = this.props;
    const columns = [
      {
        title: <b>IMAGES</b>,
        dataIndex: 'imageDesign',
        align: 'center',
        key: 'imageDesign',
        width: '50px',
        render: (record) => {
          return record ? (
            <img width="50" src={record} alt="" />
          ) : (
            <FontAwesomeIcon icon={faImage} style={{ fontSize: 50 }} />
          );
        },
      },
      {
        title: <b>PRODUCT NAME</b>,
        dataIndex: 'name',
        key: 'name',
        width: '50px',
      },
    ];
    const id = this.props.match.params.id;
    return (
      <Wrapper
        pageName={pageName}
        breadcrumDetails={{
          [id]: id && `Detail #${id || ''}`,
        }}
      >
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="row form-group">
              <div className="col-6 col-md-2 mb-3 mb-sm-0">
                <b>Export Type</b>
              </div>
              <div className="col-6 col-md-4 mb-3 mb-sm-0">
                {detail.data.module && detail.data.module}
              </div>
              <div className="col-6 col-md-2 mb-3 mb-sm-0">
                <b>Link download</b>
              </div>
              <div className="col-6 col-md-4 mb-3 mb-sm-0">
                <a href={detail.data.linkDownload} taget="_blank">
                  File export
                </a>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-6 col-md-2 mb-2 mb-sm-0">
                <b>Request time</b>
              </div>
              <div className="col-6 col-md-4 mb-2 mb-sm-0">
                {getMomentInstance(null, detail.timeStart, true)}
              </div>

              <div className="col-6 col-md-2 mb-2 mb-sm-0">
                <b>Complete time</b>
              </div>
              <div className="col-6 col-md-4 mb-2 mb-sm-0">
                {getMomentInstance(null, detail.timeFinish, true)}
              </div>
            </div>
            <div className="row form-group">
              <div className="col-6 col-md-2 mb-2 mb-sm-0">
                <b>Export Status</b>
              </div>
              <div className="col-6 col-md-4 mb-2 mb-sm-0">
                {getOptionLabel(export_product_status, detail.data.status)}{' '}
              </div>

              <div className="col-6 col-md-2 mb-2 mb-sm-0">
                <b>Send mail status</b>
              </div>
              <div className="col-6 col-md-4 mb-2 mb-sm-0">
                {getOptionLabel(options_mail_status, detail.data.sendEmail)}{' '}
              </div>
            </div>
            <div className="row form-group">
              <div className="col-6 col-md-2 mb-2 mb-sm-0">
                <b>List product</b>
              </div>
            </div>
            {detail?.data?.campaign && (
              <Table
                rowKey="id"
                columns={columns}
                dataSource={detail.data.campaign}
                pagination={false}
                scroll={{ x: 500 }}
              />
            )}
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    detail: state.campaign.export_product_detail.export_product_detail,
  }),
  (dispatch) => ({
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
  })
)(Edit);
