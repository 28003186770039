import React from "react";
export default (props) => {
  return (
    <div className="dashboard-section guideline-section px-4 py-3 mb-4 h-100">
      <div className="d-flex justify-content-center">
        <h4 className="mb-3">Chat</h4>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="text-center text-muted">
          <p>
            You can chat with us from the bottom-left corner of any Gearment
            page.
          </p>
        </div>
        <div>
          <button
            className="btn btn-primary"
            style={{ borderRadius: "5px" }}
            onClick={() => {
              if (window?.$crisp) {
                window.$crisp.push(["do", "chat:open"]);
              }
            }}
          >
            Chat with us
          </button>
        </div>
      </div>
    </div>
  );
};
