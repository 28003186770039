import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderRadioAnt } from 'utils/Form';
import { Wrapper } from './styled';
class index extends Component {
  render() {
    const { handleSubmit, dataFilter } = this.props;
    const optionsExportOrder = [
      // { value: 1, label: 'Current page' },
      { value: 2, label: 'All orders (last 6 months)' },
      {
        value: 4,
        label: 'Order matching your search',
        disabled: dataFilter === undefined && true,
      },
    ];
    return (
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="form-group mb-1">
                    <Field
                      name="exports"
                      component={renderRadioAnt}
                      defaultValue={2}
                      classNameItem={'d-block mb-3'}
                      colSpan={24}
                      options={optionsExportOrder}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Wrapper>
    );
  }
}

export default connect()(
  reduxForm({
    form: 'FormOrderExport',
  })(index)
);
