import axios from 'utils/request'

const prefix = '/dashboard';
const bonusAPI = '/logs/view-docs';

export const getUserStepTracking = () => {
  return axios({
    method: 'GET',
    url: `${prefix}/check`
  })
}

export const markDevDocs = () => {
  return axios({
    method: 'POST',
    url: bonusAPI
  })
}
