import React, { useState } from 'react';
import { Modal, Input } from 'antd';
import { search } from 'apis/image_design';
import { Wrapper } from './styled';
import { useDispatch } from 'react-redux';
import { HandleModalImage } from 'components/ModalImage';

const { confirm } = Modal;
const { Search } = Input;
export const UploadImageCreateOrder = ({
  changeDesignFront,
  changeDesignBack,
  changeDesignFrontId,
  changeDesignBackId,
  input,
  cusId = 0,
  meta,
  buttonTitle = 'Upload your image',
  isCreatingOrder = false,
  isOnlyUpload = false,
  isButtonPlaceholder = false,
  printLocation,
  orderId,
  orderItemId,
  typePrint,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [original, setOriginal] = useState('');
  const [images, setImages] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    perPage: 16,
    currentPage: 1,
  });
  const handleShowModal = (show) => {
    setVisible(show);
    setImages([]);
    if (show) {
      searchImage(original);
    }
  };

  const searchImage = (q, is_more) => {
    setOriginal(q);
    setLoading(true);
    search({
      original: q,
      page: is_more ? (pagination?.currentPage || 1) + 1 : 1,
      perPage: 18,
    })
      .then(({ code, data }) => {
        setLoading(false);
        if (code === 'success') {
          setImages(
            is_more ? [...images, ...data.imageDesign] : data.imageDesign
          );
          setPagination(data.pagination);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <Wrapper className="m-0 p-0" style={{ height: '100px' }}>
      <HandleModalImage
        visible={visible}
        title="Design library"
        onCancel={() => handleShowModal(false)}
        footer={false}
        width={1000}
        style={{
          top: '10px',
          bottom: 0,
        }}
        changeDesignFront={changeDesignFront}
        changeDesignBack={changeDesignBack}
        changeDesignFrontId={changeDesignFrontId}
        changeDesignBackId={changeDesignBackId}
        input={input}
        cusId={cusId}
        meta={meta}
        buttonTitle={buttonTitle}
        isCreatingOrder={isCreatingOrder}
        isOnlyUpload={isOnlyUpload}
        isButtonPlaceholder={isButtonPlaceholder}
        printLocation={printLocation}
        orderId={orderId}
        orderItemId={orderItemId}
        typePrint ={typePrint}
      />
    </Wrapper>
  );
};
export default React.memo(UploadImageCreateOrder);
