import React from "react";
import { Collapse, Col, Affix } from "antd";
import {
  Field,
  reduxForm,
  submit,
  change,
  formValueSelector
} from "redux-form";
import { connect } from "react-redux";
import { renderRangeDate } from "utils/Form";
import FieldCustomRadioGroup from "utils/fields/FieldCustomRadioGroup";
import { options_payment_method } from "utils/options";
import RenderAdvancedFilterButtons from "utils/Form/RenderAdvancedFilterButtons";
import RenderTimeOptions from "utils/Form/RenderTimeOptions";
import { clearFilter } from "utils/helper";
import RenderTagFilter from "utils/Form/RenderTagFilter";
import GMRow from "components/GMLayout/GMRow";
const { Panel } = Collapse;
const paramsName = {
  paymentMethod: "Payment Method",
  createdAtFrom: "From Date",
  createdAtTo: "To Date",
};
const defaultValues = {
  paymentMethod: "0",
  createdAtFrom: null,
  createdAtTo: null,
};

let paramsValueAlias = {
  paymentMethod: {
    1: "Credit Card",
    2: "Paypal",
    6: "Wallet",
    7: "PO"
  },
}
const AdvancedFilter = ({ handleSubmit, changeFieldValue, transactions, date_range, ...props}) => {
  const submitFilters = (isClosedAdvanedFileter = true) => {
    props.dispatch(submit("AdvancedFilterTransactions"));
    if(isClosedAdvanedFileter) props.onCloseAdvancedSearch();
  };
  return (
    <div>
      <RenderTagFilter
        params={transactions.params}
        paramsName={paramsName}
        paramsValueAlias={paramsValueAlias}
        defaultValues={defaultValues}
        changeFieldValue={changeFieldValue}
        isAllowedToClearFilter={true}
        submitFilters={submitFilters}
        date_range={date_range}
      />
      <form onSubmit={handleSubmit}>
        <div className="p-3">
          <Collapse
            defaultActiveKey={["1", "2", "3"]}
            bordered={true}
            ghost
            expandIconPosition="right"
          >
            <Panel header={<b className="header-filter">All time</b>} key="1">
              <RenderTimeOptions changeFieldValue={changeFieldValue}/>
              <div className="row">
                <Field
                  name="date_range"
                  component={renderRangeDate}
                  labelStart={<b>Start date</b>}
                  labelEnd={<b>End date</b>}
                  className="h-50px w-100"
                  dateTimeFormat="YYYY/MM/DD"
                  showTime={false}
                  classContainer="col-6 mb-2"
                  startPlaceholder="yyyy/mm/dd"
                  endPlaceholder="yyyy/mm/dd"
                />
              </div>
            </Panel>
            {/* <Panel
              header={<b className="header-filter">Transaction Type</b>}
              key="2"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="type"
                    component={FieldCustomRadioGroup}
                    defaultValue={"0"}
                    options={[
                      {
                        value: "1",
                        label: <span>Payment</span>,
                      },
                      {
                        value: "2",
                        label: <span>Deposit</span>,
                      },
                      {
                        value: "0",
                        label: <span>All</span>,
                      },
                    ]}
                  />
                </Col>
              </GMRow>
            </Panel> */}
            <Panel
              header={<b className="header-filter">Payment Method</b>}
              key="3"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="paymentMethod"
                    component={FieldCustomRadioGroup}
                    defaultValue={"0"}
                    options={[
                      ...options_payment_method,
                      {
                        value: "0",
                        label: "All",
                      },
                    ]}
                  />
                </Col>
              </GMRow>
            </Panel>
          </Collapse>
        </div>
      </form>
      <Affix offsetBottom={0}>
        <RenderAdvancedFilterButtons
          handleOnClearFilters={() => {
            clearFilter(changeFieldValue, {
              sortBy: "id",
              sortType: "desc",
              type: "0",
              paymentMethod: "0",
              date_range: [null, null],
            });
            setTimeout(() => props.dispatch(submit("AdvancedFilterTransactions")), 1000);
          }}
          handleOnSubmit={submitFilters}
        />
      </Affix>
    </div>
  );
};
const selector = formValueSelector("AdvancedFilterTransactions");
const AdvancedFilterReduxForm = reduxForm({
  form: "AdvancedFilterTransactions",
  enableReinitialize: true,
  //validate,
})(AdvancedFilter);

const mapStateToProps = (state) => ({
  date_range: selector(state, "date_range") || null
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change("AdvancedFilterTransactions", field, value));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedFilterReduxForm);
