import { WEBHOOKS_UPDATE_REQUEST, WEBHOOKS_DETAIL_REQUEST } from "./constants";

export function updateWebhooks(id, dataToUpdate) {
  return {
    type: WEBHOOKS_UPDATE_REQUEST,
    id,
    dataToUpdate,
  };
}
export function getDetailWebhooks(id) {
  return {
    type: WEBHOOKS_DETAIL_REQUEST,
    id,
  };
}
