import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Spin, Tabs, Table, Tag, Pagination } from 'antd';
import {
  getListStockStatus,
  getListColor,
  getListSize,
  getListProduct,
  exportStockStatus,
} from './actions';
import FormFilter from './components/FormFilter';
import { getListExproduct } from '../../../Mockups/scenes/Create/actions';
import { change } from 'redux-form';
import classNames from 'classnames';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Wrapper } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GMButton } from 'components/Button';
class List extends Component {
  constructor(props) {
    super(props);
    const { createdAtFrom, createdAtTo, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      selectedRowKeys: [],
      initial_filter_values: {
        sortBy: 'var_id',
        sortType: 'desc',
        stockType: '1',
        productId: '0',
        sizeIds: [],
        colorIds: [],
        page: 1,
        perPage: 50,
        'with[0]': 'color',
        'with[1]': 'size',
        ...query_params,
      },
    };
  }
  handleChangeTab = (activeKey) => {
    this.props.changeFieldValue('stockType', activeKey);
    this.fetchData({
      stockType: activeKey,
    });
  };

  handleOnExport = () => {
    const { initial_filter_values } = this.state;
    const { stockType, ...query_params } = queryString.parse(
      window.location.search
    );
    const exportQuery = { ...initial_filter_values, stockType };
    this.props.exportStockStatus(exportQuery);
  };

  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
    // this.props.getListColor({});
    // this.props.getListSize({});
    // this.props.getListProduct({});
    this.props.getListExproduct({ sortBy: 'type', sortType: 'asc' });
  }

  fetchData = ({ date_range, page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      'with[0]': 'color',
      'with[1]': 'size',
      sortBy: 'var_id',
      sortType: 'desc',
      ...options,
    };
    if (parseInt(params['productId']) === 0) delete params['productId'];
    if (!Array.isArray(params['sizeIds']) || params['sizeIds'].length === 0)
      delete params['sizeIds'];
    if (!Array.isArray(params['colorIds']) || params['colorIds'].length === 0)
      delete params['colorIds'];
    if (params['colorId']) delete params['colorId'];
    if (parseInt(params['stockType']) === -1) delete params['stockType'];
    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getListStockStatus({
      ...params,
    });
  };

  render() {
    const {
      stock_status,
      pageName,
      list_color,
      list_size,
      list_product,
      exportStockStatusLoading,
      exproducts,
    } = this.props;
    const { initial_filter_values } = this.state;
    const columns = [
      {
        title: <b>No.</b>,
        dataIndex: 'productId',
        key: 'productId',
        render: (text, record, i) => <span>#{i + 1}</span>,
      },
      {
        title: <b>Product name</b>,
        dataIndex: 'expBrand',
        key: 'expBrand',
        render: (text) => (
          <span
            dangerouslySetInnerHTML={{
              __html: (text || '').replace(/(<? *script)/gi, 'illegalscript'),
            }}
          ></span>
        ),
      },
      {
        title: <b>Variant ID</b>,
        dataIndex: 'variantId',
        key: 'variantId',
        render: (value) => (
          <div>
            <div className="ml-1"> {value}</div>
          </div>
        ),
      },
      {
        title: <b>Color</b>,
        dataIndex: 'color',
        key: 'color',
        render: (value, record) => {
          return (
            <div className="d-flex flex-row">
              <Tag
                className={classNames('mb-2', {
                  'border border-dark':
                    (value?.name || '').indexOf('White') > -1,
                })}
                color={`#${value.value}`}
              >
                <span className="invisible">n</span>
              </Tag>
              <div className="ml-1"> {value?.name}</div>{' '}
            </div>
          );
        },
      },
      {
        title: <b>Color hex</b>,
        dataIndex: 'color',
        key: 'color',
        render: (value, record) => {
          return (
            <div className="d-flex flex-row">
              <div className="ml-1">{value?.value}</div>{' '}
            </div>
          );
        },
      },
      {
        title: <b>Size</b>,
        dataIndex: 'size',
        key: 'size',
        render: (value) => <div>{value?.name}</div>,
      },
    ];
    return (
      <Wrapper
        pageName={pageName}
        ctas={
          <GMButton
            type="button"
            className="btn btn-primary Rectangle-817 w-100"
            onClick={() => {
              this.handleOnExport();
            }}
          >
            <FontAwesomeIcon className="mr-2" icon={faDownload} /> Export
          </GMButton>
        }
        noContainer
      >
        <Spin spinning={exportStockStatusLoading || exproducts.loading}>
          <div className="filter">
            <FormFilter
              initialValues={initial_filter_values}
              handleAdvancedSubmit={this.fetchData}
              listColor={list_color.data}
              listSize={list_size.data}
              listProduct={list_product.data}
              handleOnExport={this.handleOnExport}
              is_mobile={this.props.is_mobile}
              stock_status={stock_status}
              exproducts={exproducts.data}
            />
          </div>
          <div className="main-content">
            <Tabs
              activeKey={stock_status?.params?.stockType || '1'}
              tabBarStyle={{
                height: '50px',
                backgroundColor: '#fff',
              }}
              onChange={this.handleChangeTab}
            >
              <Tabs.TabPane tab={`In stock`} key={1} forceRender={true} />
              <Tabs.TabPane tab="Out of stock" key={2} forceRender={true} />
              <Tabs.TabPane tab="At risk" key={3} forceRender={true} />
            </Tabs>
            <Table
              rowKey="variantId"
              columns={columns}
              dataSource={stock_status.data.variants}
              loading={stock_status.loading}
              scroll={{ x: 1000 }}
              pagination={
                !this.props.is_mobile
                  ? {
                      showTotal: (total) => `Total ${total} items`,
                      total: stock_status.total,
                      pageSize: parseInt(
                        stock_status.params.perPage > stock_status.total
                          ? stock_status.total
                          : stock_status.params.perPage
                      ),
                      current: stock_status.params.page,
                      showSizeChanger: true,
                      onChange: (page, perPage) =>
                        this.fetchData({
                          ...stock_status.params,
                          page,
                          perPage,
                        }),
                    }
                  : false
              }
            />
            {this.props.is_mobile && (
              <div className="d-flex justify-content-end align-items-center py-3 px-3">
                <div>
                  <Pagination
                    size="small"
                    showSizeChanger
                    className="pagination"
                    total={stock_status.total}
                    defaultCurrent={stock_status?.params?.page || 1}
                    pageSize={stock_status?.params?.perPage || 0}
                    onChange={(page, perPage) =>
                      this.fetchData({
                        ...stock_status.params,
                        page:
                          page === Number.NEGATIVE_INFINITY
                            ? 1
                            : page === 0
                            ? 1
                            : page,
                        perPage,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    stock_status: state.order.stock_status.stock_status,
    exportStockStatusLoading:
      state.order.stock_status.exportStockStatus.loading,
    list_color: state.order.stock_status.listColor,
    list_size: state.order.stock_status.listSize,
    list_product: state.order.stock_status.listProduct,
    formStockStatus: state.form.formStockStatus?.values,
    exproducts: state.mockup.create.exproducts,
  }),
  (dispatch) => ({
    getListStockStatus: (options) => {
      dispatch(getListStockStatus(options));
    },
    getListColor: (options) => {
      dispatch(getListColor(options));
    },
    getListSize: (options) => {
      dispatch(getListSize(options));
    },
    getListProduct: (options) => {
      dispatch(getListProduct(options));
    },
    exportStockStatus: (params) => {
      dispatch(exportStockStatus(params));
    },
    getListExproduct: (params) => {
      dispatch(getListExproduct(params));
    },
    changeFieldValue: function (field, value) {
      dispatch(change('formStockStatusForm', field, value));
    },
  })
)(List);
