import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Pagination, Table } from 'antd';
import queryString from 'query-string';
import moment from 'moment';
import {
  getOptionLabel,
  options_report_manually_sync_tracking,
} from 'utils/options';
import { getStoreName } from 'utils/helper';
import { getList } from 'scenes/Logs/actions';
import { Wrapper } from 'scenes/Logs/styled';

class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getList(params);
  };
  render() {
    const { sync_trackings, pageName } = this.props;
    const columns = [
      {
        title: <b>Id</b>,
        align: 'center',
        dataIndex: 'id',
        key: 'id',
        render: (value) => (
          <Link
            to={`/logs/sync-tracking/${value}`}
            className="btn btn-link mr-1"
          >
            #{value}
          </Link>
        ),
      },
      {
        title: <b>Order</b>,
        align: 'center',
        dataIndex: 'order_name',
        key: 'order_name',
        render: (value, record) => (
          <div>
            <Link to={`/orders/${record.orderId}`}>
              {value || `${record.orderId}`}
            </Link>
            {record.onlineReference && (
              <p>Reference: {record.onlineReference}</p>
            )}
          </div>
        ),
      },
      {
        title: <b>Store</b>,
        align: 'center',
        dataIndex: 'storeId',
        key: 'storeId',
        render: (text, record) => {
          return record.storeIntegration
            ? getStoreName(record.storeIntegration)
            : '';
        },
      },
      {
        title: <b>Status</b>,
        align: 'center',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) =>
          getOptionLabel(options_report_manually_sync_tracking, text),
      },
      {
        title: <b>Time request</b>,
        align: 'center',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (value) => moment(value).format('YYYY-MM-DD hh:mm A'),
      },
    ];
    return (
      <Wrapper pageName={pageName}>
        <div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={sync_trackings.data}
            loading={sync_trackings.loading}
            scroll={{ x: 1000 }}
            pagination={
              !this.props.is_mobile
                ? {
                    showTotal: (total) => `Total ${total} items`,
                    total: sync_trackings.total,
                    pageSize: 20,
                    current: sync_trackings.params.page,
                    onChange: (page, perPage) =>
                      this.fetchData({
                        ...sync_trackings.params,
                        page,
                        perPage,
                      }),
                  }
                : false
            }
          />
        </div>
        {this.props.is_mobile && (
          <div className="d-flex justify-content-end align-items-center py-3 px-3">
            <div>
              <Pagination
                size="small"
                showSizeChanger
                className="pagination"
                total={sync_trackings.total}
                defaultCurrent={sync_trackings?.params?.page || 1}
                pageSize={20}
                onChange={(page, perPage) =>
                  this.fetchData({
                    ...sync_trackings.params,
                    page:
                      page === Number.NEGATIVE_INFINITY
                        ? 1
                        : page === 0
                        ? 1
                        : page,
                    perPage,
                  })
                }
              />
            </div>
          </div>
        )}
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    sync_trackings: state.order.reportSyncTracking.sync_trackings,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
  })
)(List);
