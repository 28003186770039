import React from "react";
import classNames from "classnames";
function SelectPaymentMethod({
    input,
    options = [],
    meta: { asyncValidating, error, submitFailed },
    ...rest
  }){
    const handleOnSelectPaymentMethod = (value) => {
      input.onChange(value);
    };
    return (
      <div className="container-fluid px-0">
        <div className="row payment-method-section">
          {options.map((option, index) => (
            <div
              className="col-6 col-sm-4 col-lg mb-3"
              key={index}
            >
              <div
                className={classNames("payment-method-item text-center p-3 h-100 w-100 d-flex flex-column align-items-center justify-content-center", 
                {'payment-method-item-selected' : parseInt(option.value) === parseInt(input.value)})}
                onClick={() => {
                  handleOnSelectPaymentMethod(option.value);
                }}
              >
                <p className="my-2 h5">
                    <img className="img-fluid" alt='G-wallet' src={option.logo}/>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
export default React.memo(SelectPaymentMethod);