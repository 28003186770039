import React, { useState, useEffect } from 'react';
import { Modal, notification, Spin } from 'antd';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { renderTextarea } from 'utils/Form';
import { updateGiftMessage } from 'apis/orders';
import { connect } from 'react-redux';
import { ReactComponent as EditIcon } from 'images/icons/edit-01.svg';
import { GlobalStyle } from 'scenes/Orders/scenes/Detail/styled';
import { GMButton } from 'components/Button';
const validate = (values) => {
  const errors = {};
  if (!values.message || values.message.replace(/\s+/g, '').length === 0) {
    errors.message = 'Please enter your gift message.';
  } else if (values.message.length > 350) {
    errors.message =
      'The maximum length of message is 350. Please check again.';
  }
  return errors;
};

const RenderEditGiftMessage = ({
  order = {},
  message = '',
  handleSubmit,
  onCancel,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currGiftMessage, setCurrGiftMessage] = useState('');
  useEffect(() => {
    if (order?.orderNote?.giftMessage) {
      setCurrGiftMessage(order?.orderNote?.giftMessage);
    }
  }, [order]);
  const handleOnEditGiftMessage = () => {
    setLoading(true);
    updateGiftMessage({
      giftMessage: message,
      orderId: order?.id || 0,
    })
      .then((response) => {
        setLoading(false);
        if (response.code === 'success') {
          setVisible(false);
          notification.success({
            message: response.clientMsg,
            duration: 5,
          });
          setCurrGiftMessage(message);
        } else {
          notification.warn({
            message:
              response?.[0]?.['clientMsg'] || 'Edit gift message failed.',
            duration: 5,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <div>
      <h5 className="mb-2 font-weight-bold d-flex justify-content-between ">
        <span className="title">Gift message</span>
        <span
          className="cursor-pointer text-info font-size-14 button-svg"
          onClick={() => {
            setVisible(true);
          }}
        >
          <EditIcon />
        </span>
      </h5>
      <p>
        {currGiftMessage || (
          <div style={{ color: '#98A2B3' }}>No gift message added yet.</div>
        )}
      </p>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        width={1000}
        okText={'Update'}
        onOk={handleSubmit(handleOnEditGiftMessage)}
        bodyStyle={{ padding: '10px 10px 0px 10px' }}
        footer={false}
      >
        <Spin spinning={loading}>
          <div className={'p-2'}>
            <form onSubmit={handleSubmit}>
              <h4 className="title">Gift message</h4>
              <div className="form-group">
                <Field
                  name="message"
                  component={renderTextarea}
                  rows={4}
                  placeholder="Enter gift message"
                />
              </div>
            </form>
            <div className="row mt-4 d-flex justify-content-end mr-2 mb-2">
              <GMButton
                className="mr-3"
                color={'SecondaryGray'}
                type="button"
                onClick={() => {
                  setVisible(false);
                }}
                size="lg"
              >
                Cancel
              </GMButton>
              <GMButton
                type="button"
                onClick={handleOnEditGiftMessage}
                size="lg"
              >
                Update
              </GMButton>
            </div>
          </div>
        </Spin>
      </Modal>
      <GlobalStyle />
    </div>
  );
};
const selector = formValueSelector('EditGiftMessage');
export default React.memo(
  connect((state, props) => ({
    initialValues: {
      message: '',
    },
    message: selector(state, 'message') || '',
  }))(
    reduxForm({
      form: 'EditGiftMessage',
      validate,
    })(RenderEditGiftMessage)
  )
);
