import {
  BASE_WHITE,
  GRAY_200,
  GRAY_500,
  GRAY_700,
  GRAY_900,
  PRIMARY_500,
  SHADOW_LG_0_03,
  SHADOW_LG_0_08,
} from 'components/colors';
import styled, { createGlobalStyle } from 'styled-components';
import { Badge, Menu, Dropdown } from 'antd';

export const Wrapper = styled.div`
  margin-right: 10px;

  svg {
    stroke: ${BASE_WHITE};
    padding-right: 6px;
  }

  a {
    color: ${BASE_WHITE};
  }
  button {
    display: flex;
    justify-content: center;
    align-items: left;
    a {
      font-weight: 600;
    }
    svg {
      width: 24px;
    }
  }
`;

export const GMNotifyMenu = styled(Menu)`
  min-width: 360px;
  background: ${BASE_WHITE};
  border: 1px solid ${GRAY_200};
  box-shadow: 0px 12px 16px -4px ${SHADOW_LG_0_08},
    0px 4px 6px -2px ${SHADOW_LG_0_03};
  border-radius: 8px;
  padding-bottom: 0;
  li {
    padding: 16px;
    border-bottom: 1px solid ${GRAY_200};
    span {
      color: ${GRAY_700};
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .title {
    padding-top: 20px;
    padding-left: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${GRAY_200};
    line-height: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: ${GRAY_900};
    }
    svg {
      stroke: ${GRAY_700};
      cursor: pointer;
    }
  }
`;
export const WrapperDropdown = styled.div``;
export const GlobalStyle = createGlobalStyle`
.ant-dropdown-menu{
  width: 240px;
  max-width: 100%;
  border-radius: 8px;

}
.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child{
  .money {
    color: ${PRIMARY_500};
  }
  .text{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;

  }
}
`;
