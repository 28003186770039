import { actionType as TYPE } from './actions';
import { notification } from 'antd';

const initialState = {
  importOrder: {
    loading: false,
  },
  exportOrder: {
    loading: false,
    data: [],
  },
};

function ImportOrderReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.IMPORT_ORDER.REQUEST:
      return {
        ...state,
        importOrder: {
          ...initialState.importOrder,
          loading: true,
        },
      };
    case TYPE.IMPORT_ORDER.SUCCESS:
      notification.success({
        message: 'Import order successfully.',
        duration: 5,
      });
      return {
        ...state,
        importOrder: {
          ...initialState.importOrder,
          loading: false,
        },
      };
    case TYPE.IMPORT_ORDER.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Import order failed.',
        duration: 5,
      });
      return {
        ...state,
        importOrder: {
          ...initialState.importOrder,
          loading: false,
        },
      };
    //Export order
    case TYPE.EXPORT_ORDER.REQUEST:
      return {
        ...state,
        exportOrder: {
          ...initialState.exportOrder,
          loading: true,
        },
      };
    case TYPE.EXPORT_ORDER.SUCCESS:
      notification.success({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Import order successfully.',
        duration: 5,
      });
      return {
        ...state,
        exportOrder: {
          ...initialState.exportOrder,
          loading: false,
        },
      };
    case TYPE.EXPORT_ORDER.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Import order failed.',
        duration: 5,
      });
      return {
        ...state,
        exportOrder: {
          ...initialState.exportOrder,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ImportOrderReducer;
