import List from './scenes/List'
import Create from './scenes/Create'
import Edit from './scenes/Edit'


export default [
    {
        key: 'promotions',
        name: 'Promotions',
        component: List,
        path: '/promotions',
        icon: null,
        template: 'default',  
    },
    {
        key: 'promotions.create',
        name: 'Create promotion',
        component: Create,
        path: '/promotions/create',
        group: 'product',
        icon: null,
        template: 'default',  
    },
    {
        key: "promotions.detail",
        name: "Edit Promotions",
        component: Edit,
        path: "/promotions/:id/edit",
        icon: null,
        template: "default",
      },
]