import React, {Component} from 'react';
import {Upload, Spin, Button, notification} from 'antd';
import {API_TOKEN_KEY, S3_CDN} from "../../web.config";
import {createFileName, createFolderName, getCookie, getSizeImage, resizeImage} from "../helper";
import getMomentInstance from "../moment";
import {getPresignedUrlS3, getPresignedUrlS3PDF, uploadArtwork} from "../../apis/image_design";
import {uploadBarcode} from "../../apis/orders";
import axios from "axios";

class renderImageDesignUpload3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            value: ''
        };
    }

    render() {
        const {
            data = {},
            accept = ".png, .pdf",
            linkFileTitle = "Link file barcode",
            showLinkFile = false,
            reloadImageDesignList = null,
            changeField = null,
            buttonTitle = 'Upload image',
            children,
            paramName = 'file',
            url,
            multiple,
            width,
            height,
            typeUpload = 'avatar',
            hideResize,
            showUploadList,
            cusId = 0,
            prefixName = 'artwork',
            customPrefix = 'tmp'
        } = this.props;
        const props = {
            accept: accept,
            listType: "picture-card",
            showUploadList: showUploadList,
            name: paramName,
            multiple: multiple ? true : false,
            action: url,
            headers: {
                // common: {
                Authorization: `Bearer ${getCookie(API_TOKEN_KEY) /* auth cookie */}`
                // }
            },
            data,
            beforeUpload: file => {
                if(typeUpload === 'barcode'){
                    if (file.type !== 'application/pdf') {
                        notification.warn({
                            className: "notic1",
                            message: (`${file.name} is not a pdf file.`)
                        });
                    }
                    if (file.size >2e8) {
                        notification.warn({
                            className: "notic1",
                            message: (`${file.name} is larger than 200MB.`)
                        });
                    }
                    return file.type === 'application/pdf' && file.size <=2e8;
                }else{
                    if (file.type !== 'image/png') {
                        notification.warn({
                            className: "notic1",
                            message: (`${file.name} is not a png file.`)
                        });
                    }
                    if (file.size >2e8) {
                        notification.warn({
                            className: "notic1",
                            message: (`${file.name} is larger than 200MB.`)
                        });
                    }
                    return file.type === 'image/png' && file.size <=2e8;
                }
            },
            customRequest: async (
                {
                    action,
                    data,
                    file,
                    filename,
                    headers,
                    onError,
                    onProgress,
                    onSuccess,
                    withCredentials
                }
            ) => {
                const folderName = createFolderName(cusId);
                const extension = file.name.split('.').pop();
                const fileName = createFileName(extension || 'png', prefixName, cusId);
                const originalName = file.name.replace(`.${extension}`, '');
                const fileType = (extension === 'pdf' ? 'application' : 'image') + `/${extension}`;
                const keyToCreatePresignedUrl = `${customPrefix}/${folderName}/${getMomentInstance(null, 0, false, true)}/${fileName}`;
                const response = extension === 'pdf' ? await getPresignedUrlS3PDF(keyToCreatePresignedUrl) : await getPresignedUrlS3(keyToCreatePresignedUrl);
                if (response && response.code === 'success') {
                    axios.put(response.data.url, file, {
                        headers: {
                            'Content-Type': fileType
                        }
                    }).then(response => {
                        if (parseInt(response.status ) === 200) {
                            onSuccess({
                                code: 'success',
                                url: `${S3_CDN}/${keyToCreatePresignedUrl}`,
                                folderName: folderName,
                                fileName: fileName,
                                fileType,
                                extension,
                                originalName
                            }, file);
                        } else {
                            onError();
                        }
                    });
                }
            },
            onChange: (info) => {
                const {status, response, originFileObj} = info.file;
                if (status === "uploading") {
                    this.setState({loading: true});
                }
                if (status === "done" && response.code === 'success') {
                    if (typeUpload === 'barcode') {
                        this.setState({value: response.url})
                        uploadBarcode(response.url, response.originalName).then(response2 => {
                            if(response2.code === 'success' && response2?.data?.barcode?.id){
                                notification.success({
                                    className: "notic2",
                                    message: (`${info.file.name} file uploaded successfully.`)
                                });
                                changeField(response2.data.barcode.id);
                            }else{
                                notification.error({
                                    className: "notic1",
                                    message: `${info.file.name} file upload failed.`
                                });
                            }
                            this.setState({ loading: false });
                        });
                    }else{
                        getSizeImage(response.url, async (width, height) => {
                            const resizedImage = await resizeImage(originFileObj, 650, Math.round((650 * height)/width), 'file', response.extension.toUpperCase());
                            const keyToCreatePresignedUrl2 = `${customPrefix}/${response.folderName}/${getMomentInstance(null, 0, false, true)}/thumbnail/${response.fileName}`;
                            const response2 = await getPresignedUrlS3(keyToCreatePresignedUrl2);
                            if (response2 && response2.code === 'success') {
                                axios.put(response2.data.url,  resizedImage, {
                                    headers: {
                                        'Content-Type': response.fileType
                                    }
                                }).then(response3 => {
                                    if (parseInt(response3.status) === 200) {
                                        if (typeUpload === 'upload-design-image') {
                                            uploadArtwork(response.url, `${response.originalName}.${response.extension}`).then(response4 => {
                                                if(response4.code === 'success'){
                                                    reloadImageDesignList();
                                                    notification.success({
                                                        className: "notic2",
                                                        message: (`${info.file.name} file uploaded successfully.`)
                                                    });
                                                }else{
                                                    notification.error({
                                                        className: "notic1",
                                                        message: `${info.file.name} file upload failed.`
                                                    });
                                                }
                                                this.setState({ loading: false });
                                            });
                                        }
                                    }else{
                                        notification.error({
                                            className: "notic1",
                                            message: `${info.file.name} file upload failed.`
                                        });
                                    }
                                });
                            }else{
                                notification.error({
                                    className: "notic1",
                                    message: `${info.file.name} file upload failed.`
                                });
                            }
                        })
                    }
                } else if (status === "error") {
                    notification.error({
                        className: "notic1",
                        message: `${info.file.name} file upload failed.`
                    });
                    this.setState({loading: false});
                }
            },
        };
        return (

            <div className="image-design-upload">
                <Upload {...props}>
                    <Spin spinning={this.state.loading}><Button type="primary">{buttonTitle}</Button></Spin>
                    {children}
                </Upload>
                <p>{(showLinkFile && this.state.value) && <a href={this.state.value} target={"_blank"} rel="noopener noreferrer">{linkFileTitle}</a>}</p>
                {width && height && !hideResize &&
                <span className="d-block mb-2">{`Hình ảnh sẽ được resize về kích thước ${width} x ${height}`}</span>}
            </div>
        );
    }
};

export default renderImageDesignUpload3