export const actionType = {
    SYNC_ORDER: {
        REQUEST: 'ORDER.SYNC_ORDER.REQUEST',
        SUCCESS: 'ORDER.SYNC_ORDER.SUCCESS',
        ERROR: 'ORDER.SYNC_ORDER.ERROR',
    }
};

export function syncOrder(data){
    return {
        type: actionType.SYNC_ORDER.REQUEST,
        data
    }
}

