export const actionType = {
  REFETCH_BILLING_INFORS: 'BILLING_INFOR.REFETCH_BILLING_INFORS',
  SET_LOADING: 'BILLING_INFOR.SET_LOADING',
  SAVE_BILLING_METHOD: {
    REQUEST: 'BILLING_INFOR.SAVE_BILLING_METHOD.REQUEST',
    SUCCESS: 'BILLING_INFOR.SAVE_BILLING_METHOD.SUCCESS',
    FAILED: 'BILLING_INFOR.SAVE_BILLING_METHOD.FAILED',
  },
  UPDATE_BILLING_METHOD: {
    REQUEST: 'BILLING_INFOR.UPDATE_BILLING_METHOD.REQUEST',
    SUCCESS: 'BILLING_INFOR.UPDATE_BILLING_METHOD.SUCCESS',
    FAILED: 'BILLING_INFOR.UPDATE_BILLING_METHOD.FAILED',
  },
  LOAD_PRIMARY_BILLING_METHOD: {
    REQUEST: 'BILLING_INFOR.LOAD_PRIMARY_BILLING_METHOD.REQUEST',
    SUCCESS: 'BILLING_INFOR.LOAD_PRIMARY_BILLING_METHOD.SUCCESS',
    FAILED: 'BILLING_INFOR.LOAD_PRIMARY_BILLING_METHOD.FAILED',
  },
  LOAD_LIST_BILLING_METHOD: {
    REQUEST: 'BILLING_INFOR.LOAD_LIST_BILLING_METHOD.REQUEST',
    SUCCESS: 'BILLING_INFOR.LOAD_LIST_BILLING_METHOD.SUCCESS',
    FAILED: 'BILLING_INFOR.LOAD_LIST_BILLING_METHOD.FAILED',
  },
  LOAD_LOG_BILLING_METHOD: {
    REQUEST: 'BILLING_INFOR.LOAD_LOG_BILLING_METHOD.REQUEST',
    SUCCESS: 'BILLING_INFOR.LOAD_LOG_BILLING_METHOD.SUCCESS',
    FAILED: 'BILLING_INFOR.LOAD_LOG_BILLING_METHOD.FAILED',
  },
  SET_PRIMARY: {
    REQUEST: 'BILLING_INFOR.SET_PRIMARY.REQUEST',
    SUCCESS: 'BILLING_INFOR.SET_PRIMARY.SUCCESS',
    FAILED: 'BILLING_INFOR.SET_PRIMARY.FAILED',
  },
  DELETE: {
    REQUEST: 'BILLING_INFOR.DELETE.REQUEST',
    SUCCESS: 'BILLING_INFOR.DELETE.SUCCESS',
    FAILED: 'BILLING_INFOR.DELETE.FAILED',
  },
  DETAIL_BILLING_METHOD: {
    REQUEST: 'BILLING_INFOR.DETAIL_BILLING_METHOD.REQUEST',
    SUCCESS: 'BILLING_INFOR.DETAIL_BILLING_METHOD.SUCCESS',
    FAILED: 'BILLING_INFOR.DETAIL_BILLING_METHOD.FAILED',
  },
};
export function getDetailBillingMethodAction(id) {
  return {
    type: actionType.DETAIL_BILLING_METHOD.REQUEST,
    id,
  };
}
export function refetchBillingPaymentAccount(isRefetching = false) {
  return {
    type: actionType.REFETCH_BILLING_INFORS,
    isRefetching,
  };
}

export function setLoading(loading = false) {
  return {
    type: actionType.SET_LOADING,
    loading,
  };
}

export function saveBillingMethodAction(payload) {
  return {
    type: actionType.SAVE_BILLING_METHOD.REQUEST,
    payload,
  };
}

export function updateBillingMethodAction(payload) {
  return {
    type: actionType.UPDATE_BILLING_METHOD.REQUEST,
    payload,
  };
}

export function loadPrimaryAccountAction() {
  return {
    type: actionType.LOAD_PRIMARY_BILLING_METHOD.REQUEST,
  };
}

export function loadListAccountAction(
  payload = {
    'with[]': 'billingInfor',
  }
) {
  return {
    type: actionType.LOAD_LIST_BILLING_METHOD.REQUEST,
    payload,
  };
}

export function setPrimaryAction(payload) {
  return {
    type: actionType.SET_PRIMARY.REQUEST,
    payload,
  };
}

export function loadLogsAction(payload) {
  return {
    type: actionType.LOAD_LOG_BILLING_METHOD.REQUEST,
    payload,
  };
}
export function deleteBillingMethodAction(payload) {
  return {
    type: actionType.DELETE.REQUEST,
    payload,
  };
}
