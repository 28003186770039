import React, { memo, useState, useEffect, useRef } from "react";
import { AdsOverlay, AdsWrapper } from "./styled";
import { ReactComponent as CloseIcon } from "images/icons/x-close.svg";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { getStore, setStore } from "utils/helper";
import { ReactComponent as RightIcon } from "images/icons/chevron-right.svg";
import { ReactComponent as LeftIcon } from "images/icons/chevron-left.svg";
import moment from "moment";

import { isElementHidden } from "./utils";

import ImgLoader from "images/Promotion/loader.svg";
import MImgLoader from "images/Promotion/mloader.svg";

import P_2509_101024 from "images/Promotion/popup_25092024_pc.png";
import M_2509_101024 from "images/Promotion/popup_25092024_mobile.png";

const CustomNavigate = ({ children, className, customClassName, ...p }) => {
  return (
    <div className={customClassName} {...p}>
      <div>{children}</div>
    </div>
  );
};

const settings = {
  dots: false,
  infinite: !!1,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: "0",
  arrows: !!1,
  autoplay: !!1,
  useTransform: !!1,
  autoplaySpeed: 8000,
  centerMode: true,
  dots: !!1,
  nextArrow: (
    <CustomNavigate customClassName="gm-nav-next">
      <RightIcon />
    </CustomNavigate>
  ),
  prevArrow: (
    <CustomNavigate customClassName="gm-nav-prev">
      <LeftIcon />
    </CustomNavigate>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: !1
        // dots: !1
      }
    }
  ]
};
const slides = [
  {
    id: 1704271633731,
    img: P_2509_101024,
    mobileImg: M_2509_101024,
    link: 'https://help.gearment.com/en-us/article/2-business-day-commitment-for-tiktok-label-orders-i5iutu/',
    from: "25/09/2024 00:00:00 -07:00",
    to: "10/10/2024 23:59:59 -07:00",
    aspect_ratio: "unset",
  }
];

export let GMADS_STATUS = "GM_ADS__STATUS__";
const now = moment();
let postfix = "";
slides.forEach((banner, index) => {
  const from = moment(banner.from, "DD/MM/YYYY hh:mm:ss Z");
  const to = moment(banner.to, "DD/MM/YYYY hh:mm:ss Z");
  if (banner.from && from.diff(now, "seconds") > 0) {
    slides[index] = null;
    return;
  }
  if (banner.to && to.diff(now, "seconds") < 0) {
    slides[index] = null;
    return;
  }
  postfix += banner?.id;
});
GMADS_STATUS += postfix;

if (slides.length <= 1) {
  settings.autoplay = !1;
}
let sessionIsShow = true;
export default memo(() => {
  const userInfo = useSelector((s) => s?.auth?.info?.profile || {});
  const storeCount = getStore(GMADS_STATUS + "__" + userInfo?.code) || 0;
  const [isShow, setShow] = useState(storeCount <= 5 - 1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDontShowAgain, setDontShowAgain] = useState(false);
  const adsRef = useRef();

  useEffect(() => {
    if (storeCount < 5 && sessionIsShow) {
      setStore(GMADS_STATUS + "__" + userInfo?.code, storeCount + 1);
    }
  }, []);
  useEffect(() => {
    if (!adsRef.current) {
      return;
    }
    const allSlideVids = [...adsRef.current?.getElementsByTagName("video")];
    allSlideVids.forEach((vid) => {
      // eslint-disable-next-line no-unused-expressions
      vid?.pause();
      vid.currentTime = 0;
    });
    const vids = [
      ...adsRef.current
        .getElementsByClassName("slick-current")?.[0]
        ?.getElementsByTagName("video")
    ];
    if (vids.length <= 0) {
      return;
    }
    vids.forEach((vid) => {
      if (!isElementHidden(vid)) {
        // eslint-disable-next-line no-unused-expressions
        vid?.play();
      }
    });
  }, [currentSlide]);
  if (!userInfo?.code || storeCount > 5 - 1 || !isShow || !sessionIsShow) {
    return null;
  }
  if (
    slides.length < 0 ||
    slides.length === slides.filter((s) => s === null).length
  ) {
    return null;
  }
  const loader = (
    <>
      <img src={ImgLoader} className="loader" />
      <img src={MImgLoader} className="loader mobile" />
    </>
  );
  return (
    <>
      <AdsOverlay />
      <AdsWrapper ref={adsRef}>
        <div className="inner-wrapper">
          <Slick {...settings} afterChange={(c) => setCurrentSlide(c)}>
            {slides.map((banner, k) => {
              if (!banner) {
                return null;
              }
              if (banner.video) {
                return (
                  <div className="vid-banner skeleton" key={"gm-ads__" + k}>
                    {loader}
                    <video preload="yes" className="ds" loop playsInline muted>
                      <source src={banner.video} type="video/mp4" />
                    </video>
                    <video preload="yes" className="mb" loop playsInline muted>
                      <source src={banner.mobileVideo} type="video/mp4" />
                    </video>
                    <a
                      href={banner.link}
                      className="link-overlay"
                      target="_blank"
                    >
                      &nbsp;
                    </a>
                  </div>
                );
              }
              return (
                <div style={{textAlign:"center"}} className="skeleton" key={"gm-ads__" + k}>
                  <a href={banner.link} target="_blank">
                    <img src={banner.img} className="ds" style={{marginLeft:banner.marginleft,maxWidth:banner.width,aspectRatio:banner.aspect_ratio,display:banner.display}}/>
                    <img src={banner.mobileImg} className="mb" />
                  </a>
                </div>
              );
            })}
          </Slick>
          <div
            className="close-btn"
            onClick={(evt) => {
              evt.preventDefault();
              if (isDontShowAgain) {
                setStore(GMADS_STATUS + "__" + userInfo?.code, 68);
              }
              sessionIsShow = false;
              setShow(false);
              return false;
            }}
          >
            <CloseIcon />
          </div>
          {/* <div className="alt-confirm">
            <Checkbox
              onChange={(v) => {
                setDontShowAgain(v?.target?.checked);
              }}
            >
              Don’t show again
            </Checkbox>
          </div> */}
        </div>
      </AdsWrapper>
    </>
  );
});
