import { GlowingSVG } from "components/SVG";
import React, {useState} from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, change } from "redux-form";
import { ReactComponent as KeyIcon } from 'images/icons/key-01.svg';
import { PRIMARY_100, PRIMARY_50, PRIMARY_600 } from "components/colors";
import { ReactComponent as AlertCircle } from 'images/icons/alert-circle.svg';
import { GMButton } from "components/Button";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { clearMessage } from "../actions";
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA3_KEY } from 'web.config';
import {GMTextbox} from "../../../../../components/InputFields";
import ResetPasswordSuccess from "./ResetPasswordSuccess";

const siteKey = RECAPTCHA3_KEY;


const FormResetPassword = ({ handleSubmit }) => {
  const serverErrors = useSelector(state => state.user.reset_password.serverErrorInfo);
  const dispatch = useDispatch();

    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

    const onRecaptchaChange = (value) => {
        if (value) {
            setIsCaptchaVerified(true); // Nếu reCAPTCHA trả về giá trị, nghĩa là người dùng đã vượt qua
        } else {
            setIsCaptchaVerified(false);
        }
    };

  useEffect(() => {
    dispatch(clearMessage());
  }, []);

  const errorMessage = serverErrors && serverErrors.length > 0 ? (
    serverErrors.map(error => {
      switch (error?.code) {
        case 'invalid_param':
          return (
            <li>
              <AlertCircle />
              <span className="error">Incorrect</span>
            </li>
          );
        default:
          return null;
      }
    })
  ) : null;

  const serverSuccess = useSelector(state => state.user.reset_password.serverSuccessInfo);
  const cusEmail = useSelector((s) => s?.auth?.info?.profile.email || "");

  const form = (
    <form onSubmit={handleSubmit}>
      <div className="text-center logo">
        <GlowingSVG
          backgroundColor={PRIMARY_100}
          borderColor={PRIMARY_50}
          svgStrokeColor={PRIMARY_600}
        >
          <KeyIcon />
        </GlowingSVG>
      </div>
      <h3 className="text-center title">Reset password</h3>
      {errorMessage ? (
        <ul className="error-list">{errorMessage}</ul>
      ) : null}
        <div className="form-group">
            <ReCAPTCHA
                sitekey={siteKey}
                onChange={onRecaptchaChange}
            />
        </div>
        <div className="form-group">
            <GMTextbox
                name="email"
                value={cusEmail}
                type="hidden"
            />
        </div>
      <div className="form-group">
        <GMButton type="submit" className="btn btn-primary cta-changepss" disabled={!isCaptchaVerified}>
          Reset Password
        </GMButton>
      </div>
    </form>
  );

  return (
      serverSuccess && serverSuccess.code == "success" ? <ResetPasswordSuccess /> : form
  );
};

const mapStateToProps = (state) => ({
  form_values: state.form.FormResetPassword,
  initialValues: {
    email: state.auth.info?.profile?.email,
  } 

});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change("FormResetPassword", field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "FormResetPassword",
    enableReinitialize: true,
  })(FormResetPassword)
);
