import React, { useState, useEffect } from 'react';
import { Collapse, Col, Affix } from 'antd';
import {
  Field,
  reduxForm,
  submit,
  change,
  formValueSelector,
  FieldArray,
} from 'redux-form';
import { connect } from 'react-redux';
import RenderTagFilter from 'utils/Form/RenderTagFilter';
import RenderAdvancedFilterButtons from 'utils/Form/RenderAdvancedFilterButtons';
import SelectProduct from 'utils/Form/Search/SelectProduct';
import SelectMultipleTagSizeProduct from 'utils/Form/Search/SelectMultipleTagSizeProduct';
import { clearFilter } from 'utils/helper';
import SelectMultipleTagColorProduct from 'utils/Form/Search/SelectMultipleTagColorProduct';
import { FilterWrapper } from 'scenes/Orders/scenes/List/styled';
import GMRow from 'components/GMLayout/GMRow';

const { Panel } = Collapse;
const paramsName = {
  stockType: 'Stock Type',
  productId: 'Product',
  sizeIds: 'Size',
  colorIds: 'Color',
};
const defaultValues = {
  stockType: -1,
  colorIds: [],
  sizeIds: [],
};
let paramsValueAlias = {
  stockType: {
    3: 'In stock',
    0: 'Out of stock',
    1: 'Temporarily unavailable',
  },
};
const AdvancedFilter = (props) => {
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  useEffect(() => {
    if (
      (Array.isArray(props?.exproducts) && props?.exproducts.length > 0) ||
      (Array.isArray(colors) && colors.length > 0) ||
      (Array.isArray(sizes) && sizes.length > 0)
    ) {
      const listProducts = {};
      (props?.exproducts || []).forEach((exproduct) => {
        listProducts[exproduct.id] = exproduct.brand;
      });
      const listColors = {};
      (colors || []).forEach((color) => {
        listColors[color.id] = color.name;
      });
      const listSizes = {};
      (sizes || []).forEach((size) => {
        listSizes[size.id] = size.name;
      });
      paramsValueAlias = {
        ...paramsValueAlias,
        productId: listProducts,
        colorIds: listColors,
        sizeIds: listSizes,
      };
    }
  }, [
    JSON.stringify(props?.exproducts),
    JSON.stringify(sizes),
    JSON.stringify(colors),
  ]);
  const submitFilters = (isClosedAdvanedFileter = true) => {
    props.dispatch(submit('formStockStatusForm'));
    if (isClosedAdvanedFileter) props.onCloseAdvancedSearch();
  };
  const {
    handleSubmit,
    productId,
    colorId,
    sizeIds,
    stock_status,
    changeFieldValue,
    exproducts,
    colorIds,
  } = props;
  return (
    <FilterWrapper>
      <form onSubmit={handleSubmit}>
        <RenderTagFilter
          params={stock_status.params}
          paramsName={paramsName}
          paramsValueAlias={paramsValueAlias}
          defaultValues={defaultValues}
          changeFieldValue={changeFieldValue}
          isAllowedToClearFilter={true}
          submitFilters={submitFilters}
        />
        <div className="p-3">
          <Collapse
            defaultActiveKey={['1', '2', '3', '4']}
            bordered={true}
            ghost
            expandIconPosition="right"
          >
            <Panel header={<b className="header-filter">Product</b>} key="1">
              <GMRow>
                <Col span={24}>
                  <Field
                    name={`productId`}
                    component={SelectProduct}
                    data={exproducts}
                    onChoose={() => {
                      changeFieldValue('colorIds', []);
                      changeFieldValue(`sizeIds`, []);
                    }}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Color</b>} key="2">
              <GMRow>
                <Col span={24}>
                  <FieldArray
                    name={`colorIds`}
                    component={SelectMultipleTagColorProduct}
                    expId={productId}
                    setColors={setColors}
                    selectedColorIds={colorIds}
                    setDefaultColor={(colorId) => {
                      changeFieldValue('colorId', colorId);
                    }}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Size</b>} key="3">
              <GMRow>
                <Col span={24}>
                  <FieldArray
                    name={`sizeIds`}
                    setSizes={setSizes}
                    component={SelectMultipleTagSizeProduct}
                    expId={productId}
                    colorId={colorId}
                    selectedSizeIds={sizeIds}
                  />
                </Col>
              </GMRow>
            </Panel>
          </Collapse>
        </div>
      </form>
      <Affix offsetBottom={0}>
        <RenderAdvancedFilterButtons
          handleOnSubmit={() => {
            submitFilters();
          }}
          handleOnClearFilters={() => {
            clearFilter(changeFieldValue, {
              sortBy: 'id',
              sortType: 'desc',
              stockType: '3',
              productId: '0',
              sizeIds: [],
              colorIds: [],
            });
            setTimeout(
              () => props.dispatch(submit('formStockStatusForm')),
              1000
            );
          }}
        />
      </Affix>
    </FilterWrapper>
  );
};
const selector = formValueSelector('formStockStatusForm');
const AdvancedFilterReduxForm = reduxForm({
  form: 'formStockStatusForm',
  enableReinitialize: true,
  //validate,
})(AdvancedFilter);

const mapStateToProps = (state) => ({
  productId: selector(state, 'productId') || 0,
  colorId: selector(state, 'colorId') || 0,
  sizeIds: selector(state, 'sizeIds') || [],
  colorIds: selector(state, 'colorIds') || [],
  formStockStatusForm: state.form.formStockStatusForm?.values || {},
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change('formStockStatusForm', field, value));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedFilterReduxForm);
