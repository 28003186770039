import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Spin, Pagination } from 'antd';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import getMomentInstance from 'utils/moment';
import { getList, markAsRead, getListNotiRead } from './actions';
import { getOptionLabel, options_notification_status } from 'utils/options';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getList(params);
  };

  render() {
    const { notifications, processing, pageName } = this.props;
    const columns = [
      {
        title: <b>#</b>,
        dataIndex: 'id',
        key: 'id',
        width: 50,
        render: (value, record, i) => (
          <div>
            <Link to={`/notification/${record.id}`}>#{value}</Link>
          </div>
        ),
      },
      {
        title: <b>TITLE</b>,
        dataIndex: 'name',
        key: 'name',
        width: 250,
        render: (value, record) => (
          <div>
            <Link to={`/notification/${record.id}`}>{value}</Link>
          </div>
        ),
      },
      {
        title: <b>STATUS</b>,
        dataIndex: 'status',
        align: 'center',
        key: 'status',
        width: 150,
        render: (value) =>
          getOptionLabel(options_notification_status, value, false),
      },
      {
        title: <b>CREATED TIME</b>,
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        width: 200,
        render: (text, record) => getMomentInstance(text, 0, true),
      },
    ];

    return (
      <Wrapper
        pageName={pageName}
        ctas={
          <Spin spinning={processing}>
            <GMButton
              onClick={() => this.props.markAsRead()}
              type="button"
              size="lg"
              color={'SecondaryGray'}
            >
              Mark all as read
            </GMButton>
          </Spin>
        }
      >
        <div>
          <div>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={notifications.data}
              loading={notifications.loading}
              scroll={{ x: 1000 }}
              pagination={
                !this.props.is_mobile
                  ? {
                      showTotal: (total) => `Total ${total} items`,
                      total: notifications.total,
                      pageSize: parseInt(notifications.params.perPage),
                      current: notifications.params.page,
                      showSizeChanger: true,
                      onChange: (page, perPage) =>
                        this.fetchData({
                          ...notifications.params,
                          page,
                          perPage,
                        }),
                      onShowSizeChange: (current, perPage) =>
                        this.fetchData({ ...notifications.params, perPage }), //current * pageSize})
                    }
                  : false
              }
            />
            {this.props.is_mobile && (
              <div className="d-flex justify-content-end align-items-center py-3 px-3">
                <div>
                  <Pagination
                    size="small"
                    showSizeChanger
                    className="pagination"
                    total={notifications.total}
                    defaultCurrent={notifications?.params?.page || 1}
                    pageSize={notifications?.params?.perPage || 0}
                    onChange={(page, perPage) =>
                      this.fetchData({
                        ...notifications.params,
                        page:
                          page === Number.NEGATIVE_INFINITY
                            ? 1
                            : page === 0
                            ? 1
                            : page,
                        perPage,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    notifications: state.notification.list.notifications,
    processing: state.notification.list.processing,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
    getListNotiRead: (options) => {
      dispatch(getListNotiRead(options));
    },
    markAsRead: () => {
      dispatch(markAsRead());
    },
  })
)(List);
