import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  updateInvoiceConfigs,
  updateReturnAddress,
  setDefaultGatewaySetting,
  getAuthorization,
} from './actions';
import { Wrapper } from './styled';

import { ReactComponent as Icon1 } from 'images/icons/settings-02.svg';
import { ReactComponent as Icon2 } from 'images/icons/user-01.svg';
import { ReactComponent as Icon5 } from 'images/icons/users-01.svg';
import { ReactComponent as Icon6 } from 'images/icons/sale-04.svg';
import { ReactComponent as Icon7 } from 'images/icons/shopping-bag-02.svg';
import { ReactComponent as Icon8 } from 'images/icons/gift-01.svg';
import { ReactComponent as Icon9 } from 'images/Icon_shiping.svg';
import { Spin } from 'antd';

class ListShipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKeyTag: '1',
    };
  }

  componentDidMount() {
    let state = { ...this.props.history.location.state };
    let { activeTag = '1' } = state;
    this.setState({ activeKeyTag: activeTag });
    this.props.getAuthorization();
    this.props.setDefaultGatewaySetting();
  }
  updateInvoiceConfigs = (value) => {
    let dataToUpdate = {
      configs: {
        storeName: value.storeName,
        email: value.email,
        phone: value.phone,
        firstName: value.firstName,
        lastName: value.lastName,
        address: value.address,
        address2: value.address2,
        city: value.city,
        state: value.state,
        postalCode: value.postalCode,
        country: value.country,
      },
    };
    this.props.updateInvoiceConfigs(dataToUpdate);
  };
  updateReturnAddress = (value) => {
    let dataToUpdateReturnAddress = {
      configs: {
        company: value.company,
        phone: value.phone,
        address: value.address,
        address2: value.address2,
        city: value.city,
        state: value.state,
        postalCode: value.postalCode,
        country: value.country,
      },
    };
    this.props.updateReturnAddress(dataToUpdateReturnAddress);
  };

  handleChangeTab = (activeKey) => {
    this.setState({ activeKeyTag: activeKey });
  };
  render() {
    const { pageName, profile, authorizationLoading } = this.props;
    const info = [
      {
        icon: <Icon1 />,
        link: '/settings/general',
        title: 'General',
        description: 'View and update your store details',
        permission: 'show',
      },
      {
        icon: <Icon2 />,
        link: '/settings/profile',
        title: 'Profile',
        description: 'Manage your account, email, password',
        permission: 'show',
      },
      // {
      //   icon: <Icon3 />,
      //   link: '/billing/method',
      //   title: 'Payment',
      //   description: 'Setup primary account payment method',
      // },
      // {
      //   icon: <Icon4/>,
      //   link: '/billing/legal-info',
      //   title: 'Legal information',
      //   description: 'Manage your store’s legal pages',
      // },
      {
        icon: <Icon5 />,
        link: '/settings/staff-accounts',
        title: 'Staff account',
        description: 'Manage what users can see or do in your store',
        permission: profile.primaryId === 0 ? 'show' : 'hide',
      },
      {
        icon: <Icon6 />,
        link: '/settings/api-information',
        title: 'Sale channels',
        description:
          'Manages the channels you use to sell your products and services',
        permission: 'show',
      },
      {
        icon: <Icon7 />,
        link: '/settings/shipping-delivery',
        title: 'Shipping and delivery',
        description: 'Manage how you ship orders to customers',
        permission: 'show',
      },
      {
        icon: <Icon8 />,
        link: '/settings/gift-message',
        title: 'Gift message',
        description: 'Create & manage gift message expiry dates',
        permission: 'show',
      },
      {
        icon: <Icon9 />,
        link: '/settings/config-buy-tracking',
        title: 'Config buy tracking',
        description: 'Config buy tracking',
        permission: 'show',
      },
    ];
    return (
      <Wrapper pageName={pageName} noContainer>
        <Spin spinning={authorizationLoading}>
          {' '}
          <div className="main-content">
            <div className="inner-wrapper">
              {info.map((st) => {
                return (
                  st.permission === 'show' && (
                    <Link to={st.link}>
                      <div className="title">
                        {st.icon}
                        <span>{st.title}</span>
                      </div>
                      <div className="description">{st.description}</div>
                    </Link>
                  )
                );
              })}
            </div>
          </div>
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    updateInvoiceLoading: state.setting.general.updateInvoiceConfigs.loading,
    updateReturnLoading: state.setting.general.updateReturnAddress.loading,
    listDefaultGateway: state.setting.general.defaultGateway.data,
    listDefaultGatewayLoading: state.setting.general.defaultGateway.loading,
    authorization: state.setting.general.authorization.data,
    authorizationLoading: state.setting.general.authorization.loading,
    profile: state.auth.info.profile,
  }),
  (dispatch) => ({
    getAuthorization: () => {
      dispatch(getAuthorization());
    },
    updateInvoiceConfigs: (dataToUpdate) => {
      dispatch(updateInvoiceConfigs(dataToUpdate));
    },
    updateReturnAddress: (dataToUpdateReturnAddress) => {
      dispatch(updateReturnAddress(dataToUpdateReturnAddress));
    },
    setDefaultGatewaySetting: () => {
      dispatch(setDefaultGatewaySetting());
    },
  })
)(ListShipping);
