import { 
  takeLatest, 
  call, 
  put, 
  all 
} from 'redux-saga/effects'
import {
    actionType
} from './actions'

import {forgetPassword} from "apis/auth";

function* forgetPasswordSaga(action) {
    try {
        const { data } = action
        const response = yield call(forgetPassword, data)
        if(response.code === 'success'){
            yield all([
                put({type: actionType.FORGET.SUCCESS, ...{...response, data}}),
            ])
        }else{
          yield put({type: actionType.FORGET.ERROR, error: response})
        }
    } catch (error) {
        yield all([
            put({type: actionType.FORGET.ERROR, error})
        ])
    }
}

function* watcher() {
    
    yield all([
        takeLatest(actionType.FORGET.REQUEST,  forgetPasswordSaga)
    ])
}

export default watcher