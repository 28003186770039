import React, { useState } from 'react';
import { PictureFilled } from '@ant-design/icons';
import {
  Spin,
  Modal,
  Empty,
  Input,
  notification,
  Button,
  Dropdown,
} from 'antd';
import { search, archive } from 'apis/image_design';
import classNames from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ReactComponent as TrashIcon } from 'images/icons/trash-01.svg';
import { GMButton } from 'components/Button';
import { Wrapper, GlobalStyle, ExtendModal } from './styled';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';
import Filter from 'components/Filter';
import UploadImage from './component/UploadImage';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import { ContextMenuWrapper } from 'scenes/BillingPaymentAccount/v2/styled';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { assignDesign, deleteDesign } from 'apis/orders';
const { confirm } = Modal;

export const HandleModalImage = ({
  changeDesignFront,
  changeDesignBack,
  changeDesignFrontId,
  changeDesignBackId,
  input,
  cusId = 0,
  meta,
  buttonTitle = 'Upload your image',
  isCreatingOrder = false,
  isOnlyUpload = false,
  isButtonPlaceholder = false,
  printLocation = 'front,back',
  orderId,
  orderItemId,
  typePrint = '',
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [original, setOriginal] = useState('');
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState({});
  const [dataRemove, setDataRemove] = useState({});

  const [pagination, setPagination] = useState({
    total: 0,
    perPage: 16,
    currentPage: 1,
  });

  const handleShowModal = (show) => {
    setVisible(show);
    setImages([]);
    if (show) {
      searchImage(original);
    }
  };

  const searchImage = (q, is_more) => {
    setOriginal(q);
    setLoading(true);
    search({
      original: q,
      page: is_more ? (pagination?.currentPage || 1) + 1 : 1,
      perPage: 18,
    })
      .then(({ code, data }) => {
        setLoading(false);
        if (code === 'success') {
          setImages(
            is_more ? [...images, ...data.imageDesign] : data.imageDesign
          );
          setPagination(data.pagination);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const archiveDesign = (id) => {
    if (!id) {
      notification.warn({
        message: 'Please choose image to archive',
        duration: 5,
      });
    }
    setLoading(true);
    archive({
      id,
    })
      .then((result) => {
        setLoading(false);
        if (result[0] && result[0]['code'] === 'archive_image_success') {
          notification.success({
            message: result[0]['clientMsg'] || 'Archive image successfully.',
            duration: 5,
          });
          searchImage('');
        } else {
          notification.warn({
            message: result[0]['clientMsg'] || 'Archive image failed',
            duration: 5,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const handleRemove = (values) => {
    if (input.name.includes('designFront')) {
      changeDesignFront('');
      changeDesignFrontId('');
    }

    if (input.name.includes('designBack')) {
      changeDesignBack('');
      changeDesignBackId('');
    }

    //Use for match product
    if (input.name.includes('frontDesignUrl')) {
      changeDesignFront('');
      changeDesignFrontId('');
      deleteDesign({
        orderItemIds: [orderItemId] || [dataRemove.id],
        sidePrint: 'front',
      });
    }
    //Use for match product
    if (input.name.includes('backDesignUrl')) {
      changeDesignBack('');
      changeDesignBackId('');
      deleteDesign({
        orderItemIds: [orderItemId] || [dataRemove.id],
        sidePrint: 'back',
      });
    }
  };
  // onDeleteDesign = (orderItemId, sidePrint) => {
  //   const { deleteDesign } = this.props;
  //   confirm({
  //     title: 'Do you want to delete design of this order item?',
  //     icon: <ExclamationCircleOutlined />,
  //     content: "You won't be able to revert this!",
  //     onOk() {
  //       deleteDesign({
  //         orderItemIds: [orderItemId],
  //         sidePrint,
  //       });
  //     },
  //     onCancel() {},
  //   });
  // };
  const handleOnAssignDesign = (designId, sidePrint, callback) => {
    setLoading(true);
    assignDesign({
      orderId,
      orderItemId,
      sidePrint,
      typePrint,
      designId,
    })
      .then(({ code, data }) => {
        setLoading(false);
        if (code === 'success') {
          notification.success({
            message: `Assign design for the ${sidePrint} side of order item #${orderItemId} successfully.`,
            duration: 5,
          });
          callback();
        } else {
          notification.warn({
            message:
              data[0] && data[0]['clientMsg']
                ? data[0]['clientMsg']
                : `Assign design for the ${sidePrint} side of order item #${orderItemId} failed.`,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Wrapper className="m-0 p-0" style={{ height: '100px' }}>
      <div
        className={classNames('px-2 rounded border-0 cursor-pointer mb-5', {
          'border-active': visible,
        })}
        style={{ borderStyle: 'dotted' }}
      >
        {input?.value ? (
          <div className={classNames('position-relative')}>
            <img
              className="img-fluid"
              src={input?.value}
              alt={`design`}
              style={
                isCreatingOrder ? { maxWidth: '70px', height: '70px' } : {}
              }
            />
            <div className="placeholder-img">
              <TrashIcon
                style={{ stroke: '#475467' }}
                onClick={() => handleRemove(input)}
              />
            </div>
          </div>
        ) : isButtonPlaceholder ? (
          <Button className="btn btn-primary  d-flex align-items-center ">
            {buttonTitle}
          </Button>
        ) : (
          <PictureFilled
            className="upload-icon"
            onClick={() => handleShowModal(true)}
          />
        )}
      </div>
      {meta && (
        <span
          className={classNames('invalid-feedback mt-5', {
            invisible: !meta?.error || !meta?.submitFailed,
          })}
        >
          {meta?.submitFailed && meta?.error
            ? meta?.error
            : 'Please upload design.'}
        </span>
      )}
      <ExtendModal
        centered
        visible={visible}
        title="Design library"
        onCancel={() => handleShowModal(false)}
        footer={false}
        width={1000}
        style={{
          top: '10px',
          bottom: 0,
        }}
      >
        <Spin spinning={loading}>
          <div className="border-top pt-4">
            <UploadImage
              showUploadList={false}
              className="text-center"
              isButtonPlaceholder={true}
              imgWidth="100%"
              typeUpload="upload-design-image"
              reloadImageDesignList={() => {
                searchImage('');
              }}
              cusId={cusId}
            />
          </div>
          <Filter
            placeholder="Search image by original name"
            className="filter-form mb-3"
            onSubmit={(values) => searchImage(values?.search)}
          />
          <div className="container-fluid">
            <div className="pt-2  mb-2 row modal-upload">
              {images.map((image) => (
                <LazyLoad className="col-6 col-sm-3">
                  <div className="block-img">
                    <img
                      style={{
                        height: 120,
                        objectFit: 'contain',
                        width: '100%',
                      }}
                      className={classNames(
                        'px-1 border-hover mx-1 mb-2 img-fluid',
                        {
                          'border-active':
                            parseInt(image.id) === parseInt(selectedImage.id),
                        }
                      )}
                      src={image.imageThumbnailUrl}
                      onClick={() => {
                        setSelectedImage(image);
                      }}
                      alt=""
                    />
                    <div className="icon">
                      <Dropdown
                        placement="topLeft"
                        overlay={
                          <ContextMenuWrapper>
                            <Link
                              onClick={() => {
                                if (!selectedImage) {
                                  notification.warn({
                                    message: 'Please choose your design.',
                                    duration: 5,
                                  });
                                  return;
                                }
                                confirm({
                                  title: 'Do you want to delete this design?',
                                  icon: <ExclamationCircleOutlined />,
                                  content: "You won't be able to revert this!",
                                  onOk() {
                                    archiveDesign(selectedImage || 0);
                                  },
                                  onCancel() {},
                                });
                              }}
                            >
                              Delete
                            </Link>
                          </ContextMenuWrapper>
                        }
                        trigger={['hover']}
                        onMouseEnter={() => setSelectedImage(image.id)}
                      >
                        <MoreAction />
                      </Dropdown>
                    </div>
                  </div>
                  <div className="name-img"> {image.original}</div>
                </LazyLoad>
              ))}
            </div>
            {!loading && images.length === 0 && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
          {pagination?.total && pagination.total !== images.length ? (
            <div className="text-center loadmore">
              <GMButton
                color="SecondaryGray"
                size="sm"
                onClick={() => searchImage(original, true)}
                type="button"
                className="btn-sm btn btn-outline-secondary"
              >
                Loading more
              </GMButton>
            </div>
          ) : null}
          <hr className="mt-2" />
          <div className="row d-flex justify-content-end mr-2">
            <div>
              {/* Sometime printLocation will be array so we need use toString */}
              {!isOnlyUpload && (
                <>
                  {printLocation?.toString()?.includes('front,back') ? (
                    <div>
                      <GMButton
                        onClick={() => handleShowModal(false)}
                        type="button"
                        color={'SecondaryGray'}
                        className="mx-1 mb-2"
                      >
                        Cancel
                      </GMButton>
                      <GMButton
                        type="button"
                        className="btn btn-primary mx-1 mb-2"
                        onClick={() => {
                          if (!selectedImage.id) {
                            notification.warn({
                              message: 'Please choose your design.',
                              duration: 5,
                            });
                            return;
                          }
                          handleOnAssignDesign(
                            selectedImage.id,
                            'front',
                            () => {
                              if (typeof changeDesignFront === 'function') {
                                changeDesignFront(
                                  selectedImage.imageThumbnailUrl,
                                  selectedImage.name
                                );
                              }
                              if (
                                typePrint === 'both' &&
                                typeof changeDesignBack === 'function'
                              ) {
                                changeDesignBack(
                                  selectedImage.imageThumbnailUrl,
                                  selectedImage.name
                                );
                              }
                            }
                          );
                          if (
                            typeof changeDesignFront === 'function' &&
                            typeof changeDesignFrontId === 'function'
                          ) {
                            changeDesignFront(selectedImage.imageThumbnailUrl);
                            changeDesignFrontId(selectedImage.id);
                          }
                        }}
                      >
                        Apply front design
                      </GMButton>
                      <GMButton
                        type="button"
                        className="btn btn-primary mx-1 mb-2"
                        onClick={() => {
                          if (!selectedImage.id) {
                            notification.warn({
                              message: 'Please choose your design.',
                              duration: 5,
                            });
                            return;
                          }
                          handleOnAssignDesign(selectedImage.id, 'back', () => {
                            setDataRemove(selectedImage);

                            if (typeof changeDesignBack === 'function') {
                              changeDesignBack(
                                selectedImage.imageThumbnailUrl,
                                selectedImage.name
                              );
                            }
                            if (
                              typePrint === 'both' &&
                              typeof changeDesignBack === 'function'
                            ) {
                              changeDesignBack(
                                selectedImage.imageThumbnailUrl,
                                selectedImage.name
                              );
                            }
                          });
                          if (
                            typeof changeDesignBack === 'function' &&
                            typeof changeDesignBackId === 'function'
                          ) {
                            changeDesignBack(selectedImage.imageThumbnailUrl);
                            changeDesignBackId(selectedImage.id);
                          }
                        }}
                      >
                        Apply back design
                      </GMButton>
                    </div>
                  ) : (
                    <div>
                      <GMButton
                        onClick={() => handleShowModal(false)}
                        type="button"
                        color={'SecondaryGray'}
                        className="mx-1 mb-2"
                      >
                        Cancel
                      </GMButton>
                      <GMButton
                        type="button"
                        className="btn btn-primary mx-1 mb-2"
                        onClick={() => {
                          if (!selectedImage.id) {
                            notification.warn({
                              message: 'Please choose your design.',
                              duration: 5,
                            });
                            return;
                          }
                          handleOnAssignDesign(
                            selectedImage.id,
                            'front',
                            () => {
                              setDataRemove(selectedImage);
                              if (typeof changeDesignFront === 'function') {
                                changeDesignFront(
                                  selectedImage.imageThumbnailUrl,
                                  selectedImage.name
                                );
                              }
                              if (
                                typePrint === 'both' &&
                                typeof changeDesignBack === 'function'
                              ) {
                                changeDesignBack(
                                  selectedImage.imageThumbnailUrl,
                                  selectedImage.name
                                );
                              }
                            }
                          );
                          if (
                            typeof changeDesignFront === 'function' &&
                            typeof changeDesignFrontId === 'function'
                          ) {
                            changeDesignFront(selectedImage.imageThumbnailUrl);
                            changeDesignFrontId(selectedImage.id);
                          }
                        }}
                      >
                        Apply design
                      </GMButton>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Spin>
      </ExtendModal>
      <GlobalStyle />
    </Wrapper>
  );
};
export default React.memo(HandleModalImage);
