import { takeLatest, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import {
  PROMOTIONS_CREATE_REQUEST,
  PROMOTIONS_CREATE_SUCCESS,
  PROMOTIONS_CREATE_FAIL,
} from "./constants";
import { create } from "apis/promotions";

function* createPromotionSaga(action) {
  try {
    const { params } = action;
    const response = yield call(create, params);

    if (response.code === "success") {
      yield all([
        put({ type: PROMOTIONS_CREATE_SUCCESS, response }),
        put(replace("/promotions")),
      ]);
    } else {
      yield put({ type: PROMOTIONS_CREATE_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: PROMOTIONS_CREATE_FAIL, error })]);
  }
}

function* createPromotionsWatcher() {
  yield all([takeLatest(PROMOTIONS_CREATE_REQUEST, createPromotionSaga)]);
}

export default createPromotionsWatcher;
