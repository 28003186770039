import React, { useEffect, useState } from 'react';
import { Collapse, Col, Affix } from 'antd';
import {
  Field,
  reduxForm,
  submit,
  formValueSelector,
  change,
  FieldArray,
} from 'redux-form';
import { connect } from 'react-redux';
import { renderRangeDate, renderSelectAnt } from 'utils/Form';
import FieldCustomRadioGroup from 'utils/fields/FieldCustomRadioGroup';
import { useSelector } from 'react-redux';
import SelectProduct from 'utils/Form/Search/SelectProduct';
import {
  option_order_duplicated,
  option_status_payment,
  option_tracking,
  option_status_order,
  option_verify_address,
  option_date_type,
  options_order_status_matched,
  option_method,
  option_approve_status,
  options_type_gift_message,
} from 'utils/options';
import RenderTagFilter from 'utils/Form/RenderTagFilter';
import { getStoreTypeName, clearFilter } from 'utils/helper';
import RenderAdvancedFilterButtons from 'utils/Form/RenderAdvancedFilterButtons';
import RenderTimeOptions from 'utils/Form/RenderTimeOptions';
import SelectMultipleTagSizeProduct from 'utils/Form/Search/SelectMultipleTagSizeProduct';
import SelectMultipleTagColorProduct from 'utils/Form/Search/SelectMultipleTagColorProduct';
import { FilterWrapper } from '../../../styled';
import SelectGeneric from 'utils/Form/Search/SelectGeneric';
import { listAccount } from 'scenes/Settings/scenes/Account/actions';
import moment from 'moment';
import GMRow from 'components/GMLayout/GMRow';
const { Panel } = Collapse;
const paramsName = {
  paymentStatus: 'Payment status',
  isImport: 'Product matching status',
  // orderNote: "Buyer name or email",
  isDuplicated: 'Duplicated order',
  status: 'Status',
  storeId: 'Store',
  trackingType: 'Tracking type',
  verifiedAddress: 'Address status',
  productId: 'Product',
  sizeIds: 'Size',
  colorIds: 'Color',
  rating: 'Rating',
  orderName: 'Order name',
  fromDate: 'From date',
  toDate: 'To date',
  method: 'Create method',
  type: 'Type order',
  platformName: 'Platform',
  orderNote: 'Order note',
  staffId: 'Staff',
};
const defaultValues = {
  productId: '0',
  colorIds: [],
  sizeIds: [],
  trackingType: null,
  verifiedAddress: '',
  isApproved: '',
  orderNote: "0",
  isImport: '0',
  status: '',
  insertMethod: '',
  paymentStatus: '',
  isDuplicated: '',
  typeDate: '0',
  storeId: '0',
  rating: '0',
  fromDate: null,
  toDate: null,
  orderName: '',
  method: '',
  type: '',
  platformName: null,
  staffId: null,
};

let paramsValueAlias = {
  status: {
    0: 'Pending',
    1: 'Processing',
    2: 'Completed',
    3: 'Canceled',
    13: 'In-production',
    19: 'On-hold',
  },
  isDuplicated: {
    0: 'Not duplicated',
    1: 'Duplicated',
  },
  isImport: {
    1: 'Matched',
    2: 'Unmatch',
  },
  paymentStatus: {
    0: 'Unpaid',
    1: 'Paid',
    2: 'Failure',
  },
  trackingType: {
    1: 'Standard',
    2: 'Fastship',
    3: 'New tracking',
    4: 'Ground',
  },
  verifiedAddress: {
    0: 'Address not verified',
    1: 'Address verified',
  },
  platformName: [
    { label: 'Amazon', value: 'amazon' },
    { label: 'Wordpress', value: 'wordpress' },
    { label: 'Shopify', value: 'shopify' },
    { label: 'Import', value: 'import' },
    { label: 'Shopbase', value: 'shopbase' },
    { label: 'Orderdesk', value: 'orderdesk' },
    { label: 'Ebay', value: 'ebay' },
    { label: 'Etsy', value: 'etsy' },
    { label: 'Duplicate', value: 'duplicate' },
    { label: 'Manual', value: 'manual' },
    { label: 'FBA', value: 'fba' },
    { label: 'Order', value: 'order_label' },
  ],
  staffId: [],
};

const AdvancedFilter = ({
  handleSubmit,
  stores,
  exproducts,
  changeFieldValue,
  productId,
  colorId,
  sizeIds,
  colorIds,
  date_range,
  ...props
}) => {
  const orders = useSelector((state) => state.order.list.orders);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const listStaff = useSelector(
    (state) => state?.setting?.account?.accounts?.data || []
  ).map((stf) => ({ label: stf?.name, value: stf?.id }));
  paramsValueAlias.staffId = listStaff;
  const submitFilters = (isClosedAdvanedFileter = true) => {
    props.dispatch(submit('AdvancedFilterOrders'));
    if (isClosedAdvanedFileter) props.onCloseAdvancedSearch();
  };
  useEffect(() => {
    props.dispatch(
      listAccount({
        page: 1,
        perPage: 999,
        sortBy: 'id',
        sortType: 'desc',
      })
    );
  }, []);

  useEffect(() => {
    if (
      (Array.isArray(exproducts) && exproducts.length > 0) ||
      (Array.isArray(stores) && stores.length > 0) ||
      (Array.isArray(colors) && colors.length > 0) ||
      (Array.isArray(sizes) && sizes.length > 0)
    ) {
      const listProducts = {};
      (exproducts || []).forEach((exproduct) => {
        listProducts[exproduct.id] = exproduct.brand;
      });
      const listStores = {};
      (stores || []).map((store) => {
        return (listStores[store.id] = store.showName);
      });
      const listColors = {};
      (colors || []).forEach((color) => {
        listColors[color.id] = color.name;
      });
      const listSizes = {};
      (sizes || []).forEach((size) => {
        listSizes[size.id] = size.name;
      });
      paramsValueAlias = {
        ...paramsValueAlias,
        storeId: listStores,
        productId: listProducts,
        colorIds: listColors,
        sizeIds: listSizes,
      };
    }
  }, [
    JSON.stringify(exproducts),
    JSON.stringify(stores),
    JSON.stringify(sizes),
    JSON.stringify(colors),
  ]);

  return (
    <FilterWrapper>
      <RenderTagFilter
        params={orders.params}
        paramsName={paramsName}
        paramsValueAlias={paramsValueAlias}
        defaultValues={defaultValues}
        changeFieldValue={changeFieldValue}
        isAllowedToClearFilter={true}
        submitFilters={submitFilters}
        date_range={date_range}
      />
      <form onSubmit={handleSubmit}>
        <div className="p-3">
          <Collapse
            defaultActiveKey={[
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
            ]}
            bordered={true}
            ghost
            expandIconPosition="right"
          >
            <Panel header={<b className="header-filter">All time</b>} key="1">
              <RenderTimeOptions changeFieldValue={changeFieldValue} />
              <div className="row">
                <Field
                  name="date_range"
                  component={renderRangeDate}
                  labelStart={<b>Start date</b>}
                  labelEnd={<b>End date</b>}
                  className="h-50px w000"
                  dateTimeFormat="YYYY/MM/DD"
                  showTime={false}
                  classContainer="col-6 mb-2"
                  startPlaceholder="yyyy/mm/dd"
                  endPlaceholder="yyyy/mm/dd"
                  forceDisableDate={(c) =>
                    c.valueOf() < moment().subtract(180, 'days').valueOf()
                  }
                />
              </div>
            </Panel>
            <Panel header={<b className="header-filter">Store</b>} key="2">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="storeId"
                    component={renderSelectAnt}
                    options={[
                      {
                        value: 0,
                        label: (
                          <span style={{ color: 'black' }}>
                            -- Select a store --
                          </span>
                        ),
                      },
                      ...stores.map((store) => ({
                        value: store.id,
                        label:
                          `[${getStoreTypeName(store.backendType)}] ` +
                          (store.showName || `#${store.id}`),
                      })),
                    ]}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Orders status</b>}
              key="3"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="status"
                    component={FieldCustomRadioGroup}
                    options={option_status_order}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Payment status</b>}
              key="4"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="paymentStatus"
                    component={FieldCustomRadioGroup}
                    options={option_status_payment}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Product matching status</b>}
              key="5"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="isImport"
                    component={FieldCustomRadioGroup}
                    options={options_order_status_matched}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Address status</b>}
              key="6"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="verifiedAddress"
                    component={FieldCustomRadioGroup}
                    options={option_verify_address}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Shipping service</b>}
              key="7"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    options={option_tracking}
                    name={`trackingType`}
                    component={SelectGeneric}
                    placeholder="All"
                    onChange={(v) => {
                      changeFieldValue('trackingType', v);
                    }}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Order duplicated</b>}
              key="8"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="isDuplicated"
                    component={FieldCustomRadioGroup}
                    options={option_order_duplicated}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Date type</b>} key="9">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="typeDate"
                    component={FieldCustomRadioGroup}
                    options={option_date_type}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Product</b>} key="10">
              <GMRow>
                <Col span={24}>
                  <Field
                    name={`productId`}
                    component={SelectProduct}
                    data={exproducts}
                    onChoose={() => {
                      changeFieldValue('colorIds', []);
                      changeFieldValue(`sizeIds`, []);
                    }}
                  />
                </Col>
              </GMRow>
            </Panel>
            {productId * 1 ? (
              <Panel header={<b className="header-filter">Color</b>} key="11">
                <GMRow>
                  <Col span={24}>
                    <FieldArray
                      name={`colorIds`}
                      component={SelectMultipleTagColorProduct}
                      expId={productId}
                      setColors={setColors}
                      selectedColorIds={colorIds || []}
                      setDefaultColor={(colorId) => {
                        changeFieldValue('colorId', colorId);
                      }}
                    />
                  </Col>
                </GMRow>{' '}
              </Panel>
            ) : null}
            {productId * 1 ? (
              <Panel header={<b className="header-filter">Size</b>} key="12">
                <GMRow>
                  <Col span={24}>
                    <FieldArray
                      name={`sizeIds`}
                      component={SelectMultipleTagSizeProduct}
                      expId={productId}
                      colorId={colorId}
                      setSizes={setSizes}
                      selectedSizeIds={sizeIds}
                    />
                  </Col>
                </GMRow>
              </Panel>
            ) : null}
            <Panel
              header={<b className="header-filter">Create method</b>}
              key="13"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name={`method`}
                    component={FieldCustomRadioGroup}
                    placeholder="-- Select method --"
                    options={option_method}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Order’s approval status</b>}
              key="14"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name={`type`}
                    component={FieldCustomRadioGroup}
                    options={option_approve_status}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Platform</b>} key="15">
              <GMRow>
                <Col span={24}>
                  <Field
                    name={`platformName`}
                    component={SelectGeneric}
                    placeholder="-- Select platform --"
                    options={paramsValueAlias.platformName}
                    onChange={(v) => {
                      changeFieldValue('platformName', v);
                    }}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Staff account</b>}
              key="16"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name={`staffId`}
                    component={SelectGeneric}
                    placeholder="-- Select staff --"
                    options={listStaff}
                    onChange={(v) => {
                      changeFieldValue('staffId', v);
                    }}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Gift message status</b>}
              key="17"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name={`orderNote`}
                    component={FieldCustomRadioGroup}
                    placeholder="-- Select type of order note  --"
                    // options={paramsValueAlias.orderNote}
                    // onChange={(v) => {
                    //   changeFieldValue('orderNote', v);
                    // }}
                    options={options_type_gift_message}
                  />
                </Col>
              </GMRow>
            </Panel>
          </Collapse>
        </div>
      </form>
      <Affix offsetBottom={0}>
        <RenderAdvancedFilterButtons
          handleOnSubmit={() => {
            submitFilters();
          }}
          handleOnClearFilters={() => {
            clearFilter(changeFieldValue, {
              sortBy: 'id',
              sortType: 'desc',
              productId: '0',
              colorIds: [],
              sizeIds: [],
              trackingType: null,
              verifiedAddress: '0',
              isApproved: '0',
              orderNote: '0',
              isImport: '0',
              status: '0',
              insertMethod: '0',
              method: '0',
              paymentStatus: '0',
              isDuplicated: '0',
              typeDate: '1',
              type: '0',
              storeId: '0',
              rating: '0',
              staffId: null,
              platformName: null,
              orderName: '',
              date_range: [null, null],
            });
            setTimeout(
              () => props.dispatch(submit('AdvancedFilterOrders')),
              1000
            );
          }}
        />
      </Affix>
    </FilterWrapper>
  );
};
const selector = formValueSelector('AdvancedFilterOrders');

const AdvancedFilterReduxForm = reduxForm({
  form: 'AdvancedFilterOrders',
  enableReinitialize: true,
  //validate,
})(AdvancedFilter);

const mapStateToProps = (state) => ({
  productId: selector(state, 'productId'),
  sizeIds: selector(state, 'sizeIds') || [],
  colorIds: selector(state, 'colorIds') || [],
  colorId: selector(state, 'colorId'),
  date_range: selector(state, 'date_range') || null,
  AdvancedFilterOrders: state.form.AdvancedFilterOrders?.values || {},
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change('AdvancedFilterOrders', field, value));
    },
  };
};
export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(AdvancedFilterReduxForm)
);
