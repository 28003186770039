import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { renderInputText, renderSelectAnt } from 'utils/Form';
import { isValidUrl } from 'utils/helper';
import { renderRadioAnt } from 'utils/Form';
import { renderDateTimePicker } from 'utils/Form';
import renderCheckboxGroup from 'utils/Form/renderCheckboxGroup';
import { Link } from 'react-router-dom';
import SearchGooglePlaces from 'utils/Form/SearchGooglePlaces';
import { GMButton } from 'components/Button';
import { GMSelect, GMTextbox } from 'components/InputFields';
const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.replace(/\s+/g, '').length === 0) {
    errors.name = 'Please enter your name.';
  }
  if (!values.address || values.address.replace(/\s+/g, '').length === 0) {
    errors.address = 'Please enter address 1.';
  }
  if (
    !values.phone ||
    values.phone.replace(/^[+][(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]$/g, '')
      .length === 0
  ) {
    errors.phone = 'Please enter phone number.';
  }
  if (!values.facebook || !isValidUrl(values.facebook)) {
    errors.facebook = "Please enter a valid facebook's profile link.";
  }
  if (!values.birthday) {
    errors.birthday = 'Please enter birthday.';
  }

  return errors;
};

const FormUpdateProfile = ({
  handleSubmit,
  form_values,
  onRemoveAvatar,
  countries,
  changeFieldValue,
  avatar,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="formgroup">
        {/* <div className="Personal-Information">Personal Information</div> */}
        <div>
          <GMTextbox
            name="firstName"
            label="First name"
            className="textInput"
            maxLength={64}
            showError
          />
        </div>
        <div>
          <GMTextbox
            name="lastName"
            label="Last name"
            className="textInput"
            maxLength={64}
            showError
          />
        </div>
        <div>
          <GMTextbox name="email" disabled label="Email" showError />
        </div>
        <div>
          <GMTextbox
            name=""
            defaultValue="*********"
            value="*********"
            disabled
            label="Password"
            showError
          />{' '}
          <Link to="/change-password">Change password </Link>
        </div>
        <div className="">
          <label className="headerAcc">Birthday</label>
          <Field
            name="birthday"
            placeholder="Birthday"
            component={renderDateTimePicker}
            hideTime={true}
            formatDate="DD/MM/YYYY"
            showError
          />
        </div>
        <div className="genderblock">
          <label className="headerAcc">Gender</label>
          <Field
            name="gender"
            component={renderRadioAnt}
            options={[
              { label: 'Female', value: '0' },
              { label: 'Male', value: '1' },
            ]}
            showError
          />
        </div>
        <div>
          <GMTextbox
            name="phone"
            label="Phone Number"
            maxLength={20}
            showError
          />
        </div>
        <div>
          <GMTextbox
            name="address"
            component={SearchGooglePlaces}
            onChangeCountryName={(country) => {
              let a = countries?.data.find((e) => e.countryName === country);
              changeFieldValue('country', a.countryName);
            }}
            label="Address"
            showError
          />
        </div>
        <div>
          <GMSelect
            name="country"
            placeholder="Country"
            label="Country"
            options={[
              { value: 0, label: 'Choose a country' },
              ...countries.data.map((item) => ({
                value: `${item.id}`,
                label: item.countryName,
              })),
            ]}
            showError
          />
        </div>
        <div>
          <label className="headerAcc">Sell on</label>
          <Field
            name="sellOn"
            component={renderCheckboxGroup}
            colSpan={24}
            options={[
              {
                label: (
                  <p className="mb-0">Marketplace (Amazon, Etsy, Ebay, ...)</p>
                ),
                value: '1',
              },
              {
                label: (
                  <p className="mb-0">Platform (Shopify, Woocommerce, ...)</p>
                ),
                value: '2',
              },
              {
                label: <p className="mb-0">Others (website, ...)</p>,
                value: '3',
              },
            ]}
          />
        </div>
    
        <div className="cta">
          <GMButton type="submit" className="btn btn-primary saveBtnGen">
            Save
          </GMButton>
        </div>
   
      </div>
    </form>
  );
};

FormUpdateProfile.propTypes = {};
const selector = formValueSelector('FormUpdateProfile');
const mapStateToProps = (state) => ({
  form_values: state.form.FormUpdateProfile,
  avatar: selector(state, 'avatar') || null,
  countries: state.user.profile.listCountry,
});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change('FormUpdateProfile', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'FormUpdateProfile',
    validate,
    enableReinitialize: true,
  })(FormUpdateProfile)
);
