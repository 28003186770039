import { takeLatest, call, put, all } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { actionType as TYPE } from './actions';
import { detailBulk } from 'apis/campaign';

function* detailBulkSaga(action) {
  try {
    const { id } = action;
    const response = yield call(detailBulk, id);
    if (response.code === 'success' && response.data.campaign) {
      yield all([put({ type: TYPE.DETAIL.SUCCESS, ...response })]);
    } else {
      yield all([
        put({ type: TYPE.DETAIL.ERROR, error: response }),
        put(replace(`/products/list`)),
      ]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.DETAIL.ERROR, error })]);
  }
}

function* BulkWatcher() {
  yield all([takeLatest(TYPE.DETAIL.REQUEST, detailBulkSaga)]);
}

export default BulkWatcher;
