import {
  PRODUCT_UPDATE_PRICE_REQUEST,
  PRODUCT_UPDATE_PRICE_SUCCESS,
  PRODUCT_UPDATE_PRICE_FAIL,
  CAMPAIGN_DETAIL_REQUEST,
  CAMPAIGN_DETAIL_SUCCESS,
  CAMPAIGN_DETAIL_FAIL,
  CAMPAIGN_UPDATE_INFO_REQUEST,
  CAMPAIGN_UPDATE_INFO_SUCCESS,
  CAMPAIGN_UPDATE_INFO_FAIL,
} from "./actions";
import { notification } from "antd";

const initialState = {
  updatePrice: {
    loading: false,
  },
  detail: {
    data: {},
    loading: false,
  },
  endCampaign: {
    loading: false,
  },
  relaunchCampaign: {
    loading: false,
  },
  updateCampaignInfo: {
    loading: false,
  },
};

function EditCampaignReducer(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_UPDATE_PRICE_REQUEST:
      return {
        ...state,
        updatePrice: {
          ...state.updatePrice,
          loading: true,
        },
      };
    case PRODUCT_UPDATE_PRICE_SUCCESS:
      notification.success({
        message: "Update product price successfully.",
      });
      return {
        ...state,
        updatePrice: {
          ...state.updatePrice,
          loading: false,
        },
      };
    case PRODUCT_UPDATE_PRICE_FAIL:
      notification.warn({
        message:
          action.error && action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Update product price failed.",
        duration: 5,
      });
      return {
        ...state,
        updatePrice: {
          ...state.updatePrice,
          loading: false,
        },
      };
    case CAMPAIGN_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
        },
      };
    case CAMPAIGN_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: action.data.campaign,
        },
      };
    case CAMPAIGN_DETAIL_FAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
        },
      };
    case CAMPAIGN_UPDATE_INFO_REQUEST:
      return {
        ...state,
        updateCampaignInfo: {
          ...state.updateCampaignInfo,
          loading: true,
        },
      };
    case CAMPAIGN_UPDATE_INFO_SUCCESS:
      notification.success({
        message: "Update product successfully.",
        duration: 5,
      });
      return {
        ...state,
        updateCampaignInfo: {
          ...state.updateCampaignInfo,
          loading: false,
        },
      };
    case CAMPAIGN_UPDATE_INFO_FAIL:
      notification.warn({
        message:
          action.error && action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Update product failed.",
        duration: 5,
      });
      return {
        ...state,
        updateCampaignInfo: {
          ...state.updateCampaignInfo,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default EditCampaignReducer;
