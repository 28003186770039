import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validate from './validate';
import {
  renderSwitchAnt,
  renderSelectAnt,
  renderCheckboxGroup,
} from 'utils/Form';
import amazon_logo from 'assets/images/amazon_logo.png';
import { GMTextbox } from 'components/InputFields';
import Select from 'components/InputFields/Select';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import { WrapperUpdateButton } from '../../styled';
import { Collapse } from 'antd';
import { ReactComponent as AmazonIcon } from 'images/Logo_store/full/amazon.svg';
import GMNotify from 'components/Notification';

const { Panel } = Collapse;

let index = ({
  handleSubmit,
  onCancel,
  accounts,
  currAction,
  is_mobile = false,
  profile,
  ...props
}) => {
  return (
    <WrapperUpdateButton>
      <form onSubmit={handleSubmit}>
        <div>
          <div className="text-left my-3">
            <AmazonIcon />
          </div>
          <GMNotify
            type="desc"
            desc={
              <div className="desc">
                <ol className="pl-4 desc">
                  <li className="mb-3">
                    Make sure that you have completed all of the steps to set up
                    your Amazon store
                  </li>
                  <li className="mb-3">
                    Fill in your Store name in Add a new Amazon Store. After
                    that click on Authorize.
                  </li>
                  <li className="mb-3">
                    Next, you’ll be asked to log in to your Amazon account (if
                    you aren’t already logged in). After that, you will be asked
                    to allow access – click on Confirm to proceed with the
                    integration.
                  </li>
                </ol>
              </div>
            }
          />

          <div>
            <div className="form-group">
              <label className="col-form-label">
                <b>Store name</b>
              </label>
              <GMTextbox name="showName" showError />
            </div>
            <div className="form-group">
              <label className="col-form-label">
                <b>Amazon marketplace</b>
              </label>
              <div className="border border-dashed p-3 bg-light">
                <Field
                  name="amazonMarketPlaceId"
                  component={renderCheckboxGroup}
                  colSpan={is_mobile ? 24 : 12}
                  options={[
                    {
                      value: '2',
                      label: (
                        <div>
                          <b>Canada</b>
                          <br />
                          (A2EUQ1WTGCTBG2)
                        </div>
                      ),
                    },
                    {
                      value: '3',
                      label: (
                        <div>
                          <b>Mexico</b>
                          <br />
                          (A2EUQ1WTGCTBG2)
                        </div>
                      ),
                    },
                    {
                      value: '4',
                      label: (
                        <div>
                          <b>United States</b>
                          <br />
                          (A2EUQ1WTGCTBG2)
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
            {parseInt(profile.primaryId) === 0 && (
              <div className="form-group">
                <label className="col-form-label">
                  <b>Staff Account</b>
                </label>
                <Field
                  name="staffId"
                  component={renderSelectAnt}
                  placeholder="-- Select staff --"
                  mode={'multiple'}
                  options={accounts.map((item) => ({
                    value: item.id.toString(),
                    label: item.name,
                  }))}
                />
              </div>
            )}

            <div className="form-group">
              <div className="d-flex align-items-center">
                <Field name="autoSync" component={renderSwitchAnt} />
                <label
                  htmlFor="store_auto_sync"
                  className="ml-3 col-form-label"
                >
                  Automatic synchronization when new orders arrive.{' '}
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex align-items-center">
                <Field name="status" component={renderSwitchAnt} />
                <label htmlFor="store_active" className="ml-3 col-form-label">
                  Active store
                </label>
              </div>
            </div>
            <div>
              <GMButton className="saveBtn" size="lg">
                {currAction === 'create' ? 'Connect store' : 'Update store'}
              </GMButton>
            </div>
          </div>
        </div>
      </form>
    </WrapperUpdateButton>
  );
};

index = reduxForm({
  form: 'formAmazonStore',
  enableReinitialize: true,
  validate,
})(index);

export default connect()(index);
