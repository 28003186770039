import React, { Component } from "react";
import { connect } from "react-redux";
import { orderReportDashboard } from "../../actions";
import ReactApexChart from "react-apexcharts";
import { Card, Spin } from "antd";
import FormFilter from "./components/FormFilter";
import getMomentInstance from "utils/moment";
import { Wrapper } from "scenes/general.styled";
import queryString from "query-string";

class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.myRef = React.createRef();
    this.chart = null;
    this.state = {
      initial_filter_values: {
        ...query_params,
        date_range: [
          getMomentInstance(null, 0, false, false, null, null, null, null, 7, 'days'),
          getMomentInstance()
        ],
      },
      series: [
        {
          name: "Orders",
          data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          type: "datetime",
          categories: [
            "1/11/2000",
            "2/11/2000",
            "3/11/2000",
            "4/11/2000",
            "5/11/2000",
            "6/11/2000",
            "7/11/2000",
            "8/11/2000",
            "9/11/2000",
            "10/11/2000",
            "11/11/2000",
            "12/11/2000",
            "1/11/2001",
            "2/11/2001",
            "3/11/2001",
            "4/11/2001",
            "5/11/2001",
            "6/11/2001",
          ],
          tickAmount: 10,
          // labels: {
          //   formatter: function (value, timestamp, opts) {
          //     return opts.dateFormatter(new Date(timestamp), "dd MMM");
          //   },
          // },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 7,
          curve: "smooth",
        },
        title: {
          text: "Order Trends by Day",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: ["#FDD835"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 4,
          colors: ["#FFA41B"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        yaxis: {
          min: 0,
          max: 50,
          title: {
            text: "Orders",
          },
        },
      },
    };
  }
  onClearFilter = () => {
    const params = {};
    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.orderReportDashboardData) !==
      JSON.stringify(prevProps.orderReportDashboardData)
    ) {
      const seriesData = this.props.orderReportDashboardData
        ? this.props.orderReportDashboardData.data.map((d) => d.orders)
        : [1];
      const xAxisCategories = this.props.orderReportDashboardData
        ? this.props.orderReportDashboardData.data.map((d) => d.datefm)
        : [1];
      this.setState({
        series: [{ ...this.state.series[0], data: seriesData }],
      });
      this.setState({
        options: {
          ...this.state.options,
          xaxis: { ...this.state.options.xaxis, categories: xAxisCategories },
        },
      });
    }
  }

  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ date_range, ...options }) => {
    let params = {
      ...options,
    };
    if (date_range[0]) {
      params.from = date_range[0] && date_range[0].format("YYYY-MM-DD");
    }
    if (date_range[1]) {
      params.to = date_range[1] && date_range[1].format("YYYY-MM-DD");
    }

    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.orderReportDashboard({
      ...params,
    });
  };
  render() {
    const {
      orderReportDashboardLoading,
      orderReportDashboardData,
      pageName,
    } = this.props;

    return (
      <Wrapper
        pageName={pageName}
        filter={
          <FormFilter
            onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
            handleAdvancedSubmit={this.fetchData}
            onClearFilter={this.onClearFilter}
            orderReportDashboardData={orderReportDashboardData}
            initialValues={this.state.initial_filter_values}
            is_mobile={this.props.is_mobile}
          />
        }
      >
        <div className="m-3">
          <Card className="mb-2 h-100">
            <Spin spinning={orderReportDashboardLoading}>
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="line"
                height={500}
              />
            </Spin>
          </Card>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    orderReportDashboardData: state.setting.account.reportOrderDashboard,
    orderReportDashboardLoading:
      state.setting.account.reportOrderDashboard.loading,
    formFilterReportDashboard:
      state.form.formFilterReportDashboard?.values || {},
  }),
  (dispatch) => ({
    orderReportDashboard: (params) => {
      dispatch(orderReportDashboard(params));
    },
  })
)(List);
