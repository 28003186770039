export const actionType = {
    PREVIEW: {
        REQUEST: 'CAMPAIGN.PREVIEW2.REQUEST',
        SUCCESS: 'CAMPAIGN.PREVIEW2.SUCCESS',
        ERROR: 'CAMPAIGN.PREVIEW2.ERROR',
    },
    CREATE: {
        REQUEST: 'CAMPAIGN.CREATE.REQUEST',
        SUCCESS: 'CAMPAIGN.CREATE.SUCCESS',
        ERROR: 'CAMPAIGN.CREATE.ERROR',
    }
};

export function previewCampaign(dataToPreviewCampaign) {
    return {
        type: actionType.PREVIEW.REQUEST,
        dataToPreviewCampaign
    }
}

export function createCampaign(dataToCreateCampaign) {
    return {
        type: actionType.CREATE.REQUEST,
        dataToCreateCampaign
    }
}