import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { getColors } from 'apis/ex_products';
import classNames from 'classnames';
import AbsoluteLoader from 'components/Loader/AbsoluteLoader';
const { Option } = Select;

function SelectProductsColor({
  input,
  mode = 'default',
  invalid,
  meta: { error, submitFailed },
  size,
  onChoose,
  expId,
  inputProps = {},
  classNameContainer = '',
  showError = false,
  disabled,
}) {
  const [options, setOptions] = useState([
    { label: '-- Select color --', value: '0' },
  ]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let isSubscribed = true;
    if (expId) {
      setLoading(true);
      getColors(expId)
        .then(({ code, data }) => {
          if (!isSubscribed) return;
          setLoading(false);
          if (code === 'success') {
            setOptions([
              { value: '0', label: '-- Select color --' },
              ...data.optionColors.map((item) => ({
                ...item,
                value2: item.value,
                value: item.id,
                label: item.name,
              })),
            ]);
          }
        })
        .catch((error) => {
          if (!isSubscribed) return;
          setLoading(false);
        });
    } else {
      if (!isSubscribed) return;
      setOptions([{ label: '-- Select color --', value: '0' }]);
    }
    return () => {
      isSubscribed = false;
    };
  }, [expId]);
  const onSelect = (data) => {
    if (mode === 'default' && typeof onChoose === 'function') {
      onChoose(
        data,
        options.find((item) => item.value == data)
      );
    }
    input.onChange(
      data === undefined ? (mode === 'default' ? null : []) : data
    );
  };
  const value = mode === 'default' ? input.value.toString() : input.value;
  return (
    <div className={classNames('position-relative', classNameContainer)}>
      <div className="position-relative">
        <Select
          loading={loading}
          showSearch
          mode={mode}
          value={value || (mode === 'default' ? undefined : [])}
          disabled={disabled}
          style={{ width: '100%' }}
          size={size || 'large'}
          onChange={onSelect}
          optionFilterProp="children"
          {...inputProps}
        >
          {options.map((item) => (
            <Option key={item.value}>{item.label}</Option>
          ))}
        </Select>
        <AbsoluteLoader loading={loading} />
      </div>
      {invalid && (
        <hr
          className="border-danger m-0 position-absolute"
          style={{ width: '90%', left: '5%', top: size === 'middle' ? 31 : 39 }}
        />
      )}
      {(showError ? error : error && submitFailed) && (
        <span className="invalid-feedback">{error}</span>
      )}
    </div>
  );
}
export default React.memo(SelectProductsColor);
