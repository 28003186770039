import List from './scenes/Topup';

export default [
  {
    key: 'billing.g-wallet',
    name: 'G-Wallet',
    component: List,
    path: '/billing/g-wallet',
    icon: null,
    template: "default",
    isPublic: false,
  },
];
