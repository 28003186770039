import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import Filter from 'components/Filter';
import ColumnRenderer from './Topup.column';
import { useSelector, useDispatch, connect } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import AltFilter from './Topup.filter.alt';
import { change } from 'redux-form';
import { getListTopup } from 'scenes/Orders/scenes/ManualPayment/actions';
import { Wrapper } from './styled';
import ModalTopUp from 'components/ModalTopUp';
import BalanceComponent from './Balance';

const TopUp = (props) => {
  const queryParams = queryString.parse(window.location.search);
  const [initialFilterValues, setInitialFilterValues] = useState({
    sortBy: 'id',
    sortType: 'desc',
    tab: 'topup',
    methodType: queryParams?.methodType || '0',
    method: queryParams?.method || '0',
    status: queryParams?.status || '-1',
    ...queryParams,
  });
  const [isOpenModal, setIsOpenModal] = useState(false);

  const listTopUp = useSelector(
    (state) => state.order.manual_payment.listTopUp
  );

  const lastTopupSuccess = useSelector(
    (state) => state.topup.requestTopUp.lastTopupSuccess
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const fetchData = ({
    page = 1,
    perPage = 20,
    status = '-1',
    methodType = '0',
    ...options
  }) => {
    let params = {
      page,
      perPage,
      sortBy: 'id',
      sortType: 'desc',
      method: options?.method || '0',
      status,
      with: ['transaction'],
      // ...options
    };

    if (options?.search) {
      params['search'] = options?.search;
    }

    if (status && status !== '-1') {
      params['status'] = status;
    } else {
      delete params.status;
    }

    const { search, tab, ...replaceParams } = params;
    delete replaceParams.with;
    delete replaceParams.page;
    const tabQueryParams = { tab: 'topup', ...replaceParams, methodType };
    if (
      `${window.location.pathname + window.location.search}` !==
      window.location.pathname + '?' + queryString.stringify(tabQueryParams)
    ) {
      history.replace(
        window.location.pathname + '?' + queryString.stringify(tabQueryParams)
      );
    }

    // type: array (1: Payoneer, 2: Pingpong, 3: Request Paypal, 4: Instant Paypal 5: LianLian)
    if (options?.method !== '0') {
      if (options?.method === '-1') {
        switch (methodType) {
          case '0':
            params.type = [3, 4];
            break;
          case '1':
            params.type = [3];
            break;
          case '2':
            params.type = [4];
            break;
        }
      } else {
        params.type = [params.method];
      }
    }
    delete params.method;
    if (options?.status !== '-1') {
      params.status = params.status;
    }
    dispatch(getListTopup(params));
  };
  useEffect(() => {
    dispatch(change('paymentFormFilter', 'method', initialFilterValues.method));
    dispatch(
      change(
        'paymentFormFilter',
        'methodType',
        initialFilterValues.methodType || '0'
      )
    );
  }, [initialFilterValues.method, initialFilterValues.methodType]);
  useEffect(() => {
    dispatch(change('paymentFormFilter', 'status', initialFilterValues.status));
  }, [initialFilterValues.status]);

  useEffect(() => {
    fetchData(initialFilterValues);
  }, [initialFilterValues, lastTopupSuccess]);

  return (
    <Wrapper
      pageName={'G-Wallet'}
      filter={
        <Filter
          placeholder="Search Top up ID"
          maxLength2Search="1"
          onSubmit={(values) => {
            setInitialFilterValues((oldState) => {
              const value2Set = {
                ...oldState,
                search: (values.search || '').trim(),
              };
              value2Set.method = values.method;
              value2Set.status = values.status;
              value2Set.methodType = values.methodType || '0';
              if (oldState.status !== values.status || oldState.method !== values.method) {
                value2Set.page = 1;
              }
              return value2Set;
            });
          }}
          customInputs={<AltFilter values={initialFilterValues} />}
        />
      }
      specialElement={<BalanceComponent />}
    >
      <Spin spinning={listTopUp.loading}>
        {' '}
        <Table
          rowKey="id"
          columns={ColumnRenderer()}
          dataSource={listTopUp.data}
          scroll={{ x: 1000 }}
          pagination={
            !props.is_mobile
              ? {
                  showTotal: (total) => `Total ${total} items`,
                  total: listTopUp.total,
                  pageSize: 20,
                  current: listTopUp.params.page,
                  showSizeChanger: false,
                  onChange: (page, perPage) =>
                    // fetchData({
                    //   ...listTopUp.params,
                    //   page,
                    //   perPage,
                    // }),
                    setInitialFilterValues({
                      ...initialFilterValues,
                      page,
                      perPage,
                    })
                }
              : false
          }
        />
      </Spin>

      <ModalTopUp
        isOpenModal={isOpenModal}
        closeModal={() => setIsOpenModal(false)}
      />
    </Wrapper>
  );
};
export default connect(
  () => ({}),
  (dispatch) => {
    return {
      dispatch,
    };
  }
)(TopUp);
