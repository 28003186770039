import { actionType as TYPE } from './actions';
const initialState = {
  preview: {
    loading: false,
    data: [],
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.GET_PREVIEW_INDEMNICATION.REQUEST:
      return {
        ...state,
        preview: {
          ...state.preview,
          loading: true,
        },
      };
    case TYPE.GET_PREVIEW_INDEMNICATION.SUCCESS:
      return {
        ...state,
        preview: {
          ...state.preview,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.GET_PREVIEW_INDEMNICATION.ERROR:
      return {
        ...state,
        preview: {
          ...state.preview,
          loading: false,
        },
      };

    default:
      return state;
  }
}

export default ListReducer;
