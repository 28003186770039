import React from "react";
import { Collapse, Affix } from "antd";
import {
  Field,
  reduxForm,
  submit,
  change,
  formValueSelector
} from "redux-form";
import { connect } from "react-redux";
import { renderRangeDate } from "utils/Form";
import RenderAdvancedFilterButtons from "utils/Form/RenderAdvancedFilterButtons";
import RenderTimeOptions from "utils/Form/RenderTimeOptions";
import { clearFilter } from "utils/helper";
import RenderTagFilter from "utils/Form/RenderTagFilter";
const { Panel } = Collapse;
const paramsName = {
  fromDate: "From Date",
  toDate: "To Date",
};
const defaultValues = {
  fromDate: null,
  toDate: null,
};
let paramsValueAlias = {}
const AdvancedFilter = ({handleSubmit, changeFieldValue, listReportData, date_range, ...props}) => {
  const submitFilters = (isClosedAdvanedFileter = true) => {
    props.dispatch(submit("AdvancedFilterAccounts"));
    if(isClosedAdvanedFileter) props.onCloseAdvancedSearch();
  };
  return (
    <div>
      <RenderTagFilter
        params={listReportData.params}
        paramsName={paramsName}
        paramsValueAlias={paramsValueAlias}
        defaultValues={defaultValues}
        changeFieldValue={changeFieldValue}
        date_range={date_range}
        isAllowedToClearFilter={true}
        submitFilters={submitFilters}
      />
      <form onSubmit={handleSubmit}>
        <div className="p-3">
          <Collapse
            defaultActiveKey={["1"]}
            bordered={true}
            ghost
            expandIconPosition="right"
          >
            <Panel header={<b className="header-filter">All time</b>} key="1">
              <RenderTimeOptions changeFieldValue={changeFieldValue}/>
              <div className="row">
                <Field
                  name="date_range"
                  component={renderRangeDate}
                  labelStart={<b>Start date</b>}
                  labelEnd={<b>End date</b>}
                  className="h-50px w-100"
                  dateTimeFormat="YYYY/MM/DD"
                  showTime={false}
                  classContainer="col-6 mb-2"
                  startPlaceholder="yyyy/mm/dd"
                  endPlaceholder="yyyy/mm/dd"
                />
              </div>
            </Panel>
          </Collapse>
        </div>
      </form>
      <Affix offsetBottom={0}>
        <RenderAdvancedFilterButtons
          handleOnSubmit={submitFilters}
          handleOnClearFilters={() => {
            clearFilter(changeFieldValue, {date_range: [
              null, null
            ]});
            setTimeout(() => props.dispatch(submit("AdvancedFilterAccounts")), 1000);
          }}
        />
      </Affix>
    </div>
  );
};
const selector = formValueSelector("AdvancedFilterAccounts");
const AdvancedFilterReduxForm = reduxForm({
  form: "AdvancedFilterAccounts",
  enableReinitialize: true,
  //validate,
})(AdvancedFilter);

const mapStateToProps = (state) => ({
  date_range: selector(state, "date_range") || null
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change("AdvancedFilterAccounts", field, value));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedFilterReduxForm);