import axios from "utils/request";

const prefix = "/customer-api";

export const createApiKey = (data) => {
  return axios({
    method: "POST",
    url: `${prefix}`,
  });
};

export const getDetailApiKey = (id) => {
  return axios({
    method: "GET",
    url: `${prefix}`,
  });
};

export const authorization = () => {
  return axios({
    method: "GET",
    url: `/customer/permission-general-setting`,
  });
};

export const removeApiKey = (id) => {
  return axios({
    method: "DELETE",
    url: `${prefix}`,
  });
};

export const getListShipping = () => {
  return axios({
    method: "GET",
    url: `/customer-shipping-carrier/detail`,
  });
};

export const updateShipping = (data) => {
  return axios({
    method: "PUT",
    data,
    url: `/customer-shipping-carrier/update`,
    is_json_content_type: true,
  });
};

export const historyShipping = (params) => {
  return axios({
    method: "GET",
    url: `/customer-shipping-carrier/logs`,
    params
  });
};
