import { all } from 'redux-saga/effects';
import alertSaga from './utils/alert/saga';
import authSaga from './scenes/Auth/saga';
import userSaga from './scenes/Users/saga';
import campaignSaga from './scenes/Campaigns/saga';
import mockupSaga from './scenes/Mockups/saga';
import promotionSaga from './scenes/Promotions/saga';
import collectionSaga from './scenes/Collections/saga';
import syncProductSaga from './scenes/SyncProducts/saga';
import orderSaga from './scenes/Orders/saga';
import profitSaga from './scenes/Profits/saga';
import orderTrackingSaga from './scenes/OrderTracking/saga';
import storeSage from './scenes/Stores/saga';
import transactionSaga from './scenes/Transactions/saga';
import dataFeedSage from './scenes/DataFeed/saga';
import settingSage from './scenes/Settings/saga';
import analyticSage from './scenes/Analytics/saga';
import indemnication from "./scenes/Indemnification/saga";
import notificationSaga from './scenes/Notifications/saga';
import notificationShippingSaga from './scenes/NotificationsShipping/saga';
import webhooksSaga from './scenes/Webhooks/saga';
import generalSaga from './scenes/General/saga';
import shippingSaga from './scenes/Shipping/saga';
import userlabelSaga from './scenes/Userlabel/saga';
import giftMessageSaga from './scenes/GiftMessage/saga';
import imageLibrarySaga from './scenes/ImageLibrary/saga';
import invoice from './scenes/Invoice/saga';
import apiCre from './scenes/ApiCredential/saga';
import topup from './scenes/TopUp/saga';
import BillingInfo from './scenes/BillingPaymentAccount/saga';
import Logs from './scenes/Logs/saga';
import Dashboard from './scenes/Dashboard/saga';
import configBuyTracking from './scenes/ConfigBuyTracking/saga';


const Saga = function* () {
  yield all([
    alertSaga(),
    authSaga(),
    userSaga(),
    campaignSaga(),
    mockupSaga(),
    promotionSaga(),
    collectionSaga(),
    syncProductSaga(),
    orderSaga(),
    profitSaga(),
    orderTrackingSaga(),
    storeSage(),
    transactionSaga(),
    dataFeedSage(),
    settingSage(),
    analyticSage(),
    shippingSaga(),
    notificationSaga(),
    notificationShippingSaga(),
    webhooksSaga(),
    userlabelSaga(),
    giftMessageSaga(),
    imageLibrarySaga(),
    invoice(),
    generalSaga(),
    apiCre(),
    BillingInfo(),
    topup(),
    Logs(),
    Dashboard(),
    configBuyTracking(),
    indemnication()
  ]);
};

export default Saga;
