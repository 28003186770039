import styled from "styled-components";
import Notification from "components/Notification";
import { GRAY_900, WARNING_600 } from "components/colors";

export default styled(Notification)`
  .columns.notify {
    gap: 12px;
  }

  .title {
    font-weight: 600;
    color: ${GRAY_900};
    font-size: 14px;
  }
  .desc {
    font-weight: 400;
    a {
      color: ${WARNING_600};
      font-weight: 600px;
    }
    p {
      margin-bottom: 4px;
      color: ${GRAY_900}
    }
  }
`;
