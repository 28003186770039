import { takeLatest, call, put, all } from "redux-saga/effects";

import { actionType as TYPE } from "./actions";

import {exportFeedFormGoogle}  from "apis/campaign"

  function* exportFeedFormGoogleSaga(action){
    const { data } = action
    try {
      const response = yield call(exportFeedFormGoogle, data)
      if(response.code === 'success'){
        yield put({ type: TYPE.EXPORT_FEED_FORM_GOOGLE.SUCCESS, ...response })
      }else{
          yield put({ type: TYPE.EXPORT_FEED_FORM_GOOGLE.FAIL, error: response})
      }
    } catch(error){
      yield all([
        yield put({ type: TYPE.EXPORT_FEED_FORM_GOOGLE.FAIL, error })
      ])
    }
  }

function* watcher() {
    yield takeLatest(TYPE.EXPORT_FEED_FORM_GOOGLE.REQUEST, exportFeedFormGoogleSaga);
}

export default watcher;
