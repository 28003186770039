import {
    takeLatest,
    call,
    put,
    all
} from 'redux-saga/effects'

import {
    actionType as TYPE
} from './actions'
import {listCampaignToPreview} from 'apis/campaign'


function* listCampaignToPreviewSaga(action){
    const { listCampaignIds } = action
    try {
        const response = yield call(listCampaignToPreview, listCampaignIds)
        if(response.code === 'success'){
            yield put({ type: TYPE.LIST_CAMPAIGN_TO_PREVIEW.SUCCESS, ...response })
        }else{
            yield put({ type: TYPE.LIST_CAMPAIGN_TO_PREVIEW.ERROR, error: response })
        }
    } catch(error){
        yield all([
            yield put({ type: TYPE.LIST_CAMPAIGN_TO_PREVIEW.ERROR, error })
        ])
    }
}


function* watcher(){
    yield takeLatest(TYPE.LIST_CAMPAIGN_TO_PREVIEW.REQUEST, listCampaignToPreviewSaga);

}

export default watcher