import {
    actionType as TYPE
} from './actions'
import {notification} from "antd";
const initialState = {
    updating: false,
    detail: {
        loading: false,
        data: {}
    }
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case TYPE.DETAIL.REQUEST:
            return {
                ...state,
                detail: {
                    ...initialState.detail,
                    loading: true
                }
            }
        case TYPE.DETAIL.SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    data: action.data.mockup,
                    loading: false
                }
            }
        case TYPE.DETAIL.ERROR:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false
                }
            }

        case TYPE.UPDATE.REQUEST:
            return {
                ...state,
                updating: true,
            }
        case TYPE.UPDATE.SUCCESS:
            notification.success({className: "notic2", message: 'Update mockup successfully.', duration: 5});
            return {
                ...state,
                updating: false,
            }
        case TYPE.UPDATE.ERROR:
            notification.warn({
                className: "notic1",
                message: action.error && action.error[0] && action.error[0]['msg'] ? action.error[0]['msg'] : 'Update mockup failed.',
                duration: 5
            });
            return {
                ...state,
                updating: false
            }
        default:
            return state
    }
}

export default reducer