import {
    takeLatest,
    call,
    put,
    all, select
} from 'redux-saga/effects'
import {
    actionType as TYPE
} from './actions'
import {deleteOrder, getListOrderAchived, unarchiveOrder,countOrderAchived} from 'apis/orders'

function* getListOrderAchiveSaga(action) {
    const {params} = action
    try {
        const response = yield call(getListOrderAchived, params)
        if (response.code === 'success') {
            yield put({type: TYPE.LIST_ARCHIVED_ORDER.SUCCESS, ...response})
        } else {
            yield put({type: TYPE.LIST_ARCHIVED_ORDER.ERROR, error: response})
        }
    } catch (error) {
        yield all([
            yield put({type: TYPE.LIST_ARCHIVED_ORDER.ERROR, error})
        ])
    }
}

function* unarchiveOrderSaga(action) {
    try {
        const {data} = action;
        const response = yield call(unarchiveOrder, data);
        const {order} = select();
        yield all([
            put({type: TYPE.UNARCHIVE_ARCHIVED_ORDER.SUCCESS, errors: response}),
            put({
                type: TYPE.LIST_ARCHIVED_ORDER.REQUEST,
                params: order?.order_achive?.archivedOrders?.params || { page: 1, perPage: 50 },
            })
        ])
    } catch (error) {
        yield all([
            yield put({type: TYPE.UNARCHIVE_ARCHIVED_ORDER.ERROR, error})
        ])
    }
}

function* deleteOrderSaga(action) {
    try {
        const {data} = action;
        const response = yield call(deleteOrder, data)
        const {order} = select();
        yield all([
            put({type: TYPE.DELETE_ARCHIVED_ORDER.SUCCESS, errors: response}),
            put({
                type: TYPE.LIST_ARCHIVED_ORDER.REQUEST,
                params: order?.order_achive?.archivedOrders?.params || { page: 1, perPage: 50 },
            })
        ])
    } catch (error) {
        yield all([
            yield put({type: TYPE.DELETE_ARCHIVED_ORDER.ERROR, error})
        ])
    }
}
function* exportOrdersSaga(action) {
    const { params } = action;
    try {
      const response = yield call(getListOrderAchived, {
        ...params,
      });
      if (response.code === "success") {
        yield put({ type: TYPE.EXPORT.SUCCESS, ...response });
      } else {
        yield put({ type: TYPE.EXPORT.ERROR, error: response });
      }
    } catch (error) {
      yield all([yield put({ type: TYPE.EXPORT.ERROR, error })]);
    }
  }
//Count achived 
function* countOrderAchivedSaga(action) {
    try {
      const response = yield call(countOrderAchived);
      if (response.code === "success") {
        yield put({ type: TYPE.COUNT_ORDER_ACHIVED.SUCCESS, ...response });
      } else {
        yield put({ type: TYPE.COUNT_ORDER_ACHIVED.ERROR, response });
      }
    } catch (error) {
      yield all([yield put({ type: TYPE.COUNT_ORDER_ACHIVED.ERROR, error })]);
    }
  }
  

function* watcher() {
    yield takeLatest(TYPE.LIST_ARCHIVED_ORDER.REQUEST, getListOrderAchiveSaga)
    yield takeLatest(TYPE.DELETE_ARCHIVED_ORDER.REQUEST, deleteOrderSaga)
    yield takeLatest(TYPE.UNARCHIVE_ARCHIVED_ORDER.REQUEST, unarchiveOrderSaga)
    yield takeLatest(TYPE.COUNT_ORDER_ACHIVED.REQUEST, countOrderAchivedSaga);
    yield takeLatest(TYPE.EXPORT.REQUEST, exportOrdersSaga);
}

export default watcher