import {
  ORDER_DETAIL_REQUEST,
  ORDER_UPDATE_ADDRESS_REQUEST,
  UPDATE_GIFT_MESSAGE_REQUEST,
  UPDATE_SHIPPING_SERVICE_REQUEST,
  DUPLICATE_ORDER_REQUEST,
  LOCK_ORDER_REQUEST,
  LIST_DUPLICATED_ORDER_REQUEST,
  MARK_AS_VALID_ADDRESS_REQUEST,
  CANCEL_ORDER_REQUEST
} from './constants';

export function getDetail(id) {
  return {
    type: ORDER_DETAIL_REQUEST,
    id,
  };
}

export function updateAddress(id, params) {
  return {
    type: ORDER_UPDATE_ADDRESS_REQUEST,
    id,
    params,
  };
}

export function updateGiftMessage(id, data) {
  return {
    type: UPDATE_GIFT_MESSAGE_REQUEST,
    id,
    data,
  };
}

export function updateShippingService(id, data) {
  return {
    type: UPDATE_SHIPPING_SERVICE_REQUEST,
    id,
    data,
  };
}

export function duplicateOrder(data) {
  return {
    type: DUPLICATE_ORDER_REQUEST,
    data,
  };
}
export function cancelOrderAction(data) {
  return {
    type: CANCEL_ORDER_REQUEST,
    data,
  };
}

export function lockOrder(id) {
  return {
    type: LOCK_ORDER_REQUEST,
    id,
  };
}

export function listDuplicatedOrder(id) {
  return {
    type: LIST_DUPLICATED_ORDER_REQUEST,
    id,
  };
}

export function markAsValidAddressAction(id) {
  return {
    type: MARK_AS_VALID_ADDRESS_REQUEST,
    id,
  };
}
