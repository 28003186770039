const validate = (values) => {
  let errors = {};
  if (!values.showName || values.showName.replace(/\s+/g, "").length === 0) {
    errors.showName = "This field cannot be left blank";
  }
  if (!values.name) {
    errors.name = "This field cannot be left blank";
  } else if (
    values.name.includes("https://") ||
    values.name.includes("http://") || values.name.replace(/\s+/g, "").length === 0
  ) {
    errors.name = "This merchant is invalid.";
  }
  return errors;
};
export default validate;
