import {
  GRAY_200,
  GRAY_400,
  GRAY_600,
} from "components/colors";
import styled from "styled-components";

export const TableWrapper = styled.div`
  overflow-x: clip;
  .ant-table-ping-left .ant-table-cell-fix-left-first::after, .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px rgba(16, 24, 40, 0.08);
    /* box-shadow: inset 10px 0 8px -8px rgba(16, 24, 40, 0.03) */
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after, .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    box-shadow: inset -10px 0 8px -8px rgba(16, 24, 40, 0.08);
  }

  svg.item_more-options {
    stroke: ${GRAY_400};
  }
  .ant-table-header.ant-table-sticky-holder {
    top: 62px!important;
  }
`;
