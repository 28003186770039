export const actionType = {
  GIFTMESSAGE_UPDATE: {
    REQUEST: "GIFTMESSAGE.UPDATE.REQUEST",
    SUCCESS: "GIFTMESSAGE.UPDATE.SUCCESS",
    ERROR: "GIFTMESSAGE.UPDATE.ERROR",
  },
  GIFTMESSAGE_DETAIL: {
    REQUEST: "GIFTMESSAGE.DETAIL.REQUEST",
    SUCCESS: "GIFTMESSAGE.DETAIL.SUCCESS",
    ERROR: "GIFTMESSAGE.DETAIL.ERROR",
  },
};
export function updateGiftMessage(id, dataToUpdate) {
  return {
    type: actionType.GIFTMESSAGE_UPDATE.REQUEST,
    id,
    dataToUpdate,
  };
}
export function getDetailGiftMessage(id) {
  return {
    type: actionType.GIFTMESSAGE_DETAIL.REQUEST,
    id,
  };
}
