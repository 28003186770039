import { combineReducers } from "redux";
import list from "./scenes/List/reducer";
import risk from "./scenes/Risk/reducer";
import detail from "./scenes/Detail/reducer";
import items_removed from "./scenes/ItemsRemoved/reducer";
import tracking from "./scenes/Tracking/reducer";
import manual_payment from "./scenes/ManualPayment/reducer";
import manual_payment_detail from "./scenes/ManualPaymentDetail/reducer";
import order_achive from "./scenes/ArchivedOrder/reducer";
import issue from "./scenes/Issue/reducer";
import issue_detail from "./scenes/DetailIssue/reducer";
import reportSyncTracking from "./scenes/ReportSyncTracking/reducer";
import reportSyncTrackingDetail from "./scenes/ReportSyncTrackingDetail/reducer";
import import_progress from "./scenes/ImportProgress/reducer";
import import_progress_detail from "./scenes/ImportProgressDetail/reducer";
import report_inkgo from "./scenes/ReportInkgo/reducer";
import createIssue from "./scenes/Create_Issues/reducer";
import syncOrder from "./scenes/SyncOrder/reducer";
import importOrder from "./scenes/ImportOrder/reducer";
import reportOrder from "./scenes/ReportProductSell/reducer";
import createOrder from "./scenes/Create/reducer";
import invoice from "./scenes/Invoice/reducer";
import matchProduct from "./scenes/MatchProduct/reducer";
import stock_status from "./scenes/StockStatus/reducer";
import CreateLableOrder from "./scenes/CreateLableOrder/reducer";
import convertOrderLabel from "./scenes/ConvertOrderLabel/reducer";
import duplicateOrderFba from "./scenes/DuplicateOrderFBA/reducer";
const reducer = combineReducers({
  list,
  risk,
  detail,
  // edit,
  order_achive,
  items_removed,
  tracking,
  manual_payment,
  manual_payment_detail,
  issue,
  reportSyncTracking,
  import_progress,
  report_inkgo,
  createIssue,
  syncOrder,
  importOrder,
  import_progress_detail,
  createOrder,
  invoice,
  matchProduct,
  stock_status,
  reportSyncTrackingDetail,
  reportOrder,
  CreateLableOrder,
  convertOrderLabel,
  duplicateOrderFba,
  issue_detail
});

export default reducer;
