import { notification } from "antd";
import { actionType as TYPE } from "./actions";

const initialState = {
  update: {
    loading: false,
  },
  detail: {
    loading: false,
    data: {},
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST_USERLABEL_DETAIL.REQUEST:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          loading: true,
        },
      };
    case TYPE.LIST_USERLABEL_DETAIL.SUCCESS:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          data: action.data.userLabel,
          loading: false,
        },
      };
    case TYPE.LIST_USERLABEL_DETAIL.ERROR:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
        },
      };

    case TYPE.LIST_USERLABEL_UPDATE.REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      };
    case TYPE.LIST_USERLABEL_UPDATE.SUCCESS:
      notification.success({
        message: action?.clientMsg || "Update return address successfully.",
        duration: 5,
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
        },
      };
    case TYPE.LIST_USERLABEL_UPDATE.ERROR:
      notification.warn({
        message:
        action && action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Update return address failed.",
        duration: 5,
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
