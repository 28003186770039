import React, { Component } from "react";
import { connect } from "react-redux";
import FormChangeEmail from "./components/FormChangeEmail";
import { update } from "./actions";
import { Spin } from "antd";
export class index extends Component {
  handleChangeEmail = (values) => {
    this.props.update(values);
  };
  render() {
    const { updating } = this.props;
    return (
      <div className="profile-section bg-white p-3 m-3 mx-lg-auto">
        <Spin spinning={updating}>
          <FormChangeEmail onSubmit={this.handleChangeEmail} />
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  updating: state.user.change_email.updating,
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => {
    dispatch(update(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
