import React, { useState, useEffect } from 'react';
import Guideline from './Components/Guideline';
import Integration from './Components/Integration';
import NextSteps from './Components/NextSteps';
import Shortcut from './Components/Shortcut';
import Tips from './Components/Tips';
import TrendingProduct from './Components/TrendingProduct';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RenderVerificationEmailStep from 'utils/Form/RenderVerificationEmailStep';
import { Wrapper } from './styled';
import AdsPopup from 'components/AdsPopup';
import Minigame from 'components/Minigame';
import { getUserStepsRequestAction } from './actions';
import NoBillingInforWarningBlock from 'scenes/BillingPaymentAccount/v2/NoBillingInforWarningBlock';
import GMRow from 'components/GMLayout/GMRow';
import { Col } from 'antd';

export default (props) => {
  const [showPopUp, setShowPopUp] = useState(
    false //Cookies.get("cookieAlert") ? false : true
  );
  const dispatch = useDispatch();

  const [steps, setSteps] = useState([]);
  useEffect(() => {
    setSteps([
      {
        key: 'sign_up',
        title: 'Sign up for Gearment',
        description: null,
        isCompleted: true,
      },
      {
        key: 'confirm_email',
        title: 'Confirm your email',
        isCompleted: true,
        description: <RenderVerificationEmailStep />,
      },
      {
        key: 'add_payment_method',
        title: 'Add a payment method',
        description: (
          <>
            <Link to="/billings/payment-method">Add a payment method</Link> so
            we can fulfll your orders
          </>
        ),
        isCompleted: true,
      },
      {
        key: 'setup_fulfillment_settings',
        title: 'Setup fullfilment settings',
        description: (
          <>
            <Link to="/billings/legal-info">Setup fullfilment settings</Link> to
            start selling
          </>
        ),
        isCompleted: true,
      },
    ]);
  }, []);

  useEffect(() => {
    dispatch(getUserStepsRequestAction());
  }, [dispatch]);

  const profile = useSelector((state) => state.auth.info.profile);
  return (
    <>
      <Minigame invalidCallback={() => setShowPopUp(true)} />
      {showPopUp ? <AdsPopup /> : null}
      <Wrapper>
        <h2 className="title">
          Welcome back,
          {profile?.name}
        </h2>
        <NoBillingInforWarningBlock className="no-billing-info-warning" />
        <GMRow className="wrapper">
          <Col lg={8 * 2} sm={12 * 2} className="left">
            <Shortcut />
            <Integration className="integration" />
            <NextSteps />
            <Guideline />
            <Tips />
          </Col>
          <Col lg={4 * 2} sm={12 * 2} className="right">
            <Integration className="integration" />
          </Col>
          <TrendingProduct className="left col-sm-12" />
        </GMRow>
      </Wrapper>
    </>
  );
};
