import React, { useEffect, useState } from "react";
import PayonnerLogo from "images/small-payoneer.png";
import LoginPayoneerButton from "./LoginPayoneerButton";
import { Spin, notification } from "antd";
import RegisterPayoneerButton from "./RegisterPayoneerButton";
import { getListPayoneer, submitRegisterPayoneer } from "apis/payment_menthod";
import { useHistory } from 'react-router-dom'
import queryString from "query-string";
import RemovePayoneerAccountButton from "./RemovePayoneerAccountButton";
function PayoneerMethod({
  input,
  mode = "default",
  meta: { error },
}) {
  const [loading, setLoading] = useState(false);
  const [payoneerAccount, setPayoneerAccount] = useState(null);
  const history = useHistory();
  const getPayoneerAccount = () => {
    setLoading(true);
    getListPayoneer()
      .then((response) => {
        setLoading(false);
        if (response?.code === "success") {
          setPayoneerAccount(response?.data);
          input.onChange(response?.data?.id || 0);
        } else {
          input.onChange(0);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  useEffect(() => {
    const params = queryString.parse(
      window.location.search
    );

    if (params?.pid && params?.token) {
      const dataToAddPayoneer = {
        token: params.token,
        pid: params.pid,
      };
      delete params['token']
      delete params['pid']
      history.replace({
        search: queryString.stringify(params)
      });
      setLoading(true);
      submitRegisterPayoneer(dataToAddPayoneer)
        .then(rs => {
          if (rs?.code === "success") {
            notification.success({
              message:
                rs?.clientMsg ||
                "Your Payoneer account has been connected successfully.",
            });
          } else {
            notification.warn({
              message:
                rs?.[0]?.clientMsg ||
                "Can not connect to your Payoneer account.",
            });
          }
        })
        .finally(() => {
          getPayoneerAccount();
        });
    } else {
      getPayoneerAccount();
    }
  }, []);
  return (
    <Spin spinning={loading}>
      <div className="p-3 text-center rounded payoneer-wallet-card">
        <img className="img-fluid" alt="payoneer logo" src={PayonnerLogo} />
        <p className="mt-3 mb-0">
          If you prefer Payoneer, this is fine with us! If you add both, we will
          use Payoneer as the default option.
        </p>
        <p className="mt-3 mb-0">
          Link Payoneer account (for international accounts)
        </p>
        {!payoneerAccount && (
          <div className="mt-3">
            <LoginPayoneerButton />
            <RegisterPayoneerButton />
          </div>
        )}
        {payoneerAccount && (
          <div className="mt-3">
            <p>
              Your Payoneer account is added to Gearment. Account ID:{" "}
              {payoneerAccount?.content}
            </p>
            <RemovePayoneerAccountButton getPayoneerAccount={getPayoneerAccount} />
          </div>
        )}
        <p className="mt-3 mb-0">
          <em>0% fee for payment to Gearment via Payoneer</em>
        </p>
        {(error) && <span className="invalid-feedback font-size-14">{error}</span>}
      </div>
    </Spin>
  );
}

export default React.memo(PayoneerMethod);
