import React, { Component } from 'react';
import { Select } from 'antd';
import { uniqueId } from 'lodash';
const Option = Select.Option;

class Index extends Component {
  render() {
    const {
      input,
      meta: { error, submitFailed },
      options,
      loading,
      mode = 'default',
      size = 'large',
      placeholder,
      defaultValue = undefined,
      onChangeTypeProduct = null,
      onChangeCountryCode = null,
      className,
      parseKey2String = false,
      ...rest
    } = this.props;
    if (defaultValue !== undefined) {
      input.value = defaultValue;
    }
    let aValue = parseKey2String
      ? (input.value || defaultValue) + ''
      : input.value || defaultValue;
    if (aValue === 'undefined') {
      aValue = undefined;
    }
    
    return (
      <div className="position-relative">
        <Select
          style={{ width: '100%' }}
          {...rest}
          mode={mode || 'default'}
          size={size}
          value={aValue}
          loading={loading}
          // showSearch
          className={className}
          optionFilterProp="children"
          placeholder={placeholder}
          onChange={(value) => {
            input.onChange(value === undefined ? null : value);
            if (onChangeTypeProduct) {
              onChangeTypeProduct(value === undefined ? null : value);
            }
            if (onChangeCountryCode) {
              onChangeCountryCode(
                options.find((option) => option.value === value)?.countryIsoCode
              );
            }
          }}
          filterOption={(input, option) =>
            String(option.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {options.map((option) => (
            <Option
              key={uniqueId(option.value)}
              value={`${option.value}`}
              disabled={option.disabled}
            >
              {option.label}
            </Option>
          ))}
        </Select>
        {submitFailed && error && (
          <span className="invalid-feedback">{error}</span>
        )}
      </div>
    );
  }
}

export default Index;
