const validate = values => {
    let errors = {};
    if(!values.phone){
        errors.phone = "Please enter number phone."
    }else if(/[^0-9\(\)\-\s]/.test(values.phone)){
        errors.phone = "Phone number only container digits"
    }
    if(!values.addressOne || values.addressOne.replace(/\s+/g, "").length === 0){
        errors.addressOne = "Please enter address 1."
    }
    if(!values.city || values.city.replace(/\s+/g, "").length === 0){
        errors.city = "Please enter city."
    }
    return errors
};
export default validate;