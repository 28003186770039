import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType } from "./actions";
import { replace } from "connected-react-router";
import { resetPassword, verifyTokenResetPassword } from "apis/auth";

function* resetPasswordSaga(action) {
  try {
    const { data } = action;
    const response = yield call(resetPassword, data);
    if (response.code === "success") {
      yield all([
        put({ type: actionType.RESET.SUCCESS, payload: response }),
      ]);
    } else {
      yield put({ type: actionType.RESET.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: actionType.RESET.ERROR, error })]);
  }
}

function* verifyTokenSaga(action) {
  try {
    const { data } = action;
    const response = yield call(verifyTokenResetPassword, data);
    if (response.code === "success") {
      yield all([put({ type: actionType.VERIFY_TOKEN.SUCCESS, payload: response })]);
    } else {
      yield put({ type: actionType.VERIFY_TOKEN.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: actionType.VERIFY_TOKEN.ERROR, error })]);
  }
}

function* watcher() {
  yield all([takeLatest(actionType.RESET.REQUEST, resetPasswordSaga)]);
  yield all([takeLatest(actionType.VERIFY_TOKEN.REQUEST, verifyTokenSaga)]);
}

export default watcher;
