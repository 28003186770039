import axios from 'utils/request'

const prefix = '/image-design';

export const getList = (params = {}) => {
    return axios({
        method: 'GET',
        params,
        url: `${prefix}`
    })
}

export const getDetail = (id) => {
    return axios({
        method: 'GET',
        url: `${prefix}/${id}`
    })
}

export const create = (data) => {
    return axios({
        method: 'POST',
        data,
        url: `${prefix}`
    })
}


export const archive = (data) => {
    return axios({
        method: 'PUT',
        data,
        url: `${prefix}/archived-design`,
        is_json_content_type: true
    })
}

export const search = (params) => {
    return axios({
        method: 'GET',
        params,
        url: `${prefix}/searching`
    })
}

export const getPresignedUrlS3 = (key) => {
    return axios({
        method: "POST",
        data: {
            key
        },
        url: `${prefix}/s3-presigned-url`
    })
}

export const getPresignedUrlS3PDF = (key) => {
    return axios({
        method: "POST",
        data: {
            key
        },
        url: `${prefix}/s3-presigned-url-pdf`
    })
}

export const uploadArtwork = (s3Url, originalName = '') => {
    return axios({
        method: "POST",
        data: {
            s3Url,
            originalName
        },
        url: `${prefix}/upload-artwork-v2`
    })
}

export const exportAll = (s3Url, originalName = '') => {
    return axios({
        method: "POST",
        data: null,
        url: `${prefix}/export`
    })
}
