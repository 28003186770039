import React from 'react';
import { ReactComponent as CheckCircleIcon } from 'images/icons/check-circle.svg';
import { GlowingSVG } from 'components/SVG';
import { SUCCESS_100, SUCCESS_50, SUCCESS_600 } from 'components/colors';
import { Link } from 'react-router-dom';
import { GMButton, LinkButton } from 'components/Button';


export default (props) => {
  return (
    <div className="d-flex flex-column">
      <div className="text-center">
        <GlowingSVG
          backgroundColor={SUCCESS_100}
          borderColor={SUCCESS_50}
          svgStrokeColor={SUCCESS_600}
        >
          <CheckCircleIcon />
        </GlowingSVG>
      </div>
      <h2 className="text-center header">
        Password reset
      </h2>
      <p className="text-center message">
        Your password has been successfully reset. Click below to log in magically
      </p>
      <div className="text-center back2login">
        <GMButton
          to="/auth/login"
          className="btn-back-cta"
          as={Link}
        >
          Log in
        </GMButton>
      </div>
    </div>
  );
};
