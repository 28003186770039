import { actionType as TYPE } from "./actions";
import { notification } from "antd";
const initialState = {
  exportFeedFormGoogle: {
    loading: false
  }
};

function FeedFormGoogleReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.EXPORT_FEED_FORM_GOOGLE.REQUEST:
      return {
        ...state,
        exportFeedFormGoogle: {
          ...state.exportFeedFormGoogle,
          loading: true
        }
      }
    case TYPE.EXPORT_FEED_FORM_GOOGLE.SUCCESS:  
      notification.success({
        message: action.clientMsg || "Export feed form Google successfully.",
        duration: 5
    });
      return {
        ...state,
        exportFeedFormGoogle: {
          ...state.exportFeedFormGoogle,
          loading: false,
        }
      }
    case TYPE.EXPORT_FEED_FORM_GOOGLE.FAIL:
      notification.warn({
        message: action.error && action.error[0] && action.error[0]['clientMsg'] ? action.error[0]['clientMsg'] : 'Export feed form Google failed.',
        duration: 5
    });
      return {
        ...state,
        exportFeedFormGoogle: {
          ...state.exportFeedFormGoogle,
          loading: false
        }
      }
    default:
      return state;
  }
}

export default FeedFormGoogleReducer;
