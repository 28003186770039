import axios from "utils/request";

const prefix = "order-issues";

export const getList = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}?with[]=orderIssueCategory&with[]=order&with[]=messages&with[]=order.storeIntegration&&with[]=order.shipment&with[]=order.orderMatch`,
  });
};

export const getDetailIssueRating = (id) => {
  return axios({
    method: "GET",
    url: `${prefix}/${id}`,
  });
};

export const getDetailIssue = (id) => {
  return axios({
    method: "GET",
    url: `${prefix}/${id}?with[]=order&with[]=orderIssueCategory&with[]=messages`,
  });
};


export const update = (id, data) => {
  return axios({
    method: "PUT",
    data,
    url: `${prefix}/${id}`,
    is_json_content_type: true,
  });
};
export const updateRating = (id, data) => {
  return axios({
    method: "PUT",
    data,
    url: `issue/rating/${id}`,
    is_json_content_type: true,
  });
};

export const detailIssue = (id) => {
  return axios({
    method: "GET",
    url: `issue/${id}`,
    is_json_content_type: true,
  });
};

export const create = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/order-issues/v2`,
    is_json_content_type: true,
  });
};

export const deleteIssue = (data) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}/delete`,
    is_json_content_type: true,
  });
};

export const checkOrderForCreatingIssue = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/orders/check-order-for-creating-issue`,
    is_json_content_type: true,
  });
};

export const getStatistic = (params) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}/statistic`,
  });
};

export const listMessages = (issueId, params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}/${issueId}/messages?sortBy=createdAt&sortType=desc`
  })
}

export const createMessage = (issueId, data) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}/${issueId}/message`
  })
}
export const updateMessage = (issueId, data = {}) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}/${issueId}/message`
  })
}