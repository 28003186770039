import React, { useState, useEffect } from "react";
import { Select } from "antd";
import classNames from "classnames";
import { change } from "redux-form";
import { useDispatch } from "react-redux";

const { Option } = Select;

export default ({
  input,
  mode = "default",
  invalid,
  size,
  onChoose,
  inputProps = {},
  meta,
  classNameContainer = "",
  placeholder = '',
  no1stValue = false,
  showError = false,
  options = [],
  defaultValue,
  showSearch = true,
  ...props
}) => {
  const { error, submitFailed, form: formName } = meta;
  const [_options, setOptions] = useState(!no1stValue ? [
    { label: placeholder, value: '' },
  ] : []);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!no1stValue) {
      setOptions([
        { label: placeholder, value: "" },
        ...options,
      ]);
    } else {
      setOptions(options)
    }
    if (value && input.name) {
      dispatch(change(formName, input.name, value));
    }
  }, [options]);
  const onSelect = (data) => {
    input.onChange(data);
  };
  const value = input?.value + '';
  return (
    <div className={classNames("position-relative", classNameContainer)}>
      <Select
        placeholder={placeholder}
        showSearch={showSearch}
        defaultValue={defaultValue}
        value={value}
        style={{ width: "100%" }}
        size={size || "large"}
        onChange={onSelect}
        optionFilterProp="children"
        {...inputProps}
      >
        {_options.map((item) => (
          <Option key={item.value}>{item.label}</Option>
        ))}
      </Select>
      {invalid && (
        <hr
          className="border-danger m-0 position-absolute"
          style={{ width: "90%", left: "5%", top: size === "middle" ? 31 : 39 }}
        />
      )}
      {(showError ? error : error && submitFailed) && <span className="invalid-feedback">{error}</span>}
    </div>
  );
};
