import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Popconfirm, Button, Spin, Tooltip } from "antd";
import { getList, deletePromotion } from "./actions";
import queryString from "query-string";
import plusCircle from "../../../../images/plus-circle.svg";
import getMomentInstance from "utils/moment";
import edit from "images/edit.svg";
import deleteIcon from "images/deleteIcon.svg";
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        sortBy: "id",
        sortType: "desc",
        ...query_params,
      },
    };
  }
  componentDidMount() {
    this.fetchData(this.state.initial_filter_values);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
      this.fetchData(this.state.initial_filter_values);
    }
  }

  onCreatePromotionsButtonClick = () => {
    this.props.history.push("/promotions/create");
  };
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getList(params);
  };
  deletePromotion = (id) => {
    this.props.deletePromotion(id);
  };
  render() {
    const { list, pageName, _delete } = this.props;
    const columns = [
      {
        title: <b>PROMOTION CODE</b>,
        dataIndex: "code",
        align: "center",
        key: "code",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>AMOUNT</b>,
        dataIndex: "age",
        align: "center",
        key: "age",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>EXPIRATION</b>,
        dataIndex: "expiredAt",
        key: "expiredAt",
        align: "center",
        render: (value) => getMomentInstance(value, 0, false, true),
      },
      {
        title: <b>COUNT USED</b>,
        dataIndex: "quantityUsed",
        key: "quantityUsed",
        align: "center",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>TIME CREATED</b>,
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        render: (value) => getMomentInstance(value, 0, false, true),
      },
      {
        title: <b>ACTION</b>,
        // fixed: "right",
        align: "center",
        render: (record) => (
          <div>
            <Tooltip title="Edit" placement="top">
              {" "}
              <Link to={`/promotions/${record.id}/edit`} className="mr-1">
                <img src={edit} height={21} alt='edit-icon'/>
              </Link>
            </Tooltip>
            <Popconfirm
              title="Are you sure to delete this record?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.deletePromotion.bind(this, record.id)}
            >
              <Link>
                {" "}
                <img src={deleteIcon} height={21} alt='delete-icon'/>
              </Link>
            </Popconfirm>
          </div>
        ),
      },
    ];

    return (
      <Spin spinning={_delete.loading}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center my-3">
            <h1 className="h3 mb-0 font-weight-bold">{pageName}</h1>
            <Button
              type="primary"
              className="create-discount-button d-flex justify-content-center align-items-center"
              icon={
                <img
                  src={plusCircle}
                  height={15}
                  style={{ marginRight: 10 }}
                  alt="plus-icon"
                />
              }
              size="large"
              onClick={this.onCreatePromotionsButtonClick}
            >
              Create Promotions
            </Button>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={list.data}
            loading={list.loading}
            scroll={{ x: 1000 }}
            pagination={{
              showTotal: (total) => `Total ${total} items`,
              total: list.total,
              pageSize: parseInt(
                list.params.perPage > list.params.total
                  ? list.params.total
                  : list.params.perPage
              ),
              current: list.params.page,
              showSizeChanger: true,
              onChange: (page, perPage) =>
                this.fetchData({ ...list.params, page, perPage }),
            }}
          />
        </div>
      </Spin>
    );
  }
}

export default connect(
  (state) => ({
    list: state.promotion.list.list,
    _delete: state.promotion.list.delete,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
    deletePromotion: (id) => {
      dispatch(deletePromotion(id));
    },
  })
)(List);
