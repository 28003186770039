import axios from "utils/request";

const prefix = "/customer-config";

export const listInvoiceSetting = () => {
  return axios({
    method: "GET",
    url: `${prefix}`,
  });
};

export const updateAutoCharge = (data) => {
  return axios({
    method: "PUT",
    data,
    url: `${prefix}/update-auto-charge`,
    is_json_content_type: true,

  });
};
export const updateReturnAddress = (data) => {
  return axios({
    method: "PUT",
    data,
    url: `${prefix}/update-return-address-setting`,
    is_json_content_type: true,
  });
};
export const updateInvoiceConfig = (data) => {
  return axios({
    method: "PUT",
    data,
    url: `${prefix}/update-invoice-setting`,
    is_json_content_type: true,
  });
};
