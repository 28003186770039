import {
  STORE_EDIT_REQUEST,
  STORE_DETAIL_REQUEST,
  STORE_EDIT_EBAY_REQUEST,
  STORE_EDIT_ETSY_REQUEST,
  STORE_EDIT_SHOPBASE_REQUEST,
  STORE_EDIT_SHOPBASE_TOKEN_REQUEST,
  LINK_SHOPBASE_TOKEN_REQUEST,
  LINK_SHOPBASE_MERCHANT_REQUEST,
  STORE_EDIT_SHOPBASE_NORMAL_REQUEST,
  GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_REQUEST,
  UPDATE_SHOPIFY_TOKEN_REQUEST,
  UPDATE_SHOPIFY_STORE_REQUEST,
  LINK_ETSY_TOKEN_REQUEST,
  UPDATE_ETSY_TOKEN_REQUEST,
  LINK_AMAZON_TOKEN_REQUEST,
  UPDATE_AMAZON_TOKEN_REQUEST,
} from './constants';

export function updateStore(dataToUpdate, id) {
  return {
    type: STORE_EDIT_REQUEST,
    dataToUpdate,
    id,
  };
}

export function detailStore(id) {
  return {
    type: STORE_DETAIL_REQUEST,
    id,
  };
}
export function editEbayStore(dataToUpdate, id) {
  return {
    type: STORE_EDIT_EBAY_REQUEST,
    dataToUpdate,
    id,
  };
}
export function editEtsyStore(dataToUpdate, id) {
  return {
    type: STORE_EDIT_ETSY_REQUEST,
    dataToUpdate,
    id,
  };
}
export function editShopBaseStore(dataToUpdate, id) {
  return {
    type: STORE_EDIT_SHOPBASE_REQUEST,
    dataToUpdate,
    id,
  };
}
export function editStoreShopbaseToken(dataToUpdate, id) {
  return {
    type: STORE_EDIT_SHOPBASE_TOKEN_REQUEST,
    dataToUpdate,
    id,
  };
}

export function editStoreShopbaseNormal(dataToUpdate, id) {
  return {
    type: STORE_EDIT_SHOPBASE_NORMAL_REQUEST,
    dataToUpdate,
    id,
  };
}

export function getLinkShopbaseToken(params) {
  return {
    type: LINK_SHOPBASE_TOKEN_REQUEST,
    params,
  };
}

export function getLinkShopbaseMerchant(params) {
  return {
    type: LINK_SHOPBASE_MERCHANT_REQUEST,
    params,
  };
}
export function getLinkUpdateTokenEtsy() {
  return {
    type: LINK_ETSY_TOKEN_REQUEST,
  };
}
export function updateEtsyToken(id, data) {
  return {
    type: UPDATE_ETSY_TOKEN_REQUEST,
    id,
    data,
  };
}

export function getShopifyLinkForUpdatingToken(id) {
  return {
    type: GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_REQUEST,
    id,
  };
}

export function updateShopifyToken(data) {
  return {
    type: UPDATE_SHOPIFY_TOKEN_REQUEST,
    data,
  };
}

//Amazon
export function getLinkUpdateTokenAmazon() {
  return {
    type: LINK_AMAZON_TOKEN_REQUEST,
  };
}

export function updateAmazonToken(id, data) {
  return {
    type: UPDATE_AMAZON_TOKEN_REQUEST,
    id,
    data,
  };
}
//

export function updateShopifyStore(id, data) {
  return {
    type: UPDATE_SHOPIFY_STORE_REQUEST,
    id,
    data,
  };
}
