import styled from 'styled-components';
import {
  ERROR_300,
  ERROR_500,
  GRAY_400,
  GRAY_600,
  GRAY_700,
} from 'components/colors';

export const GMInput = styled.div`
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_700};
  }
  .error-msg {
    margin-top: 6px;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${ERROR_500};
  }
  .input {
    position: relative;
    .show-password {
      stroke: ${GRAY_400};
      width: 20px;
      height: 20px;
      right: 14px;
      top: 50%;
      margin-top: -10px;
      position: absolute;
      cursor: pointer;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  span.message {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_400};
  }
`;

export const GMInputWrapper = styled.div`
  position: relative;
  &.error {
    .gm-gplace,
    input {
      border: 1px solid ${ERROR_300};
    }
  }
`;
