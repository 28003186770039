import React, { useState, useEffect } from "react";
import { Modal, Spin } from "antd";
import { previewOriginalImage } from "apis/orders";
function RenderPreviewOriginalImage({ orderItem = {} }) {
  const [imageLink, setImageLink] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const showPreview = () => {
    setVisible(true);
  };

  useEffect(() => {
    let isSubscribed = true;
    if (
      orderItem?.tmpContent?.image_front ||
      orderItem?.tmpContent?.image_back
    ) {
      if(!isSubscribed) return;
      setImageLink(
        orderItem?.tmpContent?.image_front || orderItem?.tmpContent?.image_back
      );
    } else if (orderItem?.pcamId) {
      setLoading(true);
      previewOriginalImage({
        productCampaignId: orderItem?.pcamId,
      })
      .then(({ code, data }) => {
        if(!isSubscribed) return;
        setLoading(false);
        if (code === "success") {
          setImageLink(data.imageUrl);
        }
      })
      .catch((error) => {
        if(!isSubscribed) return;
        setLoading(false);
      });
    }
    return () => {isSubscribed = false}
  }, [orderItem]);

  return imageLink ? (
    <Spin spinning={loading}>
      <img className="img-fluid" src={imageLink} style={{ width: "180px" }} alt=""/>
      <p className="mt-0 text-primary cursor-pointer" onClick={showPreview}>
        View larger
      </p>
      <Modal
        title={null}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        okButtonProps={{ style: { display: "none" } }}
        cancelText={"Close"}
      >
        <img className="img-fluid border-img" alt="renderUrl" src={imageLink} />
      </Modal>
    </Spin>
  ) : null;
};
export default React.memo(RenderPreviewOriginalImage);