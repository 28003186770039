import { actionType as TYPE } from './actions';

const initialState = {
  listOrderPayment: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  detailOrderPayment: {
    data: {},
    loading: false,
  },
  listTopUp: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
};

function OrderPaymentReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        listOrderPayment: {
          ...state.listOrderPayment,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        listOrderPayment: {
          ...state.listOrderPayment,
          loading: false,
          data:
            action.data && action.data.listPayment
              ? action.data.listPayment
              : [],
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        listOrderPayment: {
          ...state.listOrderPayment,
          loading: false,
        },
      };

    //Topup list
    case TYPE.LIST_TOPUP.REQUEST:
      return {
        ...state,
        listTopUp: {
          ...state.listTopUp,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_TOPUP.SUCCESS:
      return {
        ...state,
        listTopUp: {
          ...state.listTopUp,
          loading: false,
          data:
            action.data && action.data.topups
              ? action.data.topups
              : [],
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST_TOPUP.ERROR:
      return {
        ...state,
        listTopUp: {
          ...state.listTopUp,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default OrderPaymentReducer;
