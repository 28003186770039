import {
  BASE_WHITE,
  GRAY_600,
  GRAY_NEUTRAL_50,
} from 'components/colors';
import { Wrapper as GWrapper } from 'scenes/general.styled';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .ant-modal-content {
    background: ${BASE_WHITE};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }
  .ant-modal.topup-modal {
    max-width: 100%;
    .ant-modal-body {
      padding: 24px;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
      display: flex;
      justify-content: center;
      padding: 24px;
      gap: 24px;
      left: 3120px;
      top: 20px;
    }
    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 0px;
      border-radius: 16px;

    }
    .ant-modal-title {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
    }
  }
  .back {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    /* identical to box height, or 143% */

    /* Gray/600 */
    cursor:pointer;
    color: #475467;
    svg {
      width: 20px;
      height: 20px;
      stroke: ${GRAY_600};
      margin-right: 12px;
    }
  }
`;

export const Wrapper = styled(GWrapper)`
  div.tabs {
    background-color: ${GRAY_NEUTRAL_50};
    border-radius: 12px;
    margin-top: 24px;
    overflow: hidden;
  }
`;
