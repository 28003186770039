import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Spin } from "antd";
import BackToIcon from "images/chevron-down.svg";
import FormProcessSyncCampaign from "./components/FormProcessSyncCampaign";
import { getList } from "../../../Stores/scenes/List/actions";
import { syncCampaigns } from "./actions";
import { list_platform_to_sync_campaign } from "utils/options";
import { Wrapper } from "./styled";
class SyncOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listCampaignId: [],
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      parseInt(this.props.FormSyncCampaignValues?.platformId) !==
      parseInt(prevProps.FormSyncCampaignValues?.platformId)
    ) {
      const platform = list_platform_to_sync_campaign.find(
        (item) =>
          parseInt(item.value) ===
          parseInt(this.props.FormSyncCampaignValues?.platformId)
      );
      if (platform) {
        this.props.getListStore({ backendType: platform.code, status: 1 });
      }
    }
  }

  componentDidMount() {
    let state = { ...this.props.history.location.state };
    const { listCampaignId = [] } = state;
    if (
      listCampaignId &&
      Array.isArray(listCampaignId) &&
      listCampaignId.length > 0
    ) {
      delete state.listCampaignId;
      this.setState({ listCampaignId: listCampaignId });
      this.props.history.replace({ ...this.props.history.location, state });
    }
    this.props.getListStore({
      backendType: list_platform_to_sync_campaign[0]["code"],
    });
  }

  handleSubmit = (values) => {
    const dataToSync = {
      platform: values.platformId || 0,
      storeId: values.storeId || "",
      campaigns: this.state.listCampaignId,
    };
    this.props.syncCampaigns(dataToSync);
  };

  render() {
    const { stores, pageName, syncCampaignLoading } = this.props;
    const initialValues = {
      storeId: "0",
      platformId: list_platform_to_sync_campaign[0]["value"],
    };

    return (
      <Wrapper pageName={pageName}>
        <Spin spinning={stores.loading || syncCampaignLoading}>
          <FormProcessSyncCampaign
            platformId={this.props.FormSyncCampaignValues?.platformId}
            stores={stores.data}
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    stores: state.store.list.stores,
    syncCampaignLoading: state.campaign.sync.loading,
    FormSyncCampaignValues: state.form.FormSyncCampaign?.values,
  }),
  (dispatch) => ({
    getListStore: (options) => {
      dispatch(getList(options));
    },
    syncCampaigns: (data) => {
      dispatch(syncCampaigns(data));
    },
  })
)(SyncOrder);