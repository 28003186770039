import { isValidUrl } from "utils/helper";
const validate = values => {
    let errors = {};
    if(!values.name_webhooks || values.name_webhooks.replace(/\s+/g, "").length === 0){
        errors.name_webhooks = 'Please enter webhook name';
    }
    if(!values.url_webhooks || values.url_webhooks.replace(/\s+/g, "").length === 0){
        errors.url_webhooks = 'Please enter webhook url';
    }else if(!isValidUrl(values.url_webhooks)){
        errors.url_webhooks = 'Please enter a valid url';
    }
    return errors
};
export default validate;