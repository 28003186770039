import React from 'react';
import { ReactComponent as ArrowLeft } from 'images/icons/arrow-left.svg';
import { Link } from "react-router-dom";

export default ({ emailAddress = '' }) => {
  return (
    <>
      <h3 className="title">
        Check your email
      </h3>
      <p className="message">
        We have sent a verification email to <b>{emailAddress}</b>. Please click on the link in the email to verify your account. The link will be expired in 7 days
      </p>
      <div className="form-group back-cta">
        <Link to="/auth/login">
          <ArrowLeft />
          Back to log in
        </Link>
      </div>
    </>
  );
};