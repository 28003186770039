import React from 'react';
import {
  list_shipping_method,
  list_shipping_method_international,
  list_shipping_method_military_address,
  list_shipping_method_military_address_canvas,
  list_shipping_method_special_product_canvas,
  list_shipping_method_special_state,
  list_shipping_method_special_state_and_product,
} from 'utils/options';
import GMNotify from 'components/Notification';

export const shippingServive = ({
  country,
  state,
  city,
  checkCanvas,
  listCountryAvalibleCanvasIt,
  address01,
  address02,
}) => {
  if (checkCanvas) {
    if (country + '' === '231') {
      if (['gu', 'vi'].includes(state.toLowerCase().trim())) {
        return [
          null,
          [],
          <GMNotify
            type="infor"
            title={<div className="title">Invalid shipping service</div>}
            desc={
              <div className="desc">
                There is no shipping service available for the variant (SKU)
                being shipped to the provided address. Please choose a different
                size or update your shipping address to continue.
              </div>
            }
          />,
        ];
      } else {
        if (
          address01?.toLowerCase().trim().includes('po box') ||
          address02?.toLowerCase().trim().includes('po box')
        ) {
          return ['0', list_shipping_method_special_product_canvas];
        } else {
        
          if (
            ['aa', 'ae', 'ap'].includes(state.toLowerCase().trim()) ||
            ['apo', 'fpo', 'dpo', 'aa', 'ae', 'ap'].includes(
              city.toLowerCase().trim()
            )
          ) {
            return ['2', list_shipping_method_military_address_canvas];
          } else {
            if (['hi', 'ak', 'pr'].includes(state.toLowerCase().trim())) {
              return ['0', list_shipping_method_special_state_and_product];
            } else {
              return ['0', list_shipping_method];
            }
          }
        }
      }
    } else {
      if (listCountryAvalibleCanvasIt) {
        return ['0', list_shipping_method_international];
      } else {
        return [
          null,
          [],
          <GMNotify
            type="infor"
            title={<div className="title">Invalid shipping service</div>}
            desc={
              <div className="desc">
                There is no shipping service available for the variant (SKU)
                being shipped to the provided address. Please choose a different
                size or update your shipping address to continue.
              </div>
            }
          />,
        ];
      }
    }
  } else {
    if (['231'].includes(country + '')) {
      if (country + '' === '231') {
        if (
          ['aa', 'ae', 'ap'].includes(state.toLowerCase().trim()) ||
          ['apo', 'fpo', 'dpo', 'aa', 'ae', 'ap'].includes(
            city.toLowerCase().trim()
          )
        ) {
          return ['2', list_shipping_method_military_address];
        } else {
          if (['hi', 'ak', 'pr'].includes(state.toLowerCase().trim())) {
            return ['0', list_shipping_method_special_state];
          } else {
            return ['0', list_shipping_method];
          }
        }
      } else {
        if (state.toLowerCase().trim() === 'pr') {
          return ['0', list_shipping_method_special_state];
        } else {
          return ['0', list_shipping_method];
        }
      }
    } else {
      return ['0', list_shipping_method_international];
    }
  }
};
export const shippingServiveForCanvas = {};
