import {
    BASE_WHITE,
    GRAY_500,
    GRAY_600,
    GRAY_900,
    PRIMARY_500,
  } from 'components/colors';
  import PageContainer from 'components/PageContainer';
  import styled from 'styled-components';
  
  export const Wrapper = styled(PageContainer)`
    padding: 32px;
    h2.header {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: ${GRAY_900};
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .container-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .tabs {
      .ant-tabs > .ant-tabs-nav,
      .ant-tabs > div > .ant-tabs-nav {
        margin-bottom: 0;
      }
      .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
        display: none;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        background-color: transparent;
        color: ${PRIMARY_500}!important;
        border-bottom: 4px solid ${PRIMARY_500};
      }
      .ant-tabs-tab .ant-tabs-tab-btn {
        &:hover {
          color: ${PRIMARY_500};
        }
      }
      .ant-tabs-tab {
        &,
        &-btn,
        & &-btn,
        &-active,
        &-active &-btn {
          /* padding: 0;
            margin-right: 0;
            margin-right: 20px; */
          align-items: unset;
        }
        &-btn,
        & &-btn {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: ${GRAY_500};
          align-items: unset;
        }
        &-btn,
        &-active &-btn,
        & &-btn {
          height: 36px;
          margin-bottom: -1px;
          padding: 0 5px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .tab-transaction {
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          color: ${GRAY_900};
          margin-top: 20px;
          span {
            display: block;
            float: left;
            line-height: 44px;
          }
          &:after {
            content: '';
            display: table;
            clear: both;
          }
        }
      }
      .ant-table-wrapper {
        margin-top: 32px;
      }
      .tab-transaction {
        padding: 0 8px;
      }
    }
  
    .more-action {
      svg {
        width: 20px;
        height: 20px;
        stroke: ${GRAY_600};
        margin-right: 10px;
      }
    }
    .connect-icon {
      svg {
        width: 20px;
        height: 20px;
        stroke: ${BASE_WHITE};
        margin-right: 10px;
      }
    }
    .cta-upload {
      svg {
        width: 20px;
        height: 20px;
        stroke: ${BASE_WHITE};
        margin-right: 10px;
        font-weight: 600;
      }
    }
  `;
  