import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validate from './validate';
import { renderSwitchAnt, renderSelectAnt, renderInputText } from 'utils/Form';
import { GMTextbox } from 'components/InputFields';
import Select from 'components/InputFields/Select';
import { Wrapper } from '../styled';
import { GMButton } from 'components/Button';
import logo from 'images/logo_new_login.svg';
import { WrapperUpdateButton } from '../../styled';

let index = ({
  handleSubmit,
  onCancel,
  accounts,
  currAction,
  profile,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <WrapperUpdateButton>
      <div className="text-left my-3">
        <img
          className="img-fluid"
          src={logo}
          height="25px"
          width="20%"
          alt=""
        />
      </div>
      <div className="form-group">
        <label className="col-form-label"><b>Store name</b></label>
        <GMTextbox name="showName" showError />
      </div>
      {parseInt(profile.primaryId) === 0 && (
        <div className="form-group">
          <label className="col-form-label"><b>Staff Account</b></label>
          <Wrapper>
            <Field
              name="staffId"
              component={renderSelectAnt}
              placeholder="-- Select staff --"
              mode={'multiple'}
              options={accounts.map((item) => ({
                value: item.id.toString(),
                label: item.name,
              }))}
            />
          </Wrapper>
        </div>
      )}
      <div className="form-group">
        <div className="d-flex align-items-center">
          <GMTextbox name="status" showError component={renderSwitchAnt} />

          <label htmlFor="store_active" className="ml-3 col-form-label">
            Active store
          </label>
        </div>
      </div>
      <div>
        <GMButton className="saveBtn" size="lg">
          {currAction === 'create' ? 'Connect store' : 'Update store'}
        </GMButton>
      </div>
      </WrapperUpdateButton>
    </form>
  );
};

index = reduxForm({
  form: 'formStore_defaultStore',
  enableReinitialize: true,
  validate,
})(index);

export default connect()(index);
