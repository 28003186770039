import {
  BASE_BLACK,
  BASE_WHITE,
  GRAY_100,
  GRAY_200,
  GRAY_50,
  GRAY_600,
  GRAY_700,
  PRIMARY_500,
  SHADOW_LG_0_03,
  SHADOW_LG_0_08,
} from 'components/colors';
import styled from 'styled-components';

export const WrapperRenderMoreAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  border: none;
  .fixed-button {
    box-shadow: 0px 12px 16px -4px ${SHADOW_LG_0_08},
      0px 4px 6px -2px ${SHADOW_LG_0_03};
    min-width: 60%;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    box-shadow: rgba(16, 24, 40, 0.08);
    background-color: ${GRAY_50};
    border-radius: 8px;
    .deleted {
      display: flex;
      flex-direction: row;
      color: ${GRAY_600};
      align-items: center;
    }
    .action {
      display: flex;
      justify-content: space-around;
    }
    button {
      &:hover {
        color: ${PRIMARY_500};
        border-color: ${PRIMARY_500};
      }
    }
  }
`;
export const ContextMenuWrapper = styled.div`
  background: ${BASE_WHITE};
  border: 1px solid ${GRAY_200};
  box-shadow: 0px 12px 16px -4px ${SHADOW_LG_0_08},
    0px 4px 6px -2px ${SHADOW_LG_0_03};
  border-radius: 8px;
  padding: 6px;
  a {
    height: 38px;
    border-radius: 6px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    color: ${GRAY_700};
    &:hover {
      background: ${GRAY_100};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: ${GRAY_700};
      &.sync {
        fill: ${BASE_BLACK};
      }
    }
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    stroke: ${GRAY_700};
    &.sync {
      fill: ${BASE_BLACK};
    }
  }
  .sync-order:hover {
    background-color: ${GRAY_100};
    border-radius: 6px;
    cursor: pointer;
  }
`;
