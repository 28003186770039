import { takeLatest, call, put, all } from 'redux-saga/effects';

import { actionType as TYPE } from './actions';

import { importOrder, exportOrder } from 'apis/orders';
import { replace } from 'connected-react-router';

function* importOrderSaga(action) {
  const { data } = action;
  try {
    const response = yield call(importOrder, data);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.IMPORT_ORDER.SUCCESS, ...response }),
        put(replace('/orders/import')),
      ]);
    } else {
      yield put({ type: TYPE.IMPORT_ORDER.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.IMPORT_ORDER.ERROR, error })]);
  }
}

function* exportOrderSaga(action) {
  const { data } = action;
  try {
    const response = yield call(exportOrder, data);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.EXPORT_ORDER.SUCCESS, ...response }),
        put(replace('/orders/list')),
      ]);
    } else {
      yield put({ type: TYPE.EXPORT_ORDER.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.EXPORT_ORDER.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.IMPORT_ORDER.REQUEST, importOrderSaga);
  yield takeLatest(TYPE.EXPORT_ORDER.REQUEST, exportOrderSaga);
}

export default watcher;
