import { GlowingSVG } from "components/SVG";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, change } from "redux-form";
import { ReactComponent as KeyIcon } from 'images/icons/key-01.svg';
import { PRIMARY_100, PRIMARY_50, PRIMARY_600 } from "components/colors";
import { ReactComponent as AlertCircle } from 'images/icons/alert-circle.svg';
import { GMTextbox } from "components/InputFields";
import { GMButton } from "components/Button";
import { useSelector } from "react-redux";
import { ReactComponent as HelpIcon } from 'images/icons/help-circle.svg';
import { useEffect } from "react";
import { clearMessage } from "../actions";
import { Tooltip } from "antd";
import { isValidPassword } from "utils/helper";
import PasswordConditional from "components/PasswordConditional";

const validate = (values) => {
  const errors = {};
  if (!values.currentPassword) {
    errors.currentPassword = "Please enter your current password.";
  }
  if (!values.newPassword) {
    errors.newPassword = "Please enter your new password.";
  } else if (!isValidPassword(values.newPassword) || values.newPassword.indexOf(values.email) !== -1) {
    errors.newPassword = 'Invalid format';
  }
  if (!values.newPasswordConfirmation) {
    errors.newPasswordConfirmation = "Please re-enter your new password.";
  }
  if (values.newPassword !== values.newPasswordConfirmation) {
    errors.newPasswordConfirmation = "Your password and confirmation password must match.";
  }
  return errors;
};

const FormChangePassword = ({ handleSubmit }) => {
  const serverErrors = useSelector(state => state.user.change_password.serverErrorInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearMessage());
  }, []);
  const errorMessage = serverErrors && serverErrors.length > 0 ? (
    serverErrors.map(error => {
      switch (error?.code) {
        case 'invalid_param':
          return (
            <li>
              <AlertCircle />
              <span className="error">Incorrect password</span>
            </li>
          );
        default:
          return null;
      }
    })
  ) : null;
  return (
    <form onSubmit={handleSubmit}>
      <div className="text-center logo">
        <GlowingSVG
          backgroundColor={PRIMARY_100}
          borderColor={PRIMARY_50}
          svgStrokeColor={PRIMARY_600}
        >
          <KeyIcon />
        </GlowingSVG>
      </div>
      <h3 className="text-center title">Update password</h3>
      {errorMessage ? (
        <ul className="error-list">{errorMessage}</ul>
      ) : null}
      <div className="form-group">
        <GMTextbox
          name="currentPassword"
          placeholder="Current Password"
          label="Current Password"
          type="password"
          showError
        />
      </div>
      <div className="form-group">
        <GMTextbox
          name="newPassword"
          placeholder="New Password"
          label={(
            <>
              New Password
              <Tooltip
                title={(
                  <PasswordConditional />
                )}
                placement="topLeft"
              >
                <HelpIcon className="help-tooltip" />
              </Tooltip>
            </>
          )}
          type="password"
          message="Use 8 or more characters with a mix of letters, numbers & symbols and does not contain email address"
          showError
        />
      </div>
      <div className="form-group">
        <GMTextbox
          name="newPasswordConfirmation"
          placeholder="New Password"
          label="Confirm Password"
          type="password"
          showError
        />
      </div>
      <div className="form-group">
        <GMButton type="submit" className="btn btn-primary cta-changepss">
          Save
        </GMButton>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  form_values: state.form.FormChangePassword,
  initialValues: {
    email: state.auth.info?.profile?.email,
  } 

});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change("FormChangePassword", field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "FormChangePassword",
    validate,
    enableReinitialize: true,
  })(FormChangePassword)
);
