import React from "react";
import classNames from "classnames";
import { compareTime } from "utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import money from "utils/money";
function RenderShippingInfo({ order = {}, _this = {} }){
  return (
    <>
      <h5 className="mb-2 font-weight-bold d-flex justify-content-between">
        <span>Shipping Information</span>
        <span
              className="cursor-pointer text-info font-size-14"
              onClick={() => _this.setState({ showModal: "edit_address" })}
            >
              <strong>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </strong>
            </span>
      </h5>
      {(compareTime(order.updatedAt, 36 * 24 * 3600) && parseInt(order.platformId) === 3 && (parseInt(order.status) === 2 || parseInt(order.status) === 3)) ? (
        <div>Shipping info has been removed after 30 days</div>
      ) : (
        <div>
          <div>{order.cusName || ""}</div>
          <div>{order.cusEmail || ""}</div>
          <div>{order.cusPhone || ""}</div>
          <div>{order.cusAddress || ""}</div>
          <div>{order.cusAddress2 || ""}</div>
          <div>{order.cusCity || ""}</div>
          <div>{order.cusProvince || ""}</div>
          <div>{order.cusPostalCode || ""}</div>
          <div>{order.country?.countryName || ""}</div>
          <div>IOSS number: {order?.orderExtra?.iossNumber || "0"}</div>
          <div>
            IOSS number total(Unit: €):{" "}
            {!isNaN(parseFloat(order?.orderExtra?.iossNumberTotal))
              ? money(parseFloat(order?.orderExtra?.iossNumberTotal), "€")
              : "0"}
          </div>
          <button
            className={classNames("btn rounded-pill btn-sm", {
              "btn-outline-success bg-success-light": order.verifiedAddress,
              "btn-outline-danger": !order.verifiedAddress,
            })}
          >
            ADDRESS VERIFIED
          </button>
        </div>
      )}
    </>
  );
};
export default React.memo(RenderShippingInfo);