import React from "react";
import Chat from "./Components/Chat";
import Issue from "./Components/Issue";
import Email from "./Components/Email";

import HelpCenter from "./Components/HelpCenter";

export default (props) => {
  return (
    <div>
      <div>
        <div className="row align-items-stretch d-flex justify-content-center p-3 m-1">
          <div className="col-sm-12">
            <HelpCenter />
          </div>
          <div className="row px-3">
            <div className="col-md mb-3 mb-md-0">
              <Chat />
            </div>
            <div className="col-md mb-3 mb-md-0">
              <Email />
            </div>
            <div className="col-md mb-3 mb-md-0">
              <Issue />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
