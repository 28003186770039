import { 
  takeLatest, 
  call, 
  put, 
  all 
} from 'redux-saga/effects'
import { 
  actionType as TYPE
} from './actions'
import { getList } from 'apis/tracking'

function* getListSaga(action){
  const { params } = action
  try {
    const response = yield call(getList, {
      // 'with[0]': 'scheduleCampaigns',
      ...params
    })
    if(response.code === 'success'){
      yield put({ type: TYPE.LIST.SUCCESS, ...response })    
    }else{
        yield put({ type: TYPE.LIST.ERROR, response })
    }      
  } catch(error){
    yield all([
      yield put({ type: TYPE.LIST.ERROR, error })
    ])
  }
}

function* watcher(){
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga)
}

export default watcher