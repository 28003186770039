import { actionType as TYPE } from "./actions";
import { notification } from "antd";
const initialState = {
  invoices: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  exportOrders: {
    loading: false,
    params: {},
  },
  exportInvoice: {
    loading: false,
    params: {},
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: false,
          data: action.data.invoices,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: false,
        },
      };
    case TYPE.EXPORT_ORDERS.REQUEST:
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.EXPORT_ORDERS.SUCCESS:
      notification.success({
        message: "Export orders successfully.",
        duration: 5,
      });
      if (action?.data?.linkToDownloadFile) {
        window.open(action.data.linkToDownloadFile);
      }
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          loading: false,
        },
      };
    case TYPE.EXPORT_ORDERS.ERROR:
      notification.warn({
        message: action?.error?.[0]?.["msg"] || "Export orders failed.",
        duration: 5,
      });
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          loading: false,
        },
      };  
      case TYPE.EXPORT_INVOICE.REQUEST:
        return {
          ...state,
          exportInvoice: {
            ...state.exportInvoice,
            params: action.params,
            loading: true,
          },
        };
      case TYPE.EXPORT_INVOICE.SUCCESS:
        notification.success({
          message: "Export invoice successfully.",
          duration: 5,
        });
        if (action?.data?.url) {
          window.open(action.data.url);
        }
        return {
          ...state,
          exportInvoice: {
            ...state.exportInvoice,
            loading: false,
          },
        };
      case TYPE.EXPORT_INVOICE.ERROR:
        notification.warn({
          message: action?.error?.[0]?.["msg"] || "Export invoice failed.",
          duration: 5,
        });
        return {
          ...state,
          exportInvoice: {
            ...state.exportInvoice,
            loading: false,
          },
        };    
    default:
      return state;
  }
}

export default ListReducer;
