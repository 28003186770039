import { takeLatest, call, put, all } from "redux-saga/effects";

import { actionType as TYPE } from "./actions";

import {exportFeedCustomAmazon}  from "apis/campaign"

function* exportFeedCustomAmazonSaga(action){
  const { data } = action
  try {
    const response = yield call(exportFeedCustomAmazon, data)
    if(response.code === 'success'){
      yield put({ type: TYPE.EXPORT_FEED_CUSTOM_AMAZON.SUCCESS, ...response })
    }else{
        yield put({ type: TYPE.EXPORT_FEED_CUSTOM_AMAZON.FAIL, response })
    }
  } catch(error){
    yield all([
      yield put({ type: TYPE.EXPORT_FEED_CUSTOM_AMAZON.FAIL, error })
    ])
  }
}

function* watcher() {
    yield takeLatest(TYPE.EXPORT_FEED_CUSTOM_AMAZON.REQUEST, exportFeedCustomAmazonSaga);
}

export default watcher;
