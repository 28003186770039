import React, { Component, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination, Table, Tooltip } from "antd";
import { getList } from "./actions";
import queryString from "query-string";
import getMomentInstance from "utils/moment";
import { getOptionLabel, options_active } from "utils/options";
import edit from "images/edit.svg";
import { ReactComponent as EditIcon } from "images/icons/edit-01.svg";
import { checkFunctionalPermission } from "utils/helper";
import { Wrapper } from "./styled";
import { GMButton } from "components/Button";
import { ReactComponent as PlusIcon } from "images/icons/plus.svg";
import { push } from "connected-react-router";
import columns from "./column";
import { memo } from "react";
import { useHistory } from "react-router-dom";

const DataFeedConfiguration = ({ pageName, is_mobile }) => {
  const data_feeds = useSelector((state) => state.data_feed.list.data_feeds);
  const profile = useSelector((state) => state.auth.info.profile);
  const query_params = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const filterValue = {
    sortBy: "id",
    sortType: "desc",
    platformId: [3, 4],
    ...query_params
  };

  const fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options
    };

    history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    // this.props.getList(params);
    dispatch(getList(params));
  };

  useEffect(() => {
    fetchData(filterValue);
  }, []);

  return (
    <Wrapper
      pageName={pageName}
      ctas={
        <>
          {checkFunctionalPermission(profile, "createFeedPermission") && (
            <>
              <GMButton
                type="button"
                className="addnewbtn"
                onClick={() => dispatch(push("/products/data-feed/amazon"))}
              >
                <PlusIcon />
                Add Amazon Form
              </GMButton>
              <GMButton
                type="button"
                className="addnewbtn"
                onClick={() => dispatch(push("/products/data-feed/google"))}
              >
                <PlusIcon />
                Add Google Form
              </GMButton>
            </>
          )}
        </>
      }
    >
      <div className="m-3">
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data_feeds.data}
          loading={data_feeds.loading}
          scroll={{ x: 1000 }}
          pagination={
            !is_mobile
              ? {
                  showTotal: (total) => `Total ${total} items`,
                  total: data_feeds.total,
                  pageSize: parseInt(
                    data_feeds.params.perPage > data_feeds.params.total
                      ? data_feeds.params.total
                      : data_feeds.params.perPage
                  ),
                  current: data_feeds.params.page,
                  showSizeChanger: true,
                  onChange: (page, perPage) =>
                    this.fetchData({ ...data_feeds.params, page, perPage })
                }
              : false
          }
        />
        {is_mobile && (
          <div className="d-flex justify-content-end align-items-center py-3 px-3">
            <div>
              <Pagination
                size="small"
                showSizeChanger
                className="pagination"
                total={data_feeds.total}
                defaultCurrent={data_feeds?.params?.page || 1}
                pageSize={data_feeds?.params?.perPage || 0}
                onChange={(page, perPage) =>
                  this.fetchData({
                    ...data_feeds.params,
                    page:
                      page === Number.NEGATIVE_INFINITY
                        ? 1
                        : page === 0
                        ? 1
                        : page,
                    perPage
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default memo(DataFeedConfiguration);
