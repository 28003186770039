import React, { useState, useEffect } from "react";
import { Input } from "antd";
import classNames from "classnames";
import { verifyAddress } from "apis/user_label";
import { SUCCESS_500 } from "components/colors";
const { Search } = Input;
const RenderAddressInput = ({
  input,
  placeholder = "",
  size = "large",
  meta: { error: err, invalid, touched, submitFailed },
  defaultValue = "",
  maxLength = 255,
  classNameContainer = "",
  countryCode = "",
  zipcode = "",
  address2 = "",
  state = "",
  city = "",
  phoneNumber = "",
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleOnChange = (currValue) => {
    input.onChange(currValue);
  };
  useEffect(() => {
    setError("");
  }, [])
  useEffect(() => {
    if (input.value && countryCode && city && zipcode) {
      setLoading(true);
      verifyAddress({
        address1: input.value,
        address2: address2,
        city: city,
        state: state,
        zip: zipcode,
        country: countryCode,
        phone: phoneNumber,
      })
        .then((response) => {
          setLoading(false);
          if (response?.data?.isAddress === true) {
            setError("");
          } else {
            setError(response?.data?.errors?.[0]?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [input.value, zipcode, countryCode, state, address2, city, phoneNumber]);
  return (
    <div className={classNames("position-relative w-100", classNameContainer)}>
      <Search
        allowClear
        value={input.value || defaultValue}
        size={size}
        onChange={(e) => handleOnChange(e.target.value)}
        placeholder={placeholder}
        maxLength={maxLength}
        loading={loading}
      />
      {(input?.error && submitFailed) && (
        <span className="invalid-feedback">{input?.error || ""}</span>
      )}
      {(err && submitFailed) && (
        <span className="invalid-feedback">{err || ""}</span>
      )}
      {(error && !loading) && <span className="invalid-feedback">{error}</span>}
      {loading && <span className="invalid-feedback">Please wait a moment ...</span>}
      {(!error && !loading && input?.value && countryCode && city && zipcode) && (
        <span className="valid-feedback" style={{color: SUCCESS_500}}>{"Address is valid."}</span>
      )}
    </div>
  );
};

export default React.memo(RenderAddressInput);
