import { actionType as TYPE } from "./actions";
import { notification } from "antd";

const initialState = {
  list: {
    data: [],
    loading: false,
    params: {},
    total: 0
  },
  delete: {
    loading: false,
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        list: {
          ...initialState.list,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.data.giftMessages,
          total: action.data.pagination ? action.data.pagination.total : 0,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
        },
      };
    case TYPE.DELETE.REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
        },
      };
    case TYPE.DELETE.SUCCESS:
      notification.success({
        message: "Delete gift message successfully",
        duration: 5
      });
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
      };
    case TYPE.DELETE.ERROR:
      notification.error({
        message: "Delete gift message failed.",
        duration: 5
      });
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
