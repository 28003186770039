import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { GuidelinesWrapper } from "scenes/Dashboard/styled";


import { ReactComponent as AppraelIcon } from "images/icons/t-shirt.svg";
import { ReactComponent as MugIcon } from "images/icons/mug.svg";
import { ReactComponent as PosterIcon } from "images/icons/poster.svg";
import { ReactComponent as TumblerIcon } from "images/icons/tumbler-1.svg";
import { ReactComponent as OrnamentIcon } from "images/icons/ornament.svg";
import { ReactComponent as DoormatIcon } from "images/icons/doormat.svg";
import { GlowingSVG } from "components/SVG";
import { PRIMARY_100, PRIMARY_50, PRIMARY_600 } from "components/colors";


function Index(props) {
  const [guidelines, setGuidelines] = useState([]);
  useEffect(() => {
    setGuidelines([
      {
        logo: <AppraelIcon />,
        // icon: (
        //   <GlowingSVG
        //     className="icon"
        //     backgroundColor={PRIMARY_100}
        //     borderColor={PRIMARY_50}
        //     svgStrokeColor={PRIMARY_600}
        //   >
        //     <CreateProductIcon />
        //   </GlowingSVG>
        // ),
        name: "Apparel",
        link: "https://help.gearment.com/en-us/article/apparels-artwork-dimensions-1wm0aog/",
      },
      {
        logo: <MugIcon />,
        name: "Mug",
        link:
          "https://help.gearment.com/en-us/article/mug-products-vsa3nr/",
      },
      {
        logo: <PosterIcon />,
        name: "Poster",
        link: "https://help.gearment.com/en-us/article/poster-products-1e6tdp1/",
      },
      {
        logo: <TumblerIcon />,
        name: "Tumbler",
        link:
          "https://help.gearment.com/en-us/article/tumbler-products-1d2755r/",
      },
      {
        logo: <OrnamentIcon />,
        name: "Ornament",
        link: "https://help.gearment.com/en-us/article/ornament-products-jv1a90/",
      },
      {
        logo: <DoormatIcon />,
        name: "Doormat",
        link: "https://help.gearment.com/en-us/article/doormat-products-yoaiun/",
      },
    ]);
    return () => { };
  }, []);
  return (
    <GuidelinesWrapper>
      <h3 className="title">
        Guidelines
      </h3>
      {/* <a
          target="_blank"
          rel="noopener noreferrer"
          className="Skip-the-guide"
          href="https://help.gearment.com/en/category/artwork-dimensions-uf0qa/"
        >
          See more
        </a> */}
      <div className="inner-wrapper">
        {guidelines.map((guideline, index) => (
          <div
            className="item"
            key={index}
            onClick={() => {
              window.open(guideline.link);
            }}
          >
            <GlowingSVG
              className="icon md"
              backgroundColor={PRIMARY_100}
              borderColor={PRIMARY_50}
              svgStrokeColor={PRIMARY_600}
            >{guideline?.logo}</GlowingSVG>
            <p className="mb-0">{guideline.name}</p>
          </div>
        ))}
      </div>
    </GuidelinesWrapper>
  );
};

export default React.memo(Index);