import styled from "styled-components";
import { Wrapper } from "scenes/general.styled";

export const OuterWrapper  = styled(Wrapper)`
  .filter {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
  .ctarow {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;
