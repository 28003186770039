export const actionType = {
  LIST_ORDER_TO_MATCH_PRODUCT: {
    REQUEST: "LIST_ORDER_TO_MATCH_PRODUCT.REQUEST",
    SUCCESS: "LIST_ORDER_TO_MATCH_PRODUCT.SUCCESS",
    ERROR: "LIST_ORDER_TO_MATCH_PRODUCT.ERROR",
  },
  SAVE_MATCH_PRODUCT: {
    REQUEST: "SAVE_MATCH_PRODUCT.REQUEST",
    SUCCESS: "SAVE_MATCH_PRODUCT.SUCCESS",
    ERROR: "SAVE_MATCH_PRODUCT.ERROR",
    RESET_DATA: "SAVE_MATCH_PRODUCT.RESET_DATA",
    RESET_SUBMIT_STATUS: "SAVE_MATCH_PRODUCT.RESET_SUBMIT_STATUS"
  },
  SET_SELECTED_ORDER_ITEMS: {
    REQUEST: "SET_SELECTED_ORDER_ITEMS.REQUEST",
  },
  RESET_DELETE_DESIGN_DATA: {
    REQUEST: "RESET_DELETE_DESIGN_DATA.REQUEST",
  },
  RESET_REMOVE_ORDER_ITEM_DATA: {
    REQUEST: "RESET_REMOVE_ORDER_ITEM_DATA.REQUEST",
  },
  RESET_MATCH_PRODUCT_DATA: {
    REQUEST: "RESET_MATCH_PRODUCT_DATA.REQUEST"
  },
  DELETE_DESIGN: {
    REQUEST: "DELETE_DESIGN.REQUEST",
    SUCCESS: "DELETE_DESIGN.SUCCESS",
    ERROR: "DELETE_DESIGN.ERROR",
  },
  REMOVE_ITEM_MATCH_PRODUCT: {
    REQUEST: "REMOVE_ITEM_MATCH_PRODUCT.REQUEST",
    SUCCESS: "REMOVE_ITEM_MATCH_PRODUCT.SUCCESS",
    ERROR: "REMOVE_ITEM_MATCH_PRODUCT.ERROR",
  },
};

export function listOrderToMatchProduct(data = []) {
  return {
    type: actionType.LIST_ORDER_TO_MATCH_PRODUCT.REQUEST,
    data,
  };
}

export function saveMatchProduct(data) {
  return {
    type: actionType.SAVE_MATCH_PRODUCT.REQUEST,
    data,
  };
}

export function resetDataBeforeMatchingProduct(data) {
  return {
    type: actionType.LIST_ORDER_TO_MATCH_PRODUCT.SUCCESS,
    data
  }
}

export function resetSubmitStatus() {
  return {
    type: actionType.SAVE_MATCH_PRODUCT.RESET_SUBMIT_STATUS,
  }
}

export function setSelectedOrderItems(selectedOrderItems) {
  return {
    type: actionType.SET_SELECTED_ORDER_ITEMS.REQUEST,
    selectedOrderItems,
  };
}

export function deleteDesign(data) {
  return {
    type: actionType.DELETE_DESIGN.REQUEST,
    data,
  };
}

export function resetDeleteDesignData() {
  return {
    type: actionType.RESET_DELETE_DESIGN_DATA.REQUEST
  };
}

export function removedOrderItemMatchProduct(data) {
  return {
    type: actionType.REMOVE_ITEM_MATCH_PRODUCT.REQUEST,
    data,
  };
}

export function resetRemoveOrderItemData() {
  return {
    type: actionType.RESET_REMOVE_ORDER_ITEM_DATA.REQUEST
  };
}

export function resetMatchProductData() {
  return {
    type: actionType.RESET_MATCH_PRODUCT_DATA.REQUEST
  };
}

