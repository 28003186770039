import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import FormIndemnification from './Create/index.js';
import { Wrapper } from './styled';
import { S3_CDN } from 'web.config.js';
import { createFileName, createFolderName } from 'utils/helper.js';
import getMomentInstance from 'utils/moment.js';
import { getPresignedUrlS3 } from 'apis/image_design.js';
import axios from 'axios';
import { getPreviewIndemnicationAction } from './Preview/actions.js';
import {
  createIndemnicationAction,
  getTemplateAction,
  getViewFormIndemnicationAction,
} from './Create/actions';
import { dataURLtoFile } from 'utils/Form/components/Signature/index.js';

class Indemnification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKeyTag: '1',
      S3Loading: false,
      template: null,
    };
  }

  componentDidMount() {
    this.props.getViewFormIndemnicationAction();
    this.props.getTemplateAction();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.getPreviewBeforeCreate !== prevProps.getPreviewBeforeCreate
    ) {
      this.props.history.push('/billing/indemnification/preview');
    }
  }

  handleSubmit = async (values) => {
    this.setState({ S3Loading: true });
    if (values?.signatureUrl.includes('data:image')) {
      let signatureUrl = dataURLtoFile(values?.signatureUrl);
      const folderName = createFolderName(this.props.profile.id);
      const extension = signatureUrl.name.split('.').pop();
      const fileName = createFileName(
        'png',
        'signature',
        this.props.profile.id
      );
      const fileType = `image/${extension}`;
      const keyToCreatePresignedUrl = `files/indemnification/${folderName}/${getMomentInstance(
        null,
        0,
        false,
        true
      )}/${fileName}`;

      const response = await getPresignedUrlS3(keyToCreatePresignedUrl);
      if (response && response.code === 'success') {
        axios
          .put(response.data.url, signatureUrl, {
            headers: {
              'Content-Type': fileType,
              
            },
          })
          .then((response) => {
            if (parseInt(response.status) === 200) {
              let signedUrl = `${S3_CDN}/${keyToCreatePresignedUrl}`;
              let formData = {
                name: values.name,
                address: values.address,
                printNameAndTitle: values.printNameAndTitle,
                signatureUrl: signedUrl,
              };
              this.props.getPreviewIndemnicationAction(formData);
              localStorage.setItem(
                'formData',
                JSON.stringify({
                  ...formData,
                })
              );
              this.setState({ S3Loading: true });
            } else {
              console.log('error');
            }
          });
      }
    } else {
      let formData = {
        name: values.name,
        address: values.address,
        printNameAndTitle: values.printNameAndTitle,
        signatureUrl: values.signatureUrl,
      };
      this.props.getPreviewIndemnicationAction(formData);
      localStorage.setItem(
        'formData',
        JSON.stringify({
          ...formData,
        })
      );
    }
  };

  render() {
    const {
      getFormIndemnicationActionLoading,
      pageName,
      profile,
      getPreviewBeforeCreateLoading,
      getViewIndemnification,
      getViewIndemnificationLoading,
      getTemplate,
    } = this.props;
    const initialValues = {
      createdAt:
        getMomentInstance(getViewIndemnification?.data?.createdAt).format(
          'MM/DD/YYYY'
        ) + ' (UTC/GMT-8)',
      name: getViewIndemnification?.data?.name,
      address: getViewIndemnification?.data?.address,
      printNameAndTitle: getViewIndemnification?.data?.printNameAndTitle,
      signatureUrl: getViewIndemnification?.data?.signatureImage,
    };
    return (
      <Wrapper pageName={pageName}>
        <div className="pt-2 pb-4">
          <Spin
            spinning={
              getFormIndemnicationActionLoading ||
              getPreviewBeforeCreateLoading ||
              getViewIndemnificationLoading ||
              this.state.S3Loading
            }
          >
            <FormIndemnification
              initialValues={initialValues}
              profile={profile}
              onSubmit={this.handleSubmit}
              getTemplate={getTemplate.content}
              getViewIndemnification={getViewIndemnification}
              enableResumbit={
                getViewIndemnification?.data?.createdAt ? true : false
              }
            />
          </Spin>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    // getFormIndemnicationForm:
    //   state.indemnification.create.createIndemnication.data,
    // getFormIndemnicationActionLoading:
    //   state.indemnification.create.createIndemnication.loading,
    getTemplate: state.indemnification.create.template.data,

    getPreviewBeforeCreate: state.indemnification.preview.preview.data,
    getPreviewBeforeCreateLoading:
      state.indemnification.preview.preview.loading,

    getViewIndemnification: state.indemnification.create.viewIndemnication,
    getViewIndemnificationLoading:
      state.indemnification.create.viewIndemnication.loading,

    profile: state.auth.info.profile,
    FormIndemnificationValue: state.form.FormIndemnification,
    preview: state.indemnification.preview.preview.data,
  }),
  (dispatch) => ({
    getPreviewIndemnicationAction: (data) => {
      dispatch(getPreviewIndemnicationAction(data));
    },
    createIndemnicationAction: (data) => {
      dispatch(createIndemnicationAction(data));
    },
    getViewFormIndemnicationAction: () => {
      dispatch(getViewFormIndemnicationAction());
    },
    getTemplateAction: () => {
      dispatch(getTemplateAction());
    },
  })
)(Indemnification);
