import {
  BASE_WHITE,
  GRAY_200,
  GRAY_400,
  GRAY_500,
  GRAY_900,
  PRIMARY_500,
  SHADOW_SM_0_06,
  SHADOW_SM_0_1,
} from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px;
  h2.header {
    font-style: normal;
    font-size: 30px;
    line-height: 38px;
    color: ${GRAY_900};
    margin: 0;
    margin-top: 20px;
    font-weight: 600;
  }
  div.balance,
  .main-content {
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_200};
    box-shadow: 0px 1px 3px ${SHADOW_SM_0_1}, 0px 1px 2px ${SHADOW_SM_0_06};
    border-radius: 12px;
    margin-top: 20px;
  }
  .main-content {
    padding: 20px;
  }
  .tabs {
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      background-color: transparent;
      color: ${PRIMARY_500}!important;
      border-bottom: 4px solid ${PRIMARY_500};
    }
    .ant-tabs-tab .ant-tabs-tab-btn {
      &:hover {
        color: ${PRIMARY_500};
      }
    }
    .ant-tabs-tab {
      &,
      &-btn,
      & &-btn,
      &-active,
      &-active &-btn {
        padding: 0;
        margin-right: 0;
        margin-right: 20px;
        align-items: unset;
      }
      &-btn,
      & &-btn {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: ${GRAY_500};
        align-items: unset;
      }
      &-btn,
      &-active &-btn,
      & &-btn {
        height: 36px;
        margin-bottom: -1px;
        padding: 0 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .tab-transaction {
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: ${GRAY_900};
        margin-top: 20px;
        span {
          display: block;
          float: left;
          line-height: 44px;
          font-size: 14px;
          color: ${GRAY_900};

        }
        &:after {
          content: '';
          display: table;
          clear: both;
        }
      }
    }
    .ant-table-wrapper {
      margin-top: 32px;
    }
    .tab-transaction {
      padding: 0 8px;
    }
  }
  .ant-tag {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    border-radius: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    padding: 0 8px !important;
    box-sizing: content-box;
    margin: 0 !important;
    b {
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  .filter {
    color: ${GRAY_900};

    .input span.icon-after svg.search-tooltip {
      stroke: ${GRAY_400};
      margin-left: 6px;
      display: inline-block;
      cursor: default;
      width: 18px;
      height: 18px;
    }
    .select {
      &-status,
      &-method,
      &-type {
        font-size: 0;
        .ant-select-selector {
          min-width: 180px;
        }
        &,
        & .ant-select .ant-select-selector {
          height: 44px;
          line-height: 44px;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${GRAY_900};
          border-radius: 8px;
          span {
            display: inline-block;
            line-height: unset;
            color: ${GRAY_900};
          }
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }

      .date-range {
        span {
          font-size: 14px;
        }
      }
    }
  }
`;
