import { combineReducers } from 'redux'
import list from './scenes/List/reducer'
import detail from './scenes/Detail/reducer'
// import create from './scenes/Create/reducer'
// import del from './scenes/Delete/reducer'

const reducer = combineReducers({
    list,
    detail,
    // create,
    // del,
})

export default reducer