import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Table, Badge, Alert } from "antd";
import queryString from "query-string";
import getMomentInstance from "utils/moment";
import { getList } from "./actions";
import { getProfit } from "scenes/Orders/scenes/List/actions";
import GeneralInfo from "./components/GeneralInfo";
import {
  getOptionLabel,
  options_transaction_type,
  options_payment_method,
  options_payment_status,
} from "utils/options";
import money from "utils/money";

class List extends Component {
  constructor(props) {
    super(props);
    const { createdAtFrom, createdAtTo, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      showDetail: false,
      selected: null,
      initial_filter_values: {
        sortBy: "id",
        sortType: "desc",
        ...query_params,
        date_range: [
          createdAtFrom
            ? getMomentInstance(createdAtFrom)
            : null,
          createdAtTo ? getMomentInstance(createdAtTo) : null,
        ],
      },
    };
  }

  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
    this.props.getStatisticProfit();
  }

  fetchData = ({ date_range, page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (date_range) {
      params.createdAtFrom =
        date_range[0] && date_range[0].format("YYYY-MM-DD");
      params.createdAtTo = date_range[1] && date_range[1].format("YYYY-MM-DD");
    }

    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getList({
      ...params,
    });
  };
  handleShowDetail = (selected) => {
    this.setState({
      showDetail: true,
      selected,
    });
  };

  render() {
    const { profits, user, pageName, statisticProfit } = this.props;
    const { showDetail, selected } = this.state;
    const columns = [
      {
        title: <b>CODE</b>,
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        title: <b>AMOUNT</b>,
        dataIndex: "amount",
        key: "amount",
        align: "center",

        render: (value) => money(value),
      },
      {
        title: <b>STATUS</b>,
        key: "status",
        dataIndex: "status",
        align: "center",

        render: (value) => getOptionLabel(options_payment_status, value),
      },
      {
        title: <b>PAYMENT MEDTHOD</b>,
        dataIndex: "paymentMethod",
        key: "paymentMethod",
        align: "center",

        render: (value) =>
          getOptionLabel(options_payment_method, value, false, value),
      },
      {
        title: <b>DATE</b>,
        dataIndex: "date",
        key: "date",
        align: "center",

        render: (text, record) => getMomentInstance(text, 0, false, true),
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
            <h1 className="h3 mb-0">{pageName}</h1>
          </div>
          <div>
            <Alert
              className="mb-3"
              message={
                <div>
                  <b>Payment Settings!</b>
                </div>
              }
              description={
                <div className="text-warning">
                  <li className="align-items-center justify-content-center">
                    No payout preference has been selected. Please add your
                    payment info at Settings.
                    <a
                      arget="blank"
                      href=""
                      className="align-items-center justify-content-center ml-1"
                    >
                      Add Payment
                    </a>
                  </li>
                </div>
              }
              type="warning"
              showIcon
            />
          </div>
          <GeneralInfo statisticProfit={statisticProfit} user={user} />
          <div className="container-fluid px-0 py-0">
            <Table
              rowKey="id"
              columns={columns}
              dataSource={profits.data}
              loading={profits.loading}
              scroll={{ x: 1000 }}
              pagination={{
                showTotal: (total) => `Total ${total} items`,
                total: profits.total,
                pageSize: parseInt(
                  profits.params.perPage > profits.total
                    ? profits.total
                    : profits.params.perPage
                ),
                current: profits.params.page,
                showSizeChanger: true,
                // onShowSizeChange: (current, perPage)=>this.fetchData({...profits.params, perPage}),
                onChange: (page, perPage) =>
                  this.fetchData({ ...profits.params, page, perPage }),
              }}
            />
          </div>
          <Modal
            width={600}
            footer={null}
            title={
              <div className="d-flex align-items-center">
                <b className="h4 mb-0">Transaction detail #{selected?.name}</b>{" "}
                <Badge
                  count="PAID"
                  className="ml-2 site-badge site-badge-success"
                />
              </div>
            }
            visible={showDetail}
            onCancel={() => this.setState({ showDetail: false })}
            bodyStyle={{ padding: 0 }}
          >
            <div className="pb-1">
              <table className="table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td style={{ width: 200 }} className="px-4">
                      Transaction Code:
                    </td>
                    <td className="px-4">{selected?.name}</td>
                  </tr>
                  <tr>
                    <td style={{ width: 200 }} className="px-4">
                      Status:
                    </td>
                    <td className="px-4">
                      {getOptionLabel(
                        options_payment_status,
                        selected?.status,
                        false
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: 200 }} className="px-4">
                      Type:
                    </td>
                    <td className="px-4">
                      {getOptionLabel(
                        options_transaction_type,
                        selected?.type,
                        false
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: 200 }} className="px-4">
                      Payment method:
                    </td>
                    <td className="px-4">
                      {getOptionLabel(
                        options_payment_method,
                        selected?.paymentMethod,
                        false,
                        selected?.paymentMethod
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: 200 }} className="px-4">
                      Amount
                    </td>
                    <td className="px-4">{money(selected?.amount)}</td>
                  </tr>
                  <tr>
                    <td style={{ width: 200 }} className="px-4">
                      Date
                    </td>
                    <td className="px-4">
                      {getMomentInstance(selected?.data, 0, false, true)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    profits: state.profit.list.profits,
    statisticProfit: state.order.list.profit,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
    getStatisticProfit: () => {
      dispatch(getProfit());
    },
  })
)(List);
