import React, { useState } from 'react';
import { Modal, Spin, notification } from 'antd';
import { previewRender } from 'apis/orders';
import { SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { appendGflareDomain, capitalizeFirstLetter } from 'utils/helper';
import { isInteger } from 'lodash';
import { GlobalStyle, Wrapper } from './styled';
const RenderPreviewOrderDetail = ({
  orderItemId = 0,
  orderId,
  exProductId = 0,
  colorId = 0,
  sidePrint = '',
  sizeId = 0,
  typePrint = '',
  productName,
  colorHex = '',
  isCreatingOrder = false,
  design = '',
  canRender = true,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renderUrl, setRenderUrl] = useState('');
  const DOMAIN_GEAFLARE = 'https://cdn.geaflare.com';

  const showPreview = () => {
    setRenderUrl('');
    if (!isInteger(parseInt(exProductId)) || parseInt(exProductId) === 0) {
      notification.warn({
        message: 'Please select a product.',
        duration: 5,
      });
      return;
    }
    if (!isInteger(parseInt(colorId)) || parseInt(colorId) === 0) {
      notification.warn({
        message: 'Please select a color.',
        duration: 5,
      });
      return;
    }
    if (isCreatingOrder && !design) {
      notification.warn({
        message: 'Please upload design.',
        duration: 5,
      });
      return;
    }
    setVisible(true);
    setLoading(true);
    previewRender({
      orderItemId,
      exProductId,
      colorId,
      orderId,
      sizeId,
      sidePrint,
      typePrint,
      isCreatingOrder,
      design,
    })
      .then(({ code, data }) => {
        setLoading(false);
        if (code === 'success') {
          setRenderUrl(appendGflareDomain(data.url || data.url2));
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Wrapper>
      {canRender ? (
        <p
          className={classNames('mt-0 cursor-pointer', {
            'd-flex align-items-center justify-content-center text-center': isCreatingOrder,
            'text-primary': !isCreatingOrder,
          })}
          onClick={showPreview}
        >
          <div className="mt-2" style={{ color: 'black' }}>
            <span className="mr-1">{capitalizeFirstLetter(sidePrint)}</span>
            <SearchOutlined />
          </div>
        </p>
      ) : null}
      <Modal
        centered
        title={<b>{productName + ` (${sidePrint})`}</b>}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
        bodyStyle={{ minHeight: '125px' }}
      >
        <Spin
          spinning={loading}
          tip={<p className="text-primary">Please wait a moment ...</p>}
        >
          {renderUrl && (
            <img
              style={{ backgroundColor: colorHex }}
              className="img-fluid border-img"
              alt="render"
              src={
                renderUrl.includes('https://cdn.geaflare.com')
                  ? renderUrl
                  : DOMAIN_GEAFLARE + renderUrl
              }
            />
          )}
        </Spin>
      </Modal>
      <GlobalStyle />
    </Wrapper>
  );
};

export default React.memo(RenderPreviewOrderDetail);
