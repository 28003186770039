import React, { Component } from 'react'
import { Select, notification, Divider, Upload, Button, message } from 'antd';
import classNames from 'classnames'
import { UploadOutlined} from '@ant-design/icons';
import axios from 'utils/request'
import axios_original from 'axios'
import {API_TOKEN_KEY, WS_API} from '../../../web.config';
import { getCookie } from 'utils/helper';
const dataURItoBlob=(dataurl, extension)=> {
  var arr = dataurl.split(','),
  bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:'font/'+extension});
}

export default class Index extends Component {
  state = {
    options: [],
    loading: false
  }
  
  componentDidMount() {
      this.getList()
  }
  
  onSelect = data => {
    const { input, mode = 'default' } = this.props
    input.onChange(data === undefined ? (mode === 'default' ? null : []) : data)
  };

  getList =()=>{
    const { keySelect='id', params={} } = this.props
    this.setState({
      loading: true
    })
    axios({
      method: 'GET',
      params: {
        perPage: 9999,
        ...params
      },
      url: `/font-designs`
    }).then(({ code, message, data}) => {
      this.setState({
        loading: false
      })
      if(code === 'success'){
        this.setState({
          options: data.fontDesigns.map(row=>({...row, value: row[keySelect], label: row.name})),
        })
      }else{
        notification.warning({
          message: data.message || 'SEARCH ERROR'
        })
      }
    })
    .catch(error => { 
      this.setState({
        loading: false
      })
      notification.warning({
        message: error.message || 'SEARCH ERROR'
      })
     })
  }
  render() {
    const {
      allowClear,
      input,
      className,
      placeholder,
      size,
      meta: { error, invalid },
      mode = 'default',
      disabled
    } = this.props
    const { options, loading } = this.state
    const value = mode === 'default' ? input.value.toString() : input.value

    const upload_props = {
      name: 'file',
      accept: '.otf, .ttf, .pbf, .pfm',
      action: `${WS_API}/font-designs`,
      headers: {
          Authorization: `Bearer ${getCookie(API_TOKEN_KEY) /* auth cookie */}`
      },
      customRequest:({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onSuccess,
      })=> {
        const formData = new FormData();
        if (data) {
          Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
          });
        }
        const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const temp = file.name.split('.')
            const blob = dataURItoBlob(reader.result, temp[temp.length - 1])
            formData.append(filename, blob, file.name);
            axios_original
              .post(action, formData, {
                headers,
              })
              .then(({ data: response }) => {
                onSuccess(response, file);
              })
              .catch(onError);
        
            return {
              abort() {},
            };
          };
      },
      onStart: (file)=> {
        this.setState({loading: true})
      },
      onSuccess: (ret, file)=> {
        this.getList()
        message.success(`Font uploaded successfully`);
      },
      onError: (err)=> {
        this.setState({loading: false})
        message.error(`File upload failed.`);
      },
    };

    return (
      <div className="position-relative">
        <Select
          loading={loading}
          showSearch
          allowClear={allowClear}
          value={value || (mode === 'default' ? undefined : [])}
          className={classNames(className)}
          style={{ width: '100%' }}
          size={size || 'large'}
          placeholder={placeholder}
          onChange={this.onSelect}
          disabled={disabled}
          optionFilterProp="children"
          dropdownRender={menu => (
            <div>
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Upload {...upload_props}>
                <Button type="primary">
                  <UploadOutlined /> Upload New Font
                </Button>
              </Upload>
              </div>

              <Divider style={{ margin: '4px 0' }} />
              {menu}
            </div>
          )}
        >
            {options.map(d => <Select.Option key={d.value} >{d.label}</Select.Option>)}
        </Select>
        {invalid&&<hr className="border-danger m-0 position-absolute" style={{width: '90%', left: '5%', top: 39}}/>}
        {error && <span className="invalid-feedback">{error}</span>}
      </div>
    )
  }
}
