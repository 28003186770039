const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.replace(/\s+/g, "").length === 0) {
    errors.name = "Please enter your name.";
  }
  if (!values.address || values.address.replace(/\s+/g, "").length === 0) {
    errors.address = "Please enter address 1.";
  }
  if (!values.phone || values.phone.replace(/\s+/g, "").length === 0) {
    errors.phone = "Please enter your phone number.";
  }
  if (
    !values.sellOn ||
    !Array.isArray(values.sellOn) ||
    values.sellOn.length === 0
  ) {
    errors.sellOn = "Please choose the platform you are using to sell products";
  }
  if (!values.birthday) {
    errors.birthday = "Please enter your birthday.";
  }
  return errors;
};

export default validate;
