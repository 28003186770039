import React from 'react';
import BillingPaymentAccount from 'scenes/BillingPaymentAccount';
import BillingList from 'scenes/BillingPaymentAccount/v2';
import NewBilling from 'scenes/BillingPaymentAccount/v2/AddNew';
import icon from 'images/dollar2.svg';

export default [
  {
    key: 'billings.payment-method',
    name: 'Billing: Payment Method',
    component: BillingPaymentAccount,
    path: '/billings/payment-method',
    icon: <img src={icon} height={21} alt="icon" />,
    template: 'default',
    isPublic: false,
    permissions: ['viewSettingPermission'],
  },
  {
    key: 'billings.payment-method',
    name: 'Billing: Payment Method',
    component: BillingPaymentAccount,
    path: '/settings/payment-method',
    icon: <img src={icon} height={21} alt="icon" />,
    template: 'default',
    isPublic: false,
    permissions: ['viewSettingPermission'],
  },

  //Redirect link
  {
    key: 'billings.payment-method',
    name: 'Billing: Payment Method',
    component: BillingPaymentAccount,
    path: '/login-callback',
    template: 'default',
    isPublic: true,
  },
  {
    key: 'billings.payment-method',
    name: 'Billing: Payment Method',
    component: BillingPaymentAccount,
    path: '/register-callback',
    template: 'default',
    isPublic: true,
  },
  {
    key: 'billings.payment-method',
    name: 'Billing: Payment Method',
    component: BillingPaymentAccount,
    path: '/payoneer/success',
    template: 'default',
    isPublic: true,
  },
  {
    key: 'billings.payment-method',
    name: 'Billing: Payment Method',
    component: BillingPaymentAccount,
    path: '/payoneer/error',
    template: 'default',
    isPublic: true,
  },
  {
    key: 'billing.method',
    name: 'Billing Methods',
    component: BillingList,
    path: '/billing/method',
    template: 'default',
    isPublic: false,
  },
  {
    key: 'billing.method.new',
    name: 'New billing method',
    heading: 'Add new billing method',
    component: NewBilling,
    path: '/billing/method/new',
    template: 'default',
    isPublic: false,
  },
];
