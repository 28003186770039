import Edit from "./scenes/Edit";

export default [
  {
    key: "shipping-delivery",
    name: "Shipping & delivery",
    component: Edit,
    path: "/shipping-delivery",
    icon: null,
    template: "default",
    isPublic: false,
  },
  {
    key: "shipping-delivery",
    name: "Shipping & delivery",
    component: Edit,
    path: "/settings/shipping-delivery",
    icon: null,
    template: "default",
    isPublic: false,
  },
];
