import React from 'react';
import { Radio } from 'antd';
const renderRadioAnt = ({
  input,
  options,
  meta: { asyncValidating, touched, error },
  size,
  vertical,
  classNameItem = '',
  defaultValue = '',
}) => {
  const radioStyle = vertical
    ? {
        // display: 'block',
        lineHeight: '50px',
      }
    : {};
  return (
    <div>
      <Radio.Group
        size={size || 'default'}
        onChange={(e) => input.onChange(e.target.value)}
        value={(input.value || defaultValue) + ''}
        style={radioStyle}
      >
        {options.map((item) => {
          return (
            <Radio
              className={classNameItem}
              key={item.value}
              value={item.value + ''}
              disabled={item.disabled}
              style={radioStyle}
            >
              {item.label}
              {item.note && <p className="my-0 text-muted ml-4">{item.note}</p>}
            </Radio>
          );
        })}
      </Radio.Group>
      {error && <span className="invalid-feedback d-block">{error}</span>}
    </div>
  );
};

export default renderRadioAnt;
