import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Modal, Badge, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import {
  getOptionLabel,
  schedule_task_status,
  listAvailablePlatform,
} from 'utils/options';
import { getStoreName } from 'utils/helper';
import getMomentInstance from 'utils/moment';
import { getListLogSyncOrder } from 'scenes/Logs/actions';
import { Wrapper } from 'scenes/Logs/styled';
import { GMBadge } from 'components/Badge';
import { ERROR_100, ERROR_700, GRAY_100, GRAY_50, GRAY_700 } from 'components/colors';

class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      showDetail: false,
      selected: null,
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getListLogSyncOrder(params);
  };
  handleShowDetail = (selected) => {
    this.setState({
      showDetail: true,
      selected,
    });
  };
  render() {
    const { schedule_tasks, pageName } = this.props;
    const columns = [
      {
        title: <b>Id</b>,
        align: 'center',
        dataIndex: 'id',
        key: 'id',
        render: (text) => <Link to={`/logs/sync-orders/${text}`}>#{text}</Link>,
      },
      {
        title: <b>Store</b>,
        align: 'center',
        dataIndex: 'storeIntegration',
        key: 'storeIntegration',
        render: (text, record) => {
          return record.storeIntegration
            ? getStoreName(record.storeIntegration)
            : '';
        },
      },
      {
        title: <b>Platform</b>,
        align: 'center',
        dataIndex: 'storeIntegration',
        key: 'storeIntegration',
        render: (text, record) => {
          const backendType =
            record.storeIntegration && record.storeIntegration.backendType
              ? record.storeIntegration.backendType
              : '';
          const platform = listAvailablePlatform.find(
            (item) => item.type == backendType
          );
          return platform ? platform.name : '';
        },
      },
      {
        title: <b>Status</b>,
        align: 'center',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          return (
            <div>
              {record?.platformId === 3 ? (
                <GMBadge
                border={GRAY_100}
                color={GRAY_700}
                noDot={true}
                backgroundColor={GRAY_50}
                >
                  Trying again
                </GMBadge>
              ) : (
                getOptionLabel(schedule_task_status, text)
              )}
            </div>
          );
        },
      },
      {
        title: <b>Time request</b>,
        align: 'center',
        dataIndex: 'requestedAt',
        key: 'requestedAt',
        render: (text) => {
          return getMomentInstance(null, text, true);
        },
      },
    ];
    return (
      <Wrapper pageName={pageName}>
        <div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={schedule_tasks.data}
            loading={schedule_tasks.loading}
            scroll={{ x: 1000 }}
            pagination={
              !this.props.is_mobile
                ? {
                    showTotal: (total) => `Total ${total} items`,
                    total: schedule_tasks.total,
                    pageSize: parseInt(
                      schedule_tasks.params.perPage > schedule_tasks.total
                        ? schedule_tasks.total
                        : schedule_tasks.params.perPage
                    ),
                    current: schedule_tasks.params.page,
                    onChange: (page, perPage) =>
                      this.fetchData({
                        ...schedule_tasks.params,
                        page,
                        perPage,
                      }),
                  }
                : false
            }
          />
          {this.props.is_mobile && (
            <div className="d-flex justify-content-end align-items-center py-3 px-3">
              <div>
                <Pagination
                  size="small"
                  showSizeChanger
                  className="pagination"
                  total={schedule_tasks.total}
                  defaultCurrent={schedule_tasks?.params?.page || 1}
                  pageSize={schedule_tasks?.params?.perPage || 0}
                  onChange={(page, perPage) =>
                    this.fetchData({
                      ...schedule_tasks.params,
                      page:
                        page === Number.NEGATIVE_INFINITY
                          ? 1
                          : page === 0
                          ? 1
                          : page,
                      perPage,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
        <Modal
          width={600}
          footer={null}
          title={
            <div className="d-flex align-items-center">
              <b className="h4 mb-0">Schedule report detail</b>{' '}
              <Badge
                count="PAID"
                className="ml-2 site-badge site-badge-success"
              />
            </div>
          }
          visible={this.state.showDetail}
          onCancel={() => this.setState({ showDetail: false })}
          bodyStyle={{ padding: 0 }}
        >
          <div className="pb-1">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td style={{ width: 200 }} className="px-4">
                    Store:
                  </td>
                </tr>
                <tr className="d-flex flex-row">
                  <td style={{ width: 200 }} className="px-4">
                    Time from:
                  </td>
                  <td style={{ width: 200 }} className="px-4">
                    Time to:
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className="px-4">
                    Status:
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className="px-4">
                    Content:
                  </td>
                </tr>
                <tr className="d-flex flex-row">
                  <td style={{ width: 200 }} className="px-4">
                    Request time:
                  </td>
                  <td style={{ width: 200 }} className="px-4">
                    Complete time:
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    schedule_tasks: state.store.schedule_report.schedule_tasks,
  }),
  (dispatch) => ({
    getListLogSyncOrder: (options) => {
      dispatch(getListLogSyncOrder(options));
    },
  })
)(List);
