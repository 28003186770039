import {
    takeLatest,
    call,
    put,
    all
} from 'redux-saga/effects'

import {
    actionType as TYPE
} from './actions'

import {syncCampaign} from 'apis/campaign'
import {replace} from "connected-react-router";


function* syncCampaignSaga(action){
    const { data } = action
    try {
        const response = yield call(syncCampaign, data)
        yield all([
            put({ type: TYPE.SYNC_CAMPAIGN.SUCCESS, errors: response }),
            put(replace('/products/list'))
        ])
    } catch(error){
        yield all([
            yield put({ type: TYPE.SYNC_CAMPAIGN.ERROR, error })
        ])
    }
}

function* watcher(){
    yield takeLatest(TYPE.SYNC_CAMPAIGN.REQUEST, syncCampaignSaga);

}

export default watcher