import styled from "styled-components";
import PageContainer from "components/PageContainer";
import {
  BASE_WHITE,
  GRAY_300,
  SHADOW_XS
} from "components/colors";

export const Wrapper = styled(PageContainer)`
  .ant-input-affix-wrapper,
  .ant-input-number.ant-input-number-lg,
  textarea.ant-input.ant-input-lg,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker {
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px!important;
  }
`;
