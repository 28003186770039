export const invalidActionNotify = 'Your order is currently being imported. Please wait for the process to complete before taking further actions.';
export const remapFuncArray = (orderList = [], fncList = [], msgFnc) => {
  const cloneFncList = [...fncList];
  let isMapped = false;
  orderList.forEach(item => {
    if (item?.inImportProgress) {
      isMapped = true;
      return false;
    }
  });

  // isMapped = true;
  if (isMapped) {
    cloneFncList.forEach(fnc => {
      if (fnc.key !== 'archived_order') {
        fnc.onSelect = msgFnc;
      }
    });
  }
  return fncList;
};

export const fncCondRemap = (orderList = [], fncList = {}, msgFnc) => {
  const cloneFncList = { ...fncList };
  for (let i = 0; i < orderList.length; i++) {
    const item = orderList[i];

    if (item?.inImportProgress) {
      Object.keys(cloneFncList).forEach(fncName => {
        if (fncName !== 'archiveOrder') {
          cloneFncList[fncName] = msgFnc;
        }
      });
      return cloneFncList;
    }
  }
  return fncList;
};
