import { all } from 'redux-saga/effects';
import ListSaga from './scenes/List/saga';
import RiskSaga from './scenes/Risk/saga';
import DetailSaga from './scenes/Detail/saga';
import CreateIssueSaga from './scenes/Create_Issues/saga';
import ItemsRemovedSaga from './scenes/ItemsRemoved/saga';
import TrackingSaga from './scenes/Tracking/saga';
import ReportProductSellSaga from './scenes/ReportProductSell/saga';
import ManualPaymentSaga from './scenes/ManualPayment/saga';
import ManualPaymentDetailSaga from './scenes/ManualPaymentDetail/saga';
import OrderAchiveSaga from './scenes/ArchivedOrder/saga';
import IssueSaga from './scenes/Issue/saga';
import IssueDetailSaga from './scenes/DetailIssue/saga';
import reportSyncTracking from './scenes/ReportSyncTracking/saga';
import reportSyncTrackingDetail from './scenes/ReportSyncTrackingDetail/saga';
import ImportProgressSaga from './scenes/ImportProgress/saga';
import ImportProgressDetailSaga from './scenes/ImportProgressDetail/saga';
import ReportInkgoSaga from './scenes/ReportInkgo/saga';
import SyncOrderSaga from './scenes/SyncOrder/saga';
import ImportOrderSaga from './scenes/ImportOrder/saga';
import CreateOrderSaga from './scenes/Create/saga';
import InvoiceSaga from './scenes/Invoice/saga';
import MatchProductSaga from './scenes/MatchProduct/saga';
import StockStatus from './scenes/StockStatus/saga';
import CreateLableOrderSaga from './scenes/CreateLableOrder/saga';
import ConvertOrderLabelSaga from './scenes/ConvertOrderLabel/saga';
import DuplicateOrderFBASaga from './scenes/DuplicateOrderFBA/saga';
export default function* () {
  yield all([
    ListSaga(),
    RiskSaga(),
    DetailSaga(),
    OrderAchiveSaga(),
    ItemsRemovedSaga(),
    TrackingSaga(),
    ManualPaymentDetailSaga(),
    ManualPaymentSaga(),
    IssueSaga(),
    reportSyncTracking(),
    reportSyncTrackingDetail(),
    ImportProgressSaga(),
    ReportInkgoSaga(),
    CreateIssueSaga(),
    SyncOrderSaga(),
    ImportOrderSaga(),
    ImportProgressDetailSaga(),
    CreateOrderSaga(),
    InvoiceSaga(),
    MatchProductSaga(),
    StockStatus(),
    ReportProductSellSaga(),
    CreateLableOrderSaga(),
    ConvertOrderLabelSaga(),
    DuplicateOrderFBASaga(),
    IssueDetailSaga(),
  ]);
}
