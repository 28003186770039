import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Spin } from 'antd';
import BackToIcon from 'images/chevron-down.svg';
import FormDuplicateOrder from './components/FormDuplicateOrder';
import { duplicateFBAOrder } from './actions';
import { listCountry } from '../../../Users/scenes/Profile/actions';
import { getListExproduct } from '../../../Mockups/scenes/Create/actions';
import { getListStore } from '../../../Stores/scenes/List/actions';
import { getDetail } from './actions';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import {
  checkVariantRiskAction,
  closeVariantRiskAction,
  resetReducerAction,
} from '../Create/actions';
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: null,
    };
  }
  componentDidMount() {
    this.props.listCountry();
    this.props.getListExproduct({ sortBy: 'id', sortType: 'asc' });
    this.props.getListStore({
      isNotPlatform: 1,
      sortBy: 'id',
      sortType: 'asc',
    });
    this.props.getDetail(this.props.match.params.id, {
      'with[]': 'orderItems.exProduct',
    });
  }

  getConvertedData = () => {
    let values = this.props.FormDuplicateOrderValues;
    const listOrderItems = [];
    if (values.listOrderItems) {
      for (let i = 0; i < values.listOrderItems.length; i++) {
        listOrderItems.push({
          productId: values.listOrderItems?.[i]?.['productId'] || 0,
          colorId: values.listOrderItems?.[i]?.['colorId'] || 0,
          sizeId: values.listOrderItems?.[i]?.['sizeId'] || 0,
          quantity: values.listOrderItems?.[i]?.['quantity'] || 1,
          barcodeId: values.listOrderItems?.[i]?.['barcodeId'] || 0,
          imageFrontId: values.listOrderItems?.[i]?.['designFrontId'] || 0,
          imageBackId: values.listOrderItems?.[i]?.['designBackId'] || 0,
          imageFrontPath:
            values.listOrderItems?.[i]?.['frontDesignFilePath'] +
              '/' +
              values.listOrderItems?.[i]?.['frontDesignFileName'] || 0,
          imageBackPath:
            values.listOrderItems?.[i]?.['backDesignFilePath'] +
              '/' +
              values.listOrderItems?.[i]?.['backDesignFileName'] || 0,
          barcodePath: values.listOrderItems?.[i]?.['barcode'] || '',
          productType: values.listOrderItems?.[i]?.['productType'] || 0,
          sidePrint: values.listOrderItems?.[i]?.['sidePrint'] || 'normal',
        });
      }
    }

    const dataToDuplicateOrder = {
      shippingMethod: values.shippingMethod || 3,
      onlineId: values.orderId || '',
      barcodeId: values.barcodeIds || 0,
      orderItems: listOrderItems,
      orderNote: values.orderNote || '',
      parentOrderId: this.props.match.params.id,
      barcodeFiles: values.barcodeFiles || [],
    };
    return dataToDuplicateOrder;
  };

  handleSubmitCheckVariant = (values) => {
    let data = [];
    let newArray = values?.listOrderItems?.map(function (i) {
      return data.push({
        exp_id: i?.productId,
        color_id: parseInt(i?.colorId),
        size_id: parseInt(i?.sizeId),
      });
    });
    this.props.checkVariantRiskAction({
      data: { data: data },
      submitData: this.getConvertedData(),
      form: 'duplicate_order',
    });
  };

  handleSubmitDuplicate = (values) => {
    window.scroll(0, 0);
    this.props.closeVariantRiskAction();
    this.props.duplicateFBAOrder(this.getConvertedData());
    this.props.resetReducerAction();
  };

  handleCloseModal = () => {
    this.props.closeVariantRiskAction();
  };
  render() {
    const {
      pageName,
      duplicateOrderLoading,
      countries,
      exproducts,
      profile,
      detail,
      stores,
      listVariantRisk,
      listVariantRiskLoading,
      showModalVariant,
    } = this.props;
    const initialValues = {
      shippingService: 9,
      orderId: detail?.data?.onlineId || '',
      orderNote: detail?.data?.orderNote?.giftMessage,
      storeId: `${detail.data.storeId || 0}`,
      barcodeId: 0,
      barcodeUrl: '',
      listOrderItems: [],
      barcodeFiles: [],
    };
    if (
      detail?.data?.orderItems &&
      Array.isArray(detail?.data?.orderItems) &&
      detail?.data?.orderItems.length > 0
    ) {
      initialValues['listOrderItems'] = detail?.data?.orderItems.map(
        (orderItem, orderItemIndex) => ({
          key: orderItemIndex,
          productId: orderItem?.expId || '0',
          colorId: orderItem?.color ? `${orderItem?.color}` : '0',
          sizeId: orderItem?.size ? `${orderItem?.size}` : '0',
          quantity: orderItem?.quantity || '0',
          price: orderItem?.price || 0,
          designFront: orderItem?.frontDesignUrl || '',
          designBack: orderItem?.backDesignUrl || '',
          mockup: orderItem?.mainUrl || '',
          designFrontId: '',
          designBackId: '',
          barcodeId: '',
          barcode: '',
          basePrice: orderItem?.basePrice || '',
          colorHex: orderItem?.hexColor ? `#${orderItem?.hexColor}` : '',
          productType: orderItem?.exProduct?.type || 0,
          productName: orderItem?.exProduct?.brand || '',
          productDisplayName: orderItem?.exProduct?.brand || '',
          sidePrint: orderItem?.printSide2 || 'normal',
          printLocation: orderItem?.exProduct?.printLocation,
          frontDesignFilePath: orderItem?.designFilePath,
          frontDesignFileName: orderItem?.frontDesignFileName,
          backDesignFileName: orderItem?.backDesignFileName,
          backDesignFilePath: orderItem?.backDesignFilePath,
          exProduct:orderItem.exProduct
        })
      );
    } else {
      initialValues['listOrderItems'].push({
        key: 0,
        productId: '0',
        colorId: '0',
        sizeId: '0',
        quantity: '0',
        price: 0,
        designFront: '',
        designBack: '',
        mockup: '',
        designFrontId: '',
        designBackId: '',
        barcode: '',
        barcodeId: '',
        sidePrint: '',
        printLocation: '',
        imageFrontPath: '',
        imageBackPath: '',
      });
    }
    return (
      <div>
        <Wrapper pageName={pageName} noContainer>
          <Spin
            spinning={
              duplicateOrderLoading ||
              countries.loading ||
              exproducts.loading ||
              detail.loading
            }
          >
            <FormDuplicateOrder
              stores={stores?.data}
              profile={profile}
              exproducts={exproducts.data}
              countries={countries.data}
              onSubmit={this.handleSubmitCheckVariant}
              initialValues={initialValues}
            />
          </Spin>
        </Wrapper>
        <Modal
          centered
          title="Order confirmation required"
          visible={showModalVariant}
          onCancel={() => {
            this.handleCloseModal();
          }}
          onOk={() => {
            this.handleSubmitDuplicate();
          }}
          width={800}
          footer={null}
        >
          <Spin spinning={listVariantRiskLoading}>
            <div className="mb-2">
              Your order includes the following{' '}
              <a
                href="https://help.gearment.com/en/article/order-with-variation-at-risk-8wumdy/"
                target="blank"
              >
                variant(s) at risk
              </a>
              :
            </div>
            {listVariantRisk?.data?.map((i) => {
              return (
                <p>
                  <ul>
                    <li>
                      {i?.exp_brand} - {i?.color_name} - {i?.size_name}
                    </li>
                  </ul>
                </p>
              );
            })}
            <div>
              By continuing, you confirm to duplicate the order with the
              variant(s) at risk and acknowledge that the processing time may be
              extended for a few business days.
            </div>
          </Spin>
          <div className="d-flex mt-3 mb-3 pb-3 align-items-right justify-content-end">
            <GMButton
              color="SecondaryGray"
              type="button"
              onClick={() => {
                this.handleCloseModal();
              }}
              style={{ width: 170 }}
            >
              Cancel
            </GMButton>
            <GMButton
              style={{ width: 170 }}
              onClick={() => {
                this.handleSubmitDuplicate();
              }}
              className="ml-3"
            >
              Confirm order
            </GMButton>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    duplicateOrderLoading: state.order.duplicateOrderFba.duplicateOrder.loading,
    FormDuplicateOrderValues: state.form.FormCreateOrderFBA?.values,
    countries: state.user.profile.listCountry,
    exproducts: state.mockup.create.exproducts,
    stores: state.store.list.stores2,
    profile: state.auth.info.profile,
    detail: state.order.duplicateOrderFba.order,
    listVariantRiskLoading: state.order.createOrder.listVariantRisk.loading,
    listVariantRisk: state.order.createOrder.listVariantRisk,
    showModalVariant: state.order.createOrder.showModalVariant,
  }),
  (dispatch) => ({
    duplicateFBAOrder: (data) => {
      dispatch(duplicateFBAOrder(data));
    },
    listCountry: () => {
      dispatch(listCountry());
    },
    getListStore: (options) => {
      dispatch(getListStore(options));
    },
    getListExproduct: (params) => {
      dispatch(getListExproduct(params));
    },
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
    checkVariantRiskAction: (data) => {
      dispatch(checkVariantRiskAction(data));
    },
    closeVariantRiskAction: () => {
      dispatch(closeVariantRiskAction());
    },
    resetReducerAction: () => {
      dispatch(resetReducerAction());
    },
  })
)(index);
