import React, { useEffect, useState } from 'react';
import DiscardBillingMethodConfirmModal from './DiscardBillingMethodConfirmModal';
import { connect, useDispatch, useSelector } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { push } from 'connected-react-router';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import { GMSelect, GMTextbox } from 'components/InputFields';
import { getPaypalInfoFromToken } from 'apis/payment_menthod';
import { Checkbox, Spin, notification } from 'antd';
import { ReactComponent as LianLogoIcon } from 'images/logo_payment/lian-logo.svg';
import { validate } from './utils';
import {
  getDetailBillingMethodAction,
  loadListAccountAction,
  loadPrimaryAccountAction,
  saveBillingMethodAction,
  setLoading,
  updateBillingMethodAction,
} from 'scenes/BillingPaymentAccount/actions';
import { listCountry } from 'scenes/Users/scenes/Profile/actions';
import { dummyFunction } from 'utils/helper';

import { ReactComponent as PaypalIcon } from 'images/logo_payment/paypal_sz32.svg';
import { ReactComponent as CheckCircleIcon } from 'images/icons/check-circle.svg';
import { ReactComponent as PayoneerIcon } from 'images/logo_payment/payoneer_sz32.svg';
import { ReactComponent as GWalletIcon } from 'images/logo_payment/gwallet_sz32.svg';
import { ReactComponent as CopyIcon } from 'images/icons/copy-05.svg';
import { useHistory, useLocation } from 'react-router-dom';
import GMStateSelector from 'components/GMStateSelector';

export const billingInfoForm = 'form_billingInfo';

const BillingInfoForm = ({
  handleSubmit,
  altParams,
  handleDisconnectPayoneerAccount = dummyFunction,
  pageName,
  setPrefillValue,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const values = useSelector((s) => s.form?.[billingInfoForm]?.values);
  const loading = useSelector((s) => s.billingPaymentAccount?.loading);
  const countries = useSelector((s) => s.user.profile.listCountry);
  const listBillingPaymentAccount = useSelector(
    (s) => s.billingPaymentAccount?.list
  );
  const primaryBillingPaymentAccount = useSelector(
    (s) => s.billingPaymentAccount?.primary
  );
  
  const [modalAction, setModalAction] = useState(null);
  useEffect(() => {
    dispatch(listCountry());
    if (
      altParams?.update &&
      (listBillingPaymentAccount?.billings?.length || 0) <= 0
    ) {
      dispatch(loadListAccountAction());
    }
    if (!primaryBillingPaymentAccount) {
      dispatch(loadPrimaryAccountAction());
    }
  }, []);

  useEffect(() => {
    const id = altParams?.update;
    if (id && !values.payId) {
      const item = [...(listBillingPaymentAccount?.billings || []), primaryBillingPaymentAccount?.billing]?.find(
        (i) => i?.id * 1 === id * 1
      );
      dispatch(change(billingInfoForm, 'paymentMethod', item?.type));
      dispatch(change(billingInfoForm, 'payId', item?.id));
      dispatch(change(billingInfoForm, 'billingId', item?.billingId));
      dispatch(getDetailBillingMethodAction(id));
      if (item?.type === 0) {
        dispatch(change(billingInfoForm, 'paypal.email', item?.email));
      }
    }
  }, [listBillingPaymentAccount, primaryBillingPaymentAccount]);

  const backToInitStep = (paymentType = '') => {
    dispatch(push('/billing/method'));
    if (paymentType) {
      notification.warn({
        message: `Can not connect to your ${paymentType} account.`,
      });
    }
  };
  useEffect(() => {
    if (altParams?.token && altParams?.code) {
      dispatch(setLoading(true));
      getPaypalInfoFromToken({
        token: altParams.token ? altParams.token : '',
        payerId: altParams.PayerID ? altParams.PayerID : '',
        code: altParams.code ? altParams.code : '',
      })
        .then((rs) => {
          if (rs?.code === 'success') {
            dispatch(
              change(billingInfoForm, 'paypal.billing', rs?.data?.billing)
            );
            dispatch(change(billingInfoForm, 'paypal.email', rs?.data?.email));
            dispatch(change(billingInfoForm, 'paymentMethod', 0));
          } else {
            backToInitStep('Paypal');
          }
        })
        .catch((err) => {
          backToInitStep('Paypal');
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    } else if (altParams?.cus_id && altParams?.pid) {
      dispatch(change(billingInfoForm, 'payoneer.cus_id', altParams?.cus_id));
      dispatch(change(billingInfoForm, 'payoneer.pid', altParams?.pid));
      dispatch(change(billingInfoForm, 'payoneer.status', altParams?.status));
      dispatch(change(billingInfoForm, 'paymentMethod', 1));
      dispatch(setLoading(false));
    } else if (altParams?.code === 'gwallet') {
      dispatch(change(billingInfoForm, 'paymentMethod', 3));
      // update
    } else if (altParams?.code === 'lianlian') {
      dispatch(change(billingInfoForm, 'paymentMethod', 4));
      // update
    } else if (altParams?.update) {
    } else {
      backToInitStep('GWallet');
    }
  }, [...Object.values(altParams)]);

  const loadLegalInfo = setPrefillValue;
  const onSubmit = (values) => {
    if (!primaryBillingPaymentAccount || !primaryBillingPaymentAccount.billing) {
      values.isPrimary = true;
    }
    if (!values.payId) {
      dispatch(
        saveBillingMethodAction({ ...values, isPrimary: !!values.isPrimary })
      );
    } else {
      dispatch(
        updateBillingMethodAction({
          ...values,
          isPrimary: state?.isPrimary ? true : !!values.isPrimary,
        })
      );
    }
    return false;
  };
  return (
    <>
      <Spin spinning={loading}>
        <Wrapper
          pageName={altParams?.update ? 'Update billing method' : pageName}
          noContainer
        >
          <div className="pmlist mt-2">
            {values?.paypal || values?.paymentMethod === 2 ? (
              <div className="addpm active">
                <div className="inner-wrapper nohover">
                  <PaypalIcon />
                  <div className="info">{values?.paypal?.email}</div>
                </div>
              </div>
            ) : null}
            {values?.payoneer || values?.paymentMethod === 1 ? (
              <div className="addpm active">
                <div className="inner-wrapper nohover">
                  <PayoneerIcon />
                  <div className="info">
                    <p className="description">
                      If you prefer Payoneer, this is fine with us! If you add
                      both, we will use Payoneer as the default option.
                    </p>
                    <p className="description bold2">
                      Your account is linked to Payoneer
                    </p>
                    <div className="buttons">
                      <GMButton
                        color="PrimaryError"
                        onClick={handleDisconnectPayoneerAccount}
                      >
                        Remove account
                      </GMButton>
                    </div>
                    <p className="description bottom">
                      0% fee for payment to Gearment via Payoneer
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            {values?.paymentMethod === 3 ? (
              <div className="addpm active">
                <div className="inner-wrapper nohover">
                  <GWalletIcon />
                </div>
              </div>
            ) : null}
            {values?.paymentMethod === 4 ? (
              <div className="addpm active">
                <div className="inner-wrapper nohover lian">
                  <LianLogoIcon />
                </div>
              </div>
            ) : null}
          </div>
          {/* message ss. */}
          {/* {values?.paymentMethod !== 4 && ( */}
            <div className="msg">
              <CheckCircleIcon className="icon" />
              <div className="content">
                <label>Your data is safe.</label>
                <p>
                  We don't store your card or billing method details, and we use
                  industry-standard encryption to protect your personal
                  information.
                </p>
              </div>
            </div>
          {/* )} */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* form ss. */}
            <div className="info-form main-content">
              <h4>Billing Infomation</h4>
              <label onClick={loadLegalInfo} className="copy-legal">
                Use legal information <CopyIcon />
              </label>
              <div className="gmrow gcol-3">
                <div className="igrp">
                  <GMTextbox
                    name="firstName"
                    maxLength={'256'}
                    label="First name"
                    placeholder="Enter first name"
                    showError
                  />
                </div>
                <div className="igrp">
                  <GMTextbox
                    name="lastName"
                    maxLength={'256'}
                    placeholder="Enter last name"
                    label="Last name"
                    showError
                  />
                </div>
                <div className="igrp">
                  <GMTextbox
                    name="company"
                    label={
                      <>
                        Company
                        <span className="text-muted"> (Optional)</span>
                      </>
                    }
                    placeholder="Enter company"
                    maxLength={'30'}
                    showError
                  />
                </div>
              </div>
              <div className="gmrow gcol-3">
                <div className="igrp">
                  <GMTextbox
                    name="address"
                    label="Address 1"
                    placeholder="Enter address 1"
                    showError
                  />
                </div>
                <div className="igrp">
                  <GMTextbox
                    name="address2"
                    label={
                      <>
                        Address 2<span className="text-muted"> (Optional)</span>
                      </>
                    }
                    placeholder="Enter address 2"
                    showError
                  />
                </div>
                <div className="igrp">
                  <GMTextbox
                    name="phone"
                    label={
                      <>
                        Phone number
                        <span className="text-muted"> (Optional)</span>
                      </>
                    }
                    placeholder="Enter phone number"
                    maxLength={'30'}
                    showError
                  />
                </div>
              </div>
              <div className="gmrow gcol-4">
                <div className="igrp">
                  <GMTextbox
                    name="city"
                    label="City"
                    placeholder="Enter city"
                    maxLength={50}
                    showError
                  />
                </div>
                <div className="igrp">
                  {values.country ? (
                    <GMStateSelector
                      name="province"
                      label="State/Province"
                      placeholder="Enter state/province"
                      country={
                        countries?.data?.find(
                          (c) => c.id + '' === values.country + ''
                        )?.countryIsoCode
                      }
                      showError
                    />
                  ) : (
                    <GMTextbox
                      name="province"
                      maxLength={'50'}
                      placeholder="Enter state/province"
                      label="State/Province"
                      showError
                    />
                  )}
                </div>
                <div className="igrp">
                  <GMSelect
                    name="country"
                    placeholder="Select a country"
                    label="Country"
                    onChange={() => {
                      dispatch(change(billingInfoForm, 'province', ''));
                    }}
                    options={[
                      { value: '0', label: 'Select a country' },
                      ...countries.data.map((item) => ({
                        value: `${item.id}`,
                        label: item.countryName,
                      })),
                    ]}
                    showError
                  />
                </div>
                <div className="igrp">
                  <GMTextbox
                    name="postalCode"
                    label="Zip code/Postal code"
                    placeholder="Enter zip code/postal code"
                    maxLength={50}
                    showError
                  />
                </div>
              </div>
              {(state?.isPrimary === true || !!primaryBillingPaymentAccount?.billing?.payDefault) ? (
                <Checkbox name="isPrimary" defaultChecked={true} disabled>
                  Set as the primary billing method
                </Checkbox>
              ) : primaryBillingPaymentAccount?.billing?.cusId ? (
                <div className="gmrow full">
                  <div className="igrp primary">
                    <Checkbox
                      onChange={() =>
                        dispatch(
                          change(
                            billingInfoForm,
                            'isPrimary',
                            !values?.isPrimary
                          )
                        )
                      }
                    >
                      Set as the primary billing method
                    </Checkbox>
                  </div>
                </div>
              ) : null}

              {/* cta ss. */}
              <div className="ctas">
                <GMButton
                  type="button"
                  color="SecondaryGray"
                  onClick={() => history.replace('/billing/method')}
                >
                  Discard
                </GMButton>
                <GMButton>{!altParams?.update ? 'Add' : 'Save'}</GMButton>
              </div>
            </div>
          </form>
        </Wrapper>
      </Spin>
      {modalAction && (
        <DiscardBillingMethodConfirmModal
          onOk={() => modalAction()}
          onCancel={() => setModalAction(null)}
        />
      )}
    </>
  );
};
export default connect()(
  reduxForm({
    form: billingInfoForm,
    enableReinitialize: true,
    validate,
  })(BillingInfoForm)
);
