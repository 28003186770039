import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Tabs, Modal, Tooltip, notification, Pagination, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import getMomentInstance from 'utils/moment';
import {
  listCampaigns,
  archiveCampaigns,
  unarchiveCampaigns,
  deleteCampaigns,
  syncCampaigns,
  previewCampaigns,
  countCampaigns,
  previewModalProduct,
} from './actions';
import FormFilter from './components/FormFilter';
import DotLoadingIcon from 'images/dots-loading.gif';
import {
  getOptionLabel,
  options_campaign_append_product_type,
  options_campaign_status,
  options_campaign_type_group,
} from 'utils/options';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RenderListActions from 'utils/Form/RenderListActions';
import RenderPreviewCampaign from 'utils/Form/RenderPreviewCampaign';
import {
  addMarginTopToCheckBoxOfAntdTable,
  checkFunctionalPermission,
} from 'utils/helper';
import RenderExportCampaigns from 'utils/Form/RenderExportCampaigns';
import RenderOptimizedTable from 'utils/Form/RenderOptimizedTable';
import { getListExproduct } from '../../../Mockups/scenes/Create/actions';
import LazyLoad from 'react-lazyload';
import { MoreActionStore, Wrapper } from './styled';
import { GMButton } from 'components/Button';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import Filter from 'components/Filter';
import { ReactComponent as LinkIcon } from 'images/icons/plus.svg';
import { ContextMenuWrapper } from 'scenes/BillingPaymentAccount/v2/styled';
import noImg from 'images/noImg.png';
import { ReactComponent as I1Icon } from 'images/icons/alert-circle.svg';

const { confirm } = Modal;
class List extends Component {
  constructor(props) {
    super(props);
    const { createdAtFrom, createdAtTo, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      selectedRowKeys: [],
      activeKeyTag: '1',
      initial_filter_values: {
        perPage: 100,
        sortBy: 'id',
        sortType: 'desc',
        status: '4',
        productId: '0',
        colorIds: [],
        sizeIds: [],
        ...query_params,
        date_range: [
          createdAtFrom
            ? getMomentInstance(createdAtFrom, 0, false, true)
            : null,
          createdAtTo ? getMomentInstance(createdAtFrom, 0, false, true) : null,
        ],
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
    this.props.countCampaigns();
    this.props.getListExproduct({ sortBy: 'id', sortType: 'asc' });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.campaigns) !==
      JSON.stringify(this.props.campaigns)
    ) {
      const newSelectedRowKeys = this.state.selectedRowKeys.filter((id) =>
        (this.props?.campaigns?.data || []).find(
          (item) => parseInt(item.id) === parseInt(id)
        )
      );
      this.setState({ selectedRowKeys: newSelectedRowKeys });
    }
    addMarginTopToCheckBoxOfAntdTable(
      'ant-table-selection',
      this.state.selectedRowKeys
    );
  }

  fetchData = ({ date_range, page = 1, perPage = 100, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (date_range?.[0]) {
      params['fromDate'] = getMomentInstance(date_range[0]).format(
        'YYYY-MM-DD'
      );
    }
    if (date_range?.[1]) {
      params['toDate'] = getMomentInstance(date_range[1]).format('YYYY-MM-DD');
    }
    if (!params['fromDate']) delete params['fromDate'];
    if (!params['toDate']) delete params['toDate'];
    if (parseInt(params['productId']) === 0) delete params['productId'];
    if (!Array.isArray(params['sizeIds']) || params['sizeIds'].length === 0)
      delete params['sizeIds'];
    if (!Array.isArray(params['colorIds']) || params['colorIds'].length === 0)
      delete params['colorIds'];
    if (params['colorId']) delete params['colorId'];
    if (parseInt(params['status']) === 4) delete params['status'];
    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getList({
      ...params,
      'with[]': 'under100Variants',
      'with[]': 'over100Variants',
      'with[]': 'orderProfit',
    });
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleChangeTab = (activeKey) => {
    this.setState({ activeKeyTag: activeKey, selectedRowKeys: [] });
    switch (activeKey) {
      case '1':
        this.fetchData({ page: 1, archived: null });
        break;
      case '2':
        this.fetchData({ page: 1, archived: 0 });
        break;
      case '3':
        this.fetchData({ page: 1, archived: 1 });
        break;
      default:
        // nothing to do
        break;
    }
  };
  archiveCampaigns = (ids) => {
    const { archiveCampaigns, campaigns } = this.props;
    confirm({
      title: 'Do you want to archive these products?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        archiveCampaigns({ campaigns: ids }, campaigns.params);
        window.scroll(0, 0);
      },
      onCancel() {},
    });
  };
  unarchiveCampaigns = (ids) => {
    const { unarchiveCampaigns, campaigns } = this.props;
    confirm({
      title: 'Do you want to unarchive these products?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        unarchiveCampaigns({ campaigns: ids }, campaigns.params);
        window.scroll(0, 0);
      },
      onCancel() {},
    });
  };
  syncCampaigns = (ids) => {
    const redirectToSyncCampaign = () => {
      this.props.history.push('/products/sync-product', {
        listCampaignId: ids,
      });
    };
    confirm({
      title: 'Do you want to sync these products?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        redirectToSyncCampaign();
        window.scroll(0, 0);
      },
      onCancel() {},
    });
  };
  deleteCampaigns = (ids) => {
    const { deleteCampaigns, campaigns } = this.props;
    confirm({
      title: 'Do you want to delete these products?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        deleteCampaigns({ campaigns: ids }, campaigns.params);
        window.scroll(0, 0);
      },
      onCancel() {},
    });
  };
  previewCampaigns = (ids) => {
    const redirectToPreviewampaign = () => {
      this.props.history.push('/products/preview', { listCampaignId: ids });
    };
    confirm({
      title: 'Do you want to preview these products?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        redirectToPreviewampaign();
      },
      onCancel() {},
    });
  };
  feedGoogleForm = (ids) => {
    const redirectToFeedGoogleForm = () => {
      this.props.history.push('/products/data-feed/feed-form-google', {
        listCampaignId: ids,
      });
    };
    if (!Array.isArray(ids) || ids.length === 0) {
      notification.warn({
        message: 'Please choose products to feed Google Form',
        duration: 5,
      });
      return;
    }
    redirectToFeedGoogleForm();
  };
  feedAmazonForm = (ids) => {
    const redirectToFeedGoogleForm = () => {
      this.props.history.push('/products/data-feed/feed-form-amazon', {
        listCampaignId: ids,
      });
    };
    if (!Array.isArray(ids) || ids.length === 0) {
      notification.warn({
        message: 'Please choose products to feed Amazon Form',
        duration: 5,
      });
      return;
    }
    redirectToFeedGoogleForm();
  };
  feedCustomAmazon = (ids) => {
    const redirectToFeedGoogleForm = () => {
      this.props.history.push('/products/data-feed/custom-feed-amazon', {
        listCampaignId: ids,
      });
    };
    if (!Array.isArray(ids) || ids.length === 0) {
      notification.warn({
        message: 'Please choose products to feed custom Amazon',
        duration: 5,
      });
      return;
    }
    redirectToFeedGoogleForm();
  };
  render() {
    const {
      campaigns,
      pageName,
      deleteCampaignLoading,
      unarchiveCampaignLoading,
      archiveCampaignLoading,
      count,
      profile,
      exproducts,
    } = this.props;

    const { initial_filter_values, selectedRowKeys } = this.state;
    let defaultActiveKey = '1';
    if (initial_filter_values.archived !== undefined) {
      defaultActiveKey = initial_filter_values.archived === '1' ? '3' : '2';
    }

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };
    const rowSelectionActions = {
      selections: [
        {
          key: 'data',
          text: 'Preview products',
          onSelect: () => {
            this.previewCampaigns(selectedRowKeys);
          },
        },
      ],
    };
    // if (
    //   checkFunctionalPermission(profile, 'syncProductPermission')
    // ) {
    //   rowSelectionActions['selections'].push({
    //     key: 'sync_campaign',
    //     text: 'Sync products',
    //     onSelect: () => {
    //       this.syncCampaigns(selectedRowKeys);
    //     },
    //   });
    // }
    if (this.state.activeKeyTag === '3') {
      rowSelectionActions['selections'].push({
        key: 'delete_campaign',
        text: 'Delete products',
        onSelect: () => {
          this.deleteCampaigns(selectedRowKeys);
        },
      });
    }

    if (this.state.activeKeyTag === '3' || this.state.activeKeyTag === '1') {
      rowSelectionActions['selections'].push({
        key: 'unarchive_campaign',
        text: 'Unarchive products',
        onSelect: () => {
          this.unarchiveCampaigns(selectedRowKeys);
        },
      });
    }

    if (this.state.activeKeyTag === '2' || this.state.activeKeyTag === '1') {
      rowSelectionActions['selections'].push({
        key: 'archive_campaign',
        text: 'Archive products',
        onSelect: () => {
          this.archiveCampaigns(selectedRowKeys);
        },
      });
    }
    const listActions = [
      {
        key: 'export_campaigns',
        text: <RenderExportCampaigns listSelectedCampaigns={selectedRowKeys} />,
        onSelect: () => {},
      },
      {
        key: 'feed_google_form',
        text: 'Export feed Google form',
        onSelect: () => {
          this.feedGoogleForm(selectedRowKeys);
        },
      },
      {
        key: 'feed_amazon_form',
        text: 'Export feed Amazon form',
        onSelect: () => {
          this.feedAmazonForm(selectedRowKeys);
        },
      },
      {
        key: 'feed_custom_amazon_form',
        text: 'Export feed custom Amazon',
        onSelect: () => {
          this.feedCustomAmazon(selectedRowKeys);
        },
      },
      {
        key: 'unarchive_campaign',
        text: 'Unarchive products',
        onSelect: () => {
          this.unarchiveCampaigns(selectedRowKeys);
        },
      },
      {
        key: 'archive_campaign',
        text: 'Archive products',
        onSelect: () => {
          this.archiveCampaigns(selectedRowKeys);
        },
      },
      {
        key: 'delete_campaign',
        text: 'Delete products',
        onSelect: () => {
          this.deleteCampaigns(selectedRowKeys);
        },
      },
    ];

    checkFunctionalPermission(profile, 'syncProductPermission') &&
      listActions.unshift({
        key: 'sync_campaign',
        text: 'Sync products',
        onSelect: () => {
          this.syncCampaigns(selectedRowKeys);
        },
      });

    const columns = [
      {
        width: 20,
      },
      {
        title: <b>Image</b>,
        align: 'left',
        dataIndex: 'productCampaignDefault',
        key: 'productCampaignDefault',
        width: 200,
        shouldCellUpdate: (record, prevRecord) =>
          JSON.stringify(record) !== JSON.stringify(prevRecord),
        render: (value, record) => {
          return value ? (
            <div className="d-flex justify-content-start align-items-center">
              <div className="d-flex flex-row align-items-center justify-content-center">
                <a
                  href={value.designFront}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tooltip title="Front image">
                    <img
                      src={value.designFront || noImg}
                      alt="edit-icon"
                      style={{ backgroundColor: '#cccc', maxWidth: '70px' }}
                      className="mr-2"
                    />
                  </Tooltip>
                </a>
                <a
                  href={value.designBack}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'inline-block',
                  }}
                >
                  <Tooltip title="Back image">
                    <img
                      src={value.designBack || noImg}
                      alt="edit-icon"
                      style={{ backgroundColor: '#cccc', maxWidth: '70px' }}
                      className="mr-2"
                    />
                  </Tooltip>
                </a>
              </div>
              <RenderPreviewCampaign
                campaignId={record.id}
                campaignName={record.name}
              />
            </div>
          ) : (
            <FontAwesomeIcon icon={faImage} style={{ fontSize: 50 }} />
          );
        },
      },
      {
        title: <b>Product name</b>,
        dataIndex: 'name',
        align: 'left',
        className: 'product-name',
        width: '40%',
        shouldCellUpdate: (record, prevRecord) =>
          JSON.stringify(record) !== JSON.stringify(prevRecord),
        key: 'name',
      },
      {
        title: <b className="d-flex align-items-left">Variants</b>,
        width: '10%',
        shouldCellUpdate: (record, prevRecord) =>
          JSON.stringify(record) !== JSON.stringify(prevRecord),
        render: (record) => (
          <div className="line show-tooltip">
            Variant:&nbsp;<b>{record.variantNumber}</b>
            <Tooltip
              title={
                <div>
                  <div>
                    Type group:
                    <b className="ml-1">
                      {getOptionLabel(
                        options_campaign_type_group,
                        record.typeGroup
                      )}
                    </b>
                  </div>
                  <div>
                    Total variant:
                    <b className="ml-1">{record.variantNumber}</b>
                  </div>
                  <div>
                    Append product type:
                    <b className="ml-1">
                      {getOptionLabel(
                        options_campaign_append_product_type,
                        record.appendProductType
                      )}
                    </b>
                  </div>
                </div>
              }
            >
              <I1Icon />
            </Tooltip>
          </div>
        ),
      },
      // {
      //   title: <b>Status</b>,
      //   key: 'status',
      //   align: 'left',
      //   dataIndex: 'status',
      //   shouldCellUpdate: (record, prevRecord) =>
      //     JSON.stringify(record) !== JSON.stringify(prevRecord),
      //   render: (value) => getOptionLabel(options_campaign_status, value),
      // },
      {
        title: <b>Created time</b>,
        dataIndex: 'createdAt',
        align: 'left',
        width: '20%',
        key: 'createdAt',
        shouldCellUpdate: (record, prevRecord) =>
          JSON.stringify(record) !== JSON.stringify(prevRecord),
        render: (value) => getMomentInstance(value, 0, true),
      },
      {
        title: <b></b>,
        key: 'action',
        align: 'right',
        width: '10%',
        render: (record) => {
          return (
            <MoreActionStore>
              <Dropdown
                placement="topLeft"
                overlay={
                  <ContextMenuWrapper>
                    <Link to={`/products/${record.id}/edit`}>Edit</Link>

                    <Link to={`/products/${record.id}/bulk`} className="mr-1">
                      Bulk
                    </Link>
                    <Link to={`/products/${record.id}/clone`} className="mr-1">
                      Clone
                    </Link>
                  </ContextMenuWrapper>
                }
                trigger={['click']}
              >
                <MoreAction className="context-menu" />
              </Dropdown>
            </MoreActionStore>
          );
        },
      },
    ];
    return (
      <Wrapper
        pageName={pageName}
        ctas={
          <>
            {this.state.selectedRowKeys.length > 0 ? (
              <RenderListActions
                title={'products'}
                selectedRowKeys={this.state.selectedRowKeys}
                actions={listActions}
                rowSelection={rowSelectionActions}
              />
            ) : null}
            {checkFunctionalPermission(profile, 'createProductPermission') && (
              <GMButton
                className="cta-upload"
                onClick={() => {
                  this.props.history.push('/products/create');
                }}
              >
                <LinkIcon />
                Create new product
              </GMButton>
            )}
          </>
        }
        filter={
          <Filter
            placeholder="Search by file name"
            className="filter-form"
            maxLength={50}
            onSubmit={(values) => {
              this.fetchData({
                name: (values.search || '').trim(),
                page: 1,
              });
            }}
            afterAddon={
              <FormFilter
                initialValues={initial_filter_values}
                exproducts={exproducts.data}
                selectedCampaigns={this.state.selectedRowKeys}
                onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
                handleAdvancedSubmit={this.fetchData}
                is_mobile={this.props.is_mobile}
              />
            }
          />
        }
      >
        <>
          <Tabs
            defaultActiveKey={defaultActiveKey}
            onChange={this.handleChangeTab}
            className="tabs"
          >
            <Tabs.TabPane
              tab={
                count.loading ? (
                  <>
                    <span className="mr-2">All products</span>{' '}
                    <img src={DotLoadingIcon} width={20} alt="loading" />
                  </>
                ) : (
                  `All products (${
                    parseInt(count?.data?.archived || 0) +
                    parseInt(count?.data?.unArchived || 0)
                  })`
                )
              }
              key="1"
            ></Tabs.TabPane>
            <Tabs.TabPane
              tab={
                count.loading ? (
                  <>
                    <span className="mr-2">Archived</span>{' '}
                    <img src={DotLoadingIcon} width={20} alt="loading" />
                  </>
                ) : (
                  `Archived (${count?.data?.archived || 0})`
                )
              }
              key="3"
            ></Tabs.TabPane>
            {/* <Tabs.TabPane
              tab={
                count.loading ? (
                  <>
                    <span className="mr-2">Unarchived</span>{' '}
                    <img src={DotLoadingIcon} width={20} alt="loading" />
                  </>
                ) : (
                  `Unarchived (${count?.data?.unArchived || 0})`
                )
              }
              key="2"
            ></Tabs.TabPane> */}
          </Tabs>
          <div className="custom-header-sticky">
            <RenderOptimizedTable
              rowKey="id"
              sticky
              rowSelection={rowSelection}
              columns={columns}
              dataSource={campaigns.data}
              scroll={{ x: 1300 }}
              loading={
                campaigns.loading ||
                deleteCampaignLoading ||
                archiveCampaignLoading ||
                unarchiveCampaignLoading ||
                exproducts.loading
              }
              pagination={
                !this.props.is_mobile
                  ? {
                      showTotal: (total) => `Total ${total} items`,
                      total: campaigns.total,
                      pageSize: 20,
                      current: campaigns.params.page,
                      showSizeChanger: false,
                      onChange: (page, perPage) =>
                        this.fetchData({
                          ...campaigns.params,
                          page:
                            page === Number.NEGATIVE_INFINITY
                              ? 1
                              : page === 0
                              ? 1
                              : page,
                          perPage,
                        }),
                    }
                  : false
              }
            />
          </div>
          {this.props.is_mobile && (
            <div className="d-flex justify-content-end align-items-center py-3 px-3">
              <div>
                <Pagination
                  size="small"
                  className="pagination"
                  total={campaigns.total}
                  defaultCurrent={campaigns?.params?.page || 1}
                  pageSize={20}
                  onChange={(page, perPage) =>
                    this.fetchData({
                      ...campaigns.params,
                      page:
                        page === Number.NEGATIVE_INFINITY
                          ? 1
                          : page === 0
                          ? 1
                          : page,
                      perPage,
                    })
                  }
                />
              </div>
            </div>
          )}
        </>
      </Wrapper>
    );
  }
}

export default React.memo(
  connect(
    (state) => ({
      campaigns: state.campaign.list.list,
      count: state.campaign.list.count,
      deleteCampaignLoading: state.campaign.list.delete.loading,
      unarchiveCampaignLoading: state.campaign.list.unarchive.loading,
      archiveCampaignLoading: state.campaign.list.archive.loading,
      profile: state.auth.info.profile,
      exproducts: state.mockup.create.exproducts,
    }),
    (dispatch) => ({
      getList: (options) => {
        dispatch(listCampaigns(options));
      },
      getListExproduct: (params) => {
        dispatch(getListExproduct(params));
      },
      deleteCampaigns: (data, params) => {
        dispatch(deleteCampaigns(data, params));
      },
      archiveCampaigns: (data, params) => {
        dispatch(archiveCampaigns(data, params));
      },
      unarchiveCampaigns: (data, params) => {
        dispatch(unarchiveCampaigns(data, params));
      },
      syncCampaigns: (data, params) => {
        dispatch(syncCampaigns(data, params));
      },
      previewCampaigns: (data, params) => {
        dispatch(previewCampaigns(data, params));
      },
      countCampaigns: () => {
        dispatch(countCampaigns());
      },
      previewModalProduct: () => {
        dispatch(previewModalProduct());
      },
    })
  )(List)
);
