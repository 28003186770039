import React, { useState } from 'react';
import UploadForm from './UploadForm';
import { connect, useDispatch } from 'react-redux';
import { arrayRemoveAll, reset } from 'redux-form';
import { sendRequestTopUp } from 'scenes/TopUp/actions';
import { getList } from 'scenes/Analytics/actions';
import {
  exportTransaction,
  getListQuickView,
  getProfitTransaction,
} from 'scenes/Transactions/scenes/List/actions';
import { Modal } from 'antd';
import { GlobalStyle, StyledModal } from './styled';

const ModalTopUp = ({
  profile,
  sendRequestTopUp,
  resetTopupStep1Form,
  isOpenModal = false,
  notiTopup,
  uploadImage = () => { },
  closeModal = () => { },
  fetchData = () => { },
}) => {
  const [initStateTopup, setInitStateTopup] = useState({
    amount: '',
    paymentId: '',
    type: 1,
    image: '',
  });
  const dispatch = useDispatch();
  const handleOk = () => {
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
    dispatch(arrayRemoveAll("FormUploadImage", 'listImages'));
    fetchData({ page: 1, perPage: 50 });
  };

  const submitHandler = (values) => {
    const request2Send = {
      amount: parseFloat(values?.amount),
      paymentId: values?.paymentId,
      type: values?.type,
      image: values?.image,
    };
    setInitStateTopup(request2Send);
    sendRequestTopUp({ ...request2Send, check: 1 });
  };
  return (
    <>
      <StyledModal
        title={
          <div>Upload new image(s)</div>
        }
        visible={isOpenModal}
        onOk={handleOk}
        onCancel={handleCancel}
        className="topup-modal"
        footer={false}
        maskClosable={false}
        destroyOnClose
      >
        <UploadForm
          onSubmit={submitHandler}
          initialValues={initStateTopup || ''}
          profile={profile}
          notiTopup={notiTopup}
          closeModal={closeModal}
          uploadImage={uploadImage}
          fetchData={fetchData}
        />
      </StyledModal>
      <GlobalStyle />
    </>
  );
};


export default connect(
  (state) => ({
    // transactionsQuickView: state.transaction.list.transactions,
    transactions: state.transaction.list.transactions,
    exportLoading: state.transaction.list.export.loading,
    profitTransaction: state.transaction.list.profits,
    filterTransactionValues: state.form.formFilterTransaction?.values,
    valueTopUpStep1: state.form.Form,
    profile: state.auth.info.profile,
    statusVerify: state.topup.requestTopUp.statusVerify,
    notiTopup: state.topup.requestTopUp.notiTopup,
  }),
  (dispatch) => ({
    getList: (params) => {
      dispatch(getList(params));
    },
    getListQuickView: (id) => {
      dispatch(getListQuickView(id));
    },
    getProfitTransaction: () => {
      dispatch(getProfitTransaction());
    },
    exportTransactions: (params) => {
      dispatch(exportTransaction(params));
    },
    sendRequestTopUp: (dataToRequest) => {
      dispatch(sendRequestTopUp(dataToRequest));
    },
    resetTopupStep1Form: () => {
      dispatch(reset('TopUpStep1'));
    },
  })
)(ModalTopUp);
