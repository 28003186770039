import axios from "utils/request";

const prefix = "/userlabel";

export const listUserLable = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}`,
    is_json_content_type: true,
  });
};

export const createUserlabel = (data) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}`,
    is_json_content_type: true,
  });
};
export const detailUserlabel = (id) => {
  return axios({
    method: "GET",
    url: `${prefix}/${id}`,
  });
};

export const updateUserlabel = (id, data) => {
  return axios({
    method: "PUT",
    data,
    url: `${prefix}/${id}`,
    is_json_content_type: true,
  });
};

export const destroyUserlabel = (id) => {
  return axios({
    method: "DELETE",
    url: `${prefix}/${id}`,
  });
};

export const checkIfCaliforniaUserLabelExisted = () => {
  return axios({
    method: 'GET',
    url: `${prefix}/california-userlabel-existed`
  })
}