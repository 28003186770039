import { GRAY_300, GRAY_500, SHADOW_XS } from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 32px;
  line-height: 32px;

  .title {
    font-weight: 500;
    font-size: 16px;
    width: 340px;
    max-width: 100%;
    color: ${GRAY_500};
  }

  .title-2 {
    flex-wrap: nowrap;
    width: calc(100% - 340px);
  }
`;
