import styled, { createGlobalStyle } from 'styled-components';
import {
  BASE_WHITE,
  ERROR_600,
  GRAY_200,
  GRAY_50,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_200,
  PRIMARY_25,
  PRIMARY_50,
  PRIMARY_500,
  PRIMARY_600,
  SHADOW_LG_0_03,
  SHADOW_LG_0_08,
  SHADOW_SM_0_06,
  SHADOW_SM_0_1,
  WARNING_25,
  WARNING_300,
  WARNING_600,
  WARNING_700,
} from 'components/colors';
import PageContainer from 'components/PageContainer';

export const Wrapper = styled(PageContainer)`
  svg {
    &.plusicon {
      width: 20px;
      height: 20px;
      stroke: ${BASE_WHITE};
      /* margin-right: 12px; */
    }
  }
  .no-billing-info-warning {
    margin-bottom: 16px;
  }
  img.svg.lian {
    width: 46px;
  }
  div.header {
    margin-bottom: 16px;
  }
  .ctas {
    button {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      gap: 8px;
    }
   
  }
  .missing-info {
    color: ${ERROR_600};
    font-size: 14px;
    font-weight: 400px;
  }
  h4.header {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${GRAY_900};
    margin-bottom: 4px;
  }
  p.description {
    margin: 0;
  }

  .warning {
    background: ${WARNING_25};
    border: 1px solid ${WARNING_300};
    border-radius: 12px;
    padding: 18px;
    padding-left: 48px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${WARNING_700};

    position: relative;

    margin-bottom: 20px;

    a {
      color: ${PRIMARY_500};
      font-weight: 600;
    }

    svg {
      stroke: ${WARNING_600};
      position: absolute;
      top: 18px;
      left: 18px;
      width: 18px;
      height: 18px;
    }
  }
  .table .ant-table-container table {
    background: ${BASE_WHITE};
    border-radius: 8px;
    border: 1px solid ${GRAY_200};
    box-shadow: 0px 1px 3px ${SHADOW_SM_0_1}, 0px 1px 2px ${SHADOW_SM_0_06};
    border-radius: 12px;
    overflow: hidden;
    thead th {
      border-bottom: 1px solid ${PRIMARY_200};
      background-color: ${PRIMARY_50};

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${PRIMARY_600};

      padding: 12px 24px;
    }
    tbody td {
      padding: 20px 24px;
      vertical-align: middle;
      p {
        margin: 0;
        padding: 0;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${GRAY_600};
      }
      p.title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: ${GRAY_900};
      }
      img.svg,
      svg {
        margin-right: 12px;
      }

      svg.dummyaction,
      svg.context-menu {
        margin-right: 0;
      }
    }
  }

  .table {
    &.mb32 {
      margin-bottom: 32px;
    }
    &.mb12 {
      margin-bottom: 12px;
    }
    &.list .ant-table-container table {
      thead th {
        color: ${GRAY_600};
        background-color: ${GRAY_50};
        border-bottom: 1px solid ${GRAY_200};
      }
    }
    &.primary .ant-table-container table {
      tbody td {
        background: ${PRIMARY_25};
      }
    }
    svg.context-menu {
      cursor: pointer;
      stroke: ${GRAY_600};

      &.ant-dropdown-open,
      &:hover {
        stroke: ${PRIMARY_500};
      }
    }
    td,
    th {
      &:nth-child(1) {
        width: 279px;
      }
      &:nth-child(3) {
        width: 240px;
      }
      &:nth-child(4) {
        width: 50px;
      }
    }
  }
`;

export const WrapperLogs = styled.div`
  margin: 32px;
  margin-top: 0px;
  /* background: ${BASE_WHITE}; */
  /* border: 1px solid ${GRAY_200};
  box-shadow: 0px 1px 3px ${SHADOW_SM_0_1}, 0px 1px 2px ${SHADOW_SM_0_06};
  border-radius: 16px; */

  .ant-collapse.ant-collapse-icon-position-left {
    border: transparent;
    border-radius: 16px;
  }
 
  .clol {
    .ant-collapse {
      &-item {
        overflow: hidden;
        border-bottom: none;
        &-active {
          border-bottom: 1px solid #d9d9d9;
        }
      }
    }
  }
`;

export const WrapperModal = styled.span``;
export const GlobalStyle = createGlobalStyle`
  .ant-modal-content{
    border-radius: 16px;
  }
  .ant-modal-header{
    border-radius: 16px;
  }
  .ant-modal-title{
    font-weight: 600;
  }
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const ContextMenuWrapper = styled.div`
  background-color: red;

  background: ${BASE_WHITE};
  border: 1px solid ${GRAY_200};
  box-shadow: 0px 12px 16px -4px ${SHADOW_LG_0_08},
    0px 4px 6px -2px ${SHADOW_LG_0_03};
  border-radius: 8px;
  padding: 16px;
  a {
    display: block;
    width: 100%;
    margin-bottom: 22px;
    min-width: 200px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_700};

    &:hover {
      color: ${PRIMARY_500};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const GlobalStylesheet = createGlobalStyle`
  .ant-tooltip {
    max-width: unset;
  }
`;
