import React, { useEffect } from 'react';
import { Wrapper } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getListAtRiskAction } from './actions';
import RenderOptimizedTable from 'utils/Form/RenderOptimizedTable';
import RenderOrderStatus from 'utils/Form/RenderOrderStatus';
import { getOptionLabel, options_payment_status } from 'utils/options';
import getMomentInstance from 'utils/moment';
import moment from 'moment';
import RenderTotalOrder from 'utils/Form/RenderTotalOrder';

export const columns = [
  {
    title: 'Order',
    dataIndex: 'name',
    align: 'left',
    key: 'name',
    width: 200,
    render: (value, record) => (
      <Link to={`/orders/${record.id}`}>
        {value || `#${record.id}`}
      </Link>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 140,
    shouldCellUpdate: (record, prevRecord) =>
      JSON.stringify(record) !== JSON.stringify(prevRecord),
    render: (text, record) => <RenderOrderStatus order={record} />,
  },
  {
    title: 'Payment Status',
    dataIndex: 'paymentStatus',
    key: 'paymentStatus',
    width: 140,
    shouldCellUpdate: (record, prevRecord) =>
      JSON.stringify(record) !== JSON.stringify(prevRecord),
    render: (text, record) =>
      getOptionLabel(options_payment_status, text, false),
  },
  {
    title: 'Customer name',
    dataIndex: 'cusName',
    key: 'customer_name',
    shouldCellUpdate: (record, prevRecord) =>
      JSON.stringify(record) !== JSON.stringify(prevRecord),
    render: (text) => {
      return text;
    },
  },
  {
    title: 'Created date',
    dataIndex: 'createdAt',
    key: 'createdAt ',
    shouldCellUpdate: (record, prevRecord) =>
      JSON.stringify(record) !== JSON.stringify(prevRecord),
    render: (data, record) =>
      data
        ? getMomentInstance(
          null,
          moment(data, 'YYYY-MM-DDTHH:mm:ssZ').unix(),
          true
        )
        : 'N/A',
  },
  {
    title: 'Approved date',
    dataIndex: 'approvedAt',
    key: 'approvedAt ',
    shouldCellUpdate: (record, prevRecord) =>
      JSON.stringify(record) !== JSON.stringify(prevRecord),
    render: (text, record) =>
      text ? getMomentInstance(undefined, text, true) : 'N/A',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    align: 'right',
    shouldCellUpdate: (record, prevRecord) =>
      JSON.stringify(record) !== JSON.stringify(prevRecord),
    render: (text, record) => <RenderTotalOrder order={record} />,
  },
];

const OrderAtRisk = ({ pageName }) => {
  const dispatch = useDispatch();
  const data = useSelector(s => s?.order?.risk);
  const { list, loading } = data;

  useEffect(() => {
    dispatch(getListAtRiskAction());
  }, []);


  return (
    <Wrapper
      pageName={pageName}
    >
      <RenderOptimizedTable
        rowKey="id"
        size="small"
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={false}
        sticky
      />
    </Wrapper>
  );
};

export default OrderAtRisk;