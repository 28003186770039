import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validate';
import { Spin, Tooltip } from 'antd';
import { BASE_WHITE, GRAY_300, SHADOW_XS } from 'components/colors';
import styled from 'styled-components';
import { GMButton } from 'components/Button';
import { GMSelect, GMTextbox } from 'components/InputFields';
import { ReactComponent as Infor } from 'images/icons/info-circle.svg';
import GMNotify from 'components/Notification';
import { renderRadioAnt } from 'utils/Form';
import GMStateSelector from 'components/GMStateSelector';
import {
  shippingServive,
  shippingServiveForCanvas,
} from 'scenes/Orders/scenes/Create/components/FormCreateOrder/utils';
const InternalWrapper = styled.form`
  .ant-input-affix-wrapper,
  .ant-input-number.ant-input-number-lg,
  textarea.ant-input.ant-input-lg,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .sp .ant-input-wrapper.ant-input-group,
  .ant-picker {
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px !important;
  }
  .sp .ant-input-wrapper.ant-input-group {
    overflow: hidden;
    .ant-input-affix-wrapper {
      border: none;
    }
    .ant-input-search-button {
      border: none;
    }
  }
  .ctas {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    margin-top: 24px;
  }
  .ant-radio-wrapper {
    padding-top: 20px;
  }
`;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUS: false,
      lisCountryUS: [],
    };
  }
  render() {
    const {
      handleSubmit,
      onCancel,
      countries,
      checkCanvas,
      updateAddressLoading,
      checkAddress,
      orderNewUpdate,
      orderId,
      detail,
      countryId,
      listCountryAvalibleCanvasIt,
      state,
      city,
      address01,
      address02,
      reasonOnHold,
      country,
    } = this.props;
    let domesticCountry = [];
    if (detail?.country?.id === 231) {
      this.setState({ isUS: true });
    }
    // Check domestic or international
    if (detail?.data.country?.id === 231 || detail?.data.country?.id === 177) {
      let a = countries?.data.filter((item) => {
        return item?.countryIsoCode === 'US' || item?.countryIsoCode === 'PR';
      });
      domesticCountry = a;
    } else {
      let a = countries?.data.filter((item) => {
        return item?.countryIsoCode !== 'US' && item?.countryIsoCode !== 'PR';
      });
      domesticCountry = a;
    }

    const [dV, list, errorNotification] = shippingServive({
      country,
      state,
      city,
      address01,
      address02,
      checkCanvas,
      listCountryAvalibleCanvasIt,
    });

    return (
      <InternalWrapper onSubmit={handleSubmit}>
        <Spin spinning={countries.loading || updateAddressLoading}>
          <div className="row">
            <div className="col-12">
              <h4 className="h4 mb-3">Edit shipping information</h4>
              {reasonOnHold === 9 ? (
                ''
              ) : (
                <div>
                  {orderNewUpdate?.ord_id == orderId &&
                    checkAddress?.valid === false && (
                      <GMNotify
                        type="infor"
                        title={
                          <div className="title">
                            Address can not be verified
                          </div>
                        }
                        desc={checkAddress?.original_response?.errors?.map(
                          (err) => {
                            return (
                              <div className="desc">
                                <li>{err?.message}</li>
                              </div>
                            );
                          }
                        )}
                      />
                    )}
                  <div className="row mt-2">
                    <div className="col-sm-6">
                      <div className="form-group mb-1">
                        <label className="col-form-label">Full name</label>
                        <GMTextbox
                          name="cusName"
                          placeholder="Enter Full name"
                          showError
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-1">
                        <label className="col-form-label">Email</label>
                        <GMTextbox
                          name="cusEmail"
                          placeholder="Enter email"
                          showError
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-1">
                        <label className="col-form-label">
                          Phone number{' '}
                          <span className="text-muted">(Optional)</span>
                        </label>
                        <GMTextbox
                          name="cusPhone"
                          placeholder="Enter phone number"
                          maxLength={'30'}
                          showError
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-1 sp">
                        <label className="col-form-label">Address 1</label>
                        <GMTextbox
                          name="cusAddress"
                          placeholder="Enter address 1"
                          showError
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-1">
                        <label className="col-form-label">
                          Address 2{' '}
                          <span className="text-muted">(Optional)</span>
                        </label>
                        <GMTextbox
                          name="cusAddress2"
                          placeholder="Enter address 2"
                          showError
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-1">
                        <label className="col-form-label">City</label>
                        <GMTextbox
                          name="cusCity"
                          placeholder="Enter city"
                          showError
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-1">
                        <label className="col-form-label">State/Province</label>
                        <GMStateSelector
                          name="cusProvince"
                          placeholder="Enter state/province"
                          country={
                            country + '' === '231'
                              ? 'us'
                              : countries?.data?.find(
                                  (c) => c?.id + '' === country + ''
                                )?.countryIsoCode
                          }
                          showError
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-1">
                        <label className="col-form-label">
                          Zip code/Postal code
                        </label>
                        <GMTextbox
                          name="cusPostalCode"
                          placeholder="Enter zip code/postal code"
                          showError
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-1">
                        <label className="col-form-label">Country</label>
                        <GMSelect
                          name="cusCountry"
                          showError
                          onChange={() => {
                            this.props.dispatch(
                              change('FormEditAddress', 'cusProvince', '')
                            );
                          }}
                          options={[
                            { value: 0, label: 'Select a country' },
                            ...countries?.data?.map((item) => ({
                              value: item.id,
                              label: item.countryName,
                            })),
                          ]}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-form-label">
                        IOSS number
                        <Tooltip
                          title={
                            <div>
                              Provide your IOSS number and the value of the
                              order generated on your e-commerce platform to
                              avoid additional tax charges on this order.{' '}
                              <a href="https://help.gearment.com/en-us/article/sales-tax-rtp0rj/">
                                Click here
                              </a>{' '}
                              for more details.
                            </div>
                          }
                          placement="right"
                        >
                          <Infor
                            className="inforIcon ml-1"
                            style={{
                              stroke: '#98A2B3',
                              width: '15px',
                              height: '15px',
                            }}
                          />
                        </Tooltip>
                      </label>
                      <GMTextbox
                        name="iossNumber"
                        maxLength={'50'}
                        placeholder="Enter IOSS number"
                        showError
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-form-label">Order value (€)</label>
                      <GMTextbox
                        name="iossNumberTotal"
                        maxLength={'50'}
                        placeholder="Enter order value (€)"
                        showError
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-form-label">
                        Tax number{' '}
                        <Tooltip
                          title={
                            <div>
                              Provide your tax number number and the value of
                              the order generated on your e-commerce platform to
                              avoid additional tax charges on this order.{' '}
                              <a href="https://help.gearment.com/en-us/article/sales-tax-rtp0rj/">
                                Click here
                              </a>{' '}
                              for more details.
                            </div>
                          }
                          placement="right"
                        >
                          <Infor
                            className="inforIcon ml-1"
                            style={{
                              stroke: '#98A2B3',
                              width: '15px',
                              height: '15px',
                            }}
                          />
                        </Tooltip>
                      </label>
                      <GMTextbox
                        name="buyerTaxNumber"
                        maxLength={'50'}
                        placeholder="Enter tax number"
                        showError
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-form-label">
                        Order tax value ($)
                      </label>
                      <GMTextbox
                        name="buyerTaxAmount"
                        maxLength={'50'}
                        placeholder="Enter order tax value ($)"
                        showError
                      />
                    </div>
                  </div>
                </div>
              )}

              <label className="col-form-label">Shipping service</label>
              <div className="row col-12">
                <div className="form-group mb-1">
                  {address01 && (
                    <div>
                      {errorNotification || (
                        <GMTextbox
                          className="shippingMethod"
                          name="shippingMethod"
                          component={renderRadioAnt}
                          defaultValue={dV}
                          options={list}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {reasonOnHold === 9 ? (
                <div className="ctas">
                  <GMButton
                    color="SecondaryColor"
                    type="button"
                    onClick={onCancel}
                    style={{ width: 150 }}
                  >
                    Cancel
                  </GMButton>
                  <GMButton>Update shipping service</GMButton>
                </div>
              ) : (
                <div className="ctas">
                  <GMButton
                    color="SecondaryColor"
                    type="button"
                    onClick={onCancel}
                    style={{ width: 150 }}
                  >
                    Cancel
                  </GMButton>
                  <GMButton>Save and verify address</GMButton>
                </div>
              )}
            </div>
          </div>
        </Spin>
      </InternalWrapper>
    );
  }
}

const selector = formValueSelector('FormEditAddress');
export default connect(
  (state) => ({
    address01: selector(state, 'cusAddress') || '',
    address02: selector(state, 'cusAddress2') || '',
    cusPostalCode: selector(state, 'cusPostalCode') || '',
    state: selector(state, 'cusProvince') || '',
    city: selector(state, 'cusCity') || '',
    country: selector(state, 'cusCountry') || '',
  }),
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change('FormEditAddress', field, value));
    },
    shouldValidate: () => true,

  })
)(
  reduxForm({
    form: 'FormEditAddress',
    enableReinitialize: true,
    validate,
  })(index)
);
