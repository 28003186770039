  import styled from "styled-components";
import {
  BASE_WHITE,
  ERROR_300,
  GRAY_300,
  GRAY_400,
  GRAY_50,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_300,
  SHADOW_XS
} from "components/colors";
import { GMInput, GMInputWrapper } from "./styled";

export default styled(GMInput)`
  width: 100%;
  input {
    height: 44px;
    line-height: 44px;
    padding: 0 14px;
    outline: none;
    width: 100%;
    
    color: ${GRAY_900};
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    &:placeholder  {
      color: ${GRAY_400};
    }
    &:focus-visible,
    &:focus {
      outline: none;
      border: 1px solid ${PRIMARY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    }
    &:disabled {
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    }
  }
  input.error {
    border: 1px solid ${ERROR_300};
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
    height: 44px;
    line-height: 44px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .ant-select-selection-item {
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    line-height: 44px;
    padding: 0 14px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    input {
      box-shadow: none;
    }
  }
  .ant-select-single .ant-select-selector,
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    &:focus-within {
      outline: none;
      /* border: 1px solid ${PRIMARY_300}; */
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
      border-radius: 8px;
    }
  }
`;

