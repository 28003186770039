import { takeLatest, call, put, all} from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import { listInvoiceSetting, updateAutoCharge } from "apis/customer_config";
function* getListCustomerConfigSaga() {
  try {
    const response = yield call(listInvoiceSetting);
    if (response.code === "success") {
      yield put({ type: TYPE.LIST_CUSTOMER_CONFIG.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_CUSTOMER_CONFIG.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_CUSTOMER_CONFIG.ERROR, error })]);
  }
}

function* updateAutoChargesSaga(action) {
  try {
    const { dataToUpdateAutoCharge } = action;
    const response = yield call(updateAutoCharge, dataToUpdateAutoCharge);

    if (response.code === "success") {
      yield all([put({ type: TYPE.UPDATE_AUTO_CHARGE.SUCCESS, response })]);
    } else {
      yield put({ type: TYPE.UPDATE_AUTO_CHARGE.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.UPDATE_AUTO_CHARGE.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(
    TYPE.LIST_CUSTOMER_CONFIG.REQUEST,
    getListCustomerConfigSaga
  );
  yield takeLatest(TYPE.UPDATE_AUTO_CHARGE.REQUEST, updateAutoChargesSaga);
}

export default watcher;
