import { actionType as TYPE } from "./actions";
import { notification } from "antd";
const initialState = {
  reportOrderList: {
    data: [],
    loading: false,
    params: {},
  },
  exportProductSell: {
    loading: false,
    params: {},
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        reportOrderList: {
          ...initialState.reportOrderList,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        reportOrderList: {
          ...state.reportOrderList,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        reportOrderList: {
          ...state.reportOrderList,
          loading: false,
        },
      };
    //Export product sell
    case TYPE.EXPORT_PRODUCT_SELL.REQUEST:
      return {
        ...state,
        exportProductSell: {
          ...state.exportProductSell,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.EXPORT_PRODUCT_SELL.SUCCESS:
      notification.success({
        message: "Export products successfully.",
        duration: 5,
      });
      if (action.data && action.data.url) {
        window.open(action.data.url);
      }
      return {
        ...state,
        exportProductSell: {
          ...state.exportProductSell,
          loading: false,
        },
      };
    case TYPE.EXPORT_PRODUCT_SELL.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Export products failed.",
        duration: 5,
      });
      return {
        ...state,
        exportProductSell: {
          ...state.exportProductSell,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
