import React from "react";
import { GMButton } from "components/Button";
import InfoStyled from "components/Modal/ConfirmDialog/InfoStyled";
import { exportAll } from "apis/image_design";
import { useState } from "react";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { InlineP } from "./styled";

export default ({ show }) => {
  const [isShow, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const email = useSelector((s) => s.auth?.info?.profile?.email);
  const callExportAll = async () => {
    setLoading(true);
    const rs = (await exportAll()) || [];
    // await new Promise((r) => {
    //   setTimeout(() => r("ok"), 2000);
    // });
    // const rs = {
    //   code: "success",
    //   msg: "Request successful",
    //   clientMsg: "Sent export request is success!",
    //   data: "Sent export request is success!"
    // };
    setLoading(false);
    if (rs.code === "success") {
      setShow(false);
      return notification.success({
        message:
          "Your request is being processed. It may take a few minutes to complete, we will notify you when it done.",
        duration: 5
      });
    }
    const errorList = rs
      ?.filter((r) => r.code === "error")
      ?.map((r) => <InlineP>{r.msg}</InlineP>);
    if (errorList.length > 0) {
      return notification.error({
        message: errorList,
        duration: 5
      });
    }
  };
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };
  if (!email) {
    return null;
  }
  return (
    <>
      <GMButton className="cta-upload" type="button" onClick={showModal}>
        Export all
      </GMButton>
      {isShow ? (
        <InfoStyled
          onCancel={hideModal}
          onOk={callExportAll}
          isLoading={isLoading}
          text={{
            ok: "Export",
            cancel: "Cancel",
            content: (
              <>
                The exported file including all designs' URLs will be in CSV
                format and sent to your email <b>{email}</b> once the process is
                completed.
              </>
            ),
            header: "Export all the designs?"
          }}
        />
      ) : null}
    </>
  );
};
