import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { change, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { validateUpdate } from "./validate";
import { GMButton } from "components/Button";
import { GMSelect, GMTextbox } from "components/InputFields";
import { getOptionLabel, getOptionLabel2, issue_priority_options, issue_solution_options, methodShipping, options_platform_type_issue } from "utils/options";
import renderLink from "utils/Form/renderLink";
import FieldNumber from "utils/fields/FieldNumber";

const UpdateIssueForm = ({
  handleSubmit,
  initialValues,
  listIssueCategoryData,
}) => {
  const history = useHistory();
  return (
    <form onSubmit={handleSubmit}>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row gap32 t24 ln">
            <div className="fixed-cell-50p">
              <GMTextbox
                name="orderData.name"
                label="Order name"
                disabled
              />
            </div>
            <div className="fixed-cell-50p platform-info">
              <label className="platform-label">Order platform</label>
              <div className="w100p">
                {getOptionLabel(
                  options_platform_type_issue,
                  initialValues?.orderData?.platformId === 1 &&
                    initialValues?.orderData?.insertMethod === 0
                    ? 'import'
                    : initialValues?.orderData?.platformId
                )}
              </div>
            </div>
          </div>
          <div className="row gap32 t24 ln">
            <div className="fixed-cell-50p">
              <GMTextbox
                name="shippingMethod"
                label="Shipping service"
                defaultValue={getOptionLabel2({
                  options: methodShipping,
                  value: initialValues?.orderData?.shippingMethod,
                  isRaw: true,
                })}
                disabled
              />
            </div>
            <div className="fixed-cell-50p">
              <GMTextbox
                disabled
                label="Total price"
                defaultValue={'$' + initialValues?.orderData?.total}
                name={`price`}
              />
            </div>
          </div>
          <div className="row gap32 t24 ln">
            <div className="fixed-cell-50p">
              <GMSelect
                label="Priority"
                name={'priority'}
                disabled
                options={issue_priority_options}
                placeholder={"Choose priority"}
              />
            </div>
            <div className="fixed-cell-50p">
              <GMSelect
                name={`issueCategoryId`}
                label="Issue type"
                options={[
                  ...listIssueCategoryData.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
                placeholder={"Choose issue category"}
              />
            </div>
          </div>
          <div className="row gap32 t24 ln">
            <div className="fixed-cell-50p">
              <GMTextbox
                label="Effected item"
                placeholder="Effected item"
                name={'issueItemAffected'}
                component={FieldNumber}
                forGMInput
                min={1}
                max={99999}
              />
            </div>
            <div className="fixed-cell-50p">
              <GMSelect
                label="Solution"
                name={`solution`}
                showError
                options={issue_solution_options}
                placeholder={"Solution"}
              />
            </div>
          </div>
          <div className="row gap32 t24 ln">
            <div className="fixed-cell-100p">
              <GMTextbox
                label="Issue description"
                name={'detail'}
                textarea
                maxLength={500}
              />
            </div>
          </div>
          <div className="row links ln">
            <div className="w100p">
              <label className="col-form-label">Link</label>
              <div className="link input-group">
                <FieldArray name={'link'} component={renderLink} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="blk-right">
            <GMButton
              color="SecondaryGray"
              onClick={() => history.replace(`/orders/order-issues/${initialValues.id}`)}
              type="button"
            >
              Cancel
            </GMButton>
            <GMButton
              className="btn btn-primary"
            >
              Save
            </GMButton>
          </div>
        </div>
      </div>
    </form>
  );
}

const ReduxUpdateIssueForm = reduxForm({
  form: "formUpdateIssue",
  enableReinitialize: true,
  validate: validateUpdate,
})(UpdateIssueForm);
const selector = formValueSelector("formCreateIssue");

export default connect(
  (state) => ({
    listIssues: selector(state, "listIssues") || { link: [''] },
  }),
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change("formCreateIssue", field, value));
    },
    dispatch,
  })
)(ReduxUpdateIssueForm);
