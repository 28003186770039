import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { createAccountStaffAction, verifyStaffToken } from './actions';
import Form from './components/form';
import { Helmet } from 'react-helmet';
import { Wrapper } from '../Register/styled';

class Index extends Component {
  componentDidMount() {
    this.props.verifyStaffToken({
      verifyEmailToken: this.props.match.params.token,
    });
  }

  handleSubmitForm = (values) => {
    this.props.createAccountStaffAction({
      ...values,
      email:this.props.emailCus?.emailStaff,
      verifyEmailToken:this.props.match.params.token
    });
  };
  render() {
    const { loading, tokenValid,emailCus } = this.props;
    return (
      <Wrapper className="bg-white w-100">
        <Spin spinning={loading}>
          <Form
            tokenValid={tokenValid}
            verifyTokenloading={loading}
            onSubmit={this.handleSubmitForm}
            emailCus={emailCus}
            loading={loading}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.auth.verifyStaffEmail.loading,
    tokenValid: state.auth.verifyStaffEmail.verifiedStaffToken.tokenValid,
    emailCus: state.auth.verifyStaffEmail.verifiedStaffToken.data,
  }),
  (dispatch) => ({
    createAccountStaffAction: (data) => {
      dispatch(createAccountStaffAction(data));
    },
    verifyStaffToken: (data) => {
      dispatch(verifyStaffToken(data));
    },
  })
)(Index);
