import {
  BASE_BLACK,
  BASE_WHITE,
  GRAY_200,
  GRAY_600} from "components/colors";
import styled, { keyframes } from "styled-components";
import ImgInitAction from "images/Promotion/Minigame/surprise-box/surprise-box_banner.png";
import MImgInitAction from "images/Promotion/Minigame/surprise-box/surprise-box_mobi_bg-tinh.png";
import ImgActionLoading from "images/Promotion/Minigame/surprise-box/surprise-box_banner-loading.gif";
import MImgActionLoading from "images/Promotion/Minigame/surprise-box/m_surprise-box_banner.gif";
import ImgCTA from "images/Promotion/Minigame/surprise-box/cta2.png";
import ImgGif from "images/Promotion/Minigame/surprise-box/gif.png";
import ImgEff from "images/Promotion/Minigame/surprise-box/eff.png";

const bounce1 = keyframes`
  70% { transform:translateY(0%); }
  80% { transform:translateY(-15%); }
  90% { transform:translateY(0%); }
  95% { transform:translateY(-7%); }
  97% { transform:translateY(0%); }
  99% { transform:translateY(-3%); }
  100% { transform:translateY(0); }
`;

const bounce2 = keyframes`
  0%   { transform: scale(1,1)      translateY(0); opacity: 0; }
  10%  { transform: scale(1.1,.9)   translateY(0); opacity: 1; }
  30%  { transform: scale(.9,1.1)   translateY(-100px); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-7px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
`;

export const AdsOverlay = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2147483631;
  background: ${BASE_BLACK};
  opacity: 0.2;
  font-size: 0;
`;

export const AdsOverlayEffect = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2147483632;
  font-size: 0;
  transition: background-position 5000ms;
  background-image: url(${ImgEff});
  background-position: 50% 101vh;
  background-repeat: no-repeat;
  background-size: cover;
  &.congr {
    background-position: 50% -150vh;
  }
`;

export const AdsWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2147483632;
  font-size: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  max-height: 100vh;
  .wrapper {
    position: relative;
    padding: 16px;
    object-fit: contain;
    box-sizing: border-box;
  }
  .inner-wrapper {
    position: relative;
    object-fit: contain;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    video {
      &.dvideo {
        width: 800px;
        display: block;
        @media (orientation: portrait) {
          display: none;
        }
      }
      &.mvideo {
        width: calc(100vw - 32px);
        display: none;
        @media (orientation: portrait) {
          display: block;
        }
      }
    }
  }
  .close-btn {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &::after {
      display: block;
      content: ' ';
      right: 0;
      top: 0;
      border-radius: 100px;
      border: 1px solid ${GRAY_200};
      background: ${BASE_WHITE};
      opacity: 1;
      z-index: 2;
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 100px;
    }
    &:hover::after {
      opacity: 0.8;
    }
    svg {
      stroke: ${GRAY_600};
      z-index: 3;
      width: 20px;
      height: 20px;
    }
  }

  .game-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    /* cursor: pointer; */
    background-image: url(${ImgInitAction});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (orientation: portrait) {
      background-image: url(${MImgInitAction});
    }
    .gif {
      background-size: contain;
      width: 500px;
      height: 500px;
      display: none;
      background-image: url(${ImgGif});
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    &.loading {
      background-image: url(${ImgActionLoading});
      @media (orientation: portrait) {
        background-image: url(${MImgActionLoading});
      }
      .cta {
        display: none;
      }
    }
    &.loaded {
      background: none;
      .cta {
        display: none;
      }
      .gif {
        display: block;
        animation-name: ${bounce2};
        animation-duration: 2s;
        animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
        animation-iteration-count: 1;
      }
    }
  }

  .video-overlay {

  }
`;
export const CTA = styled.div`
  background-image: url(${ImgCTA});
  width: 400px;
  height: 200px;
  margin-top: -290px;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  animation: ${bounce1} 2s ease infinite;
  @media (orientation: portrait) {
    margin-top: -198px;
    width: 230px;
    height: 140px;
  }
}
`