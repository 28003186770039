import React, { Component } from "react";
import { connect } from "react-redux";
import { Pagination, Table } from "antd";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { getStoreName, getPlatformForImportOrderProgress } from "utils/helper";
import getMomentInstance from "utils/moment";
import { import_progress_status, getOptionLabel } from "utils/options";
import { getListLogSyncOrderImport } from "scenes/Logs/actions";
import { Wrapper } from "scenes/Logs/styled";
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      showDetail: false,
      selected: null,
      initial_filter_values: {
        sortBy: "id",
        sortType: "desc",
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getListLogSyncOrderImport(params);
  };

  render() {
    const { import_progress, pageName } = this.props;
    const columns = [
      {
        title: <b>Id</b>,
        align: "center",
        dataIndex: "id",
        key: "id",
        render: (text) => (
          <Link to={`/logs/import-orders/${text}`}>#{text}</Link>
        ),
      },
      {
        title: <b>Store</b>,
        align: "center",
        dataIndex: "storeIntegration",
        key: "storeIntegration",
        render: (text, record) => {
          return getStoreName(record.storeIntegration || "") || "N/A";
        },
      },
      {
        title: <b>Platform</b>,
        align: "center",
        dataIndex: "type",
        key: "type",
        render: (text, record) => {
          return getPlatformForImportOrderProgress(text) || 'N/A';
        },
      },
      {
        title: <b>File import</b>,
        align: "center",
        dataIndex: "downloadLink",
        key: "downloadLink",
        render: (text, record) => {
          return (
            <a href={text} taget="_blank">
              Link download
            </a>
          );
        },
      },
      {
        title: <b>Status</b>,
        align: "center",
        dataIndex: "status",
        key: "status",
        render: (text) => {
          return getOptionLabel(import_progress_status, text);
        },
      },
      {
        title: <b>Time request</b>,
        align: "center",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => {
          return getMomentInstance(text, null, true);
        },
      },
    ];
    return (
      <Wrapper pageName={pageName}>
        <div>
          <div>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={import_progress.data}
              loading={import_progress.loading}
              scroll={{ x: 1000 }}
              pagination={!this.props.is_mobile ? {
                showTotal: (total) => `Total ${total} items`,
                total: import_progress.total,
                pageSize:20,
                current: import_progress.params.page,
                showSizeChanger: false,
                onChange: (page, perPage) =>
                  this.fetchData({ ...import_progress.params, page, perPage }),
              }: false}
            />
            {this.props.is_mobile && <div className="d-flex justify-content-end align-items-center py-3 px-3">
              <div>
                <Pagination
                  size="small"
                  showSizeChanger
                  className="pagination"
                  total={import_progress.total}
                  defaultCurrent={import_progress?.params?.page || 1}
                  pageSize={20}
                  onChange={(page, perPage) =>
                    this.fetchData({ ...import_progress.params, page: page === Number.NEGATIVE_INFINITY ? 1 : (page === 0 ? 1 : page), perPage })
                  }
                />
              </div>
            </div>}
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    import_progress: state.order.import_progress.import_progress,
  }),
  (dispatch) => ({
    getListLogSyncOrderImport: (options) => {
      dispatch(getListLogSyncOrderImport(options));
    },
  })
)(List);
