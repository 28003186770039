import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType } from "./actions";
import { verifyEmail,verifyTokenEmail } from "apis/auth";

function* verifyEmailSaga(action) {
  try {
    const { data } = action;
    const response = yield call(verifyEmail, data);
    if (response.code === "success") {
      yield all([put({ type: actionType.VERIFY_EMAIL.SUCCESS, ...response })]);
    } else {
      yield put({ type: actionType.VERIFY_EMAIL.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: actionType.VERIFY_EMAIL.ERROR, error })]);
  }
}

function* verifyTokenEmailSaga(action) {
  try {
    const { data } = action;
    const response = yield call(verifyTokenEmail, data);
    if (response.code === "success") {
      yield all([put({ type: actionType.VERIFY_TOKEN_EMAIL.SUCCESS, ...response })]);
    } else {
      yield put({ type: actionType.VERIFY_TOKEN_EMAIL.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: actionType.VERIFY_TOKEN_EMAIL.ERROR, error })]);
  }
}


function* watcher() {
  yield all([takeLatest(actionType.VERIFY_EMAIL.REQUEST, verifyEmailSaga)]);
  yield all([takeLatest(actionType.VERIFY_TOKEN_EMAIL.REQUEST, verifyTokenEmailSaga)]);

}

export default watcher;
