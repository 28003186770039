import {
  ERROR_600,
  GRAY_600,
  SUCCESS_600,
  WARNING_100,
  WARNING_50,
  WARNING_600,
} from 'components/colors';
import styled from 'styled-components';
import TYPES from './types';

export default styled.div`
  padding: 16px;
  max-width: 100%;
  ${(p) => TYPES[p.type || 'default']}
  svg.bullet {
    stroke: ${ERROR_600};
    width: 17px;
    height: 17px;
    margin-right: 17px;
  }

  div.icon {
    margin-right: 17px;
    width: 46px;
    height: 46px;
    line-height: 30px;
    background: ${WARNING_100};
    border: 6px solid ${WARNING_50};
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    svg {
      width: 20px;
      height: 20px;
      stroke: ${WARNING_600};
    }
  }
  div.desc {
    svg {
      stroke: ${GRAY_600};
      width: 17px;
      height: 17px;
      margin-right: 17px;
    }
  }
  div.success {
    svg {
      stroke: ${SUCCESS_600};
      width: 17px;
      height: 17px;
      margin-right: 17px;
    }
  }
`;
