import Sync_Orders from 'scenes/Logs/scenes/Sync_Orders';
import Sync_Tracking from 'scenes/Logs/scenes/Sync_Tracking';
import Import_Orders from 'scenes/Logs/scenes/Import_Orders';
import Sync_Product from './scenes/Sync_Product';
import Feed_Product from './scenes/Feed_Product';
import Export_Orders from './scenes/Export_Orders';
export default [
  {
    key: 'logs.sync-orders',
    name: 'Sync order logs',
    path: '/logs/sync-orders',
    component: Sync_Orders,
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'logs.sync-tracking',
    name: 'Sync tracking logs' ,
    path: '/logs/sync-tracking',
    component: Sync_Tracking,
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'logs.import-orders',
    name: 'Import order logs',
    path: '/logs/import-orders',
    component: Import_Orders,
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'logs.export-orders',
    name: 'Export orders',
    path: '/logs/export-orders',
    component: Export_Orders,
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'logs.sync_product',
    name: 'Sync product logs',
    path: '/logs/sync-product',
    component: Sync_Product,
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'logs.feed_product',
    name: 'Feed product logs',
    path: '/logs/feed-product',
    component: Feed_Product,
    icon: null,
    template: 'default',
    isPublic: true,
  },
];
