import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType } from "./actions";
import { replace } from "connected-react-router";
import { createAccountStaff, verifyStaffToken } from "apis/auth";

function* createAccountStaffSaga(action) {
  try {
    const { data } = action;
    const response = yield call(createAccountStaff, data);
    if (response.code === "success") {
      yield all([
        put({ type: actionType.CREATE_STAFF_ACCOUNT.SUCCESS, response }),
        put(replace("/auth/login")),
      ]);
    } else {
      yield put({ type: actionType.CREATE_STAFF_ACCOUNT.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: actionType.CREATE_STAFF_ACCOUNT.ERROR, error })]);
  }
}

function* verifyStaffTokenSaga(action) {
  try {
    const { data } = action;
    const response = yield call(verifyStaffToken, data);
    if (response.code === "success") {
      yield all([put({ type: actionType.VERIFY_STAFF_TOKEN.SUCCESS, ...response })]);
    } else {
      yield put({ type: actionType.VERIFY_STAFF_TOKEN.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: actionType.VERIFY_STAFF_TOKEN.ERROR, error })]);
  }
}

function* watcher() {
  yield all([takeLatest(actionType.CREATE_STAFF_ACCOUNT.REQUEST, createAccountStaffSaga)]);
  yield all([takeLatest(actionType.VERIFY_STAFF_TOKEN.REQUEST, verifyStaffTokenSaga)]);
}

export default watcher;
