import {
  BASE_WHITE,
  BLUE_600,
  GRAY_200,
  GRAY_300,
  GRAY_400,
  GRAY_500,
  GRAY_700,
  PRIMARY_50,
  SHADOW_SM_0_06,
  SHADOW_XS,
} from "components/colors";
import styled, { createGlobalStyle } from "styled-components";
import { Affix } from "antd";

const MENU_EXPANDED_WIDTH = '240px';
const MENU_TOGGLED_WIDTH = '72px';
const MENU_TRANSITION = 'all 300ms cubic-bezier(0.2, 0, 0, 1) 0s';

const textSize = '${textSize}';
export const Wrapper = styled.div`
  border-right: 1px solid ${GRAY_200};
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  height: 100vh;
  max-height: 100vh;
  width: ${MENU_EXPANDED_WIDTH};
  background-color: ${BASE_WHITE};
  transition: width 300ms cubic-bezier(0.2,0,0,1) 0s;
  li.hover > .menu-item-wrapper > .menu-item {
    background-color: ${PRIMARY_50};
    color: ${BLUE_600};
  }
  &.toggle {
    width: ${MENU_TOGGLED_WIDTH};
    .logo-wrapper {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    .menu-content {
      padding-left: 0;
      .menu-item {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
          display: none;
        }
        &-wrapper {
          position: relative;
          ul.sub {
            position: fixed;
            left: 57px;
            top: 0;
            display: none;
            width: 180px;
            background-color: ${BASE_WHITE};
            border-radius: 6px;
            z-index: 17;
            box-shadow: 0px 1px 3px ${SHADOW_XS}, 0px 1px 2px ${SHADOW_SM_0_06};
            li {
              align-items: flex-start;
            }
            &.show {
              max-height: 100%;
              display: block;
            }
            .menu-item {
              width: 100%;
              text-align: left;
              justify-content: flex-start;
              > span {
                font-size: ${textSize};
                display: inline-block;
              }
            }
          }
          .sub-indicator {
            display: none;
          }
        }
        span {
        }
        .dummy-icon {
          display: none;
          &.iconLog {
          }
        }
        &-wrapper.active > .menu-item,
        &.active,
        &:hover {
          .dummy-icon, svg {
            &.iconLog {
            }
          }
        }
      }
    }
  }
  .logo-wrapper {
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }
  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  li {
    /* padding-right: 12px; */
  }
  ul.sub {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 200ms ease-out;
    li:first-child {
      margin-top: 4px;
    }
  }
  #menu-scroll-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .scrollview {
      margin-right: 0!important;
      margin-bottom: 0!important;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 0 12px;
    cursor: pointer;
    position: relative;

    color: ${GRAY_700};
    font-size: ${textSize};
    font-style: normal;
    font-weight: 500;
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* padding-right: 12px; */
      &.open {
        ul.sub {
          max-height: 1000px;
          transition: max-height 200ms cubic-bezier(0.2, 0, 0, 1);
        }
        .sub-indicator {
          transform: rotate(180deg);
        }
      }
    }
    span {
      padding-left: 12px;
    }
    .dummy-icon, svg {
      display: inline-block;
      fill: ${GRAY_400};
      width: 24px;
      height: 24px;
      /* &.iconLog {
        stroke: none;
        fill: ${GRAY_500};
      } */
    }
    &-wrapper.active > .menu-item,
    &.active,
    &:hover {
      background-color: ${PRIMARY_50};
      color: ${BLUE_600};
      .dummy-icon, svg {
        fill: ${BLUE_600};
        /* &.iconLog {
          stroke: none;
          fill: ${BLUE_600};
        } */
      }
    }
    .sub-indicator {
      position: absolute;
      top: 50%;
      margin-top: -10px;
      right: 12px;
      font-size: 0;
      transition: all 200ms;
      svg {
        height: 20px;
        width: 20px;
      }
      /* transform: rotate(45deg); */
    }
  }
  .menu-content {
    padding: 0;
    padding-left: ${textSize};
    margin-top: 12px;
    overflow-y: hidden;
    height: 100%;
  }
  .menu-minimize {
    position: absolute;
    right: -14px;
    top: 80px;
    width: 28px;
    height: 28px;
    border: 1px solid ${GRAY_300};
    background-color: ${BASE_WHITE};
    box-shadow: 0px 1px 2px 0px ${SHADOW_XS};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      width: ${textSize};
      height: ${textSize};
      stroke: ${GRAY_700};
      transition: all 200ms cubic-bezier(0.2, 0, 0, 1);
    }
    &.toggle {
      svg {
        stroke: ${BLUE_600};
        transform: rotate(180deg);
      }
    }
    &:hover {
      border-color: ${BLUE_600};
      svg {
        stroke: ${BLUE_600};
      }
    }
  }
  .padding {
    padding: 0 12px;
    > ul {
      padding-bottom: 12px;
    }
  }
`;

export const SubMenu = styled.ul`
`;
export const MenuPlaceHolder = styled.div`
  position: relative;
  height: 100vh;
  transition: ${MENU_TRANSITION};
  width: ${MENU_EXPANDED_WIDTH};
  &.toggle {
    width: ${MENU_TOGGLED_WIDTH};
  }
`;

export const MenuAffix = styled.div`
  position: fixed;
  z-index: 16;
  left: 0;
  top: 0;
  transition: ${MENU_TRANSITION};
  &.mobile {
    overflow: hidden;
    max-width: 0;
    &.open {
      max-width: 100%;
    }
    .menu-minimize {
      display: none;
    }
  }
`;

export const MenuMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${BASE_WHITE};
  opacity: 0.2;
  z-index: 15;
`;
