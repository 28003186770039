import { isValidEmail } from "utils/helper";
const validate = (values) => {
    const errors = {};
    if(!values.email || values.email.replace(/\s+/g, "").length === 0){
        errors.email = "Please enter your email.";
    }else if(!isValidEmail(values.email)){
        errors.email = "Your email is invalid. Please check again.";
    }
    if(!values.firstName || values.firstName.replace(/\s+/g, "").length === 0){
        errors.firstName = 'Please enter your first name.';
    }
    if(!values.lastName || values.lastName.replace(/\s+/g, "").length === 0){
        errors.lastName = 'Please enter your last name.';
    }
    if(!values.terms || !Array.isArray(values.terms) || !(values.terms.includes(1) && values.terms.includes(2))){
        errors.terms = "You must agree to our Terms of Service and Privacy Policies before signing up to Gearment"
    }
    return errors;
};

export default validate;