import React, { useState, useEffect } from 'react';
import money from 'utils/money';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getList } from 'apis/ex_products';
import { TrendingProductWrapper } from 'scenes/Dashboard/styled';
import { ReactComponent as NextIcon } from 'images/icons/arrow-narrow-right.svg';
import { BREAKPOINTS } from 'components/const';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  centerPadding: '0',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: BREAKPOINTS[1360],
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 1,
      },
    },
    {
      breakpoint: BREAKPOINTS[1800],
      settings: {
        slidesToShow: 5,
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
  ],
};
function Index({ className }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getList({
      'with[0]': 'optionSizes',
      'with[1]': 'optionColors',
      perPage: 6,
      is_trending: 1,
    })
      .then(({ code, data }) => {
        setLoading(false);
        if (code === 'success') {
          setProducts(data?.exProducts || []);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    return () => {};
  }, []);
  if (loading) {
    return null;
  }
  return (
    <TrendingProductWrapper className={className}>
      <h3 className="title">
        Best sellers
        <a
          target="_blank"
          className="Skip-the-guide"
          href="https://gearment.com/catalog/apparel/"
          rel="noopener noreferrer"
        >
          View more <NextIcon />
        </a>
      </h3>
      <div className="inner-wrapper">
        {products.map((item) => (
          <div key={item.id} className="item">
            <img
              loading="lazy"
              className="w-100"
              src={item.avatar}
              style={{ backgroundColor: 'white' }}
              alt="avatar"
            />
            <div className="content">
              <div className="name">{item.brand}</div>
              {/* <div className="qdescription">
                {alts(item.optionSizes.length, 'Size')} • {alts(item.optionColors.length, 'Color')}
              </div> */}
            </div>
            <div className="price">
              <b>{money(item.basePrice)}</b>
            </div>
          </div>
        ))}
      </div>
    </TrendingProductWrapper>
  );
}

export default React.memo(Index);
