import { actionType as TYPE } from "./actions";
import {notification} from "antd";

const initialState = {
  userlableCreate: {
    data: [],
    params: {},
    loading: false,
  },
  listStoreUserlabel: {
    data: [],
    params: {},
    loading: false,
  },
};

function PostCreateReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST_USERLABEL_CREATE.REQUEST:
      return {
        ...state,
        userlableCreate: {
          ...state.userlableCreate,
          loading: true
        }
      };
    case TYPE.LIST_USERLABEL_CREATE.SUCCESS:
      notification.success({
        message: "Create return address successfully.",
        duration: 5
      });
      return {
        ...state,
        userlableCreate: {
          ...state.userlableCreate,
          loading: false
        }
      };
    case TYPE.LIST_USERLABEL_CREATE.ERROR:
      notification.warn({
        message: action.error && action.error[0] && action.error[0]['msg'] ? action.error[0]['msg'] : 'Create return address failed.',
        duration: 5
      });
      return {
        ...state,
        userlableCreate: {
          ...state.userlableCreate,
          loading: false
        }
      };
      //List store user label
      case TYPE.LIST_STORE_USERLABEL.REQUEST:
        return {
          ...state,
          listStoreUserlabel: {
            ...state.listStoreUserlabel,
            loading: true,
          },
        };
      case TYPE.LIST_STORE_USERLABEL.SUCCESS:
        return {
          ...state,
          listStoreUserlabel: {
            ...state.listStoreUserlabel,
            loading: false,
            data: action?.data?.stores || [],
          },
        };
      case TYPE.LIST_STORE_USERLABEL.ERROR:
        return {
          ...state,
          listStoreUserlabel: {
            ...state.listStoreUserlabel,
            loading: false,
          },
        };
    default:
      return state;
  }
}

export default PostCreateReducer;
