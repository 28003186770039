import { GRAY_100, GRAY_600, PRIMARY_500 } from 'components/colors';
import styled from 'styled-components';

export default styled.div`
  .ant-radio {
    display: none;
  }
  .payment-method {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 18%;
    height: 52px;
    background: ${GRAY_100};
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: 1px solid transparent;
    float: left;
    &:last-child {
      margin-right: 0;
    }
    &.checked {
      border: 1px solid #169dd8;
    }
  }
  .ant-radio-wrapper.ant-radio-wrapper-disabled.payment-method {
    pointer-events: none;
      filter: grayscale(1);
      svg {
        opacity: 0.3;
      }
  }
`;
