export const actionType = {
  LIST_PRODUCT_TO_CLONE: {
    REQUEST: "CAMPAIGN.LIST_PRODUCT_TO_CLONE.REQUEST",
    SUCCESS: "CAMPAIGN.LIST_PRODUCT_TO_CLONE.SUCCESS",
    ERROR: "CAMPAIGN.LIST_PRODUCT_TO_CLONE.ERROR",
  },
  PREVIEW_CLONE: {
    REQUEST: "CAMPAIGN.PREVIEW.CLONE.REQUEST",
    SUCCESS: "CAMPAIGN.PREVIEW.CLONE.SUCCESS",
    ERROR: "CAMPAIGN.PREVIEW.CLONE.ERROR",
  },
};

export function listProductToClone(id) {
  return {
    type: actionType.LIST_PRODUCT_TO_CLONE.REQUEST,
    id,
  };
}
export function previewCloneCampaign(dataToPreviewCampaign) {
  return {
    type: actionType.PREVIEW_CLONE.REQUEST,
    dataToPreviewCampaign,
  };
}
