export const actionType = {
    LIST_CAMPAIGN_TO_PREVIEW: {
        REQUEST: 'CAMPAIGN.LIST_CAMPAIGN_TO_PREVIEW.REQUEST',
        SUCCESS: 'CAMPAIGN.LIST_CAMPAIGN_TO_PREVIEW.SUCCESS',
        ERROR: 'CAMPAIGN.LIST_CAMPAIGN_TO_PREVIEW.ERROR',
    }
};

export function listCampaignToPreview(listCampaignIds){
    return {
        type: actionType.LIST_CAMPAIGN_TO_PREVIEW.REQUEST,
        listCampaignIds
    }
}
