const validate = (values) => {
  const errors = {};
  if (!values.showName || values.showName.replace(/\s+/g, "").length === 0) {
    errors.showName = "This field cannot be left blank";
  }
  if (!values.storeApiKey || values.storeApiKey.replace(/\s+/g, "").length === 0) {
    errors.storeApiKey = "This field cannot be left blank";
  }
  if (!values.name || values.name.replace(/\s+/g, "").length === 0) {
    errors.name = "This field cannot be left blank";
  }
  return errors;
};
export default validate;
