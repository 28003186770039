import { actionType as TYPE } from './actions';
import { notification } from 'antd';

const initialState = {
  syncOrder: {
    loading: false,
  },
};

function SyncOrderReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.SYNC_ORDER.REQUEST:
      return {
        ...state,
        syncOrder: {
          ...initialState.syncOrder,
          loading: true,
        },
      };
    case TYPE.SYNC_ORDER.SUCCESS:
      notification.success({
        message: action?.response[0].clientMsg,
        duration: 5,
      });
      return {
        ...state,
        syncOrder: {
          ...initialState.syncOrder,
          loading: false,
        },
      };
    case TYPE.SYNC_ORDER.ERROR:
      notification.warn({
        message: action?.response[0].clientMsg,
        duration: 5,
      });
      return {
        ...state,
        syncOrder: {
          ...initialState.syncOrder,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default SyncOrderReducer;
