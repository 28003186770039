import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  div.label {
    margin-top: 16px;
  }
  .cta-section {
    text-align: right;
  }
`;
