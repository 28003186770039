import { takeLatest, call, put, all } from "redux-saga/effects";

import { actionType as TYPE } from "./actions";

import {listCampaignsToExport, exportFeedFormAmazon}  from "apis/campaign"

function* listCampaignsToExportSaga(action){
    const { listCampaignId } = action
    try {
      const response = yield call(listCampaignsToExport, listCampaignId)
      if(response.code === 'success'){
        yield put({ type: TYPE.LIST_CAMPAIGN_TO_EXPORT.SUCCESS, ...response })
      }else{
          yield put({ type: TYPE.LIST_CAMPAIGN_TO_EXPORT.FAIL, error: response })
      }
    } catch(error){
      yield all([
        yield put({ type: TYPE.LIST_CAMPAIGN_TO_EXPORT.FAIL, error })
      ])
    }
  }

  function* exportFeedFormAmazonSaga(action){
    const { data } = action
    try {
      const response = yield call(exportFeedFormAmazon, data)
      if(response.code === 'success'){
        yield put({ type: TYPE.EXPORT_FEED_FORM_AMAZON.SUCCESS, ...response })
      }else{
          yield put({ type: TYPE.EXPORT_FEED_FORM_AMAZON.FAIL, response })
      }
    } catch(error){
      yield all([
        yield put({ type: TYPE.EXPORT_FEED_FORM_AMAZON.FAIL, error })
      ])
    }
  }

function* watcher() {
    yield takeLatest(TYPE.LIST_CAMPAIGN_TO_EXPORT.REQUEST, listCampaignsToExportSaga);
    yield takeLatest(TYPE.EXPORT_FEED_FORM_AMAZON.REQUEST, exportFeedFormAmazonSaga);
}

export default watcher;
