import { combineReducers } from 'redux';
import create from './Create/reducer';
import preview from './Preview/reducer';

const reducer = combineReducers({
  create,
  preview,
});

export default reducer;
