import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import validate from './validate'
import { renderRadioAnt, renderSelectAnt } from 'utils/Form'
import { getStoreTypeName } from "utils/helper";
import { list_platform_to_sync_campaign } from "utils/options";
import { GMSelect } from 'components/InputFields'
import { GMButton } from 'components/Button'

class index extends Component {

  render() {
    const { handleSubmit, onCancel, platformId, stores } = this.props;
    const platform = list_platform_to_sync_campaign.find(item => parseInt(item.value) === parseInt(platformId));
    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-group mb-1">
              <label className="col-form-label">Choose platform</label>
              <Field
                name="platformId"
                component={renderRadioAnt}
                classNameItem={"d-block mb-3"}
                colSpan={24}
                options={list_platform_to_sync_campaign}
              />
            </div>
            <div className="form-group mb-1">
              <GMSelect
                name="storeId"
                placeholder="Select store"
                label="Select store"
                options={[
                  { value: "0", label: `Select store ${platform?.code || ''}` },
                  ...stores.map(store => ({ value: store.id, label: `[${getStoreTypeName(store.backendType)}] ` + (store.showName || `#${store.id}`) + ` - ${store.url === null ? "No Store URL" : store.url}` }))
                ]}
                showError
              />
            </div>
            <div className="cta">
              <GMButton color="SecondaryColor" onClick={onCancel}>Cancel</GMButton>
              <GMButton>Continue</GMButton>
            </div>
          </div>

        </div>

      </form>
    )
  }
}


export default connect()(reduxForm({
  form: 'FormSyncCampaign',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(index))
