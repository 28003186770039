import styled, { createGlobalStyle } from 'styled-components';
import PageContainer from 'components/PageContainer';
import {
  BASE_BLACK,
  BASE_WHITE,
  GRAY_100,
  GRAY_200,
  GRAY_50,
  GRAY_700,
  SHADOW_LG_0_03,
  SHADOW_LG_0_08,
  GRAY_300,
  GRAY_400,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_300,
  SHADOW_XS,
  ERROR_700,
  BLUE_700,
  ERROR_800,
  ERROR_600,
} from 'components/colors';

export const Wrapper = styled(PageContainer)`
  .topcta .ctas {
    display: flex;
    flex-direction: row;
    gap: 12px;

    .moreactions {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
  .w100p {
    &,
    & .ant-space-item {
      min-width: 100%;
    }
    .ant-space-item {
      margin-right: 8px;
    }
  }
  .main-content {
    padding: 0;
    overflow: hidden;
    background: ${GRAY_50};
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      margin-bottom: 0;
    }
    .custom-header-sticky .ant-table-sticky-header {
      top: 0 !important;
    }
  }

  .ant-input-group-addon {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  textarea,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input-affix-wrapper {
    height: 44px;
    line-height: 44px;
    padding: 0 14px;
    outline: none;
    width: 100%;

    overflow: hidden;

    color: ${GRAY_900};
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.ant-input {
      border-radius: 8px !important;
    }
    &:placeholder {
      color: ${GRAY_400};
    }
    &:focus-visible,
    &:focus {
      outline: none;
      border: 1px solid ${PRIMARY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    }
    &:disabled {
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: ${GRAY_700};
    }
  }
  .ant-input-affix-wrapper {
    padding-right: 0;
  }
  td b {
    font-weight: 600;
  }
  textarea {
    height: unset;
    line-height: 1.3;
    overflow: auto;
    padding-top: 8px;
  }
  .form-footer {
    display: flex;
    height: 52px;
    background-color: ${BASE_WHITE};
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
    padding-right: 24px;
    gap: 24px;
    border-top: 1px solid ${GRAY_200};
  }
  .icon {
    &.trash {
      stroke: ${ERROR_600};
    }
    &.save {
      stroke: ${BLUE_700};
    }
    &.close {
      stroke: ${ERROR_600};
    }
    &.search {
      stroke: ${GRAY_900};
    }
    &.filesearch {
      stroke: ${GRAY_900};
    }
  }
  .btn-group-footer {
    border-top: 1px solid ${GRAY_200};
    .group-footer-notice {
      color: ${GRAY_900};
    }
  }
`;

export const ContextMenuWrapper = styled.div`
  background: ${BASE_WHITE};
  border: 1px solid ${GRAY_200};
  box-shadow: 0px 12px 16px -4px ${SHADOW_LG_0_08},
    0px 4px 6px -2px ${SHADOW_LG_0_03};
  border-radius: 8px;
  padding: 6px;
  a {
    height: 38px;
    border-radius: 6px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    color: ${GRAY_700};
    &:hover {
      background: ${GRAY_100};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: ${GRAY_700};
      &.sync {
        fill: ${BASE_BLACK};
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  .ant-dropdown-arrow {
    display: none;
  }
  .ant-modal-content {
    background: ${BASE_WHITE};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }

    .ant-modal-body {
      // padding: 24px;
      font-size: 14px;
      padding-left: 24px;
      padding-right: 24px;
      gap: 24px;
   
    }
    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 0px;
      border-radius: 16px;
    }

    .ant-modal-title {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
    }
  
`;
