import React, { useState } from "react";
import { Tooltip, Modal } from "antd";
import { capitalizeFirstLetter } from "utils/helper";
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as SearchIcon } from 'images/icons/search-md.svg';

function RenderViewMoreImage({ imageUrl = "", side = '' }) {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Tooltip placement="top" title={`View larger ${side} design`}>
        <span onClick={() => setVisible(true)} className="cursor-pointer">
          <SearchIcon className="icon search"/>
        </span>
      </Tooltip>
      <Modal
        title={`${capitalizeFirstLetter(side)} design`}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        okButtonProps={{ style: { display: "none" } }}
        cancelText={"Close"}
      >
        <div className="text-center">
        <img
          className="img-fluid border-img"
          alt="renderUrl"
          src={imageUrl}
        />
        </div>
      </Modal>
    </>
  );
}

export default React.memo(RenderViewMoreImage);