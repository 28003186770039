import React, { useState } from  "react";
import { Modal, notification, Spin } from "antd";
import {useSelector} from 'react-redux';
import {sendConfirmationEmail} from "apis/user";
function RenderVerificationEmailStep(props){
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOnVerifyEmail = () => {
        setVisible(true);
    }
    const handleOnResendEmail = () => {
        setVisible(true);
        setLoading(true);
        sendConfirmationEmail()
        .then(({ code, data }) => {
            setLoading(false);
            if (code === "success") {
              notification.success({
                  message: "Confirmation email resent, please check your email!",
                  duration: 5
              })
            }else{
                notification.warn({
                    message: "Please try again!",
                    duration: 5
                })
            }
          })
          .catch((error) => {
            setLoading(false);
          });
    }
    const profile = useSelector((state) => state.auth.info.profile);
    return (
        <>
            <span><span className="text-primary" onClick={handleOnVerifyEmail}>Verify your email</span> to get relevant updates and the welcome guide</span>
            <Modal
            title={<b>Confirm email</b>}
            cancelButtonProps={{ style: { display: "none" } }}
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
            okText={'Got it'}
          >
              <Spin spinning={loading}>
              <p>We’ve sent a confirmation email to <b>{profile?.email}</b>. Follow the instructions to finish signup!</p>
              <p>Didn’t receive anything? Check your spam folder or <span className="text-primary cursor-pointer" onClick={handleOnResendEmail}>resend the email</span></p>
              </Spin>
          </Modal>
        </>
    )
}

export default React.memo(RenderVerificationEmailStep)