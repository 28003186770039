export const actionType = {
  LIST_NOTIFY_SHIPPING: {
    REQUEST: "NOTIFICATION.LIST_NOTIFY_SHIPPING.REQUEST",
    SUCCESS: "NOTIFICATION.LIST_NOTIFY_SHIPPING.SUCCESS",
    ERROR: "NOTIFICATION.LIST_NOTIFY_SHIPPING.ERROR",
  },
  //   READ: {
  //     REQUEST: "NOTIFICATION.READ.REQUEST",
  //     SUCCESS: "NOTIFICATION.READ.SUCCESS",
  //     ERROR: "NOTIFICATION.READ.ERROR",
  //   },
  //   MARK_AS_READ: {
  //     REQUEST: "NOTIFICATION.LIST_NOTIFY_SHIPPING.MARK_AS_READ.REQUEST",
  //     SUCCESS: "NOTIFICATION.LIST_NOTIFY_SHIPPING.MARK_AS_READ.SUCCESS",
  //     ERROR: "NOTIFICATION.LIST_NOTIFY_SHIPPING.MARK_AS_READ.ERROR",
  //   },
};

export function getListNotifyShipping(params) {
  return {
    type: actionType.LIST_NOTIFY_SHIPPING.REQUEST,
    params,
  };
}
// export function getListNotiRead(params) {
//   return {
//     type: actionType.READ.REQUEST,
//     params,
//   };
// }
// export function markAsRead() {
//   return {
//     type: actionType.MARK_AS_READ.REQUEST,
//   };
// }
