export const actionType = {
  CREATE_ORDER: {
    REQUEST: 'ORDER.CREATE.REQUEST',
    SUCCESS: 'ORDER.CREATE.SUCCESS',
    ERROR: 'ORDER.CREATE.ERROR',
  },
  LIST_DESIGN: {
    REQUEST: 'LIST_DESIGN.REQUEST',
    SUCCESS: 'LIST_DESIGN.SUCCESS',
    ERROR: 'LIST_DESIGN.ERROR',
  },
  CHECK_VARIANT_RISK: {
    REQUEST: 'CHECK_VARIANT_RISK.REQUEST',
    SUCCESS: 'CHECK_VARIANT_RISK.SUCCESS',
    ERROR: 'CHECK_VARIANT_RISK.ERROR',
  },
  CLOSE_VARIANT_RISK: 'CLOSE_VARIANT_RISK',
  OPEN_VARIANT_RISK: 'OPEN_VARIANT_RISK',
  RESET_STATE: 'RESET_STATE',
};

export function createOrder(data) {
  return {
    type: actionType.CREATE_ORDER.REQUEST,
    data,
  };
}

export function listDesigns() {
  return {
    type: actionType.LIST_DESIGN.REQUEST,
  };
}

export function checkVariantRiskAction(data) {
  return {
    type: actionType.CHECK_VARIANT_RISK.REQUEST,
    data,
  };
}

export function closeVariantRiskAction() {
  return {
    type: actionType.CLOSE_VARIANT_RISK,
  };
}

export function openVariantRiskAction() {
  return {
    type: actionType.OPEN_VARIANT_RISK,
  };
}
export function resetReducerAction() {
  return {
    type: actionType.RESET_STATE,
  };
}
