/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ReactComponent as IconEmail } from 'images/icons/mail-01.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from 'images/icons/arrow-left.svg';

export default (props) => {
  return (
      <>
        <div>
          <div className="text-center">
            <div className="icon">
              <IconEmail />
            </div>
          </div>
          <h3 className="text-center form-header">Check your email</h3>
          <p className="form-message">
            We have sent a password reset email to your email.
              Please click on the link in the email to reset your password.
              The link will be expired in 7 days
          </p>
        </div>
        <div className="form-resend-content">

          <div className="text-center mb-3 fontSize-child">
            <Link to="/auth/logout" className="back-cta">
              <ArrowLeft />
              Back to log in
            </Link>
          </div>
        </div>
      </>
  );
};
