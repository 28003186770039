import { isValidEmail, isValidPassword } from 'utils/helper';
const validate = (values) => {
  const errors = {};
  if (!values.email || values.email.replace(/\s+/g, '').length === 0) {
    errors.email = 'Please enter your email.';
  } else if (!isValidEmail(values.email)) {
    errors.email = 'Invalid email format.';
  }
  if (!values.password || values.password.replace(/\s+/g, '').length === 0) {
    errors.password = 'Please enter your password.';
  } else if (
    !isValidPassword(values.password) ||
    values.password === values.email ||
    values.password.includes(values.email)
  ) {
    errors.password = 'Invalid password format.';
  }
  if (
    !values.confirmPassword ||
    values.confirmPassword.replace(/\s+/g, '').length === 0
  ) {
    errors.confirmPassword = 'Please enter your confirm-password.';
  }
  if (values.confirmPassword !== values.password) {
    errors.confirmPassword =
      'Your password and confirmation password must match.';
  }
  if (!values.firstName || values.firstName.replace(/\s+/g, '').length === 0) {
    errors.firstName = 'Please enter first name.';
  }
  if (!values.lastName || values.lastName.replace(/\s+/g, '').length === 0) {
    errors.lastName = 'Please enter last name.';
  }
  return errors;
};

export default validate;
