import {
    DATA_FEED_GOOGLE_CREATE_REQUEST,
    DATA_FEED_GOOGLE_CREATE_FAIL,
    DATA_FEED_GOOGLE_CREATE_SUCCESS
} from './constants'
import { notification } from 'antd'
const initialState = {
    loading: false
}

function PostCreateReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_FEED_GOOGLE_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DATA_FEED_GOOGLE_CREATE_SUCCESS:
            notification.success({
                message: "Create Feed Form Google successfully.",
                duration: 5
            })
            return {
                ...state,
                loading: false,
            }
        case DATA_FEED_GOOGLE_CREATE_FAIL:
            notification.error({
                message: action.error && action.error[0] && action.error[0]["clientMsg"]
                ? action.error[0]["clientMsg"]
                : "Create Feed Form Google failed.",
                duration: 5
            })
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export default PostCreateReducer