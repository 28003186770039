import axios from 'utils/request';

const prefix = '/countries';

export const getList = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}?suspended=0`,
  });
};

export const create = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}`,
  });
};
export const getDetail = (id) => {
  return axios({
    method: 'GET',
    // params,
    url: `${prefix}/${id}`,
  });
};

export const update = (id, data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/${id}`,
  });
};

export const destroy = (id) => {
  return axios({
    method: 'POST',
    url: `${prefix}/${id}/delete`,
  });
};

export const getStates = (countryCode) => {
  return axios({
    method: 'GET',
    url: `${prefix}/${countryCode}/states`,
  });
};
