import { takeLatest, call, put, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actionType as TYPE } from "./actions";
import { detailAPI } from "apis/mockup";
import axios from "utils/request";

function* detailSaga(action) {
  try {
    const { id } = action;
    const response = yield call(detailAPI, id);

    if (response.code === "success") {
      yield all([put({ type: TYPE.DETAIL.SUCCESS, ...response })]);
    } else {
      yield put({ type: TYPE.DETAIL.ERROR, response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.DETAIL.ERROR, error })]);
  }
}

function updateAPI(id, data) {
  return axios({
    method: "PUT",
    data,
    url: `/mockups/${id}`,
  });
}

function* updateSaga(action) {
  try {
    const { id, params } = action;
    const response = yield call(updateAPI, id, params);

    if (response.code === "success") {
      yield all([
        put({ type: TYPE.UPDATE.SUCCESS, response }),
        put(push("/products/mockups")),
      ]);
    } else {
      yield put({ type: TYPE.UPDATE.ERROR, response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.UPDATE.ERROR, error })]);
  }
}

function* watcher() {
  yield all([
    takeLatest(TYPE.UPDATE.REQUEST, updateSaga),
    takeLatest(TYPE.DETAIL.REQUEST, detailSaga),
  ]);
}

export default watcher;
