import React, { Component } from "react";
import { Input } from "antd";
import classNames from "classnames";
function formatNumber(value) {
  value += "";
  value = value.replace(/,/g, "");
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] || list[1] === "" ? `.${list[1]}` : ""}`;
}

class renderMoney extends Component {
  onBlur = () => {
    const {
      input: { value, onBlur, onChange },
    } = this.props;
    let valueTemp = value;
    if (value.toString().charAt(value.length - 1) === "." || value === "-") {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.toString().replace(/0*(\d+)/, "$1"));

    if (onBlur) {
      onBlur();
    }
  };
  onChange = ({ target: { value } }) => {
    const { min, max, input, float = true } = this.props;
    let result = (value + "").replace(/,/g, "");
    const reg = float ? /^-?[0-9,]*(\.[0-9]*)?$/ : /^-?[0-9,]*?$/;
    if (!isNaN(result) && !float) {
      result = parseInt(result) + "";
    }

    if (
      (!isNaN(result) && reg.test(result)) ||
      result === "" ||
      result === "-"
    ) {
      if (!isNaN(min) && result < min) {
        return;
      }
      if (!isNaN(max) && result > max) {
        return;
      }
      input.onChange(result);
      if (this.props.onChangeComparePrice) {
        this.props.onChangeComparePrice(result);
      }
    }
  };

  render() {
    const {
      input,
      meta: { invalid, error },
      prefix,
      suffix,
      addonBefore,
      addonAfter,
      className,
      size = "large",
      defaultValue = null,
    } = this.props;
    return (
      <div className="position-relative w-100 input-text-right">
        {/* <InputNumber
          style={{width: '100%'}}
          // size="150px"
          step={10000}
          min={0}
          value={input.value == undefined ? null : input.value}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          onChange={(value)=>input.onChange(value)}
        /> */}
        <Input
          placeholder="0"
          style={{ width: "100%" }}
          size={size}
          // size="150px"
          // step={10000}
          // min={0}
          className={classNames(className)}
          prefix={prefix}
          suffix={suffix}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          value={input.value === undefined ? defaultValue: formatNumber(input.value)}
          // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          // parser={value => value.replace(/\$\s?|(,*)/g, '')}
          onBlur={this.onBlur}
          onChange={this.onChange}
        />
        {invalid && (
          <hr
            className="border-danger m-0 position-absolute"
            style={{ width: "90%", left: "5%", top: 39 }}
          />
        )}
        {error && <span className="invalid-feedback">{error}</span>}
      </div>
    );
  }
}

export default renderMoney;
