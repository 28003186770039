import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import {
  getOrderAtRisk,
} from "apis/orders";

function* getListAtRiskSaga(action) {
  try {
    const response = yield call(getOrderAtRisk);
    if (response.code === "success") {
      yield put({ type: TYPE.AT_RISK.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.AT_RISK.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.AT_RISK.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.AT_RISK.REQUEST, getListAtRiskSaga);
}

export default watcher;
