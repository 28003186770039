import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { login } from './actions';
import Form from './components/form';
import Countdown from 'react-countdown';
import GMHelmet from 'components/GMHelmet';
import queryString from 'query-string';
import {
  checkConnectWithShopifyAction,
  getUrlShopifyAction,
} from 'scenes/Stores/scenes/Create/actions';

class Index extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { profile, history } = this.props;
    /**Use for connect Shopify from app Shopify */
    // Redirect when api step 1 successfull
    // if (this.props.linkUrlShopify.url && this.props.linkUrlShopify.url !== '') {
    //   window.location.replace(this.props.linkUrlShopify.url);
    // }
    /**Use for connect Shopify from app Shopify */

    if (profile.id && profile.id !== prevProps.profile.id) {
      history.replace('/');
    }
  }
  handleLogin = (values) => {
    // let isConnectShopify = localStorage.getItem('isCheckConnectSuccessful');
    this.props.login({
      ...values,
      // shopifyConnection: !!isConnectShopify || null,
    });
  };
  /**Use for connect Shopify from app Shopify */
  // componentDidMount() {
  //   const params = queryString?.parse(this.props?.location.search);
  //   //Callback shopify step 1:
  //   if (
  //     window.location.href.includes('callback_shopify') &&
  //     params.hmac &&
  //     params.shop &&
  //     params.session
  //   ) {
  //     this.props.checkConnectWithShopifyAction({
  //       hmac: params.hmac,
  //       shop: params.shop,
  //     });
  //   }
  //   //Callback shopify step 2:
  //   if (
  //     window.location.href.includes('callback_shopify') &&
  //     params.hmac &&
  //     params.shop &&
  //     params.code
  //   ) {
  //     this.props.getUrlShopifyAction({
  //       hmac: params.hmac,
  //       shop: params.shop,
  //       code: params.code,
  //       host: params.host,
  //       timestamp: params.timestamp,
  //     });
  //   }
  // }
  /**Use for connect Shopify from app Shopify */

  render() {
    const { loading, error, success, loadingVerify, resendData } = this.props;

    return (
      <>
        <GMHelmet />
        {localStorage.getItem('timeOut') ? (
          <div className="d-flex justify-content-center flex-column align-items-center min-vh-100">
            <h5
              className="d-flex justify-content-center"
              style={{ fontFamily: 'OCR A Std, monospace' }}
            >
              Please login again after 15 minutes
            </h5>
            <Countdown
              className="countdown-item"
              daysInHours
              zeroPadDay
              onStart={() => {
                localStorage.getItem('timeOut');
                this.forceUpdate();
              }}
              date={
                new Date(
                  parseInt(localStorage.getItem('timeOut')) + 15 * 60 * 1000
                )
              }
              onComplete={() => {
                localStorage.removeItem('timeOut');
                this.forceUpdate();
              }}
            />
          </div>
        ) : (
          <div className="bg-white w-100">
            <Spin spinning={loading}>
              <Form
                onSubmit={this.handleLogin}
                formError={error}
                successResendEmail={success}
                loadingVerify={loadingVerify}
                resendData={resendData}
              />
            </Spin>
          </div>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.auth.login.loading,
    profile: state.auth.info.profile,
    error: state.auth.login.error,
    success: state.auth.verifyEmail.success,
    loadingVerify: state.auth.verifyEmail.loadingResentEmail,
    resendData: state.auth.login.resendData,
    linkUrlShopify: state.store.create.checkConnectWithShopify.data,
    getUrlFromShopify: state.store.create.getUrlFromShopify.data,
  }),
  (dispatch) => ({
    login: (post) => {
      dispatch(login(post));
    },
    //Step to connect Shopify
    checkConnectWithShopifyAction: (params) => {
      dispatch(checkConnectWithShopifyAction(params));
    },
    getUrlShopifyAction: (data) => {
      dispatch(getUrlShopifyAction(data));
    },
  })
)(Index);
