import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validate from './validate';
import renderInputSearch from 'utils/Form/renderInputSearch';
import { Card, Drawer } from 'antd';
import AdvancedFilter from './AdvancedFilter';
import CloseIcon from 'images/x2.svg';
import SearchIcon from 'images/search.svg';
import { PlusCircleOutlined } from '@ant-design/icons';
import { ButtonGM } from 'components/ButtonGM/styled';
class index extends Component {
  state = {
    advance_search: false,
  };
  onCloseAdvancedSearch = () => {
    this.setState({
      advance_search: false,
    });
  };
  onOpenAdvancedSearch = () => {
    this.setState({
      advance_search: true,
    });
  };
  render() {
    const { handleSubmit, handleAdvancedSubmit, initialValues } = this.props;
    return (
      <>
        <form onSubmit={handleSubmit}>
          <Card bordered={false} bodyStyle={{ padding: 0 }}>
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 mb-2 ">
                <Field
                  name="name"
                  component={renderInputSearch}
                  size="large"
                  onSearch={handleSubmit(({ name, perPage }) =>
                    this.props.onSubmit({ name, perPage })
                  )}
                  placeholder="Filter by mockup name"
                  enterButton={<img src={SearchIcon} alt="Search" />}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-2 mb-sm-0">
                <p
                  className="More-Filter m-0"
                  onClick={this.onOpenAdvancedSearch}
                >
                  More Filter
                </p>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-2 mb-sm-0">
                <ButtonGM
                  display="flex"
                  backgroundColor="#07f"
                  borderRadius="5px"
                  textColor="white"
                  padding="8px"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => {
                    this.props.history.push('/products/mockups/create');
                  }}
                >
                  <PlusCircleOutlined style={{ marginRight: 10 }} />
                  Create mockup
                </ButtonGM>
              </div>
            </div>
          </Card>
        </form>
        <Drawer
          title={<b className="Filter">Filter</b>}
          className="custom-filter"
          placement="right"
          closable={true}
          onClose={this.onCloseAdvancedSearch}
          visible={this.state.advance_search}
          closeIcon={
            <img src={CloseIcon} style={{ width: '20px' }} alt="close-icon" />
          }
          width={this.props.is_mobile ? '80%' : '480px'}
        >
          <AdvancedFilter
            onCloseAdvancedSearch={this.onCloseAdvancedSearch}
            onSubmit={handleAdvancedSubmit}
            initialValues={initialValues}
            exproducts={this.props.exproducts}
          />
        </Drawer>
      </>
    );
  }
}

index = reduxForm({
  form: 'formFilterMockup',
  validate,
  enableReinitialize: true,
})(index);

export default connect()(index);
