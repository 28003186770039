import { actionType } from "./actions";
import { notification } from "antd";

const initialState = {
  loading: false,
  serverError: null,
  serverSuccessData: null,
};

function ForgetReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.FORGET.REQUEST:
      return {
        ...state,
        serverError: null,
        loading: true,
      };
    case actionType.FORGET.SUCCESS:
      return {
        ...state,
        serverSuccessData: action.data,
        serverError: null,
        loading: false,
      };
    case actionType.FORGET.ERROR:
      return {
        ...state,
        serverError: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export default ForgetReducer;
