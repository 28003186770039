export const actionType = {
  LIST_CUSTOMER_CONFIG: {
    REQUEST: "CUSTOMER.LIST.REQUEST",
    SUCCESS: "CUSTOMER.LIST.SUCCESS",
    ERROR: "CUSTOMER.LIST.ERROR",
  },
  UPDATE_INVOICE_CONFIG: {
    REQUEST: "UPDATE.INVOICE.REQUEST",
    SUCCESS: "UPDATE.INVOICE.SUCCESS",
    ERROR: "UPDATE.INVOICE.ERROR",
  },
  UPDATE_RETURN_ADDRESS: {
    REQUEST: "UPDATE.RETURN.ADDRESS.REQUEST",
    SUCCESS: "UPDATE.RETURN.ADDRESS.SUCCESS",
    ERROR: "UPDATE.RETURN.ADDRESS.ERROR",
  },
  UPDATE_AUTO_CHARGE: {
    REQUEST: "UPDATE.AUTO.CHARGE.REQUEST",
    SUCCESS: "UPDATE.AUTO.CHARGE.SUCCESS",
    ERROR: "UPDATE.AUTO.CHARGE.ERROR",
  },
  UPDATE_SHIPPING: {
    REQUEST: "UPDATE_SHIPPING.REQUEST",
    SUCCESS: "UPDATE_SHIPPING.SUCCESS",
    ERROR: "UPDATE_SHIPPING.ERROR",
  },
  LIST_USERLABEL: {
    REQUEST: "USERLABEL.LIST_USERLABEL.REQUEST",
    SUCCESS: "USERLABEL.LIST_USERLABEL.SUCCESS",
    ERROR: "USERLABEL.LIST_USERLABEL.ERROR",
  },
  AUTHORIZATION: {
    REQUEST: "AUTHORIZATION.REQUEST",
    SUCCESS: "AUTHORIZATION.SUCCESS",
    ERROR: "AUTHORIZATION.ERROR",
  },
  LIST_USERLABEL_DETAIL: {
    REQUEST: "USERLABEL.LIST_USERLABEL_DETAIL.REQUEST",
    SUCCESS: "USERLABEL.LIST_USERLABEL_DETAIL.SUCCESS",
    ERROR: "USERLABEL.LIST_USERLABEL_DETAIL.ERROR",
  },
  LIST_USERLABEL_CREATE: {
    REQUEST: "USERLABEL.LIST_USERLABEL_CREATE.REQUEST",
    SUCCESS: "USERLABEL.LIST_USERLABEL_CREATE.SUCCESS",
    ERROR: "USERLABEL.LIST_USERLABEL_CREATE.ERROR",
  },

  DEFAULT_GATEWAY_SET: {
    REQUEST: "DEFAULT_GATEWAY_SET.REQUEST",
    SUCCESS: "DEFAULT_GATEWAY_SET.SUCCESS",
    ERROR: "DEFAULT_GATEWAY_SET.ERROR",
  },
};
export function setDefaultGatewaySetting() {
  return {
    type: actionType.DEFAULT_GATEWAY_SET.REQUEST,
  };
}

export function getListCustomerConfig() {
  return {
    type: actionType.LIST_CUSTOMER_CONFIG.REQUEST,
  };
}
export function getAuthorization() {
  return {
    type: actionType.AUTHORIZATION.REQUEST,
  };
}

export function updateInvoiceConfigs(dataToUpdate) {
  return {
    type: actionType.UPDATE_INVOICE_CONFIG.REQUEST,
    dataToUpdate,
  };
}

export function updateReturnAddress(dataToUpdateReturnAddress) {
  return {
    type: actionType.UPDATE_RETURN_ADDRESS.REQUEST,
    dataToUpdateReturnAddress,
  };
}

export function updateAutoCharge(dataToUpdateAutoCharge) {
  return {
    type: actionType.UPDATE_AUTO_CHARGE.REQUEST,
    dataToUpdateAutoCharge,
  };
}
export function getListUselable(params) {
  return {
    type: actionType.LIST_USERLABEL.REQUEST,
    params,
  };
}

export function getListUselabelDetail(id) {
  return {
    type: actionType.LIST_USERLABEL_DETAIL.REQUEST,
    id,
  };
}

export function getListUselabelCreate(params) {
  return {
    type: actionType.LIST_USERLABEL_CREATE.REQUEST,
    params,
  };
}
