import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { getMetaTitle } from 'utils/helper';

export default ({ overrideTitle = false }) => {
  const history = useHistory();
  const { pathname, search } = history.location;
  const metaInfo = getMetaTitle((pathname + search) || '');
  if (overrideTitle) {
    metaInfo.title = overrideTitle;
  }
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {metaInfo.title ? (
        <title>
          {metaInfo.title}
        </title>
      ) : null}
      {metaInfo.description ? (
        <meta
          name="description"
          content={metaInfo.description}
        />
      ) : null}
    </Helmet>
  );
}
