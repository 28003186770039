import { 
    actionType as TYPE
} from './actions'

const initialState = {
    analytics: {
        order: [],
        gaSession: [],
        gaTraffic: [],
        loading: false,
        params: {},
    },
}

function reducer(state = initialState, action){
    switch(action.type) {
        case TYPE.LIST.REQUEST:
            return {
                ...state,
                analytics: {
                    ...initialState.analytics,
                    params: action.params,
                    loading: true
                }              
            }
        case TYPE.LIST.SUCCESS:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    loading: false,
                    order: action.data.order,
                    gaSession: action.data.gaSession,
                    gaTraffic: action.data.gaTraffic,
                },
            }
        case TYPE.LIST.ERROR:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    loading: false
                } 
            }
        default:
            return state
    }
}

export default reducer