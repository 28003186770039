import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Spin } from 'antd';
import FormCreateOrder from './components/FormCreateOrder';
import {
  createLableOrder,
  checkIfUploadingShippingLabelIsRequired,
} from './actions';
import { listCountry } from '../../../Users/scenes/Profile/actions';
import { getListExproduct } from '../../../Mockups/scenes/Create/actions';
import { getListStore } from '../../../Stores/scenes/List/actions';
import { Wrapper } from './styled';
import {
  checkVariantRiskAction,
  closeVariantRiskAction,
  resetReducerAction,
} from '../Create/actions';
import { GMButton } from 'components/Button';
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }
  componentDidMount() {
    this.props.listCountry();
    this.props.getListExproduct({
      'excludeExpType[]': 12,
      sortBy: 'id',
      sortType: 'asc',
    });
    this.props.getListStore({
      isNotPlatform: 1,
      sortBy: 'id',
      sortType: 'asc',
    });
    this.props.checkIfUploadingShippingLabelIsRequired();
  }

  getConvertedData = () => {
    let values = this.props.FormCreateOrderLabel;
    const listOrderItems = [];
    if (values.listOrderItems) {
      for (let i = 0; i < values.listOrderItems.length; i++) {
        listOrderItems.push({
          productId: values.listOrderItems?.[i]?.['productId'] || 0,
          colorId: values.listOrderItems?.[i]?.['colorId'] || 0,
          sizeId: values.listOrderItems?.[i]?.['sizeId'] || 0,
          quantity: values.listOrderItems?.[i]?.['quantity'] || 1,
          barcodeId: values.listOrderItems?.[i]?.['barcodeId'] || 0,
          imageFrontId: values.listOrderItems?.[i]?.['designFrontId'] || 0,
          imageBackId: values.listOrderItems?.[i]?.['designBackId'] || 0,
          productType: values.listOrderItems?.[i]?.['productType'] || 0,
          sidePrint: values.listOrderItems?.[i]?.['sidePrint'] || 'normal',
        });
      }
    }
    const dataToCreate = {
      cusName: values.fullName || '',
      cusPhoneNumber: values.phoneNumber || '',
      cusAddress: values.address01 || '',
      cusAddress2: values.address02 || '',
      cusCity: values.city || '',
      cusPostalCode: values.zipcode || '',
      storeId: values.storeId || '',
      shippingService: values.shippingService || 0,
      onlineId: values.orderId || '',
      cusEmail: values.email || '',
      state: values.state || '',
      cusCountry: values.country || '',
      barcodeIds: values.barcodeIds || [],
      orderItems: listOrderItems,
      orderNote: values.orderNote || '',
    };
    return dataToCreate;
  };

  handleSubmitCheckVariant = (values) => {
    let data = [];
    let newArray = values?.listOrderItems?.map(function (i) {
      return data.push({
        exp_id: parseInt(i?.productId),
        color_id: parseInt(i?.colorId),
        size_id: parseInt(i?.sizeId),
      });
    });

    this.props.checkVariantRiskAction({
      data: { data: data },
      submitData: this.getConvertedData(),
      form: 'create_fba_order',
    });
  };

  handleSubmit = (values) => {
    window.scroll(0, 0);
    this.props.createLableOrder(this.getConvertedData());
    this.props.resetReducerAction();
  };

  handleCloseModal = () => {
    this.props.closeVariantRiskAction();
  };
  render() {
    const {
      pageName,
      createOrderLoading,
      stores,
      countries,
      exproducts,
      profile,
      checkIfUploadingShippingLabelIsRequiredLoading,
      checkIfUploadingShippingLabelIsRequiredData,
      listVariantRiskLoading,
      listVariantRisk,
      showModalVariant,
    } = this.props;
    const initialValues = {
      shippingService: 9,
      fullName: '',
      orderId: '',
      orderNote: '',
      zipcode: '',
      state: '',
      city: '',
      phoneNumber: '',
      email: '',
      barcodeIds: [],
      barcodeFiles: [],
      listOrderItems: [
        {
          key: 0,
          productId: '0',
          colorId: '0',
          sizeId: '0',
          quantity: '0',
          price: 0,
          designFront: '',
          designBack: '',
          mockup: '',
          designFrontId: '',
          designBackId: '',
          barcode: '',
          barcodeId: '',
          sidePrint: 'normal',
          printLocation: 'front,back',
        },
      ],
    };
    return (
      <div>
        <Wrapper pageName={pageName} noContainer>
          <Spin
            spinning={
              createOrderLoading ||
              countries.loading ||
              exproducts.loading ||
              stores.loading ||
              checkIfUploadingShippingLabelIsRequiredLoading 
              // || listVariantRiskLoading
            }
          >
            <FormCreateOrder
              history={this.props.history}
              isUploadShippingLabelRequired={
                checkIfUploadingShippingLabelIsRequiredData
              }
              stores={stores.data}
              profile={profile}
              exproducts={exproducts.data}
              countries={countries.data}
              onSubmit={this.handleSubmitCheckVariant}
              initialValues={initialValues}
            />
          </Spin>
        </Wrapper>
        <Modal
          centered
          title="Order confirmation required"
          visible={showModalVariant}
          onCancel={() => {
            this.handleCloseModal();
          }}
          onOk={() => {
            this.handleSubmit();
          }}
          width={800}
          footer={null}
        >
          <Spin spinning={listVariantRiskLoading}>
            <div className="mb-2">
              Your order includes the following{' '}
              <a
                href="https://help.gearment.com/en/article/order-with-variation-at-risk-8wumdy/"
                target="blank"
              >
                variant(s) at risk
              </a>
              :
            </div>
            {listVariantRisk?.data?.map((i) => {
              return (
                <p>
                  <ul>
                    <li>
                      {i?.exp_brand} - {i?.color_name} - {i?.size_name}
                    </li>
                  </ul>
                </p>
              );
            })}
            <div>
              By continuing, you confirm to create the order label with the
              variant(s) at risk and acknowledge that the processing time may be
              extended for a few business days.
            </div>
          </Spin>
          <div className="d-flex mt-3 mb-3 pb-3 align-items-right justify-content-end">
            <GMButton
              color="SecondaryGray"
              type="button"
              onClick={() => {
                this.handleCloseModal();
              }}
              style={{ width: 170 }}
            >
              Cancel
            </GMButton>
            <GMButton
              style={{ width: 170 }}
              onClick={() => {
                this.handleSubmit();
              }}
              className="ml-3"
            >
              Confirm order
            </GMButton>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    createOrderLoading: state.order.CreateLableOrder.createOrder.loading,
    countries: state.user.profile.listCountry,
    stores: state.store.list.stores2,
    exproducts: state.mockup.create.exproducts,
    profile: state.auth.info.profile,
    checkIfUploadingShippingLabelIsRequiredLoading:
      state.order.CreateLableOrder.checkIfUploadingShippingLabelIsRequired
        .loading,
    checkIfUploadingShippingLabelIsRequiredData:
      state.order.CreateLableOrder.checkIfUploadingShippingLabelIsRequired.data,
    listVariantRiskLoading: state.order.createOrder.listVariantRisk.loading,
    listVariantRisk: state.order.createOrder.listVariantRisk,
    showModalVariant: state.order.createOrder.showModalVariant,
    FormCreateOrderLabel: state.form.FormCreateOrderLabel?.values,
  }),
  (dispatch) => ({
    createLableOrder: (data) => {
      dispatch(createLableOrder(data));
    },
    listCountry: () => {
      dispatch(listCountry());
    },
    getListExproduct: (params) => {
      dispatch(getListExproduct(params));
    },
    getListStore: (options) => {
      dispatch(getListStore(options));
    },
    checkIfUploadingShippingLabelIsRequired: () => {
      dispatch(checkIfUploadingShippingLabelIsRequired());
    },
    checkVariantRiskAction: (data) => {
      dispatch(checkVariantRiskAction(data));
    },
    closeVariantRiskAction: () => {
      dispatch(closeVariantRiskAction());
    },
    resetReducerAction: () => {
      dispatch(resetReducerAction());
    },
  })
)(index);
