import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Slider } from './style';
// import IMGUrl from "images/Promotion/mockup.png"
import IMGUrl from "images/Promotion/banner3.jfif"

// const settings = {
//   dots: false,
//   infinite: !!1,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   centerPadding: "0",
//   arrows: !1,
//   autoplay: !!1,
//   useTransform: !!1,
//   autoplaySpeed: 8000,
//   dots: !!1,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         arrows: !1,
//         // dots: !1
//       }
//     }
//   ]
// };

export default ({ ...props }) => {
  return (
    <a href="https://gearment.com/custom-valentines-day-gifts/" target="_blank" style={{
      backgroundImage: `url(${IMGUrl})`,
      display: 'block',
      width: '100%',
      height: '100vh',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '100% 50%',
      backgroundSize: 'contain',
      backgroundColor: 'white',
    }}>
      &nbsp;
    </a >
    // <Slider {...settings}>
    // <div class="item">
    //   <div class="item-wrapper">
    //     <img src={IMGUrl} />
    //     <div class="item_description">
    //       <h4>Create Custom Products</h4>
    //       <p>Get creative with Gearment Merchant Center’s easy custom product creation. Design unique merchandise
    //         that
    //         captivates your audience, hassle-fee</p>
    //     </div>
    //   </div>
    // </div>
    // <div class="item">
    //   <div class="item-wrapper">
    //     <img src={IMGUrl} />
    //     <div class="item_description">
    //       <h4>Integrate and Publish</h4>
    //       <p>Expand your reach and boost your sale by integrating Gearment Merchant Center with your stores.
    //         Synchronize and publish custom products across platforms</p>
    //     </div>
    //   </div>
    // </div>
    // <div class="item">
    //   <div class="item-wrapper">
    //     <img src={IMGUrl} />
    //     <div class="item_description">
    //       <h4>Order Tracking</h4>
    //       <p>Stay in control with real-time order tracking. Monitor every step from processing to fulfillment,
    //         ensuring seamless delivery and happy customers.</p>
    //     </div>
    //   </div>
    // </div>
    // </Slider>
  );
}
