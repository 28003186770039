import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalStyle, Wrapper } from './styled';
import { GMButton } from 'components/Button';
import { useHistory } from 'react-router-dom';
import { createIndemnicationAction } from '../Create/actions';
import { Modal, Spin } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import ModalAfterSubmit from 'components/ModalAfterSubmit';

function PreviewBeforeCreate() {
  let [isOpenModal, setIsOpenModal] = useState(false);
  let [isOpenModalAfterSubmit, setIsOpenModalAfterSubmit] = useState(false);

  let preview = useSelector(
    (state) => state.indemnification.preview.preview.data
  );
  let createIndemnificationLoading = useSelector(
    (state) => state.indemnification.create.createIndemnication.loading
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (preview.length === 0) history.push('/billing/indemnification');
  });

  const createIndemnication = () => {
    setIsOpenModal(false);
    const getDataIndem = JSON.parse(localStorage.getItem('formData'));
    dispatch(createIndemnicationAction(getDataIndem));
    setIsOpenModalAfterSubmit(true);
  };

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCancel = () => {
    setIsOpenModal(false);
  };
  const submitted = () => {
    setIsOpenModalAfterSubmit(false);
    history.push('/billing/indemnification');
  };
  return (
    <div>
      <Wrapper>
        <Spin spinning={createIndemnificationLoading}>
          <div className="preview">
            {preview.length !== 0 && (
              <span
                dangerouslySetInnerHTML={{
                  __html: preview || '',
                }}
              ></span>
            )}
          </div>
        </Spin>
      </Wrapper>
      <div className="d-flex justify-content-end bg-white py-3 px-3 text-white mt-5">
        <GMButton
          className="mr-3"
          color={'SecondaryGray'}
          onClick={() => {
            history.push('/billing/indemnification');
          }}
          type="button"
        >
          Back
        </GMButton>
        <GMButton type="button" onClick={handleOpenModal}>
          Submit
        </GMButton>
      </div>
      <Modal
        title="Confirmation"
        centered
        destroyOnClose={true}
        visible={isOpenModal}
        width={400}
        footer={false}
      >
        <div className="pb-2">
          Are you sure you want to submit this form? Once submitted, no edits
          are allowed.
        </div>

        <div className="d-flex mt-3 align-items-right justify-content-end">
          <GMButton color="SecondaryGray" type="button" onClick={handleCancel}>
            Cancel
          </GMButton>
          <GMButton
            type="button"
            onClick={createIndemnication}
            className="ml-3"
          >
            Submit
          </GMButton>
        </div>
      </Modal>
      {!createIndemnificationLoading && (
        <ModalAfterSubmit
          visible={isOpenModalAfterSubmit}
          ctas={
            <>
              <GMButton type="button" onClick={submitted} className="btn-close">
                Close
              </GMButton>
            </>
          }
        ></ModalAfterSubmit>
      )}

      <GlobalStyle />
    </div>
  );
}
export default React.memo(PreviewBeforeCreate);
