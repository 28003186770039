import { GRAY_600 } from 'components/colors';
import { Hotfix_CreateOrderWrapepr } from 'scenes/general.styled';
import styled from 'styled-components';
export const Wrapper = styled(Hotfix_CreateOrderWrapepr)`
  .add-icon {
    svg {
      width: 15px;
      height: 15px;
    }
  }

  .design-product {
    padding-bottom: 24px;
  }
`;
export const WrapperColumn = styled.div`
  width: 500px;
  min-width: 100%;
  .ant-select {
    .ant-select-selector {
      border-radius: 8px;
      height: 44px;
      width: 200px;
    }
  }
  .ant-select-selection-placeholder {
    color: black;
  }
  .special-field {
    display: flex;
    flex-direction: row;
    .hi {
      display: flex;
      align-items: center;
      margin-right: 2px;
      font-weight: 600;
    }
  }
  .ant-input-group-addon {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
export const WrapperIcon = styled.div`
  svg {
    stroke: ${GRAY_600};
  }
`;
