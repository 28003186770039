import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Button } from "antd";
import { getNotificationDetail } from "./actions";
import BackToIcon from "images/chevron-down.svg";
import getMomentInstance from "utils/moment";
import { Wrapper } from "scenes/general.styled";
export class index extends Component {
  componentDidMount() {
    this.props.getNotificationDetail(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.getNotificationDetail(this.props.match.params.id);
    }
  }

  render() {
    const { detailNotification, pageName } = this.props;
    return (
      <Wrapper
        pageName={`${pageName} #${this.props.match.params.id || ''}`}
        breadcrumDetails={{
          [this.props.match.params.id]:
            this.props.match.params.id &&
            `#${this.props.match.params.id || ''}`,
        }}
      >
        <Spin spinning={detailNotification.loading}>
          <div className="table-responsive">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <b
                    style={{ width: 200 }}
                    className="px-4 d-flex align-items-start justify-content-start"
                  >
                    Title
                  </b>
                  <td className="px-4">
                    {detailNotification.data.name || ""}
                  </td>
                </tr>
                <tr>
                  <b style={{ width: 200 }} className="px-4">
                    Content
                  </b>
                  <td className="px-4">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: (
                          detailNotification.data.content || ""
                        ).replace(/(<? *script)/gi, "illegalscript"),
                      }}
                    ></div>
                  </td>
                </tr>
                <tr>
                  <b style={{ width: 200 }} className="px-4">
                    Time created
                  </b>
                  <td className="px-4">
                    {detailNotification.data.createdAt
                      ? getMomentInstance(
                        detailNotification.data.createdAt,
                        0,
                        true
                      )
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    detailNotification: state.notification.detail.detail,
  }),
  (dispatch) => ({
    getNotificationDetail: (id) => {
      dispatch(getNotificationDetail(id));
    },
  })
)(index);
