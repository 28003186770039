import axios from "utils/request";

const prefix = "/order-items";

export const getListRemovedOrderItem = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}/removed-items?with[]=exProduct&with[]=color2&with[]=size2`,
  });
};

export const removeOrderItem = (data) => {
  return axios({
    method: "DELETE",
    data,
    url: `${prefix}/removed-items`,
    is_json_content_type: true,
  });
};

export const rollbackRemovedOrderItem = (data) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}/rollback-removed-item`,
    is_json_content_type: true,
  });
};
