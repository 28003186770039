import React from "react";
import classNames from "classnames";
import { Tooltip } from 'antd';
import money from "utils/money";
function RenderTotalOrder({ order = {} }) {
    return <Tooltip
        title={parseInt(order.paymentStatus) !== 0 ? 'Paid' : 'Unpaid'}
    >
        <span className="price">
            {order.total ? money(order.total) : ''}
        </span>
    </Tooltip>
}

export default React.memo(RenderTotalOrder)