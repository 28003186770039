import {
    MOCKUP_DELETE_REQUEST,
    MOCKUP_DELETE_SUCCESS,
    MOCKUP_DELETE_FAIL
} from './constants'
import {notification} from "antd";
const initialState = {
    loading: false
}

function MockupDeleteReducer(state = initialState, action) {
    switch(action.type){
        case MOCKUP_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case MOCKUP_DELETE_SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === "delete_mockup_success") {
            notification.success({
              message: action.errors[index].clientMsg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].clientMsg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        loading: false
      }
    case MOCKUP_DELETE_FAIL:
      notification.warn({
        className: "notic1",
        message: 'Delete mockup failed.',
        duration: 5
    });
      return {
        ...state,
        loading: false
      }
        
        default:
          return state
    }
}
    
export default MockupDeleteReducer