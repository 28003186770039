export const actionType = {
  GIFTMESSAGE_CREATE: {
    REQUEST: "GIFTMESSAGE_CREATE.REQUEST",
    SUCCESS: "GIFTMESSAGE_CREATE.SUCCESS",
    ERROR: "GIFTMESSAGE_CREATE.ERROR",
  },
};
export function giftMessageCreate(params) {
  return {
    type: actionType.GIFTMESSAGE_CREATE.REQUEST,
    params,
  };
}
