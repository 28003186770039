import { combineReducers } from "redux";
import general from "./scenes/General/reducer";
import account from "./scenes/Account/reducer";

const reducer = combineReducers({
  general,
  account,
});

export default reducer;
