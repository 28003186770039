import { all, call, put, takeLatest } from "redux-saga/effects";
import { actionType } from "./actions";
import { getUserStepTracking } from "apis/dashboard";
function* requestUseStepStatusSaga(action) {
  try {
    const response = yield call(getUserStepTracking);
    if (response.code === "success") {
      yield all([put({ type: actionType.DASHBOARD.USER_STEPS.SUCCESS, payload: response?.data })]);
    } else {
      yield put({ type: actionType.DASHBOARD.USER_STEPS.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: actionType.DASHBOARD.USER_STEPS.ERROR, error })]);
  }
}

function* getDashboardListWatcher() {
  yield takeLatest(actionType.DASHBOARD.USER_STEPS.REQUEST, requestUseStepStatusSaga);
}

export default function* () {
  yield all([getDashboardListWatcher()]);
}
