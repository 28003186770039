import { GRAY_200, GRAY_50, GRAY_600, GRAY_900 } from "components/colors";
import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  .filter-form {
    margin-bottom: 16px;
  }
  h2.header {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: ${GRAY_900};
    margin: 0;
    margin-top: 20px;
  }

  .ctas {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 16px 8px;
  }

  .table table {
    thead th {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      border: none;
      height: 44px;
      vertical-align: middle;
      background-color: ${GRAY_50};
      border-bottom: 1px solid ${GRAY_200};
    }
    tbody td {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      border-bottom: 1px solid ${GRAY_200};
      border-top: none;
      vertical-align: middle;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
`;

export const InlineP = styled.p`
  margin-bottom: 5px;
`;
