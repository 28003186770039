import React from 'react';
import { Modal } from 'antd';
import { dummyFunction } from 'utils/helper';
import { GMButton } from 'components/Button';
import { GlobalStyle } from './styled';
import classNames from 'classnames';
import { ReactComponent as DoneIcon } from 'images/Done.svg';

export default ({
  handleOk = dummyFunction,
  handleCancel = dummyFunction,
  visible = false,
  title = '',
  customContent = undefined,
  children,
  ctas = undefined,
  customCtaText = {},
}) => {
  return (
    <>
      <Modal
        className="modal-after-submit"
        centered
        destroyOnClose={true}
        visible={visible}
        footer={null}
        width={400}
      >
        <div className="done">
          {' '}
          <DoneIcon />
        </div>

        <>
          {<h4 className="title">Thank you</h4>}
          <div className="content mb-1">Your submission has been received</div>
        </>

        <div className="footer mt-2">
          {ctas || (
            <div className="btn-close">
              <GMButton onClick={handleOk}>
                {customCtaText.ok || 'Close'}
              </GMButton>
            </div>
          )}
        </div>
      </Modal>
      <GlobalStyle />
    </>
  );
};
