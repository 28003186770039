export const actionType = {
  CREATE_STORE: {
    REQUEST: 'CREATE_STORE.REQUEST',
    SUCCESS: 'CREATE_STORE.SUCCESS',
    FAIL: 'CREATE_STORE.ERROR',
  },
  CREATE_EBAY_STORE: {
    REQUEST: 'CREATE_EBAY_STORE.REQUEST',
    SUCCESS: 'CREATE_EBAY_STORE.SUCCESS',
    FAIL: 'CREATE_EBAY_STORE.FAIL',
  },
  CREATE_SHOPBASE_STORE: {
    REQUEST: 'CREATE_STORE_SHOPBASE.REQUEST',
    SUCCESS: 'CREATE_STORE_SHOPBASE.SUCCESS',
    FAIL: 'CREATE_STORE_SHOPBASE.FAIL',
  },

  CREATE_ETSY_STORE: {
    REQUEST: 'CREATE_ETSY_STORE.REQUEST',
    SUCCESS: 'CREATE_ETSY_STORE.SUCCESS',
    FAIL: 'CREATE_ETSY_STORE.FAIL',
  },
  CREATE_AMAZON_STORE: {
    REQUEST: 'CREATE_AMAZON_STORE.REQUEST',
    SUCCESS: 'CREATE_AMAZON_STORE.SUCCESS',
    FAIL: 'CREATE_AMAZON_STORE.FAIL',
  },
  GET_EBAY_LINK: {
    REQUEST: 'GET_EBAY_LINK.REQUEST',
    SUCCESS: 'GET_EBAY_LINK.SUCCESS',
    FAIL: 'GET_EBAY_LINK.ERROR',
  },
  GET_SHOPBASE_LINK: {
    REQUEST: 'GET_SHOPBASE_LINK.REQUEST',
    SUCCESS: 'GET_SHOPBASE_LINK.SUCCESS',
    FAIL: 'GET_SHOPBASE_LINK.ERROR',
  },
  GET_SHOPIFY_LINK: {
    REQUEST: 'GET_SHOPIFY_LINK.REQUEST',
    SUCCESS: 'GET_SHOPIFY_LINK.SUCCESS',
    FAIL: 'GET_SHOPIFY_LINK.ERROR',
  },
  GET_AMAZON_LINK: {
    REQUEST: 'GET_AMAZON_LINK.REQUEST',
    SUCCESS: 'GET_AMAZON_LINK.SUCCESS',
    FAIL: 'GET_AMAZON_LINK.ERROR',
  },
  GET_ETSY_LINK: {
    REQUEST: 'GET_ETSY_LINK.REQUEST',
    SUCCESS: 'GET_ETSY_LINK.SUCCESS',
    FAIL: 'GET_ETSY_LINK.ERROR',
  },

  //Step to connect store Shopify
  CHECK_CONNECT_SHOPIFY: {
    REQUEST: 'CHECK_CONNECT_SHOPIFY.REQUEST',
    SUCCESS: 'CHECK_CONNECT_SHOPIFY.SUCCESS',
    FAIL: 'CHECK_CONNECT_SHOPIFY.ERROR',
  },
  GET_URL_SHOPIFY: {
    REQUEST: 'GET_URL_SHOPIFY.REQUEST',
    SUCCESS: 'GET_URL_SHOPIFY.SUCCESS',
    FAIL: 'GET_URL_SHOPIFY.ERROR',
  },
  CONNECT_SHOPIFY: {
    REQUEST: 'CONNECT_SHOPIFY.REQUEST',
    SUCCESS: 'CONNECT_SHOPIFY.SUCCESS',
    FAIL: 'CONNECT_SHOPIFY.ERROR',
  },
  IS_CONNECT_SHOPIFY: 'IS_CONNECT_SHOPIFY',
};

export function createStore(store) {
  return {
    type: actionType.CREATE_STORE.REQUEST,
    store,
  };
}
export function createEbayStore(store) {
  return {
    type: actionType.CREATE_EBAY_STORE.REQUEST,
    store,
  };
}
export function createAmazonStore(store) {
  return {
    type: actionType.CREATE_AMAZON_STORE.REQUEST,
    store,
  };
}

export function createShopBaseStore(store) {
  return {
    type: actionType.CREATE_SHOPBASE_STORE.REQUEST,
    store,
  };
}
export function createEtsyStore(store) {
  return {
    type: actionType.CREATE_ETSY_STORE.REQUEST,
    store,
  };
}
export function getEbayLink(params) {
  return {
    type: actionType.GET_EBAY_LINK.REQUEST,
    params,
  };
}

export function getAmazonLink(params) {
  return {
    type: actionType.GET_AMAZON_LINK.REQUEST,
    params,
  };
}

export function getShopBaseLink(params) {
  return {
    type: actionType.GET_SHOPBASE_LINK.REQUEST,
    params,
  };
}

export function getEtsyLink(params) {
  return {
    type: actionType.GET_ETSY_LINK.REQUEST,
    params,
  };
}

export function getShopifyLink(data) {
  return {
    type: actionType.GET_SHOPIFY_LINK.REQUEST,
    data,
  };
}

//Step to connect store Shopify
export function checkConnectWithShopifyAction(params) {
  return {
    type: actionType.CHECK_CONNECT_SHOPIFY.REQUEST,
    params,
  };
}
export function getUrlShopifyAction(data) {
  return {
    type: actionType.GET_URL_SHOPIFY.REQUEST,
    data,
  };
}
export function connectToShopifyAction() {
  return {
    type: actionType.CONNECT_SHOPIFY.REQUEST,
  };
}

export function isConnectToShopifyAction() {
  return {
    type: actionType.IS_CONNECT_SHOPIFY,
  };
}
