import React from 'react';
import { removePaypal } from 'apis/payment_menthod';
import { useDispatch } from 'react-redux';
import { setLoading } from 'scenes/BillingPaymentAccount/actions';
import { notification, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;
function RemovePaypalAccountButton({ accountId, getPaypalAccounts }) {
  const dispatch = useDispatch();
  const handleOnRemovePaypalAccount = () => {
    confirm({
      title: 'Remove this Paypal account?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will not be able to top up to G-Wallet or make payment with this account anymore.',
      cancelText: 'Cancel',
      okText: 'Remove',
      onOk() {
        dispatch(setLoading(true));
        removePaypal(accountId)
          .then((response) => {
            dispatch(setLoading(false));
            if (response?.code === 'success') {
              notification.success({
                message:
                  response?.clientMsg ||
                  'Remove your Paypal account successfully.',
              });
              getPaypalAccounts();
            } else {
              notification.warn({
                message:
                  response?.[0]?.clientMsg || 'Remove your Paypal account failed.',
              });
            }
          })
          .catch((error) => {
            dispatch(setLoading(false));
          });
      },
      onCancel() {},
    });
  };
  return (
    <button className='btn btn-link text-danger' type='button' onClick={handleOnRemovePaypalAccount}>
      Remove account
    </button>
  );
}

export default React.memo(RemovePaypalAccountButton);