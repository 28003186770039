export const actionType = {
  LIST_CAMPAIGN_TO_EXPORT: {
      REQUEST: "LIST_CAMPAIGN_TO_EXPORT.REQUEST",
      SUCCESS: "LIST_CAMPAIGN_TO_EXPORT.SUCCESS",
      FAIL: "LIST_CAMPAIGN_TO_EXPORT.FAIL"
  },
  EXPORT_FEED_FORM_AMAZON: {
    REQUEST: "EXPORT_FEED_FORM_AMAZON.REQUEST",
    SUCCESS: "EXPORT_FEED_FORM_AMAZON.SUCCESS",
    FAIL: "EXPORT_FEED_FORM_AMAZON.FAIL"
  }
};

export const listCampaignsToExport = (listCampaignId) => {
  return {
    type: actionType.LIST_CAMPAIGN_TO_EXPORT.REQUEST,
    listCampaignId
  }
}

export const exportFeedFormAmazon = (data) => {
  return {
    type: actionType.EXPORT_FEED_FORM_AMAZON.REQUEST,
    data
  }
}


