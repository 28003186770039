import { 
    ORDER_TRACKING_LIST_REQUEST,
    ORDER_TRACKING_LIST_SUCCESS,
    ORDER_TRACKING_LIST_FAIL 
} from './constants'

const initialState = {
    data: [],
    loading: false,
    params: {}
}

function ListReducer(state = initialState, action){
    switch(action.type) {
        case ORDER_TRACKING_LIST_REQUEST:
            return {
                ...state,
                data: [],
                loading: true,
                params: action.options               
            }
        case ORDER_TRACKING_LIST_SUCCESS:
            return {
                ...state,
                data: action.response,
                loading: false
            }
        case ORDER_TRACKING_LIST_FAIL:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export default ListReducer