import styled from 'styled-components';
import PageContainer from 'components/PageContainer';

export const Wrapper = styled(PageContainer)`
  .cta {
    display: flex;
    margin-top: 24px;
    gap: 12px;
    width: 100%;
    justify-content: flex-end;
  }
`;
