import {
    ERROR_25,
    ERROR_300,
    ERROR_600,
    ERROR_700,
    GRAY_400,
    GRAY_600,
    GRAY_900,
    PRIMARY_500,
  } from "components/colors";
  import styled from "styled-components";
  
  export const Wrapper = styled.div`
    .left-form {
      width: 480px;
      max-width: 100%;
      justify-content: normal;
      padding-top: 60px;
    }
    .form-container {
      margin-top: 90px;
    }
    h2.header {
      margin-top: 20px;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: ${GRAY_900};
    }
    p.message {
      margin-top: 12px;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: ${GRAY_600};
    }
    p.cta {
      margin-top: 32px;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      a {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${PRIMARY_500};
      }
    }
    
    a.back-cta {
      display: inline-block;
      margin-top: 32px;
      color: ${GRAY_600};
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      svg {
        width: 20px;
        height: 20px;
        stroke: ${GRAY_600};
        margin-right: 12px;
      }
    }
    .help-tooltip {
      width: 16px;
      height: 16px;
      stroke: ${GRAY_400};
      margin-left: 6px;
      display: inline-block;
    }
    .error-list {
      background: ${ERROR_25};
      border: 1px solid ${ERROR_300};
      border-radius: 12px;
      padding: 16px 18px;
      width: 100%;
      list-style: none;
      li {
        position: relative;
        padding-left: 34px;
        span {
          display: block;
          &.error {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: ${ERROR_700};
          }
          &.detail {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: ${ERROR_700};
          }
        }
      }
      svg {
        stroke: ${ERROR_600};
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .back2login {
      margin-top: 32px;
      .btn-back-cta {
        width: 100%;
        display: inline-block;
      }
    }
  `;
  