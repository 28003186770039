import { combineReducers } from "redux";
import { actionType } from "./actions";

const initialState = {
  loading: false,
  status: {},
};
const reducer = combineReducers({
  userSteps: (state = initialState, action) => {
    switch (action.type) {
      case actionType.DASHBOARD.USER_STEPS.REQUEST:
        return {
          ...state,
          loading: true,
        }
      case actionType.DASHBOARD.USER_STEPS.SUCCESS:
        return {
          ...state,
          loading: false,
          status: action.payload,
        }
      case actionType.DASHBOARD.USER_STEPS.ERROR:
        return {
          ...state,
          loading: false,
        }
      default:
        return state;
    }
  },
});

export default reducer;
