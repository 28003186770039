import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CheckIcon } from "images/icons/check-circle.svg";
import { ReactComponent as NextIcon } from "images/icons/arrow-narrow-right.svg";
import classNames from 'classnames';
import { getUserStepsRequestAction } from 'scenes/Dashboard/actions';
import { useDispatch } from 'react-redux';

// isDone: true,
// title: "Add billing method",
// description: 'Add a valid payment method for fulfillments. We won\'t bill you until we accept your order.',
// url: '/billing/payment',
// cta: 'Add payment',
export default ({ info, data }) => {
  const history = useHistory();
  const d = useDispatch();
  return info.map((item, index) => {
    const isDone = data?.[item.apiMap];
    return (
      <div
        key={'usersteps_' + item?.title + '_' + index}
        className={classNames('item', { active: isDone, noclick: !item.url })}
        onClick={() => {
          if (typeof item.bonusAction === 'function') {
            item.bonusAction(() => d(getUserStepsRequestAction()));
          }
          if (item.url.indexOf('https://') !== -1) {
            window.open(item.url, "_blank");
          } else {
            history.push(item.url);
          }
        }}>
        {isDone ? (<CheckIcon />) : (<NextIcon />)}
        <div className="info">
          <div className="content-wrapper">
            <span className="title">
              {item.title}
            </span>
            <div className="description">
              <span dangerouslySetInnerHTML={{
                __html: item.description,
              }}></span>
            </div>
          </div>
        </div>
      </div>
    );
  });
};