import React, { Component } from 'react';
import { Upload, notification } from 'antd';
import { API_TOKEN_KEY, S3_CDN } from '../../web.config'
import {
  UploadOutlined
} from '@ant-design/icons';
import classNames from 'classnames';
import { createFileName, createFolderName, getCookie, getSizeImage, resizeImage } from "../helper";
import axios from "axios";
import { getPresignedUrlS3 } from "apis/image_design"
import getMomentInstance from "../moment";
import slugify from "utils/slugify"
class FieldUploadDragger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listImageId: [],
    };
  }

  render() {
    const {
      setLoading = null,
      changeFieldValue,
      maximumFile,
      setShowListImage,
      requestName,
      currStore = {},
      method,
      data = {},
      url = '',
      multiple = false,
      typeUpload = 'avatar',
      showUploadList,
      cusId = 0,
      prefixName = 'artwork',
      customPrefix = 'tmp'
    } = this.props;
    const props = {
      accept: ".png",
      listType: "picture",
      showUploadList: showUploadList,
      name: requestName,
      multiple: multiple ? true : false,
      action: url,
      headers: {
        // common: {
        Authorization: `Bearer ${getCookie(API_TOKEN_KEY) /* auth cookie */}`,
        storeid: currStore
        // }
      },
      method: method,
      data,
      customRequest: async (
        {
          action,
          data,
          file,
          filename,
          headers,
          onError,
          onProgress,
          onSuccess,
          withCredentials
        }
      ) => {
        const folderName = createFolderName(cusId);
        const extension = file.name.split('.').pop();
        const fileName = createFileName(extension || 'png', prefixName, cusId);
        const originalName = file.name.replace(`.${extension}`, '');
        const fileType = `image/${extension}`;
        const keyToCreatePresignedUrl = `${customPrefix}/${folderName}/${getMomentInstance(null, 0, false, true)}/${fileName}`;
        const response = await getPresignedUrlS3(keyToCreatePresignedUrl, currStore, fileType);
        if (response && response.code === 'success') {
          axios.put(response.data.url, file, {
            headers: {
              'Content-Type': fileType
            }
          }).then(response => {
            if (parseInt(response.status) === 200) {
              onSuccess({
                code: 'success',
                url: `${S3_CDN}/${keyToCreatePresignedUrl}`,
                folderName: folderName,
                fileName: fileName,
                fileType: fileType,
                extension: extension,
                originalName: originalName
              }, file);
            } else {
              onError();
            }
          });
        }
      },
      beforeUpload: (file, fileList) => {
        if (file.type !== 'image/png') {
          notification.error({
            className: "notic1",
            message: (`${file.name} is not a image file.`)
          });
          return false;
        }
        if (fileList.length <= maximumFile) {
          setShowListImage(true);
          if (file.size <= 2e8) {
            if (fileList[0] && fileList[0]['uid'] == file.uid) {
              const newListImageId = {};
              for (let index = 0; index <= fileList.length - 1; index++) {
                if (fileList[index]['size'] <= 2e8) {
                  newListImageId[fileList[index]['uid']] = index + 1;
                  const extension = file.name.split('.').pop();
                  const originalName = file.name.replace(`.${extension}`, '');
                  changeFieldValue(`listImages.${index + 1}`, {
                    campaignTitle: originalName,
                    fileName: `${originalName}.${extension}`,
                    imageLink: '',
                    urlPath: data?.prefix && data?.includePrefix === 'yes' ? `/${data.prefix}/${slugify(originalName)}` : `/${slugify(originalName)}`,
                    status: 0
                  })
                }
              }
              this.setState({ listImageId: newListImageId });
            }
          } else {
            notification.warn({
              className: "notic1",
              message: (`${file.name} is larger than 200MB.`)
            });
            return false;
          }
        } else {
          notification.error({
            className: "notic1",
            message: (`The maximum number of files upload: ${maximumFile} files`)
          });
          return false;
        }
        return true;
      },
      onChange: (info) => {
        const { status, response, uid, originFileObj } = info.file;
        if (status === 'uploading') {
          setLoading({ loading: true })
        }
        if (status === "done" && response.code === 'success') {
          getSizeImage(response.url, async (width, height) => {
            const resizedImage = await resizeImage(originFileObj, 650, Math.round((650 * height) / width), 'file', response.extension.toUpperCase());
            const keyToCreatePresignedUrl2 = `${customPrefix}/${response.folderName}/${getMomentInstance(null, 0, false, true)}/thumbnail/${response.fileName}`;
            const response2 = await getPresignedUrlS3(keyToCreatePresignedUrl2, currStore, response.fileType);
            if (response2 && response2.code === 'success') {
              axios.put(response2.data.url, resizedImage, {
                headers: {
                  'Content-Type': response.fileType
                }
              }).then(response3 => {
                if (parseInt(response3.status) === 200) {
                  if (typeUpload === 'upload-artwork-to-bulk') {
                    const currItemImage = {
                      campaignTitle: response.originalName,
                      fileName: `${response.originalName}.${response.extension}`,
                      imageLink: `${S3_CDN}/${keyToCreatePresignedUrl2}`,
                      urlPath: data?.prefix && data?.includePrefix === 'yes' ? `/${data.prefix}/${slugify(response.originalName)}` : `/${slugify(response.originalName)}`,
                      status: 1
                    };
                    setLoading({ loading: false })
                    changeFieldValue(`listImages.${this.state.listImageId[uid]}`, currItemImage)
                  } else {
                    notification.success({
                      className: "notic2",
                      message: `${info.file.name} file upload successfully.`
                    });
                    this.setState({ loading: false });
                  }
                } else {
                  notification.error({
                    className: "notic1",
                    message: `${info.file.name} file upload failed.`
                  });
                  this.setState({ loading: false });
                }
              });
            }
          })
        } else if (status === "error") {
          notification.warn({
            className: "notic1",
            message: (`${info.file.name} file upload failed.`)
          });
          setLoading({ loading: false })
        }
      },
    };
    return (
      <div className={classNames('w-100 upload-w-100', this.props.classNameContainer)}>
        <div className={classNames({ 'd-none': (this.props.showListImage && this.props.listImages.length > 0) })}>
          <Upload.Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Upload.Dragger>
        </div>
      </div>
    );
  }
};

export default FieldUploadDragger;