export const STORE_EDIT_REQUEST = 'STORE_EDIT_REQUEST';
export const STORE_EDIT_SUCCESS = 'STORE_EDIT_SUCCESS';
export const STORE_EDIT_FAIL = 'STORE_EDIT_FAIL';

export const STORE_DETAIL_REQUEST = 'STORE_DETAIL_REQUEST';
export const STORE_DETAIL_SUCCESS = 'STORE_DETAIL_SUCCESS';
export const STORE_DETAIL_FAIL = 'STORE_DETAIL_FAIL';

export const STORE_EDIT_EBAY_REQUEST = 'STORE_EDIT_EBAY_REQUEST';
export const STORE_EDIT_EBAY_SUCCESS = 'STORE_EDIT_EBAY_SUCCESS';
export const STORE_EDIT_EBAY_FAIL = 'STORE_EDIT_EBAY_FAIL';

export const STORE_EDIT_ETSY_REQUEST = 'STORE_EDIT_ETSY_REQUEST';
export const STORE_EDIT_ETSY_SUCCESS = 'STORE_EDIT_ETSY_SUCCESS';
export const STORE_EDIT_ETSY_FAIL = 'STORE_EDIT_ETSY_FAIL';

//Update token Etsy
export const LINK_ETSY_TOKEN_REQUEST = 'LINK_ETSY_TOKEN_REQUEST';
export const LINK_ETSY_TOKEN_SUCCESS = 'LINK_ETSY_TOKEN_SUCCESS';
export const LINK_ETSY_TOKEN_FAIL = 'LINK_ETSY_TOKEN_FAIL';

export const UPDATE_ETSY_TOKEN_REQUEST = 'UPDATE_ETSY_TOKEN_REQUEST';
export const UPDATE_ETSY_TOKEN_SUCCESS = 'UPDATE_ETSY_TOKEN_SUCCESS';
export const UPDATE_ETSY_TOKEN_FAIL = 'UPDATE_ETSY_TOKEN_FAIL';

export const STORE_EDIT_SHOPBASE_REQUEST = 'STORE_EDIT_SHOPBASE_REQUEST';
export const STORE_EDIT_SHOPBASE_SUCCESS = 'STORE_EDIT_SHOPBASE_SUCCESS';
export const STORE_EDIT_SHOPBASE_FAIL = 'STORE_EDIT_SHOPBASE_FAIL';

export const STORE_EDIT_SHOPBASE_TOKEN_REQUEST =
  'STORE_EDIT_SHOPBASE_TOKEN_REQUEST';
export const STORE_EDIT_SHOPBASE_TOKEN_SUCCESS =
  'STORE_EDIT_SHOPBASE_TOKEN_SUCCESS';
export const STORE_EDIT_SHOPBASE_TOKEN_FAIL = 'STORE_EDIT_SHOPBASE_TOKEN_FAIL';

export const STORE_EDIT_SHOPBASE_NORMAL_REQUEST =
  'STORE_EDIT_SHOPBASE_NORMAL_REQUEST';
export const STORE_EDIT_SHOPBASE_NORMAL_SUCCESS =
  'STORE_EDIT_SHOPBASE_NORMAL_SUCCESS';
export const STORE_EDIT_SHOPBASE_NORMAL_FAIL =
  'STORE_EDIT_SHOPBASE_NORMAL_FAIL';

export const LINK_SHOPBASE_TOKEN_REQUEST = 'LINK_SHOPBASE_TOKEN_REQUEST';
export const LINK_SHOPBASE_TOKEN_SUCCESS = 'LINK_SHOPBASE_TOKEN_SUCCESS';
export const LINK_SHOPBASE_TOKEN_FAIL = 'LINK_SHOPBASE_TOKEN_FAIL';

export const LINK_SHOPBASE_MERCHANT_REQUEST = 'LINK_SHOPBASE_MERCHANT_REQUEST';
export const LINK_SHOPBASE_MERCHANT_SUCCESS = 'LINK_SHOPBASE_MERCHANT_SUCCESS';
export const LINK_SHOPBASE_MERCHANT_FAIL = 'LINK_SHOPBASE_MERCHANT_FAIL';

export const GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_REQUEST =
  'GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_REQUEST';
export const GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_SUCCESS =
  'GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_SUCCESS';
export const GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_FAIL =
  'GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_FAIL';

export const UPDATE_SHOPIFY_TOKEN_REQUEST = 'UPDATE_SHOPIFY_TOKEN_REQUEST';
export const UPDATE_SHOPIFY_TOKEN_SUCCESS = 'UPDATE_SHOPIFY_TOKEN_SUCCESS';
export const UPDATE_SHOPIFY_TOKEN_FAIL = 'UPDATE_SHOPIFY_TOKEN_FAIL';

export const UPDATE_SHOPIFY_STORE_REQUEST = 'UPDATE_SHOPIFY_STORE_REQUEST';
export const UPDATE_SHOPIFY_STORE_SUCCESS = 'UPDATE_SHOPIFY_STORE_SUCCESS';
export const UPDATE_SHOPIFY_STORE_FAIL = 'UPDATE_SHOPIFY_STORE_FAIL';

export const LINK_AMAZON_TOKEN_REQUEST = ' LINK_AMAZON_TOKEN_REQUEST';
export const LINK_AMAZON_TOKEN_SUCCESS = ' LINK_AMAZON_TOKEN_SUCCESS';
export const LINK_AMAZON_TOKEN_FAIL = ' LINK_AMAZON_TOKEN_FAIL';

export const UPDATE_AMAZON_TOKEN_REQUEST = 'UPDATE_AMAZON_TOKEN_REQUEST';
export const UPDATE_AMAZON_TOKEN_SUCCESS = 'UPDATE_AMAZON_TOKEN_SUCCESS';
export const UPDATE_AMAZON_TOKEN_FAIL = 'UPDATE_AMAZON_TOKEN_FAIL';
