import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input, AutoComplete } from 'antd';
import classNames from 'classnames'

const formatNumber = (value) => {
  value += '';
  value = value.replace(/,/g, '')
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] || list[1] === '' ? `.${list[1]}` : ''}`;
}

const FieldNumber = ({
  input: { value, onBlur, onChange },
  meta: { invalid, error, touched, submitFailed },
  prefix,
  suffix,
  addonBefore,
  addonAfter,
  className,
  classNameContainer = "",
  size = 'large',
  disabled,
  placeholder,
  options,
  min,
  max,
  float = true
}) => {
  const { t } = useTranslation()

  const handleBlur = () => {
    let valueTemp = String(value)
    let value_str = String(value)
    if (value_str.charAt(value_str.length - 1) === '.' || value_str === '-') {
      valueTemp = value_str.slice(0, -1);
    }
    const result = valueTemp.replace(/0*(\d+)/, '$1')
    onChange(result === '-0' ? '0' : result);

    if (onBlur) {
      onBlur();
    }
  };
  const handleChange = ({ target }) => {
    let result = String(target.value).replace(/,/g, '') || '';
    if (result === '') {
      onChange(result);
    }
    const reg = float ? /^[0-9,]*(\.[0-9]*)?$/ : /^-?[0-9,]*?$/;
    if (!isNaN(result) && !float) {
      result = parseInt(result) + ''
    }

    if ((!isNaN(result) && reg.test(result)) || result === '') {
      if (!isNaN(min) && result < min) {
        return;
      }
      if (!isNaN(max) && result > max) {
        return;
      }
      onChange(result);
    }
  }

  return (
    <div className={classNames("position-relative w-100 input-text-right", classNameContainer)}>
      {options ? <AutoComplete
        style={{ width: '100%' }}
        disabled={disabled}
        className={classNames('text-input-right', className)}
        options={options.map(item => ({ ...item, value: String(item.value) }))}
        value={value === undefined ? null : formatNumber(value)}
        onBlur={handleBlur}
        onSelect={(v) => handleChange({ target: { value: v } })}
        onChange={(v) => handleChange({ target: { value: v } })}
      >
        <Input
          size={size}
          placeholder={t(placeholder)}
          disabled={disabled}
          className={classNames(className)}
          prefix={prefix}
          suffix={suffix}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          onFocus={(e) => e.target.select()}
        />
      </AutoComplete> :
        <Input
          style={{ width: '100%' }}
          size={size}
          placeholder={t(placeholder)}
          disabled={disabled}
          className={classNames(className)}
          prefix={prefix}
          suffix={suffix}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          value={value === undefined ? null : formatNumber(value)}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={(e) => e.target.select()}
        />
      }
      {invalid && <hr className="border-danger m-0 position-absolute" style={{ width: '90%', left: '5%', top: size === 'middle' ? 31 : 39 }} />}
      {(touched && submitFailed && error) && <span className="invalid-feedback">{error}</span>}
    </div>
  )
}
export default FieldNumber
