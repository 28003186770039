import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { Table, Popconfirm, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import getMomentInstance from "utils/moment";
import { getList, destroy } from "./actions";
import {
  getOptionLabel,
  options_active,
  options_condition,
  options_condition_field,
} from "utils/options";
import edit from "images/edit.svg";
import deleteIcon from "images/deleteIcon.svg";
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 10, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getList({
      ...params,
    });
  };
  render() {
    const { collections, destroying, pageName } = this.props;
    const columns = [
      {
        title: <b>ID</b>,
        dataIndex: "id",
        key: "id",
        align: "center",
        render: (value) => <div>#{value}</div>,
      },
      {
        title: <b>COLLECTION NAME</b>,
        dataIndex: "name",
        align: "center",
        key: "name",
        width: "20%",
        render: (text) => <span>{text}</span>,
      },
      {
        title: (
          <b className="d-flex justify-content-center align-items-center">
            PRODUCT CONDITIONS
          </b>
        ),
        dataIndex: "conditionMatch",
        key: "conditionMatch",
        width: "20%",
        render: (value) => {
          if (!value) {
            return null;
          }
          return value.map((item, index) => (
            <div key={index}>
              {getOptionLabel(options_condition_field, item.field, false)}{" "}
              {getOptionLabel(options_condition, item.operator, false)}{" "}
              {item.value}
            </div>
          ));
        },
      },
      {
        title: <b>LINK</b>,
        dataIndex: "age",
        align: "center",
        key: "age",
      },
      {
        title: <b>CREATED TIME</b>,
        dataIndex: "createdAt",
        align: "center",
        key: "createdAt",
        render: (value) => getMomentInstance(value, 0, true, false),
      },
      {
        title: <b>STATUS</b>,
        key: "status",
        align: "center",
        dataIndex: "status",
        render: (value) => getOptionLabel(options_active, value),
      },
      {
        title: <b>ACTION</b>,
        // fixed: "right",
        align: "center",
        render: (record) => (
                  <div>
                  <Tooltip title="Edit" placement="top">
                    {" "}
                    <Link to={`/customer-collection/${record.id}/edit`} className="mr-1">
                      <img src={edit} height={21}  alt="edit-icon"/>
                    </Link>
                  </Tooltip>
                  <Popconfirm
                    title="Are you sure to delete this record?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => this.props.destroy(record.id)}
                  >
                    <Link>
                      {" "}
                      <img src={deleteIcon} height={21}  alt="delete icon"/>
                    </Link>
                  </Popconfirm>
                </div>
        ),
      },
    ];
    return (
      <div>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center my-3">
            <h1 className="h3 mb-0 font-weight-bold">{pageName}</h1>
            <div>
              <Link
                to="/customer-collection/create"
                className="btn btn-primary d-flex align-items-center"
              >
                <PlusCircleOutlined style={{ marginRight: 10 }} /> Create
                Collection
              </Link>
            </div>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={collections.data}
            loading={collections.loading || destroying}
            scroll={{ x: 1000 }}
            pagination={{
              showTotal: (total) => `Total ${total} items`,
              total: collections.total,
              // pageSize: parseInt(collections.params.perPage),
              pageSize: parseInt(
                collections.params.perPage > collections.total
                  ? collections.total
                  : collections.params.perPage
              ),
              current: collections.params.page,
              showSizeChanger: true,
              onShowSizeChange: (current, perPage) =>
                this.fetchData({ ...collections.params, perPage }),
              onChange: (page, perPage) =>
                this.fetchData({ ...collections.params, page, perPage }),
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    collections: state.collection.list.collections,
    destroying: state.collection.list.destroying,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
    destroy: (id) => {
      dispatch(destroy(id));
    },
  })
)(List);
