import { takeLatest, call, put, all} from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import {
  getDetailApiKey,
  createApiKey,
  removeApiKey,
} from "apis/general_settings";
function* createApiKeySaga(action) {
  try {
    const response = yield call(createApiKey);
    if (response.code === "success") {
      yield all([
        put({ type: TYPE.CREATE_API_KEY.SUCCESS, response }),
        put({ type: TYPE.DETAIL_API_KEY.REQUEST }),
      ]);
    } else {
      yield put({ type: TYPE.CREATE_API_KEY.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.CREATE_API_KEY.ERROR, error })]);
  }
}
function* detailApiKeySaga(action) {
  try {
    const response = yield call(getDetailApiKey);
    if (response.code === "success" && response.data.apiKey) {
      yield all([put({ type: TYPE.DETAIL_API_KEY.SUCCESS, ...response })]);
    } else {
      yield all([
        yield put({
          type: TYPE.DETAIL_API_KEY.ERROR,
          error: { message: "" },
        }),
      ]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.DETAIL_API_KEY.ERROR, error })]);
  }
}
function* removeApiKeySaga(action) {
  try {
    const response = yield call(removeApiKey);
    if (response.code === "success") {
      yield all([
        put({ type: TYPE.DELETE_API_KEY.SUCCESS, ...response }),
        put({ type: TYPE.DETAIL_API_KEY.REQUEST }),
      ]);
    } else {
      yield put({ type: TYPE.DELETE_API_KEY.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.DELETE_API_KEY.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.CREATE_API_KEY.REQUEST, createApiKeySaga);
  yield takeLatest(TYPE.DETAIL_API_KEY.REQUEST, detailApiKeySaga);
  yield takeLatest(TYPE.DELETE_API_KEY.REQUEST, removeApiKeySaga);
}

export default watcher;
