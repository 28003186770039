import { actionType as TYPE } from './actions';
import { notification } from 'antd';

const initialState = {
  updating: false,
  listCountry: {
    data: [],
    loading: false,
  },
  deleteAccount: {
    loading: false,
  },
  connectSocial: {
    loading: false,
    data: {},
  },
  disconnectSocial: {
    loading: false,
    data: {},
  },
};

function reducer(state = initialState, action) {
  //Delete account
  switch (action.type) {
    case TYPE.DELETE_ACCOUNT.REQUEST:
      return {
        ...state,
        deleteAccount: {
          loading: true,
        },
      };
    case TYPE.DELETE_ACCOUNT.SUCCESS:
      notification.success({
        className: 'notic2',
        message: 'Delete account successfully.',
        duration: 5,
      });
      return {
        ...state,
        deleteAccount: {
          loading: false,
        },
      };
    case TYPE.DELETE_ACCOUNT.ERROR:
      notification.warn({
        className: 'notic1',
        message:
          action.error && action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Delete account failed.',
        duration: 5,
      });
      return {
        ...state,
        deleteAccount: {
          loading: false,
        },
      };
    //
    case TYPE.REMOVE_AVATAR.REQUEST:
      return {
        ...state,
        updating: true,
      };
    case TYPE.REMOVE_AVATAR.SUCCESS:
      notification.success({
        className: 'notic2',
        message: 'Remove avatar successfully.',
        duration: 5,
      });
      return {
        ...state,
        updating: false,
      };
    case TYPE.REMOVE_AVATAR.ERROR:
      notification.warn({
        className: 'notic1',
        message:
          action.error && action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Remove avatar failed.',
        duration: 5,
      });
      return {
        ...state,
        updating: false,
      };
    case TYPE.LIST_COUNTRY.REQUEST:
      return {
        ...state,
        listCountry: {
          ...state.listCountry,
          loading: false,
        },
      };
    case TYPE.LIST_COUNTRY.SUCCESS:
      return {
        ...state,
        listCountry: {
          ...state.listCountry,
          loading: false,
          data: action.data || [],
        },
      };
    case TYPE.LIST_COUNTRY.ERROR:
      return {
        ...state,
        listCountry: {
          ...state.listCountry,
          loading: false,
        },
      };
    case TYPE.UPDATE_PROFILE.REQUEST:
      return {
        ...state,
        updating: true,
      };
    case TYPE.UPDATE_PROFILE.SUCCESS:
      notification.success({
        className: 'notic2',
        message: 'Update profile successfully.',
        duration: 5,
      });
      return {
        ...state,
        updating: false,
      };
    case TYPE.UPDATE_PROFILE.ERROR:
      notification.warn({
        className: 'notic1',
        message:
          action.error && action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Update profile failed.',
        duration: 5,
      });
      return {
        ...state,
        updating: false,
      };

    //Handle connect social account
    case TYPE.CONNECT_SOCIAL_ACCOUNT.REQUEST:
      return {
        ...state,
        connectSocial: {
          loading: true,
        },
      };
    case TYPE.CONNECT_SOCIAL_ACCOUNT.SUCCESS:
      notification.success({
        className: 'notic2',
        message: action?.response.clientMsg,
        duration: 5,
      });
      return {
        ...state,
        connectSocial: {
          loading: false,
          data: action?.data,
        },
      };
    case TYPE.CONNECT_SOCIAL_ACCOUNT.ERROR:
      notification.warn({
        className: 'notic1',
        message: action?.response.clientMsg,
        duration: 5,
      });
      return {
        ...state,
        connectSocial: {
          loading: false,
        },
      };

    //Disconnect social account

    case TYPE.DISCONNECT_SOCIAL_ACCOUNT.REQUEST:
      return {
        ...state,
        disconnectSocial: {
          loading: true,
        },
      };
    case TYPE.DISCONNECT_SOCIAL_ACCOUNT.SUCCESS:
      notification.success({
        className: 'notic2',
        message: action?.response.clientMsg,
        duration: 5,
      });
      return {
        ...state,
        disconnectSocial: {
          loading: false,
          data: action?.data,
        },
      };
    case TYPE.DISCONNECT_SOCIAL_ACCOUNT.ERROR:
      notification.warn({
        className: 'notic1',
        message:
          action.response 
            ? action.response.clientMsg
            : "You can't disconnect this social account.",
        duration: 5,
      });
      return {
        ...state,
        disconnectSocial: {
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
