export const actionType = {
  LIST: {
    REQUEST: "GIFTMESSAGE.LIST.REQUEST",
    SUCCESS: "GIFTMESSAGE.LIST.SUCCESS",
    ERROR: "GIFTMESSAGE.LIST.ERROR",
  },
  DELETE: {
    REQUEST: "GIFTMESSAGES.DELETE.REQUEST",
    SUCCESS: "GIFTMESSAGES.DELETE.SUCCESS",
    ERROR: "GIFTMESSAGES.DELETE.ERROR",
  },
};

export function getList(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}
export function deleteGiftMessage(id) {
  return {
    type: actionType.DELETE.REQUEST,
    id,
  };
}
