import { Modal } from "antd";
import { GMButton } from "components/Button";
import {
  ERROR_500,
  GRAY_200,
  SHADOW_SM_0_06,
  SHADOW_SM_0_1
} from "components/colors";
import styled from "styled-components";

export const GMModal = styled(Modal)`
  .ant-modal {
    &-content {
      overflow: hidden;
      border: 1px solid ${GRAY_200};
      box-shadow: 0px 1px 3px ${SHADOW_SM_0_1}, 0px 1px 2px ${SHADOW_SM_0_06};
      border-radius: 12px;
      margin-top: 20px;
    }
    &-footer {
      button {
        ${GMButton.componentStyle.rules.join("")}
      }
    }
    &-body .ctas {
      display: flex;
      justify-content: flex-end;
    }
  }
  &.error-modal {
    .ant-modal-header .ant-modal-title {
      color: ${ERROR_500};
    }
  }
`;
