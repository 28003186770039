import React, { Component } from 'react';
import { Field, change } from 'redux-form';
import { Divider, Table, Space, notification } from 'antd';
import { PictureFilled } from '@ant-design/icons';
// import { renderImageAnt } from './index';
import nophotoImg from 'images/noPhoto.png';
import { Wrapper, WrapperColumn, WrapperIcon } from './styled';
import { GMButton } from 'components/Button';
import { ReactComponent as AddIcon } from 'images/icons/plus.svg';
import money from 'utils/money';
import SelectProductsColor from 'utils/Form/Search/SelectProductsColor';
import SelectProductsSize from 'utils/Form/Search/SelectProductsSize';
import RenderPreviewDesign from 'utils/Form/renderPreviewDesign';
import SelectProduct from 'utils/Form/Search/SelectProduct';
import renderInputText from 'utils/Form/renderInputText';
import { renderSelectAnt } from 'utils/Form';
import { ReactComponent as TrashIcon } from 'images/icons/trash-01.svg';
import RenderUploadBarcodeOrderLabel from 'utils/Form/components/RenderUploadBarcodeOrderLabel';
import UploadImageCreateOrder from '../UploadImageCreateOrder';
import GMNotify from 'components/Notification';

export default class renderOrderItemsFBA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      listPrintType: {},
      productIdSelected: '',
    };
  }
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const {
      fields,
      meta: { error },
      data,
      changeField,
      exproducts,
      cusId,
      shippingService,
    } = this.props;
    const { selectedRowKeys, listPrintType } = this.state;
    const listChildernColumns = [
      {
        title: 'Product name',
        dataIndex: 'productId',
        align: 'center',
        width: 350,
        key: 'productId',
        render: (text, record, i) => {
          let sidePrint = listPrintType[`${record?.productId}_${i}`];

          let placements = [];
          record?.exProduct?.listSidePrint &&
            Object.keys(record?.exProduct?.listSidePrint).forEach((item) => {
              placements.push({
                value: item,
                label: record?.exProduct?.listSidePrint?.[item],
              });
              if (placements.length > 0) {
                placements.unshift({
                  value: 'normal',
                  label: 'Normal',
                });
              }
            });
          return (
            <WrapperColumn>
              <Field
                name={`${fields.name}[${i}].productId`}
                component={SelectProduct}
                classNameContainer={'mb-4'}
                data={exproducts}
                onChoose={(id, data) => {
                  const listSidePrint = [];
                  this.setState({ productIdSelected: id });
                  if (data?.listSidePrint) {
                    Object.keys(data?.listSidePrint).forEach((item) => {
                      listSidePrint.push({
                        value: item,
                        label: data.listSidePrint[item],
                      });
                    });
                  }
                  if (listSidePrint.length > 0) {
                    listSidePrint.unshift({
                      value: 'normal',
                      label: 'Normal',
                    });
                  }
                  this.setState((state) => {
                    return {
                      listPrintType: {
                        ...state.listPrintType,
                        [`${id}_${i}`]: listSidePrint,
                      },
                    };
                  });
                  if (typeof changeField === 'function') {
                    changeField(`${fields.name}[${i}].colorId`, 0);
                    changeField(`${fields.name}[${i}].sizeId`, 0);
                    changeField(`${fields.name}[${i}].colorHex`, null);
                    changeField(`${fields.name}[${i}].sidePrint`, '');
                    changeField(`${fields.name}[${i}].barcode`, null);
                    changeField(`${fields.name}[${i}].barcodeId`, null);
                    changeField(
                      `${fields.name}[${i}].printLocation`,
                      data?.printLocation
                    );
                    changeField(
                      `${fields.name}[${i}].productDisplayName`,
                      data?.brand
                    );
                    changeField(
                      `${fields.name}[${i}].basePrice`,
                      data.basePrice
                    );
                    changeField(`${fields.name}[${i}].display`, data.display);
                    changeField(
                      `${fields.name}[${i}].mockup`,
                      data.frontImg || ''
                    );
                    changeField(
                      `${fields.name}[${i}].productType`,
                      data.type || ''
                    );
                    changeField(
                      `${fields.name}[${i}].productName`,
                      data.brand || ''
                    );
                  }
                }}
                placeholder="-- Select Product --"
              />
              <Field
                name={`${fields.name}[${i}].colorId`}
                component={SelectProductsColor}
                expId={fields.get(i).productId}
                classNameContainer={'mb-4'}
                placeholder="-- Select color --"
                onChoose={(id, data) => {
                  if (typeof changeField === 'function' && data) {
                    changeField(
                      `${fields.name}[${i}].colorHex`,
                      `#${data.value2}`
                    );
                    changeField(`${fields.name}[${i}].sizeId`, 0);
                  }
                }}
              />
              <Field
                name={`${fields.name}[${i}].sizeId`}
                component={SelectProductsSize}
                expId={fields.get(i).productId}
                classNameContainer={'mb-4'}
                colorId={fields.get(i).colorId}
                checkStockAvailability={true}
                placeholder="-- Select size --"
                onChoose={(data, currOption) => {
                  if (typeof changeField === 'function' && currOption) {
                    changeField(`${fields.name}[${i}].mockup`, currOption?.mockup);
                    changeField(`${fields.name}[${i}].basePrice`,currOption?.priceShow);
                  }
                }}
              />
              <Field
                name={`${fields.name}[${i}].quantity`}
                classNameContainer={'mb-4'}
                component={renderInputText}
                placeholder={'Quantity'}
                prevent
                type="number"
                isNumberInputQuantity={true}
                onClick={(event) => {
                  event.preventDefault();
                }}
                addonBefore={
                  parseInt(shippingService) === 9
                    ? 'Quantity/Item/1 box'
                    : 'Quantity'
                }
              />
              <div className="mb-4">
                {placements.length > 0 ? (
                  <Field
                    name={`${fields.name}[${i}].sidePrint`}
                    component={renderSelectAnt}
                    options={placements}
                    placeholder="-- Placements --"
                    onChange={(id, xx) => {
                      if (id === 'whole') {
                        changeField(
                          `${fields.name}[${i}].printLocation`,
                          'front'
                        );
                        changeField(`${fields.name}[${i}].designBackId`, '');
                        changeField(`${fields.name}[${i}].designBack`, '');
                      } else {
                        changeField(
                          `${fields.name}[${i}].printLocation`,
                          exproducts?.find(
                            (ep) => ep.id * 1 === data?.[i]?.productId * 1
                          )?.printLocation
                        );
                      }
                    }}
                  />
                ) : (
                  <div>
                    {listPrintType[`${record?.productId}_${i}`] &&
                      Array.isArray(
                        listPrintType[`${record?.productId}_${i}`]
                      ) &&
                      listPrintType[`${record?.productId}_${i}`].length > 0 && (
                        <Field
                          name={`${fields.name}[${i}].sidePrint`}
                          component={renderSelectAnt}
                          options={sidePrint}
                          placeholder="-- Placements --"
                          onChange={(id) => {
                            if (id === 'whole') {
                              changeField(
                                `${fields.name}[${i}].printLocation`,
                                'front'
                              );
                              changeField(
                                `${fields.name}[${i}].designBackId`,
                                ''
                              );
                              changeField(
                                `${fields.name}[${i}].designBack`,
                                ''
                              );
                            } else {
                              changeField(
                                `${fields.name}[${i}].printLocation`,
                                exproducts?.find(
                                  (ep) => ep.id * 1 === data?.[i]?.productId * 1
                                )?.printLocation
                              );
                            }
                          }}
                        />
                      )}
                  </div>
                )}
              </div>
              {parseInt(shippingService) === 9 && (
                <Field
                  name={`${fields.name}[${i}].barcode`}
                  component={RenderUploadBarcodeOrderLabel}
                  showLinkFile={true}
                  accept={'.pdf'}
                  changeField={(value) => {
                    changeField(`${fields.name}[${i}].barcodeId`, value);
                  }}
                  showUploadList={false}
                  typeUpload={'barcode'}
                  cusId={cusId}
                />
              )}
            </WrapperColumn>
          );
        },
      },
      {
        title: 'Mockup',
        dataIndex: 'mockup',
        key: 'mockup',
        align: 'center',
        width: 250,
        render: (text, record) => {
          return (
            <img
              src={record.mockup || nophotoImg}
              width="100"
              style={{
                backgroundColor: record.colorHex
                  ? `${record.colorHex}`
                  : 'transparent',
              }}
              alt="mockup"
            />
          );
        },
      },
      {
        title: 'Design',
        key: 'designFront',
        align: 'center',
        dataIndex: 'designFront',
        width: 250,
        render: (text, record, i) => {
          const colorString = record.colorHex
            ? `${record.colorHex}`
            : 'transparent';
          return (
            <Space>
              {record?.productId !== '0' ? (
                <div>
                  {' '}
                  {record?.printLocation?.includes('front,back') ? (
                    <div className="d-flex columns">
                      <div>
                        <Field
                          name={`${fields.name}[${i}].designFront`}
                          changeDesignFront={(value) => {
                            changeField(
                              `${fields.name}[${i}].designFront`,
                              value
                            );
                            value !== '' &&
                              notification.success({
                                message:
                                  'Assign design for order item - side front successfully.',
                                duration: 5,
                              });
                          }}
                          changeDesignBack={(value) => {
                            changeField(
                              `${fields.name}[${i}].designBack`,
                              value
                            );
                            value !== '' &&
                              notification.success({
                                message:
                                  'Assign design for order item - side back successfully.',
                                duration: 5,
                              });
                          }}
                          changeDesignFrontId={(value) =>
                            changeField(
                              `${fields.name}[${i}].designFrontId`,
                              value
                            )
                          }
                          changeDesignBackId={(value) =>
                            changeField(
                              `${fields.name}[${i}].designBackId`,
                              value
                            )
                          }
                          component={UploadImageCreateOrder}
                          printLocation={record.printLocation}
                          cusId={cusId}
                          isCreatingOrder={true}
                        />

                        <RenderPreviewDesign
                          isCreatingOrder={true}
                          colorHex={colorString}
                          exProductId={record.productId}
                          colorId={record.colorId}
                          sidePrint={'front'}
                          sizeId={record.sizeId}
                          design={record.designFront}
                          typePrint={record.sidePrint}
                          productName={record.productDisplayName}
                          printLocation={record.printLocation}
                        />
                      </div>
                      <div>
                        <Field
                          name={`${fields.name}[${i}].designBack`}
                          changeDesignFront={(value) => {
                            changeField(
                              `${fields.name}[${i}].designFront`,
                              value
                            );
                            value !== '' &&
                              notification.success({
                                message:
                                  'Assign design for order item - side front successfully.',
                                duration: 5,
                              });
                          }}
                          changeDesignBack={(value) => {
                            changeField(
                              `${fields.name}[${i}].designBack`,
                              value
                            );
                            value !== '' &&
                              notification.success({
                                message:
                                  'Assign design for order item - side back successfully.',
                                duration: 5,
                              });
                          }}
                          changeDesignFrontId={(value) =>
                            changeField(
                              `${fields.name}[${i}].designFrontId`,
                              value
                            )
                          }
                          changeDesignBackId={(value) =>
                            changeField(
                              `${fields.name}[${i}].designBackId`,
                              value
                            )
                          }
                          component={UploadImageCreateOrder}
                          printLocation={record.printLocation}
                          cusId={cusId}
                          isCreatingOrder={true}
                        />

                        <RenderPreviewDesign
                          isCreatingOrder={true}
                          colorHex={colorString}
                          exProductId={record.productId}
                          colorId={record.colorId}
                          sidePrint={'back'}
                          sizeId={record.sizeId}
                          typePrint={record.sidePrint}
                          design={record.designBack}
                          productName={record.productDisplayName}
                          printLocation={record.printLocation}
                        />
                      </div>
                    </div>
                  ) : record?.printLocation?.includes('front') ? (
                    <div>
                      <Field
                        name={`${fields.name}[${i}].designFront`}
                        changeDesignFront={(value) => {
                          changeField(
                            `${fields.name}[${i}].designFront`,
                            value
                          );
                          value !== '' &&
                            notification.success({
                              message:
                                'Assign design for order item - side front successfully.',
                              duration: 5,
                            });
                        }}
                        changeDesignBack={(value) => {
                          changeField(`${fields.name}[${i}].designBack`, value);
                          value !== '' &&
                            notification.success({
                              message:
                                'Assign design for order item - side back successfully.',
                              duration: 5,
                            });
                        }}
                        changeDesignFrontId={(value) =>
                          changeField(
                            `${fields.name}[${i}].designFrontId`,
                            value
                          )
                        }
                        changeDesignBackId={(value) =>
                          changeField(
                            `${fields.name}[${i}].designBackId`,
                            value
                          )
                        }
                        component={UploadImageCreateOrder}
                        printLocation={record.printLocation}
                        cusId={cusId}
                        isCreatingOrder={true}
                      />

                      <RenderPreviewDesign
                        isCreatingOrder={true}
                        colorHex={colorString}
                        exProductId={record.productId}
                        colorId={record.colorId}
                        sidePrint={'front'}
                        sizeId={record.sizeId}
                        design={record.designFront}
                        typePrint={record.sidePrint}
                        productName={record.productDisplayName}
                        printLocation={record.printLocation}
                      />
                    </div>
                  ) : (
                    <div>
                      <Field
                        name={`${fields.name}[${i}].designBack`}
                        changeDesignFront={(value) => {
                          changeField(
                            `${fields.name}[${i}].designFront`,
                            value
                          );
                          value !== '' &&
                            notification.success({
                              message:
                                'Assign design for order item - side front successfully.',
                              duration: 5,
                            });
                        }}
                        changeDesignBack={(value) => {
                          changeField(`${fields.name}[${i}].designBack`, value);
                          value !== '' &&
                            notification.success({
                              message:
                                'Assign design for order item - side back successfully.',
                              duration: 5,
                            });
                        }}
                        changeDesignFrontId={(value) =>
                          changeField(
                            `${fields.name}[${i}].designFrontId`,
                            value
                          )
                        }
                        changeDesignBackId={(value) =>
                          changeField(
                            `${fields.name}[${i}].designBackId`,
                            value
                          )
                        }
                        component={UploadImageCreateOrder}
                        printLocation={record.printLocation}
                        cusId={cusId}
                        isCreatingOrder={true}
                      />
                      <RenderPreviewDesign
                        isCreatingOrder={true}
                        colorHex={colorString}
                        exProductId={record.productId}
                        colorId={record.colorId}
                        sidePrint={'back'}
                        sizeId={record.sizeId}
                        typePrint={record.sidePrint}
                        design={record.designBack}
                        productName={record.productDisplayName}
                        printLocation={record.printLocation}
                      />
                    </div>
                  )}
                </div>
              ) : null}
            </Space>
          );
        },
      },
      {
        title: 'Price',
        dataIndex: 'basePrice',
        align: 'center',
        width: 120,
        key: 'basePrice',
        render: (text) => {
          return <b>{money(text)}</b>;
        },
      },
    ];
    // if (parseInt(shippingService) === 9) {
    //   listChildernColumns.push({
    //     title: 'Barcode',
    //     key: 'barcodeId',
    //     align: 'center',
    //     dataIndex: 'barcodeId',
    //     width: 250,
    //     render: (text, record, i) => {
    //       return (
    //         <div>
    //           <Field
    //             name={`${fields.name}[${i}].barcode`}
    //             component={renderImageAnt}
    //             showLinkFile={true}
    //             accept={'.pdf'}
    //             changeField={(value) => {
    //               changeField(`${fields.name}[${i}].barcodeId`, value);
    //             }}
    //             showUploadList={false}
    //             typeUpload={'barcode'}
    //             cusId={cusId}
    //           />
    //         </div>
    //       );
    //     },
    //   });
    // }
    listChildernColumns.push({
      title: '',
      key: 'action',
      width: 100,
      render: (text, record, i) =>
        fields.length >= 2 && (
          <div className="text-center">
            <WrapperIcon className="text-center">
              <TrashIcon
                onClick={() => {
                  fields.remove(i);
                }}
              />
            </WrapperIcon>
          </div>
        ),
    });

    return (
      <Wrapper>
        <div className="d-flex row justify-content-between align-items-center px-3 design-product">
          <div>
            <b>
              Products information {fields.length ? `(${fields.length})` : ''}
            </b>
          </div>
          <GMButton
            type="button"
            color={'SecondaryGray'}
            onClick={() =>
              fields.push({
                key: fields.length,
                productId: '0',
                colorId: '0',
                sizeId: '0',
                quantity: '0',
                price: 0,
                designFront: '',
                designBack: '',
                mockup: '',
                designFrontId: '',
                designBackId: '',
                barcode: '',
                barcodeId: '',
                printLocation: '',
              })
            }
            className="add-icon"
          >
            <b>
              <AddIcon className="mr-2" />
              Add item
            </b>
          </GMButton>
        </div>
        <div className="mb-4">
          <GMNotify
            type="desc"
            desc={
              <div className="desc">
                An extra fee will be applied for the second-side print of <b>Apparel</b> and <b>Ceramic Ornament</b> products.
              </div>
            }
          />
        </div>
        <Table
          pagination={false}
          columns={listChildernColumns}
          dataSource={data}
          size={'middle'}
          bordered
          key={`key`}
          scroll={{ x: 1000 }}
          rowClassName={(record, index) => (index % 2 !== 0 ? '' : '')}
        />
        {error && <span className="invalid-feedback d-block">{error}</span>}
        <Divider className="my-0" />
      </Wrapper>
    );
  }
}
