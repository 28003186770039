import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withSize } from 'react-sizeme'
import { ResponsiveBar } from '@nivo/bar'
import { Space, Empty } from 'antd';

// import { digits } from '@components/utils'

const index = props => {
    const { loading, data, title, indexBy, keys, colors={"scheme":"nivo"} } = props;
    return (
        <Fragment>
            {data.length === 0 && !loading && <Empty/>}
            {data.length > 0 && <div>
                <div className="text-center mb-3">
                    {title && <h5 className="mb-3"><b>{title}</b></h5>}
                    {data.map(({id, total, color })=>(
                        <Space key={id} className="mx-2"><span className="d-block" style={{width: 40, height: 15, border: `3px solid ${color}`}}></span>{ id }</Space>
                    ))}
                </div>
                <div style={{overflowX: 'auto'}}>
                    <div style={{width: '100%',height: 400}}>
                        <ResponsiveBar
                            data={data}
                            keys={keys}
                            indexBy={indexBy}
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            colors={colors}
                            enableLabel={false}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                        />
                    </div>
                </div>
            </div>}
        </Fragment>
    );
};

index.propTypes = {
    indexBy: PropTypes.string.isRequired,
    keys: PropTypes.array.isRequired
};

export default withSize()(index);
