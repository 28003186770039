import React from 'react';
import { Radio } from "antd";
import classNames from 'classnames';

export default ({ defaultValue = 0, options = [], meta: { asyncValidating, error }, classNameContainer = '', input }) => {
    return (
        <div className={classNames(classNameContainer, { 'async-validating': asyncValidating })}>
            <Radio.Group className="row m-0" value={input.value || defaultValue} style={{ width: '100%' }} onChange={(e) => input.onChange(e.target.value)}>
                {options.map(item => (
                    <div key={item.value} className="d-flex justify-content-start mb-3 col-6 p-0">
                        <Radio className={classNames('d-flex align-items-center ant-checkbox-wrapper-lg', { 'custom-radio-input': item.label.length === 0 })} value={item.value} >{item.label}</Radio>
                    </div>
                ))}
            </Radio.Group>
            {error && <span className="invalid-feedback d-block">{error}</span>}
        </div>
    );
}