import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { renderInputText } from 'utils/Form';
import { withRouter } from 'react-router-dom';
import validate from './validate';
import renderCheckboxGroup from 'utils/Form/renderCheckboxGroup';
import RenderAuthenticationSlider from 'utils/Form/RenderAuthenticationSlider';
import { renderDateTimePicker } from 'utils/Form';
import { GMTextbox } from 'components/InputFields';
import { Wrapper } from './styled';

import logo from 'images/logo_new_login.svg';
import { ScheduleOutlined } from '@ant-design/icons';
import { GMButton } from 'components/Button';

let Register = (props) => {
  const { handleSubmit } = props;
  return (
    <Wrapper className="container-fluid">
      <div className="row align-items-stretch justify-content-center">
        <div className="col-md-6">
          <div className="col-12 col-md-8 col-xl-6 m-auto min-vh-100 d-flex align-items-center px-0">
            <form onSubmit={handleSubmit} className="w-100 update-information">
              <div className="mb-3">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={logo}
                    style={{ width: '242px', height: '48px' }}
                    alt="logo"
                  />
                </div>
                <h3 className="text-center mt-5 mb-2">
                  Update your information
                </h3>
                <p className="text-center">
                  This will help us to better support you.
                </p>
              </div>
              <div>
                <div className="form-group">
                  <label className="name-field">Name</label>
                  <GMTextbox
                    name="name"
                    placeholder="Full name"
                    showError
                    maxLength={256}
                  />
                </div>
                <div className="form-group">
                  <label className="name-field">Birthday</label>
                  <Field
                    name="birthday"
                    placeholder="Birthday"
                    component={renderDateTimePicker}
                    hideTime={true}
                    addonBefore={<ScheduleOutlined />}
                  />
                </div>
                <div className="form-group">
                  <label className="name-field">Address</label>
                  <GMTextbox
                    name="address"
                    placeholder="Address"
                    showError
                    maxLength={256}
                  />
                </div>
                <div className="form-group">
                  <label className="name-field">Phone</label>
                  <GMTextbox
                    name="phone"
                    placeholder="Phone number"
                    type="phone"
                    showError
                    maxLength={'30'}
                  />
                </div>
                <div className="form-group">
                  <label className="name-field">Facebook</label>
                  <GMTextbox
                    name="facebook"
                    placeholder="Facebook link"
                    showError
                    maxLength={256}
                  />
                </div>
                <div className="form-group">
                  <label className="font-weight-bold text-dark">Sell on</label>
                  <Field
                    name="sellOn"
                    component={renderCheckboxGroup}
                    colSpan={24}
                    options={[
                      {
                        label: (
                          <p className="mb-0">
                            Marketplace (Amazon, Etsy, Ebay, ...)
                          </p>
                        ),
                        value: 1,
                      },
                      {
                        label: (
                          <p className="mb-0">
                            Platform (Shopify, Woocommerce, ...)
                          </p>
                        ),
                        value: 2,
                      },
                      {
                        label: <p className="mb-0">Others (website, ...)</p>,
                        value: 3,
                      },
                    ]}
                  />
                </div>
                <div className="text-center footer-login">
                  <Link to="/auth/login">Skip and continue</Link>
                </div>
                <div>
                  <div className="login-button">
                    <GMButton type="submit" size="lg" className="login-button">
                      Update
                    </GMButton>
                  </div>
                </div>
                <div className="text-center footer-login">
                  <span className="al">Already have an account?</span>
                  <Link className="sign-up ml-2" to="/auth/register">
                    Log in
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6 right-authentication-section">
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            <RenderAuthenticationSlider />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(
  connect()(
    reduxForm({
      form: 'formUpdateInformation',
      enableReinitialize: true,
      validate,
    })(Register)
  )
);
