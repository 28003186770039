import React, { Component } from 'react';
import { Card, Drawer } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import validate from './validate';
import { withRouter } from 'react-router-dom';
import AdvancedFilter from './AdvancedFilter';
import CloseIcon from 'images/x2.svg';
import { ReactComponent as MoreAction } from 'images/icons/filter-lines.svg';
import { GMButton } from 'components/Button';
class index extends Component {
  state = {
    advance_search: false,
  };
  onCloseAdvancedSearch = () => {
    this.setState({
      advance_search: false,
    });
  };
  onOpenAdvancedSearch = () => {
    this.setState({
      advance_search: true,
    });
  };
  render() {
    const {
      handleSubmit,
      handleAdvancedSubmit,
      initialValues,
      exproducts,
    } = this.props;
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="ml-2">
            <GMButton
              color={'SecondaryGray'}
              onClick={this.onOpenAdvancedSearch}
              size="lg"
              type="button"
              className='d-flex align-items-center'
              >
              <MoreAction  className='mr-2'/>
              Filter
            </GMButton>
          </div>
        </form>
        <Drawer
          title={<b className="Filter">Filter</b>}
          className="custom-filter"
          placement="right"
          closable={true}
          onClose={this.onCloseAdvancedSearch}
          visible={this.state.advance_search}
          closeIcon={
            <img src={CloseIcon} style={{ width: '20px' }} alt="close icon" />
          }
          width={this.props.is_mobile ? '80%' : '480px'}
        >
          <AdvancedFilter
            onCloseAdvancedSearch={this.onCloseAdvancedSearch}
            onSubmit={handleAdvancedSubmit}
            initialValues={initialValues}
            exproducts={exproducts}
          />
        </Drawer>
      </>
    );
  }
}

index = reduxForm({
  form: 'formFilterProduct',
  validate,
  enableReinitialize: true,
})(index);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change('formFilterProduct', field, value));
    },
  };
};
export default React.memo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(index))
);
