import React from "react";
import { Link } from "react-router-dom";
function RenderProductInfoMatchProduct({ order = {}, orderItem = {} }) {
  return orderItem && order && (
    <div>
      {orderItem.titleOnlineId && (
        <p
          dangerouslySetInnerHTML={{
            __html: (orderItem.titleOnlineId || "").replace(
              /(<? *script)/gi,
              "illegalscript"
            ),
          }}
        ></p>
      )}
      {orderItem.asinOnlineId && <p>ASIN: {orderItem.asinOnlineId}</p>}
      {orderItem.sellerSkuOnlineId && (
        <p>SellerSKU: {orderItem.sellerSkuOnlineId}</p>
      )}
      {orderItem.tmpContent && orderItem.tmpContent.style && (
        <p
          dangerouslySetInnerHTML={{
            __html: ("Style: " + (orderItem.tmpContent.style || "")).replace(
              /(<? *script)/gi,
              "illegalscript"
            ),
          }}
        ></p>
      )}
      {order.onlineId && <p>Order ID: {order.onlineId}</p>}
      {orderItem.tmpContent && orderItem.tmpContent.ship_service_level && (
        <p>Ship service: {orderItem.tmpContent.ship_service_level}</p>
      )}
      {order.onlineReference && <p>Reference: {order.onlineReference}</p>}
      <p>
        Order (Gearment):{" "}
        <Link to={`/orders/${order.id}`}>{order.name}</Link>
      </p>
      <p>Order Item (Gearment): #{orderItem.id}</p>
      {[0, 1].indexOf(order.shippingLocation) !== -1 && !order?.shippingLabel === -1 && (
        <p>Shipping Location: {order.shippingLocation === 1 ? 'International' : 'Domestics'}</p>
      )}
    </div>
  );
}
export default React.memo(RenderProductInfoMatchProduct);