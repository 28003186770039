import React from 'react';
import RadioStyle from './styled';
import { Radio } from 'antd';
import classNames from 'classnames';
const renderRadioTopUp = ({
  input,
  options,
  meta: { asyncValidating, touched, error },
  size,
  vertical,
  classNameItem = '',
}) => {
  return (
    <RadioStyle>
      <div className="payment-method-3">
        <Radio.Group
          size={size || 'default'}
          onChange={(e) => input.onChange(e.target.value)}
          value={input.value}
          // className="ml-2"
        >
          {options.map((item) => (
            <Radio
              className={classNames('payment-method', {
                checked: item.value === input.value,
              })}
              key={item.value}
              value={item.value}
              disabled={item.disabled}
            >
              {item.label}
              {item.note && (
                <p className="my-0">
                  <i>{item.note}</i>
                </p>
              )}
            </Radio>
          ))}
        </Radio.Group>
        {error && <span className="invalid-feedback d-block">{error}</span>}
      </div>
    </RadioStyle>
  );
};

export default renderRadioTopUp;
