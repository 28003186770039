import moment from "moment";
import React, {useState} from "react";
import getMomentInstance from "utils/moment";
import { leadingZero } from "utils/helper";
import Countdown from "react-countdown";
function RenderRemainingAutoLockOrder({startTime = 0, duration = 0, unit="hours", forceUpdate}){
    const [showCountDown, setShowCountDown] = useState(true);
    const renderCountDown = ({ hours, minutes, seconds, completed }) => {
        return (
          <span>
            {leadingZero(hours)}h:{leadingZero(minutes)}m:{leadingZero(seconds)}s
          </span>
        );
      };
    const startMoment = getMomentInstance(null, startTime);
    const now = getMomentInstance();
    const endMoment = startMoment.add(duration, unit);
    const remainingTime = moment.duration(endMoment.diff(now)).asSeconds() * 1000;
    const completeCountDown = () => {
        setShowCountDown(false)
        forceUpdate();
    };
    return (
      <>
         {(remainingTime > 0 && showCountDown) && 
         <Countdown
            date={
            Date.now() + remainingTime
            }
            renderer={renderCountDown}
            onComplete={completeCountDown}
        />}
      </>
    )
}
export default React.memo(RenderRemainingAutoLockOrder);