import { ERROR_700, GRAY_100 } from "components/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  &.mass-payment {
    border: 8px;
    background-color: ${GRAY_100};
    padding: 16px;
    width: 100%;
    .mass-payment-notice {
      color: ${ERROR_700};
      text-align: center;
      padding: 0 10px;
    }
  }
  img.paypal {
    height: 38px;
  }
`;
