import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import { getList, destroy } from "apis/promotions";

function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getList, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}

function* deletePromotionSaga(action) {
  const { id, storeId } = action;
  try {
    const response = yield call(destroy, id);
    if (response.code === "success") {
      yield all([
        yield put({ type: TYPE.DELETE.SUCCESS, ...response }),
        yield put({
          type: TYPE.LIST.REQUEST,
          params: { page: 1, perPage: 50 },
        }),
      ]);
    } else {
      yield put({ type: TYPE.DELETE.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.DELETE.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga);
  yield takeLatest(TYPE.DELETE.REQUEST, deletePromotionSaga);
}

export default watcher;
