import React, { Component } from "react";
import { connect } from "react-redux";
import FormChangePassword from "./components/FormChangePassword";
import { update } from "./actions";
import { Spin, notification } from "antd";
import { Wrapper } from "./styled";
export class index extends Component {
  handleChangePassword = (values) => {
    if (values.newPassword !== values.newPasswordConfirmation) {
      notification.error({
        message: "New Passwords do not match.",
        duration: 5,
      });
    } else {
      this.props.update(values);
    }
  };
  render() {
    const { updating } = this.props;
    return (
      <Wrapper className="profile-section bg-white p-3 m-3 mx-lg-auto">
        <Spin spinning={updating}>
          <FormChangePassword onSubmit={this.handleChangePassword} />
        </Spin>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  updating: state.user.change_password.updating,
});

const mapDispatchToProps = (dispatch) => ({
  update: (params) => {
    dispatch(update(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
