const SIZES = {
  sm: `
    padding: 0 16px;
    line-height: 36px;
    height: 36px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  `,
  md: `
    padding: 0 18px;
    line-height: 40px;
    height: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  `,
  lg: `
    padding: 0 20px;
    line-height: 44px;
    height: 44px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  `,
  xl: `
    padding: 0 22px;
    line-height: 48px;
    height: 48px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  `,
  xxl: `
    padding: 0 30px;
    line-height: 60px;
    height: 60px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  `,
};
SIZES.default = SIZES.md;

export default SIZES;