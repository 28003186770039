export const actionType = {
  HISTORY_SHIPPING: {
    REQUEST: "HISTORY_SHIPPING.REQUEST",
    SUCCESS: "HISTORY_SHIPPING.SUCCESS",
    ERROR: "HISTORY_SHIPPING.ERROR",
  },
  LIST_SHIPPING: {
    REQUEST: "LIST_SHIPPING.REQUEST",
    SUCCESS: "LIST_SHIPPING.SUCCESS",
    ERROR: "LIST_SHIPPING.ERROR",
  },
  UPDATE_SHIPPING: {
    REQUEST: "UPDATE_SHIPPING.REQUEST",
    SUCCESS: "UPDATE_SHIPPING.SUCCESS",
    ERROR: "UPDATE_SHIPPING.ERROR",
  },
};
export function getListShipping() {
  return {
    type: actionType.LIST_SHIPPING.REQUEST,
  };
}
export function getListHistoryShipping() {
  return {
    type: actionType.HISTORY_SHIPPING.REQUEST,
  };
}
export function updateShipping(dataToUpdateShipping) {
  return {
    type: actionType.UPDATE_SHIPPING.REQUEST,
    dataToUpdateShipping,
  };
}
