import React, { useState } from 'react';
import { Input, List } from 'antd';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { EnvironmentOutlined, LoadingOutlined } from '@ant-design/icons';
import { GOOGLE_AP_PLACES_KEY } from 'web.config';
import useDelayInput from 'hooks/useDelayInput';
import useEffectOnce from 'hooks/useEffectOnce';
import classNames from 'classnames';
import { useRef } from 'react';
import { useEffect } from 'react';

const ADDRESS_COMPONENT_TYPE = {
  COUNTRY: 'country',
  STATE: 'administrative_area_level_1',
  CITY: 'administrative_area_level_2',
  LOCALITY: 'locality',
  ZIP: 'postal_code',
};

// const GOOGLE_MAPS_API_KEY = 'AIzaSyCmq_w4Yo_NR8ZzoUOAB3G7kaEexaUTEXE';

// const PoweredByGoogleMap = () => (
//   <div className="d-flex justify-content-end p-1">
//     <img
//       src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
//       alt="Powered by Google"
//     />
//   </div>
// );
const center = { lat: 36.778259, lng: -119.417931 };

const SearchGooglePlaces = ({
  input,
  invalid,
  meta: { error, touched, submitFailed },
  size = 'large',
  classNameContainer = '',
  className = '',
  defaultValue = '',
  placeholder = 'Address 01',
  onChangeCityName,
  onChangeStateName,
  onChangeStateShortName,
  onChangeCountryName,
  onChangeZip,
  shouldGetDetailAddress = true,
  requiredUS = false,
}) => {
  const refReact = useRef();
  const [keyword, setKeyword] = useState(input.value);
  const [shouldOpenDropdown, setOpenDropdown] = useState(false);
  const {
    ref,
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: GOOGLE_AP_PLACES_KEY,
  });

  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };

  const handleInputChange = (value) => {
    setKeyword(value);
    input.onChange(value);
  };
  useOnClickOutside(ref, () => setOpenDropdown(false));
  // Hook
  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!refReact.current || refReact.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }
  const onGetPlacePredictionsUS = (input) => {
    getPlacePredictions({
      input,
      bounds: defaultBounds,
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    });
  };
  const onGetPlacePredictions = (input) => {
    getPlacePredictions({
      input,
      types: ['address'],
    });
  };

  const handleSelect = ({
    description,
    place_id,
    structured_formatting: { main_text, secondary_text },
    terms,
  }) => {
    setOpenDropdown(false);
    if (terms?.length >= 5 && shouldGetDetailAddress) {
      const addressArr = [...terms];
      const address = addressArr
        .slice(0, terms.length - 2)
        .map((term) => term.value)
        .join(' ');
      handleInputChange(address);
    } else {
      handleInputChange(main_text);
      setOpenDropdown(false);
    }

    if (place_id && placesService)
      // eslint-disable-next-line no-unused-expressions
      placesService?.getDetails({ placeId: place_id }, (placeDetails) => {
        const address_components = placeDetails?.address_components;
        if (address_components && address_components?.length > 0) {
          const state = address_components.find((comp) =>
            comp?.types.includes(ADDRESS_COMPONENT_TYPE.STATE)
          );
          onChangeStateName && onChangeStateName(state?.short_name || '');
          onChangeStateShortName &&
            onChangeStateShortName(state?.short_name || '');
          const city = address_components.find(
            (comp) =>
              comp?.types.includes(ADDRESS_COMPONENT_TYPE.CITY) ||
              comp?.types.includes(ADDRESS_COMPONENT_TYPE.LOCALITY)
          );
          onChangeCityName && onChangeCityName(city?.long_name || '');
          const country = address_components.find((comp) =>
            comp?.types.includes(ADDRESS_COMPONENT_TYPE.COUNTRY)
          );
          onChangeCountryName && onChangeCountryName(country?.long_name || '');
          const postal_code = address_components.find((comp) =>
            comp?.types.includes(ADDRESS_COMPONENT_TYPE.ZIP)
          );
          onChangeZip && onChangeZip(postal_code?.long_name || '');
        }
      });
  };

  const { handleChange } = useDelayInput({
    onChange: handleInputChange,
    onDelay: requiredUS ? onGetPlacePredictionsUS : onGetPlacePredictions,
  });

  // Refresh keyword if value updated for the first time
  useEffectOnce(() => {
    setKeyword(input.value);
  }, input.value && input.value !== keyword);

  return (
    <div className={classNameContainer} ref={refReact}>
      <div className="position-relative search-place-input">
        <Input
          style={{ borderRadius: '8px', height: '44px' }}
          className={classNames(className, 'gm-gplace', {
            error: touched && submitFailed && error,
          })}
          ref={ref}
          size={size}
          value={keyword}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={() => {
            setOpenDropdown(true);
          }}
          prefix={<EnvironmentOutlined style={{ color: '#2F3336' }} />}
          suffix={
            isPlacePredictionsLoading && (
              <LoadingOutlined style={{ color: '#2F3336' }} />
            )
          }
        />
        {shouldOpenDropdown && placePredictions?.length > 0 && (
          <div
            className="position-absolute ant-select-dropdown w-100 mt-1 dropdown-container"
            style={{
              top: 40,
              left: 0,
            }}
          >
            <List
              className="dropdown-list"
              dataSource={placePredictions}
              // onMouseLeave={() => {
              //   setOpenDropdown(false);
              // }}
              renderItem={(option, index) => {
                return (
                  <List.Item
                    className="p-2 dropdown-item"
                    key={option?.place_id || index}
                    onClick={() => handleSelect(option)}
                  >
                    {option.structured_formatting?.main_text}{' '}
                    {option.structured_formatting?.secondary_text && (
                      <span className="text-secondary">
                        ({option.structured_formatting.secondary_text})
                      </span>
                    )}
                  </List.Item>
                );
              }}
            />
          </div>
        )}
      </div>
      {invalid && (
        <hr
          className="border-danger m-0 position-absolute"
          style={{ width: '100%', left: '0%', top: '0%' }}
        />
      )}
      {error && submitFailed && (
        <span className="invalid-feedback">{error}</span>
      )}
    </div>
  );
};

export default SearchGooglePlaces;
