import {
  actionType as TYPE
} from './actions'
import { notification } from "antd";

const initialState = {
  updating: false,
  serverErrorInfo: null,
  serverSuccessInfo: null,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.UPDATE.REQUEST:
      return {
        ...state,
        updating: true,
      }
    case TYPE.UPDATE.SUCCESS:
      notification.success({ message: 'Update password successfully.', duration: 5 })
      return {
        ...state,
        updating: false,
        serverSuccessInfo: action.payload,
      }
    case TYPE.UPDATE.CLEAR_MESSAGE:
      return {
        ...state,
        updating: false,
        ...action.payload,
      }
    case TYPE.UPDATE.ERROR:
      return {
        ...state,
        updating: false,
        serverErrorInfo: action.error,
      }
    default:
      return state
  }
}

export default reducer