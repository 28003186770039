import React, { Component } from "react";
import {
  reduxForm,
  Field,
  formValueSelector,
  change,
  arrayPush,
} from "redux-form";
import { connect } from "react-redux";
import validate from "./validate";
import { renderSelectAnt } from "utils/Form";
import { withRouter } from "react-router-dom";
import { renderCheckboxGroup } from "utils/Form";
import RenderCampaignCheckboxGroup from "utils/Form/RenderCampaignCheckboxGroup";
import FieldText from "utils/fields/FieldText";
import SelectCampaign from "utils/Form/Search/SelectCampaign";
import { GMButton } from "components/Button";
class index extends Component {
  render() {
    const {
      listFeedForms,
      listCampaigns,
      handleSubmit,
      changeEmail,
      pushArray,
      campaigns,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-2">
          <div className="form-group mb-3 col-md-4 px-0">
            <label className="col-form-label font-weight-bold">Feed form</label>
            <Field
              name="feedFormId"
              component={renderSelectAnt}
              options={[
                { value: "0", label: "-- Select Feed Form --" },
                ...listFeedForms.map((item) => ({
                  value: item.id,
                  label: `Feed Form #${item.id} ${item.name}`,
                })),
              ]}
            />
          </div>
          <div className="form-group">
            <label className="col-form-label font-weight-bold">
              Search Products
            </label>
            <Field
              name={"listCampaigns2"}
              component={SelectCampaign}
              arrayPush={pushArray}
              listCampaigns={listCampaigns}
              classNameContainer="col-12 col-sm-4 px-0"
              campaigns={campaigns}
            />
          </div>
          <div className="form-group">
            <label className="col-form-label font-weight-bold">Products</label>
            <Field
              name={"campaigns"}
              component={RenderCampaignCheckboxGroup}
              colSpan={24}
              options={listCampaigns}
            />
          </div>
          <div className="form-group mb-1">
            <Field
              name="changeEmail"
              component={renderCheckboxGroup}
              classNameItem={"d-block mb-3"}
              colSpan={24}
              options={[
                {
                  value: 1,
                  label: <b>Change email address to receive file export</b>,
                },
              ]}
            />
          </div>
          {(Array.isArray(changeEmail) && changeEmail.includes(1)) && (
            <div className="form-group col-md-4 px-0">
              <label className="col-form-label font-weight-bold">
                Email to recieve export
              </label>
              <Field name="email" component={FieldText} />
            </div>
          )}
          <div className="ctas">
            <GMButton
              type="submit"
            >
              Export Feed Custom Amazon
            </GMButton>
          </div>
        </div>
      </form>
    );
  }
}

const selector = formValueSelector("FormFeedCustomAmazon");
export default withRouter(
  connect(
    (state) => ({
      changeEmail: selector(state, "changeEmail") || [],
      listCampaigns: selector(state, "listCampaigns") || [],
      campaigns: selector(state, "campaigns") || [],
    }),
    (dispatch) => ({
      changeFieldValue: function (field, value) {
        dispatch(change("FormFeedCustomAmazon", field, value));
      },
      pushArray: function (field, value) {
        dispatch(arrayPush("FormFeedCustomAmazon", field, value));
      },
    })
  )(
    reduxForm({
      form: "FormFeedCustomAmazon",
      enableReinitialize: true,
      validate,
    })(index)
  )
);
