import { takeLatest, call, put, all } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { actionType as TYPE } from './actions';
import { getList } from 'apis/ex_products';
import { create } from 'apis/mockup';

function* createSaga(action) {
  try {
    const { params } = action;
    const response = yield call(create, params);

    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CREATE.SUCCESS, response }),
        put(replace('/products/mockups')),
      ]);
    } else {
      yield put({ type: TYPE.CREATE.ERROR, response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.CREATE.ERROR, error })]);
  }
}

function* getListExproductSaga(action) {
  try {
    const { params } = action;
    const response = yield call(getList, params);
    if (response.code === 'success') {
      yield all([put({ type: TYPE.LIST_EXPRODUCT.SUCCESS, ...response })]);
    } else {
      yield put({ type: TYPE.LIST_EXPRODUCT.ERROR, response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.LIST_EXPRODUCT.ERROR, error })]);
  }
}

function* watcher() {
  yield all([
    takeLatest(TYPE.CREATE.REQUEST, createSaga),
    takeLatest(TYPE.LIST_EXPRODUCT.REQUEST, getListExproductSaga),
  ]);
}

export default watcher;
