import { notification } from 'antd';
import { actionType as TYPE } from './actions';

const initialState = {
  detail: {
    loading: false,
    data: {},
  },
  listImage: [],
};

function CreateBulkReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.DETAIL.REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
        },
      };
    case TYPE.DETAIL.SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: action.data.campaign,
          loading: false,
        },
      };
    case TYPE.DETAIL.ERROR:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Preview product failed',
      });
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
        },
      };
    case TYPE.SET_BULK_IMAGE:
      return {
        ...state,
        listImage: [...state.listImage, action.image],
      };
    default:
      return state;
  }
}

export default CreateBulkReducer;
