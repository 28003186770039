import axios from "utils/request";

const prefix = "/transactions";

export const getList = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}`,
  });
};

export const getInvoiceTransaction = (id) => {
  return axios({
    method: "GET",
    id,
    url: `${prefix}/${id}/invoice?with[]=transactionFee`,
  });
};
export const getTransactionToQuickView = (id) => {
  return axios({
    method: "GET",
    url: `${prefix}/${id}/quick`,
  });
};

export const getDetail = (id) => {
  return axios({
    method: "GET",
    id,
    url: `${prefix}/${id}?with[]=transactionFee`,
    is_json_content_type: true,
  });
};

export const create = (data) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}`,
  });
};

export const update = (id, data) => {
  return axios({
    method: "PUT",
    data,
    url: `${prefix}/${id}`,
  });
};

export const destroy = (id) => {
  return axios({
    method: "POST",
    url: `${prefix}/${id}/delete`,
  });
};

export const getProfitTransaction = () => {
  return axios({
    method: "GET",
    url: `${prefix}/statistic`,
  });
};

export const exportTransactionInvoice = (id) => {
  return axios({
    method: "GET",
    id,
    url: `${prefix}/${id}/invoice?isExportPDF=1`,
  });
}
