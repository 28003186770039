export const actionType = {
  DASHBOARD: {
    USER_STEPS: {
      REQUEST: 'DASHBOARD.USER_STEPS.REQUEST',
      SUCCESS: 'DASHBOARD.USER_STEPS.SUCCESS',
      ERROR: 'DASHBOARD.USER_STEPS.ERROR',
    },
  },
};
export function getUserStepsRequestAction() {
  return {
    type: actionType.DASHBOARD.USER_STEPS.REQUEST
  };
}
