import { GRAY_400, GRAY_600, GRAY_900 } from "components/colors";
import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  div.title {
    padding-top: 1rem;
    padding-left: 5px;
  }
  p.message {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_600};
    margin-bottom: 1rem;
  }
  .cta-update {
    width: 100%;
    max-width: 200px;
  }
  .filter {
    .input span.icon-after svg.search-tooltip {
      stroke: ${GRAY_400};
      margin-left: 6px;
      display: inline-block;
      cursor: default;
      width: 18px;
      height: 18px;
    }
    .select {
      &-status,
      &-method,
      &-type {
        font-size: 0;
        .ant-select-selector {
          min-width: 180px;
        }
        &, & .ant-select .ant-select-selector {
          height: 44px;
          line-height: 44px;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${GRAY_900};
          span {
            display: inline-block;
            line-height: unset;
          }
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
      .date-range {
        span {
          font-size: 14px;
        }
      }
    }
    }
`;