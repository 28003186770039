import Help from "scenes/Help";

export default [
  {
    key: "help",
    name: "Help",
    component: Help,
    path: "/help",
    group: "help",
    icon: null,
    template: "default",
    isPublic: true,
  },
];
