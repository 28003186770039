import styled from 'styled-components';
import COLORS from './colors';
import SIZES from './sizes';
import { BASE_WHITE } from 'components/colors';

export default styled.button`
  /* colors */
  &,
  &:hover {
    ${p => COLORS[p.color || 'default']?.default}
  }
  cursor: pointer;
  &:hover {
    ${p => COLORS[p.color || 'default']?.hover}
  }
  &:focus {
    ${p => COLORS[p.color || 'default']?.focus}
  }
  &:disabled {
    ${p => COLORS[p.color || 'default']?.disabled}
    cursor: default;
  }
  /*\ colors */

  border-radius: 8px;
  vertical-align: middle;
  /* sizes */
  ${p => SIZES[p.size || 'default']}
  /*\ sizes */

  span {
    line-height: 1;
  }
  label.loader {
    width: 20px;
    height: 20px;
    border: 4px solid ${BASE_WHITE};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    line-height: 1;
    box-sizing: border-box;
    float: none;
    animation: rotation 1s linear infinite;
    margin: 0;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;