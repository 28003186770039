export const actionType = {
  RESET: {
    REQUEST: "AUTH.RESET.REQUEST",
    SUCCESS: "AUTH.RESET.SUCCESS",
    ERROR: "AUTH.RESET.ERROR",
  },
  VERIFY_TOKEN: {
    REQUEST: "AUTH.VERIFY_TOKEN.REQUEST",
    SUCCESS: "AUTH.VERIFY_TOKEN.SUCCESS",
    ERROR: "AUTH.VERIFY_TOKEN.ERROR",
  },
};
export function resetPassword(data) {
  return {
    type: actionType.RESET.REQUEST,
    data,
  };
}

export function setResetSuccessInfo(payload) {
  return {
    type: actionType.RESET.SUCCESS,
    payload,
  };
}

export function verifyToken(data) {
  return {
    type: actionType.VERIFY_TOKEN.REQUEST,
    data,
  };
}
