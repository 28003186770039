import React, { useState, useEffect } from "react";
import { Collapse, Col, Affix } from "antd";
import {
  Field,
  reduxForm,
  submit,
  change,
  formValueSelector,
  FieldArray
} from "redux-form";
import { connect } from "react-redux";
import { renderRangeDate } from "utils/Form";
import FieldCustomRadioGroup from "utils/fields/FieldCustomRadioGroup";
import { options_campaign_status } from "utils/options";
import RenderAdvancedFilterButtons from "utils/Form/RenderAdvancedFilterButtons";
import SelectProduct from "utils/Form/Search/SelectProduct";
import SelectMultipleTagSizeProduct from "utils/Form/Search/SelectMultipleTagSizeProduct";
import RenderTimeOptions from "utils/Form/RenderTimeOptions";
import { clearFilter } from "utils/helper";
import RenderTagFilter from "utils/Form/RenderTagFilter";
import { useSelector } from "react-redux";
import SelectMultipleTagColorProduct from "utils/Form/Search/SelectMultipleTagColorProduct";
import GMRow from "components/GMLayout/GMRow";
const { Panel } = Collapse;
const paramsName = {
  productId: "Product",
  sizeIds: "Size",
  colorIds: "Color",
  fromDate: "From Date",
  toDate: "To Date",
  status: "Status"
};
const defaultValues = {
  sortBy: "id",
  sortType: "desc",
  productId: "0",
  colorIds: [],
  sizeIds: [],
  status: "4",
  fromDate: null,
  toDate: null,
};

let paramsValueAlias = {
  status: {
    0: "End",
    1: "Active",
    2: "Declined",
    3: "Processing"
  },
}
const AdvancedFilter = ({
  handleSubmit,
  exproducts,
  changeFieldValue,
  productId,
  colorId,
  colorIds,
  sizeIds,
  date_range,
  ...props
}) => {
  const campaigns = useSelector((state) => state.campaign.list.list);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  useEffect(() => {
    if ((Array.isArray(exproducts) && exproducts.length > 0) || (Array.isArray(colors) && colors.length > 0) ||
      (Array.isArray(sizes) && sizes.length > 0)) {
      const listProducts = {};
      (exproducts || []).forEach((exproduct) => {
        listProducts[exproduct.id] = exproduct.brand;
      });
      const listColors = {};
      (colors || []).forEach((color) => {
        listColors[color.id] = color.name;
      });
      const listSizes = {};
      (sizes || []).forEach((size) => {
        listSizes[size.id] = size.name;
      });
      paramsValueAlias = {
        ...paramsValueAlias,
        productId: listProducts,
        colorIds: listColors,
        sizeIds: listSizes
      }
    }
  }, [JSON.stringify(exproducts), JSON.stringify(sizes), JSON.stringify(colors)]);
  const submitFilters = (isClosedAdvanedFileter = true) => {
    props.dispatch(submit("AdvancedFilterProducts"));
    if (isClosedAdvanedFileter) {
      props.onCloseAdvancedSearch();
    }
  }
  return (
    <div>
      <RenderTagFilter
        params={campaigns.params}
        paramsName={paramsName}
        paramsValueAlias={paramsValueAlias}
        defaultValues={defaultValues}
        changeFieldValue={changeFieldValue}
        isAllowedToClearFilter={true}
        submitFilters={submitFilters}
        date_range={date_range}
      />
      <form onSubmit={handleSubmit}>
        <div className="p-3">
          <Collapse
            defaultActiveKey={["1", "2", "3", "4", "5", "6", "7"]}
            bordered={true}
            ghost
            expandIconPosition="right"
          >
            <Panel header={<b className="header-filter">All time</b>} key="1">
              <RenderTimeOptions changeFieldValue={changeFieldValue} />
              <div className="row">
                <Field
                  name="date_range"
                  component={renderRangeDate}
                  labelStart={<b>Start date</b>}
                  labelEnd={<b>End date</b>}
                  className="h-50px w-100"
                  dateTimeFormat="YYYY/MM/DD"
                  showTime={false}
                  classContainer="col-6 mb-2"
                  startPlaceholder="yyyy/mm/dd"
                  endPlaceholder="yyyy/mm/dd"
                />
              </div>
            </Panel>
            <Panel header={<b className="header-filter">Status</b>} key="2">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="status"
                    component={FieldCustomRadioGroup}
                    options={[
                      ...options_campaign_status,
                      {
                        label: "All",
                        value: "4",
                      },
                    ]}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Sort By</b>} key="3">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="sortType"
                    component={FieldCustomRadioGroup}
                    options={[
                      {
                        value: "asc",
                        label: <span>Ascending</span>,
                      },
                      {
                        value: "desc",
                        label: <span>Descending</span>,
                      },
                    ]}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Order By</b>} key="4">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="sortBy"
                    component={FieldCustomRadioGroup}
                    options={[
                      {
                        value: "id",
                        label: <span>ID</span>,
                      },
                      {
                        value: "createdAt",
                        label: <span>Start date</span>,
                      },
                      {
                        value: "endedAt",
                        label: <span>End date</span>,
                      },
                    ]}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Product</b>} key="5">
              <GMRow>
                <Col span={24}>
                  <Field
                    name={`productId`}
                    component={SelectProduct}
                    data={exproducts}
                    onChoose={() => {
                      changeFieldValue("colorIds", []);
                      changeFieldValue(`sizeIds`, []);
                    }}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Color</b>} key="6">
              <GMRow>
                <Col span={24}>
                  <FieldArray
                    name={`colorIds`}
                    component={SelectMultipleTagColorProduct}
                    expId={productId}
                    setColors={setColors}
                    selectedColorIds={colorIds}
                    setDefaultColor={(colorId) => {
                      changeFieldValue('colorId', colorId);
                    }}
                  />
                </Col>
              </GMRow>{" "}
            </Panel>
            <Panel header={<b className="header-filter">Size</b>} key="7">
              <GMRow>
                <Col span={24}>
                  <FieldArray
                    name={`sizeIds`}
                    component={SelectMultipleTagSizeProduct}
                    expId={productId}
                    colorId={colorId}
                    setSizes={setSizes}
                    selectedSizeIds={sizeIds}
                  />
                </Col>
              </GMRow>
            </Panel>
          </Collapse>
        </div>
      </form >
      <Affix offsetBottom={0}>
        <RenderAdvancedFilterButtons
          handleOnClearFilters={() => {
            clearFilter(changeFieldValue, {
              perPage: 100,
              sortBy: "id",
              sortType: "desc",
              status: "4",
              productId: "0",
              colorIds: [],
              sizeIds: [],
              date_range: [
                null,
                null
              ],
            });
            setTimeout(() => props.dispatch(submit("AdvancedFilterProducts")), 1000);
          }}
          handleOnSubmit={submitFilters}
        />
      </Affix>
    </div >
  );
};
const selector = formValueSelector("AdvancedFilterProducts");
const AdvancedFilterReduxForm = reduxForm({
  form: "AdvancedFilterProducts",
  enableReinitialize: true,
  //validate,
})(AdvancedFilter);

const mapStateToProps = (state) => ({
  productId: selector(state, "productId") || 0,
  colorId: selector(state, "colorId") || 0,
  colorIds: selector(state, "colorIds") || [],
  sizeIds: selector(state, "sizeIds") || [],
  date_range: selector(state, "date_range") || null
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change("AdvancedFilterProducts", field, value));
    },
  };
};
export default React.memo(connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedFilterReduxForm));