import { notification } from 'antd';
import { actionType as TYPE } from './actions';

const initialState = {
  requestTopUp: {
    loading: false,
    statusVerify: false,
    notiTopup: '',
  },
  requestTopUpPayPal: {
    loading: false,
  },
  listPaypal: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.REQUEST_TOPUP.REQUEST:
      return {
        ...state,
        requestTopUp: {
          ...initialState.requestTopUp,
          loading: true,
          statusVerify: false,
          notiTopup: '',
        },
      };
    case TYPE.REQUEST_TOPUP.SUCCESS:
      if (action?.response?.check === 0) {
        notification.success({
          message:
            action?.response?.clientMsg ||
            'You have submitted a top up request successfully',
          duration: 5,
        });
      }
      return {
        ...state,
        requestTopUp: {
          ...state.requestTopUp,
          loading: false,
          statusVerify: true,
          notiTopup: '',
          lastTopupSuccess: new Date().getTime(),
        },
      };
    case TYPE.REQUEST_TOPUP.ERROR:
      notification.warning({
        message:
          action?.error?.clientMsg ||
          'Top up failed. Please try again later',
        duration: 5,
      });
      return {
        ...state,
        requestTopUp: {
          ...state.requestTopUp,
          loading: false,
          statusVerify: false,
          notiTopup: action?.error?.clientMsg,
        },
      };

    //Instant Paypal
    case TYPE.REQUEST_TOPUP_PAYPAL.REQUEST:
      return {
        ...state,
        requestTopUpPayPal: {
          ...initialState.requestTopUpPayPal,
          loading: true,
        },
      };
    case TYPE.REQUEST_TOPUP_PAYPAL.SUCCESS:
      notification.success({
        message:
          action?.response?.clientMsg ||
          'You have submitted a top up request successfully',
        duration: 5,
      });
      return {
        ...state,
        requestTopUpPayPal: {
          ...state.requestTopUpPayPal,
          loading: false,
        },
      };
    case TYPE.REQUEST_TOPUP_PAYPAL.ERROR:
      notification.warning({
        message:
          action?.error[0]?.clientMsg ||
          'Top up failed. Please try again later',
        duration: 5,
      });
      return {
        ...state,
        requestTopUpPayPal: {
          ...state.requestTopUpPayPal,
          loading: false,
        },
      };
    //List account Paypal
    case TYPE.LIST_PAYPAL.REQUEST:
      return {
        ...state,
        listPaypal: {
          ...initialState.listPaypal,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_PAYPAL.SUCCESS:
      return {
        ...state,
        listPaypal: {
          ...state.listPaypal,
          loading: false,
          data: action.data.paypals,
        },
      };
    case TYPE.LIST_PAYPAL.ERROR:
      return {
        ...state,
        listPaypal: {
          ...state.listPaypal,
          loading: false,
        },
      };

    default:
      return state;
  }
}

export default ListReducer;
