import styled from 'styled-components';

export const FlipCardComp = styled.div`
  background-color: transparent;
  perspective: 1000px;
  font-size: 0;
  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  &.flipped .inner {
    transform: rotateY(180deg);
  }
  &.no-animate .inner {
    transition: none;
  }
  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-size: 0;
    display: flex;
  }

  .front {
    color: black;
  }

  .back {
    color: white;
    transform: rotateY(180deg);
  }
`;
