import { 
    actionType as TYPE
} from './actions'
import {notification} from "antd";

const initialState = {
    items: {
        data: [],
        loading: false,
        params: {},
        total: 0
    },
    remove: {
        loading: false
    },
    rollback: {
        loading: false,
        errors: [],
    }
}

function ListReducer(state = initialState, action){
    switch(action.type) {
        case TYPE.LIST_REMOVED_ORDER_ITEM.REQUEST:
            return {
                ...state,
                items: {
                    ...state.items,
                    params: action.params,
                    loading: true
                }              
            }
        case TYPE.LIST_REMOVED_ORDER_ITEM.SUCCESS:
            return {
                ...state,
                items: {
                    ...state.items,
                    loading: false,
                    data: action.data.listOrderItems,
                    total: action.data.pagination.total,
                },
            }
        case TYPE.LIST_REMOVED_ORDER_ITEM.ERROR:
            return {
                ...state,
                items: {
                    ...state.items,
                    loading: false
                }  
            }
        case TYPE.REMOVE.REQUEST:
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: true
                }
            }
        case TYPE.REMOVE.SUCCESS:
            notification.success({message: 'Remove order item successfully.', duration: 5})
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false
                },
            }
        case TYPE.REMOVE.ERROR:
            notification.warn({
                message: action.error && action.error[0] && action.error[0]['msg'] ? action.error[0]['msg'] : 'Remove order item failed.',
                duration: 5
            });
            return {
                ...state,
                remove: {
                    ...state.remove,
                    loading: false
                }
            }
        case TYPE.ROLLBACK.REQUEST:
            return {
                ...state,
                rollback: {
                    ...state.rollback,
                    loading: true
                }
            }
        case TYPE.ROLLBACK.SUCCESS:
            if (action.errors && Array.isArray(action.errors)) {
                for (let index = 0; index < action.errors.length; index++) {
                  if (action.errors[index].code === "rollback_order_item_successfully") {
                    notification.success({
                      message: action.errors[index].msg,
                      duration: 5,
                    });
                  } else {
                    notification.warn({
                      message: action.errors[index].msg,
                      duration: 5,
                    });
                  }
                }
              }
              return {
                ...state,
                rollback: {
                  ...state.rollback,
                  loading: false,
                  errors: action.errors ? action.errors : [],
                },
              };
        case TYPE.ROLLBACK.ERROR:
            notification.warn({
                message: action.error && action.error[0] && action.error[0]['msg'] ? action.error[0]['msg'] : 'Rollback order item failed.',
                duration: 5
            });
            return {
                ...state,
                rollback: {
                    ...state.rollback,
                    loading: false
                }
            }
        default:
            return state
    }
}

export default ListReducer