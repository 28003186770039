export const actionType = {
    LIST_REMOVED_ORDER_ITEM: {
        REQUEST: 'ORDER_ITEM_REMOVED.LIST.REQUEST',
        SUCCESS: 'ORDER_ITEM_REMOVED.LIST.SUCCESS',
        ERROR: 'ORDER_ITEM_REMOVED.LIST.ERROR',
    },
    REMOVE: {
        REQUEST: 'REMOVE_ORDER_ITEM.REQUEST',
        SUCCESS: 'REMOVE_ORDER_ITEM.SUCCESS',
        ERROR: 'REMOVE_ORDER_ITEM.ERROR'
    },
    ROLLBACK: {
        REQUEST: 'ROLLBACK_REMOVED_ORDER_ITEM.REQUEST',
        SUCCESS: 'ROLLBACK_REMOVED_ORDER_ITEM.SUCCESS',
        ERROR: 'ROLLBACK_REMOVED_ORDER_ITEM.ERROR'
    }
};

export function getList(params) {
    return {
        type: actionType.LIST_REMOVED_ORDER_ITEM.REQUEST,
        params
    }
}

export function removedOrderItem(data){
    return {
        type: actionType.REMOVE.REQUEST,
        data
    }
}

export function rollbackRemovedOrderItem(data, params = {}){
    return {
        type: actionType.ROLLBACK.REQUEST,
        data,
        params
    }
}