import { all } from 'redux-saga/effects'
import ListSaga from './scenes/List/saga'
import EditSaga from './scenes/Edit/saga'
// import CreateSaga from './scenes/Create/saga'
// import DeleteSaga from './scenes/Delete/saga'

export default function* () {
    yield all([
        ListSaga(),
        EditSaga(),
        // CreateSaga(),
        // DeleteSaga(),
    ])
}