export const actionType = {
    ARCHIVE: {
        REQUEST: 'IMAGE_LIBRARY.ARCHIVE.REQUEST',
        SUCCESS: 'IMAGE_LIBRARY.ARCHIVE.SUCCESS',
        ERROR: 'IMAGE_LIBRARY.ARCHIVE.ERROR',
    },
    LIST: {
        REQUEST: 'IMAGE_LIBRARY.LIST.REQUEST',
        SUCCESS: 'IMAGE_LIBRARY.LIST.SUCCESS',
        ERROR: 'IMAGE_LIBRARY.LIST.ERROR',
    },
    
    SET_IMAGE_LIBRARY_IMAGE: 'SET_IMAGE_LIBRARY_IMAGE2'
};

export function getListImages(params){
    return {
        type: actionType.LIST.REQUEST,
        params
    }
}

export function archiveImages(data){
    return {
        type: actionType.ARCHIVE.REQUEST,
        data
    }
}


