import React from "react";
import { Button,Tooltip } from "antd";
import { setPrimaryBillingPaymentAccount } from "apis/billing_payment_account";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setLoading, refetchBillingPaymentAccount } from "../../../actions";
import { Modal, notification } from "antd";
import check from "images/checkPayment.svg";
const { confirm } = Modal;
function SetPrimaryButton({ billingPaymentMethodId = 0 }) {
  const dispatch = useDispatch();
  const handleOnSetPrimaryPaymentMethod = () => {
    confirm({
      title: "Do you want to set this payment method by default?",
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        dispatch(setLoading(true));
        setPrimaryBillingPaymentAccount(billingPaymentMethodId)
          .then((response) => {
            dispatch(setLoading(false));
            if (response?.code === "success") {
              dispatch(refetchBillingPaymentAccount(true));
              notification.success({
                message:
                  response?.clientMsg ||
                  `Set payment method  #${billingPaymentMethodId} by default successfully.`,
                duration: 5,
              });
            } else {
              notification.warn({
                message:
                  response?.[0]?.clientMsg ||
                  `Set payment method #${billingPaymentMethodId} by default failed.`,
                duration: 5,
              });
            }
          })
          .catch((error) => {
            dispatch(setLoading(false));
          });
      },
      onCancel() {},
    });
  };
  return (
    <Tooltip title="Set primary">
      <Button
        className="btn btn-link mr-1 text-center d-flex justify-content-center"
        onClick={handleOnSetPrimaryPaymentMethod}
      >
        <img src={check} width={20} alt={'check icon'}/>
      </Button>
    </Tooltip>
  );
}

export default React.memo(SetPrimaryButton);
