import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag, notification, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Modal from 'antd/lib/modal/Modal';
import { WrapperPickerSize } from './styled';
import { ReactComponent as Addmore } from 'images/icons/edit-02.svg';

function RenderProductSizes({
  input,
  active,
  product,
  options,
  changeSizeActive,
}) {
  const [showPicksize, setShowPicksize] = useState(false);
  const [sizeSelected, setSizeSelected] = useState([]);
  const [sizeActive, setSizeActive] = useState('');
  useEffect(() => {
    if (showPicksize) {
      setSizeSelected([...(input.value || [])]);
      setSizeActive(`#${active?.value}`);
    }
    return () => {};
  }, [showPicksize]);

  const handleSelectSize = (size) => {
    if (sizeSelected.find((item) => parseInt(item.id) === parseInt(size.id))) {
      setSizeSelected([
        ...sizeSelected.filter(
          (item) => parseInt(item.id) !== parseInt(size.id)
        ),
      ]);
    } else {
      setSizeSelected([...sizeSelected, size]);
    }
  };
  const selected = input.value || [];
  return (
    <WrapperPickerSize>
      <Tag
        className="cursor-pointer add-more text-center"
        onClick={() => setShowPicksize(true)}
      >
        <Addmore />
      </Tag>
      {selected.map((size) => (
        <Tag
          // onClick={() => changeSizeActive(size)}
          className="mb-2 cursor-pointer text-center tag-size-primary"
          key={size.id}
        >
            {`${size.value}`}
            <span
              className={classNames({
                invisible: parseInt(size.id) !== parseInt(active?.id),
              })}
            >
              {/* <FontAwesomeIcon icon={faCheck} /> */}
            </span>
          {/* {parseInt(size.id) === parseInt(active?.id) ? (
            <Tooltip placement="top" title="Default">
              {`${size.value}`}
              <span
                className={classNames({
                  invisible: parseInt(size.id) !== parseInt(active?.id),
                })}
              >
                <FontAwesomeIcon icon={faCheck} />
              </span>
            </Tooltip>
          ) : (
            <>
              {`${size.value}`}
              <span
                className={classNames({
                  invisible: parseInt(size.id) !== parseInt(active?.id),
                })}
              >
                <FontAwesomeIcon icon={faCheck} />
              </span>
            </>
          )} */}
        </Tag>
      ))}

      <Modal
        visible={showPicksize}
        title={`Select sizes for ${product.brand} - ${product.name}`}
        onCancel={() => setShowPicksize(false)}
        onOk={() => {
          if (sizeSelected.length > 0) {
            setShowPicksize(false);
            input.onChange(sizeSelected);
            if (
              !sizeSelected.find(
                (size) => parseInt(size.id) === parseInt(active?.id)
              )
            ) {
              changeSizeActive(sizeSelected[0]);
            }
          } else {
            notification.warn({
              message: 'Please choose sizes for product',
            });
          }
        }}
      >
        <div className="row">
          <div className="col-md-6 text-center">
            <img
              style={{ backgroundsize: sizeActive }}
              className="w-100"
              src={product.frontImg}
              alt=""
            />
          </div>
          <div className="col-md-6">
            {options.map((size) => (
              <Tag
                onClick={() => handleSelectSize(size)}
                onMouseOver={() => setSizeActive(`#${size.value}`)}
                className="cursor-pointer mb-2"
                color={`default`}
                key={size.id}
              >
                {`${size.value} `}
                <span
                  className={classNames({
                    invisible: !sizeSelected.find(
                      (item) => parseInt(item.id) === parseInt(size.id)
                    ),
                  })}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </Tag>
            ))}
          </div>
        </div>
      </Modal>
    </WrapperPickerSize>
  );
}
export default React.memo(RenderProductSizes);
