import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "./components/form";
import { Button, Spin } from "antd";
import BackToIcon from "images/chevron-down.svg";
import { getDetailWebhooks, updateWebhooks } from "./actions";
import { options_delivery, options_active_webhooks } from "utils/options";
import { Wrapper } from "./styled";
import GMHelmet from "components/GMHelmet";
import { postfix } from "utils/options";

export class index extends Component {
  componentDidMount() {
    this.props.getDetailWebhooks(this.props.match.params.id);
  }
  onEditFormSubmit = (values) => {
    let dataToUpdate = {
      name: values.name_webhooks ? values.name_webhooks : "",
      url: values.url_webhooks ? values.url_webhooks : "",
      status: values.webhooks_status
        ? values.webhooks_status
        : options_active_webhooks[0]["value"],
      topic: values.webhooks_topic
        ? values.webhooks_topic
        : options_delivery[0]["value"],
    };
    this.props.updateWebhooks(this.props.match.params.id, dataToUpdate);
  };

  render() {
    const { detailWebhooks, updateWebhooksLoading, pageName } = this.props;
    const initialValues = {
      name_webhooks: detailWebhooks.data.name || "",
      webhooks_topic: detailWebhooks.data.topic ? `${detailWebhooks.data.topic}` : options_delivery[0]["value"],
      url_webhooks: detailWebhooks.data.url || "",
      webhooks_status:
        detailWebhooks.data.status || options_active_webhooks[0]["value"],
    };
    return (
      <Wrapper
        pageName={pageName + ` #${this.props.match.params.id}`}
      >
        {this.props.match.params.id ? (
          <GMHelmet
            overrideTitle={`Edit webhooks #${this.props.match.params.id || ''}` + postfix}
          />
        ) : null}
        <Spin spinning={detailWebhooks.loading || updateWebhooksLoading}>
          <Form
            initialValues={initialValues}
            onSubmit={this.onEditFormSubmit}
          />
        </Spin>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  updateWebhooksLoading: state.webhooks.edit.update.loading,
  detailWebhooks: state.webhooks.edit.detail,
});

const mapDispatchToProps = (dispatch) => ({
  getDetailWebhooks: (id) => {
    dispatch(getDetailWebhooks(id));
  },
  updateWebhooks: (id, dataToUpdate) => {
    dispatch(updateWebhooks(id, dataToUpdate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
