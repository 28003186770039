import React from "react";
import SetPrimaryButton from "./SetPrimaryButton";
import DeleteButton from "./DeleteButton";
import { list_payment_method } from "utils/options";
import classNames from "classnames";
import AccountInfor from "./AccountInfor";
import { hidePartialOfEmail } from "utils/helper";
function BillingInforItem(billingPaymentAccount) {
  return (
    <div
      className={classNames("billing-infor-item p-3 mb-3", {
        "billing-infor-item-selected":
          parseInt(billingPaymentAccount?.isDefault) === 1,
      })}
    >
      {parseInt(billingPaymentAccount?.isDefault) === 1 && (
        <span className="font-weight-bold font-size-14">
          PRIMARY PAYMENT METHOD
        </span>
      )}
      <div className="row align-items-center">
        <div className="col-md-3 col-xl-3">
          {parseInt(billingPaymentAccount?.paymentAccount?.type) !== 2 ? (
            <div className="row d-flex justify-content-start align-items-center">
              <div className="col">
                <img
                  className="img-fluid"
                  style={{ width: 100 }}
                  src={
                    list_payment_method.find(
                      (method) =>
                        parseInt(method.value) ===
                        parseInt(billingPaymentAccount?.paymentAccount?.type)
                    )?.logo
                  }
                  alt="logo"
                />
              </div>
              {(parseInt(billingPaymentAccount?.paymentAccount?.type) === 0 ||
                parseInt(billingPaymentAccount?.paymentAccount?.type) ===
                  1) && (
                <div className="col">
                  <div className="font-size-16 text-muted">
                    {hidePartialOfEmail(
                      billingPaymentAccount?.paymentAccount?.email
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <AccountInfor
              cardId={billingPaymentAccount?.paymentAccount?.content}
            />
          )}
        </div>
        <div className="col-md-3 col-xl-2 text-md-center">
          <div>
            <div className="font-size-16 text-muted">Currency:</div>
            <div className="font-size-12 text-muted">Multicurrency</div>
          </div>
        </div>
        <div
          className={classNames(
            {
              "col-md-4 col-xl-5":
                parseInt(billingPaymentAccount?.isDefault) === 0,
            },
            {
              "col-md-5 col-xl-6":
                parseInt(billingPaymentAccount?.isDefault) === 1,
            }
          )}
        >
          <div className="font-size-16 text-muted">Billing address:</div>
          <div className="font-size-12 text-muted">
            {`${billingPaymentAccount?.billingInfor?.firstName} ${billingPaymentAccount?.billingInfor?.lastName}, 
          ${billingPaymentAccount?.billingInfor?.streetAddress}, ${billingPaymentAccount?.billingInfor?.city}, ${billingPaymentAccount?.billingInfor?.country?.countryName}, ${billingPaymentAccount?.billingInfor?.postalcode}`}
          </div>
        </div>
        {parseInt(billingPaymentAccount?.isDefault) === 0 && (
          <div className="col-6 col-md col-xl-2 d-md-flex align-items-md-center justify-content-md-end">
            <SetPrimaryButton
              billingPaymentMethodId={billingPaymentAccount?.id || 0}
            />
            <DeleteButton
              billingPaymentMethodId={billingPaymentAccount?.id || 0}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(BillingInforItem);
