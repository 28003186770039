import axios from "../utils/request";
import queryString from "query-string";
const prefix = "/product-campaigns";
export const detailCampaign = (id) => {
  return axios({
    method: "GET",
    url: `/campaigns/${id}?with[]=campaignTags`,
  });
};
export const getListExportFeed = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `/export_background`,
  });
};

export const getListExportFeedDetail = (id) => {
  return axios({
    method: "GET",
    url: `/export_background/${id}`,
  });
};

export const detailBulk = (id) => {
  return axios({
    method: "GET",
    url: `campaigns/${id}/bulk?with[]=imageArtWorK`,
  });
};

export const syncCampaign = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/campaigns/sync`,
    is_json_content_type: true,
  });
};

export const createBulk = (dataToCreate, id) => {
  return axios({
    method: "POST",
    data: dataToCreate,
    url: `/campaigns/${id}/bulk-create-campaigns`,
    is_json_content_type: true,
  });
};

export const createBulk2 = (dataToCreate, id) => {
  return axios({
    method: "POST",
    data: dataToCreate,
    url: `/campaigns/${id}/bulk-create-campaigns/v2`,
    is_json_content_type: true,
  });
};

export const listProductToClone = (id, params = {}) => {
  return axios({
    method: "GET",
    url: `/campaigns/${id}/product-campaigns`,
    params: {
      ...params,
      "with[0]": "variants",
      "with[1]": "sizes",
      "with[2]": "exProduct",
      "with[3]": "exProduct.optionColors",
      "with[4]": "customPrice",
      "with[5]": "exProduct.optionSizes",
    },
  });
};

export const listCampaignToPreview = (listCampaignIds) => {
  return axios({
    method: "GET",
    url: `campaigns/list-campaign-to-preview?${queryString.stringify(
      { cam: listCampaignIds },
      { arrayFormat: "bracket" }
    )}&with[]=productCampaigns&with[]=productCampaigns.exProduct&with[]=productCampaigns.exProduct.optionColors&with[]=productCampaigns.exProduct.optionSizes&with[]=productCampaigns.productAdditionImages`,
  });
};

export const updateListAdditionalImage = (dataToUpdate, productCampaignId) => {
  return axios({
    method: "PUT",
    data: dataToUpdate,
    url: `/product-addition-image/${productCampaignId}`,
    is_json_content_type: true,
  });
};

export const createCampaign = (dataToCreateCampaign) => {
  return axios({
    method: "POST",
    data: dataToCreateCampaign,
    url: `${prefix}/v2`,
    is_json_content_type: true,
  });
};


export const previewCampaign = (dataToCreateCampaign) => {
  return axios({
    method: "POST",
    data: dataToCreateCampaign,
    url: `/campaigns/review-render`,
    is_json_content_type: true,
  });
};
export const previewListCampaign = (id) => {
  return axios({
    method: "GET",
    url: `/campaigns/${id}/review-render`,
    is_json_content_type: true,
  });
};

export const listCampaigns = (params) => {
  return axios({
    method: "GET",
    params,
    url: `campaigns?with[]=imageRender&with[]=productCampaigns`,
  });
};

export const archiveCampaigns = (data) => {
  return axios({
    method: "POST",
    data,
    url: `campaigns/archive`,
    is_json_content_type: true,
  });
};

export const unarchiveCampaigns = (data) => {
  return axios({
    method: "POST",
    data,
    url: `campaigns/unarchive`,
    is_json_content_type: true,
  });
};

export const deleteCampaigns = (data) => {
  return axios({
    method: "POST",
    data,
    url: `campaigns/delete`,
    is_json_content_type: true,
  });
};

export const syncCampaigns = (data) => {
  return axios({
    method: "data",
    data,
    url: `campaign/sync`,
    is_json_content_type: true,
  });
};

export const previewCampaigns = (params) => {
  return axios({
    method: "GET",
    params,
    url: `campaigns?with[]=imageRender`,
    is_json_content_type: true,
  });
};

export const updateProductPrice = (dataToUpdate, id) => {
  return axios({
    method: "PUT",
    data: dataToUpdate,
    url: `product-campaigns/${id}/prices`,
    is_json_content_type: true,
  });
};

export const updateProductSize = (dataToUpdate, id) => {
  return axios({
    method: "PUT",
    data: dataToUpdate,
    url: `campaigns/${id}/sizes`,
    is_json_content_type: true,
  });
};

export const updateProductColor = (dataToUpdate, id) => {
  return axios({
    method: "PUT",
    data: dataToUpdate,
    url: `campaigns/${id}/colors`,
    is_json_content_type: true,
  });
};

export const updateCampaignInfo = (dataToUpdate, id) => {
  return axios({
    method: "PUT",
    data: dataToUpdate,
    url: `product-campaigns/${id}`,
    is_json_content_type: true,
  });
};

export const listProductByCampaign = (campaignId, params = {}) => {
  return axios({
    method: "GET",
    url: `/campaigns/${campaignId}/product-campaigns`,
    params: {
      ...params,
      "with[0]": "variants",
      "with[1]": "sizes",
      "with[2]": "exProduct",
      "with[3]": "exProduct.optionColors",
      "with[4]": "customPrice",
      "with[5]": "exProduct.optionSizes",
    },
  });
};

export const exportCampaigns = (data) => {
  return axios({
    method: "POST",
    data,
    url: `campaigns/export`,
    is_json_content_type: true,
  });
}

export const listCampaignsToExport = (camIds, params = {}) => {
  return axios({
    method: "GET",
    url: `campaigns/list-campaigns-to-export?with[]=productCampaigns&with[]=productCampaigns.exProduct&with[]=productCampaigns.exProduct.optionColors`,
    params: {
      "campaigns": camIds
    },
  });
}

export const searchCampaignsToExport = (params = {}) => {
  return axios({
    method: "GET",
    url: `campaigns/list-campaigns-to-export?with[]=productCampaigns`,
    params,
  });
}

export const exportFeedFormAmazon = (data) => {
  return axios({
    method: "POST",
    data,
    url: `campaigns/export-product-amazon`,
    is_json_content_type: true,
  });
}

export const exportFeedFormGoogle = (data) => {
  return axios({
    method: "POST",
    data,
    url: `campaigns/export-product-google`,
    is_json_content_type: true,
  });
}

export const exportFeedCustomAmazon = (data) => {
  return axios({
    method: "POST",
    data,
    url: `campaigns/export-custom-amazon`,
    is_json_content_type: true,
  });
}

export const countCampaign = () => {
  return axios({
    method: "GET",
    url: `campaigns/count-by-statuses`
  })
}

export const updateCampaignDesign = (data) => {
  return axios({
    url: `campaigns/update-design`,
    method: "POST",
    data,
    is_json_content_type: true
  })
}