/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, notification, Modal, Dropdown } from 'antd';
import { ReactComponent as DownIcon } from 'images/icons/chevron-down.svg';
import {
  listOrderToMatchProduct,
  saveMatchProduct,
  setSelectedOrderItems,
  deleteDesign,
  removedOrderItemMatchProduct,
  resetDeleteDesignData,
  resetRemoveOrderItemData,
  resetMatchProductData,
} from './actions';
import FormMatchProduct from './components/FormMatchProduct';
import { getListExproduct } from '../../../Mockups/scenes/Create/actions';
import { change, arrayRemove } from 'redux-form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ContextMenuWrapper, GlobalStyle, Wrapper } from './styled';
import { GMButton } from 'components/Button';
import GMNotify from 'components/Notification';
import {
  checkVariantRiskAction,
  closeVariantRiskAction,
  resetReducerAction,
} from '../Create/actions';
const { confirm } = Modal;
const listOrderIdToMatchProduct = localStorage.getItem(
  'listOrderIdToMatchProduct'
)
  ? JSON.parse(localStorage.getItem('listOrderIdToMatchProduct'))
  : [];
class index extends Component {
  constructor(props) {
    super(props);
    this.uploadImageRef = React.createRef();
    this.state = {
      checked: false,
    };
  }

  componentDidMount() {
    let state = { ...this.props.history.location.state };
    let { listOrderId = [] } = state;
    if (listOrderId && Array.isArray(listOrderId) && listOrderId.length > 0) {
      delete state.listOrderId;
      localStorage.setItem(
        'listOrderIdToMatchProduct',
        JSON.stringify(listOrderId)
      );
      this.props.history.replace({ ...this.props.history.location, state });
    } else {
      listOrderId = listOrderIdToMatchProduct;
    }

    if (listOrderId.length > 0) {
      const fetchOrders = ({ orders = [], index = 0, step = 5 }) => {
        const orders2Send = [...orders].slice(index, index + step);
        const { pathname } = this.props.history?.location;
        if (orders2Send?.length <= 0 || pathname !== '/orders/match-product') {
          return;
        }
        this.props.listOrderToMatchProduct({ orders: orders2Send, cb: () => fetchOrders({ orders, index: index + step, step }) });
      };
      fetchOrders({ orders: listOrderId, index: 0, step: 5 });
    } else {
      notification.warn({
        message: 'Please choose orders to match product.',
        duration: 5,
      });
    }
    if (
      this.props.FormMatchProduct?.syncErrors?.listOrderItemsToMatch &&
      this.props.FormMatchProduct?.syncErrors?.listOrderItemsToMatch &&
      Array.isArray(
        this.props.FormMatchProduct.syncErrors.listOrderItemsToMatch
      )
    ) {
      for (
        let i = 0;
        i < this.props.FormMatchProduct.syncErrors.listOrderItemsToMatch.length;
        i++
      ) {
        if (
          this.props.FormMatchProduct.syncErrors.listOrderItemsToMatch[i] &&
          Object.keys(
            this.props.FormMatchProduct.syncErrors.listOrderItemsToMatch[i]
          ).length !== 0
        ) {
          this.props.changeFieldValue(
            `listOrderItemsToMatch[${i}].isImport`,
            2
          );
        } else {
          this.props.changeFieldValue(
            `listOrderItemsToMatch[${i}].isImport`,
            1
          );
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(
        prevProps.FormMatchProduct?.syncErrors?.listOrderItemsToMatch
      ) !==
      JSON.stringify(
        this.props.FormMatchProduct?.syncErrors?.listOrderItemsToMatch
      ) &&
      this.props.FormMatchProduct?.syncErrors?.listOrderItemsToMatch &&
      Array.isArray(
        this.props.FormMatchProduct.syncErrors.listOrderItemsToMatch
      )
    ) {
      for (
        let i = 0;
        i < this.props.FormMatchProduct.syncErrors.listOrderItemsToMatch.length;
        i++
      ) {
        if (
          this.props.FormMatchProduct.syncErrors.listOrderItemsToMatch[i] &&
          Object.keys(
            this.props.FormMatchProduct.syncErrors.listOrderItemsToMatch[i]
          ).length !== 0
        ) {
          this.props.changeFieldValue(
            `listOrderItemsToMatch[${i}].isImport`,
            2
          );
        } else {
          this.props.changeFieldValue(
            `listOrderItemsToMatch[${i}].isImport`,
            1
          );
        }
      }
    }
    let tempData = [];
    let isDataChanged = false;
    if (
      JSON.stringify(prevProps.listOrderItemsSuccess) !==
      JSON.stringify(this.props.listOrderItemsSuccess) &&
      Array.isArray(this.props.listOrderItemsSuccess)
    ) {
      tempData = this.props.FormMatchProductData.listOrderItemsToMatch.map(
        (item) => {
          if (!this.props.listOrderItemsSuccess.includes(item.id)) {
            item.altStatus = 'success';
          }
          return item;
        }
      );
      isDataChanged = true;
      // this.props.changeFieldValue("listOrderItemsToMatch", tempData);
    }
    if (
      JSON.stringify(prevProps.listOrderItemsError) !==
      JSON.stringify(this.props.listOrderItemsError) &&
      Array.isArray(this.props.listOrderItemsError)
    ) {
      tempData = this.props.FormMatchProductData.listOrderItemsToMatch.map(
        (item) => {
          if (!this.props.listOrderItemsError.includes(item.id)) {
            item.altStatus = 'failed';
          }
          return item;
        }
      );

      isDataChanged = true;
      // this.props.changeFieldValue("listOrderItemsToMatch", tempData);
    }
    isDataChanged &&
      this.props.changeFieldValue('listOrderItemsToMatch', tempData);
    if (prevProps.removedOrderItemData != this.props.removedOrderItemData) {
      const tempData = this.props.FormMatchProductData.listOrderItemsToMatch.filter(
        (item) => item.id != this.props.removedOrderItemData
      );
      this.props.changeFieldValue('listOrderItemsToMatch', tempData);
    }
    if (
      JSON.stringify(prevProps.deleteDesignData) !==
      JSON.stringify(this.props.deleteDesignData) &&
      Array.isArray(this.props.deleteDesignData)
    ) {
      for (
        let index = 0;
        index < this.props.FormMatchProductData.listOrderItemsToMatch.length;
        index++
      ) {
        const currOrderItem = (this.props.deleteDesignData || []).find(
          (item) =>
            parseInt(item.orderItemId) ===
            parseInt(
              this.props?.FormMatchProductData?.listOrderItemsToMatch?.[index]
                ?.id
            )
        );
        if (currOrderItem) {
          this.props.changeFieldValue(
            `listOrderItemsToMatch[${index}].${currOrderItem.sidePrint === 'front'
              ? 'frontDesignUrl'
              : 'backDesignUrl'
            }`,
            ''
          );
          this.props.changeFieldValue(
            `listOrderItemsToMatch[${index}].${currOrderItem.sidePrint
              ? 'imageFrontOriginal'
              : 'imageBackOriginal'
            }`,
            ''
          );
        }
      }
      this.props.resetDeleteDesignData();
    }

    if (
      this.props.listOrderToMatchProductData.data?.length === 0 &&
      this.props.listOrderToMatchProductDataLoading === false
    ) {
      notification.warning({
        message: 'Orders are not eligible for matching product',
      });
      this.props.history.push('/orders/list');
      // this.props.history.replace({ ...this.props.history.location });
    }
  }

  removedOrderItemMatchProduct = (orderItemId, orderId) => {
    this.props.removedOrderItemMatchProduct({
      orderItemId: orderItemId,
      orderId: orderId,
    });
  };

  getConvertedData = (values = null) => {
    if (!values) values = this.props.FormMatchProduct?.values;

    let a = [];
    let temp = values?.listOrderItemsToMatch?.map(function (item) {
      return a.push({
        orderId: item.orderId,
        expId: item.expId,
        quantity: item.quantity,
        colorId: item.color,
        sizeId: item.size,
        orderItemId: item.id,
        giftMessage: item?.giftMessage || '',
        shippingMethod: item?.shippingLabel || '',
        imageBackUrl: item.backDesignUrl || '',
        imageFrontUrl: item.frontDesignUrl || '',
        imageBackOriginal: item.imageBackOriginal || '',
        imageFrontOriginal: item.imageFrontOriginal || '',
        sidePrint: item.printSide2 || 'normal',
      });
    });

    let item = { items: a };
    return item;
  };
  //Check variant at risk
  handleSubmit = () => {
    let values = this.props.FormMatchProduct?.values?.listOrderItemsToMatch;
    let data = [];

    let newArray = values?.map(function (i) {
      return data.push({
        exp_id: parseInt(i?.expId),
        color_id: parseInt(i?.color),
        size_id: parseInt(i?.size),
        order_id: parseInt(i?.orderId),
        order_item_id: parseInt(i?.id),
      });
    });
    this.props.checkVariantRiskAction({
      data: { data: data },
      submitData: this.getConvertedData(),
      form: 'match_product',
      matchProductFnc: this.handleOnMatchProduct,
    });
  };
  //Submit after check and close modal
  handleOnMatchProduct = () => {
    let listItems = [];
    let temp = this.props.FormMatchProduct?.values?.listOrderItemsToMatch?.map(
      function (item) {
        return listItems.push({
          orderId: item.orderId,
          expId: item.expId,
          quantity: item.quantity,
          colorId: item.color,
          sizeId: item.size,
          orderItemId: item.id,
          giftMessage: item?.giftMessage || '',
          shippingMethod: item?.shippingLabel || '',
          imageBackUrl: item.backDesignUrl || '',
          imageFrontUrl: item.frontDesignUrl || '',
          imageBackOriginal: item.imageBackOriginal || '',
          imageFrontOriginal: item.imageFrontOriginal || '',
          sidePrint: item.printSide2 || 'normal',
        });
      }
    );
    let stack = {};

    listItems.forEach(function (el) {
      let key = el.orderId;
      if (stack[key] === undefined) {
        stack[key] = [el];
      } else {
        stack[key].push(el);
      }
    });
    // save
    const saveObjs = Object.values(stack);
    const saveFnc = (index = -1) => {
      const obj = saveObjs[index];
      if (!obj) {
        return this.props.saveMatchProduct({ items: null });
      }
      this.props.saveMatchProduct({ items: obj, next: () => setTimeout(() => saveFnc(index + 1), 0) });
    }
    saveFnc(0);

    window.scroll(0, 0);
    this.props.closeVariantRiskAction();

    this.props.resetReducerAction();
  };

  handleCloseModal = () => {
    this.props.closeVariantRiskAction();
  };

  removeAllDesignBySide = (side) => {
    const {
      selectedOrderItems,
      deleteDesign,
      setSelectedOrderItems,
    } = this.props;
    confirm({
      title: `Do you want to remove ${side} design of these orders ?`,
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        if (selectedOrderItems.length === 0) {
          notification.warn({
            message: 'Please choose order item before removing design.',
            duration: 5,
          });
          return;
        }
        deleteDesign({
          orderItemIds: selectedOrderItems,
          sidePrint: side,
        });
        setSelectedOrderItems([]);
      },
      onCancel() { },
    });
  };

  render() {
    const {
      listOrderToMatchProductData,
      pageName,
      exproducts,
      profile,
      removedOrderItemLoading,
      saveMatchProductLoading,
      setSelectedOrderItems,
      changeFieldValue,
      deleteDesignLoading,
      selectedOrderItems,
      removedOrderItemMatchProductLoading,
      listVariantRisk,
      listVariantRiskLoading,
      showModalVariant,
    } = this.props;
    let listOrderItems = [];
    for (let i = 0; i < listOrderToMatchProductData.data.length; i++) {
      listOrderItems = listOrderItems.concat(
        listOrderToMatchProductData.data[i]['orderItems'] || []
      );
      listOrderToMatchProductData.data[i]['giftMessage'] =
        listOrderToMatchProductData.data[i]?.orderNote?.giftMessage;
    }
    // listOrderItems = listOrderItems.map((i) => {
    //   return {
    //     ...i,
    //     printSide: i?.exProduct?.printLocation || i.printSide,
    //   };
    // });
    const initialValues = {
      listOrderItemsToMatch: listOrderItems,
      listOrdersToMatch: listOrderToMatchProductData.data.map((o) => {
        // order?.shippingLabel
        if (o?.shippingLabel && o.shippingLocation === 1) {
          o.shippingMethod = 0;
        }
        return o;
      }),
    };
    const listActions = [
      {
        key: 'remova_all_front_designs',
        text: 'Remove all front designs',
        onSelect: (evt) => {
          evt.preventDefault();
          this.removeAllDesignBySide('front');
        },
      },
      {
        key: 'remove_all_back_designs',
        text: 'Remove all back designs',
        onSelect: (evt) => {
          evt.preventDefault();
          this.removeAllDesignBySide('back');
        },
      },
      {
        key: 'upload_images',
        text: 'Upload your images',
        onSelect: (evt) => {
          evt.preventDefault();
          if (this.uploadImageRef && this.uploadImageRef.current) {
            this.uploadImageRef.current.click();
          }
        },
      },
    ];
    return (
      <>
        <Wrapper
          pageName={pageName}
          noContainer
          ctas={
            selectedOrderItems.length > 0 ? (
              <Dropdown
                trigger="click"
                overlay={
                  <ContextMenuWrapper>
                    {listActions.map((actionItem) => (
                      <a
                        key={actionItem.key}
                        href=""
                        onClick={actionItem.onSelect}
                      >
                        <span>{actionItem.text}</span>
                      </a>
                    ))}
                  </ContextMenuWrapper>
                }
                placement="bottomLeft"
                arrow
              >
                <GMButton
                  type="button"
                  className="moreactions"
                  color="SecondaryGray"
                >
                  More actions
                  <DownIcon />
                </GMButton>
              </Dropdown>
            ) : null
          }
        >
          <div className="mt-4">
            <GMNotify
              type="desc"
              className="message"
              noColor
              desc={
                <div className="desc">
                  An extra fee will be applied for the second-side print of <b>Apparel</b> and <b>Ceramic Ornament</b> products.
                </div>
              }
            />
          </div>
          <div className="main-content">
            <Spin
              spinning={
                listOrderToMatchProductData.loading ||
                exproducts.loading ||
                removedOrderItemLoading ||
                saveMatchProductLoading ||
                deleteDesignLoading ||
                removedOrderItemMatchProductLoading
              }
              tip={
                saveMatchProductLoading && (
                  <p className="text-center font-size-16">
                    We are processing your request. Please be patient.
                    <br />
                  </p>
                )
              }
            >
              <FormMatchProduct
                handleOnSubmit={this.handleSubmit}
                saveOrderItem={this.saveOrderItem}
                changeFieldValue={changeFieldValue}
                setSelectedOrderItems={setSelectedOrderItems}
                selectedOrderItems={selectedOrderItems}
                removedOrderItemMatchProduct={this.removedOrderItemMatchProduct}
                profile={profile}
                exproducts={exproducts.data}
                uploadImageRef={this.uploadImageRef}
                initialValues={initialValues}
                removeAllDesignBySide={this.removeAllDesignBySide}
                deleteDesign={this.props.deleteDesign}
                listOrders={this.props.listOrderToMatchProductData.data}
                listOrderItemsError={this.props.listOrderItemsError}
                listOrderItemsSuccess={this.props.listOrderItemsSuccess}
              />
            </Spin>
          </div>
        </Wrapper>
        <GlobalStyle />
        <Modal
          title="Order confirmation required"
          visible={showModalVariant}
          onCancel={() => {
            this.handleCloseModal();
          }}
          onOk={() => {
            this.handleOnMatchProduct();
          }}
          width={800}
          footer={null}
        >
          <Spin spinning={listVariantRiskLoading}>
            <div className="mb-2">
              Your order includes the following{' '}
              <a
                href="https://help.gearment.com/en/article/order-with-variation-at-risk-8wumdy/"
                target="blank"
              >
                variant(s) at risk
              </a>
              :
            </div>
            {listVariantRisk?.data?.map((i) => {
              return (
                <p>
                  <ul>
                    <li>
                      {i?.order?.name} - {i?.exp_brand} - {i?.color_name} -{' '}
                      {i?.size_name}
                    </li>
                  </ul>
                </p>
              );
            })}
            <div>
              By continuing, you confirm to match product with the variant(s) at
              risk and acknowledge that the processing time may be extended for
              a few business days.
            </div>
          </Spin>
          <div className="d-flex mt-3 align-items-right justify-content-end pb-3">
            <GMButton
              color="SecondaryGray"
              type="button"
              onClick={() => {
                this.handleCloseModal();
              }}
              style={{ width: 170 }}
            >
              Cancel
            </GMButton>
            <GMButton
              style={{ width: 170 }}
              onClick={() => {
                this.handleOnMatchProduct();
              }}
              className="ml-3"
            >
              Confirm order
            </GMButton>
          </div>
        </Modal>
      </>
    );
  }
}

export default connect(
  (state) => ({
    listOrderToMatchProductData:
      state.order.matchProduct.listOrderToMatchProduct,
    listOrderToMatchProductDataLoading: state.order.matchProduct.loading,
    exproducts: state.mockup.create.exproducts,
    profile: state.auth.info.profile,
    removedOrderItemLoading: state.order.matchProduct.remove.loading,
    removedOrderItemData: state.order.matchProduct.remove.data,
    saveMatchProductLoading: state.order.matchProduct.saveMatchProduct.loading,
    saveMatchProductData: state.order.matchProduct.saveMatchProduct,
    listOrderItemsSuccess:
      state.order.matchProduct.saveMatchProduct.listOrderItemsSuccess,
    listOrderItemsError:
      state.order.matchProduct.saveMatchProduct.listOrderItemsError,
    selectedOrderItems: state.order.matchProduct.selectedOrderItems,
    FormMatchProductData: state.form?.FormMatchProduct?.values,
    FormMatchProduct: state.form?.FormMatchProduct,
    deleteDesignLoading: state.order.matchProduct.deleteDesign.loading,
    deleteDesignData: state.order.matchProduct.deleteDesign.data,
    removedOrderItemMatchProductLoading:
      state.order.matchProduct.remove.loading,
    listVariantRiskLoading: state.order.createOrder.listVariantRisk.loading,
    listVariantRisk: state.order.createOrder.listVariantRisk,
    showModalVariant: state.order.createOrder.showModalVariant,
  }),
  (dispatch) => ({
    listOrderToMatchProduct: (data) => {
      dispatch(listOrderToMatchProduct(data));
    },
    listExproduct: (params) => {
      dispatch(getListExproduct(params));
    },
    saveMatchProduct: (data) => {
      dispatch(saveMatchProduct(data));
    },
    setSelectedOrderItems: (selectedOrderItems) => {
      dispatch(setSelectedOrderItems(selectedOrderItems));
    },
    changeFieldValue: function (field, value) {
      dispatch(change('FormMatchProduct', field, value));
    },
    removeArray: function (index) {
      dispatch(arrayRemove('FormMatchProduct', 'listOrderItemsToMatch', index));
    },
    deleteDesign: function (data) {
      dispatch(deleteDesign(data));
    },
    resetDeleteDesignData: function () {
      dispatch(resetDeleteDesignData());
    },
    removedOrderItemMatchProduct: function (data) {
      dispatch(removedOrderItemMatchProduct(data));
    },
    resetRemoveOrderItemData: function () {
      dispatch(resetRemoveOrderItemData());
    },
    resetMatchProductData: function () {
      dispatch(resetMatchProductData());
    },
    checkVariantRiskAction: (data) => {
      dispatch(checkVariantRiskAction(data));
    },
    closeVariantRiskAction: () => {
      dispatch(closeVariantRiskAction());
    },
    resetReducerAction: () => {
      dispatch(resetReducerAction());
    },
  })
)(index);
