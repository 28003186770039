import React, { memo } from "react";
import { TableWrapper } from "./styled";
import { uniqueId } from "lodash";
import { Checkbox, Table } from "antd";
import { connect } from "react-redux";

const GMTable = ({ className, ...props }) => {
  return (
    <TableWrapper className={className + " gmtable"}>
      <Table rowKey="id" {...props} />
    </TableWrapper>
  );
};

// export default GMTable;
export default memo(
  connect((props, state) => {
    const {
      rowSelection: { selectedRowKeys }
    } = state;

    return {
      ...state
    };
  })(GMTable)
);
