import styled from "styled-components";
import PageContainer from "components/PageContainer";
import { BASE_WHITE, ERROR_500, ERROR_800, PRIMARY_500, PRIMARY_800 } from "components/colors";

export const Wrapper = styled(PageContainer)`
  button.addnewbtn {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      stroke: ${BASE_WHITE};
      margin-right: 12px;
    }
  }
  .edit {
    svg {
      stroke: ${PRIMARY_500};
    }
    &:hover svg {
      stroke: ${PRIMARY_800};
    }
  }
  .delete {
    svg {
      stroke: ${ERROR_500};
    }
    &:hover svg {
      stroke: ${ERROR_800};
    }
  }
  .edit,
  .delete {
    &:hover {
      cursor: pointer;
    }
  }
`;
