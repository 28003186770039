import { GRAY_100, GRAY_600, PRIMARY_500 } from 'components/colors';
import styled from 'styled-components';
export const Wrapper = styled.div`
  border: 1px dashed #eaecf0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  padding: 8px;
  gap:8px;

  /* Base/White */

  background: #ffffff;
  /* Gray/200 */

  border-radius: 12px;

  .upload-success {
    border: 1px dashed #eaecf0;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    padding: 8px;
    height: 100px;
  }

  .text-upload {
    font-size: 14px;
  }
  .click-to {
    color: ${PRIMARY_500};
  }
  .upload {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
