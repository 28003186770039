import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { Radio, Spin } from 'antd';

import { renderInputText, renderSwitchAnt, renderSelectAnt } from 'utils/Form';

const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.replace(/\s+/g, '').length === 0) {
    errors.name = 'Please enter your mockup name';
  }
  if (!values.expId) {
    errors.expId = true;
  }
  if (!values.expId) {
    errors.expId = 'Please choose product to customize mockup.';
  }
  return errors;
};

let FormUpdateMockup = ({
  handleSubmit,
  exproducts,
  product_style_selected,
  frontImgUrl,
  backImgUrl,
  changeFieldValue,
  ...props
}) => {
  const [isFrontOrBack, setIsFrontOrBack] = useState('Front');
  console.log(exproducts, 'brand');
  let img_preview = '';
  if (product_style_selected) {
    img_preview =
      isFrontOrBack === 'Front'
        ? product_style_selected.frontImg
        : product_style_selected.backImage;
  }

  const handleChangeProductStyle = (value) => {};
  return (
    <form onSubmit={handleSubmit}>
      <Field name="boxFrontTop" component="input" type="hidden" />
      <Field name="boxFrontLeft" component="input" type="hidden" />
      <Field name="boxBackTop" component="input" type="hidden" />
      <Field name="boxBackLeft" component="input" type="hidden" />
      <div className="container-fluid p-0">
        <div className="row mb-3">
          <div className="col-md-12 col-xl-6 mb-4">
            <div className="form-group">
              <label htmlFor="mockup_name" className="col-form-label">
                Mockup name
              </label>
              <Field id="mockup_name" name="name" component={renderInputText} />
            </div>
            <div className="form-group">
              <label htmlFor="mockup_style" className="col-form-label">
                Product style
              </label>
              <Field
                id="mockup_style"
                name="expId"
                component={renderSelectAnt}
                options={(exproducts?.data || []).map((o) => {
                  return {
                    value: o?.value,
                    label: o?.brand,
                  };
                })}
            
                loading={exproducts.loading}
                onChange={handleChangeProductStyle}
                placeholder="-- Choose product style --"
              />
            </div>
            <div className="form-group">
              <div className="d-flex align-items-center">
                <Field
                  id="mockup_mark_default"
                  name="status"
                  component={renderSwitchAnt}
                />
                <label
                  htmlFor="mockup_mark_default"
                  className="ml-3 col-form-label"
                >
                  Mark as default
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label>Mockup</label>
              </div>
              <div className="col-6 text-center">
                <div className="border border-dashed p-1">
                  <img
                    src={frontImgUrl}
                    className="w-100"
                    style={{ maxWidth: 205 }}
                    alt=""
                  />
                  <h3 className="font-weight-light">Front</h3>
                </div>
              </div>
              {product_style_selected && product_style_selected.backImg && (
                <div className="col-6 text-center">
                  <div className="border border-dashed p-1">
                    <img
                      src={backImgUrl}
                      className="w-100"
                      style={{ maxWidth: 205 }}
                      alt=""
                    />
                    <h3 className="font-weight-light">Back</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 col-xl-6">
            <Spin spinning={exproducts.loading}>
              <div style={{ maxWidth: 400, margin: '0 auto' }}>
                <h5 className="mb-3 text-center">Mockup Original</h5>
                <div className="border border-dashed p-4 mb-2 text-center">
                  <div className="position-relative">
                    {!!img_preview && (
                      <img
                        src={img_preview}
                        className="w-100"
                        alt="preview-icon"
                      />
                    )}
                  </div>
                </div>
                <div className="text-center">
                  <Radio.Group
                    value={isFrontOrBack}
                    onChange={(e) => setIsFrontOrBack(e.target.value)}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="Front">Front </Radio.Button>
                    {product_style_selected &&
                      product_style_selected.backImg && (
                        <Radio.Button value="Back">Back</Radio.Button>
                      )}
                  </Radio.Group>
                </div>
              </div>
            </Spin>
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary"
            style={{ width: 150, borderRadius: '5px' }}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

FormUpdateMockup.propTypes = {};

FormUpdateMockup = reduxForm({
  form: 'FormUpdateMockup',
  enableReinitialize: true,
  validate,
})(FormUpdateMockup);
const selector = formValueSelector('FormUpdateMockup');
FormUpdateMockup = connect(
  (state, props) => {
    return {
      product_style_selected:
        props.exproducts.data_obj[selector(state, 'expId')],
      frontImgUrl: selector(state, 'frontImgUrl'),
      backImgUrl: selector(state, 'backImgUrl'),
      boxFrontTop: selector(state, 'boxFrontTop'),
      boxFrontLeft: selector(state, 'boxFrontLeft'),
      boxBackTop: selector(state, 'boxBackTop'),
      boxBackLeft: selector(state, 'boxBackLeft'),
    };
  },
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change('FormUpdateMockup', field, value));
    },
  })
)(FormUpdateMockup);
export default FormUpdateMockup;
