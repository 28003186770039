import React, { Component } from 'react'
import { Field } from 'redux-form'
import { Table } from 'antd'
import FieldText from './FieldText'


export default class FieldAdditionImages extends Component {

    render() {
        const { fields, data } = this.props
        const columns = [
            {
                title: 'NUMBER',
                dataIndex: 'id',
                key: 'id',
                width: 20,
                render: (text, record, i) => `#${i + 1}`
            },
            {
                title: 'IMAGE URL',
                dataIndex: 'url',
                key: 'url',
                render: (text, record, i) => {
                    return(
                        <Field
                            name={`${fields.name}[${i}].url`}
                            component={FieldText}
                        />
                    )},
            },
        ];
        return(
            <Table rowKey='id' pagination={false} columns={columns} dataSource={data}/>
        )
    }
}