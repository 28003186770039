import axios from 'utils/request';

const prefix = '/order-payment';

export const getList = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}?with[]=transaction`,
    is_json_content_type: true,
  });
};

export const getDetail = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/${id}?with[]=transaction`,
  });
};
