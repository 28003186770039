import axios from 'utils/request';

const prefix = '/profile';

export const updatePassword = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/change-password`,
  });
};
export const resetPassword = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `/accounts/forget-password`,
  });
};
export const deleteAccount = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/delete`,
    is_json_content_type: true,
  });
};
export const getOTPCodeToDeleteAccount = () => {
  return axios({
    method: 'GET',
    url: `/accounts/delete-account-otp-code`,
  });
};
export const listStepsOfSettingAccount = (data) => {
  return axios({
    method: 'GET',
    url: `${prefix}/setting-account-steps`,
  });
};

export const sendConfirmationEmail = () => {
  return axios({
    method: 'GET',
    url: '/accounts/verification-email',
  });
};

export const updateEmail = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/change-email`,
    is_json_content_type: true,
  });
};

export const updateProfile = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}`,
    is_json_content_type: true,
  });
};

export const updateAvatar = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/upload-avatar-v2`,
    is_json_content_type: true,
  });
};

export const removeAvatar = () => {
  return axios({
    method: 'PUT',
    url: `${prefix}/remove-avatar`,
  });
};
export const getSystemSetting = () => {
  return axios({
    method: 'GET',
    url: 'settings',
  });
};
export const verifyAPI = () => {
  return axios({
    method: 'GET',
    url: prefix,
  });
};
export const connectToSocial = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/connect-social`,
  });
};

export const disconnectSocial = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/disconnect-social`,
  });
};
