import React, { memo, useState, useEffect, useRef } from "react";
import { AdsOverlay, AdsOverlayEffect, AdsWrapper, CTA } from "./styled";
import { ReactComponent as CloseIcon } from "images/icons/x-close.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Video from "images/Promotion/Minigame/surprise-box/surprise-box_banner.mp4";
import MVideo from "images/Promotion/Minigame/surprise-box/video_hop qua bi an_mobi_bg.mp4";
import classNames from "classnames";
import axios from "utils/request";
import { callUnboxer, checkStatus } from "./api";

function isHidden(el) {
  if (!el) {
    return !!1;
  }
  const style = window.getComputedStyle(el);
  return style.display === "none";
}

export default memo(({ invalidCallback = () => null }) => {
  const [isShow, setShow] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgLink, setImgLink] = useState("");
  const video = useRef();
  const mvideo = useRef();
  const checkIsShowModal = async () => {
    const res = await checkStatus();
    if (res.code === "success" && res?.data?.events?.length <= 0) {
      return setShow(!!1);
    }
    invalidCallback();
    return setShow(!1);
  };
  const openx = async () => {
    setLoading(!!1);
    const res = await callUnboxer();
    const imgLink1 = res?.data?.data?.link_image;
    const imgLink2 = res?.link_image;
    setImgLink(imgLink1 || imgLink2);
    setLoading(!1);
    setLoaded(!!1);
    // eslint-disable-next-line no-unused-expressions
    !isHidden(video?.current) && video?.current?.play();
    // eslint-disable-next-line no-unused-expressions
    !isHidden(mvideo?.current) && mvideo?.current?.play();
    setTimeout(() => {
      setShowGif(!!1);
    }, 2000);
  };
  useEffect(() => {
    checkIsShowModal();
  }, []);

  const play = (evt) => {
    evt.preventDefault();

    if (loading || loaded) {
      return false;
    }
    openx();
    return false;
  };

  if (!isShow) {
    return null;
  }

  return (
    <>
      <AdsOverlay />
      <AdsOverlayEffect className={classNames({ congr: loaded })} />
      <AdsWrapper>
        <div className="wrapper">
          <div className="inner-wrapper">
            <video
              preload="yes"
              ref={video}
              className="dvideo"
              playsInline
              muted
            >
              <source src={Video} type="video/mp4" />
            </video>
            <video
              preload="yes"
              ref={mvideo}
              className="mvideo"
              playsInline
              muted
            >
              <source src={MVideo} type="video/mp4" />
            </video>
            <div
              className={classNames("game-overlay", { loading, loaded })}
              onClick={play}
            >
              <CTA className="cta" />
              {showGif ? (
                <div
                  className="gif"
                  style={{
                    backgroundImage: `url(${imgLink})`
                  }}
                ></div>
              ) : null}
            </div>
          </div>
          <div
            className="close-btn"
            onClick={(evt) => {
              evt.preventDefault();
              setShow(false);
              return false;
            }}
          >
            <CloseIcon />
          </div>
        </div>
      </AdsWrapper>
    </>
  );
});
