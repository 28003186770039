import { actionType as TYPE } from "./actions";
import { notification } from "antd";

const initialState = {
  stores: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  stores2: {
    loading: false,
    data: []
  },
  listStoreStatus: {
    loading: false,
    data: [],
  },
  delete: {
    loading: false,
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        stores: {
          ...initialState.stores,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        stores: {
          ...state.stores,
          loading: false,
          data:
            action.data && action.data.storeIntegrations
              ? action.data.storeIntegrations
              : [],
          total:
            action?.data?.pagination?.total
              ? action.data.pagination.total
              : (action?.data?.storeIntegrations
                  ? action.data.storeIntegrations
                  : []
                ).length,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        stores: {
          ...state.stores,
          loading: false,
        },
      };

      case TYPE.LIST_STORE.REQUEST:
        return {
          ...state,
          stores2: {
            ...initialState.stores2,
            loading: true,
          },
        };
      case TYPE.LIST_STORE.SUCCESS:
        return {
          ...state,
          stores2: {
            ...state.stores2,
            loading: false,
            data:
              action.data && action.data.storeIntegrations
                ? action.data.storeIntegrations
                : [],
          },
        };
      case TYPE.LIST_STORE.ERROR:
        return {
          ...state,
          stores2: {
            ...state.stores2,
            loading: false,
          },
        }; 
    // Fall
    case TYPE.DELETE.REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
        },
      };
    case TYPE.DELETE.SUCCESS:
      notification.success({
        message: "Delete store successfully.",
        duration: 5,
      });
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
      };
    case TYPE.DELETE.ERROR:
      notification.error({
        message: "Delete store failed.",
        duration: 5,
      });
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
      };
    case TYPE.LIST_STORE_STATUS.REQUEST:
      return {
        ...state,
        listStoreStatus: {
          ...state.listStoreStatus,
          loading: true,
          data:
            action.data && action.data.storeStatus
              ? action.data.storeStatus
              : [],
        },
      };
    case TYPE.LIST_STORE_STATUS.SUCCESS:
      return {
        ...state,
        listStoreStatus: {
          ...state.listStoreStatus,
          loading: false,
          data:
            action.data && action.data.storeStatus
              ? action.data.storeStatus
              : [],
        },
      };
    case TYPE.LIST_STORE_STATUS.ERROR:
      return {
        ...state,
        listStoreStatus: {
          ...state.listStoreStatus,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
