import List from "./scenes/List";
import Edit from "./scenes/Edit";
import Create from "./scenes/Create";

export default [
  {
    key: "billing.return-address",
    name: "Return address",
    component: List,
    path: "/billing/return-address",
    icon: null,
    template: "default",
    isPublic: false,
  },
  {
    key: "billing.return-address.create",
    name: "Create return address",
    component: Create,
    path: "/billing/return-address/create",
    icon: null,
    template: "default",
    isPublic: false,
  },
  {
    key: ".billing.return-address.detail",
    name: "Edit return address",
    component: Edit,
    path: "/billing/return-address/:id/edit",
    icon: null,
    template: "default",
    isPublic: false,
  },
];
