import React, { Component } from 'react';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validate';
import { renderSelectAnt } from 'utils/Form';
import { list_platform_to_sync } from 'utils/options';
import FieldRangeDate from 'utils/fields/FieldRangeDate';
import { getStoreTypeName } from 'utils/helper';
import { Wrapper } from './styled';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listStore: [],
    };
  }
  componentDidUpdate(prevState, prevProps) {
    if (prevState.platformId !== this.props.platformId) {
      this.props.dispatch(change('FormSyncOrder', 'storeId', null));
      this.setState({
        listStore: this.props.stores.filter((store) => {
          return (
            store.backendType ==
            list_platform_to_sync.find(
              (item) => item.value == this.props.platformId
            )?.code
          );
        }),
      });
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-1">
            <label className="col-form-label font-weight-bold">Platform</label>
            <Field
              name="platformId"
              uploadFileRef={this.uploadFileRef}
              component={renderSelectAnt}
              options={list_platform_to_sync}
              placeholder="Choose platform"
            />
          </div>
          <div className="form-group mb-1">
            <div className="row">
              <Field
                name="date_range"
                component={FieldRangeDate}
                className="w-100"
                dateTimeFormat="YYYY/MM/DD"
                showTime={false}
                classContainer="col-12 col-md-6 mb-2"
                startPlaceholder="yyyy/mm/dd"
                labelStart={<span className="font-weight-bold">From</span>}
                labelEnd={<span className="font-weight-bold">To</span>}
                endPlaceholder="yyyy/mm/dd"
              />
            </div>
          </div>
          <div className="form-group mb-1">
            <label className="col-form-label font-weight-bold">Store</label>
            <Field
              name="storeId"
              component={renderSelectAnt}
              colSpan={24}
              placeholder="Choose store"
              options={[
                ...this.state.listStore?.map((store) => ({
                  value: `${store.id}`,
                  label:
                    `[${getStoreTypeName(store.backendType)}] ` +
                    (store.showName || `#${store.id}`),
                })),
              ]}
            />
          </div>
        </form>
      </Wrapper>
    );
  }
}
const selector = formValueSelector('FormSyncOrder');

export default connect((state) => ({
  platformId: selector(state, 'platformId'),
}))(
  reduxForm({
    form: 'FormSyncOrder',
    validate,
  })(Index)
);
