export const actionType = {
    LIST: {
        REQUEST: 'ORDER.IMPORT_PROGRESS.LIST.REQUEST',
        SUCCESS: 'ORDER.IMPORT_PROGRESS.LIST.SUCCESS',
        ERROR: 'ORDER.IMPORT_PROGRESS.LIST.ERROR',
    },
};

export function getList(params) {
    return {
        type: actionType.LIST.REQUEST,
        params
    }
}