export const actionType = {
  LIST: {
    REQUEST: "ORDER.STOCK.STATUS.REQUEST",
    SUCCESS: "ORDER.STOCK.STATUS.SUCCESS",
    ERROR: "ORDER.STOCK.STATUS.ERROR",
  },
  LIST_COLOR: {
    REQUEST: "LIST.COLOR.REQUEST",
    SUCCESS: "LIST.COLOR.SUCCESS",
    ERROR: "LIST.COLOR.ERROR",
  },
  LIST_SIZE: {
    REQUEST: "LIST.SIZE.REQUEST",
    SUCCESS: "LIST.SIZE.SUCCESS",
    ERROR: "LIST.SIZE.ERROR",
  },
  LIST_PRODUCT: {
    REQUEST: "LIST.PRODUCT.REQUEST",
    SUCCESS: "LIST.PRODUCT.SUCCESS",
    ERROR: "LIST.PRODUCT.ERROR",
  },
  EXPORT_STOCK_STATUS: {
    REQUEST: "EXPORT_STOCK_STATUS.REQUEST",
    SUCCESS: "EXPORT_STOCK_STATUS.SUCCESS",
    ERROR: "EXPORT_STOCK_STATUS.ERROR",
  },
};

export function getListStockStatus(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}
export function getListColor() {
  return {
    type: actionType.LIST_COLOR.REQUEST,
  };
}
export function getListSize() {
  return {
    type: actionType.LIST_SIZE.REQUEST,
  };
}
export function getListProduct() {
  return {
    type: actionType.LIST_PRODUCT.REQUEST,
  };
}
export function exportStockStatus(params) {
  return {
    type: actionType.EXPORT_STOCK_STATUS.REQUEST,
    params,
  };
}
