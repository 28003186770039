import {
    DATA_FEED_GOOGLE_DETAIL_REQUEST,
    DATA_FEED_GOOGLE_DETAIL_FAIL,
    DATA_FEED_GOOGLE_DETAIL_SUCCESS,

    DATA_FEED_GOOGLE_EDIT_REQUEST,
    DATA_FEED_GOOGLE_EDIT_FAIL,
    DATA_FEED_GOOGLE_EDIT_SUCCESS
} from './constants'
import { notification } from 'antd'
const initialState = {
    loading: false,
    detail: {
        loading: false
    }
}

function PostCreateReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_FEED_GOOGLE_DETAIL_REQUEST:
            return {
                ...state,
                detail: {
                    ...initialState.detail,
                    loading: true
                }
            }
        case DATA_FEED_GOOGLE_DETAIL_SUCCESS:
            return {
                ...state,
                detail: {
                    ...action.data.feedForm,
                    loading: false
                }
            }
        case DATA_FEED_GOOGLE_DETAIL_FAIL:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false
                }
            }

        
        case DATA_FEED_GOOGLE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DATA_FEED_GOOGLE_EDIT_SUCCESS:
            notification.success({
                message: action?.clientMsg || "Edit Feed Form Google successfully.",
                duration: 5
            })
            return {
                ...state,
                loading: false,
            }
        case DATA_FEED_GOOGLE_EDIT_FAIL:
            notification.error({
                message: action?.error?.[0]?.["clientMsg"] || "Edit Feed Form Google failed.",
                duration: 5
            })
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export default PostCreateReducer