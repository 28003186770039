import { actionType as TYPE } from "./actions";
import { notification } from "antd";
const initialState = {
  archivedOrders: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  unarchiveOrder: {
    loading: false,
  },
  deleteOrder: {
    loading: false,
  },
  countOrderAchived: {
    loading: false,
    data: {},
  },
  export: {
    loading: false,
    params: {},
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST_ARCHIVED_ORDER.REQUEST:
      return {
        ...state,
        archivedOrders: {
          ...initialState.archivedOrders,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_ARCHIVED_ORDER.SUCCESS:
      return {
        ...state,
        archivedOrders: {
          ...state.archivedOrders,
          loading: false,
          data: action.data.orderArchived,
          total:
            action.data &&
            action.data.pagination &&
            action.data.pagination.total
              ? action.data.pagination.total
              : action.data.length,
        },
      };
    case TYPE.LIST_ARCHIVED_ORDER.ERROR:
      return {
        ...state,
        archivedOrders: {
          ...state.archivedOrders,
          loading: false,
        },
      };
    case TYPE.UNARCHIVE_ARCHIVED_ORDER.REQUEST:
      return {
        ...state,
        unarchiveOrder: {
          ...state.unarchiveOrder,
          loading: true,
        },
      };
    case TYPE.UNARCHIVE_ARCHIVED_ORDER.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === "unarchive_order_successfully") {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        unarchiveOrder: {
          ...state.unarchiveOrder,
          loading: false,
          errors: action.error ? action.error : [],
        },
      };
    case TYPE.UNARCHIVE_ARCHIVED_ORDER.ERROR:
      notification.warning({ message: "Unarchive order failed", duration: 5 });
      return {
        ...state,
        unarchiveOrder: {
          ...state.unarchiveOrder,
          loading: false,
        },
      };
    case TYPE.DELETE_ARCHIVED_ORDER.REQUEST:
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: true,
        },
      };
    case TYPE.DELETE_ARCHIVED_ORDER.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === "delete_order_successfully") {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: false,
          errors: action.error ? action.error : [],
        },
      };
    case TYPE.DELETE_ARCHIVED_ORDER.ERROR:
      notification.warning({ message: "Archive order failed", duration: 5 });
      return {
        ...state,
        deleteOrder: {
          ...state.deleteOrder,
          loading: false,
        },
      };
    //Count order achived
    case TYPE.COUNT_ORDER_ACHIVED.REQUEST:
      return {
        ...state,
        countOrderAchived: {
          ...state.countOrderAchived,
          loading: true,
        },
      };
    case TYPE.COUNT_ORDER_ACHIVED.SUCCESS:
      const data = {};
      Object.values(action?.data || {}).forEach(item => {
        data[item?.title] = item;
      });
      return {
        ...state,
        countOrderAchived: {
          ...state.countOrderAchived,
          loading: false,
          data,
        },
      };
    case TYPE.COUNT_ORDER_ACHIVED.ERROR:
      return {
        ...state,
        countOrderAchived: {
          ...state.countOrderAchived,
          loading: false,
        },
      };
      case TYPE.EXPORT.REQUEST:
        return {
          ...state,
          export: {
            ...state.export,
            params: action.params,
            loading: true,
          },
        };
      case TYPE.EXPORT.SUCCESS:
        notification.success({
          message: "Export orders successfully.",
          duration: 5,
        });
        if (action.data && action.data.linkToDownloadFile) {
          window.open(action.data.linkToDownloadFile);
        }
        return {
          ...state,
          export: {
            ...state.export,
            loading: false,
          },
        };
      case TYPE.EXPORT.ERROR:
        notification.warn({
          message:
            action?.error?.[0]?.["msg"]
            || "Export orders failed.",
          duration: 5,
        });
        break;
    default:
      return state;
  }
}

export default ListReducer;
