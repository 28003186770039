import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Spin } from 'antd';
import BackToIcon from 'images/chevron-down.svg';
import FormCreateOrderLabel from '../CreateLableOrder/components/FormCreateOrder';
import { convertOrder } from './actions';
import { listCountry } from '../../../Users/scenes/Profile/actions';
import { getListExproduct } from '../../../Mockups/scenes/Create/actions';
import { getDetail } from './actions';
import { getListStore } from '../../../Stores/scenes/List/actions';
import { Wrapper } from 'scenes/general.styled';
import {
  checkVariantRiskAction,
  closeVariantRiskAction,
  resetReducerAction,
} from '../Create/actions';
import { GMButton } from 'components/Button';
class index extends Component {
  componentDidMount() {
    this.props.listCountry();
    this.props.getListExproduct({ sortBy: 'id', sortType: 'asc' });
    this.props.getDetail(this.props.match.params.id, {
      'with[]': 'orderItems.exProduct',
    });
    this.props.getListStore({
      isNotPlatform: 1,
      sortBy: 'id',
      sortType: 'asc',
    });
  }
  getConvertedData = () => {
    let values = this.props.FormCreateOrderLabel;
    const listOrderItems = [];
    if (values.listOrderItems) {
      for (let i = 0; i < values.listOrderItems.length; i++) {
        listOrderItems.push({
          productId: values.listOrderItems?.[i]?.['productId'] || 0,
          colorId: values.listOrderItems?.[i]?.['colorId'] || 0,
          sizeId: values.listOrderItems?.[i]?.['sizeId'] || 0,
          quantity: values.listOrderItems?.[i]?.['quantity'] || 1,
          barcodeId: values.listOrderItems?.[i]?.['barcodeId'] || 0,
          imageFrontId: values.listOrderItems?.[i]?.['designFrontId'] || 0,
          imageBackId: values.listOrderItems?.[i]?.['designBackId'] || 0,
          imageFrontPath:
            values.listOrderItems?.[i]?.['frontDesignFilePath'] +
              '/' +
              values.listOrderItems?.[i]?.['frontDesignFileName'] || 0,
          imageBackPath:
            values.listOrderItems?.[i]?.['backDesignFilePath'] +
              '/' +
              values.listOrderItems?.[i]?.['backDesignFileName'] || 0,
          barcodePath: values.listOrderItems?.[i]?.['barcode'] || '',
          productType: values.listOrderItems?.[i]?.['productType'] || 0,
          sidePrint: values.listOrderItems?.[i]?.['sidePrint'] || 'normal',
        });
      }
    }
    const dataToConvert = {
      cusName: values.fullName || '',
      cusPhoneNumber: values.phoneNumber || '',
      cusAddress: values.address01 || '',
      cusAddress2: values.address02 || '',
      cusCity: values.city || '',
      cusPostalCode: values.zipcode || '',
      shippingService: values.shippingService || 0,
      onlineId: values.orderId || '',
      cusEmail: values.email || '',
      state: values.state || '',
      cusCountry: values.country || '',
      storeId: values.storeId || '',
      barcodeIds: values.barcodeIds || [],
      orderItems: listOrderItems,
      orderNote: values.orderNote || '',
      originalOrderId: this.props?.match?.params?.id || 0,
    };
    return dataToConvert;
  };
  handleCheckVariantRisk = (values) => {
    let data = [];
    let newArray = values?.listOrderItems?.map(function (i) {
      return data.push({
        exp_id: parseInt(i?.productId),
        color_id: parseInt(i?.colorId),
        size_id: parseInt(i?.sizeId),
      });
    });
    this.props.checkVariantRiskAction({
      data: { data: data },
      submitData: this.getConvertedData(),
      form: 'convert_order',
    });
  };
  handleSubmit = (values) => {
    window.scroll(0, 0);
    this.props.closeVariantRiskAction();
    this.props.convertOrder(this.getConvertedData());
    this.props.resetReducerAction();
  };
  handleCloseModal = () => {
    this.props.closeVariantRiskAction();
  };

  render() {
    const {
      pageName,
      createOrderLoading,
      stores,
      countries,
      exproducts,
      profile,
      detail,
      listVariantRisk,
      showModalVariant,
      listVariantRiskLoading,
      convertOrderLoading,
    } = this.props;
    const initialValues = {
      storeId: `${detail.data.storeId || 0}`,
      country: `${detail.data?.cusCountry}` || '231',
      countryCode:
        countries?.data?.find(
          (item) => parseInt(item.id) === parseInt(detail.data?.cusCountry)
        )?.countryIsoCode || 'US',
      shippingService: 9,
      fullName: detail?.data?.cusName || '',
      orderId: detail?.data?.onlineId || '',
      orderNote: detail?.data?.orderNote?.giftMessage,
      zipcode: detail?.data?.cusPostalCode || '',
      state: detail?.data?.cusProvince || '',
      city: detail?.data?.cusCity || '',
      address02: detail?.data?.cusAddress2 || '',
      address01: detail?.data?.cusAddress || '',
      phoneNumber: detail?.data?.cusPhone || '',
      email: detail?.data?.cusEmail || '',
      barcodeIds: [],
      barcodeFiles: [],
      listOrderItems: [],
    };
    if (
      detail?.data?.orderItems &&
      Array.isArray(detail?.data?.orderItems) &&
      detail?.data?.orderItems.length > 0
    ) {
      initialValues['listOrderItems'] = detail?.data?.orderItems.map(
        (orderItem, orderItemIndex) => {
          return {
            key: orderItemIndex,
            productId: orderItem?.expId || '0',
            colorId: orderItem?.color ? `${orderItem?.color}` : '0',
            sizeId: orderItem?.size ? `${orderItem?.size}` : '0',
            quantity: isNaN(parseInt(orderItem?.quantity))
              ? parseInt(orderItem?.quantity)
              : '0',
            price: orderItem?.price || 0,
            designFront: orderItem?.frontDesignUrl || '',
            designBack: orderItem?.backDesignUrl || '',
            mockup: orderItem?.mainUrl || '',
            designFrontId: '',
            designBackId: '',
            barcodeId: '',
            // barcode: orderItem?.linkBarcode || '',
            barcode: '',
            basePrice: orderItem?.basePrice || '',
            colorHex: orderItem?.hexColor ? `#${orderItem?.hexColor}` : '',
            productType: orderItem?.exProduct?.type || 0,
            productName: orderItem?.exProduct?.brand || '',
            productDisplayName: orderItem?.exProduct?.brand || '',
            sidePrint: orderItem?.printSide2 || 'normal',
            printLocation: orderItem?.exProduct?.printLocation,
            frontDesignFilePath: orderItem?.designFilePath,
            frontDesignFileName: orderItem?.frontDesignFileName,
            backDesignFileName: orderItem?.backDesignFileName,
            backDesignFilePath: orderItem?.backDesignFilePath,
            exProduct: orderItem.exProduct,
          };
        }
      );
    } else {
      initialValues['listOrderItems'].push({
        key: 0,
        productId: '0',
        colorId: '0',
        sizeId: '0',
        quantity: '0',
        price: 0,
        designFront: '',
        designBack: '',
        mockup: '',
        designFrontId: '',
        designBackId: '',
        barcode: '',
        barcodeId: '',
        sidePrint: 'normal',
      });
    }
    return (
      <div>
        <Wrapper pageName={pageName}>
          <Spin
            spinning={
              convertOrderLoading ||
              createOrderLoading ||
              countries.loading ||
              exproducts.loading ||
              detail.loading ||
              stores.loading
            }
          >
            <FormCreateOrderLabel
              profile={profile}
              stores={stores.data}
              exproducts={exproducts.data}
              countries={countries.data}
              onSubmit={this.handleCheckVariantRisk}
              initialValues={initialValues}
            />
          </Spin>
        </Wrapper>
        <Modal
          centered
          title="Order confirmation required"
          visible={showModalVariant}
          onCancel={() => {
            this.handleCloseModal();
          }}
          onOk={() => {
            this.handleSubmit();
          }}
          width={800}
          footer={null}
        >
          <Spin spinning={listVariantRiskLoading}>
            <div className="mb-2">
              Your order includes the following{' '}
              <a
                href="https://help.gearment.com/en/article/order-with-variation-at-risk-8wumdy/"
                target="blank"
              >
                variant(s) at risk
              </a>
              :
            </div>
            {listVariantRisk?.data?.map((i) => {
              return (
                <p>
                  <ul>
                    <li>
                      {i?.exp_brand} - {i?.color_name} - {i?.size_name}
                    </li>
                  </ul>
                </p>
              );
            })}
            <div>
              By continuing, you confirm to create the order with the variant(s)
              at risk and acknowledge that the processing time may be extended
              for a few business days.
            </div>
          </Spin>
          <div className="d-flex mt-3 mb-3 pb-3 align-items-right justify-content-end">
            <GMButton
              color="SecondaryGray"
              type="button"
              onClick={() => {
                this.handleCloseModal();
              }}
              style={{ width: 170 }}
            >
              Cancel
            </GMButton>
            <GMButton
              style={{ width: 170 }}
              onClick={() => {
                this.handleSubmit();
              }}
              className="ml-3"
            >
              Confirm order
            </GMButton>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    createOrderLoading: state.order.CreateLableOrder.createOrder.loading,
    convertOrderLoading: state.order.convertOrderLabel.convertOrder.loading,
    countries: state.user.profile.listCountry,
    stores: state.store.list.stores2,
    exproducts: state.mockup.create.exproducts,
    profile: state.auth.info.profile,
    detail: state.order.convertOrderLabel.order,
    listVariantRiskLoading: state.order.createOrder.listVariantRisk.loading,
    listVariantRisk: state.order.createOrder.listVariantRisk,
    showModalVariant: state.order.createOrder.showModalVariant,
    FormCreateOrderLabel: state.form.FormCreateOrderLabel?.values,
  }),
  (dispatch) => ({
    convertOrder: (data) => {
      dispatch(convertOrder(data));
    },
    listCountry: () => {
      dispatch(listCountry());
    },
    getListExproduct: (params) => {
      dispatch(getListExproduct(params));
    },
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
    getListStore: (options) => {
      dispatch(getListStore(options));
    },
    checkVariantRiskAction: (data) => {
      dispatch(checkVariantRiskAction(data));
    },
    closeVariantRiskAction: () => {
      dispatch(closeVariantRiskAction());
    },
    resetReducerAction: () => {
      dispatch(resetReducerAction());
    },
  })
)(index);
