import React from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
const renderInputPassword = ({
  input,
  className,
  placeholder,
  size,
  prefix,
  meta: { error, invalid, submitFailed },
}) => {
  return (
    <>
      <Input.Password
        value={input.value}
        size={size || 'large'}
        className={classNames(className)}
        onChange={(e) => input.onChange(e.target.value)}
        placeholder={placeholder}
        prefix={prefix}
      />
      {submitFailed && error && (
        <span className="invalid-feedback">{error}</span>
      )}
    </>
  );
};

export default renderInputPassword;
