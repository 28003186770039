import { notification } from "antd";
import {
  PROMOTIONS_UPDATE_REQUEST,
  PROMOTIONS_UPDATE_FAIL,
  PROMOTIONS_UPDATE_SUCCESS,
  PROMOTIONS_DETAIL_REQUEST,
  PROMOTIONS_DETAIL_FAIL,
  PROMOTIONS_DETAIL_SUCCESS,
} from "./constants";
const initialState = {
  update: {
    loading: false,
  },
  detail: {
    loading: false,
    data: {},
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case PROMOTIONS_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          loading: true,
        },
      };
    case PROMOTIONS_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          data: action.data.promotion,
          loading: false,
        },
      };
    case PROMOTIONS_DETAIL_FAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
        },
      };

    case PROMOTIONS_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      };
    case PROMOTIONS_UPDATE_SUCCESS:
      notification.success({
        message: "Update promotion successfully.",
        duration: 5
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
        },
      };
    case PROMOTIONS_UPDATE_FAIL:
      notification.warn({
        message: action.error && action.error[0] && action.error[0]['msg'] ? action.error[0]['msg'] : 'Update promotion failed.',
        duration: 5
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
