export const actionType = {
  EXPORT_FEED_FORM_GOOGLE: {
    REQUEST: "EXPORT_FEED_FORM_GOOGLE.REQUEST",
    SUCCESS: "EXPORT_FEED_FORM_GOOGLE.SUCCESS",
    FAIL: "EXPORT_FEED_FORM_GOOGLE.FAIL"
  }
};


export const exportFeedFormGoogle = (data) => {
  return {
    type: actionType.EXPORT_FEED_FORM_GOOGLE.REQUEST,
    data
  }
}


