import { BREAKPOINTS } from 'components/const';
import PageContainer from 'components/PageContainer';
import styled from 'styled-components';

export const Wrapper = styled(PageContainer)`
  .cta-section {
    justify-content: right;
    text-align: right;
    button {
      width: 100%;
      max-width: 500px;
      display: inline-block;
    }
  }
  .main-content {
    box-shadow: none;
    border: none;
    background-color: transparent;
    padding: 0;
  }
  .mh110 {
    min-height: auto;
    @media only screen and (min-width: ${BREAKPOINTS['1024']}) {
      min-height: 130px;
    }
    @media only screen and (min-width: ${BREAKPOINTS['xxl']}) {
      min-height: 110px;
    }
  }
`;
