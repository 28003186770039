import React from 'react';
import {Table}from 'antd';

function RenderOptimizedTable(props){
    return (
        <Table
         {...props}
      />
      
    )
}

export default React.memo(RenderOptimizedTable);