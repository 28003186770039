import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import getMomentInstance from 'utils/moment';
import { getDetail } from './actions';
import {
  getOptionLabel,
  options_report_manually_sync_tracking,
} from 'utils/options';
import { Wrapper } from './styled';

class Detail extends Component {
  componentDidMount() {
    this.props.getDetail(this.props.match.params.id);
  }

  render() {
    const { detail, pageName, loadingDetail } = this.props;
    const id = this.props.match.params.id;
    return (
      <Wrapper
        pageName={pageName}
        breadcrumDetails={{
          [id]: id && `Detail #${id || ''}`,
        }}
      >
        <Spin spinning={loadingDetail}>
          <table className="table table-borderless table-sm">
            <tbody>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Store:
                </td>
                <td className="px-4">
                  {detail.storeIntegration && detail.storeIntegration.showName
                    ? detail.storeIntegration.showName
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Status:
                </td>
                <td className="px-4">
                  {getOptionLabel(
                    options_report_manually_sync_tracking,
                    detail.status
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Request time:
                </td>
                <td className="px-4">
                  {detail.requestedAt
                    ? getMomentInstance(null, detail.requestedAt, true)
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Completed Time
                </td>
                <td className="px-4">
                  {detail.completedAt
                    ? getMomentInstance(null, detail.completedAt, true)
                    : 'N/A'}
                </td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Logs
                </td>
                <td className="px-4">
                  {detail?.storeIntegration?.log || 'N/A'}
                </td>
              </tr>
            </tbody>
          </table>
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    // loadingDetail: state.order.reportSyncTracking.reportManualSyncTracking.loading,
    detail: state.order.reportSyncTrackingDetail.reportManualSyncTracking.data,
    loadingDetail:
      state.order.reportSyncTrackingDetail.reportManualSyncTracking.loading,
  }),
  (dispatch) => ({
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
  })
)(Detail);
