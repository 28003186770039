import { BASE_WHITE, GRAY_900 } from 'components/colors';
import styled from 'styled-components';
import { BREAKPOINTS } from 'components/const';

export const Wrapper = styled.div`
  padding: 20px 16px;
  background: ${BASE_WHITE};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 20px;
  @media (min-width: ${BREAKPOINTS.lg}) {
    flex-direction: row;
  }
  .content {
    .title-balance {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: ${GRAY_900};
      padding-left: 0;
    }
    .value-balance {
      margin-top: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: ${GRAY_900};
    }
  }

  svg {
    width: 20px;
    height: 20px;
    stroke: ${BASE_WHITE};
    margin: 0;
  }
`;
