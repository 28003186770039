import {
  BASE_WHITE,
  GRAY_200,
  GRAY_300,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_300,
  PRIMARY_50,
  PRIMARY_500,
  PRIMARY_700,
  SUCCESS_50,
  SUCCESS_500,
} from 'components/colors';
import { BREAKPOINTS } from 'components/const';
import { GMBox } from 'scenes/general.styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 0 32px;
  margin-bottom: 32px;
  flex-direction: column;
  h2.title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    margin-top: 24px;
    color: ${GRAY_900};
    margin-bottom: 24px;
  }
  h3.title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${GRAY_900};
    margin: 0;
    padding: 0;
  }
  .wrapper {
    display: flex;
    overflow: hidden;
  }
  .no-billing-info-warning {
    margin-bottom: 12px;
  }
  .left {
    .integration {
      margin-top: 24px;
      display: block;

      @media only screen and (min-width: ${BREAKPOINTS[992]}) {
        display: none;
      }
    }
  }
  .right {
    .integration {
      display: none;
      @media only screen and (min-width: ${BREAKPOINTS[992]}) {
        display: block;
      }
    }
  }
`;
Wrapper.displayName = 'DashboardWrapper';
export const WrapperNofify = styled.div`
  margin-bottom: 24px;
  .color-list {
    color: #000000;
  }
  .title-account {
    color: ${PRIMARY_500};
    font-weight: 600;

  }
  .desc-account {
    color: #000000;
    line-height: 1.7;
  }
  .main-account {
    font-weight: 600;
  }
`;
export const ShortcutsWrapper = styled(GMBox)`
  .inner-wrapper {
    display: flex;
    margin-top: 20px;
    gap: 24px;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .item {
    border: 1px solid ${GRAY_200};
    border-radius: 12px;
    padding: 20px;
    display: flex;
    gap: 12px;
    cursor: pointer;

    width: 100%;
    @media only screen and (min-width: ${BREAKPOINTS.md}) {
      width: calc(50% - 12px);
    }
    .icon {
      width: 66px;
      height: 66px;
      min-width: 66px;
    }
  }
`;

export const ConnectStoreWrapper = styled(GMBox)`
  .inner-wrapper {
    display: flex;
    gap: 12px;
    margin-top: 20px;
    justify-content: space-around;
    align-content: space-around;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  a {
    display: flex;
    padding: 16px;
    gap: 10px;
    padding: 8px;
    height: 40px;
    border: 1px solid ${GRAY_200};
    border-radius: 8px;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: calc(50% - 6px);
    &.disabled {
      /* pointer-events: none; */
      filter: grayscale(1);
      cursor: default;
      svg {
        opacity: 0.3;
      }
    }
    svg {
      height: 24px;
      max-height: 100%;
    }
  }
  img {
    height: 24px;
    max-height: 100%;
    max-width: 100%;
  }
`;

export const NextStepsWrapper = styled(GMBox)`
  margin-top: 24px;
  p.description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_600};
  }

  .ant-tabs-tab {
    &.ant-tabs-tab {
      border-radius: 0 !important;
      background-color: violet;
      margin: 0;
      background: transparent;
      border-bottom: 2px solid transparent;
      color: ${GRAY_500};
      &-active {
        background: ${PRIMARY_50};
        border-bottom: 2px solid ${PRIMARY_500};
        color: ${PRIMARY_500};
        .ant-tabs-tab-btn {
          font-weight: 600;
        }
      }

      .ant-tabs-tab-btn {
        padding: 12px;
        font-weight: 600;
      }
    }
  }
  .ant-tabs-tabpane {
    display: flex;
    flex-direction: column;
    &-hidden {
      display: none;
    }
  }
  .item {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    gap: 10px;
    margin: 8px 0;
    position: relative;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &::after {
      position: absolute;
      display: block;
      content: ' ';
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${GRAY_200};
    }
    span.title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_900};
    }
    div.description span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
    }
    svg {
      stroke: ${GRAY_500};
      width: 14px;
    }
    &.active {
      background-color: ${SUCCESS_50};
      svg {
        stroke: ${SUCCESS_500};
      }
    }
    &.noclick {
      cursor: default;
    }
    button {
      white-space: nowrap;
      align-items: center;
      gap: 10px;
      display: flex;
      &:disabled svg {
        stroke: ${GRAY_300};
      }
      svg {
        stroke: ${GRAY_700};
        gap: 10px;
        width: 20px;
      }
    }
    .info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .content-wrapper {
      width: 100%;
    }
  }
`;

export const GuidelinesWrapper = styled(GMBox)`
  margin-top: 24px;
  .inner-wrapper {
    margin-top: 20px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    .item {
      display: flex;
      padding: 8px 20px;
      gap: 12px;
      border: 1px solid ${GRAY_200};
      border-radius: 12px;
      align-items: center;

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: ${GRAY_700};
      width: 100%;
      @media only screen and (min-width: ${BREAKPOINTS.sm}) {
        width: calc(50% - 12px);
      }
      @media only screen and (min-width: ${BREAKPOINTS[1800]}) {
        width: calc(33% - 13px);
      }
      &:hover {
        border: 1px solid ${PRIMARY_500};
        cursor: pointer;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const TipsWrapper = styled(GMBox)`
  margin-top: 24px;
  h3.title {
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    display: flex;
    a {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: ${PRIMARY_500};
      white-space: nowrap;
      svg {
        stroke: ${PRIMARY_500};
        margin-left: 12px;
        width: 20px;
      }
    }
  }
  .inner-wrapper {
    gap: 24px;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: auto;
    @media only screen and (min-width: ${BREAKPOINTS.xl}) {
      overflow: hidden;
    }
  }
  a.item {
    display: flex;
    border-radius: 8px;

    overflow: hidden;
    display: flex;
    flex-direction: column;
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      display: flex;
    }
    min-width: 300px;
    @media only screen and (min-width: ${BREAKPOINTS.xl}) {
      width: calc(${100 / 3}% - 12px);
      min-width: auto;
      &:nth-child(3) {
        display: flex;
      }
      &:nth-child(4) {
        display: none;
      }
      &:nth-child(5) {
        display: none;
      }
    }
    @media only screen and (min-width: ${BREAKPOINTS.xxl}) {
      width: calc(20% - 12px);
      &:nth-child(3) {
        display: flex;
      }
      &:nth-child(4) {
        display: flex;
      }
      &:nth-child(5) {
        display: flex;
      }
    }
    /* img {
      max-width: 100%;
    } */
    div.thumbnail {
      height: 136px;
      width: 100%;
      background-size: cover;
      background-position: center;
    }
    div.content {
      display: flex;
      flex-direction: column;
      span {
        display: inline-block;
      }
      .date {
        margin-top: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${PRIMARY_700};
      }
      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: ${GRAY_900};
        margin-top: 8px;
      }
    }
  }
`;

export const TrendingProductWrapper = styled.div`
  margin-top: 24px;
  background: transparent;
  h3.title {
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    display: flex;
    a {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: ${PRIMARY_500};
      svg {
        stroke: ${PRIMARY_500};
        margin-left: 12px;
        width: 20px;
      }
    }
  }
  .inner-wrapper {
    gap: 24px;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow: auto;
  }
  div.item {
    min-width: 190px;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_200};
    border-radius: 16px;

    padding-bottom: 35px;
    position: relative;
    overflow: auto;

    @media only screen and (min-width: ${BREAKPOINTS.lg}) {
      overflow: hidden;
    }

    @media only screen and (min-width: ${BREAKPOINTS.lg}) {
      width: calc(25% - 12px);
      &:nth-child(3) {
        display: block;
      }
      &:nth-child(4) {
        display: block;
      }
      &:nth-child(5) {
        display: none;
      }
      &:nth-child(6) {
        display: none;
      }
    }
    @media only screen and (min-width: ${BREAKPOINTS.xl}) {
      width: calc(${100 / 5}% - 12px);
      &:nth-child(3) {
        display: block;
      }
      &:nth-child(4) {
        display: block;
      }
      &:nth-child(5) {
        display: block;
      }
      &:nth-child(6) {
        display: none;
      }
    }
    @media only screen and (min-width: ${BREAKPOINTS.xxl}) {
      width: calc(${100 / 6}% - 12px);
      &:nth-child(3) {
        display: block;
      }
      &:nth-child(4) {
        display: block;
      }
      &:nth-child(5) {
        display: block;
      }
      &:nth-child(6) {
        display: block;
      }
    }
    img {
      max-width: 100%;
      background-color: ${GRAY_200};
    }
    div.content {
      display: flex;
      flex-direction: column;
      span {
        display: inline-block;
      }
      .date {
        margin-top: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${PRIMARY_700};
      }
      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: ${GRAY_900};
        margin-top: 8px;
      }
    }
  }
  .price {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: ${PRIMARY_500};
    margin-top: 8px;

    position: absolute;
    left: 0;
    width: 100%;
    padding: 0 16px;
    bottom: 16px;
  }
  .content {
    padding: 16px;
    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: ${GRAY_900};
      min-height: 84px;
      overflow: hidden;
    }
    .qdescription {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: ${GRAY_600};
    }
  }
`;
