import { actionType as TYPE } from "./actions";
import { notification } from "antd";
const initialState = {
  stock_status: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  listColor: {
    data: [],
    loading: false,
  },
  listSize: {
    data: [],
    loading: false,
  },
  listProduct: {
    data: [],
    loading: false,
  },
  exportStockStatus: {
    loading: false,
    params: {},
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        stock_status: {
          ...initialState.stock_status,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        stock_status: {
          ...state.stock_status,
          loading: false,
          data: action.data
          ,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        stock_status: {
          ...state.stock_status,
          loading: false,
        },
      }; //List colors
    case TYPE.LIST_COLOR.REQUEST:
      return {
        ...state,
        listColor: {
          ...initialState.listColor,
          loading: true,
        },
      };
    case TYPE.LIST_COLOR.SUCCESS:
      return {
        ...state,
        listColor: {
          ...state.listColor,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.LIST_COLOR.ERROR:
      return {
        ...state,
        listColor: {
          ...state.data,
          loading: false,
        },
      };
    //List size
    case TYPE.LIST_SIZE.REQUEST:
      return {
        ...state,
        listSize: {
          ...initialState.listSize,
          loading: true,
        },
      };
    case TYPE.LIST_SIZE.SUCCESS:
      return {
        ...state,
        listSize: {
          ...state.listSize,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.LIST_SIZE.ERROR:
      return {
        ...state,
        listSize: {
          ...state.listSize,
          loading: false,
        },
      };
    //Líst product
    case TYPE.LIST_PRODUCT.REQUEST:
      return {
        ...state,
        listProduct: {
          ...initialState.listProduct,
          loading: true,
        },
      };
    case TYPE.LIST_PRODUCT.SUCCESS:
      return {
        ...state,
        listProduct: {
          ...state.listProduct,
          loading: false,
          data: action.data.exProducts,
        },
      };
    case TYPE.LIST_PRODUCT.ERROR:
      return {
        ...state,
        listProduct: {
          ...state.listProduct,
          loading: false,
        },
      };
    //Export stock status
    case TYPE.EXPORT_STOCK_STATUS.REQUEST:
      return {
        ...state,
        exportStockStatus: {
          ...state.exportStockStatus,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.EXPORT_STOCK_STATUS.SUCCESS:
      notification.success({
        message: "Export stock status successfully.",
        duration: 5,
      });
      if (action.data && action.data.url) {
        window.open(action.data.url);
      }
      return {
        ...state,
        exportStockStatus: {
          ...state.exportStockStatus,
          loading: false,
        },
      };
    case TYPE.EXPORT_STOCK_STATUS.ERROR:
      notification.warn({
        message:
          action.error?.[0]?.["msg"]
            || "Export stock status failed.",
        duration: 5,
      });
      return {
        ...state,
        exportStockStatus: {
          ...state.exportStockStatus,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
