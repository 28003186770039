import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Result, Spin } from 'antd';
import queryString from 'query-string';
import { updateConfigBuyTracking, detailConfigBuyTracking } from './actions';
import { Wrapper } from './styled';
import ConfigBuyTracking from './component';
import Forbidden from 'scenes/Errors/scenes/Forbidden';
class List extends Component {
  constructor(props) {
    super(props);
    const { createdAtFrom, createdAtTo, ...query_params } = queryString.parse(
      window.location.search
    );
  }
  // componentDidUpdate(prevProps) {
  //   if (prevProps?.data === this.props.data) {
  //     this.props.detailConfigBuyTracking();
  //   }
  // }
  componentDidMount() {
    this.props.detailConfigBuyTracking();
  }

  handleSubmit = (values) => {
    this.props.updateConfigBuyTracking(values);
  };

  render() {
    const {
      loadingDetail,
      loadingUpdate,
      pageName,
      data,
      profile,
    } = this.props;
    const initialValues = {
      type: data?.label?.label_type || 0,
      time: data?.label?.label_value === '0' ? '2' : data?.label?.label_value,
    };
    return (
      <Wrapper pageName={pageName}>
        {profile?.configBuyTracking === false ? (
          <Forbidden />
        ) : (
          <Spin spinning={loadingDetail || loadingUpdate}>
            <ConfigBuyTracking
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              data={data}
            />
          </Spin>
        )}
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    data: state.config_buy_tracking.detailConfigBuyTracking.data,
    loadingDetail: state.config_buy_tracking.detailConfigBuyTracking.loading,
    loadingUpdate: state.config_buy_tracking.updateConfigBuyTracking.loading,
    profile: state.auth.info.profile,
  }),
  (dispatch) => ({
    detailConfigBuyTracking: () => {
      dispatch(detailConfigBuyTracking());
    },
    updateConfigBuyTracking: (data) => {
      dispatch(updateConfigBuyTracking(data));
    },
  })
)(List);
