import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';
import { getList, getSyncProduct } from 'apis/schedule_tracking';
import { getList as getListSyncImportOrder } from 'apis/import-backgrounds';
import { getList as getListSyncOrder } from 'apis/schedule_tasks';
import { getListExportFeed } from 'apis/campaign';
import { listLogExportOrder, webhookLogs, orderLogs } from 'apis/logs';

function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getList, {
      // 'with[0]': 'scheduleCampaigns',
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}

function* getListLogImportOrderSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListSyncImportOrder, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_SYNC_IMPORT_ORDER.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_SYNC_IMPORT_ORDER.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_SYNC_IMPORT_ORDER.ERROR, error })]);
  }
}
//Get list logs export order
function* getListLogExportOrderSaga(action) {
  const { params } = action;
  try {
    const response = yield call(listLogExportOrder, { ...params });
    if (response.code === 'success') {
      yield put({
        type: TYPE.LIST_LOGS_EXPORT_ORDER.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({ type: TYPE.LIST_LOGS_EXPORT_ORDER.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_LOGS_EXPORT_ORDER.ERROR, error })]);
  }
}
//
//Get list logs webhook
function* getListLogWebhookSaga(action) {
  const { id } = action;
  try {
    const response = yield call(webhookLogs, id);
    if (response.code === 'success') {
      yield put({
        type: TYPE.LIST_LOGS_WEBHOOK.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({ type: TYPE.LIST_LOGS_WEBHOOK.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_LOGS_WEBHOOK.ERROR, error })]);
  }
}
//
function* getListSyncOrderSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListSyncOrder, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_SYNC_ORDER.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_SYNC_ORDER.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_SYNC_ORDER.ERROR, error })]);
  }
}

function* getListSyncProductSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getSyncProduct, params);
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_LOG_SYNC_PRODUCT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_LOG_SYNC_PRODUCT.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_LOG_SYNC_PRODUCT.ERROR, error })]);
  }
}
function* getLogFeedProductSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListExportFeed, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.LOG_FEED_PRODUCT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LOG_FEED_PRODUCT.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LOG_FEED_PRODUCT.ERROR, error })]);
  }
}

//Get list logs order
function* getListLogOrderSaga(action) {
  const { id } = action;
  try {
    const response = yield call(orderLogs, id);
    if (response.code === 'success') {
      yield put({
        type: TYPE.LIST_LOGS_ORDER.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({ type: TYPE.LIST_LOGS_ORDER.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_LOGS_ORDER.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga);
  yield takeLatest(
    TYPE.LIST_SYNC_IMPORT_ORDER.REQUEST,
    getListLogImportOrderSaga
  );
  yield takeLatest(
    TYPE.LIST_LOGS_EXPORT_ORDER.REQUEST,
    getListLogExportOrderSaga
  );
  yield takeLatest(TYPE.LIST_LOGS_WEBHOOK.REQUEST, getListLogWebhookSaga);
  yield takeLatest(TYPE.LIST_SYNC_ORDER.REQUEST, getListSyncOrderSaga);
  yield takeLatest(TYPE.LIST_LOG_SYNC_PRODUCT.REQUEST, getListSyncProductSaga);
  yield takeLatest(TYPE.LOG_FEED_PRODUCT.REQUEST, getLogFeedProductSaga);
  yield takeLatest(TYPE.LIST_LOGS_ORDER.REQUEST, getListLogOrderSaga);
}

export default watcher;
