import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ModalTopUp from 'components/ModalTopUp';
import { ShortcutsWrapper, WrapperNofify } from 'scenes/Dashboard/styled';
import { ReactComponent as CreateProductIcon } from 'images/icons/t-shirt.svg';
import { ReactComponent as CreditCardIcon } from 'images/icons/credit-card-plus.svg';
import { ReactComponent as PackageIcon } from 'images/icons/package-plus.svg';
import { ReactComponent as PhoneCallIcon } from 'images/icons/phone-call-01.svg';
import {
  GRAY_100,
  GRAY_50,
  GRAY_600,
  PRIMARY_100,
  PRIMARY_50,
  PRIMARY_600,
  SUCCESS_100,
  SUCCESS_50,
  SUCCESS_600,
  WARNING_100,
  WARNING_50,
  WARNING_600,
} from 'components/colors';
import { GlowingSVG } from 'components/SVG';
import { getUserStepsRequestAction } from 'scenes/Dashboard/actions';
import { useDispatch, useSelector } from 'react-redux';
import GMNotify from 'components/Notification';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

function Index(props) {
  const [shortcuts, setShortcuts] = useState([]);
  const [checkAccount, setCheckAccount] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const listAction = useSelector(
    (s) => s.dashboard.userSteps.status?.accountInformation
  );

  const AccountCheckLoading = useSelector((s) => s.dashboard.userSteps.loading);
  const profile = useSelector((state) => state.auth.info.profile);

  useEffect(() => {
    setShortcuts([
      {
        icon: (
          <GlowingSVG
            className="icon"
            backgroundColor={PRIMARY_100}
            borderColor={PRIMARY_50}
            svgStrokeColor={PRIMARY_600}
          >
            <CreateProductIcon />
          </GlowingSVG>
        ),
        name: 'Create products',
        description:
          'Create product templates for quickly adding products to your store',
        url: '/products/create',
        isInternalUrl: true,
      },
      {
        icon: (
          <GlowingSVG
            className="icon"
            backgroundColor={WARNING_100}
            borderColor={WARNING_50}
            svgStrokeColor={WARNING_600}
          >
            <CreditCardIcon />
          </GlowingSVG>
        ),
        name: 'Top up',
        description:
          'Top up G-wallet balance so that you can experience a smoother checkout process',
        url: '/',
        isInternalUrl: true,
        specialActions: () => setIsOpenModal(true),
      },
      {
        icon: (
          <GlowingSVG
            className="icon"
            backgroundColor={SUCCESS_100}
            borderColor={SUCCESS_50}
            svgStrokeColor={SUCCESS_600}
          >
            <PackageIcon />
          </GlowingSVG>
        ),
        name: 'New order',
        description:
          'You can create for your customer & follow the progress of your order',
        url: '/orders/create',
        isInternalUrl: true,
      },
      {
        icon: (
          <GlowingSVG
            className="icon"
            backgroundColor={GRAY_100}
            borderColor={GRAY_50}
            svgStrokeColor={GRAY_600}
          >
            <PhoneCallIcon />
          </GlowingSVG>
        ),
        name: 'Help center',
        description:
          'You’ll find answer to many question in out FAQs section and video tutorial',
        url: '/help',
        isInternalUrl: true,
      },
    ]);
    return () => {};
  }, []);
  useEffect(() => {
    if (listAction) {
      setCheckAccount([
        {
          name: 'Return Address',
          description:
            'Orders that fail to deliver will be returned to this address.',
          url: '/billing/return-address',
          show: listAction?.returnAddress,
        },
        // {
        //   name: 'Legal information',
        //   description:
        //     'The information used when issuing invoices for paid orders.',
        //   url: '/billing/legal-info',
        //   show: listAction?.legalInformation,
        // },
        // {
        //   name: 'Indemnification Agreement',
        //   description:
        //     "Terms and conditions under which you agree to indemnify and hold harmless Gearment Inc. against any claims related to copyright and intellectual property rights arising from the use of your designs, artworks, or any other intellectual property in connection with Gearment Inc.'s print-on-demand services.",
        //   url: '/billing/indemnification',
        //   show: listAction?.indemnificationDocument,
        // },
        {
          name: 'Primary Billing Method',
          description:
            'The method that will be used to charge for your future transactions.',
          url: '/billing/method',
          show: listAction?.primaryBillingMethod,
        },
      ]);
    }
  }, [listAction]);


  const keys = checkAccount?.filter((i) => i.show === false);
  return (
    <>
      {keys?.length > 0 && profile?.primaryId === 0 && (
        <WrapperNofify>
          <Spin spinning={AccountCheckLoading}>
            <GMNotify
              type="warning"
              title={
                <div className="mb-2">
                  <div className="main-account mb-2">
                    Important: Update necessary information before placing an
                    order
                  </div>
                  <div className="desc-account">
                    To ensure a smoother ordering process, it is essential that
                    you update the following information:
                  </div>
                </div>
              }
              desc={
                <div>
                  {checkAccount.map(
                    (item, index) =>
                      item.show === false && (
                        <div>
                          <div>
                            <li className="mb-0 color-list">
                              <Link to={item.url} className="title-account">
                                {item.name}
                              </Link>
                              :
                              <span className="desc-account">
                                {' '}
                                &nbsp;{item.description}
                              </span>
                            </li>
                          </div>
                        </div>
                      )
                  )}
                </div>
              }
            />
          </Spin>
        </WrapperNofify>
      )}

      <ShortcutsWrapper>
        <h3 className="title">Your go-to shortcuts</h3>
        <div className="inner-wrapper">
          {shortcuts.map((shortcut, index) => (
            <div
              className="item"
              onClick={() => {
                if (typeof shortcut?.specialActions === 'function') {
                  shortcut.specialActions();
                  return;
                }
                if (shortcut?.isInternalUrl) {
                  props.history.push(shortcut?.url);
                } else {
                  window.open(shortcut?.url, '_blank');
                }
              }}
              key={'shortcuts_' + index}
            >
              {shortcut.icon}
              <div className="description">
                <h5 className="mt-2">{shortcut.name}</h5>
                <p className="mb-0">{shortcut.description}</p>
              </div>
            </div>
          ))}
        </div>
      </ShortcutsWrapper>
      <ModalTopUp
        isOpenModal={isOpenModal}
        closeModal={() => setIsOpenModal(false)}
      />
    </>
  );
}
export default React.memo(withRouter(Index));
