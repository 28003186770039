export const actionType = {
  LIST: {
    REQUEST: 'ORDER.LIST.LIST.REQUEST',
    SUCCESS: 'ORDER.LIST.LIST.SUCCESS',
    ERROR: 'ORDER.LIST.LIST.ERROR',
    UPDATE_ITEM: 'ORDER.LIST.UPDATE_ITEM',
  },
  EXPORT: {
    REQUEST: 'ORDER.EXPORT.REQUEST',
    SUCCESS: 'ORDER.EXPORT.SUCCESS',
    ERROR: 'ORDER.EXPORT.ERROR',
  },
  GET_PROFIT: {
    REQUEST: 'ORDER.LIST.GET_PROFIT.REQUEST',
    SUCCESS: 'ORDER.LIST.GET_PROFIT.SUCCESS',
    ERROR: 'ORDER.LIST.GET_PROFIT.ERROR',
  },
  ARCHIVE_ORDER: {
    REQUEST: 'ORDER.ARCHIVE_ORDER.REQUEST',
    SUCCESS: 'ORDER.ARCHIVE_ORDER.SUCCESS',
    ERROR: 'ORDER.ARCHIVE_ORDER.ERROR',
  },
  APPROVE_ORDER: {
    REQUEST: 'ORDER.APPROVE_ORDER.REQUEST',
    SUCCESS: 'ORDER.APPROVE_ORDER.SUCCESS',
    ERROR: 'ORDER.APPROVE_ORDER.ERROR',
  },
  UNAPPROVE_ORDER: {
    REQUEST: 'ORDER.UNAPPROVE_ORDER.REQUEST',
    SUCCESS: 'ORDER.UNAPPROVE_ORDER.SUCCESS',
    ERROR: 'ORDER.UNAPPROVE_ORDER.ERROR',
  },
  VERIFY_ADDRESS: {
    REQUEST: 'ORDER.VERIFY_ADDRESS.REQUEST',
    SUCCESS: 'ORDER.VERIFY_ADDRESS.SUCCESS',
    ERROR: 'ORDER.VERIFY_ADDRESS.ERROR',
  },
  CREATE_ISSUE: {
    REQUEST: 'ORDER.CREATE_ISSUE.REQUEST',
    SUCCESS: 'ORDER.CREATE_ISSUE.SUCCESS',
    ERROR: 'ORDER.CREATE_ISSUE.ERROR',
  },
  SYNC_TRACKING: {
    REQUEST: 'ORDER.SYNC_TRACKING.REQUEST',
    SUCCESS: 'ORDER.SYNC_TRACKING.SUCCESS',
    ERROR: 'ORDER.SYNC_TRACKING.ERROR',
  },
  SYNC_TRACKING_AMAZON: {
    REQUEST: 'ORDER.SYNC_TRACKING_AMAZON.REQUEST',
    SUCCESS: 'ORDER.SYNC_TRACKING_AMAZON.SUCCESS',
    ERROR: 'ORDER.SYNC_TRACKING_AMAZON.ERROR',
  },
  FORCE_VERIFIED_DELIVERY: {
    REQUEST: 'ORDER.FORCE_VERIFIED_DELIVERY.REQUEST',
    SUCCESS: 'ORDER.FORCE_VERIFIED_DELIVERY.SUCCESS',
    ERROR: 'ORDER.FORCE_VERIFIED_DELIVERY.ERROR',
  },
  PAYMENT_ORDER: {
    REQUEST: 'ORDER.PAYMENT_ORDER.REQUEST',
    SUCCESS: 'ORDER.PAYMENT_ORDER.SUCCESS',
    ERROR: 'ORDER.PAYMENT_ORDER.ERROR',
  },
  COUNT_ORDER: {
    REQUEST: 'ORDER.COUNT.REQUEST',
    SUCCESS: 'ORDER.COUNT.SUCCESS',
    ERROR: 'ORDER.COUNT.FAIL',
  },
  PAYMENT_ORDER_LIST: {
    REQUEST: 'ORDER.PAYMENT_ORDER_LIST.REQUEST',
    SUCCESS: 'ORDER.PAYMENT_ORDER_LIST.SUCCESS',
    ERROR: 'ORDER.PAYMENT_ORDER_LIST.FAIL',
  },
  CLOSE_MODAL_WARNING_PAYMENT: 'CLOSE_MODAL_WARNING_PAYMENT',
  OPEN_MODAL_WARNING_PAYMENT: 'OPEN_MODAL_WARNING_PAYMENT',
};

export function getList(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}

export function setPaymentError(params) {
  return {
    type: actionType.PAYMENT_ORDER_LIST.ERROR,
    ...params,
  };
}

export function updateDetailItem(detail, id) {
  return {
    type: actionType.LIST.UPDATE_ITEM,
    payload: { detail, id },
  };
}
export function getProfit() {
  return {
    type: actionType.GET_PROFIT.REQUEST,
  };
}
export function archiveOrder(data, params) {
  return {
    type: actionType.ARCHIVE_ORDER.REQUEST,
    data,
    params,
  };
}
export function approveOrder(data, params) {
  return {
    type: actionType.APPROVE_ORDER.REQUEST,
    data,
    params,
  };
}

export function unapproveOrder(data, params) {
  return {
    type: actionType.UNAPPROVE_ORDER.REQUEST,
    data,
    params,
  };
}

export function paymentOrder(data, params) {
  return {
    type: actionType.PAYMENT_ORDER.REQUEST,
    data,
    params,
  };
}

export function getPaymentOrderListAction(payload) {
  return {
    type: actionType.PAYMENT_ORDER_LIST.REQUEST,
    payload,
  };
}

export function syncTracking(data, params) {
  return {
    type: actionType.SYNC_TRACKING.REQUEST,
    data,
    params,
  };
}
export function syncTrackingAmazon(data, params) {
  return {
    type: actionType.SYNC_TRACKING_AMAZON.REQUEST,
    data,
    params,
  };
}

export function verifyAddress(data, params) {
  return {
    type: actionType.VERIFY_ADDRESS.REQUEST,
    data,
    params,
  };
}

export function createIssue(data) {
  return {
    type: actionType.CREATE_ISSUE.REQUEST,
    data,
  };
}

export function foreceVerifiedDelivery(data, params) {
  return {
    type: actionType.FORCE_VERIFIED_DELIVERY.REQUEST,
    data,
    params,
  };
}

export function countOrder(params) {
  return {
    type: actionType.COUNT_ORDER.REQUEST,
    params,
  };
}

export function exportOrders(params) {
  return {
    type: actionType.EXPORT.REQUEST,
    params,
  };
}

export function setPaymentOrderData(payload) {
  return {
    type: actionType.PAYMENT_ORDER_LIST.SUCCESS,
    payload,
  };
}

export function closeModalWarningPaymentAction() {
  return {
    type: actionType.CLOSE_MODAL_WARNING_PAYMENT,
  };
}

export function openModalWarningPaymentAction() {
  return {
    type: actionType.OPEN_MODAL_WARNING_PAYMENT,
  };
}
