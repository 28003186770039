import React, { Component, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { GMButton } from 'components/Button';
import axios from 'axios';
import { Base64 } from 'js-base64';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { Wrapper } from './styled';

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
const Signature = (props) => {
  const dispatch = useDispatch();
  const { showError = false, message, input = '', meta, enableForm } = props;
  const { error, touched, submitFailed } = meta;
  let sigPad = {};
  useEffect(() => {
    sigPad.clear();
    sigPad.fromDataURL(null);
    if (input.value.includes('https')) {
      axios
        .get(input.value, { responseType: 'arraybuffer' })
        .then(function (response) {
          const base64 = Base64.fromUint8Array(new Uint8Array(response.data));
          let dataUrl = 'data:image/png;base64,' + base64;
          sigPad.fromDataURL(dataUrl);
        })
        .catch(function (err) {
          console.log(err);
        })
        .finally(function () {
          // always executed
        });
    } else {
      sigPad.fromDataURL(input.value);
      sigPad.getCanvas();
    }
  }, [sigPad, input.value]);

  const clear = () => {
    sigPad.clear();
    sigPad.fromDataURL(null);
    dispatch(change('FormIndemnification', 'signatureUrl', ''));
  };

  const onEnd = (value) => {
    const imgData = sigPad.getCanvas().toDataURL('image/png');
    input.onChange(imgData);
  };

  return (
    <Wrapper>
      <div className="d-flex columns">
        {' '}
        <SignatureCanvas
          id="myCanvas"
          ref={(ref) => {
            sigPad = ref;
          }}
          onEnd={onEnd}
          canvasProps={{
            style: { pointerEvents: enableForm ? 'none' : 'auto' },
          }}
        />
      </div>
      <div>
        {touched && submitFailed && showError && error ? (
          <span className="error-msg">{error}</span>
        ) : message ? (
          <span className="message">{message}</span>
        ) : null}
      </div>
      {!enableForm && <div className="tip">Tip: Draw your signature</div>}
      {!enableForm && (
        <div className="pt-2">
          <GMButton
            type="button"
            onClick={clear}
            color={'SecondaryGray'}
            // disabled={enableForm}
          >
            Clear
          </GMButton>
        </div>
      )}
    </Wrapper>
  );
};

export default Signature;
