import React from 'react';
import { Wrapper } from './styled';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter, getSelectedMenuKeys } from 'utils/helper';
import { ReactComponent as ChevronRightIcon } from 'images/icons/chevron-right.svg';
import menu from 'route/menu';
import _ from 'lodash';
import { Link } from 'react-router-dom';

export default ({ menuObj, virtualPaths, details = {} }) => {
  const lastItem = _.get(menuObj, _.last(virtualPaths));
  const keyList = Object.keys(details);
  return (
    <Wrapper>
      <ul>
        <li>
          <Link to="/">
            <span>Home</span>
            <ChevronRightIcon />
          </Link>
          {
            virtualPaths.map((pI, inx) => {
              const obj = _.get(menuObj, pI);
              const _url = (obj?.target || obj?.path)?.replace(/^\//, '');
              const link = obj?.key?.replace(/\./gi, '/') || '';
              const lastIndentItem = _.last(link.split("/"))
              const lastLink = lastItem?.key?.replace(/\./gi, '/');
              let target = obj?.target || obj?.path || link;
              let dspName = obj?.name;
              if (keyList.length > 0) {
                if (lastIndentItem === ":id") {
                  const replace2Id = keyList.shift();
                  dspName = details[replace2Id] || dspName;
                  target = target.replace(':id', replace2Id);
                }
              }
              if (link === lastLink) {
                return (
                  <li className="last" key={'breadcrumb_item_' + inx}>
                    <span>{dspName}</span>
                  </li>
                );
              }
              return (
                <li key={'breadcrumb_item_' + inx}>
                  <Link to={target}>
                    <span>{dspName}</span>
                  </Link>
                  <ChevronRightIcon />
                </li>
              );

              // ids.forEach((id) => {
              //   url = url.replace(':id', id);
              //   names.push(details?.[parseInt(id)]);
              // });
              // <li key={'breadcrumb_item_' + inx}>
              //     <span>{lastName || obj?.name}</span>
              //     <ChevronRightIcon />
              //   </li>
            })
          }
        </li>
      </ul>
    </Wrapper>
  );
};
