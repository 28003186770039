import { GMButton } from 'components/Button';
import { ERROR_700, GRAY_400, PRIMARY_500 } from 'components/colors';
import { BREAKPOINTS } from 'components/const';
import styled from 'styled-components';

export const Button = styled(GMButton)``;
export const Wrapper = styled.div`
  form {
    max-width: 100%;
  }
  .font-login .size-input .login-button {
    max-width: 100%;
  }
  .input-field {
    max-width: 100%;
    max-width: 100%;
    &:placeholder {
      color: ${GRAY_400};
    }
  }
  .font-login .size-input .login-button,
  .input-field {
    width: 480px;
    max-width: 100%;
  }
  /* .font-login .size-input .login-button,
  .input-field {
    width: auto;
    @media only screen and (min-width: ${BREAKPOINTS.sm}) {
      width: 480px;
    }
  } */
  .noti-margin {
    line-height: 20px;
    max-width: 100%;
    width: 480px;

    .email {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-top: 16px;
      max-width: 100%;
      cursor: pointer;

      /* identical to box height, or 143% */

      /* Primary/500 */

      color: #169dd8;
    }
  }
  .right-authentication-section {
    display: none;
    @media only screen and (min-width: ${BREAKPOINTS[992]}) {
      display: block;
    }
  }
  .form-section {
    padding-top: 32px;
    .input-field {
      width: auto;
    }
    @media only screen and (min-width: ${BREAKPOINTS[992]}) {
      padding-top: 0;
    }
  }
  .social-login-button {
    & > div {
      width: 48%;
      @media only screen and (min-width: ${BREAKPOINTS.sm}) {
        width: auto;
      }
    }
    .button-Social {
      max-width: 100%;
      width: 234px;
    }
  }
  .term-privacy {
    position: absolute;
    bottom: 55px;
    left: 0;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #475467;
    a {
      color: ${PRIMARY_500};
    }
  }
`;

export const SocialLoginButton = styled.button`
  &[disabled] {
    opacity: 0.6;
  }
`;

Wrapper.displayName = 'LoginWrapper';
