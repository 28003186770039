import styled from "styled-components";
import PageContainer from "components/PageContainer";
import { BASE_WHITE, ERROR_500, GRAY_200, GRAY_300, GRAY_400, GRAY_50, GRAY_500, GRAY_700, GRAY_900, GREEN_500, PRIMARY_100, PRIMARY_300, SHADOW_XS } from "components/colors";

export const Wrapper = styled(PageContainer)`
  .file-type-section .file-type-item {
    border-radius: 8px;
  }
  button.altbtn {
    min-width: 185px;
  }
  textarea,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-affix-wrapper {
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    outline: none;
    width: 100%;

    overflow: hidden;
    
    color: ${GRAY_900};
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.ant-input {
      border-radius: 8px!important;
    }
    &:placeholder  {
      color: ${GRAY_400};
    }
    &:focus-visible,
    &:focus {
      outline: none;
      border: 1px solid ${PRIMARY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    }
    &:disabled {
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: ${GRAY_700};
    }
  }
  button {
    display: flex;
    align-items: center;
    white-space: nowrap;
    svg {
      width: 20px;
      margin-right: 8px;
    }
  }
  .add-form {
    display: flex;
    align-items: center;
    gap: 12px;
    .position-relative {
      width: 100%;
    }
  }

  .inner-wrapper {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
  }
  .ant-upload.ant-upload-drag {
    background-color: transparent;
    border: solid 1px ${GRAY_200};
    border-radius: 8px;
  }
  .importOrderFile .d-flex > span {
    width: 100%;
    .ant-upload-btn {
      padding: 24px;
      display: flex;
      row-gap: 12px;
      justify-content: center;
      align-items: center;
    }
    .upload-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
  }
  .input-grp {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    .position-relative {
      width: 100%;
    }
  }
`;

export const DropdownItemWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px;
  img {
    height: 24px;
    margin-right: 12px;
  }
  .ant-select.ant-select-lg .ant-select-selector & {
    display: inline;
  }
`;

export const FileProgress = styled.div`
  padding: 16px;
  border: 1px solid ${GRAY_200};
  border-radius: 4px;
  display: flex;
  position: relative;
  width: 100%;
  gap: 16px;
  svg.remove {
    stroke: ${GRAY_500};
    cursor: pointer;

    position: absolute;
    right: 16px;
    top: 16px;

    width: 20px;
    height: 20px;
    &:hover {
      stroke: ${ERROR_500};
    }
  }
  .icon {
    .glowing-svg-file {
      padding: 8px;
      border-width: 4px;
      margin-top: -4px;
      margin-left: -4px;
      box-sizing: content-box;
      width: 16px;
      height: 16px;
      line-height: 1;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .info {
    color: ${GRAY_700};
    width: 100%;
    .filename {
      font-style: 14px;
      font-weight: 500;
    }
    .filesize {
      font-size: 14px;
      font-weight: 400;
    }
    .progress {
      gap: 12px;
      background-color: transparent;
      display: flex;
      align-items: center;
      flex-direction: row;
      &-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: ${GREEN_500};
      }
      &-barwrapper {
        font-size: 0;
        height: 4px;
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
        background-color: ${GRAY_200};
      }
      &-bar {
        content: " ";
        display: block;
        width: 0%;
        height: 4px;

        background-color: ${GREEN_500};
      }
    }
  }
`;
