import { GMBadge } from 'components/Badge';
import { GRAY_600, GRAY_700 } from 'components/colors';
import React from 'react';
import { Link } from 'react-router-dom';
import { toFixed } from 'utils/helper';
import getMomentInstance, { getMomentInstance2 } from 'utils/moment';
import money from 'utils/money';
import {
  getOptionLabel,
  import_progress_status,
  PAYMENT_METHOD_LIST,
  PAYMENT_TYPE,
  PAYMENT_TYPE_BADGE_COLOR,
  TRANSACTION_STATUS_LIST
} from 'utils/options';

export default () => ([
  {
    title: 'ID',
    align: "left",
    dataIndex: "name",
    key: "name",
    render: (text, row) => {
      return text ? (
        <Link to={`/billing/transactions/${row?.id}/detail`}>
          {text}
        </Link>
      ) : (
        "N/A"
      );
    }
  },
  {
    title: 'Payment method',
    align: "center",
    dataIndex: "paymentMethod",
    key: "paymentMethod",
    render: (text) => {
      const k = text?.toLowerCase()?.trim() === 'wallet' ? 'G-Wallet' : text;
      return PAYMENT_METHOD_LIST[parseInt(text)]?.logoComponent ||
        Object.values(PAYMENT_METHOD_LIST).find(m => m.title?.toLowerCase()?.trim() === k?.toLowerCase()?.trim())?.logoComponent ||
        '--';
    }
  },
  {
    title: 'Type',
    align: "center",
    dataIndex: "type",
    key: "type",
    render: (text) => {
      return <GMBadge {...PAYMENT_TYPE_BADGE_COLOR[text.toLowerCase()] || PAYMENT_TYPE_BADGE_COLOR.default}>
        {text}
      </GMBadge>
    }
  },
  {
    title: 'Amount',
    align: "right",
    dataIndex: "amount",
    key: "amount",
    render: (text, record) => {
      return <b>{money(text)}</b>;
    }
  },
  {
    title: 'Created date',
    align: "center",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text) => {
      return getMomentInstance2({ unixDateTime: text, isDateTimFormat: true });
    }
  },
  {
    title: 'Status',
    align: "center",
    dataIndex: "status",
    key: "status",
    render: (text) => {
      return getOptionLabel(TRANSACTION_STATUS_LIST, text);
    }
  },
]);
