import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Spin, Collapse, notification } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import BackToIcon from "images/chevron-down.svg";
import { listCampaignToPreview } from "./actions";
import { submit } from "redux-form";
import { RenderPreviewCampaign2 } from "utils/Form/RenderPreviewCampaign2";
const { Panel } = Collapse;
const listCampaignIdToPreview = localStorage.getItem("listCampaignIdToPreview")
  ? JSON.parse(localStorage.getItem("listCampaignIdToPreview"))
  : [];
class PreviewCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listCampaignIds: [],
    };
  }

  componentDidMount() {
    let state = { ...this.props.history.location.state };
    let { listCampaignId = [] } = state;
    if (
      listCampaignId &&
      Array.isArray(listCampaignId) &&
      listCampaignId.length > 0
    ) {
      delete state.listCampaignId;
      localStorage.setItem(
        "listCampaignIdToPreview",
        JSON.stringify(listCampaignId)
      );
      this.props.history.replace({ ...this.props.history.location, state });
    } else {
      listCampaignId = listCampaignIdToPreview;
    }

    if (listCampaignId.length > 0) {
      this.props.listCampaignToPreview(listCampaignId);
    } else {
      notification.warn({
        message: "Please choose products to preview.",
        duration: 5,
      });
    }
  }

  render() {
    const { listCampaignToPreviewData, pageName } = this.props;
    return (
      <>
      <div>
        <div className="Rectangle-848 d-block d-sm-flex align-items-center mb-4">
          <div className="col-12 col-xl-2 pr-0 Orders py-2 py-sm-4 ">{pageName}</div>
        </div>
        <div className="card m-3">
          <div className="card-body p-2">
            <Spin spinning={listCampaignToPreviewData.loading}>
              {listCampaignToPreviewData.data.length > 0 && (
                <Collapse
                  bordered={false}
                  defaultActiveKey={listCampaignToPreviewData.data.map(
                    (campaign) => campaign.id
                  )}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  {listCampaignToPreviewData.data.map((campaign) => {
                    return (
                      <Panel
                        header={campaign.name.toUpperCase()}
                        key={campaign.id}
                        className="site-collapse-custom-panel"
                      >
                        <RenderPreviewCampaign2
                          data={campaign.productCampaigns || []}
                          campaign={campaign}
                        />
                      </Panel>
                    );
                  })}
                </Collapse>
              )}
            </Spin>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    listCampaignToPreviewData: state.campaign.preview.listCampaignToPreview,
  }),
  (dispatch) => ({
    listCampaignToPreview: (listCampaignIds) => {
      dispatch(listCampaignToPreview(listCampaignIds));
    },
    submitFormUpdateAdditionalImages: function () {
      dispatch(submit("FormUpdateAdditionalImages"));
    },
  })
)(PreviewCampaign);
