import React from 'react';
import { GMButton } from 'components/Button';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ReactComponent as TopupIcon } from 'images/icons/credit-card-up.svg';
import ModalTopUp from 'components/ModalTopUp';
import { Wrapper } from './styled';
import money from 'utils/money';

const BalanceComponent = () => {
  const userBalance = useSelector(
    (state) => state?.auth?.info?.profile?.balance
  );
  const [isOpenModal, setIsOpenModal] = useState(false);

  const showModal = () => {
    setIsOpenModal(true);
  };

  if (!userBalance) {
    return;
  }

  return (
    <>
      <Wrapper className="d-flex justify-content-between inner-wrapper">
        <div className="content">
          <div className="title-balance">G-Wallet's Balance</div>
          <div className="value-balance">{money(userBalance)}</div>
        </div>
        <div className="d-flex align-items-center">
          <GMButton
            size="xl"
            onClick={showModal}
            className="d-flex align-items-center justify-content-center"
          >
            <TopupIcon className="mr-2" />
            Top up
          </GMButton>
        </div>
      </Wrapper>

      <ModalTopUp
        isOpenModal={isOpenModal}
        closeModal={() => setIsOpenModal(false)}
      />
    </>
  );
};

export default BalanceComponent;
