import { all } from "redux-saga/effects";
import ListSaga from "./scenes/List/saga";
import EditSaga from "./scenes/Edit/saga";
import CreateSaga from "./scenes/Create/saga";
import ScheduleReportSaga from "./scenes/ScheduleReport/saga";
import ScheduleReportDetailSaga from "../Stores/scenes/ScheduelReportDetail/saga";
export default function* () {
  yield all([
    ListSaga(),
    EditSaga(),
    CreateSaga(),
    ScheduleReportSaga(),
    ScheduleReportDetailSaga(),
  ]);
}
