import axios from "../utils/request";

const prefix = `/order-issue-category`;

export const list = (params) => {
    return axios({
        method: 'GET',
        params,
        url: `${prefix}`
    })
}