import { BASE_WHITE, PRIMARY_500, PRIMARY_800 } from "components/colors";
import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  button.addnewbtn {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      stroke: ${BASE_WHITE};
      margin-right: 12px;
    }
  }
  .edit {
    svg {
      width: 20px;
      height: 20px;
      stroke: ${PRIMARY_500};
    }
    &:hover svg {
      stroke: ${PRIMARY_800};
    }
  }
  .ctas {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
`;
