import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { reduxForm, change, formValueSelector } from 'redux-form';
import { Card, Spin } from 'antd';
import fb from 'images/facebookSetting.svg';
import gg from 'images/googleSetting.svg';
import { GMButton } from 'components/Button';
import { facebookLogin, googleLogin } from 'utils/SocialAuth';

import { connectSocialAction, disconnectSocialAction } from '../actions';


const FormSocial = ({ profile }) => {
  const dispatch = useDispatch();
  const connectLoading = useSelector((s) => s?.user?.profile.connectSocial.loading);
  const disconnectLoading = useSelector((s) => s?.user?.profile.disconnectSocial.loading);
  const socialLogin = (loginType) => {
    if (loginType === 'google') {
      googleLogin((error, result) => {
        if (error) {
        } else if (result.accessToken) {
          dispatch(
            connectSocialAction({
              accessToken: result?.accessToken || [],
              type: 'google',
            })
          );
        }
      });
    } else if (loginType === 'facebook') {
      facebookLogin((error, result) => {
        if (error) {
        } else if (result.accessToken) {
          dispatch(
            connectSocialAction({
              accessToken: result?.accessToken || [],
              type: 'google',
            })
          );
        }
      });
    }
  };
  const disconnectSocialAccount = (loginType) => {
    dispatch(
      disconnectSocialAction({
        type: loginType,
      })
    );
  };
  return (
    <Spin spinning={connectLoading || disconnectLoading}>
      {' '}
      <Card bordered={false}>
        <div className="Personal-Information">Connect social accounts</div>
        <div className="row d-flex social">
          {profile?.emailFacebook ? (
            <div className="social-card">
              <div>
                <div className="text-center">
                  <img src={fb} alt="facebook" />
                </div>
                <div className="mt-2">{profile?.emailFacebook}</div>
              </div>
              <GMButton
                onClick={() => disconnectSocialAccount('facebook')}
                className="mt-3"
                color={'SecondaryGray'}
                disabled
              >
                Disconnect
              </GMButton>
            </div>
          ) : (
            <div className="social-card">
              <div>
                <div className="text-center">
                  <img src={fb} alt="facebook" />
                </div>
              </div>
              <GMButton
                onClick={() => socialLogin('facebook')}
                className="mt-3"
                disabled
              >
                Connect
              </GMButton>
            </div>
          )}
          {profile?.emailGoogle ? (
            <div className="social-card">
              <div>
                <div className="text-center">
                  <img src={gg} alt="Google" />
                </div>
                <div className="mt-2"> {profile?.emailGoogle}</div>
              </div>
              <GMButton
                onClick={() => disconnectSocialAccount('google')}
                className="mt-3"
                color={'SecondaryGray'}
              >
                Disconnect
              </GMButton>
            </div>
          ) : (
            <div className="social-card">
              <div>
                <div className="text-center">
                  <img src={gg} alt="Google" />
                </div>
              </div>
              <GMButton onClick={() => socialLogin('google')} className="mt-3">
                Connect
              </GMButton>
            </div>
          )}
        </div>
      </Card>
    </Spin>
  );
};

FormSocial.propTypes = {};
const selector = formValueSelector('FormSocial');
const mapStateToProps = (state) => ({
  form_values: state.form.FormSocial,
  avatar: selector(state, 'avatar') || null,
  profile: state.auth.info.profile,
});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change('FormSocial', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'FormSocial',
    enableReinitialize: true,
  })(FormSocial)
);
