import React, { Component } from 'react';
import { Field, change, reduxForm, formValueSelector, reset } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validate';
import { renderSelectAnt } from 'utils/Form';
import FieldRangeDate from 'utils/fields/FieldRangeDate';
import { getStoreTypeName } from 'utils/helper';
import RenderSelectPlatformToSyncOrder from 'utils/Form/RenderSelectPlatformToSyncOrder';
import { list_platform_to_sync } from 'utils/options';
import { getList } from '../../../../../Stores/scenes/List/actions';
import { GMButton } from 'components/Button';

class index extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (parseInt(this.props.platformId) !== parseInt(prevProps.platformId)) {
      const platform = list_platform_to_sync.find(
        (item) => parseInt(item.value) === parseInt(this.props.platformId)
      );

      if (platform?.code) {
        this.props.getListStore({
          backendType: platform && platform?.code ? platform?.code : '',
        });
      }
      this.props.dispatch(change('FormSyncOrder', 'storeId', '0'));
    }
  }

  render() {
    const { handleSubmit, platformId, stores, storeLoading } = this.props;
    const platform = list_platform_to_sync.find(
      (item) => parseInt(item.value) === parseInt(platformId)
    );
    return (
      <form onSubmit={handleSubmit} className="p-4">
        <div className="form-group mb-1">
          <label className="col-form-label font-weight-bold">
            Choose platform :
          </label>
          <Field
            name="platformId"
            uploadFileRef={this.uploadFileRef}
            component={RenderSelectPlatformToSyncOrder}
            options={list_platform_to_sync}
            active={platform?.logo}
          />
        </div>

        <div className="form-group mb-1">
          <div className="row">
            <Field
              name="date_range"
              component={FieldRangeDate}
              className="w-100"
              dateTimeFormat="YYYY/MM/DD"
              showTime={false}
              classContainer="col-12 col-md-6 mb-2"
              startPlaceholder="From date"
              labelStart={<span className="font-weight-bold">From date</span>}
              labelEnd={<span className="font-weight-bold">To date</span>}
              endPlaceholder="To date"
            />
          </div>
        </div>
        <div className="form-group mb-1">
          <label className="col-form-label font-weight-bold">
            Choose store
          </label>
          <Field
            name="storeId"
            component={renderSelectAnt}
            colSpan={24}
            options={[
              { value: '0', label: 'Select store' },
              ...stores.map((store) => ({
                value: `${store.id}`,
                label:
                  `[${getStoreTypeName(store.backendType)}] ` +
                  (store.showName || `#${store.id}`),
              })),
            ]}
          />
        </div>
        <div className="ctas">
          <GMButton>Sync Order</GMButton>
        </div>
      </form>
    );
  }
}
const selector = formValueSelector('FormSyncOrder');
export default connect(
  (state) => ({
    platformId: selector(state, 'platformId'),
  }),
  (dispatch) => ({
    getListStore: (options) => {
      dispatch(getList(options));
    },
    changeFieldValue: function (field, value) {
      dispatch(change('FormSyncOrder', field, value));
    },
    resetForm: (formName) => {
      dispatch(reset(formName));
    },
  })
)(
  reduxForm({
    form: 'FormSyncOrder',
    enableReinitialize: true,
    validate,
  })(index)
);
