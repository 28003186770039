import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Button } from 'antd';
import getMomentInstance from 'utils/moment';
import { getDetail } from './actions';
import { getOptionLabel, schedule_task_status } from 'utils/options';
import BackToIcon from 'images/chevron-down.svg';
import { Wrapper } from './styled';
class Detail extends Component {
  componentDidMount() {
    this.props.getDetail(this.props.match.params.id);
  }

  render() {
    const { detail, pageName, loadingDetail } = this.props;
    const id = this.props.match.params.id;
    return (
      <Wrapper
        pageName={pageName}
        breadcrumDetails={{
          [id]: id && `Detail #${id || ''}`,
        }}
      >
        <div>
          <Spin spinning={loadingDetail}>
            <div className="table-responsive">
              <table className="table table-borderless table-sm">
                <tbody>
                  <tr className="d-flex flex-row">
                    <td
                      style={{ width: this.props.is_mobile ? 120 : 200 }}
                      className="px-md-4"
                    >
                      <b> Store: </b>
                    </td>
                    <td className="px-md-4">
                      {detail?.storeIntegration?.showName || ''}
                    </td>
                  </tr>
                  <tr className="d-flex flex-row">
                    <td
                      style={{ width: this.props.is_mobile ? 120 : 200 }}
                      className="px-md-4"
                    >
                      <b>Time from:</b>
                    </td>
                    <td className="px-md-4">
                      {detail.fromDate
                        ? getMomentInstance(null, detail.fromDate, true)
                        : 'N/A'}
                    </td>
                  </tr>
                  <tr className="d-flex flex-row">
                    <td
                      style={{ width: this.props.is_mobile ? 120 : 200 }}
                      className="px-md-4"
                    >
                      <b>Time to:</b>
                    </td>
                    <td className="px-md-4">
                      {detail.toDate
                        ? getMomentInstance(null, detail.toDate, true)
                        : 'N/A'}
                    </td>
                  </tr>
                  <tr className="d-flex flex-row">
                    <td
                      style={{ width: this.props.is_mobile ? 120 : 200 }}
                      className="px-md-4"
                    >
                      <b> Status: </b>
                    </td>
                    <td className="px-md-4">
                      {getOptionLabel(schedule_task_status, detail.status)}
                    </td>
                  </tr>
                  <tr className="d-flex flex-row">
                    <td
                      style={{ width: this.props.is_mobile ? 120 : 200 }}
                      className="px-md-4"
                    >
                      <b>Content:</b>
                    </td>
                    {detail?.log?.error ? (
                      <td className="px-md-4"> {detail?.log?.error?.title}</td>
                    ) : (
                      <td className="px-md-4">
                        {detail?.log?.success?.inserted}
                      </td>
                    )}
                  </tr>
                  <tr className="d-flex flex-row">
                    <td
                      style={{ width: this.props.is_mobile ? 120 : 200 }}
                      className="px-md-4"
                    >
                      <b>Request time:</b>
                    </td>
                    <td className="px-md-4">
                      {detail.requestedAt
                        ? getMomentInstance(null, detail.requestedAt, true)
                        : 'N/A'}
                    </td>
                  </tr>
                  <tr className="d-flex flex-row">
                    <td
                      style={{ width: this.props.is_mobile ? 120 : 200 }}
                      className="px-md-4"
                    >
                      <b>Complete time:</b>
                    </td>
                    <td className="px-md-4">
                      {detail.completedAt
                        ? getMomentInstance(null, detail.completedAt, true)
                        : 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Spin>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    loadingDetail:
      state.store.schedule_report_detail.scheduelReportDetail.loading,
    detail: state.store.schedule_report_detail.scheduelReportDetail.data,
  }),
  (dispatch) => ({
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
  })
)(Detail);
