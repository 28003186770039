import React from 'react';
import bannerImage from 'images/bg.png';
import bannerImageFill from 'images/bg-fill.png';
import bannerAnniversaryImageFill from 'images/Promotion/gm-anniversary/bg-fill.jpg';
import bannerAnniversaryImage from 'images/Promotion/gm-anniversary/bg.jpg';
import Counter from './Counter';
import Slider from './Slider';
export default () => {
  return <Slider />;
  return (
    <div
      className="authentication-slider-item text-center"
      // style={{ backgroundImage: `url(${bannerImage}), url(${bannerImageFill})` }}
      style={{ backgroundImage: `url(${bannerAnniversaryImage}), linear-gradient(#58b7f1, #58b7f1)` }}
    >
      {/* <Slider /> */}
      <Counter />
    </div>
  );
};
