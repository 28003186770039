import React from "react";

import { connect } from "react-redux";
import { Field, reduxForm, FieldArray } from "redux-form";

import {
  renderInputText,
  renderConditions,
  renderSwitchAnt,
  renderRadioAnt,
} from "utils/Form";

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = true;
  }
  if (!values.conditionMatch || !values.conditionMatch.length) {
    errors.conditionMatch = { _error: true };
  } else {
    const conditionArrayErrors = [];
    for (let index = 0; index < values.conditionMatch.length; index++) {
      const element = values.conditionMatch[index];
      const conditionErrors = {};
      if (!element.value) {
        conditionErrors.value = true;
        conditionArrayErrors[index] = conditionErrors;
      }
    }
    if (conditionArrayErrors.length) {
      errors.conditionMatch = conditionArrayErrors;
    }
  }
  return errors;
};

let FormCreateCollection = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
       {/* <div className="cursor-pointer d-flex justify-content-end mb-1">
          <img
            style={{ right: 10, bottom: 10 }}
            onClick={openTourCollection}
            src={question}
            height={35}
          />
        </div> */}
      <div className="row mb-3">
        <div className="col-md-12 mb-4">
          <div className="form-group tour1Collection">
            <label htmlFor="collection_name" className="col-form-label">
              <h5 className="font-weight-bold">
                Collection name
              </h5>{" "}
            </label>
            <Field
              id="collection_name"
              name="name"
              component={renderInputText}
            />
          </div>
          <div className="form-group tour2Collection">
            <div className="d-flex align-items-center">
              <Field
                id="collection_status"
                name="status"
                component={renderSwitchAnt}
              />
              <label
                htmlFor="collection_status"
                className="ml-3 col-form-label"
              >
                Status
              </label>
            </div>
          </div>
          <div className="form-group tour3Collection">
            <h5 className="font-weight-bold">Condition Setting</h5>
            <label className="col-form-label">Product must match</label>
            <Field
              name="typeMatch"
              component={renderRadioAnt}
              options={[
                { label: "All conditions", value: 1 },
                { label: "Any conditions", value: 0 },
              ]}
            />
          </div>
          <FieldArray name={`conditionMatch`} component={renderConditions} />
        </div>
      </div>
      <div className="text-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ width: 150 ,borderRadius: "5px"}}
        >
          Save
        </button>
      </div>
      {/* <Tour
          className="size-tour"
          onBeforeClose={enableBody}
          steps={stepsCollection}
          startAt={stepCount - 1}
          isOpen={isTourOpenCollection}
          disableFocusLock={true}
          onRequestClose={closeTourCollection}
          badgeContent={(curr, tot) => `${curr}/${tot}`}
          rounded={5}
          onAfterOpen={(e) => {
            disableBody(e);
          }}
          prevButton={
            <div>
              <div className="btn d-flex justify-content-end">Back</div>
            </div>
          }
          nextButton={
            <div>
              <div className="btn btn-primary d-flex justify-content-center align-items-center">
                Next
              </div>
            </div>
          }
          lastStepNextButton={
            <div>
              <div className="btn btn-secondary d-flex justify-content-center align-items-center">
                Close
              </div>
            </div>
          }
        /> */}
    </form>
  );
};

FormCreateCollection.propTypes = {};

FormCreateCollection = reduxForm({
  form: "FormCreateCollection",
  enableReinitialize: true,
  validate,
})(FormCreateCollection);
FormCreateCollection = connect((state) => {
  return {
    initialValues: {
      status: true,
      typeMatch: 1,
      conditions: [],
    },
  };
})(FormCreateCollection);
export default FormCreateCollection;
