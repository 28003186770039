import React, { Component } from 'react';
import classNames from 'classnames';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkFunctionalPermission } from 'utils/helper';
import { GMButton } from 'components/Button';
import { Wrapper } from './styled';
import { Dropdown } from 'antd';
import { ReactComponent as EditIcon } from 'images/icons/edit-01.svg';
import { ReactComponent as SyncIcon } from 'images/icons/sync.svg';
import { ReactComponent as UploadIcon } from 'images/icons/upload-01.svg';
import { ReactComponent as DownIcon } from 'images/icons/chevron-down.svg';
import { ContextMenuWrapper } from 'scenes/general.styled';
class BtnCreateProduct extends Component {
  render() {
    const { className, profile } = this.props;

    return (
      checkFunctionalPermission(profile, 'createOrderPermission') && (
        <Wrapper>
          <Dropdown
            trigger="click"
            overlay={
              <ContextMenuWrapper>
                <Link to={`/orders/create`}>
                  <span>
                    <PlusIcon />
                    Manual order
                  </span>
                </Link>

                <Link to={`/orders/create-label-order`}>
                  <span>
                    <EditIcon />
                    Label order
                  </span>
                </Link>
                {checkFunctionalPermission(profile, 'syncOrderPermission') && (
                  <Link to={`/orders/sync`}>
                    <span>
                      <SyncIcon className="sync" />
                      Sync order
                    </span>
                  </Link>
                )}
                {checkFunctionalPermission(
                  profile,
                  'importOrderPermission'
                ) && (
                  <Link to={`/orders/import`}>
                    <span>
                      <UploadIcon />
                      Import order
                    </span>
                  </Link>
                )}
              </ContextMenuWrapper>
            }
            placement="bottomLeft"
            arrow
          >
            <GMButton size="md" >
              <a href="/orders/create">
                <PlusIcon className="plusicon" />
                Create new order
              </a>
            </GMButton>
          </Dropdown>
        </Wrapper>
      )
    );
  }
}

export default React.memo(
  connect(
    (state) => ({
      profile: state.auth.info.profile,
    }),
    (dispatch) => ({})
  )(BtnCreateProduct)
);
