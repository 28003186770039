import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { renderRadioAnt } from "utils/Form";
import { listDefaultShippingServices } from "utils/options";
import { Card } from "antd";
import { GMSelect } from "components/InputFields";
import { GMTextbox } from 'components/InputFields';
import { GMButton } from "components/Button";

const index = ({ handleSubmit, is_mobile }) => {
  return (
    <form onSubmit={handleSubmit} className="mr-3">
      <div>
        <Card style={{ backgroundColor: "#fbfbfb" }}>
          <div>
            <b style={{ fontSize: "18px" }}>Domestic (U.S.) Shipping</b>
          </div>
          <GMSelect
            name="domesticUnder16oz"
            placeholder="Less than 15oz."
            label="Less than 15oz."
            options={[
              {
                label: <div className="optionShip">USPS</div>,
                value: `USPS`,
              },
              {
                label: (
                  <div className="optionShip">
                    DHL Parcels Expedited Domestic
                  </div>
                ),
                value: `DHLE`,
              },
            ]}
          />
          <GMSelect
            name="domesticOver16oz"
            placeholder="15oz. - 60lb."
            label="15oz. - 60lb."
            options={[
              {
                label: <div className="optionShip">USPS</div>,
                value: `USPS`,
              },
              {
                label: (
                  <div className="optionShip">
                    DHL Parcels Plus Expedited Domestic
                  </div>
                ),
                value: `DHLPE`,
              },
            ]}
          />
        </Card>
        <Card style={{ backgroundColor: "#fbfbfb" }} className="mt-3">
          <div>
            {" "}
            <b style={{ fontSize: "18px" }}>International Shipping</b>
          </div>
          <GMTextbox label="Less than 15oz." name="intlUnder16oz" disabled />
          <GMTextbox label="15oz. - 60lb." name="intlOver16oz" disabled />
        </Card>
        <div>
          {" "}
          <div className="col-sm-12 mb-4 mt-5">
            <b>Default Shipping Service</b>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <Field
                name="defaultShippingService"
                component={renderRadioAnt}
                options={listDefaultShippingServices}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="cta-section">
        <GMButton
        >
          Save
        </GMButton>
      </div>
    </form>
  );
};

const formShipping = reduxForm({
  form: "formShipping",
  enableReinitialize: true,
})(index);
export default connect()(formShipping);
