import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Space } from 'antd';
import { withTranslation } from 'react-i18next';
import money from 'utils/money';
import { withRouter } from 'react-router';
import { ReactComponent as Avatar } from 'images/Avatar.svg';
import { GlobalStyle, WrapperDropdown } from './styled';
import { GlobalStyle as MenuGlobalStyle } from '../styled';
import { setStore } from 'utils/helper';
import { IS_LEFT_SIDEBAR_OPEN } from '../GMMenu';

class UserDropdown extends Component {
  render() {
    const { t, profile } = this.props;
    return (
      <>
        <WrapperDropdown>
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu className="profile-menu">
                <Menu.Item>
                  <div className="d-flex">
                    <Avatar />
                    <div className="pl-2">
                      <div className="font-weight-bold profile-name">
                        {profile?.name}
                      </div>{' '}
                      {/* 0: Sale price, 1: Sale price old, 2: Silver, 3: Gold, 4: 30k, 5: 50k */}
                      {profile?.cusTierLevel || profile?.cusTierLevel === 0 ? (
                        <div className="tier">
                          {
                            [
                              'Bronze Tier',
                              null,
                              'Silver Tier',
                              'Gold Tier',
                              'Platinum Tier',
                              'Diamond Tier',
                            ][profile?.cusTierLevel]
                          }
                        </div>
                      ) : null}
                      {profile?.primaryId === 0 && (
                        <div className="money">{money(profile.balance)}</div>
                      )}
                    </div>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="1"
                  onClick={() => {
                    this.props.history.push('/settings/profile');
                  }}
                >
                  <div className="text">
                    <div className="text">
                      {profile?.primaryId !== 0 ? 'Staff profile' : 'Profile'}
                    </div>
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="4"
                  id="header_profile_logout-menu"
                  onClick={() => {
                    setStore(IS_LEFT_SIDEBAR_OPEN, !1);
                    this.props.history.push('/auth/logout');
                  }}
                >
                  <div className="text"> {t('Log out')}</div>
                </Menu.Item>
              </Menu>
            }
          >
            <Space
              className="cursor-pointer account-infor"
              id="header_profile_icon"
            >
              <Avatar />
            </Space>
          </Dropdown>
          <GlobalStyle />
        </WrapperDropdown>
        <MenuGlobalStyle />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.auth.info.profile,
});

const mapDispatchToProps = () => ({});

export default React.memo(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(withTranslation()(UserDropdown))
  )
);
