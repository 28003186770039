import { Table } from 'antd';
import React from 'react';
import { ReactComponent as WarningIcon } from 'images/icons/alert-triangle.svg';
import GMNotify from 'components/Notification';

const columns = [
  {
    title: 'Order name',
    align: 'left',
    key: 'order-name',
    render: (value, record) => {
      return record.orderName;
    },
  },
  {
    title: 'Customer name',
    align: 'left',
    key: 'customerName',
    render: (value, record) => record.customerName,
  },
  {
    title: 'Store',
    align: 'left',
    key: 'store',
    render: (value, record) => {
      return (
        <>
          <span className="bolder">{record.storeName}</span>
        </>
      );
    },
  },
  {
    title: 'Reason',
    align: 'left',
    key: 'reason',
    render: (value, record) => {
      return <span className="bolder">{`${record.reason}`}</span>;
    },
  },
];

export default ({ data }) => {
  const tableData = Object.values(data?.data?.orderError || {});
  if (tableData.length <= 0) {
    return null;
  }
  return (
    <div className="error-table block">
      <h4>Orders not ready for payment</h4>
      <div className="warning">
        <WarningIcon />
        The following orders can not be processed due to some reasons, please
        resolve them and re-try.{' '}
      </div>
      {/* <GMNotify
        type="infor"
        title={<div className="title">Notification</div>}
        desc={
          <div className="desc">
            {' '}
            The below orders can not be processed due to 1 or more reasons,
            please re-check the orders and take necessary action(s) and re-try.
          </div>
        }
      /> */}
      <Table
        className="order-list"
        columns={columns}
        pagination={false}
        dataSource={tableData}
        scroll={{ x: 768 }}
      />
    </div>
  );
};
