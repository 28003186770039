import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Divider, Button, Table, Space, notification } from 'antd';
import { PlusCircleOutlined, PictureFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import renderSelectAnt from './renderSelectAnt';
import renderInputText from './renderInputText';
import SelectProductsColor from './Search/SelectProductsColor';
import SelectProductsSize from './Search/SelectProductsSize';
import SelectProduct from './Search/SelectProduct';
import { RenderImageDesign } from './renderImageDesign';
import money from '../money';
import { renderImageAnt } from './index';
import RenderPreviewDesign from './renderPreviewDesign';
export default class renderOrderItemsFBA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      listPrintType: {},
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const {
      fields,
      meta: { error },
      data,
      changeField,
      exproducts,
      cusId,
      shippingService,
    } = this.props;
    const { selectedRowKeys, listPrintType } = this.state;
    const listChildernColumns = [
      {
        title: 'Poduct name',
        dataIndex: 'productId',
        align: 'center',
        width: 350,
        key: 'productId',
        render: (text, record, i) => {
          return (
            <div>
              <Field
                name={`${fields.name}[${i}].productId`}
                component={SelectProduct}
                classNameContainer={'mb-4'}
                data={exproducts}
                onChoose={(id, data) => {
                  const listSidePrint = [];
                  if (data?.flip !== null && typeof data?.flip === 'object') {
                    Object.keys(data?.flip).forEach((item) => {
                      listSidePrint.push({
                        value: item,
                        label: data.flip[item],
                      });
                    });
                  }
                  if (data.whole) {
                    Object.keys(data.whole).forEach((item) => {
                      listSidePrint.push({
                        value: item,
                        label: data.whole[item],
                      });
                    });
                  }

                  if (data.pocket) {
                    Object.keys(data.pocket).forEach((item) => {
                      listSidePrint.push({
                        value: item,
                        label: data.pocket[item],
                      });
                    });
                  }
                  if (listSidePrint.length > 0) {
                    listSidePrint.unshift({
                      value: 'normal',
                      label: 'Normal',
                    });
                  }
                  this.setState((state, props) => {
                    return {
                      listPrintType: {
                        ...state.listPrintType,
                        [`${id}_${i}`]: listSidePrint,
                      },
                    };
                  });
                  if (typeof changeField === 'function') {
                    changeField(`${fields.name}[${i}].colorId`, 0);
                    changeField(`${fields.name}[${i}].sizeId`, 0);
                    changeField(`${fields.name}[${i}].colorHex`, null);
                    changeField(`${fields.name}[${i}].sidePrint`, 'normal');
                    changeField(`${fields.name}[${i}].barcode`, null);
                    changeField(`${fields.name}[${i}].barcodeId`, null);
                    changeField(
                      `${fields.name}[${i}].productDisplayName`,
                      data?.brand
                    );
                    changeField(
                      `${fields.name}[${i}].basePrice`,
                      data.basePrice
                    );
                    changeField(
                      `${fields.name}[${i}].mockup`,
                      data.frontImg || ''
                    );
                    changeField(
                      `${fields.name}[${i}].productType`,
                      data.type || ''
                    );
                    changeField(
                      `${fields.name}[${i}].productName`,
                      data.brand || ''
                    );
                  }
                }}
                placeholder="-- Select Product --"
              />
              <Field
                name={`${fields.name}[${i}].colorId`}
                component={SelectProductsColor}
                expId={fields.get(i).productId}
                classNameContainer={'mb-4'}
                placeholder="-- Select color --"
                onChoose={(id, data) => {
                  if (typeof changeField === 'function' && data) {
                    changeField(
                      `${fields.name}[${i}].colorHex`,
                      `#${data.value2}`
                    );
                    changeField(`${fields.name}[${i}].sizeId`, 0);
                  }
                }}
              />
              <Field
                name={`${fields.name}[${i}].sizeId`}
                component={SelectProductsSize}
                expId={fields.get(i).productId}
                classNameContainer={'mb-4'}
                colorId={fields.get(i).colorId}
                checkStockAvailability={true}
                placeholder="-- Select size --"
              />
              <Field
                name={`${fields.name}[${i}].quantity`}
                classNameContainer={'mb-4'}
                component={renderInputText}
                placeholder={'Quantity'}
                min={0}
                isNumberInput={true}
                addonBefore={
                  parseInt(shippingService) === 9
                    ? 'Quantity/Item/1 box'
                    : 'Quantity'
                }
              />
              {listPrintType[`${record?.productId}_${i}`] &&
                Array.isArray(listPrintType[`${record?.productId}_${i}`]) &&
                listPrintType[`${record?.productId}_${i}`].length > 0 && (
                  <Field
                    name={`${fields.name}[${i}].sidePrint`}
                    classNameContainer={'mb-4'}
                    component={renderSelectAnt}
                    options={listPrintType[`${record?.productId}_${i}`]}
                  />
                )}
            </div>
          );
        },
      },
      {
        title: 'Mockup',
        dataIndex: 'mockup',
        key: 'mockup',
        align: 'center',
        width: 250,
        render: (text, record) => {
          return record.mockup ? (
            <>
              <img
                src={record.mockup}
                width="100"
                style={{
                  backgroundColor: record.colorHex
                    ? `${record.colorHex}`
                    : 'transparent',
                }}
                alt=""
              />
              <p>
                <b>{record?.productName || ''}</b>
              </p>
            </>
          ) : (
            <PictureFilled style={{ fontSize: 80, color: '#ccc' }} />
          );
        },
      },
      {
        title: 'Price',
        dataIndex: 'basePrice',
        align: 'center',
        width: 120,
        key: 'basePrice',
        render: (text, record, i) => {
          return <b>{money(text)}</b>;
        },
      },
      {
        title: 'Design',
        key: 'designFront',
        align: 'center',
        dataIndex: 'designFront',
        width: 250,
        render: (text, record, i) => {
          const colorString = record.colorHex
            ? `${record.colorHex}`
            : 'transparent';
          return (
            <Space>
              <div>

                {record?.productDisplay * 1 || record?.display * 1 ? (
                  <RenderPreviewDesign
                    isCreatingOrder={true}
                    colorHex={colorString}
                    exProductId={record.productId}
                    colorId={record.colorId}
                    sidePrint={'front'}
                    sizeId={record.sizeId}
                    design={record.designFront}
                    typePrint={record.sidePrint}
                    productName={record.productDisplayName}
                  />
                ) : null}
                <Field
                  name={`${fields.name}[${i}].designFront`}
                  changeDesignFront={(value) => {
                    changeField(`${fields.name}[${i}].designFront`, value);
                    notification.success({
                      message:
                        'Assign design for order item - side front successfully.',
                      duration: 5,
                    });
                  }}
                  changeDesignBack={(value) => {
                    changeField(`${fields.name}[${i}].designBack`, value);
                    notification.success({
                      message:
                        'Assign design for order item - side back successfully.',
                      duration: 5,
                    });
                  }}
                  changeDesignFrontId={(value) =>
                    changeField(`${fields.name}[${i}].designFrontId`, value)
                  }
                  changeDesignBackId={(value) =>
                    changeField(`${fields.name}[${i}].designBackId`, value)
                  }
                  component={RenderImageDesign}
                  cusId={cusId}
                  isCreatingOrder={true}
                />
              </div>
              <div>

                {record?.productDisplay * 1 || record?.display * 1 ? (
                  <RenderPreviewDesign
                    isCreatingOrder={true}
                    colorHex={colorString}
                    exProductId={record.productId}
                    colorId={record.colorId}
                    sidePrint={'back'}
                    sizeId={record.sizeId}
                    typePrint={record.sidePrint}
                    design={record.designBack}
                    productName={record.productDisplayName}
                  />
                ) : null}
                <Field
                  name={`${fields.name}[${i}].designBack`}
                  changeDesignFront={(value) => {
                    changeField(`${fields.name}[${i}].designFront`, value);
                    notification.success({
                      message:
                        'Assign design for order item - side front successfully.',
                      duration: 5,
                    });
                  }}
                  changeDesignBack={(value) => {
                    changeField(`${fields.name}[${i}].designBack`, value);
                    notification.success({
                      message:
                        'Assign design for order item - side back successfully.',
                      duration: 5,
                    });
                  }}
                  changeDesignFrontId={(value) =>
                    changeField(`${fields.name}[${i}].designFrontId`, value)
                  }
                  changeDesignBackId={(value) =>
                    changeField(`${fields.name}[${i}].designBackId`, value)
                  }
                  component={RenderImageDesign}
                  cusId={cusId}
                  isCreatingOrder={true}
                />
              </div>
            </Space>
          );
        },
      },
    ];
    if (parseInt(shippingService) === 9) {
      listChildernColumns.push({
        title: 'Barcode',
        key: 'barcodeId',
        align: 'center',
        dataIndex: 'barcodeId',
        width: 250,
        render: (text, record, i) => {
          return (
            <div>
              <Field
                name={`${fields.name}[${i}].barcode`}
                component={renderImageAnt}
                showLinkFile={true}
                accept={'.pdf'}
                changeField={(value) => {
                  changeField(`${fields.name}[${i}].barcodeId`, value);
                }}
                showUploadList={false}
                typeUpload={'barcode'}
                cusId={cusId}
              />
            </div>
          );
        },
      });
    }
    listChildernColumns.push({
      title: '',
      key: 'action',
      width: 100,
      render: (text, record, i) =>
        i > 0 && (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => {
                fields.remove(i);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ),
    });

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [],
    };
    return (
      <div>
        <div className=" d-flex justify-content-end align-content-end">
          <Button
            type="link"
            className="text-primary"
            onClick={() =>
              fields.push({
                key: fields.length,
                productId: '0',
                colorId: '0',
                sizeId: '0',
                quantity: '0',
                price: 0,
                designFront: '',
                designBack: '',
                mockup: '',
                designFrontId: '',
                designBackId: '',
                barcode: '',
                barcodeId: '',
              })
            }
          >
            <PlusCircleOutlined style={{ fontSize: 20 }} />{' '}
            <b className="text-uppercase">Add another item</b>
          </Button>
        </div>
        <Table
          pagination={false}
          rowSelection={rowSelection}
          columns={listChildernColumns}
          dataSource={data}
          size={'middle'}
          bordered
          key={`key`}
          scroll={{ x: 1000 }}
          rowClassName={(record, index) => (index % 2 !== 0 ? '' : '')}
        />
        {error && <span className="invalid-feedback d-block">{error}</span>}
        <Divider className="my-0" />
      </div>
    );
  }
}
