import React, { useState } from "react";
import { Input } from "antd";
import classNames from "classnames";
const RenderCreditCardNumber = ({
  input,
  prefix = null,
  suffix = null,
  addonBefore = null,
  addonAfter = null,
  className = "",
  placeholder = "",
  size = "large",
  meta: { error, submitFailed },
  disabled = false,
  defaultValue = "",
  maxLength,
  classNameContainer = "",
  id = null,
}) => {
  let [trueValue, setTrueValue] = useState("");
  let [showValue, setShowValue] = useState("");
  let [timeOut, setTimeOut] = useState(null);

  const divideNumber = (str) => {
    if (!str) str = "";
    str = str.replace("-", "");
    let partNumber = Math.ceil(str.length / 4);
    let result = [];
    for (let i = 0; i < partNumber; i++) {
      if ((i + 1) * 4 > str.length) {
        result.push(str.substring(i * 4));
      } else {
        result.push(str.substring(i * 4, (i + 1) * 4));
      }
    }
    return result.join("-");
  };
  const handleKeyDown = (k_event) => {
    clearTimeout(timeOut);
    setTimeOut(null);
    let key = k_event.which || k_event.keyCode;
    let n = Number(String.fromCharCode(key));
    let tempTrue = trueValue,
      tempShow = showValue;

    if (!isNaN(n)) {
      tempTrue = trueValue + n.toString();
      tempShow = new Array(showValue.length + 1).join("x") + n.toString();
      setTrueValue(tempTrue);
      setShowValue(tempShow);
    } else {
      if (key === 8 || key === 46) {
        tempTrue = trueValue.slice(0, -1);
        tempShow = showValue.slice(0, -1);
        setTrueValue(tempTrue);
        setShowValue(tempShow);
      }
    }
    setTimeOut(
      setTimeout(function () {
        tempShow = new Array((tempShow?.length || 0) + 1).join("x");
        setShowValue(tempShow);
        input.onChange({
          trueValue: tempTrue,
          hiddenValue: divideNumber(tempShow),
        });
      }, 450)
    );
    input.onChange({
      trueValue: tempTrue,
      hiddenValue: divideNumber(tempShow),
    });
  };
  return (
    <div
      className={classNames(
        "position-relative w-100 optionShip",
        classNameContainer
      )}
    >
      <Input
        id={id}
        allowClear
        disabled={disabled}
        value={input.value.hiddenValue || defaultValue}
        size={size}
        className={classNames(className)}
        // onChange={(e) => handleOnChange(e.target.value)}
        onKeyDown={(k) => handleKeyDown(k)}
        placeholder={placeholder}
        prefix={prefix}
        suffix={suffix}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        defaultValue={defaultValue}
        maxLength={maxLength}
      />
      {(error && submitFailed) && (
        <span className="invalid-feedback">{error}</span>
      )}
    </div>
  );
};

export default RenderCreditCardNumber;
