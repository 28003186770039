import React from 'react';
import CreditCardMethod from './CreditCardMethod';
import GWalletMethod from './GWalletMethod';
import PayoneerMethod from './PayoneerMethod';
import PaypalMethod from './PaypalMethod';

function SelectPaymentAccount({paymentMethod, ...props}){
    return (
        <>
        {parseInt(paymentMethod) === 0 && <PaypalMethod {...props}/>}
        {parseInt(paymentMethod) === 1 && <PayoneerMethod {...props}/>}
        {parseInt(paymentMethod) === 2 && <CreditCardMethod {...props}/>}
        {parseInt(paymentMethod) === 3 && <GWalletMethod {...props}/>}
        </>
    )
}

export default React.memo(SelectPaymentAccount)