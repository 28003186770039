import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Button } from 'antd';
import BackToIcon from 'images/chevron-down.svg';
import { detailAccount, updateAccount } from '../../actions';
import EditForm from '../Edit/components/EditForm';
import { change } from 'redux-form';
import { listPermissions } from 'utils/options';
import { Wrapper } from '../Create/styled';
class Edit extends Component {
  componentDidMount() {
    this.props.detailAccount(this.props.match.params.id);
  }

  handleOnSubmitEditForm = (values) => {
    const dataToUpdate = {
      name: values.fullName || '',
      email: values.email || '',
      status: values.status || [2],
      permissions: {
        fullPermission:
          values.fullPermission && values.fullPermission.includes(1) ? 1 : 0,
      },
    };
    for (let index = 0; index < listPermissions.length; index++) {
      dataToUpdate['permissions'][listPermissions[index]] =
        values[listPermissions[index]] &&
        values[listPermissions[index]].includes(1)
          ? 1
          : 0;
    }
    this.props.updateAccount(this.props.match.params.id, dataToUpdate);
  };

  handleOnChangePermission = (name, value) => {
    const { changeFieldValue, formEditAccountData } = this.props;
    if (name === 'fullPermission') {
      if (value.includes(1)) {
        for (let index = 0; index < listPermissions.length; index++) {
          changeFieldValue(listPermissions[index], [1]);
        }
      } else {
        for (let index = 0; index < listPermissions.length; index++) {
          changeFieldValue(listPermissions[index], [0]);
        }
      }
    } else {
      if (
        !value.includes(1) &&
        formEditAccountData?.fullPermission &&
        formEditAccountData?.fullPermission.includes(1)
      ) {
        changeFieldValue('fullPermission', [0]);
      } else if (value.includes(1)) {
        let flag = false;
        for (let index = 0; index < listPermissions.length; index++) {
          if (listPermissions[index] !== name) {
            if (
              Array.isArray(formEditAccountData[listPermissions[index]]) &&
              formEditAccountData[listPermissions[index]].includes(1)
            ) {
              flag = true;
            } else {
              flag = false;
              break;
            }
          }
        }

        if (flag === true) {
          changeFieldValue('fullPermission', [1]);
        }
      }
    }
  };

  render() {
    const {
      pageName,
      updateAccountLoading,
      detailAccountLoading,
      detailAccountData,
    } = this.props;
    const initialValues = {
      fullName: detailAccountData.name || '',
      email: detailAccountData.email || '',
      status: detailAccountData.status || 2,
      fullPermission: detailAccountData.fullPermission === 1 ? [1] : [0],
    };
    for (let index = 0; index < listPermissions.length; index++) {
      initialValues[listPermissions[index]] =
        parseInt(detailAccountData.fullPermission) === 1 ||
        detailAccountData[listPermissions[index]] === 1
          ? [1]
          : [0];
    }
    return (
      <Wrapper
        pageName={pageName}
        breadcrumDetails={{
          [this.props.match.params.id]: this.props?.match?.params?.id
            ? `User #${this.props?.match?.params?.id}`
            : '',
        }}
      >
        <Spin spinning={updateAccountLoading || detailAccountLoading}>
          <EditForm
            onSubmit={this.handleOnSubmitEditForm}
            initialValues={initialValues}
            handleOnChangePermission={this.handleOnChangePermission}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    updateAccountLoading: state.setting.account.updateAccount.loading,
    detailAccountLoading: state.setting.account.detailAccount.loading,
    detailAccountData: state.setting.account.detailAccount.data,
    formEditAccountData: state.form.formEditAccount?.values,
  }),
  (dispatch) => ({
    detailAccount: (id) => {
      dispatch(detailAccount(id));
    },
    updateAccount: (id, dataToUpdate) => {
      dispatch(updateAccount(id, dataToUpdate));
    },
    changeFieldValue: function (field, value) {
      dispatch(change('formEditAccount', field, value));
    },
  })
)(Edit);
