import React from "react";
import NotificationShippingList from "./scenes/List";
import DetailNotifyShipping from "./scenes/Detail";
import icon from "images/bell2.svg";

export default [
  {
    key: "statistics.shipping",
    name: "Shipping",
    component: NotificationShippingList,
    path: "/statistics/shipping",
    template: "default",
    icon: <img src={icon} height={21} alt="icon"/>,
    group: "notification_shipping",
    isPublic: true,
  },
  {
    key: "statistics.shipping",
    name: "Shipping",
    component: DetailNotifyShipping,
    path: "/statistics/shipping/:id",
    template: "default",
    isPublic: true,
  },
];
