import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import validate from './validate';
import {
  renderSwitchAnt,
  renderSelectAnt,
  renderInputText,
  renderRadioAnt,
} from 'utils/Form';
import { options_ebay } from 'utils/options';
import ebay_logo from 'assets/images/ebay.png';
import { GMTextbox } from 'components/InputFields';
import Select from 'components/InputFields/Select';
import { Wrapper } from '../styled';
import { GMButton } from 'components/Button';
import { WrapperUpdateButton } from '../../styled';

import { ReactComponent as EbayIcon } from 'images/Logo_store/full/ebay.svg';

let Index = ({
  handleSubmit,
  onCancel,
  accounts,
  currAction,
  amazonMarketPlaceId,
  changeFieldValue,
  profile,
  ...props
}) => {
  useEffect(() => {
    if (amazonMarketPlaceId) {
      changeFieldValue('amazonMarketPlaceId', '0');
    }
  }, [amazonMarketPlaceId]);
  return (
    <form onSubmit={handleSubmit} className="mx-2">
      <WrapperUpdateButton>
        <div className="text-left my-3">
          <EbayIcon />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Store name</b>
          </label>
          <GMTextbox name="showName" showError />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            {' '}
            <b>Url store</b>
          </label>
          <GMTextbox name="url" showError />

          <span className="text-muted">
            Ex: https://stores.ebay.com/yourstorename
          </span>
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Ebay marketplace</b>
          </label>
          <div className="border border-dashed p-3 bg-light">
            <Field
              name="amazonMarketPlaceId"
              component={renderRadioAnt}
              options={options_ebay}
              defaultValue={'0'}
            />
          </div>
        </div>
        {parseInt(profile.primaryId) === 0 && (
          <div className="form-group">
            <label className="col-form-label">
              <b>Staff Account</b>
            </label>
            <Wrapper>
              <Field
                name="staffId"
                component={renderSelectAnt}
                placeholder="-- Select staff --"
                mode={'multiple'}
                options={accounts.map((item) => ({
                  value: item.id.toString(),
                  label: item.name,
                }))}
              />
            </Wrapper>
          </div>
        )}
        <div className="form-group">
          <div className="d-flex align-items-center">
            <Field name="autoSync" component={renderSwitchAnt} />
            <label htmlFor="store_auto_sync" className="ml-3 col-form-label">
              Automatic synchronization when new orders arrive.{' '}
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="d-flex align-items-center">
            <GMTextbox name="status" showError component={renderSwitchAnt} />

            <label htmlFor="store_active" className="ml-3 col-form-label">
              Active store
            </label>
          </div>
        </div>
        <div>
          <GMButton className="saveBtn" size="lg">
            {currAction === 'create' ? 'Connect store' : 'Update store'}
          </GMButton>
        </div>
      </WrapperUpdateButton>
    </form>
  );
};
const selector = formValueSelector('FormEbayStore');
const mapStateToProps = (state) => ({
  amazonMarketPlaceId: selector(state, 'amazonMarketPlaceId') || '',
});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change('Index', field, value));
  },
});
Index = reduxForm({
  form: 'FormEbayStore',
  enableReinitialize: true,
  validate,
})(Index);

export default connect(mapDispatchToProps, mapStateToProps)(Index);
