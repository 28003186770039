import React from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { TINY_MCE_KEY } from 'web.config';

export const RenderTinyMCE = ({height=500, input, meta: { error }, inputProps={}}) => {
    const handleEditorChange =(content, editor)=>{
        input.onChange(content)
    }
    return (
        <div>
            <Editor
                apiKey={TINY_MCE_KEY}
                value={input.value || ''}
                init={{
                    height,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                }}
                onEditorChange={handleEditorChange}
                {...inputProps}
            />
            { error && <span className="invalid-feedback d-block">{error}</span> }
        </div>
    )
}
