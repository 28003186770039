import React, { Component } from 'react'
import { options_bulk_campaign_status, getOptionLabel, uploading_message } from "utils/options"
import noImage from "images/noImg.png"
import { Popover, Spin, Popconfirm } from "antd"
import renderInputText from "./renderInputText";
import { Field } from "redux-form";
import { ReactComponent as DeleteIcon } from 'images/icons/trash-01.svg';
class RenderListBulkImage extends Component {
  render() {
    const { data, rebulkCampaign, fields } = this.props;
    return (
      <div className="table-responsive">
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th scope="col" className="text-center"><b>IMAGE</b></th>
              <th scope="col" className="text-center"><b>FILE NAME</b></th>
              <th scope="col" className="text-center"><b>CAMPAIGN TITLE</b></th>
              <th scope="col" className="text-center"><b>URL PATH</b></th>
              <th scope="col" className="text-center"><b>STATUS</b></th>
              <th scope="col" className="text-center"><b>ACTION</b></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr key={i}>
                <th scope="row" className="text-center">{item.imageLink ? <img alt="" style={{ width: '100px' }} className='img-fluid' src={item.imageLink} /> : <img alt="" style={{ width: '100px' }} className='img-fluid' src={noImage} />}</th>
                <td className="text-center">{item.fileName}</td>
                <td className="text-center">
                  <Field
                    name={`${fields.name}[${i}].campaignTitle`}
                    component={renderInputText}
                    className='w-100'
                  />
                </td>
                <td className="text-center">
                  <Field
                    name={`${fields.name}[${i}].urlPath`}
                    component={renderInputText}
                    className='w-100'
                  />
                </td>
                <td className="text-center">
                  <Spin spinning={item.loading === true} tip={uploading_message}>
                    <div className={"mb-2"}>{getOptionLabel(options_bulk_campaign_status, item.status)}</div>
                    <div className={"mb-2"}>{item.error &&
                      <Popover content={<div className="font-italic">{item.error}</div>} title="Error Detail">
                        <span className="text-primary cursor-pointer">Detail</span>
                      </Popover>}</div>
                    {item.error && <button type="button" className="btn btn-primary" onClick={() => { rebulkCampaign(item, i + 1) }}>Retry</button>}
                  </Spin>
                </td>
                <td className="text-center">
                  {/* <Popconfirm
                    title="Are you sure to delete this record?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => fields.remove(i)}
                  >
                  </Popconfirm> */}
                  <DeleteIcon className="action-delete" onClick={() => fields.remove(i)} />
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default React.memo(RenderListBulkImage);