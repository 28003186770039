import styled from "styled-components";

export const Wrapper = styled.span`
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-size: 0;
    background-image: url(${p => p.src});
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
`;