import { takeLatest, call, put, all } from "redux-saga/effects";
import { AUTH } from "./actions";
import { LOGIN } from "./scenes/Login/actions";
import LoginSaga from "./scenes/Login/saga";
import ForgetSaga from "./scenes/Forgot/saga";
import LogoutSaga from "./scenes/Logout/saga";
import VerifyTokenSaga from "./scenes/ResetPassword/saga";
import VerifyStaffTokenSaga from "./scenes/VerifyStaffAccount/saga";
import VerifyEmailSaga from "./scenes/VerifyEmail/saga";
import RegisterSaga from "./scenes/Register/saga";
import { verifyAPI, getSystemSetting } from "apis/user";
import { API_TOKEN_KEY } from "../../web.config";
import { getCookie } from "utils/helper";
function* verifySaga() {
  try {
    if (getCookie(API_TOKEN_KEY) /* auth cookie */) {
      const response = yield call(verifyAPI);
      if (response.code === "success" && response?.data?.customer) {
        const {
          data: { customer: profile, permission },
        } = response;
        yield put({ type: AUTH.verify.success, profile, permission });
      } else {
        yield put({ type: AUTH.verify.error, error: response });
      }
    } else {
      yield put({ type: AUTH.verify.error });
    }
  } catch (error) {
    yield put({ type: AUTH.verify.error, error });
  }
}

function* getSystemSettingSaga() {
  try {
    const response = yield call(getSystemSetting);
    if (response.code === "success") {
      yield put({ type: AUTH.SYSTEM_SETTING.SUCCESS, ...response });
    } else {
      yield put({ type: AUTH.SYSTEM_SETTING.ERROR, response });
    }
  } catch (error) {
    yield put({ type: AUTH.getProfile.error, error });
  }
}

function* watcher() {
  yield all([
    takeLatest(AUTH.verify.request, verifySaga),
    takeLatest(LOGIN.success, verifySaga),
    takeLatest(AUTH.SYSTEM_SETTING.REQUEST, getSystemSettingSaga),
  ]);
}

export default function* () {
  yield all([
    watcher(),
    ForgetSaga(),
    LoginSaga(),
    RegisterSaga(),
    LogoutSaga(),
    VerifyTokenSaga(),
    VerifyStaffTokenSaga(),
    VerifyEmailSaga()
  ]);
}
