import { GRAY_600, PRIMARY_500 } from 'components/colors';
import styled from 'styled-components';
export const Wrapper = styled.div`
  .upload-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .upload-success {
    border: 1px dashed #eaecf0;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    padding: 8px;
  }

  .text-upload {
    font-size: 14px;
  }
  .click-to {
    color: ${PRIMARY_500};
  }
  .upload {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
