import { actionType as TYPE } from "./actions";

const initialState = {
  transactionDetail: {
    data: {},
    loading: false,
  },
};
function DetailReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.DETAIL_TRANSACTION.REQUEST:
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          loading: true,
        },
      };
    case TYPE.DETAIL_TRANSACTION.SUCCESS:
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          loading: false,
          data: action.data.transaction,
        },
      };
    case TYPE.DETAIL_TRANSACTION.ERROR:
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default DetailReducer;
