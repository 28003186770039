import React, { PureComponent, Children } from 'react';
import { connect } from 'react-redux';
import { Layout, Affix } from 'antd';
import Header from './Header';
import { openLeftSidebar, toggleFullScreen } from 'ui/actions';
import classNames from 'classnames';
import { getStyleOfMainLayout } from 'utils/helper';
import { withRouter } from 'react-router-dom';
import Footer from './Footer';
import GMHelmet from 'components/GMHelmet';
import GMMenu from './GMMenu';
import { GMLayout } from './styled';
import ResetpasswordPopup from "../../../components/ResetPasswordPopup";

class index extends PureComponent {
  handleToggleSidebar = () => {
    const {
      ui: { open_left_sidebar },
    } = this.props;
    this.props.openLeftSidebar(!open_left_sidebar);
  };

  getLayoutStyle = () => {
    const {
      screens: { is_mobile },
    } = this.props;

    if (!is_mobile) {
      return {
        // paddingLeft: this.props.open_left_sidebar ? '0' : '256px',
      };
    }
    return null;
  };

  componentDidMount() {
    this.props.openLeftSidebar(true);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      screens: { is_mobile },
    } = this.props;
    if (is_mobile !== prevProps.screens.is_mobile) {
      if (1) {
        this.props.openLeftSidebar(false);
      }
    }
  }

  handleToggleFullScreen = () => {
    const {
      ui: { full_screen },
    } = this.props;
    this.props.toggleFullScreen();

    const elem = document.getElementById('root');
    if (document.fullscreenElement && full_screen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen && full_screen) {
        // Firefox //
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen && full_screen) {
        // Chrome, Safari and Opera //
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen && full_screen) {
        // IE//Edge //
        document.msExitFullscreen();
      }
    } else {
      if (elem.requestFullscreen && !full_screen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen && !full_screen) {
        // Firefox //
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen && !full_screen) {
        // Chrome, Safari & Opera //
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen && !full_screen) {
        // IE/Edge /
        elem.msRequestFullscreen();
      }
    }
  };
  render() {
    const { children, ...props } = this.props;
    return (
      <GMLayout>
        <ResetpasswordPopup />
        <GMHelmet />
        <GMMenu
          isMobile={this?.props?.screens?.is_mobile}
          handleToggleSidebar={this.handleToggleSidebar}
          {...props}
        />
        <Layout
          style={getStyleOfMainLayout(
            this.props?.history?.location?.pathname || ''
          )}
        >
          {!props.ui.full_screen && (
            <Affix offsetTop={0}>
              <Layout.Header className="main-header pl-0 pr-3 box-shadow d-table w-100">
                <Header
                  toggleSidebar={this.handleToggleSidebar}
                  {...props}
                  currStore={this.props.currStore}
                  listStores={this.props.listStores}
                  is_mobile={this?.props?.screens?.is_mobile}
                />
              </Layout.Header>
            </Affix>
          )}
          <Layout.Content
            className={classNames('main-content', {
              'mx-2 my-1': props.ui.full_screen,
            })}
          >
            {Children.map(this.props.children, (child) =>
              React.cloneElement(child, { ...props.screens })
            )}
          </Layout.Content>
          {['/dashboard', '/help'].includes(window.location.pathname) && (
            <Layout.Footer className="bg-footer p-0">
              <Footer />
            </Layout.Footer>
          )}
        </Layout>
      </GMLayout>
    );
  }
}

export default React.memo(
  withRouter(
    connect(
      (state) => ({
        ui: state.ui,
        systemSetting: state.auth.info.setting,
      }),
      (dispatch) => ({
        openLeftSidebar: (open_left_sidebar) => {
          dispatch(openLeftSidebar(open_left_sidebar));
        },
        toggleFullScreen: () => {
          dispatch(toggleFullScreen());
        },
      })
    )(index)
  )
);
