import { takeLatest, call, put, all, takeEvery } from 'redux-saga/effects';

import { actionType as TYPE } from './actions';

import { actionType as actionType2 } from '../../../Mockups/scenes/Create/actions';

import {
  listOrderToMatchProduct,
  saveMatchProduct,
  deleteDesign,
  removeOrderItemMatchProduct,
} from 'apis/orders';
import { replace } from 'connected-react-router';
import { notification } from 'antd';

function* listOrderToMatchProductSaga(action) {
  const { cb, ...data } = action?.data || {};
  try {
    const response = yield call(listOrderToMatchProduct, data);
    if (response.code === 'success') {
      response.data = response?.data || {};
      response.data.listOrderToMatch = response?.data?.listOrderToMatch || [];
      response.data.listOrderToMatch = response?.data?.listOrderToMatch?.map(
        (oI) => {
          return {
            ...oI,
            orderItems: oI?.orderItems?.map((i) => {
              return {
                ...i,
                printSide: i?.exProduct?.printLocation || i.printSide,
              };
            }),
          };
        }
      );
      if (response.data.listOrderToMatch.length === 0) {
        // if (typeof cb !== 'function') {
        //   yield put(replace('/orders/list'));
        // }
        yield put(replace('/orders/list'));
        notification.warn({
          message:
            'The selected order(s) is locked and cannot be modified. If you need any assistance, please visit our helpdesk page for more information.',
          duration: 2,
        });
      }
      yield put({
        type: TYPE.LIST_ORDER_TO_MATCH_PRODUCT.SUCCESS,
        isCb: !!cb,
        ...response,
      });
      yield put({ type: actionType2.LIST_EXPRODUCT.REQUEST });
    } else {
      const dispatchList = [yield put({
        type: TYPE.LIST_ORDER_TO_MATCH_PRODUCT.ERROR,
        error: response,
      })];
      // if (typeof cb !== 'function') {
      //   dispatchList.push(
      //     yield put(replace('/orders/list'))
      //   );
      // }
      dispatchList.push(
        yield put(replace('/orders/list'))
      );
      yield all(dispatchList);
    }
  } catch (error) {
    yield all([
      yield put({ type: TYPE.LIST_ORDER_TO_MATCH_PRODUCT.ERROR, error }),
      yield put(replace('/orders/list')),
    ]);
  }
  if (typeof cb === 'function') {
    cb();
  }
}

function* saveMatchProductSaga(action) {
  const { next, ...data } = action?.data;
  const isNext = typeof next === 'function';
  if (!data?.items) {
    yield all([
      yield put({
        type: TYPE.SAVE_MATCH_PRODUCT.SUCCESS,
        errors: [],
        isNext: false,
      }),
    ]);
    return;
  }
  try {
    const response = yield call(saveMatchProduct, data);
    yield all([
      yield put({
        type: TYPE.SAVE_MATCH_PRODUCT.SUCCESS,
        errors: response.data || [],
        isNext,
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.SAVE_MATCH_PRODUCT.ERROR, error })]);
  } finally {
    if (isNext) {
      next();
    }
  }
}

function* deleteDesignSaga(action) {
  try {
    const { data } = action;
    const response = yield call(deleteDesign, data);
    yield put({ type: TYPE.DELETE_DESIGN.SUCCESS, errors: response });
  } catch (error) {
    yield all([yield put({ type: TYPE.DELETE_DESIGN.ERROR, error })]);
  }
}

function* removeOrderItemMatchProductSaga(action) {
  const { data } = action;
  try {
    const response = yield call(removeOrderItemMatchProduct, data);
    if (response.code === 'success') {
      yield put({ type: TYPE.REMOVE_ITEM_MATCH_PRODUCT.SUCCESS, ...response });
    } else {
      yield put({
        type: TYPE.REMOVE_ITEM_MATCH_PRODUCT.ERROR,
        error: response,
      });
    }
  } catch (error) {
    yield all([
      yield put({ type: TYPE.REMOVE_ITEM_MATCH_PRODUCT.ERROR, error }),
    ]);
  }
}

function* watcher() {
  yield takeLatest(
    TYPE.LIST_ORDER_TO_MATCH_PRODUCT.REQUEST,
    listOrderToMatchProductSaga
  );
  yield takeEvery(TYPE.SAVE_MATCH_PRODUCT.REQUEST, saveMatchProductSaga);
  yield takeLatest(TYPE.DELETE_DESIGN.REQUEST, deleteDesignSaga);
  yield takeLatest(
    TYPE.REMOVE_ITEM_MATCH_PRODUCT.REQUEST,
    removeOrderItemMatchProductSaga
  );
}

export default watcher;
