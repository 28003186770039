import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Spin, Pagination, Modal, Steps } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';

import getMomentInstance from 'utils/moment';
import {
  getList,
  getProfitTransaction,
  exportTransaction,
  getListQuickView,
} from './actions';
import FormFilter from './components/FormFilter';
import GeneralInfo from './components/GeneralInfo';
import {
  getOptionLabel,
  options_payment_method,
  options_payment_status,
} from 'utils/options';
import money from 'utils/money';
import RenderQuickViewTransaction from 'utils/Form/RenderQuickViewTransaction';
import { GlobalStyle } from './styled';
import Step1 from 'components/ModalTopUp/components/Step1';
import Step2 from 'components/ModalTopUp/components/Step2';
import { ReactComponent as ArrowLeft } from 'images/icons/arrow-left.svg';
import { sendRequestTopUp } from 'scenes/TopUp/actions';
import { Wrapper } from './styled';
import { ButtonGM } from 'components/ButtonGM/styled';
import { GMButton } from 'components/Button';

const { Step } = Steps;

class List extends Component {
  constructor(props) {
    super(props);
    const { createdAtFrom, createdAtTo, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        type: '0',
        paymentMethod: '0',
        ...query_params,
        date_range: [
          createdAtFrom ? getMomentInstance(createdAtFrom) : null,
          createdAtTo ? getMomentInstance(createdAtTo) : null,
        ],
        isModalOpen: false,
      },
      currentStep: 0,

      initial_values_topup: {
        amount: '',
        paymentId: '',
        type: 1,
        image: '',
      },
    };
  }
  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleOk = () => {
    this.setState({ isModalOpen: false });
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
  };
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
    this.props.getProfitTransaction();
  }

  fetchData = ({ date_range = null, page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (date_range?.[0]) {
      params.createdAtFrom = getMomentInstance(date_range[0]).format(
        'YYYY-MM-DD'
      );
    }
    if (date_range?.[1]) {
      params.createdAtTo = getMomentInstance(date_range[1]).format(
        'YYYY-MM-DD'
      );
    }
    if (parseInt(params['type']) === 0) delete params['type'];
    if (parseInt(params['paymentMethod']) === 0) delete params['paymentMethod'];
    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getList({
      ...params,
    });
  };
  handleOnExport = (isExport = false) => {
    const paramsTemp = { ...this.props.transactions?.params };
    delete paramsTemp['page'];
    delete paramsTemp['perPage'];
    this.props.exportTransactions({
      export: 1,
      ...paramsTemp,
    });
  };

  handleShowDetail = (selected) => {
    this.setState({
      showDetail: true,
      selected,
    });
  };

  submitHandler = (values) => {
    this.setState({
      initial_values_topup: {
        amount: values?.amount,
        paymentId: values?.paymentId,
        type: values?.type,
        image: values?.image,
      },
    });

    if (this.state.currentStep < 1) {
      this.setState({ currentStep: this.state.currentStep + 1 });
    } else {
      this.props.sendRequestTopUp(this.state.initial_values_topup);
    }
  };

  submitRequestTopUp = () => {
    this.props.sendRequestTopUp(this.state.initial_values_topup);
  };
  backPrevForm = () => {
    this.setState({ currentStep: 0 });
  };
  render() {
    const {
      transactions,
      profitTransaction,
      user,
      pageName,
      exportLoading,
      profile,
    } = this.props;
    const { initial_filter_values } = this.state;

    const columns = [
      {
        title: <b>TRANSACTION CODE</b>,
        dataIndex: 'name',
        align: 'center',
        key: 'name',
        render: (value, record) => (
          <Link
            type="button"
            to={`/billing/transactions/${record.id}/detail`}
            className="mr-1"
          >
            {value}
          </Link>
        ),
      },
      // {
      //   title: <b>PAYMENT TYPE</b>,
      //   dataIndex: "type",
      //   key: "type",
      //   render: (value) =>
      //     getOptionLabel(options_transaction_type, value, false),
      // },
      {
        title: <b>PAYMENT MEDTHOD</b>,
        dataIndex: 'paymentMethod',
        align: 'center',
        key: 'paymentMethod',
        render: (value) =>
          getOptionLabel(options_payment_method, value, false, value),
      },
      {
        title: <b>AMOUNT</b>,
        dataIndex: 'amount',
        align: 'center',
        key: 'amount',
        render: (value) => money(value),
      },
      {
        title: <b>STATUS</b>,
        key: 'status',
        align: 'center',
        dataIndex: 'status',
        render: (value) => getOptionLabel(options_payment_status, value),
      },
      {
        title: <b>CREATED DATE</b>,
        dataIndex: 'createdAt',
        align: 'center',
        key: 'createdAt',
        render: (text) =>
          text ? getMomentInstance(text, 0, true, false) : '',
      },
      {
        title: <b>ACTION</b>,
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <div>
            <Link
              to={`/billing/transactions/${record.id}/detail`}
              className="mr-1"
            >
              Detail
            </Link>
            <RenderQuickViewTransaction transaction={record} />
          </div>
        ),
      },
    ];
    const initialValuesRequestTopUp = {
      amount: '',
      paymentId: '',
      type: 1,
      image: '',
    };

    const steps = [
      <>
        <Step1
          onSubmit={this.submitHandler}
          initialValues={
            this.state.initial_values_topup
              ? this.state.initial_values_topup
              : initialValuesRequestTopUp
          }
          profile={profile}
        />
      </>,
      <>
        <Step2
          onSubmit={this.submitRequestTopUp}
          initialValues={this.state.initial_values_topup}
          summary={this.state.initial_values_topup}
        />
      </>,
    ];

    return (
      <Wrapper
        pageName={pageName}
        noContainer
        ctas={
          <>
            <GMButton
              type="button"
              onClick={() => {
                this.handleOnExport();
              }}
            >
              <FontAwesomeIcon className="mr-2" icon={faDownload} />
              Export
            </GMButton>
          </>
        }
      >
        <div className="filter">
          <FormFilter
            initialValues={initial_filter_values}
            onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
            handleOnExport={this.handleOnExport}
            handleAdvancedSubmit={this.fetchData}
            is_mobile={this.props.is_mobile}
            transactions={transactions}
          />
        </div>
        <Spin spinning={exportLoading}>
          <div className="tabs">
            <GeneralInfo
              typeActive={transactions.params.type}
              onChangeType={(type) =>
                this.fetchData({ ...transactions.params, type })
              }
              profitTransaction={profitTransaction}
              user={user}
            />
          </div>
          <div className="main-content">
            <Table
              rowKey="id"
              columns={columns}
              dataSource={transactions.data}
              loading={transactions.loading}
              scroll={{ x: 1000 }}
              pagination={
                !this.props.is_mobile
                  ? {
                    showTotal: (total) => `Total ${total} items`,
                    total: transactions.total,
                    pageSize: parseInt(
                      transactions.params.perPage > transactions.total
                        ? transactions.total
                        : transactions.params.perPage
                    ),
                    current: transactions.params.page,
                    showSizeChanger: true,
                    onChange: (page, perPage) =>
                      this.fetchData({
                        ...transactions.params,
                        page,
                        perPage,
                      }),
                  }
                  : false
              }
            />
            {this.props.is_mobile && (
              <div className="d-flex justify-content-end align-items-center py-3 px-3">
                <div>
                  <Pagination
                    size="small"
                    showSizeChanger={true}
                    className="pagination"
                    total={transactions.total}
                    defaultCurrent={transactions?.params?.page || 1}
                    pageSize={transactions?.params?.perPage || 0}
                    onChange={(page, perPage) =>
                      this.fetchData({
                        ...transactions.params,
                        page:
                          page === Number.NEGATIVE_INFINITY
                            ? 1
                            : page === 0
                              ? 1
                              : page,
                        perPage,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </Spin>
        <Modal
          title={
            <div>
              {this.state.currentStep === 1 ? (
                <div className="back" onClick={this.backPrevForm}>
                  <ArrowLeft />
                  Back
                </div>
              ) : (
                ''
              )}
              <div>Top up</div>
            </div>
          }
          visible={this.state.isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="topup-modal"
          footer={false}
        >
          {steps[this.state.currentStep]}
        </Modal>
        <GlobalStyle />
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    // transactionsQuickView: state.transaction.list.transactions,
    transactions: state.transaction.list.transactions,
    exportLoading: state.transaction.list.export.loading,
    profitTransaction: state.transaction.list.profits,
    filterTransactionValues: state.form.formFilterTransaction?.values,
    valueTopUpStep1: state.form.Form?.values,
    profile: state.auth.info.profile,
  }),
  (dispatch) => ({
    getList: (params) => {
      dispatch(getList(params));
    },
    getListQuickView: (id) => {
      dispatch(getListQuickView(id));
    },
    getProfitTransaction: () => {
      dispatch(getProfitTransaction());
    },
    exportTransactions: (params) => {
      dispatch(exportTransaction(params));
    },
    sendRequestTopUp: (dataToRequest) => {
      dispatch(sendRequestTopUp(dataToRequest));
    },
  })
)(List);
