import React, { useEffect } from "react";
import BillingInforList from "./components/BillingPaymentAccountList";
import { Card, Spin } from "antd";
import BillingPaymentAccountForm from "./components/BillingPaymentAccountForm";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { Wrapper } from "./styled";

// dispatcher
function BillingInfo({ pageName }) {
  const isLoading = useSelector((state) => state.billingPaymentAccount.loading);
  const history = useHistory();
  // useEffect(() => {
  //   const params = queryString.parse(
  //     window.location.search
  //   );

  //   if (params?.pid && params?.token) {
  //     history.replace('/billings/payment-method?' + queryString.stringify({ ...params, payoneerStatus: 1 }));
  //   }
  // }, []);
  useEffect(() => {
    // ?isPaypalStatus=1&&code=CUS1&token=EC-8BU40953K35349023
    // http://localhost.io:3000/billings/payment-method?isPaypalStatus=1&&code=CUS1&token=EC-4AJ15281GG504730F
    const params = queryString.parse(
      window.location.search
    );
    // re-direct
    if (Object?.keys(params)?.length > 0) {
      history.replace('/billing/method/new?' + queryString.stringify(params));
    } else {
      history.replace('/billing/method');
    }
  }, []);

  return null;
  return (
    <Wrapper pageName={pageName} forcePathName={pageName}>
      <Spin spinning={isLoading}>
        <Card className="mb-3">
          <BillingInforList />
        </Card>
        <Card>
          <BillingPaymentAccountForm initialValues={{
            billingInfoType: 2,
            paymentMethod: window.location.search.indexOf('payoneerStatus=') > -1 ? 1 : window.location.search.indexOf('isPaypalStatus=') > -1 ? 0 : 3,
            paymentAccountId: 0,
            isPrimary: 0,
            billingInforId: '-1',
            firstName: '',
            lastName: '',
            address: '',
            address2: '',
            city: '',
            province: '',
            postalCode: '',
            country: '231',
            cardNumber: "",
            cardholderName: "",
            cvv: "",
            expirationDate: "",
            nonce: ""
          }}
          />
        </Card>
      </Spin>
    </Wrapper>
  );
}

export default React.memo(BillingInfo);