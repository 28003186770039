import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "./components/form";
import { Button, Spin } from "antd";
import BackToIcon from "../../../../images/chevron-down.svg";
import { createPromotions } from "./actions";
import { options_delivery, options_active_webhooks } from "utils/options";

export class index extends Component {

  onCreateFormSubmit = (values) => {
    const dataToCreate = {
      name: values.name_webhooks ? values.name_webhooks : "",
      url: values.url_webhooks ? values.url_webhooks : "",
      status: values.webhooks_status
        ? values.webhooks_status
        : options_active_webhooks[0]["value"],
      topic: values.webhooks_topic
        ? values.webhooks_topic
        : options_delivery[0]["value"],
    };
    this.props.createPromotions(dataToCreate, this.props.currStore);
  };

  render() {
    const { creating, pageName } = this.props;
    const initialValues = {
      name_webhooks: "",
      webhooks_topic: options_delivery[0]["value"],
      url_webhooks: "",
      webhooks_status: options_active_webhooks[0]["value"],
    };
    return (
      <div className="container-fluid">
        <Button
          className="BackToButton d-flex align-items-center"
          onClick={() => {
            this.props.history.push("/promotions");
          }}
        >
          <img src={BackToIcon}  alt="back-icon"/>
          <p className="m-0 ml-2">Back to Promotions</p>
        </Button>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <h1 className="h3 mb-0 font-weight-bold">{pageName}</h1>
        </div>
        <Spin spinning={creating}>
          <div >
            <div>
              <Form
                initialValues={initialValues}
                onSubmit={this.onCreateFormSubmit}
              ></Form>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  creating: state.promotion.create.creating,
  // currStore: state.store.listStore.currStore,
});

const mapDispatchToProps = (dispatch) => ({
  createPromotions: (dataToCreate) => {
    dispatch(createPromotions(dataToCreate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
