import React from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { renderRadioAnt, renderSwitchAnt } from 'utils/Form';
import SelectStoreUserlabel from 'utils/Form/Search/SelectStoreUserlabel';
import { options_store } from 'utils/options';
import validate from './validate';
import { GMButton } from 'components/Button';
import SearchGooglePlaces from 'utils/Form/SearchGooglePlaces';
import { GMTextbox } from 'components/InputFields';
import GMStateSelector from 'components/GMStateSelector';

const FormCreateUserlabel = ({
  allStore,
  changeFieldValue,
  handleSubmit,
  listStore,
  _default,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row mt-3">
        <div className="col-sm-12">
          <div className="form-group">
            <GMTextbox
              name="default"
              component={renderRadioAnt}
              classNameItem={'d-block'}
              options={options_store}
            />
          </div>
        </div>
        {_default === 'no' ? (
          <div className="col-sm-12">
            <div className="form-group">
              <label className="col-form-label font-weight-bold">
                Apply configuration return address to store
              </label>
              <Field
                name="storeIds"
                component={SelectStoreUserlabel}
                placeholder="-- Select store--"
                list_store={[
                  ...listStore.map((item) => ({
                    label: (
                      <p style={{ color: '#00a8ff' }} className={'my-0'}>
                        {(item?.backendType || '').toUpperCase() +
                          ' - ' +
                          (item.showName || '') +
                          ' - ' +
                          (item.url || '')}
                      </p>
                    ),
                    value: item.id,
                  })),
                ]}
                disabled={allStore}
              />
              <div className="mt-2">
                <Field
                  name="allStore"
                  isShowConfirmPopup={true}
                  confirmContent={
                    'Are you sure to apply this configuration to all stores ?'
                  }
                  component={renderSwitchAnt}
                  suffix={<span>Apply return address to all stores</span>}
                />
                <p className="my-0 mt-2">
                  <i>
                    * Choose this if you want to apply this return address
                    configuration to all stores.
                  </i>
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Company <span className="text-muted">(Optional)</span></label>
            <GMTextbox name="company" placeholder="Enter company" />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Phone number</label>
            <GMTextbox
              name="phone"
              maxLength={'30'}
              placeholder="Enter phone number"
              showError
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Address 1</label>
            <Field
              name="addressOne"
              placeholder="Enter address 1"
              component={SearchGooglePlaces}
              requiredUS={true}
              onChangeCityName={(cityName) => {
                changeFieldValue('city', cityName);
              }}
              onChangeStateName={(state) => {
                changeFieldValue('state', state);
              }}
              onChangeZip={(zip) => {
                changeFieldValue('code', zip);
              }}
              showError
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Address 2 <span className="text-muted">(Optional)</span></label>
            <GMTextbox name="addressTwo" placeholder="Enter address 2" />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">City</label>
            <GMTextbox name="city" placeholder="Enter city" showError />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">State/Province</label>
            <GMStateSelector
              name="state"
              placeholder="Enter state/province"
              showError
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Zip code/Postal code</label>
            <GMTextbox
              name="code"
              placeholder="Enter zip code/postal code"
              showError
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Country</label>
            <GMTextbox
              disabled={true}
              name="country"
              placeholder="Enter country"
              showError
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mb-3">
        <GMButton>Create</GMButton>
      </div>
    </form>
  );
};

FormCreateUserlabel.propTypes = {};
const selector = formValueSelector('formCreateUserlabel');
const formCreateUserlabelForm = reduxForm({
  form: 'formCreateUserlabel',
  enableReinitialize: true,
  destroyOnUnmount: true,
  validate,
})(FormCreateUserlabel);


export default connect(
  (state) => ({
    allStore: selector(state, 'allStore'),
    _default: selector(state, 'default'),
  }),
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change('formCreateUserlabel', field, value));
    },
  })
)(formCreateUserlabelForm);
