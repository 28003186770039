import {
    takeLatest,
    call,
    put,
    all
} from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { actionType } from "./actions";
import { findOrderToDuplicateOrderFBA, duplicateFBAOrder } from 'apis/orders'

function* findOrderToDuplicateOrderFbaSaga(action) {
    const { id, cb } = action
    try {
        const response = yield call(findOrderToDuplicateOrderFBA, id)
        if (response.code === 'success') {
            yield all([
                put({ type: actionType.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.SUCCESS, ...response }),
            ])
        } else {
            yield all([
                put({ type: actionType.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.ERROR, error: response }),
                put(replace("/orders/list"))
            ])
        }
    } catch (error) {
        yield all([
            yield put({ type: actionType.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.ERROR, error })
        ])
    } finally {
        if (typeof cb === 'function') {
            cb();
        }
    }
}

function* duplicateFBAOrderSaga(action) {
    const { data, cb } = action
    try {
        const response = yield call(duplicateFBAOrder, data)
        if (response.code === 'success') {
            yield all([
                put({ type: actionType.DUPLICATE_FBA_ORDER.SUCCESS, ...response }),
                put(replace('/orders/list'))
            ])
        } else {
            yield put({ type: actionType.DUPLICATE_FBA_ORDER.ERROR, error: response })
        }
    } catch (error) {
        yield all([
            yield put({ type: actionType.DUPLICATE_FBA_ORDER.ERROR, error })
        ])
    } finally {
        if (typeof cb === 'function') {
            cb();
        }
    }
}


function* duplicateOrderFbaWatcher() {
    yield takeLatest(actionType.FIND_ORDER_TO_DUPLICATE_FBA_ORDER.REQUEST, findOrderToDuplicateOrderFbaSaga);
    yield takeLatest(actionType.DUPLICATE_FBA_ORDER.REQUEST, duplicateFBAOrderSaga);
}

export default duplicateOrderFbaWatcher