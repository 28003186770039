import PageContainer from 'components/PageContainer';
import { ERROR_600 } from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled(PageContainer)`
  .cta-container {
    button {
      max-width: 200px;
      width: 100%;
      float: right;
    }
  }
  svg.bullet {
    stroke: ${ERROR_600};
    width: 17px;
    height: 17px;
    margin-right: 17px;
  }

  .gp2 {
    margin-top: 30px;
  }
`;
