import React from "react";
import ConfirmDialog from "components/Modal/ConfirmDialog";
import { ReactComponent as IconInfo } from "images/icons/info-circle.svg";
import { ConfirmModalInnerWrapper } from "./styled";
import { GMButton } from "components/Button";
import { Spin } from "antd";

export default ({
  onOk,
  onCancel,
  isLoading = false,
  text: { ok, cancel, header, content },
  ...props
}) => {
  return (
    <ConfirmDialog
      ctas={
        <>
          <GMButton
            type="button"
            color="SecondaryGray"
            onClick={onCancel}
            disabled={isLoading}
          >
            {cancel || "Cancel"}
          </GMButton>
          <GMButton type="button" onClick={onOk} disabled={isLoading}>
            {ok || "Ok"}
          </GMButton>
        </>
      }
      {...props}
    >
      <Spin spinning={isLoading}>
        <ConfirmModalInnerWrapper>
          <div className="icon">
            <div className="wrapper">
              <IconInfo />
            </div>
          </div>
          <div className="content">
            <h4 className="header">{header}</h4>
            <div className="msg">{content}</div>
          </div>
        </ConfirmModalInnerWrapper>
      </Spin>
    </ConfirmDialog>
  );
};
