import { actionType as TYPE } from "./actions";

const initialState = {
  schedule_tasks: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        schedule_tasks: {
          ...initialState.schedule_tasks,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        schedule_tasks: {
          ...state.schedule_tasks,
          loading: false,
          data:
            action.data && action.data.scheduleTasks
              ? action.data.scheduleTasks
              : [],
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        schedule_tasks: {
          ...state.schedule_tasks,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
