import {
  BASE_WHITE,
  GRAY_200,
  GRAY_900,
  SHADOW_SM_0_1,
  SHADOW_SM_0_06,
  PRIMARY_500,
  GRAY_600,
  PRIMARY_600,
} from "components/colors";
import { BREAKPOINTS } from "components/const";
import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  h2.header {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: ${GRAY_900};
    margin: 0;
    margin-top: 20px;
  }
  div.balance,
  .main-content {
    margin-top: 20px;
    border-top: none;
    padding: 0;
    background: unset;
    box-shadow: unset;
    border: none;
  }
  .ant-card {
    border-radius: 12px;
  }
  > .ant-card-body {
    width: 100%;
  }
  .col {
    justify-content: space-between;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    .iconSet {
      margin-bottom: 8px;
    }
    > .ant-card {
      width: 100%;
      max-height: 180px;
      @media only screen and (min-width: ${BREAKPOINTS.sm}) {
        max-width: calc(50% - 1rem);
      }
      @media only screen and (min-width: ${BREAKPOINTS.lg}) {
        max-width: calc(33.333% - 1rem);
      }
      @media only screen and (min-width: ${BREAKPOINTS.xl}) {
        max-width: calc(25% - 1rem);
      }
    }
  }
  .inner-wrapper {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    svg {
      stroke: ${PRIMARY_500};
      width: 24px;
      height: 24px;
    }
    a {
      display: flex;
      height: 136px;
      background: ${BASE_WHITE};
      border: 1px solid ${GRAY_200};
      box-shadow: 0px 1px 3px ${SHADOW_SM_0_1}, 0px 1px 2px ${SHADOW_SM_0_06};
      border-radius: 16px;
      padding: 26px;
      padding-bottom: 0;
      flex-direction: column;
      width: 100%;
      @media (min-width: ${BREAKPOINTS.ssx}) {
        max-height: 350px;
        width: calc((100% - 26px * 1) / 1);
      }
      @media (min-width: ${BREAKPOINTS.lg}) {
        max-height: 350px;
        width: calc((100% - 26px * 3) / 4);
      }
      &:hover {
        svg {
          stroke: ${PRIMARY_600};
          width: 24px;
          height: 24px;
        }
      }
      
      @media only screen and (min-width: ${BREAKPOINTS.xxl}) {
        gap: 16px;
      }
      @media only screen and (min-width: ${BREAKPOINTS.xxl}) {
        gap: 26px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      gap: 16px;
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: ${GRAY_900};
      }
    }
    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
    }
  }
`;