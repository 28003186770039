import { takeLatest, call, put, all} from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import {
  updateShipping,
  getListShipping,
  historyShipping,
} from "apis/general_settings";
function* updateShippingSaga(action) {
  try {
    const { dataToUpdateShipping } = action;
    const response = yield call(updateShipping, dataToUpdateShipping);

    if (response.code === "success") {
      yield all([
        put({ type: TYPE.UPDATE_SHIPPING.SUCCESS, response }),
        put({ type: TYPE.HISTORY_SHIPPING.REQUEST }),
      ]);
    } else {
      yield put({ type: TYPE.UPDATE_SHIPPING.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.UPDATE_SHIPPING.ERROR, error })]);
  }
}
function* getListShippingSaga() {
  try {
    const response = yield call(getListShipping);
    if (response.code === "success") {
      yield put({ type: TYPE.LIST_SHIPPING.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_SHIPPING.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_SHIPPING.ERROR, error })]);
  }
}
//History Shipping
function* historyShippingSaga() {
  try {
    const response = yield call(historyShipping);
    if (response.code === "success") {
      yield put({ type: TYPE.HISTORY_SHIPPING.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.HISTORY_SHIPPING.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.HISTORY_SHIPPING.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.UPDATE_SHIPPING.REQUEST, updateShippingSaga);
  yield takeLatest(TYPE.LIST_SHIPPING.REQUEST, getListShippingSaga);
  yield takeLatest(TYPE.HISTORY_SHIPPING.REQUEST, historyShippingSaga);
}

export default watcher;
