import React from 'react';
import TopUpStep2 from './styled';
import { reduxForm } from 'redux-form';
import { GMButton } from 'components/Button';
import { METHOD_LIST } from 'constants/common';
import { ReactComponent as TooltipIcon } from 'images/icons/info-circle.svg';
import { Tooltip } from 'antd';

const Form = (props) => {
  const { handleSubmit, summary, accountsPaypal } = props;

  let payID = summary?.payId;

  const emailPaypal = accountsPaypal.filter((account) => {
    return account?.id === payID;
  });

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div>
      <TopUpStep2>
        {!summary?.payId ? (
          <form onSubmit={handleSubmit}>
            <div className="preview">
              <div className="item">
                <div>Reference Transaction ID</div>
                <div className="value">{summary?.paymentId}</div>
              </div>
              <div className="item">
                <div>Payment Method</div>
                <div className="value">
                  {
                    METHOD_LIST.filter(
                      (item) => item.value === summary?.type
                    )[0]?.name
                  }
                </div>
              </div>
              <div className="item">
                <div>Top up amount</div>
                <div className="value">
                  {USDollar.format(summary?.amount || 0)}
                </div>
              </div>
            </div>
            <div className="policy">
              By using Upfront Deposits you agree to Gearment's{' '}
              <a
                className="term"
                href="https://gearment.com/p/terms-of-services/"
                targer="_blank"
              >
                Terms of Service
              </a>
            </div>
            <div>
              <div className="next-step">
                <GMButton type="submit" size="lg" className="next-button">
                  Top up
                </GMButton>
              </div>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="preview">
              <div className="item">
                <div>Top up method</div>
                <div className="value">
                  <div className="value">
                    <b>Paypal</b>-({emailPaypal[0].email})
                  </div>
                </div>
              </div>
              <div className="item">
                <div>Top up amount</div>
                <div className="value">
                  {USDollar.format(summary?.amount || 0)}
                </div>
              </div>
              <div className="item processing-fee">
                <div>
                  Processing Fee{' '}
                  <Tooltip title="PayPal transactions will incur a processing fee of (5% of the Total charge + $0.49) per transaction.">
                    {' '}
                    <TooltipIcon />
                  </Tooltip>
                </div>
                <div className="value">
                  {USDollar.format(summary?.fee || 0)}
                </div>
              </div>
              <div className="item">
                <div>
                  <b>Total Charge</b>
                </div>
                <div className="value">
                  <b>{USDollar.format(summary?.total || 0)}</b>
                </div>
              </div>
            </div>
            <div className="policy">
              By clicking on “Top up”, you confirm that{' '}
              <b>{USDollar.format(summary?.total || 0)}</b> will be charged to
              your Paypal account ({emailPaypal[0].email}) and{' '}
              <b>{USDollar.format(summary?.amount || 0)}</b> will be deposited
              into your G-Wallet.
            </div>
            <div>
              <div className="next-step">
                <GMButton type="submit" size="lg" className="next-button">
                  Top up
                </GMButton>
              </div>
            </div>
          </form>
        )}
      </TopUpStep2>
    </div>
  );
};

Form.propTypes = {};

export default reduxForm({
  form: 'TopUpStep2',
  enableReinitialize: true,
})(Form);
