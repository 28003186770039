import DataFeedList from 'scenes/DataFeed/scenes/List'
import DataFeedAmazon from 'scenes/DataFeed/scenes/Amazon'
import DataFeedAmazonEdit from 'scenes/DataFeed/scenes/AmazonEdit'
import DataFeedGoogle from 'scenes/DataFeed/scenes/Google'
import DataFeedGoogleEdit from 'scenes/DataFeed/scenes/GoogleEdit'

export default [
    // {
    //     key: 'data-feed.list',
    //     name: 'Data Feed',
    //     component: DataFeedList,
    //     path: '/data-feed/list',
    //     group: 'data_feed',
    //     icon: null,
    //     template: 'default',  
    //     isPublic: false,
    //     permissions: ["viewFeedPermission"]
    // },
    {
        key: 'products.data-feed.amazon',
        name: 'Amazon',
        component: DataFeedAmazon,
        path: '/products/data-feed/amazon',
        group: 'data_feed',
        icon: null,
        template: 'default',
        isPublic: false,
        permissions: ["createFeedPermission"]
    },
    //New Data ListReport
    {
        key: 'products.data-feed.amazon',
        name: 'Amazon Feed',
        component: DataFeedAmazonEdit,
        path: '/products/data-feed/:id/amazon',
        group: 'data_feed',
        icon: null,
        template: 'default',
        isPublic: false,
        permissions: ["viewFeedPermission"]
    },
    {
        key: 'products.data-feed.google',
        name: 'Google Feed',
        component: DataFeedGoogle,
        path: '/products/data-feed/google',
        group: 'data_feed',
        icon: null,
        template: 'default',
        isPublic: false,
        permissions: ["createFeedPermission"]
    },

    {
        key: 'products.data-feed.google',
        name: 'Google Feed',
        component: DataFeedGoogleEdit,
        path: '/products/data-feed/:id/google',
        group: 'data_feed',
        icon: null,
        template: 'default',
        isPublic: false,
        permissions: ["viewFeedPermission"]
    },
]