import { actionType as TYPE } from "./actions";
import {notification} from "antd";

const initialState = {
    loading: false
};

function SyncCampaignReducer(state = initialState, action) {
    switch (action.type) {
        case TYPE.SYNC_CAMPAIGN.REQUEST:
            return {
                ...state,
                loading: true
            };
        case TYPE.SYNC_CAMPAIGN.SUCCESS:
            if(action.errors && Array.isArray(action.errors)){
                for(let index = 0; index < action.errors.length; index++){
                    if(action.errors[index].code === 'sync_campaign_success'){
                        notification.success({message: action.errors[index].msg, duration: 5})
                    }else{
                        notification.warn({message: action.errors[index].msg, duration: 5})
                    }
                }
            }
            return {
                ...state,
                loading: false
            };
        case TYPE.SYNC_CAMPAIGN.ERROR:
            notification.warn({
                message: 'Sync campaign failed.',
                duration: 5
            });
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}

export default SyncCampaignReducer;
