export const actionType = {
    DETAIL: {
        REQUEST: 'MANUAL.DETAIL.REQUEST',
        SUCCESS: 'MANUAL.DETAIL.SUCCESS',
        ERROR: 'MANUAL.DETAIL.ERROR',
    },
};

export function getDetail(id) {
    return {
        type: actionType.DETAIL.REQUEST,
        id
    }
}