import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { searchCampaignsToExport } from "apis/campaign";
import classNames from "classnames";
const { Option } = Select;

export default ({
  input,
  mode = "default",
  invalid,
  meta: { error },
  size,
  classNameContainer = "",
  arrayPush = null,
  listCampaigns=[],
  placeholder="Search products",
  campaigns = []
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword]  = useState("");
  useEffect(() => {
    if (keyword && keyword.length >= 2) {
      setLoading(true);
      searchCampaignsToExport({
          name: keyword
      })
        .then((result) => {
          setLoading(false);
          if (result.code === "success" && result?.data?.campaigns) {
            setOptions([
              ...(result?.data?.campaigns || []).map((item) => ({
                ...item,
              })),
            ]);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [keyword]);
  const onSelect = (data) => {
    const selectedCampaign = options.find(item => parseInt(item.id) === parseInt(data));
    if(selectedCampaign && !listCampaigns.find(campaign => parseInt(campaign.id) === parseInt(data))){
      arrayPush("listCampaigns", options.find(item => parseInt(item.id) === parseInt(data)))
    }
    if(!campaigns.find(campaignId => parseInt(campaignId) === parseInt(data))){
      arrayPush("campaigns", data);
    }
  };
  return (
    <div className={classNames("position-relative", classNameContainer)}>
      <Select
        loading={loading}
        showSearch
        mode={mode}
        value={input.value || []}
        style={{ width: "100%" }}
        size={size || "large"}
        placeholder={placeholder}
        onChange={onSelect}
        filterOption={false}
        onSearch={(k) => {
          if (k !== null && k.length >= 2) setKeyword(k);
        }}
        optionFilterProp="children"
      >
        {options.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              <div className="d-flex justify-content-start align-items-center">
                  {item?.productCampaigns && item?.productCampaigns[0] && item?.productCampaigns[0]['designLink'] && 
                  <img src={item?.productCampaigns[0]['designLink']} style={{width: "60px"}} alt=""/>}
                  <h6 className="ml-2">
                  {item.name}
                  </h6>
              </div>
            </Option>
          )
        })}
      </Select>
      {invalid && (
        <hr
          className="border-danger m-0 position-absolute"
          style={{ width: "90%", left: "5%", top: size === "middle" ? 31 : 39 }}
        />
      )}
      {error && <span className="invalid-feedback">{error}</span>}
    </div>
  );
};
