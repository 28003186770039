import { BASE_BLACK, BASE_WHITE, GRAY_600, GRAY_900 } from 'components/colors';
import PageContainer from 'components/PageContainer';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  .ant-modal-content {
    background: ${BASE_WHITE};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;    
  }
  .ant-modal-header{
    border-bottom:none;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

 .ant-modal-title {
  .title{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

    }

  .ant-modal-body{
    padding-top: 0px;
  }

  .back {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    /* identical to box height, or 143% */

    /* Gray/600 */

    color: #475467;
    svg {
      width: 20px;
      height: 20px;
      stroke: ${GRAY_600};
      margin-right: 12px;
    }
  }


`;
export const Wrapper = styled(PageContainer)`
  h2.header {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: ${GRAY_900};
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .more-action {
    svg {
      width: 20px;
      height: 20px;
      stroke: ${GRAY_600};
      margin-right: 10px;
    }
  }
  .connect-icon {
    svg {
      width: 20px;
      height: 20px;
      stroke: ${BASE_WHITE};
      margin-right: 12px;
    }
  }
`;

export const WrapperIcon = styled.div`
  svg {
    width: 20px;
    height: 20px;
    stroke: ${GRAY_600};
    margin-right: 10px;
  }
`;
export const WrapperIconTooltip = styled.div`
  svg {
    width: 15px;
    height: 15px;
    stroke: ${GRAY_600};
    margin-right: 10px;
  }
  color: ${GRAY_600};
`;

export const MoreActionStore = styled.div`
  .pointer {
    cursor: pointer;
  }
`;
export const WrapperAction = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  color: black;
  cursor: pointer;
  .delete {
    color: red;
  }
  .goto-shop {
    color: ${BASE_BLACK};
  }
`;

export const WrapperUpdateButton = styled.div`
  .saveBtn {
    max-width: 100%;
    margin-top: 12px;
    width: 666px;
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    /* Gray/600 */

    color: #475467;
  }
`;
