import { isValidUrl } from "utils/helper";

const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.replace(/\s+/g, "").length === 0) {
    errors.name = "This field cannot be left blank";
  }
  if (!values.showName || values.showName.replace(/\s+/g, "").length === 0) {
    errors.showName = "This field cannot be left blank";
  }
  if (
    !values.checkboxTerm ||
    (Array.isArray(values.checkboxTerm) && values.checkboxTerm.length === 0)
  ) {
    errors.checkboxTerm = "Please choose Amazon marketplace";
  }
  if (!values.url) {
    errors.url = "This field cannot be left blank";
  } else if (
    !values.url.includes("https://etsy.com/shop/") ||
    !isValidUrl(values.url)
  ) {
    errors.url = "The format of url is invalid.";
  }
  return errors;
};

export default validate;
