import React, { Component } from "react";
import { connect } from "react-redux";
import { Pagination, Table } from "antd";
import queryString from "query-string";
import getMomentInstance from "utils/moment";
import { getList } from "./actions";
import FormFilter from "./components/FormFilter";
import money from "utils/money";
class List extends Component {
  constructor(props) {
    super(props);
    const { from, to, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      showDetail: false,
      selected: null,
      initial_filter_values: {
        sortBy: "id",
        sortType: "desc",
        group: "customer",
        ...query_params,
        date_range: [
          from ? getMomentInstance(from) : null,
          to ? getMomentInstance(to) : null,
        ],
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ date_range, page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (date_range) {
      params.from = date_range[0] && date_range[0].format("YYYY-MM-DD");
      params.to = date_range[1] && date_range[1].format("YYYY-MM-DD");
    }

    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getList({
      ...params,
    });
  };
  handleShowDetail = (selected) => {
    this.setState({
      showDetail: true,
      selected,
    });
  };
  render() {
    const { report_inkgos, pageName } = this.props;
    const { initial_filter_values } = this.state;

    let columns = [
      {
        title: <b>ORDERS</b>,
        dataIndex: "numberOfOrders",
        align: "center",
        key: "numberOfOrders",
        render: (value) => money(value, false),
      },
      {
        title: <b>QUANTITY</b>,
        dataIndex: "quantity",
        align: "center",
        key: "quantity",
      },
      {
        title: <b>AMOUNT</b>,
        dataIndex: "numberOfItems",
        key: "numberOfItems",
        align: "center",
      },
      {
        title: <b>CASHBACK</b>,
        key: "cashback",
        dataIndex: "cashback",
        align: "center",
        render: (value) => money(value),
      },
    ];
    const customerColumn = {
      title: <b>ACCOUNT</b>,
      align: "center",
      dataIndex: "customerEmail",
      key: "customerEmail",
    };
    const productColumn = {
      title: <b>PRODUCT</b>,
      align: "center",
      dataIndex: "exproductName",
      key: "exproductName",
    };
    columns.unshift(
      report_inkgos.params.group === "product" ? productColumn : customerColumn
    );
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <h1 className="h3 mb-0 font-weight-bold">{pageName}</h1>
        </div>
        <div className="py-3">
          <FormFilter
            initialValues={initial_filter_values}
            onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
          />
        </div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={report_inkgos.data}
          loading={report_inkgos.loading}
          scroll={{ x: 1000 }}
          pagination={!this.props.is_mobile ? {
            showTotal: (total) => `Total ${total} items`,
            total: report_inkgos.total,
            pageSize: parseInt(
              report_inkgos.params.perPage > report_inkgos.params.total
                ? report_inkgos.params.total
                : report_inkgos.params.perPage
            ),
            current: report_inkgos.params.page,
            showSizeChanger: true,
            onChange: (page, perPage) =>
              this.fetchData({ ...report_inkgos.params, page, perPage }),
          }: false}
        />
        {this.props.is_mobile && <div className="d-flex justify-content-end align-items-center py-3 px-3">
          <div>
            <Pagination
              size="small"
              showSizeChanger
              className="pagination"
              total={report_inkgos.total}
              defaultCurrent={report_inkgos?.params?.page || 1}
              pageSize={report_inkgos?.params?.perPage || 0}
              onChange={(page, perPage) =>
                this.fetchData({ ...report_inkgos.params, page: page === Number.NEGATIVE_INFINITY ? 1 : (page === 0 ? 1 : page), perPage })
              }
            />
          </div>
        </div>}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    report_inkgos: state.order.report_inkgo.report_inkgos,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
  })
)(List);
