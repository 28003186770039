export const actionType = {
    SYNC_CAMPAIGN: {
        REQUEST: 'CAMPAIGN.SYNC_CAMPAIGN.REQUEST',
        SUCCESS: 'CAMPAIGN.SYNC_CAMPAIGN.SUCCESS',
        ERROR: 'CAMPAIGN.SYNC_CAMPAIGN.ERROR',
    }
};

export function syncCampaigns(data){
    return {
        type: actionType.SYNC_CAMPAIGN.REQUEST,
        data
    }
}

