export const actionType = {
  DETAIL_CONFIG_BUY_TRACKING: {
    REQUEST: 'DETAIL_CONFIG_BUY_TRACKING.REQUEST',
    SUCCESS: 'DETAIL_CONFIG_BUY_TRACKING.SUCCESS',
    ERROR: 'DETAIL_CONFIG_BUY_TRACKING.ERROR',
  },
  UPDATE_CONFIG_BUY_TRACKING: {
    REQUEST: 'UPDATE_CONFIG_BUY_TRACKING.REQUEST',
    SUCCESS: 'UPDATE_CONFIG_BUY_TRACKING.SUCCESS',
    ERROR: 'UPDATE_CONFIG_BUY_TRACKING.ERROR',
  },
};

export function detailConfigBuyTracking() {
  return {
    type: actionType.DETAIL_CONFIG_BUY_TRACKING.REQUEST,
  };
}

export function updateConfigBuyTracking(data) {
    return {
      type: actionType.UPDATE_CONFIG_BUY_TRACKING.REQUEST,
      data
    };
  }
