import React, { Component } from "react";
import { connect } from "react-redux";
import { Pagination, Table } from "antd";
import queryString from "query-string";
import { Link } from "react-router-dom";
import getMomentInstance from "utils/moment";
import {
  getListNotifyShipping,
  // markAsRead,
  // getListNotifyShippingNotiRead,
} from "./actions";
import { getOptionLabel, options_notification_status } from "utils/options";
import { Wrapper } from "scenes/general.styled";
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        sortBy: "id",
        sortType: "desc",
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getListNotifyShipping(params);
  };

  render() {
    const { notifications, pageName } = this.props;
    const columns = [
      {
        title: <b>TITLE</b>,
        dataIndex: "name",
        key: "name",
        width: 250,
        render: (value, record) => (
          <div>
            <Link to={`/statistics/shipping/${record.id}`}>{value}</Link>
          </div>
        ),
      },
      {
        title: <b>STATUS</b>,
        dataIndex: "status",
        align: "center",
        key: "status",
        width: 150,
        render: (value) =>
          getOptionLabel(options_notification_status, value, false),
      },
      {
        title: <b>CREATED TIME</b>,
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        width: 200,
        render: (text, record) => getMomentInstance(text, 0, true),
      },
    ];

    return (
      <Wrapper pageName={pageName}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={notifications.data}
          loading={notifications.loading}
          scroll={{ x: 1000 }}
          pagination={!this.props.is_mobile ? {
            showTotal: (total) => `Total ${total} items`,
            total: notifications.total,
            pageSize: parseInt(notifications.params.perPage),
            current: notifications.params.page,
            showSizeChanger: true,
            onChange: (page, perPage) =>
              this.fetchData({ ...notifications.params, page, perPage }),
            onShowSizeChange: (current, perPage) =>
              this.fetchData({ ...notifications.params, perPage }), //current * pageSize})  
          } : false}
        />
        {this.props.is_mobile && <div className="d-flex justify-content-end align-items-center py-3 px-3">
          <Pagination
            size="small"
            showSizeChanger
            className="pagination"
            total={notifications.total}
            defaultCurrent={notifications?.params?.page || 1}
            pageSize={notifications?.params?.perPage || 0}
            onChange={(page, perPage) =>
              this.fetchData({ ...notifications.params, page: page === Number.NEGATIVE_INFINITY ? 1 : (page === 0 ? 1 : page), perPage })
            }
          />
        </div>}
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    notifications: state.notification_shipping.list.notificationShipping,
    processing: state.notification.list.processing,
  }),
  (dispatch) => ({
    getListNotifyShipping: (options) => {
      dispatch(getListNotifyShipping(options));
    },
    // getListNotifyShippingNotiRead: (options) => {
    //   dispatch(getListNotifyShippingNotiRead(options));
    // },
    // markAsRead: () => {
    //   dispatch(markAsRead());
    // },
  })
)(List);
