import styled from "styled-components";
import { GMSelect } from "components/InputFields";
import { GRAY_400 } from "components/colors";

export const InputWrapper = styled.div`
  /* .ant-select-selection-item {
    font-size: 14px;
    height: 100%;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }
  input {
    box-shadow: none;
  } */
  width: 100%;
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-selection-placeholder {
    /* color: ${GRAY_400}; */
    color: #757575;
  }
`;
