import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import Transaction from './Transaction';
import queryString from 'query-string';
import PaymentQueue from './PaymentQueue';
import { useSelector } from 'react-redux';
import Forbidden from 'scenes/Errors/scenes/Forbidden';

export default (prosp) => {
  const params = queryString.parse(window.location.search);
  const profile = useSelector((state) => state.auth.info.profile);
  return (
    <div className="tabs">
      <Tabs defaultActiveKey={params?.tab} destroyInactiveTabPane>
        <Tabs.TabPane tab="Transactions history" key="transaction">
          {profile?.viewTransactionPermission === 0 ? (
            <Forbidden />
          ) : (
            <Transaction />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Payment queue" key="payment">
          <PaymentQueue />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
