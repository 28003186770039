import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Button } from "antd";
import { getDetailGoogle, updateDataFeedGoogle } from "./actions";
import Form from "./components/form";
import feed_google from "assets/images/feed_google.png";
import arrow from "assets/images/arrow.png";
import google_icon from "assets/images/google_icon.png";
import { Wrapper } from "scenes/DataFeed/styled";
import GMHelmet from "components/GMHelmet";
import { postfix } from "utils/options";

class Google extends Component {
  componentDidMount() {
    this.props.getDetailGoogle(this.props.match.params.id);
  }

  handleSubmit = (values) => {
    this.props.updateDataFeedGoogle(this.props.match.params.id, {
      ...values,
      default: values.default ? 1 : 0,
      status: values.status ? 1 : 0,
    });
  };
  render() {
    const { loading, detail, pageName } = this.props;
    return (
      <Wrapper
        pageName={pageName}
        breadcrumDetails={{
          [detail?.id]: detail?.id && `#${detail?.id || ''}`,
        }}
      >
        {detail?.id ? (
          <GMHelmet
            overrideTitle={`Edit Feed Form Google #${detail?.id || ''}` + postfix}
          />
        ) : null}
        <Spin spinning={loading || detail.loading}>
          <div className="text-center mb-3">
            <img src={feed_google} height="90" alt="google-icon" />
            <img
              src={arrow}
              width="40"
              className="mx-4"
              alt="arrow-icon"
            />
            <img src={google_icon} height="70" alt="google-icon" />
          </div>
          <Form onSubmit={this.handleSubmit} initialValues={detail} />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    detail: state.data_feed.google_edit.detail,
    loading: state.data_feed.google_edit.loading,
  }),
  (dispatch) => ({
    updateDataFeedGoogle: (id, data) => {
      dispatch(updateDataFeedGoogle(id, data));
    },
    getDetailGoogle: (id) => {
      dispatch(getDetailGoogle(id));
    }
  })
)(Google);