const validate = (values) => {
  const errors = {};
  if (!values.showName || values.showName.replace(/\s+/g, "").length === 0) {
    errors.showName = "This field cannot be left blank";
  }
  if (!values.name || values.name.replace(/\s+/g, "").length === 0) {
    errors.name = "This field cannot be left blank";
  }

  if (!values.authToken || values.authToken.replace(/\s+/g, "").length === 0) {
    errors.authToken = "This field cannot be left blank";
  }
  if (!values.amazonMarketPlaceId || (Array.isArray(values.amazonMarketPlaceId) && values.amazonMarketPlaceId.length === 0)) {
    errors.amazonMarketPlaceId = "Please choose Amazon marketplace";
  }
  return errors;
};
export default validate;
