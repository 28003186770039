import React, { Component } from 'react';
import { Upload, Spin, Button, notification, Modal } from 'antd';
import { getPresignedUrlS3PDF } from 'apis/image_design';
import { uploadLabel } from 'apis/orders';
import axios from 'axios';
import classNames from 'classnames';
import { GlowingSVG } from 'components/SVG';
import { ReactComponent as UploadIcon } from 'images/icons/upload-cloud-02.svg';
import { GRAY_100, GRAY_50, GRAY_600 } from 'components/colors';
import { Wrapper } from './styled';
import { API_TOKEN_KEY, S3_CDN } from 'web.config';
import { createFileName, createFolderName, getCookie } from 'utils/helper';
import getMomentInstance from 'utils/moment';
import { change } from 'redux-form';
import { connect } from 'react-redux';

class RenderUploadMultipleBarcodeFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
    };
  }

  render() {
    const {
      data = {},
      accept = '.pdf',
      pushArray = null,
      removeArray = null,
      children,
      paramName = 'file',
      url,
      fields,
      meta: { error, submitFailed },
      cusId = 0,
      prefixName = 'barcode',
      customPrefix = 'tmp',
      isUploadShippingLabelRequired = true,
      shippingService,
      setListFiles,
    } = this.props;
    const props = {
      accept: accept,
      name: paramName,
      multiple: true,
      action: url,
      headers: {
        // common: {
        Authorization: `Bearer ${getCookie(API_TOKEN_KEY) /* auth cookie */}`,
        // }
      },
      data,
      beforeUpload: (file) => {
        if (file.type !== 'application/pdf') {
          notification.warn({
            className: 'notic1',
            message: `${file.name} is not a pdf file.`,
          });
        }
        if (file.size > 2e8) {
          notification.warn({
            className: 'notic1',
            message: `${file.name} is larger than 200MB.`,
          });
        }
        if (shippingService !== 9) {
          this.props.setListFiles([]);
          this.props.clearFileIds();
        }
        return file.type === 'application/pdf' && file.size <= 2e8;
      },
      customRequest: async ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials,
      }) => {
        const folderName = createFolderName(cusId);
        const extension = file.name.split('.').pop();
        const fileName = createFileName('pdf', prefixName, cusId);
        const originalName = file.name.replace(`.${extension}`, '');
        const platform_id = 17;
        const fileType = `application/pdf`;
        const keyToCreatePresignedUrl = `${customPrefix}/${folderName}/${getMomentInstance(
          null,
          0,
          false,
          true
        )}/${fileName}`;
        const response = await getPresignedUrlS3PDF(keyToCreatePresignedUrl);
        if (response?.code === 'success') {
          axios
            .put(response.data.url, file, {
              headers: {
                'Content-Type': fileType,
              },
              onUploadProgress: (event) => {
                onProgress({ percent: (event.loaded / event.total) * 100 });
              },
            })
            .then((response) => {
              if (parseInt(response.status) === 200) {
                onSuccess(
                  {
                    code: 'success',
                    url: `${S3_CDN}/${keyToCreatePresignedUrl}`,
                    folderName: folderName,
                    fileName: fileName,
                    fileType,
                    extension,
                    originalName,
                    platform_id: shippingService !== 9 ? platform_id : '',
                  },
                  file
                );
              } else {
                onError();
              }
            });
        }
      },
      onChange: (info) => {
        setListFiles(
          info.fileList.filter(
            (file) =>
              file.status === 'error' ||
              file.status === 'done' ||
              file.status === 'uploading'
          )
        );
        const { status, response } = info.file;
        if (status === 'uploading') {
          this.setState({ loading: true });
        }
        const removeField = () => {
          const fieldValue = this.props?.fields?.getAll() || [];
          this.props.dispatch(
            change(
              'FormCreateOrderLabel',
              'barcodeFiles',
              fieldValue.filter((fV) => {
                return fV.name !== response.originalName + '.pdf';
              })
            )
          );
        };
        if (status === 'done' && response.code === 'success') {
          uploadLabel(
            response.url,
            response.originalName,
            response.platform_id
          ).then((response2) => {
            if (response2.code === 'success' && response2?.data?.barcode?.id) {
              notification.success({
                className: 'notic2',
                message: `${info.file.name} file uploaded successfully.`,
              });
              pushArray(response2.data.barcode.id);
            } else {
              notification.error({
                className: 'notic1',
                message: response2[0].clientMsg,
              });
              removeField();
            }
            this.setState({ loading: false });
          });
        } else if (status === 'error') {
          notification.error({
            className: 'notic1',
            message: `${info.file.name} file uploaded failed.`,
          });
          removeField();
          this.setState({ loading: false });
        }
      },
      onRemove: (file) => {
        const listFiles = fields.getAll() || [];
        listFiles.forEach((fileItem, fileIndex) => {
          if (fileItem.uid === file.uid) {
            fields.remove(fileIndex);
            removeArray(fileIndex);
          }
        });
      },
      showUploadList: true,
      progress: {
        strokeColor: {
          '0%': '#108ee9',
          '100%': '#87d068',
        },
        strokeWidth: 3,
        format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
      },
      fileList: fields.getAll(),
    };
    return (
      <Wrapper>
        {parseInt(shippingService) === 9 ||
        (parseInt(shippingService) !== 9 && isUploadShippingLabelRequired) ? (
          parseInt(shippingService) === 9 ? (
            <>
              {' '}
              <Upload {...props}>
                <Spin spinning={this.state.loading}>
                  <div className="upload">
                    <div className="upload-frame">
                      <GlowingSVG
                        backgroundColor={GRAY_100}
                        borderColor={GRAY_50}
                        svgStrokeColor={GRAY_600}
                      >
                        <UploadIcon />
                      </GlowingSVG>
                      <div className="text-upload">
                        <b className="click-to">Click to upload</b>
                        <br />
                        {parseInt(shippingService) !== 9 && (
                          <div>
                            Please upload vertical shipping labels with the size
                            of 4x6 inch.
                            <a
                              href="https://account.gearment.com/public/docs/label_example.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Label example
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Spin>

                {children}
              </Upload>{' '}
              {submitFailed && error && (
                <span className="invalid-feedback d-block">{error}</span>
              )}
            </>
          ) : (
            <>
              {' '}
              <Upload {...props} multiple={false}>
                <Spin spinning={this.state.loading}>
                  <div className="upload">
                    <div className="upload-frame">
                      <GlowingSVG
                        backgroundColor={GRAY_100}
                        borderColor={GRAY_50}
                        svgStrokeColor={GRAY_600}
                      >
                        <UploadIcon />
                      </GlowingSVG>
                      <div className="text-upload">
                        <b className="click-to">Click to upload</b>
                        <br />
                        Please upload vertical shipping labels with the size of
                        4x6 inch
                      </div>
                    </div>
                  </div>
                </Spin>

                {children}
              </Upload>{' '}
              {submitFailed && error && (
                <span className="invalid-feedback d-block">{error}</span>
              )}
            </>
          )
        ) : (
          <div
            className="upload"
            onClick={() => {
              this.setState({ visible: true });
            }}
          >
            <div className="upload-frame">
              <GlowingSVG
                backgroundColor={GRAY_100}
                borderColor={GRAY_50}
                svgStrokeColor={GRAY_600}
              >
                <UploadIcon />
              </GlowingSVG>
              <div className="text-upload">
                <b className="click-to">Click to upload</b>
                <br />
                Please upload vertical shipping labels with the size of 4x6 inch
              </div>
            </div>
          </div>
        )}

        <Modal
          centered
          footer={null}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          width={600}
          title={
            <p className="mb-0 text-center font-weight-bold font-size-20">
              Notification
            </p>
          }
        >
          <div className="text-center text-danger">
            <p>Shipping label can only be uploaded from Monday to Thursday</p>
            <p>
              You can continue to create order and upload shipping lable after
              we notify you
            </p>
          </div>
        </Modal>
      </Wrapper>
    );
  }
}
export default React.memo(
  connect(undefined, (dispatch) => ({ dispatch }))(
    RenderUploadMultipleBarcodeFile
  )
);
