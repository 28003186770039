import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { login } from '../Login/actions';
import { register } from './actions';
import Form from './components/form';
import { API_TOKEN_KEY } from 'web.config';
import { Wrapper } from './styled';
import GMHelmet from 'components/GMHelmet';
import { removeCookie } from 'utils/helper';

class Index extends Component {
  componentDidMount() {
    removeCookie(API_TOKEN_KEY) /* auth cookie */;
  }
  handleOnSubmitRegister = (values) => {
    this.props.register({ ...values, registerType: 'email' });
  };
  handleOnLogin = (values) => {
    this.props.login(values);
  };
  render() {
    const { loading, loginLoading } = this.props;
    return (
      <>
        <GMHelmet />
        <Wrapper className="bg-white w-100">
          <Spin spinning={loading || loginLoading}>
            <Form
              onLogin={this.handleOnLogin}
              onSubmit={this.handleOnSubmitRegister}
            />
          </Spin>
        </Wrapper>
      </>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.auth.register.register.loading,
    loginLoading: state.auth.login.loading,
  }),
  (dispatch) => ({
    register: (data) => {
      dispatch(register(data));
    },
    login: (post) => {
      dispatch(login(post));
    },
  })
)(Index);
