import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Pagination, Dropdown } from 'antd';
import { getOptionLabel, options_trademark } from 'utils/options';
import getMomentInstance from 'utils/moment';
import { getListUserlabel, deleteUserlabel } from './actions';
import queryString from 'query-string';
import { Wrapper } from 'scenes/general.styled';
import Filter from 'components/Filter';
import { GMButton } from 'components/Button';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { MoreActionStore } from 'scenes/Campaigns/scenes/List/styled';
import { ContextMenuWrapper } from 'scenes/BillingPaymentAccount/v2/styled';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import { ConfirmModalInnerWrapper } from 'scenes/BillingPaymentAccount/v2/AddNew/styled';
import { ReactComponent as IconInfo } from 'images/icons/info-circle.svg';

class Index extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      record2Remove: null,
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',

        ...query_params,
      },
    };
  }
  componentDidMount() {
    this.fetchData(this.state.initial_filter_values);
  }

  fetchData = ({ date_range = null, page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    window.scroll(0, 0);
    this.props.getListUserlabel({
      ...params,
    });
  };
  handleSearchUserlabel = (values) => {
    const params = {};
    if (values.keyword) {
      params['name'] = values.keyword;
    }
    this.fetchData(params);
  };
  deleteUserlabel = (id) => {
    this.props.deleteUserlabel(this.state.record2Remove?.id);
    this.setState({ record2Remove: null });
  };

  render() {
    const {
      listUserLableConfig,
      initial_filter_values,
      deleteUserLableLoading,
      pageName,
    } = this.props;

    const columns = [
      {
        title: <b>ID</b>,
        dataIndex: 'id',
        align: 'center',
        key: 'id',
        render: (value) => (
          <Link to={`/billing/return-address/${value}/edit`}>#{value}</Link>
        ),
      },
      {
        title: <b>Store name</b>,
        dataIndex: 'storeName',
        key: 'storeName',
        render: (value, record) => {
          return record.storeName ? (
            record.storeName
          ) : parseInt(record.storeId) === 0 ? (
            <b>Default return address</b>
          ) : (
            ''
          );
        },
      },
      {
        title: <b>Address</b>,
        dataIndex: 'address',
        align: 'center',
        key: 'address',
        render: (value) =>
          Object.values(value)
            .filter((item) => item)
            .join(', '),
      },
      {
        title: <b>Time created</b>,
        dataIndex: 'time',
        align: 'center',
        key: 'time',
        render: (text, record) =>
          text ? getMomentInstance(text, 0, true, false) : '',
      },
      {
        title: <b>Last update</b>,
        dataIndex: 'timeUpdate',
        align: 'center',
        key: 'timeUpdate',
        render: (text, record) =>
          text ? getMomentInstance(text, 0, true, false) : '',
      },
      {
        title: <b>Trademark status</b>,
        key: 'scanTrademark',
        align: 'center',
        dataIndex: 'scanTrademark',
        render: (value) =>
          getOptionLabel(options_trademark, value, false, value),
      },
      {
        title: <b></b>,
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <div>
            <MoreActionStore>
              <Dropdown
                placement="topLeft"
                overlay={
                  <ContextMenuWrapper className="p-3">
                    <Link
                      to={`/billing/return-address/${record.id}/edit`}
                      className="cursor-pointer"
                    >
                      Edit
                    </Link>
                    <div
                      className="delete cursor-pointer"
                      onClick={() => this.setState({ record2Remove: record })}
                    >
                      Delete
                    </div>
                  </ContextMenuWrapper>
                }
                trigger={['click']}
              >
                <MoreAction className="context-menu" />
              </Dropdown>
            </MoreActionStore>
          </div>
        ),
      },
    ];
    return (
      <>
        {' '}
        <Wrapper
          pageName={pageName}
          ctas={
            <GMButton
              type="button"
              onClick={() => {
                this.props.history.push('/billing/return-address/create');
              }}
            >
              <PlusIcon className="plusicon" />
              Add new return address
            </GMButton>
          }
          noContainer
        >
          <div className="filter">
            <Filter
              onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
              initialValues={initial_filter_values}
              handleSearchUserlabel={this.handleSearchUserlabel}
            />
          </div>
          <div className="main-content">
            <Table
              rowKey="id"
              columns={columns}
              dataSource={listUserLableConfig.data}
              loading={listUserLableConfig.loading || deleteUserLableLoading}
              scroll={{ x: 1000 }}
              pagination={
                !this.props?.is_mobile
                  ? {
                      showTotal: (total) => `Total ${total} items`,
                      total: listUserLableConfig.total,
                      pageSize: parseInt(
                        listUserLableConfig.params.perPage >
                          listUserLableConfig.total
                          ? listUserLableConfig.total
                          : listUserLableConfig.params.perPage
                      ),
                      current: listUserLableConfig.params.page,
                      showSizeChanger: true,
                      onChange: (page, perPage) =>
                        this.fetchData({
                          ...listUserLableConfig.params,
                          page,
                          perPage,
                        }),
                    }
                  : false
              }
            />
            {this.props.is_mobile && (
              <div className="d-flex justify-content-end align-items-center py-3 px-3">
                <div>
                  <Pagination
                    size="small"
                    showSizeChanger
                    className="pagination"
                    total={listUserLableConfig.total}
                    defaultCurrent={listUserLableConfig?.params?.page || 1}
                    pageSize={listUserLableConfig?.params?.perPage || 0}
                    onChange={(page, perPage) =>
                      this.fetchData({
                        ...listUserLableConfig.params,
                        page:
                          page === Number.NEGATIVE_INFINITY
                            ? 1
                            : page === 0
                            ? 1
                            : page,
                        perPage,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </Wrapper>
        {this.state.record2Remove !== null ? (
          <ConfirmDialog
            ctas={
              <>
                <GMButton
                  type="button"
                  color="SecondaryGray"
                  disabled={deleteUserLableLoading}
                  onClick={() => this.setState({ record2Remove: null })}
                >
                  Cancel
                </GMButton>

                <GMButton
                  type="button"
                  color="PrimaryError"
                  disabled={deleteUserLableLoading}
                  onClick={() =>
                    this.deleteUserlabel(this.state.record2Remove?.id)
                  }
                >
                  Delete
                </GMButton>
              </>
            }
            {...this.props}
          >
            <ConfirmModalInnerWrapper>
              <div className="icon">
                <div className="wrapper">
                  <IconInfo />
                </div>
              </div>
              <div className="content">
                <h4 className="header">Delete return address</h4>
                <div className="msg">
                  Deleting this return address may result in difficulties in
                  handling returns or tracking orders that fail to deliver.
                </div>
              </div>
            </ConfirmModalInnerWrapper>
          </ConfirmDialog>
        ) : null}
      </>
    );
  }
}

export default connect(
  (state) => ({
    listUserLableConfig: state.userlabel.list.userlableList,
    deleteUserLableLoading: state.userlabel.list.delete.loading,
  }),
  (dispatch) => ({
    getListUserlabel: (options) => {
      dispatch(getListUserlabel(options));
    },
    deleteUserlabel: (id) => {
      dispatch(deleteUserlabel(id));
    },
  })
)(Index);
