export const actionType = {
    DETAIL: {
        REQUEST: 'NOTIFICATION.DETAIL.REQUEST',
        SUCCESS: 'NOTIFICATION.DETAIL.SUCCESS',
        ERROR: 'NOTIFICATION.DETAIL.ERROR',
    }
};

export function getNotificationDetail(id) {
    return {
        type: actionType.DETAIL.REQUEST,
        id,
    }
}