import { GMButton } from 'components/Button';
import {
  BASE_WHITE,
  GRAY_300,
  GRAY_400,
  GRAY_50,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_300,
  SHADOW_XS,
} from 'components/colors';
import styled from 'styled-components';

export const Button = styled(GMButton)``;
export const Wrapper = styled.div`
  .login-button {
    width: 480px;
    max-width: 100%;
    margin: 6px 0 12px 0;
  }
  .input-field {
    width: 480px;
    max-width: 100%;
    &:placeholder {
      color: ${GRAY_400};
    }
  }
  .name-field {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #344054;
  }
  .register {
    .forgot-password-social {
      color: #169dd8;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .policy {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #667085;
  }
  .al {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Gray/600 */

    color: #475467;
  }
  .footer-login {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #169dd8;
    margin-left: 2px;
  }
  .ant-picker {
    height: 44px;
    line-height: 44px;
    padding: 0 14px;
    outline: none;
    width: 100%;

    color: ${GRAY_900};
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    &:placeholder {
      color: ${GRAY_400};
    }
    &:focus-visible,
    &:focus {
      outline: none;
      border: 1px solid ${PRIMARY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    }
    &:disabled {
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    }
  }
  .ant-picker-input,
  .input {
    input {
      &::placeholder {
        color:  ${GRAY_400}
      }
    }
  }
`;
