import React, { useEffect, useState } from "react";
import { list_store_error } from "utils/options";
function RenderUrlStore({ store = null, ...props }) {
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    if (store && parseInt(store?.expiredAt) > 0) {
        switch(parseInt(store?.expiredAt)){
            case 456:
                setErrorMessage(store?.backendType === 'amazon' && store?.resLogs ? store?.resLogs : list_store_error?.[store?.expiredAt])
            break;
            case 403:
                setErrorMessage(`403 Forbidden ${store?.resLogs}`)
            break;
            default:
                setErrorMessage(`${store?.expiredAt} ${list_store_error?.[store?.expiredAt]}`);
            break;            
        }
    }
  }, [store]);
  const storeUrl = (/^http/gi).test(store?.url || '') ? store?.url : `https://${store?.url}`;
  return (
    <>
      {store.url && <p className="mb-1"><a href={storeUrl} target="_blank" rel="noopener noreferrer" title={store?.url || ''}>Store URL</a></p>}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </>
  );
}

export default React.memo(RenderUrlStore);