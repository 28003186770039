import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import classnames from 'classnames';
import { Card } from 'antd';
import { renderSwitchAnt } from 'utils/Form';
import { GMButton } from 'components/Button';
const index = ({ handleSubmit, is_mobile, profile }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <div>
            <Card className="mb-4 mh110">
              <div className="row text-center">
                <div className="col-md-1 col-2">
                  <Field
                    className="switchGen"
                    name="autoSyncTracking"
                    component={renderSwitchAnt}
                  />
                </div>
                <div className="col-md-11 col-10">
                  <div className="headerGen">Auto sync tracking</div>
                  <div className="desGen1">
                    Automatically synced tracking to linked platforms or
                    marketplaces.
                  </div>
                </div>
              </div>
            </Card>
            <Card className="mb-4 mh110">
              <div className="row">
                <div className="col-md-1 col-2">
                  <Field
                    className="switchGen"
                    name="amazonAutoReplaceTracking"
                    component={renderSwitchAnt}
                  />
                </div>
                <div className="col-md-11 col-10">
                  <div className="headerGen">Amazon auto replace tracking</div>
                  <div className="desGen1">
                    Orders with "New Tracking" will be automatically updated to
                    Amazon.
                  </div>
                </div>
              </div>
            </Card>
            <Card className="mb-4 mh110">
              <div className="row">
                <div className="col-md-1 col-2">
                  <Field
                    className="switchGen"
                    name="autoApprove"
                    component={renderSwitchAnt}
                  />
                </div>
                <div className="col-md-11 col-10">
                  <div className="headerGen">Auto approve orders</div>
                  <div className="desGen1">
                    Once synced and meeting standard conditions, order will be
                    auto approved (SKY, Address, Verified, etc).
                  </div>
                </div>
              </div>
            </Card>
            <Card className="mb-4 mh110">
              <div className="row">
                <div className="col-md-1 col-2">
                  <Field
                    className="switchGen"
                    name="enableReceiveTrackingEmail"
                    component={renderSwitchAnt}
                  />
                </div>
                <div className="col-md-11 col-10">
                  <div className="headerGen">
                    Receive tracking code notifications via email
                  </div>
                  <div className="desGen1">
                    Order tracking updates will be sent to your email.
                  </div>
                </div>
              </div>
            </Card>{' '}
            {parseInt(profile?.id) === 60 && (
              <Card className="mb-4 mh110">
                <div className="row">
                  <div className="col-md-1 col-2">
                    <Field
                      className="switchGen"
                      name="autoBuyTracking"
                      component={renderSwitchAnt}
                    />
                  </div>
                  <div className="col-md-11 col-10">
                    <div className="headerGen">Auto buy Tracking label</div>
                    <div className="desGen1">
                      Select “Off”. The system will only buy Tracking Label when
                      the Order is set into Picked status in the facility.
                    </div>
                    <div className="desGen2"></div>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
        <div className="col">
          {' '}
          <Card className="mb-4 mh110">
            <div className="row">
              <div className="col-md-1 col-2">
                <Field
                  className="switchGen"
                  name="amazonConfirmShipment"
                  component={renderSwitchAnt}
                />
              </div>
              <div className="col-md-11 col-10">
                <div className="headerGen">Amazon confirm shipment</div>
                <div className="desGen1">
                  To avoid late shipment for an order, Gearment will
                  automatically select the “Confirm Shipment” option on Amazon.
                  After the tracking number for the order has been updated, it
                  will be added to the store after 24 to 48 hours.
                </div>
              </div>
            </div>
          </Card>{' '}
          <Card className="mb-4 mh110">
            <div className="row">
              <div className="col-md-1 col-2">
                <Field
                  className="switchGen"
                  name="autoMatchProductSku"
                  component={renderSwitchAnt}
                />
              </div>
              <div className="col-md-11 col-10">
                <div className="headerGen">
                  Auto match order by product SKUs
                </div>
                <div className="desGen1">
                  Gearment will automatically copy information from pass custom
                  orders to fulfill to avoid SKU duplication.
                </div>
              </div>
            </div>
          </Card>{' '}
          <Card className="mb-4 mh110">
            <div className="row">
              <div className="col-md-1 col-2">
                <Field
                  className="switchGen"
                  name="autoCharge"
                  component={renderSwitchAnt}
                />
              </div>
              <div className="col-md-11 col-10">
                <div className="headerGen">Auto payment order</div>
                <div className="desGen1">
                  Gearment auto charges approved orders every 4 hours. After
                  payment, you'll receive a confirmation email.
                </div>
              </div>
            </div>
          </Card>{' '}
          <Card className="mb-4 mh110">
            <div className="row">
              <div className="col-md-1 col-2">
                <Field
                  className="switchGen"
                  name="autoBuyTracking"
                  component={renderSwitchAnt}
                />
              </div>
              <div className="col-md-11 col-10">
                <div className="headerGen">Auto buy Tracking label</div>
                <div className="desGen1">
                  Gearment will buy the Tracking Label automatically after a
                  fixed time.
                </div>
              </div>
            </div>
          </Card>{' '}
        </div>
      </div>
      <div className="cta-section">
        <GMButton type="submit">Save</GMButton>
      </div>
    </form>
  );
};

const formFulfillmentSettings = reduxForm({
  form: 'formFulfillmentSettings',
  enableReinitialize: true,
})(index);
export default connect()(formFulfillmentSettings);
