import axios from 'utils/request';

const prefix = '/logs';

export const orderLogs = (id, params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/order/${id}?with[]=customer`,
  });
};

export const listLogExportOrder = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `/orders/export-order-list`,
  });
};

export const webhookLogs = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/order/${id}/webhooks`,
  });
};


