import { BASE_WHITE } from 'components/colors';
import styled from 'styled-components';
export const Wrapper = styled.div`
  .ant-radio-group.ant-radio-group-outline.ant-radio-group-default {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-infor {
    background-color: ${BASE_WHITE};
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 32px;
  }
  .customer-infor {
    background-color: ${BASE_WHITE};
    border-radius: 8px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-bottom: 24px;
    .ant-select-selector {
      border-radius: 8px;
      height: 44px;
    }
    .ant-input-affix-wrapper {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    .ant-btn .ant-btn-lg .ant-btn-icon-only .ant-input-search-button {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
  .footer-order {
    margin: 0 -52px;
    bottom: 0;
  }
  .shippingMethod {
    .ant-radio-group.ant-radio-group-outline.ant-radio-group-default {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .ant-radio-wrapper {
      width: 500px;
      max-width: 100%
    }
  }
`;
