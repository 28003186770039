import React, {useEffect} from "react";
import { Collapse, Col, Affix } from "antd";
import { Field, reduxForm, submit, change } from "redux-form";
import { connect } from "react-redux";
import FieldCustomRadioGroup from "utils/fields/FieldCustomRadioGroup";
import { renderSelectAnt } from "utils/Form";
import RenderAdvancedFilterButtons from "utils/Form/RenderAdvancedFilterButtons";
import { clearFilter } from "utils/helper";
import RenderTagFilter from "utils/Form/RenderTagFilter";
import { useSelector } from "react-redux";
import GMRow from "components/GMLayout/GMRow";
const { Panel } = Collapse;
const paramsName = {
  expId: "Product",
  status: "Status"
};
const defaultValues = {
  expId: "0",
  status: "4",
};

let paramsValueAlias = {
  status: {
    0: "Deactive",
    1: "Active"
  },
}
const AdvancedFilter = ({ handleSubmit, exproducts, changeFieldValue, ...props}) => {
  const mockups = useSelector((state) => state.mockup.list.mockups);
  useEffect(() => {
    if ((Array.isArray(exproducts) && exproducts.length > 0)) {
      const listProducts = {};
      (exproducts || []).forEach((exproduct) => {
        listProducts[exproduct.id] = exproduct.brand;
      });
      paramsValueAlias = {
        ...paramsValueAlias,
        expId: listProducts
      }
    }
  }, [JSON.stringify(exproducts)]);
  const submitFilters = (isClosedAdvanedFileter = true) => {
    props.dispatch(submit("AdvancedFilterMockups"));
    if(isClosedAdvanedFileter) props.onCloseAdvancedSearch();
  }
  return (
    <div>
      <RenderTagFilter
        params={mockups.params}
        paramsName={paramsName}
        paramsValueAlias={paramsValueAlias}
        defaultValues={defaultValues}
        changeFieldValue={changeFieldValue}
        isAllowedToClearFilter={true}
        submitFilters={submitFilters}
      />
      <form onSubmit={handleSubmit}>
        <div className="p-3">
          <Collapse
            defaultActiveKey={["1", "2"]}
            bordered={true}
            ghost
            expandIconPosition="right"
          >
            <Panel header={<b className="header-filter">Product</b>} key="2">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="expId"
                    component={renderSelectAnt}
                    options={[
                      { value: "0", label: "Select Product" },
                      ...exproducts.map((exproduct) => ({
                        value: exproduct.id,
                        label: exproduct.brand,
                      })),
                    ]}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Status</b>} key="1">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="status"
                    component={FieldCustomRadioGroup}
                    defaultValue={"0"}
                    options={[
                      { value: "1", label: <span>Active</span> },
                      { value: "0", label: <span>Deactive</span> },
                      { value: "-1", label: <span>All</span> },
                    ]}
                  />
                </Col>
              </GMRow>
            </Panel>
          </Collapse>
        </div>
      </form>
      <Affix offsetBottom={0}>
        <RenderAdvancedFilterButtons
          handleOnClearFilters={() => {
            clearFilter(changeFieldValue, {
              status: "-1",
              expId: "0",
            });
            setTimeout(() => props.dispatch(submit("AdvancedFilterMockups")), 1000);
          }}
          handleOnSubmit={submitFilters}
        />
      </Affix>
    </div>
  );
};

const AdvancedFilterReduxForm = reduxForm({
  form: "AdvancedFilterMockups",
  enableReinitialize: true,
  //validate,
})(AdvancedFilter);

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change("AdvancedFilterMockups", field, value));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedFilterReduxForm);
