export const actionType = {
  LIST_USERLABEL_CREATE: {
    REQUEST: "USERLABEL.LIST_USERLABEL_CREATE.REQUEST",
    SUCCESS: "USERLABEL.LIST_USERLABEL_CREATE.SUCCESS",
    ERROR: "USERLABEL.LIST_USERLABEL_CREATE.ERROR",
  },
  LIST_STORE_USERLABEL: {
    REQUEST: "LIST_STORE_USERLABEL.REQUEST",
    SUCCESS: "LIST_STORE_USERLABEL.SUCCESS",
    ERROR: "LIST_STORE_USERLABEL.ERROR",
  },
};
export function getUselabelCreate(params) {
  return {
    type: actionType.LIST_USERLABEL_CREATE.REQUEST,
    params,
  };
}
export function getListStoreUserlabel(params) {
  return {
    type: actionType.LIST_STORE_USERLABEL.REQUEST,
    params,
  };
}
