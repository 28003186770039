import { takeLatest, call, put, all } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { LOGIN, setDataResendAction } from './actions';
import { login } from 'apis/auth';
import { API_TOKEN_KEY } from 'web.config';
import { clearFields } from 'redux-form';
import { setCookie } from 'utils/helper';

function* loginSaga(action) {
  try {
    const { data } = action;
    const response = yield call(login, data);
    if (response.code === 'success') {
      //Save isLogin for connect shopify from Shopify App
      // localStorage.setItem('isLogin', response?.data.isLogin);
      if (response?.data?.redirectToRegister === true) {
        if (response?.data?.loginType === 'google') {
          yield all([
            put({ type: LOGIN.social, token: null}),
            put(
              replace('/auth/google-register', {
                ...data,
                email: response?.data?.email,
                name: response?.data?.name,
              })
            ),
          ]);
        } else if (response?.data?.loginType === 'facebook') {
          yield all([
            put({ type: LOGIN.social, token: null }),
            put(
              replace('/auth/facebook-register', {
                ...data,
                email: response?.data?.email,
                name: response?.data?.name,
              })
            ),
          ]);
        }
      } else {
        const {
          data: { accessToken: token },
        } = response;
        setCookie(API_TOKEN_KEY, token);
        if (window.innerWidth < 800) {
          setTimeout(() => {
            window.location = '/';
          }, 100);
        } else {
          yield all([put({ type: LOGIN.success, token,...response }), put(replace('/'))]);
        }
      }

      yield put(clearFields('formLogin'));
    } else {
      const responseArr = response?.[0] || {};
      if (responseArr?.resend_verification_email === true) {
        yield put(setDataResendAction(data));
      }
      yield put({ type: LOGIN.error, error: response });
    }
  } catch (error) {
    yield all([put({ type: LOGIN.error, error })]);
  }
}

function* loginWatcher() {
  yield all([takeLatest(LOGIN.request, loginSaga)]);
}

export default loginWatcher;
