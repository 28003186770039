import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Pagination, Table } from 'antd';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { getStoreName, getPlatformForImportOrderProgress } from 'utils/helper';
import getMomentInstance from 'utils/moment';
import {
  import_progress_status,
  getOptionLabel,
  export_progress_status,
  status_email_export,
} from 'utils/options';
import { getListLogExportOrder } from 'scenes/Logs/actions';
import { Wrapper } from 'scenes/Logs/styled';
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      showDetail: false,
      selected: null,
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 20, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getListLogExportOrder(params);
  };

  render() {
    const { pageName, logs_export_order } = this.props;
    const columns = [
      {
        title: <b>ID</b>,
        align: 'center',
        dataIndex: 'id',
        key: 'id',
        render: (text) => <>#{text}</>,
      },

      {
        title: <b>Link download</b>,
        align: 'center',
        dataIndex: 'linkDownload',
        key: 'linkDownload',
        render: (text) => {
          return text !== null ? (
            <a href={"https://d33jplnp2vyxjf.cloudfront.net/"+text} download onClick={(e) => e.stopPropagation()}>
              File export{' '}
            </a>
          ) : (
            'N/A'
          );
        },
      },
      {
        title: <b>Request time</b>,
        align: 'center',
        dataIndex: 'exportTime',
        key: 'exportTime',
        render: (text) => {
          return getMomentInstance(text, null, true);
        },
      },
      {
        title: <b>Completed time</b>,
        align: 'center',
        dataIndex: 'exportTimeFinish',
        key: 'exportTimeFinish',
        render: (text) => {
          return text + '' === '0' ? 'N/A' : getMomentInstance(text, null, true);
        },
      },
      {
        title: <b>Export status</b>,
        align: 'center',
        dataIndex: 'exportStatus',
        key: 'exportStatus',
        render: (text) => {
          return getOptionLabel(export_progress_status, text);
        },
      },
      {
        title: <b>Send email status</b>,
        align: 'center',
        dataIndex: 'sendEmail',
        key: 'sendEmail',
        render: (text) => {
          return getOptionLabel(status_email_export, text);
        },
      },
    ];
    return (
      <Wrapper pageName={pageName}>
        <div>
          <div>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={logs_export_order?.data?.order_exporting}
              loading={logs_export_order?.loading}
              scroll={{ x: 1000 }}
              pagination={
                !this.props.is_mobile
                  ? {
                      showTotal: (total) => `Total ${total} items`,
                      total: logs_export_order?.total,
                      pageSize: 20,
                      current: logs_export_order?.params.page,
                      showSizeChanger: false,
                      onChange: (page, perPage) =>
                        this.fetchData({
                          ...logs_export_order?.params,
                          page,
                          perPage,
                        }),
                    }
                  : false
              }
            />
            {/* {this.props.is_mobile && (
              <div className="d-flex justify-content-end align-items-center py-3 px-3">
                <div>
                  <Pagination
                    size="small"
                    showSizeChanger
                    className="pagination"
                    total={import_progress?.total}
                    defaultCurrent={import_progress?.params?.page || 1}
                    pageSize={20}
                    onChange={(page, perPage) =>
                      this.fetchData({
                        ...import_progress?.params,
                        page:
                          page === Number.NEGATIVE_INFINITY
                            ? 1
                            : page === 0
                            ? 1
                            : page,
                        perPage,
                      })
                    }
                  />
                </div>
              </div>
            )} */}
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    logs_export_order: state.logs?.log_export_order,
    import_progress: state.order.import_progress.import_progress,
  }),
  (dispatch) => ({
    getListLogExportOrder: (options) => {
      dispatch(getListLogExportOrder(options));
    },
  })
)(List);
