import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Descriptions } from "antd";

import { getList } from "./actions";
import getMomentInstance from "utils/moment";
// import { getOptionLabel, options_schedule_campaign_status, options_platform_type } from 'utils/options'
import FormFilter from "./components/FormFilter";
import LineChart from "utils/LineChart";
import BarChart from "utils/BarChart";

class List extends Component {
  constructor(props) {
    super(props);
    const { from, to, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      initial_filter_values: {
        perPage: 10,
        ...query_params,
        date_range: [
          from
            ? getMomentInstance(from, 0, false, true)
            : getMomentInstance(null, 0, false, true),
          to
            ? getMomentInstance(to, 0, false, true)
            : getMomentInstance(null, 0, false, true),
        ],
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ date_range, page = 1, perPage = 10, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (date_range) {
      params.from = date_range[0] && date_range[0].format("YYYY-MM-DD");
      params.to = date_range[1] && date_range[1].format("YYYY-MM-DD");
    }
    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getList(params);
  };
  render() {
    // const { list: { data, loading } } = this.props;
    const { analytics } = this.props;
    const { initial_filter_values } = this.state;
    const data_line_chart = [
      {
        id: "Total Sales",
        color: "hsl(131, 80%, 60%)",
        data: analytics.order.map((item) => ({
          x: item.time,
          y:
            parseInt(item.quantum) ||
            Math.floor(Math.random() * Math.floor(100)),
        })),
      },
    ];
    return (
      <div>
        <div className="container-fluid">
          <div className="row my-3">
            <div className="col-md-5">
              <h1 className="h3 mb-0 font-weight-bold">Dashboard overview</h1>
            </div>
            <div className="col-md-7">
              <FormFilter
                initialValues={initial_filter_values}
                onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
              />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 mb-3">
              <LineChart
                title="Total sales"
                data={data_line_chart}
                loading={analytics.loading}
                colors={["hsl(131, 80%, 60%)"]}
              />
            </div>
            <div className="col-md-6 mb-3">
              <BarChart
                title="Online store conversion rate"
                data={analytics.gaSession}
                loading={analytics.loading}
                indexBy="type"
                keys={["views"]}
                colors={["hsl(24, 80%, 60%)"]}
              />
            </div>
            <div className="col-12 mb-3 text-center">
              <Descriptions title="Top referrers by sessions">
                {analytics.gaTraffic.map((item) => (
                  <Descriptions.Item
                    key={item.source}
                    label={<b>{item.source}</b>}
                  >
                    {item.quantum}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </div>
          </div>
          {/* <div style={{overflowX: 'auto'}}>
                    <div style={{width: '100%',height: 400}}>
                        <ResponsiveLine
                            data={data_line_chart}
                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                            xScale={{ type: 'point' }}
                            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'transportation',
                                legendOffset: 36,
                                legendPosition: 'middle'
                            }}
                            axisLeft={{
                                orient: 'left',
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'count',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }}
                            colors={{ scheme: 'nivo' }}
                            pointSize={10}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            pointLabel="y"
                            pointLabelYOffset={-12}
                            useMesh={true}
                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 100,
                                    translateY: 0,
                                    itemsSpacing: 0,
                                    itemDirection: 'left-to-right',
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemOpacity: 0.75,
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                        </div>
            </div> */}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    analytics: state.analytic.analytics,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
  })
)(List);
