import { takeLatest, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { actionType as TYPE } from "./actions";
import { createUserlabel } from "apis/userlabel";
import { getListStoreUserlabel } from "apis/store_integrations";
import { verify } from "scenes/Auth/actions";

//Create userlabel
function* createUserlabelSaga(action) {
  try {
    const { params } = action;
    const response = yield call(createUserlabel, params);

    if (response.code === "success") {
      yield all([
        put({ type: TYPE.LIST_USERLABEL_CREATE.SUCCESS, response }),
        put(replace("/billing/return-address")),
        put(verify()),
      ]);
    } else {
      yield put({ type: TYPE.LIST_USERLABEL_CREATE.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.LIST_USERLABEL_CREATE.ERROR, error })]);
  }
}
//List store userlabel
function* getListStoreUserLableSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListStoreUserlabel, { ...params });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST_STORE_USERLABEL.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_STORE_USERLABEL.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LISTSTORE__USERLABEL.ERROR, error })]);
  }
}

function* creatUserlabelWatcher() {
  yield all([
    takeLatest(TYPE.LIST_USERLABEL_CREATE.REQUEST, createUserlabelSaga),
  ]);
  yield all([
    takeLatest(TYPE.LIST_STORE_USERLABEL.REQUEST, getListStoreUserLableSaga),
  ]);
}

export default creatUserlabelWatcher;
