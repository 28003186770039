import { isInteger } from 'lodash';
import { isValidEmail } from 'utils/helper';

const validate = (values) => {
  const errors = {};
  if (!values.fullName || values.fullName.replace(/\s+/g, '').length === 0) {
    errors.fullName = 'Please enter the full name';
  }
  if (!values.address01 || values.address01.replace(/\s+/g, '').length === 0) {
    errors.address01 = 'Please enter address 1.';
  }
  if (!values.city || values.city.replace(/\s+/g, '').length === 0) {
    errors.city = 'Please enter city.';
  }

  if (values?.iossNumber && !values?.orderValue) {
    errors.orderValue = 'Please enter order value.';
  }

  if (values?.taxNumber && !values?.orderTaxValue) {
    errors.orderTaxValue = 'Please enter order tax value.';
  }
  if (
    parseInt(values.country) === 231 &&
    (!values.state || values.state.replace(/\s+/g, '').length === 0)
  ) {
    errors.state = 'Please enter state/province.';
  }

  if (!values.zipcode || values.zipcode.replace(/\s+/g, '').length === 0) {
    errors.zipcode = 'Please enter zip code/postal code.';
  }
  if (values.email && !isValidEmail(values.email)) {
    errors.email = 'Please enter valid email';
  }
  if (!values.listOrderItems || !values.listOrderItems.length) {
    errors.listOrderItems = {
      _error: 'Please add order item to create order.',
    };
  } else {
    const conditionArrayErrors = [];
    for (let index = 0; index < values.listOrderItems.length; index++) {
      const element = values.listOrderItems[index];
      const conditionErrors = {};
      if (element?.sidePrint === 'pocket' && !element.designFront) {
        conditionErrors.designFront = 'Please upload design.';
      }
      if (!element.productId || parseInt(element.productId) === 0) {
        conditionErrors.productId = 'Please select a product.';
      }
      if (!element.colorId || parseInt(element.colorId) === 0) {
        conditionErrors.colorId = 'Please select a color.';
      }
      if (!element.sizeId || parseInt(element.sizeId) === 0) {
        conditionErrors.sizeId = 'Please select a size.';
      }
      if (!element.sidePrint) {
        conditionErrors.sidePrint = 'Please select a placement.';
      }
      if (
        !element.quantity ||
        isNaN(parseInt(element.quantity)) ||
        parseInt(element.quantity) < 1 ||
        parseInt(element.quantity) > 100
      ) {
        conditionErrors.quantity = 'Please enter quantity between 1 and 100.';
      }
      if (!element.designFront && !element.designBackId) {
        conditionErrors.designFront = 'Please upload design.';
      }
      conditionArrayErrors[index] = conditionErrors;
    }
    if (conditionArrayErrors.length) {
      errors.listOrderItems = conditionArrayErrors;
    }
  }
  return errors;
};

export default validate;
