import { takeLatest, call, put, all } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { LOGOUT } from './actions';
import { logout } from 'apis/auth';
import { API_TOKEN_KEY, SHOPIFY_INTEGRATE } from 'web.config';
import { getCookie, removeCookie } from 'utils/helper';

function* logoutSaga(action) {
  try {
    if (getCookie(API_TOKEN_KEY) /* auth cookie */) {
      const response = yield call(logout);
      if (response.code === 'success') {
        yield all([put({ type: LOGOUT.success })]);
        // localStorage.removeItem('isLogin');
        removeCookie(SHOPIFY_INTEGRATE)
      } else {
        yield put({ type: LOGOUT.error, error: response });
      }
      yield put(replace('/auth/login'));
    } else {
      yield put({
        type: LOGOUT.error,
        error: [{ clientMsg: 'Logout failed.' }],
      });
      yield put(replace('/auth/login'));
    }
  } catch (error) {
    yield all([put({ type: LOGOUT.error, error })]);
  }
}

function* logoutWatcher() {
  yield all([takeLatest(LOGOUT.request, logoutSaga)]);
}

export default logoutWatcher;
