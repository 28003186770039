import { takeLatest, call, put, all } from 'redux-saga/effects';

import { actionType as TYPE } from './actions';

import { syncOrder } from 'apis/orders';

function* syncOrderSaga(action) {
  const { data } = action;
  try {
    const response = yield call(syncOrder, data);
    if (response?.[0].code === 'sync_order_success') {
      yield put({ type: TYPE.SYNC_ORDER.SUCCESS, response });
    } else {
      yield put({ type: TYPE.SYNC_ORDER.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.SYNC_ORDER.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.SYNC_ORDER.REQUEST, syncOrderSaga);
}

export default watcher;
