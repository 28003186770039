import axios from 'utils/request'

const prefix = '/schedule-tasks';

export const getList =(params={})=> {
    return axios({
        method: 'GET',
        params,
        url: `${prefix}?with[]=storeIntegration`
    })
}

export const create =(data)=> {
    return axios({
        method: 'POST',
        data,
        url: `${prefix}`
    })
}
export const getDetail =(id)=> {
    return axios({
        method: 'GET',
        // url: `${prefix}/${id}?&with[]=customer`
        url: `${prefix}/${id}?with[]=storeIntegration`
        
    })
}

export const update =(id, data)=> {
    return axios({
        method: 'PUT',
        data,
        url: `${prefix}/${id}`
    })
}

export const destroy =(id)=> {
    return axios({
        method: 'POST',
        url: `${prefix}/${id}/delete`
    })
}