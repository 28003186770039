import React, { memo, useEffect, useState } from "react";
import { AmountBox, BallanceBox, Footer, Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import money from "utils/money";
import { GMButton } from "components/Button";
import ModalTopUp from "components/ModalTopUp";
import { setPaymentError } from "scenes/Orders/scenes/List/actions";
import { loadPrimaryAccountAction } from "scenes/BillingPaymentAccount/actions";

export default memo((props) => {
  const [isOpenTopup, setOpenTopup] = useState(false);
  const d = useDispatch();
  const profile = useSelector((s) => s.auth?.info?.profile);
  const error = useSelector((s) => s.order?.list?.orderPaymentData?.error);
  const primaryBillingPaymentAccount = useSelector(
    (s) => s.billingPaymentAccount?.primary
  );
  useEffect(() => {
    d(loadPrimaryAccountAction());
  }, []);
  /* Nếu level > 1  là khách invoice */
  if (
    profile?.level > 1 ||
    `${primaryBillingPaymentAccount?.type}` === "3" ||
    (error &&
      error?.length > 1 &&
      error.indexOf((err) => err.code === "insufficient_balance_gwallet") ===
        -1)
  ) {
    return;
  }
  const closeModal = () => d(setPaymentError({ payload: null }));
  const openTopupModal = (evt) => {
    // eslint-disable-next-line no-unused-expressions
    evt?.preventDefault();
    setOpenTopup(!!1);
    closeModal();
    return false;
  };
  if (error && error?.[0]?.code !== "required_minimum_five_orders") {
    profile.balance = error?.[0]?.data?.balance;
    return (
      <Wrapper
        footer={null}
        visible
        onCancel={() => closeModal()}
        title="Insufficient Balance"
      >
        <p className="msg">
          The balance in your G-Wallet is insufficient to cover payment for
          selected orders. To proceed, please topping up your account.
        </p>

        <BallanceBox>
          <span className="label">G-Wallet’s Balance</span>
          <div className="balance">{money(error?.[0]?.data?.balance || 0)}</div>
        </BallanceBox>
        <AmountBox>
          <span className="label">Total amount:</span>
          <span className="amount">{money(error?.[0]?.data?.amount || 0)}</span>
        </AmountBox>
        <Footer>
          <GMButton color="SecondaryGray" onClick={() => closeModal()}>
            Cancel
          </GMButton>
          <GMButton onClick={openTopupModal}>Top up G-wallet</GMButton>
        </Footer>
      </Wrapper>
    );
  }
  if (isOpenTopup) {
    return (
      <ModalTopUp
        isOpenModal={isOpenTopup}
        closeModal={() => setOpenTopup(!1)}
      />
    );
  }
});
