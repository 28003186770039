import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import { getListReportOrder, exportProductSell } from "apis/orders";

function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListReportOrder, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}

function* exportProductSellSaga(action) {
  const { params } = action;
  try {
    const response = yield call(exportProductSell, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.EXPORT_PRODUCT_SELL.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.EXPORT_PRODUCT_SELL.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.EXPORT_PRODUCT_SELL.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga);
  yield takeLatest(TYPE.EXPORT_PRODUCT_SELL.REQUEST, exportProductSellSaga);
}

export default watcher;
