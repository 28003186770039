import React from 'react';
import { Field } from 'redux-form';
import { Select } from 'antd';
import { GMInputWrapper } from './styled';
import Wrapper from './Select.styled';
import classNames from 'classnames';

const SelectComponent = ({
  input,
  // meta: { error, touched, submitFailed },
  options,
  loading,
  mode = 'default',
  size = 'large',
  placeholder,
  message,
  showError,
  defaultValue = undefined,
  onChangeTypeProduct = null,
  onChangeCountryCode = null,
  className,
  meta,
  ...rest
}) => {
  const { error, touched, submitFailed } = meta;
  if (defaultValue !== undefined) {
    input.value = defaultValue;
  }
  return (
    <>
      <GMInputWrapper>
        <Select
          style={{ width: '100%' }}
          {...rest}
          mode={mode || 'default'}
          size={size}
          value={input.value || defaultValue}
          loading={loading}
          autoComplete="off"
          showSearch
          optionFilterProp="children"
          placeholder={placeholder}
          onChange={(value) => {
            input.onChange(value === undefined ? null : value);
            if (onChangeTypeProduct) {
              onChangeTypeProduct(value === undefined ? null : value);
            }
            if (onChangeCountryCode) {
              onChangeCountryCode(
                options.find((option) => option.value == value)?.countryIsoCode
              );
            }
          }}
          filterOption={(input, option) =>
            String(option.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {options.map((option) => (
            <Select.Option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </Select.Option>
          ))}
        </Select>
        {touched && submitFailed && showError && error ? (
          <span className="error-msg">{error}</span>
        ) : message ? (
          <span className="message">{message}</span>
        ) : null}
      </GMInputWrapper>
    </>
  );
};
export default ({
  input,
  label,
  component,
  error,
  touched,
  submitFailed,
  className,
  showError,
  ...rest
}) => {
  return (
    <Wrapper className="gm-select">
      {label ? (
        <div className="label">
          <label htmlFor={rest.id || rest.name}>{label}</label>
        </div>
      ) : null}
      <div className="input">
        <Field
          className="ant-select-customize-input"
          component={component || SelectComponent}
          {...rest}
          showError
        />
      </div>
    </Wrapper>
  );
};
