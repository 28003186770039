import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import {
  getList,
  exportOrders,
  exportInvoice
} from "apis/invoice";

function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getList, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}

function* exportOrdersSaga(action) {
  const { id } = action;
  try {
    const response = yield call(exportOrders, id);
    if (response.code === "success") {
      yield put({ type: TYPE.EXPORT_ORDERS.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.EXPORT_ORDERS.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.EXPORT_ORDERS.ERROR, error })]);
  }
}

function* exportInvoiceSaga(action) {
  const { id } = action;
  try {
    const response = yield call(exportInvoice, id);
    if (response.code === "success") {
      yield put({ type: TYPE.EXPORT_INVOICE.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.EXPORT_INVOICE.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.EXPORT_INVOICE.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga);
  yield takeLatest(TYPE.EXPORT_ORDERS.REQUEST, exportOrdersSaga);
  yield takeLatest(TYPE.EXPORT_INVOICE.REQUEST, exportInvoiceSaga);
}

export default watcher;
