import { 
    takeLatest, 
    call, 
    put, 
    all 
  } from 'redux-saga/effects'
  import {
      actionType as TYPE
  } from './actions'

  import { AUTH } from 'scenes/Auth/actions'

  import {resetPassword} from 'apis/user'
  
  function* updateSaga(action) {
    try {
        let { params } = action
        console.log("go here")
        const response = yield call(resetPassword, params)
        if(response.code === 'success'){
            yield all([
                put({type: TYPE.UPDATE.SUCCESS, payload: response}),
                put({type: AUTH.verify.request}),
            ])
        }else{
          yield put({type: TYPE.UPDATE.ERROR, error: response})
        }
    } catch (error) {
        yield all([
            put({type: TYPE.UPDATE.ERROR, error})
        ])
    }
}

  
  function* watcher() {
    yield all([
        takeLatest(TYPE.UPDATE.REQUEST, updateSaga),
    ])
  }
  
  export default watcher