import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Button, Affix } from "antd";
import { getInvoice } from "./actions";
import { getListCustomerConfig } from "../../../Settings/scenes/General/actions";
import Logo from 'images/Logo_store/full/gearment.svg';
import money from "utils/money";
import getMomentInstance from "utils/moment";
import BackToIcon from "images/chevron-down.svg";
import { Wrapper } from "./styled";
import RenderPrintPDF from "utils/Form/RenderPrintPDF";
import { GMButton } from "components/Button";
class Invoice extends Component {

  componentDidMount() {
    this.props.getInvoice(this.props.match.params.id);
    this.props.getCutomerConfig();
  }

  render() {
    const {
      invoice,
      pageName,
      listCustomerConfig,
      listCustomerConfigLoading,
    } = this.props;
    return (
      <Wrapper
        pageName={pageName}
        // breadcrumDetails={{
        //   [this.props.match.params.id]: this.props.match.params.id && `#${this.props.match.params.id || ''}`,
        // }}
        filter={
          <GMButton
            className="BackToButton d-flex align-items-center"
            onClick={() => {
              this.props.history.push(`/orders/${this.props.match.params.id}`);
            }}
            color="SecondaryGray"
          >
            <img src={BackToIcon} className="back-icon" />
            <p className="m-0 ml-2">Back to Order</p>
          </GMButton>
        }
        noBreadcrumb
      >
        <Spin spinning={invoice.loading || listCustomerConfigLoading}>
          <div
            className="invoice"
            ref={(el) => (this.componentRef = el)}
          >
            <div className="row">
              <div className="col-md-6 col-xl-8">
                <div className="mb-4">
                  <img src={Logo} alt={"Gearmnet Logo"} width={200} />
                  <h5 className="mt-2">Gearment LLC</h5>
                </div>
                <div className="mb-4">
                  <p>
                    Address:{" "}
                    {invoice.configs && invoice.configs.company_street1
                      ? invoice.configs.company_street1
                      : ", "}
                  </p>
                  {invoice.configs && invoice.configs.company_street2 && (
                    <p>{invoice.configs.company_street2}</p>
                  )}
                  <p>
                    {invoice.configs && invoice.configs.company_city
                      ? invoice.configs.company_city
                      : ", "}
                    {invoice.configs && invoice.configs.company_state
                      ? invoice.configs.company_state
                      : ", "}
                    {invoice.configs && invoice.configs.postal_code
                      ? invoice.configs.postal_code
                      : ", "}
                  </p>
                  <p>
                    {invoice.configs &&
                      invoice.configs.default_country_checkout
                      ? invoice.configs.default_country_checkout
                      : ""}
                  </p>
                </div>
                <div>
                  <p>
                    Phone:{" "}
                    {invoice.configs && invoice.configs.company_phone
                      ? invoice.configs.company_phone
                      : ""}
                  </p>
                  <p>Email: info@gearment.com</p>
                  <p>Website: https://gearment.com</p>
                </div>
                <div>
                  <h5>Invoice To</h5>
                  <p>
                    Seller store:{" "}
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_store_name"] &&
                      listCustomerConfig["invoice_store_name"]["value"]
                      ? listCustomerConfig["invoice_store_name"]["value"]
                      : ""}
                  </p>
                  <p>
                    Full Name:{" "}
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_first_name"] &&
                      listCustomerConfig["invoice_first_name"]["value"]
                      ? listCustomerConfig["invoice_first_name"]["value"]
                      : ""}{" "}
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_last_name"] &&
                      listCustomerConfig["invoice_last_name"]["value"]
                      ? listCustomerConfig["invoice_last_name"]["value"]
                      : ""}
                  </p>
                  <p>
                    Address:
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_address"] &&
                      listCustomerConfig["invoice_address"]["value"]
                      ? listCustomerConfig["invoice_address"]["value"] + " "
                      : ""}
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_extended_address"] &&
                      listCustomerConfig["invoice_extended_address"]["value"]
                      ? listCustomerConfig["invoice_extended_address"][
                      "value"
                      ] + " "
                      : ""}
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_city"] &&
                      listCustomerConfig["invoice_city"]["value"]
                      ? listCustomerConfig["invoice_city"]["value"] + ","
                      : ""}
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_state"] &&
                      listCustomerConfig["invoice_state"]["value"]
                      ? listCustomerConfig["invoice_state"]["value"] + ", "
                      : ""}
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_postalcode"] &&
                      listCustomerConfig["invoice_postalcode"]["value"]
                      ? listCustomerConfig["invoice_postalcode"]["value"] + ", "
                      : ""}
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_country_name"] &&
                      listCustomerConfig["invoice_country_name"]["value"]
                      ? listCustomerConfig["invoice_country_name"]["value"] + " "
                      : ""}
                  </p>
                  <p>
                    Email:{" "}
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_email"] &&
                      listCustomerConfig["invoice_email"]["value"]
                      ? listCustomerConfig["invoice_email"]["value"]
                      : ""}
                  </p>
                  <p>
                    Phone:{" "}
                    {listCustomerConfig &&
                      listCustomerConfig["invoice_phone"] &&
                      listCustomerConfig["invoice_phone"]["value"]
                      ? listCustomerConfig["invoice_phone"]["value"]
                      : ""}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="mb-3">
                  <h3 className="text-uppercase">
                    INVOICE #{this.props.match.params.id}
                  </h3>
                  <p>
                    Date:{" "}
                    {invoice.order && invoice.order.createdAt
                      ? getMomentInstance(
                        invoice.order.createdAt,
                        null,
                        true
                      )
                      : ""}
                  </p>
                </div>
                <div className="p-4 border rounded">
                  <h5>Payment details</h5>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: "55%" }}>Order name: </td>
                        <td>
                          <b>
                            {invoice && invoice.order && invoice.order.name
                              ? invoice.order.name
                              : ""}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "55%" }}>Subtotal: </td>
                        <td>
                          {invoice &&
                            invoice.order &&
                            invoice.order.subTotal
                            ? money(invoice.order.subTotal)
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "55%" }}>Shipping fee: </td>
                        <td>
                          {invoice &&
                            invoice.order &&
                            invoice.order.shippingFee
                            ? money(invoice.order.shippingFee)
                            : ""}
                        </td>
                      </tr>
                      {console.log(invoice)}
                      {invoice?.order?.orderExtraFee?.length > 0 ? (
                        invoice?.order?.orderExtraFee.map(fee => (
                          <tr>
                            <td style={{ width: '55%' }}>
                              {fee?.extraTypeName}:{' '}
                            </td>
                            <td>
                              {money(
                                fee?.amount || 0
                              )}
                            </td>
                          </tr>))
                      ) : null}
                      <tr>
                        <td style={{ width: "55%" }}>Tax: </td>
                        <td>
                          {invoice &&
                            invoice.order &&
                            invoice.order.totalVat
                            ? money(invoice.order.totalVat)
                            : money(0)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "55%" }}>Total Due: </td>
                        <td>
                          <b>
                            {invoice && invoice.order && invoice.order.total
                              ? money(invoice.order.total)
                              : ""}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="10">#</th>
                  <th width="10">Order</th>
                  <th width="20">Item</th>
                  <th width="20">Product</th>
                  <th width="5">Quantity</th>
                  <th width="10">Tracking code</th>
                  <th width="10">Total</th>
                </tr>
              </thead>
              <tbody>
                {invoice.order &&
                  invoice.order.orderItems &&
                  invoice.order.orderItems.map((item) => {
                    return (
                      <tr>
                        <td>#{item.id}</td>
                        <td>{item.name}</td>
                        <td className="text-uppercase">
                          {item.content.name_product}{" "}
                          {item.content.name_color} {item.content.name_size}
                        </td>
                        <td style={{ width: "15%" }}>
                          {item.imageUrl && <img
                            style={{ width: "50%" }}
                            src={item.imageUrl}
                            alt=""
                          />}
                        </td>
                        <td>{item.quantity}</td>
                        <td>{invoice.order?.orderExtra?.trackingNumber || ''}</td>
                        <td>{money(item.subTotal)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="row">
              <div className="col-md-7 col-xl-8"></div>
              <div className="col-md-5 col-xl-4">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td style={{ width: "50%" }}>Subtotal: </td>
                        <td>
                          {invoice &&
                            invoice.order &&
                            invoice.order.subTotal
                            ? money(invoice.order.subTotal)
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>Shipping fee: </td>
                        <td>
                          {invoice &&
                            invoice.order &&
                            invoice.order.shippingFee
                            ? money(invoice.order.shippingFee)
                            : ""}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>Tax: </td>
                        <td>
                          {invoice &&
                            invoice.order &&
                            invoice.order.totalVat
                            ? money(invoice.order.totalVat)
                            : money(0)}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "50%" }}>Total Due: </td>
                        <td>
                          <b>
                            {invoice && invoice.order && invoice.order.total
                              ? money(invoice.order.total)
                              : ""}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Affix offsetBottom={0}>
            <RenderPrintPDF type={"printOrderInvoice"} id={this.props.match.params.id} />
          </Affix>
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    invoice: state.order.invoice.getInvoice,
    listCustomerConfigLoading: state.setting.general.listCustomerConfig.loading,
    listCustomerConfig: state.setting.general.listCustomerConfig.data,
  }),
  (dispatch) => ({
    getInvoice: (id) => {
      dispatch(getInvoice(id));
    },
    getCutomerConfig: (id) => {
      dispatch(getListCustomerConfig(id));
    },
  })
)(Invoice);
