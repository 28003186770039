import {
  takeLatest,
  call,
  put,
  all
} from 'redux-saga/effects'
import {
  actionType as TYPE
} from './actions'
import { getDetail } from 'apis/schedule_tracking'

function* getDetailSaga(action){
  const { id } = action
  try {
    const response = yield call(getDetail, id)
    if(response.code === 'success'){
      yield put({ type: TYPE.DETAIL.SUCCESS, ...response })
    }else{
        yield put({ type: TYPE.DETAIL.ERROR, response })
    }
  } catch(error){
    yield all([
      yield put({ type: TYPE.DETAIL.ERROR, error })
    ])
  }
}

function* watcher(){
  yield takeLatest(TYPE.DETAIL.REQUEST, getDetailSaga)
}

export default watcher