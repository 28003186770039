import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import { getDetail } from "apis/transactions";
import {replace} from "connected-react-router";

function* getDetailSaga(action) {
  const { id } = action;

  try {
    const response = yield call(getDetail, id);
    if (response.code === "success") {
      yield put({ type: TYPE.DETAIL_TRANSACTION.SUCCESS, ...response });
    } else {
      yield all([
        yield put({ type: TYPE.DETAIL_TRANSACTION.ERROR, error: {message: 'Not found transaction'} }),
        yield put(replace(`/transactions`))
      ])
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.DETAIL_TRANSACTION.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.DETAIL_TRANSACTION.REQUEST, getDetailSaga);
}

export default watcher;
