import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { Radio, Button, Modal, Space, Tooltip, Affix } from 'antd';
import Draggable from 'react-draggable';

import { renderInputText, renderSwitchAnt, renderSelectAnt } from 'utils/Form';
import { faBorderNone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderImageAntS3 from 'utils/Form/renderImageAntS3';
import { GMButton } from 'components/Button';
const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.replace(/\s+/g, '').length === 0) {
    errors.name = 'Please enter your mockup name';
  }
  if (!values.frontImg || values.frontImg.length === 0) {
    errors.frontImg = 'Please upload front mockup';
  }
  if (!values.backImg || values.backImg.length === 0) {
    errors.backImg = 'Please upload back mockup';
  }
  if (!values.expId || values.expId === '0') {
    errors.expId = 'Please choose product to customize mockup.';
  }
  return errors;
};

let FormCreateMockup = ({
  handleSubmit,
  exproducts,
  product_style_selected,
  frontImage,
  backImage,
  changeFieldValue,
  profile,
  ...props
}) => {
  const [isFrontOrBack, setIsFrontOrBack] = useState('Front');
  const [showModalSeting, setShowModalSeting] = useState();
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  const myRef = React.createRef();

  const handleChangeProductStyle = (value) => {
    setPosition({ x: 0, y: 0 });
    const product = exproducts.data_obj[value];
    if (product) {
      changeFieldValue('boxFrontTop', product.boxFrontTop);
      changeFieldValue('boxFrontLeft', product.boxFrontLeft);

      changeFieldValue('boxBackTop', product.boxBackTop);
      changeFieldValue('boxBackLeft', product.boxBackLeft);
    }
  };
  const handleOpenSettingFor = (type) => {
    setPosition({ x: 0, y: 0 });
    setShowModalSeting(type);
    setIsFrontOrBack(type);
  };
  const handleCopyPosition = () => {
    const container_width = myRef.current.clientWidth;
    const container_height = myRef.current.clientHeight;
    const source = showModalSeting === 'Front' ? 'Back' : 'Front';
    const x_distance =
      props[`box${source}Left`] - props[`box${showModalSeting}Left`];
    const y_distance =
      props[`box${source}Top`] - props[`box${showModalSeting}Top`];
    const x = (x_distance * container_width) / 100;
    const y = (y_distance * container_height) / 100;
    setPosition({
      x,
      y,
    });
  };
  const handleSavePosition = () => {
    const container_width = myRef.current.clientWidth;
    const container_height = myRef.current.clientHeight;

    const y_pixel =
      (props[`box${showModalSeting}Top`] / 100) * container_height + position.y;
    const x_pixel =
      (props[`box${showModalSeting}Left`] / 100) * container_width + position.x;

    changeFieldValue(
      `box${showModalSeting}Top`,
      Math.abs(Math.round((y_pixel / container_height) * 100))
    );
    changeFieldValue(
      `box${showModalSeting}Left`,
      Math.abs(Math.round((x_pixel / container_width) * 100))
    );
    setShowModalSeting();
  };
  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  let img_preview = product_style_selected
    ? isFrontOrBack === 'Front'
      ? product_style_selected.frontImg
      : product_style_selected.backImg
    : '';

  const img_setting =
    product_style_selected &&
    (showModalSeting === 'Front' ? frontImage : backImage);

  let draggable_style = {
    border: '1px dashed #333',
    position: 'absolute',
  };
  if (product_style_selected) {
    draggable_style = {
      ...draggable_style,
      top: props[`box${showModalSeting}Top`] + '%',
      left: props[`box${showModalSeting}Left`] + '%',
      width: product_style_selected[`box${showModalSeting}Width`] + '%',
      height: product_style_selected[`box${showModalSeting}Height`] + '%',
    };
  }
  return (
    <div className={'create-mockup'}>
      <Modal
        title="Configuration position"
        bodyStyle={{ position: 'relative' }}
        width={600}
        visible={!!showModalSeting}
        onCancel={() => setShowModalSeting()}
        destroyOnClose
        footer={
          <Space>
            <button
              type="button"
              className="btn btn-outline-info"
              onClick={handleCopyPosition}
            >
              Copy the {showModalSeting === 'Front' ? 'back' : 'front'} position
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => setShowModalSeting()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleSavePosition}
            >
              Save Position
            </button>
          </Space>
        }
      >
        <div className="position-relative">
          <div
            ref={myRef}
            className="position-absolute"
            style={{ top: 0, left: 0, bottom: 0, right: 0 }}
          >
            <Draggable onDrag={handleDrag} position={position} bounds="parent">
              <div style={draggable_style}></div>
            </Draggable>
          </div>
          {img_setting && (
            <img src={img_setting} className="w-100" alt="setting" />
          )}
        </div>
      </Modal>
      <form onSubmit={handleSubmit}>
        <Field name="boxFrontTop" component="input" type="hidden" />
        <Field name="boxFrontLeft" component="input" type="hidden" />
        <Field name="boxBackTop" component="input" type="hidden" />
        <Field name="boxBackLeft" component="input" type="hidden" />
        <div className="row mb-3">
          <div className="col-md-12 col-xl-6 mb-4">
            <div className="form-group tour1Mockup">
              <label className="font-weight-bold" htmlFor="mockup_name">
                Mockup name
              </label>
              <Field id="mockup_name" name="name" component={renderInputText} />
            </div>
            <div className="form-group tour2Mockup">
              <label className="font-weight-bold" htmlFor="mockup_style">
                Original product
              </label>
              <Field
                id="mockup_style"
                name="expId"
                component={renderSelectAnt}
                // options={exproducts.data}
                loading={exproducts.loading}
                onChange={handleChangeProductStyle}
                // options={(exproducts?.data || []).map((o) => {
                //   return {
                //     value: o?.value,
                //     label: o?.brand,
                //   };
                // })}
                options={[
                  {
                    value: '0',
                    label: (
                      <span style={{ color: 'black' }}>
                        -- Select a product --
                      </span>
                    ),
                  },
                  ...(exproducts?.data || []).map((o) => ({
                    value: o?.value,
                    label: o?.brand,
                  })),
                ]}
              />
            </div>
            <div className="form-group tour3Mockup">
              <div className="d-flex align-items-center">
                <Field
                  id="mockup_mark_default"
                  name="status"
                  component={renderSwitchAnt}
                />
                <label
                  htmlFor="mockup_mark_default"
                  className="ml-3 col-form-label"
                >
                  Mark as default
                </label>
              </div>
            </div>
            <div className="row tour4Mockup">
              <div className="col-12">
                <h5>Mockup</h5>
              </div>
              <div className="col-sm-6">
                <Field
                  id="mockup_front"
                  name="frontImg"
                  component={renderImageAntS3}
                  cusId={profile.id}
                  customPrefix={'mockup'}
                  showUploadList={false}
                  className="text-center"
                  imgWidth="100%"
                  typeUpload="upload-custom-mockup"
                >
                  <div className="small">
                    <b>
                      FRONT CUSTOM MOCKUP
                      <br />
                      Choose a design
                    </b>
                    <div className="text-muted mb-3">(.png only)</div>
                    <button
                      type="button"
                      className="btn btn-lg btn-primary rounded-pill font-size-14"
                    >
                      Choose file
                    </button>
                  </div>
                </Field>
                {frontImage && (
                  <Tooltip
                    title={
                      !product_style_selected
                        ? 'Please select product style'
                        : ''
                    }
                  >
                    <div className="text-center">
                      <GMButton
                        disabled={!product_style_selected}
                        onClick={() => handleOpenSettingFor('Front')}
                        htmlType="button"
                        type="link"
                      >
                        Settings
                      </GMButton>
                    </div>
                  </Tooltip>
                )}
              </div>
              {product_style_selected &&
                product_style_selected.backImg &&
                (product_style_selected.printLocation.indexOf('back') > -1 ||
                  product_style_selected.printLocation.indexOf('both') >
                    -1) && (
                  <div className="col-sm-6">
                    <Field
                      id="mockup_back"
                      name="backImg"
                      component={renderImageAntS3}
                      cusId={profile.id}
                      customPrefix={'mockup'}
                      showUploadList={false}
                      className="text-center"
                      imgWidth="100%"
                      typeUpload="upload-custom-mockup"
                    >
                      <div className="small">
                        <b>
                          Back custom mockup
                          <br />
                          Choose a design
                        </b>
                        <div className="text-muted mb-3">(.png only)</div>
                        <button
                          type="button"
                          className="btn btn-lg btn-primary rounded-pill font-size-14"
                        >
                          Choose file
                        </button>
                      </div>
                    </Field>
                    {backImage && (
                      <Tooltip
                        title={
                          !product_style_selected
                            ? 'Please select product style'
                            : ''
                        }
                      >
                        <div className="text-center">
                          <GMButton
                            disabled={!product_style_selected}
                            onClick={() => handleOpenSettingFor('Back')}
                            htmlType="button"
                            type="link"
                          >
                            Settings
                          </GMButton>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                )}
            </div>
          </div>
          <div className="col-md-12 col-xl-6 tour5Mockup">
            <div style={{ maxWidth: 400, margin: '0 auto' }}>
              <h5 className="mb-3 text-center">Mockup Original</h5>
              <div className="border border-dashed p-4 mb-2 text-center">
                <div className="position-relative">
                  {img_preview ? (
                    <img src={img_preview} className="w-100" alt="preview" />
                  ) : (
                    <div>
                      <FontAwesomeIcon
                        className="fa-10x color-light"
                        icon={faBorderNone}
                      />
                      <br />
                      Please select product style
                    </div>
                  )}
                </div>
              </div>
              <div className="text-center">
                <Radio.Group
                  value={isFrontOrBack}
                  onChange={(e) => setIsFrontOrBack(e.target.value)}
                  buttonStyle="solid"
                >
                  <GMButton value="Front" className="mr-2" type="button">
                    Front{' '}
                  </GMButton>
                  {product_style_selected &&
                    product_style_selected.backImg &&
                    (product_style_selected.printLocation.indexOf('back') >
                      -1 ||
                      product_style_selected.printLocation.indexOf('both') >
                        -1) && (
                      <GMButton value="Back" type="button">
                        Back
                      </GMButton>
                    )}
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {/* <GMButton
            className=" mr-3"
            color={'SecondaryGray'}
            onClick={() => {
              this.props.history.push('/products/mockups');
            }}
            type="button"
          >
            Cancel
          </GMButton> */}
          <GMButton>Create</GMButton>
        </div>
      </form>
    </div>
  );
};

FormCreateMockup.propTypes = {};

FormCreateMockup = reduxForm({
  form: 'FormCreateMockup',
  enableReinitialize: true,
  validate,
})(FormCreateMockup);
const selector = formValueSelector('FormCreateMockup');
FormCreateMockup = connect(
  (state, props) => {
    return {
      product_style_selected:
        props.exproducts.data_obj[selector(state, 'expId')],
      frontImg: selector(state, 'frontImage'),
      backImg: selector(state, 'backImage'),
      boxFrontTop: selector(state, 'boxFrontTop'),
      boxFrontLeft: selector(state, 'boxFrontLeft'),
      boxBackTop: selector(state, 'boxBackTop'),
      boxBackLeft: selector(state, 'boxBackLeft'),
    };
  },
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change('FormCreateMockup', field, value));
    },
  })
)(FormCreateMockup);
export default FormCreateMockup;
