import React from "react";
import icon_duplicate from "assets/images/ico-duplicate.png";
import { Tooltip } from "antd";
import logoIcon from 'images/icons/api.png';
import { ServiceLabel } from "constants/service-label";
import { IconWrapper } from "./RenderOrderPlatformInfo.styled";

export const replaceIcon = (src) => {
  if (`${src}`.indexOf('icons/ico_api.png') !== -1) {
    return logoIcon;
  }
  return src;
}

function RenderOrderPlatformInfo({ order = {}, isOnlyShowIcon = false }) {
  return (
    <>
      {isOnlyShowIcon ? (
        <Tooltip
          title={
            order.storeIntegration && order.storeIntegration.showName
              ? order.storeIntegration.showName
              : "N/A"
          }
          placement="right"
        >
          <IconWrapper>
            <img src={ServiceLabel[order?.platform?.name?.toLowerCase()] || ServiceLabel['n/a']} alt={order?.platform?.name} />
          </IconWrapper>
        </Tooltip>
      ) : (
        <div className="d-flex flex-column justify-content-start align-items-start">
          <div>
            <p className="mb-0" style={{ backgroundColor: "#efefef" }}>
              {order?.platform?.icon ? (
                <img
                  src={replaceIcon(order.platform.icon)}
                  style={{ width: "25px" }}
                  alt=""
                />
              ) : (
                <img src={icon_duplicate} style={{ width: "25px" }} alt="" />
              )}
            </p>
          </div>
          <div>
            <p className="mb-0 colorTextOrder">
              {order.storeIntegration &&
                order.storeIntegration.backendType === "shopify" &&
                order.resContent &&
                order.resContent.id &&
                order.storeIntegration.url && (
                  <a
                    href={`https://${order.storeIntegration.url}/admin/orders/${order.resContent.id}`}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {parseInt(order.platformId) === 1
                      ? `#${order.resContent.order_number}`
                      : order.name}
                  </a>
                )}
              {order.onlineId}
            </p>
          </div>
          <div className="colorTextOrder">
            {order.onlineName && (
              <p className="m=0">Order #{order.onlineName}</p>
            )}
          </div>
          <div>
            <p className="mb-0 colorTextOrder">
              {order.storeIntegration && order.storeIntegration.showName
                ? order.storeIntegration.showName
                : "N/A"}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
export default React.memo(RenderOrderPlatformInfo, (prevProps, nextProps) => {
  return JSON.stringify(prevProps?.order) !== JSON.stringify(nextProps?.order);
});