import {
  GRAY_300, GRAY_600, PRIMARY_500
} from "components/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
  }
  svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    stroke: ${GRAY_300};
    margin: 0 12px;
  }
  li {
    a, span {
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: ${GRAY_600};
    }
    &.last {
      span {
        color: ${PRIMARY_500};
        font-weight: 600;
      }
    }
  }
`;
