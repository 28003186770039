import React from "react";
import getMomentInstance from "utils/moment";
import { GMButton } from "components/Button";
const timeOptions = [
  {
    text: "Today",
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ["day"],
      null,
      null,
      null,
      null,
      null
    ),
    end: getMomentInstance(
      null,
      0,
      false,
      false,
      null,
      ["day"],
      1,
      "day",
      null,
      null
    ),
  },
  {
    text: "Yesterday",
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ["day"],
      null,
      null,
      null,
      1,
      "day"
    ),
    end: getMomentInstance(
      null,
      0,
      false,
      false,
      null,
      null,
      null,
      null,
      null,
    ),
  },
  {
    text: "This week",
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ["week"],
      null,
      null,
      null,
      null,
      null
    ),
    end: getMomentInstance(
      null,
      0,
      false,
      false,
      null,
      ["week"],
      null,
      null,
      null,
      null
    ),
  },
  {
    text: "Last week",
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ["week"],
      null,
      null,
      null,
      7,
      "days"
    ),
    end: getMomentInstance(
      null,
      0,
      false,
      false,
      null,
      ["week"],
      null,
      null,
      7,
      "days"
    ),
  },
  {
    text: "This month",
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ["month", "day"],
      null,
      null,
      null,
      null,
      null
    ),
    end: getMomentInstance(
      null,
      0,
      false,
      false,
      null,
      ["month", "day"],
      null,
      null,
      null,
      null
    ),
  },
  {
    text: "Last month",
    start: getMomentInstance(
      null,
      0,
      false,
      false,
      ["month", "day"],
      null,
      null,
      null,
      1,
      "month"
    ),
    end: getMomentInstance(
      null,
      0,
      false,
      false,
      null,
      ["month", "day"],
      null,
      null,
      1,
      "month"
    ),
  },
  {
    text: "Custom",
    start: null,
    end: null,
  },
];
function RenderTimeOptions({
  changeFieldValue = null,
  inputName = "date_range",
  ...props
}) {
  return (
    <>
      {timeOptions.map((option, index) => (
        <GMButton
          key={index}
          className="mr-2 mb-2"
          size="sm"
          color="SecondaryGray"
          onClick={() => {
            if (typeof changeFieldValue === "function") {
              changeFieldValue(inputName, [option.start, option.end]);
            }
          }}
        >
          {option.text}
        </GMButton>
      ))}
    </>
  );
}
export default React.memo(RenderTimeOptions);