/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ReactComponent as IconEmail } from 'images/icons/mail-01.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from 'images/icons/arrow-left.svg';
import { forgetPassword } from '../actions';

export default (props) => {
  const serverSuccessData = useSelector(
    (state) => state.auth.forgetPassword.serverSuccessData
  );
  const dispatch = useDispatch();
  const resend = (evt) => {
    evt.preventDefault();
    dispatch(forgetPassword(serverSuccessData));
    return false;
  };
  return (
    <>
      <div>
        <div className="text-center">
          <div className="icon">
            <IconEmail />
          </div>
        </div>
        <h3 className="text-center form-header">Check your email</h3>
        <p className="form-message">
          We have sent a password reset email to{' '}
          <span>{serverSuccessData?.email}</span>. Please click on the link in
          the email to reset your password. The link will be expired in 7 days
        </p>
      </div>
      <div className="form-resend-content">
        <div className="form-group">
          Didn’t receive the email?{' '}
          <a href="#" onClick={resend}>
            Click to resend
          </a>
          {/* <GMTextbox
            name="email"
            placeholder="Email"
            label="Email"
            className="email"
            showError
          /> */}
        </div>
        <div className="text-center mb-3 fontSize-child">
          <Link to="/auth/login" className="back-cta">
            <ArrowLeft />
            Back to log in
          </Link>
        </div>
      </div>
    </>
  );
};
