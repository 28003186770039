import styled, { createGlobalStyle } from 'styled-components';
import PageContainer from 'components/PageContainer';
import {
  BASE_BLACK,
  BASE_WHITE,
  BLUE_600,
  ERROR_100,
  ERROR_500,
  ERROR_600,
  GRAY_100,
  GRAY_200,
  GRAY_300,
  GRAY_400,
  GRAY_50,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_800,
  GRAY_900,
  GRAY_BLUE_100,
  PRIMARY_100,
  PRIMARY_500,
  PRIMARY_600,
  SHADOW_LG_0_03,
  SHADOW_LG_0_08,
  SHADOW_XS,
  SUCCESS_100,
  SUCCESS_50,
  SUCCESS_500,
  SUCCESS_600,
  SUCCESS_700,
  WARNING_500,
} from 'components/colors';

export const Wrapper = styled(PageContainer)`
  svg {
    &.plusicon {
      width: 20px;
      height: 20px;
      stroke: ${BASE_WHITE};
      margin-right: 12px;
    }
  }
  .order-table {
    border-radius: 8px;
    border: 1px solid ${GRAY_200};
  }
  table {
    .ant-table-selection-extra {
      display: none;
    }
    .col {
      &-tracking {
        display: flex;
        flex-direction: column;
        a {
          font-size: 13px;
        }
        > div {
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
        }
      }
    }
  }
  .addnew {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .more-action {
    position: sticky;
    bottom: 0;
    .fixed-button {
      z-index: 5;
      margin-top: -108px;
    }
  }
  .gm-table.more-action {
    .ant-table-container .ant-table-body {
      padding-bottom: 70px;
    }
  }
  .filter {
    &-section {
      display: flex;
      align-items: center;
      .input span.icon-after {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .input span.icon-after svg {
        &.clear {
          stroke: ${GRAY_400};
          width: 16px;
          height: 16px;
        }
        &.search-tooltip {
          stroke: ${GRAY_600};
          width: 18px;
          height: 18px;
        }
      }
    }
    &-button {
      line-height: 44px;
      height: 44px;
      background: ${BASE_WHITE};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      border-radius: 8px;
      margin-left: 12px;
      padding: 0 18px;
      vertical-align: middle;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      svg.filter {
        stroke: ${GRAY_700};
        margin: 0;
        margin-right: 8px;
      }
    }
  }
  .ant-tabs-nav {
    margin-top: 0;
  }
  .gmtextbox {
    width: 100%;
    max-width: 400px;
  }
  .ant-tabs.ant-tabs-top {
    .ant-tabs-nav {
      &::before {
        border-bottom: 1px solid ${GRAY_200};
      }
      .ant-tabs-tab {
        font-size: 0;
        margin-right: 8px;
        margin-left: 8px;
        .ant-tabs-tab-btn {
          padding-bottom: 10px;
          color: ${GRAY_500};
          height: auto;
          padding: 0 4px;
          padding-bottom: 12px;
          font-size: 16px;
        }
        &-active {
          .ant-tabs-tab-btn {
            padding: 0 4px;
            color: ${PRIMARY_500};
            font-weight: 600;
            background-color: unset;
            padding-bottom: 12px;
          }
        }
      }
      .ant-tabs-ink-bar {
        border-bottom: 2px solid ${PRIMARY_500};
      }
    }
  }

  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 16px 8px;
  }

  .ant-table-sticky-header {
    top: 64px!important;
  }
  td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last:before {
    position: absolute;
    left: -2px;
    z-index: -1;
    content: ' ';
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    background: inherit;
  }
  .table table {
    thead th {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      border: none;
      height: 44px;
      vertical-align: middle;
      background-color: ${GRAY_50};
      border-bottom: 1px solid ${GRAY_200};
      font-weight: 600;

      position: sticky;
      top: 0;
    }
    tbody td {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      border-bottom: 1px solid ${GRAY_200};
      border-top: none;
      vertical-align: middle;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .icon-wrapper {
      width: 40px;
      height: 40px;
      background: ${PRIMARY_100};
      border-radius: 20px;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .ant-tag,
    .tag {
      height: 22px;
      border: none;
      border-radius: 16px;
      line-height: 150%;
      padding: 2px 8px;
    }
    .shipping {
      display: flex;
      align-items: center;
      gap: 12px;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
      &.secondary span {
        background-color: ${PRIMARY_100};
        stroke: ${PRIMARY_600};
      }
      &.red span {
        background-color: ${ERROR_100};
        stroke: ${ERROR_600};
      }
      &.success span {
        background-color: ${SUCCESS_100};
        stroke: ${SUCCESS_600};
      }
      &.dark span {
        background-color: ${GRAY_700};
        stroke: ${BASE_WHITE};
      }
    }
    .price {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_900};
    }
  }

  .topcta .ctas {
    display: flex;
    flex-direction: row;
    gap: 12px;

    .moreactions {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }

  padding-bottom: ${50 + 20}px;
  .gmpagination {
    height: 50px;
    width: 100%;
    padding: 0 52px;
    gap: 12px;
    align-items: center;
    border-top: 1px solid ${GRAY_BLUE_100};
    padding-left: 1px;
    padding-right: 1px;
    z-index: 5;
    &-innerwrapper {
      background-color: ${BASE_WHITE};
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      align-items: center;
      svg {
        stroke: ${GRAY_600};
        width: 20px;
        height: 20px;
      }
    }
    &-rrp {
      display: flex;
      .ant-dropdown-trigger {
        color: ${GRAY_600};
        font-weight: 600;
      }
    }
    &-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${GRAY_600};
      span {
        font-size: inherit;
      }
    }
    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      span, a {
        display: inline-block;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${BASE_BLACK};
      }
      a {
        &:hover {
          color: ${PRIMARY_500};
        }
        &.current {
        color: ${PRIMARY_500};
        pointer-events: none;
      }
      }
    }
    &-btn {
      background-color: transparent;
      padding: 10px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      &:disabled {
        opacity: 0.6;
      }
      &:hover {
        svg {
          stroke: ${BLUE_600};
        }
      }
    }
    &-separator {
      width: 25px;
      display: flex;
      justify-content: center;
      &:after {
        display: block;
        font-size: 0;
        content: ' ';
        height: 20px;
        width: 1px;
        background-color: ${GRAY_200};
      }
    }
  }
  
  .toppager .gmpagination {
    border: none;
    &-innerwrapper {
      padding-left: 0;
    }
  }
  .ant-table .ant-table-container::before,
  .ant-table .ant-table-container::after {
    /* display: none; */
  }
  .ant-table-container {
    /* display: flex; */
  }
  td .tags {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    gap: 4px;
    svg {
      width: 16px;
      height: 16px;
      stroke: ${GRAY_400};
      &.active {
        stroke: ${SUCCESS_500};
      }
    }
  }
  .sync-order-modal {
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 8px;
    }
  }
`;

export const ColTracking = styled.div`
  > div.tracking-info {
    margin-top: 0;
  }
  .flip-card {
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  .tracking-info {
    margin-top: 0;
    svg {
      &.shipping {
        stroke: ${PRIMARY_600};
      }
      &.warehouse {
        stroke: ${GRAY_600};
      }
      &.copy {
        stroke: ${PRIMARY_500};
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
      &.copied {
        stroke: ${SUCCESS_500};
        width: 16px;
        height: 16px;
        cursor: default;
      }
      &.chevdown {
        stroke: ${GRAY_500};
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .icon {
      width: 46px;
      min-width: 46px;
      height: 32px;
      border: 1px solid ${GRAY_100};
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .label-info {
      display: flex;
      flex-direction: column;
    }

    .shipping-group {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
    }
    .info-group {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      .type {
        display: flex;
        gap: 6px;
        align-items: center;
      }
    }
    .tracking-code {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }
`;
export const ShippingStatus = styled.div`
  display: flex;
`;

export const ModalTableWrapper = styled.div`
  margin-top: 24px;
`;

export const TagNewTracking = styled.span`
  display: flex;
  background: ${SUCCESS_50};
  border-radius: 16px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${SUCCESS_700};
`;

export const TrackingWrapper = styled.a`
  display: flex;
  gap: 6px;
`;
export const ContextMenuWrapper = styled.div`
  background: ${BASE_WHITE};
  border: 1px solid ${GRAY_200};
  box-shadow: 0px 12px 16px -4px ${SHADOW_LG_0_08},
    0px 4px 6px -2px ${SHADOW_LG_0_03};
  border-radius: 8px;
  padding: 6px;
  a {
    height: 38px;
    border-radius: 6px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    color: ${GRAY_700};
    &:hover {
      background: ${GRAY_100};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: ${GRAY_700};
      &.sync {
        fill: ${BASE_BLACK};
      }
    }
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    stroke: ${GRAY_700};
    &.sync {
      fill: ${BASE_BLACK};
    }
  }
  .sync-order:hover {
    background-color: ${GRAY_100};
    border-radius: 6px;
    cursor: pointer;
  }
`;
export const ContextMenuWrapper2 = styled.div`
  background: ${BASE_WHITE};
  border: 1px solid ${GRAY_200};
  box-shadow: 0px 12px 16px -4px ${SHADOW_LG_0_08},
    0px 4px 6px -2px ${SHADOW_LG_0_03};
  border-radius: 8px;
  padding: 6px;
  span.itemcta {
    height: 38px;
    border-radius: 6px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    color: ${GRAY_700};
    cursor: pointer;
    &:hover {
      background: ${GRAY_100};
    }
  }
`;

export const SVGWrapper = styled.span`
  display: block;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    stroke: ${GRAY_500};
    cursor: pointer;
    /* &:hover {
      stroke: ${GRAY_800};
    } */
    &.sync {
      fill: ${BASE_BLACK};
    }
  }
  &.ant-dropdown-open, &:hover {
    svg {
      stroke: ${GRAY_800};
    }
  }
`;
export const FilterWrapper = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-affix-wrapper,
  .ant-picker {
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 8px;
    }
  .ant-dropdown-arrow {
    display: none;
  }
  .ant-picker.ant-picker-large{
    border-radius: 8px;
    height: 44px;
  }
  .ant-modal-close-x{
    display: flex;
    align-items:center;
    padding-left: 10px;
  }
  .ant-modal-header{
    border-bottom: none;

    .ant-modal-title{
      font-size: 18px;
      font-weight: 600;
    }
  }
  .warning-modal {
    .ctas {
      display: flex;
      justify-content: flex-end;
    }
  }
  .ant-checkbox-wrapper {}
  .orders-tabs-menu {
    .ant-dropdown-menu-item {
      &, & > a {
        font-size: 16px;
        color: ${GRAY_500};
      }
    }
  }
  .ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  border-radius: 8px;
}
.ant-modal-content {
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    border-radius: 8px;
  }
  .ant-modal-header {
  border-radius: 8px 8px 0 0;
}

`;
export const WrapperText = styled.div`
  color: ${ERROR_500};
`;
