import React from "react";
import { redirectPaypal } from "apis/payment_menthod";
import { useDispatch } from "react-redux";
import { setLoading } from "scenes/BillingPaymentAccount/actions";
import { notification } from "antd";
import { GMButton } from 'components/Button';

function AddPaypalButton({ getPaypalAccounts, ...rest }) {
  const dispatch = useDispatch();
  const handleOnAddPaypalAccount = () => {
    dispatch(setLoading(true));
    redirectPaypal()
      .then((response) => {
        dispatch(setLoading(false));
        if (response?.code === "success" && response?.data?.url) {
          notification.success({
            message: "Please wait for a minute ...",
          });
          window.location.href = response?.data?.url;
        } else {
          notification.warn({
            message: response?.[0]?.clientMsg || "Register Paypal account failed.",
          });
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
      });
  }
  return (
    <GMButton
      type="button"
      className="text-white rounded btn btn-primary mt-3 font-weight-bold"
      onClick={handleOnAddPaypalAccount}
      {...rest}
    >
      ADD PAYPAL
    </GMButton>
  );
}

export default React.memo(AddPaypalButton);