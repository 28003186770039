export const actionType = {
  UPDATE_PROFILE: {
    REQUEST: 'USERS.PROFILE.UPDATE.REQUEST',
    SUCCESS: 'USERS.PROFILE.UPDATE.SUCCESS',
    ERROR: 'USERS.PROFILE.UPDATE.ERROR',
  },
  REMOVE_AVATAR: {
    REQUEST: 'USERS.PROFILE.REMOVE_AVATAR.REQUEST',
    SUCCESS: 'USERS.PROFILE.REMOVE_AVATAR.SUCCESS',
    ERROR: 'USERS.PROFILE.REMOVE_AVATAR.ERROR',
  },
  LIST_COUNTRY: {
    REQUEST: 'COUNTRY.LIST.REQUEST',
    SUCCESS: 'COUNTRY.LIST.SUCCESS',
    ERROR: 'COUNTRY.LIST.FAIL',
  },
  DELETE_ACCOUNT: {
    REQUEST: 'DELETE_ACCOUNT.REQUEST',
    SUCCESS: 'DELETE_ACCOUNT.SUCCESS',
    ERROR: 'DELETE_ACCOUNT.FAIL',
  },

  CONNECT_SOCIAL_ACCOUNT: {
    REQUEST: 'CONNECT_SOCIAL_ACCOUNT.REQUEST',
    SUCCESS: 'CONNECT_SOCIAL_ACCOUNT.SUCCESS',
    ERROR: 'CONNECT_SOCIAL_ACCOUNT.FAIL',
  },
  DISCONNECT_SOCIAL_ACCOUNT: {
    REQUEST: 'DISCONNECT_SOCIAL_ACCOUNT.REQUEST',
    SUCCESS: 'DISCONNECT_SOCIAL_ACCOUNT.SUCCESS',
    ERROR: 'DISCONNECT_SOCIAL_ACCOUNT.FAIL',
  },
};
export const update = (params) => ({
  type: actionType.UPDATE_PROFILE.REQUEST,
  params,
});
export const removeAvatar = () => ({
  type: actionType.REMOVE_AVATAR.REQUEST,
});
export const deleteAccount = () => ({
  type: actionType.DELETE_ACCOUNT.REQUEST,
});
export const listCountry = () => ({
  type: actionType.LIST_COUNTRY.REQUEST,
});

export const connectSocialAction = (data) => ({
  type: actionType.CONNECT_SOCIAL_ACCOUNT.REQUEST,
  data,
});

export const disconnectSocialAction = (data) => ({
  type: actionType.DISCONNECT_SOCIAL_ACCOUNT.REQUEST,
  data,
});
