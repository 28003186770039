import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';
import { ORDER_DETAIL_REQUEST } from '../Detail/constants';
import {
  getList,
  getProfit,
  archiveOrder,
  approveOrder,
  unapproveOrder,
  paymentOrder,
  syncTrackingAmazon,
  syncTracking,
  verirfyAddress,
  forceVerifiedDelivery,
  countOrder,
  getListPaymentOrder,
  exportOrder,
} from 'apis/orders';
import { push } from 'connected-react-router';

function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getList, {
      'with[]': 'storeIntegration',
      'with[]': 'transaction',
      'with[]': 'orderPaymentExtendPendingAndProcessing',
      // "with[1]": "orderItems.campaign",
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}
function* exportOrdersSaga(action) {
  const { params } = action;
  try {
    const response = yield call(exportOrder, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.EXPORT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.EXPORT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.EXPORT.ERROR, error })]);
  }
}

function* getProfitSaga() {
  try {
    const response = yield call(getProfit);
    if (response.code === 'success') {
      yield put({ type: TYPE.GET_PROFIT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.GET_PROFIT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.GET_PROFIT.ERROR, error })]);
  }
}

function* archiveOrderSaga(action) {
  try {
    const { data } = action;
    const response = yield call(archiveOrder, data);
    const { order } = select();
    yield all([
      put({ type: TYPE.ARCHIVE_ORDER.SUCCESS, errors: response }),
      data?.from === 'detailOrder'
        ? put({
          type: ORDER_DETAIL_REQUEST,
          id: data?.orders?.[0],
        })
        : put({
          type: TYPE.LIST.REQUEST,
          params: order?.list?.orders?.params || { page: 1, perPage: 50 },
        }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.ARCHIVE_ORDER.ERROR, error })]);
  }
}

function* approveOrderSaga(action) {
  try {
    const { data } = action;
    const response = yield call(approveOrder, data);
    const { order } = select();
    yield all([
      put({ type: TYPE.APPROVE_ORDER.SUCCESS, errors: response }),
      data?.from === 'detailOrder'
        ? put({
          type: ORDER_DETAIL_REQUEST,
          id: data?.orders?.[0],
        })
        : put({
          type: TYPE.LIST.REQUEST,
          params: order?.list?.orders?.params || { page: 1, perPage: 50 },
        }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.APPROVE_ORDER.ERROR, error })]);
  }
}

function* unapproveOrderSaga(action) {
  try {
    const { data } = action;
    const response = yield call(unapproveOrder, data);
    const { order } = select();
    yield all([
      put({ type: TYPE.UNAPPROVE_ORDER.SUCCESS, errors: response }),
      data?.from === 'detailOrder'
        ? put({
          type: ORDER_DETAIL_REQUEST,
          id: data?.orders?.[0],
        })
        : put({
          type: TYPE.LIST.REQUEST,
          params: order?.list?.orders?.params || { page: 1, perPage: 50 },
        }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.UNAPPROVE_ORDER.ERROR, error })]);
  }
}

function* syncTrackingSaga(action) {
  try {
    const { data } = action;
    const response = yield call(syncTracking, data);
    const { order } = select();
    yield all([
      put({ type: TYPE.SYNC_TRACKING.SUCCESS, errors: response }),
      put({
        type: TYPE.LIST.REQUEST,
        params: order?.list?.orders?.params || { page: 1, perPage: 50 },
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.SYNC_TRACKING.ERROR, error })]);
  }
}

function* syncTrackingAmazonSaga(action) {
  try {
    const { data } = action;
    const response = yield call(syncTrackingAmazon, data);
    const { order } = select();
    yield all([
      put({ type: TYPE.SYNC_TRACKING_AMAZON.SUCCESS, errors: response }),
      put({
        type: TYPE.LIST.REQUEST,
        params: order?.list?.orders?.params || { page: 1, perPage: 50 },
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.SYNC_TRACKING_AMAZON.ERROR, error })]);
  }
}

function* verifyAddressSaga(action) {
  try {
    const { data } = action;
    const response = yield call(verirfyAddress, data);
    const { order } = select();
    yield all([
      put({ type: TYPE.VERIFY_ADDRESS.SUCCESS, errors: response }),
      put({
        type: TYPE.LIST.REQUEST,
        params: order?.list?.orders?.params || { page: 1, perPage: 50 },
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.VERIFY_ADDRESS.ERROR, error })]);
  }
}

function* forceVerifiedDeliverySaga(action) {
  try {
    const { data } = action;
    const response = yield call(forceVerifiedDelivery, data);
    const { order } = select();
    yield all([
      put({ type: TYPE.FORCE_VERIFIED_DELIVERY.SUCCESS, errors: response }),
      put({
        type: TYPE.LIST.REQUEST,
        params: order?.list?.orders?.params || { page: 1, perPage: 50 },
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.FORCE_VERIFIED_DELIVERY.ERROR, error })]);
  }
}

function* paymentOrderSaga(action) {
  const { cb } = action;
  try {
    const { data } = action;
    const response = yield call(paymentOrder, data);
    const { order } = select();
    yield all([
      put({ type: TYPE.PAYMENT_ORDER.SUCCESS, errors: response }),
      data?.from === 'detailOrder'
        ? put({
          type: ORDER_DETAIL_REQUEST,
          id: data?.orders?.[0],
        })
        : put({
          type: TYPE.LIST.REQUEST,
          params: order?.list?.orders?.params || { page: 1, perPage: 50 },
        }),
      put(push('/orders/list')),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.PAYMENT_ORDER.ERROR, error })]);
  } finally {
    if (typeof cb === 'function') {
      cb();
    }
  }
}

function* countOrderSaga(action) {
  try {
    const response = yield call(countOrder);
    if (response.code === 'success') {
      yield put({ type: TYPE.COUNT_ORDER.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.COUNT_ORDER.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.COUNT_ORDER.ERROR, error })]);
  }
}

function* getPaymentOrderListSaga({ payload }) {
  try {
    const response = yield call(getListPaymentOrder, {
      orders: payload?.orders,
    });
    if (response.code === 'success') {
      if (
        Object.keys(response?.data?.orderError).length !== 0 &&
        payload?.form === 'detail_order'
      ) {
        yield all([
          put({
            type: TYPE.PAYMENT_ORDER_LIST.SUCCESS,
            payload: response,
          }),
          put({
            type: TYPE.OPEN_MODAL_WARNING_PAYMENT,
            payload: response,
          }),
        ]);
      } else {
        yield all([
          put({
            type: TYPE.PAYMENT_ORDER_LIST.SUCCESS,
            payload: response,
          }),
          put(push('/orders/make-payment')),
        ]);
      }
    } else {
      yield all([
        put({ type: TYPE.PAYMENT_ORDER_LIST.ERROR, payload: response }),
        put(push('/orders/list')),
      ]);
    }
  } catch (error) {
    yield put({ type: TYPE.PAYMENT_ORDER_LIST.ERROR, payload: error });
  }
}

function* watcher() {
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga);
  yield takeLatest(TYPE.GET_PROFIT.REQUEST, getProfitSaga);
  yield takeLatest(TYPE.ARCHIVE_ORDER.REQUEST, archiveOrderSaga);
  yield takeLatest(TYPE.APPROVE_ORDER.REQUEST, approveOrderSaga);
  yield takeLatest(TYPE.UNAPPROVE_ORDER.REQUEST, unapproveOrderSaga);
  yield takeLatest(TYPE.VERIFY_ADDRESS.REQUEST, verifyAddressSaga);
  yield takeLatest(TYPE.EXPORT.REQUEST, exportOrdersSaga);
  yield takeLatest(
    TYPE.FORCE_VERIFIED_DELIVERY.REQUEST,
    forceVerifiedDeliverySaga
  );
  yield takeLatest(TYPE.PAYMENT_ORDER.REQUEST, paymentOrderSaga);
  yield takeLatest(TYPE.SYNC_TRACKING_AMAZON.REQUEST, syncTrackingAmazonSaga);
  yield takeLatest(TYPE.SYNC_TRACKING.REQUEST, syncTrackingSaga);
  yield takeLatest(TYPE.COUNT_ORDER.REQUEST, countOrderSaga);
  yield takeLatest(TYPE.PAYMENT_ORDER_LIST.REQUEST, getPaymentOrderListSaga);
}

export default watcher;
