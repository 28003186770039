import moment from 'moment-timezone';
import { default_config } from './options';
const settingSystem = localStorage.getItem('system_setting') ? JSON.parse(localStorage.getItem('system_setting')) : default_config;
// const settingSystem = default_config;

export const getMomentInstance2 = ({
  dateTime = null,
  unixDateTime = 0,
  isDateTimFormat = false,
  isDateFormat = false,
  start = null,
  end = null,
  add = null,
  addUnit = null,
  substract = null,
  substractUnit = null,
  instance = null,
}) => getMomentInstance(
  dateTime,
  unixDateTime,
  isDateTimFormat,
  isDateFormat,
  start,
  end,
  add,
  addUnit,
  substract,
  substractUnit,
  instance,
);

const getMomentInstance = (
  dateTime = null,
  unixDateTime = 0,
  isDateTimFormat = false,
  isDateFormat = false,
  start = null,
  end = null,
  add = null,
  addUnit = null,
  substract = null,
  substractUnit = null,
  instance = null
) => {
  let momentInstance = null;
  if (instance) {
    momentInstance = instance;
  } else {
    if (unixDateTime) {
      momentInstance = moment(unixDateTime * 1000);
    } else if (dateTime) {
      momentInstance = moment(dateTime);
    } else {
      momentInstance = moment();
    }
  }
  if (settingSystem.timezone) {
    momentInstance = momentInstance.tz(settingSystem.timezone);
  }
  if (start) {
    for (let index = 0; index < start.length; index++) {
      momentInstance = momentInstance.startOf(start[index]);
    }
  }
  if (end) {
    for (let index = 0; index < end.length; index++) {
      momentInstance = momentInstance.endOf(end[index]);
    }
  }
  if (add && addUnit) {
    momentInstance = momentInstance.add(add, addUnit);
  }
  if (substract && substractUnit) {
    momentInstance = momentInstance.subtract(substract, substractUnit);
  }
  if (isDateTimFormat) {
    momentInstance = momentInstance.format(settingSystem.dateTimeFormat);
  }
  if (isDateFormat) {
    momentInstance = momentInstance.format(settingSystem.dateFormat);
  }
  return momentInstance;
};

export default getMomentInstance;
