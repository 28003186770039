import { actionType as TYPE } from "./actions";
import { notification } from "antd";

const initialState = {
  issues: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  deleteIssue: {
    loading: false,
  },
  statistic: {
    loading: false,
    data: [],
  },
  detailIssue: {
    loading: false,
    data: {},
  },
};

function ListReducer(state = initialState, action) {
  // let data = [];
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        issues: {
          ...initialState.issues,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        issues: {
          ...state.issues,
          loading: false,
          data: action.data.listIssues,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        issues: {
          ...state.issues,
          loading: false,
        },
      };
    case TYPE.DELETE_ISSUE.REQUEST:
      return {
        ...state,
        deleteIssue: {
          ...initialState.deleteIssue,
          loading: true,
        },
      };
    case TYPE.DELETE_ISSUE.SUCCESS:
      notification.success({
        message: action.clientMsg,
        duration: 5,
      });
      return {
        ...state,
        deleteIssue: {
          ...state.deleteIssue,
          loading: false,
        },
      };
    case TYPE.DELETE_ISSUE.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Delete issue failed.",
        duration: 5,
      });
      return {
        ...state,
        deleteIssue: {
          ...initialState.deleteIssue,
          loading: false,
        },
      };
    case TYPE.STATISTIC.REQUEST:
      return {
        ...state,
        statistic: {
          ...state.statistic,
          loading: true,
        },
      };
    case TYPE.STATISTIC.SUCCESS:
      let data = {};
      for (let index = 0; index < action.data.length; index++) {
        data[action.data[index]["title"]] = action.data[index]["value"];
      }
      return {
        ...state,
        statistic: {
          ...state.statistic,
          loading: false,
          data: data,
        },
      };
    case TYPE.STATISTIC.ERROR:
      return {
        ...state,
        statistic: {
          ...state.statistic,
          loading: false,
        },
      };
    //Detail issue
    case TYPE.DETAIL_ISSUE.REQUEST:
      return {
        ...state,
        detailIssue: {
          ...initialState.detailIssue,
          loading: true,
        },
      };
    case TYPE.DETAIL_ISSUE.SUCCESS:

      return {
        ...state,
        detailIssue: {
          ...state.detailIssue,
          loading: false,
          data: action?.response?.data?.issue,
        },
      };
    case TYPE.DETAIL_ISSUE.ERROR:
      return {
        ...state,
        detailIssue: {
          ...state.detailIssue,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
