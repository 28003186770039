import React from 'react';
import { Steps, Checkbox, Spin } from 'antd';
import classNames from 'classnames';
import { NextStepsWrapper } from 'scenes/Dashboard/styled';
import { Tabs } from 'antd';
import Step from './Step';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStepsRequestAction } from 'scenes/Dashboard/actions';
import { markDevDocs } from 'apis/dashboard';
const { TabPane } = Tabs;

const dummyInfo = {
  csvImport: [
    {
      isDone: true,
      title: "Setup primary billing method",
      description: 'Set up a valid primary billing method that will be used to charge for your future transactions.',
      url: '/billing/method',
      cta: 'Add billing method',
      apiMap: 'hasPaymentPrimary',
    },
    {
      isDone: false,
      title: "Upload a CSV file",
      description: 'Orders can be uploaded via a CSV file. The file’s template for each platform can be found at order import page.',
      url: '/orders/import',
      cta: 'Import Orders',
      apiMap: 'uploadACsvFile',
    },
  ],
  apiIntegration: [
    {
      isDone: true,
      title: "Setup primary billing method",
      description: 'Set up a valid primary billing method that will be used to charge for your future transactions.',
      url: '/billing/method',
      cta: 'Add billing method',
      apiMap: 'hasPaymentPrimary',
    },
    {
      isDone: false,
      title: "Retrieve API key",
      description: 'Your API Key can be found at the integrations page. There you can view, create, and delete your keys.',
      url: '/settings/api-information',
      cta: 'Retrieve Key',
      apiMap: 'retrieveApiKey',
    },
    {
      isDone: false,
      title: "View the dev docs",
      description: 'Follow the documentation and contact Gearment if you have any questions.',
      url: 'https://api.gearment.com/',
      cta: 'Read Docs',
      apiMap: 'viewTheDevDocs',
      bonusAction: (cb) => {
        markDevDocs().then(() => {
          if (typeof cb === 'function') {
            cb();
          }
        }).catch(err => console.log(err));
      },
    },
    {
      isDone: false,
      title: "Send an order request",
      description: 'Send a request including product and customer details by the programming language of your choice and we\'ll handle the order fulfillment.',
      // url: 'https://api.gearment.com/',
      // cta: 'View the dev docs',
      apiMap: 'sendAnOrderRequest',
    },
  ],
  // Manually create order
  manuallyCreateOrder: [
    {
      isDone: true,
      title: "Setup primary billing method",
      description: 'Set up a valid primary billing method that will be used to charge for your future transactions.',
      url: '/billing/method',
      cta: 'Add billing method',
      apiMap: 'hasPaymentPrimary',
    },
    {
      isDone: false,
      title: "Create Order",
      description: 'Create a new order with your customized products by clicking the <b>Create new order</b> button at the order page, then submit it for fulfillment and leave the rest to us.',
      url: '/orders/create',
      cta: 'Create Order',
      apiMap: 'createOrder',
    },
  ],
  // Sync Order 
  syncOrder: [
    {
      isDone: true,
      title: "Setup primary billing method",
      description: 'Set up a valid primary billing method that will be used to charge for your future transactions.',
      url: '/billing/method',
      cta: 'Add billing method',
      apiMap: 'hasPaymentPrimary',
    },
    {
      isDone: false,
      title: "Connect your store",
      description: 'Connect your store to Gearment to get started. Gearment supports the ability to connect multiple shops to a single Gearment account.',
      url: '/stores',
      cta: 'Connect your store',
      apiMap: 'connectYourStore',
    },
    {
      isDone: false,
      title: "Fulfill an order",
      description: 'Once a customer creates an order it will sync to the Gearment system for fulfillment.',
      // url: '/stores',
      // cta: 'Connect your store',
      apiMap: 'fulfillOrder',
    },
  ],
};

function Index({ steps, loading, ...props }) {
  const d = useDispatch();
  const userSteps = useSelector(
    (s) => s.dashboard.userSteps.status
  );
  useEffect(() => {
    d(getUserStepsRequestAction());
  }, []);
  
  return (
    <NextStepsWrapper>
      <h3 className="title">
        How do you want to fulfill your orders?
      </h3>
      <p className="description">There are many ways to fulfill with Gearment.</p>
      <div className="inner-wrapper">
        <Tabs defaultActiveKey="1">
          <TabPane tab="CSV Import" key="1">
            <Step info={dummyInfo.csvImport} data={userSteps?.csvImport} />
          </TabPane>
          <TabPane tab="API integration" key="2">
            <Step info={dummyInfo.apiIntegration} data={userSteps?.apiIntegration} />
          </TabPane>
          <TabPane tab="Manually create order" key="3">
            <Step info={dummyInfo.manuallyCreateOrder} data={userSteps?.manuallyCreateOrder} />
          </TabPane>
          <TabPane tab="Sync Order" key="4">
            <Step info={dummyInfo.syncOrder} data={userSteps?.syncOrder} />
          </TabPane>
          {/* <TabPane tab="Tab 3" key="3">
            Tab 3
          </TabPane> */}
        </Tabs>
      </div>
    </NextStepsWrapper>
  );
}

export default React.memo(Index);
