import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import { getListShipping } from "apis/notifications";

function* getListShippingSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListShipping, params);
    if (response.code === "success") {
      yield put({
        type: TYPE.LIST_NOTIFY_SHIPPING.SUCCESS,
        ...response,
      });
    } else {
      yield put({ type: TYPE.LIST_NOTIFY_SHIPPING.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_NOTIFY_SHIPPING.ERROR, error })]);
  }
}
// function* getListNotiReadSaga(action) {
//   const { params } = action;
//   try {
//     const response = yield call(getList, params);
//     if (response.code === "success") {
//       yield put({ type: TYPE.READ.SUCCESS, ...response });
//     } else {
//       yield put({ type: TYPE.READ.ERROR, response });
//     }
//   } catch (error) {
//     yield all([yield put({ type: TYPE.READ.ERROR, error })]);
//   }
// }
// function* markAsReadSaga() {
//   try {
//     const response = yield call(markAsRead);
//     if (response.code === "success") {
//       const { notification } = yield select();
//       yield all([
//         put({ type: TYPE.MARK_AS_READ.SUCCESS, ...response }),
//         put({
//           type: TYPE.LIST_NOTIFY_SHIPPING.REQUEST,
//           params: notification.list.notifications.params,
//         }),
//       ]);
//     } else {
//       yield put({ type: TYPE.MARK_AS_READ.ERROR, response });
//     }
//   } catch (error) {
//     yield all([yield put({ type: TYPE.MARK_AS_READ.ERROR, error })]);
//   }
// }

function* watcher() {
  yield takeLatest(TYPE.LIST_NOTIFY_SHIPPING.REQUEST, getListShippingSaga);
  // yield takeLatest(TYPE.READ.REQUEST, getListNotiReadSaga);
  // yield takeLatest(TYPE.MARK_AS_READ.REQUEST, markAsReadSaga);
}

export default watcher;
