import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';

const { Option } = Select;

export default ({
  input,
  mode = 'default',
  invalid,
  size,
  onChoose,
  inputProps = {},
  meta: { error, submitFailed },
  classNameContainer = '',
  data = [],
  placeholder = '',
  showError = false,
  disabled,
}) => {
  const [options, setOptions] = useState([
    { label: '-- Select product --', value: '0' },
  ]);
  useEffect(() => {
    setOptions([
      { label: '-- Select product --', value: '0' },
      ...data.map((item) => ({ ...item, value: item.id, label: item.brand })),
    ]);
  }, [data]);
  const onSelect = (data) => {
    if (mode === 'default' && typeof onChoose === 'function') {
      onChoose(
        data,
        options?.find((item) => parseInt(item.value) === parseInt(data))
      );
    }
    input.onChange(
      data === undefined ? (mode === 'default' ? null : []) : data
    );
  };
  const value = mode === 'default' ? input.value.toString() : input.value;
  return (
    <div className={classNames('position-relative', classNameContainer)}>
      <Select
        placeholder={placeholder}
        showSearch
        mode={mode}
        value={value || (mode === 'default' ? undefined : [])}
        style={{ width: '100%' }}
        size={size || 'large'}
        onChange={onSelect}
        disabled={disabled}
        optionFilterProp="children"
        {...inputProps}
      >
        {options.map((item) => (
          <Option key={item.value}>{item.label}</Option>
        ))}
      </Select>
      {invalid && (
        <hr
          className="border-danger m-0 position-absolute"
          style={{ width: '90%', left: '5%', top: size === 'middle' ? 31 : 39 }}
        />
      )}
      {(showError ? error : error && submitFailed) && (
        <span className="invalid-feedback">{error}</span>
      )}
    </div>
  );
};
