import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { change, FieldArray, formValueSelector, reduxForm } from "redux-form";
import validate from "./validate";
import { renderIssues } from "utils/Form";
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { GMButton } from "components/Button";

const IssueForm = ({
  handleSubmit,
  listIssueCategory,
  listIssues,
  changeFieldValue,
  appendEmptyIssue = () => { },
  ...rest
}) => {
  const history = useHistory();
  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        listIssues={listIssues}
        listIssueCategory={listIssueCategory}
        name={`listIssues`}
        component={renderIssues}
        changeFieldValue={changeFieldValue}
      />
      <div className="cta-blk">
        <div className="clearfix">
          <GMButton
            color="SecondaryColor"
            type="button"
            onClick={appendEmptyIssue}
          >
            <PlusIcon />
            <span>Add issue</span>
          </GMButton>
          <div className="blk-right">
            <GMButton
              color="SecondaryGray"
              onClick={() => history.replace('/orders/order-issues')}
              type="button"
            >
              Cancel
            </GMButton>
            <GMButton
              className="btn btn-primary"
            >
              Create issue
            </GMButton>
          </div>
        </div>
      </div>
    </form>
  );
}

const ReduxIssueForm = reduxForm({
  form: "formCreateIssue",
  enableReinitialize: true,
  validate,
})(IssueForm);
const selector = formValueSelector("formCreateIssue");

export default connect(
  (state) => ({
    listIssues: selector(state, "listIssues") || [],
  }),
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change("formCreateIssue", field, value));
    },
    dispatch,
  })
)(ReduxIssueForm);
