import React from 'react';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import { ReactComponent as IconInfo } from 'images/icons/info-circle.svg';
import { ConfirmModalInnerWrapper } from './styled';
import { GMButton } from 'components/Button';

export default ({ onOk, onCancel, ...props }) => {
  return (
    <ConfirmDialog
      ctas={
        <>
          <GMButton type="button" color="SecondaryGray" onClick={onCancel}>
            Cancel
          </GMButton>
          <GMButton type="button" onClick={onOk}>
            Update billing info
          </GMButton>
        </>
      }
      {...props}
    >
      <ConfirmModalInnerWrapper>
        <div className="icon">
          <div className="wrapper">
            <IconInfo />
          </div>
        </div>
        <div className="content">
          <h4 className="header">Missing billing information</h4>
          <div className="msg">
            To set this as your primary billing method, please complete your
            billing information.
          </div>
        </div>
      </ConfirmModalInnerWrapper>
    </ConfirmDialog>
  );
};
