import { GRAY_600 } from "components/colors";
import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  div.title {
    padding-top: 1rem;
    padding-left: 5px;
  }
  p.message {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_600};
    margin-bottom: 1rem;
  }
  .cta-update {
    width: 100%;
    max-width: 200px;
  }
`;