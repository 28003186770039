import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';
import { replace } from 'connected-react-router';
import { actionType as TYPE1 } from '../List/actions';
import { create } from 'apis/store_integrations';
import {
  getEbayLink,
  getShopbaseLink,
  createEbayStore,
  createShopBaseStore,
  getShopifyLink,
  getEtsyLink,
  createEtsyStore,
  getAmazonLink,
  createAmazonStore,
  checkConnectWithShopify,
  getUrlFromShopify,
  connectStoreShopifyCase1,
} from 'apis/create_store';
function* createStoreSaga(action) {
  try {
    const { store } = action;
    const response = yield call(create, store);

    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CREATE_STORE.SUCCESS, response }),
        put({
          type: TYPE1.LIST.REQUEST,
          params: {
            page: 1,
            perPage: 50,
            sortBy: 'id',
            sortType: 'desc',
          },
        }),
      ]);
    } else {
      yield put({ type: TYPE.CREATE_STORE.FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.CREATE_STORE.FAIL, error })]);
  }
}
function* createEbayStoreSaga(action) {
  try {
    const { store } = action;
    const response = yield call(createEbayStore, store);

    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CREATE_EBAY_STORE.SUCCESS, response }),
        put(replace('/stores')),
      ]);
    } else {
      yield all([
        put({ type: TYPE.CREATE_EBAY_STORE.FAIL, error: response }),
        put(replace('/stores')),
      ]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.CREATE_EBAY_STORE.FAIL, error })]);
  }
}
//Create amazon
function* createAmazonStoreSaga(action) {
  try {
    const { store } = action;
    const response = yield call(createAmazonStore, store);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CREATE_AMAZON_STORE.SUCCESS, response }),
        put({
          type: TYPE1.LIST.REQUEST,
          params: {
            page: 1,
            perPage: 50,
            sortBy: 'id',
            sortType: 'desc',
          },
        }),
      ]);
    } else {
      yield all([
        put({ type: TYPE.CREATE_AMAZON_STORE.FAIL, error: response }),
        put(replace('/stores')),
      ]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.CREATE_AMAZON_STORE.FAIL, error })]);
  }
}

function* createShopBaseStoreSaga(action) {
  try {
    const { store } = action;
    const response = yield call(createShopBaseStore, store);

    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CREATE_SHOPBASE_STORE.SUCCESS, response }),
        put(replace('/stores')),
      ]);
    } else {
      yield all([
        put({ type: TYPE.CREATE_SHOPBASE_STORE.FAIL, error: response }),
        put(replace('/stores')),
      ]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.CREATE_SHOPBASE_STORE.FAIL, error })]);
  }
}

function* getEbayLinkSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getEbayLink, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.GET_EBAY_LINK.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.GET_EBAY_LINK.FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.GET_EBAY_LINK.FAIL, error })]);
  }
}

//Amazon
function* getAmazonLinkSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getAmazonLink, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.GET_AMAZON_LINK.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.GET_AMAZON_LINK.FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.GET_AMAZON_LINK.FAIL, error })]);
  }
}
//Get link connect Shopify
function* getShopifyLinkSaga(action) {
  const { data } = action;
  try {
    const response = yield call(getShopifyLink, data);
    if (response.code === 'success') {
      yield put({ type: TYPE.GET_SHOPIFY_LINK.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.GET_SHOPIFY_LINK.FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.GET_SHOPIFY_LINK.FAIL, error })]);
  }
}

//Step 1:
function* checkConnectWithShopifySaga(action) {
  const { params } = action;
  try {
    const response = yield call(checkConnectWithShopify, { ...params });
    if (response.code === 'success') {
      yield put({ type: TYPE.CHECK_CONNECT_SHOPIFY.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.CHECK_CONNECT_SHOPIFY.FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.CHECK_CONNECT_SHOPIFY.FAIL, error })]);
  }
}
//Step 2:
function* getUrlShopifySaga(action) {
  const { data } = action;
  try {
    const response = yield call(getUrlFromShopify, data);
    if (response.code === 'success') {
      yield put({ type: TYPE.GET_URL_SHOPIFY.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.GET_URL_SHOPIFY.FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.GET_URL_SHOPIFY.FAIL, error })]);
  }
}
//Step 3:
function* connectToShopifySaga(action) {
  try {
    const response = yield call(connectStoreShopifyCase1);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CONNECT_SHOPIFY.SUCCESS, ...response }),
        put(replace('/stores')),
      ]);
    } else {
      yield all([
        put({ type: TYPE.CONNECT_SHOPIFY.FAIL, error: response }),
        put(replace('/stores')),
      ]);
    }
  } catch (error) {
    yield all([
      yield put({ type: TYPE.CONNECT_SHOPIFY.FAIL, error }),
      put(replace('/stores')),
    ]);
  }
}

//Get link connect Shopbase
function* getShopbaseLinkSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getShopbaseLink, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.GET_SHOPBASE_LINK.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.GET_SHOPBASE_LINK.FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.GET_SHOPBASE_LINK.FAIL, error })]);
  }
}
//Get link connect Etsy
function* getEtsyLinkSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getEtsyLink, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.GET_ETSY_LINK.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.GET_ETSY_LINK.FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.GET_ETSY_LINK.FAIL, error })]);
  }
}
function* createEtsyStoreSaga(action) {
  try {
    const { store } = action;
    const response = yield call(createEtsyStore, store);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CREATE_ETSY_STORE.SUCCESS, response }),
        put(replace('/stores')),
      ]);
    } else {
      yield all([
        put({ type: TYPE.CREATE_ETSY_STORE.FAIL, error: response }),
        put(replace('/stores')),
      ]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.CREATE_ETSY_STORE.FAIL, error })]);
  }
}
function* createStoreWatcher() {
  yield takeLatest(TYPE.CREATE_STORE.REQUEST, createStoreSaga);
  yield takeLatest(TYPE.CREATE_EBAY_STORE.REQUEST, createEbayStoreSaga);
  yield takeLatest(TYPE.CREATE_SHOPBASE_STORE.REQUEST, createShopBaseStoreSaga);
  yield takeLatest(TYPE.CREATE_AMAZON_STORE.REQUEST, createAmazonStoreSaga);
  yield takeLatest(TYPE.GET_EBAY_LINK.REQUEST, getEbayLinkSaga);
  yield takeLatest(TYPE.GET_SHOPBASE_LINK.REQUEST, getShopbaseLinkSaga);
  yield takeLatest(TYPE.GET_SHOPIFY_LINK.REQUEST, getShopifyLinkSaga);
  yield takeLatest(TYPE.GET_AMAZON_LINK.REQUEST, getAmazonLinkSaga);
  yield takeLatest(TYPE.GET_ETSY_LINK.REQUEST, getEtsyLinkSaga);
  yield takeLatest(TYPE.CREATE_ETSY_STORE.REQUEST, createEtsyStoreSaga);
  yield takeLatest(
    TYPE.CHECK_CONNECT_SHOPIFY.REQUEST,
    checkConnectWithShopifySaga
  );
  yield takeLatest(TYPE.GET_URL_SHOPIFY.REQUEST, getUrlShopifySaga);
  yield takeLatest(TYPE.CONNECT_SHOPIFY.REQUEST, connectToShopifySaga);
}

export default createStoreWatcher;
