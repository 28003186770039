import React from "react";
import GWalletIcon from "images/gwallet.png";
import {useSelector} from 'react-redux';
import money from "utils/money";
function GWalletMethod(props) {
  const profile = useSelector((state) => state.auth.info.profile);
  return (
    <>
      <div className="row">
        <div className="col-xl-7">
          <p style={{ color: "#0c8814" }}>
            Each Wallet can only be used to pay for orders in the same currency
            (e.g. you can't pay for an order in EUR from your USD Wallet). It's
            not possible to transfer money from one Wallet to another.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 col-xl-4">
          <div className="g-wallet-card p-4 rounded">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <img alt="gwallet-icon" src={GWalletIcon} className="img-fluid" />
              <p className="font-size-16 m-0">USD Wallet</p>
            </div>
            <div>
              <p className="font-size-14 mb-0">Balance</p>
              <p className="font-size-20 font-weight-bold mb-0">
                  {money(profile?.balance)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(GWalletMethod);