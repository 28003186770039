import React, { useEffect } from 'react';
import ModalTopUp, { Notify } from './styled';
import { METHOD_LIST } from 'constants/common';
import { GMTextbox } from 'components/InputFields';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { GMButton } from 'components/Button';
import { toFixed } from 'utils/helper';
import uploadImageTopUp from './UploadImageTopup';
import { connect, useDispatch } from 'react-redux';
import GMNotify from 'components/Notification';
import renderRadioTopUp from 'utils/Form/components/renderRadioTopUp';
import { renderRadioAnt, renderSelectAnt, renderText } from 'utils/Form';
import { ReactComponent as Plus } from 'images/icons/plus.svg';
// import { method_topUp } from 'utils/options';
import money from 'utils/money';
import { useRef } from 'react';
import validate from './validate';
import { redirectPaypal } from 'apis/payment_menthod';
import { notification, Spin } from 'antd';
import { setLoading } from 'scenes/BillingPaymentAccount/actions';
import { useState } from 'react';

const Form = (props) => {
  const {
    handleSubmit,
    _type,
    _deposit,
    profile,
    notiTopup,
    initialValues,
    accountsPaypal,
    _instant,
    changeFieldValue,
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const numberInputHanlder = (evt) => {
    if (evt.keyCode >= 48 && !evt.key.match(/^[0-9.]$/)) {
      evt.preventDefault();
      return false;
    }
  };

  useEffect(() => {
    let numDeposit = _deposit || 0;
    if (numDeposit >= 10) {
      let fee = Math.round(((numDeposit * 0.05 + 0.49) / 0.95) * 100) / 100;
      let total = Math.round((numDeposit + fee) * 100) / 100;
      changeFieldValue('fee', fee);
      changeFieldValue('total', total);
    } else {
      changeFieldValue('fee', '0');
      changeFieldValue('total', '0');
    }
    return () => {};
  }, [_deposit]);

  useEffect(() => {
    const fields = [
      'paymentId',
      'payId',
      'amount',
      'deposit',
      'instant',
      'image',
      'amountPaypal',
    ];
    for (let i = 0; i < fields.length; i++) {
      dispatch(change('TopUpStep1', fields[i], null));
    }
  }, [dispatch, _type]);

  const method_topUp = [
    {
      value: 1,
      name: 'Make payment',
      label: _type === 3 ? 'Paypal mass payment' : 'Make payment',
    },
    {
      value: 2,
      name: 'Instant',
      label: 'Instant',
    },
  ];
  const handleOnAddPaypalAccount = () => {
    setLoading(true);
    redirectPaypal()
      .then((response) => {
        setLoading(false);
        if (response?.code === 'success' && response?.data?.url) {
          notification.success({
            message: 'Please wait for a minute ...',
          });
          let a = response?.data?.url;
          window.open(a);
        } else {
          setLoading(false);
          notification.warn({
            message:
              response?.[0]?.clientMsg || 'Register Paypal account failed.',
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        notification.warn({
          message: error?.clientMsg || 'Register Paypal account failed.',
        });
      });
  };

  return (
    <Spin spinning={loading}>
      <Notify className="mb-4">
        {notiTopup && initialValues.amount !== '' ? (
          <GMNotify
            type="warning"
            className="notify"
            title={
              <div className="title">
                {notiTopup}
              </div>
            }
          />
        ) : (
          ''
        )}
      </Notify>

      <ModalTopUp>
        <form onSubmit={handleSubmit}>
          <div className="wallet-balance">
            G-Wallet:
            <strong>
              <div className="value-balance">
                <b>{money(profile?.balance || 0)}</b>
              </div>
            </strong>
          </div>
          <div>
            <Field
              name="type"
              component={renderRadioTopUp}
              options={METHOD_LIST}
            />
          </div>
          {_type === 1 && (
            <div className="noti mb-0">
              <GMNotify
                type="desc"
                title={<div className="title">For PAYONEER top up</div>}
                desc={
                  <div className="desc">
                    <p className="m-0">
                      - Transfer to email payment@gearment.com
                    </p>
                    <p className="m-0">
                      - Provide the transaction id shown on your screen right
                      after the action is completed
                    </p>
                  </div>
                }
              />
            </div>
          )}

          <div className="mt-4">
            <Field
              name="instant"
              component={renderRadioAnt}
              defaultValue={1}
              options={method_topUp.map((item) => ({
                ...item,
                disabled: parseInt(item.value) === 2 && _type !== 3,
              }))}
            />
          </div>
          {_type === 3 && parseInt(_instant) === 2 ? (
            <div>
              <div className="group">
                <div className="paypal-account">
                  <div className="form-group">
                    <label className="name-field">Paypal account</label>
                    <Field
                      name="payId"
                      component={renderSelectAnt}
                      placeholder="Select Paypal account"
                      parseKey2String={true}
                      options={accountsPaypal?.map((item) => ({
                        value: item.id,
                        label: item.email,
                      }))}
                    />
                  </div>
                </div>
                <div className="button d-flex row">
                  <div className="form-group">
                    <label className="name-field">
                      <br />
                    </label>
                    <div className="new-account">
                      <div
                        className="button-new-account"
                        onClick={handleOnAddPaypalAccount}
                      >
                        <Plus />
                        New account
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment-instant">
                <div className="form-group">
                  <label className="name-field">Total G-Wallet Deposit</label>
                  <GMTextbox
                    name="amountPaypal"
                    placeholder="00000.00"
                    className="input-field"
                    type="number"
                    showError
                    maxLength={9}
                    iconBefore="$"
                    onKeyDown={numberInputHanlder}
                    onKeyUp={numberInputHanlder}
                    parse={(val) => parseFloat(val)}
                  />
                </div>
              </div>
              <div className="payment-instant">
                <div className="form-group">
                  <label className="name-field">Processing Fee</label>
                  <GMTextbox
                    name="fee"
                    className="input-field"
                    showError
                    maxLength={8}
                    iconBefore="$"
                    parse={(val) => parseFloat(val)}
                    disabled
                  />
                </div>
              </div>
              <div className="term">
                PayPal transactions will incur a processing fee of{' '}
                <b>(5% of the Total charge + $0.49)</b> per transaction.
              </div>
              <div className="payment-instant">
                <div className="form-group">
                  <label className="name-field">Total Charge</label>
                  <GMTextbox
                    name="total"
                    className="input-field"
                    showError
                    maxLength={8}
                    iconBefore="$"
                    parse={(val) => parseFloat(val)}
                    disabled
                  />
                </div>
              </div>
              <div className="term">
                This amount will be charged to your Paypal account. Paypal will
                take the processing fee and the <b>remaining</b> will be
                deposited into your G-Wallet.
              </div>
              <div className="next-step">
                <GMButton type="submit" size="lg" className="next-button">
                  Next
                </GMButton>
              </div>
            </div>
          ) : (
            <div>
              {_type === 3 && profile?.massPayment === 0 ? (
                <div className="noti-margin mb-0">
                  <GMNotify
                    type="warning"
                    title={
                      <div className="title">
                        Your Paypal Mass Payment top up is not available
                      </div>
                    }
                    desc={
                      <div className="desc">
                        If you have Mass Payment, please contact our support
                        team to enable it.
                      </div>
                    }
                  />
                </div>
              ) : (
                <div>
                  <div className="group">
                    <div className="payment-input">
                      <div className="form-group">
                        <label className="name-field">
                          Reference transaction ID
                        </label>
                        <GMTextbox
                          name="paymentId"
                          placeholder="Enter reference transaction ID"
                          className="input-field"
                          showError
                          maxLength={64}
                        />
                      </div>
                    </div>
                    <div className="payment-input-amount">
                      <div className="form-group">
                        <label className="name-field">
                          Total G-Wallet Deposit
                        </label>
                        <GMTextbox
                          name="amount"
                          placeholder="00000.00"
                          className="input-field"
                          showError
                          maxLength={8}
                          iconBefore="$"
                          onKeyDown={numberInputHanlder}
                          onKeyUp={numberInputHanlder}
                        ></GMTextbox>
                      </div>
                    </div>
                  </div>
                  <div className="upload-frame">
                    <Field
                      id="top-up"
                      name="image"
                      component={uploadImageTopUp}
                      cusId={profile?.id}
                      customPrefix={'topUp'}
                      showUploadList={false}
                      imgWidth="30%"
                    />
                  </div>
                  <div className="next-step d-flex justify-content-center">
                    <GMButton type="submit" size="lg" className="next-button">
                      Next
                    </GMButton>
                  </div>
                </div>
              )}
            </div>
          )}
        </form>
      </ModalTopUp>
    </Spin>
  );
};

const TopUpStep1 = reduxForm({
  form: 'TopUpStep1',
  enableReinitialize: true,
  validate,
})(Form);
const selector = formValueSelector('TopUpStep1');

const mapStateToProps = (state) => ({
  _type: selector(state, 'type'),
  _deposit: selector(state, 'amountPaypal'),
  _instant: selector(state, 'instant'),
});
const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change('TopUpStep1', field, value));
  },
  dispatch,
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(TopUpStep1)
);
