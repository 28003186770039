import { actionType } from './actions';
import { notification } from 'antd';

const initialState = {
  order: {
    data: {},
    loading: false,
  },
  convertOrder: {
    data: [],
    params: {},
    loading: false,
  },
};

function ConvertOrderLabelReducer(state = initialState, action) {
  switch (action.type) {
    //Order Detail
    case actionType.FIND_ORDER_TO_CONVERT_ORDER_LABEL.REQUEST:
      return {
        ...state,
        order: {
          ...state.order,
          loading: true,
        },
      };
    case actionType.FIND_ORDER_TO_CONVERT_ORDER_LABEL.SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          data: action.data.order,
        },
      };
    case actionType.FIND_ORDER_TO_CONVERT_ORDER_LABEL.ERROR:
      notification.warn({
        message: 'Not found order to convert label order.',
        duration: 5,
      });
      return {
        ...state,
        order: {
          ...state.order,
          loading: true,
        },
      };

    //convert order
    case actionType.CONVERT_ORDER.REQUEST:
      return {
        ...state,
        convertOrder: {
          ...initialState.convertOrder,
          loading: true,
        },
      };
    case actionType.CONVERT_ORDER.SUCCESS:
      notification.success({
        message: action?.clientMsg || 'Convert order successfully.',
        duration: 5,
      });
      return {
        ...state,
        convertOrder: {
          ...initialState.convertOrder,
          loading: false,
        },
      };
    case actionType.CONVERT_ORDER.ERROR:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Convert order failed.',
        duration: 5,
      });
      return {
        ...state,
        convertOrder: {
          ...initialState.convertOrder,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ConvertOrderLabelReducer;
