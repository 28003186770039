import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';
import { listUserLable, destroyUserlabel } from 'apis/userlabel';
import { verify } from 'scenes/Auth/actions';
function* getListUserLableSaga(action) {
  const { params } = action;
  try {
    const response = yield call(listUserLable, { ...params });
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_USERLABEL.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_USERLABEL.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_USERLABEL.ERROR, error })]);
  }
}

function* deleteUserlabelSaga(action) {
  const { id, cb } = action;
  try {
    const response = yield call(destroyUserlabel, id);
    if (response.code === 'success') {
      const { userlabel } = yield select();
      yield all([
        put({ type: TYPE.USERLABEL_DELETE.SUCCESS, ...response }),
        put({
          type: TYPE.LIST_USERLABEL.REQUEST,
          params: userlabel?.list?.userlableList?.params || {
            page: 1,
            perPage: 50,
          },
        }),
        put(verify()),
      ]);
      if (typeof cb === 'function') {
        cb();
      }
    } else {
      yield put({ type: TYPE.USERLABEL_DELETE.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.USERLABEL_DELETE.ERROR, error })]);
  }
}
function* watcher() {
  yield takeLatest(TYPE.LIST_USERLABEL.REQUEST, getListUserLableSaga);
  yield takeLatest(TYPE.USERLABEL_DELETE.REQUEST, deleteUserlabelSaga);
}
export default watcher;
