export const actionType = {
  LIST_CUSTOMER_CONFIG: {
    REQUEST: "CUSTOMER.LIST.REQUEST",
    SUCCESS: "CUSTOMER.LIST.SUCCESS",
    ERROR: "CUSTOMER.LIST.ERROR",
  },
  UPDATE_AUTO_CHARGE: {
    REQUEST: "UPDATE.AUTO.CHARGE.REQUEST",
    SUCCESS: "UPDATE.AUTO.CHARGE.SUCCESS",
    ERROR: "UPDATE.AUTO.CHARGE.ERROR",
  },
};
export function getListCustomerConfig() {
  return {
    type: actionType.LIST_CUSTOMER_CONFIG.REQUEST,
  };
}
export function updateAutoCharge(dataToUpdateAutoCharge) {
  return {
    type: actionType.UPDATE_AUTO_CHARGE.REQUEST,
    dataToUpdateAutoCharge,
  };
}
