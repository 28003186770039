import {
  BASE_WHITE,
  GRAY_500,
  GRAY_600,
  GRAY_900,
  PRIMARY_500,
} from 'components/colors';
import PageContainer from 'components/PageContainer';
import styled from 'styled-components';

export const Wrapper = styled(PageContainer)`
  .main-content {
    background-color: transparent;
    padding:0px;
    border: none;
    box-shadow: none;
    &.ant-table-wrapper {
      box-shadow: none;
    }
  }
`;
