import React, { Component } from "react";
import { connect } from "react-redux";
import DefaultShipping from "./components/form";
import { Spin, Tabs } from "antd";
import {
  updateShipping,
  getListShipping,
  getListHistoryShipping,
} from "./actions";
import getMomentInstance from "utils/moment";
import queryString from "query-string";
import RenderHistoryOfShippingService from "utils/Form/RenderHistoryOfShippingService";
import shipping from "images/shipping-img.svg";
import { Wrapper } from "./styled";
const { TabPane } = Tabs;

export class index extends Component {
  constructor(props) {
    super(props);
    const { createdAtFrom, createdAtTo, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      activeKeyTag: "1",
      initial_filter_values: {
        sortBy: "id",
        sortType: "desc",
        ...query_params,
        date_range: [
          createdAtFrom
            ? getMomentInstance(createdAtFrom, 0, false, true)
            : null,
          createdAtTo ? getMomentInstance(createdAtTo, 0, false, true) : null,
        ],
      },
    };
  }

  componentDidMount() {
    this.props.getListShipping();
    this.props.getListHistoryShipping();
  }

  updateShipping = (value) => {
    let updateShipping = {
      domesticUnder16oz: value?.domesticUnder16oz || "",
      domesticOver16oz: value?.domesticOver16oz || "",
      defaultShippingService: value?.defaultShippingService || "0",
    };
    this.props.updateShipping(updateShipping);
  };

  render() {
    const {
      pageName,
      updateShippingLoading,
      listShipping,
      listShippingLoading,
    } = this.props;
    const initialValuesShipping = {
      domesticUnder16oz:
        listShipping?.customerShipping?.domesticUnder16oz || "USPS",
      domesticOver16oz:
        listShipping?.customerShipping?.domesticOver16oz || "USPS",
      intlUnder16oz: "DHL Packet Plus International",
      intlOver16oz: "DHL Packet Plus International",
      defaultShippingService: listShipping?.defaultShippingService || "0",
    };
    return (
      <Wrapper pageName={pageName}>
        <div className="row">
          <div className="col-md-12">
            <Tabs defaultActiveKey="1" className="m-4">
              <TabPane tab="Default shipping preferences" key="1">
                <div>
                  <div className="defaultShip">
                    Default shipping preferences
                  </div>
                  <div className="desShip">
                    Configure your preferred shipping methods for domestic and
                    international shipping
                  </div>
                </div>
                <div>
                  {" "}
                  <Spin
                    spinning={listShippingLoading || updateShippingLoading}
                  >
                    <DefaultShipping
                      initialValues={initialValuesShipping}
                      onSubmit={this.updateShipping}
                      className="m-3"
                    />
                  </Spin>
                </div>
              </TabPane>
              <TabPane tab="History" key="2">
                <RenderHistoryOfShippingService
                  updateShippingLoading={updateShippingLoading}
                />{" "}
              </TabPane>
            </Tabs>
          </div>
        </div>{" "}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  updateShippingLoading: state.shipping.edit.updateShipping.loading,
  listShipping: state.shipping.edit.listShipping.data,
  listShippingLoading: state.shipping.edit.listShipping.loading,
  listHistoryShipping: state.shipping.edit.listHistoryShipping.data,
  listHistoryShippingLoading: state.shipping.edit.listHistoryShipping.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getListHistoryShipping: () => {
    dispatch(getListHistoryShipping());
  },
  getListShipping: () => {
    dispatch(getListShipping());
  },
  updateShipping: (dataToUpdateShipping) => {
    dispatch(updateShipping(dataToUpdateShipping));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
