export const actionType = {
  CREATE: {
    REQUEST: 'MOCKUP.CREATE.CREATE.REQUEST',
    SUCCESS: 'MOCKUP.CREATE.CREATE.SUCCESS',
    ERROR: 'MOCKUP.CREATE.CREATE.ERROR',
  },
  LIST_EXPRODUCT: {
    REQUEST: 'LIST_EXPRODUCT.REQUEST',
    SUCCESS: 'LIST_EXPRODUCT.SUCCESS',
    ERROR: 'LIST_EXPRODUCT.ERROR',
  },
};

export function create(params) {
  return {
    type: actionType.CREATE.REQUEST,
    params,
  };
}
export function getListExproduct(params) {
  return {
    type: actionType.LIST_EXPRODUCT.REQUEST,
    params,
  };
}
