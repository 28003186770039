import React, { useState, useEffect } from "react";
import { Spin, Tag, Select } from "antd";
import { getSizes } from "apis/ex_products";
import classNames from "classnames";
import { GMTag } from "scenes/general.styled";
import { BASE_BLACK } from "components/colors";

function SelectMultipleTagSizeProduct({
  fields,
  meta: { error, submitFailed },
  expId,
  colorId,
  setSizes,
  classNameContainer = "",
  selectedSizeIds = []
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (expId && colorId) {
      setLoading(true);
      getSizes(expId, colorId)
        .then(({ code, data }) => {
          setLoading(false);
          if (code === "success") {
            setOptions(data.optionSizes || []);
            if (typeof setSizes === 'function' && Array.isArray(data.optionSizes) && data.optionSizes.length > 0) setSizes(data.optionSizes || []);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setOptions([]);
    }
  }, [expId, colorId]);
  const onSelect = (size) => {
    const currIndex = selectedSizeIds.findIndex(selectedSizeId => parseInt(selectedSizeId) === parseInt(size.id));
    if (currIndex > -1) {
      fields.remove(currIndex);
    } else {
      fields.push(size.id);
    }
  };
  const tagRender = (props) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const cl = options.find(o => o.id * 1 === 1 * value);
    return (
      <GMTag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        textColor={BASE_BLACK}
        borderColor={BASE_BLACK}
        style={{ marginRight: 3, marginTop: 3 }}
      >
        {cl?.name}
      </GMTag>
    );
  };
  return (
    <div className={classNames("position-relative", classNameContainer)}>
      <Spin spinning={loading}>
        <Select
          mode="multiple"
          showArrow
          tagRender={tagRender}
          value={selectedSizeIds}
          style={{ width: '100%' }}
          options={options.map(i => ({ label: i?.name, value: i?.id + '' }))}
          onSelect={cid => fields.push(cid + '')}
          onDeselect={cid => fields.remove(selectedSizeIds.indexOf(cid))}
        />
      </Spin>
      {(error && submitFailed) && (
        <span className="invalid-feedback">{error}</span>
      )}
    </div>
  );
};
export default React.memo(SelectMultipleTagSizeProduct);