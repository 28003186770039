import { isValidEmail } from 'utils/helper';
import { isInteger } from 'lodash';
const validate = (values) => {
  const errors = {};

  if (values?.barcodeFiles?.length <= 0 || values.barcodeIds?.length <= 0) {
    errors.barcodeFiles = { _error: 'Please upload a label file' };
  }
  if (!values.listOrderItems || !values.listOrderItems.length) {
    errors.listOrderItems = {
      _error: 'Please add order items to create order.',
    };
  } else {
    const conditionArrayErrors = [];
    for (let index = 0; index < values.listOrderItems.length; index++) {
      const element = values.listOrderItems[index];
      const conditionErrors = {};
      if (parseInt(values.shippingService) === 9) {
        if (!element.barcode) {
          conditionErrors.barcode = 'Please upload a barcode file';
        }
      }
      //
      if (!element.productId || parseInt(element.productId) === 0) {
        conditionErrors.productId = 'Please select a product.';
      }
      //
      if (!element.colorId || parseInt(element.colorId) === 0) {
        conditionErrors.colorId = 'Please select a color.';
      }
      //
      if (!element.sizeId || parseInt(element.sizeId) === 0) {
        conditionErrors.sizeId = 'Please select a size.';
      }

      //Quantity
      const prodId = parseInt(element?.productId);
      const quantity = parseInt(element.quantity);
      if(element?.sidePrint === "pocket" && !element.designFront ){
        conditionErrors.designFront = 'Please upload design.';
      }
      if (parseInt(values.shippingService) === 9) {
        if (quantity <= 3000 && quantity >= 1) {
          if (prodId === 149 || prodId === 150 || prodId === 63) {
            if ((prodId === 149 || prodId === 150) && quantity % 9 !== 0) {
              conditionErrors.quantity =
                'Please enter a number between 9 and 2997 that is divisible by 9.';
            } else if (prodId === 63 && quantity % 15 !== 0) {
              conditionErrors.quantity =
                'Please enter a number between 15 and 3000 that is divisible by 15.';
            }
          } else if (quantity % 30 !== 0) {
            conditionErrors.quantity =
              'Please enter a number between 30 and 3000 that is divisible by 30.';
          }
        } else {
          conditionErrors.quantity =
            'Please enter a number less than or equal to 3000.';
        }
      } else if (
        !quantity ||
        isNaN(quantity) ||
        quantity < 1 ||
        quantity > 1000
      ) {
        conditionErrors.quantity = 'Please enter a number between 1 and 1000.';
      }

      if (!element.designFront && !element.designBack) {
        conditionErrors.designFront = 'Please upload design.';
      }
      conditionArrayErrors[index] = conditionErrors;
    }
    if (conditionArrayErrors.length) {
      errors.listOrderItems = conditionArrayErrors;
    }
  }
  return errors;
};

export default validate;
