import React from 'react';
import { Link } from 'react-router-dom';

import { Tag } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { ReactComponent as PingpongLogo } from 'images/Logo_store/pingpong.svg';
import { ReactComponent as PayoneerLogo } from 'images/Logo_store/payoneer.svg';
import { ReactComponent as PaypalLogo } from 'images/Logo_store/paypal.svg';

import { ReactComponent as PayoneerLogoSVG } from 'images/logo_payment/md-Payoneer.svg';
import { ReactComponent as LianLogoSVG } from 'images/logo_payment/lian-logo.svg';
import { ReactComponent as PaypalLogoSVG } from 'images/logo_payment/md-PayPal.svg';

export const PLACEHOLDER_SIDES = {
  FRONT: 1,
  BACK: 2,
  DEFAULT: 3,
};

export const RESIZE_IMAGE = {
  SMALL: 200,
  MEDIUM: 400,
  LARGE: 680,
};

export const G_WALLET_ID = 1;

export const METHOD_LIST = [
  {
    value: 1,
    name: 'Payoneer',
    label: <PayoneerLogo width={100} />,
    labelTopUp: <PayoneerLogoSVG/>,
  },
  {
    value: 2,
    name: 'Pingpong',
    label: <PingpongLogo width={90} />,
    labelTopUp: <PingpongLogo style={{width:"80px"}} />,
  },
  {
    value: 3,
    name: 'Paypal',
    // disabled: true,
    label: <PaypalLogo />,
    labelTopUp: <PaypalLogoSVG style={{width:"90px"}}/>,
  },  
  // {
  //   value: 4,
  //   name: 'Instant',
  //   label: <PaypalLogo />,
  //   labelTopUp: <PaypalLogoSVG style={{width:"90px"}}/>,
  // },
  {
    value: 5,
    name: 'Lian Lian',
    label: <LianLogoSVG style={{width:"125px"}}/>,
    labelTopUp: <LianLogoSVG style={{width:"90px"}}/>,
  },
];

export const PAYMENT_STATUS = [
  {
    status: 0,
    name: (
      <Tag icon={<CloseCircleOutlined />} color="warning">
        Not paid
      </Tag>
    ),
  },
  {
    status: 1,
    name: (
      <Tag icon={<SyncOutlined />} color="processing">
        Being processed
      </Tag>
    ),
    desc: 'We are processing your payment.',
  },
  {
    status: 2,
    name: (
      <Tag icon={<CheckCircleOutlined />} color="success">
        Paid
      </Tag>
    ),
  },
  {
    status: 3,
    name: (
      <Tag icon={<CloseCircleOutlined />} color="error">
        Failed
      </Tag>
    ),
  },
];
export const TRANSACTION_STATUS = [
  {
    status: 2,
    name: <div className="paid status">Paid</div>,
  },
  {
    status: 3,
    name: <div className="status refunded refund">Refunded</div>,
  },
  {
    status: 4,
    name: <div className="status deposited deposit">Deposited</div>,
  },
];
export const TRANSACTION_TYPE = [
  {
    status: 1,
    name: <span className="subs">&#x2022; Subscription</span>,
  },
  {
    status: 2,
    name: <span className="dot payout">&#x2022; Payout</span>,
  },
  {
    status: 3,
    name: <span className="dot refund">&#x2022; Refund</span>,
  },
  {
    status: 4,
    name: <span className="dot deposit">&#x2022; Deposit</span>,
  },
];

export const STATUS = [
  {
    status: 0,
    name: <div className="draft">Draft</div>,
  },
  {
    status: 1,
    name: <div className="hold">Hold</div>,
  },
  {
    status: 2,
    name: <div className="await-payment">Await Payment</div>,
  },
  {
    status: 3,
    name: <div className="in-production">In Production</div>,
  },
  {
    status: 4,
    name: <div className="full-filled">Fullfilled</div>,
  },
  {
    status: 5,
    name: <div className="draft">Canceled</div>,
  },
];

export const TOPUP_STATUS = [
  {
    status: 0,
    name: (
      <Tag icon={<SyncOutlined />} color="warning">
        Pending
      </Tag>
    ),
  },
  {
    status: 1,
    name: (
      <Tag icon={<CloseCircleOutlined />} color="error">
        Rejected
      </Tag>
    ),
  },
  {
    status: 2,
    name: (
      <Tag icon={<CheckCircleOutlined />} color="success">
        Approved
      </Tag>
    ),
  },
];

export const ACTION_LIST = {
  MAKE_PAYMENT: 'MAKE_PAYMENT',
  DELETE: 'DELETE',
  CLONE: 'CLONE',
  CANCEL: 'CANCEL',
  EDIT: 'EDIT',
  VIEW_INVOICE: 'VIEW_INVOICE',
};

export const FULL_DATE_TIME_FORMAT = 'MMM DD, YYYY  HH:mm';
export const FULL_DATE_FORMAT = 'MMM DD, YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_MONTH = 'MMM';

export const STATUS_KEY = {
  DRAFT: 0,
  HOLD: 1,
  AWAIT_PAYMENT: 2,
  IN_PRODUCTION: 3,
  FULL_FILLED: 4,
  CANCELED: 5,
};

export const PAYMENT_METHOD_KEY = {
  NOT_PAID: 0,
  BEING_PROCESSED: 1,
  PAID: 2,
  FAILED: 3,
};

export const REASON_HOLD = [
  {
    reason: 'Design Error',
    render: (order_id, tabQuery) => (
      <>
        Please{' '}
        <Link
          to={
            order_id
              ? {
                  pathname: `/orders/${order_id}/edit`,
                  search: tabQuery,
                  state: {
                    step: 0,
                  },
                }
              : null
          }
        >
          click here
        </Link>{' '}
        to go back create product steps to fulfill information.
      </>
    ),
  },
  {
    reason: 'SKU Error',
    render: (order_id, tabQuery) => (
      <>
        Please{' '}
        <Link
          to={
            order_id
              ? {
                  pathname: `/orders/${order_id}/edit`,
                  search: tabQuery,
                  state: {
                    step: 0,
                  },
                }
              : null
          }
        >
          click here
        </Link>{' '}
        to go back create product steps to fulfill information.
      </>
    ),
  },
  {
    reason: 'Address Error',
    render: (order_id, tabQuery) => (
      <>
        Please{' '}
        <Link
          to={
            order_id
              ? {
                  pathname: `/orders/${order_id}/edit`,
                  search: tabQuery,
                  state: {
                    step: 1,
                  },
                }
              : null
          }
        >
          click here
        </Link>{' '}
        to go back information steps to update information.
      </>
    ),
  },
  {
    reason: 'Payment Error',
    render: () => (
      <>
        Please <Link to="/billings/payment-method">click here</Link> to change
        payment method.
      </>
    ),
  },
  {
    reason: 'Gift Message Error',
    render: () => <div></div>,
  },
  {
    reason: 'Shipping medthod Error',
    render: () => <div></div>,
  },
];

export const STATUS_LOGIN = [
  {
    code: 'user_not_existed',
    status: 'Invalid email or password',
    desc: 'Please check your email or password again',
  },
  {
    code: 'timeOut',
    status: 'Time out.',
  },
  {
    code: 'not_verify',
    status: 'Your email need to verify!',
    desc:
      'We have sent an email to chinhcuamoinha@gmail.com Please check your email to verify',
  },
];
export const STATE_OF_US = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'AS',
  'GU',
  'MP',
  'PR',
  'VI',
  'UM',
  'MH',
  'FM',
  'PW',
];

export const COUNTRY_AVALIBLE_CANVAS = [
  { id: '142', country: 'Mexico' },
  { id: '230', country: 'United Kingdom' },
  { id: '13', country: 'Australia' },
  { id: '162', country: 'Norfolk Island' },
  { id: '205', country: 'Spain' },
  { id: '82', country: 'Germany' },
  { id: '75', country: 'France' },
  { id: '176', country: 'Portugal' },
  { id: '14', country: 'Austria' },
  { id: '21', country: 'Belgium' },
  { id: '107', country: 'Italy' },
  { id: '155', country: 'Netherlands' },
  { id: '74', country: 'Finland' },
  { id: '105', country: 'Ireland' },
  { id: '58', country: 'Denmark' },
  { id: '127', country: 'Luxembourg' },
  { id: '164', country: 'Norway' },
  { id: '57', country: 'Czech Republic' },
  { id: '177', country: 'Puerto Rico' },
  { id: '56', country: 'Cyprus' },
  { id: '68', country: 'Estonia' },
  { id: '99', country: 'Hungary' },
  { id: '135', country: 'Malta' },
  { id: '175', country: 'Poland' },
  { id: '212', country: 'Switzerland' },
];
