export const actionType = {
  LIST: {
    REQUEST: 'ORDER.SYNC_TRACKING.LIST.REQUEST',
    SUCCESS: 'ORDER.SYNC_TRACKING.LIST.SUCCESS',
    ERROR: 'ORDER.SYNC_TRACKING.LIST.ERROR',
  },
  LIST_SYNC_IMPORT_ORDER: {
    REQUEST: 'ORDER.IMPORT_PROGRESS.LIST.REQUEST',
    SUCCESS: 'ORDER.IMPORT_PROGRESS.LIST.SUCCESS',
    ERROR: 'ORDER.IMPORT_PROGRESS.LIST.ERROR',
  },
  LIST_SYNC_ORDER: {
    REQUEST: 'ORDER.SCHEDULE_REPORT.LIST.REQUEST',
    SUCCESS: 'ORDER.SCHEDULE_REPORT.LIST.SUCCESS',
    ERROR: 'ORDER.SCHEDULE_REPORT.LIST.ERROR',
  },
  LIST_LOG_SYNC_PRODUCT: {
    REQUEST: 'SYNC_PRODUCTS.LIST.REQUEST',
    SUCCESS: 'SYNC_PRODUCTS.LIST.SUCCESS',
    ERROR: 'SYNC_PRODUCTS.LIST.ERROR',
  },
  LOG_FEED_PRODUCT: {
    REQUEST: 'EXPORT.PRODUCT.LIST.REQUEST',
    SUCCESS: 'EXPORT.PRODUCT.LIST.SUCCESS',
    ERROR: 'EXPORT.PRODUCT.LIST.ERROR',
  },
  LIST_LOGS_EXPORT_ORDER: {
    REQUEST: 'LIST_LOGS_EXPORT_ORDER.REQUEST',
    SUCCESS: 'LIST_LOGS_EXPORT_ORDER.SUCCESS',
    ERROR: 'LIST_LOGS_EXPORT_ORDER.ERROR',
  },
  LIST_LOGS_WEBHOOK: {
    REQUEST: 'LIST_LOGS_WEBHOOK.REQUEST',
    SUCCESS: 'LIST_LOGS_WEBHOOK.SUCCESS',
    ERROR: 'LIST_LOGS_WEBHOOK.ERROR',
  },
  LIST_LOGS_ORDER: {
    REQUEST: 'LIST_LOGS_ORDER.REQUEST',
    SUCCESS: 'LIST_LOGS_ORDER.SUCCESS',
    ERROR: 'LIST_LOGS_ORDER.ERROR',
  },
};

export function getListLogsOrder(id) {
  return {
    type: actionType.LIST_LOGS_ORDER.REQUEST,
    id,
  };
}

export function getListLogsWebhook(id) {
  return {
    type: actionType.LIST_LOGS_WEBHOOK.REQUEST,
    id,
  };
}

export function getList(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}
export function getListLogSyncOrderImport(params) {
  return {
    type: actionType.LIST_SYNC_IMPORT_ORDER.REQUEST,
    params,
  };
}
export function getListLogSyncOrder(params) {
  return {
    type: actionType.LIST_SYNC_ORDER.REQUEST,
    params,
  };
}

export function getListLogExportOrder(params) {
  return {
    type: actionType.LIST_LOGS_EXPORT_ORDER.REQUEST,
    params,
  };
}

export function getListLogSyncProduct(params) {
  return {
    type: actionType.LIST_LOG_SYNC_PRODUCT.REQUEST,
    params,
  };
}
export function getLogFeedProduct(params) {
  return {
    type: actionType.LOG_FEED_PRODUCT.REQUEST,
    params,
  };
}
