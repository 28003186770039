import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormUpdateProfile from './components/FormUpdateProfile';
import FormSocial from './components/FormSocial';
import {
  update,
  removeAvatar,
  listCountry,
  deleteAccount,
} from './actions';
import { Spin } from 'antd';
import getMomentInstance from 'utils/moment';
import moment from 'moment';
import { Wrapper } from './styled';

export class index extends Component {
  handleUpdateProfile = ({ avatar, email, birthday, ...values }) => {
    this.props.update({
      birthday: birthday ? Math.ceil(moment(birthday).valueOf() / 1000) : null,
      ...values,
    });
  };
  componentDidMount() {
    this.props.listCountry();
  }

  render() {
    const {
      pageName,
      updating,
      profile: {
        email = '',
        firstName = '',
        lastName = '',
        phone = '',
        country = 0,
        sellOn = [],
        birthday = 0,
        address = '',
        gender = 0,
        thumbnail_avatar = null,
        facebook = '',
      },
      countries,
      profile,
    } = this.props;
    const initialValues = {
      email,
      firstName,
      lastName,
      phone,
      address,
      gender: `${gender}`,
      avatar: thumbnail_avatar || null,
      country,
      facebook,
      birthday: birthday ? getMomentInstance(null, birthday) : null,
      sellOn: sellOn
        ? (Array.isArray(sellOn) ? sellOn : Object.values(sellOn)) || []
        : '',
    };
    return (
      <Wrapper
        pageName={profile?.primaryId === 0 ? pageName : 'Staff profile'}
        extendPageName={
          profile?.primaryId !== 0 && `Managed by ${profile?.primary?.email}`
        }
      >
        <Spin spinning={updating}>
          <Spin spinning={updating || countries.loading}>
            <FormUpdateProfile
              countries={countries.data}
              initialValues={initialValues}
              onSubmit={this.handleUpdateProfile}
              onRemoveAvatar={() => {
                this.props.removeAvatar();
              }}
            />
          </Spin>

          <div className="m-3 card sub-section">
            <div className="row">
              <div className="col-md-8">
                <FormSocial />
              </div>
            </div>
          </div>
        </Spin>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  updating: state.user.profile.updating,
  profile: state.auth.info.profile,
  countries: state.user.profile.listCountry,
  deleteAccountLoading: state.user.profile.deleteAccount.loading,
});

const mapDispatchToProps = (dispatch) => ({
  update: (params) => {
    dispatch(update(params));
  },
  removeAvatar: () => {
    dispatch(removeAvatar());
  },
  listCountry: () => {
    dispatch(listCountry());
  },
  deleteAccount: () => {
    dispatch(deleteAccount());
  },
  // connectSocialAction: (data) => dispatch(connectSocialAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
