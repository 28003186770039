export const actionType = {
  CREATE_API_KEY: {
    REQUEST: "API.CREATE.REQUEST",
    SUCCESS: "API.CREATE.SUCCESS",
    ERROR: "API.CREATE.ERROR",
  },
  DETAIL_API_KEY: {
    REQUEST: "API.DETAIL.REQUEST",
    SUCCESS: "API.DETAIL.SUCCESS",
    ERROR: "API.DETAIL.ERROR",
  },
  DELETE_API_KEY: {
    REQUEST: "API.DELETE.REQUEST",
    SUCCESS: "API.DELETE.SUCCESS",
    ERROR: "API.DELETE.ERROR",
  },
};
export function createApiKey() {
  return {
    type: actionType.CREATE_API_KEY.REQUEST,
  };
}
export function getApiKey() {
  return {
    type: actionType.DETAIL_API_KEY.REQUEST,
  };
}
export function deleteApiKey() {
  return {
    type: actionType.DELETE_API_KEY.REQUEST,
  };
}
