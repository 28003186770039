import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Spin } from "antd";
import { getDetailAmazon, updateDataFeedAmazon } from "./actions";
import Form from "./components/form";
import feed_amazon from "assets/images/feed_amazon.png";
import BackToIcon from "../../../../images/chevron-down.svg";
import arrow from "assets/images/arrow.png";
import amazon_icon from "assets/images/amazon_icon.png";
import { Wrapper } from "scenes/DataFeed/styled";
import GMHelmet from "components/GMHelmet";
import { postfix } from "utils/options";

class Amazon extends Component {
  componentDidMount() {
    this.props.getDetailAmazon(this.props.match.params.id);
  }

  handleSubmit = (values) => {
    this.props.updateDataFeedAmazon(this.props.match.params.id, {
      ...values,
      default: values.default ? 1 : 0,
      status: values.status ? 1 : 0,
      custom: values.custom || [],
    });
  };
  render() {
    const { loading, detail, pageName } = this.props;
    return (
      <Wrapper
        pageName={pageName}
        breadcrumDetails={{
          [detail?.id]: detail?.id && `#${detail?.id || ''}`,
        }}
      >
        {detail?.id ? (
          <GMHelmet
            overrideTitle={`Edit Feed Form Amazon #${detail?.id || ''}` + postfix}
          />
        ) : null}
        <Spin spinning={loading || detail.loading}>
          <div className="text-center mb-3">
            <img src={feed_amazon} height="90" alt="amazon-icon" />
            <img src={arrow} width="40" className="mx-4" alt="arrow-icon" />
            <img src={amazon_icon} height="70" alt="amazon-icon" />
          </div>
          <Form onSubmit={this.handleSubmit} initialValues={detail} />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    detail: state.data_feed.amazon_edit.detail,
    loading: state.data_feed.amazon_edit.loading,
  }),
  (dispatch) => ({
    updateDataFeedAmazon: (id, data) => {
      dispatch(updateDataFeedAmazon(id, data));
    },
    getDetailAmazon: (id) => {
      dispatch(getDetailAmazon(id));
    },
  })
)(Amazon);
