const validate = (values) => {
  let errors = {};
  if (!values.name || values?.name?.replace(/\s+/g, "").length === 0) {
    errors.name = "Please enter your name";
  }
  if (!values.amzBrand || values?.amzBrand?.replace(/\s+/g, "").length === 0) {
    errors.amzBrand = "Please enter your brand on Amazon ";
  }
  if (!values.amzDepartment || values?.amzDepartment?.replace(/\s+/g, "").length === 0) {
    errors.amzDepartment = "Please enter department type ";
  }
  if (!values.amzFulfillmentLatency) {
    errors.amzFulfillmentLatency = "Please enter fulfillment latency";
  }
  if (!values.amzProductType || values?.amzProductType?.replace(/\s+/g, "").length === 0) {
    errors.amzProductType = "Please enter product type";
  }
  if (!values.amzItemsType || values?.amzItemsType?.replace(/\s+/g, "").length === 0) {
    errors.amzItemsType = "Please enter item type keyword";
  }
  if (!values.amzQuantity) {
    errors.amzQuantity = "Please enter quantity";
  }
  return errors;
};
export default validate;
