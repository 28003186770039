export const LOGIN = {
  request: 'LOGIN_REQUEST',
  success: 'LOGIN_SUCCESS',
  social: 'LOGIN_SOCAL',
  error: 'LOGIN_ERROR',
  resend_email:"SET_DATA_RESEND_EMAIL"
};
export function login(data) {
  return {
    type: LOGIN.request,
    data,
  };
}
export function setDataResendAction(data) {
  return {
    type: LOGIN.resend_email,
    data,
  };
}
