import React, { Component } from 'react';
import moment from 'moment'
import { DatePicker } from 'antd';
import getMomentInstance from "../moment";
import classNames from 'classnames';
const { RangePicker } = DatePicker;
class FieldRangeDateTimePicker extends Component {
  disabledDate =(current)=> {
    return current && current < getMomentInstance(null, 0, false, false, ['day'])
  }
  render() {
    const { input, placeholder, classNameContainer="w-100", size, meta: { invalid, error }, disabled, formatDateTime, showTime, className} = this.props;
    return (
      <div className={classNames("position-relative", classNameContainer)}>
        <RangePicker
          disabled={disabled}
          size={size || "large"}
          disabledDate={this.disabledDate}
          value={input.value}
          showTime={showTime ? {
            format: 'HH:mm',
            hideDisabledOptions: true,
            defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
          } : false}
          format={formatDateTime}
          style={{width: '100%'}}
          placeholder={placeholder}
          className={className}
          onChange={(v)=>input.onChange(v)}//[v[0].format(valueFormat||'YYYY/MM/DD HH:mm:ss'), v[1].format(valueFormat||'YYYY/MM/DD HH:mm:ss')])}
        />
        {invalid&&<hr className="border-danger m-0 position-absolute" style={{width: '90%', left: '5%', top: size === 'middle' ? 31 : 39}}/>}
        {error && <span className="invalid-feedback">{error}</span>}
      </div>
    );
  }
}

export default FieldRangeDateTimePicker;