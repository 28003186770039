import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import RenderAuthenticationSlider from 'utils/Form/RenderAuthenticationSlider';
import { GMCopyright } from 'info';
import { GMButton } from 'components/Button';
import { Wrapper } from './styled';
import { GMTextbox } from 'components/InputFields';
import validate from './validate';

import facebookIcon from 'images/facebook.svg';
import googleIcon from 'images/gg.svg';
import logoIcon from 'images/logo-gearment-notext.png';
import linkedIcon from 'images/link.svg';
import logo from 'images/logo_new_login.svg';

let index = (props) => {
  const { handleSubmit, loginType } = props;
  return (
    <Wrapper className="container-fluid">
      <div className="row align-items-stretch justify-content-center">
        <div className="col-md-6">
          <div className="col-12 col-md-8 col-xl-6 m-auto min-vh-100 d-flex align-items-center px-0">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={logo}
                    style={{ width: '242px', height: '48px' }}
                    alt="logo"
                  />
                </div>
                <h3 className="text-center mt-5 mb-3">Connect to Gearment</h3>
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <div className="social-login-icon d-flex align-items-center justify-content-center">
                    <img
                      className={'img-fluid'}
                      src={loginType === 'facebook' ? facebookIcon : googleIcon}
                      style={{
                        width: loginType === 'facebook' ? '15px' : '30px',
                      }}
                      alt=""
                    />
                  </div>
                  <img
                    className="mx-3 cursor-pointer"
                    src={linkedIcon}
                    style={{ width: '20px' }}
                    alt=""
                  />
                  <div className="social-login-icon d-flex align-items-center justify-content-center">
                    <img
                      className={'img-fluid'}
                      src={logoIcon}
                      style={{ width: '30px' }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="form-group">
                  <label className="name-field">First name</label>
                  <GMTextbox
                    name="firstName"
                    placeholder="First name"
                    className="input-field"
                    showError
                    maxLength={256}
                  />
                </div>
                <div className="form-group">
                  <label className="name-field">Last name</label>
                  <GMTextbox
                    name="lastName"
                    placeholder="Last name"
                    className="input-field"
                    showError
                    maxLength={256}
                  />
                </div>
                <div className="form-group">
                  <label className="name-field">Email</label>
                  <GMTextbox
                    name="email"
                    placeholder="Email"
                    className="input-field"
                    showError
                    maxLength={256}
                    disabled
                  />
                </div>
                <div className="policy">
                  By continuing, you agree to Gearment's{' '}
                  <a
                    href="https://gearment.com/p/terms-of-services/"
                    targer="_blank"
                  >
                    Term Of Services
                  </a>{' '}
                  and acknowledge you've read our{' '}
                  <a
                    href="https://gearment.com/p/privacy-policy/"
                    targer="_blank"
                  >
                    Privacy Policies
                  </a>
                </div>
                <div className="login-button">
                  <GMButton type="submit" size="lg" className="login-button">
                    Create new account
                  </GMButton>
                </div>

                <div className="text-center footer-login">
                  <span className="al">Already have an account?</span>
                  <Link className="sign-up ml-2" to="/auth/login">
                    Log in
                  </Link>
                </div>
              </div>
            </form>
          </div>
          {GMCopyright}
        </div>
        <div className="col-md-6 right-authentication-section">
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            <RenderAuthenticationSlider />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default connect()(
  reduxForm({
    form: 'formSocialRegister',
    enableReinitialize: true,
    validate,
  })(index)
);
