import { actionType as TYPE } from "./actions";
import {notification} from "antd";

const initialState = {
  loading: false,
};

function PostCreateReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.GIFTMESSAGE_CREATE.REQUEST:
      return {
        ...state,
        loading: true
      };
    case TYPE.GIFTMESSAGE_CREATE.SUCCESS:
      notification.success({
        message: "Create gift message successfully.",
        duration: 5
      });
      return {
        ...state,
        loading: false
      };
    case TYPE.GIFTMESSAGE_CREATE.ERROR:
      notification.warn({
        message: action.error && action.error[0] && action.error[0]['msg'] ? action.error[0]['msg'] : 'Create Gift Message Failed.',
        duration: 5
      });
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

export default PostCreateReducer;
