import {actionType} from "./actions"

const initialState = {
    getInvoiceTransaction: {
        data: [],
        // config: {},
        loading: false,
    },
}

function InvoiceReducer(state = initialState, action){
    switch(action.type) {
        case actionType.INVOICE_TRANSACTIONS.REQUEST:
            return {
                ...state,
                getInvoiceTransaction: {
                    ...state.getInvoiceTransaction,
                    loading: true
                }
            }
        case actionType.INVOICE_TRANSACTIONS.SUCCESS:
            return {
                ...state,
                getInvoiceTransaction: {
                    ...state.getInvoiceTransaction,
                    loading: false,
                    data: action.data? action.data: {},
                },
            }
        case actionType.INVOICE_TRANSACTIONS.ERROR:
            return {
                ...state,
                getInvoiceTransaction: {
                    ...state.getInvoiceTransaction,
                    loading: false
                }
            }
        default:
            return state
    }
}

export default InvoiceReducer