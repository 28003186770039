import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';

import {
  listCampaigns,
  archiveCampaigns,
  unarchiveCampaigns,
  syncCampaigns,
  deleteCampaigns,
  previewCampaigns,
  listProductByCampaign,
  countCampaign,
  previewListCampaign,
} from 'apis/campaign';

import { getColors } from 'apis/ex_products';
import { replace } from 'lodash';

function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(listCampaigns, params);
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}

function* listColorSaga(action) {
  try {
    const { params, id } = action;
    const response = yield call(getColors, id, params);
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_COLOR.SUCCESS, ...response });
    } else {
      yield all([put({ type: TYPE.LIST_COLOR.ERROR, response })]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.LIST_COLOR.ERROR, error })]);
  }
}

function* archiveCampaignsSaga(action) {
  const { data, params } = action;
  try {
    const response = yield call(archiveCampaigns, data);
    yield all([
      put({ type: TYPE.ARCHIVE.SUCCESS, errors: response }),
      put({
        type: TYPE.LIST.REQUEST,
        params,
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.ARCHIVE.ERROR, error })]);
  }
}

function* unarchiveCampaignsSaga(action) {
  const { data, params } = action;
  try {
    const response = yield call(unarchiveCampaigns, data);
    yield all([
      put({ type: TYPE.UNARCHIVE.SUCCESS, errors: response }),
      put({
        type: TYPE.LIST.REQUEST,
        params,
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.UNARCHIVE.ERROR, error })]);
  }
}

function* deleteCampaignsSaga(action) {
  const { data, params } = action;
  try {
    const response = yield call(deleteCampaigns, data);
    yield all([
      put({ type: TYPE.DELETE.SUCCESS, errors: response }),
      put({
        type: TYPE.LIST.REQUEST,
        params,
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.DELETE.ERROR, error })]);
  }
}

function* syncCampaignsSaga(action) {
  const { data, params } = action;
  try {
    const response = yield call(syncCampaigns, data);
    yield all([
      put({ type: TYPE.SYNC.SUCCESS, errors: response }),
      put({
        type: TYPE.LIST.REQUEST,
        params,
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.SYNC.ERROR, error })]);
  }
}

function* previewCampaignsSaga(action) {
  const { data, params } = action;
  try {
    const response = yield call(previewCampaigns, data);
    yield all([
      put({ type: TYPE.PREVIEW.SUCCESS, errors: response }),
      put({
        type: TYPE.LIST.REQUEST,
        params,
      }),
    ]);
  } catch (error) {
    yield all([yield put({ type: TYPE.c.ERROR, error })]);
  }
}

//Preview modal in product list 
function* previewCampaignsListSaga(action) {
  try {
    const { id } = action;
    const response = yield call(previewListCampaign, id);
    if (response.code === "success") {
      yield put({ type: TYPE.MODAL_PREVIEW_PRODUCT.SUCCESS, ...response });
    } else {
      yield all([
        put({
          type: TYPE.MODAL_PREVIEW_PRODUCT.FAIL,
          error: { message: "Not found product" },
        }),
        put(replace(`/products/list`)),
      ]);
    }
  } catch (error) {
    yield all([
      put({ type:  TYPE.MODAL_PREVIEW_PRODUCT.FAIL, error }),
      put(replace("/products/list")),
    ]);
  }
}

function* listProductByCampaignSaga(action) {
  try {
    const { campaignId, params } = action;
    const response = yield call(listProductByCampaign, campaignId, params);
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_PRODUCT_BY_CAMPAIGN.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_PRODUCT_BY_CAMPAIGN.ERROR, response });
    }
  } catch (error) {
    yield all([
      put({ type: TYPE.LIST_PRODUCT_BY_CAMPAIGN.ERROR, error }),
      takeLatest(
        TYPE.LIST_PRODUCT_BY_CAMPAIGN.REQUEST,
        listProductByCampaignSaga
      ),
    ]);
  }
}

//Count achived
function* countCampaignSaga(action) {
  try {
    const response = yield call(countCampaign);
    if (response.code === 'success') {
      yield put({ type: TYPE.COUNT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.COUNT.ERROR, errors: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.COUNT.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga);
  yield takeLatest(TYPE.ARCHIVE.REQUEST, archiveCampaignsSaga);
  yield takeLatest(TYPE.UNARCHIVE.REQUEST, unarchiveCampaignsSaga);
  yield takeLatest(TYPE.SYNC.REQUEST, syncCampaignsSaga);
  yield takeLatest(TYPE.PREVIEW.REQUEST, previewCampaignsSaga);
  yield takeLatest(TYPE.MODAL_PREVIEW_PRODUCT.REQUEST, previewCampaignsListSaga);

  yield takeLatest(TYPE.DELETE.REQUEST, deleteCampaignsSaga);
  yield takeLatest(TYPE.LIST_COLOR.REQUEST, listColorSaga);
  yield takeLatest(
    TYPE.LIST_PRODUCT_BY_CAMPAIGN.REQUEST,
    listProductByCampaignSaga
  );
  yield takeLatest(TYPE.COUNT.REQUEST, countCampaignSaga);
}

export default watcher;
