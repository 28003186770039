import React, { Component } from "react";
import { connect } from "react-redux";
import {
  change,
  formValueSelector,
  reduxForm,
} from "redux-form";
import validate from "./validate";
import { Drawer } from "antd";
import CloseIcon from "images/x2.svg";
import AdvancedFilter from "../../../ArchivedOrder/components/FormFilter/AdvancedFilter";
import { GMTextbox } from "components/InputFields";
import { ReactComponent as SearchIcon2 } from 'images/icons/search-md.svg';
import { ReactComponent as ClearIcon } from 'images/icons/x-close.svg';
import { ReactComponent as FilterIcon } from 'images/icons/filter-lines.svg';
class index extends Component {
  state = {
    advance_search: false,
  };
  onCloseAdvancedSearch = () => {
    this.setState({
      advance_search: false,
    });
  };
  onOpenAdvancedSearch = () => {
    this.setState({
      advance_search: true,
    });
  };

  render() {
    const {
      handleSubmit,
      stores,
      exproducts,
      changeFieldValue,
      productId,
      colorId,
      profile,
      handleAdvancedSubmit,
      initialValues,
      is_mobile,
      filterValues,
    } = this.props;
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="filter-section">
            <GMTextbox
              name="orderName"
              className="id-search"
              placeholder="Search"
              iconBefore={<SearchIcon2 />}
              iconAfter={
                filterValues?.orderName?.length > 0 && (
                  <ClearIcon
                    className="clear"
                    onClick={() => {
                      changeFieldValue('orderName', '');
                      this.debounceAutoSubmit();
                    }}
                  />
                )}
              onChange={this.debounceAutoSubmit}
            />
            <div
              className="filter-button"
              onClick={this.onOpenAdvancedSearch}
            >
              <FilterIcon className="filter" />
              Filters
            </div>
          </div>
        </form>
        <Drawer
          title={<b className="Filter">Filter</b>}
          className="custom-filter"
          placement="right"
          closable={true}
          onClose={this.onCloseAdvancedSearch}
          visible={this.state.advance_search}
          closeIcon={
            <img src={CloseIcon} style={{ width: "20px" }} alt="close-icon" />
          }
          width={this.props.is_mobile ? "80%" : "480px"}
        >
          <AdvancedFilter
            onCloseAdvancedSearch={this.onCloseAdvancedSearch}
            onSubmit={handleAdvancedSubmit}
            stores={stores}
            exproducts={exproducts}
            changeFieldValue={changeFieldValue}
            initialValues={initialValues}
            productId={productId}
            colorId={colorId}
          />
        </Drawer>
      </>
    );
  }
}
const selector = formValueSelector("formFilterDeletedOrderList");
index = reduxForm({
  form: "formFilterDeletedOrderList",
  validate,
  enableReinitialize: true,
})(index);

const mapStateToProps = (state) => ({
  productId: selector(state, "productId"),
  colorId: selector(state, "colorId"),
  filterValues: state?.form?.formFilterDeletedOrderList?.values || {},
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change("formFilterDeletedOrderList", field, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
