import styled from "styled-components";
import PageContainer from "components/PageContainer";
import {
  BASE_WHITE,
  GRAY_300,
  GRAY_400,
  GRAY_50,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_300,
  PRIMARY_500,
  SHADOW_XS
} from "components/colors";

export const Wrapper = styled(PageContainer)`
  .ant-select .ant-select-selector,
  .ant-picker {
    height: 44px;
    line-height: 44px;
    padding: 0 14px;
    outline: none;
    width: 100%;

    color: ${GRAY_900};
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    &:placeholder {
      color: ${GRAY_400};
    }
    &:focus-visible,
    &:focus {
      outline: none;
      border: 1px solid ${PRIMARY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    }
    &:disabled {
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    }
  }
  .ant-picker-input,
  .input {
    input {
      &::placeholder {
        color:  ${GRAY_400}
      }
    }
  }
  .ctas {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
  .integration-section .platform-item, .guideline-section .guideline-item, .shortcut-section .shortcut-item, .trending-product-section .trending-product-item, .file-type-section .file-type-item, .sync-order-platform-section .sync-order-platform-item, .payment-method-section .payment-method-item {
    border-radius: 8px;
    &.active {
      border: 1px solid ${PRIMARY_500};
    }
  }
`;
