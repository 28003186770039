import {
  BASE_BLACK,
  BASE_WHITE,
  GRAY_200,
  GRAY_300,
  GRAY_50,
  GRAY_500,
  GRAY_600,
  GRAY_700
} from 'components/colors';
import styled from 'styled-components';
import facebook_gray_default from 'images/icons/social/facebook_gray_default.svg';
import facebook_brand_default from 'images/icons/social/facebook_brand_default.svg';
import linkedin_gray_default from 'images/icons/social/linkedin_gray_default.svg';
import linkedin_brand_default from 'images/icons/social/linkedin_brand_default.svg';
import { BREAKPOINTS } from 'components/const';

export const FooterWrapper = styled.div`
  background-color: ${BASE_WHITE};
  font-weight: 400;
  color: ${GRAY_600};
  .wrapper {
    padding: 0 32px;
    padding-bottom: 20px;
    @media (min-width: ${BREAKPOINTS.xl}) {
      /* padding: 0 80px; */
      padding-bottom: 40px;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    row-gap: 16px;
    flex-direction: column;
    @media (min-width: ${BREAKPOINTS.xl}) {
      flex-direction: row;
      padding-top: 40px;
    }

    border-bottom: 1px solid ${GRAY_200};
    padding-bottom: 32px;
    a {
      color: ${GRAY_500};
      white-space: nowrap;
      &:hover {
        color: ${GRAY_600};
      }
    }
  }
  .addr {
    color: ${BASE_BLACK};
    padding-top: 24px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    b {
      font-weight: 600;
    }
    p {
      display: flex;
      row-gap: 8px;
      flex-direction: column;
    }
  }
  .footer {
    padding: 20px 32px;
    background-color: ${GRAY_50};
    font-size: 16px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: ${BREAKPOINTS.sm}) {
      padding: 40px 32px;
      align-items: center;
      flex-direction: row;
    }

    a {
      &.facebook,
      &.linkedin {
        font-size: 0;
        display: inline-block;
        width: 24px;
        height: 24px;
      }
      &.facebook {
        background-image: url(${facebook_gray_default});
        &:hover {
          background-image: url(${facebook_brand_default});
        }
      }
      &.linkedin {
        background-image: url(${linkedin_gray_default});
        &:hover {
          background-image: url(${linkedin_brand_default});
        }
      }
    }

    .ref-links {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 24px;
    }
  }
  .info {
    display: flex;
    row-gap: 8px;
    white-space: nowrap;
    flex-direction: column;
    .footer-logo {
      width: 160px;
    }
    svg {
      stroke: ${GRAY_500};
    }
    p {
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }
  }
  ul.menu {
    display: flex;
    gap: 32px;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    flex-direction: column;
    row-gap: 32px;
    margin-top: 24px;
    @media (min-width: ${BREAKPOINTS.xl}) {
      margin-top: 0;
      flex-direction: row;
    }
    li {
      margin: 0;
      padding: 0;
      a {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
`;
