import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Spin, Pagination } from 'antd';
import { getList } from './actions';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import getMomentInstance from 'utils/moment';
import {
  getOptionLabel,
  export_product_status_email,
  export_product_status,
} from 'utils/options';
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      showDetail: false,
      selected: null,
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getList(params);
  };

  render() {
    const { export_product, pageName } = this.props;
    const columns = [
      {
        title: <b>ID</b>,
        align: 'center',
        dataIndex: 'id',
        key: 'id',
        render: (text) => (
          <Link to={`/logs/export-feed/${text}`}>#{text}</Link>
        ),
      },
      {
        title: <b>EXPORT TYPE</b>,
        align: 'center',
        dataIndex: 'module',
        key: 'module',
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>DOWNLOAD</b>,
        align: 'center',
        dataIndex: 'linkDownload',
        key: 'linkDownload',
        render: (text, record) => {
          return text ? (
            <a href={record.linkDownload} taget="_blank">
              File export
            </a>
          ) : (
            'N/A'
          );
        },
      },
      {
        title: <b>TIME REQUEST</b>,
        align: 'center',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => {
          return getMomentInstance(text, null, true);
        },
      },

      {
        title: <b>EXPORT STATUS </b>,
        align: 'center',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
          return getOptionLabel(export_product_status, text);
        },
      },
      {
        title: <b>SEND EMAIL STATUS </b>,
        align: 'center',
        dataIndex: 'sendEmail',
        key: 'sendEmail',
        render: (text) => {
          return getOptionLabel(export_product_status_email, text);
        },
      },
    ];
    return (
      <>
        <div className="Rectangle-848 px-3 py-1">
          <div className="Orders p-2">{pageName}</div>
        </div>
        <div className="m-3">
          <Spin spinning={export_product.loading}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={export_product.data}
              loading={export_product.loading}
              scroll={{ x: 1000 }}
              pagination={
                !this.props.is_mobile
                  ? {
                      showTotal: (total) => `Total ${total} items`,
                      total: export_product.total,
                      pageSize: parseInt(
                        export_product.params.perPage > export_product.total
                          ? export_product.total
                          : export_product.params.perPage
                      ),
                      current: export_product.page,
                      showSizeChanger: true,
                      onChange: (page, perPage) =>
                        this.fetchData({
                          ...export_product.params,
                          page,
                          perPage,
                        }),
                    }
                  : false
              }
            />
          </Spin>
          {this.props.is_mobile && (
            <div className="d-flex justify-content-end align-items-center py-3 px-3">
              <div>
                <Pagination
                  size="small"
                  showSizeChanger
                  className="pagination"
                  total={export_product.total}
                  defaultCurrent={export_product?.params?.page || 1}
                  pageSize={export_product?.params?.perPage || 0}
                  onChange={(page, perPage) =>
                    this.fetchData({
                      ...export_product.params,
                      page:
                        page === Number.NEGATIVE_INFINITY
                          ? 1
                          : page === 0
                          ? 1
                          : page,
                      perPage,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    export_product: state.campaign.export_product.export_product,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
  })
)(List);
