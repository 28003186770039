import SyncProductEdit from './scenes/Edit'

export default [
 
    {
        key: 'logs.sync-products.detail',
        name: 'Sync product log detail',
        component: SyncProductEdit,
        path: '/logs/sync-product/:id',
        icon: null,
        template: 'default',  
        isPublic: true
    },
]