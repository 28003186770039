import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Table, Modal, Pagination, Tooltip } from 'antd';
import { Menu } from 'antd';
import {
  syncTracking,
  syncTrackingAmazon,
  createIssue,
  countOrder,
  foreceVerifiedDelivery,
} from '../List/actions';
import RenderListActions from 'utils/Form/RenderListActions';
import {
  getListOrderAchived,
  unarchivedOrder,
  deleteOrder,
  getcountOrderAchived,
  exportArchivedOrders,
} from './actions';
import FormFilter from './components/FormFilter';
import SyncOrderModal from '../List/components/SyncOrderModal';
import {
  getOptionLabel,
  options_payment_status,
  pageSizeOptions,
  listlUsersPermittedToVerifyForcedDelivery,
} from 'utils/options';
import getMomentInstance from 'utils/moment';
import money from 'utils/money';
import RenderOrderPlatformInfo from 'utils/Form/RenderOrderPlatformInfo';
import RenderTrackingInfo from 'utils/Form/RenderTrackingInfo';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import QuickViewModal from '../List/components/QuickViewModal';
import { getListStore } from '../../../Stores/scenes/List/actions';
import { getListExproduct } from '../../../Mockups/scenes/Create/actions';
import RenderOrderStatus from 'utils/Form/RenderOrderStatus';
import {
  addMarginTopToCheckBoxOfAntdTable,
  checkFunctionalPermission,
} from 'utils/helper';
import { GlobalStyle, Wrapper } from './styled';

import { ReactComponent as I1Icon } from 'images/icons/marker-pin-01.svg';
import { ReactComponent as I2Icon } from 'images/icons/check-circle.svg';
import { ReactComponent as I3Icon } from 'images/icons/container.svg';
import { ReactComponent as I4Icon } from 'images/icons/lock-01.svg';
import classNames from 'classnames';

const { confirm } = Modal;
class List extends Component {
  constructor(props) {
    super(props);
    const { fromDate, toDate, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      showModal: null,
      currIdToQuickView: null,
      selectedRowKeys: [],
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        productId: '0',
        colorIds: [],
        sizeIds: [],
        trackingType: null,
        verifiedAddress: '-1',
        isApproved: '-1',
        orderNote: '0',
        isImport: '0',
        status: '-1',
        insertMethod: '-1',
        paymentStatus: '-1',
        isDuplicated: '-1',
        typeDate: '1',
        storeId: '0',
        orderName: '',
        fromDate: fromDate ? getMomentInstance(fromDate, 0, false, true) : null,
        toDate: toDate ? getMomentInstance(toDate, 0, false, true) : null,
        ...query_params,
      },
    };
  }

  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
    this.props.getListStore({});
    this.props.getcountOrderAchived({ storeId: 0, achived: 0 });
    this.props.getListExproduct({ sortBy: 'type', sortType: 'asc' });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.orders) !== JSON.stringify(this.props.orders)
    ) {
      const newSelectedRowKeys = this.state.selectedRowKeys.filter((id) =>
        (this.props?.orders?.data || []).find(
          (item) => parseInt(item.id) === parseInt(id)
        )
      );
      this.setState({ selectedRowKeys: newSelectedRowKeys });
    }
    addMarginTopToCheckBoxOfAntdTable(
      'ant-table-selection',
      this.state.selectedRowKeys
    );
  }
  fetchData = ({ date_range, page = 1, perPage = 100, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (date_range?.[0]) {
      params['fromDate'] = getMomentInstance(date_range[0]).format(
        'YYYY-MM-DD'
      );
    }
    if (date_range?.[1]) {
      params['toDate'] = getMomentInstance(date_range[1]).format('YYYY-MM-DD');
    }
    if (parseInt(params['isApproved']) === -1) delete params['isApproved'];
    if (parseInt(params['orderNote']) === 0) delete params['orderNote'];
    if (parseInt(params['isDuplicated']) === -1) delete params['isDuplicated'];
    if (parseInt(params['isImport']) === 0) delete params['isImport'];
    if (parseInt(params['status']) === -1) delete params['status'];
    if (parseInt(params['insertMethod']) === -1) delete params['insertMethod'];
    if (parseInt(params['paymentStatus']) === -1)
      delete params['paymentStatus'];
    if (parseInt(params['storeId']) === 0) delete params['storeId'];
    // if (!(params['trackingType']) === '-1') delete params['trackingType'];
    if (parseInt(params['verifiedAddress']) === -1)
      delete params['verifiedAddress'];
    if (parseInt(params['typeDate']) === 0) delete params['typeDate'];
    if (parseInt(params['productId']) === 0) delete params['productId'];
    if (!Array.isArray(params['sizeIds']) || params['sizeIds'].length === 0)
      delete params['sizeIds'];
    if (!Array.isArray(params['colorIds']) || params['colorIds'].length === 0)
      delete params['colorIds'];
    if (params['colorId']) delete params['colorId'];
    if (!params['fromDate']) delete params['fromDate'];
    if (!params['toDate']) delete params['toDate'];
    if (!params['orderName']) delete params['orderName'];
    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getListOrderAchived({
      ...params,
    });
  };
  handleOnExport = () => {
    let params = { ...this.props.filterOrderFormValues };
    if (parseInt(params['isApproved']) === -1) delete params['isApproved'];
    if (parseInt(params['orderNote']) === 0) delete params['orderNote'];
    if (parseInt(params['isDuplicated']) === -1) delete params['isDuplicated'];
    if (parseInt(params['isImport']) === 0) delete params['isImport'];
    if (parseInt(params['status']) === -1) delete params['status'];
    if (parseInt(params['insertMethod']) === -1) delete params['insertMethod'];
    if (parseInt(params['paymentStatus']) === 0) delete params['paymentStatus'];
    if (parseInt(params['storeId']) === 0) delete params['storeId'];
    if (!params['orderName']) delete params['orderName'];
    // if (!(params['trackingType']) === '-1') delete params['trackingType'];
    if (parseInt(params['verifiedAddress']) === -1)
      delete params['verifiedAddress'];
    if (parseInt(params['typeDate']) === 0) delete params['typeDate'];
    if (parseInt(params['productId']) === 0) delete params['productId'];
    if (!Array.isArray(params['sizeIds']) || params['sizeIds'].length === 0)
      delete params['sizeIds'];
    if (!Array.isArray(params['colorIds']) || params['colorIds'].length === 0)
      delete params['colorIds'];
    if (params['colorId']) delete params['colorId'];
    if (!params['fromDate']) delete params['fromDate'];
    if (!params['toDate']) delete params['toDate'];
    this.props.exportOrders({
      export: 1,
      ...params,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  showQuickViewModal = (orderId) => {
    this.setState({ showModal: 'quick_view', currIdToQuickView: orderId });
  };
  handleChangeTab = (activeKey) => {
    if (activeKey === '9999') {
      return;
    }
    const currrentActiveTabKey = this.tabList[activeKey * 1];
    const qStr = queryString.parse(
      this.props.countOrder?.data?.[currrentActiveTabKey]?.queryString
    );
    if (!isNaN(activeKey * 1)) {
      this.fetchData({ page: 1, ...qStr });
    }
    return;
  };
  getActiveTag = (status) => {
    const items = Object.values(this.props.countOrder?.data);
    const str = queryString.stringify(status);
    let tmp = -1;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (str.indexOf(item.queryString?.substr(1)) !== -1) {
        tmp = item;
        break;
      }
    }
    if (this.tabList.indexOf(tmp?.title) === -1) {
      if (!status?.isImport) {
        return '0';
      }
    }
    return this.tabList.indexOf(tmp?.title) + '';
  };
  unarchiveOrder = (ids) => {
    const { unarchiveOrder } = this.props;
    confirm({
      title: 'Do you want to unarchive these orders?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        unarchiveOrder({ orders: ids });
        window.scroll(0, 0);
      },
      onCancel() {},
    });
  };

  deleteOrder = (ids) => {
    const { deleteOrder } = this.props;
    confirm({
      title: 'Do you want to delete these orders?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        deleteOrder({ orders: ids });
        window.scroll(0, 0);
      },
      onCancel() {},
    });
  };

  syncTracking = (ids) => {
    const { syncTracking } = this.props;
    confirm({
      title: 'Do you want to sync tracking for these orders?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        syncTracking({ orders: ids });
        window.scroll(0, 0);
      },
      onCancel() {},
    });
  };

  syncTrackingAmazon = (ids) => {
    const { syncTrackingAmazon } = this.props;
    confirm({
      title: 'Do you want to sync tracking Amazon for these orders?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        syncTrackingAmazon({ orders: ids });
        window.scroll(0, 0);
      },
      onCancel() {},
    });
  };

  createIssue = (ids) => {
    const redirectToCreateIssue = () => {
      this.props.history.push('/orders/order-issues/create', {
        listOrderId: ids,
      });
    };
    confirm({
      title: 'Do you want to create issue for these orders?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        redirectToCreateIssue();
        window.scroll(0, 0);
      },
      onCancel() {},
    });
  };

  foreceVerifiedDelivery = (ids) => {
    const { foreceVerifiedDelivery } = this.props;
    confirm({
      title: 'Do you want to force verified deliver for these orders?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        foreceVerifiedDelivery({ orders: ids });
        window.scroll(0, 0);
      },
      onCancel() {},
    });
  };

  handleOnSubmitSyncOrderForm = (values) => {
    this.props.history.push('/orders/sync', { platformId: values.platformId });
  };

  tabList = [
    'All Orders',
    'Pending',
    'Processing',
    'Completed',
    'Order Shipping',
    'On-Hold',
    'Canceled',
  ];

  render() {
    const {
      orders,
      pageName,
      syncTrackingLoading,
      syncTrackingAmazonLoading,
      unarchivedOrderLoading,
      deleteOrderLoading,
      stores,
      exproducts,
      countOrderAchived,
      exportLoading,
      profile,
    } = this.props;
    const { initial_filter_values, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: 'unarchived_order',
          text: 'Unarchive order',
          onSelect: () => {
            this.unarchiveOrder(selectedRowKeys);
          },
        },
        {
          key: 'create_issue',
          text: 'Create issue',
          onSelect: () => {
            this.createIssue(selectedRowKeys);
          },
        },
        {
          key: 'sync_tracking_amazon',
          text: 'Sync tracking - Order Amazon',
          onSelect: () => {
            this.syncTrackingAmazon(selectedRowKeys);
          },
        },
      ],
    };
    if (parseInt(this.props.orders?.config?.autoSyncTracking) === 0) {
      rowSelection['selections'].push({
        key: 'sync_tracking',
        text: 'Sync tracking',
        onSelect: () => {
          this.syncTracking(selectedRowKeys);
        },
      });
    }
    // if (
    //   listlUsersPermittedToVerifyForcedDelivery.includes(profile?.primaryId) ||
    //   listlUsersPermittedToVerifyForcedDelivery.includes(profile?.id)
    // ) {
    //   rowSelection['selections'].push({
    //     key: 'force_verified_delivery',
    //     text: 'Force verified delivery',
    //     onSelect: () => {
    //       this.foreceVerifiedDelivery(selectedRowKeys);
    //     },
    //   });
    // }

    if (checkFunctionalPermission(profile, 'deleteOrderPermission')) {
      rowSelection['selections'].push({
        key: 'delete_order',
        text: 'Delete order',
        onSelect: () => {
          this.deleteOrder(selectedRowKeys);
        },
      });
    }
    const columns = [
      {
        title: '',
        width: 10,
      },
      {
        title: 'Store',
        dataIndex: 'platform',
        align: 'left',
        key: 'platform',
        width: 90,
        shouldCellUpdate: (record, prevRecord) =>
          JSON.stringify(record) !== JSON.stringify(prevRecord),
        render: (value, record) => {
          return (
            <RenderOrderPlatformInfo order={record} isOnlyShowIcon={true} />
          );
        },
      },
      {
        title: 'Reference ID',
        dataIndex: 'onlineReference',
        align: 'left',
        key: 'onlineReference',
        width: 150,
        shouldCellUpdate: (record, prevRecord) =>
          JSON.stringify(record) !== JSON.stringify(prevRecord),
        render: (value, record) => {
          return value || 'N/A';
        },
      },
      {
        title: 'Order',
        dataIndex: 'name',
        width: 180,
        align: 'left',
        key: 'name',
        render: (value, record) => (
          <div>
            <Link to={`/orders/${record.id}`}>{value || `#${record.id}`}</Link>
            <div className="tags">
              <Tooltip
                title={
                  record?.verifiedAddress === 1
                    ? 'Verified Address'
                    : 'Unverified Address'
                }
              >
                <I1Icon
                  className={classNames({
                    active: record?.verifiedAddress === 1,
                  })}
                />
              </Tooltip>
              <Tooltip
                title={
                  record?.orderMatch?.isImport === 1
                    ? 'Matched Product'
                    : 'Unmatched Product'
                }
              >
                <I3Icon
                  className={classNames({
                    active: record?.orderMatch?.isImport === 1,
                  })}
                />
              </Tooltip>
              <Tooltip title={record?.isApproved ? 'Approved' : 'Unapproved'}>
                <I2Icon
                  className={classNames({ active: record?.isApproved })}
                />
              </Tooltip>
              <Tooltip title={!record?.locked ? 'Unlocked' : 'Locked'}>
                <I4Icon className={classNames({ active: record?.locked })} />
              </Tooltip>
            </div>
            {/* {record.onlineReference && (
                  <p>Reference: {record.onlineReference}</p>
                )}
                <RenderOrderInfo order={record} /> */}
            {/* <p
                  className="text-primary cursor-pointer"
                  onClick={this.showQuickViewModal.bind(this, record.id)}
                >
                  Quick view
                </p> */}
          </div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 150,
        align: 'left',
        key: 'status',
        render: (text, record) => <RenderOrderStatus order={record} />,
      },
      {
        title: 'Tracking',
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        width: 150,
        render: (text, record) => {
          return <RenderTrackingInfo order={record} />;
        },
      },
      {
        title: 'Customer name',
        dataIndex: 'cusName',
        key: 'cusName',
        align: 'left',
        width: 150,
        render: (text) => {
          return text;
        },
      },
      {
        title: 'Payment',
        dataIndex: 'paymentStatus',
        width: 120,
        align: 'left',
        key: 'paymentStatus',
        render: (text, record) =>
          getOptionLabel(options_payment_status, text, false),
      },
      // {
      //   title: 'SHIPPING NAME',
      //   width: 130,
      //   dataIndex: "cusName",
      //   key: "cusName",
      // },
      // {
      //   title: 'RECEIVED TIME',
      //   dataIndex: "purchaseDateOnline",
      //   width: 130,
      //   key: "purchaseDateOnline ",
      //   render: (text, record) => {
      //     if (record.purchaseDateOnline > 0) {
      //       return getMomentInstance(null, record.purchaseDateOnline, true);
      //     } else {
      //       return orders.params.sortBy === "updateAt"
      //         ? getMomentInstance(record.updateAt, null, true)
      //         : getMomentInstance(record.createdAt, null, true);
      //     }
      //   },
      // },
      {
        title: 'Approved time',
        dataIndex: 'approvedAt',
        width: 150,
        align: 'left',
        key: 'approvedAt ',
        render: (text, record) =>
          text ? getMomentInstance(null, text, true) : 'N/A',
      },
      {
        title: 'Created date',
        align: 'left',
        dataIndex: 'createdAt',
        width: 100,
        key: 'createdAt',
        render: (v) => (v ? getMomentInstance(v, null, true) : 'N/A'),
        //
      },
      {
        title: 'Qty.',
        dataIndex: 'quantity',
        align: 'left',
        width: 100,
        key: 'quantity',
        render: (value) => value || 0,
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: 100,
        align: 'left',
        render: (text) => (
          <span className="price">{text ? money(text) : 'N/A'}</span>
        ),
      },
    ];

    let listActions = [];
    listActions.unshift({
      key: 'unarchived_order',
      text: 'Unarchive order',
      onSelect: () => {
        this.unarchiveOrder(selectedRowKeys);
      },
    });

    listActions.unshift({
      key: 'delete_order',
      text: 'Delete order',
      onSelect: () => {
        this.deleteOrder(selectedRowKeys);
      },
    });

    listActions.unshift({
      key: 'create_issue',
      text: 'Create issue',
      onSelect: () => {
        this.createIssue(selectedRowKeys);
      },
    });

    listActions.unshift({
      key: 'sync_tracking',
      text: 'Sync tracking',
      onSelect: () => {
        this.syncTracking(selectedRowKeys);
      },
    });

    listActions.unshift({
      key: 'sync_tracking_amazon',
      text: 'Sync tracking - Order Amazon',
      onSelect: () => {
        this.syncTrackingAmazon(selectedRowKeys);
      },
    });
    return (
      <>
        <Wrapper
          pageName={pageName}
          noContainer
          ctas={
            <RenderListActions
              title={'orders'}
              selectedRowKeys={this.state.selectedRowKeys}
              rowSelection={rowSelection}
              actions={listActions}
            />
          }
        >
          <div className="filter">
            <FormFilter
              profile={profile}
              stores={stores.data}
              exproducts={exproducts.data}
              handleOnExport={this.handleOnExport}
              showModal={(name) => {
                this.setState({ showModal: name });
              }}
              initialValues={initial_filter_values}
              onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
              handleAdvancedSubmit={this.fetchData}
              is_mobile={this.props.is_mobile}
            />
          </div>
          <div className="main-content">
            {/* <Tabs
              defaultActiveKey={this.getActiveTag(orders?.params)}
              activeKey={this.getActiveTag(orders?.params)}
              onChange={this.handleChangeTab}
            >
              {this.tabList.map((tab, i) => {
                return (
                  <Tabs.TabPane
                    tab={
                      this.props.countOrder.loading ? (
                        <>
                          <span className="mr-2">{tab} </span>
                          <img
                            src={DotLoadingIcon}
                            width={20}
                            alt="loading"
                          />{" "}
                        </>
                      ) : (
                        `${tab} (${
                          this.props.countOrder.data?.[tab]?.value || 0
                        })`
                      )
                    }
                    key={i}
                  />
                );
              })}
              <Tabs.TabPane
                tab={
                  <Dropdown
                    placement="bottomCenter"
                    arrow
                    overlay={SubMenu}
                    trigger={["click"]}
                  >
                    <span className="font-weight-bold font-size-20">...</span>
                  </Dropdown>
                }
                key={9999}
              />
            </Tabs> */}
            <div className="custom-header-sticky">
              <Table
                rowKey="id"
                rowSelection={rowSelection}
                columns={columns}
                scroll={{ x: 1000 }}
                dataSource={orders.data}
                size="small"
                loading={
                  orders.loading ||
                  syncTrackingLoading ||
                  syncTrackingAmazonLoading ||
                  unarchivedOrderLoading ||
                  deleteOrderLoading ||
                  exportLoading ||
                  stores.loading
                }
                pagination={
                  !this.props.is_mobile
                    ? {
                        showTotal: (total) => `Total ${total} items`,
                        pageSizeOptions: pageSizeOptions,
                        total: orders.total,
                        pageSize: parseInt(
                          orders.params.perPage > orders.total
                            ? orders.total
                            : orders.params.perPage
                        ),
                        current: orders.params.page,
                        showSizeChanger: true,
                        onChange: (page, perPage) =>
                          this.fetchData({ ...orders.params, page, perPage }),
                      }
                    : false
                }
                sticky
              />
            </div>
            {this.props.is_mobile && (
              <div className="d-flex justify-content-end align-items-center py-3 px-3">
                <div>
                  <Pagination
                    size="small"
                    showSizeChanger
                    className="pagination"
                    total={orders.total}
                    defaultCurrent={orders?.params?.page || 1}
                    pageSize={orders?.params?.perPage || 0}
                    pageSizeOptions={pageSizeOptions}
                    onChange={(page, perPage) =>
                      this.fetchData({
                        ...orders.params,
                        page:
                          page === Number.NEGATIVE_INFINITY
                            ? 1
                            : page === 0
                            ? 1
                            : page,
                        perPage,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </Wrapper>
        <Modal
          footer={null}
          visible={this.state.showModal === 'sync_order'}
          onCancel={() => this.setState({ showModal: null })}
          width={800}
        >
          <SyncOrderModal
            onSubmit={this.handleOnSubmitSyncOrderForm}
            onCancel={() => this.setState({ showModal: null })}
            initialValues={{ platformId: 1 }}
          />
        </Modal>
        <Modal
          footer={null}
          visible={this.state.showModal === 'quick_view'}
          onCancel={() => this.setState({ showModal: null })}
          width={1200}
        >
          <QuickViewModal
            history={this.props.history}
            orderIdToQuickView={this.state.currIdToQuickView}
          />
        </Modal>
        <GlobalStyle />
      </>
    );
  }
}

export default connect(
  (state) => ({
    orders: state.order.order_achive.archivedOrders,
    unarchivedOrderLoading: state.order.order_achive.unarchiveOrder.loading,
    deleteOrderLoading: state.order.order_achive.deleteOrder.loading,
    countOrder: state.order.order_achive.countOrderAchived,
    syncTrackingLoading: state.order.list.syncTracking.loading,
    syncTrackingAmazonLoading: state.order.list.syncTrackingAmazon.loading,
    stores: state.store.list.stores2,
    exportLoading: state.order.order_achive.export.loading,
    countOrderAchived: state.order.order_achive.countOrderAchived,
    countOrderAchivedLoading:
      state.order.order_achive.countOrderAchived.loading,
    exproducts: state.mockup.create.exproducts,
    filterOrderFormValues: state.form.formFilterDeletedOrderList?.values,
    profile: state.auth.info.profile,
    FormFilterOrderArchived: state.form.formFilterDeletedOrderList,
    AdvancedFilterOrderArchived: state.form.AdvancedFilterOrdersArchived,
  }),
  (dispatch) => ({
    getListOrderAchived: (options) => {
      dispatch(getListOrderAchived(options));
    },
    unarchiveOrder: (data) => {
      dispatch(unarchivedOrder(data));
    },
    exportOrders: (params) => {
      dispatch(exportArchivedOrders(params));
    },
    deleteOrder: (data) => {
      dispatch(deleteOrder(data));
    },
    syncTracking: (data) => {
      dispatch(syncTracking(data));
    },
    syncTrackingAmazon: (data) => {
      dispatch(syncTrackingAmazon(data));
    },
    createIssue: (data) => {
      dispatch(createIssue(data));
    },
    foreceVerifiedDelivery: (data) => {
      dispatch(foreceVerifiedDelivery(data));
    },
    getCountOrder: (params) => {
      dispatch(countOrder(params));
    },
    getListStore: (options) => {
      dispatch(getListStore(options));
    },
    getcountOrderAchived: (params) => {
      dispatch(getcountOrderAchived(params));
    },
    getListExproduct: (params) => {
      dispatch(getListExproduct(params));
    },
  })
)(List);
