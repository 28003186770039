import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import _ from 'lodash';
import {
    FIREBASE_API_KEY,
    FIREBASE_PROJECT_ID,
    FIREBASE_APP_ID,
    FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_AUTH_DOMAIN,
    FIREBASE_MEASUREMENT_ID,
} from "web.config"
const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    databaseURL: "",
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: "",
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID,
};

const init = () => {
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
    }
}

init();


const ggProvider = new firebase.auth.GoogleAuthProvider();
const googleLogin = async (callback) => {
    try {
        let result = await firebase.auth().signInWithPopup(ggProvider);
        let credential = result.credential;

        if (!_.isEmpty(credential)) {
            if (_.isFunction(callback)) callback(null, credential);
        }
    } catch (err) {
        callback(err, null);
    }
}

const fbProvider = new firebase.auth.FacebookAuthProvider();
const facebookLogin = async (callback) => {
    try {
        let result = await firebase.auth().signInWithPopup(fbProvider);
        let credential = result.credential;
        if (!_.isEmpty(credential)) {
            if (_.isFunction(callback)) callback(null, credential);
        }
    } catch (err) {
        callback(err, null);
    }
}

const appleProvider = new firebase.auth.OAuthProvider('apple.com');
const appleLogin = async (callback) => {
    try {
        let result = await firebase.auth().signInWithPopup(appleProvider);
        let credential = result.credential;
        if (!_.isEmpty(credential)) {
            if (_.isFunction(callback)) callback(null, credential);
        }
    } catch (err) {
        callback(err, null);
    }
}




export {
    init,
    googleLogin,
    facebookLogin,
    appleLogin
};