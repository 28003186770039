import React, { useEffect } from "react";

import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { Tabs } from "antd";
import giftCartImage from "images/bg_giftcard.png";
import { capitalizeFirstLetter } from "utils/helper";
import { renderSwitchAnt, FieldColorPicker, renderTextarea } from "utils/Form";
import renderImageAntS3 from "utils/Form/renderImageAntS3";
import SelectStoreGiftMessage from "utils/Form/Search/SelectStoreGiftMessage";
import FieldRangeDateGiftMessage from "utils/fields/FieldRangeDateGiftMessage";
import validate from "./validate";
import { GMButton } from "components/Button";

let FormCreateGiftMessage = ({
  storesList,
  data,
  handleSubmit,
  fonts,
  textSize,
  colorHex,
  bgHex,
  imgLogo,
  message,
  frontImg,
  backImg,
  changeFieldValue,
  allStore,
  invalid,
  profile,
  timeStart,
  timeEnd,
  date_range,
  ...props
}) => {
  useEffect(() => {
    if (
      date_range &&
      Array.isArray(date_range) &&
      date_range[0] &&
      date_range[1]
    ) {
      changeFieldValue("timeStart", date_range[0].format("YYYY/MM/DD hh:mm A"));
      changeFieldValue("timeEnd", date_range[1].format("YYYY/MM/DD hh:mm A"));
    }
  }, [date_range]);

  return (
    <div>
      <form
        onSubmit={handleSubmit((values) =>
          props.onSubmit({
            ...values,
          })
        )}
      >
        <div className="row">
          <div className="col-md-12 col-xl-6">
            <div
              className="box-message"
              style={{ backgroundImage: giftCartImage, backgroundColor: bgHex }}
            >
              <div className="box_logo">
                <img
                  id="img_preview"
                  src={imgLogo}
                  style={{
                    height:
                      (message?.length || 0) > 0
                        ? "calc(40vw/2)"
                        : "calc(67vw/2)",
                  }}
                  alt=""
                />
              </div>
              <div
                className="box_text d-flex justify-content-center align-items-center"
                style={{ color: colorHex, fontSize: "17px" }}
              >
                {message}
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xl-6">
            <div className="form-group">
              <Tabs defaultActiveKey="1" className="mt-3 mt-md-0">
                <Tabs.TabPane
                  key="1"
                  tab={
                    <label
                      className="font-weight-bold text-dark mb-0"
                      style={{ fontSize: "16px" }}
                    >
                      About Store
                    </label>
                  }
                />
              </Tabs>
              <div>
                <div className="form-group">
                  <Field
                    name="storeIds"
                    component={SelectStoreGiftMessage}
                    placeholder="-- Select store--"
                    storesList={[
                      ...storesList.map((item) => ({
                        label: (
                          <p style={{ color: "#00a8ff" }} className={"my-0"}>
                            {(item.showName || item.showName) +
                              " - " +
                              capitalizeFirstLetter(item.backendType) +
                              " - " +
                              (item.status ? "Active" : "Inactive")}
                          </p>
                        ),
                        value: item.id,
                      })),
                    ]}
                    disabled={allStore}
                  />
                  <div className="mt-3" style={{ fontSize: "14px" }}>
                    {" "}
                    <Field
                      name="allStore"
                      isShowConfirmPopup={true}
                      confirmContent={
                        "Are you sure to apply this configuration to all stores ?"
                      }
                      component={renderSwitchAnt}
                      suffix={<span>Apply for all stores</span>}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane
                  key="1"
                  tab={
                    <label
                      className="font-weight-bold text-dark mb-0"
                      style={{ fontSize: "16px" }}
                    >
                      Gift message configure
                    </label>
                  }
                />
              </Tabs>
            </div>
            <p style={{ fontSize: "14px", fontStyle: "italic", color: "#f00" }}>
              <strong>* Notice:</strong> Only apply to orders with a gift
              message synced along with orders and configured stores.
            </p>
            <div className="form-group" style={{ fontSize: "14px" }}>
              <div className="d-flex flex-row justify-content-between">
                <div>
                  <label className="col-form-label">
                    Choose background color
                  </label>
                  <Field name="bgHex" component={FieldColorPicker} />
                </div>
                <div className="ml-4">
                  <label className="col-form-label">Choose text color</label>
                  <Field name="colorHex" component={FieldColorPicker} />
                </div>
              </div>
            </div>
            <div className="form-group mb-1">
              <label className="col-form-label mr-3">
                Logo Image
              </label>
              <Field
                name="imgLogo"
                component={renderImageAntS3}
                typeUpload="gift_message_template"
                showUploadList={false}
                cusId={profile.id}
                customPrefix={"imgLogo"}
              />
            </div>
            <div className="form-group">
              <label className="col-form-label">
                Message   (
                <span style={{ color: "#f00", fontWeight: "bold" }}>
                  {message?.length || 0}
                </span>{" "}
                / 350 character)
              </label>
              <Field name="message" component={renderTextarea} rows={4} />
            </div>
            <div className="form-group">
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane
                  key="1"
                  tab={
                    <label
                      className="font-weight-bold text-dark mb-0"
                      style={{ fontSize: "16px" }}
                    >
                      Set period for promotion program
                    </label>
                  }
                />
              </Tabs>
              <p
                style={{ fontSize: "14px", fontStyle: "italic", color: "#f00" }}
              >
                <strong>* Notice:</strong> Apply to all orders within the
                selected period, in case orders come with gift message that will
                be printed onto this promotion as well.
              </p>
              <div className="form-group">
                <div className="d-flex flex-row">
                  <Field
                    name="date_range"
                    component={FieldRangeDateGiftMessage}
                    labelStart={"Start Date"}
                    labelEnd={"End Date"}
                    className="w-700"
                    style={{ backgroundColor: "red" }}
                    dateTimeFormat="YYYY/MM/DD hh:mm A"
                    showTime={true}
                    classContainer="col-6 mb-2"
                    startPlaceholder="From date"
                    endPlaceholder="To date"
                    classNameContainer="w-100"
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Field name="approve" component={renderSwitchAnt} />
                <label
                  htmlFor="mockup_mark_default"
                  className="ml-3 col-form-label"
                >
                  Active this configure
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center d-flex justify-content-end">
          <GMButton
            disabled={invalid}
          >
            Save
          </GMButton>
        </div>
      </form>
    </div>
  );
};

FormCreateGiftMessage.propTypes = {};

FormCreateGiftMessage = reduxForm({
  form: "FormCreateGiftMessage",
  enableReinitialize: true,
  validate,
})(FormCreateGiftMessage);
const selector = formValueSelector("FormCreateGiftMessage");
FormCreateGiftMessage = connect(
  (state, props) => {
    return {
      textSize: selector(state, "textSize"),
      colorHex: selector(state, "colorHex"),
      bgHex: selector(state, "bgHex"),
      imgLogo: selector(state, "imgLogo"),
      message: selector(state, "message"),
      allStore: selector(state, "allStore"),
      timeEnd: selector(state, "timeEnd"),
      timeStart: selector(state, "timeStart"),
      date_range: selector(state, "date_range") || null,
    };
  },
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change("FormCreateGiftMessage", field, value));
    },
  })
)(FormCreateGiftMessage);
export default FormCreateGiftMessage;
