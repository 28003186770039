export const validate = (values) => {
  const errors = {};
  if (
    values?.phone?.trim() &&
    values?.phone?.trim().replace(/^(\+?(\d){2,3})?[0-9 -]{6,16}$/g, '')
      .length > 0
  ) {
    errors.phone = 'Invalid phone number format.';
  }
  if (!values.firstName || values.firstName.replace(/\s+/g, '').length === 0) {
    errors.firstName = 'Please enter first name.';
  }
  if (!values.lastName || values.lastName.replace(/\s+/g, '').length === 0) {
    errors.lastName = 'Please enter last name.';
  }
  if (!values.address || values.address.replace(/\s+/g, '').length === 0) {
    errors.address = 'Please enter address 1.';
  }
  if (!values.city || values.city.replace(/\s+/g, '').length === 0) {
    errors.city = 'Please enter city.';
  }
  if (!values.province || values.province.replace(/\s+/g, '').length === 0) {
    errors.province = 'Please enter state/province.';
  }
  if (!values.country || (values.country && (values.country + '').replace(/\s+/g, '')?.length === 0)) {
    errors.country = 'Please enter country.';
  }
  if (
    !values.postalCode ||
    values.postalCode.replace(/\s+/g, '').length === 0
  ) {
    errors.postalCode = 'Please enter zip code/postal code.';
  }
  return errors;
};