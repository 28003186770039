import { actionType as TYPE } from "./actions";
import { notification } from "antd";

const initialState = {
  createOrder: {
    loading: false,
  },
  checkIfUploadingShippingLabelIsRequired: {
    loading: false,
    data: null,
  },
};

function CreateOrderLabelReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.CREATE_LABEL_ORDER.REQUEST:
      return {
        ...state,
        createOrder: {
          ...initialState.createOrder,
          loading: true,
        },
      };
    case TYPE.CREATE_LABEL_ORDER.SUCCESS:
      notification.success({
        message: action?.clientMsg,
        duration: 5,
      });
      return {
        ...state,
        createOrder: {
          ...initialState.createOrder,
          loading: false,
        },
      };
    case TYPE.CREATE_LABEL_ORDER.ERROR:
      notification.warn({
        message:
          action?.error?.[0]?.["clientMsg"] || "Create label order failed.",
            duration: 5,
      });
      return {
        ...state,
        createOrder: {
          ...initialState.createOrder,
          loading: false,
        },
      };
    case TYPE.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.REQUEST:
      return {
        ...state,
        checkIfUploadingShippingLabelIsRequired: {
          ...initialState.checkIfUploadingShippingLabelIsRequired,
          loading: true,
        },
      };
    case TYPE.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.SUCCESS:
      return {
        ...state,
        checkIfUploadingShippingLabelIsRequired: {
          ...initialState.checkIfUploadingShippingLabelIsRequired,
          loading: false,
          data: action?.data?.isUploadingShippingLabelRequired || false,
        },
      };
    case TYPE.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.ERROR:
      return {
        ...state,
        checkIfUploadingShippingLabelIsRequired: {
          ...initialState.checkIfUploadingShippingLabelIsRequired,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default CreateOrderLabelReducer;