import { BASE_BLACK, BASE_WHITE, GRAY_200, GRAY_300, GRAY_600, PRIMARY_500 } from "components/colors";
import styled from "styled-components";

export const AdsOverlay = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2147483631;
  background: ${BASE_BLACK};
  opacity: 0.5;
  font-size: 0;
`;

export const AdsWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2147483632;
  font-size: 0;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: auto;
  max-height: 100vh;
  .inner-wrapper {
    /* width: 100%; */
    /* height: 100%; */
    /* max-width: 800px; */
    /* max-height: 500px; */
    position: relative;
    padding: 16px;
    object-fit: contain;
    box-sizing: border-box;
    /* @media screen and (min-device-aspect-ratio: 800/500) {
      max-height: calc(100vh - 32px);
      position: relative;
      aspect-ratio: 800/500;
      width: auto;
    } */
  }
  .close-btn {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &::after {
      display: block;
      content: ' ';
      right: 0;
      top: 0;
      border-radius: 100px;
      border: 1px solid ${GRAY_200};
      background: ${BASE_WHITE};
      opacity: 1;
      z-index: 2;
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 100px;
    }
    &:hover::after {
      opacity: 0.8;
    }
    svg {
      stroke: ${GRAY_600};
      z-index: 3;
      width: 20px;
      height: 20px;
    }
  }
  .gm-nav {
    &-prev, &-next {
      position: absolute;
      top: 50%;
      margin-top: -16px;
      cursor: pointer;
      z-index: 2;
      div {
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 100px;
        background: ${BASE_WHITE};
        border: 1px solid ${GRAY_200};
        justify-content: center;
        align-items: center;
        &:hover {
          opacity: 0.8;
        }
      }
      svg {
        stroke: ${BASE_BLACK};
        width: 20px;
        height: 20px;
      }
    }
    &-prev {
      left: 16px;
      right: auto;
    }
    &-next {
      right: 16px;
      left: auto;
    }
  }
  .alt-confirm {
    position: absolute;
    bottom: 40px;
    left: 40px;
    .ant-checkbox-wrapper {
      line-height: 1;
      display: flex;
      align-items: center;
    }
    span {
      color: ${BASE_WHITE};
      &.ant-checkbox {
        margin-bottom: -1px;
      }
    }
  }
  .slick-list {
    border-radius: 12px;
    overflow: hidden;
  }
  .slick-dots {
    bottom: 24px;
    li {
      margin: 0;
      button:before {
        color: ${GRAY_300};
        opacity: 1;
      }
      &.slick-active button:before {
        color: ${PRIMARY_500};
        opacity: 1;
      }
    }
  }
  .inner-wrapper {
    width: 100%;
    /* height: 100%; */
    @media screen and (orientation: landscape) {
      max-height: calc(100vh - 32px);
      position: relative;
      aspect-ratio: 800/500;
      width: auto;
      width: 832px;
      height: 532px;
    }
  }
  .slick-slide {
    .link-overlay {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 0;
    }
    .vid-banner {
      position: relative;
    }
    .vid-banner, video, img {
      /* aspect-ratio: 1420/2000; */
      max-height: auto;
      width: calc(100vw - 32px);
      /* height: calc(100vh - 32px); */
      &.mb {
        display: block;
      }
      &.ds {
        display: none;
      }
      @media screen and (orientation: landscape) {
        aspect-ratio: 800/500;
        width: (100vw - 32px);
        max-height: (100vh - 32px);
        max-width: 800px;
        &.mb {
          display: none;
        }
        &.ds {
          display: block;
        }
      }
    }
  }
  .skeleton {
    min-width: 343px;
    min-height: 483px;

    position: relative;
    @media screen and (orientation: landscape) {
      min-width: 800px;
      min-height: 500px;
    }
    img.loader {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      display: none;
      min-width: 800px;
      min-height: 500px;
      &.mobile {
        display: block;
        min-width: 343px;
        min-height: 483px;
      }
      @media screen and (orientation: landscape) {
        display: block;
        &.mobile {
          display: none;
        }
      }
    }
  }
`;
