import { isValidUrl } from "utils/helper";

const validate = (values) => {
  const errors = {};
  if (!values.url) {
    errors.url = "This field cannot be left blank";
  } else if (!isValidUrl(values.url)) {
    errors.url = "The format of url is invalid.";
  }
  if (!values.showName || values.showName.replace(/\s+/g, "").length === 0) {
    errors.showName = "This field cannot be left blank";
  }
  if (!values.storeApiKey || values.storeApiKey.replace(/\s+/g, "").length === 0) {
    errors.storeApiKey = "This field cannot be left blank";
  }
  if (!values.storeSecretKey || values.storeSecretKey.replace(/\s+/g, "").length === 0) {
    errors.storeSecretKey = "This field cannot be left blank";
  }
  return errors;
};
export default validate;
