export const BREAKPOINTS = {
    ssx: '320px',
    sx: '480px',
    sm: '576px',
    md: '600px',
    lg: '1136px',
    xl: '1200px',
    xxl: '1600px',
    992: '992px',
    1024: '1024px',
    1280: '1280px',
    1360: '1360px',
    1800: '1800px',
};

export const STRING_DATE_FORMAT_1 = 'YYYY/MM/DD';