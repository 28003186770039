import React from 'react';
import { compareTime } from 'utils/helper';
import { Wrapper } from './styled';
import { ReactComponent as EditIcon } from 'images/icons/edit-01.svg';
import { GMBadge } from 'components/Badge';
import {
  ERROR_100,
  ERROR_50,
  ERROR_700,
  SUCCESS_50,
  SUCCESS_600,
} from 'components/colors';

function RenderShippingInfo({
  order = {},
  _this = {},
  lockedOrder,
  reasonOnHold,
}) {
  return (
    <Wrapper>
      <h5 className="mb-2 font-weight-bold d-flex justify-content-between ">
        <div>
          <span className="title">Shipping Information</span>
          <div>
            {order.verifiedAddress === 1 ? (
              <GMBadge
                border={SUCCESS_600}
                color={SUCCESS_600}
                noDot={true}
                backgroundColor={SUCCESS_50}
                className="badge mt-2"
              >
                Address verified
              </GMBadge>
            ) : (
              <div>
                {' '}
                <GMBadge
                  border={ERROR_100}
                  color={ERROR_700}
                  noDot={true}
                  backgroundColor={ERROR_50}
                  className="badge mt-2"
                >
                  Address can not be verified
                </GMBadge>
                <div
                  className="mark-address mt-3 cursor-pointer"
                  onClick={() =>
                    _this.setState({ showModal: 'mark_valid_address' })
                  }
                >
                  Mark as valid
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          {lockedOrder === 0 ? (
            <span
              className="cursor-pointer text-info font-size-14 button-svg"
              onClick={() => _this.setState({ showModal: 'edit_address' })}
            >
              <EditIcon />
            </span>
          ) : (
            <span className="text-muted font-size-14 button-svg disable">
              <EditIcon />
            </span>
          )}
        </div>
      </h5>
      {compareTime(order.updatedAt, 36 * 24 * 3600) &&
      parseInt(order.platformId) === 3 &&
      (parseInt(order.status) === 2 || parseInt(order.status) === 3) ? (
        <div>Shipping info has been removed after 30 days</div>
      ) : (
        <div>
          <div className="row justify-content-between infor">
            <div className="title-o">Full name</div>
            <div className="content-o">{order.cusName || 'N/A'}</div>
          </div>{' '}
          <div className="row justify-content-between infor">
            <div className="title-o">Email</div>
            <div className="content-o">{order.cusEmail || 'N/A'}</div>
          </div>{' '}
          <div className="row justify-content-between infor">
            <div className="title-o">Phone number</div>
            <div className="content-o">{order.cusPhone || 'N/A'}</div>
          </div>{' '}
          <div className="row justify-content-between infor">
            <div className="title-o">Address 1</div>
            <div className="content-o">{order.cusAddress || 'N/A'}</div>
          </div>
          <div className="row justify-content-between infor">
            <div className="title-o">Address 2</div>
            <div className="content-o">{order.cusAddress2 || 'N/A'}</div>
          </div>{' '}
          <div className="row justify-content-between infor">
            <div className="title-o">City</div>
            <div className="content-o">{order.cusCity || 'N/A'}</div>
          </div>{' '}
          <div className="row justify-content-between infor">
            <div className="title-o">State/Province</div>
            <div className="content-o">{order.cusProvince || 'N/A'}</div>
          </div>
          <div className="row justify-content-between infor">
            <div className="title-o">Zip code/Postal code</div>
            <div className="content-o">{order.cusPostalCode || 'N/A'}</div>
          </div>{' '}
          <div className="row justify-content-between infor">
            <div className="title-o">Country</div>
            <div className="content-o">
              {order.country?.countryName || 'N/A'}
            </div>
          </div>
          <div className="row justify-content-between infor">
            <div className="title-o">IOSS number</div>
            <div className="content-o">
              {order?.orderExtra?.iossNumber || '0'}
            </div>
          </div>
          <div className="row justify-content-between infor">
            <div className="title-o">Order value (€)</div>
            <div className="content-o">
              {order?.orderExtra?.iossNumberTotal || '0'}
            </div>
          </div>
          <div className="row justify-content-between infor">
            <div className="title-o">Tax number</div>
            <div className="content-o">
              {order?.orderExtra?.buyerTaxNumber || '0'}
            </div>
          </div>
          <div className="row justify-content-between infor">
            <div className="title-o">Order tax value ($)</div>
            <div className="content-o">
              {order?.orderExtra?.buyerTaxAmount || '0'}
            </div>
          </div>
          <div className="row justify-content-between infor">
            <div className="title-o">Shipping service</div>
            <div className="content-o">{order?.shippingLabel} shipping</div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}
export default React.memo(RenderShippingInfo);
