import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Pagination, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getList, deleteGiftMessage } from './actions';
import queryString from 'query-string';
import { ReactComponent as PlusCircle } from 'images/icons/plus.svg';
import { getOptionLabel, options_gift_message } from 'utils/options';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import getMomentInstance from 'utils/moment';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import Filter from 'components/Filter';
import { debounce } from 'lodash';
import { MoreActionStore } from 'scenes/Campaigns/scenes/List/styled';
import { ContextMenuWrapper } from 'scenes/BillingPaymentAccount/v2/styled';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import { ConfirmModalInnerWrapper } from 'components/Modal/ConfirmDialog/styled';
import { ReactComponent as IconInfo } from 'images/icons/info-circle.svg';
import ConfirmDialog from 'components/Modal/ConfirmDialog';

class List extends Component {
  constructor(props) {
    super(props);
    const { createdAtFrom, createdAtTo, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      record2Remove: null,
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        ...query_params,
        date_range: [
          createdAtFrom
            ? getMomentInstance(createdAtFrom, 0, false, true)
            : null,
          createdAtTo ? getMomentInstance(createdAtFrom, 0, false, true) : null,
        ],
      },
    };
  }

  componentDidMount() {
    this.fetchData(this.state.initial_filter_values);
  }

  fetchData = ({ date_range = null, page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (date_range) {
      params.createdAtFrom =
        date_range[0] && date_range[0].format('YYYY-MM-DD');
      params.createdAtTo = date_range[1] && date_range[1].format('YYYY-MM-DD');
    }

    window.scroll(0, 0);
    this.props.getList(params);
  };

  handleSearchGiftMessage = (values) => {
    const params = {};
    if (values.keyword) {
      params['name'] = values.keyword;
    }
    this.fetchData(params);
  };
  onCreateGiftMessageButton = () => {
    this.props.history.push('/settings/gift-message/create');
  };
  deleteGiftMessage = (id) => {
    this.props.deleteGiftMessage(this.state.record2Remove?.id);
    this.setState({ record2Remove: null });
  };

  render() {
    const { list, props, _delete, pageName } = this.props;
    const columns = [
      {
        title: <b>#</b>,
        dataIndex: 'id',
        align: 'center',
        key: 'id',
        render: (text) => (
          <Link to={`/settings/gift-message/${text}/edit`} className="mr-1">
            #{text}
          </Link>
        ),
      },
      {
        title: <b>Store name</b>,
        dataIndex: 'storeName',
        align: 'center',
        key: 'storeName',
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>Images</b>,
        dataIndex: 'imgLogo',
        align: 'center',
        key: 'imgLogo',
        render: (record) => {
          return record ? (
            <img width="50" src={record} alt="" />
          ) : (
            <FontAwesomeIcon icon={faImage} style={{ fontSize: 50 }} />
          );
        },
      },
      {
        title: <b>Approved</b>,
        dataIndex: 'approve',
        align: 'center',
        key: 'approve',
        render: (value) => getOptionLabel(options_gift_message, value),
      },
      {
        title: <b>Time duration</b>,
        dataIndex: 'timeEnd',
        align: 'center',
        key: 'timeEnd',
        render: (value, record) => {
          return (
            getMomentInstance(null, record.timeStart || 0, false, true) +
            '-' +
            getMomentInstance(null, record.timeEnd || 0, false, true)
          );
        },
      },
      {
        title: <b>Time created</b>,
        dataIndex: 'timeStart',
        align: 'center',
        key: 'timeStart',
        render: (value) => getMomentInstance(null, value, true, false),
      },
      {
        title: <b>Action</b>,
        align: 'center',
        render: (record) => {
          return (
            <MoreActionStore>
              <Dropdown
                placement="topLeft"
                overlay={
                  <ContextMenuWrapper className="p-3">
                    <Link
                      to={`/settings/gift-message/${record.id}/edit`}
                      className="cursor-pointer"
                    >
                      Edit
                    </Link>
                    <div className="delete cursor-pointer">
                      <div
                        onClick={() => this.setState({ record2Remove: record })}
                      >
                        Delete
                      </div>
                    </div>
                  </ContextMenuWrapper>
                }
                trigger={['click']}
              >
                <MoreAction className="context-menu" />
              </Dropdown>
            </MoreActionStore>
          );
        },
      },
    ];
    const debounceSubmit = debounce(
      (values) =>
        this.fetchData({ name: (values.search || '').trim(), page: 1 }),
      700
    );
    return (
      <Wrapper
        pageName={pageName}
        ctas={
          <GMButton
            className="cta-newgift"
            size="lg"
            onClick={this.onCreateGiftMessageButton}
          >
            <PlusCircle />
            <span>Create Gift Message</span>
          </GMButton>
        }
        filter={
          <Filter
            placeholder="Filter by store name"
            className="filter-form"
            onSubmit={debounceSubmit}
          />
        }
      >
        <div className="mt-3">
          <Table
            rowKey="id"
            columns={columns}
            dataSource={list.data}
            loading={list.loading || _delete.loading}
            scroll={{ x: 1000 }}
            pagination={
              !this.props.is_mobile
                ? {
                    showTotal: (total) => `Total ${total} items`,
                    total: list.total,
                    pageSize: parseInt(
                      list.params.perPage > list.params.total
                        ? list.params.total
                        : list.params.perPage
                    ),
                    current: list.params.page,
                    showSizeChanger: true,
                    onChange: (page, perPage) =>
                      this.fetchData({ ...list.params, page, perPage }),
                  }
                : false
            }
          />
          {this.props.is_mobile && (
            <div className="d-flex justify-content-end align-items-center py-3 px-3">
              <div>
                <Pagination
                  size="small"
                  showSizeChanger
                  className="pagination"
                  total={list.total}
                  defaultCurrent={list?.params?.page || 1}
                  pageSize={list?.params?.perPage || 0}
                  onChange={(page, perPage) =>
                    this.fetchData({
                      ...list.params,
                      page:
                        page === Number.NEGATIVE_INFINITY
                          ? 1
                          : page === 0
                          ? 1
                          : page,
                      perPage,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
        {this.state.record2Remove !== null ? (
          <ConfirmDialog
            ctas={
              <>
                <GMButton
                  type="button"
                  color="SecondaryGray"
                  disabled={_delete.loading}
                  onClick={() => this.setState({ record2Remove: null })}
                >
                  Cancel
                </GMButton>

                <GMButton
                  type="button"
                  color="PrimaryError"
                  disabled={_delete.loading}
                  onClick={() =>
                    this.deleteGiftMessage(this.state.record2Remove?.id)
                  }
                >
                  Delete
                </GMButton>
              </>
            }
            {...props}
          >
            <ConfirmModalInnerWrapper>
              <div className="icon">
                <div className="wrapper">
                  <IconInfo />
                </div>
              </div>
              <div className="content">
                <h4 className="header">Remove this user?</h4>
                <div className="msg">
                  This user will be no longer have access to your Gearment
                  account.
                </div>
              </div>
            </ConfirmModalInnerWrapper>
          </ConfirmDialog>
        ) : null}
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    list: state.gift_message.list.list,
    _delete: state.gift_message.list.delete,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
    deleteGiftMessage: (id) => {
      dispatch(deleteGiftMessage(id));
    },
  })
)(List);
