import { GRAY_100, GRAY_600, PRIMARY_500 } from 'components/colors';
import { BREAKPOINTS } from 'components/const';
import styled from 'styled-components';

export default styled.div`
  .ant-radio {
    display: none;
  }
  .wallet-balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;

    width: 672px;
    height: 60px;
    max-width: 100%;

    /* Gray/100 */

    background: #f2f4f7;
    border-radius: 12px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    max-width: 100%;
  }
  .payment-method {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 370px;
    height: 52px;
    background: ${GRAY_100};
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    border: 1px solid transparent;
    float: left;
    margin-top: 3px;
    &:last-child {
      margin-right: 0;
    }
    &.checked {
      border: 1px solid #169dd8;
    }
    @media (min-width: ${BREAKPOINTS.lg}) {
      width: 161.5px;
      flex-direction: row;
      margin-top: 0px;
    }
  }
  .payment-method-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    max-width: 100%;
  }
  .payment-input {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    .name-field {
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
  .payment-input-amount {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    .name-field {
      margin-bottom: 16px;
    }
  }
  .amount-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;

    /* width: 76px; */
    height: 22px;

    /* Gray/100 */

    background: #f2f4f7;
    mix-blend-mode: multiply;
    border-radius: 16px;
    font-size: 12px;
  }
  .next-button {
    width: 477px;
    max-width: 100%;
    margin-top: 12px;
  }
  .upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    width: 477px;
    max-width: 100%;
    max-height: 100vh;
    /* Base/White */

    background: #ffffff;
    /* Gray/200 */

    border: 1px dashed #eaecf0;
    border-radius: 12px;
    margin-top: 36px;
    margin-bottom: 24px;
  }
  .upload-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    width: 477px;
    max-width: 100%;
    max-height: 100vh;
    /* Base/White */

    background: #ffffff;
    /* Gray/200 */

    /* border: 1px dashed #eaecf0; */
    border-radius: 12px;
    margin-top: 36px;
    margin-bottom: 24px;
  }
  .upload-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    width: 477px;
    max-width: 100%;
    background: #ffffff;
    border-radius: 12px;
    margin-top: 36px;
    margin-bottom: 24px;
  }
  .text-upload {
    font-size: 14px;
  }
  .click-to {
    color: ${PRIMARY_500};
  }
  .input#3 {
    box-sizing: border-box;

    width: 20px;
    height: 20px;

    /* Base/White */

    background: #ffffff;
    /* Gray/300 */

    border: 1px solid #d0d5dd;
    border-radius: 10px;
  }
  .policy {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 16px;
    color: #27272a;

    /* identical to box height, or 143% */

    /* Dialog/Title/Text-Default */

    .term {
      color: ${PRIMARY_500};
    }
  }
  .preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;
    width: 477px;
    max-width: 100%;
    background: #f2f4f7;
    border-radius: 8px;
    .item {
      display: flex;
      justify-content: space-between;
      .value {
        color: #27272a;
      }
    }
    .total {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */

      text-align: right;

      /* Gray/900 */

      color: #101828;
    }
  }
`;
