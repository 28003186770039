import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Modal, Pagination } from "antd";
import { getList, rollbackRemovedOrderItem } from "./actions";
import queryString from "query-string";
import noPhoto from "../../../../images/noPhoto.png";
import RenderListActions from "utils/Form/RenderListActions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      selectedRowKeys: [],
      initial_filter_values: {
        sortBy: "id",
        sortType: "asc",
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.orders) !== JSON.stringify(this.props.orders)
    ) {
      const newSelectedRowKeys = this.state.selectedRowKeys.filter((id) =>
        (this.props?.items?.data || []).find((item) => parseInt(item.id) === parseInt(id))
      );
      this.setState({ selectedRowKeys: newSelectedRowKeys });
    }
  }
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getList(params);
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  rollbackItem = (ids) => {
    const { rollbackRemovedOrderItem, items } = this.props;
    confirm({
      title: "Do you want to rollback these order items?",
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        rollbackRemovedOrderItem(
          {
            orderItems: ids,
          },
          items.params
        );
      },
      onCancel() {},
    });
  };
  render() {
    const { items, rollbackLoading, pageName } = this.props;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "rollback",
          text: "Rollback",
          onSelect: () => {
            this.rollbackItem(selectedRowKeys);
          },
        },
      ],
    };
    const columns = [
      {
        title:
          this.state.selectedRowKeys.length > 0 ? (
            <RenderListActions
              title={"items"}
              selectedRowKeys={this.state.selectedRowKeys}
              rowSelection={rowSelection}
            />
          ) : null,
        children: [
          {
            title: <b>ITEM NAME</b>,
            dataIndex: "name",
            align: "center",
            key: "name",
            render: (text) => <span>{text}</span>,
          },
          {
            title: <b>PRODUCT TITLE</b>,
            align: "center",
            dataIndex: "titleOnlineId",
            width: 300,
            key: "titleOnlineId",
          },
          {
            title: <b>ORDER NAME</b>,
            align: "center",
            dataIndex: "orderName",
            key: "orderName",
            render: (text, record) => (
              <Link to={`/orders/${record.orderId}`}>{`${text}`}</Link>
            ),
          },

          {
            title: <b>IMAGE</b>,
            dataIndex: "exProduct",
            align: "center",
            key: "exProduct",
            render: (text, record) =>
              text ? (
                <img
                  width="50"
                  src={text?.frontImg}
                  alt=""
                  style={{
                    backgroundColor: `#${record?.color2?.value || "#25282B"}`,
                  }}
                  className={"border-img"}
                />
              ) : (
                <img width="50" src={noPhoto} alt="" className={"border-img"} />
              ),
          },
          {
            title: <b>DESIGN FRONT</b>,
            dataIndex: "frontDesignUrl",
            align: "center",
            key: "frontDesignUrl",
            render: (text) =>
              text ? (
                <img width="50" src={text} alt="" className={"border-img"} />
              ) : (
                <img width="50" src={noPhoto} alt="" className={"border-img"} />
              ),
          },
          {
            title: <b>DESIGN BACK</b>,
            dataIndex: "backDesignUrl",
            align: "center",
            key: "backDesignUrl",
            render: (text) =>
              text ? (
                <img width="50" src={text} alt="" className={"border-img"} />
              ) : (
                <img width="50" src={noPhoto} alt="" className={"border-img"} />
              ),
          },
          {
            title: <b>COLOR</b>,
            dataIndex: "content",
            align: "center",
            key: "content",
            render: (text) => text?.name_color || "",
          },
          {
            title: <b>SIZE</b>,
            dataIndex: "content",
            align: "center",
            render: (text) => text?.name_size || "",
          },
          {
            title: <b>QUANTITY</b>,
            dataIndex: "quantity",
            align: "center",
            key: "quantity",
          },
        ],
      },
    ];
    return (
      <>
        <div className="Rectangle-848 px-3 py-1">
          <div className="Orders p-2">{pageName}</div>
        </div>
        <div className="m-3">
          <div className="custom-header-sticky">
            <Table
              rowKey="id"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={items.data}
              loading={items.loading || rollbackLoading}
              scroll={{ x: 1000 }}
              sticky
              pagination={!this.props.is_mobile ? {
                showTotal: (total) => `Total ${total} items`,
                total: items.total,
                pageSize: parseInt(
                  items.params.perPage > items.total
                    ? items.total
                    : items.params.perPage
                ),
                current: items.params.page,
                showSizeChanger: true,
                onChange: (page, perPage) =>
                  this.fetchData({ ...items.params, page, perPage }),
              }: false}
            />
          </div>
          {this.props.is_mobile && <div className="d-flex justify-content-end align-items-center py-3 px-3">
            <div>
              <Pagination
                size="small"
                showSizeChanger
                className="pagination"
                total={items.total}
                defaultCurrent={items?.params?.page || 1}
                pageSize={items?.params?.perPage || 0}
                onChange={(page, perPage) =>
                  this.fetchData({ ...items.params, page: page === Number.NEGATIVE_INFINITY ? 1 : (page === 0 ? 1 : page), perPage })
                }
              />
            </div>
          </div>}
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    items: state.order.items_removed.items,
    rollbackLoading: state.order.items_removed.rollback.loading,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
    rollbackRemovedOrderItem: (data, params) => {
      dispatch(rollbackRemovedOrderItem(data, params));
    },
  })
)(List);