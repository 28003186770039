import { actionType as TYPE } from './actions';
import { notification } from 'antd';

const initialState = {
  loading: false,
  accounts: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  updateAccount: {
    loading: false,
  },
  createAccount: {
    loading: false,
  },
  detailAccount: {
    loading: false,
    data: {},
  },
  listReport: {
    loading: false,
    params: {},
    data: [],
  },
  exportOrders: {
    loading: false,
    params: {},
  },
  detailReport: {
    data: [],
    params: {},
    loading: false,
  },
  exportReportAccount: {
    loading: false,
    params: {},
  },
  exportDetailReport: {
    loading: false,
    params: {},
  },
  reportOrderDashboard: {
    data: [],
    loading: false,
    params: {},
  },
  deleteAccount: {
    loading: false,
    data: {},
  },
  deleteInvitation: {
    loading: false,
  },
  listInvatitionAccount: {
    data: {},
    loading: false,
    params: {},
    total: 0,
  },
  //Resend invitation
  resendInvitation: {
    loading: false,
    data: {},
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST_ACCOUNT.REQUEST:
      return {
        ...state,
        accounts: {
          ...initialState.accounts,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_ACCOUNT.SUCCESS:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: false,
          data: action?.data?.customers || [],
          total:
            action?.data?.pagination?.total ||
            (action?.data?.customers || []).length,
        },
      };
    case TYPE.LIST_ACCOUNT.ERROR:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          loading: false,
        },
      };
    //Order dashboard
    case TYPE.REPORT_DASHBOARD.REQUEST:
      return {
        ...state,
        reportOrderDashboard: {
          ...initialState.reportOrderDashboard,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.REPORT_DASHBOARD.SUCCESS:
      return {
        ...state,
        reportOrderDashboard: {
          ...state.reportOrderDashboard,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.REPORT_DASHBOARD.ERROR:
      return {
        ...state,
        reportOrderDashboard: {
          ...state.reportOrderDashboard,
          loading: false,
        },
      };
    //
    case TYPE.DETAIL_ACCOUNT.REQUEST:
      return {
        ...state,
        detailAccount: {
          ...state.detailAccount,
          loading: true,
        },
      };
    case TYPE.DETAIL_ACCOUNT.SUCCESS:
      return {
        ...state,
        detailAccount: {
          ...state.detailAccount,
          data: action.data && action.data.customer ? action.data.customer : {},
          loading: false,
        },
      };
    case TYPE.DETAIL_ACCOUNT.ERROR:
      return {
        ...state,
        detailAccount: {
          ...state.detailAccount,
          loading: false,
        },
      };
    //List invitaion staff
    case TYPE.LIST_INVITATION_ACCOUNT.REQUEST:
      return {
        ...state,
        listInvatitionAccount: {
          ...state.listInvatitionAccount,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_INVITATION_ACCOUNT.SUCCESS:
      return {
        ...state,
        listInvatitionAccount: {
          ...state.listInvatitionAccount,
          data: action.data && action.data,
          loading: false,
        },
      };
    case TYPE.LIST_INVITATION_ACCOUNT.ERROR:
      notification.error({
        message: action?.error[0].clientMsg,
        duration: 5,
      });
      return {
        ...state,
        listInvatitionAccount: {
          ...state.listInvatitionAccount,
          loading: false,
        },
      };

    //
    //Delete account
    case TYPE.DELETE_ACCOUNT.REQUEST:
      return {
        ...state,
        deleteAccount: {
          ...state.detailAccount,
          loading: true,
        },
      };
    case TYPE.DELETE_ACCOUNT.SUCCESS:
      notification.success({
        message: action.clientMsg,
        duration: 5,
      });
      return {
        ...state,
        deleteAccount: {
          ...state.detailAccount,
          data: action.data && action.data.customer ? action.data.customer : {},
          loading: false,
        },
      };
    case TYPE.DELETE_ACCOUNT.ERROR:
      notification.error({
        message: action?.error[0].clientMsg,
        duration: 5,
      });
      return {
        ...state,
        deleteAccount: {
          ...state.detailAccount,
          loading: false,
        },
      };
    //Remove invitation
    case TYPE.DELETE_INVITATION_ACCOUNT.REQUEST:
      return {
        ...state,
        deleteInvitation: {
          loading: true,
        },
      };
    case TYPE.DELETE_INVITATION_ACCOUNT.SUCCESS:
      notification.success({
        message: action.clientMsg,
        duration: 5,
      });
      return {
        ...state,
        deleteInvitation: {
          loading: false,
        },
      };
    case TYPE.DELETE_INVITATION_ACCOUNT.ERROR:
      notification.error({
        message: action?.error[0].clientMsg,
        duration: 5,
      });
      return {
        ...state,
        deleteInvitation: {
          loading: false,
        },
      };
    //
    case TYPE.UPDATE_ACCOUNT.REQUEST:
      return {
        ...state,
        updateAccount: {
          ...state.updateAccount,
          loading: true,
        },
      };
    case TYPE.UPDATE_ACCOUNT.SUCCESS:
      notification.success({
        message: action.clientMsg || 'Update staff successfully.',
        duration: 5,
      });
      return {
        ...state,
        updateAccount: {
          ...state.updateAccount,
          loading: false,
        },
      };
    case TYPE.UPDATE_ACCOUNT.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Update staff failed.',
        duration: 5,
      });
      return {
        ...state,
        updateAccount: {
          ...state.updateAccount,
          loading: false,
        },
      };
    case TYPE.CREATE_ACCOUNT.REQUEST:
      return {
        ...state,
        createAccount: {
          ...state.createAccount,
          loading: true,
        },
      };
    case TYPE.CREATE_ACCOUNT.SUCCESS:
      notification.success({
        message:
          action && action['clientMsg']
            ? action['clientMsg']
            : 'Please check your email for verification link.',
        duration: 5,
      });
      return {
        ...state,
        createAccount: {
          ...state.createAccount,
          loading: false,
        },
      };
    case TYPE.LIST_REPORT.REQUEST:
      return {
        ...state,
        listReport: {
          ...state.listReport,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_REPORT.SUCCESS:
      return {
        ...state,
        listReport: {
          ...state.listReport,
          data: action.data ? action.data : [],
          loading: false,
        },
      };
    case TYPE.CREATE_ACCOUNT.ERROR:
      notification.warn({
        message:
          action.error && action.error['clientMsg']
            ? action.error['clientMsg']
            : 'Create account failed.',
        duration: 5,
      });
      return {
        ...state,
        createAccount: {
          ...state.createAccount,
          loading: false,
        },
      };
    case TYPE.LIST_REPORT.ERROR:
      return {
        ...state,
        listReport: {
          ...state.listReport,
          loading: false,
        },
      };
    case TYPE.EXPORT_ORDER.REQUEST:
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          params: {},
          loading: true,
        },
      };
    case TYPE.EXPORT_ORDER.SUCCESS:
      notification.success({
        message: 'Export orders successfully.',
        duration: 5,
      });
      if (action.data && action.data.linkToDownloadFile) {
        window.open(action.data.linkToDownloadFile);
      }
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          loading: false,
        },
      };
    case TYPE.EXPORT_ORDER.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Export orders failed.',
        duration: 5,
      });
      return {
        ...state,
        exportOrders: {
          ...state.exportOrders,
          loading: false,
        },
      };

    //Resend invitation
    case TYPE.RESEND_INVITAION.REQUEST:
      return {
        ...state,
        resendInvitation: {
          ...state.resendInvitation,
          loading: true,
        },
      };
    case TYPE.RESEND_INVITAION.SUCCESS:
      notification.success({
        message:
          action && action['clientMsg']
            ? action['clientMsg']
            : '',
        duration: 5,
      });
      return {
        ...state,
        resendInvitation: {
          ...state.resendInvitation,
          loading: false,
          data: action.data,
        },
      };
      case TYPE.RESEND_INVITAION.ERROR:
        notification.warn({
          message:
            action.error[0] && action.error[0]['msg']
              ? action.error[0]['msg']
              : 'Resend invitation failed.',
          duration: 5,
        });
        return {
          ...state,
          resendInvitation: {
            ...state.resendInvitation,
            loading: false,
          },
        };
    case TYPE.DETAIL_REPORT.REQUEST:
      return {
        ...state,
        detailReport: {
          ...state.detailReport,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.DETAIL_REPORT.SUCCESS:
      return {
        ...state,
        detailReport: {
          ...state.detailReport,
          loading: false,
          data:
            action.data && action.data.reportDetail
              ? action.data.reportDetail
              : [],
        },
      };
    case TYPE.DETAIL_REPORT.ERROR:
      return {
        ...state,
        detailReport: {
          ...state.detailReport,
          loading: false,
        },
      };
    case TYPE.EXPORT_REPORT_ACCOUNT.REQUEST:
      return {
        ...state,
        exportReportAccount: {
          ...state.exportReportAccount,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.EXPORT_REPORT_ACCOUNT.SUCCESS:
      notification.success({
        message: 'Export account reports successfully.',
        duration: 5,
      });
      if (action.data && action.data.url) {
        window.open(action.data.url);
      }
      return {
        ...state,
        exportReportAccount: {
          ...state.exportReportAccount,
          loading: false,
        },
      };
    case TYPE.EXPORT_REPORT_ACCOUNT.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Export account reports failed.',
        duration: 5,
      });
      return {
        ...state,
        exportReportAccount: {
          ...state.exportReportAccount,
          loading: false,
        },
      };
    case TYPE.EXPORT_DETAIL_REPORT_ACCOUNT.REQUEST:
      return {
        ...state,
        exportDetailReport: {
          ...state.exportDetailReport,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.EXPORT_DETAIL_REPORT_ACCOUNT.SUCCESS:
      notification.success({
        message: 'Export detail reports successfully.',
        duration: 5,
      });
      if (action.data && action.data.url) {
        window.open(action.data.url);
      }
      return {
        ...state,
        exportDetailReport: {
          ...state.exportDetailReport,
          loading: false,
        },
      };
    case TYPE.EXPORT_DETAIL_REPORT_ACCOUNT.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Export detail reports failed.',
        duration: 5,
      });
      return {
        ...state,
        exportDetailReport: {
          ...state.exportDetailReport,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
