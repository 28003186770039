import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actionType as TYPE, detailConfigBuyTracking } from './actions';
import { getConfigBuyTracking, updateConfigBuyTracking } from 'apis/tracking';
import { push, replace } from 'connected-react-router';

function* getConfigBuyTrackingSaga(action) {
  try {
    const response = yield call(getConfigBuyTracking);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.DETAIL_CONFIG_BUY_TRACKING.SUCCESS, ...response }),
      ]);
    } else {
      yield put({ type: TYPE.DETAIL_CONFIG_BUY_TRACKING.ERROR, response });
    }
  } catch (error) {
    yield all([
      yield put({ type: TYPE.DETAIL_CONFIG_BUY_TRACKING.ERROR, error }),
    ]);
  }
}

function* updateConfigBuyTrackingSaga(action) {
  const { data } = action;
  const dispatchArray = [];
  try {
    const response = yield call(updateConfigBuyTracking, data);
    if (response.code === 'success') {
      dispatchArray.push(put({ type: TYPE.UPDATE_CONFIG_BUY_TRACKING.SUCCESS, ...response }));
    } else {
      dispatchArray.push(put({ type: TYPE.UPDATE_CONFIG_BUY_TRACKING.ERROR, response }));
    }
  } catch (error) {
    dispatchArray.push(put({ type: TYPE.UPDATE_CONFIG_BUY_TRACKING.ERROR, error }));
  }
  dispatchArray.push(put(detailConfigBuyTracking()));
  yield all(dispatchArray);
}

function* watcher() {
  yield takeLatest(
    TYPE.DETAIL_CONFIG_BUY_TRACKING.REQUEST,
    getConfigBuyTrackingSaga
  );
  yield takeLatest(
    TYPE.UPDATE_CONFIG_BUY_TRACKING.REQUEST,
    updateConfigBuyTrackingSaga
  );
}

export default watcher;
