import {
    CREATE_ISSUES_REQUEST,
    CREATE_ISSUES_SUCCESS,
    CREATE_ISSUES_FAIL,

    LIST_ISSUE_CATEGORY_REQUEST,
    LIST_ISSUE_CATEGORY_FAIL,
    LIST_ISSUE_CATEGORY_SUCCESS,

    LIST_ORDER_TO_CREATE_ISSUE_REQUEST,
    LIST_ORDER_TO_CREATE_ISSUE_SUCCESS,
    LIST_ORDER_TO_CREATE_ISSUE_FAIL,
    LIST_ORDER_SET_LOADING,
    UPDATE_ISSUES_FAIL,
    UPDATE_ISSUES_SUCCESS,
    UDPATE_ISSUES_REQUEST
} from './constants'
import { notification } from "antd";
import { ISSUE_DETAIL_REQUEST } from '../DetailIssue/constants';

const initialState = {
    create: {
        loading: false,
        data: [],
        errors: []
    },
    listIssueCategory: {
        loading: false,
        data: []
    },
    listOrderToCreateIssue: {
        loading: false,
        data: [],
        errors: []
    }
}

function createIssueReducer(state = initialState, action) {
    const errors = [];
    const data = [];
    switch (action.type) {
        case CREATE_ISSUES_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true
                }
            }
        case CREATE_ISSUES_SUCCESS:
            if (action.errors && Array.isArray(action.errors)) {
                for (let index = 0; index < action.errors.length; index++) {
                    if (action.errors[index].code === 'create_order_issue_successfully') {
                        data.push(action.errors[index].data)
                        notification.success({ message: action.errors[index].msg, duration: 5 })
                    } else {
                        errors.push(action.errors[index])
                        notification.warn({ message: action.errors[index].msg, duration: 5 })
                    }
                }
            } else {
                notification.success({ message: 'Create issue(s) successfully.', duration: 5 })
            }
            return {
                ...state,
                create: {
                    ...state.create,
                    data: data,
                    errors: errors,
                    loading: false
                }
            }
        case CREATE_ISSUES_FAIL:
            notification.warning({ message: 'Create issue failed.', duration: 5 });
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false
                }
            }
        case UDPATE_ISSUES_REQUEST:
        case ISSUE_DETAIL_REQUEST:
            return {
                ...state,
                update: {
                    loading: true,
                }
            }
        case UPDATE_ISSUES_SUCCESS:
            notification.success({ message: 'Update issue successfully.', duration: 5 });
            return {
                ...state,
                update: {
                    loading: false
                }
            }
        case UPDATE_ISSUES_FAIL:
            notification.warning({ message: 'Update issue failed.', duration: 5 });
            return {
                ...state,
                create: {
                    loading: false
                }
            }
        case LIST_ISSUE_CATEGORY_REQUEST:
            return {
                ...state,
                listIssueCategory: {
                    ...state.listIssueCategory,
                    loading: true
                }

            }
        case LIST_ISSUE_CATEGORY_SUCCESS:
            return {
                ...state,
                listIssueCategory: {
                    ...state.listIssueCategory,
                    loading: false,
                    data: action.data && action.data.listIssueCategory ? action.data.listIssueCategory : []
                }

            }
        case LIST_ISSUE_CATEGORY_FAIL:
            return {
                ...state,
                listIssueCategory: {
                    ...state.listIssueCategory,
                    loading: false
                }

            }

        case LIST_ORDER_TO_CREATE_ISSUE_REQUEST:
            return {
                ...state,
                listOrderToCreateIssue: {
                    ...state.listOrderToCreateIssue,
                    data: [],
                    errors: [],
                    loading: true
                }

            }
        case LIST_ORDER_TO_CREATE_ISSUE_SUCCESS:
            if (action.errors && Array.isArray(action.errors)) {
                for (let index = 0; index < action.errors.length; index++) {
                    if (action.errors[index].code === 'check_order_for_creating_issue_success') {
                        data.push(action.errors[index].data)
                        notification.success({ message: action.errors[index].msg, duration: 5 })
                    } else {
                        errors.push(action.errors[index])
                        notification.warn({ message: action.errors[index].msg, duration: 5 })
                    }
                }
            }
            return {
                ...state,
                listOrderToCreateIssue: {
                    ...state.listOrderToCreateIssue,
                    loading: false,
                    data: data,
                    errors: errors
                }
            }
        case LIST_ORDER_TO_CREATE_ISSUE_FAIL:
            notification.warning({ message: 'Check order for creating issue failed', duration: 5 });
            return {
                ...state,
                listOrderToCreateIssue: {
                    ...state.listOrderToCreateIssue,
                    data: [],
                    errors: [],
                    loading: false
                }
            }
        case LIST_ORDER_SET_LOADING:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: action.payload,
                }
            }
        default:
            return state
    }
}

export default createIssueReducer

