import { BASE_WHITE } from "components/colors";
import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  .topcta .ctas {
    button {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    svg {
      stroke: ${BASE_WHITE};
      width: 20px;
      height: 20px;
      margin-left: 8px;
    }
  }
`;
