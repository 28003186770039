import ListStore from 'scenes/Stores/scenes/List';
import StoreCallback from 'scenes/Stores/scenes/StoreCallback';
import OrderScheduleReport from 'scenes/Stores/scenes/ScheduleReport';
import OrderScheduleReportDetail from '../Stores/scenes/ScheduelReportDetail';
import Redirector from 'scenes/Redirector';
import AuthLogin from 'scenes/Auth/scenes/Login';

export default [
  {
    key: 'stores',
    name: 'Stores',
    component: ListStore,
    path: '/stores',
    icon: null,
    template: 'default',
    isPublic: false,
    permissions: ['createStorePermission'],
  },
  {
    key: 'stores',
    name: 'Stores',
    component: ListStore,
    path: '/stores/callback_shopbase',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'stores',
    name: 'Stores',
    component: ListStore,
    path: '/stores/lianlian-callback',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'stores',
    name: 'Stores',
    component: Redirector,
    path: '/payoneer-callback',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'stores',
    name: 'Stores',
    component: Redirector,
    path: '/lianlian-callback',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'stores',
    name: 'Stores',
    component: ListStore,
    path: '/stores/callback_ebay',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'stores',
    name: 'Stores',
    component: ListStore,
    path: '/stores/callback_amazon',
    icon: null,
    template: 'default',
    isPublic: true,
  },

  //update token
  {
    key: 'etsy_callback_add',
    name: 'Stores',
    component: StoreCallback,
    path: '/stores/etsy_callback_add',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'etsy_callback_token',
    name: 'Stores',
    component: ListStore,
    path: '/stores/etsy_callback_token',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  //
  {
    key: 'callback_shopify.stores_callback',
    name: 'Stores',
    component: StoreCallback,
    path: '/stores/callback_shopify',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'stores',
    name: 'Stores',
    component: ListStore,
    path: '/stores/callback_token_shopify',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'stores',
    name: 'Stores',
    component: ListStore,
    path: '/stores/callback_shopbase_update_scope',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'stores',
    name: 'Stores',
    component: ListStore,
    path: '/stores/etsycallback_add',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'stores',
    name: 'Stores',
    component: ListStore,
    path: '/stores/callback_shopbase_edit',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  // {
  //   key: "orders.logs.sync_order",
  //   name: "Sync order logs",
  //   component: OrderScheduleReport,
  //   path: "/logs/sync-order",
  //   icon: null,
  //   template: "default",
  //   isPublic: true,
  // },
  {
    key: 'orders.logs.sync_order',
    name: 'Sync order log detail',
    component: OrderScheduleReportDetail,
    path: '/logs/sync-orders/:id',
    icon: null,
    template: 'default',
    isPublic: true,
  },
];
