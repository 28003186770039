/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getIcon } from 'scenes/BillingPaymentAccount/v2/billingInfo.column';
import { censorEmail, censorToken } from 'utils/helper';
import money from 'utils/money';
import { Link } from 'react-router-dom';
import { ReactComponent as WarningIcon } from 'images/icons/alert-triangle.svg';
import ModalTopUp from 'components/ModalTopUp';

export default ({ primaryBillingPaymentAccount, data }) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const profile = useSelector((s) => s?.auth?.info?.profile);
  const account =
    primaryBillingPaymentAccount?.billing?.type !== 1
      ? censorEmail(primaryBillingPaymentAccount?.billing?.email)
      : censorToken(primaryBillingPaymentAccount?.billing?.content);
  const billingInfo = primaryBillingPaymentAccount?.billing ? (
    <>
      {getIcon(primaryBillingPaymentAccount?.billing?.type)}
      <div className="info">
        {[1, 4].indexOf(primaryBillingPaymentAccount?.billing?.type) === -1 && (
          <span className="account">{account}</span>
        )}
        {[0, 1, 4].indexOf(primaryBillingPaymentAccount?.billing?.type) === -1 && (
          <span className="balance">{money(profile.balance * 1)}</span>
        )}
      </div>
    </>
  ) : (
    <div className="warning nogap">
      <WarningIcon />
      It appears that you haven't added any billing methods yet. Please{' '}
      <Link to="/billings/payment-method">click here</Link> to add one to
      complete your purchase.
    </div>
  );
  if (!primaryBillingPaymentAccount) {
    return null;
  }
  const openTopupHandler = (evt) => {
    evt.preventDefault();
    setOpenModal(true);
    return false;
  };
  return (
    <>
      {profile?.primaryId === 0 && (
        <div className="block">
          <h4>Payment information</h4>
          <div className="billing-visual">{billingInfo}</div>
          {profile?.level <= 1 && profile.balance * 1 < data?.data?.amount &&
            primaryBillingPaymentAccount?.billing?.type === 3 ? (
            <div className="warning">
              <WarningIcon />
              The balance in your G-Wallet is insufficient to cover this
              payment. Please{' '}
              <a href="" onClick={openTopupHandler}>
                click here
              </a>{' '}
              to add more funds to your account and try again.
            </div>
          ) : null}
        </div>
      )}

      <ModalTopUp
        isOpenModal={isOpenModal}
        closeModal={() => setOpenModal(false)}
      />
    </>
  );
};
