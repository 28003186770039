import { combineReducers } from "redux";
import list from "./scenes/List/reducer";
import detail from "./scenes/Detail/reducer";

const reducer = combineReducers({
  list,
  detail,
});

export default reducer;
