import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import Form from './components/form';
import { update } from '../../../Users/scenes/Profile/actions';
class Index extends Component {
  handleUpdateProfile = ({ birthday, ...values }) => {
    this.props.updateProfile({
      birthday: birthday ? birthday.format('DD/MM/YYYY') : '',
      from: 'update-information',
      ...values,
    });
  };
  componentDidMount() {
    // let state = { ...this.props.history.location.state };
    // let { from = '' } = state;
    // if (!from) {
    //   this.props.history.push('/auth/login');
    // }
    // delete state.from;
    // this.props.history.replace({ ...this.props.history.location, state });
  }
  render() {
    const { loading, customer } = this.props;
    return (
      <div className="bg-white w-100">
        <Spin spinning={loading}>
          <Form
            initialValues={{ name: customer?.name || '' }}
            onSubmit={this.handleUpdateProfile}
          />
        </Spin>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.user.profile.updating,
    customer: state.auth.register.register.data,
  }),
  (dispatch) => ({
    updateProfile: (data) => {
      dispatch(update(data));
    },
  })
)(Index);
