import React from "react";
import { ReactComponent as IconPlus } from 'images/icons/plus.svg';
import { ReactComponent as IconMinus } from 'images/icons/minus.svg';
import { GMTextbox } from "components/InputFields";
import { GMButton } from "components/Button";
import classNames from "classnames";
const renderLink = ({ fields, meta: { error }, ...rest }) => {
  return fields.map((field, index) => {
    return (
      <div className="link-row">
        <GMTextbox
          name={`${field}`}
          placeholder={'Link'}
        />
        {index === 0 ? (
          <GMButton
            type="button"
            className={classNames("link2add", {
              disabled: (fields || []).length >= 10
            })}
            onClick={() => fields.push('')}
          ><IconPlus /></GMButton>
        ) : (
          <GMButton
            type="button"
            className="link2remove"
            onClick={() => fields.remove(index)}
          ><IconMinus /></GMButton>
        )}
      </div>
    )
  });
};

export default React.memo(renderLink);
