/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { GMButton } from 'components/Button';
import { GlobalStylesheet, Wrapper, WrapperLogs } from './styled';
import { useHistory, useLocation } from 'react-router-dom';
import { Collapse, Spin, Steps, Table, notification } from 'antd';
import { getColumnsWithActions, primaryColumns } from './billingInfo.column';
import { ReactComponent as WarningIcon } from 'images/icons/alert-triangle.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadListAccountAction,
  loadPrimaryAccountAction,
  deleteBillingMethodAction,
  setPrimaryAction,
  loadLogsAction,
  getDetailBillingMethodAction,
} from '../actions';

import { listCountry } from 'scenes/Users/scenes/Profile/actions';
import RemoveBillingMethodConfirmModal from './AddNew/RemoveBillingMethodConfirmModal';
import UpdateBillingMethodConfirmModal from './AddNew/UpdateBillingMethodConfirmModal';
import ModalAddBillingMethod from './AddNew/index';
import { useState } from 'react';
import getMomentInstance from 'utils/moment';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import NoBillingInforWarningBlock from './NoBillingInforWarningBlock';

const { Panel } = Collapse;

export default ({ pageName }) => {
  const history = useHistory();
  const loading = useSelector((s) => s.billingPaymentAccount?.loading);
  const dispatch = useDispatch();
  const location = useLocation();
  let callbackResponse = location?.state?.callbackResponse || [];
  if (!Array.isArray(callbackResponse)) {
    callbackResponse = [callbackResponse];
  }

  const primaryBillingPaymentAccount = useSelector(
    (s) => s.billingPaymentAccount?.primary
  );
  const listBillingPaymentAccount = useSelector(
    (s) => s.billingPaymentAccount?.list
  );
  const listLogsChangeBillingPaymentAccount = useSelector(
    (s) => s.billingPaymentAccount?.logs?.logs
  );

  const countries = useSelector((s) => s.user.profile.listCountry);
  const [removeModal, setRemoveModal] = useState(null);
  const [isPrimaryAccount, setIsPrimaryAccount] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalAddNewMethod, setIsOpenModalAddNewMethod] = useState(false);
  const handleModalAddNewMethod = () => {
    setIsOpenModalAddNewMethod(true);
  };
  useEffect(() => {
    dispatch(loadPrimaryAccountAction());
    dispatch(loadListAccountAction());
    dispatch(listCountry());
    dispatch(loadLogsAction());

    if (callbackResponse.length > 0) {
      callbackResponse.forEach((rs) => {
        const message = rs?.clientMsg || rs?.msg;
        if (rs?.code?.indexOf('error') !== -1) {
          notification.error({ message });
        } else if (rs?.code?.indexOf('warning') !== -1) {
          notification.warning({ message });
        }
      });
    }
  }, []);
  const remapList = (listBillingPaymentAccount?.billings || []).map((item) => {
    return { ...item, countries: countries?.data };
  });

  const onSetPrimary = (evt, data) => {
    evt?.preventDefault();
    let dataSumbit = {
      payId: data?.payId,
      billingId: data?.billingId,
      altParams: {
        billing_method: data?.altParams?.billing_method,
        account: data?.altParams?.account,
      },
    };
    if (
      !data?.item?.billingInfor?.firstName ||
      !data?.item?.billingInfor?.lastName ||
      !data?.item?.billingInfor?.city ||
      !data?.item?.billingInfor?.state ||
      !data?.item?.billingInfor?.postalcode ||
      !data?.item?.billingInfor?.countryId ||
      !data?.item?.billingInfor?.streetAddress
    ) {
      setRemoveModal(
        <UpdateBillingMethodConfirmModal
          onOk={() => {
            history.push('/billing/method/new?update=' + (data?.item?.id || ''), {
              isPrimary: true,
            });
            setRemoveModal(null);
            setIsPrimaryAccount(true);
          }}
          onCancel={() => setRemoveModal(null)}
        />
      );
    } else {
      dispatch(setPrimaryAction(dataSumbit));
    }
    return false;
  };
  const onDelete = (evt, data) => {
    evt?.preventDefault();
    evt?.stopPropagation();
    setRemoveModal(
      <RemoveBillingMethodConfirmModal
        onOk={() => {
          dispatch(deleteBillingMethodAction(data));
          setRemoveModal(null);
        }}
        onCancel={() => setRemoveModal(null)}
      />
    );
    return false;
  };
  const onUpdate = (evt, pmItem) => {
    evt?.preventDefault();
    evt?.stopPropagation();
    history.push('/billing/method/new?update=' + pmItem?.id);
    return false;
  };
  return (
    <Spin spinning={loading}>
      <Wrapper
        pageName={pageName}
        ctas={
          <GMButton onClick={handleModalAddNewMethod} size="md">
            <PlusIcon className="plusicon" />
            Add billing method
          </GMButton>
        }
      >
        {!loading && (
          <div className="primary-billingmethod">
            <div className="header">
              <h4 className="header">Primary billing method</h4>

              {!primaryBillingPaymentAccount?.billing &&
                remapList?.length > 0 ? (
                <div className="warning">
                  <WarningIcon />
                  No primary billing methods have been selected yet.
                </div>
              ) : primaryBillingPaymentAccount?.billing ? (
                <p className="description">
                  This is the primary billing method for your Gearment account.
                  It’ll be the default we use to charge you for future
                  transactions.
                </p>
              ) : (
                <div className="warning">
                  <WarningIcon />
                  No billing methods have been added. Please{' '}
                  <a href="" onClick={handleModalAddNewMethod}>
                    click here
                  </a>{' '}
                  (or “Add Billing Method” button) to add a billing method.
                </div>
              )}
            </div>
            <NoBillingInforWarningBlock className="no-billing-info-warning" />
            <div className="table mb32 primary">
              {primaryBillingPaymentAccount?.billing && (
                <Table
                  rowKey="id"
                  columns={primaryColumns}
                  dataSource={[
                    {
                      ...primaryBillingPaymentAccount?.billing,
                      countries: countries?.data,
                    },
                  ]}
                  loading={loading}
                  pagination={false}
                  locale={{
                    emptyText: <span>no data</span>,
                  }}
                />
              )}
            </div>
          </div>
        )}

        {/* list... */}
        {remapList?.length > 0 && (
          <div className="primary-billingmethod">
            <div className="header">
              <h4 className="header">All other billing methods</h4>
            </div>
            <div className="table mb12 list">
              <Table
                rowKey="id"
                columns={getColumnsWithActions({
                  onSetPrimary,
                  onDelete,
                  onUpdate,
                  modalActions: { isOpenModal, setIsOpenModal },
                })}
                dataSource={remapList}
                loading={loading}
                locale={{
                  emptyText: <span>no data</span>,
                }}
                pagination={false}
              />
            </div>
          </div>
        )}
      </Wrapper>
      {removeModal}
      <WrapperLogs>
        {listLogsChangeBillingPaymentAccount?.length > 0 && (
          <div className="primary-billingmethod">
            <div className="table mb12 list">
              <Spin spinning={loading}>
                <Collapse className="clol">
                  <Panel
                    header={<h5 className="font-weight-bold">History</h5>}
                    key="1"
                  >
                    <Steps progressDot direction="vertical">
                      {listLogsChangeBillingPaymentAccount.map((item) => (
                        <Steps.Step
                          key={item.id}
                          title={getMomentInstance(item.logTime, 0, true)}
                          description={
                            <div>
                              <b>{item.customer?.name || 'N/A'}:</b>{' '}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: (item.logName || '').replace(
                                    /(<? *script)/gi,
                                    'illegalscript'
                                  ),
                                }}
                              ></span>
                            </div>
                          }
                        />
                      ))}
                    </Steps>
                  </Panel>
                </Collapse>
              </Spin>
            </div>
          </div>
        )}
      </WrapperLogs>
      <ModalAddBillingMethod
        isOpenModal={isOpenModalAddNewMethod}
        closeModal={() => setIsOpenModalAddNewMethod(false)}
      />
      <GlobalStylesheet />
    </Spin>
  );
};
