import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Spin, Button, Pagination } from "antd";
import { detailReport, exportDetailReport } from "../../actions";
import money from "utils/money";
import FormFilter from "./components/FormFilter";
import queryString from "query-string";
import BackToIcon from "images/chevron-down.svg";
import getMomentInstance from "utils/moment";
import GMHelmet from "components/GMHelmet";
import { postfix } from "utils/options";
class List extends Component {
  constructor(props) {
    super(props);
    const { fromDate, toDate, ...query_params } = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        sortBy: "id",
        sortType: "desc",
        ...query_params,
        date_range: [
          fromDate ? getMomentInstance(null, 0, false, false) : null,
          toDate ? getMomentInstance(null, 0, false, false) : null,
        ],
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ date_range = null, page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (date_range) {
      params.fromDate =
        date_range[0] && date_range[0].format("YYYY-MM-DD");
      params.toDate = date_range[1] && date_range[1].format("YYYY-MM-DD");
    }
    if (!params['fromDate']) delete params['fromDate'];
    if (!params['toDate']) delete params['toDate'];
    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.detailReport(this.props.match.params.id, params);
  };

  handleOnExport = () => {
    const params = {};
    const { date_range = null } = this.props.FormFilterReportDetailData;
    if (date_range) {
      params.fromDate = date_range[0] && date_range[0].format("YYYY-MM-DD");
      params.toDate = date_range[1] && date_range[1].format("YYYY-MM-DD");
    }
    this.props.exportDetailReport(this.props.match.params.id, params);
  };

  render() {
    const {
      detailReportData,
      pageName,
      exportDetailReportLoading,
    } = this.props;
    const columns = [
      {
        title: <b>#STORE ID</b>,
        align: "center",
        dataIndex: "storeId",
        key: "storeId",
        render: (text) => <span>#{text}</span>,
      },
      {
        title: <b>STORE NAME</b>,
        dataIndex: "storeName",
        align: "center",
        key: "storeName",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>ORDERS</b>,
        dataIndex: "orderCount",
        align: "center",
        key: "orderCount",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>AMOUNT</b>,
        dataIndex: "orderRevenue",
        align: "center",
        key: "orderRevenue",
        render: (text) => money(text.toFixed(2)),
      },
    ];

    return (
      <div>
        {this.props.match.params.id ? (
          <GMHelmet
            overrideTitle={`Report staff account #${this.props.match.params.id || ''}` + postfix}
          />
        ) : null}
        <div className="Rectangle-848 d-block d-sm-flex align-items-center">
          <div className="col-12 col-md-4 Orders py-3">{pageName} #{this.props.match.params.id}</div>
          <div className="col-12 col-md-8">
            <FormFilter
              onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
              handleOnExport={this.handleOnExport}
              initialValues={this.state.initial_filter_values}
              handleAdvancedSubmit={this.fetchData}
              detailReportData={detailReportData}
            />
          </div>
        </div>
        <div className="m-3">
          <Button
            className="BackToButton d-flex align-items-center my-4"
            onClick={() => {
              this.props.history.push("/statistics/account-reports");
            }}
          >
            <img src={BackToIcon} alt="back-icon" />
            <p className="m-0 ml-2">Back to Account Report</p>
          </Button>
          <Spin spinning={exportDetailReportLoading}>
            <Table
              rowKey="storeId"
              columns={columns}
              dataSource={detailReportData.data}
              loading={detailReportData.loading}
              scroll={{ x: 1000 }}
              pagination={!this.props.is_mobile ? {
                showTotal: (total) => `Total ${total} items`,
                total: detailReportData.total,
                current: parseInt(detailReportData.params.page),
                pageSize:
                  detailReportData.params.page > detailReportData.total
                    ? detailReportData.total
                    : detailReportData.params.perPage,
                showSizeChanger: false,
                onChange: (page, perPage) =>
                  this.fetchData({ ...detailReportData.params, page, perPage }),
              } : false}
            />
            {this.props.is_mobile && <div className="d-flex justify-content-end align-items-center py-3 px-3">
              <div>
                <Pagination
                  size="small"
                  showSizeChanger
                  className="pagination"
                  total={detailReportData.total}
                  defaultCurrent={detailReportData?.params?.page || 1}
                  pageSize={detailReportData?.params?.perPage || 0}
                  onChange={(page, perPage) =>
                    this.fetchData({ ...detailReportData.params, page: page === Number.NEGATIVE_INFINITY ? 1 : (page === 0 ? 1 : page), perPage })
                  }
                />
              </div>
            </div>}
          </Spin>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    detailReportData: state.setting.account.detailReport,
    exportDetailReportLoading: state.setting.account.exportDetailReport.loading,
    FormFilterReportDetailData: state.form.formFilterReportDetail?.values || {},
  }),
  (dispatch) => ({
    detailReport: (staffId, params) => {
      dispatch(detailReport(staffId, params));
    },
    exportDetailReport: (staffId, params) => {
      dispatch(exportDetailReport(staffId, params));
    },
  })
)(List);
