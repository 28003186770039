import {actionType} from "./actions"

const initialState = {
    getInvoice: {
        order: {},
        config: {},
        loading: false,
    },
}

function InvoiceReducer(state = initialState, action){
    switch(action.type) {
        case actionType.INVOICE.REQUEST:
            return {
                ...state,
                getInvoice: {
                    ...state.getInvoice,
                    loading: true
                }
            }
        case actionType.INVOICE.SUCCESS:
            return {
                ...state,
                getInvoice: {
                    ...state.getInvoice,
                    loading: false,
                    order: action.data && action.data.order ? action.data.order : {},
                    configs: action.data && action.data.configs ? action.data.configs : {}
                },
            }
        case actionType.INVOICE.ERROR:
            return {
                ...state,
                getInvoice: {
                    ...state.getInvoice,
                    loading: false
                }
            }
        default:
            return state
    }
}

export default InvoiceReducer