import React, { Component } from 'react'
import { options_upload_image_status, getOptionLabel, uploading_message } from "utils/options"
import noImage from "images/noImg.png"
import { Popover, Spin } from "antd"
import { Wrapper } from './styled';
class index extends Component {
  render() {
    const { data, uploadImage } = this.props;
    return (
      <Wrapper>
        <ul className="upload-list">
          {data.map((item, index) => {
            const status = <div>{getOptionLabel(options_upload_image_status, item.status)}</div>;
            return (
              <li key={'upload-item_'+ index}>
                <div className="img">
                  {item.imageLink ? <img className='img-fluid' src={item.imageLink} alt="" /> : <img className='img-fluid' src={noImage} alt="" />}
                </div>
                <div className="status">
                  <Spin spinning={item.loading === true} tip={uploading_message}>
                    {status}
                    <div>{(item.error) &&
                      <Popover content={<div className="font-italic">{item.error}</div>} title="Error Detail">
                        <span className="text-primary cursor-pointer">Detail</span>
                      </Popover>}</div>
                    {item.error && <button type="button" className="btn btn-primary" onClick={() => { uploadImage(item, index + 1) }}>Retry</button>}
                  </Spin>
                </div>
              </li>
            );
          })}
        </ul>
      </Wrapper>
    )
  }
}

export default index;
