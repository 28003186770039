/* eslint-disable no-unused-expressions */
import classNames from 'classnames';
import { GMButton } from 'components/Button';
import React, { createRef, useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Wrapper } from './GenerateIssueFromOrderID.styled';

export const GenerateIssueFromOrderID = ({ onSubmit = () => { }, errorElem, cb = () => undefined }) => {
  const tRef = createRef(null);
  const { state } = useLocation();
  const [error, setError] = useState(false);
  useEffect(() => {
    if (state?.listOrderId && tRef.current) {
      tRef.current.value = state?.listOrderId?.join(", ");
      submitHandler();
    }
  }, [state, tRef]);
  const submitHandler = (evt) => {
    evt?.preventDefault();
    evt?.stopPropagation();
    setError(false);
    const domIElem = tRef.current;
    if (!domIElem) {
      return;
    }

    const values = domIElem.value;
    if (!values) {
      return;
    }
    const splittedValues = `${values}`.replace('\r', '').split(/[\s\n,]/);
    const filteredValues = splittedValues.map(txt => txt.trim()).filter(txt => txt && txt.length > 0) || [];
    if (filteredValues.length < 0) {
      return;
    } else if (filteredValues.length > 100) {
      setError(true);
      return;
    }
    cb(!!(tRef.current?.value && tRef.current?.value?.length > 0));
    onSubmit(filteredValues);

    return false;
  };
  return (
    <Wrapper>
      <label>
        List of order names:
      </label>
      <span className={classNames('msg', { error })}>Enter up to 50 orders, each separated by a comma(,) space, or put in a new line.</span>
      {errorElem}
      <label htmlFor="input-GenerateIssueFromOrderID" className='input'>
        List of order name:
      </label>
      <textarea
        id="input-GenerateIssueFromOrderID"
        ref={tRef}
        placeholder="List of order name"
        onChange={evt => {
          if (error) {
            setError(false);
          }
        }}
      />
      <div className="cta">
        <GMButton
          onClick={submitHandler}
        >
          Generate
        </GMButton>
      </div>
    </Wrapper>
  );
};

export default GenerateIssueFromOrderID;
