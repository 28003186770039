import {
  PROMOTIONS_CREATE_REQUEST,
  PROMOTIONS_CREATE_FAIL,
  PROMOTIONS_CREATE_SUCCESS,
} from "./constants";
import {notification} from "antd";

const initialState = {
  data: [],
  params: {},
  creating: false,
};

function PostCreateReducer(state = initialState, action) {
  switch (action.type) {
    case PROMOTIONS_CREATE_REQUEST:
      return {
        ...state,
        creating: true,
        params: action.params,
      };
    case PROMOTIONS_CREATE_SUCCESS:
      notification.success({
        message: "Create promotion successfully.",
        duration: 5
      });
      return {
        ...state,
        creating: false,
      };
    case PROMOTIONS_CREATE_FAIL:
      notification.warn({
        message: action.error && action.error[0] && action.error[0]['msg'] ? action.error[0]['msg'] : 'Create promotion failed.',
        duration: 5
      });
      return {
        ...state,
        creating: false,
      };
    default:
      return state;
  }
}

export default PostCreateReducer;
