import React, { useState, useEffect } from 'react';
import { Spin, Card, Tag, notification, Button, Tooltip } from 'antd';
import { createCampaign } from 'apis/campaign';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import money from 'utils/money';
import noImg from 'images/noImg.png';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GMButton } from 'components/Button';
import { Wrapper } from './styled';
const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [listProductCampaigns, setListProductCampaigns] = useState([]);
  const [rawData, setRawData] = useState(null);
  const [sizePrime, setSizePrime] = useState(null);
  const previewData = useSelector((state) => state.campaign.create.previewData);
  const requestData = useSelector((state) => state.campaign.create.requestData);
  const handleOnCreateCampaign = () => {
    setLoading(true);
    createCampaign(requestData)
      .then((response) => {
        setLoading(false);
        if (response?.code === 'success') {
          notification.success({
            message: 'Create product successfully.',
          });
          props.history.push('/products/list');
          localStorage.setItem('CreateCampaignForm3', null);
        } else {
          notification.warn({
            message: response?.[0]?.['msg'] || 'Create product failed',
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const temp = localStorage.getItem('CreateCampaignForm3')
      ? JSON.parse(localStorage.getItem('CreateCampaignForm3'))
      : null;
    if (Array.isArray(temp?.products) && temp?.products.length > 0) {
      setListProductCampaigns(temp?.products);
      setRawData(temp);
    }
  }, []);
  const onChooseColor = (productCampaignId, color) => {
    let temp = [...listProductCampaigns];
    for (let index = 0; index < temp.length; index++) {
      if (parseInt(temp[index]['id']) === parseInt(productCampaignId)) {
        temp[index]['colorActive'] = color;
      }
    }
    setListProductCampaigns(temp);
  };
  const onChooseSize = (productCampaignId, size) => {
    setSizePrime(size);
    let temp = [...listProductCampaigns];
    for (let index = 0; index < temp.length; index++) {
      if (parseInt(temp[index]['id']) === parseInt(productCampaignId)) {
        temp[index]['sizeActive'] = size;
      }
    }
    setListProductCampaigns(temp);
  };
  const { pageName } = props;
  return (
    <Wrapper pageName={pageName}>
      <Spin spinning={loading}>
        <div className="m-3">
          {listProductCampaigns.map((productCampaign, campaignIndex) => {
            let imageUrl =
              previewData?.[productCampaign.id]?.[
                productCampaign?.colorActive?.id
              ]?.frontImage || '';
            let imageOtherUrl =
              previewData?.[productCampaign.id]?.[
                productCampaign?.colorActive?.id
              ]?.backImage || '';
            let colorHex = productCampaign?.colorActive?.value || '';
            return (
              <Card bordered={true} className="mb-3" key={campaignIndex} style={{ borderRadius: "12px" }}>
                <div className="row">
                  <div className="col-md-6">
                    <Card hoverable={false} bordered={false}>
                      <div className="row justify-content-start align-items-center">
                        <div className="col">
                          <Card
                            className="d-flex align-items-center m-2"
                            bodyStyle={{ padding: '10px', width: '100%' }}
                          >
                            {imageUrl ? (
                              <img
                                className="img-fluid"
                                style={{
                                  width: '100%',
                                }}
                                src={imageUrl}
                                alt=""
                              />
                            ) : (
                              <img
                                className="img-fluid"
                                style={{
                                  backgroundColor: `#${colorHex}`,
                                  width: '100%',
                                }}
                                src={productCampaign?.frontImg || noImg}
                                alt=""
                              />
                            )}
                          </Card>
                        </div>
                        {((productCampaign?.printLocation || '').indexOf('back') >
                          -1 ||
                          (productCampaign?.printLocation || '').indexOf('both') >
                          -1) && (
                            <div className="col">
                              <Card
                                className=" d-flex align-items-center m-2"
                                bodyStyle={{ padding: '10px', width: '100%' }}
                              >
                                {imageOtherUrl ? (
                                  <img
                                    className="img-fluid"
                                    style={{
                                      width: '100%',
                                    }}
                                    src={imageOtherUrl}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    className="img-fluid"
                                    style={{
                                      backgroundColor: `#${colorHex}`,
                                      width: '100%',
                                    }}
                                    src={productCampaign?.backImg || noImg}
                                    alt=""
                                  />
                                )}
                              </Card>
                            </div>
                          )}
                      </div>
                    </Card>
                  </div>
                  <div className="col-md-6">
                    <Card hoverable={false} bordered={false}>
                      <div style={{ lineHeight: '3' }}>
                        <div className="row">
                          <div className="col">
                            <b>Product</b>
                          </div>
                          <div className="col">
                            {' '}
                            {productCampaign?.brand || 'No name'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <b>Price</b>{' '}
                          </div>
                          <div className="col">
                            {money(
                              productCampaign?.customPrice !== null &&
                                Object.keys(productCampaign?.customPrice).length >
                                0
                                ? productCampaign.customPrice[
                                  productCampaign.sizeActive?.id
                                ].price
                                : productCampaign?.comparePrice || 0
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <b>Product colors</b>
                          </div>
                          <div className="col">
                            {' '}
                            {productCampaign.colors.map((color, colorIndex) => (
                              <Tag
                                key={color.id}
                                className={classNames('mb-2 cursor-pointer', {
                                  'border-secondary text-dark':
                                    parseInt(color?.id) === 12,
                                })}
                                onClick={() => {
                                  onChooseColor(
                                    (rawData?.products || [])?.[campaignIndex]?.[
                                    'id'
                                    ] || 0,
                                    color
                                  );
                                }}
                                color={`#${color?.value || 'No color'}`}
                              >
                                {parseInt(color?.id) ===
                                  parseInt(productCampaign?.colorActive?.id) ? (
                                  <Tooltip placement="top" title="Default">
                                    <span
                                      className={classNames({
                                        invisible:
                                          parseInt(color?.id) !==
                                          parseInt(
                                            productCampaign?.colorActive?.id
                                          ),
                                        visible:
                                          parseInt(color?.id) ===
                                          parseInt(
                                            productCampaign?.colorActive?.id
                                          ),
                                      })}
                                    >
                                      <FontAwesomeIcon icon={faCheck} />
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <>
                                    <span
                                      className={classNames({
                                        invisible:
                                          parseInt(color?.id) !==
                                          parseInt(
                                            productCampaign?.colorActive?.id
                                          ),
                                        visible:
                                          parseInt(color?.id) ===
                                          parseInt(
                                            productCampaign?.colorActive?.id
                                          ),
                                      })}
                                    >
                                      <FontAwesomeIcon icon={faCheck} />
                                    </span>
                                  </>
                                )}
                              </Tag>
                            ))}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <b>Product sizes</b>
                          </div>
                          <div className="col">
                            {Array.isArray(productCampaign.sizes) &&
                              productCampaign.sizes.length > 0
                              ? productCampaign.sizes.map((size) => (
                                <Tag
                                  key={size.id}
                                  className="mb-2 text-dark cursor-pointer"
                                  color={`#ccc`}
                                // onClick={() => {
                                //   onChooseSize(
                                //     (rawData?.products || [])?.[
                                //       campaignIndex
                                //     ]?.["id"] || 0,
                                //     size
                                //   );
                                // }}
                                >
                                  <span>{size?.value || ''}</span>
                                  <span
                                    className={classNames({
                                      invisible:
                                        parseInt(size?.id) !==
                                        parseInt(
                                          productCampaign?.sizeActive?.id
                                        ),
                                      visible:
                                        parseInt(size?.id) ===
                                        parseInt(
                                          productCampaign?.sizeActive?.id
                                        ),
                                    })}
                                  ></span>
                                </Tag>
                              ))
                              : 'All sizes'}{' '}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Card>
            );
          })}
          <div>
            <div className="row d-flex justify-content-end">
              <GMButton
                type="button"
                size="md"
                color={'SecondaryGray'}
                onClick={() => {
                  props.history.push('/products/create');
                }}
                className="mr-3"
              >
                Back
              </GMButton>
              <GMButton
                size="md"
                onClick={handleOnCreateCampaign}
                type="button"
                className="mr-3"
              >
                Complete
              </GMButton>
            </div>
          </div>
        </div>
      </Spin>
    </Wrapper>
  );
};

export default withRouter(Index);
