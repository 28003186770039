import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Drawer } from "antd";
import AdvancedFilter from "./AdvancedFilter";
import CloseIcon from "images/x2.svg";
import { ReactComponent as FilterIcon } from 'images/icons/filter-lines.svg';
import { GMButton } from "components/Button";
function FormReportAccount({
  handleAdvancedSubmit,
  changeFieldValue,
  initialValues,
  is_mobile,
  listReportData,
  ...props
}) {
  const [advancdSearch, setAdvancedSearch] = useState(false);

  const onCloseAdvancedSearch = () => {
    setAdvancedSearch(false);
  };
  const onOpenAdvancedSearch = () => {
    setAdvancedSearch(true);
  };
  return (
    <>
      <GMButton
        onClick={onOpenAdvancedSearch}
        type="button"
        color="SecondaryGray"
      >
        More Filter &nbsp;
        <FilterIcon />
      </GMButton>
      <GMButton
        type="button"
        onClick={() => {
          props.onExportReport();
        }}
      >
        <FontAwesomeIcon className="mr-2" icon={faDownload} /> Export
      </GMButton>
      <Drawer
        title={<b className="Filter">Filter</b>}
        className="custom-filter"
        placement="right"
        closable={true}
        onClose={onCloseAdvancedSearch}
        visible={advancdSearch}
        closeIcon={<img src={CloseIcon} style={{ width: "20px" }} alt="close-icon" />}
        width={is_mobile ? "80%" : "480px"}
      >
        <AdvancedFilter
          onCloseAdvancedSearch={onCloseAdvancedSearch}
          onSubmit={handleAdvancedSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          listReportData={listReportData}
        />
      </Drawer>
    </>
  );
}

FormReportAccount.propTypes = {};
export default FormReportAccount;