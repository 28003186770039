import axios from "utils/request";

const prefix = "/mockups";

export const getListAPI = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}`,
  });
};

export const create = (data) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}?with[]=optionSizes&with[]=optionColors`,
  });
};

export const detailAPI = (id) => {
  return axios({
    method: "GET",
    id,
    url: `${prefix}/${id}`,
  });
};

export const deleteMockupAPI = (id) => {
  return axios({
    method: "PUT",
    data: {
      id
    },
    url: `${prefix}/delete-mockup`,
  });
};
