import axios from 'utils/request'

const prefix = '/reports';
const iframeReport = '/embedded-reporting';
// Analytics
export const getIFrameUrl = (params = {}) => {
  return axios({
    method: 'GET',
    url: `${iframeReport}`
  })
}

export const getList = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}`
  })
}

export const create = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}`
  })
}
export const getDetail = (id) => {
  return axios({
    method: 'GET',
    // params,
    url: `${prefix}/${id}?&with[]=orderItems`
  })
}

export const update = (id, data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/${id}`
  })
}

export const destroy = (id) => {
  return axios({
    method: 'POST',
    url: `${prefix}/${id}/delete`
  })
}

export const inkgo = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/inkgo`
  })
}