import React from 'react';
import icon from 'images/invoice.svg';
import Indemnification from '.';
import Preview from './Preview';

export default [
  {
    key: 'billing.indemnification',
    name: 'Indemnification Agreement',
    component: Indemnification,
    path: '/billing/indemnification',
    icon: <img src={icon} height={21} alt="icon" />,
    template: 'default',
    isPublic: false,
  },
  {
    key: 'billing.indemnification.preview',
    name: 'Indemnification Agreement',
    component: Preview,
    path: '/billing/indemnification/preview',
    icon: <img src={icon} height={21} alt="icon" />,
    template: 'default',
    isPublic: false,
  },
];
