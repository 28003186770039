import React from 'react';
import Breadcrumb from 'components/Breadcrumb';
import { PageContainerWrapper } from './styled';

const PageContainer = ({
  pageName,
  children,
  ctas = null,
  forcePathName = null,
  filter,
  noContainer = false,
  breadcrumDetails = {},
  noBreadcrumb = false,
  specialElement = false,
  extendPageName = false,
  ...rest
}) => {
  return (
    <PageContainerWrapper {...rest}>
      {!noBreadcrumb ? (
        <Breadcrumb details={breadcrumDetails} forcePathName={forcePathName} />
      ) : null}
      <div className="topcta clearfix">
        <h2 className="header">
          <span className="pagename">{pageName}</span>
        </h2>
        <p className='extendPageName'>{extendPageName ? extendPageName : ''}</p>
        {ctas ? <div className="ctas">{ctas}</div> : null}
      </div>
      {specialElement ? <div>{specialElement}</div> : null}
      {filter ? <div className="filter">{filter}</div> : null}
      {!noContainer ? <div className="main-content">{children}</div> : children}
    </PageContainerWrapper>
  );
};

// PageContainer.propTypes = {
//   name: PropTypes.string,
//   pageName: PropTypes.string,
//   children: PropTypes.object,
//   ctas: PropTypes.any,
//   noContainer: PropTypes.bool,
// };

export default PageContainer;
