import React, {useState} from "react";
import { Modal} from "antd";

export default ({rawData = {}}) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
        <p className="m-0 text-primary" onClick={() => {setVisible(true)}}>View data</p>
        <Modal
            footer={null}
            visible={visible}
            onCancel={() => setVisible(false)}
            width={1200}
          >
            {JSON.stringify(rawData)}
          </Modal>
    </>
  );
};
