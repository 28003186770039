export const actionType = {
  LIST: {
    REQUEST: "ISSUE.LIST.REQUEST",
    SUCCESS: "ISSUE.LIST.SUCCESS",
    ERROR: "ISSUE.LIST.ERROR",
  },
  STATISTIC: {
    REQUEST: "ISSUE.STATISTIC.REQUEST",
    SUCCESS: "ISSUE.STATISTIC.SUCCESS",
    ERROR: "ISSUE.STATISTIC.ERROR",
  },
  DELETE_ISSUE: {
    REQUEST: "ISSUE.DELETE.REQUEST",
    SUCCESS: "ISSUE.DELETE.SUCCESS",
    ERROR: "ISSUE.DELETE.ERROR",
  },
  DETAIL_ISSUE: {
    REQUEST: "DETAIL_ISSUE.REQUEST",
    SUCCESS: "DETAIL_ISSUE.SUCCESS",
    ERROR: "DETAIL_ISSUE.ERROR",
  },
};

export function getList(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}

export function getStatistic(params) {
  return {
    type: actionType.STATISTIC.REQUEST,
    params,
  };
}

export function deleteIssue(params) {
  return {
    type: actionType.DELETE_ISSUE.REQUEST,
    params,
  };
}

export function detailIssue(id) {
  return {
    type: actionType.DETAIL_ISSUE.REQUEST,
    id,
  };
}
