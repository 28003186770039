import styled from "styled-components";
import {
  BASE_WHITE,
  ERROR_300,
  GRAY_300,
  GRAY_400,
  GRAY_50,
  GRAY_500,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_300,
  SHADOW_XS
} from "components/colors";
import { GMInput, GMInputWrapper } from "./styled";

export default styled(GMInput)`
  textarea,
  input {
    height: 44px;
    line-height: 44px;
    padding: 0 14px;
    outline: none;
    width: 100%;
    
    color: ${GRAY_900};
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    &.ant-input {
      border-radius: 8px!important;
    }
    &:placeholder  {
      color: ${GRAY_400};
    }
    &:focus-visible,
    &:focus {
      outline: none;
      border: 1px solid ${PRIMARY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    }
    &:disabled {
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    }
  }
  textarea {
    height: auto;
    line-height: 24px;
  }
  
  
  .gm-gplace {
    overflow: hidden;
  }
  .gm-gplace.error,
  /* input.error {
    border: 1px solid ${ERROR_300};
  } */
  .input {
    span.icon-before {
      font-size: 0;
      position: absolute;
      left: 14px;
      top: 22px;
      margin-top: -10px;
      display: block;
      float: left;
      z-index: 1;
      svg {
        width: 20px;
        height: 20px;
        stroke: ${GRAY_500};
        float: left;
      }
    }
    &.has-icon-before {
      input {
        padding-left: 40px;
      }
    }
    span.icon-after {
      font-size: 0;
      position: absolute;
      right: 14px;
      top: 22px;
      margin-top: -10px;
      display: block;
      float: left;
      z-index: 1;
      svg {
        width: 20px;
        height: 20px;
        stroke: ${GRAY_500};
        float: left;
      }
    }
    &.has-icon-after {
      input {
        padding-right: 40px;
      }
    }
  }
`;

export const InputWrapper = GMInputWrapper;
