import { ReactComponent as TrashIconG } from 'images/icons/trash-01.svg';
import { ReactComponent as Infor } from 'images/icons/info-circle.svg';
import { Radio, Tooltip, Modal, notification, Space, Tabs } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { Field } from 'redux-form';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import classNames from 'classnames';
import { debounce } from 'lodash';
import money from 'utils/money';
import { profitAble } from 'apis/ex_products';
import 'react-resizable/css/styles.css';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  listProductNotReviewed,
  listProductTypeNotShowedSize,
} from 'utils/options';
import { GMButton } from 'components/Button';
import { WrapperCreateProduct } from './styled';
import { GMTextbox } from 'components/InputFields';
import {
  PickProduct,
  renderImageAnt,
  renderMoney,
  renderSelectAnt,
  renderSwitchAnt,
} from 'utils/Form';
import RenderProductColors from 'utils/Form/RenderProductColors';
import RenderProductSizes from 'utils/Form/RenderProductSizes';
import { getSizeImage, processMockup } from 'utils/helper';

const { confirm } = Modal;

export const RenderProducts = React.memo((props) => {
  const {
    openDefault,
    fields,
    changeField,
    products,
    variants,
    appendProductType,
    cusId = 0,
    loading = false,
    isCloneCampaign = false,
  } = props;
  const [tabPosition, setTabPosition] = useState('front');
  const [tabDesign, setTabDesign] = useState('design');
  const [isFrontOrBack, setIsFrontOrBack] = useState('Front');

  const [productIndexSelected, setProductIndexSelected] = useState(-1);
  const [isChangingTypePrintMug, setIsChangingTypePrintMug] = useState(false);
  const [imageFrontSize, setImageFrontSize] = useState({
    w: 0,
    h: 0,
  });
  const [positionImageFront, setPositionImageFront] = useState({
    x: 0,
    y: 0,
  });
  const [imageFrontMaxConstraints, setImageFrontMaxConstraints] = useState([
    Infinity,
    Infinity,
  ]);

  const [listSizeByDirection, setListSizeByDirection] = useState([]);
  const [frontImageSize, setFrontImageSize] = useState({
    w: 0,
    h: 0,
  });
  const [backImageSize, setBackImageSize] = useState({
    w: 0,
    h: 0,
  });
  const [frontImage, setFrontImage] = useState('');
  const [backImage, setBackImage] = useState('');

  const [imageBackSize, setImageBackSize] = useState({
    w: 0,
    h: 0,
  });
  const [positionImageBack, setPositionImageBack] = useState({
    x: 0,
    y: 0,
  });
  const [imageBackMaxConstraints, setImageBackMaxConstraints] = useState([
    Infinity,
    Infinity,
  ]);
  useEffect(() => {
    if (productIndexSelected > -1 && products[productIndexSelected]) {
      if (
        (products[productIndexSelected][`size${isFrontOrBack}`].w == 0 &&
          products[productIndexSelected][`size${isFrontOrBack}`].h == 0) ||
        isNaN(products[productIndexSelected][`size${isFrontOrBack}`].w) ||
        isNaN(products[productIndexSelected][`size${isFrontOrBack}`].h) ||
        (isChangingTypePrintMug && products[productIndexSelected]['type'] === 2)
      ) {
        resetArtWorkSideByProduct(productIndexSelected);
      }
      if (products[productIndexSelected]['type'] === 'poster') {
        setListSizeByDirection(
          products[productIndexSelected].listSizeByDirection
        );
      }
      setImageFrontSize({
        w: products[productIndexSelected].sizeFront.w,
        h: products[productIndexSelected].sizeFront.h,
      });

      setPositionImageFront({
        x: products[productIndexSelected].positionFront.x,
        y: products[productIndexSelected].positionFront.y,
      });

      setImageBackSize({
        w: products[productIndexSelected].sizeBack.w,
        h: products[productIndexSelected].sizeBack.h,
      });

      setPositionImageBack({
        x: products[productIndexSelected].positionBack.x,
        y: products[productIndexSelected].positionBack.y,
      });
      setFrontImage(products[productIndexSelected].frontImage);
      setBackImage(products[productIndexSelected].backImage);
      setFrontImageSize(products[productIndexSelected].frontImageSize);
      setBackImageSize(products[productIndexSelected].backImageSize);
    }
    return () => { };
  }, [productIndexSelected, products[productIndexSelected], isFrontOrBack]);

  useEffect(() => {
    if (loading === false && isCloneCampaign) {
      setTimeout(() => {
        setIsFrontOrBack('Back');
        setIsFrontOrBack('Front');
      }, 500);
    }
  }, [loading]);
  useEffect(() => {
    if (productIndexSelected > -1 && products[productIndexSelected]) {
      resetArtWorkSideByProduct(productIndexSelected);
    }
    return () => { };
  }, [listSizeByDirection]);
  const changeArtworkToProductInSameCategory = (
    imageSize,
    url,
    side = '',
    optionImage = null,
    isCustomTemplate = false
  ) => {
    if (side === '') {
      side = isFrontOrBack;
    }
    resetArtWorkSideByProduct2(productIndexSelected, imageSize);
    for (let currIndex = 0; currIndex < products.length; currIndex++) {
      if (
        products[currIndex]['type'] ===
        products[productIndexSelected]['type'] &&
        parseInt(currIndex) !== parseInt(productIndexSelected)
      ) {
        changeField(
          side === 'Front'
            ? `products[${currIndex}].frontImage`
            : `products[${currIndex}].backImage`,
          url
        );
        changeField(
          side === 'Front'
            ? `products[${currIndex}].frontImageSize`
            : `products[${currIndex}].backImageSize`,
          imageSize
        );
        if (optionImage) {
          changeField(
            `products[${currIndex}].${side === 'Front' ? 'frontOptionImage' : 'backOptionImage'
            }`,
            optionImage
          );
          changeField(
            `products[${currIndex}].${side === 'Front' ? 'backImage' : 'frontImage'
            }`,
            optionImage.thumbnail_url
          );
          getSizeImage(optionImage.thumbnail_url, (width, height) => {
            changeField(
              `products[${currIndex}].${side === 'Front' ? 'backImageSize' : 'frontImageSize'
              }`,
              {
                w: width,
                h: height,
              }
            );
            resetArtWorkSideByProduct2(
              currIndex,
              {
                w: width,
                h: height,
              },
              side
            );
          });
        } else {
          if (isCustomTemplate) {
            changeField(
              `products[${currIndex}].${side === 'Front' ? 'frontOptionImage' : 'backOptionImage'
              }`,
              null
            );
            changeField(
              `products[${currIndex}].${side === 'Front' ? 'backImage' : 'frontImage'
              }`,
              ''
            );
            changeField(
              `products[${currIndex}].${side === 'Front' ? 'backImageSize' : 'frontImageSize'
              }`,
              {
                w: 0,
                h: 0,
              }
            );
          }
          resetArtWorkSideByProduct2(currIndex, imageSize);
        }
      }
    }
  };

  const resetArtWorkSideByProduct2 = (productId, imageSize, currSide = '') => {
    if (!currSide) {
      currSide = isFrontOrBack;
    }
    const mockupBox = document.getElementById('mockup-box')
      ? document.getElementById('mockup-box').getBoundingClientRect()
      : null;
    const mockupHeightFront =
      mockupBox && products[productId].frontSizeSample
        ? (products[productId].frontSizeSample.h * mockupBox.width) /
        products[productId].frontSizeSample.w
        : 0;
    const mockupHeightBack =
      mockupBox && products[productId].backSizeSample
        ? (products[productId].backSizeSample.h * mockupBox.width) /
        products[productId].backSizeSample.w
        : 0;
    if (mockupBox) {
      if (
        isPetBowlOrWholeMug(productId) &&
        mockupHeightFront &&
        mockupHeightBack
      ) {
        let temp = processMockup(
          {
            w: products[productId][`boxFrontWidth`],
            h: products[productId][`boxFrontHeight`],
          },
          imageSize,
          {
            w: mockupBox.width,
            h: Math.round(mockupHeightFront),
          },
          products[productId]['type'] ? products[productId]['type'] : '',
          products[productId]['typePrint']
            ? products[productId]['typePrint']
            : '',
          'Front'
        );
        let temp2 = processMockup(
          {
            w: products[productId][`boxBackWidth`],
            h: products[productId][`boxBackHeight`],
          },
          imageSize,
          {
            w: mockupBox.width,
            h: Math.round(mockupHeightBack),
          },
          products[productId]['type'] ? products[productId]['type'] : '',
          products[productId]['typePrint']
            ? products[productId]['typePrint']
            : '',
          'Back'
        );
        changeField(`${fields.name}.${productId}.sizeFront`, {
          w: temp.w,
          h: temp.h,
          w2: temp.w2,
          h2: temp.h2,
        });
        changeField(`${fields.name}.${productId}.positionFront`, {
          x: temp.x,
          y: 0,
          x2: temp.x2,
          y2: 0,
        });

        changeField(`${fields.name}.${productId}.sizeBack`, {
          w: temp2.w,
          h: temp2.h,
          w2: temp2.w2,
          h2: temp2.h2,
        });
        changeField(`${fields.name}.${productId}.positionBack`, {
          x: temp2.x,
          y: 0,
          x2: temp2.x2,
          y2: 0,
        });
      } else {
        let temp = processMockup(
          {
            w: products[productId][`box${currSide}Width`],
            h: products[productId][`box${currSide}Height`],
          },
          imageSize,
          {
            w: mockupBox.width,
            h: Math.round(
              currSide === 'Front' ? mockupHeightFront : mockupHeightBack
            ),
          },
          products[productId]['type'] ? products[productId]['type'] : '',
          products[productId]['typePrint']
            ? products[productId]['typePrint']
            : '',
          currSide
        );
        changeField(`${fields.name}.${productId}.size${currSide}`, {
          w: temp.w,
          h: temp.h,
          w2: temp.w2,
          h2: temp.h2,
        });
        changeField(`${fields.name}.${productId}.position${currSide}`, {
          x: temp.x,
          y: 0,
          x2: temp.x2,
          y2: 0,
        });
      }
    }
  };

  const isPetBowlOrWholeMug = (productId) => {
    return (
      (parseInt(products[productId]['type']) === 2 &&
        parseInt(products[productId]['typePrint']) === 5) ||
      parseInt(products[productId]['type']) === 5
    );
  };

  const resetArtWorkSideByProduct = (productId) => {
    if (isChangingTypePrintMug) {
      setIsChangingTypePrintMug(false);
    }
    if (parentRef2 && parentRef2.current) {
      if (isPetBowlOrWholeMug(productId)) {
        let ratio = products[productId]['frontSizeSample']
          ? products[productId]['frontSizeSample']['h'] /
          products[productId]['frontSizeSample']['w']
          : 1;
        let ratio2 = products[productId]['backSizeSample']
          ? products[productId]['backSizeSample']['h'] /
          products[productId]['backSizeSample']['w']
          : 1;
        let temp = processMockup(
          {
            w: products[productId][`boxFrontWidth`],
            h: products[productId][`boxFrontHeight`],
          },
          frontImage ? frontImageSize : backImageSize,
          {
            w: parentRef2.current.clientWidth,
            h: Math.round(parentRef2.current.clientWidth * ratio),
          },
          products[productId]['type'] ? products[productId]['type'] : '',
          products[productId]['typePrint']
            ? products[productId]['typePrint']
            : '',
          'Front'
        );
        let temp2 = processMockup(
          {
            w: products[productId][`boxBackWidth`],
            h: products[productId][`boxBackHeight`],
          },
          frontImage ? frontImageSize : backImageSize,
          {
            w: parentRef2.current.clientWidth,
            h: Math.round(parentRef2.current.clientWidth * ratio2),
          },
          products[productId]['type'] ? products[productId]['type'] : '',
          products[productId]['typePrint']
            ? products[productId]['typePrint']
            : '',
          'Back'
        );
        changeField(`${fields.name}.${productId}.sizeFront`, {
          w: temp.w,
          h: temp.h,
          w2: temp.w2,
          h2: temp.h2,
        });
        changeField(`${fields.name}.${productId}.positionFront`, {
          x: temp.x,
          y: 0,
          x2: temp.x2,
          y2: 0,
        });

        changeField(`${fields.name}.${productId}.sizeBack`, {
          w: temp2.w,
          h: temp2.h,
          w2: temp2.w2,
          h2: temp2.h2,
        });
        changeField(`${fields.name}.${productId}.positionBack`, {
          x: temp2.x,
          y: 0,
          x2: temp2.x2,
          y2: 0,
        });
      } else {
        let ratio =
          isFrontOrBack === 'Front'
            ? products[productId]['frontSizeSample']['h'] /
            products[productId]['frontSizeSample']['w']
            : products[productId]['backSizeSample']['h'] /
            products[productId]['backSizeSample']['w'];
        let temp = processMockup(
          {
            w: products[productId][`box${isFrontOrBack}Width`],
            h: products[productId][`box${isFrontOrBack}Height`],
          },
          isPetBowlOrWholeMug(productId)
            ? frontImage
              ? frontImageSize
              : backImageSize
            : isFrontOrBack === 'Front'
              ? frontImageSize
              : backImageSize,
          {
            w: parentRef2.current.clientWidth,
            h: Math.round(parentRef2.current.clientWidth * ratio),
          },
          products[productId]['type'] ? products[productId]['type'] : '',
          products[productId]['typePrint']
            ? products[productId]['typePrint']
            : '',
          isFrontOrBack
        );
        changeField(`${fields.name}.${productId}.size${isFrontOrBack}`, {
          w: temp.w,
          h: temp.h,
          w2: temp.w2,
          h2: temp.h2,
        });
        changeField(`${fields.name}.${productId}.position${isFrontOrBack}`, {
          x: temp.x,
          y: 0,
          x2: temp.x2,
          y2: 0,
        });
      }
    }
  };

  useEffect(() => {
    if (parseInt(productIndexSelected) === -1 && products.length > 0) {
      setProductIndexSelected(0);
    }
    if (products.length === 0) {
      setProductIndexSelected(-1);
    }
    if (frontImage) {
      resetArtWorkSideByProduct(products.length - 1);
    }
    if (backImage) {
      resetArtWorkSideByProduct(products.length - 1);
    }
    return () => { };
  }, [products.length]);

  const handleChangePrice = (field, salePrice, baseCost) => {
    profitAble(
      field === 'price'
        ? {
          salePrice: salePrice,
          baseCost: baseCost,
        }
        : {
          salePrice: salePrice,
          baseCost: baseCost,
        }
    )
      .then(({ code, data }) => {
        if (code === 'success' && data.profitAble) {
          changeField(
            `${fields.name}[${productIndexSelected}].profitAble`,
            data.profitAble.value
          );
        }
      })
      .catch((error) => { });
  };

  const handleDragImage = (e, ui) => {
    const container_width = parentRef?.current?.clientWidth;
    const container_height = parentRef?.current?.clientHeight;
    if (isFrontOrBack === 'Front') {
      const { x, y } = positionImageFront;
      changeField(`${fields.name}[${productIndexSelected}].positionFront`, {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
        x2: ((x + ui.deltaX) / container_width) * 100,
        y2: ((y + ui.deltaY) / container_height) * 100,
      });
    }
    if (isFrontOrBack === 'Back') {
      const { x, y } = positionImageBack;
      changeField(`${fields.name}[${productIndexSelected}].positionBack`, {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
        x2: ((x + ui.deltaX) / container_width) * 100,
        y2: ((y + ui.deltaY) / container_height) * 100,
      });
    }
  };
  const onResizeImage = (event, { element, size, handle }) => {
    return false;
    if (
      parseInt(products[productIndexSelected]['type']) === 2 &&
      parseInt(products[productIndexSelected]['typePrint']) === 5
    )
      return;
    if (isFrontOrBack === 'Front') {
      const { x, y } = positionImageFront;
      const container_width = parentRef?.current?.clientWidth;
      const container_height = parentRef?.current?.clientHeight;
      setImageFrontMaxConstraints([container_width, container_height]);

      let temp_width = size.width;
      let temp_height = size.height;
      if (container_width - x < size.width) {
        setPositionImageFront({ x: x - 1, y });
        temp_width = container_width - x;
      }
      if (container_height - y < size.height) {
        setPositionImageFront({ x: x, y: y - 1 });
        temp_height = container_height - y;
      }
      changeField(`${fields.name}[${productIndexSelected}]['sizeFront']`, {
        w: temp_width,
        h: temp_height,
        w2: (temp_width / container_width) * 100,
        h2: (temp_height / container_height) * 100,
      });
    }
    if (isFrontOrBack === 'Back') {
      const { x, y } = positionImageBack;
      const container_width = parentRef?.current?.clientWidth;
      const container_height = parentRef.current.clientHeight;
      setImageBackMaxConstraints([container_width, container_height]);
      let temp_width = size.width;
      let temp_height = size.height;
      if (container_width - x < size.width) {
        setPositionImageBack({ x: x - 1, y });
        temp_width = container_width - x;
      }
      if (container_height - y < size.height) {
        setPositionImageBack({ x: x, y: y - 1 });
        temp_height = container_height - y;
      }
      changeField(`${fields.name}[${productIndexSelected}]['sizeBack']`, {
        w: temp_width,
        h: temp_height,
        w2: (temp_width / container_width) * 100,
        h2: (temp_height / container_height) * 100,
      });
    }
  };
  const handleControlPosition = (position) => {
    if (isFrontOrBack === 'Front') {
      switch (position) {
        case 'up-left':
          handleControlPositionDetect(
            positionImageFront.x - 5,
            positionImageFront.y - 5
          );
          break;
        case 'up':
          handleControlPositionDetect(
            positionImageFront.x,
            positionImageFront.y - 5
          );
          break;
        case 'up-right':
          handleControlPositionDetect(
            positionImageFront.x + 5,
            positionImageFront.y - 5
          );
          break;
        case 'left':
          handleControlPositionDetect(
            positionImageFront.x - 5,
            positionImageFront.y
          );
          break;
        case 'center':
          const container_width = parentRef?.current?.clientWidth;
          const container_height = parentRef?.current?.clientHeight;
          handleControlPositionDetect(
            container_width / 2 - imageFrontSize.w / 2,
            container_height / 2 - imageFrontSize.h / 2
          );
          break;
        case 'right':
          handleControlPositionDetect(
            positionImageFront.x + 5,
            positionImageFront.y
          );
          break;
        case 'down-left':
          handleControlPositionDetect(
            positionImageFront.x - 5,
            positionImageFront.y + 5
          );
          break;
        case 'down':
          handleControlPositionDetect(
            positionImageFront.x,
            positionImageFront.y + 5
          );
          break;
        case 'down-right':
          handleControlPositionDetect(
            positionImageFront.x + 5,
            positionImageFront.y + 5
          );
          break;
        default:
        // nothing to do
      }
    }
    if (isFrontOrBack === 'Back') {
      switch (position) {
        case 'up-left':
          handleControlPositionDetect(
            positionImageBack.x - 5,
            positionImageBack.y - 5
          );
          break;
        case 'up':
          handleControlPositionDetect(
            positionImageBack.x,
            positionImageBack.y - 5
          );
          break;
        case 'up-right':
          handleControlPositionDetect(
            positionImageBack.x + 5,
            positionImageBack.y - 5
          );
          break;
        case 'left':
          handleControlPositionDetect(
            positionImageBack.x - 5,
            positionImageBack.y
          );
          break;
        case 'center':
          const container_width = parentRef?.current?.clientWidth;
          const container_height = parentRef.current.clientHeight;
          handleControlPositionDetect(
            container_width / 2 - imageBackSize.w / 2,
            container_height / 2 - imageBackSize.h / 2
          );
          break;
        case 'right':
          handleControlPositionDetect(
            positionImageBack.x + 5,
            positionImageBack.y
          );
          break;
        case 'down-left':
          handleControlPositionDetect(
            positionImageBack.x - 5,
            positionImageBack.y + 5
          );
          break;
        case 'down':
          handleControlPositionDetect(
            positionImageBack.x,
            positionImageBack.y + 5
          );
          break;
        case 'down-right':
          handleControlPositionDetect(
            positionImageBack.x + 5,
            positionImageBack.y + 5
          );
          break;
        default:
        // nothing to do
      }
    }
  };
  const handleControlPositionDetect = (imageX, imageY) => {
    const container_width = parentRef?.current?.clientWidth;
    const container_height = parentRef?.current?.clientHeight;
    if (isFrontOrBack === 'Front') {
      let tempX =
        imageX > 0
          ? imageX + imageFrontSize.w > container_width
            ? container_width - imageFrontSize.w
            : imageX
          : 0;
      let tempY =
        imageY > 0
          ? imageY + imageFrontSize.h > container_height
            ? container_height - imageFrontSize.h
            : imageY
          : 0;
      changeField(`${fields.name}[${productIndexSelected}].positionFront`, {
        x: tempX,
        y: tempY,
        x2: (tempX / container_width) * 100,
        y2: (tempY / container_height) * 100,
      });
    }
    if (isFrontOrBack === 'Back') {
      let tempX =
        imageX > 0
          ? imageX + imageBackSize.w > container_width
            ? container_width - imageBackSize.w
            : imageX
          : 0;
      let tempY =
        imageY > 0
          ? imageY + imageBackSize.h > container_height
            ? container_height - imageBackSize.h
            : imageY
          : 0;
      changeField(`${fields.name}[${productIndexSelected}].positionBack`, {
        x: tempX,
        y: tempY,
        x2: (tempX / container_width) * 100,
        y2: (tempY / container_height) * 100,
      });
    }
  };
  const parentRef = React.createRef();
  const parentRef2 = React.createRef();
  const imgSize =
    isFrontOrBack === 'Front' ? { ...imageFrontSize } : { ...imageBackSize };
  const imgPosition =
    isFrontOrBack === 'Front'
      ? { ...positionImageFront }
      : { ...positionImageBack };
  const imgMaxConstraints =
    isFrontOrBack === 'Front'
      ? { ...imageFrontMaxConstraints }
      : { ...imageBackMaxConstraints };
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };
  const changeTabDesign = (e) => {
    setTabDesign(e.target.value);
  };
  const handleChangeTab = (activeKey) => {
    switch (activeKey) {
      case '1':
        break;
      case '2':
        break;
      default:
      // nothing to do
    }
  };

  products.forEach((i, index) => {
    if (
      !Array.isArray(i?.listSidePrint) &&
      typeof i?.listSidePrint === 'object'
    ) {
      i.listSidePrint = Object.keys(i?.listSidePrint).map((iSP) => {
        const value = i?.listSidePrint[iSP];
        return { id: index + 1, value: value };
      });
    }
  });

  return (
    <WrapperCreateProduct className="row" style={{ marginTop: '20px' }}>
      {fields.length === 0 && (
        <div className="col-md-12">
          <PickProduct
            onPick={(value) => fields.push(value)}
            curSelected={products}
            openDefault={openDefault}
          />
        </div>
      )}
      <Tabs
        defaultActiveKey={1}
        onChange={handleChangeTab}
        className="col-md-5 main-block"
      >
        {
          <Tabs.TabPane tab={<b>Product list</b>} key="1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="row ml-1 variants">
                <div className="mr-3">
                  <b>{fields.length || 0}</b> Products
                </div>
                <div>
                  <b>{variants || 0}</b> Variants
                </div>
              </div>
              <div>
                <PickProduct
                  onPick={(value) => fields.push(value)}
                  element={
                    <GMButton type="button" size="lg" color={'SecondaryGray'}>
                      Add item
                    </GMButton>
                  }
                  curSelected={products}
                />
              </div>
            </div>
            <div className="form-group append">
              <Field
                name="appendProductType"
                component={renderSwitchAnt}
                suffix={<span>Append the product type</span>}
              />
            </div>
            <div className="main-title">Product list</div>
            {products.length > 0 && (
              <div className="design-product">
                {fields?.map((field, index) => {
                  return (
                    <div
                      onClick={() => {
                        setIsFrontOrBack('Front');
                        setProductIndexSelected(index);
                      }}
                      className={classNames(
                        'cursor-pointer card mb-3 border-hover',
                        {
                          'border-active':
                            products[index] &&
                            products[productIndexSelected] &&
                            parseInt(products[index].id) ===
                            parseInt(products[productIndexSelected]?.id),
                        }
                      )}
                      key={index}
                    >
                      <div className="card-body p-5">
                        <div>
                          <div className="col-md-3">
                            <img
                              style={{
                                backgroundColor: `#${products[index]?.colorActive?.value}`,
                                objectFit: 'contain',
                                width: '100%',
                                maxWidth: '100%',
                              }}
                              alt={'mockup'}
                              src={products[index].frontImg}
                            />
                          </div>
                          <div className="col-md-9 mb-3 edit-product">
                            <div className="d-flex columns justify-content-between">
                              <div className="title-design">
                                {products[index]?.brand} -{' '}
                                {products[index]?.name}
                              </div>
                              <div>
                                <TrashIconG
                                  className="trashIcon"
                                  onClick={() => {
                                    if (
                                      parseInt(products[index].id) ===
                                      parseInt(productIndexSelected)
                                    ) {
                                      setProductIndexSelected(-1);
                                    } else {
                                      setTimeout(() => {
                                        setProductIndexSelected(
                                          productIndexSelected
                                        );
                                      }, 0);
                                    }
                                    fields.remove(index);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between base-price-product-list">
                              <span>Base price</span>
                              <span className="money">
                                <b>{money(products[index]?.basePrice)}</b>
                              </span>
                            </div>
                            <div className="d-flex justify-content-between base-price-product-list">
                              <span>Variants</span>
                              <span>
                                {(products[index]?.sizes || []).length *
                                  (products[index]?.colors || []).length || 1}
                              </span>
                            </div>
                          </div>
                          {!(
                            (products[index] &&
                              products[index].typeProduct &&
                              products[index].typeProduct[0] &&
                              products[index].typeProduct[0]['id'] == null) ||
                            (products[index] &&
                              products[index].typeProduct &&
                              products[index].typeProduct[0] &&
                              products[index].typeProduct[0]['imageLink'])
                          ) && (
                              <div className="row group-price">
                                <div className="col-md-6 ">
                                  <div>
                                    <label>
                                      <span className="sale-price-title">
                                        Sale price
                                      </span>
                                      <span className="sale-price ml-1">($)</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div>
                                    <label className="sale-price-title">
                                      <Tooltip
                                        title={
                                          <div>
                                            The product’s original price that
                                            shows up on a product’s listing when
                                            its on sale.
                                          </div>
                                        }
                                      >
                                        <span className="sale-price-title">
                                          Compare as prices
                                        </span>
                                        <span className="sale-price ml-1">
                                          ($)
                                        </span>
                                        <Infor className="inforIcon ml-1" />
                                      </Tooltip>
                                    </label>
                                  </div>
                                </div>
                                <Fragment>
                                  <div className="col-6 mb-3">
                                    <GMTextbox
                                      name={`${field}.price`}
                                      component={renderMoney}
                                      onChange={debounce(
                                        (value) =>
                                          handleChangePrice(
                                            'comparePrice',
                                            fields.get(index)
                                              ? fields.get(index).price
                                              : 0,
                                            fields.get(index)
                                              ? fields.get(index).comparePrice
                                              : 0
                                          ),
                                        500
                                      )}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <GMTextbox
                                      name={`${field}.comparePrice`}
                                      component={renderMoney}
                                      onChange={debounce(
                                        (value) =>
                                          handleChangePrice(
                                            'comparePrice',
                                            fields.get(index)
                                              ? fields.get(index).price
                                              : 0,
                                            fields.get(index)
                                              ? fields.get(index).comparePrice
                                              : 0
                                          ),
                                        500
                                      )}
                                    />
                                  </div>
                                </Fragment>
                              </div>
                            )}
                          {products[index].typeProduct &&
                            products[index].typeProduct[0] &&
                            products[index].typeProduct[0]['id'] == null && (
                              <div className="row group-price">
                                <div className="col-md-6 ">
                                  <div>
                                    <label>
                                      <span className="sale-price-title">
                                        Sale price
                                      </span>
                                      <span className="sale-price ml-1">
                                        ($)
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div>
                                    <label className="sale-price-title">
                                      <Tooltip
                                        title={
                                          <div>
                                            The product’s original price that
                                            shows up on a product’s listing when
                                            its on sale.
                                          </div>
                                        }
                                      >
                                        <span className="sale-price-title">
                                          Compare as price
                                        </span>
                                        <span className="sale-price ml-1">
                                          ($)
                                        </span>
                                        <Infor className="inforIcon ml-1" />
                                      </Tooltip>
                                    </label>
                                  </div>
                                </div>
                                {products[index].typeProduct[0].sizePrice?.map(
                                  (item) => (
                                    <Fragment key={item.id}>
                                      <div className="col-6 mb-3">
                                        <Field
                                          name={`${field}.customPrice[${item.id}].price`}
                                          component={renderMoney}
                                          addonBefore={item.value}
                                          className="h-40px"
                                          onChangeComparePrice={(currValue) => {
                                            changeField(
                                              `${field}.customPrice[${item.id}].comparePrice`,
                                              currValue
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="col-6">
                                        <Field
                                          name={`${field}.customPrice[${item.id}].comparePrice`}
                                          component={renderMoney}
                                          className="h-40px"
                                          addonBefore={item.value}
                                        />
                                      </div>
                                    </Fragment>
                                  )
                                )}
                              </div>
                            )}
                          {products[index].typeProduct &&
                            products[index].typeProduct[0] &&
                            products[index].typeProduct[0]['imageLink'] && (
                              <div className="row group-price">
                                <div className="col-md-6 ">
                                  <div className="form-group">
                                    <label>
                                      Sale price
                                      <span className="sale-price ml-1">
                                        ($)
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6 ">
                                  <div className="form-group">
                                    <label className="">
                                      <Tooltip
                                        title={
                                          <div>
                                            The product’s original price that
                                            shows up on a product’s listing when
                                            its on sale.
                                          </div>
                                        }
                                      >
                                        <span className="sale-price-title">
                                          Compare as prices
                                        </span>
                                        <span className="sale-price ml-1">
                                          ($)
                                        </span>
                                        <Infor className="inforIcon ml-1" />
                                      </Tooltip>
                                    </label>
                                  </div>
                                </div>
                                {Array.isArray(
                                  products[index].listSizeByDirection
                                ) &&
                                  products[index].listSizeByDirection?.map(
                                    (item) => {
                                      return (
                                        <Fragment key={item.id}>
                                          <div className="col-6 mb-3">
                                            <Field
                                              name={`${field}.customPrice[${item.id}].price`}
                                              component={renderMoney}
                                              addonBefore={item.value}
                                              className="h-40px"
                                              onChangeComparePrice={(
                                                currValue
                                              ) => {
                                                changeField(
                                                  `${field}.customPrice[${item.id}].comparePrice`,
                                                  currValue
                                                );
                                              }}
                                            />
                                          </div>
                                          <div className="col-6">
                                            <Field
                                              name={`${field}.customPrice[${item.id}].comparePrice`}
                                              component={renderMoney}
                                              addonBefore={item.value}
                                              className="h-40px"
                                            />
                                          </div>
                                        </Fragment>
                                      );
                                    }
                                  )}
                              </div>
                            )}
                          <div className="row selectField">
                            <div className="col-12">
                              <div className="form-group">
                                {products[index].listSidePrint?.length ? (
                                  <div>
                                    <label className="font-weight-bold text-dark">
                                      Placements
                                    </label>
                                    <GMTextbox
                                      name={`${field}.typePrint`}
                                      component={renderSelectAnt}
                                      options={[
                                        {
                                          value: 0,
                                          label: 'Normal',
                                        },
                                        ...products[index].listSidePrint?.map(
                                          (product_type) => ({
                                            value: product_type.id,
                                            label: product_type.value,
                                          })
                                        ),
                                      ]}
                                      onChangeTypeProduct={() => {
                                        setIsChangingTypePrintMug(true);
                                      }}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              {appendProductType === true && (
                                <div className="form-group">
                                  <label className="font-weight-bold text-dark">
                                    Short product type
                                  </label>
                                  <GMTextbox
                                    name={`${fields.name}[${index}].shortProductType`}
                                    maxLength={255}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label className="font-weight-bold text-dark">
                                  Color
                                </label>
                                <GMTextbox
                                  name={`${field}.colors`}
                                  component={RenderProductColors}
                                  options={products[index].optionColors}
                                  active={products[index].colorActive}
                                  product={products[index]}
                                  changeColorActive={(colorActive) =>
                                    changeField(
                                      `${fields.name}[${index}].colorActive`,
                                      colorActive
                                    )
                                  }
                                />
                              </div>
                            </div>
                            {!listProductTypeNotShowedSize.includes(
                              parseInt(products?.[index]?.['type'])
                            ) && (
                                <div className="col-12">
                                  <div className="form-group">
                                    <label className="font-weight-bold text-dark">
                                      Sizes
                                    </label>
                                    <GMTextbox
                                      name={`${field}.sizes`}
                                      component={RenderProductSizes}
                                      options={products[index].optionSizes}
                                      active={products[index].sizeActive}
                                      product={products[index]}
                                    // changeSizeActive={(sizeActive) => {
                                    //   return changeField(
                                    //     `${fields.name}[${index}].sizeActive`,
                                    //     sizeActive
                                    //   );
                                    // }}
                                    />
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Tabs.TabPane>
        }
        {
          <Tabs.TabPane tab={<b>Upload design</b>} key="2">
            <Space
              style={{
                marginBottom: 24,
              }}
              className="button-upload"
            >
              <Radio.Group value={tabPosition} onChange={changeTabPosition}>
                <Radio.Button value="front" className="front font-weight-bold">
                  <b>Front</b>
                </Radio.Button>
                {products?.[productIndexSelected]?.printLocation?.indexOf('back') !== -1 ? (
                  <Radio.Button value="back" className="back font-weight-bold">
                    <b>Back</b>
                  </Radio.Button>
                ) : null}
              </Radio.Group>
            </Space>
            <div>
              {tabPosition === 'front' &&
                ((
                  products?.[productIndexSelected]?.printLocation || ''
                ).indexOf('front') > -1 ||
                  (
                    products?.[productIndexSelected]?.printLocation || ''
                  ).indexOf('both') > -1) && (
                  <div className="mb-3 text-center upload-frame">
                    <Field
                      name={`products[${productIndexSelected}].frontImage`}
                      component={renderImageAnt}
                      paramName="image"
                      cusId={cusId}
                      showUploadList={false}
                      className="text-center"
                      imgWidth="100%"
                      typeUpload="upload-artwork"
                      changeField={changeField}
                      changeField2={(imageSize) => {
                        changeField(
                          `products[${productIndexSelected}].frontImageSize`,
                          imageSize
                        );
                      }}
                      changeField4={(imageSize, url) => {
                        setIsFrontOrBack('Front');
                        confirm({
                          title: `Do you want to apply artwork to all products in the ${products[productIndexSelected]['productCategory']} category`,
                          icon: <ExclamationCircleOutlined />,
                          okText: 'Yes',
                          cancelText: 'No',
                          onOk() {
                            changeArtworkToProductInSameCategory(
                              imageSize,
                              url,
                              'Front'
                            );
                            notification.success({
                              message: `This artwork is applied to all products in the ${products[productIndexSelected]['productCategory']} category`,
                              duration: 5,
                            });
                          },
                          onCancel() {
                            resetArtWorkSideByProduct2(
                              productIndexSelected,
                              imageSize,
                              'Front'
                            );
                            notification.success({
                              message: `This artwork is apllied to ${products[productIndexSelected]['name']} product`,
                              duration: 5,
                            });
                          },
                        });
                      }}
                      isFrontOrBack="Front"
                    >
                      <div className="small">
                        <b>
                          FRONT IMAGE
                          <br />
                          Choose a design
                        </b>
                        <div className="text-muted mb-3">(.png only)</div>
                        <button
                          type="button"
                          className="btn btn-lg btn-primary rounded-pill font-size-14"
                        >
                          Choose file
                        </button>
                      </div>
                    </Field>
                  </div>
                )}
              {tabPosition === 'back' &&
                ((
                  products?.[productIndexSelected]?.printLocation || ''
                ).indexOf('back') > -1 ||
                  (
                    products?.[productIndexSelected]?.printLocation || ''
                  ).indexOf('both') > -1) && (
                  <div className="mb-3 text-center upload-frame">
                    <Field
                      name={`products[${productIndexSelected}].backImage`}
                      component={renderImageAnt}
                      paramName="image"
                      cusId={cusId}
                      showUploadList={false}
                      className="text-center"
                      imgWidth="100%"
                      typeUpload="upload-artwork"
                      changeField={changeField}
                      changeField2={(imageSize) => {
                        changeField(
                          `products[${productIndexSelected}].backImageSize`,
                          imageSize
                        );
                      }}
                      changeField4={(imageSize, url) => {
                        setIsFrontOrBack('Back');
                        confirm({
                          title: `Do you want to apply artwork to all products in the ${products[productIndexSelected]['productCategory']} category?`,
                          icon: <ExclamationCircleOutlined />,
                          content: "You won't be able to revert this!",
                          okText: 'Yes',
                          cancelText: 'No',
                          onOk() {
                            changeArtworkToProductInSameCategory(
                              imageSize,
                              url,
                              'Back'
                            );
                            notification.success({
                              message: `This artwork is applied to all products in the ${products[productIndexSelected]['productCategory']} category`,
                              duration: 5,
                            });
                          },
                          onCancel() {
                            resetArtWorkSideByProduct2(
                              productIndexSelected,
                              imageSize,
                              'Back'
                            );
                            notification.success({
                              message: `This artwork is apllied to ${products[productIndexSelected]['name']} product`,
                              duration: 5,
                            });
                          },
                        });
                      }}
                      isFrontOrBack="Back"
                    >
                      <div className="small">
                        <b>
                          BACK IMAGE
                          <br />
                          Choose a design
                        </b>
                        <div className="text-muted mb-3">(.png only)</div>
                        <button
                          type="button"
                          className="btn btn-lg btn-primary rounded-pill font-size-14"
                        >
                          Choose file
                        </button>
                      </div>
                    </Field>
                  </div>
                )}
            </div>
          </Tabs.TabPane>
        }
      </Tabs>
      <div className="col-md-7 p-0">
        <div className="design-area">
          <div>
            {productIndexSelected > -1 && products[productIndexSelected] && (
              <div className="">
                <div>
                  <div className="mb-2 text-center">
                    <div
                      className="position-relative"
                      ref={parentRef2}
                      id="mockup-box"
                    >
                      <img
                        className="img-fluid"
                        style={{
                          backgroundColor: `#${products[productIndexSelected].colorActive?.value}`,
                        }}
                        src={
                          isFrontOrBack === 'Front'
                            ? products[productIndexSelected].frontImg
                            : products[productIndexSelected].backImg
                        }
                        alt="mockup-icon"
                      />
                      <div
                        ref={parentRef}
                        className="position-absolute overflow-hidden"
                        style={{
                          top: `${products[productIndexSelected][
                            `box${isFrontOrBack}Top`
                          ]
                            }%`,
                          left: `${products[productIndexSelected][
                            `box${isFrontOrBack}Left`
                          ]
                            }%`,
                          width: `${products[productIndexSelected][
                            `box${isFrontOrBack}Width`
                          ]
                            }%`,
                          height: `${products[productIndexSelected][
                            `box${isFrontOrBack}Height`
                          ]
                            }%`,
                          outline: '1px dashed #333',
                        }}
                      >
                        {((!isPetBowlOrWholeMug(productIndexSelected) &&
                          ((isFrontOrBack === 'Front' && frontImage) ||
                            (isFrontOrBack === 'Back' && backImage))) ||
                          (isPetBowlOrWholeMug(productIndexSelected) &&
                            (frontImage || backImage))) && (

                            <div
                              style={{
                                // cursor: 'move',
                                display: 'table',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                outline: '1px dashed #000',
                                backgroundImage: `url(${!isPetBowlOrWholeMug(productIndexSelected)
                                  ? isFrontOrBack === 'Front'
                                    ? frontImage
                                    : backImage
                                  : frontImage
                                    ? frontImage
                                    : backImage
                                  })`,
                                backgroundSize: 'contain',
                                backgroundPosition: '50% 50%',
                                backgroundRepeat: 'no-repeat',
                                width: '100%',
                                height: '100%',
                              }}
                            >
                            </div>
                          )}
                        {((((isFrontOrBack === 'Front' && !frontImage) ||
                          (isFrontOrBack === 'Back' && !backImage)) &&
                          !isPetBowlOrWholeMug(productIndexSelected)) ||
                          (isPetBowlOrWholeMug(productIndexSelected) &&
                            !frontImage &&
                            !backImage)) && (
                            <div className="mb-3 text-center">
                              <Field
                                name={
                                  isFrontOrBack === 'Front'
                                    ? `products[${productIndexSelected}].frontImage`
                                    : `products[${productIndexSelected}].backImage`
                                }
                                component={renderImageAnt}
                                paramName="image"
                                showUploadList={false}
                                className="text-center"
                                imgWidth="100%"
                                typeUpload="upload-artwork"
                                changeField={changeField}
                                changeField2={(imageSize) => {
                                  changeField(
                                    isFrontOrBack === 'Front'
                                      ? `products[${productIndexSelected}].frontImageSize`
                                      : `products[${productIndexSelected}].backImageSize`,
                                    imageSize
                                  );
                                }}
                                changeField4={(imageSize, url) => {
                                  confirm({
                                    title: `Do you want to apply artwork to all products in the ${products[productIndexSelected]['productCategory']} category?`,
                                    icon: <ExclamationCircleOutlined />,
                                    content: "You won't be able to revert this!",
                                    okText: 'Yes',
                                    cancelText: 'No',
                                    onOk() {
                                      changeArtworkToProductInSameCategory(
                                        imageSize,
                                        url
                                      );
                                      notification.success({
                                        message: `This artwork is applied to all products in the ${products[productIndexSelected]['productCategory']} category`,
                                        duration: 5,
                                      });
                                    },
                                    onCancel() {
                                      resetArtWorkSideByProduct2(
                                        productIndexSelected,
                                        imageSize
                                      );
                                      notification.success({
                                        message: `This artwork is apllied to ${products[productIndexSelected]['name']} product`,
                                        duration: 5,
                                      });
                                    },
                                  });
                                }}
                                isFrontOrBack={isFrontOrBack}
                              >
                                <div className="small">
                                  <b className="mb-1 text-dark">
                                    Upload Artwork{' '}
                                    <span className="text-danger mt-5">(*)</span>
                                  </b>
                                  <div className="mb-1 text-dark">
                                    Requirements
                                  </div>
                                  <div className="text-primary mb-2">
                                    File type: PNG|300 DPI minimum
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-block"
                                  >
                                    Upload artwork
                                  </button>
                                </div>
                              </Field>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {listProductNotReviewed.includes(
              parseInt(products?.[productIndexSelected]?.['id'])
            ) && (
                <p className="text-danger mt-1">
                  This product is not supported for preview on this version of
                  Gearment. You can review it after creating the product.
                </p>
              )}
          </div>
          <div className="d-flex">
            <div className="ml-1">
              <Radio.Group
                value={isFrontOrBack}
                onChange={(e) => setIsFrontOrBack(e.target.value)}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                {products?.[productIndexSelected]?.printLocation?.indexOf('back') !== -1 ? (
                  <div className="d-flex flex-row">
                    <Radio.Button value="Front">
                      <b>Front</b>
                    </Radio.Button>
                    <Radio.Button value="Back">
                      <b>Back</b>
                    </Radio.Button>
                  </div>
                ) : (
                  <Radio.Button value="Front">
                    <b>Front</b>
                  </Radio.Button>
                )}
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>
    </WrapperCreateProduct>
  );
});
