import {
  BASE_WHITE,
  ERROR_700,
  GRAY_300,
  GRAY_700,
} from "components/colors";
import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  .ln {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .gap32 {
    gap: 32px;
    flex-wrap: nowrap;
    flex-direction: row;
    .fixed-cell {
      &-100p,
      &-25p {
        display: flex;
      }
      &-50p {
        width: 50%;
      }
      &-25p {
        width: 25%;
      }
      &-100p {
        width: 100%;
      }
    }
  }
  .gmtextbox {
    width: 100%;
    font-size: 0;
    input {
      font-size: 16px;
    }
  }
  .platform-label {
    margin-bottom: 0;
  }
  .w100p {
    width: 100%;
  }
  .platform-label,
  .w100p {
    width: 100%;
  }
  .rounded-pill {
    margin-top: 10px;
  }
  .platform-info {
    flex-wrap: wrap;
  }
  .blk-right {
    text-align: right;
    width: 100%;
    button {
      margin-left: 16px;
    }
  }
  textarea {
    resize: none;
    height: 128px;
    padding-top: 12px;
    font-size: 16px;
  }
  
  .link {
    &2remove,
    &2add {
      margin-left: 8px;
      width: 44px;
      height: 44px;
      padding: 0;
      text-align: center;
      display: inline-block;
      background: ${BASE_WHITE};
      svg {
        width: 20px;
      }
      &.disabled {
        visibility: hidden;
        pointer-events: none;
      }
    }
    &2remove {
      border-color: ${ERROR_700};
      svg {
        stroke: ${ERROR_700};
      }
    }
    &2add {
      border-color: ${GRAY_300};
      svg {
        stroke: ${GRAY_700};
      }
    }
  }
  .link {
    .input-group {
      flex-wrap: nowrap;
    }
    .link-row {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
    }
  }
  .links label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_700};
    padding: 0;
  }
  .border-danger {
    display: none;
  }
  .main-content {
    margin-bottom: 32px;
    padding: 32px;
  }
`;