import axios from 'utils/request'

const prefix = '/import-backgrounds';

export const getList =(params={})=> {
    return axios({
        method: 'GET',
        params,
        url: `${prefix}?with[]=storeIntegration`
    })
}

export const getDetail =(id)=> {
    return axios({
        method: 'GET',
         url: `${prefix}/${id}?with[]=storeIntegration&with[]=importOrderQueue`
    })
}
