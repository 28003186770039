export const actionType = {
  LIST: {
    REQUEST: "STORE.LIST.REQUEST",
    SUCCESS: "STORE.LIST.SUCCESS",
    ERROR: "STORE.LIST.ERROR",
  },
  LIST_STORE: {
    REQUEST: "STORE.LIST_STORE.REQUEST",
    SUCCESS: "STORE.LIST_STORE.SUCCESS",
    ERROR: "STORE.LIST_STORE.ERROR",
  },
  DELETE: {
    REQUEST: "STORE.DELETE.REQUEST",
    SUCCESS: "STORE.DELETE.SUCCESS",
    ERROR: "STORE.DELETE.ERROR",
  },
  LIST_STORE_STATUS: {
    REQUEST: "STORE.LIST_STORE_STATUS.REQUEST",
    SUCCESS: "STORE.LIST_STORE_STATUS.SUCCESS",
    ERROR: "STORE.LIST_STORE_STATUS.ERROR"
  }
};

export function getList(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}
export function getListStore(params) {
  return {
    type: actionType.LIST_STORE.REQUEST,
    params,
  };
}
export function deleteStore(id) {
  return {
    type: actionType.DELETE.REQUEST,
    id,
  };
}
export function listStoreStatus(){
  return {
    type: actionType.LIST_STORE_STATUS.REQUEST,
  }
}
