import React, { Component } from 'react';

import { reduxForm } from 'redux-form';
import { GMButton } from 'components/Button';
import { Wrapper } from './styled';
class index extends Component {
  render() {
    const { handleSubmit, onCancel } = this.props;
    return (
      <Wrapper>
        {' '}
        <form onSubmit={handleSubmit}>
          <div className="text-muted">
            Are you sure you want to mark this address as valid? This action can
            not be undone.
          </div>

          <div className="d-flex justify-content-between mt-3">
            <GMButton
              color="SecondaryGray"
              type="button"
              onClick={onCancel}
              style={{ width: 170 }}
            >
              Cancel
            </GMButton>
            <GMButton style={{ width: 170 }}>Mark as valid </GMButton>
          </div>
        </form>
      </Wrapper>
    );
  }
}

export default reduxForm({
  form: 'FormMarkValidAddress',
  enableReinitialize: true,
})(index);
