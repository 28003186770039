import React, { useState } from 'react';
import getMomentInstance from 'utils/moment';
import { Steps, Spin, Collapse, Modal } from 'antd';
import { Wrapper } from './styled';
const { Panel } = Collapse;

function RenderHistoryOfOrder({ loading, logsOrder }) {
  // const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(null);
  const showModal = (item2Show) => {
    setShowDetail(item2Show);
  };
  // useEffect(() => {
  //   if (orderId > 0) {
  //     getLogs(orderId);
  //   }
  // }, [orderId]);

  // const getLogs = useCallback(
  //   (orderId, isMore = false) => {
  //     setLoading(true);
  //     list(orderId, {
  //       page: isMore ? (pagination?.currentPage || 1) + 1 : 1,
  //       perPage: 50,
  //     })
  //       .then(({ code, data }) => {
  //         setLoading(false);
  //         if (code === 'success') {
  //           const temp = data?.logs || [];
  //           setListLogs(isMore ? [...listLogs, ...temp] : temp);
  //           setPagination(data.pagination);
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //       });
  //   },
  //   [orderId]
  // );

  return (
    <Wrapper>
      <Spin spinning={loading}>
        <Collapse className="clol">
          <Panel header={<h5 className="font-weight-bold">History</h5>} key="1">
            <Steps progressDot direction="vertical">
              {logsOrder.map((item) => (
                <Steps.Step
                  key={item.id}
                  title={getMomentInstance(item.logTime, 0, true)}
                  description={
                    <div className="d-flex row ml-1">
                      <b>{item.customer?.name}: </b>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: (item.logName || '').replace(
                            /(<? *script)/gi,
                            'illegalscript'
                          ),
                        }}
                      ></span>
                      <span
                        className="text-primary ml-1 cursor-pointer"
                        onClick={() => {
                          showModal(item);
                        }}
                      >
                        detail
                      </span>
                    </div>
                  }
                />
              ))}
            </Steps>
            {/* {pagination?.total && pagination.total !== listLogs.length ? (
              <div className="text-center">
                <button
                  onClick={() => getLogs(orderId, true)}
                  type="button"
                  className="btn-sm btn btn-outline-secondary"
                >
                  View more
                </button>
              </div>
            ) : null} */}
          </Panel>
        </Collapse>
      </Spin>

      {showDetail ? (
        <Modal
          centered
          visible
          footer={null}
          onCancel={() => setShowDetail(null)}
          width={600}
          title={<p className="mb-0  font-weight-bold font-size-20">Detail</p>}
        >
          <span>
            <ul>
              <li>Name: {showDetail.customer?.name} </li>
              <li>
                Content:{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: (showDetail?.logName || '').replace(
                      /(<? *script)/gi,
                      'illegalscript'
                    ),
                  }}
                ></span>
              </li>
              <li>Request: </li>
              <li>
                Time update:{' '}
                {getMomentInstance(
                  null,
                  showDetail.logContent?.ord_update_time,
                  true
                )}
              </li>
              <li>IP address: {showDetail?.logIpAddress}</li>
              <li>
                Information:
                <dl>
                  <dt>
                    {showDetail.logContent?.ord_price_new ? (
                      <span>
                        <>
                          Price: ${showDetail.logContent?.ord_price} -> $
                          {showDetail.logContent?.ord_price_new}
                        </>
                      </span>
                    ) : null}
                  </dt>
                  <dt>
                    {showDetail.logContent?.ord_fee_shipping ? (
                      <span>
                        <>
                          Shipping fee: $
                          {showDetail.logContent?.ord_fee_shipping} -> $
                          {showDetail.logContent?.ord_fee_shipping_new}
                        </>
                      </span>
                    ) : null}
                  </dt>
                  <dt>
                    {' '}
                    {showDetail.logContent?.ord_vat_new ? (
                      <span>
                        <>
                          Tax: ${showDetail.logContent?.ord_vat} -{'>'} $
                          {showDetail.logContent?.ord_vat_new}
                        </>
                      </span>
                    ) : null}
                  </dt>
                  <dt>
                    {' '}
                    {showDetail.logContent?.ord_subtotal_new ? (
                      <span>
                        <>
                          Sub total: ${showDetail.logContent?.ord_subtotal} -> $
                          {showDetail.logContent?.ord_subtotal_new}
                        </>
                      </span>
                    ) : null}
                  </dt>
                  <dt>
                    {' '}
                    {showDetail.logContent?.ord_total_new ? (
                      <span>
                        <>
                          Total: ${showDetail.logContent?.ord_total} -> $
                          {showDetail.logContent?.ord_total_new}
                        </>
                      </span>
                    ) : null}
                  </dt>
                  <dt>
                    {showDetail.logContent?.ord_cus_address1 ? (
                      <span>
                        <>
                          Address 1: {showDetail.logContent?.ord_cus_address1}{' '}
                          ->
                          {showDetail.logContent?.ord_cus_address1_new}
                        </>
                      </span>
                    ) : null}
                  </dt>
                  <dt>
                    {' '}
                    {showDetail.logContent?.ord_update_time_new ? (
                      <span>
                        <>
                          Update time:{' '}
                          {getMomentInstance(
                            null,
                            parseInt(
                              showDetail.logContent?.ord_update_time_new
                            ),
                            true
                          ) || 'N/A'}
                        </>
                      </span>
                    ) : null}
                  </dt>
                </dl>
              </li>
            </ul>
          </span>
        </Modal>
      ) : null}
    </Wrapper>
  );
}
export default React.memo(RenderHistoryOfOrder);
