import React from "react";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
} from "redux-form";
import FieldSelect from "utils/fields/FieldSelect";
import { options_delivery, options_active_webhooks } from "utils/options";
import { renderInputText } from "utils/Form";
import validate from "./validate";
import { GMSelect, GMTextbox } from "components/InputFields";
import { GMButton } from "components/Button";
const FormCreateWebhooks = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <div className="form-group">
          <GMTextbox
            label="Webhooks name"
            placeholder="Webhooks name"
            name="name_webhooks"
            maxLength={"50"}
            showError
          />
        </div>
        <div className="form-group">
          <GMTextbox
            label="Delivery URL"
            placeholder="Delivery URL"
            name="url_webhooks"
            showError
          />
        </div>

        <div className="form-group">
          <GMSelect
            label="Topic"
            placeholder="Topic"
            name="webhooks_topic"
            options={options_delivery}
            showError
          />
        </div>
        <div className="form-group">
          <GMSelect
            label="Status"
            placeholder="Status"
            name="webhooks_status"
            options={options_active_webhooks}
            showError
          />
        </div>
      </div>
      <div className="text-center d-flex align-items-center justify-content-end">
        <GMButton>Save</GMButton>
      </div>
    </form>
  );
};

FormCreateWebhooks.propTypes = {};

const selector = formValueSelector("FormCreateWebhooks");
const mapStateToProps = (state) => ({
  topic: selector(state, "topic") || "",
  formCreateWebhooks: state.form.formCreateWebhooks,
  name_webhooks: selector(state, "name_webhooks") || "",
  url_webhooks: selector(state, "url_webhooks") || "",
  topic_webhooks:
    selector(state, "topic_webhooks") || options_delivery[0]["value"],
  status_webhooks:
    selector(state, "status_webhooks") || options_active_webhooks[0]["value"],
});
const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change("FormCreateWebhooks", field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "FormCreateWebhooks",
    enableReinitialize: true,
    validate
  })(FormCreateWebhooks)
);
