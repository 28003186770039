import React from 'react';
import { Wrapper } from './styled';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter, getSelectedMenuKeys } from 'utils/helper';
import { ReactComponent as ChevronRightIcon } from 'images/icons/chevron-right.svg';
import menu from 'route/menu';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Virtual from './Virtual';
import { useHistory } from 'react-router-dom';

export const convertMenuArr2Obj = (arr) => {
  const menuObj = {};
  arr.forEach((mI) => {
    const subInfo = mI?.subInfo || [];
    const children = mI?.children || [];
    if (!mI.ignoreSub) {
      const infoConv = convertMenuArr2Obj([...children, ...subInfo])
      mI = { ...mI, ...infoConv };
    }
    const key = _.last((mI?.key || mI?.hkey)?.split('.'));

    menuObj[key] = mI;
  });
  return menuObj;
};
export default ({ forcePathName, details = {} }) => {
  const location = useSelector((s) => s.router.location);
  const { pathname } = location;
  const ids = [...pathname.matchAll(/\d+/gi)]?.map((p) => p?.[0]) || [];
  const currentPath = `${pathname}`.replace(/\d+/, ':id');
  const currentPathKey = currentPath.split('/').filter(c => c).join('.');
  const pathKeys = getSelectedMenuKeys(
    currentPath,
    false
  );
  if (!pathKeys || pathKeys?.length * 1 <= 0) {
    return null;
  }
  const menuObj = convertMenuArr2Obj(menu);
  const lastItemIndex = pathKeys?.length - 1;
  let nameIndex = 0;
  let names = [];
  const currentMenuInfo = _.get(menuObj, currentPathKey);
  if (currentMenuInfo?.virtualPath) {
    return <Virtual
      menuObj={menuObj}
      virtualPaths={currentMenuInfo?.virtualPath}
      details={details}
    />;
  }
  const history = useHistory();
  return (
    <Wrapper>
      <ul>
        <li>
          <Link to="/">
            <span>Home</span>
            <ChevronRightIcon />
          </Link>
        </li>
        {!forcePathName ? (
          pathKeys.map((pI, inx) => {
            const obj = _.get(menuObj, pI);
            const _url = (obj?.target || obj?.path)?.replace(/^\//, '');
            let url = (_url || pI).replace(/\./gi, '/');
            ids.forEach((id) => {
              url = url.replace(':id', id);
              names.push(details?.[parseInt(id)]);
            });
            const lastName = /:id$/.test(pI) ? names[nameIndex++] : null;
            if (!obj) {
              return null;
            }

            const infoName = (history?.location?.search + '').includes('update=') || (history?.location?.search + '').includes('id=') ? (obj?.updateName || obj?.name) : obj?.name;

            if (inx === lastItemIndex || obj.ignoreSub) {
              return (
                <li className="last" key={'breadcrumb_item_' + inx}>
                  <span>{lastName || infoName}</span>
                </li>
              );
            }
            if (obj.target === null) {
              return (
                <li key={'breadcrumb_item_' + inx}>
                  <span>{lastName || infoName}</span>
                  <ChevronRightIcon />
                </li>
              );
            } else {
              return (
                <li key={'breadcrumb_item_' + inx}>
                  <Link to={obj.target || '/' + url}>
                    <span>{lastName || infoName}</span>
                  </Link>
                  <ChevronRightIcon />
                </li>
              );
            }
          })
        ) : (
          <li className="last">
            <span>{forcePathName}</span>
          </li>
        )}
      </ul>
    </Wrapper>
  );
};
