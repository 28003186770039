import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Button, Spin, Pagination, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getList, deleteWebhooks } from './actions';
import queryString from 'query-string';
import plusCircle from 'images/plus-circle.svg';
import { ReactComponent as AddIcon } from 'images/icons/plus.svg';
import { ReactComponent as EditIcon } from 'images/icons/edit-01.svg';
import { ReactComponent as TrashIcon } from 'images/icons/trash-01.svg';
import {
  getOptionLabel,
  options_active_webhooks,
  options_delivery,
} from 'utils/options';
import edit from 'images/edit.svg';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import { MoreActionStore } from 'scenes/Campaigns/scenes/List/styled';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import { ContextMenuWrapper } from 'scenes/BillingPaymentAccount/v2/styled';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import { ConfirmModalInnerWrapper } from 'components/Modal/ConfirmDialog/styled';
import { ReactComponent as IconInfo } from 'images/icons/info-circle.svg';

class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      record2Remove: null,
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        ...query_params,
      },
    };
  }
  componentDidMount() {
    this.fetchData(this.state.initial_filter_values);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
      this.fetchData(this.state.initial_filter_values);
    }
  }

  onCreateWebhooksButtonClick = () => {
    this.props.history.push('/settings/webhooks/create');
  };
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getList(params);
  };
  deleteWebhooks = (id) => {
    this.props.deleteWebhooks(this.state.record2Remove.id);
    this.setState({ record2Remove: null });
  };
  render() {
    const { list, pageName, _delete } = this.props;
    const columns = [
      {
        title: <b>#</b>,
        dataIndex: 'id',
        with: '10%',
        align: 'center',
        key: 'id',
        render: (text) => (
          <Link to={`/settings/webhooks/${text}/edit`}>#{text}</Link>
        ),
      },
      {
        title: <b>Name</b>,
        with: '10%',
        dataIndex: 'name',
        align: 'center',
        key: 'name',
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>Topic</b>,
        with: '10%',
        dataIndex: 'topicKey',
        align: 'center',
        key: 'topicKey',
        // render: (value) => getOptionLabel(options_delivery, value),
      },
      {
        title: <b>Status</b>,
        with: '5%',
        dataIndex: 'statusTitle',
        align: 'center',
        key: 'statusTitle',
        render: (value) => getOptionLabel(options_active_webhooks, value),
      },
      {
        title: <b>Delivery URL</b>,
        dataIndex: 'url',
        with: '35%',
        align: 'center',
        key: 'url',
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>Action</b>,
        with: '25%',
        align: 'center',
        render: (record) => (
          <div>
            {/* <Link
              to={`/settings/webhooks/${record.id}/edit`}
              className="edit mr-1"
            >
              <EditIcon />
            </Link>
            <Popconfirm
              title="Are you sure to delete this record?"
              okText="Yes"
              cancelText="No"
              onConfirm={this.deleteWebhooks.bind(this, record.id)}
            >
              <span className="delete">
                <TrashIcon />
              </span>
            </Popconfirm> */}

            <div>
              <MoreActionStore>
                <Dropdown
                  placement="topLeft"
                  overlay={
                    <ContextMenuWrapper className="p-3">
                      <Link
                        to={`/settings/webhooks/${record.id}/edit`}
                        className="cursor-pointer"
                      >
                        Edit
                      </Link>
                      <div className="delete cursor-pointer">
                        <div
                          onClick={() =>
                            this.setState({ record2Remove: record })
                          }
                        >
                          Delete
                        </div>
                      </div>
                    </ContextMenuWrapper>
                  }
                  trigger={['click']}
                >
                  <MoreAction className="context-menu" />
                </Dropdown>
              </MoreActionStore>
            </div>
          </div>
        ),
      },
    ];

    return (
      <Spin spinning={_delete.loading}>
        <Wrapper
          pageName={pageName}
          // forcePathName="Webhooks"
          ctas={
            <GMButton
              type="primary"
              className="addnewbtn"
              onClick={this.onCreateWebhooksButtonClick}
            >
              <AddIcon />
              Create Webhook
            </GMButton>
          }
        >
          <Table
            rowKey="id"
            columns={columns}
            dataSource={list.data}
            loading={list.loading}
            scroll={{ x: 1000 }}
            pagination={
              !this.props.is_mobile
                ? {
                    showTotal: (total) => `Total ${total} items`,
                    total: list.total,
                    pageSize: parseInt(
                      list.params.perPage > list.params.total
                        ? list.params.total
                        : list.params.perPage
                    ),
                    current: list.params.page,
                    showSizeChanger: true,
                    onChange: (page, perPage) =>
                      this.fetchData({ ...list.params, page, perPage }),
                  }
                : false
            }
          />
          {this.props.is_mobile && (
            <div className="d-flex justify-content-end align-items-center py-3 px-3">
              <div>
                <Pagination
                  size="small"
                  showSizeChanger
                  className="pagination"
                  total={list.total}
                  defaultCurrent={list?.params?.page || 1}
                  pageSize={list?.params?.perPage || 0}
                  onChange={(page, perPage) =>
                    this.fetchData({
                      ...list.params,
                      page:
                        page === Number.NEGATIVE_INFINITY
                          ? 1
                          : page === 0
                          ? 1
                          : page,
                      perPage,
                    })
                  }
                />
              </div>
            </div>
          )}
        </Wrapper>
        {this.state.record2Remove !== null ? (
          <ConfirmDialog
            ctas={
              <>
                <GMButton
                  type="button"
                  color="SecondaryGray"
                  disabled={_delete.loading}
                  onClick={() => this.setState({ record2Remove: null })}
                >
                  Cancel
                </GMButton>

                <GMButton
                  type="button"
                  color="PrimaryError"
                  disabled={_delete.loading}
                  onClick={() => this.deleteWebhooks()}
                >
                  Delete
                </GMButton>
              </>
            }
            // {...props}
          >
            <ConfirmModalInnerWrapper>
              <div className="icon">
                <div className="wrapper">
                  <IconInfo />
                </div>
              </div>
              <div className="content">
                <h4 className="header">Remove this user?</h4>
                <div className="msg">
                  This user will be no longer have access to your Gearment
                  account.
                </div>
              </div>
            </ConfirmModalInnerWrapper>
          </ConfirmDialog>
        ) : null}
      </Spin>
    );
  }
}

export default connect(
  (state) => ({
    list: state.webhooks.list.list,
    _delete: state.webhooks.list.delete,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
    deleteWebhooks: (id) => {
      dispatch(deleteWebhooks(id));
    },
  })
)(List);
