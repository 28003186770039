import { notification } from "antd";
import { actionType as TYPE } from "./actions";
import parse from "html-react-parser";

const initialState = {
  createApiKey: {
    loading: false,
    data: {},
  },
  deleteApiKey: {
    loading: false,
  },
  detailApiKey: {
    loading: false,
    data: {},
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    //Create api key
    case TYPE.CREATE_API_KEY.REQUEST:
      return {
        ...state,
        createApiKey: {
          ...initialState.createApiKey,
          loading: true,
        },
      };
    case TYPE.CREATE_API_KEY.SUCCESS:
      notification.success({
        message: "Request API key successfully.",
        duration: 5,
      });
      return {
        ...state,
        createApiKey: {
          ...state.createApiKey,
          loading: false,
        },
      };
    case TYPE.CREATE_API_KEY.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Request API key failed",
        duration: 5,
      });
      return {
        ...state,
        createApiKey: {
          ...state.createApiKey,
          loading: false,
        },
      };
    //Detail api key

    case TYPE.DETAIL_API_KEY.REQUEST:
      return {
        ...state,
        detailApiKey: {
          ...initialState.detailApiKey,
          loading: true,
        },
      };
    case TYPE.DETAIL_API_KEY.SUCCESS:
      return {
        ...state,
        detailApiKey: {
          ...state.detailApiKey,
          loading: false,
          data: action.data.apiKey,
        },
      };
    case TYPE.DETAIL_API_KEY.ERROR:
      return {
        ...state,
        detailApiKey: {
          ...state.detailApiKey,
          loading: false,
        },
      };
    //Delete api key

    case TYPE.DELETE_API_KEY.REQUEST:
      return {
        ...state,
        deleteApiKey: {
          ...state.deleteApiKey,
          loading: true,
        },
      };
    case TYPE.DELETE_API_KEY.SUCCESS:
      notification.success({
        message: "Remove API key successfully.",
        duration: 5,
      });
      return {
        ...state,
        deleteApiKey: {
          ...state.deleteApiKey,
          loading: false,
        },
      };
    case TYPE.DELETE_API_KEY.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Remove api key failed",
        duration: 5,
      });
      return {
        ...state,
        deleteApiKey: {
          ...state.deleteApiKey,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
