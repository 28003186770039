import React from "react";
function RenderSelectPlatformToSyncOrder({
  input,
  active = null,
  options = [],
  meta: { asyncValidating, error, submitFailed },
  ...rest
}) {
  const handleOnChooseFileType = (value) => {
    input.onChange(value);
  };
  return (
    <div className="container-fluid px-0">
      <div className="row sync-order-platform-section">
        {options.map((option, index) => (
          <div
            className="col-6 col-sm-4 col-lg mb-3"
            key={index}
          >
            <div
              className={"sync-order-platform-item text-center p-3 h-100 w-100 d-flex flex-column align-items-center justify-content-center" + (active === option.logo ? ' active' : '')}
              onClick={() => {
                handleOnChooseFileType(option.value);
              }}
            >
              <p className={'my-2'}>
                <img
                  src={option.logo}
                  className="img-fluid"
                  style={{ width: option.logoWidth || "50%" }}
                  alt="logo"
                />
              </p>
            </div>
          </div>
        ))}
      </div>
      {(submitFailed && error) && <span className="invalid-feedback">{error}</span>}
    </div>
  );
};
export default React.memo(RenderSelectPlatformToSyncOrder);