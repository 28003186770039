import React from "react";
import SettingGeneral from "scenes/Settings/scenes/General";
import ListStaff from "scenes/Settings/scenes/Account/scenes/ListStaff";
import DetailAccount from "scenes/Settings/scenes/Account/scenes/Edit";
import CreateAccount from "scenes/Settings/scenes/Account/scenes/Create";
import ListReport from "scenes/Settings/scenes/Account/scenes/ListReport";
import DetailReport from "scenes/Settings/scenes/Account/scenes/ReportDetail";
import FormFulfillment from "scenes/Settings/scenes/General/components/FormFulfillment";
import DefaultShipping from "scenes/Settings/scenes/General/components/DefaultShipping";
import icon from "images/dollar2.svg";
import OrderReportDashboard from "./scenes/Account/scenes/OrderReportDashboard";
import IFrameAnalytics from "scenes/IFrameAnalytics";

export default [
  {
    key: "setting.general",
    name: "Settings",
    component: SettingGeneral,
    path: "/settings",
    group: "settings",
    template: "default",
    icon: <img src={icon} height={21} alt="Icon" />,
    isPublic: false,
    permissions: ["viewSettingPermission"],
  },
  {
    key: "setting.general.formFulfill",
    name: "General",
    component: FormFulfillment,
    path: "/fulfillment",
    group: "settings",
    template: "default",
    icon: <img src={icon} height={21} alt="Icon" />,
    isPublic: false,
    permissions: ["viewSettingPermission"],
  },
  {
    key: "setting.general.shipping",
    name: "Shipping and delivery",
    component: DefaultShipping,
    path: "/shipping",
    group: "settings",
    template: "default",
    icon: <img src={icon} height={21} alt="Icon" />,
    isPublic: false,
    permissions: ["viewSettingPermission"],
  },

  {
    key: "setting.accounts",
    name: "Staff accounts",
    component: ListStaff,
    path: "/settings/staff-accounts",
    group: "settings",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["viewSettingPermission"],
  },
  {
    key: "setting.accounts.edit",
    name: "Edit permission",
    component: DetailAccount,
    path: "/settings/staff-accounts/:id/edit",
    group: "settings",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["viewSettingPermission"],
  },

  {
    key: "setting.accounts.create",
    name: "Invite staff account",
    component: CreateAccount,
    path: "/settings/staff-accounts/create",
    group: "settings",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["viewSettingPermission"],
  },

  {
    key: "setting.account-reports",
    name: "Report staff account",
    component: ListReport,
    path: "/settings/staff-accounts/reports",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["viewSettingPermission"],
  },
  {
    key: "analytics",
    name: "Analytics",
    component: IFrameAnalytics,
    path: "/analytics",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["viewSettingPermission"],
  },
  //Order Report Dashboard
  {
    key: "statistics.reports-dashboard",
    name: "Report Dashboard",
    component: OrderReportDashboard,
    path: "/statistics/reports-dashboard",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["viewSettingPermission"],
  },
  //
  {
    key: "setting.account.report",
    name: "Account ",
    component: DetailReport,
    path: "/settings/staff-accounts/:id/report",
    group: "settings",
    icon: null,
    template: "default",
    isPublic: false,
    permissions: ["viewSettingPermission"],
  },
];
