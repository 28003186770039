import { notification } from 'antd';
import { actionType as TYPE } from './actions';

const initialState = {
  detailConfigBuyTracking: {
    loading: false,
    data: {},
  },
  updateConfigBuyTracking: {
    loading: false,
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.DETAIL_CONFIG_BUY_TRACKING.REQUEST:
      return {
        ...state,
        detailConfigBuyTracking: {
          ...initialState.detailConfigBuyTracking,
          loading: true,
        },
      };
    case TYPE.DETAIL_CONFIG_BUY_TRACKING.SUCCESS:
      return {
        ...state,
        detailConfigBuyTracking: {
          ...state.detailConfigBuyTracking,
          loading: false,
          data: action?.data,
        },
      };
    case TYPE.DETAIL_CONFIG_BUY_TRACKING.ERROR:
      return {
        ...state,
        detailConfigBuyTracking: {
          ...state.detailConfigBuyTracking,
          loading: false,
        },
      };

    case TYPE.UPDATE_CONFIG_BUY_TRACKING.REQUEST:
      return {
        ...state,
        updateConfigBuyTracking: {
          ...initialState.updateConfigBuyTracking,
          loading: true,
        },
      };
    case TYPE.UPDATE_CONFIG_BUY_TRACKING.SUCCESS:
      notification.success({
        message: action?.clientMsg || "Update successfully",
        duration: 5,
      });
      return {
        ...state,
        updateConfigBuyTracking: {
          ...state.updateConfigBuyTracking,
          loading: false,
        },
      };
    case TYPE.UPDATE_CONFIG_BUY_TRACKING.ERROR:
      return {
        ...state,
        updateConfigBuyTracking: {
          ...state.updateConfigBuyTracking,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
