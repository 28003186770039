import React, { useState } from "react";

import { Drawer } from "antd";
import AdvancedFilter from "./AdvancedFilter";
import { ReactComponent as FilterIcon } from 'images/icons/filter-lines.svg';
import CloseIcon from "images/x2.svg";
function FormStockStatus({
  listColor,
  listSize,
  listProduct,
  handleAdvancedSubmit,
  changeFieldValue,
  initialValues,
  is_mobile,
  stock_status,
  exproducts,
  ...props
}) {
  const [advancdSearch, setAdvancedSearch] = useState(false);

  const onCloseAdvancedSearch = () => {
    setAdvancedSearch(false);
  };
  const onOpenAdvancedSearch = () => {
    setAdvancedSearch(true);
  };
  return (
    <>
      <div className="filter-section">
        <div
          className="filter-button"
          onClick={onOpenAdvancedSearch}
        >
          <FilterIcon className="filter" />
          Filters
        </div>
      </div>
      <Drawer
        title={<b className="Filter">Filter</b>}
        className="custom-filter"
        placement="right"
        closable={true}
        onClose={onCloseAdvancedSearch}
        visible={advancdSearch}
        closeIcon={<img src={CloseIcon} style={{ width: "20px" }} alt="close icon" />}
        width={is_mobile ? "80%" : "480px"}
      >
        <AdvancedFilter
          onCloseAdvancedSearch={onCloseAdvancedSearch}
          onSubmit={handleAdvancedSubmit}
          listProduct={listProduct}
          changeFieldValue={changeFieldValue}
          listSize={listSize}
          initialValues={initialValues}
          listColor={listColor}
          stock_status={stock_status}
          exproducts={exproducts}

        />
      </Drawer>
    </>
  );
}

FormStockStatus.propTypes = {};
export default FormStockStatus;
