import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  change,
  formValueSelector,
  reduxForm,
  arrayRemoveAll,
  arrayInsert,
} from 'redux-form';
import { Table, Modal, Pagination } from 'antd';
import { getListImages, archiveImages } from './actions';
import { uploadArtwork } from 'apis/image_design';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { addMarginTopToCheckBoxOfAntdTable } from 'utils/helper';
import Filter from 'components/Filter';
import { Wrapper } from './styled';
import { getColumnConfig } from './column';
import { GMButton } from 'components/Button';
import ModalUpload from './ModalUpload';
import RenderListActions from 'utils/Form/RenderListActions';
import Export from './Export';
const { confirm } = Modal;
const validate = () => {
  let errors = {};
  return errors;
};
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showListImage: false,
      selectedRowKeys: [],
      openModalUpload: false,
    };
  }

  componentDidMount() {
    this.fetchData({});
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.images) !== JSON.stringify(this.props.images)
    ) {
      const newSelectedRowKeys = this.state.selectedRowKeys.filter((id) =>
        (this.props?.images?.data || []).find(
          (item) => parseInt(item.id) === parseInt(id)
        )
      );
      this.setState({ selectedRowKeys: newSelectedRowKeys });
      window.scroll(0, 0);
    }
    addMarginTopToCheckBoxOfAntdTable(
      'ant-table-selection',
      this.state.selectedRowKeys
    );
  }

  fetchData = ({ date_range = null, page = 1, perPage = 20, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    if (date_range?.[0] && date_range?.[1]) {
      params.from = date_range?.[0]?.startOf('day')?.unix();
      params.to = date_range?.[1].endOf('day')?.unix();
    } else {
      params.from = params.to = undefined;
    }
    this.props.getListImages({
      ...params,
    });
  };

  setShowListImage = (status) => {
    this.setState({ showListImage: status });
  };

  uploadImage = (item, index) => {
    const data = this.props.data || [];
    this.props.changeFieldValue(`listImages.${index + data.length}`, {
      ...item,
      loading: true,
      status: 0,
    });
    uploadArtwork(`${item.imageLink}`, item.imageTitle).then((response) => {
      if (response.code === 'success') {
        this.props.changeFieldValue(`listImages.${index + data.length}`, {
          ...item,
          loading: false,
          status: 1,
          error: '',
        });
      } else {
        this.props.changeFieldValue(`listImages.${index + data.length}`, {
          ...item,
          loading: false,
          status: 2,
          error:
            Array.isArray(response) && response[0] && response[0]['clientMsg']
              ? response[0]['clientMsg']
              : '',
        });
      }
    });
  };
  archiveImage = (ids) => {
    const { archiveImages } = this.props;
    confirm({
      title: 'Do you want to archive these images?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        archiveImages({ id: ids.join(',') });
      },
      onCancel() {},
    });
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { pageName, images, archiveImageLoading } = this.props;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };
    const rowSelectionAltActions = {
      selections: [
        {
          key: 'arhived',
          text: 'Delete',
          onSelect: () => {
            this.archiveImage(selectedRowKeys);
          },
        },
      ],
    };
    const listActions = [
      {
        key: 'arhived',
        text: 'Delete',
        onSelect: () => {
          this.archiveImage(selectedRowKeys);
        },
      },
    ];
    return (
      <Wrapper
        pageName={pageName}
        ctas={
          <>
            {this.state.selectedRowKeys.length > 0 ? (
              <RenderListActions
                title={'images'}
                selectedRowKeys={this.state.selectedRowKeys}
                rowSelection={rowSelectionAltActions}
                actions={listActions}
              />
            ) : null}
            <Export />
            <GMButton
              className="cta-upload"
              type="button"
              onClick={() => this.setState({ openModalUpload: true })}
            >
              Upload new
            </GMButton>
          </>
        }
        filter={
          <Filter
            placeholder="Search by file name"
            className="filter-form"
            showFilter
            onSubmit={(values) => {
              this.fetchData({
                date_range: values?.date_range,
                name: (values.search || '').trim(),
                page: 1,
              });
            }}
          />
        }
      >
        {(this.props.listImages.length === 0 || !this.state.showListImage) && (
          <>
            <div className="custom-header-sticky table">
              <Table
                rowKey="id"
                columns={getColumnConfig(rowSelection, this.state)}
                rowSelection={rowSelection}
                dataSource={images.data}
                loading={images.loading || archiveImageLoading}
                scroll={{ x: 1000 }}
                pagination={
                  !this.props.is_mobile
                    ? {
                        showTotal: (total) => `Total ${total} items`,
                        total: images.total,
                        pageSize: 20,
                        showSizeChanger: false,

                        onChange: (page, perPage) =>
                          this.fetchData({ ...images.params, page, perPage }),
                      }
                    : false
                }
              />
            </div>
            {this.props.is_mobile && (
              <div className="d-flex justify-content-end align-items-center py-3 px-3">
                <div>
                  <Pagination
                    size="small"
                    showSizeChanger
                    className="pagination"
                    total={images.total}
                    defaultCurrent={images?.params?.page || 1}
                    pageSize={20}
                    onChange={(page, perPage) =>
                      this.fetchData({
                        ...images.params,
                        page:
                          page === Number.NEGATIVE_INFINITY
                            ? 1
                            : page === 0
                            ? 1
                            : page,
                        perPage,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </>
        )}
        <ModalUpload
          isOpenModal={this.state.openModalUpload}
          closeModal={() => this.setState({ openModalUpload: false })}
          fetchData={this.fetchData}
          uploadImage={this.uploadImage}
        />
      </Wrapper>
    );
  }
}
index.propTypes = {};
const selector = formValueSelector('FormUploadImage');
const mapStateToProps = (state) => ({
  formCreateBulkData: state.form.FormUploadImage?.values || {},
  listImages: selector(state, 'listImages') || [],
  images: state.image_library.list.listImages,
  profile: state.auth.info.profile,
  archiveImageLoading: state.image_library.list.archive.loading,
  initialValues: {
    listImages: [],
  },
});
const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change('FormUploadImage', field, value));
  },
  removeAllItem: (field) => {
    dispatch(arrayRemoveAll('FormUploadImage', field));
  },
  insertItem: (field, value, index) => {
    dispatch(arrayInsert('FormUploadImage', field, index, value));
  },
  getListImages: function (params) {
    dispatch(getListImages(params));
  },
  archiveImages: function (data) {
    dispatch(archiveImages(data));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'FormUploadImage',
    enableReinitialize: true,
    validate,
  })(index)
);
