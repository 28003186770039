import React, { useState } from 'react';
import { Dropdown, Spin, Table } from 'antd';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import { MoreActionStore } from 'scenes/Campaigns/scenes/List/styled';
import { ContextMenuWrapper } from 'scenes/BillingPaymentAccount/v2/styled';
import { Link } from 'react-router-dom';
import getMomentInstance from 'utils/moment';
import { getOptionLabel, options_active } from 'utils/options';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  deleteAccount,
  listAccount,
} from 'scenes/Settings/scenes/Account/actions';
import { ConfirmModalInnerWrapper } from 'components/Modal/ConfirmDialog/styled';
import { ReactComponent as IconInfo } from 'images/icons/info-circle.svg';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import { GMButton } from 'components/Button';

export default ({ props, accounts, loading, deleteLoading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [record2Remove, setRecord2Remove] = useState(null);
  const fetchData = ({ page = 1, perPage = 10, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    dispatch(listAccount(params));
  };

  const columns = [
    {
      title: <b>Staff id</b>,
      align: 'left',
      dataIndex: 'id',
      key: 'id',
      render: (text) => (
        <Link to={`/settings/staff-accounts/${text}/edit`} className="mr-1">
          #{text}
        </Link>
      ),
    },
    {
      title: <b>Staff name</b>,
      dataIndex: 'name',
      align: 'left',
      key: 'name',
      render: (text) => <span>{text}</span>,
    },
    {
      title: <b>Email</b>,
      dataIndex: 'email',
      align: 'left',
      key: 'email',
    },
    {
      title: <b>Status</b>,
      dataIndex: 'status',
      align: 'center',
      key: 'status',
      render: (value) => getOptionLabel(options_active, value),
    },
    {
      title: <b>Joined date</b>,
      dataIndex: 'createdAt',
      align: 'center',
      key: 'createdAt',
      render: (text, record) => getMomentInstance(text, 0, true),
    },
    {
      title: <b></b>,
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <div>
          <MoreActionStore>
            <Dropdown
              placement="topLeft"
              overlay={
                <ContextMenuWrapper className="p-3">
                  <Link
                    to={`/settings/staff-accounts/${record.id}/edit`}
                    className="cursor-pointer"
                  >
                    Edit
                  </Link>
                  <div className="delete cursor-pointer">
                    <div onClick={() => setRecord2Remove(record)}>Delete</div>
                  </div>
                </ContextMenuWrapper>
              }
              trigger={['click']}
            >
              <MoreAction className="context-menu" />
            </Dropdown>
          </MoreActionStore>
        </div>
      ),
    },
  ];
  return (
    <>
      {' '}
      <div className="tab-transaction">
        <Spin spinning={loading || deleteLoading}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={accounts?.data}
            loading={accounts?.loading}
            scroll={{ x: 1000 }}
            pagination={{
              showTotal: (total) => `Total ${total} items`,
              total: accounts?.total,
              pageSize: 10,
              current: accounts?.params.page,
              showSizeChanger: false,
              onChange: (page, perPage) =>
                fetchData({
                  ...accounts.params,
                  page,
                  perPage,
                }),
            }}
          />
        </Spin>
      </div>{' '}
      {record2Remove !== null ? (
        <ConfirmDialog
          ctas={
            <>
              <GMButton
                type="button"
                color="SecondaryGray"
                disabled={deleteLoading}
                onClick={() => setRecord2Remove(null)}
              >
                Cancel
              </GMButton>

              <GMButton
                type="button"
                color="PrimaryError"
                disabled={deleteLoading}
                onClick={() =>
                  dispatch(deleteAccount(record2Remove?.id, () =>
                      setRecord2Remove(null)
                    )
                  )
                }
              >
                Delete
              </GMButton>
            </>
          }
          {...props}
        >
          <ConfirmModalInnerWrapper>
            <div className="icon">
              <div className="wrapper">
                <IconInfo />
              </div>
            </div>
            <div className="content">
              <h4 className="header">Remove this user?</h4>
              <div className="msg">
                This user will be no longer have access to your Gearment
                account.
              </div>
            </div>
          </ConfirmModalInnerWrapper>
        </ConfirmDialog>
      ) : null}
    </>
  );
};
