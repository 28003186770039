import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import { renderInputText, renderSelectAnt } from 'utils/Form';
import { listCountry } from 'scenes/Users/scenes/Profile/actions';
import classnames from 'classnames';
import { GMButton } from 'components/Button';
import { GMTextbox, GMSelect } from 'components/InputFields';
import { Spin } from 'antd';
import GMStateSelector from 'components/GMStateSelector';
const validate = (values) => {
  const errors = {};
  if (!values.email || values.email.replace(/\s+/g, '').length === 0) {
    errors.email = 'Please enter email.';
  }
  // if (!values.phone || values.phone.replace(/\s+/g, '').length === 0) {
  //   errors.phone = 'Please enter the phone';
  // }
  if (!values.firstName || values.firstName.replace(/\s+/g, '').length === 0) {
    errors.firstName = 'Please enter first name.';
  }
  if (!values.lastName || values.lastName.replace(/\s+/g, '').length === 0) {
    errors.lastName = 'Please enter last name.';
  }
  if (!values.address || values.address.replace(/\s+/g, '').length === 0) {
    errors.address = 'Please enter address 1.';
  }
  if (!values.city || values.city.replace(/\s+/g, '').length === 0) {
    errors.city = 'Please enter city.';
  }
  if (!values.state || values.state.replace(/\s+/g, '').length === 0) {
    errors.state = 'Please enter state/province.';
  }
  if (!values.country) {
    errors.country = 'Please enter country.';
  }
  if (
    !values.postalCode ||
    values.postalCode.replace(/\s+/g, '').length === 0
  ) {
    errors.postalCode = 'Please enter zip code/postal code';
  }
  return errors;
};
class index extends Component {
  componentDidMount() {
    this.props.listCountry();
  }
  render() {
    const { handleSubmit, countries, is_mobile, listCustomerConfig } = this.props;
    return (
      <Spin spinning={listCustomerConfig?.loading}>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <GMTextbox
                  name="email"
                  placeholder="Enter email"
                  label="Email"
                  showError
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <GMTextbox
                  name="phone"
                  placeholder="Enter phone number"
                  maxLength={'30'}
                  label={
                    <>
                      Phone number
                      <span className="text-muted"> (Optional)</span>
                    </>
                  }
                  showError
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <GMTextbox
                  name="firstName"
                  placeholder="Enter first name"
                  label="First name"
                  showError
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <GMTextbox
                  name="lastName"
                  placeholder="Enter last name"
                  label="Last name"
                  showError
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <GMTextbox
                  name="address"
                  placeholder="Enter address 1"
                  label="Address 1"
                  showError
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <GMTextbox
                  name="address2"
                  placeholder="Enter address 2"
                  label={
                    <>
                      Address 2<span className="text-muted"> (Optional)</span>
                    </>
                  }
                  showError
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <GMTextbox
                  name="city"
                  placeholder="Enter city"
                  label="City"
                  showError
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                {this.props.country ? (
                  <GMStateSelector
                    name="state"
                    placeholder="Enter state/province"
                    label="State/Province"
                    country={this.props.country}
                    showError
                  />
                ) : (
                  <GMTextbox
                    name="state"
                    maxLength={'50'}
                    placeholder="Enter state/province"
                    label="State/Province"
                    showError
                  />
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <GMTextbox
                  name="postalCode"
                  placeholder="Enter zip code/postal code"
                  label="Zip code/Postal code"
                  showError
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <GMSelect
                  name="country"
                  placeholder="Select a country"
                  label="Country"
                  onChange={() => {
                    this.props.dispatch(change('formInvoiceSettings', 'state', ''));
                  }}
                  options={[
                    { value: 0, label: 'Select a country' },
                    ...countries.data.map((item) => ({
                      value: `${item.countryIsoCode}`,
                      label: item.countryName,
                    })),
                  ]}
                  showError
                />
              </div>
            </div>
          </div>
          <div className="text-right mb-3">
            <GMButton
              className={classnames(
                'btn btn-primary cta-update Rectangle-817',
                {
                  'w-100': is_mobile,
                }
              )}
            >
              Update
            </GMButton>
          </div>
        </form>
      </Spin>
    );
  }
}

const selector = formValueSelector('formInvoiceSettings');
const formInvoiceSettings = reduxForm({
  form: 'formInvoiceSettings',
  validate,
  enableReinitialize: true,
})(index);
export default connect(
  (state) => ({
    countries: state.user.profile.listCountry,
    country: selector(state, 'country') || '',
  }),
  (dispatch) => ({
    listCountry: () => {
      dispatch(listCountry());
    },
  })
)(formInvoiceSettings);
