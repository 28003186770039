import React, {useState, useEffect} from "react";
import {Select} from "antd";

const {Option} = Select;

export default ({
                    input,
                    invalid,
                    error,
                    size,
                    inputProps = {},
                    storesList = [],
                    disabled =  false,
                    defaultValue = null,
                }) => {
    const [keyword, setKeyword] = useState();
    const [options, setOptions] = useState([]);
    // useEffect(() => {
    //   setOptions([{ label: keyword, value: keyword }]);
    // }, [keyword]);

    useEffect(() => {
        if (storesList.length > options.length && storesList.length > 0) {
            setOptions(storesList);
        }
    }, [storesList]);

    const onSelect = async (data) => {
        input.onChange(data || []);
    };

    return (
        <div className="position-relative">
            <Select disabled={disabled}
                    mode="multiple"
                    value={defaultValue || input.value || []}
                    style={{width: "100%"}}
                    size={size || "large"}
                    onChange={onSelect}
                    onSearch={(k) => {
                        if (k.length > 2 && k !== null) setKeyword(k);
                    }}
                    filterOption={false}
                    showSearch={true}
                    {...inputProps}
            >
                {options.map((item) => {
                    return (
                        <Option key={item.value} value={item.value}>
                            {item.label}
                        </Option>
                    );
                })}
            </Select>
            {invalid && (
                <hr
                    className="border-danger m-0 position-absolute"
                    style={{width: "100%", left: "0%", bottom: "0%"}}
                />
            )}
            {error && <span className="invalid-feedback">{error}</span>}
        </div>
    );
};
