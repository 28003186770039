import { Table } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import money from 'utils/money';

const columns = [
  {
    title: 'Order name',
    align: "left",
    key: "order-name",
    render: (value, record) => {
      return record?.orderName;
    },
  },
  {
    title: 'Customer name',
    align: "left",
    key: "customer-name",
    render: (value, record) => {
      return record?.customerName;
    },
  },
  {
    title: 'Store',
    align: "left",
    key: "store",
    render: (value, record) => {
      return (
        <span className="bolder">{record?.storeName}</span>
      );
    },
  },
  {
    title: 'Reference ID',
    align: "left",
    key: "ref-id",
    render: (value, record) => {
      return record?.orderReference;
    },
  },
  {
    title: 'Price',
    align: "left",
    key: "price",
    render: (value, record) => {
      return (
        <span className="bolder">{money(record?.orderTotal)}</span>
      );
    },
  },
];

export default ({ data, ...props }) => {
  const tableData = Object.values(data?.data?.orders || {});
  if (tableData.length <= 0) {
    return null;
  }
  return (
    <div className="main-table block">
      <h4>Orders ready for payment</h4>
      <Table
        className="order-list"
        columns={columns}
        dataSource={tableData}
        scroll={{ x: 768 }}
        pagination={false}
      />
    </div>
  );
};
