import { 
    takeLatest, 
    call, 
    put, 
    all 
  } from 'redux-saga/effects'
  import {
      DATA_FEED_GOOGLE_DETAIL_REQUEST,
      DATA_FEED_GOOGLE_DETAIL_SUCCESS,
      DATA_FEED_GOOGLE_DETAIL_FAIL,
      DATA_FEED_GOOGLE_EDIT_REQUEST,
      DATA_FEED_GOOGLE_EDIT_SUCCESS,
      DATA_FEED_GOOGLE_EDIT_FAIL,
  } from './constants'
  import { updateGoogle,  getDetail} from 'apis/feed_forms'
  
  function* updateDataFeedGoogleSaga(action) {
      try {
          const { id, data_feed } = action
          const response = yield call(updateGoogle, id, data_feed)
          
          if(response.code === 'success'){
              yield all([
                  put({type: DATA_FEED_GOOGLE_EDIT_SUCCESS, ...response}),
              ])
          }else{
            yield put({type: DATA_FEED_GOOGLE_EDIT_FAIL, error: response})
          }
      } catch (error) {
          yield all([
              put({type: DATA_FEED_GOOGLE_EDIT_FAIL, error})
          ])
      }
  }
  
  
  function* getDetailSaga(action) {
      try {
          const { id } = action
          const response = yield call(getDetail, id)
          
          if(response.code === 'success'){
              yield all([
                  put({type: DATA_FEED_GOOGLE_DETAIL_SUCCESS, ...response}),
              ])
          }else{
            yield put({type: DATA_FEED_GOOGLE_DETAIL_FAIL, response})
          }
      } catch (error) {
          yield all([
              put({type: DATA_FEED_GOOGLE_DETAIL_FAIL, error})
          ])
      }
  }
  
  function* updateDataFeedGoogleWatcher() {
      
      yield all([
          takeLatest(DATA_FEED_GOOGLE_EDIT_REQUEST, updateDataFeedGoogleSaga),
          takeLatest(DATA_FEED_GOOGLE_DETAIL_REQUEST, getDetailSaga)
      ])
  }
  
  export default updateDataFeedGoogleWatcher