import React, { useState } from "react";
import { Popover, Input } from "antd";
import { SketchPicker } from "react-color";

const FieldColorPicker = ({ input }) => {
  const [openPicker, setOpenPicker] = useState(false);
  return (
    <Popover
      content={
        <div>
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={() => setOpenPicker(false)}
          />
          <SketchPicker
            color={input.value}
            onChangeComplete={({ hex }) => input.onChange(hex)}
          />
        </div>
      }
      trigger="click"
      visible={openPicker}
      onVisibleChange={() => setOpenPicker(!openPicker)}
    >
      <Input
        value={input.value}
        addonBefore={
          <div style={{ width: 25 }}>
            <div
              className="position-absolute"
              style={{
                top: -1,
                left: -1,
                right: -1,
                bottom: -1,
                backgroundColor: input.value,
                border: "1px solid #E6E6E6",
              }}
            ></div>
          </div>
        }
      />
      {/* <div className="d-flex align-items-center">
                <div className="mr-3">{input.value}</div>
                <div className="cursor-pointer position-relative border" style={{backgroundColor: input.value, width: 30, height: 30, boxShadow: '0 0 0 2px #efefef inset'}}>
                    <span style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}} onClick={()=>setOpenPicker(true)}></span>
                </div>
            </div> */}
    </Popover>
  );
};

FieldColorPicker.propTypes = {};

export default FieldColorPicker;
