import React from "react";
import { Field } from "redux-form";
import { Divider, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import renderInputText from "./renderInputText";
const renderAmazonForm = ({ fields, meta: { error }, disabled = false }) => {
  return (
    <div>
      <ul className="list-unstyled mb-0">
        {fields.map((custom, index) => (
          <li key={index}>
            <div className="row pb-3">
              <div className="col-12">
                <Divider className="mt-0 mb-3" />
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <div className="w-100">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <Field
                            name={`${custom}.name`}
                            type="text"
                            placeholder="CUSTOM NAME"
                            component={renderInputText}
                            maxLength={"50"}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <Field
                            name={`${custom}.key`}
                            type="text"
                            placeholder="CUSTOM KEY"
                            component={renderInputText}
                            maxLength={"50"}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <Field
                          name={`${custom}.value`}
                          type="text"
                          placeholder="CUSTOM VALUE"
                          component={renderInputText}
                          maxLength={"300"}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {fields.length > 0 && index > 0 ? (
                      <button
                        onClick={() => fields.splice(index, 1)}
                        type="button"
                        className="ml-2 mb-3 btn btn-danger"
                        disabled={disabled}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    ) : (
                      <div className="col-sm-4 ml-3"></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {!disabled && (
        <>
          <Divider className="my-0" />
          <Button
            type="link"
            className={classNames("d-flex align-items-center", {
              "text-info": !error,
              "text-danger": error,
            })}
            onClick={() => fields.push({})}
          >
            <PlusCircleOutlined style={{ fontSize: 20 }} /> Add another field
          </Button>
        </>
      )}
    </div>
  );
};

export default renderAmazonForm;
