import React from "react";
import { Input } from "antd";
import classNames from "classnames";
const renderInputSearch = ({
  input,
  enterButton,
  className,
  placeholder,
  size,
  meta: { error, invalid },
  onSearch,
  disabled,
  onResize,
}) => {
  return (
    <div className="position-relative h-100 ">
      <Input.Search
        value={input.value}
        size={size || "large"}
        className={classNames(className)}
        onChange={(e) => input.onChange(e.target.value)}
        placeholder={placeholder}
        enterButton={enterButton}
        onSearch={onSearch}
        disabled={disabled}
        prefix
      />
      {invalid && (
        <hr
          className="border-danger m-0 position-absolute"
          style={{ width: "90%", left: "5%", top: 39 }}
        />
      )}
      {error && <span className="invalid-feedback">{error}</span>}
    </div>
  );
};

export default renderInputSearch;
