import axios from "utils/request";

export const listColorStockStatus = () => {
  return axios({
    method: "GET",
    url: `/attribute/list-color`,
  });
};

export const listSizeStockStatus = () => {
  return axios({
    method: "GET",
    url: `/attribute/list-size`,
  });
};

export const listProductStockStatus = () => {
  return axios({
    method: "GET",
    url: `/ex-products`,
  });
};

export const exportStockStatus = (data = {}) => {
  const {
    page,
    perPage,
    ...rest
  } = data;
  return axios({
    method: 'GET',
    url: `/variant/stock-status?${new URLSearchParams({
      ...rest,
      export: 1,
    }).toString()}`,
  });
};
