import React, { memo } from 'react';
import Form from './Form';
import { Wrapper } from './styled';

export default memo(({
  placeholder,
  maxLength2Search,
  showFilter,
  className,
  customInputs = null,
  filterCustomInputs = null,
  onSubmit = () => { },
  ...rest
}) => {
  return (
    <Wrapper className={'filter ' + className}>
      <Form
        showFilter={showFilter}
        maxLength2Search={maxLength2Search}
        placeholder={placeholder}
        onSubmit={onSubmit}
        customInputs={customInputs}
        filterCustomInputs={filterCustomInputs}
        {...rest}
      />
    </Wrapper>
  );
});
