import React from "react";
export default (props) => {
  return (
    <div className="dashboard-section guideline-section px-4 py-3 mb-4 h-100">
      <div className="d-flex justify-content-center">
        <h4 className="mb-3">Email</h4>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="text-center text-muted">
          <p>
            No matter how big or small your question is, we'll make sure to get
            back to you.
          </p>
        </div>
        <div>
          <a href="mailto:support@gearment.com">support@gearment.com</a>
        </div>
      </div>
    </div>
  );
};
