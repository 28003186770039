import { notification } from 'antd';
import {
  ISSUE_UPDATE_REQUEST,
  ISSUE_UPDATE_FAIL,
  ISSUE_UPDATE_SUCCESS,
  ISSUE_DETAIL_REQUEST,
  ISSUE_DETAIL_FAIL,
  ISSUE_DETAIL_SUCCESS,
  ISSUE_DETAIL_SET,
} from './constants';
const initialState = {
  update: {
    loading: false,
  },
  detail: {
    loading: false,
    data: {},
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ISSUE_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          loading: true,
        },
      };
    case ISSUE_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          data: action.data,
          loading: false,
        },
      };
    case ISSUE_DETAIL_SET:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          data: action.data,
          loading: false,
        },
      };
    case ISSUE_DETAIL_FAIL:
      return {
        ...state,
        detail: {
          ...state.detail.data?.issue,
          loading: false,
        },
      };

    case ISSUE_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      };
    case ISSUE_UPDATE_SUCCESS:
      notification.success({
        message: 'Update issue successfully.',
        duration: 5,
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
        },
      };
    case ISSUE_UPDATE_FAIL:
      notification.warn({
        message:
          action.error && action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Update issue failed.',
        duration: 5,
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
