import axios from 'utils/request';

const prefix = '/orders';

export const shopify = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/shopify`,
  });
};

export const getProfit = () => {
  return axios({
    method: 'GET',
    url: `orders/get-profit-stats`,
  });
};

export const getOrderAtRisk = () => {
  return axios({
    method: 'GET',
    url: `orders/risk?perPage=9999&page=1`,
  });
};

export const uploadBarcode = (s3Url, originalName) => {
  return axios({
    method: 'POST',
    data: {
      s3Url,
      originalName,
    },
    url: `${prefix}/upload-barcode-v2`,
  });
};

export const uploadLabel = (s3Url, originalName, platform_id) => {
  return axios({
    method: 'POST',
    data: {
      s3Url,
      originalName,
      platform_id,
    },
    url: `${prefix}/upload-barcode-v2`,
  });
};

export const updateShippingLabel = (orderId = 0, barcode = '') => {
  return axios({
    method: 'PUT',
    data: {
      orderId,
      barcode,
    },
    url: `${prefix}/update-shipping-label`,
  });
};

export const getList = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}?with[]=orderExtra&with[]=allShipment&with[]=orderMatch&with[]=orderBarcodeFiles`,
  });
};

export const getListPaymentOrder = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/list-order-to-payment`,
  });
};

export const create = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/create-order`,
    is_json_content_type: true,
  });
};

export const convertOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/convert-order`,
    is_json_content_type: true,
  });
};

export const getDetail = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/${id}?with[]=orderExtend&with[]=orderExtraFee&with[]=orderItems.exProduct&with[]=orderRating&with[]=orderMatch&with[]=orderExtraCharges&with[]=orderExtraCharges.transaction&with[]=removedOrderItems&with[]=orderBarcodeFiles&with[]=orderItems&with[]=storeIntegration&with[]=country&with[]=orderExtra&with[]=orderNote&with[]=allShipment&with[]=allShipment&with[]=orderIssue&with[]=orderDuplicate&with[]=orderDuplicate.order&with[]=originalOrder&with[]=originalOrder.order&with[]=orderItems.size2&with[]=orderItems.color2`,
  });
};

export const findOrderToDuplicateOrderFBA = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/duplicate-fba-order/${id}?with[]=orderBarcodeFiles&with[]=orderItems&with[]=orderItems.exProduct`,
  });
};

export const findOrderToConvertOrderLabel = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/convert-label-order/${id}?with[]=orderBarcodeFiles&with[]=orderItems&with[]=orderItems.exProduct`,
  });
};

export const updateAddress = (id, data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/update-address/${id}`,
  });
};

export const updateGiftMessage = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/save-gift-message`,
    is_json_content_type: true,
  });
};

export const updateShippingService = (id, data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/update-shipping-service/${id}`,
    is_json_content_type: true,
  });
};

export const listNewNotification = () => {
  return axios({
    method: 'GET',
    url: `${prefix}/notifications`,
  });
};

export const listTrackings = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/${id}/shipment?with[]=order`,
  });
};

export const getListOrderAchived = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/archive-order?with[]=orderMatch&with[]=storeIntegration`,
    is_json_content_type: true,
  });
};

export const matchProduct = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/match-product`,
    is_json_content_type: true,
  });
};
export const listOrderToMatchProduct = (data) => {
  return axios({
    method: 'GET',
    params: data,
    url: `${prefix}/list-order-to-match?with[]=orderItems&with[]=orderItems.exProduct&with[]=orderNote&with[]=orderMatch&with[]=orderBarcodeFiles`,
    is_json_content_type: true,
  });
};
export const archiveOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/archive-order`,
    is_json_content_type: true,
  });
};

export const approveOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/approve-order`,
    is_json_content_type: true,
  });
};

export const unapproveOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/unapprove-order`,
    is_json_content_type: true,
  });
};

export const syncTracking = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/sync-tracking`,
    is_json_content_type: true,
  });
};

export const syncTrackingAmazon = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/sync-tracking-amazon`,
    is_json_content_type: true,
  });
};

export const createIssue = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/create-issue`,
    is_json_content_type: true,
  });
};

export const verirfyAddress = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/verify-address`,
    is_json_content_type: true,
  });
};

export const forceVerifiedDelivery = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/force-verified-delivery`,
    is_json_content_type: true,
  });
};

export const paymentOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/payment-order`,
    is_json_content_type: true,
  });
};

export const deleteOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/delete-order`,
    is_json_content_type: true,
  });
};

export const unarchiveOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/unarchive-order`,
    is_json_content_type: true,
  });
};

export const syncOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/sync-order`,
    is_json_content_type: true,
  });
};

export const duplicateOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/duplicate-order`,
    is_json_content_type: true,
  });
};

export const lockOrder = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/lock-order/${id}`,
  });
};

export const listDuplicatedOrder = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/list-duplicated-order/${id}`,
  });
};

export const listOrderToCreateIssue = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/check-order-for-creating-issue`,
    is_json_content_type: true,
  });
};

export const countOrder = (params) => {
  return axios({
    method: 'GET',
    url: `${prefix}/count/v2?archived=0`,
    params,
  });
};

export const countOrderAchived = (params) => {
  return axios({
    method: 'GET',
    url: `${prefix}/count/v2?archived=0`,
    params,
  });
};

export const importOrder = (data, progressHook = () => {}) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/import-order`,
    onUploadProgress: progressHook,
  });
};

// export const exportOrder = (data) => {
//   return axios({
//     method: 'GET',
//     data,
//     url: `${prefix}/export-order-list`,
//   });
// };

export const exportOrder = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}`,
  });
};

export const duplicateFBAOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/duplicate-order-fba`,
    is_json_content_type: true,
  });
};

export const createLableOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/create-label-order`,
    is_json_content_type: true,
  });
};

export const getInvoice = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/${id}/invoice?with[]=orderItems&with[]=orderExtra&with[]=orderExtraFee`,
  });
};

export const exportOrderInvoice = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/${id}/invoice?with[]=orderItems&with[]=orderExtra&with[]=orderExtraFee&isExportPDF=1`,
  });
};

export const previewRender = (data) => {
  return axios({
    method: 'POST',
    url: `${prefix}/preview-render`,
    data,
    is_json_content_type: true,
  });
};

export const previewOriginalImage = (data) => {
  return axios({
    method: 'POST',
    url: `${prefix}/preview-original-design`,
    data,
    is_json_content_type: true,
  });
};

export const getListStockStatus = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `/variant/stock-status`,
  });
};

export const saveMatchProduct = (data) => {
  return axios({
    method: 'POST',
    url: `${prefix}/save-match-product`,
    data,
    is_json_content_type: true,
  });
};

export const deleteDesign = (data) => {
  return axios({
    method: 'POST',
    url: `${prefix}/delete-design`,
    data,
    is_json_content_type: true,
  });
};

export const saveDesign = (data) => {
  return axios({
    method: 'POST',
    url: `${prefix}/save-design`,
    data,
    is_json_content_type: true,
  });
};

export const removeOrderItemMatchProduct = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/remove-item-match-product`,
  });
};

export const assignDesign = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/assign-design`,
    is_json_content_type: true,
  });
};

export const assignDesigns = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/assign-designs`,
    is_json_content_type: true,
  });
};

export const editOrderBarCode = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/edit-order-barcode`,
    is_json_content_type: true,
  });
};

export const editOrderItemBarCode = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/edit-order-item-barcode`,
    is_json_content_type: true,
  });
};
export const getListReportOrder = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/report-product-sell`,
    is_json_content_type: true,
  });
};

export const exportProductSell = (params = {}) => {
  return axios({
    method: 'POST',
    params,
    url: `${prefix}/export-product-sell`,
    is_json_content_type: true,
  });
};

export const checkIfUploadingShippingLabelIsRequired = () => {
  return axios({
    method: 'GET',
    url: `${prefix}/uploading-shipping-label-required`,
  });
};

export const markAsValidAddress = (params = {}) => {
  return axios({
    method: 'PUT',
    params,
    url: `${prefix}/mark-verified-delivery`,
    is_json_content_type: true,
  });
};

export const cancelOrder = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/cancel`,
    is_json_content_type: true,
  });
};

export const checkVariantAtRisk = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/check-variant-risk`,
    is_json_content_type: true,
  });
};
