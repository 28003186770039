export const PRODUCT_UPDATE_PRICE_REQUEST = 'PRODUCT_UPDATE_PRICE_REQUEST'
export const PRODUCT_UPDATE_PRICE_SUCCESS = 'PRODUCT_UPDATE_PRICE_SUCCESS'
export const PRODUCT_UPDATE_PRICE_FAIL = 'PRODUCT_UPDATE_PRICE_FAIL'

export const CAMPAIGN_DETAIL_REQUEST = 'CAMPAIGN_DETAIL_REQUEST'
export const CAMPAIGN_DETAIL_SUCCESS = 'CAMPAIGN_DETAIL_SUCCESS'
export const CAMPAIGN_DETAIL_FAIL = 'CAMPAIGN_DETAIL_FAIL'

export const CAMPAIGN_UPDATE_INFO_REQUEST = "CAMPAIGN_UPDATE_INFO_REQUEST"
export const CAMPAIGN_UPDATE_INFO_SUCCESS = "CAMPAIGN_UPDATE_INFO_SUCCESS"
export const CAMPAIGN_UPDATE_INFO_FAIL = "CAMPAIGN_UPDATE_INFO_FAIL"

export function detailCampaign(id) {
    return {
        type: CAMPAIGN_DETAIL_REQUEST,
        id,
    }
}

export function updateProductPrice(dataToUpdate, id){
    return {
        type: PRODUCT_UPDATE_PRICE_REQUEST,
        dataToUpdate,
        id
    }
}


export function updateCampaignInfo(dataToUpdate, id){
    return {
        type: CAMPAIGN_UPDATE_INFO_REQUEST,
        dataToUpdate,
        id
    }
}