import { GMButton } from "components/Button";
import { PRIMARY_500 } from "components/colors";
import styled from "styled-components";

export const ExtGMButton = styled(GMButton)`
  height: 100%;
  border: 1px solid ${PRIMARY_500};

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  gap: 8px;
`;
