import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validate from './validate';
import { renderSwitchAnt, renderSelectAnt, renderInputText } from 'utils/Form';
import shopbase_logo from 'assets/images/shopbase_logo.png';
import { GMTextbox } from 'components/InputFields';
import { Wrapper } from '../styled';
import Select from 'components/InputFields/Select';
import { GMButton } from 'components/Button';
import { WrapperUpdateButton } from '../../styled';
import { ReactComponent as ShopbaseIcon } from 'images/Logo_store/full/shopbase.svg';
let index = ({
  handleSubmit,
  onCancel,
  accounts,
  currAction,
  profile,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit} className="mx-2">
      <WrapperUpdateButton>
        <div className="text-left my-3">
          <ShopbaseIcon />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Store name</b>
          </label>
          <GMTextbox name="showName" showError />
        </div>
        <div className="form-group iconAfter">
          <label className="col-form-label">
            <b>Merchant</b>
          </label>
          <Wrapper>
            <Field
              className="shopbase"
              name="name"
              component={renderInputText}
              addonAfter=".onshopbase.com"
            />
          </Wrapper>

          {/* <GMTextbox name="name" showError iconAfter=".onshopbase.com" /> */}
          <span className="text-muted">
            Ex: domainname.com. Domain must not contain https or http.
          </span>
        </div>
        {profile.primaryId === 0 && (
          <div className="form-group">
            <label className="col-form-label">
              <b>Staff Account</b>
            </label>
            <Wrapper>
              <Field
                name="staffId"
                component={renderSelectAnt}
                placeholder="-- Select staff --"
                mode={'multiple'}
                options={accounts.map((item) => ({
                  value: item.id.toString(),
                  label: item.name,
                }))}
              />
            </Wrapper>
          </div>
        )}
        <div className="form-group">
          <div className="d-flex align-items-center">
            <GMTextbox name="autoSync" showError component={renderSwitchAnt} />

            <label htmlFor="store_auto_sync" className="ml-3 col-form-label">
              Automatic synchronization when new orders arrive.{' '}
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="d-flex align-items-center">
            <GMTextbox name="status" showError component={renderSwitchAnt} />

            <label htmlFor="store_active" className="ml-3 col-form-label">
              Active store
            </label>
          </div>
        </div>
        <div>
          <GMButton className="saveBtn" size="lg">
            {currAction === 'create' ? 'Connect store' : 'Update store'}
          </GMButton>
        </div>
      </WrapperUpdateButton>
    </form>
  );
};

index = reduxForm({
  form: 'formShopbaseStore',
  enableReinitialize: true,
  validate,
})(index);

export default connect()(index);
