import { GRAY_600 } from 'components/colors';
import styled, { createGlobalStyle } from 'styled-components';
import { Layout } from 'antd';
const { Sider } = Layout;

export const Wrapper = styled.div`
  .notify-icon {
    width: 22px;
    height: 22px;
    stroke: ${GRAY_600};
  }
  .ant-menu-item a::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: black;
  }
  .name {
    color: #344054;
    font-weight: 500;
  }
`;

export const GMMenuSlider = styled(Sider)`
  .ant-layout-sider-trigger {
    left: 295px !important;
    z-index: 11;
  }
  img,
  svg {
    &.collapsed {
      display: none;
    }
    &.expanded {
      display: inline-block;
    }
  }
  &.ant-layout-sider-collapsed {
    img,
    svg {
      &.collapsed {
        display: inline-block;
      }
      &.expanded {
        display: none;
      }
    }
    .ant-layout-sider-trigger {
      left: 60px !important;
    }
  }
`;
export const WraperHeader = styled.div`
  .switchbtn {
    flex-direction: row;
    flex-wrap: nowrap;
    line-height: 1.3;
  }
`;

export const GlobalStyle = createGlobalStyle`
  .profile-menu {
    .profile-name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  // .ant-tooltip-inner {
  //     color: yellow;
  //     background-color: green;
  // }

  // .ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  //     border-bottom-color: green;
  // }
  // .ant-tooltip-content{
  //   color:white;
    
  // }
  // .ant-tooltip-arrow{
  //   color
  // }
`;

export const GMLayout = styled(Layout)`
  display: flex;
  flex-direction: row;
  > .ant-layout {
    /* overflow: hidden; */
    overflow-x: clip;
  }
`;
