import axios from "utils/request";

const prefix = "/userlabel";

export const listUserLable = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}`,
  });
};

export const getDetailUserLable = (id) => {
  return axios({
    method: "GET",
    url: `${prefix}/${id}`,
  });
};

export const searchPlaces = (keyword) => {
  return axios({
    method: "GET",
    url: `${prefix}/search-places/${keyword.replace(" ", "%20")}`
  })
}

export const detailPlace = (placeId) => {
  return axios({
    method: "GET",
    url: `${prefix}/detail-place/${placeId}`
  })
}

export const verifyAddress = (data) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}/verify-address`
  })
}