import { actionType } from './actions';
import { notification } from 'antd';

const initialState = {
  loading: false,
  verifiedStaffToken: {
    tokenValid: null,
    data: [],
  },
};

function VerifyAccountReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.CREATE_STAFF_ACCOUNT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionType.CREATE_STAFF_ACCOUNT.SUCCESS:
      notification.success({
        message: action.clientMsg || 'Create account successfully.',
        duration: 5,
      });
      return {
        ...state,
        loading: false,
      };
    case actionType.CREATE_STAFF_ACCOUNT.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['clientMsg']
            ? action.error[0]['clientMsg']
            : 'Create account failed.',
        duration: 5,
      });
      return {
        ...state,
        loading: false,
      };

    //Verify token staff:
    case actionType.VERIFY_STAFF_TOKEN.REQUEST:
      return {
        ...state,
        loading: true,
        verifiedStaffToken: {
          ...initialState.verifiedStaffToken,
          tokenValid: false,
        },
      };
    case actionType.VERIFY_STAFF_TOKEN.SUCCESS:
      return {
        ...state,
        loading: false,
        verifiedStaffToken: {
          tokenValid: true,
          data: action.data || [],
        },
      };
    case actionType.VERIFY_STAFF_TOKEN.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['clientMsg']
            ? action.error[0]['clientMsg']
            : 'The invitation link is no longer valid. Please ask the person who invited you for a new invitation.',
        duration: 5,
      });
      return {
        ...state,
        loading: false,
        verifiedStaffToken: {
          tokenValid: false,
        },
      };
    default:
      return state;
  }
}

export default VerifyAccountReducer;
