import axios from "utils/request";

export const checkStatus = () => {
  return axios({
    method: "GET",
    url: `/events?type=1&page=1`,
  });
};

export const callUnboxer = () => {
  return axios({
    method: "POST",
    url: `/events?type=1`,
  });
}
