export const actionType = {
    IMPORT_ORDER: {
        REQUEST: 'ORDER.IMPORT.REQUEST',
        SUCCESS: 'ORDER.IMPORT.SUCCESS',
        ERROR: 'ORDER.IMPORT.ERROR',
    },
    EXPORT_ORDER: {
        REQUEST: 'ORDER.EXPORT_ORDER.REQUEST',
        SUCCESS: 'ORDER.EXPORT_ORDER.SUCCESS',
        ERROR: 'ORDER.EXPORT_ORDER.ERROR',
    }
};

export function importOrder(data){
    return {
        type: actionType.IMPORT_ORDER.REQUEST,
        data
    }
}
export function exportOrder(data){
    return {
        type: actionType.EXPORT_ORDER.REQUEST,
        data
    }
}

