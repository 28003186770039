import React, { Component } from 'react';
import { Input, Tooltip } from 'antd';
import classNames from 'classnames'
const formatNumber =(value)=> {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}


class renderSelect extends Component {
  onChange = (e) => {
    const { value } = e.target;
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!Number.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.input.onChange(value);
    }
  }
  onBlur = () => {
    const { input } = this.props;
    if (input.value.charAt(input.value.length - 1) === '.' || input.value === '-') {
      input.onChange({ value: input.value.slice(0, -1) });
    }
  }

  render() {
    const {
      className,
      size= "large",
      input,
      placeholder,
      meta: { invalid, error },
    } = this.props

    const title = input.value ? (
      <span className="numeric-input-title">
        {input.value !== '-' ? formatNumber(input.value) : '-'}
      </span>
    ) : placeholder;

    
    return (
      <div className="position-relative">
        <Tooltip
          trigger={['focus']}
          title={title}
          placement="topLeft"
          overlayClassName="numeric-input"
        >
          <Input
            value={input.value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            placeholder={placeholder}
            maxLength={25}
            size={size}
            className={classNames(className)}
          />
        </Tooltip>
        {invalid&&<hr className="border-danger m-0 position-absolute" style={{width: '90%', left: '5%', top: 39}}/>}
        {error && <span className="invalid-feedback">{error}</span>}
      </div>
    );
  }
}

export default renderSelect;