export default (input, show_unit = '$') => {
    let unit = show_unit ? ' ' + show_unit : ''
    let value = input + '';
    const sepos = value?.indexOf('.');
    if (sepos === -1) {
        value += '.00';
    } else if (value.slice(sepos).length === 2) {
        value += '0';
    }
    value = value.replace(/,/g, '')
    const reg = /^-?[0-9,]*(\.[0-9]*)?$/;
    if (!isNaN(value) && reg.test(value)) {
        const list = (Math.round(value * 10000) / 10000 + '').split('.');
        const prefix = list[0].charAt(0) === '-' ? '-' : '';
        let num = prefix ? list[0].slice(1) : list[0];
        let result = '';
        while (num.length > 3) {
            result = `,${num.slice(-3)}${result}`;
            num = num.slice(0, num.length - 3);
        }
        if (num) {
            result = num + result;
        }
        return `${prefix}${unit}${result}${list[1] ? `.${list[1]}` : ''}`;
    }

    return input || ''
}