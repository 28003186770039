const { API_TOKEN_KEY } = require("web.config");
const { getCookie } = require("./helper");

let currentToken = getCookie(API_TOKEN_KEY) + '';

const tokenWatcher = () => {
  const newToken = getCookie(API_TOKEN_KEY) + '';
  if (currentToken.length === 0) {
    currentToken = getCookie(API_TOKEN_KEY);
  }
  if (currentToken.length > 0) {
    if (newToken?.length > 0 && currentToken?.length > 0 && newToken.indexOf(currentToken) !== 0) {
      window.location = window.location;
    } else if (window.location.pathname === '/auth/login') {
      window.location = '/';
    }
  }
  if (window.debugTick) {
    console.log(`currentToken: [${currentToken}] | newToken: [${newToken}]`, newToken.indexOf(currentToken));
  }
  setTimeout(tokenWatcher, 1000);
};

export default tokenWatcher;