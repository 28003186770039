/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Wrapper } from './styled';
import ErrorTable from './component/ErrorTable';
import ProductsInformation from './component/ProductsInformation';
import ActionBlock from './component/ActionBlock';
import MainTable from './component/MainTable';
import { Spin } from 'antd';
import { getStore, setStore } from 'utils/helper';
import { setPaymentOrderData } from '../List/actions';
import { useEffect } from 'react';
import { replace } from 'connected-react-router';
import { loadPrimaryAccountAction } from 'scenes/BillingPaymentAccount/actions';

export const STORAGE_NAME = 'PaymentOrder_data';

export default ({
  pageName,
  ...props
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(s => s.order?.list?.paymentOrder?.loading);
  const dataStore = getStore(STORAGE_NAME);
  const sdata = useSelector(s => s?.order?.list?.orderPaymentData?.list);
  const data = Object.keys(sdata || {}).length <= 0 ? dataStore : sdata;
  const primaryBillingPaymentAccount = useSelector((s) => s.billingPaymentAccount?.primary);
  useEffect(() => {
    if (Object.keys(sdata || {}).length <= 0 && dataStore) {
      dispatch(setPaymentOrderData(dataStore));
    } else {
      setStore(STORAGE_NAME, data);
    }
  }, [sdata]);
  useEffect(() => {
    if (!data || Object.keys(data || {}).length <= 0) {
      dispatch(replace('/orders/list'));
    }
  }, [data]);
  useEffect(() => {
    dispatch(loadPrimaryAccountAction());
  }, []);
  return (
    <Spin
      spinning={isLoading}
    >
      <Wrapper
        pageName={pageName}
        noContainer
      >
        <div className="inner-wrapper">
          <div className="col-left">
            <MainTable
              data={data}
            />
            <ErrorTable
              data={data}
            />
          </div>
          <div className="col-right">
            <ProductsInformation
              primaryBillingPaymentAccount={primaryBillingPaymentAccount}
              data={data}
            />
            <ActionBlock data={data} />
          </div>
        </div>
      </Wrapper>
    </Spin>
  );
};
