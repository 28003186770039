import { BASE_WHITE, GRAY_100, GRAY_300, GRAY_600 } from 'components/colors';
import styled, { createGlobalStyle } from 'styled-components';

export const Wrapper = styled.div`
  max-width: 860px;
  max-height: 3508px;
  border: ${GRAY_100} solid 1px;
  background-color: ${BASE_WHITE};
  display: flex;
  flex-direction: column;
  padding: 60px;
  margin: 0 auto;
  font-size: 12pt;
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 0.1pt;
  .title {
    font-weight: 600;
    font-size: 30px;
    display: flex;
    justify-content: center;
  }
  .desc {
  }
  .infor {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    .item {
      margin-right: 12px;
      font-weight: 600;
      min-width: 40%;
      display: flex;
      justify-content: flex-end;
    }
  }
  canvas {
    border: 1px solid ${GRAY_300};
  }
`;
export const GlobalStyle = createGlobalStyle`
.ant-modal-content{
  border-radius: 12px;
}
.ant-modal-header{
  border-radius: 12px;
  border-bottom: none;

}
.ant-modal-title{
  font-size: 18px;
  font-weight: 600;
}
.ant-modal-footer{
  border-radius: 12px;
  border-top: none;
}
.ant-btn{
border-radius: 8px;
}
.ant-modal-body{
  color:${GRAY_600};
  font-weight: 400;
  margin-top:-15px;
}
.btn-close{
  min-width: 352px;
}
`;
