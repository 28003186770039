export const actionType = {
  LIST: {
    REQUEST: "PROMOTIONS.LIST.LIST.REQUEST",
    SUCCESS: "PROMOTIONS.LIST.LIST.SUCCESS",
    ERROR: "PROMOTIONS.LIST.LIST.ERROR",
  },
  DELETE: {
    REQUEST: "PROMOTIONS.DELETE.REQUEST",
    SUCCESS: "PROMOTIONS.DELETE.SUCCESS",
    ERROR: "PROMOTIONS.DELETE.ERROR",
  }
};

export function getList(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}
export function deletePromotion(id) {
  return {
    type: actionType.DELETE.REQUEST,
    id,
  };
}
