import React from 'react';
import classNames from 'classnames';
import { Tag } from 'antd';
import shopify_logo from 'images/logo_shopify.svg';
import wordpress_logo from 'images/Logo_store/wooicon.svg';
import order_desk_logo from 'images/logo-orderdesk.svg';
import shopbase_logo from 'images/logo-shopbase.svg';
import amazon_logo from 'images/logo-amazon.svg';
import etsy_logo_store from 'images/Etsy_logo.svg';
import ebay_logo from 'images/logo-ebay.svg';
import gearment_logo from 'images/logo-gearment-1.svg';
import big_commerce_logo from 'images/bigcommerce.jpg';
import inkgo_logo from 'images/inkgo.png';
import wish_logo from 'images/wishlogo.png';
import pretashop_logo from 'images/pretashop.png';
import etsy_logo from 'images/etsy.jpg';
import GWalletLogo from 'images/gwallet.png';
import PayoneerLogo from 'images/small-payoneer.png';
import PaypalLogo from 'images/small-paypal.png';
import { ReactComponent as GearmentApiIcon } from 'images/Logo_store/full/gearment-api.svg';
import TiktokLabel from 'images/OrderLabel/tiktok.png';

import brandSMPresta from 'images/Logo_store/brand-sm/presta-shop.svg';
import brandSMShopify from 'images/Logo_store/brand-sm/shopify.svg';
import brandSMInkgo from 'images/Logo_store/brand-sm/inkgo.svg';
import brandSMWish from 'images/Logo_store/brand-sm/wish.svg';
import brandSMWoo from 'images/Logo_store/brand-sm/woo.svg';
import brandSMAmazon from 'images/Logo_store/brand-sm/amazon.svg';
import brandSMBigcommerce from 'images/Logo_store/brand-sm/bigcommerce.svg';
import brandSMEbay from 'images/Logo_store/brand-sm/ebay.svg';
import brandSMEtsy from 'images/Logo_store/brand-sm/etsy.svg';
import brandSMGearmentWColor from 'images/Logo_store/brand-sm/gearment-color.svg';

import { GMBadge } from 'components/Badge';

import { ReactComponent as AmazonLabel } from 'images/OrderLabel/AmazonLabel.svg';
import { ReactComponent as EbayLabel } from 'images/OrderLabel/EbayLabel.svg';
import { ReactComponent as EtsyLabel } from 'images/OrderLabel/EtsyLabel.svg';
import { ReactComponent as PoshmarkLabel } from 'images/OrderLabel/PoshmarkLabel.svg';
import { ReactComponent as GearmentLabel } from 'images/logo-gearment-1.svg';
import { ReactComponent as ShopbaseLabel } from 'images/OrderLabel/AmazonLabel.svg';
import { ReactComponent as WooLabel } from 'images/Logo_store/full/woo.svg';
import { ReactComponent as ShopifyLabel } from 'images/logo_shopify.svg';
import { ReactComponent as OrderDeskLabel } from 'images/logo-orderdesk.svg';

import shopify_logo2 from 'images/Logo_store/full/shopify.svg';
import amazon_logo2 from 'images/Logo_store/full/amazon.svg';
import woo_logo2 from 'images/Logo_store/full/woo.svg';

import { ReactComponent as PaypalLogoSVG } from 'images/logo_payment/md-PayPal.svg';
import { ReactComponent as PayoneerLogoSVG } from 'images/logo_payment/md-Payoneer.svg';
import {
  BLUE_100,
  BLUE_50,
  BLUE_700,
  ERROR_100,
  ERROR_50,
  ERROR_600,
  ERROR_700,
  GRAY_100,
  GRAY_200,
  GRAY_50,
  GRAY_600,
  GRAY_700,
  PRIMARY_100,
  PRIMARY_50,
  PRIMARY_600,
  PRIMARY_700,
  SUCCESS_100,
  SUCCESS_50,
  SUCCESS_600,
  SUCCESS_700,
  WARNING_100,
  WARNING_50,
  WARNING_700,
} from 'components/colors';

// import { ReactComponent as GearmentLogoSVG } from 'images/logo_payment/md-Gearment.svg';
import pathGearmentLogoSVG from 'images/logo_payment/gwallet_sz32.svg';
// import { ReactComponent as GWalletIcon } from 'images/logo_payment/gwallet_sz32.svg';

export const option_status_tracking = [
  {
    value: 'unknown',
    label: <span>Unknown</span>,
  },
  {
    value: 'pre_transit',
    label: <span>Pre-transit</span>,
  },
  {
    value: 'in_transit',
    label: <span>In-transit</span>,
  },
  {
    value: 'out_of_delivery',
    label: <span>Out of delivery</span>,
  },
  {
    value: 'failure',
    label: <span>Failure</span>,
  },
  {
    value: 'delivered',
    label: <span>Delivered</span>,
  },
  {
    value: 'all',
    label: <span>All</span>,
  },
];
export const option_platform_tracking = [
  {
    value: 'DHLGlobalMail',
    label: <span>DHL Global Mail</span>,
  },
  {
    value: 'DHLGlobalmailInternational',
    label: <span>DHL Global Mail International</span>,
  },
  {
    value: 'USPS',
    label: <span>USPS</span>,
  },
  {
    value: 'all',
    label: <span>All carrier</span>,
  },
];
export const option_platform_stores = [
  {
    value: 'all',
    label: <span>All</span>,
  },
  {
    value: 'shopify',
    label: <span>Shopify</span>,
  },
  {
    value: 'amazon',
    label: <span>Amazon</span>,
  },
  {
    value: 'wordpress',
    label: <span>Woocommerce</span>,
  },
  {
    value: 'shopbase',
    label: <span>Shopbase</span>,
  },
  {
    value: 'orderdesk',
    label: <span>Order Desk</span>,
  },
  {
    value: 'ebay',
    label: <span>Ebay</span>,
  },
  {
    value: 'etsy',
    label: <span>Etsy</span>,
  },
  {
    value: 'store_default',
    label: <span>Gearment</span>,
  },
  // {
  //   value: 'platform',
  //   label: <span>Platform</span>,
  // },
];
export const option_status_stores = [
  {
    value: 2,
    label: <span>All</span>,
  },
  {
    value: 1,
    label: <span>Active</span>,
  },
  {
    value: 0,
    label: <span>Inactive</span>,
  },
];
export const options_notification_status = [
  {
    value: 1,
    type_color: 'success',
    short_label: 'Read',
    label: (
      <span>
        <b>Read</b>
      </span>
    ),
  },
  {
    value: 0,
    type_color: 'warning',
    short_label: 'New',
    label: (
      <span>
        <b>New</b>
      </span>
    ),
  },
];
export const listValidImportFile = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'text/csv',
];
export const options_store = [
  {
    label: 'Apply return address default',
    note:
      '* If you choose this option, this return address info will be automatically applied to stores in which return address info is unavailable.',
    value: 'yes',
  },
  { label: 'Apply return address to store', value: 'no' },
];
export const options_ebay = [
  {
    value: '0',
    label: (
      <div>
        <b>US</b>
      </div>
    ),
  },
  {
    value: '2',
    label: (
      <div>
        <b>Canada</b>
      </div>
    ),
  },
  {
    value: '3',
    label: (
      <div>
        <b>UK</b>
      </div>
    ),
  },
  {
    value: '15',
    label: (
      <div>
        <b>Australia</b>
      </div>
    ),
  },
];
export const email_status = [
  {
    value: 'yes',
    type_color: 'success',
    short_label: 'Sent',
    label: (
      <span>
        Status: <b>Sent</b>
      </span>
    ),
  },
  {
    value: 'no',
    type_color: 'error',
    short_label: 'Unsent',
    label: (
      <span>
        Status: <b>Unsent</b>
      </span>
    ),
  },
];
export const options_active = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Deactivated
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Deactivated</b>
      </span>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={PRIMARY_50}
        color={PRIMARY_700}
        noDot={true}
        backgroundColor={PRIMARY_50}
      >
        Activated
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Activated</b>
      </span>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Deactivated
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Deactivated</b>
      </span>
    ),
  },
];

export const options_active_invitation_staff = [
  {
    value: 'Expired',
    short_label: (
      <GMBadge
        border={ERROR_50}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Expired
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Expired</b>
      </span>
    ),
  },
  {
    value: 'Pending',
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Pending
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Pending</b>
      </span>
    ),
  },
];

export const options_active_webhooks = [
  {
    value: 'active',
    short_label: (
      <GMBadge
        border={SUCCESS_50}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Active
      </GMBadge>
    ),
  },
  {
    value: 'deactive',
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Deactive
      </GMBadge>
    ),
  },
];
export const options_gift_message = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={ERROR_50}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Deactivated
      </GMBadge>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={SUCCESS_50}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Activated
      </GMBadge>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Disabled
      </GMBadge>
    ),
  },
];
export const options_delivery = [
  {
    value: '1',
    short_label: 'Order Completed',
    label: <span>Order Completed</span>,
  },
  {
    value: '2',
    short_label: 'Order Canceled',
    label: <span>Order Canceled</span>,
  },
  {
    value: '3',
    short_label: 'Order un-verify address',
    label: <span>Order un-verify address</span>,
  },
  {
    value: '4',
    short_label: 'Tracking_updated',
    label: <span>Update Tracking</span>,
  },
  {
    value: '5',
    short_label: 'shipping_address_unverified',
    label: <span>Shipping Address Unverified</span>,
  },
  {
    value: '6',
    short_label: 'shipping_address_verified',
    label: <span>Shipping Address Verified</span>,
  },
];

export const options_campaign_status = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={GRAY_50}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Ended
      </GMBadge>
    ),
    label: <span>Ended</span>,
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={SUCCESS_50}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Active
      </GMBadge>
    ),
    label: <span>Active</span>,
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={WARNING_50}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Declined
      </GMBadge>
    ),
    label: <span>Declined</span>,
  },
  {
    value: 3,
    short_label: (
      <GMBadge
        border={GRAY_50}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Processing
      </GMBadge>
    ),
    label: <span>Processing</span>,
  },
];

export const options_campaign_type_group = [
  {
    value: 1,
    short_label: 'Combine',
    label: <span>Combine</span>,
  },
  {
    value: 2,
    short_label: 'Split',
    label: <span>Split</span>,
  },
];
export const options_campaign_append_product_type = [
  {
    value: 0,
    short_label: 'No',
    label: (
      <span>
        Append product type: <b>No</b>
      </span>
    ),
  },
  {
    value: 1,
    short_label: 'Yes',
    label: (
      <span>
        Append product type: <b>Yes</b>
      </span>
    ),
  },
];

export const options_bulk = [
  {
    label: (
      <span className="font-size-14">
        Auto-generate campaign titles and urls based on file names.
      </span>
    ),
    value: 0,
  },
  {
    label: (
      <span className="font-size-14">
        If the title duplicated, a number will be expand at the suffix.
      </span>
    ),
    value: 1,
  },
  {
    label: (
      <span className="font-size-14">
        Include a prefix for the generated url paths.
      </span>
    ),
    value: 2,
  },
];

export const options_product_type = [
  {
    value: '0',
    short_label: 'Product for custom domain',
    label: 'Product for custom domain',
  },
  { value: '1', short_label: 'Product for sell', label: 'Product for sell' },
];
export const options_product_grouping = [
  {
    value: '1',
    short_label: 'Combine Gearment products into a single product',
    label: 'Combine Gearment products into a single product',
  },
  {
    value: '2',
    short_label: 'Split Gearment products into individual products',
    label: 'Split Gearment products into individual products',
  },
];
export const options_condition = [
  {
    value: 'equals',
    short_label: 'is qual to',
    label: (
      <span>
        <span>is qual to</span>
      </span>
    ),
  },
  {
    value: 'not_equals',
    short_label: 'is not equal to',
    label: (
      <span>
        <span>is not equal to</span>
      </span>
    ),
  },
  {
    value: 'starts_with',
    short_label: 'starts with',
    label: (
      <span>
        <span>starts with</span>
      </span>
    ),
  },
  {
    value: 'end_with',
    short_label: ' ends with',
    label: (
      <span>
        <span> ends with</span>
      </span>
    ),
  },
  {
    value: 'contains',
    short_label: 'contains',
    label: (
      <span>
        <span>contains</span>
      </span>
    ),
  },
  {
    value: 'not_contains',
    short_label: 'not contains',
    label: (
      <span>
        <span></span>
      </span>
    ),
  },
];

export const options_condition_field = [
  {
    value: 'title',
    short_label: 'Product title',
    label: (
      <span>
        <span>Product title</span>
      </span>
    ),
  },
  {
    value: 'tag',
    short_label: 'Product tag',
    label: (
      <span>
        <span>Product tag</span>
      </span>
    ),
  },
];

export const options_platform_type = [
  {
    value: 1,
    short_label: (
      <img
        src="/images/shopify_logo.png"
        width="100"
        alt="shopify logo"
        className="branch-logo"
      />
    ),
    label: (
      <span>
        <span>Shopify</span>
      </span>
    ),
  },
  {
    value: '15',
    short_label: (
      <img src={etsy_logo} width="70" alt="esty logo" className="branch-logo" />
    ),
    label: (
      <span>
        <span>Esty</span>
      </span>
    ),
  },
  {
    value: 5,
    short_label: (
      <img
        src="/images/shopbase_logo.png"
        width="100"
        alt="shopbase logo"
        className="branch-logo"
      />
    ),
    label: (
      <span>
        <span>Shopbase</span>
      </span>
    ),
  },
  {
    value: '3',
    short_label: (
      <img
        src="/images/amazon_logo.png"
        width="100"
        alt="amazon logo"
        className="branch-logo"
      />
    ),
    label: (
      <span>
        <span>Amazon</span>
      </span>
    ),
  },
  {
    value: '4',
    short_label: 'Google',
    label: (
      <span>
        <span>Google</span>
      </span>
    ),
  },
];

export const options_platform_type_issue = [
  {
    value: 'import',
    short_label: 'import',
    type_color: 'default',
    import: 1,
    label: (
      <span>
        <span>Import</span>
      </span>
    ),
  },
  {
    value: 1,
    import: 0,
    short_label: 'Shopify',
    type_color: 'default',
    label: (
      <span>
        <span>Shopify</span>
      </span>
    ),
  },
  {
    value: 2,
    short_label: 'API',
    type_color: 'default',
    label: (
      <span>
        <span>API</span>
      </span>
    ),
  },
  {
    value: 3,
    short_label: 'Amazon',
    type_color: 'default',
    label: (
      <span>
        <span>Amazon</span>
      </span>
    ),
  },
  {
    value: 4,
    short_label: 'Duplicate',
    type_color: 'default',
    label: (
      <span>
        <span>Duplicate</span>
      </span>
    ),
  },
  {
    value: 5,
    short_label: 'Shopbase',
    type_color: 'default',
    label: (
      <span>
        <span>Shopbase</span>
      </span>
    ),
  },
  {
    value: 7,
    short_label: 'Manual',
    type_color: 'default',
    label: (
      <span>
        <span>Manual</span>
      </span>
    ),
  },
  {
    value: 8,
    short_label: 'OrderDesk',
    type_color: 'default',
    label: (
      <span>
        <span>OrderDesk</span>
      </span>
    ),
  },
  {
    value: 9,
    short_label: 'FBA',
    type_color: 'default',
    label: (
      <span>
        <span>FBA</span>
      </span>
    ),
  },
  {
    value: 14,
    short_label: 'Ebay',
    type_color: 'default',
    label: (
      <span>
        <span>Ebay</span>
      </span>
    ),
  },
  {
    value: 15,
    short_label: 'Etsy',
    type_color: 'default',
    label: (
      <span>
        <span>Etsy</span>
      </span>
    ),
  },
  {
    value: 17,
    short_label: 'Label',
    type_color: 'default',
    label: (
      <span>
        <span>Label</span>
      </span>
    ),
  },
];

export const options_datafeed_platform_type = [
  {
    value: '3',
    short_label: 'Amazon',
    label: (
      <span>
        <span>Amazon</span>
      </span>
    ),
  },
  {
    value: '4',
    short_label: 'Google',
    label: (
      <span>
        <span>Google</span>
      </span>
    ),
  },
];
export const options_backend_type = [
  {
    value: 'amazon',
    short_label: (
      <img src={amazon_logo2} width="100" height="30" alt="Amazon logo" />
    ),
    label: <img src={amazon_logo2} width="100" height="30" alt="Amazon logo" />,
    name: 'Amazon',
  },
  {
    value: 'ebay',
    short_label: (
      <img src={ebay_logo} width="100" height="30" alt="ebay logo" />
    ),
    label: <img src={ebay_logo} width="100" height="30" alt="ebay logo" />,
    name: 'Ebay',
  },
  {
    value: 'shopify',
    short_label: <img src={shopify_logo2} height="30" alt="Shopify logo" />,
    label: <img src={shopify_logo2} height="30" alt="Shopify logo" />,
    name: 'Shopify',
  },

  {
    value: 'wordpress',
    short_label: <img src={woo_logo2} height="30" alt="Woo logo" />,
    label: <img src={woo_logo2} height="30" alt="Woo logo" />,
    name: 'Wordpress',
  },
  {
    value: 'etsy',
    short_label: <img src={etsy_logo} height="30" alt="Etsy logo" />,
    label: <img src={etsy_logo} height="30" alt="Etsy logo" />,
    name: 'Etsy',
  },
  {
    value: 'shopbase',
    short_label: (
      <img src={shopbase_logo} width="100" height="30" alt="shopbase logo" />
    ),
    label: (
      <img src={shopbase_logo} width="100" height="30" alt="shopbase logo" />
    ),
    name: 'Shopbase',
  },
  {
    value: 'store_default',
    short_label: (
      <img src={gearment_logo} width="100" height="30" alt="gearment logo" />
    ),
    label: (
      <img src={gearment_logo} width="100" height="30" alt="gearment logo" />
    ),
    name: 'Gearment',
  },
  {
    value: 'gearment-api',
    short_label: <GearmentApiIcon width="100" height="30" />,
    label: <GearmentApiIcon width="100" height="30" />,
    name: 'Gearment API',
  },
  // {
  //   value: 'platform',
  //   short_label: (
  //     <img src={platform_logo} width="100" height="30" alt="platform logo" />
  //   ),
  //   label: (
  //     <img src={platform_logo} width="100" height="30" alt="platform logo" />
  //   ),
  //   name: 'Platform',
  // },
  {
    value: 'orderdesk',
    short_label: (
      <img src={order_desk_logo} width="100" height="30" alt="shopify-logo" />
    ),
    label: (
      <img src={order_desk_logo} width="100" height="30" alt="shopify-logo" />
    ),
    name: 'Order Desk',
  },
];
export const listPlatformKeyToDisableAction = ['platform'];
export const methodShipping = [
  {
    value: 0,
    short_label: 'Standard',
    label: <span>Standard</span>,
  },
  { value: 1, short_label: 'Fastship' },
  { value: 2, short_label: 'Ground' },
];
export const priorityIssue = [
  {
    value: 0,
    short_label: 'Low',
    type_color: 'default',
    label: <span>Standard</span>,
  },
  {
    value: 1,
    type_color: 'processing',
    short_label: 'Medium',
    label: <span>Medium</span>,
  },
  {
    value: 2,
    type_color: 'warning',
    short_label: 'High',
    label: <span>High</span>,
  },
];
export const options_schedule_campaign_status = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Pending
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Pending</b>
      </span>
    ),
  },
  {
    value: -1,
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Pending
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Pending</b>
      </span>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Processing
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Processing</b>
      </span>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={SUCCESS_100}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Completed
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Completed</b>
      </span>
    ),
  },
];
export const last_sync_store = [
  {
    value: '0',
    type_color: 'default',
    short_label: <b>Pending</b>,
    label: <span>Pending</span>,
  },
  {
    value: '1',
    type_color: '',
    short_label: <b></b>,
    label: <span></span>,
  },
  {
    value: '2',
    type_color: '',
    short_label: <b></b>,
    label: <span></span>,
  },
  {
    value: '3',
    type_color: 'default',
    short_label: <b>Processing</b>,
    label: (
      <span>
        <b>Processing</b>
      </span>
    ),
  },
  {
    value: '4',
    type_color: 'default',
    short_label: <b>Completed</b>,
    label: (
      <span>
        <b>Completed</b>
      </span>
    ),
  },
  // {
  //   value: "5",
  //   type_color: "default",
  //   short_label: <b>Completed</b>,
  //   label: (
  //     <span>
  //       <b>Completed</b>
  //     </span>
  //   ),
  // },
];
export const options_order_issue_status = [
  {
    value: -1,
    type_color: 'default',
    text_label: 'All',
    short_label: <b>All</b>,
    label: <span>All</span>,
  },
  {
    value: 0,
    type_color: 'default',
    text_label: 'Opening',
    short_label: <b>Opening</b>,
    label: <span>Opening</span>,
  },
  {
    value: 1,
    type_color: 'processing',
    text_label: 'Processing',
    short_label: <b>Processing</b>,
    label: <span>Processing</span>,
  },
  {
    value: 2,
    type_color: 'warning',
    text_label: 'On-hold',
    short_label: <b>On-hold</b>,
    label: <span>On-hold</span>,
  },
  {
    value: 3,
    type_color: 'success',
    text_label: 'Completed',
    short_label: <b>Completed</b>,
    label: <span>Completed</span>,
  },
];

export const issue_solution_options = [
  { value: 1, label: 'Replace' },
  { value: 2, label: 'Refund' },
];

export const issue_priority_options = [
  { value: 0, label: 'Low' },
  { value: 1, label: 'Medium' },
  { value: 2, label: 'High' },
];

export const options_order_status = [
  {
    value: '0',
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Pending
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Pending</b>
      </span>
    ),
  },
  {
    value: '1',
    short_label: (
      <GMBadge
        border={PRIMARY_100}
        color={PRIMARY_700}
        noDot={true}
        backgroundColor={PRIMARY_50}
      >
        In-Production
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>In-Production</b>
      </span>
    ),
  },
  {
    value: '2',
    short_label: (
      <GMBadge
        border={SUCCESS_100}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Done
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Done</b>
      </span>
    ),
  },
  {
    value: '3',
    short_label: (
      <GMBadge
        border={ERROR_100}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Canceled
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Canceled</b>
      </span>
    ),
  },
  {
    value: '4',
    type_color: 'warning',
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Renewing
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Renewing</b>
      </span>
    ),
  },
];

export const export_product_status = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Pending
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Pending</b>
      </span>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Processing
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Processing</b>
      </span>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={SUCCESS_100}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Completed
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Completed</b>
      </span>
    ),
  },
  {
    value: 3,
    short_label: (
      <GMBadge
        border={ERROR_100}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Failed
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Failed</b>
      </span>
    ),
  },
];

export const export_product_status_email = [
  {
    value: 1,
    short_label: (
      <GMBadge
        border={SUCCESS_100}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Sent
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Sent</b>
      </span>
    ),
  },
  {
    value: 0,
    short_label: (
      <GMBadge
        border={ERROR_100}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Unsend
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Unsend</b>
      </span>
    ),
  },
];
export const options_mail_status = [
  {
    value: '1',
    type_color: 'success',
    short_label: <b>Send</b>,
    label: (
      <span>
        Status: <b>Send</b>
      </span>
    ),
  },
  {
    value: '0',
    type_color: 'error',
    short_label: <b>Unsend</b>,
    label: (
      <span>
        Status: <b>Unsend</b>
      </span>
    ),
  },
];
export const options_report_manually_sync_tracking = [
  {
    value: -1,
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Not syncing
      </GMBadge>
    ),

    label: (
      <span>
        Status: <b>Not syncing</b>
      </span>
    ),
  },
  {
    value: 0,
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Pending
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Pending</b>
      </span>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Processing
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Processing</b>
      </span>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={SUCCESS_100}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Completed
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Completed</b>
      </span>
    ),
  },
  {
    value: 3,
    short_label: (
      <GMBadge
        border={ERROR_100}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Failed
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Failed</b>
      </span>
    ),
  },
  {
    value: 4,
    short_label: (
      <GMBadge
        border={BLUE_100}
        color={BLUE_700}
        noDot={true}
        backgroundColor={BLUE_50}
      >
        Confirm shipment
      </GMBadge>
    ),
    label: (
      <span>
        Status: <b>Confirm shipment</b>
      </span>
    ),
  },
];
export const options_inkgo_group = [
  {
    value: 'customer',
    short_label: 'Sellers',
    label: (
      <span>
        Group: <b>Sellers</b>
      </span>
    ),
  },
  {
    value: 'product',
    short_label: 'Products',
    label: (
      <span>
        Group: <b>Products</b>
      </span>
    ),
  },
];

export const options_inkgo_type = [
  {
    value: '0',
    short_label: 'Apparel',
    label: (
      <span>
        Type: <b>Apparel</b>
      </span>
    ),
  },
  {
    value: '1',
    short_label: 'Mug',
    label: (
      <span>
        Type: <b>Mug</b>
      </span>
    ),
  },
  {
    value: '2',
    short_label: 'Poster',
    label: (
      <span>
        Type: <b>Poster</b>
      </span>
    ),
  },
  {
    value: '3',
    short_label: 'Face mask',
    label: (
      <span>
        Type: <b>Face mask</b>
      </span>
    ),
  },
];

export const options_rating = [
  {
    value: '0',
    short_label: '1/5',
    label: (
      <span>
        <b>1</b>/5
      </span>
    ),
  },
  {
    value: '1',
    short_label: '2/5',
    label: (
      <span>
        <b>2</b>/5
      </span>
    ),
  },
  {
    value: '2',
    short_label: '3/5',
    label: (
      <span>
        <b>3</b>/5
      </span>
    ),
  },
  {
    value: '3',
    short_label: '4/5',
    label: (
      <span>
        <b>4</b>/5
      </span>
    ),
  },
  {
    value: '4',
    short_label: '5/5',
    label: (
      <span>
        <b>5</b>/5
      </span>
    ),
  },
];

export const options_order_status_matched = [
  {
    value: '1',
    type_color: 'primary',
    short_label: 'Matching',
    label: <span>Matching product</span>,
  },
  {
    value: '2',
    type_color: 'warning',
    short_label: 'Non-matching',
    label: <span>Non-matching product</span>,
  },
  {
    value: '-1',
    type_color: 'primary',
    short_label: 'All',
    label: <span>All</span>,
  },
];
export const options_matched_insert = [
  {
    value: '0',
    type_color: 'primary',

    short_label: 'Sync',
    label: <span>Sync</span>,
  },
  {
    value: '1',
    type_color: 'primary',

    short_label: 'Import',
    label: <span>Import</span>,
  },
];
export const option_storefront = [
  {
    value: '1',
    type_color: 'primary',
    short_label: 'Matched product',
    label: (
      <span>
        <b>Matched product</b>
      </span>
    ),
  },
  {
    value: '2',
    type_color: 'warning',
    short_label: 'Unmatch product',
    label: (
      <span>
        <b>Unmatch product</b>
      </span>
    ),
  },
];
export const option_stock_order = [
  {
    value: '3',
    type_color: 'primary',
    short_label: 'In stock',
    label: <span>In stock</span>,
  },
  {
    value: '0',
    type_color: 'warning',
    short_label: 'Out of stock',
    label: <span>Out of stock</span>,
  },
  {
    value: '1',
    type_color: 'warning',
    short_label: 'Temporarily unavailable',
    label: <span>Temporarily unavailable</span>,
  },
];
export const option_status_order = [
  {
    value: '0',
    type_color: 'primary',
    short_label: 'Pending',
    label: <span>Pending</span>,
  },
  {
    value: '1',
    type_color: 'warning',
    short_label: 'Processing',
    label: <span>Processing</span>,
  },
  {
    value: '2',
    type_color: 'primary',
    short_label: 'Completed',
    label: <span>Completed</span>,
  },
  {
    value: '3',
    type_color: 'warning',
    short_label: 'Canceled',
    label: <span>Canceled</span>,
  },
  {
    value: '19',
    type_color: 'warning',
    short_label: 'On-hold',
    label: <span>On hold</span>,
  },
  {
    value: '-1',
    type_color: 'primary',
    short_label: 'All',
    label: <span>All</span>,
  },
];
export const option_order_duplicated = [
  {
    value: '1',
    type_color: 'primary',
    short_label: 'Yes',
    label: <span>Duplicated</span>,
  },
  {
    value: '0',
    type_color: 'primary',
    short_label: 'No',
    label: <span>Not duplicated</span>,
  },
  {
    value: '-1',
    type_color: 'primary',
    short_label: 'All',
    label: <span>All</span>,
  },
];
export const option_is_approve = [
  {
    value: '1',
    short_label: 'Approved',
    label: <span>Approved</span>,
  },
  {
    value: '0',
    short_label: 'Unapproved',
    label: <span>Unapproved</span>,
  },
  {
    value: '-1',
    short_label: 'All',
    label: <span>All</span>,
  },
];
export const options_filter_gift_message = [
  {
    value: '1',
    short_label: 'Without gift message',
    label: <span>Without gift message</span>,
  },
  {
    value: '2',
    short_label: 'With gift message',
    label: <span>With gift message</span>,
  },
  {
    value: '-1',
    short_label: 'All',
    label: <span>All</span>,
  },
];

export const option_order_rating = [
  {
    value: '0',
    type_color: 'primary',
    short_label: 'Select rating',
    label: <span>--Select rating--</span>,
  },
  {
    value: '1',
    type_color: 'primary',
    short_label: '1 star',
    label: <span>1 star</span>,
  },
  {
    value: '2',
    type_color: 'primary',
    short_label: '2 star',
    label: <span>2 star</span>,
  },
  {
    value: '3',
    type_color: 'primary',
    short_label: '3 star',
    label: <span>3 star</span>,
  },
  {
    value: '4',
    type_color: 'primary',
    short_label: '4 star',
    label: <span>4 star</span>,
  },
  {
    value: '5',
    type_color: 'primary',
    short_label: '5 star',
    label: <span>5 star</span>,
  },
];

export const option_date_type = [
  {
    value: '1',
    type_color: 'primary',
    short_label: 'Create date',
    label: <span>Create date</span>,
  },
  {
    value: '2',
    type_color: 'warning',
    short_label: 'Payment date',
    label: <span>Payment date</span>,
  },
];
// export const option_data_type = [
//   {
//     value: '0',
//     type_color: 'primary',
//     short_label: 'Matched product',
//     label: (
//       <span>
//         <b>Create date</b>
//       </span>
//     ),
//   },
//   {
//     value: '1',
//     type_color: 'warning',
//     short_label: 'Unmatch product',
//     label: (
//       <span>
//         <b>Payment date</b>
//       </span>
//     ),
//   },
// ];
export const option_method = [
  { label: 'Manual', value: '2' },
  { label: 'Import', value: '1' },
  { label: 'Sync', value: '0' },
  { label: 'All', value: '-1' },
];
export const option_approve_status = [
  { label: 'Approved', value: '1' },
  { label: 'Unapproved', value: '0' },
  { label: 'All', value: '-1' },
];
export const option_status_payment = [
  {
    value: '0',
    type_color: 'primary',
    short_label: 'Unpaid',
    label: <span>Unpaid</span>,
  },
  {
    value: '1',
    type_color: 'primary',
    short_label: 'Paid',
    label: <span>Paid</span>,
  },
  {
    value: '-1',
    type_color: 'primary',
    short_label: 'All',
    label: <span>All</span>,
  },
];
export const options_type_gift_message = [
  { label: 'With gift message', value: '2' },
  { label: 'Without gift message', value: '1' },
  { label: 'All', value: '-1' },
];
export const option_invoice_status = [
  {
    value: '0',
    type_color: 'default',
    short_label: 'Unpaid',
    label: <span>Unpaid</span>,
  },
  {
    value: '1',
    type_color: 'success',
    short_label: 'Paid',
    label: <span>Paid</span>,
  },
];
export const option_tracking = [
  {
    value: '0',
    short_label: 'Standard shipping',
    label: <span>Standard shipping</span>,
  },
  {
    value: '2',
    short_label: 'Ground shipping',
    label: <span>Ground shipping</span>,
  },
  // {
  //   value: '4',
  //   short_label: 'Ground',
  //   label: <span>Ground</span>,
  // },
  {
    value: '1',
    short_label: 'Fast shipping',
    label: <span>Fast shipping</span>,
  },
  {
    value: '4',
    short_label: 'Amazon label',
    label: <span>Amazon label</span>,
  },
  {
    value: '14',
    short_label: 'Ebay label',
    label: <span>Ebay label</span>,
  },
  {
    value: '15',
    short_label: 'Etsy label',
    label: <span>Etsy label</span>,
  },
  {
    value: '17',
    short_label: 'Poshmark label',
    label: <span>Poshmark label</span>,
  },
];
export const option_order_note = [
  {
    value: '0',
    type_color: 'primary',
    short_label: 'Select Order Note',
    label: <span>--Select Order Note--</span>,
  },
  {
    value: '1',
    type_color: 'primary',
    short_label: 'Matched product',
    label: <span>Not noted yet</span>,
  },
  {
    value: '2',
    type_color: 'warning',
    short_label: 'Unmatch product',
    label: <span>Noted</span>,
  },
];
export const option_type_order = [
  {
    value: '-1',
    type_color: 'primary',
    short_label: 'Select type order',
    label: <span>--Select type order--</span>,
  },
  {
    value: '0',
    type_color: 'primary',
    short_label: 'Unapproved',
    label: <span>Unapproved</span>,
  },
  {
    value: '1',
    type_color: 'primary',
    short_label: 'Approved',
    label: <span>Approved</span>,
  },
];
export const option_verify_address = [
  {
    value: '0',
    type_color: 'primary',
    short_label: 'Matched product',
    label: <span>Address not verified</span>,
  },
  {
    value: '1',
    type_color: 'warning',
    short_label: 'Unmatch product',
    label: <span>Address verified</span>,
  },
  {
    value: '-1',
    type_color: 'primary',
    short_label: 'All',
    label: <span>All</span>,
  },
];
export const options_order_by = [
  {
    value: 'des',
    short_label: 'DESC',
    label: <span>Descending</span>,
  },
  {
    value: 'asc',
    short_label: 'ASC',
    label: <span>Ascending</span>,
  },
];
export const options_trademark = [
  {
    value: 1,
    short_label: 'Scan trademark',
    label: (
      <span>
        <b>Scan trademark</b>
      </span>
    ),
  },
  {
    value: 0,
    short_label: 'Not scan trademark',
    label: (
      <span>
        <b>Not scan trademark</b>
      </span>
    ),
  },
];

export const options_payment_status = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={GRAY_200}
        color={GRAY_600}
        noDot={true}
        backgroundColor={GRAY_50}
        className="badge"
      >
        Unpaid
      </GMBadge>
    ),
    label: (
      <span>
        <b>Unpaid</b>
      </span>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={SUCCESS_600}
        color={SUCCESS_600}
        noDot={true}
        backgroundColor={SUCCESS_50}
        className="badge"
      >
        Paid
      </GMBadge>
    ),
    label: (
      <span>
        <b>Paid</b>
      </span>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={ERROR_600}
        color={ERROR_600}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Failed
      </GMBadge>
    ),
    label: (
      <span>
        <b>Failed</b>
      </span>
    ),
  },
  {
    value: 3,
    short_label: (
      <GMBadge
        border={GRAY_600}
        color={GRAY_600}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Canceled
      </GMBadge>
    ),
    label: (
      <span>
        <b>Canceled</b>
      </span>
    ),
  },
];

export const match_product = [
  {
    value: 1,
    short_label: (
      <GMBadge
        border={SUCCESS_600}
        color={SUCCESS_600}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Matching
      </GMBadge>
    ),
    label: (
      <span>
        <b>Matching</b>
      </span>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={GRAY_600}
        color={GRAY_600}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Non-matching
      </GMBadge>
    ),
    label: (
      <span>
        <b> Non-matching</b>
      </span>
    ),
  },
];

export const options_payment_status_detail = [
  {
    value: 'Unpaid',
    type_color: 'default',
    short_label: <span className="text-secondary">Unpaid</span>,
    label: (
      <span>
        <b>Unpaid</b>
      </span>
    ),
    isBadge: true,
  },
  {
    value: 'Paid',
    type_color: 'success',
    short_label: (
      <span className="text-success">
        <b>Paid</b>
      </span>
    ),
    label: (
      <span>
        <b>Paid</b>
      </span>
    ),
    isBadge: true,
  },
  {
    value: 'Failure',
    type_color: 'error',
    short_label: (
      <span className="text-warning">
        <b>Failure</b>
      </span>
    ),
    label: (
      <span>
        <b>failure</b>
      </span>
    ),
    isBadge: true,
  },
  {
    value: 'Canceled',
    type_color: 'default',
    short_label: (
      <span className="text-danger">
        <b>Canceled</b>
      </span>
    ),
    label: (
      <span>
        <b>Canceled</b>
      </span>
    ),
    isBadge: true,
  },
];
export const options_transaction_type = [
  {
    value: '0',
    short_label: 'All',
    label: (
      <span>
        <b>All</b>
      </span>
    ),
  },
  {
    value: '1',
    short_label: 'Payment',
    label: (
      <span>
        <b>Payment</b>
      </span>
    ),
  },
  {
    value: '2',
    short_label: 'Deposit/Profit',
    label: (
      <span>
        <b>Deposit/Profit</b>
      </span>
    ),
  },
];

export const list_shipping_method_fba = [
  { value: 3, label: 'FBA Amazon' },
  { value: 4, label: 'Pickup at warehouse' },
];

export const list_shipping_services = [
  {
    value: 9,
    name: 'Amazon label',
    label: <AmazonLabel width={100} />,
    labelTopUp: <AmazonLabel />,
  },
  {
    value: 17,
    name: 'Poshmark label',
    label: <PoshmarkLabel />,
    labelTopUp: <PoshmarkLabel />,
  },
  {
    value: 14,
    name: 'Ebay label',
    label: <EbayLabel />,
    labelTopUp: <EbayLabel />,
  },
  {
    value: 15,
    name: 'Etsy label',
    label: <EtsyLabel />,
    labelTopUp: <EtsyLabel />,
  },
  {
    value: 20,
    name: 'Tiktok label',
    label: <img src={TiktokLabel} alt="tiktok-label" width={80} />,
    labelTopUp: <img src={TiktokLabel} alt="tiktok-label" />,
  },
];
export const list_shipping_services_duplicate = [
  {
    value: 9,
    name: 'Amazon label',
    label: <AmazonLabel width={100} />,
    labelTopUp: <AmazonLabel />,
  },
  {
    value: 17,
    name: 'Poshmark label',
    label: <PoshmarkLabel />,
    labelTopUp: <PoshmarkLabel />,
  },
  {
    value: 14,
    name: 'Ebay label',
    label: <EbayLabel />,
    labelTopUp: <EbayLabel />,
  },
  {
    value: 15,
    name: 'Etsy label',
    label: <EtsyLabel />,
    labelTopUp: <EtsyLabel />,
  },
];
//Shipping method in create order
export const list_shipping_method = [
  {
    value: 0,
    label: 'Standard shipping',
    note: '7 - 10 business days delivery',
  },
  {
    value: 2,
    label: 'Ground shipping',
    note: '3 - 5 business days delivery.',
  },
  {
    value: 1,
    label: 'Fast shipping',
    note: '2 - 3 business days delivery.',
  },
];
export const list_shipping_method_international = [
  {
    value: '0',
    label: 'Standard shipping',
    note: '14 - 21 business days delivery ',
  },
];
export const list_shipping_method_special_state = [
  {
    value: 0,
    label: 'Standard shipping',
    note: '7 - 10 business days delivery',
  },
  {
    value: 1,
    label: 'Fast shipping',
    note: '2 - 3 business days delivery.',
  },
];
export const list_shipping_method_special_state_and_product = [
  {
    value: 0,
    label: 'Standard shipping',
    note: '7 - 10 business days delivery',
  },
];
export const list_shipping_method_special_product = [
  {
    value: 2,
    label: 'Ground shipping',
    note: '3 - 5 business days delivery.',
  },
  {
    value: 1,
    label: 'Fast shipping',
    note: '2 - 3 business days delivery.',
  },
];
export const list_shipping_method_military_address = [
  {
    value: 2,
    label: 'Ground shipping',
    note:
      'Due to security reasons, orders delivered to military addresses may take up to 35 business days to arrive.',
  },
  {
    value: 1,
    label: 'Fast shipping',
    note:
      'Due to security reasons, orders delivered to military addresses may take up to 35 business days to arrive.',
  },
];

export const list_shipping_method_military_address_canvas = [
  {
    value: 2,
    label: 'Ground shipping',
    note:
      'Due to security reasons, orders delivered to military addresses may take up to 35 business days to arrive.',
  },
];

export const list_shipping_method_special_product_canvas = [
  {
    value: 0,
    label: 'Standard shipping',
    note: '7 - 10 business days delivery',
  },
  {
    value: 2,
    label: 'Ground shipping',
    note: '3 - 5 business days delivery.',
  },
];
//
export const method_topUp = [
  {
    value: 1,
    name: 'Make payment',
    label: 'Make payment',
  },
  {
    value: 2,
    name: 'Instant',
    label: 'Instant',
  },
];
export const platform_create_store = [
  {
    value: 1,
    label: <AmazonLabel />,
  },
  {
    value: 2,
    label: <EbayLabel />,
  },
  {
    value: 3,
    label: <GearmentLabel />,
  },
  {
    value: 4,
    label: <EtsyLabel />,
  },
  {
    value: 5,
    label: <OrderDeskLabel />,
  },
  {
    value: 6,
    label: <ShopifyLabel />,
  },
  {
    value: 7,
    label: <WooLabel />,
  },
  {
    value: 8,
    label: <ShopbaseLabel />,
  },
  {
    value: 9,
    label: <ShopbaseLabel />,
  },
];
export const options_payment_method = [
  {
    value: '1',
    short_label: 'Credit card',
    label: 'Credit card',
  },
  {
    value: '2',
    short_label: 'Paypal',
    label: 'Paypal',
  },
  {
    value: '6',
    short_label: 'Wallet',
    label: 'Wallet',
  },
  {
    value: '7',
    short_label: 'PO',
    label: 'PO',
  },
];

export const listAvailablePlatform = [
  {
    type: 'amazon',
    name: 'Amazon',
    src: amazon_logo,
    formName: 'formAmazonStore',
    environment: 'all',
  },
  {
    type: 'wordpress',
    name: 'Wordpress',
    src: wordpress_logo,
    formName: 'formWordpressStore',
    environment: 'all',
  },
  {
    type: 'shopbase',
    name: 'Shopbase',
    src: shopbase_logo,
    formName: 'formShopbaseStore',
    environment: 'all',
  },
  {
    type: 'orderdesk',
    name: 'Order Desk',
    src: order_desk_logo,
    formName: 'formOrderDeskStore',
    environment: 'all',
  },
  {
    type: 'ebay',
    name: 'Ebay',
    src: ebay_logo,
    formName: 'FormEbayStore',
    environment: 'development',
  },
  {
    type: 'etsy',
    name: 'Etsy',
    src: etsy_logo_store,
    formName: 'formEtsyStore',
    environment: 'all',
  },
  {
    type: 'shopify',
    name: 'Shopify',
    src: null,
    formName: 'formShopifyStore',
    environment: 'all',
  },
  {
    type: 'store_default',
    name: 'Custom',
    src: gearment_logo,
    formName: 'formStore_defaultStore',
    environment: 'all',
  },
];

export const import_progress_status = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Pending
      </GMBadge>
    ),
    label: (
      <span>
        <b>Pending</b>
      </span>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Processing
      </GMBadge>
    ),
    label: (
      <span>
        <b>Processing</b>
      </span>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={SUCCESS_100}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Completed
      </GMBadge>
    ),
    label: (
      <span>
        <b>Completed</b>
      </span>
    ),
  },
  {
    value: 3,
    short_label: (
      <GMBadge
        border={ERROR_100}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Error
      </GMBadge>
    ),
    label: (
      <span>
        <b>Error</b>
      </span>
    ),
  },
];
export const export_progress_status = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Pending
      </GMBadge>
    ),
    label: (
      <span>
        <b>Pending</b>
      </span>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Processing
      </GMBadge>
    ),
    label: (
      <span>
        <b>Processing</b>
      </span>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={SUCCESS_100}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Completed
      </GMBadge>
    ),
    label: (
      <span>
        <b>Completed</b>
      </span>
    ),
  },
  {
    value: 3,
    short_label: (
      <GMBadge
        border={ERROR_100}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Failed
      </GMBadge>
    ),
    label: (
      <span>
        <b>Failed</b>
      </span>
    ),
  },
];
export const status_email_export = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Waiting
      </GMBadge>
    ),
    label: (
      <span>
        <b>Waiting</b>
      </span>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={SUCCESS_100}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Send
      </GMBadge>
    ),
    label: (
      <span>
        <b>Send</b>
      </span>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={ERROR_100}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Failed
      </GMBadge>
    ),
    label: (
      <span>
        <b>Failed</b>
      </span>
    ),
  },
];
export const options_sync_product_status = [
  {
    value: 0,
    type_color: 'warning',
    short_label: <b>Pending</b>,
    label: (
      <span>
        <b>Pending</b>
      </span>
    ),
  },
  {
    value: 1,
    type_color: 'processing',
    short_label: <b>Processing</b>,
    label: (
      <span>
        <b>Processing</b>
      </span>
    ),
  },
  {
    value: 2,
    type_color: 'success',
    short_label: <b>Completed</b>,
    label: (
      <span>
        <b>Completed</b>
      </span>
    ),
  },
  {
    value: 3,
    type_color: 'error',
    short_label: <b>Failed</b>,
    label: (
      <span>
        <b>Failed</b>
      </span>
    ),
  },
];

//TOpup status
export const topup_status = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={GRAY_50}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Pending
      </GMBadge>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={SUCCESS_50}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Completed
      </GMBadge>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={ERROR_50}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Rejected
      </GMBadge>
    ),
  },
];

export const topup_errors = {
  1: 'Wrong transaction ID',
  2: 'Wrong Amount',
  3: 'Wrong Payment method',
  4: 'Other',
};

export const import_order_queue_status = [
  {
    value: 0,
    type_color: 'warning',
    short_label: <b>Pending</b>,
    label: (
      <span>
        <b>Pending</b>
      </span>
    ),
  },
  {
    value: 1,
    type_color: 'processing',
    short_label: <b>In Progress</b>,
    label: (
      <span>
        <b>In Progress</b>
      </span>
    ),
  },
  {
    value: 2,
    type_color: 'success',
    short_label: <b>Completed</b>,
    label: (
      <span>
        <b>Completed</b>
      </span>
    ),
  },
  {
    value: 3,
    type_color: 'error',
    short_label: <b>Failed</b>,
    label: (
      <span>
        <b>Failed</b>
      </span>
    ),
  },
];

export const manual_detail_status = [
  {
    value: 0,
    type_color: 'warning',
    short_label: <b>Pending</b>,
    label: (
      <span>
        <b>Pending</b>
      </span>
    ),
  },
  {
    value: 1,
    type_color: 'processing',
    short_label: <b>Processing</b>,
    label: (
      <span>
        <b>Processing</b>
      </span>
    ),
  },
  {
    value: 2,
    type_color: 'success',
    short_label: <b>Completed</b>,
    label: (
      <span>
        <b>Completed</b>
      </span>
    ),
  },
  {
    value: 3,
    type_color: 'error',
    short_label: <b>Failed</b>,
    label: (
      <span>
        <b>Failed</b>
      </span>
    ),
  },
];
export const manual_type_status = [
  {
    value: 0,
    short_label: <p>Manual</p>,
    label: <p>Manual</p>,
  },
  {
    value: 1,
    short_label: <p>Manual</p>,
    label: <span>Manual</span>,
  },
  {
    value: 2,
    short_label: <p>Automation </p>,
    label: <span>Automation</span>,
  },
];
export const partial_detail_status = [
  {
    value: 1,
    type_color: 'warning',
    short_label: <b>Partial</b>,
    label: (
      <span>
        <b>Partial</b>
      </span>
    ),
  },
  {
    value: 0,
    type_color: 'success',
    short_label: <b>Full payment</b>,
    label: (
      <span>
        <b>Full payment</b>
      </span>
    ),
  },
];
export const schedule_task_status = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Pending
      </GMBadge>
    ),
    label: (
      <span>
        <b>Pending</b>
      </span>
    ),
  },
  // {
  //   value: 1,
  //   short_label: (
  //     <GMBadge
  //       border={PRIMARY_100}
  //       color={PRIMARY_700}
  //       noDot={true}
  //       backgroundColor={PRIMARY_50}
  //     >
  //       Processing
  //     </GMBadge>
  //   ),
  //   label: (
  //     <span>
  //       <b>Processing</b>
  //     </span>
  //   ),
  // },
  // {
  //   value: 2,
  //   short_label: (
  //     <GMBadge
  //       border={WARNING_100}
  //       color={WARNING_700}
  //       noDot={true}
  //       backgroundColor={WARNING_50}
  //     >
  //       Processing
  //     </GMBadge>
  //   ),
  //   label: (
  //     <span>
  //       <b>Processing</b>
  //     </span>
  //   ),
  // },
  {
    value: 3,
    short_label: (
      <GMBadge
        border={WARNING_100}
        color={WARNING_700}
        noDot={true}
        backgroundColor={WARNING_50}
      >
        Processing
      </GMBadge>
    ),
    label: (
      <span>
        <b>Processing</b>
      </span>
    ),
  },
  {
    value: 4,
    short_label: (
      <GMBadge
        border={SUCCESS_100}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Completed
      </GMBadge>
    ),
    label: (
      <span>
        <b>Completed</b>
      </span>
    ),
  },
  {
    value: 5,
    short_label: (
      <GMBadge
        border={ERROR_100}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Failed
      </GMBadge>
    ),
    label: (
      <span>
        <b>Failed</b>
      </span>
    ),
  },
  {
    value: 6,
    type_color: 'default',
    short_label: (
      <GMBadge
        border={GRAY_100}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Report ID Error
      </GMBadge>
    ),
    label: (
      <span>
        <b> Report ID Error</b>
      </span>
    ),
  },
];

export const list_store_error = {
  400: 'Bad request',
  401: 'Unauthorized. [API] Invalid API key or access token (unrecognized login or wrong password)',
  402: 'Payment pequired',
  406: 'Not Acceptable- shop end free trial',
  403: 'Forbidden - daily variant creation limit reached or exceeded maximum number of variants allowed',
  422: 'Failed to down Or unprocessable entity',
  423: 'Locked',
  429: 'Too many requests',
  456: 'Token expired',
  500: 'Internal server error',
  502: 'Bad gateway',
  501: 'Payment pequired',
  503: 'Service unavailable',
  522: 'Origin connection time-out',
};

export const listTypeToExportCampaign = [
  {
    value: '0',
    label: <b>Shopify products via CSV</b>,
  },
  {
    value: '2',
    label: <b>Shopbase products via CSV</b>,
  },
  {
    value: '1',
    label: <b>Wordpress plugin via CSV</b>,
  },
];
export const list_platform_to_sync = [
  {
    value: 1,
    label: 'Shopify',
    code: 'shopify',
    logo: shopify_logo,
    logoWidth: '70%',
  },
  {
    value: 5,
    label: 'Shopbase',
    code: 'shopbase',
    logo: shopbase_logo,
    logoWidth: '90%',
  },
  {
    value: 3,
    label: 'Amazon',
    code: 'amazon',
    logo: amazon_logo,
    logoWidth: '100%',
  },
  {
    value: 2,
    label: 'Wordpress',
    code: 'wordpress',
    logoWidth: '90%',
    logo: wordpress_logo,
  },
  {
    value: 4,
    label: 'Order Desk',
    code: 'orderdesk',
    logo: order_desk_logo,
    logoWidth: '90%',
  },
  {
    value: 14,
    label: 'Ebay',
    code: 'ebay',
    logo: ebay_logo,
    logoWidth: '60%',
  },
  {
    value: 15,
    label: 'Etsy',
    code: 'etsy',
    logoWidth: '40%',
    logo: etsy_logo,
  },
];

export const listDefaultShippingServices = [
  {
    value: '0',
    label: 'Standard',
  },
  {
    value: '2',
    label: 'Ground',
  },
  {
    value: '1',
    label: 'Fastship',
  },
];

export const list_platform_to_sync_campaign = [
  {
    value: 0,
    label: 'Shopify',
    code: 'shopify',
  },
  {
    value: 1,
    label: 'Shopbase',
    code: 'shopbase',
  },
  {
    value: 15,
    label: 'Etsy',
    code: 'etsy',
  },
];

export const list_file_type_to_import = [
  {
    value: '0',
    name: 'File Order Gearment',
    logo: gearment_logo,
    logoSmall: brandSMGearmentWColor,
    logoWidth: '80%',
    logoWidthMobile: '100%',
    sampleLink:
      'https://origin.geaflare.com/excel_sample/import_order_example_v3_9.xlsx',
  },
  {
    value: '12',
    name: 'File Order Variant Gearment',
    logo: gearment_logo,
    logoSmall: brandSMGearmentWColor,
    logoWidth: '80%',
    logoWidthMobile: '100%',

    sampleLink:
      'https://origin.geaflare.com/excel_sample/import_order_variant_example_v1_4.xls',
  },
  {
    value: '1',
    name: 'File Order BigCommerce',
    logo: big_commerce_logo,
    logoSmall: brandSMBigcommerce,
    logoWidth: '35%',
    sampleLink:
      'https://d33jplnp2vyxjf.cloudfront.net/excel_sample/import_order_example1.csv',
  },
  {
    value: '2',
    name: 'File Wordpress',
    logo: wordpress_logo,
    logoSmall: brandSMWoo,
    logoWidth: '80%',
    logoWidthMobile: '100%',

    sampleLink:
      'https://d33jplnp2vyxjf.cloudfront.net/excel_sample/import_order_example_2_v3.xlsx',
  },
  {
    value: '3',
    name: 'File Etsy',
    logoWidth: '40%',
    logoWidthMobile: '60%',
    logo: etsy_logo,
    logoSmall: brandSMEtsy,
    sampleLink:
      'https://origin.geaflare.com/excel_sample/import_order_example_3_v2.csv',
  },
  {
    value: '13',
    name: 'File Etsy 2',
    logoWidth: '40%',
    logoWidthMobile: '60%',
    logo: etsy_logo,
    logoSmall: brandSMEtsy,
    sampleLink:
      'https://origin.geaflare.com/excel_sample/import_order_example_etsy_v2_2.csv',
  },
  {
    value: '4',
    name: 'File Presta shop',
    logo: pretashop_logo,
    logoSmall: brandSMPresta,
    logoWidth: '40%',
    sampleLink:
      'https://d33jplnp2vyxjf.cloudfront.net/excel_sample/import_order_example4.xlsx',
  },
  {
    value: '5',
    name: 'File eBay',
    logo: ebay_logo,
    logoSmall: brandSMEbay,
    logoWidth: '80%',
    logoWidthMobile: '100%',
    sampleLink:
      'https://d33jplnp2vyxjf.cloudfront.net/excel_sample/import_order_exampleEbay.csv',
  },
  {
    value: '9',
    name: 'File eBay 2',
    logo: ebay_logo,
    logoSmall: brandSMEbay,
    logoWidth: '80%',
    logoWidthMobile: '100%',
    sampleLink:
      'https://d33jplnp2vyxjf.cloudfront.net/excel_sample/import_order_exampleEbay2.1.xls',
  },
  {
    value: '10',
    name: 'File eBay 3',
    logo: ebay_logo,
    logoSmall: brandSMEbay,
    logoWidth: '80%',
    logoWidthMobile: '100%',
    sampleLink:
      'https://d33jplnp2vyxjf.cloudfront.net/excel_sample/import_order_exampleEbay.csv',
  },
  {
    value: '6',
    name: 'File Amazon',
    logo: amazon_logo,
    logoSmall: brandSMAmazon,
    logoWidth: '100%',
    logoWidthMobile: '100%',
    sampleLink:
      'https://origin.geaflare.com/excel_sample/import_order_example_6_v2.xls',
  },
  {
    value: '14',
    name: 'File Shopify',
    logo: shopify_logo,
    logoSmall: brandSMShopify,
    logoWidth: '40%',
    logoWidthMobile: '50%',
    sampleLink:
      'https://origin.geaflare.com/excel_sample/shopify_import_order_templates.csv',
  },
  {
    value: '11',
    name: 'File Inkgo',
    logo: inkgo_logo,
    logoSmall: brandSMInkgo,
    logoWidth: '30%',
    logoWidthMobile: '45%',
    sampleLink:
      'https://d33jplnp2vyxjf.cloudfront.net/excel_sample/import_order_example_11.xlsx',
  },
  {
    value: '7',
    name: 'File Inkgo 2',
    logo: inkgo_logo,
    logoSmall: brandSMInkgo,
    logoWidth: '30%',
    logoWidthMobile: '45%',
    sampleLink:
      'https://d33jplnp2vyxjf.cloudfront.net/excel_sample/import_inkgo_order.csv',
  },
  {
    value: '8',
    name: 'File Inkgo 3',
    logo: inkgo_logo,
    logoSmall: brandSMInkgo,
    logoWidth: '30%',
    logoWidthMobile: '45%',
    sampleLink:
      'https://d33jplnp2vyxjf.cloudfront.net/excel_sample/import_inkgo_order.csv',
  },
  {
    value: '19',
    name: 'Wish',
    logo: wish_logo,
    logoSmall: brandSMWish,
    logoWidth: '30%',
    logoWidthMobile: '45%',
    sampleLink:
      'https://origin.geaflare.com/excel_sample/wish_import_order_templates_v1.xlsx',
  },
];
export const label_order_template =
  'https://origin.geaflare.com/excel_sample/import_lable_order_example_v1_1.xlsx';

export const default_config = {
  timezone: 'America/Los_Angeles',
  dateFormat: 'YYYY/MM/DD',
  dateTimeFormat: 'YYYY/MM/DD hh:mm A',
  locales: 'en-US',
  metaTitle: 'Account Gearment',
};
export const getOptionLabel2 = ({
  options,
  value,
  uppercase = true,
  label_if_not,
  isRaw = false,
}) => {
  return getOptionLabel(options, value, uppercase, label_if_not, isRaw);
};

export const getOptionLabel = (
  options,
  value,
  uppercase = true,
  label_if_not,
  isRaw = false
) => {
  const option = options.find((item) => item.value + '' === value + '') || {
    label: label_if_not || value,
    short_label: label_if_not || value,
  };
  if (isRaw) {
    return option.short_label;
  }
  let label = <span>{option.short_label}</span>;
  if (option.type_color) {
    if (option.isBadge) {
      label = (
        <GMBadge
          className={classNames({
            'text-uppercase': uppercase,
          })}
          // color={option.type_color}
          type={option.value}
          noDot
        >
          {option.value}
        </GMBadge>
      );
    } else {
      label = (
        <Tag
          style={{ minWidth: 50 }}
          className={classNames('text-center rounded-pill px-2 py-1', {
            'text-uppercase': uppercase,
          })}
          color={option.type_color}
        >
          {option.short_label}
        </Tag>
      );
    }
  }
  return label;
};
export const uploading_message =
  "We're processing your image, please wait a moment";

export const pageSizeOptions = [50, 100, 150, 200, 250, 300];

export const listTempErrorForOrder = {
  1: {
    title: 'Re-match the product',
    description:
      'The color of the T-shirt is the same as the design color (Black on black OR white on white)',
  },
  2: {
    title: 'Re-match the product',
    description:
      'The design will be small when it is elastic because of many empty areas',
  },
  3: {
    title: 'Update the address',
    description:
      'Address information is incorrect (Ex: Name, address, state, zip code).',
  },
  4: {
    title: 'Re-match the product',
    description: 'The design has been reversed',
  },
  5: {
    title: 'Re-match the product',
    description: 'The design file is incorrect',
  },
  6: {
    title: 'Trademark Detected',
    description: 'The design file is Trademark',
  },
};
export const options_upload_image_status = [
  {
    value: 1,
    type_color: 'success',
    short_label: 'Success',
    label: (
      <span>
        Status: <b>Success</b>
      </span>
    ),
  },
  {
    value: 0,
    type_color: 'processing',
    short_label: 'Uploading',
    label: (
      <span>
        Status: <b>Uploading</b>
      </span>
    ),
  },
  {
    value: 2,
    type_color: 'error',
    short_label: 'Error',
    label: (
      <span>
        Status: <b>Error</b>
      </span>
    ),
  },
];
export const options_bulk_campaign_status = [
  {
    value: 1,
    type_color: 'success',
    short_label: 'Success',
    label: (
      <span>
        Status: <b>Success</b>
      </span>
    ),
  },
  {
    value: 0,
    type_color: 'processing',
    short_label: 'Pending',
    label: (
      <span>
        Status: <b>Pending</b>
      </span>
    ),
  },
  {
    value: 2,
    type_color: 'error',
    short_label: 'Error',
    label: (
      <span>
        Status: <b>Error</b>
      </span>
    ),
  },
];

export const shipping_location = [
  {
    value: 0,
    label: <span>Domestic</span>,
  },
  {
    value: 1,
    label: <span>International</span>,
  },
];

export const listPermissions = [
  'createProductPermission',
  'syncProductPermission',
  'createOrderPermission',
  'deleteOrderPermission',
  'approveOrderPermission',
  'matchOrderPermission',
  'syncOrderPermission',
  'importOrderPermission',
  'viewTransactionPermission',
  'viewSettingPermission',
  'createStorePermission',
  'createFeedPermission',
  'viewFeedPermission',
  'viewAllOrderPermission',
  'paymentOrderPermission',
  'cancelOrderPermission',
];

export const listlUsersPermittedToVerifyForcedDelivery = [2656, 60, 1];
export const listUsersPermittedToConverOrderLabel = [
  5222,
  45,
  60,
  909,
  2910,
  4609,
  271,
];
export const listUsersPermittedToCreateLabelOrder = [
  5222,
  45,
  60,
  909,
  2910,
  4609,
  271,
];
export const cusShippingFrom = [1, 60, 2656];
export const listProductNotReviewed = [62, 63, 64, 66, 67, 68, 69, 128];
export const listProductTypeNotShowedSize = [3, 4, 6, 11];

export const listSpecialCustomer = [1, 30, 60, 45, 3727, 3714, 4307, 909];

export const listMetaTitleOptions = [
  {
    key: 'dashboard',
    value: 'Gearment | Dashboard',
  },
  {
    key: 'order',
    value: 'Gearment | Orders',
  },
  {
    key: 'products',
    value: 'Gearment | Products',
  },
  {
    key: 'image-library',
    value: 'Gearment | Design Library',
  },
  {
    key: 'store',
    value: 'Gearment | Stores',
  },
  {
    key: 'billing',
    value: 'Gearment | Billing',
  },
  {
    key: 'statistics',
    value: 'Gearment | Report ',
  },
  {
    key: 'logs',
    value: 'Gearment | Logs',
  },
  {
    key: 'settings',
    value: 'Gearment | Settings',
  },
  {
    key: 'help',
    value: 'Gearment | Help',
  },
];
export const value_topup = [
  {
    id: 1,
    value: '1000',
  },
  {
    id: 2,
    value: '5000',
  },
  {
    id: 3,
    value: '10000',
  },
  {
    id: 4,
    value: '50000',
  },
  {
    id: 5,
    value: '100000',
  },
];

export const list_payment_method = [
  {
    value: 3,
    title: 'G-Wallet',
    logo: GWalletLogo,
  },
  {
    value: 0,
    title: 'Paypal',
    logo: PaypalLogo,
  },
  {
    value: 1,
    title: 'Payoneer',
    logo: PayoneerLogo,
  },
];

export const PAYMENT_TYPE = {
  2: {
    title: 'Deposit',
    logoComponent: (
      <GMBadge border={GRAY_600} color={GRAY_700}>
        Deposit
      </GMBadge>
    ),
  },
  1: {
    title: 'Payout',
    logoComponent: (
      <GMBadge border={PRIMARY_600} color={PRIMARY_700}>
        Payout
      </GMBadge>
    ),
  },
};

export const PAYMENT_TYPE_BADGE_COLOR = {
  default: {
    border: GRAY_600,
    color: GRAY_700,
  },
  deposit: {
    border: GRAY_600,
    color: GRAY_700,
  },
  payout: {
    border: PRIMARY_600,
    color: PRIMARY_700,
  },
};

export const PAYMENT_METHOD_TYPE = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={GRAY_50}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Pending
      </GMBadge>
    ),
  },
  {
    value: 1,
    short_label: (
      <GMBadge
        border={PRIMARY_50}
        color={PRIMARY_700}
        noDot={true}
        backgroundColor={PRIMARY_50}
      >
        Processing
      </GMBadge>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={SUCCESS_50}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Completed
      </GMBadge>
    ),
  },
  {
    value: 3,
    short_label: (
      <GMBadge
        border={ERROR_50}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Failed
      </GMBadge>
    ),
  },
  {
    value: 4,
    short_label: (
      <GMBadge
        border={GRAY_50}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Processing
      </GMBadge>
    ),
  },
];

export const PAYMENT_METHOD_LIST = {
  0: {
    title: 'Cash',
    logo: null,
    logoComponent: (
      <Tag
        style={{
          background: 'transparent',
          color: GRAY_700,
          border: `1px solid ${GRAY_700}`,
        }}
      >
        Cash
      </Tag>
    ),
  },
  1: {
    title: 'Credit Card',
    logo: null,
    logoComponent: (
      <Tag
        style={{
          background: 'transparent',
          color: GRAY_700,
          border: `1px solid ${GRAY_700}`,
        }}
      >
        Credit Card
      </Tag>
    ),
  },
  2: {
    title: 'Paypal',
    logo: PaypalLogo,
    logoComponent: <PaypalLogoSVG />,
  },
  3: {
    title: 'Bank transfer',
    logo: null,
    logoComponent: (
      <Tag
        style={{
          background: 'transparent',
          color: GRAY_700,
          border: `1px solid ${GRAY_700}`,
        }}
      >
        Bank transfer
      </Tag>
    ),
  },
  4: {
    title: 'Internet banking',
    logo: null,
    logoComponent: (
      <Tag
        style={{
          background: 'transparent',
          color: GRAY_700,
          border: `1px solid ${GRAY_700}`,
        }}
      >
        Internet banking
      </Tag>
    ),
  },
  5: {
    title: 'ATM',
    logo: null,
    logoComponent: (
      <Tag
        style={{
          background: 'transparent',
          color: GRAY_700,
          border: `1px solid ${GRAY_700}`,
        }}
      >
        ATM
      </Tag>
    ),
  },
  6: {
    title: 'G-Wallet',
    logo: GWalletLogo,
    // logoComponent: <GearmentLogoSVG />,
    logoComponent: <img src={pathGearmentLogoSVG} alt="G-Wallet" height={24} />,
  },
  7: {
    title: 'Payoneer',
    logo: PayoneerLogo,
    logoComponent: <PayoneerLogoSVG />,
  },
  8: {
    title: 'TeeWorld',
    logo: null,
    logoComponent: (
      <Tag
        style={{
          background: 'transparent',
          color: GRAY_700,
          border: `1px solid ${GRAY_700}`,
        }}
      >
        TeeWorld
      </Tag>
    ),
  },
};
// 0 pending | 1 paid | 2 overdue | 3 closed | 4 rejected
export const TRANSACTION_STATUS_LIST = [
  {
    value: 0,
    short_label: (
      <GMBadge
        border={GRAY_50}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Pending
      </GMBadge>
    ),
  },
  {
    value: 2,
    short_label: (
      <GMBadge
        border={GRAY_50}
        color={GRAY_700}
        noDot={true}
        backgroundColor={GRAY_50}
      >
        Overdue
      </GMBadge>
    ),
  },

  {
    value: 1,
    short_label: (
      <GMBadge
        border={SUCCESS_50}
        color={SUCCESS_700}
        noDot={true}
        backgroundColor={SUCCESS_50}
      >
        Paid
      </GMBadge>
    ),
  },
  {
    value: 3,
    short_label: (
      <GMBadge
        border={ERROR_50}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Closed
      </GMBadge>
    ),
  },
  {
    value: 4,
    short_label: (
      <GMBadge
        border={ERROR_50}
        color={ERROR_700}
        noDot={true}
        backgroundColor={ERROR_50}
      >
        Rejected
      </GMBadge>
    ),
  },
];

export const ACCOUNT_GEARMENT_DOMAIN = 'https://account.gearment.com/';
