import {
  BASE_WHITE,
  GRAY_100,
  GRAY_200,
  GRAY_300,
  GRAY_400,
  GRAY_500,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_50,
  PRIMARY_500,
  SHADOW_XS,
  SUCCESS_500,
  WARNING_500,
} from 'components/colors';
import styled from 'styled-components';

export const WrapperCreateProduct = styled.div`
  .edit-product {
    .title-design {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: ${GRAY_900};
      margin-right: 15px;
    }
    .trashIcon {
      stroke: ${GRAY_400};
    }

    .base-price-product-list {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      .money {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        /* identical to box height, or 27px */

        text-align: right;
        letter-spacing: -0.02em;

        /* Primary/500 */

        color: ${PRIMARY_500};
      }
    }
  }
  .design-product {
    height: 570px;
    max-height: 100%;
    overflow: auto;
    margin-top: 24px;
  }
  .main-title {
    font-weight: 650;
    font-size: 18px;
    line-height: 28px;
  }
  .variants {
    font-size: 18px;
    line-height: 28px;
  }
  .append {
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .inforIcon {
    stroke: ${GRAY_400};
    width: 15px;
    height: 15px;
  }

  .group-price {
    margin-top: 16px;
    margin-bottom: 6px;
    .sale-price {
      color: ${GRAY_400};
      width: 15px;
      height: 15px;
    }
    .sale-price-title {
      font-weight: 700;
    }
  }
  .main-block {
    padding: 32px;
    background: ${BASE_WHITE};
    border-radius: 16px;
  }

  .selectField {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-select-selector {
      max-width: 100%;
      max-height: 100%;
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      border-radius: 8px;
    }
    .ant-select-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
      .ant-select-selector {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 8px;
    }
  }
  .button-upload {
    background-color: ${BASE_WHITE};
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      padding: 8px 12px;
      gap: 8px;
      width: 64px;
      height: 36px;
      max-width: 100%;
      border: none;
      /* Primary/50 */

      background: ${PRIMARY_50};
      border-radius: 6px;
      padding: 8px 12px;
      gap: 8px;

      width: 64px;
      height: 36px;

      /* Primary/50 */

      border-radius: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .ant-radio-button-wrapper {
      padding: 8px 12px;
      gap: 8px;
      width: 64px;
      height: 36px;
      max-width: 100%;
      border: none;
      /* Primary/50 */

      background: ${BASE_WHITE};

      border-radius: 6px;
      padding: 8px 12px;
      gap: 8px;

      width: 64px;
      height: 36px;

      /* Primary/50 */

      border-radius: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: white;
      border: none;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: white;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    background-color: ${PRIMARY_50};
    color: ${PRIMARY_500};
  }
  .ant-tabs-tab {
    background-color: ${BASE_WHITE};
    color: ${GRAY_500};
  }
  .design-area {
    border-radius: 16px;
    background-color: ${BASE_WHITE};
    margin-left: 24px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .block-arrow {
    display: flex;
    flex-direction: column;
    .position-arrow {
      cursor: pointer;
      border: 1px solid #d0d5dd;
      padding: 4px;
      max-height: 100%;
      max-width: 100%;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: ${BASE_WHITE};
      margin-top: 8px;
      svg {
        stroke: ${GRAY_500};
      }
    }
  }
  .upload-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    width: 672px;
    max-width: 100%;
    max-height: 100vh;
    /* Base/White */

    background: #ffffff;
    /* Gray/200 */

    border-radius: 12px;
  }
  .btn-open-modal-product{
    width: 100%;
    border-radius: 8px;
    background-color:${SUCCESS_500};
    height: 44px;
    max-height: 100%;
    border:none;
    color:${BASE_WHITE}
  }
`;
export const WrapperPickerSize = styled.div`
  .add-more {
    max-height: 100%;
    max-width: 100%;
    width: 32px;
    height: 32px;
    background: ${BASE_WHITE};
    border: 1px dashed ${GRAY_300};
    border-radius: 8px;
    padding: 4px;
    svg {
      stroke: ${GRAY_500};
      width: 15px;
      height: 15px;
    }
  }
  .tag-size-primary {
    padding: 6px 16px;
    width: 72px;
    height: 32px;
    max-height: 100%;
    max-width: 100%;
    border: none;
    background: ${GRAY_200};
    border-radius: 8px;
  }
`;
export const WrapperPickerColor = styled.div`
  .add-more {
    max-height: 100%;
    max-width: 100%;
    width: 32px;
    height: 32px;
    border: none;
    background: ${BASE_WHITE};
    border: 1px dashed ${GRAY_300};
    border-radius: 8px;
    padding: 4px;
    svg {
      stroke: ${GRAY_500};
      width: 15px;
      height: 15px;
    }
  }
  .tag-color-primary {
    padding: 4px;
    max-height: 100%;
    max-width: 100%;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`;
