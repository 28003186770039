import { 
    actionType as TYPE
} from './actions'

const initialState = {
    trackings: {
        data: [],
        loading: false,
        params: {},
        total: 0
    },
   
}

function ListReducer(state = initialState, action){
    switch(action.type) {
        case TYPE.LIST.REQUEST:
            return {
                ...state,
                trackings: {
                    ...initialState.trackings,
                    params: action.params,
                    loading: true
                }              
            }
        case TYPE.LIST.SUCCESS:
            return {
                ...state,
                trackings: {
                    ...state.trackings,
                    loading: false,
                    data: action?.data?.shipments || [],
                    total: action?.data?.pagination?.total || (Array.isArray(action?.data?.shipments) ? action?.data?.shipments : []).length,
                },
            }
        case TYPE.LIST.ERROR:
            return {
                ...state,
                trackings: {
                    ...state.trackings,
                    loading: false
                }  
            }
        default:
            return state
    }
}

export default ListReducer