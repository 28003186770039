export const actionType = {
    INVOICE_TRANSACTIONS: {
        REQUEST: 'TRANSACTION.INVOICE.REQUEST',
        SUCCESS: 'TRANSACTION.INVOICE.SUCCESS',
        ERROR: 'TRANSACTION.INVOICE.ERROR',
    },
};

export function getInvoiceTransaction(id) {
    return {
        type: actionType.INVOICE_TRANSACTIONS.REQUEST,
        id
    }
}


