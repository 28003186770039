import MockupList from './scenes/List';
import MockupCreate from './scenes/Create';
import MockupEdit from './scenes/Edit';

export default [
  {
    key: 'products.mockups',
    name: 'Mockups',
    component: MockupList,
    path: '/products/mockups',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'products.mockup.create',
    name: 'Mockups',
    component: MockupCreate,
    path: '/products/mockups/create',
    group: 'product',
    icon: null,
    template: 'default',
    isPublic: true,
  },
  {
    key: 'products.mockups.edit',
    name: 'Edit',
    component: MockupEdit,
    path: '/products/mockups/:id/edit',
    group: 'product',
    icon: null,
    template: 'default',
    isPublic: true,
  },
];
