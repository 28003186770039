import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import validate from './validate';
import { GMCopyright } from 'info';
import RenderAuthenticationSlider from 'utils/Form/RenderAuthenticationSlider';
import { ReactComponent as ArrowLeft } from 'images/icons/arrow-left.svg';
import { ReactComponent as AlertCircle } from 'images/icons/alert-circle.svg';
import { GlowingSVG } from 'components/SVG';
import { ReactComponent as HelpIcon } from 'images/icons/help-circle.svg';
import { ReactComponent as ClockSnoozeIcon } from 'images/icons/clock-snooze.svg';
import { ReactComponent as KeyIcon } from 'images/icons/key-01.svg';
import {
  PRIMARY_100,
  PRIMARY_50,
  PRIMARY_600,
  WARNING_100,
  WARNING_50,
  WARNING_500,
} from 'components/colors';
import { Wrapper } from './styled';
import { GMTextbox } from 'components/InputFields';
import { GMButton } from 'components/Button';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { setResetSuccessInfo } from '../actions';
import ResetSuccess from './ResetSuccess';
import { useEffect } from 'react';
import PasswordConditional from 'components/PasswordConditional';

const ResetpasswordForm = (props) => {
  const { handleSubmit, tokenValid, verifyTokenloading } = props;
  const sussessInfo = useSelector(
    (state) => state.auth.resetPassword.serverSuccessInfo
  );
  const serverErrors = useSelector(
    (state) => state.auth.resetPassword.serverErrorInfo
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setResetSuccessInfo(null));
  }, []);
  const errorMessage =
    serverErrors && serverErrors.length > 0
      ? serverErrors.map((error) => {
        switch (error?.code) {
          case 'token_not_exist':
            return (
              <li>
                <AlertCircle />
                <span className="error">Invalid verification email</span>
                <span className="detail">
                  Your verification email expired
                </span>
              </li>
            );
          default:
            return null;
        }
      })
      : null;
  if (tokenValid !== true && tokenValid !== false) {
    return null;
  }
  return (
    <Wrapper className="container-fluid">
      <div className="row align-items-stretch justify-content-center">
        <div className="col-md-6">
          <div className="col-12 col-md-8 col-xl-6 m-auto min-vh-100 d-flex left-form form-container">
            {!verifyTokenloading &&
              sussessInfo === null &&
              (tokenValid ? (
                <div className="d-flex flex-column w100p">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <div className="text-center">
                        <GlowingSVG
                          backgroundColor={PRIMARY_100}
                          borderColor={PRIMARY_50}
                          svgStrokeColor={PRIMARY_600}
                        >
                          <KeyIcon />
                        </GlowingSVG>
                      </div>
                      <h3 className="text-center mt-5 mb-2 title">
                        Set new password
                      </h3>
                      <p className="text-center message">
                        {/* Your new password must be different to previously used
                        passwords */}
                        &nbsp;
                      </p>
                    </div>
                    {errorMessage ? (
                      <ul className="error-list">{errorMessage}</ul>
                    ) : null}
                    <div>
                      <div className="form-group">
                        <GMTextbox
                          name="password"
                          label={
                            <>
                              Password
                              <Tooltip
                                title={<PasswordConditional />}
                                placement="topLeft"
                              >
                                <HelpIcon className="help-tooltip" />
                              </Tooltip>
                            </>
                          }
                          type="password"
                          placeholder="Password"
                          message="Use 8 or more characters with a mix of letters, numbers & symbols and does not contain email address"
                          showError
                        />
                      </div>
                      <div className="form-group">
                        <GMTextbox
                          name="confirmPassword"
                          label="Confirm password"
                          type="password"
                          placeholder="Confirm password"
                          showError
                        />
                      </div>
                      <div className="mt-4 login-form-button">
                        <GMButton
                          type="submit"
                          className="btn btn-primary button-Login w-100"
                        >
                          Update password
                        </GMButton>
                      </div>
                      <div className="text-center back2login">
                        <Link to="/auth/login" className="back-cta">
                          <ArrowLeft />
                          Back to log in
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="d-flex flex-column w100p justify-content-center align-items-center">
                  <div className="text-center">
                    <GlowingSVG
                      backgroundColor={WARNING_100}
                      borderColor={WARNING_50}
                      svgStrokeColor={WARNING_500}
                    >
                      <ClockSnoozeIcon />
                    </GlowingSVG>
                  </div>
                  <h2 className="text-center header">
                    Expired password reset link
                  </h2>
                  <p className="text-center message">
                    The password reset link you received has expired. Please
                    request a new password reset link by clicking the button
                    below.
                  </p>
                  <GMButton className="text-center my-2" size="md" style={{ width: "200px" }}>
                    <Link to="/auth/forgot" style={{ color: 'white' }}>
                      Request new link
                    </Link>
                  </GMButton>
                  <div className="text-center back2login">
                    <Link to="/auth/login" className="back-cta">
                      <ArrowLeft />
                      Back to log in
                    </Link>
                  </div>
                </div>
              ))}
            {sussessInfo && <ResetSuccess />}
            {GMCopyright}
          </div>
        </div>
        <div className="col-md-6 right-authentication-section">
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            <RenderAuthenticationSlider />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default connect()(
  reduxForm({
    form: 'formResetPassword',
    enableReinitialize: true,
    validate,
  })(ResetpasswordForm)
);
