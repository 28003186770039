import {
  BASE_WHITE,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_500,
} from 'components/colors';
import PageContainer from 'components/PageContainer';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    font-weight: 600;
    font-size: 18px;
  }

  .infor {
    padding: 8px;
    padding-left: 24px;
    padding-right: 24px;

    .title-o {
      color: ${GRAY_500};
      font-weight: 500;
      font-size: 14px;
    }
    .content-o {
      color: ${GRAY_900};
      font-weight: 500;
      font-size: 14px;
    }
  }
  .mark-address {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Primary/500 */

    color: #169dd8;
  }
  .button-svg {
    svg {
      stroke: ${GRAY_700};
      width: 20px;
      height: 20px;
    }
  }
  .disable {
    pointer-events: none;
    filter: grayscale(1);
    svg {
      opacity: 0.3;
    }
  }
`;
