import React, { useState, useEffect } from 'react';
import { Upload, Spin, Button, notification } from 'antd';
import { S3_CDN } from '../../web.config';
import { createFileName, createFolderName } from '../helper';
import getMomentInstance from '../moment';
import { ReactComponent as EditIcon } from 'images/icons/edit-01.svg';
import { getPresignedUrlS3PDF } from '../../apis/image_design';
import {
  uploadBarcode,
  editOrderBarCode,
  editOrderItemBarCode,
} from 'apis/orders';
import axios from 'axios';
import { GMButton } from 'components/Button';
import { Secondary } from 'stories/Button.stories';
import { Wrapper } from './components/RenderShippingInfo/styled';

function RenderEditFileBarCode({
  orderId = 0,
  accept = '.pdf',
  linkFileTitle = 'Link file barcode',
  buttonTitle = 'Edit barcode file',
  children,
  multiple = false,
  showUploadList = false,
  cusId = 0,
  prefixName = 'barcode',
  customPrefix = 'tmp',
  isEditOrderItemBarCode = false,
  currBarcodeUrl = '',
  orderItemId = 0,
  orderBarcodeFileId = 0,
  editable,
  fullwidth = true,
}) {
  const [loading, setLoading] = useState(false);
  const [newFileBarcode, setNewFileBarCode] = useState('');
  useEffect(() => {
    if (currBarcodeUrl) setNewFileBarCode(currBarcodeUrl);
  }, currBarcodeUrl);
  const props = {
    accept: accept,
    listType: 'picture-card',
    showUploadList: showUploadList,
    multiple: multiple ? true : false,
    beforeUpload: (file) => {
      if (file.type !== 'application/pdf') {
        notification.warn({
          className: 'notic1',
          message: `${file.name} is not a pdf file.`,
        });
      }
      if (file.size > 2e8) {
        notification.warn({
          className: 'notic1',
          message: `${file.name} is larger than 200MB.`,
        });
      }
      return file.type === 'application/pdf' && file.size <= 2e8;
    },
    customRequest: async ({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    }) => {
      const folderName = createFolderName(cusId);
      const extension = file.name.split('.').pop();
      const fileName = createFileName(extension || 'png', prefixName, cusId);
      const originalName = file.name.replace(`.${extension}`, '');
      const fileType =
        (extension === 'pdf' ? 'application' : 'image') + `/${extension}`;
      const keyToCreatePresignedUrl = `${customPrefix}/${folderName}/${getMomentInstance(
        null,
        0,
        false,
        true
      )}/${fileName}`;
      const response = await getPresignedUrlS3PDF(keyToCreatePresignedUrl);
      if (response && response.code === 'success') {
        axios
          .put(response.data.url, file, {
            headers: {
              'Content-Type': fileType,
            },
          })
          .then((response) => {
            if (parseInt(response.status) === 200) {
              onSuccess(
                {
                  code: 'success',
                  url: `${S3_CDN}/${keyToCreatePresignedUrl}`,
                  folderName: folderName,
                  fileName: fileName,
                  fileType,
                  extension,
                  originalName,
                },
                file
              );
            } else {
              onError();
            }
          });
      }
    },
    onChange: async (info) => {
      const { status, response } = info.file;
      if (status === 'uploading') {
        setLoading(true);
      } else if (status === 'done' && response.code === 'success') {
        const response2 = await uploadBarcode(
          response.url,
          response.originalName
        );
        if (response2.code === 'success' && response2?.data?.barcode?.id) {
          const response3 = isEditOrderItemBarCode
            ? await editOrderItemBarCode({
                barcodeId: response2.data.barcode.id,
                orderId: orderId,
                orderItemId: orderItemId,
              })
            : await editOrderBarCode({
                barcodeId: response2.data.barcode.id,
                orderId: orderId,
                orderBarcodeFileId,
              });
          if (response3.code === 'success') {
            setNewFileBarCode(response.url);
            notification.success({
              className: 'notic2',
              message: `${response3.clientMsg}`,
            });
          } else {
            notification.error({
              className: 'notic1',
              message: response3?.[0]?.['clientMsg'] || '',
            });
          }
          setLoading(false);
        } else {
          notification.error({
            className: 'notic1',
            message: response2?.[0]?.['clientMsg'] || '',
          });
          setLoading(false);
        }
      } else if (status === 'error') {
        notification.error({
          className: 'notic1',
          message: `Edit barcode file failed.`,
        });
        setLoading(false);
      }
    },
  };
  return (
    <Wrapper
      className={
        'image-design-upload edit-barcode-file ' + (fullwidth ? 'l100p' : '')
      }
    >
      <p className="mb-0">
        {newFileBarcode && (
          <a href={newFileBarcode} target={'_blank'} rel="noopener noreferrer">
            {linkFileTitle}
          </a>
        )}
      </p>
      {editable ? (
        <Upload className="gmupload" {...props}>
          <Spin spinning={loading}>
            <button className="nobutton" type="primary">
              <EditIcon />
            </button>
          </Spin>
          {children}
        </Upload>
      ) : (
        <span className="text-muted font-size-14 button-svg disable">
          <EditIcon />
        </span>
      )}
    </Wrapper>
  );
}

export default React.memo(RenderEditFileBarCode);
