export const actionType = {
  AT_RISK: {
    REQUEST: "ORDER.AT_RISK.REQUEST",
    SUCCESS: "ORDER.AT_RISK.SUCCESS",
    ERROR: "ORDER.AT_RISK.ERROR",
  },
};

export function getListAtRiskAction(params) {
  return {
    type: actionType.AT_RISK.REQUEST,
    params,
  };
}
