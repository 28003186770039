import { all } from 'redux-saga/effects'
import ListSaga from './scenes/List/saga'
import EditSaga from './scenes/Edit/saga'
import CreateSaga from './scenes/Create/saga';
import BulkSaga from './scenes/Bulk/saga'
import CloneSaga from './scenes/Clone/saga'
import SyncSaga from './scenes/Sync/saga'
import PreviewSaga from './scenes/Preview/saga'
import ExportFeedProductSaga from './scenes/ExportFeedProduct/saga'
import ExportFeedProductDetailSaga from './scenes/ExportFeedProductDetail/saga'
import FeedFormAmazonSaga from "./scenes/FeedAmazonData/saga";
import FeedFormGooleSaga from "./scenes/FeedGoogleData/saga";
import FeedCustomAamazonSaga from './scenes/FeedCustomAamazon/saga';
import DataFeedConfigurationSaga from './scenes/DataFeedConfiguration/saga';
export default function* () {
    yield all([
        ListSaga(),
        EditSaga(),
        CreateSaga(),
        BulkSaga(),
        CloneSaga(),
        SyncSaga(),
        PreviewSaga(),
        ExportFeedProductSaga(),
        ExportFeedProductDetailSaga(),
        FeedFormAmazonSaga(),
        FeedFormGooleSaga(),
        FeedCustomAamazonSaga(),
        DataFeedConfigurationSaga(),
    ])
}