import React, { memo } from "react";
import { Wrapper } from "./styled";
import { useEffect } from "react";
import { getIFrameUrl } from "apis/reports";
import { useState } from "react";
import { Spin } from "antd";

export default memo(({ pageName }) => {
  const [iframeUrl, setIFrameUrl] = useState();
  const [isLoading, setLoading] = useState();
  const loadData = async () => {
    setLoading(!!1);
    const rs = await getIFrameUrl();
    setIFrameUrl(
      rs?.data?.url?.replace(
        "#bordered=true&titled=true",
        "#bordered=false&titled=false"
      )
    );
    setLoading(!1);
  };
  useEffect(() => {
    loadData();
  }, []);
  if (!iframeUrl) {
    return (
      <Spin spinning={isLoading}>
        <Wrapper pageName={pageName} noContainer></Wrapper>
      </Spin>
    );
  }
  return (
    <Wrapper pageName={pageName} noContainer>
      <iframe src={iframeUrl} frameborder="0"></iframe>
    </Wrapper>
  );
});
