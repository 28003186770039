export const actionType = {
  LIST: {
    REQUEST: "DATA_FEED.LIST.LIST.REQUEST",

    SUCCESS: "DATA_FEED.LIST.LIST.SUCCESS",
    ERROR: "DATA_FEED.LIST.LIST.ERROR",
  },
};

export function getList(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}
