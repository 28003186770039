import React, { useState, useEffect } from 'react';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  sendRequestTopUp,
  sendRequestTopUpPaypalInstant,
} from 'scenes/TopUp/actions';
import { getList } from 'scenes/Analytics/actions';
import { getListPaypal } from 'scenes/TopUp/actions';

import {
  exportTransaction,
  getListQuickView,
  getProfitTransaction,
} from 'scenes/Transactions/scenes/List/actions';
import { ReactComponent as ArrowLeft } from 'images/icons/arrow-left.svg';
import { Modal } from 'antd';
import { GlobalStyle } from './styled';
import { dummyFunction } from 'utils/helper';
import { dataCorrection } from './utils';

const ModalTopUp = ({
  profile,
  sendRequestTopUp,
  sendRequestTopUpPaypalInstant,
  statusVerify,
  getListPaypal = dummyFunction,
  resetTopupStep1Form,
  isOpenModal = false,
  notiTopup,
  accountsPaypal,
  closeModal = () => {},
}) => {
  const [initStateTopup, setInitStateTopup] = useState({
    amount: 0,
    payId: 0,
    type: 1,
    image: '',
    total: 0,
    fee: '',
    paymentId: 0,
    instant: '',
    amountPaypal: 0,
  });

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (statusVerify) {
      setCurrentStep(+1);
    }
  }, [statusVerify]);

  useEffect(() => {
    getListPaypal();
  }, [getListPaypal]);

  useEffect(() => {
    setCurrentStep(0);
  }, []);

  useEffect(() => {
    if (isOpenModal) {
      setCurrentStep(0);
      resetTopupStep1Form();
    }
  }, [isOpenModal]);

  const handleOk = () => {
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
    resetTopupStep1Form();
    setInitStateTopup({ amount: '', paymentId: '', type: 1, image: '' });
  };

  const submitHandler = (values) => {
    if (values?.payId && values?.type === 3) {
      const request2Send = {
        amount: values?.amountPaypal,
        payId: parseInt(values?.payId),
        fee: values?.fee,
        total: values?.total,
        type: values?.type,
        instant: 2,
      };
      setInitStateTopup({
        ...request2Send,
        amountPaypal: values?.amountPaypal,
        payId: parseInt(values?.payId),
      });
      setCurrentStep(1);
    } else {
      const request2Send = {
        amount: parseFloat(values?.amount),
        paymentId: values?.paymentId,
        type: values?.type,
        image: values?.image,
      };
      setInitStateTopup(request2Send);
      sendRequestTopUp({ ...request2Send, check: 1 });
    }
    if (currentStep >= 1) {
      sendRequestTopUp(initStateTopup);
    }
  };

  const submitRequestTopUp = () => {
    if (initStateTopup?.payId) {
      sendRequestTopUpPaypalInstant({ ...initStateTopup });
    } else {
      sendRequestTopUp({ ...initStateTopup, check: 0 });
    }
    closeModal();
    setCurrentStep(0);
    resetTopupStep1Form();
    setInitStateTopup({ amount: '', paymentId: '', type: 1, image: '' });
  };

  const backPrevForm = () => {
    setCurrentStep(0);
  };
  const steps = [
    <>
      <Step1
        onSubmit={submitHandler}
        initialValues={initStateTopup || ''}
        profile={profile}
        notiTopup={notiTopup}
        accountsPaypal={accountsPaypal}
      />
    </>,
    <>
      <Step2
        onSubmit={submitRequestTopUp}
        initialValues={initStateTopup}
        summary={initStateTopup}
        accountsPaypal={accountsPaypal}
      />
    </>,
  ];
  const props_modal = {
    width: '720px',
  };
  return (
    <>
      <Modal
        title={
          <div>
            {currentStep === 1 ? (
              <div className="back" onClick={backPrevForm}>
                <ArrowLeft />
                Back
              </div>
            ) : (
              ''
            )}
            <div>Top up</div>
          </div>
        }
        visible={isOpenModal}
        onOk={handleOk}
        onCancel={handleCancel}
        className="topup-modal"
        footer={false}
        maskClosable={false}
        destroyOnClose
        {...props_modal}
      >
        {steps[currentStep]}
      </Modal>
      <GlobalStyle />
    </>
  );
};

export default connect(
  (state) => ({
    // transactionsQuickView: state.transaction.list.transactions,
    transactions: state.transaction.list.transactions,
    exportLoading: state.transaction.list.export.loading,
    profitTransaction: state.transaction.list.profits,
    filterTransactionValues: state.form.formFilterTransaction?.values,
    valueTopUpStep1: state.form.Form,
    profile: state.auth.info.profile,
    statusVerify: state.topup.requestTopUp.statusVerify,
    notiTopup: state.topup.requestTopUp.notiTopup,
    accountsPaypal: state.topup.listPaypal?.data,
  }),
  (dispatch) => ({
    getList: (params) => {
      dispatch(getList(params));
    },
    getListQuickView: (id) => {
      dispatch(getListQuickView(id));
    },
    getProfitTransaction: () => {
      dispatch(getProfitTransaction());
    },
    exportTransactions: (params) => {
      dispatch(exportTransaction(params));
    },
    sendRequestTopUp: (dataToRequest) => {
      dispatch(sendRequestTopUp(dataCorrection(dataToRequest)));
    },
    sendRequestTopUpPaypalInstant: (dataToRequest) => {
      dispatch(sendRequestTopUpPaypalInstant(dataCorrection(dataToRequest)));
    },
    resetTopupStep1Form: () => {
      dispatch(reset('TopUpStep1'));
    },
    getListPaypal: (options) => {
      dispatch(getListPaypal(options));
    },
  })
)(ModalTopUp);
