export const actionType = {
  CONVERT_ORDER: {
    REQUEST: 'ORDER.CONVERT_ORDER_LABEL.REQUEST',
    SUCCESS: 'ORDER.CONVERT_ORDER_LABEL.SUCCESS',
    ERROR: 'ORDER.CONVERT_ORDER_LABEL.ERROR',
  },
  FIND_ORDER_TO_CONVERT_ORDER_LABEL: {
    REQUEST: 'ORDER.FIND_ORDER_TO_CONVERT_ORDER_LABEL.REQUEST',
    SUCCESS: 'ORDER.FIND_ORDER_TO_CONVERT_ORDER_LABEL.SUCCESS',
    ERROR: 'ORDER.FIND_ORDER_TO_CONVERT_ORDER_LABEL.ERROR',
  },
};

export function getDetail(id) {
  return {
    type: actionType.FIND_ORDER_TO_CONVERT_ORDER_LABEL.REQUEST,
    id,
  };
}

export function convertOrder(data) {
  return {
    type: actionType.CONVERT_ORDER.REQUEST,
    data,
  };
}
