import React from 'react';
import { Steps, Spin, Collapse } from 'antd';
import { Wrapper } from './styled';
import getMomentInstance from 'utils/moment';
const { Panel } = Collapse;

function RenderWebhookLogs({ loading, logsWebhook }) {
  return (
    <Wrapper className="mt-2">
      <Spin spinning={loading}>
        <Collapse className="clol">
          <Panel
            header={<h5 className="font-weight-bold">Webhook logs</h5>}
            key="1"
          >
            {logsWebhook?.length !== 0 ? (
              <Steps progressDot direction="vertical">
                {logsWebhook?.map((item) => (
                  <Steps.Step
                    key={item.id}
                    title={getMomentInstance(item.logTime, 0, true)}
                    description={
                      <div>
                        <b>{item.customer?.name}:</b>{' '}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: (item.logName || '').replace(
                              /(<? *script)/gi,
                              'illegalscript'
                            ),
                          }}
                        ></span>
                      </div>
                    }
                  />
                ))}
              </Steps>
            ) : (
              <div className='text-muted'>No data</div>
            )}
          </Panel>
        </Collapse>
      </Spin>
    </Wrapper>
  );
}
export default React.memo(RenderWebhookLogs);
