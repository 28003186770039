export const actionType = {
  LIST_USERLABEL_UPDATE: {
    REQUEST: "USERLABEL_UPDATE.REQUEST",
    SUCCESS: "USERLABEL_UPDATE.SUCCESS",
    ERROR: "USERLABEL_UPDATE.ERROR",
  },
  LIST_USERLABEL_DETAIL: {
    REQUEST: "USERLABEL_DETAIL.REQUEST",
    SUCCESS: "USERLABEL_DETAIL.SUCCESS",
    ERROR: "USERLABEL_DETAIL.ERROR",
  },
};
export function updateUserlabel(id, dataToUpdate) {
  return {
    type: actionType.LIST_USERLABEL_UPDATE.REQUEST,
    id,
    dataToUpdate,
  };
}
export function getDetailUserlabel(id) {
  return {
    type: actionType.LIST_USERLABEL_DETAIL.REQUEST,
    id,
  };
}
