import React, { Component } from 'react';
import { connect } from 'react-redux';
import Api from './components/form';
import { Spin } from 'antd';
import { createApiKey, deleteApiKey, getApiKey } from './actions';
import { Wrapper } from './styled';

export class index extends Component {
  componentDidMount() {
    this.props.getApiKey();
  }

  render() {
    const {
      deleteApiKey,
      _createApiKey,
      _deleteApiKey,
      apiKey,
      pageName,
      createApiKey,
    } = this.props;
    const initialValues = {
      key: apiKey.data.key,
      signature: apiKey.data.signature,
    };
    return (
      <Wrapper pageName={pageName}>
        <Spin
          spinning={
            apiKey.loading || _createApiKey.loading || _deleteApiKey.loading
          }
        >
          <Api
            apiKey={apiKey}
            createApiKey={createApiKey}
            deleteApiKey={deleteApiKey}
            initialValues={initialValues}
            value={apiKey}
          />
        </Spin>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  apiKey: state.apiCre.edit.detailApiKey,
  _createApiKey: state.apiCre.edit.createApiKey,
  _deleteApiKey: state.apiCre.edit.deleteApiKey,
});

const mapDispatchToProps = (dispatch) => ({
  createApiKey: () => {
    dispatch(createApiKey());
  },
  deleteApiKey: () => {
    dispatch(deleteApiKey());
  },
  getApiKey: () => {
    dispatch(getApiKey());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
