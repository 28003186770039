export const actionType = {
  GET_PREVIEW_INDEMNICATION: {
    REQUEST: 'GET_PREVIEW_INDEMNICATION.REQUEST',
    SUCCESS: 'GET_PREVIEW_INDEMNICATION.SUCCESS',
    ERROR: 'GET_PREVIEW_INDEMNICATION.ERROR',
  },
};

export function getPreviewIndemnicationAction(data) {
  return {
    type: actionType.GET_PREVIEW_INDEMNICATION.REQUEST,
    data
  };
}
