import ProfitList from 'scenes/Profits/scenes/List'
// import OrderDetail from 'scenes/Orders/scenes/Detail'
// import OrderCreate from 'scenes/Orders/scenes/Create'
// import OrderShopify from 'scenes/Orders/scenes/Shopify'

export default [  
    {   
        key: 'profits',
        name: 'Profits',
        component: ProfitList,
        path: '/profits',    
        icon: null,    
        template: 'default',              
    },   
    // {
    //     key: 'order',
    //     name: 'Orders',
    //     component: OrderList,
    //     path: '/order',

    //     icon: null,
    //     template: 'default',  
    // },
    // {
    //     key: 'order.order_shopify',
    //     name: 'All order',
    //     component: OrderShopify,
    //     path: '/order',

    //     icon: null,
    //     template: 'default',  
    // },
    // {
    //     key: 'order.create',
    //     name: 'Create',
    //     component: OrderCreate,
    //     path: '/order/create',

    //     icon: null,
    //     template: 'default',  
    // },
    // {
    //     key: 'order.detail',
    //     name: 'Detail',
    //     component: OrderDetail,
    //     path: '/order/:id/detail',

    //     icon: null,
    //     template: 'default',  
    // },
]