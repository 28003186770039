import { takeLatest, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { actionType as TYPE } from "./actions";
import { listProductToClone, previewCampaign } from "apis/campaign";

function* listProductToCloneSaga(action) {
  try {
    const { id } = action;
    const response = yield call(listProductToClone, id);
    if (response.code === "success") {
      yield put({ type: TYPE.LIST_PRODUCT_TO_CLONE.SUCCESS, ...response });
    } else {
      yield all([
        put({ type: TYPE.LIST_PRODUCT_TO_CLONE.ERROR, response }),
        put(replace("/products/list")),
      ]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.LIST_PRODUCT_TO_CLONE.ERROR, error })]);
  }
}
function* previewCampaigCloneSaga(action) {
  try {
    const { dataToPreviewCampaign } = action;
    const response = yield call(previewCampaign, dataToPreviewCampaign);

    if (response.code === "success") {
      yield all([
        put({ type: TYPE.PREVIEW_CLONE.SUCCESS, ...response }),
        put(replace("/products/clone/preview")),
      ]);
    } else {
      yield put({ type: TYPE.PREVIEW_CLONE.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.PREVIEW_CLONE.ERROR, error })]);
  }
}

function* cloneCampaignWatcher() {
  yield all([
    takeLatest(TYPE.LIST_PRODUCT_TO_CLONE.REQUEST, listProductToCloneSaga),
    takeLatest(TYPE.PREVIEW_CLONE.REQUEST, previewCampaigCloneSaga),
  ]);
}

export default cloneCampaignWatcher;
