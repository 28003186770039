import ConfigBuyTracking from "./index";


export default [
  {
    key: "config_buy_tracking",
    name: "Config buy tracking",
    component: ConfigBuyTracking,
    path: "/settings/config-buy-tracking",
    icon: null,
    template: "default",
    isPublic: true
  },
];
