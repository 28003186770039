import ListCampaigns from "./scenes/List";
import ExportFeedProduct from "./scenes/ExportFeedProduct";
import ExportFeedProductDetail from "./scenes/ExportFeedProductDetail";
import CreateCampaign from "./scenes/Create";
import EditCampaign from "./scenes/Edit";
import BulkCampaign from "./scenes/Bulk";
import CloneCampaign from "./scenes/Clone";
import SyncCampaigns from "./scenes/Sync";
import PreviewCampaigns from "./scenes/Preview";
import FeedFormAmazon from "./scenes/FeedAmazonData";
import FeedFormGoogle from "./scenes/FeedGoogleData";
import FeedCustomAmazon from "./scenes/FeedCustomAamazon";
import Preview from "./scenes/Preview2/index";
import PreviewClone from "./scenes/PreviewClone/index";
import DataFeedConfiguration from "./scenes/DataFeedConfiguration";

export default [
  {
    key: "products.list",
    name: "Products",
    component: ListCampaigns,
    path: "/products/list",
    template: "default",
    isPublic: true,
  },
  {
    key: "products.create",
    name: "Create product",
    component: CreateCampaign,
    path: "/products/create",
    template: "default",
    isPublic: false,
    permissions: ["createProductPermission"],
  },
  {
    key: "products.create.preview",
    name: "Preview",
    component: Preview,
    path: "/products/create/preview",
    template: "default",
    isPublic: false,
    permissions: ["createProductPermission"],
  },
  {
    key: "products.clone.preview",
    name: "Clone",
    component: PreviewClone,
    path: "/products/clone/preview",
    template: "default",
    isPublic: false,
    permissions: ["createProductPermission"],
  },
  {
    key: "products.edit",
    name: "Edit products",
    component: EditCampaign,
    path: "/products/:id/edit",
    template: "default",
    isPublic: true,
  },
  {
    key: "products.bulk",
    name: "Bulk product",
    component: BulkCampaign,
    path: "/products/:id/bulk",
    template: "default",
    isPublic: false,
    permissions: ["createProductPermission"],
  },
  {
    key: "products.clone",
    name: "Clone product",
    component: CloneCampaign,
    path: "/products/:id/clone",
    template: "default",
    isPublic: false,
    permissions: ["createProductPermission"],
  },
  {
    key: "products.sync-product",
    name: "Sync products",
    component: SyncCampaigns,
    path: "/products/sync-product",
    template: "default",
    isPublic: false,
    permissions: ["syncProductPermission"],
  },
  {
    key: "products.preview",
    name: "Preview products",
    component: PreviewCampaigns,
    path: "/products/preview",
    template: "default",
    isPublic: true,
  },
  {
    key: "products.logs.export-feed-product",
    name: "Feed product logs",
    component: ExportFeedProduct,
    path: "/logs/export-feed",
    template: "default",
    isPublic: true,
  },
  {
    key: "products.logs.export-feed-product.detail",
    name: "Feed product log detail",
    component: ExportFeedProductDetail,
    path: "/logs/feed-product/:id",
    template: "default",
    isPublic: true,
  },
  {
    key: "feed-form-amazon",
    name: "Export feed form amazon",
    component: FeedFormAmazon,
    path: "/products/data-feed/feed-form-amazon",
    template: "default",
    isPublic: true,
  },
  {
    key: "custom-feed-amazon",
    name: "Export feed custom amazon",
    component: FeedCustomAmazon,
    path: "/products/data-feed/custom-feed-amazon",
    template: "default",
    isPublic: true,
  },
  {
    key: "feed-form-google",
    name: "Export feed form Google",
    component: FeedFormGoogle,
    path: "/products/data-feed/feed-form-google",
    template: "default",
    isPublic: true,
  },
  {
    key: "products.data-feed",
    name: "Data Feed configuration",
    component: DataFeedConfiguration,
    path: "/products/data-feed",
    template: "default",
    isPublic: true,
  },
];
