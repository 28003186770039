import Edit from "./scenes/Edit";
import Create from "./scenes/Create";

export default [
  {
    key: "webhook",
    name: "Configuation Gift Message",
    component: Create,
    path: "/settings/gift-message/configuation",
    icon: null,
    template: "default",
    isPublic: true
  },
  {
    key: "webhook.detail",
    name: "Edit webhook",
    component: Edit,
    path: "/settings/webhooks/:id/edit-configuation",
    icon: null,
    template: "default",
    isPublic: true
  },
];
