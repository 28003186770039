import { takeLatest, call, put, all } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { actionType } from './actions';
import { findOrderToConvertOrderLabel, convertOrder } from 'apis/orders';

function* findOrderToDuplicateOrderFbaSaga(action) {
  const { id } = action;
  try {
    const response = yield call(findOrderToConvertOrderLabel, id);
    if (response.code === 'success') {
      yield all([
        put({
          type: actionType.FIND_ORDER_TO_CONVERT_ORDER_LABEL.SUCCESS,
          ...response,
        }),
      ]);
    } else {
      yield all([
        put({
          type: actionType.FIND_ORDER_TO_CONVERT_ORDER_LABEL.ERROR,
          error: response,
        }),
        put(replace('/orders/list')),
      ]);
    }
  } catch (error) {
    yield all([
      yield put({
        type: actionType.FIND_ORDER_TO_CONVERT_ORDER_LABEL.ERROR,
        error,
      }),
    ]);
  }
}

function* convertOrderSaga(action) {
  const { data } = action;
  try {
    const response = yield call(convertOrder, data);
    if (response.code === 'success') {
      yield all([
        put({ type: actionType.CONVERT_ORDER.SUCCESS, ...response }),
        put(replace('/orders/list')),
      ]);
    } else {
      yield put({ type: actionType.CONVERT_ORDER.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: actionType.CONVERT_ORDER.ERROR, error })]);
  }
}

function* convertOrderLabelWatcher() {
  yield takeLatest(
    actionType.FIND_ORDER_TO_CONVERT_ORDER_LABEL.REQUEST,
    findOrderToDuplicateOrderFbaSaga
  );
  yield takeLatest(actionType.CONVERT_ORDER.REQUEST, convertOrderSaga);
}

export default convertOrderLabelWatcher;
