import React, { useEffect, useState } from "react";
import { Collapse, Col, Affix } from "antd";
import {
  Field,
  reduxForm,
  submit,
  formValueSelector,
  change,
  FieldArray,
} from "redux-form";
import { connect } from "react-redux";
import { renderRangeDate, renderSelectAnt } from "utils/Form";
import FieldCustomRadioGroup from "utils/fields/FieldCustomRadioGroup";
import { useSelector } from "react-redux";
import SelectProduct from "utils/Form/Search/SelectProduct";
import SelectProductsTagColor from "utils/Form/Search/SelectProductsTagColor";
import {
  option_order_duplicated,
  option_status_payment,
  option_tracking,
  option_status_order,
  option_verify_address,
  option_date_type,
  options_order_status_matched,
} from "utils/options";
import RenderTagFilter from "utils/Form/RenderTagFilter";
import { getStoreTypeName, clearFilter } from "utils/helper";
import RenderAdvancedFilterButtons from "utils/Form/RenderAdvancedFilterButtons";
import RenderTimeOptions from "utils/Form/RenderTimeOptions";
import SelectMultipleTagSizeProduct from "utils/Form/Search/SelectMultipleTagSizeProduct";
import SelectMultipleTagColorProduct from "utils/Form/Search/SelectMultipleTagColorProduct";
import { FilterWrapper } from "../../../styled";
import SelectGeneric from "utils/Form/Search/SelectGeneric";
import GMRow from "components/GMLayout/GMRow";
const { Panel } = Collapse;
const paramsName = {
  paymentStatus: "Payment Status",
  isImport: "Match Status",
  orderNote: "Buyer name or email",
  isDuplicated: "Duplicated Order",
  status: "Status",
  storeId: "Store",
  trackingType: "Tracking Type",
  verifiedAddress: "Address Status",
  productId: "Product",
  sizeIds: "Size",
  colorIds: "Color",
  rating: "Rating",
  orderName: "Order Name",
  fromDate: "From Date",
  toDate: "To Date",
};
const defaultValues = {
  sortBy: "id",
  sortType: "desc",
  productId: "0",
  colorIds: [],
  sizeIds: [],
  trackingType: null,
  verifiedAddress: "-1",
  isApproved: "-1",
  orderNote: "0",
  isImport: "0",
  status: "-1",
  insertMethod: "-1",
  paymentStatus: "-1",
  isDuplicated: "-1",
  typeDate: "0",
  storeId: "0",
  rating: "0",
  fromDate: null,
  toDate: null,
  orderName: "",
};

let paramsValueAlias = {
  status: {
    0: "Pending",
    1: "Processing",
    2: "Completed",
    3: "Canceled",
    13: "In-production",
    19: "On-hold",
  },
  isDuplicated: {
    0: "Not Duplicated",
    1: "Duplicated",
  },
  isImport: {
    1: "Matched",
    2: "Unmatch",
  },
  paymentStatus: {
    0: "Unpaid",
    1: "Paid",
    2: "Failure",
  },
  trackingType: {
    1: "Standard",
    2: "Fastship",
    3: "New tracking",
    4: "Ground",
  },
  verifiedAddress: {
    0: "Address not verified",
    1: "Address verified",
  },
}

const AdvancedFilter = (props) => {
  const orders = useSelector((state) => state.order.order_achive.archivedOrders);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const submitFilters = (isClosedAdvanedFileter = true) => {
    props.dispatch(submit("AdvancedFilterOrdersArchived"));
    if(isClosedAdvanedFileter) props.onCloseAdvancedSearch();
  };

  useEffect(() => {
    if ((Array.isArray(props?.exproducts) && props?.exproducts.length > 0) || (Array.isArray(props?.stores) && props?.stores.length > 0)
    || (Array.isArray(colors) && colors.length > 0) || (Array.isArray(sizes) && sizes.length > 0)) {
      const listProducts = {};
      (props?.exproducts || []).forEach((exproduct) => {
        listProducts[exproduct.id] = exproduct.brand;
      });
      const listStores = {};
      (props?.stores || []).map((store) => {
        return (listStores[store.id] = store.showName);
      });
      const listColors = {};
      (colors || []).forEach((color) => {
        listColors[color.id] = color.name;
      });
      const listSizes = {};
      (sizes || []).forEach((size) => {
        listSizes[size.id] = size.name;
      });
      paramsValueAlias = {
        ...paramsValueAlias,
        storeId: listStores,
        productId: listProducts,
        colorIds: listColors,
        sizeIds: listSizes
      }
    }
  }, [JSON.stringify(props?.exproducts), JSON.stringify(props?.stores), JSON.stringify(sizes), JSON.stringify(colors)]);

  const {
    handleSubmit,
    stores,
    exproducts,
    changeFieldValue,
    date_range
  } = props;
  return (
    <FilterWrapper>
      <RenderTagFilter
        params={orders.params}
        paramsName={paramsName}
        paramsValueAlias={paramsValueAlias}
        defaultValues={defaultValues}
        changeFieldValue={changeFieldValue}
        isAllowedToClearFilter={true}
        submitFilters={submitFilters}
        date_range={date_range}
      />
      <form onSubmit={handleSubmit}>
        <div className="p-3">
          <Collapse
            defaultActiveKey={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ]}
            bordered={true}
            ghost
            expandIconPosition="right"
          >
            <Panel header={<b className="header-filter">All time</b>} key="1">
              <RenderTimeOptions changeFieldValue={changeFieldValue} />
              <div className="row">
                <Field
                  name="date_range"
                  component={renderRangeDate}
                  labelStart={<b>Start date</b>}
                  labelEnd={<b>End date</b>}
                  className="h-50px w-100"
                  dateTimeFormat="YYYY/MM/DD"
                  showTime={false}
                  classContainer="col-6 mb-2"
                  startPlaceholder="yyyy/mm/dd"
                  endPlaceholder="yyyy/mm/dd"
                />
              </div>
            </Panel>
            <Panel header={<b className="header-filter">Storefront</b>} key="2">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="storeId"
                    component={renderSelectAnt}
                    options={[
                      {
                        value: 0,
                        label: (
                          <span style={{ color: "black" }}>
                            -- Select storefront --
                          </span>
                        ),
                      },
                      ...stores.map((store) => ({
                        value: store.id,
                        label:
                          `[${getStoreTypeName(store.backendType)}] ` +
                          (store.showName || `#${store.id}`),
                      })),
                    ]}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Orders Status</b>}
              key="3"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="status"
                    component={FieldCustomRadioGroup}
                    options={option_status_order}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Payment Status</b>}
              key="4"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="paymentStatus"
                    component={FieldCustomRadioGroup}
                    options={option_status_payment}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Matches Status</b>}
              key="5"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="isImport"
                    component={FieldCustomRadioGroup}
                    options={options_order_status_matched}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Address Status</b>}
              key="6"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="verifiedAddress"
                    component={FieldCustomRadioGroup}
                    options={option_verify_address}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Shipping service</b>} key="7">
              <GMRow>
                <Col span={24}>
                  <Field
                    options={option_tracking}
                    name={`trackingType`}
                    component={SelectGeneric}
                    placeholder="All"
                    onChange={(v) => {
                      changeFieldValue("trackingType", v);
                    }}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Order Duplicated</b>}
              key="8"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="isDuplicated"
                    component={FieldCustomRadioGroup}
                    options={option_order_duplicated}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Date Type</b>} key="9">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="typeDate"
                    component={FieldCustomRadioGroup}
                    options={option_date_type}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Product</b>} key="10">
              <GMRow>
                <Col span={24}>
                  <Field
                    name={`productId`}
                    component={SelectProduct}
                    data={exproducts}
                    onChoose={() => {
                      changeFieldValue("colorIds", []);
                      changeFieldValue(`sizeIds`, []);
                    }}
                  />
                </Col>
              </GMRow>
            </Panel>
            {/* <Panel header={<b className="header-filter">Color</b>} key="11">
              <GMRow>
                <Col span={24}>
                  <FieldArray
                    name={`colorIds`}
                    component={SelectMultipleTagColorProduct}
                    expId={productId}
                    setColors={setColors}
                    selectedColorIds = {colorIds}
                    setDefaultColor={(colorId) => {
                      changeFieldValue('colorId', colorId);
                    }}
                  />
                </Col>
              </GMRow>{" "}
            </Panel>
            <Panel header={<b className="header-filter">Size</b>} key="12">
              <GMRow>
                <Col span={24}>
                  <FieldArray
                    name={`sizeIds`}
                    component={SelectMultipleTagSizeProduct}
                    expId={productId}
                    colorId={colorId}
                    setSizes={setSizes}
                    selectedSizeIds={sizeIds}
                  />
                </Col>
              </GMRow>
            </Panel> */}
          </Collapse>
        </div>
      </form>
      <Affix offsetBottom={0}>
        <RenderAdvancedFilterButtons
          handleOnSubmit={() => {
            submitFilters();
          }}
          handleOnClearFilters={() => {
            clearFilter(changeFieldValue, {
              sortBy: "id",
              sortType: "desc",
              productId: "0",
              colorIds: [],
              sizeIds: [],
              trackingType: null,
              verifiedAddress: "-1",
              isApproved: "-1",
              orderNote: "0",
              isImport: "0",
              status: "-1",
              insertMethod: "-1",
              paymentStatus: "-1",
              isDuplicated: "-1",
              typeDate: "1",
              storeId: "0",
              orderName: "",
              date_range: [null, null]
            });
            setTimeout(() => props.dispatch(submit("AdvancedFilterOrdersArchived")), 1000);
          }}
        />
      </Affix>
    </FilterWrapper>
  );
};
const selector = formValueSelector("AdvancedFilterOrdersArchived");

const AdvancedFilterReduxForm = reduxForm({
  form: "AdvancedFilterOrdersArchived",
  enableReinitialize: true,
  //validate,
})(AdvancedFilter);

const mapStateToProps = (state) => ({
  productId: selector(state, "productId"),
  sizeIds: selector(state, "sizeIds") || [],
  colorId: selector(state, "colorId"),
  colorIds: selector(state, "colorIds") || [],
  date_range: selector(state, "date_range") || null,
  AdvancedFilterOrdersArchived: state.form.AdvancedFilterOrdersArchived?.values || {},
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change("AdvancedFilterOrdersArchived", field, value));
    },
  };
};
export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(AdvancedFilterReduxForm)
);