import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import FormInvoice from './components/FormInvoice';
import {
  getListCustomerConfig,
  updateInvoiceConfigs,
  getAuthorization,
} from './actions';
import { Wrapper } from './styled';

class LegalInfo extends Component {
  constructor(props) {
    super(props);
    const { listCustomerConfig } = props;
    this.state = {
      activeKeyTag: '1',
      value: {
        email: listCustomerConfig?.data?.invoice_email?.value || '',
        phone: listCustomerConfig?.data?.invoice_phone?.value || '',
        firstName: listCustomerConfig?.data?.invoice_first_name?.value || '',
        lastName: listCustomerConfig?.data?.invoice_last_name?.value || '',
        address: listCustomerConfig?.data?.invoice_address?.value || '',
        address2: listCustomerConfig?.data?.invoice_extended_address?.value || '',
        city: listCustomerConfig?.data?.invoice_city?.value || '',
        state: listCustomerConfig?.data?.invoice_state?.value || '',
        postalCode: listCustomerConfig?.data?.invoice_postalcode?.value || '',
        country: listCustomerConfig?.data?.invoice_country_name?.value || 'US',
      }
    };
  }

  componentDidMount() {
    let state = { ...this.props.history.location.state };
    let { activeTag = '1' } = state;
    this.setState({ activeKeyTag: activeTag });
    this.props.getAuthorization();
    this.props.getListCustomerConfig();
  }
  componentDidUpdate(prevProps) {
    const {
      listCustomerConfig, authorization
    } = this.props;
    if (JSON.stringify(prevProps.listCustomerConfig?.data) !== JSON.stringify(listCustomerConfig?.data) || prevProps.authorization?.type !== authorization?.type) {
      this.setState({
        value: {
          email: listCustomerConfig?.data?.invoice_email?.value || '',
          phone: listCustomerConfig?.data?.invoice_phone?.value || '',
          firstName: listCustomerConfig?.data?.invoice_first_name?.value || '',
          lastName: listCustomerConfig?.data?.invoice_last_name?.value || '',
          address: listCustomerConfig?.data?.invoice_address?.value || '',
          address2: listCustomerConfig?.data?.invoice_extended_address?.value || '',
          city: listCustomerConfig?.data?.invoice_city?.value || '',
          state: listCustomerConfig?.data?.invoice_state?.value || '',
          postalCode: listCustomerConfig?.data?.invoice_postalcode?.value || '',
          country: listCustomerConfig?.data?.invoice_country_name?.value || 'US',
          authorizationType: authorization?.type,
        }
      });
    }
  }
  updateInvoiceConfigs = (value) => {
    let dataToUpdate = {
      configs: {
        storeName: value.storeName,
        email: value.email,
        phone: value.phone,
        firstName: value.firstName,
        lastName: value.lastName,
        address: value.address,
        address2: value.address2,
        city: value.city,
        state: value.state,
        postalCode: value.postalCode,
        country: value.country,
      },
    };
    this.props.updateInvoiceConfigs(dataToUpdate);
  };

  handleChangeTab = (activeKey) => {
    this.setState({ activeKeyTag: activeKey });
  };

  render() {
    const {
      listCustomerConfig,
      updateInvoiceLoading,
      pageName,
      authorization,
      authorizationLoading,
    } = this.props;
    const initialValues = this.state.value;
    return (
      <Wrapper pageName={pageName}>
        <p className="message">
          Here you can add or update your legal information. We will use these
          details on all your invoices
        </p>
        <div className="pt-2 pb-4">
          <Spin spinning={authorizationLoading || listCustomerConfig.loading || updateInvoiceLoading}>
            {authorization?.type === 'main' ? (
              <Spin
                spinning={listCustomerConfig.loading || updateInvoiceLoading}
              >
                <FormInvoice
                  is_mobile={this.props.is_mobile}
                  initialValues={initialValues}
                  onSubmit={this.updateInvoiceConfigs}
                  listCustomerConfig={listCustomerConfig}
                />
              </Spin>
            ) : (
              <div style={{ maxWidth: 800, margin: '0 auto' }}>
                <h5
                  className="text-uppercase "
                  style={{ maxWidth: 800, margin: '0 auto' }}
                >
                  Invoice Settings
                </h5>
                <Spin
                  spinning={listCustomerConfig.loading || updateInvoiceLoading}
                >
                  <FormInvoice
                    invoice={listCustomerConfig}
                    initialValues={initialValues}
                    onSubmit={this.updateInvoiceConfigs}
                  />
                </Spin>
              </div>
            )}
          </Spin>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    listCustomerConfig: state.setting.general.listCustomerConfig,
    updateInvoiceLoading: state.setting.general.updateInvoiceConfigs.loading,
    authorization: state.setting.general.authorization.data,
    authorizationLoading: state.setting.general.authorization.loading,
    profile: state.auth.info.profile,
  }),
  (dispatch) => ({
    getListCustomerConfig: () => {
      dispatch(getListCustomerConfig());
    },
    getAuthorization: () => {
      dispatch(getAuthorization());
    },
    updateInvoiceConfigs: (dataToUpdate) => {
      dispatch(updateInvoiceConfigs(dataToUpdate));
    },
  })
)(LegalInfo);
