import styled from 'styled-components';
import PageContainer from 'components/PageContainer';
import {
  BASE_WHITE,
  BASE_BLACK,
  GRAY_50,
  GRAY_100,
  GRAY_200,
  GRAY_300,
  GRAY_400,
  GRAY_700,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_300,
  PRIMARY_500,
  PRIMARY_800,
  ERROR_500,
  ERROR_800,
  SHADOW_XS,
  SHADOW_LG_0_08,
  SHADOW_LG_0_03,
  SHADOW_SM_0_1,
  SHADOW_SM_0_06,
  GRAY_500,
  GRAY_800,
} from 'components/colors';
import { Tag } from 'antd';

export const Wrapper = styled(PageContainer)`
  .file-type-section .file-type-item {
    border-radius: 8px;
  }
  .ant-input-group-wrapper .ant-input-wrapper {
    padding: 0;
    padding-right: 32px;
    .ant-input {
      border: none;
      padding: 0;
    }
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border: none;
  }
  textarea,
  .ant-picker-range,
  .ant-input-wrapper,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input-affix-wrapper {
    height: 44px;
    line-height: 44px;
    padding: 0 14px;
    outline: none;
    width: 100%;

    overflow: hidden;
    color: ${GRAY_900};
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.ant-input {
      border-radius: 8px !important;
    }
    &:placeholder {
      color: ${GRAY_400};
    }
    &:focus-visible,
    &:focus {
      outline: none;
      border: 1px solid ${PRIMARY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    }
    &:disabled {
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: ${GRAY_700};
    }
  }
  .optionShip {
    .ant-input-wrapper {
      display: table;
      border: none;
      padding-right: 0;
      .ant-input-affix-wrapper {
        width: 100%;
        height: 100%;
        padding-right: 0;
      }
    }
  }
  button {
    display: flex;
    align-items: center;
    white-space: nowrap;
    svg {
      width: 20px;
      margin-right: 8px;
    }
  }
  .add-form {
    display: flex;
    align-items: center;
    gap: 12px;
    .position-relative {
      width: 100%;
    }
  }
  .ctas {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
  }

  .filter {
    .gmtextbox {
      width: 100%;
      max-width: 400px;
    }
    &-section {
      display: flex;
    }
    &-button {
      line-height: 44px;
      height: 44px;
      background: ${BASE_WHITE};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      border-radius: 8px;
      margin-left: 12px;
      padding: 0 18px;
      vertical-align: middle;
      cursor: pointer;
      svg.filter {
        stroke: ${GRAY_700};
        margin: 0;
        margin-right: 8px;
      }
    }
  }

  svg {
    &.action {
      &-delete {
        stroke: ${ERROR_500};
        &:hover {
          stroke: ${ERROR_800};
        }
      }
      &-edit {
        stroke: ${GRAY_500};
        &:hover {
          stroke: ${GRAY_800};
        }
      }
      &-delete,
      &-edit {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const ContextMenuWrapper = styled.div`
  background: ${BASE_WHITE};
  border: 1px solid ${GRAY_200};
  box-shadow: 0px 12px 16px -4px ${SHADOW_LG_0_08},
    0px 4px 6px -2px ${SHADOW_LG_0_03};
  border-radius: 8px;
  padding: 6px;
  a {
    height: 38px;
    border-radius: 6px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    color: ${GRAY_700};
    &:hover {
      background: ${GRAY_100};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: ${GRAY_700};
      &.sync {
        fill: ${BASE_BLACK};
      }
    }
  }
`;

export const Hotfix_CreateOrderWrapepr = styled.div`
  textarea,
  .ant-picker-range,
  .ant-input-wrapper,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input-affix-wrapper {
    height: 44px;
    line-height: 44px;
    padding: 0 14px;
    outline: none;
    width: 100%;

    overflow: hidden;
    color: ${GRAY_900};
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.ant-input {
      border-radius: 8px !important;
    }
    &:placeholder {
      color: ${GRAY_400};
    }
    &:focus-visible,
    &:focus {
      outline: none;
      border: 1px solid ${PRIMARY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    }
    &:disabled {
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: ${GRAY_700};
    }
  }
  .ant-input-wrapper {
    padding-left: 0;
  }
  .optionShip {
    .ant-input-wrapper {
      display: table;
      border: none;
      padding-right: 0;
      .ant-input-affix-wrapper {
        width: 100%;
        height: 100%;
        padding-right: 0;
      }
    }
  }
`;

export const GMBox = styled.div`
  padding: 24px;
  background: ${BASE_WHITE};
  border: 1px solid ${GRAY_200};
  box-shadow: 0px 1px 3px ${SHADOW_SM_0_1}, 0px 1px 2px ${SHADOW_SM_0_06};
  border-radius: 16px;
`;

export const GMTag = styled(Tag)`
  border-radius: 4px;
  display: flex;
  align-items: center;
  ${p => {
    let rs = ``;
    if (p.textColor) {
      rs += `
        color: ${p.textColor};
        svg {
          fill: ${p.textColor};
        }
      `
    }
    if (p.borderColor) {
      rs += `
        border-color: ${p.borderColor};
      `;
    }
    return rs;
  }}
`;
