// DEV config
// export const WS_HOST = "https://app-prod-api.geadev.com/seller"
// export const WS_API = `${WS_HOST}`;
// https://api.gearment.com/app/v1

export const WS_HOST = 'https://api.gearment.com/app/v1';
export const WS_API = `${WS_HOST}`;
// export const WS_HOST = 'https://api.geadev.com';
// export const WS_API = `${WS_HOST}/portal`;
export const MEDIA_URL = process.env.REACT_APP_ENV === 'production' ? '/' : '/'
export const TINY_MCE_KEY = 'hedojbiuofkr0i4lly2jxjzfobxmbjasqcp5lvf3fvibpay6';
export const REACT_APP_CF_TURNSTILE_SITE_KEY='0x4AAAAAAAwrgVFi2JfhZ_y4';
export const API_TOKEN_KEY = 'gea_api_token';
export const SHOPIFY_INTEGRATE = 'shopify_integrate';
export const EBAY_INTEGRATE = 'ebay_integrate';
export const S3_CDN = 'https://d33jplnp2vyxjf.cloudfront.net';
export const GFLARE_CDN = 'https://https://cdn.geaflare.com';
export const GOOGLE_AP_PLACES_KEY = 'AIzaSyCiV5dbZcYblf5cmU9GTUEoKwrWnme4G9I';
export const FIREBASE_API_KEY = 'AIzaSyAi0tU4FWnF14sAIr6TOGYvGg_eziHZkkE';
export const FIREBASE_AUTH_DOMAIN = 'gearment-app-v2-65f42.firebaseapp.com';
export const FIREBASE_DATABASE_URL = '';
export const FIREBASE_PROJECT_ID = 'gearment-app-v2-65f42';
export const FIREBASE_STORAGE_BUCKET = 'gearment-app-v2-65f42.appspot.com';
export const FIREBASE_MESSAGING_SENDER_ID = '904124513212';
export const FIREBASE_APP_ID = '1:904124513212:web:4859f3a3989d554eb849b9';
export const FIREBASE_MEASUREMENT_ID = undefined;
export const ENVIRONMENT = 'development';
export const RECAPTCHA3_KEY = '6LcssCUjAAAAAJqeWRcG7hkCXTPjUuT24gEbdrnJ';
export const RECAPTCHA3_SECRET = '6LcssCUjAAAAAMGa2cv5PSxfnfOKCOjGeWEaC62u';
