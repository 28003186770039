import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import FieldSelect from 'utils/fields/FieldSelect';
import { options_delivery, options_active_webhooks } from 'utils/options';
import { renderInputText, renderRadioAnt } from 'utils/Form';
import validate from './validate';
import { GMButton } from 'components/Button';
import { GMSelect, GMTextbox } from 'components/InputFields';
const formEditWebhooks = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <GMTextbox
          label="Webhooks name"
          placeholder="Webhooks name"
          name="name_webhooks"
          maxLength={'50'}
          showError
        />
      </div>
      <div className="form-group">
        <GMTextbox
          label="Delivery URL"
          placeholder="Delivery URL"
          name="url_webhooks"
          showError
        />
      </div>
      <div className="form-group">
        <GMSelect
          label="Topic"
          placeholder="Topic"
          name="webhooks_topic"
          options={options_delivery}
          showError
        />
      </div>
      <div className="form-group">
        <Field
          name="webhooks_status"
          component={renderRadioAnt}
          options={[
            {
              label: `Active`,
              value: 1,
            },
            {
              label: `Deactive`,
              value: 2,
            },
          ]}
        />
      </div>
      <div className="text-center d-flex align-items-center justify-content-end">
        <GMButton>Save</GMButton>
      </div>
    </form>
  );
};

formEditWebhooks.propTypes = {};

const formEditWebhookForm = reduxForm({
  form: 'formEditWebhooks',
  enableReinitialize: true,
  destroyOnUnmount: true,
  validate,
})(formEditWebhooks);
export default connect((state) => ({}))(formEditWebhookForm);
