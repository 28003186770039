import { GRAY_100, GRAY_200, GRAY_600, PRIMARY_500 } from 'components/colors';
import styled from 'styled-components';

export default styled.div`
  .ant-radio {
    display: none;
  }

  .payment-method {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 208px;
    max-width: 100%;
    height: 48px;
    border: 1px solid ${GRAY_200};
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    float: left;
    margin-bottom: 10px;
    margin: 5px;
    padding: 5px;


    &:last-child {
      margin-right: 0;
    }
    &.checked {
      border: 1px solid #169dd8;
    }
  }

  .ant-radio-wrapper.ant-radio-wrapper-disabled.payment-method {
    pointer-events: none;
      filter: grayscale(1);
      svg {
        opacity: 0.3;
      }
  }
`;
