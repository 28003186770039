import React, { useState, useEffect } from 'react';

import Breadcrumb from 'components/Breadcrumb';
import { Wrapper } from './styled';
import Form from './components/form';
import { useHistory } from "react-router-dom";
import { getDetailIssue } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GMButton } from 'components/Button';
import GMHelmet from 'components/GMHelmet';
import { postfix } from 'utils/options';

function DetailIssue({ pageName, ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();
  const detailIssue = useSelector(
    (state) => state.order.issue_detail.detail?.data?.issue
  );
  useEffect(() => {
    dispatch(getDetailIssue(id));
  }, [dispatch, id]);
  return (
    <Wrapper>
      {id ? (
        <GMHelmet
          overrideTitle={`Issue #${id || ''}` + postfix}
        />
      ) : null}
      <Breadcrumb
        details={{
          [id]: id && `Issue #${id || ''}`,
        }}
      />
      <h2 className="header">
        <span>{id && `Issue #${id || ''}: View Details`}</span>
        {detailIssue?.status === 1 && (
          <span>
            <GMButton
              size="md"
              color={'SecondaryGray'}
              className="mr-4"
              onClick={() => history.push(`/orders/order-issues/${detailIssue.id}/edit`)}
            >
              Edit
            </GMButton>
          </span>
        )}
      </h2>
      <div>
        <Form detailIssue={detailIssue} />
      </div>
    </Wrapper>
  );
}

export default DetailIssue;
