import React from 'react';
import { ConnectStoreWrapper } from 'scenes/Dashboard/styled';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import GearmentIcon from 'images/Logo_store/full/gearment.svg';
import GearmentApiIcon from 'images/Logo_store/full/gearment-api.svg';
import ShopifyIcon from 'images/Logo_store/full/shopify.svg';
import AmazonIcon from 'images/Logo_store/full/amazon.svg';
import EtsyIcon from 'images/Logo_store/full/etsy.svg';
import WooIcon from 'images/Logo_store/full/woo.svg';
import EbayIcon from 'images/Logo_store/full/ebay.svg';
import OrderDeskIcon from 'images/Logo_store/full/order-desk.svg';
import ShopbaseIcon from 'images/Logo_store/full/shopbase.svg';
import { setCookie } from 'utils/helper';
import { SHOPIFY_INTEGRATE } from 'web.config';

export const platforms = [
  {
    icon: <img src={ShopifyIcon} />,
    name: 'Shopify',
    url: 'https://apps.shopify.com/gearment-print-on-demand-solution',
    extLink: true,
    // tooltip: 'Coming soon',
    // available: false,
  },
  {
    icon: <img src={AmazonIcon} />,
    name: 'Amazon',
    url: '/stores?type=amazon',
    extLink: '',
  },
  {
    icon: <img src={EtsyIcon} />,
    name: 'Etsy',
    url: '/stores?type=etsy',
    extLink: '',
  },
  {
    icon: <img src={WooIcon} />,
    name: 'Woo Commerce',
    url: '/stores?type=wordpress',
    extLink: '',
  },
  {
    icon: <img src={EbayIcon} />,
    name: 'Ebay',
    url: '/stores?type=ebay',
    // available: true,
    extLink: '',
  },
  {
    icon: <img src={OrderDeskIcon} />,
    name: 'Order Desk',
    url: '/stores?type=orderdesk',
    extLink: '',
  },
  {
    icon: <img src={ShopbaseIcon} />,
    name: 'ShopBase',
    url: '/stores?type=shopbase',
    extLink: '',
  },
  {
    icon: <img src={GearmentApiIcon} />,
    name: 'Gearment API',
    url: 'https://api.gearment.com/',
    extLink: true,
  },
  {
    icon: <img src={GearmentIcon} />,
    name: 'Gearment',
    url: '/stores?type=store_default',
    extLink: false,
  },
  // {
  //   icon: <img src={CustomilyIcon} />,
  //   name: 'Customily',
  //   url: '/stores',
  //   extLink: '',
  // },
  // {
  //   icon: <img src={TeeinblueIcon} />,
  //   name: 'teeinblue',
  //   url: '/stores',
  //   extLink: '',
  // },
  // {
  //   icon: <img src={SellerwixIcon} />,
  //   name: 'Sellerwix',
  //   url: '/stores',
  //   extLink: '',
  // },
];

function Index({ className }) {
  const noClick = (evt) => evt.preventDefault();
  const handleNavigateToShopify = () => {
    setCookie(
      SHOPIFY_INTEGRATE,
      `GM_APP_${Math.floor(100000000 + Math.random() * 900000000)}`
    );
    localStorage.setItem('SHOPIFY_INTEGRATE', 'SHOPIFY_INTEGRATE');
    window.open('https://apps.shopify.com/gearment-print-on-demand-solution');
  };
  return (
    <ConnectStoreWrapper className={className}>
      <h3 className="title">Connect store</h3>
      <div className="inner-wrapper">
        {platforms.map((p, index) => (
          <Tooltip title={p?.tooltip || p?.name} key={'platform_' + index}>
            {p.name === 'Shopify' ? (
              <Link
                onClick={handleNavigateToShopify}
                className={classNames({ disabled: p?.available === false })}
              >
                {p.icon}
              </Link>
            ) : (
              <a
                href={p.url}
                target="_blank"
                className={classNames({ disabled: p?.available === false })}
              >
                {p.icon}
              </a>
            )}
          </Tooltip>
        ))}
      </div>
    </ConnectStoreWrapper>
  );
}

export default React.memo(Index);
