import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination, Table, Tooltip } from "antd";
import { getList } from "./actions";
import queryString from "query-string";
import getMomentInstance from "utils/moment";
import { getOptionLabel, options_active } from "utils/options";
import edit from "images/edit.svg";
import { ReactComponent as EditIcon } from 'images/icons/edit-01.svg';
import { checkFunctionalPermission } from "utils/helper";
import { Wrapper } from "./styled";
import { GMButton } from "components/Button";
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { push } from "connected-react-router";

class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        sortBy: "id",
        sortType: "desc",
        platformId: "3",
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 50, platformId, ...options }) => {
    let params = {
      page,
      perPage,
      platformId: [platformId],
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getList(params);
  };
  handleSearchDataFeed = (values) => {
    const params = {};
    if (values.keyword) {
      params["name"] = values.keyword;
    }
    this.fetchData(params);
  };
  render() {
    const { data_feeds, pageName, profile } = this.props;
    const columns = [
      {
        title: '#',
        dataIndex: "id",
        align: "center",
        key: "id",
        render: (text, record) => (
          <Link
            to={`/data-feed/${record.id}/${parseInt(record.platformId) === 3 ? "amazon" : "google"
              }`}
            className="mr-1"
          >
            #{text}
          </Link>
        ),
      },
      {
        title: 'Form name',
        dataIndex: "name",
        align: "center",
        key: "name",
        render: (text) => <span>{text}</span>,
      },
      // {
      //   title: 'Platform',
      //   dataIndex: "platformId",
      //   align: "center",
      //   key: "platformId",
      //   render: (value) =>
      //     getOptionLabel(options_datafeed_platform_type, value, false, value),
      // },
      {
        title: 'Form default',
        dataIndex: "default",
        align: "center",
        key: "default",
        render: (value) => (value ? "Yes" : "No"),
      },
      {
        title: 'Status',
        dataIndex: "status",
        align: "center",
        key: "status",
        render: (value) => getOptionLabel(options_active, value),
      },
      {
        title: 'Created time',
        dataIndex: "createdAt",
        align: "center",
        key: "createdAt",
        render: (text, record) => getMomentInstance(text, 0, true),
      },
      {
        title: 'Action',
        key: "action",
        align: "center",
        render: (_, record) => (
          <div>
            <Tooltip title="Edit" placement="top">
              {" "}
              <Link
                to={`/data-feed/${record.id}/${parseInt(record.platformId) === 3 ? "amazon" : "google"
                  }`}
                className="mr-1 edit"
              >
                {/* <img src={edit} height={21} alt="Icon" /> */}
                <EditIcon />
              </Link>
            </Tooltip>
          </div>
        ),
      },
    ];

    return (
      <Wrapper
        pageName={pageName + ' ' + (parseInt(this.state.initial_filter_values?.platformId) === 3
          ? "Amazon"
          : "Google")
        }
        forcePathName={'Data Feed ' + (parseInt(this.state.initial_filter_values?.platformId) === 3
          ? "Amazon"
          : "Google")}
        ctas={(
          <>
            {checkFunctionalPermission(profile, "createFeedPermission") && (
              <div className="mb-3 mb-md-0">
                <GMButton
                  type="button"
                  className="addnewbtn"
                  onClick={() => this.props.dispatch(push('/products/data-feed/amazon'))}
                >
                  <PlusIcon />Create
                </GMButton>
                <GMButton
                  type="button"
                  className="addnewbtn"
                  onClick={() => this.props.dispatch(push('/products/data-feed/google'))}
                >
                  <PlusIcon />Create
                </GMButton>
              </div>
            )}
          </>
        )}
      >
        <div className="m-3">
          <Table
            rowKey="id"
            columns={columns}
            dataSource={data_feeds.data}
            loading={data_feeds.loading}
            scroll={{ x: 1000 }}
            pagination={
              !this.props.is_mobile
                ? {
                  showTotal: (total) => `Total ${total} items`,
                  total: data_feeds.total,
                  pageSize: parseInt(
                    data_feeds.params.perPage > data_feeds.params.total
                      ? data_feeds.params.total
                      : data_feeds.params.perPage
                  ),
                  current: data_feeds.params.page,
                  showSizeChanger: true,
                  onChange: (page, perPage) =>
                    this.fetchData({ ...data_feeds.params, page, perPage }),
                }
                : false
            }
          />
          {this.props.is_mobile && (
            <div className="d-flex justify-content-end align-items-center py-3 px-3">
              <div>
                <Pagination
                  size="small"
                  showSizeChanger
                  className="pagination"
                  total={data_feeds.total}
                  defaultCurrent={data_feeds?.params?.page || 1}
                  pageSize={data_feeds?.params?.perPage || 0}
                  onChange={(page, perPage) =>
                    this.fetchData({
                      ...data_feeds.params,
                      page:
                        page === Number.NEGATIVE_INFINITY
                          ? 1
                          : page === 0
                            ? 1
                            : page,
                      perPage,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    data_feeds: state.data_feed.list.data_feeds,
    profile: state.auth.info.profile,
  }),
  (dispatch) => ({
    dispatch,
    getList: (options) => {
      dispatch(getList(options));
    },
  })
)(List);
