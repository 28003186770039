import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "./components/form";
import { Button, Spin } from "antd";
import BackToIcon from "../../../../images/chevron-down.svg";
import { createWebhooks } from "./actions";
import { options_delivery, options_active_webhooks } from "utils/options";
import { Wrapper } from "./styled";
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { GMButton } from "components/Button";
import { push } from "connected-react-router";

export class index extends Component {
  onCreateFormSubmit = (values) => {
    const dataToCreate = {
      name: values.name_webhooks ? values.name_webhooks : "",
      url: values.url_webhooks ? values.url_webhooks : "",
      status: values.webhooks_status
        ? values.webhooks_status
        : options_active_webhooks[0]["value"],
      topic: values.webhooks_topic
        ? values.webhooks_topic
        : options_delivery[0]["value"],
    };
    this.props.createWebhooks(dataToCreate, this.props.currStore);
  };

  render() {
    const { creating, pageName } = this.props;
    const initialValues = {
      name_webhooks: "",
      webhooks_topic: `${options_delivery[0]["value"]}`,
      url_webhooks: "",
      webhooks_status: options_active_webhooks[0]["value"],
    };
    return (
      <Wrapper
        pageName={pageName}
      >
        <Spin spinning={creating}>
          <Form
            initialValues={initialValues}
            onSubmit={this.onCreateFormSubmit}
          />
        </Spin>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  creating: state.webhooks.create.creating,
  // currStore: state.store.listStore.currStore,
});

const mapDispatchToProps = (dispatch) => ({
  createWebhooks: (dataToCreate) => {
    dispatch(createWebhooks(dataToCreate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
