import React from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
const renderInputText = ({
  input,
  prefix = null,
  suffix = null,
  addonBefore = null,
  addonAfter = null,
  className = '',
  placeholder = '',
  size = 'large',
  meta: { error, invalid, touched, submitFailed },
  disabled = false,
  defaultValue = '',
  maxLength = 255,
  min = 0,
  classNameContainer = '',
  isNumberInput = false,
  isNumberInputQuantity = false,
  id = null,
  showError = false,
}) => {
  const handleOnChange = (currValue) => {
    if (isNumberInput) {
      const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
      if (
        !(!Number.isNaN(currValue) && reg.test(currValue)) ||
        currValue === '' ||
        currValue === '-'
      ) {
        currValue = min;
      }
    }
    if (isNumberInputQuantity) {
      const reg = /^\d?\d{0,3}$/;
      if (
        !((!Number.isNaN(currValue) && reg.test(currValue)) || currValue === '')
      ) {
        return currValue;
      }
    }

    input.onChange(currValue);
  };
  return (
    <div
      className={classNames(
        'position-relative w-100 optionShip',
        classNameContainer
      )}
    >
      <Input
        id={id}
        allowClear
        disabled={disabled}
        value={input.value || defaultValue}
        size={size}
        className={classNames(className)}
        onChange={(e) => handleOnChange(e.target.value)}
        placeholder={placeholder}
        prefix={prefix}
        suffix={suffix}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        defaultValue={defaultValue}
        maxLength={maxLength}
      />
      {(showError ? error : error && submitFailed) && (
        <span className="invalid-feedback">{error}</span>
      )}
    </div>
  );
};

export default React.memo(renderInputText);
