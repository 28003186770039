import React from 'react';
import { GMButton } from 'components/Button';
import { paymentOrder as paymentOrderAction } from '../../List/actions';
import { useDispatch, useSelector } from 'react-redux';
import { toFixed } from 'utils/helper';

export default ({ data }) => {
  const orders2Pay = Object.values(data?.data?.orders || {});
  const dispatch = useDispatch();
  const orders = Object.values(data?.data?.orders || {});
  const primaryBillingPaymentAccount = useSelector(
    (s) => s.billingPaymentAccount?.primary?.billing
  );
  const prcPayout = () => {
    if (orders.length <= 0) {
      return false;
    }
    dispatch(
      paymentOrderAction({ orders: orders?.map((item) => item.orderId) || [] })
    );
  };
  return (
    <div className="block">
      <h4>
        <span>My orders</span>
        <span className="sub">{orders2Pay.length} orders</span>
      </h4>
      <div className="sum">
        <span className="label">Total</span>
        <span className="value">${toFixed(data?.data?.amount || 0)}</span>
      </div>
      <GMButton
        type="button"
        onClick={prcPayout}
        disabled={orders.length <= 0 || primaryBillingPaymentAccount === null}
      >
        Pay now {orders.length > 0 ? `(${orders.length})` : null}
      </GMButton>
    </div>
  );
};
