import React, { Component } from 'react'
import { Select, Divider, notification } from 'antd';
import classNames from 'classnames'
import axios from 'utils/request'

export default class Index extends Component {
  state = {
    options: [],
    fetched: false,
    loading: false,
  }
  componentDidMount() {
    // if(input.value){
    //   if(keySelect === 'id'){
    //     this.search({ ids: input.value })
    //   }else{
    //     this.search({ q: input.value })
    //   }
    // }else if(requestFrom === 'url' && query_params[input.name]){
    //   if(keySelect === 'id'){
    //     this.search({ ids: query_params[input.name] })
    //   }else{
    //     this.search({ q: query_params[input.name] })
    //   }
    // }
    this.search()
  }
  componentDidUpdate(prevProps, prevState) {
    const { requestFrom, input, keySelect } = this.props
    if(!this.state.fetched && requestFrom === 'props' && input.value){
      if(keySelect === 'id'){
        this.search({ ids: input.value })
      }else{
        this.search({ q: input.value })
      }
      this.setState({
        fetched: true
      })
    }
  }
  
  onSelect = data => {
    const { input, mode = 'default' } = this.props
    input.onChange(typeof data === 'undefined' ? (mode === 'default' ? null : []) : data)
  };
  handleSearch = (name) =>{
    const { minKeyword } = this.props
    if(!minKeyword || name.length >= minKeyword){
      this.search({ name })
    }
  }
  search =(params)=>{
    const { keySelect='id' } = this.props
    this.setState({
      loading: true
    })
    axios({
      method: 'GET',
      params: {
        // perPage: 10,
        // page: 1,
        ...params
      },
      url: `/ex-products`
    }).then((data) => {
      this.setState({
        loading: false
      })
      if(data.code === 'success'){
        this.setState({
          fetched: true,
          options: data.data.exProducts.map(row=>({...row, value: row[keySelect], label: row.name })),
        })
      }else{
        notification.warning({
          message: data.message || 'SEARCH ERROR'
        })
      }
    })
    .catch(error => { 
      this.setState({
        loading: false
      })
      notification.warning({
        message: error.message || 'SEARCH ERROR'
      })
     })
  }
  render() {
    const {
      input,
      className,
      placeholder,
      size,
      meta: { error, invalid },
      mode = 'default',
      minKeyword=0,
      disabled,
    } = this.props
    const { options, loading } = this.state
    const select_options = options.length > 0 ? options : (this.props.options || [])
    const value = mode === 'default' ? input.value.toString() : input.value
    return (
      <div className="position-relative">
        <Select
          disabled={disabled}
          loading={loading}
          allowClear
          showSearch
          value={value || (mode === 'default' ? undefined : [])}
          className={classNames(className)}
          style={{ width: '100%' }}
          size={size || 'large'}
          placeholder={placeholder}
          // defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={this.handleSearch}
          onChange={this.onSelect}
          dropdownRender={menu => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0 0 0' }} />
              {!!minKeyword && <div className="px-3 py-2 text-muted bg-light">at least {minKeyword} words</div>}
            </div>
          )}
        >
          {select_options.map(d => <Select.Option key={d.value}>{d.label}</Select.Option>)}
        </Select>
        {invalid&&<hr className="border-danger m-0 position-absolute" style={{width: '90%', left: '5%', top: 39}}/>}
        {error && <span className="invalid-feedback">{error}</span>}
      </div>
    )
  }
}
