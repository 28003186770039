import { actionType } from './actions';
import { notification } from 'antd';

const initialState = {
  loading: false,
  confirmEmail: false,
  loadingResentEmail: false,
  success: false,
  responseError: {},
};

function VerifyEmailReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.VERIFY_EMAIL.REQUEST:
      return {
        ...state,
        loadingResentEmail: true,
        success: false,
      };
    case actionType.VERIFY_EMAIL.SUCCESS:
      return {
        ...state,
        loadingResentEmail: false,
        success: true,
      };
    case actionType.VERIFY_EMAIL.ERROR:
      return {
        ...state,
        success: false,
        loadingResentEmail: false,
      };

    //Verify token after click email verify account
    case actionType.VERIFY_TOKEN_EMAIL.ERROR:
      return {
        ...state,
        loading: false,
        confirmEmail: false,
        responseError: action,
      };
    case actionType.VERIFY_TOKEN_EMAIL.REQUEST:
      return {
        ...state,
        loading: true,
        confirmEmail: false,
      };
    case actionType.VERIFY_TOKEN_EMAIL.SUCCESS:
      return {
        ...state,
        loading: false,
        confirmEmail: true,
      };

    default:
      return state;
  }
}

export default VerifyEmailReducer;
