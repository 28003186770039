import React, { Component } from 'react'
import { Select, notification } from 'antd';
import classNames from 'classnames'
import axios from 'utils/request'
export default class Index extends Component {
  state = {
    options: [],
    loading: false
  }
  
  componentDidMount() {
      this.getList()
  }
  
  onSelect = data => {
    const { input, mode = 'default' } = this.props
    input.onChange(data === undefined ? (mode === 'default' ? null : []) : data)
  };

  getList =()=>{
    const { keySelect='id', params={} } = this.props
    this.setState({
      loading: true
    })
    axios({
      method: 'GET',
      params,
      url: `/ex-products`
    }).then(({ code, message, data}) => {
      this.setState({
        loading: false
      })
      if(code === 'success'){
        this.setState({
          options: data.exProducts.map(row=>({...row, value: row[keySelect], label: row.name})),
        })
      }else{
        notification.warning({
          message: data.message || 'SEARCH ERROR'
        })
      }
    })
    .catch(error => { 
      this.setState({
        loading: false
      })
      notification.warning({
        message: error.message || 'SEARCH ERROR'
      })
     })
  }
  render() {
    const {
      allowClear,
      input,
      className,
      placeholder,
      size,
      meta: { error, invalid },
      mode = 'default',
      disabled
    } = this.props
    const { options, loading } = this.state
    const value = mode == 'default' ? input.value.toString() : input.value
    return (
      <div className="position-relative">
        <Select
          loading={loading}
          showSearch
          allowClear={allowClear}
          value={value || (mode == 'default' ? undefined : [])}
          className={classNames(className)}
          style={{ width: '100%' }}
          size={size || 'large'}
          placeholder={placeholder}
          onChange={this.onSelect}
          disabled={disabled}
          optionFilterProp="children"
        >
            {options.map(d => <Select.Option key={d.value} >{d.label}</Select.Option>)}
        </Select>
        {invalid&&<hr className="border-danger m-0 position-absolute" style={{width: '90%', left: '5%', top: 39}}/>}
        {error && <span className="invalid-feedback">{error}</span>}
      </div>
    )
  }
}
