import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import validate from './validate';
import { renderSwitchAnt, renderSelectAnt, renderInputText } from 'utils/Form';
import order_desk_logo from 'assets/images/order_desk_logo.png';
import { GMTextbox } from 'components/InputFields';
import Select from 'components/InputFields/Select';
import { Wrapper } from '../styled';
import { GMButton } from 'components/Button';
import { WrapperUpdateButton } from '../../styled';
import { ReactComponent as OrderDeskIcon } from 'images/Logo_store/full/order-desk.svg';
let index = ({
  handleSubmit,
  onCancel,
  accounts,
  currAction,
  profile,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit} className="mx-2">
      <WrapperUpdateButton>
        <div className="text-left my-3">
          <OrderDeskIcon
          />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Store name</b>
          </label>
          <GMTextbox name="showName" showError />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>OrderDesk Store ID </b>
          </label>
          <GMTextbox name="name" showError />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>OrderDesk API Key</b>
          </label>
          <GMTextbox name="storeApiKey" showError />
          <a
            href="https://account.gearment.com/sellerv2/assets/files/order_desk_guide_apikey.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faCode} /> <b>Quick guide Get API Key</b>
          </a>
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>OrderDesk Folder ID</b>
          </label>

          <GMTextbox name="folderId" showError />
          <a
            href="https://account.gearment.com/sellerv2/assets/files/order_desk_guide_folderid.png"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faCode} />
            <b>Quick guide Get Folder ID</b>
          </a>
          <p className="text-muted">
            If folder id is empty then Gearment will synchronize all orders.
          </p>
        </div>
        {parseInt(profile.primaryId) === 0 && (
          <div className="form-group">
            <label className="col-form-label"><b>Staff Account</b></label>
            <Wrapper>
              <Field
                name="staffId"
                component={renderSelectAnt}
                placeholder="-- Select staff --"
                mode={'multiple'}
                options={accounts.map((item) => ({
                  value: item.id.toString(),
                  label: item.name,
                }))}
              />
            </Wrapper>
          </div>
        )}
        <div className="form-group">
          <div className="d-flex align-items-center">
            <GMTextbox name="status" showError component={renderSwitchAnt} />

            <label htmlFor="store_active" className="ml-3 col-form-label">
              Active store
            </label>
          </div>
        </div>
        <div>
          <GMButton className="saveBtn" size="lg" >
            {currAction === 'create' ? 'Connect store' : 'Update store'}
          </GMButton>
        </div>
      </WrapperUpdateButton>
    </form>
  );
};

index = reduxForm({
  form: 'formOrderDeskStore',
  enableReinitialize: true,
  validate,
})(index);

export default connect()(index);
