import { all } from 'redux-saga/effects';
import PreviewSaga from './Preview/saga';
import CreateSaga from './Create/saga';

export default function* () {
  yield all([
    CreateSaga(),
    PreviewSaga(),
    // DeleteSaga()
  ]);
}
