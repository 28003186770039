import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "./components/form";
import { Button, Spin } from "antd";
import BackToIcon from "../../../../images/chevron-down.svg";
import { getDetailPromotions, updatePromotions } from "./actions";
import { options_delivery, options_active_webhooks } from "utils/options";

export class index extends Component {

  componentDidMount() {
    this.props.getDetailPromotions(this.props.match.params.id);
  }
  onEditFormSubmit = (values) => {
    let dataToUpdate = {
      code: values.code ? values.code : "",
      url: values.url_webhooks ? values.url_webhooks : "",
      status: values.webhooks_status
        ? values.webhooks_status
        : options_active_webhooks[0]["value"],
      topic: values.webhooks_topic
        ? values.webhooks_topic
        : options_delivery[0]["value"],
    };
    this.props.updatePromotions(this.props.match.params.id, dataToUpdate);
  };

  render() {
    const { detailPromotions, updatePromotionsLoading, pageName } = this.props;
    const initialValues = {
      code: detailPromotions.data.code || "",
      webhooks_topic: detailPromotions.data.topic || options_delivery[0]["value"],
      url_webhooks: detailPromotions.data.url || "",
      webhooks_status:
        detailPromotions.data.status || options_active_webhooks[0]["value"],
    };
    return (
      <div className="container-fluid">
        <Button
          className="BackToButton d-flex align-items-center"
          onClick={() => {
            this.props.history.push("/promotions");
          }}
        >
          <img src={BackToIcon}  alt="back-icon"/>
          <p className="m-0 ml-2">Back to Promotions</p>
        </Button>
        <div className="d-flex justify-content-between align-items-center my-3">
          <h1 className="h3 mb-0 font-weight-bold">{pageName}</h1>
        </div>
        <Spin spinning={detailPromotions.loading || updatePromotionsLoading}>
          <div className="card">
            <div className="card-body">
              <Form
                initialValues={initialValues}
                onSubmit={this.onEditFormSubmit}
              ></Form>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  updatePromotionsLoading: state.promotion.edit.update.loading,
  detailPromotions: state.promotion.edit.detail,
});

const mapDispatchToProps = (dispatch) => ({
  getDetailPromotions: (id) => {
    dispatch(getDetailPromotions(id));
  },
  updatePromotions: (id, dataToUpdate) => {
    dispatch(updatePromotions(id, dataToUpdate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
