import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import {
  STORE_EDIT_REQUEST,
  STORE_EDIT_SUCCESS,
  STORE_EDIT_FAIL,
  STORE_DETAIL_REQUEST,
  STORE_DETAIL_SUCCESS,
  STORE_DETAIL_FAIL,
  STORE_EDIT_EBAY_REQUEST,
  STORE_EDIT_EBAY_SUCCESS,
  STORE_EDIT_EBAY_FAIL,
  STORE_EDIT_ETSY_REQUEST,
  STORE_EDIT_ETSY_SUCCESS,
  STORE_EDIT_ETSY_FAIL,
  LINK_ETSY_TOKEN_REQUEST,
  LINK_ETSY_TOKEN_SUCCESS,
  LINK_ETSY_TOKEN_FAIL,
  UPDATE_ETSY_TOKEN_REQUEST,
  UPDATE_ETSY_TOKEN_SUCCESS,
  UPDATE_ETSY_TOKEN_FAIL,
  STORE_EDIT_SHOPBASE_REQUEST,
  STORE_EDIT_SHOPBASE_SUCCESS,
  STORE_EDIT_SHOPBASE_FAIL,
  STORE_EDIT_SHOPBASE_TOKEN_REQUEST,
  STORE_EDIT_SHOPBASE_TOKEN_SUCCESS,
  STORE_EDIT_SHOPBASE_TOKEN_FAIL,
  STORE_EDIT_SHOPBASE_NORMAL_REQUEST,
  STORE_EDIT_SHOPBASE_NORMAL_SUCCESS,
  STORE_EDIT_SHOPBASE_NORMAL_FAIL,
  LINK_SHOPBASE_TOKEN_REQUEST,
  LINK_SHOPBASE_TOKEN_SUCCESS,
  LINK_SHOPBASE_TOKEN_FAIL,
  LINK_SHOPBASE_MERCHANT_REQUEST,
  LINK_SHOPBASE_MERCHANT_SUCCESS,
  LINK_SHOPBASE_MERCHANT_FAIL,
  GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_SUCCESS,
  GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_FAIL,
  GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_REQUEST,
  UPDATE_SHOPIFY_TOKEN_SUCCESS,
  UPDATE_SHOPIFY_TOKEN_FAIL,
  UPDATE_SHOPIFY_STORE_SUCCESS,
  UPDATE_SHOPIFY_STORE_FAIL,
  UPDATE_SHOPIFY_STORE_REQUEST,
  UPDATE_SHOPIFY_TOKEN_REQUEST,

  //Update amazon token
  LINK_AMAZON_TOKEN_SUCCESS,
  LINK_AMAZON_TOKEN_FAIL,
  LINK_AMAZON_TOKEN_REQUEST,
  UPDATE_AMAZON_TOKEN_SUCCESS,
  UPDATE_AMAZON_TOKEN_FAIL,
  UPDATE_AMAZON_TOKEN_REQUEST,
} from './constants';
import { getDetail, update } from 'apis/store_integrations';
import {
  editEbay,
  editEtsy,
  editShopbase,
  getLinkStoreShopbaseToken,
  getLinkStoreShopbaseMerchant,
  editShopbaseToken,
  editShopbaseNormal,
  getShopifyLinkForUpdatingToken,
  updateShopifyToken,
  updateShopifyStore,
  updateTokenEtsyStore,
  updateEtsyToken,
  getAmazonLinkForUpdatingToken,
  updateAmazonToken,
} from 'apis/create_store';
import { actionType as TYPE } from '../List/actions';

function* editStoreSaga(action) {
  try {
    const { dataToUpdate, id } = action;
    const response = yield call(update, dataToUpdate, id);
    if (response.code === 'success') {
      const { store } = yield select();
      yield all([
        put({ type: STORE_EDIT_SUCCESS, response }),
        put({
          type: TYPE.LIST.REQUEST,
          params: {
            page: 1,
            perPage: 50,
            sortBy: 'id',
            sortType: 'desc',
          },
        }),
      ]);
    } else {
      yield put({ type: STORE_EDIT_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: STORE_EDIT_FAIL, error })]);
  }
}

function* detailStoreSaga(action) {
  try {
    const { id } = action;
    const response = yield call(getDetail, id);
    if (response.code === 'success') {
      yield put({ type: STORE_DETAIL_SUCCESS, ...response });
    } else {
      yield all([
        put({ type: STORE_DETAIL_FAIL, response }),
        put(replace('/stores')),
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: STORE_DETAIL_FAIL, error }),
      put(replace('/stores')),
    ]);
  }
}
//Edit store Ebay
function* editStoreEbaySaga(action) {
  try {
    const { dataToUpdate, id } = action;
    const response = yield call(editEbay, dataToUpdate, id);
    if (response.code === 'success') {
      const { store } = yield select();
      yield all([
        put({ type: STORE_EDIT_EBAY_SUCCESS, response }),
        put({
          type: TYPE.LIST.REQUEST,
          params: store?.list?.stores?.params || {
            page: 1,
            perPage: 50,
            sortBy: 'id',
            sortType: 'desc',
          },
        }),
      ]);
    } else {
      yield put({ type: STORE_EDIT_EBAY_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: STORE_EDIT_EBAY_FAIL, error })]);
  }
}
//Edit store Etsy
function* editStoreEtsySaga(action) {
  try {
    const { dataToUpdate, id } = action;
    const response = yield call(editEtsy, dataToUpdate, id);
    if (response.code === 'success') {
      const { store } = yield select();
      yield all([
        put({ type: STORE_EDIT_ETSY_SUCCESS, response }),
        put({
          type: TYPE.LIST.REQUEST,
          params: store?.list?.stores?.params || {
            page: 1,
            perPage: 50,
            sortBy: 'id',
            sortType: 'desc',
          },
        }),
      ]);
    } else {
      yield put({ type: STORE_EDIT_ETSY_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: STORE_EDIT_ETSY_FAIL, error })]);
  }
}

//Update token for Etsy store
// 1.1 Get link etsy token
function* getLinkUpdateEtsyTokenSaga(action) {
  try {
    const response = yield call(updateTokenEtsyStore);
    if (response.code === 'success') {
      yield put({ type: LINK_ETSY_TOKEN_SUCCESS, ...response });
    } else {
      yield put({ type: LINK_ETSY_TOKEN_FAIL, response });
    }
  } catch (error) {
    yield all([yield put({ type: LINK_ETSY_TOKEN_FAIL, error })]);
  }
}

//1.2
function* updateEtsyStoreSaga(action) {
  try {
    const { id, data } = action;
    const response = yield call(updateEtsyToken, id, data);
    const { store } = yield select();
    if (response.code === 'success') {
      yield all([
        put({ type: UPDATE_ETSY_TOKEN_SUCCESS, ...response }),
        put(replace('/stores')),
      ]);
    } else {
      yield all([
        put({ type: UPDATE_ETSY_TOKEN_FAIL, error: response }),
        put(replace('/stores')),
      ]);
    }
  } catch (error) {
    yield all([put({ type: UPDATE_ETSY_TOKEN_FAIL, error })]);
  }
}
/** Update token for Amazon store*/
// 1.1 Get link amazon token
function* getAmazonLinkForUpdatingTokenSaga(action) {
  try {
    const response = yield call(getAmazonLinkForUpdatingToken);
    if (response.code === 'success') {
      yield put({ type: LINK_AMAZON_TOKEN_SUCCESS, ...response });
    } else {
      yield put({ type: LINK_AMAZON_TOKEN_FAIL, response });
    }
  } catch (error) {
    yield all([yield put({ type: LINK_AMAZON_TOKEN_FAIL, error })]);
  }
}

//1.2
function* updateAmazonStoreSaga(action) {
  try {
    const { id, data } = action;
    const response = yield call(updateAmazonToken, id, data);
    if (response.code === 'success') {
      yield all([
        put({ type: UPDATE_AMAZON_TOKEN_SUCCESS, ...response }),
        put(replace('/stores')),
      ]);
    } else {
      yield all([
        put({ type: UPDATE_AMAZON_TOKEN_FAIL, error: response }),
        put(replace('/stores')),
      ]);
    }
  } catch (error) {
    yield all([put({ type: UPDATE_AMAZON_TOKEN_FAIL, error })]);
  }
}
//Edit store shopbase
function* editStoreShopbaseSaga(action) {
  try {
    const { dataToUpdate, id } = action;
    const response = yield call(editShopbase, dataToUpdate, id);
    if (response.code === 'success') {
      const { store } = yield select();
      yield all([
        put({ type: STORE_EDIT_SHOPBASE_SUCCESS, response }),
        put({
          type: TYPE.LIST.REQUEST,
          params: store?.list?.stores?.params || {
            page: 1,
            perPage: 50,
            sortBy: 'id',
            sortType: 'desc',
          },
        }),
      ]);
    } else {
      yield put({ type: STORE_EDIT_SHOPBASE_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: STORE_EDIT_SHOPBASE_FAIL, error })]);
  }
}
//Edit shopbase normal
function* editStoreShopbaseNormalSaga(action) {
  try {
    const { dataToUpdate, id } = action;
    const response = yield call(editShopbaseNormal, dataToUpdate, id);
    if (response.code === 'success') {
      yield all([
        put({ type: STORE_EDIT_SHOPBASE_NORMAL_SUCCESS, response }),
        put({
          type: TYPE.LIST.REQUEST,
          params: {
            page: 1,
            perPage: 50,
            sortBy: 'id',
            sortType: 'desc',
          },
        }),
      ]);
    } else {
      yield put({ type: STORE_EDIT_SHOPBASE_NORMAL_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: STORE_EDIT_SHOPBASE_NORMAL_FAIL, error })]);
  }
}
//Get link shopbase token
function* getLinkStoreShopbaseTokenSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getLinkStoreShopbaseToken, params);
    if (response.code === 'success') {
      yield put({ type: LINK_SHOPBASE_TOKEN_SUCCESS, ...response });
    } else {
      yield put({ type: LINK_SHOPBASE_TOKEN_FAIL, response });
    }
  } catch (error) {
    yield all([yield put({ type: LINK_SHOPBASE_TOKEN_FAIL, error })]);
  }
}
//get link edit shopbase merchant
function* getLinkStoreShopbaseMerchantSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getLinkStoreShopbaseMerchant, params);
    if (response.code === 'success') {
      yield put({ type: LINK_SHOPBASE_MERCHANT_SUCCESS, ...response });
    } else {
      yield put({ type: LINK_SHOPBASE_MERCHANT_FAIL, response });
    }
  } catch (error) {
    yield all([yield put({ type: LINK_SHOPBASE_MERCHANT_FAIL, error })]);
  }
}
//Edit shopbase by token
function* editStoreShopbaseTokenSaga(action) {
  try {
    const { dataToUpdate, id } = action;
    const response = yield call(editShopbaseToken, dataToUpdate, id);
    if (response.code === 'success') {
      yield all([
        put({ type: STORE_EDIT_SHOPBASE_TOKEN_SUCCESS, response }),
        put(replace('/stores')),
      ]);
    } else {
      yield put({ type: STORE_EDIT_SHOPBASE_TOKEN_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: STORE_EDIT_SHOPBASE_TOKEN_FAIL, error })]);
  }
}
function* getShopifyLinkForUpdatingTokenSaga(action) {
  const { id } = action;
  try {
    const response = yield call(getShopifyLinkForUpdatingToken, id);
    if (response.code === 'success') {
      yield put({
        type: GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_SUCCESS,
        ...response,
      });
    } else {
      yield put({ type: GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_FAIL, response });
    }
  } catch (error) {
    yield all([
      yield put({ type: GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_FAIL, error }),
    ]);
  }
}

//Update token for Shopify
function* updateShopifyTokenSaga(action) {
  try {
    const { data } = action;
    const response = yield call(updateShopifyToken, data);
    const { store } = yield select();
    if (response.code === 'success') {
      yield all([
        put({ type: UPDATE_SHOPIFY_TOKEN_SUCCESS, ...response }),
        put({
          type: TYPE.LIST.REQUEST,
          params: store?.list?.stores?.params || {
            page: 1,
            perPage: 50,
            sortBy: 'id',
            sortType: 'desc',
          },
        }),
      ]);
    } else {
      yield put({ type: UPDATE_SHOPIFY_TOKEN_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: UPDATE_SHOPIFY_TOKEN_FAIL, error })]);
  }
}

//Edit shopify store
function* updateShopifyStoreSaga(action) {
  try {
    const { data, id } = action;
    const response = yield call(updateShopifyStore, id, data);
    const { store } = yield select();
    if (response.code === 'success') {
      yield all([
        put({ type: UPDATE_SHOPIFY_STORE_SUCCESS, ...response }),
        put({
          type: TYPE.LIST.REQUEST,
          params: store?.list?.stores?.params || {
            page: 1,
            perPage: 50,
            sortBy: 'id',
            sortType: 'desc',
          },
        }),
      ]);
    } else {
      yield put({ type: UPDATE_SHOPIFY_STORE_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: UPDATE_SHOPIFY_STORE_FAIL, error })]);
  }
}

function* editStoreWatcher() {
  yield all([
    takeLatest(STORE_EDIT_REQUEST, editStoreSaga),
    takeLatest(STORE_DETAIL_REQUEST, detailStoreSaga),

    takeLatest(STORE_EDIT_EBAY_REQUEST, editStoreEbaySaga),

    takeLatest(STORE_EDIT_ETSY_REQUEST, editStoreEtsySaga),
    takeLatest(LINK_ETSY_TOKEN_REQUEST, getLinkUpdateEtsyTokenSaga),
    takeLatest(UPDATE_ETSY_TOKEN_REQUEST, updateEtsyStoreSaga),

    takeLatest(STORE_EDIT_SHOPBASE_REQUEST, editStoreShopbaseSaga),
    takeLatest(STORE_EDIT_SHOPBASE_NORMAL_REQUEST, editStoreShopbaseNormalSaga),
    takeLatest(STORE_EDIT_SHOPBASE_TOKEN_REQUEST, editStoreShopbaseTokenSaga),

    takeLatest(
      GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_REQUEST,
      getShopifyLinkForUpdatingTokenSaga
    ),
    takeLatest(UPDATE_SHOPIFY_STORE_REQUEST, updateShopifyStoreSaga),
    takeLatest(UPDATE_SHOPIFY_TOKEN_REQUEST, updateShopifyTokenSaga),

    takeLatest(LINK_SHOPBASE_TOKEN_REQUEST, getLinkStoreShopbaseTokenSaga),
    takeLatest(
      LINK_SHOPBASE_MERCHANT_REQUEST,
      getLinkStoreShopbaseMerchantSaga
    ),

    takeLatest(LINK_AMAZON_TOKEN_REQUEST, getAmazonLinkForUpdatingTokenSaga),
    takeLatest(UPDATE_AMAZON_TOKEN_REQUEST, updateAmazonStoreSaga),
  ]);
}

export default editStoreWatcher;
