import React, { useState } from "react";
import { Form, Button } from "antd";
import { TINY_MCE_KEY } from "web.config";
import { Editor } from '@tinymce/tinymce-react';
function MessageEditor({ submitting = false, onSubmit, ...props }) {
  const [value, setValue] = useState("");
  return (
    <>
      <Form.Item>
        <Editor
          init={{
            height: 150,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help",
          }}
          apiKey={TINY_MCE_KEY}
          rows={4}
          onEditorChange={(content, editor) => {
            setValue(content);
          }}
          value={value}
        />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={() => {
            onSubmit(value);
          }}
          type="primary"
        >
          Send message
        </Button>
      </Form.Item>
    </>
  );
}

export default React.memo(MessageEditor);