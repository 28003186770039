import React from "react";
import { Wrapper } from "./AbsoluteLoader.styled";
const { Spin } = require("antd");

export default ({ loading = false }) => {
  if (!loading) {
    return null;
  }
  return (
    <Wrapper>
      <Spin size="small" />
    </Wrapper>
  );
}
