import { Tooltip } from 'antd';
import { REGEX } from 'components/ModalTopUp/utils';
import { METHOD_LIST } from 'constants/common';
import React from 'react';
import { Link } from 'react-router-dom';
import { shorten3dots } from 'utils/helper';
import getMomentInstance, { getMomentInstance2 } from 'utils/moment';
import money from 'utils/money';
import { getOptionLabel, topup_errors, topup_status } from 'utils/options';

export default () => [
  {
    title: 'ID',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Reference transaction ID',
    align: 'left',
    dataIndex: 'paymentId',
    key: 'paymentId',
    render: (text) => {
      if (text.length > 20) {
        return (
          <Tooltip title={text}>
            {shorten3dots(text, 20)}
          </Tooltip>
        );
      }
      return text;
    },
  },
  {
    title: 'Transaction ID',
    align: 'left',
    dataIndex: 'transaction_id',
    key: 'transaction_id',
    render: (data, row) => {
      return row?.transaction?.name ? (
        <Link
          to={`/billing/transactions/${row?.transaction?.id}/detail?ref=topup`}
        >
          {row?.transaction?.name}
        </Link>
      ) : (
        'N/A'
      );
    },
  },
  {
    title: 'Payment method',
    align: 'center',
    dataIndex: 'type',
    key: 'type',
    render: (type) => {
      return METHOD_LIST.find((method) => parseInt(method.value) === type)
        ?.labelTopUp;
    },
  },
  {
    title: 'Amount',
    align: 'right',
    dataIndex: 'amount',
    key: 'amount',
    render: (text, record) => {
      return <b>{money(text)}</b>;
    },
  },
  {
    title: 'Image',
    align: 'center',
    dataIndex: 'image',
    key: 'image',
    render: (value, record) => {
      const link = value.match(REGEX) ? value : 'https://origin.geaflare.com/' + value;
      return (
        <div>
          {value ? (
            <a href={link} target="_blank">
              <img src={link} alt="bill" width={32} />
            </a>
          ) : (
            'N/A'
          )}
        </div>
      );
    },
  },
  {
    title: 'Created date',
    align: 'center',
    dataIndex: 'createdTime',
    key: 'createdTime',
    render: (text, record) =>
      text
        ? getMomentInstance2({ unixDateTime: text, isDateTimFormat: true })
        : '',
  },
  {
    title: 'Status',
    align: 'center',
    dataIndex: 'status',
    key: 'status',
    render: (text, data) => {
      if (text === 2 && topup_errors?.[data?.rejectReason]) {
        return (
          <Tooltip title={topup_errors[data?.rejectReason]} placement="bottom">
            {getOptionLabel(topup_status, text)}
          </Tooltip>
        );
      }
      return getOptionLabel(topup_status, text);
    },
  },
];
