import { takeLatest, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { getDetail, update } from "apis/webhooks";
import {
  WEBHOOKS_UPDATE_REQUEST,
  WEBHOOKS_UPDATE_FAIL,
  WEBHOOKS_UPDATE_SUCCESS,
  WEBHOOKS_DETAIL_REQUEST,
  WEBHOOKS_DETAIL_FAIL,
  WEBHOOKS_DETAIL_SUCCESS,
} from "./constants";
import { actionType } from "../List/actions";

function* detailSaga(action) {
  try {
    const { id } = action;
    const response = yield call(getDetail, id);
    if (response.code === "success" && response.data.webhook) {
      yield put({ type: WEBHOOKS_DETAIL_SUCCESS, ...response });
    } else {
      yield all([
        yield put({
          type: actionType.LIST.REQUEST,
          error: { message: "Not found webhook" },
        }),
        yield put(replace(`/settings/webhooks/list`)),
      ]);
    }
  } catch (error) {
    yield all([put({ type: WEBHOOKS_DETAIL_FAIL, error })]);
  }
}
function* updateSaga(action) {
  try {
    const { id, dataToUpdate } = action;
    const response = yield call(update, id, dataToUpdate);

    if (response.code === "success") {
      yield all([put({ type: WEBHOOKS_UPDATE_SUCCESS, ...response })]);
    } else {
      yield put({ type: WEBHOOKS_UPDATE_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: WEBHOOKS_UPDATE_FAIL, error })]);
  }
}

function* watcher() {
  yield all([
    takeLatest(WEBHOOKS_UPDATE_REQUEST, updateSaga),
    takeLatest(WEBHOOKS_DETAIL_REQUEST, detailSaga),
  ]);
}

export default watcher;
