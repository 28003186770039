import styled from 'styled-components';

export const Wrapper = styled.div`
  .update-btn {
    display: flex;
    justify-content: end;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .ant-radio-group.ant-radio-group-outline.ant-radio-group-default {
    display: flex;
    flex-direction: column;
  }
  .ant-radio-wrapper {
    width: 100%;
    line-height: 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
    span:last-child {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .select-tracking-time {
    width: 100px
  }
  .block-right {
    padding: 24px;
    gap: 16px;
    /* Base/White */

    background: #ffffff;
    /* Gray/200 */

    border: 1px solid #eaecf0;
    /* Shadow/sm */

    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 16px;
    margin-bottom: 24px;
    .title {
      font-weight: 600;
      font-size: 18px;
    }
  }
`;
