import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { Pagination, Table } from "antd";
import getMomentInstance from "utils/moment";
import {
  getOptionLabel,
  options_platform_type,
  options_schedule_campaign_status,
} from "utils/options";
import { getListLogSyncProduct } from "scenes/Logs/actions";
import { Wrapper } from "scenes/Logs/styled";
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getListLogSyncProduct({
      ...params,
    });
  };
  render() {
    const { sync_products, destroying, pageName } = this.props;
    const columns = [
      {
        title: <b>Id</b>,
        dataIndex: "id",
        align: "center",
        key: "id",
        width: 90,
        render: (value) => (
          <Link
            to={`/logs/sync-product/${value}`}
            className="btn btn-link mr-1"
          >
            #{value}
          </Link>
        ),
      },
      {
        title: <b>Store</b>,
        dataIndex: "storeIntegration",
        align: "center",
        key: "storeIntegration",
        render: (value) => value && value.showName,
      },
      {
        title: <b>Platform</b>,
        dataIndex: "platformType",
        align: "center",
        key: "platformType",
        render: (value) => getOptionLabel(options_platform_type, value),
      },
      {
        title: <b>Time request</b>,
        dataIndex: "requestedAt",
        align: "center",
        key: "requestedAt",
        render: (value) => getMomentInstance(null, value, true),
      },
      {
        title: <b>Time completed</b>,
        dataIndex: "completedAt",
        align: "center",
        key: "completedAt",
        render: (value) => !value ? 'N/A' : getMomentInstance(null, value, true),
      },
      {
        title: <b>Status</b>,
        key: "status",
        align: "center",
        dataIndex: "status",
        render: (value) =>
          getOptionLabel(options_schedule_campaign_status, value),
      },
    ];
    return (
      <Wrapper pageName={pageName}>
        <div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={sync_products.data}
            loading={sync_products.loading || destroying}
            scroll={{ x: 1000 }}
            pagination={!this.props?.is_mobile ? {
              showTotal: (total) => `Total ${total} items`,
              total: sync_products.total,
              pageSize: parseInt(
                sync_products.params.perPage > sync_products.total
                  ? sync_products.total
                  : sync_products.params.perPage
              ),
              current: sync_products.params.page,
              onShowSizeChange: (current, perPage) =>
                this.fetchData({ ...sync_products.params, perPage }),
              onChange: (page, perPage) =>
                this.fetchData({ ...sync_products.params, page, perPage }),
            }: false}
          />
          {this.props.is_mobile && <div className="d-flex justify-content-end align-items-center py-3 px-3">
            <div>
              <Pagination
                size="small"
                showSizeChanger
                className="pagination"
                total={sync_products.total}
                defaultCurrent={sync_products?.params?.page || 1}
                pageSize={sync_products?.params?.perPage || 0}
                onChange={(page, perPage) =>
                  this.fetchData({ ...sync_products.params, page: page === Number.NEGATIVE_INFINITY ? 1 : (page === 0 ? 1 : page), perPage })
                }
              />
            </div>
          </div>}
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    sync_products: state.sync_product.list.sync_products,
  }),
  (dispatch) => ({
    getListLogSyncProduct: (options) => {
      dispatch(getListLogSyncProduct(options));
    },
  })
)(List);
