import React from 'react';
import { DatePicker } from 'antd';
// C:\Users\my_pc\gea-portal-app-account\src\components\InputFields\Textbox.styled.js
const renderField = ({
  input,
  // className,
  size,
  hideTime,
  formatDate = 'YYYY-MM-DD',
  meta: { invalid, touched, error, submitFailed },
  ...props
}) => {
  function disabledDate(current) {
    return current > Date.now();
  }
  return (
    <div>
      <DatePicker
        {...props}
        disabledDate={disabledDate}
        value={input.value}
        size={size || 'large'}
        showTime={hideTime ? false : true}
        format={hideTime ? formatDate : 'YYYY-MM-DD HH:mm'}
        onChange={(value, dateString) => input.onChange(value)}
        onOk={(value, dateString) => input.onChange(value)}
      />
      {error && submitFailed && (
        <span className="invalid-feedback">{error}</span>
      )}
    </div>
  );
};

export default renderField;
