import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { createAccount } from '../../actions';
import CreateForm from '../Create/components/CreateForm';
import { change } from 'redux-form';
import { listPermissions } from 'utils/options';
import { Wrapper } from './styled';
class Create extends Component {
  handleOnSubmitCreateForm = (values) => {
    const dataToCreate = {
      email: values.email || '',
      permissions: {
        fullPermission:
          values.fullPermission && values.fullPermission.includes(1) ? 1 : 0,
      },
    };
    for (let index = 0; index < listPermissions.length; index++) {
      dataToCreate['permissions'][listPermissions[index]] =
        values[listPermissions[index]] &&
        values[listPermissions[index]].includes(1)
          ? 1
          : 0;
    }
    this.props.createAccount(dataToCreate);
  };

  handleOnChangePermission = (name, value) => {
    const { changeFieldValue, formCreateAccountData } = this.props;
    if (name === 'fullPermission') {
      if (value.includes(1)) {
        for (let index = 0; index < listPermissions.length; index++) {
          changeFieldValue(listPermissions[index], [1]);

        }
      } else {
        for (let index = 0; index < listPermissions.length; index++) {
          changeFieldValue(listPermissions[index], [0]);
        }
      }
    } else {
      if (
        !value.includes(1) &&
        formCreateAccountData?.fullPermission &&
        formCreateAccountData?.fullPermission.includes(1)
      ) {
        changeFieldValue('fullPermission', [0]);
      } else if (value.includes(1)) {
        let flag = false;
        for (let index = 0; index < listPermissions.length; index++) {
          if (listPermissions[index] !== name) {
            if (
              Array.isArray(formCreateAccountData[listPermissions[index]]) &&
              formCreateAccountData[listPermissions[index]].includes(1)
            ) {
              flag = true;
            } else {
              flag = false;
              break;
            }
          }
        }

        if (flag === true) {
          changeFieldValue('fullPermission', [1]);

        }
      }
    }
  };

  render() {
    const { pageName, createAccountLoading } = this.props;
    const initialValues = {
      fullName: '',
      email: '',
      fullPermission: [0],
    };
    for (let index = 0; index < listPermissions.length; index++) {
      initialValues[listPermissions[index]] = [0];
    }
    return (
      <Wrapper pageName={pageName}>
        <Spin spinning={createAccountLoading}>
          <CreateForm
            onSubmit={this.handleOnSubmitCreateForm}
            initialValues={initialValues}
            handleOnChangePermission={this.handleOnChangePermission}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    createAccountLoading: state.setting.account.createAccount.loading,
    formCreateAccountData: state.form.formCreateAccount?.values,
  }),
  (dispatch) => ({
    createAccount: (dataToCreate) => {
      dispatch(createAccount(dataToCreate));
    },
    changeFieldValue: function (field, value) {
      dispatch(change('formCreateAccount', field, value));
    },
  })
)(Create);
