import { actionType as TYPE } from './actions';
import { notification } from 'antd';

const initialState = {
  createOrder: {
    data: [],
    params: {},
    loading: false,
  },
  listDesigns: {
    loading: false,
    data: [],
  },
  listVariantRisk: {
    data: [],
    loading: false,
  },
  showModalVariant: false,
};

function OrderReducer(state = initialState, action) {
  
  switch (action.type) {
    case TYPE.CREATE_ORDER.REQUEST:
      return {
        ...state,
        createOrder: {
          ...initialState.createOrder,
          loading: true,
        },
      };
    case TYPE.CREATE_ORDER.SUCCESS:
      notification.success({
        message: action?.clientMsg || 'Create order successfully.',
        duration: 5,
      });
      return {
        ...state,
        createOrder: {
          ...initialState.createOrder,
          loading: false,
        },
      };
    case TYPE.CREATE_ORDER.ERROR:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Create order failed.',
        duration: 5,
      });
      return {
        ...state,
        createOrder: {
          ...initialState.createOrder,
          loading: false,
        },
      };
    case TYPE.LIST_DESIGN.REQUEST:
      return {
        ...state,
        listDesigns: {
          ...initialState.listDesigns,
          loading: true,
        },
      };
    case TYPE.LIST_DESIGN.SUCCESS:
      return {
        ...state,
        listDesigns: {
          ...initialState.listDesigns,
          loading: false,
          data:
            action?.data?.exProducts && Array.isArray(action?.data?.exProducts)
              ? action?.data?.exProducts
              : [],
        },
      };
    case TYPE.LIST_DESIGN.ERROR:
      return {
        ...state,
        listDesigns: {
          ...initialState.listDesigns,
          loading: false,
        },
      };

    case TYPE.CHECK_VARIANT_RISK.REQUEST:
      return {
        ...state,
        listVariantRisk: {
          ...initialState.listVariantRisk,
          loading: true,
        },
      };
    case TYPE.CHECK_VARIANT_RISK.SUCCESS:
      return {
        ...state,
        listVariantRisk: {
          ...initialState.listVariantRisk,
          loading: false,
          data: action?.data ? action?.data?.risks : [],
        },
        showModalVariant: action?.data?.risks?.length > 0 ? true : false,
      };
    case TYPE.CHECK_VARIANT_RISK.ERROR:
      return {
        ...state,
        listVariantRisk: {
          ...initialState.listVariantRisk,
          loading: false,
        },
      };
    case TYPE.CLOSE_VARIANT_RISK:
      return {
        ...state,
        showModalVariant: false,
      };

    case TYPE.OPEN_VARIANT_RISK:
      return {
        ...state,
        showModalVariant: true,
      };

    case TYPE.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default OrderReducer;
