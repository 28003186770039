import na from 'images/service-label/n_a.svg';
// import gearment from 'images/service-label/manual.svg';
import wordpress from 'images/service-label/wordpress.svg';
import ebay from 'images/service-label/ebay.svg';
import etsy from 'images/service-label/etsy.svg';
import inkgo from 'images/service-label/inkgo.svg';
import shopify from 'images/service-label/shopify.svg';
import amazon from 'images/service-label/amazon.svg';
import api from 'images/service-label/api.svg';
import duplicate from 'images/service-label/duplicate.svg';
import shopbase from 'images/service-label/shopbase.svg';
import manual from 'images/service-label/manual.svg';
import orderdesk from 'images/service-label/orderdesk.svg';
import amazonFba from 'images/service-label/amazon_fba.svg';
import serviceLabel from 'images/service-label/service_label.svg';
import importIcon from 'images/service-label/import.svg';
import woo from 'images/service-label/woo.svg';

export const ServiceLabel = {
  'n/a': na,
  'gearment': manual,
  'wordpress': wordpress,
  'ebay': ebay,
  'etsy': etsy,
  'inkgo': inkgo,
  'shopify': shopify,
  'amazon': amazon,
  'api': api,
  'duplicate': duplicate,
  'shopbase': shopbase,
  'manual': manual,
  'orderdesk': orderdesk,
  'amazon fba': amazonFba,
  'service label': serviceLabel,
  'import': importIcon,
  'woo': woo,
};
