import axios from 'utils/request';

const prefix = '/staff';

export const getList = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}`,
  });
};
export const getListInvitationStaff = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/invitations`,
  });
};

export const create = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}`,
    is_json_content_type: true,
  });
};
export const getDetail = (id) => {
  return axios({
    method: 'GET',
    // params,
    url: `${prefix}/${id}`,
  });
};

export const deleteStaffAccount = (id) => {
  return axios({
    method: 'DELETE',
    url: `${prefix}/${id}`,
  });
};

export const deleteInvitationAccount = (id) => {
  return axios({
    method: 'DELETE',
    url: `${prefix}/${id}/invite`,
  });
};
export const resendInvitationAccount = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/resend-verification-email`,
    is_json_content_type: true,
  });
};
export const update = (id, data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/${id}`,
    is_json_content_type: true,
  });
};

export const getListReport = (params) => {
  return axios({
    url: `${prefix}/report-accounts`,
    method: 'GET',
    params,
  });
};

export const exportOrders = (id, params) => {
  return axios({
    url: `${prefix}/${id}/export-order`,
    method: 'GET',
    params,
  });
};

export const detailReport = (id, params = {}) => {
  return axios({
    url: `${prefix}/${id}/report`,
    method: 'GET',
    params,
  });
};
//List report order
export const listReportOrdersDashboard = (params = {}) => {
  return axios({
    url: `${prefix}/report-all-order`,
    method: 'GET',
    params,
  });
};
export const exportAccountReport = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/export-accounts`,
    is_json_content_type: true,
  });
};

export const exportAccountReportDetail = (id, data) => {
  return axios({
    method: 'POST',
    data,
    id,
    url: `${prefix}/${id}/export`,
    is_json_content_type: true,
  });
};

export const checkWishAvailabe = () => {
  return axios({
    method: 'GET',
    url: `/accounts/importing-order-wish`,
  });
};
