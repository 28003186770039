import { 
  takeLatest, 
  call, 
  put, 
  all,
} from 'redux-saga/effects'
import { 
  actionType as TYPE
} from './actions'
import axios from 'utils/request'

function getListAPI(params){
    return axios({
      method: 'GET',
      params,
      url: `analytics/general`
    })
}

function* getListSaga(action){
  const { params } = action
  try {
    const response = yield call(getListAPI, params)
    if(response.code === 'success'){
      yield put({ type: TYPE.LIST.SUCCESS, ...response })    
    }else{
        yield put({ type: TYPE.LIST.ERROR, response })
    }      
  } catch(error){
    yield all([
      yield put({ type: TYPE.LIST.ERROR, error })
    ])
  }
}

function* watcher(){
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga)
}

export default watcher