import React from "react";
import { GMButton } from "components/Button";
function RenderAdvancedFilterButtons({ handleOnClearFilters = null, handleOnSubmit = null }) {
  return (
    <div className="container-fluid" style={{ backgroundColor: "rgb(243, 243, 243)" }}>
      <div className="row py-3">
        <div className="col-6  d-flex align-items-center">
          <GMButton
            className="Clear-all-filter w-100"
            type="button"
            color="SecondaryGray"
            onClick={handleOnClearFilters}
          >
            Clear all filters
          </GMButton>
        </div>
        <div className="col-6">
          <GMButton
            type="primary"
            className="search-button w-100"
            onClick={handleOnSubmit}
          >
            Search
          </GMButton>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RenderAdvancedFilterButtons);