import React from 'react';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import { ReactComponent as IconInfo } from 'images/icons/info-circle.svg';
import { ConfirmModalInnerWrapper } from './styled';
import { GMButton } from 'components/Button';

export default ({ onOk, onCancel, ...props }) => {
  return (
    <ConfirmDialog
      ctas={(
        <>
          <GMButton type="button" color="SecondaryGray" onClick={onCancel}>Cancel</GMButton>
          <GMButton type="button" color="PrimaryError" onClick={onOk}>Remove</GMButton>
        </>
      )}
      {...props}
    >
      <ConfirmModalInnerWrapper>
        <div className="icon">
          <div className="wrapper">
            <IconInfo />
          </div>
        </div>
        <div className="content">
          <h4 className="header">
            Remove Billing Method?
          </h4>
          <div className="msg">
            This billing method will be removed from your account.
          </div>
        </div>
      </ConfirmModalInnerWrapper>
    </ConfirmDialog>
  );
}