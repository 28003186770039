export const actionType = {
  LIST_USERLABEL: {
    REQUEST: 'LIST_USERLABEL.REQUEST',
    SUCCESS: 'LIST_USERLABEL.SUCCESS',
    ERROR: 'LIST_USERLABEL.ERROR',
  },
  USERLABEL_DELETE: {
    REQUEST: 'USERLABEL.DELETE.REQUEST',
    SUCCESS: 'USERLABEL.DELETE.SUCCESS',
    ERROR: 'USERLABEL.DELETE.ERROR',
  },
};

export function getListUserlabel(params) {
  return {
    type: actionType.LIST_USERLABEL.REQUEST,
    params,
  };
}
export function deleteUserlabel(id, cb = undefined) {
  return {
    type: actionType.USERLABEL_DELETE.REQUEST,
    id,
    cb,
  };
}
