import {
  ERROR_500,
  ERROR_800,
  GRAY_500,
  GRAY_800,
} from "components/colors";
import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  .cta-newgift {
    margin-left: auto;
    display: flex;
    align-content: center;
    align-items: center;
    svg {
      margin-right: 10px;
      vertical-align: middle;
      width: 24px;
    }
  }
  svg {
    cursor: pointer;
  }
  svg.edit {
    stroke: ${GRAY_500};
    width: 20px;
    height: 20px;
    margin-right: 12px;
    &:hover {
      stroke: ${GRAY_800};
    }
  }
  svg.trash {
    stroke: ${ERROR_500};
    width: 20px;
    height: 20px;
    margin-right: 12px;
    &:hover {
      stroke: ${ERROR_800};
    }
  }
`;
