import { combineReducers } from 'redux';
import login from './scenes/Login/reducer';
import forgetPassword from './scenes/Forgot/reducer';
import register from './scenes/Register/reducer';
import resetPassword from './scenes/ResetPassword/reducer';
import { AUTH } from './actions';
import { LOGIN } from './scenes/Login/actions';
import { API_TOKEN_KEY } from '../../web.config';
import logout from './scenes/Logout/reducer';
import verifyEmail from './scenes/VerifyEmail/reducer';
import verifyStaffEmail from './scenes/VerifyStaffAccount/reducer';

import { notification } from 'antd';
import { getCookie, removeCookie, setCookie } from 'utils/helper';
const initialState = {
  profile: {},
  permission: [],
  logged: false,
  verified: false,
  token: getCookie(API_TOKEN_KEY) /* auth cookie */,
  setting: {},
};

function info(state = initialState, action) {
  switch (action.type) {
    case AUTH.verify.success:
      return {
        ...state,
        verified: true,
        logged: true,
        profile: action.profile,
        permission: action.permission,
      };
    case AUTH.verify.error:
      if (
        action.error &&
        action.error[0] &&
        action.error[0]['code'] === 'not_activated_user'
      ) {
        notification.warn({
          message:
            action.error && action.error[0] && action.error[0]['clientMsg']
              ? action.error[0]['clientMsg']
              : 'Your session is expired. Please login again.',
        });
        removeCookie(API_TOKEN_KEY);
      }
      return {
        ...state,
        verified: true,
        logged: false,
      };
    case LOGIN.success:
      setCookie(API_TOKEN_KEY, action.token);
      localStorage.removeItem('listOrderIdToMatchProduct');
      localStorage.removeItem('CreateCampaignForm3');
      return {
        ...state,
        verified: true,
        logged: true,
        user_id: action.user_id,
        token: action.token,
      };
    case AUTH.SYSTEM_SETTING.REQUEST:
      return {
        ...state,
      };
    case AUTH.SYSTEM_SETTING.SUCCESS:
      localStorage.setItem('system_setting', JSON.stringify(action.data));
      return {
        ...state,
        setting: action.data,
      };
    case AUTH.SYSTEM_SETTING.ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}

const reducer = combineReducers({
  info,
  login,
  register,
  forgetPassword,
  resetPassword,
  verifyEmail,
  verifyStaffEmail,
  logout,
});

export default reducer;
