import React from "react";

const Sidebar = (props) => {
  return (
    <div className="seller-help">
      <h2 className="helpTitle">Help</h2>
      <div className="seller-help-box">
        <h3 className="mTitle">File names</h3>
        <p>
          An artwork file name will become the title and URL path of a new
          product if auto-generate product titles is selected. Otherwise titles
          will be the original product title.
        </p>
        <h3 className="sbTitle">Example</h3>
        <div className="table-responsive">
          <table className="table table-hover table-zp table-zp-top">
            <thead>
              <tr>
                <th>File name</th>
                <th>Product name</th>
                <th>URL path</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name Design.png</td>
                <td>Name Design</td>
                <td>/name-design</td>
              </tr>
              <tr>
                <td>I am Tshirt.png</td>
                <td>I am Tshirt</td>
                <td>/i-am-tshirt</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="seller-help-box">
        <h3 className="mTitle">URL prefix</h3>
        <p>
          A URL prefix will be added to the beginning of product url paths being
          created if the option is selected..
        </p>
        <h3 className="mTitle">Example</h3>
        <div className="table-responsive">
          <table className="table table-hover table-zp table-zp-top">
            <thead>
              <tr>
                <th>URL prefix</th>
                <th>URL path</th>
                <th>Path with prefix</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>pets</td>
                <td>i-like-dogs</td>
                <td>/pets-i-like-dogs</td>
              </tr>
              <tr>
                <td>pets</td>
                <td>i-like-cats</td>
                <td>/pets-i-like-cats</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
