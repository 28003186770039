export const actionType = {
    INVOICE: {
        REQUEST: 'ORDER.INVOICE.REQUEST',
        SUCCESS: 'ORDER.INVOICE.SUCCESS',
        ERROR: 'ORDER.INVOICE.ERROR',
    },
};

export function getInvoice(id) {
    return {
        type: actionType.INVOICE.REQUEST,
        id
    }
}


