import { actionType as TYPE } from "./actions";
import { notification } from "antd";

const initialState = {
  transactions: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  transactionsQuickView: {
    data: [],
    loading: false,
  },
  export: {
    loading: false,
    params: {},
  },
  profits: {
    data: [],
    loading: false,
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: false,
          data: action.data.transactions,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: false,
        },
      };
    //Quick View
    case TYPE.LIST_QUICKVIEW.REQUEST:
      return {
        ...state,
        transactionsQuickView: {
          ...state.transactionsQuickView,
          loading: true,
        },
      };
    case TYPE.LIST_QUICKVIEW.SUCCESS:
      return {
        ...state,
        transactionsQuickView: {
          ...state.transactionsQuickView,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.LIST_QUICKVIEW.ERROR:
      return {
        ...state,
        transactionsQuickView: {
          ...state.transactionsQuickView,
          loading: false,
        },
      };
    //Export
    case TYPE.EXPORT.REQUEST:
      return {
        ...state,
        export: {
          ...state.export,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.EXPORT.SUCCESS:
      notification.success({
        message: "Export transactions successfully.",
        duration: 5,
      });
      if (action.data && action.data.linkToDownloadFile) {
        window.open(action.data.linkToDownloadFile);
      }
      return {
        ...state,
        export: {
          ...state.export,
          loading: false,
        },
      };
    case TYPE.EXPORT.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Export transactions failed.",
        duration: 5,
      });
      return {
        ...state,
        export: {
          ...state.export,
          loading: false,
        },
      };
    //Get profits
    case TYPE.GET_PROFIT.REQUEST:
      return {
        ...state,
        profits: {
          ...initialState.profits,
          loading: true,
        },
      };
    case TYPE.GET_PROFIT.SUCCESS:
      return {
        ...state,
        profits: {
          ...state.profits,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.GET_PROFIT.ERROR:
      return {
        ...state,
        profits: {
          ...state.profits,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
