import React from 'react';
import { Link } from 'react-router-dom';
import RenderAuthenticationSlider from 'utils/Form/RenderAuthenticationSlider';

import { ReactComponent as ArrowLeft } from 'images/icons/arrow-left.svg';
import { ReactComponent as CheckCircleIcon } from 'images/icons/check-circle.svg';
import { GlowingSVG } from 'components/SVG';
import { ReactComponent as ClockSnoozeIcon } from 'images/icons/clock-snooze.svg';
import {
  PRIMARY_500,
  SUCCESS_100,
  SUCCESS_50,
  SUCCESS_600,
  WARNING_100,
  WARNING_50,
  WARNING_500,
} from 'components/colors';
import { GMButton } from 'components/Button';
import { Wrapper } from './styled';
import { verifyEmail } from '../actions';
import { Spin } from 'antd';

import { ReactComponent as ArrowNarrowRightIcon } from 'images/icons/arrow-narrow-right.svg';
import { ReactComponent as CheckIcon } from 'images/icons/check.svg';
import { useDispatch } from 'react-redux';

const Index = (props) => {
  const dispatch = useDispatch();
  const {
    confirmEmail,
    loading,
    successResendEmail,
    resendData,
    loadingVerify,
    responseError,
  } = props;
  if (loading) {
    return null;
  }

  const resendEmail = (values) => {
    dispatch(verifyEmail({ verifyEmailToken: values }));
  };

  return (
    <Wrapper className="container-fluid">
      <div className="row align-items-stretch justify-content-center">
        <div className="col-md-6">
          <div className="col-12 col-md-8 col-xl-6 m-auto min-vh-100 d-flex align-items-center px-0">
            {confirmEmail === false ? (
              <div className="d-flex flex-column">
                <div className="text-center">
                  <GlowingSVG
                    backgroundColor={WARNING_100}
                    borderColor={WARNING_50}
                    svgStrokeColor={WARNING_500}
                  >
                    <ClockSnoozeIcon />
                  </GlowingSVG>
                </div>
                <h2 className="text-center header">
                  Invalid verification link
                </h2>
                <p className="text-center message">
                  The verification link is no longer valid.
                </p>
                {responseError?.error?.[0]?.code === 'verify_expired' && (
                  <div className="mt-3 d-flex justify-content-center cursor-pointer">
                    {successResendEmail ? (
                      <div className="email">
                        Email sent
                        <CheckIcon stroke={PRIMARY_500} className="ml-2" />
                      </div>
                    ) : (
                      <Spin
                        spinning={loadingVerify}
                        className="email"
                        onClick={() =>
                          !loadingVerify && resendEmail(resendData)
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        Resend verification email
                        <ArrowNarrowRightIcon
                          stroke={PRIMARY_500}
                          className=" ml-2"
                        />
                      </Spin>
                    )}
                  </div>
                )}
                <div className="text-center back2login">
                  <Link to="/auth/login" className="back-cta">
                    <ArrowLeft />
                    Back to log in
                  </Link>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div className="text-center">
                  <GlowingSVG
                    backgroundColor={SUCCESS_100}
                    borderColor={SUCCESS_50}
                    svgStrokeColor={SUCCESS_600}
                  >
                    <CheckCircleIcon />
                  </GlowingSVG>
                </div>
                <h2 className="text-center header">
                  Verify your email successfully
                </h2>
                <p className="text-center message">
                  Please login again to access to this page
                </p>
                <div className="text-center back2login">
                  <GMButton to="/auth/login" className="btn-back-cta" as={Link}>
                    Log in
                  </GMButton>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-6 right-authentication-section">
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            <RenderAuthenticationSlider />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Index;
