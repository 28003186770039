import { actionType as TYPE } from './actions';
import { notification } from 'antd';
const initialState = {
  createIndemnication: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  viewIndemnication: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  template: {
    data: [],
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    //Create indemnification
    case TYPE.CREATE_INDEMNICATION.REQUEST:
      return {
        ...state,
        createIndemnication: {
          ...state.createIndemnication,
          loading: true,
        },
      };
    case TYPE.CREATE_INDEMNICATION.SUCCESS:
      notification.success({
        message: action.clientMsg,
      });
      return {
        ...state,
        createIndemnication: {
          ...state.createIndemnication,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.CREATE_INDEMNICATION.ERROR:
      notification.warn({
        message: action.clientMsg,
      });
      return {
        ...state,
        createIndemnication: {
          ...state.createIndemnication,
          loading: false,
        },
      };
    //View indemnification
    case TYPE.VIEW_INDEMNICATION.REQUEST:
      return {
        ...state,
        viewIndemnication: {
          ...state.viewIndemnication,
          loading: true,
        },
      };
    case TYPE.VIEW_INDEMNICATION.SUCCESS:
      return {
        ...state,
        viewIndemnication: {
          ...state.viewIndemnication,
          loading: false,
          data: action.data?.term,
        },
      };
    case TYPE.VIEW_INDEMNICATION.ERROR:
      return {
        ...state,
        viewIndemnication: {
          ...state.viewIndemnication,
          loading: false,
        },
      };
    //View template
    case TYPE.VIEW_TEMPLATE.REQUEST:
      return {
        ...state,
        template: {
          ...state.template,
          loading: true,
        },
      };
    case TYPE.VIEW_TEMPLATE.SUCCESS:
      return {
        ...state,
        template: {
          ...state.template,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.VIEW_TEMPLATE.ERROR:
      return {
        ...state,
        template: {
          ...state.template,
          loading: false,
        },
      };

    default:
      return state;
  }
}

export default ListReducer;
