import React from 'react';
import LazyLoad from 'react-lazyload';
import RenderListActions from "utils/Form/RenderListActions";
import getMomentInstance, { getMomentInstance2 } from "utils/moment";

export const getColumnConfig = (rowSelection, state) => {
  return [
    {
      title: <b>ID</b>,
      dataIndex: "id",
      align: "left",
      width: 100,
      key: "id",
      render: id => id,
    },
    {
      title: <b>Image</b>,
      dataIndex: "imageThumbnailUrl",
      align: "left",
      key: "imageThumbnailUrl",
      render: (text) => (
        <LazyLoad><img className="img-fluid" width="120px" src={text} alt="" /></LazyLoad>
      ),
    },
    {
      title: <b>Original Name</b>,
      dataIndex: "original",
      align: "left",
      key: "original",
    },
    {
      title: <b>System Name</b>,
      dataIndex: "name",
      align: "left",
      key: "name",
    },
    {
      title: <b>Uploaded At</b>,
      dataIndex: "createdAt",
      align: "left",
      key: "createdAt",
      render: (text) => getMomentInstance2({ unixDateTime: text, isDateTimFormat: true }),//getMomentInstance(text, 0, true),
    },
  ];
};
