import { 
    takeLatest, 
    call, 
    put, 
    all 
  } from 'redux-saga/effects'
  import {
      actionType as TYPE
  } from './actions'

  import { AUTH } from 'scenes/Auth/actions'

  import {updateEmail} from 'apis/user'
import { API_TOKEN_KEY } from 'web.config'
import { setCookie } from 'utils/helper'
  
  function* updateSaga(action) {
    try {
        let { data } = action
        const response = yield call(updateEmail, data)
        if(response.code === 'success'){
          setCookie(API_TOKEN_KEY, response.data.accessToken);
            yield all([
                put({type: TYPE.UPDATE.SUCCESS, response}),
                put({type: AUTH.verify.request}),
            ])
        }else{
          yield put({type: TYPE.UPDATE.ERROR, error: response})
        }
    } catch (error) {
        yield all([
            put({type: TYPE.UPDATE.ERROR, error})
        ])
    }
}

  
  function* watcher() {
    yield all([
        takeLatest(TYPE.UPDATE.REQUEST, updateSaga),
    ])
  }
  
  export default watcher