export const actionType = {
  REGISTER: {
    REQUEST: "AUTH.REGISTER.REQUEST",
    SUCCESS: "AUTH.REGISTER.SUCCESS",
    ERROR: "AUTH.REGISTER.ERROR",
    RESET_MESSAGE: "AUTH.REGISTER.RESET_MESSAGE",
  },
};
export function register(data) {
  return {
    type: actionType.REGISTER.REQUEST,
    data,
  };
}
export function resetMessage() {
  return {
    type: actionType.REGISTER.RESET_MESSAGE,
  };
}
