import React, { Component } from "react";
import { reduxForm, FieldArray, formValueSelector, arrayRemove } from "redux-form";
import { connect } from "react-redux";
import validate from "./validate";
import { Affix } from "antd";
import { withRouter } from "react-router-dom";
import { GMButton } from "components/Button";
import { replace } from "connected-react-router";
import renderMatchProduct from "utils/Form/components/MatchProduct";
class index extends Component {
  render() {
    const {
      handleOnSubmit,
      setSelectedOrderItems,
      changeFieldValue,
      exproducts,
      profile,
      listOrderItemsToMatch,
      listOrders,
      saveOrderItem,
      removedOrderItemMatchProduct,
      selectedOrderItems,
      deleteDesign,
      removeAllDesignBySide,
      uploadImageRef,
      dispatch,
      handleSubmit,
    } = this.props;
    const handleOnCancel = () => {
      dispatch(replace('/orders/list'));
      return false;
    };
    return (
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="form-group mb-0">
          <FieldArray
            name={`listOrderItemsToMatch`}
            component={renderMatchProduct}
            removeAllDesignBySide={removeAllDesignBySide}
            profile={profile}
            exproducts={exproducts}
            changeField={changeFieldValue}
            listOrderItems={listOrderItemsToMatch}
            listOrders={listOrders}
            setSelectedOrderItems={setSelectedOrderItems}
            saveOrderItem={saveOrderItem}
            uploadImageRef={uploadImageRef}
            removedOrderItemMatchProduct={removedOrderItemMatchProduct}
            removeOrderItem={(index) => { this.props.removeItem('listOrderItemsToMatch', index) }}
            deleteDesign={deleteDesign}
            selectedOrderItems={selectedOrderItems}
            listOrderItemsError={this.props.listOrderItemsError}
            listOrderItemsSuccess={this.props.listOrderItemsSuccess}
          />
        </div>
        {((listOrderItemsToMatch || []).length > 0) &&
          <Affix offsetBottom={0}>
            <div className="form-footer">
              <GMButton
                // type="button"
                // onClick={() => { handleOnSubmit() }}
                className="btn btn-primary"
                style={{ width: 150, borderRadius: "5px" }}
              >
                Match product
              </GMButton>
              <GMButton
                type="button"
                color="SecondaryGray"
                onClick={() => { handleOnCancel() }}
                className="btn btn-primary"
                style={{ width: 150, borderRadius: "5px" }}
              >
                Cancel
              </GMButton>
            </div>
          </Affix>}
      </form>
    );
  }
}

const selector = formValueSelector("FormMatchProduct");
export default withRouter(connect(
  (state) => ({
    listOrderItemsToMatch: selector(state, "listOrderItemsToMatch") || [],
  }),
  (dispatch) => ({
    removeItem: (field, index) => {
      dispatch(arrayRemove('FormMatchProduct', field, index))
    }
  })
)(
  reduxForm({
    form: "FormMatchProduct",
    enableReinitialize: true,
    validate,
  })(index)
));
