import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import { getListStockStatus } from "apis/orders";
import {
  listColorStockStatus,
  listSizeStockStatus,
  listProductStockStatus,
  exportStockStatus,
} from "apis/filter_stock_status";

function* getListStockStatusSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListStockStatus, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}
function* getListColorSaga(action) {
  const { params } = action;
  try {
    const response = yield call(listColorStockStatus, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST_COLOR.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_COLOR.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_COLOR.ERROR, error })]);
  }
}
function* getListSizeSaga(action) {
  const { params } = action;
  try {
    const response = yield call(listSizeStockStatus, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST_SIZE.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_SIZE.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_SIZE.ERROR, error })]);
  }
}
function* getListProductSaga(action) {
  const { params } = action;
  try {
    const response = yield call(listProductStockStatus, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST_PRODUCT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_PRODUCT.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_PRODUCT.ERROR, error })]);
  }
}
function* exportStockStatusSaga(action) {
  const { params } = action;
  try {
    const response = yield call(exportStockStatus, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.EXPORT_STOCK_STATUS.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.EXPORT_STOCK_STATUS.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.EXPORT_STOCK_STATUS.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.LIST.REQUEST, getListStockStatusSaga);
  yield takeLatest(TYPE.LIST_COLOR.REQUEST, getListColorSaga);
  yield takeLatest(TYPE.LIST_SIZE.REQUEST, getListSizeSaga);
  yield takeLatest(TYPE.LIST_PRODUCT.REQUEST, getListProductSaga);
  yield takeLatest(TYPE.EXPORT_STOCK_STATUS.REQUEST, exportStockStatusSaga);
}

export default watcher;
