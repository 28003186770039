import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import menuList from 'route/menu';
import { checkRoutePermission, getSelectedMenuKeys } from 'utils/helper';
import { ReactComponent as DownIcon } from 'images/icons/chevron-down.svg';
import { Link } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { SubMenu } from './GMMenu.styled';
import { convertMenuArr2Obj } from 'components/Breadcrumb';

let fkExpandedMenuItem = undefined;

const SUBMENU_PORTAL = 'gm-submenu-portal';

const Menu = ({ }) => {
  const profile = useSelector((s) => s?.auth?.info?.profile);
  const logged = useSelector((s) => s?.auth?.info?.logged);
  // const scrollRef = useRef();
  const history = useHistory();
  const [expandedItemKey, setExpandedItemKey] = useState(fkExpandedMenuItem);

  const menuMapping = (menu) => {
    const isPermitted = checkRoutePermission(
      profile,
      menu?.key,
      menu.permissions,
      logged,
      menu.isPublic
    );
    if (!isPermitted || menu?.hkey) {
      return null;
    }
    const mappedSubMenu = (menu?.children || []).map(menuMapping);
    return {
      icon: menu?.icon,
      key: menu?.key,
      name: menu?.name,
      path: menu?.path,
      children: mappedSubMenu,
    };
  };

  const toggleMenu = useCallback((evt) => {
    let targetElem = evt.target;
    if (!targetElem.hasAttribute('data-ref')) {
      targetElem = targetElem.closest('.menu-item');
    }
    if (!targetElem) {
      return;
    }
    setExpandedItemKey((cV) => {
      if (cV !== targetElem.dataset.ref) {
        fkExpandedMenuItem = targetElem.dataset.ref;
        return targetElem.dataset.ref;
      } else {
        fkExpandedMenuItem = undefined;
        return undefined;
      }
    });
  }, []);

  const getActivePath = useCallback(
    (menus = []) => {
      for (let i = 0; i < menus.length; i++) {
        const m = menus[i];
        if (
          m?.key === history?.location?.pathname?.slice(1)?.replace('/', '.')
        ) {
          return [m?.key];
        }
        if (Array.isArray(m?.children) && m?.children?.length > 0) {
          const mSM = getActivePath(m?.children);
          if (mSM.length > 0) {
            return [m?.key, ...mSM];
          }
        }
      }
      return [];
    },
    [history?.location?.pathname]
  );
  //List menu set up for main account
  const mappedMenu = menuList.map(menuMapping).filter((i) => i !== null);
  //List menu set up for staff
  const mappedMenuForStaff = menuList
    .map(menuMapping)
    .filter((i) => i?.key !== 'statistics' && i?.key !== 'logs');

  const currentKeyPath = useCallback(
    (sP) => sP?.slice(1)?.replace(/\//gi, '.').replace(/\d+/gi, ':id'),
    [history?.location?.pathname]
  )(history?.location?.pathname);
  let activePath = getSelectedMenuKeys(currentKeyPath, false, '.');
  const menuListObjConverted = useCallback(convertMenuArr2Obj, [])(menuList);
  // eslint-disable-next-line no-undef
  const currentMenuObjInfo = _.get(menuListObjConverted, currentKeyPath);
  if (currentMenuObjInfo?.virtualPath) {
    activePath = [...currentMenuObjInfo?.virtualPath];
    // eslint-disable-next-line no-undef
    const firstPath = _.first(activePath) + '';
    if (firstPath?.indexOf('.') !== -1) {
      const prependPath = getSelectedMenuKeys(firstPath, false, '.');
      activePath.shift();
      activePath = [...prependPath, ...activePath];
    }
  }
  useEffect(() => {
    if (activePath.length > 1 && !expandedItemKey) {
      setExpandedItemKey(activePath[0]);
    }
  }, []);

  // const createPortal

  const showSub = useCallback((evt) => {
    let targetElem = evt.target;
    if (!targetElem?.classList?.contains('root')) {
      targetElem = targetElem.closest('li.root');
    }
    if (!targetElem) {
      return;
    }
    const outBoundBoxInfo = targetElem.getBoundingClientRect();
    const nextSibling = targetElem.querySelector('ul');
    if (!nextSibling) {
      return;
    }
    targetElem.classList.add('hover');
    nextSibling.classList.add('show');
    nextSibling.style.top = outBoundBoxInfo.top + 'px';
    nextSibling.style.bottom = 'auto';
    // double check 
    setTimeout(() => {
      const nextSiblingBoundingInfo = nextSibling.getBoundingClientRect();
      if (window.innerHeight < nextSiblingBoundingInfo.top + nextSiblingBoundingInfo.height) {
        nextSibling.style.top = 'auto';
        nextSibling.style.bottom = '0px';
      }
    }, 100);
  }, []);

  const hideSub = useCallback((evt) => {
    let targetElem = evt.target;
    if (!targetElem?.classList?.contains('root')) {
      targetElem = targetElem.closest('li.root');
    }
    if (!targetElem) {
      return;
    }
    const nextSibling = targetElem.querySelector('ul');
    if (!nextSibling) {
      return;
    }
    targetElem.classList.remove('hover');
    nextSibling.classList.remove('show');
    nextSibling.style.top = undefined;
  }, []);

  const renderMenuList = (menuList, sub = false) => {
    return (
      <SubMenu className={classNames({ sub })}>
        {menuList.map((menu) => {
          let subMenuForStaff = menu?.children?.filter(
            (i) =>
              i?.key !== 'billing.g-wallet' &&
              i?.key !== 'billing.method' &&
              i?.key !== 'settings.webhooks' &&
              i?.key !== 'settings.profile' &&
              i?.key !== 'settings.shipping-delivery' &&
              i?.key !== 'settings.accounts' &&
              i?.key !== 'settings.gift-message' &&
              i?.key !== 'settings.config-buy-tracking'
          );
          let subMenuForPrimary = menu?.children?.filter((i) =>
            profile?.configBuyTracking === false
              ? i?.key !== 'settings.config-buy-tracking'
              : i
          );
          if (menu?.children?.length > 0) {
            return (
              <li
                key={menu?.key}
                className={classNames({
                  haschild: menu?.children?.length > 0,
                  root: !sub,
                })}
                onMouseEnter={showSub}
                onMouseLeave={hideSub}
              >
                <div
                  className={classNames('menu-item-wrapper', {
                    active: activePath.includes(menu?.key),
                    open: expandedItemKey === menu?.key,
                  })}
                >
                  <div
                    className={classNames('menu-item', {
                      active: activePath.includes(menu?.key),
                    })}
                    data-ref={menu?.key}
                    ref={(ref) => {
                      if (ref) {
                        ref.removeEventListener('click', toggleMenu);
                        ref.addEventListener('click', toggleMenu);
                      }
                    }}
                  >
                    {menu?.icon}
                    <span>{menu?.name}</span>
                    <div className="sub-indicator">
                      <DownIcon />
                    </div>
                  </div>
                  {profile?.primaryId === 0
                    ? renderMenuList(subMenuForPrimary || [], !!1)
                    : renderMenuList(subMenuForStaff || [], !!1)}
                </div>
              </li>
            );
          }
          if (!menu) {
            return null;
          }
          return (
            <li key={menu?.key}>
              <Link
                to={menu?.path}
                className={classNames('menu-item', {
                  active: activePath.includes(menu?.key),
                })}
              >
                {menu?.icon || <div className="dummy-icon">&nbsp;</div>}
                <span>{menu?.name}</span>
              </Link>
            </li>
          );
        })}
      </SubMenu>
    );
  };
  return (
    <Scrollbars
      id="menu-scroll-container"
      renderView={(props) => <div {...props} className="scrollview" />}
      autoHide
    // ref={scrollRef}
    >
      {profile?.primaryId === 0 ? (
        <div className="padding">{renderMenuList(mappedMenu)}</div>
      ) : (
        <div className="padding">{renderMenuList(mappedMenuForStaff)}</div>
      )}
    </Scrollbars>
  );
};

export default memo(Menu);
