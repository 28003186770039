export const actionType = {
  LIST: {
    REQUEST: "ORDER.REPORT.LIST.REQUEST",
    SUCCESS: "ORDER.REPORT.LIST.SUCCESS",
    ERROR: "ORDER.REPORT.LIST.ERROR",
  },
  EXPORT_PRODUCT_SELL: {
    REQUEST: "EXPORT_PRODUCT_SELL.REQUEST",
    SUCCESS: "EXPORT_PRODUCT_SELL.SUCCESS",
    ERROR: "EXPORT_PRODUCT_SELL.ERROR",
  },
};

export function getList(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}
export function exportOrdersProductSell(params) {
  return {
    type: actionType.EXPORT_PRODUCT_SELL.REQUEST,
    params,
  };
}
