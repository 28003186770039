/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import FormImportOrder from './components/FormImportOrder';
import { getListStore } from '../../../Stores/scenes/List/actions';
import { list_file_type_to_import } from 'utils/options';
import { Wrapper } from './styled';
import { importOrder } from 'apis/orders';

class ImportOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platform: {},
      uploadProgress: 0,
    };
  }

  componentDidMount() {
    this.listStoreToImportOrder();
  }

  listStoreToImportOrder = () => {
    this.props.getListStore({ sortType: 'asc', sortBy: 'id' });
  };

  handleSubmit = async (values) => {
    this.setState({ isUploading: true });
    const rs = await importOrder(
      {
        importFile: values.fileToImport || null,
        storeId: values.storeId || '',
        typeFile: values.typeFile || list_file_type_to_import[0]['value'] || '',
      },
      (p) => {
        this.setState({ uploadProgress: p?.loaded / p?.total });
      }
    );
    this.setState({ isUploading: false });
    const data2push = {};
    if (rs?.[0]?.code === 'import_order_failed') {
      data2push.type = 'error';
      data2push.clientMsg = rs?.[0]?.clientMsg
      data2push.clientMsg = rs?.[0]?.clientMsg;
      data2push.file = rs?.[0]?.file;
    } else if (rs?.data?.importBackground?.id) {
      data2push.redirectUrl = `/logs/import-orders/${rs?.data?.importBackground?.id}`;
    }
    this.props?.history?.push(`/orders/list`, data2push);
  };

  render() {
    const { stores, pageName, importOrderLoading } = this.props;
    const initialValues = {
      storeId: '0',
      typeFile: `${list_file_type_to_import[0]['value']}`,
    };

    return (
      <Wrapper pageName={pageName}>
        <Spin spinning={stores.loading || importOrderLoading}>
          <FormImportOrder
            className="inner-wrapper"
            stores={stores.data}
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            is_mobile={this.props.is_mobile}
            listStoreToImportOrder={this.listStoreToImportOrder}
            uploadProgress={this.state.uploadProgress}
            isUploading={this.state.isUploading}
            setUploadProgress={(v) => {
              this.setState({ uploadProgress: v });
            }}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    stores: state.store.list.stores2,
    importOrderLoading: state.order.importOrder.importOrder.loading,
    storesModal: state.store.list.stores,
    detailStoreData: state.store.edit.detail.data,
    updateStoreLoading: state.store.edit.edit.loading,
    updateStoreEbayLoading: state.store.edit.editStoreEbay.loading,
    updateStoreEtsyLoading: state.store.edit.editStoreEtsy.loading,
    updateStoreShopbaseLoading: state.store.edit.editStoreShopbase.loading,
    updateStoreShopbaseTokenLoading: state.store.edit.editShopbaseToken.loading,
    updateStoreShopbaseNormalLoading:
      state.store.edit.editShopbaseNormal.loading,
    detailStoreLoading: state.store.edit.detail.loading,
    createStoreLoading: state.store.create.loading,
    shopifyLinkForUpdatingToken:
      state.store.edit.getShopifyLinkForUpdatingToken,
    _updateShopifyToken: state.store.edit.updateShopifyToken,
    _updateShopifyStore: state.store.edit.updateShopifyStore,
    linkEbay: state.store.create.getLinkEbay.data,
    linkShopbase: state.store.create.getLinkShopbase.data,
    linkShopbaseToken: state.store.edit.getLinkShopbaseToken.data,
    linkShopbaseMerchant: state.store.edit.getLinkShopbaseMerchant.data,
    linkEbayLoading: state.store.create.getLinkEbay.loading,
    linkShopbaseLoading: state.store.create.getLinkShopbase.loading,
    linkShopifyLoading: state.store.create.getShopifyLink.loading,
    linkShopify: state.store.create.getShopifyLink.data,
    linkEtsy: state.store.create.getEtsyLink.data,
    linkEtsyLoading: state.store.create.getEtsyLink.loading,
    accounts: state.setting.account.accounts,
    storeStatus: state.store.list.listStoreStatus,
    deleteStoreLoading: state.store.list.delete.loading,
    profile: state.auth.info.profile,
  }),
  (dispatch) => ({
    getListStore: (options) => {
      dispatch(getListStore(options));
    },
  })
)(ImportOrder);
