import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, FieldArray } from "redux-form";
import validate from "../components/validate";
import {
  renderInputText,
  renderTextareaAnt,
  renderSwitchAnt,
  renderNumberAnt,
  renderAmazonForm,
} from "utils/Form";
import { GMButton } from "components/Button";

let FormCreateDataFeedAmazon = (props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 800, margin: "0 auto" }}>
      <div className="cursor-pointer d-flex justify-content-end mb-1">
        {/* <img
          style={{ right: 10, bottom: 10 }}
          onClick={openTourCreateAmazon}
          src={question}
          height={35}
        /> */}
      </div>
      <div className="form-group tour1CreateAmazon">
        <label className="col-form-label">
          Form name
        </label>
        <Field name="name" component={renderInputText} />
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group tour2CreateAmazon">
            <div className="d-flex align-items-center">
              <Field name="default" component={renderSwitchAnt} />
              <label className="ml-3 col-form-label">Mark as default</label>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group tour3CreateAmazon">
            <div className="d-flex align-items-center">
              <Field name="status" component={renderSwitchAnt} />
              <label className="ml-3 col-form-label">Active form</label>
            </div>
          </div>
        </div>
        <div className="col-12">
          <h5>Form Settings</h5>
        </div>
        <div className="col-sm-6 ">
          <div className="form-group tour4CreateAmazon">
            <label className="col-form-label ">
              Brand on Amazon
            </label>
            <Field
              name="amzBrand"
              component={renderInputText}
              placeholder="Brand on Amazon"
            />
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="form-group tour5CreateAmazon">
            <label className="col-form-label">
              Department Type
            </label>
            <Field name="amzDepartment" component={renderInputText} />
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="form-group tour6CreateAmazon">
            <label className="col-form-label">
              Fulfillment latency
            </label>
            <Field
              name="amzFulfillmentLatency"
              component={renderNumberAnt}
              placeholder="Ex: 10"
            />
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="form-group tour7CreateAmazon">
            <label className="col-form-label">
              Product Type
            </label>
            <Field
              name="amzProductType"
              component={renderInputText}
              placeholder="Product Feed Type"
            />
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="form-group tour7CreateAmazon">
            <label className="col-form-label">
              Item Type Keyword
            </label>
            <Field name="amzItemsType" component={renderInputText} />
            <p className="text-muted">
              Ex: fashion-t-shirts, novelty-t-shirts, fashion-hoodies,
              <br />
              fashion-sweatshirts, novelty-tank-tops
            </p>
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="form-group tour8CreateAmazon">
            <label className="col-form-label">
              Quantity
            </label>
            <Field
              name="amzQuantity"
              component={renderNumberAnt}
              placeholder="Ex: 100"
            />
          </div>
        </div>
      </div>
      <div className="form-group tour9CreateAmazon">
        <label className="col-form-label">Search term</label>
        <Field
          name="amzSearchTerm"
          component={renderInputText}
          placeholder="Search term"
        />
      </div>
      <div className="form-group tour10CreateAmazon">
        <label className="col-form-label">Image option 1</label>
        <Field
          name="amzImgSize"
          component={renderInputText}
          placeholder="Image option 1"
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Image option 2</label>
        <Field
          name="amzImgSize2"
          component={renderInputText}
          placeholder="Image option 2"
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Image option 3</label>
        <Field
          name="amzImgSize3"
          component={renderInputText}
          placeholder="Image option 3"
        />
      </div>
      <div className="form-group tour11CreateAmazon">
        <label className="col-form-label">Image option 4</label>
        <Field
          name="amzImgSize4"
          component={renderInputText}
          placeholder="Image option 4"
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Image option 5</label>
        <Field
          name="amzImgSize5"
          component={renderInputText}
          placeholder="Image option 5"
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Bullet point 1 2 3 4 5</label>
        <Field
          name="amzBullet1"
          component={renderInputText}
          className="mb-2"
          placeholder="Printed in the USA exclusively By Go All Out Screenprinting [brand]"
        />
        <Field
          name="amzBullet2"
          component={renderInputText}
          className="mb-2"
          placeholder="Go All Out is a registered trademark and Go All Out Screenprinting is an Amazon registered brand"
        />
        <Field
          name="amzBullet3"
          component={renderInputText}
          className="mb-2"
          placeholder="SAME DAY SHIPPING PROGRAM! Go All Out is now offering same day shipping on this item if ordered before 2PM CST on a weekday!"
        />
        <Field
          name="amzBullet4"
          component={renderTextareaAnt}
          placeholder="Go All Out is the only authorized seller of this product. We cannot guarantee shirt sizing, material, print quality or same day shipping if 
                    purchased through another seller."
        />
      </div>
      <div className="form-group tour12CreateAmazon">
        <label className="col-form-label">Description</label>
        <Field name="amzDescription" component={renderTextareaAnt} />
      </div>
      <div className="form-group tour13CreateAmazon">
        <label
          htmlFor="data_feed_external_product_type"
          className="col-form-label"
        >
          External product type
        </label>
        <Field
          id="data_feed_external_product_type"
          name="external_product_type"
          component={renderInputText}
          placeholder="External product type"
        />
      </div>
      <div className="form-group tour14CreateAmazon">
        <label className="col-form-label">External product lists</label>
        <Field name="amzExternalProductIdType" component={renderTextareaAnt} />
      </div>
      <div className="form-group">
        <label
          htmlFor="data_feed_external_product_type"
          className="col-form-label"
        >
          Customize field
        </label>
        <FieldArray name={`custom`} component={renderAmazonForm} />
      </div>
      <div className="text-right">
        <GMButton
          type="submit"
        >
          Add Form
        </GMButton>
      </div>
      {/* <Tour
        className="size-tour"
        onBeforeClose={enableBody}
        steps={stepsCreateAmazon}
        startAt={stepCount - 1}
        isOpen={isTourOpenCreateAmazon}
        disableFocusLock={true}
        onRequestClose={closeTourCreateAmazon}
        badgeContent={(curr, tot) => `${curr}/${tot}`}
        rounded={5}
        onAfterOpen={(e) => {
          disableBody(e);
        }}
        prevButton={
          <div>
            <div className="btn d-flex justify-content-end">Back</div>
          </div>
        }
        nextButton={
          <div>
            <div className="btn btn-primary d-flex justify-content-center align-items-center">
              Next
            </div>
          </div>
        }
        lastStepNextButton={
          <div>
            <div className="btn btn-secondary d-flex justify-content-center align-items-center">
              Close
            </div>
          </div>
        }
      /> */}
    </form>
  );
};

FormCreateDataFeedAmazon.propTypes = {};

FormCreateDataFeedAmazon = reduxForm({
  form: "FormCreateDataFeedAmazon",
  enableReinitialize: true,
  validate,
})(FormCreateDataFeedAmazon);
FormCreateDataFeedAmazon = connect((state) => {
  return {};
})(FormCreateDataFeedAmazon);
export default FormCreateDataFeedAmazon;
