import { actionType } from './actions';
import { notification } from 'antd';

const initialState = {
  register: {
    loading: false,
    data: {},
  },
  serverErrorMessage: null,
  serverSuccessMessage: null,
};

function RegisterReducer(state = initialState, action) {
  switch (action.type) {
    case actionType.REGISTER.REQUEST:
      return {
        ...state,
        register: {
          ...state.register,
          loading: true,
        },
      };
    case actionType.REGISTER.SUCCESS:
      return {
        ...state,
        register: {
          ...state.register,
          data: action?.data?.customer || {},
          loading: false,
        },
        serverSuccessMessage: action.payload,
      };
    case actionType.REGISTER.ERROR:
      notification.warn({
        message:
          (action.error && action.error[0] && action.error[0]['clientMsg']) ||
          '',
      });
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
        },
        serverErrorMessage:
          (action.error && action.error[0] && action.error[0]['clientMsg']) ||
          '',
      };
    case actionType.REGISTER.RESET_MESSAGE:
      return {
        ...state,
        serverErrorMessage: null,
        serverSuccessMessage: null,
      };
    default:
      return state;
  }
}

export default RegisterReducer;
