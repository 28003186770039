export const actionType = {
  CREATE_STAFF_ACCOUNT: {
    REQUEST: "AUTH.CREATE_STAFF_ACCOUNT.REQUEST",
    SUCCESS: "AUTH.CREATE_STAFF_ACCOUNT.SUCCESS",
    ERROR: "AUTH.CREATE_STAFF_ACCOUNT.ERROR",
  },
  VERIFY_STAFF_TOKEN: {
    REQUEST: "AUTH.VERIFY_STAFF_TOKEN.REQUEST",
    SUCCESS: "AUTH.VERIFY_STAFF_TOKEN.SUCCESS",
    ERROR: "AUTH.VERIFY_STAFF_TOKEN.ERROR",
  },
};
export function createAccountStaffAction(data) {
  return {
    type: actionType.CREATE_STAFF_ACCOUNT.REQUEST,
    data,
  };
}

export function verifyStaffToken(data) {
  return {
    type: actionType.VERIFY_STAFF_TOKEN.REQUEST,
    data,
  };
}
