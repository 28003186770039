import React, { useEffect, useState } from 'react';
import { Table, Tag, Tooltip } from 'antd';
import Filter from 'components/Filter';
import ColumnRenderer from './PaymentQueue.column';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { getList } from 'scenes/Orders/scenes/ManualPayment/actions';
import { ReactComponent as HelpIcon } from 'images/icons/help-circle.svg';

export default (props) => {
  const queryParams = queryString.parse(window.location.search);
  const [initialFilterValues, setInitialFilterValues] = useState({
    sortBy: 'id',
    sortType: 'desc',
    ...queryParams,
  });
  const listOrderPayment = useSelector(
    (state) => state.order.manual_payment.listOrderPayment
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const fetchData = ({ page = 1, perPage = 20, ...options }) => {
    let params = {
      page,
      perPage,
      sortBy: 'id',
      sortType: 'desc',
    };
    if (options?.search) {
      params['search'] = options?.search;
    }
    const { search, ...replaceParams } = params;
    delete replaceParams.page;
    delete params.name;

    if (`${window.location.pathname + window.location.search}` !== window.location.pathname + '?' + queryString.stringify(replaceParams)) {
      history.replace(
        window.location.pathname + '?' + queryString.stringify(replaceParams)
      );
    }
    dispatch(getList(params));
  };

  useEffect(() => {
    fetchData(initialFilterValues);
  }, [initialFilterValues]);

  return (
    <div className="tab-transaction">
      <div className="title">
        <Filter
          placeholder="Search by keyword"
          onSubmit={(values) => {
            setInitialFilterValues(oldState => ({
              ...oldState,
              search: (values.search || '').trim(),
            }))
          }}
          tooltip={
            <Tooltip title="Search by Order name/Order ID, or Queue ID.">
              <HelpIcon className="search-tooltip" />
            </Tooltip>
          }
        />
      </div>
      <Table
        rowKey="id"
        columns={ColumnRenderer()}
        dataSource={listOrderPayment.data}
        loading={listOrderPayment.loading}
        scroll={{ x: 1000 }}
        pagination={
          !props.is_mobile
            ? {
              showTotal: (total) => `Total ${total} items`,
              total: listOrderPayment.total,
              pageSize: 20,
              current: listOrderPayment.params.page,
              showSizeChanger: false,
              onChange: (page, perPage) =>
                fetchData({
                  ...listOrderPayment.params,
                  page,
                  perPage,
                }),
            }
            : false
        }
      />
    </div>
  );
};
