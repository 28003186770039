import { 
    actionType as TYPE
} from './actions'

const initialState = {
    import_progress: {
        data: [],
        loading: false,
        params: {},
        total: 0
    },
   
}

function ListReducer(state = initialState, action){
    switch(action.type) {
        case TYPE.LIST.REQUEST:
            return {
                ...state,
                import_progress: {
                    ...initialState.import_progress,
                    params: action.params,
                    loading: true
                }              
            }
        case TYPE.LIST.SUCCESS:
            return {
                ...state,
                import_progress: {
                    ...state.import_progress,
                    loading: false,
                    data: action.data.importBackgrounds,
                    total: action.data.pagination.total,
                },
            }
        case TYPE.LIST.ERROR:
            return {
                ...state,
                import_progress: {
                    ...state.import_progress,
                    loading: false
                }  
            }
        default:
            return state
    }
}

export default ListReducer