import { takeLatest, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import {
  WEBHOOKS_CREATE_REQUEST,
  WEBHOOKS_CREATE_SUCCESS,
  WEBHOOKS_CREATE_FAIL,
} from "./constants";
import { create } from "apis/webhooks";

function* createWebhooksSaga(action) {
  try {
    const { params } = action;
    const response = yield call(create, params);

    if (response.code === "success") {
      yield all([
        put({ type: WEBHOOKS_CREATE_SUCCESS, response }),
        put(replace("/settings/webhooks/list")),
      ]);
    } else {
      yield put({ type: WEBHOOKS_CREATE_FAIL, error: response });
    }
  } catch (error) {
    yield all([put({ type: WEBHOOKS_CREATE_FAIL, error })]);
  }
}

function* createWebhooksWatcher() {
  yield all([takeLatest(WEBHOOKS_CREATE_REQUEST, createWebhooksSaga)]);
}

export default createWebhooksWatcher;
