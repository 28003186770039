import React from "react";
import StarRatings from "react-star-ratings";
import classNames from "classnames";
const renderRatingStar = ({
  input,
  starDimension= "20px",
  starSpacing="1px",
  meta: { error, invalid },
  classNameContainer="",
  defaultValue,
  handleOnRatting= null,
}) => {
  return (
    <div className={classNames("d-flex justify-content-center align-items-center", classNameContainer)}>
      <StarRatings
        rating={input.value || defaultValue}
        changeRating={(val) => {handleOnRatting(val); input.onChange(val)}}
        starSpacing={starSpacing}
        starDimension={starDimension}
        starRatedColor="#ffc400"
        starEmptyColor="#DADED7"
        starHoverColor="#ffc400"
      />
      {invalid && (
        <hr
          className="border-danger m-0 position-absolute"
          style={{ width: "100%", left: "0%", bottom: "0" }}
        />
      )}
      {error && <span className="invalid-feedback">{error}</span>}
    </div>
  );
};

export default React.memo(renderRatingStar);
