import Edit from "./scenes/Edit";

export default [
  {
    key: "general",
    name: "General",
    component: Edit,
    path: "/settings/general",
    icon: null,
    template: "default",
    isPublic: true,
  },
];
