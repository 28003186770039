import {
  ERROR_50,
  ERROR_600,
  GRAY_100,
  GRAY_50,
  GRAY_600,
  SUCCESS_50,
  SUCCESS_900,
} from "components/colors";

export const badgeColors = (colorName = false) => {
  switch ((colorName + '')?.toLowerCase()) {
    case 'unpaid':
      return `
        border-color: ${GRAY_100};
        color: ${GRAY_600};
        background-color: ${GRAY_50};
      `;
    case 'paid':
      return `
        border-color: ${SUCCESS_900};
        color: ${SUCCESS_900};
        background-color: ${SUCCESS_50};
      `;
    case 'failed':
      return `
        border-color: ${ERROR_600};
        color: ${ERROR_600};
        background-color: ${ERROR_50};
      `;
    case 'canceled':
      return `
        border-color: ${GRAY_100};
        color: ${GRAY_600};
        background-color: ${GRAY_50};
      `;
    default:
      return undefined;
  }
};