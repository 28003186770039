import React from 'react';

export const COMPANY_ADDRESS = (
    <>
        <span>
            Gearment Inc. 14801 Able Ln, Ste 102, Huntington Beach, CA
            92647, US
        </span>
        <br />
        <span>
            1 (866) 236 5476{'   '}-{'   '}
            <a
                href="mailto:info@gearment.com"
                className="font-weight-bold"
            >
                info@gearment.com
            </a>
        </span>
    </>
)

export const GMCopyright = (
    <div className="gmportal-copyright text-center reserved">
        ©2023 Gearment Inc. All Rights Reserved.
    </div>
)

export const GMTermPrivacy = (
    <div className="term-privacy text-center reserved">
        <a
            target="_blank"
            href="https://gearment.com/p/terms-of-services/"
            rel="noopener noreferrer"
        >
            Term Of Services
        </a>
        {' '}|{' '}
        <a
            href="https://gearment.com/p/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
        >
            Privacy Policies
        </a>
    </div>
);
