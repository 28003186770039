import React from 'react';
import { Link } from 'react-router-dom';
import { toFixed } from 'utils/helper';
import getMomentInstance from 'utils/moment';
import money from 'utils/money';
import {
  getOptionLabel,
  manual_type_status,
  PAYMENT_METHOD_TYPE,
} from 'utils/options';

export default () => [
  {
    title: 'ID',
    align: 'left',
    dataIndex: 'id',
    key: 'id',
    render: (text) => {
      return text ? <Link to={`/billing/payment/${text}`}>{text}</Link> : 'N/A';
    },
  },
  {
    title: 'Type',
    align: 'center',
    dataIndex: 'paymentType',
    key: 'paymentType',
    render: (text) => {
      return getOptionLabel(manual_type_status, text);
    },
  },
  {
    title: 'Transaction ID',
    align: 'center',
    dataIndex: 'transaction',
    key: 'transaction',
    render: (text, record) => {
      return record ? (
        <Link
          to={`/billing/transactions/${record?.transactionData?.id}/detail`}
        >
          {record?.transactionData?.name}
        </Link>
      ) : (
        'N/A'
      );
    },
  },
  {
    title: 'Amount',
    align: 'right',
    dataIndex: 'amount',
    key: 'amount',
    render: (text, record) => {
      return <b>{toFixed(text)}</b>;
    },
  },
  {
    title: 'Created date',
    align: 'center',
    dataIndex: 'startTime',
    key: 'startTime',
    render: (text) => {
      return getMomentInstance(null, text, true);
    },
  },
  {
    title: 'Status',
    align: 'center',
    dataIndex: 'status',
    key: 'status',
    render: (text) => {
      return getOptionLabel(PAYMENT_METHOD_TYPE, text);
    },
  },
];
