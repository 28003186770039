import { notification, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getDetailIssue, setDetailIssueAction } from "../DetailIssue/actions";
import { listIssueCategory, udpateIssueAction } from "./actions";
import UpdateForm from "./components/UpdateForm";
import { Wrapper } from "./Update.styled";

export const UpdateIssue = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const detailIssue = useSelector(
    (state) => state.order.issue_detail.detail?.data?.issue
  );
  const udpateIssueLoading = useSelector(
    (state) => state.order.issue_detail.detail.loading
  );
  const listIssueCategoryData = useSelector(
    (state) => state.order.createIssue.listIssueCategory.data
  );
  const submitHandler = (values) => {
    dispatch(udpateIssueAction(values));
  };
  useEffect(() => {
    dispatch(getDetailIssue(id));
    dispatch(listIssueCategory());
  }, [dispatch, id]);
  useEffect(() => {
    if (detailIssue && detailIssue?.status !== 1) {
      notification.warning({ message: `Cannot edit issue #${detailIssue?.orderData?.name || detailIssue.id}`, duration: 5 });
      history.replace(`/orders/order-issues/${detailIssue.id}`)
    }
  }, [detailIssue, history]);
  return (
    <Wrapper
      pageName={id && `Issue #${id || ''}: Edit Details`}
      noContainer
      breadcrumDetails={{
        [id]: id && `Issue #${id || ''}`,
      }}
    >
      <Spin
        spinning={udpateIssueLoading}
      >
        <UpdateForm
          onSubmit={submitHandler}
          initialValues={{ ...detailIssue, link: (detailIssue?.link?.length || 0) > 0 ? detailIssue?.link : [''] }}
          listIssueCategoryData={listIssueCategoryData}
        />
      </Spin>
    </Wrapper>
  );
};
