import styled from 'styled-components';
import PropTypes from 'prop-types';

export const GlowingSVG = styled.div`
  background: ${(p) => p.backgroundColor};
  border: 10px solid ${(p) => p.borderColor};
  border-radius: 50%;
  width: 66px;
  height: 66px;
  line-height: 66px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  svg {
    width: 28px;
    height: 28px;
    line-height: 1;
    ${(p) => `stroke: ${p.svgStrokeColor};`}
    ${(p) => `fill: ${p.svgFillColor};`}
    vertical-align: middle;
  }
  &.sx {
    width: 24px;
    height: 24px;
    display: inline-block;
    text-align: center;
    svg {
      width: 10px;
      height: 10px;
      line-height: 1;
      stroke: ${(p) => p.svgStrokeColor};
      vertical-align: middle;
    }
  }
  &.md {
    width: 34px;
    height: 34px;
    display: inline-block;
    text-align: center;
    /* border: 12px solid ${(p) => p.borderColor}; */
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
      line-height: 1;
      stroke: ${(p) => p.svgStrokeColor};
      vertical-align: middle;
    }
  }
`;

GlowingSVG.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  svgStrokeColor: PropTypes.string.isRequired,
};
