import React, { Component } from "react";
import { connect } from "react-redux";
import { Pagination, Table} from "antd";
import queryString from "query-string";
import {
  getList,
  exportOrders,
  exportInvoice
} from "./actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  getOptionLabel,
  option_invoice_status
} from "utils/options";
import money from "utils/money";
import getMomentInstance from "utils/moment";
class List extends Component {
  constructor(props) {
    super(props);
    const { createdAtFrom, createdAtTo, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      initial_filter_values: {
        sortBy: "id",
        sortType: "desc",
        ...query_params,
        date_range: [
          createdAtFrom
            ? getMomentInstance(createdAtFrom, 0, false, true)
            : null,
          createdAtTo ? getMomentInstance(createdAtTo, 0, false, true) : null,
        ],
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }

  fetchData = ({ date_range = null, page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (date_range && date_range[0]) {
      params.createdAtFrom =
        typeof date_range[0] === "string"
          ? date_range[0]
          : date_range[0].format("YYYY/MM/DD");
    }
    if (date_range && date_range[1]) {
      params.createdAtTo =
        typeof date_range[1] === "string"
          ? date_range[1]
          : date_range[1].format("YYYY/MM/DD");
    }

    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getList({
      ...params,
    });
  };

  render() {
    const {
      invoices,
      exportOrdersLoading,
      exportInvoiceLoading,
      pageName,
    } = this.props;
    const columns = [
      {
        title: <b>CODE</b>,
        dataIndex: "code",
        align: "center",
        key: "code",
      },
      {
        title: <b>AMOUNT</b>,
        dataIndex: "total",
        key: "total",
        render: (value) => money(value || 0)
      },
      {
        title: <b>PAYMENT</b>,
        dataIndex: "status",
        align: "center",
        key: "status",
        render: (value) =>
          getOptionLabel(option_invoice_status, value, false),
      },
      {
        title: <b>DATE</b>,
        dataIndex: "createdAt",
        align: "center",
        key: "createdAt",
        render: (value) => getMomentInstance(null, value, false, true),
      },
      {
        title: <b>DUE DATE</b>,
        key: "endedAt",
        align: "center",
        dataIndex: "endedAt",
        render: (value) => getMomentInstance(null, value, false, true),
      },
      {
        title: <b>ACTION</b>,
        key: "action",
        align: "center",
        render: (text, record) => (
          <div>
             <button
                type="button"
                className="btn btn-success mr-2"
                onClick={() => {this.props.exportInvoice(record.id)}}
            >
              <FontAwesomeIcon className="mr-2" icon={faDownload} /> Invoice
            </button>
            <button
                type="button"
                className="btn btn-success"
                onClick={() => {this.props.exportOrders(record.id)}}
              >
                <FontAwesomeIcon className="mr-2" icon={faDownload} /> Orders
              </button>
          </div>
        ),
      },
    ];

    return (
      <div>
         <div className="d-flex justify-content-between align-items-center my-4">
            <h1 className="h3 mb-0">{pageName}</h1>
          </div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={invoices.data}
            loading={invoices.loading || exportOrdersLoading || exportInvoiceLoading}
            scroll={{ x: 1000 }}
            pagination={!this.props.is_mobile ? {
              showTotal: (total) => `Total ${total} items`,
              total: invoices.total,
              pageSize: parseInt(
                invoices.params.perPage > invoices.total
                  ? invoices.total
                  : invoices.params.perPage
              ),
              current: invoices.params.page,
              showSizeChanger: true,
              onChange: (page, perPage) =>
                this.fetchData({ ...invoices.params, page, perPage }),
            }: false}
          />
          {this.props.is_mobile && <div className="d-flex justify-content-end align-items-center py-3 px-3">
            <div>
              <Pagination
                size="small"
                showSizeChanger
                className="pagination"
                total={invoices.total}
                defaultCurrent={invoices?.params?.page || 1}
                pageSize={invoices?.params?.perPage || 0}
                onChange={(page, perPage) =>
                  this.fetchData({ ...invoices.params, page: page === Number.NEGATIVE_INFINITY ? 1 : (page === 0 ? 1 : page), perPage })
                }
              />
            </div>
          </div>}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    invoices: state.invoice.list.invoices,
    exportOrdersLoading: state.invoice.list.exportOrders.loading,
    exportInvoiceLoading: state.invoice.list.exportInvoice.loading,
  }),
  (dispatch) => ({
    getList: (params) => {
      dispatch(getList(params));
    },
    exportOrders: (id) => {
      dispatch(exportOrders(id));
    },
    exportInvoice: (id) => {
      dispatch(exportInvoice(id))
    }
  })
)(List);
