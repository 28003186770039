
import {
    takeLatest,
    call,
    put,
    all
} from 'redux-saga/effects'
import { create, update } from "apis/order-issues";
import { listOrderToCreateIssue } from "apis/orders"
import { list } from "apis/order_issue_category"
import {
    UDPATE_ISSUES_REQUEST,
    CREATE_ISSUES_REQUEST,
    CREATE_ISSUES_SUCCESS,
    CREATE_ISSUES_FAIL,
    UPDATE_ISSUES_SUCCESS,
    UPDATE_ISSUES_FAIL,

    LIST_ISSUE_CATEGORY_REQUEST,
    LIST_ISSUE_CATEGORY_SUCCESS,
    LIST_ISSUE_CATEGORY_FAIL,
    LIST_ORDER_TO_CREATE_ISSUE_SUCCESS,
    LIST_ORDER_TO_CREATE_ISSUE_FAIL,
    LIST_ORDER_TO_CREATE_ISSUE_REQUEST

} from './constants'
import { replace } from "connected-react-router";


function* createIssueSaga(action) {
    try {
        const { data } = action
        const response = yield call(create, data)

        const rsItem = (response.data || [])?.filter(r => r.code === 'invalid_reason');
        const dispatchArray = [put({ type: CREATE_ISSUES_SUCCESS, errors: response })];
        if (rsItem.length <= 0) {
            dispatchArray.push(put(replace('/orders/order-issues')));
        }

        yield all(dispatchArray);
    } catch (error) {
        yield all([
            put({ type: CREATE_ISSUES_FAIL, error })
        ])
    }
}

function* updateIssueSaga(action) {
    try {
        const { data } = action
        const response = yield call(
            update,
            data.id,
            {
                issueDetail: data?.detail,
                issueImage: data?.link,
                itemEffect: data?.issueItemAffected,
                issueCategoryId: data?.issueCategoryId,
                solution: data?.solution,
            },
        );
        yield all([
            put({ type: UPDATE_ISSUES_SUCCESS, errors: response }),
            put(replace(`/orders/order-issues/${data.id}`)),
        ])
    } catch (error) {
        yield all([
            put({ type: UPDATE_ISSUES_FAIL, error }),
        ])
    }
}

function* listIssueCategorySaga(action) {
    try {
        const { data } = action;
        const response = yield call(list, data)

        if (response.code === 'success') {
            yield all([
                put({ type: LIST_ISSUE_CATEGORY_SUCCESS, ...response }),
            ])
        } else {
            yield put({ type: LIST_ISSUE_CATEGORY_FAIL, error: response })
        }
    } catch (error) {
        yield all([
            put({ type: LIST_ISSUE_CATEGORY_FAIL, error })
        ])
    }
}

function* listOrderToCreateIssueSaga(action) {
    try {
        const { data } = action;
        const response = yield call(listOrderToCreateIssue, data)

        yield all([
            put({ type: LIST_ORDER_TO_CREATE_ISSUE_SUCCESS, errors: response }),
        ])
    } catch (error) {
        yield all([
            put({ type: LIST_ORDER_TO_CREATE_ISSUE_FAIL, error })
        ])
    }
}


function* createIssueWatcher() {
    yield all([
        takeLatest(CREATE_ISSUES_REQUEST, createIssueSaga),
        takeLatest(UDPATE_ISSUES_REQUEST, updateIssueSaga),
        takeLatest(LIST_ISSUE_CATEGORY_REQUEST, listIssueCategorySaga),
        takeLatest(LIST_ORDER_TO_CREATE_ISSUE_REQUEST, listOrderToCreateIssueSaga)
    ])
}

export default createIssueWatcher