export const actionType = {
    LIST: {
        REQUEST: 'TRANSACTIONS.LIST.REQUEST',
        SUCCESS: 'TRANSACTIONS.LIST.SUCCESS',
        ERROR: 'TRANSACTIONS.LIST.ERROR',
    },
    LIST_QUICKVIEW: {
        REQUEST: 'LIST_QUICKVIEW.REQUEST',
        SUCCESS: 'LIST_QUICKVIEW.SUCCESS',
        ERROR: 'LIST_QUICKVIEW.ERROR',
    },
    GET_PROFIT: {
        REQUEST: 'TRANSACTIONS.GET_PROFIT.REQUEST',
        SUCCESS: 'TRANSACTIONS.GET_PROFIT.SUCCESS',
        ERROR: 'TRANSACTIONS.GET_PROFIT.ERROR',
    },
    EXPORT: {
        REQUEST: 'TRANSACTIONS.EXPORT.REQUEST',
        SUCCESS: 'TRANSACTIONS.EXPORT.SUCCESS',
        ERROR: 'TRANSACTIONS.EXPORT.ERROR',
    },
};

export function getList(params) {
    return {
        type: actionType.LIST.REQUEST,
        params
    }
}
export function getListQuickView(id) {
    return {
        type: actionType.LIST_QUICKVIEW.REQUEST,
        id
    }
}
export function getProfitTransaction() {
    return {
        type: actionType.GET_PROFIT.REQUEST,
    }
}

export function exportTransaction(params){
    return {
        type: actionType.EXPORT.REQUEST,
        params
    }
}