import { actionType as TYPE } from "./actions";
import { notification } from "antd";
const initialState = {
  listCampaignsToExport: {
    loading: false,
    data: [],
  },
  exportFeedFormAmazon: {
    loading: false
  }
};

function FeedFormAmazonReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST_CAMPAIGN_TO_EXPORT.REQUEST:
      return {
        ...state,
        listCampaignsToExport: {
          ...state.listCampaignsToExport,
          loading: true
        }
      }
    case TYPE.LIST_CAMPAIGN_TO_EXPORT.SUCCESS:  
      return {
        ...state,
        listCampaignsToExport: {
          ...state.listCampaignsToExport,
          loading: false,
          data: action?.data?.campaigns || []
        }
      }
    case TYPE.LIST_CAMPAIGN_TO_EXPORT.FAIL:
      return {
        ...state,
        listCampaignsToExport: {
          ...state.listCampaignsToExport,
          loading: false
        }
      }
    case TYPE.EXPORT_FEED_FORM_AMAZON.REQUEST:
      return {
        ...state,
        exportFeedFormAmazon: {
          ...state.exportFeedFormAmazon,
          loading: true
        }
      }
    case TYPE.EXPORT_FEED_FORM_AMAZON.SUCCESS:  
      notification.success({
        message: action.clientMsg || "Export feed form Amazon successfully.",
        duration: 5
    });
      return {
        ...state,
        exportFeedFormAmazon: {
          ...state.exportFeedFormAmazon,
          loading: false,
        }
      }
    case TYPE.EXPORT_FEED_FORM_AMAZON.FAIL:
      notification.warn({
        message: action.error && action.error[0] && action.error[0]['clientMsg'] ? action.error[0]['clientMsg'] : 'Export feed form Amazon failed.',
        duration: 5
    });
      return {
        ...state,
        exportFeedFormAmazon: {
          ...state.exportFeedFormAmazon,
          loading: false
        }
      }
    default:
      return state;
  }
}

export default FeedFormAmazonReducer;
