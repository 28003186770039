import {
  BASE_WHITE,
  GRAY_200,
  GRAY_900,
  SHADOW_SM_0_1,
  SHADOW_SM_0_06,
  GRAY_600,
} from "components/colors";
import styled from "styled-components";

export const PageContainerWrapper = styled.div`
  padding: 32px;
  .topcta {
    width: 100%;
    position: relative;
    .ctas {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .extendPageName{
      color:${GRAY_600};
    }
  }
  .filter {
    margin-top: 20px;
    &.only-filter {
      .filter-button {
        margin-left: 0;
      }
    }
  }
  h2.header {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: ${GRAY_900};
    margin: 0;
    margin-top: 20px;
    .pagename{
      font-weight: 600;
    }
  }
  div.balance,
  .gm-card,
  .main-content {
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_200};
    box-shadow: 0px 1px 3px ${SHADOW_SM_0_1}, 0px 1px 2px ${SHADOW_SM_0_06};
    border-radius: 12px;
    margin-top: 20px;
  }
  .ant-card {
    border-radius: 12px;
  }
  .gm-card,
  .main-content {
    padding: 20px;
  }
`;
