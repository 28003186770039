import React from 'react';
import { cancelPayoneer } from 'apis/payment_menthod';
import { useDispatch } from 'react-redux';
import { setLoading } from 'scenes/BillingPaymentAccount/actions';
import { notification, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;
function RemovePayoneerAccountButton({ getPayoneerAccount }) {
  const dispatch = useDispatch();
  const handleOnRemovePayoneerAccount = () => {
    confirm({
      title: 'Remove this Payoneer account?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will not be able to top up or  make payment to G-Wallet with this account anymore.',
      cancelText: 'Cancel',
      okText: 'Remove',
      onOk() {
        dispatch(setLoading(true));
        cancelPayoneer()
          .then((response) => {
            dispatch(setLoading(false));
            if (response?.code === 'success') {
              notification.success({
                message:
                  response?.clientMsg ||
                  'Remove Payoneer account successfully.',
              });
              getPayoneerAccount();
            } else {
              notification.warn({
                message:
                  response?.clientMsg || 'Remove Payoneer account failed.',
              });
            }
          })
          .catch((error) => {
            dispatch(setLoading(false));
          });
      },
      onCancel() {},
    });
  };
  return (
    <Button
      type='button'
      size='large'
      shape='round'
      className='btn btn-danger'
      onClick={handleOnRemovePayoneerAccount}
    >
      Remove Payoneer account
    </Button>
  );
}

export default React.memo(RemovePayoneerAccountButton);