import {
  BASE_WHITE,
  ERROR_500,
  ERROR_600,
  ERROR_700,
  ERROR_200,
  ERROR_100,
  GRAY_200,
  GRAY_300,
  GRAY_50,
  GRAY_700,
  GRAY_800,
  PRIMARY_100,
  PRIMARY_200,
  PRIMARY_25,
  PRIMARY_300,
  PRIMARY_50,
  PRIMARY_500,
  PRIMARY_600,
  PRIMARY_700,
  PRIMARY_800,
  SHADOW_XS,
  BLUE_400,
} from 'components/colors';

const svgColoring = (color) => {
  return `
    svg {
      stroke: ${color};
    }
  `;
};

const COLORS = {
  Primary: {
    default: `
      color: ${BASE_WHITE};
      background: ${PRIMARY_500};
      border: 1px solid ${PRIMARY_500};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      ${svgColoring(BASE_WHITE)}
    `,
    hover: `
      background: ${PRIMARY_600};
      border: 1px solid ${PRIMARY_600};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    `,
    focus: `
      background: ${PRIMARY_500};
      border: 1px solid ${PRIMARY_500};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    `,
    disabled: `
      background: ${PRIMARY_200};
      border: 1px solid ${PRIMARY_200};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    `,
  },
  PrimaryError: {
    default: `
      background: ${ERROR_500};
      border: 1px solid ${ERROR_600};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      color: ${BASE_WHITE};
      ${svgColoring(BASE_WHITE)}
    `,
    hover: `
      background: ${ERROR_600};
      border: 1px solid ${ERROR_700};
    `,
    focus: `
      background: ${ERROR_500};
      border: 1px solid ${ERROR_600};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${ERROR_100};
    `,
    disabled: `
      background: ${ERROR_200};
      border: 1px solid ${ERROR_200};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    `,
  },
  SecondaryGray: {
    default: `
      color: ${GRAY_700};
      background: ${BASE_WHITE};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      ${svgColoring(GRAY_700)}
    `,
    hover: `
      color: ${GRAY_800};
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      ${svgColoring(GRAY_800)}
    `,
    focus: `
      color: ${GRAY_700};
      background: ${BASE_WHITE};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      ${svgColoring(GRAY_700)}
    `,
    disabled: `
      color: ${GRAY_300};
      background: ${BASE_WHITE};
      border: 1px solid ${GRAY_200};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      ${svgColoring(GRAY_300)}
    `,
  },
  SecondaryColor: {
    default: `
      color: ${PRIMARY_500};
      background: ${PRIMARY_50};
      border: 1px solid ${PRIMARY_50};
      box-shadow: none;
      ${svgColoring(PRIMARY_500)}
    `,
    hover: `
      color: ${PRIMARY_800};
      background: ${PRIMARY_100};
      border: 1px solid ${PRIMARY_100};
      box-shadow: none;
      ${svgColoring(PRIMARY_800)}
    `,
    focus: `
      color: ${PRIMARY_700};
      background: ${PRIMARY_50};
      border: 1px solid ${PRIMARY_50};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
      ${svgColoring(PRIMARY_700)}
    `,
    disabled: `
      color: ${PRIMARY_300};
      background: ${PRIMARY_25};
      border: 1px solid ${PRIMARY_25};
      box-shadow: none;
      ${svgColoring(PRIMARY_300)}
    `,
  },

  CopiedColor: {
    default: `
      color: ${BLUE_400};
      background: ${BASE_WHITE};
      border: 1px solid ${BLUE_400};
      box-shadow: none;
      ${svgColoring(BLUE_400)}
    `,
  },
};
COLORS.default = COLORS.Primary;

export default COLORS;
