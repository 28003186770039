import React from "react";
import { registerPayoneer } from "apis/payment_menthod";
import { useDispatch } from "react-redux";
import { setLoading } from "scenes/BillingPaymentAccount/actions";
import { notification } from "antd";
function RegisterPayoneerButton(props) {
  const dispatch = useDispatch();
  const handleOnRegisterPayoneer = () => {
    dispatch(setLoading(true));
    registerPayoneer()
      .then((response) => {
        dispatch(setLoading(false));
        if (response?.code === "success" && response?.data?.registration_link) {
          notification.success({
            message: "Please wait for a minute ...",
          });
          window.location.href = response?.data?.registration_link;
        } else {
          notification.warn({
            message: response?.[0]?.clientMsg || "Register Payoneer account failed.",
          });
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
      });
  };
  return (
    <button
      style={{ backgroundColor: "#f60" }}
      type="button"
      className="text-white rounded btn btn-primary"
      onClick={handleOnRegisterPayoneer}
    >
      Register Payoneer
    </button>
  );
}

export default React.memo(RegisterPayoneerButton);