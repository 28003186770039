import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';
import { getPreviewIndemnication } from 'apis/indemnification';

function* getPreviewFormSaga(action) {
  const { data } = action;
  try {
    const response = yield call(getPreviewIndemnication,data);
    if (response.code === 'success') {
      yield put({ type: TYPE.GET_PREVIEW_INDEMNICATION.SUCCESS, ...response });
    } else {
      yield put({
        type: TYPE.GET_PREVIEW_INDEMNICATION.ERROR,
        error: response,
      });
    }
  } catch (error) {
    yield all([
      yield put({ type: TYPE.GET_PREVIEW_INDEMNICATION.ERROR, error }),
    ]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.GET_PREVIEW_INDEMNICATION.REQUEST, getPreviewFormSaga);
}

export default watcher;
