import React from "react";
import { Input } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import money from "utils/money";

const FieldText = ({
  input,
  prefix,
  suffix,
  addonBefore,
  addonAfter,
  className,
  placeholder,
  size,
  meta: { error, invalid, submitFailed },
  disabled,
  maxLength,
  classNameContainer = "",
  defaultValue,
  isUppercase,
  isLowercase,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames("position-relative w-100", classNameContainer)}>
      {maxLength && (
        <small
          className="text-muted"
          style={{
            position: "absolute",
            right: 2,
            top: "-15px",
            zIndex: 1,
          }}
        >
          {money((input.value || "").length, false)}/{money(maxLength, false)}
        </small>
      )}
      <Input
        allowClear
        disabled={disabled}
        value={input.value || defaultValue}
        size={size || "large"}
        className={classNames(className)}
        onChange={(e) => {
          let realValue = maxLength
            ? e.target.value.substring(0, maxLength)
            : e.target.value;
          realValue = isUppercase ? realValue.toUpperCase() : (isLowercase ? realValue.toLowerCase() : realValue);
          input.onChange(realValue);
        }}
        placeholder={t(placeholder)}
        prefix={prefix}
        suffix={suffix}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
      />
      {invalid && (
        <hr
          className="border-danger m-0 position-absolute"
          style={{ width: "100%", left: "0%", bottom: "0%" }}
        />
      )}
      {(submitFailed && error) && <span className="invalid-feedback">{error}</span>}
    </div>
  );
};

export default FieldText;
