export const actionType = {
    DETAIL: {
        REQUEST: 'COLLECTION.EDIT.DETAIL.REQUEST',
        SUCCESS: 'COLLECTION.EDIT.DETAIL.SUCCESS',
        ERROR: 'COLLECTION.EDIT.DETAIL.ERROR',
    },
    UPDATE: {
        REQUEST: 'COLLECTION.EDIT.UPDATE.REQUEST',
        SUCCESS: 'COLLECTION.EDIT.UPDATE.SUCCESS',
        ERROR: 'COLLECTION.EDIT.UPDATE.ERROR',
    },
};


export function update(id, params) {
    return {
        type: actionType.UPDATE.REQUEST,
        id,
        params
    }
}

export function getDetail(id) {
    return {
        type: actionType.DETAIL.REQUEST,
        id
    }
}