import React from 'react';
import { notification } from 'antd';
import { actionType as TYPE } from './actions';
import { MSG_REMOVE_PM_FAIL, MSG_REMOVE_PM_SUCCESS } from './const';

const initialState = {
  isRefetching: false,
  primary: null,
  list: [],
  loading: false,
  logs: [],
  detailBillingMethod: { data: [], loading: false },
};

function BillingInforReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.REFETCH_BILLING_INFORS:
      return {
        ...state,
        isRefetching: action.isRefetching,
      };
    case TYPE.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case TYPE.SAVE_BILLING_METHOD.SUCCESS:
      setTimeout(() => {
        notification.success({
          message:
            action?.response?.[0]?.clientMsg ||
            'Add billing method successfully.',
          duration: 5,
        });
      });
      return state;
    case TYPE.SAVE_BILLING_METHOD.FAILED:
      setTimeout(() => {
        notification.warn({
          message:
            action?.response?.[0]?.clientMsg ||
            'Add billing method failed.',
          duration: 5,
        });
      });
      return state;
    case TYPE.LOAD_PRIMARY_BILLING_METHOD.SUCCESS:
      return {
        ...state,
        primary: action.payload,
      };
    case TYPE.LOAD_PRIMARY_BILLING_METHOD.FAILED:
      return {
        ...state,
        primary: action.payload,
      };
    case TYPE.LOAD_LIST_BILLING_METHOD.SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case TYPE.LOAD_LIST_BILLING_METHOD.FAILED:
      return {
        ...state,
        list: action.payload,
      };
    //Load log
    case TYPE.LOAD_LOG_BILLING_METHOD.SUCCESS:
      return {
        ...state,
        logs: action.payload,
      };
    case TYPE.LOAD_LOG_BILLING_METHOD.FAILED:
      return {
        ...state,
        logs: action.payload,
      };
    //Get detail
    case TYPE.DETAIL_BILLING_METHOD.REQUEST:
      return {
        ...state,
        detailBillingMethod: {
          ...initialState.detailBillingMethod,
          loading: true,
        },
      };
    case TYPE.DETAIL_BILLING_METHOD.SUCCESS:
      return {
        ...state,
        detailBillingMethod: {
          ...state.detailBillingMethod,
          loading: false,
          data: action.data ? action.data?.billing : [],
        },
      };
    case TYPE.DETAIL_BILLING_METHOD.FAILED:
      return {
        ...state,
        detailBillingMethod: {
          ...state.detailBillingMethod,
          loading: false,
        },
      };
    //
    case TYPE.SET_PRIMARY.SUCCESS:
      notification.success({
        message: `Your primary billing method has been changed to ${action?.payload?.billing_method}${action?.payload?.account ? ` - ${action?.payload?.account}` : ''}.`,
        duration: 5,
      });
      return state;
    case TYPE.SET_PRIMARY.FAILED:
      notification.warn({
        message: `Your primary billing method cannot be changed to ${action?.payload?.billing_method}${action?.payload?.account ? ` - ${action?.payload?.account}` : ''}.`,
        duration: 5,
      });
      return state;
    case TYPE.DELETE.SUCCESS:
      notification.success({
        message: MSG_REMOVE_PM_SUCCESS,
        duration: 5,
      });
      return state;
    case TYPE.DELETE.FAILED:
      notification.warn({
        message: MSG_REMOVE_PM_FAIL,
        duration: 5,
      });
      return state;
    default:
      return state;
  }
}

export default BillingInforReducer;
