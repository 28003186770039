import React, { memo, useEffect } from "react";
import { ReactComponent as WarningIcon } from "images/icons/error.svg";
import { WARNING_500 } from "components/colors";
import CustomNotification from "./styled";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadPrimaryAccountAction } from "scenes/BillingPaymentAccount/actions";

export default memo(({ className = "" }) => {
  const d = useDispatch();
  const loading = useSelector((s) => s.billingPaymentAccount?.loading);
  const primaryBillingPaymentAccount = useSelector(
    (s) => s.billingPaymentAccount?.primary
  );
  useEffect(() => {
    if (!primaryBillingPaymentAccount && !loading) {
      d(loadPrimaryAccountAction());
    }
  }, []);
  const textDatas = Object.values(
    primaryBillingPaymentAccount?.billing?.billingInfor || {}
  );
  const txt = textDatas.filter((s) => typeof s === "string").join("");
  if (
    primaryBillingPaymentAccount?.billing?.billingInfor?.id &&
    txt.length > 0 &&
    (primaryBillingPaymentAccount?.billing?.billingInfor || loading)
  ) {
    return null;
  }
  return (
    <CustomNotification
      className={className}
      customIcon={<WarningIcon fill={WARNING_500} />}
      title="Information required"
      desc={
        <>
          <p>
            To continue using Primary billing method, you need to update billing
            info about your business.
          </p>
          <Link
            to={
              "/billing/method/new?update=" +
              (primaryBillingPaymentAccount?.billing?.id || "")
            }
          >
            Update billing info
          </Link>
        </>
      }
    />
  );
});
