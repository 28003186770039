import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderRadioAnt, renderSelectAnt } from 'utils/Form';
import { connect } from 'react-redux';
import { GMSelect } from 'components/InputFields';
import { GMButton } from 'components/Button';
import { Collapse, Spin, Steps } from 'antd';
import getMomentInstance from 'utils/moment';
import { Wrapper } from './styled';
const { Panel } = Collapse;

// dispatcher
function ConfigBuyTracking({ pageName, handleSubmit, data }) {
  return (
    <Wrapper pageName={pageName}>
      <form onSubmit={handleSubmit}>
        <div className="block-right">
          <Field
            name="type"
            component={renderRadioAnt}
            defaultValue={0}
            options={[
              { label: 'Default', value: 0 },
              {
                label:
                  'Tracking number will be updated immediately after the payment time',
                value: 1,
              },
              {
                label:
                  'Tracking number will be updated once all items are printed',
                value: 2,
              },
              {
                label: (
                  <>
                    <div>
                      Tracking number will be updated within a specific number
                      of day:
                    </div>
                    <div
                      onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        return false;
                      }}
                      className="select-tracking-time"
                    >
                      <Field
                        name="time"
                        component={renderSelectAnt}
                        options={[
                          { label: '2 days', value: '2' },
                          {
                            label: '3 days',
                            value: '3',
                          },
                          {
                            label: '4 days',
                            value: '4',
                          },
                          {
                            label: '5 days',
                            value: '5',
                          },
                        ]}
                      />
                    </div>
                  </>
                ),
                value: '3',
              },
            ]}
          />
          <div className="update-btn">
            <GMButton className="btn btn-primary">Update</GMButton>
          </div>
        </div>
        {data?.logs?.length > 0 && (
          <div className="block-right">
            <div>
              <b>
                <h4>History</h4>
              </b>
            </div>
            <div>
              <Steps progressDot direction="vertical">
                {data?.logs?.map((item) => (
                  <Steps.Step
                    key={item.log_id}
                    title={getMomentInstance(item.log_time, 0, true)}
                    description={
                      <div>
                        <b>{item.cus_name}:</b>{' '}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: (item.log_name || '').replace(
                              /(<? *script)/gi,
                              'illegalscript'
                            ),
                          }}
                        ></span>
                      </div>
                    }
                  />
                ))}
              </Steps>
            </div>
          </div>
        )}
      </form>
    </Wrapper>
  );
}

ConfigBuyTracking.propTypes = {};

// eslint-disable-next-line no-func-assign
ConfigBuyTracking = reduxForm({
  form: 'formConfigBuyTracking',
  enableReinitialize: true,
})(ConfigBuyTracking);
// eslint-disable-next-line no-func-assign
ConfigBuyTracking = connect((state) => {
  return {};
})(ConfigBuyTracking);
export default ConfigBuyTracking;
// export default React.memo(ConfigBuyTracking);
