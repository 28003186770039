import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType } from "./actions";
import { replace } from "connected-react-router";
import { register } from "apis/auth";
import { API_TOKEN_KEY } from "web.config";
import { verifyAPI } from "apis/user";
import { AUTH } from "scenes/Auth/actions";
import { setCookie } from "utils/helper";
function* registerSaga(action) {
  try {
    const { data } = action;
    const response = yield call(register, data);
    if (response.code === "success") {
      setCookie(API_TOKEN_KEY, response?.data?.accessToken);
      const rs = yield call(verifyAPI);
      if (data?.registerType !== 'email') {
        if (rs.code === "success" && rs?.data?.customer) {
          const {
            data: { customer: profile, permission },
          } = rs;
          yield put({ type: AUTH.verify.success, profile, permission });
        } else {
          yield put({ type: AUTH.verify.error, error: rs });
        }
        yield all([
          put({ type: actionType.REGISTER.SUCCESS, ...response }),
          put(replace("/dashboard")),
        ]);
      } else {
        const yieldChain = [
          put({ type: actionType.REGISTER.SUCCESS, payload: { ...response, email: data?.email } }),
        ];
        if (response?.data?.isReactive === 1) {
          put(replace("/dashboard"));
        }
        yield all(yieldChain);
      }
    } else {
      yield put({ type: actionType.REGISTER.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: actionType.REGISTER.ERROR, error })]);
  }
}

function* watcher() {
  yield all([takeLatest(actionType.REGISTER.REQUEST, registerSaga)]);
}

export default watcher;
