import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Table, Popconfirm, Tooltip, Modal, Pagination, Dropdown } from 'antd';
import getMomentInstance from 'utils/moment';
import { getList } from './actions';
import { getListExproduct } from '../Create/actions';
import { destroy } from '../Delete/actions';
import { getOptionLabel, options_active } from 'utils/options';
import edit from 'images/edit.svg';
import deleteIcon from 'images/deleteIcon.svg';
import FormFilter from './components/FormFilter';
import RenderListActions from 'utils/Form/RenderListActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import LazyLoad from 'react-lazyload';
import { Wrapper } from './styled';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import Filter from 'components/Filter';
import { MoreActionStore } from 'scenes/Campaigns/scenes/List/styled';
import { ContextMenuWrapper } from 'scenes/BillingPaymentAccount/v2/styled';
import { GMButton } from 'components/Button';
import { ReactComponent as LinkIcon } from 'images/icons/plus.svg';

const { confirm } = Modal;
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      selectedRowKeys: [],
      initial_filter_values: {
        status: '-1',
        expId: '0',
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
    this.props.getListExproduct({ sortBy: 'type', sortType: 'asc' });
  }
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (parseInt(params['status']) === -1) delete params['status'];
    if (parseInt(params['expId']) === 0) delete params['expId'];
    if (params['with[]']) delete params['with[]'];
    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getList({
      ...params,
      'with[]': 'exProduct',
    });
  };
  deleteMockup = (ids) => {
    const { destroy } = this.props;
    confirm({
      title: 'Do you want to delete these mockups?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        destroy(ids.join(','));
      },
      onCancel() { },
    });
  };
  render() {
    const { mockups, pageName, destroyMockupLoading, exproducts } = this.props;
    const { initial_filter_values, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selectionActions: [
        {
          key: 'delete_mockup',
          text: 'Delete Mockup',
          onSelect: () => {
            this.deleteMockup(selectedRowKeys);
          },
        },
      ],
    };
    const columns = [
      {
        title: <b>ID</b>,
        align: 'center',
        dataIndex: 'id',
        key: 'id',
        width: 90,
        render: (value) => (
          <Link to={`/products/mockups/${value}/edit`} className="mr-1">
            #{value}
          </Link>
        ),
      },
      {
        title: (
          <b className="d-flex justify-content-center align-items-center">
            Mockup name
          </b>
        ),
        dataIndex: 'name',
        align: 'center',
        key: 'name',
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>Mockup file</b>,
        align: 'center',
        render: (record) => (
          <div className="d-flex justify-content-center">
            <LazyLoad>
              <img
                src={record.frontImgUrl}
                width="50"
                className="mr-1"
                alt="front"
              />
            </LazyLoad>
            {record.backImgUrl && (
              <LazyLoad>
                <img src={record.backImgUrl} width="50" alt="back" />
              </LazyLoad>
            )}
          </div>
        ),
      },
      {
        title: (
          <b className="d-flex justify-content-center align-items-center">
            Product style
          </b>
        ),
        dataIndex: 'exProduct',
        key: 'exProduct',
        render: (value) =>
          value && (
            <div>
              {value.name} - {value.brand}
            </div>
          ),
      },
      {
        title: <b>Status</b>,
        align: 'center',
        key: 'status',
        dataIndex: 'status',
        render: (value) => getOptionLabel(options_active, value),
      },
      {
        title: <b>Create time</b>,
        dataIndex: 'createdAt',
        align: 'center',
        key: 'createdAt',
        render: (value) => getMomentInstance(value, 0, true),
      },
      {
        align: 'center',
        render: (record) => (
          <MoreActionStore>
            <Dropdown
              placement="topLeft"
              overlay={
                <ContextMenuWrapper>
                  <Link to={`/products/mockups/${record.id}/edit`}>Edit</Link>

                  <Popconfirm
                    title="Are you sure to delete this record?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => this.props.destroy(record.id)}
                  >
                    Delete
                  </Popconfirm>
                </ContextMenuWrapper>
              }
              trigger={['click']}
            >
              <MoreAction className="context-menu" />
            </Dropdown>
          </MoreActionStore>
        ),
      },
    ];

    return (
      <Wrapper
        pageName={pageName}
        ctas={
          <>
            {this.state.selectedRowKeys.length > 0 ? (
              <RenderListActions
                title={'mockups'}
                selectedRowKeys={this.state.selectedRowKeys}
                rowSelection={rowSelection}
              />
            ) : null}
            <GMButton
              className="cta-upload"
              onClick={() => {
                this.props.history.push('/products/mockups/create');
              }}
            >
              <LinkIcon />
              Create new mockup
            </GMButton>
          </>
        }
        filter={
          <Filter
            placeholder="Search by store name"
            className="filter-form"
            onSubmit={(values) => {
              this.fetchData({
                keyword: (values.search || '').trim(),
              });
            }}
          />
        }
      >
        {/* <div className="col-md-10">
          <FormFilter
            initialValues={initial_filter_values}
            onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
            handleAdvancedSubmit={this.fetchData}
            exproducts={this.props.exproducts.data}
            history={this.props.history}
          />
        </div> */}
        <div>
          <div>
            <Table
              rowKey="id"
              sticky
              rowSelection={rowSelection}
              columns={columns}
              dataSource={mockups.data}
              loading={
                mockups.loading || destroyMockupLoading || exproducts.loading
              }
              scroll={{ x: 1000 }}
              pagination={
                !this.props.is_mobile
                  ? {
                    showTotal: (total) => `Total ${total} items`,
                    total: mockups.total,
                    pageSize: parseInt(mockups.params.perPage),
                    current: mockups.params.page,
                    showSizeChanger: true,
                    onShowSizeChange: (current, perPage) =>
                      this.fetchData({ ...mockups.params, perPage }),
                    onChange: (page, perPage) =>
                      this.fetchData({ ...mockups.params, page, perPage }),
                  }
                  : false
              }
            />
          </div>
          {this.props.is_mobile && (
            <div className="d-flex justify-content-end align-items-center py-3 px-3">
              <div>
                <Pagination
                  size="small"
                  showSizeChanger
                  className="pagination"
                  total={mockups.total}
                  defaultCurrent={mockups?.params?.page || 1}
                  pageSize={mockups?.params?.perPage || 0}
                  onChange={(page, perPage) =>
                    this.fetchData({
                      ...mockups.params,
                      page:
                        page === Number.NEGATIVE_INFINITY
                          ? 1
                          : page === 0
                            ? 1
                            : page,
                      perPage,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    mockups: state.mockup.list.mockups,
    destroyMockupLoading: state.mockup.destroy.loading,
    exproducts: state.mockup.create.exproducts,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
    destroy: (id) => {
      dispatch(destroy(id));
    },
    getListExproduct: (params) => {
      dispatch(getListExproduct(params));
    },
  })
)(List);
