import {
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  ORDER_UPDATE_ADDRESS_REQUEST,
  ORDER_UPDATE_ADDRESS_SUCCESS,
  ORDER_UPDATE_ADDRESS_FAIL,
  UPDATE_GIFT_MESSAGE_REQUEST,
  UPDATE_GIFT_MESSAGE_SUCCESS,
  UPDATE_GIFT_MESSAGE_FAIL,
  UPDATE_SHIPPING_SERVICE_REQUEST,
  UPDATE_SHIPPING_SERVICE_SUCCESS,
  UPDATE_SHIPPING_SERVICE_FAIL,
  DUPLICATE_ORDER_REQUEST,
  DUPLICATE_ORDER_SUCCESS,
  DUPLICATE_ORDER_FAIL,
  LOCK_ORDER_REQUEST,
  LOCK_ORDER_SUCCESS,
  LOCK_ORDER_FAIL,
  LIST_DUPLICATED_ORDER_REQUEST,
  LIST_DUPLICATED_ORDER_SUCCESS,
  LIST_DUPLICATED_ORDER_FAIL,
  MARK_AS_VALID_ADDRESS_REQUEST,
  MARK_AS_VALID_ADDRESS_SUCCESS,
  MARK_AS_VALID_ADDRESS_FAIL,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
} from './constants';
import { notification } from 'antd';

const initialState = {
  orderDetail: {
    data: {},
    loading: false,
    params: {},
  },
  updateAddress: {
    loading: false,
    checkAddress: {},
    orderNewUpdate: {},
  },
  updateGiftMessage: {
    loading: false,
  },
  updateShippingService: {
    loading: false,
  },
  duplicateOrder: {
    loading: false,
  },
  lockOrder: {
    loading: false,
  },
  listDuplicatedOrder: {
    loading: false,
    data: [],
  },
  markAsValidAddress: {
    loading: false,
    data: [],
  },
  cancelOrder: {
    loading: false,
  },
};

function DetailReducer(state = initialState, action) {
  switch (action.type) {
    //Order Detail
    case ORDER_DETAIL_REQUEST:
      return {
        ...state,
        orderDetail: {
          ...state.orderDetail,
          loading: true,
          params: action.options,
        },
      };
    case ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetail: {
          ...state.orderDetail,
          data: action.data.order,
          loading: false,
        },
      };
    case ORDER_DETAIL_FAIL:
      return {
        ...state,
        orderDetail: {
          ...state.orderDetail,
          loading: false,
        },
      };
    case ORDER_UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        updateAddress: {
          ...state.updateAddress,
          loading: true,
        },
      };
    case ORDER_UPDATE_ADDRESS_SUCCESS:
      notification.success({
        message: 'The address has been saved successfully.',
        duration: 5,
      });
      return {
        ...state,
        updateAddress: {
          ...state.updateAddress,
          loading: false,
          checkAddress: action?.data?.checkAddress,
          orderNewUpdate: action?.data?.orderNewUpdate,
        },
      };
    case ORDER_UPDATE_ADDRESS_FAIL:
      notification.warn({
        message: action.error[0]
          ? action.error[0]['msg'] || action.error[0]['message']
          : 'Update address failed.',
        duration: 5,
      });
      return {
        ...state,
        updateAddress: {
          ...state.updateAddress,
          loading: false,
        },
      };
    case UPDATE_GIFT_MESSAGE_REQUEST:
      return {
        ...state,
        updateGiftMessage: {
          ...state.updateGiftMessage,
          loading: true,
        },
      };
    case UPDATE_GIFT_MESSAGE_SUCCESS:
      notification.success({
        message: 'Update gift message successfully.',
        duration: 5,
      });
      return {
        ...state,
        updateGiftMessage: {
          ...state.updateGiftMessage,
          loading: false,
        },
      };
    case UPDATE_GIFT_MESSAGE_FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Update gift message failed.',
        duration: 5,
      });
      return {
        ...state,
        updateGiftMessage: {
          ...state.updateGiftMessage,
          loading: false,
        },
      };
    case UPDATE_SHIPPING_SERVICE_REQUEST:
      return {
        ...state,
        updateShippingService: {
          ...state.updateShippingService,
          loading: true,
        },
      };
    case UPDATE_SHIPPING_SERVICE_SUCCESS:
      notification.success({
        message: 'Update shipping service successfully.',
      });
      return {
        ...state,
        updateShippingService: {
          ...state.updateShippingService,
          loading: false,
        },
      };
    case UPDATE_SHIPPING_SERVICE_FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Update shipping service failed',
      });
      return {
        ...state,
        updateShippingService: {
          ...state.updateShippingService,
          loading: false,
        },
      };
    case DUPLICATE_ORDER_REQUEST:
      return {
        ...state,
        duplicateOrder: {
          ...state.duplicateOrder,
          loading: true,
        },
      };
    case DUPLICATE_ORDER_SUCCESS:
      notification.success({
        message: action?.data?.order?.name
          ? `Order successfully duplicated as ${action?.data?.order?.name}`
          : 'Duplicate order successfully.',
        duration: 5,
      });
      return {
        ...state,
        duplicateOrder: {
          ...state.duplicateOrder,
          loading: false,
        },
      };
    case DUPLICATE_ORDER_FAIL:
      notification.warn({
        message: action?.error?.message
          ? action.error?.message
          : 'Duplicate order failed.',
        duration: 5,
      });
      return {
        ...state,
        duplicateOrder: {
          ...state.duplicateOrder,
          loading: false,
        },
      };
    case LOCK_ORDER_REQUEST:
      return {
        ...state,
        lockOrder: {
          ...state.lockOrder,
          loading: true,
        },
      };
    case LOCK_ORDER_SUCCESS:
      notification.success({
        message: 'Lock order successfully.',
        duration: 5,
      });
      return {
        ...state,
        lockOrder: {
          ...state.lockOrder,
          loading: false,
        },
      };
    case LOCK_ORDER_FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Lock order failed.',
        duration: 5,
      });
      return {
        ...state,
        lockOrder: {
          ...state.lockOrder,
          loading: false,
        },
      };
    case LIST_DUPLICATED_ORDER_REQUEST:
      return {
        ...state,
        listDuplicatedOrder: {
          ...state.listDuplicatedOrder,
          loading: true,
        },
      };
    case LIST_DUPLICATED_ORDER_SUCCESS:
      return {
        ...state,
        listDuplicatedOrder: {
          ...state.listDuplicatedOrder,
          loading: false,
          data: action.data || [],
        },
      };
    case LIST_DUPLICATED_ORDER_FAIL:
      return {
        ...state,
        listDuplicatedOrder: {
          ...state.listDuplicatedOrder,
          loading: false,
        },
      };
    //Mark as valid address
    case MARK_AS_VALID_ADDRESS_REQUEST:
      return {
        ...state,
        markAsValidAddress: {
          ...state.markAsValidAddress,
          loading: true,
        },
      };
    case MARK_AS_VALID_ADDRESS_SUCCESS:
      notification.success({
        message: action?.data_success
          ? action?.data_success?.msg
          : 'Request successful',
        duration: 5,
      });
      return {
        ...state,
        markAsValidAddress: {
          ...state.markAsValidAddress,
          loading: false,
          data: action.data || [],
        },
      };
    case MARK_AS_VALID_ADDRESS_FAIL:
      notification.warn({
        message: action?.error
          ? action?.error?.[0].clientMsg
          : 'Address verified',
        duration: 5,
      });
      return {
        ...state,
        markAsValidAddress: {
          ...state.markAsValidAddress,
          loading: false,
        },
      };
    //Cancel order
    case CANCEL_ORDER_REQUEST:
      return {
        ...state,
        cancelOrder: {
          ...state.cancelOrder,
          loading: true,
        },
      };
    case CANCEL_ORDER_SUCCESS:
      notification.success({
        message: action?.clientMsg,
        duration: 5,
      });
      return {
        ...state,
        cancelOrder: {
          ...state.cancelOrder,
          loading: false,
        },
      };
    case CANCEL_ORDER_FAIL:
      notification.warn({
        message: action?.error?.[0]?.clientMsg,
        duration: 5,
      });
      return {
        ...state,
        cancelOrder: {
          ...state.cancelOrder,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default DetailReducer;
