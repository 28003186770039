import React from "react";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ACCOUNT_GEARMENT_DOMAIN } from "utils/options";
function RenderCopyWordpressWebhook({ store = null, ...props }) {
  return (
    <>
      <Tooltip title="Copy webhook" placement="bottom">
        <CopyToClipboard
          text={`${ACCOUNT_GEARMENT_DOMAIN}api/webhook-wordpress/${store?.storeToken}`}
          onCopy={() => {
            notification.success({
              message: "Copy link webhook endpoint successfully!",
            });
          }}
        >
          <p className="cursor-pointer mt-1">
            <FontAwesomeIcon icon={faLink} /> Webhook
          </p>
        </CopyToClipboard>
      </Tooltip>
    </>
  );
}

export default React.memo(RenderCopyWordpressWebhook);