import { BASE_WHITE, GRAY_400, GRAY_500, GRAY_600, PRIMARY_600, PRIMARY_700 } from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${BASE_WHITE};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  padding-left: 100px;
  .error {
    font-size: 30px;
    font-weight: 500;
    color: ${PRIMARY_600};
  }
  .header {
    font-size: 57px;
    font-weight: 800;
  }
  .desc {
    font-size: 30px;
    color:${GRAY_600};
    padding-top: 20px;
    padding-bottom: 60px;
    font-weight: 400;
  }
`;
