import { DATA_FEED_AMAZON_EDIT_REQUEST, DATA_FEED_AMAZON_DETAIL_REQUEST } from './constants'

export function updateDataFeedAmazon(id, data_feed) {
    return {
        type: DATA_FEED_AMAZON_EDIT_REQUEST,
        id, data_feed
    }
}
export function getDetailAmazon(id) {
    return {
        type: DATA_FEED_AMAZON_DETAIL_REQUEST,
        id
    }
}