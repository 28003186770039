import {
  BASE_WHITE,
  GRAY_200,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_500,
  SHADOW_SM_0_06,
  SHADOW_SM_0_1,
} from 'components/colors';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    .ant-modal-content {
      background: ${BASE_WHITE};
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
    }
    .ant-modal-header{
      border-radius: 16px;
      border-bottom:none;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  
  `;
export const Wrapper = styled.div`
  padding: 32px;

  h2.header {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: ${GRAY_900};
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .more-action {
    svg {
      width: 20px;
      height: 20px;
      stroke: ${GRAY_600};
      margin-right: 10px;
    }
  }
  .connect-icon {
    svg {
      width: 20px;
      height: 20px;
      stroke: ${BASE_WHITE};
      margin-right: 10px;
    }
  }
`;

export const WrapperIcon = styled.div`
  svg {
    width: 20px;
    height: 20px;
    stroke: ${GRAY_600};
    margin-right: 10px;
  }
`;
export const WrapperIconTooltip = styled.div`
  svg {
    width: 15px;
    height: 15px;
    stroke: ${GRAY_600};
    margin-right: 10px;
  }
  color: ${GRAY_600};
`;

export const MoreActionStore = styled.div`
  .pointer {
    cursor: pointer;
  }
`;
export const WrapperAction = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  color: black;
  cursor: pointer;
  .delete {
    color: red;
  }
`;
