import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import { verify, getSystemSetting } from './scenes/Auth/actions';
import privateRoutes from 'route/privateRoutes';
import Layout from './ui';
import { checkRoutePermission, removeCookie } from 'utils/helper';
import tokenWatcher from 'utils/loginWatcher';
import { API_TOKEN_KEY } from 'web.config';
let isCookieWatched = false;
class App extends Component {
  componentWillMount() {
    this.props.verify();
    this.props.getSystemSetting();
    if (!isCookieWatched) {
      tokenWatcher();
      isCookieWatched = true;
    }
  }
  render() {
    const {
      auth: { profile, logged, verified },
    } = this.props;
    if (!verified) {
      return (
        <div
          className="d-flex w-100 justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <Spin size="large" />
        </div>
      );
    }
    return (
      <div>
        <ConnectedRouter history={this.props.history}>
          {/* <Layout {...this.props}> */}
          <Switch>
            {privateRoutes.map(({ children, ...rest }) => {
              let components = children ? [rest, ...children] : [rest];
              return components.map(
                ({
                  component: Content,
                  key,
                  name,
                  heading,
                  permissions,
                  path,
                  template,
                  isPublic = false,
                  exact = true,
                }) => {
                  let isPermitted = checkRoutePermission(
                    profile,
                    key,
                    permissions,
                    logged,
                    isPublic
                  );
                  // console.log(privateRoutes,"privateRoutes")
                  // console.log(isPermitted,"isPermitted")

                  if (!logged) {
                    removeCookie(API_TOKEN_KEY);
                  }
                  return (
                    <Route
                      exact={exact}
                      path={path}
                      key={key}
                      render={
                        (route) => {
                          if (isPermitted) {
                            if (isPermitted === 5) {
                              return (
                                <Content
                                  {...route}
                                  logged={logged}
                                  pageName={heading || name || ''}
                                  user={profile}
                                />
                              );
                            }
                            return (
                              <Layout template={template}>
                                <Content
                                  {...route}
                                  pageName={heading || name || ''}
                                  user={profile}
                                  logged={logged}
                                />
                              </Layout>
                            );
                          }
                          return logged ? (
                            <Redirect to="/errors/403" />
                          ) : (
                            <Redirect to="/auth/login" />
                          );
                        }
                        // isPermitted  ? (
                        //   <Layout template={template}>
                        //     <Content
                        //       {...route}
                        //       pageName={heading || name || ''}
                        //       user={profile}
                        //     />
                        //   </Layout>
                        // ) : logged ? (
                        //   <Redirect to="/errors/403" />
                        // ) : (
                        //   <Redirect to="/auth/login" />
                        // )
                      }
                    />
                  );
                }
              );
            })}
            <Redirect from="*" to="/errors/404" />
          </Switch>
          {/* </Layout> */}
        </ConnectedRouter>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.auth.info,
    systemSetting: state.auth.info.setting,
  }),
  (dispatch) => ({
    verify: () => {
      dispatch(verify());
    },
    getSystemSetting: () => {
      dispatch(getSystemSetting());
    },
  })
)(App);
