import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Button } from 'antd';
import { create, getListExproduct } from './actions';
import Form from './components/form';
import BackToIcon from 'images/chevron-down.svg';
import { Wrapper } from './styled';

class Create extends Component {
  componentDidMount() {
    this.props.getListExproduct({ action: 'mockup' });
  }

  handleSubmit = ({ frontImg, backImg, status, ...values }) => {
    this.props.create({
      ...values,
      frontImg: frontImg,
      backImg: backImg,
      status: status ? 1 : 0,
    });
  };
  render() {
    const {
      creating,
      exproducts,
      profile,
      pageName,
      exproductsLoading,
    } = this.props;
    return (
      <Wrapper pageName={pageName}>
        <Spin spinning={creating || exproductsLoading}>
          <div>
            <Form
              onSubmit={this.handleSubmit}
              exproducts={exproducts}
              profile={profile}
              initialValues={{
                name: '',
                frontImg: '',
                backImg: '',
                boxFrontTop: 0,
                boxFrontLeft: 0,
                boxBackTop: 0,
                boxBackLeft: 0,
                expId: '0',
                status: true,
              }}
            />
          </div>
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    exproducts: state.mockup.create.exproducts,
    exproductsLoading: state.mockup.create.exproducts.loading,
    profile: state.auth.info.profile,
    creating: state.mockup.create.creating,
  }),
  (dispatch) => ({
    create: (params) => {
      dispatch(create(params));
    },
    getListExproduct: (params) => {
      dispatch(getListExproduct(params));
    },
  })
)(Create);
