import { isValidEmail } from "utils/helper";
const validate = (values) => {
    const errors = {};
    if(!values.feedFormId || values.feedFormId === 0){
        errors.feedFormId = 'Please choose a feed form';
    }
    if(values.email && !isValidEmail(values.email)){
        errors.email = "Please enter a valid email.";
    }
    if (!values.campaigns || values.campaigns.length === 0) {
        errors.campaigns = 'Please choose product to export';
    } 
    return errors;
};

export default validate;