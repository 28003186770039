/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown, Menu } from "antd";
import { GMButton } from "components/Button";
import { ReactComponent as DownIcon } from 'images/icons/chevron-down.svg';
import { ContextMenuWrapper } from "scenes/Orders/scenes/List/styled";

function RenderListActions({ selectedRowKeys, ignoreItemCheck = false, rowSelection = [], actions = [], title = [], classNameContainer = '' }) {
  if (!ignoreItemCheck && selectedRowKeys?.length <= 0) {
    return null;
  }
  return (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          {actions?.map((actionItem, actionIndex) => (
            <Menu.Item key={'actions_' + actionIndex} >
              <div
                key={actionIndex + '_actions'}
                href=""
                onClick={evt => {
                  evt.preventDefault()
                  actionItem.onSelect();
                  return false;
                }}
              >
                {actionItem.text}
              </div>
            </Menu.Item>
          ))}
          {rowSelection?.selectionActions?.map((selectionItem, selectionIndex) => (
            <Menu.Item key={'selectionActions_' + selectionIndex}>
              <div
                key={'_rowSelection' + selectionIndex}
                href=""
                onClick={evt => {
                  evt.preventDefault()
                  selectionItem.onSelect();
                  return false;
                }}
              >
                {selectionItem.text}
              </div>
            </Menu.Item>
          ))}
        </Menu>
      )}
    >
      <GMButton
        type="button"
        color="SecondaryGray"
      >
        More actions<DownIcon />
      </GMButton>
    </Dropdown >
  );
};

export default React.memo(RenderListActions);