import React from "react";
import homeIcon from "images/home-icon.svg";
import emailIcon from "images/email-icon.svg";
import logo from "images/logo_new_login.svg";
import PaymentLogo from "images/payment-logos.png";
import { ReactComponent as IconEmail } from 'images/icons/mail-01.svg';
import { FooterWrapper } from "./footer.styled";
function Footer(props) {
  return (
    <>
      <FooterWrapper>
        <div className="wrapper">
          <div className="header">
            <div className="info">
              <img className="img-fluid footer-logo" src={logo} alt="logo" />
              <p>One-stop Print on Demand solution</p>
              <p className="mail">
                <IconEmail />
                <a href="mailto:info@gearment.com">info@gearment.com</a>
              </p>
            </div>
            <ul className="menu">
              <li className="list-inline-item px-1">
                <a
                  href="https://gearment.com/become-a-partner/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Services
                </a>
              </li>
              <li className="list-inline-item px-1">
                <a
                  href="https://gearment.com/integrations/amazon/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Integrations
                </a>
              </li>
              <li className="list-inline-item px-1">
                <a
                  href="https://help.gearment.com/en/category/shipping-information-3hhv5/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Shipping
                </a>
              </li>
              <li className="list-inline-item px-1">
                <a
                  href="https://gearment.com/policies/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Policies
                </a>
              </li>
              <li className="list-inline-item px-1">
                <a
                  href="https://gearment.com/contact-us/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Feature requests
                </a>
              </li>
              <li className="list-inline-item px-1">
                <a
                  href="https://gearment.com/blog/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </li>
              <li className="list-inline-item px-1">
                <a
                  href="https://help.gearment.com/en/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </a>
              </li>
            </ul>
          </div>
          <div className="addr">
            <p>
              <b>Headquarters</b>
              14801 Able Ln, Ste 102 Huntington Beach, CA 92647, United States
            </p>
            <p>
              <b>Branch</b>
              10th Floor, TNR Tower, 192 Nguyen Cong Tru Street, Nguyen Thai Binh Ward, District 1, Ho Chi Minh City, Vietnam
            </p>
            <p>
              <b>Warehouse</b>
              10811 Vine crest Dr, #100, Houston, TX 77086, United States
            </p>
          </div>
        </div>
        <div className="footer">
          <span className="copyright">© 2023 Gearment Inc. All rights reserved.</span>
          <div className="ref-links">
            <a className="facebook" href="https://www.facebook.com/GearmentFulfillment" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a className="linkedin" href="https://www.linkedin.com/company/70796872/admin/" target="_blank" rel="noopener noreferrer">Linkedin</a>
          </div>
        </div>
      </FooterWrapper>
    </>
  );
};

export default React.memo(Footer);
