import PageContainer from 'components/PageContainer';
import styled from 'styled-components';

export const Wrapper = styled.div`
  /* .ant-collapse-header {
    border-radius: 16px;
    background-color: lightblue;
  } */
  .ant-collapse.ant-collapse-icon-position-start.clol {
    border-radius: 16px;
    border:none;
  }
  .ant-collapse.ant-collapse-icon-position-left {
    border: transparent;
    border-radius: 16px;
  }
  .clol {
    .ant-collapse {
      &-item {
        overflow: hidden;
        border-bottom: none;
        &-active {
          border-bottom: 1px solid #d9d9d9;
        }
      }
    }
    .cols-2 {
      .ant-collapse {
        background: red;
      }
    }
  }
  .ant-collapse-item.ant-collapse-item-active {
    border-bottom: transparent;
    border-radius: 16px;
  }

  .ant-collapse-content-box {
    border-bottom: transparent;
  }
`;
