import { actionType as TYPE } from "./actions";

const initialState = {
  detail: {
    loading: false,
    data: {},
  },
};

function NotificationDetailReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.DETAIL.REQUEST:
      return {
        ...state,
        detail: { ...initialState.detail, loading: true },
      };
    case TYPE.DETAIL.SUCCESS:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          data: action.data.notification,
          loading: false,
        },
      };
    case TYPE.DETAIL.ERROR:
      return {
        ...state,
        detail: { ...initialState.detail, loading: false },
      };
    default:
      return state;
  }
}

export default NotificationDetailReducer;
