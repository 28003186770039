export const actionType = {
  EXPORT_FEED_CUSTOM_AMAZON: {
    REQUEST: "EXPORT_FEED_CUSTOM_AMAZON.REQUEST",
    SUCCESS: "EXPORT_FEED_CUSTOM_AMAZON.SUCCESS",
    FAIL: "EXPORT_FEED_CUSTOM_AMAZON.FAIL"
  }
};

export const exportFeedCustomAmazon = (data) => {
  return {
    type: actionType.EXPORT_FEED_CUSTOM_AMAZON.REQUEST,
    data
  }
}


