import { actionType as TYPE } from "./actions";
import { notification } from "antd";
const initialState = {
  loading: false,
  requestData: {},
  previewData: {},
};

function PostCreateReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.PREVIEW.REQUEST:
      return {
        ...state,
        loading: true,
        requestData: action.dataToPreviewCampaign,
      };
    case TYPE.PREVIEW.SUCCESS:
      return {
        ...state,
        loading: false,
        previewData: action.data,
      };
    case TYPE.PREVIEW.ERROR:
      notification.warn({
        message: action?.error?.[0]?.["msg"] || "Preview product failed",
      });
      return {
        ...state,
        loading: false,
      };
    case TYPE.CREATE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.CREATE.SUCCESS:
      notification.success({
        message: "Create product successfully.",
      });
      return {
        ...state,
        loading: false,
      };
    case TYPE.CREATE.ERROR:
      notification.warn({
        message: action?.error?.[0]?.["msg"] || "Create product failed",
      });
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default PostCreateReducer;
