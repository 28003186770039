import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';
import {
  createIndemnication,
  viewIndem,
  getTemplate,
} from 'apis/indemnification';
import { replace } from 'connected-react-router';

function* createFormndemnificationSaga(action) {
  const { data } = action;
  try {
    const response = yield call(createIndemnication, data);
    localStorage.removeItem('formData');
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CREATE_INDEMNICATION.SUCCESS, ...response }),
        put({
          type: TYPE.VIEW_INDEMNICATION.REQUEST,
        }),
        // put(replace('/billing/indemnification')),
      ]);
    } else {
      yield put({ type: TYPE.CREATE_INDEMNICATION.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.CREATE_INDEMNICATION.ERROR, error })]);
  }
}
function* viewIndemnificationSaga(action) {
  try {
    const response = yield call(viewIndem);
    if (response.code === 'success') {
      yield put({ type: TYPE.VIEW_INDEMNICATION.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.VIEW_INDEMNICATION.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.VIEW_INDEMNICATION.ERROR, error })]);
  }
}

function* getTemplateSaga(action) {
  try {
    const response = yield call(getTemplate);
    if (response.code === 'success') {
      yield put({ type: TYPE.VIEW_TEMPLATE.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.VIEW_TEMPLATE.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.VIEW_TEMPLATE.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(
    TYPE.CREATE_INDEMNICATION.REQUEST,
    createFormndemnificationSaga
  );
  yield takeLatest(TYPE.VIEW_INDEMNICATION.REQUEST, viewIndemnificationSaga);
  yield takeLatest(TYPE.VIEW_TEMPLATE.REQUEST, getTemplateSaga);
}

export default watcher;
