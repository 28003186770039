import React, { Component } from "react";
import { connect } from "react-redux";
import FormResetPassword from "./components/FormResetPassword";
import { update } from "./actions";
import { Spin, notification } from "antd";
import { Wrapper } from "./styled";
export class index extends Component {
  handleResetPassword = (values) => {
    this.props.update(values);
  };
  render() {
    const { updating } = this.props;
    return (
      <Wrapper className="profile-section bg-white p-3 m-3 mx-lg-auto">
        <Spin spinning={updating}>
          <FormResetPassword onSubmit={this.handleResetPassword} />
        </Spin>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  updating: state.user.reset_password.updating,
});

const mapDispatchToProps = (dispatch) => ({
  update: (params) => {
    dispatch(update(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
