import React, { Component } from 'react';
import Navbar from './components/Navbar';
import menu from 'images/menu.svg';
import { ACCOUNT_GEARMENT_DOMAIN } from 'utils/options';
import { WraperHeader } from './styled';
import { GMButton } from 'components/Button';
import { ReactComponent as SyncIcon } from 'images/icons/iconHead.svg';
import { gtmtrack, removeCookie } from 'utils/helper';
import { SHOPIFY_INTEGRATE } from 'web.config';
import { connect } from 'react-redux';

class MainHeader extends Component {
  render() {
    const { toggleSidebar, is_mobile, profile } = this.props;
    return (
      <WraperHeader className="row align-items-center">
        <div className="col-4 col-md-4">
          {is_mobile && (
            <span className="top-menu-toggler d-block" onClick={toggleSidebar}>
              <img src={menu} alt="menu icon" />
            </span>
          )}
          {!is_mobile && (
            <div className="d-flex ml-4">
              {/* <div className="mr-2">
                <Tooltip
                  title="This is beta version of Gearment Account"
                  placement="bottom"
                >
                  <GMButton color={'SecondaryGray'} size="sm">Beta version</GMButton>
                </Tooltip>
              </div> */}
              <div
                className="cursor-pointer"
                onClick={() => {
                  gtmtrack({ event: 'switch_gearment_account', path: window?.pathname });
                  window.open(ACCOUNT_GEARMENT_DOMAIN, '_blank').focus();
                  removeCookie(SHOPIFY_INTEGRATE);
                }}
              >
                <GMButton
                  size="md"
                  color={'SecondaryGray'}
                  className="d-flex align-items-center switchbtn"
                >
                  <SyncIcon
                    className="sync text-center mr-2"
                    style={{ stroke: 'none', width: '15px', height: '15px' }}
                  />
                  Switch to Classic Version
                </GMButton>
              </div>
            </div>
          )}
        </div>
        <div className="col-8 col-md-8">
          <Navbar is_mobile={is_mobile} />
        </div>
      </WraperHeader>
    );
  }
}
export default React.memo(connect(
  (state) => ({
    profile: state?.auth?.info?.profile,
  })
)(MainHeader));
