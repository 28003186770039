export const actionType = {
    CREATE: {
        REQUEST: 'COLLECTION.CREATE.CREATE.REQUEST',
        SUCCESS: 'COLLECTION.CREATE.CREATE.SUCCESS',
        ERROR: 'COLLECTION.CREATE.CREATE.ERROR',
    },
};


export function create(params) {
    return {
        type: actionType.CREATE.REQUEST,
        params
    }
}