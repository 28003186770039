import axios from 'utils/request';

const prefix = '/ex-products';

export const getList = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}?sortBy=id&sortType=asc`,
    is_json_content_type: true,

  });
};

export const getListDesigns = (params) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/designs?&with[]=optionColors&sortBy=id&sortType=asc`,
  });
};

export const listProductCategory = (params) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/exproduct-category`,

  });
};

export const getDetail = (id, params) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/${id}`,
  });
};

export const getSizes = (expId, colorId) => {
  return axios({
    method: 'GET',
    url: `${prefix}/sizes?expId=${expId}&colorId=${colorId}`,
  });
};

export const getColors = (expId, params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/${expId}/colors`,
  });
};
export const profitAble = ({ salePrice, baseCost }) => {
  return axios({
    method: 'GET',
    url: `${prefix}/profit-able?salePrice=${salePrice}&baseCost=${baseCost}`,
  });
};
