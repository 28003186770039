import { takeLatest, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import {
  PRODUCT_UPDATE_PRICE_REQUEST,
  PRODUCT_UPDATE_PRICE_SUCCESS,
  PRODUCT_UPDATE_PRICE_FAIL,
  CAMPAIGN_DETAIL_REQUEST,
  CAMPAIGN_DETAIL_SUCCESS,
  CAMPAIGN_DETAIL_FAIL,
  CAMPAIGN_UPDATE_INFO_REQUEST,
  CAMPAIGN_UPDATE_INFO_SUCCESS,
  CAMPAIGN_UPDATE_INFO_FAIL,
} from "./actions";

import { actionType } from "../List/actions";
import {
  detailCampaign,
  updateProductPrice,
  updateCampaignInfo,
} from "apis/campaign";
function* detailCampaignSaga(action) {
  try {
    const { id } = action;
    const response = yield call(detailCampaign, id);
    if (response.code === "success" && response.data.campaign) {
      yield put({ type: CAMPAIGN_DETAIL_SUCCESS, ...response });
    } else {
      yield all([
        put({
          type: CAMPAIGN_DETAIL_FAIL,
          error: { message: "Not found product" },
        }),
        put(replace(`/products/list`)),
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: CAMPAIGN_DETAIL_FAIL, error }),
      put(replace("/products/list")),
    ]);
  }
}

function* updateProductPriceSaga(action) {
  try {
    const { dataToUpdate, id } = action;
    const response = yield call(updateProductPrice, dataToUpdate, id);
    if (response.code === "success") {
      yield put({ type: PRODUCT_UPDATE_PRICE_SUCCESS, ...response });
      yield put(replace(`/products/list`));
    } else {
      yield all([put({ type: PRODUCT_UPDATE_PRICE_FAIL, error: response })]);
    }
  } catch (error) {
    yield all([put({ type: PRODUCT_UPDATE_PRICE_FAIL, error })]);
  }
}

function* updateCampaignInfoSaga(action) {
  try {
    const { dataToUpdate, id } = action;
    const response = yield call(updateCampaignInfo, dataToUpdate, id);
    if (response.code === "success") {
      yield all([
        put({ type: CAMPAIGN_UPDATE_INFO_SUCCESS, ...response }),
        put(replace(`/products/list`))
      ]);
    } else {
      yield all([put({ type: CAMPAIGN_UPDATE_INFO_FAIL, error: response })]);
    }
  } catch (error) {
    yield all([put({ type: CAMPAIGN_UPDATE_INFO_FAIL, error })]);
  }
}

function* editCampaignWatcher() {
  yield all([
    takeLatest(CAMPAIGN_DETAIL_REQUEST, detailCampaignSaga),
    takeLatest(PRODUCT_UPDATE_PRICE_REQUEST, updateProductPriceSaga),
    takeLatest(CAMPAIGN_UPDATE_INFO_REQUEST, updateCampaignInfoSaga),
  ]);
}

export default editCampaignWatcher;
