import {
  BASE_WHITE,
  GRAY_200,
  GRAY_500,
  GRAY_600,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_50,
  PRIMARY_500,
  PRIMARY_700,
} from 'components/colors';
import { BREAKPOINTS } from 'components/const';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const WrapperPickProduct = createGlobalStyle`
  .modal-catalog.ant-modal {
    padding: 0;
    top: 0;
    max-width: 100%;
    .ant-tag {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border: none;
      /* padding: 8px 12px; */

      /* Gray/500 */
      color: ${GRAY_500};
      border-radius: 16px;

      &.defaultSelect {
        background: ${BASE_WHITE};
      }
      &.catagorySelected {
        background: ${PRIMARY_50};
        color: ${PRIMARY_500};
        border: 1px solid ${PRIMARY_500};
        padding: 2px 10px;
        border-radius: 16px;
      }
    }

    .ant-modal-header {
        border-bottom: none;
    }

    .ant-input-affix-wrapper {
      border-radius: 8px;
      height: 44px;
    }
    .ant-modal-body {
      padding-top: 0;
    }
    .ant-modal,
    .ant-modal-content {
      top: 0;
      bottom: 0;
    }
    .ant-modal {
      &.modal-catalog {
        max-width: 100%;
      }
    }
    .ant-modal-content{
      height: 100vh;
      overflow: scroll;
    }
    .block-catagory {
      width: 100vw;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0;
    }
  }
`;

export const Img = styled.div`
  height: 324.19px;
  background-image: url("${p => p.img}");
  width: 100%;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

export const Wrapper = styled.div``;

export const CardProduct = styled.div`
  .main-card {
    margin: 0;
    width: 100%;
    > div {
      width: 100%;
      @media (min-width: ${BREAKPOINTS.md}) {
        margin-right: 16px;
        width: calc((100% - 16px * 1) / 2);
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      /* @media (min-width: ${BREAKPOINTS[1024]}) {
        margin-right: 16px;
        width: calc((100% - 16px * 3) / 4);
        &:nth-child(2n) {
          margin-right: 16px;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
        
      } */

      @media (min-width: ${BREAKPOINTS[1024]}) {
        margin-right: 16px;
        width: calc((100% - 16px * 2) / 3);
        &:nth-child(2n) {
          margin-right: 16px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      @media (min-width: ${BREAKPOINTS.xxl}) {
        margin-right: 16px;
        width: calc((100% - 16px * 4) / 5);
        &:nth-child(2n) {
          margin-right: 16px;
        }
        &:nth-child(3n) {
          margin-right: 16px;
        }
        &:nth-child(4n) {
          margin-right: 16px;
        }
        &:nth-child(5n) {
          margin-right: 0;
        }
      }
    }
  }
  .card-product {
    margin: 0;
    margin-bottom: 32px;
    padding: 16px;
    max-width: 100%;
    max-height: 100%;
    /* Base/White */

    background: ${BASE_WHITE};
    /* Gray/200 */

    border: 1px solid ${GRAY_200};
    border-radius: 16px;
    .bottom-card {
      padding: 16px;
    }
  }
  .color-price {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    padding-top: 15px;
    padding-bottom: 15px;
    /* identical to box height, or 27px */

    letter-spacing: -0.02em;

    /* Primary/500 */

    color: ${PRIMARY_500};
  }
  .style-size {
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    /* Gray/600 */

    color: ${GRAY_600};
  }
  .title-product {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    height: 94px;
    max-height: 100%;
    /* or 156% */

    /* Gray/900 */

    color: ${GRAY_900};
  }
  .footer {
    position: fixed;
    bottom: 0;
    z-index: 100;
    background-color: ${BASE_WHITE};
    width: 100%;
    border: 1px solid ${BASE_WHITE};
    padding: 24px;
    right: 24px;
  }
`;
