import axios from "utils/request";

const prefix = "/auth";

export const login = (data) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}/login`,
    is_json_content_type: true,
  });
};

export const logout = () => {
  return axios({
    method: "GET",
    url: `${prefix}/logout`,
  });
};

export const register = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/accounts/register`,
    is_json_content_type: true,
  });
};

export const forgetPassword = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/accounts/forget-password`,
    is_json_content_type: true,
  });
};

export const resetPassword = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/accounts/reset-password`,
    is_json_content_type: true,
  });
};

export const verifyTokenResetPassword = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/accounts/verify-reset-password-token`,
    is_json_content_type: true,
  });
};

export const verifyEmail = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/accounts/resend-verification-email`,
    is_json_content_type: true
  })
}

export const verifyTokenEmail = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/accounts/verification-email`,
    is_json_content_type: true
  })
}

export const createAccountStaff = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/staff/store`,
    is_json_content_type: true,
  });
};

export const verifyStaffToken = (data) => {
  return axios({
    method: "POST",
    data,
    url: `/staff/verification-email`,
    is_json_content_type: true,
  });
};

