import React, { useState, useEffect } from "react";
import { List, Comment, Spin, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import getMomentInstance from "utils/moment";
import CustomerSupportIcon from "images/customer-support.png";
import CustomerIcon from "images/customer.png";
import { listMessages } from "apis/order-issues";
function ListMessages({ newMessages = false, issueId = 0 }) {
  const [pagination, setPagination] = useState({
    total: 0,
    perPage: 10,
    currentPage: 1,
  });
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    if (newMessages) {
      setMessages([...messages, ...[newMessages]]);
      setPagination({
        ...pagination,
        total: pagination.total + 1,
      });
    }
  }, [newMessages]);
  useEffect(() => {
    fetchMessages();
  }, []);
  const fetchMessages = (isMore = false) => {
    listMessages(issueId, {
      page: isMore ? (pagination?.currentPage || 1) + 1 : 1,
      perPage: 10,
    })
      .then(({ code, data }) => {
        if (code === "success") {
          setMessages(
            [...messages, ...(data?.messages || [])].sort((a, b) => {
              return a.createdAt - b.createdAt;
            })
          );
          setPagination(data.pagination);
        }
      })
      .catch((error) => {});
  };
  return (
    <Spin spinning={false}>
      <div
        id="scrollableDiv"
        style={{
          height:
            pagination?.total > 10 ? 500 : pagination?.total > 5 ? 300 : 120,
          overflow: "auto",
          padding: "0 16px",
          border: "1px solid rgba(140, 140, 140, 0.35)",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={() => {
            fetchMessages(true);
          }}
          hasMore={
            pagination?.total > 0 && pagination.total !== messages.length
          }
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={null}
          scrollableTarget="scrollableDiv"
          style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
          inverse={true}
        >
          <List
            dataSource={messages.map((message) => {
              return {
                author: message?.userGroup === 1 ? "Me" : "Customer Support",
                avatar:
                  message?.userGroup === 1 ? (
                    <img
                      src={CustomerIcon}
                      className="img-fluid"
                      alt="customer"
                    />
                  ) : (
                    <img
                      src={CustomerSupportIcon}
                      className="img-fluid"
                      alt="customer-support"
                    />
                  ),
                content: (
                  <p
                    className="my-0"
                    dangerouslySetInnerHTML={{
                      __html: (message?.content || "").replace(
                        /(<? *script)/gi,
                        "illegalscript"
                      ),
                    }}
                  ></p>
                ),
                datetime: getMomentInstance(null, message.createdAt, true),
              };
            })}
            header={
              pagination?.total > 0 &&
              `${pagination?.total} ${
                pagination?.total > 1 ? "replies" : "reply"
              }`
            }
            itemLayout="horizontal"
            renderItem={(props) => <Comment {...props} />}
          />
        </InfiniteScroll>
      </div>
    </Spin>
  );
}

export default React.memo(ListMessages);
