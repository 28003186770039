import React, { Component } from 'react';
import { Tree, Checkbox, Card, Button } from 'antd';

const { TreeNode } = Tree;

class renderTreeCheckbox extends Component {
    state = {
        checkedKeys: ['0-0-0'],
        selectedKeys: [],
    }

    renderTreeNodes = data => {
        const { propTitle, propKey, input } = this.props
        return data.map((item) => {
            if (item.children.length > 0) {
                return (
                    <TreeNode 
                        selectable={false} 
                        title={<div>
                            <Checkbox 
                                onChange={(e)=>this.onChangeCheckbox(e, item[propKey])} 
                                checked={input.value.selected.indexOf(item[propKey]) > -1 }
                            >
                            {item[propTitle]}
                            </Checkbox>
                            { input.value.selected.indexOf(item[propKey]) > -1 && (input.value.primary == item[propKey] ? 
                                <b>Primary</b> 
                                :
                                <Button size="small" onClick={()=>input.onChange({ ...(input.value), primary: item[propKey]})}>Make primary</Button>)
                            }
                        </div>} 
                        key={item[propKey]} 
                        dataRef={item}
                    >
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode 
                selectable={false} 
                title={<div>
                    <Checkbox 
                        onChange={(e)=>this.onChangeCheckbox(e, item[propKey])} 
                        checked={input.value.selected.indexOf(item[propKey]) > -1 }
                    >
                    {item[propTitle]}
                    </Checkbox>
                    { input.value.selected.indexOf(item[propKey]) > -1 && (input.value.primary == item[propKey] ? 
                        <b>Primary</b> 
                        :
                        <Button size="small" onClick={()=>input.onChange({ ...(input.value), primary: item[propKey]})}>Make primary</Button>)
                    }
                </div>} 
                key={item[propKey]}/>;
        })
    }
    onChangeCheckbox = ( e, key ) =>{
        const { input } = this.props
        if(e.target.checked){
            input.onChange({
                ...(input.value), 
                selected: [...(input.value.selected.filter(item=>item != key)), key]
            })
        }else{
            input.onChange({
                ...(input.value), 
                selected: input.value.selected.filter(item=>item != key)
            })
        }
    }
    
  render() {
    const {
    input,
      options,
      placeholder,
      meta: { asyncValidating, error },
    } = this.props
    if(!input.value){
        return <span>Vui lòng đợi</span>
    }
    return (
      <div className={`mb-2 ${asyncValidating ? 'async-validating' : ''}`}>
        <Card size="small" title={placeholder} bodyStyle={{padding: 0}}>
            <div style={{overflowY: 'auto', maxHeight: 300}}>
                <Tree
                    defaultExpandAll
                >
                    {this.renderTreeNodes(options)}
                </Tree>
            </div>
        </Card>  
        {error && <span className="invalid-feedback d-block">{error}</span>}
      </div>
    );
  }
}

export default renderTreeCheckbox;