import React from 'react';
import { Wrapper } from './styled';

export default () => {
  return (
    <Wrapper>
      <span className="title">Password requirement</span>
      <ul>
        <li>Contains at least 8 characters</li>
        <li>Contains lowercase and uppercase</li>
        <li>Contains at least 1 number and a symbol</li>
        <li>Does not contain your email address</li>
      </ul>
    </Wrapper>
  );
};
