import axios from "utils/request";

const prefix = "/invoices";

export const getList = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}`,
  });
};

export const exportOrders = (id) => {
  return axios({
    method: "GET",
    id,
    url: `${prefix}/${id}/export-orders`,
  });
};


export const exportInvoice = (id) => {
  return axios({
    method: "GET",
    id,
    url: `${prefix}/${id}/export-invoice`,
  });
};
