import {
    takeLatest,
    call,
    put,
    all
} from 'redux-saga/effects'

import {
    actionType as TYPE
} from './actions'

import {createLableOrder, checkIfUploadingShippingLabelIsRequired} from 'apis/orders'
import {replace} from "connected-react-router";


function* createLableOrderSaga(action){
    const { data } = action
    try {
        const response = yield call(createLableOrder, data)
        if(response.code === 'success'){
            yield all([
                put({ type: TYPE.CREATE_LABEL_ORDER.SUCCESS, ...response }),
                put(replace('/orders/list'))
            ])
        }else{
            yield put({ type: TYPE.CREATE_LABEL_ORDER.ERROR, error: response })
        }
    } catch(error){
        yield all([
            yield put({ type: TYPE.CREATE_LABEL_ORDER.ERROR, error })
        ])
    }
}

function* checkIfUploadingShippingLabelIsRequiredSaga(){
    try {
        const response = yield call(checkIfUploadingShippingLabelIsRequired)
        if(response.code === 'success'){
            yield all([
                put({ type: TYPE.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.SUCCESS, ...response }),
            ])
        }else{
            yield put({ type: TYPE.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.ERROR, error: response })
        }
    } catch(error){
        yield all([
            yield put({ type: TYPE.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.ERROR, error })
        ])
    }
}

function* watcher(){
    yield takeLatest(TYPE.CREATE_LABEL_ORDER.REQUEST, createLableOrderSaga);
    yield takeLatest(TYPE.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.REQUEST, checkIfUploadingShippingLabelIsRequiredSaga);
}

export default watcher