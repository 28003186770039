import { GRAY_600 } from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  svg {
    stroke: ${GRAY_600};
    width: 15px;
    height: 15px;
    margin-bottom: 1px;
    margin-left: 2px;
  }
`;
