import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, change, submit } from "redux-form";
import SelectGeneric from "utils/Form/Search/SelectGeneric";
import { ReactComponent as UploadIcon } from "images/icons/download-cloud.svg";
import { BASE_WHITE } from "components/colors";
import { ExtGMButton } from "./Transastion.filter.alt.styled";
import { exportTransaction } from "./actions";

export default ({}) => {
  const d = useDispatch();
  const searchValue =
    useSelector((state) => state?.form?.paymentFormFilter?.values) || "";
  const isLoading =
    useSelector((state) => state?.transaction?.list?.export?.loading) || false;
  useEffect(() => {
    d(change("paymentFormFilter", "type", "0"));
  }, []);
  const exportHandler = (evt) => {
    const saveParams = {
      export: 1,
      tab: "transaction",
      ...searchValue
    };
    if (saveParams?.date_range) {
      saveParams.from = saveParams?.date_range?.[0]?.startOf("day")?.unix();
      saveParams.to = saveParams?.date_range?.[1]?.endOf("day")?.unix();

      delete saveParams.date_range;
    }
    if (saveParams?.type * 1 === 0) {
      delete saveParams.type;
    }

    d(exportTransaction(saveParams));
  };
  return (
    <>
      <div className="ml-2">
        <Field
          name={`type`}
          component={SelectGeneric}
          classNameContainer="select-type"
          placeholder="Transaction type"
          defaultValue={"0"}
          showSearch={!1}
          options={[
            { value: "0", label: "All transaction types" },
            { value: "3", label: "Refund transactions" },
            { value: "2", label: "Deposit transactions" },
            { value: "1", label: "Payout transactions" }
          ]}
          onChange={(v) => {
            d(change("paymentFormFilter", "type", v));
            // work-around
            setTimeout(() => {
              d(submit("paymentFormFilter"));
            }, 0);
          }}
          noStyle
          no1stValue
        />
      </div>
      <div className="ml-2">
        <div className="position-relative">
          <ExtGMButton type="button" onClick={exportHandler}>
            {isLoading ? (
              <label className="loader"></label>
            ) : (
              <UploadIcon
                style={{ fill: BASE_WHITE, stroke: "none" }}
                className="icon-before"
              />
            )}
            Export transactions
          </ExtGMButton>
        </div>
      </div>
    </>
  );
};
