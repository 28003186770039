/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { censorEmail, censorToken, dummyFunction } from 'utils/helper';
import getMomentInstance from 'utils/moment';
import { Dropdown, Modal, Spin, Tooltip } from 'antd';
import PPIcon from 'images/logo_payment/md-PayPal.svg';
import PYIcon from 'images/logo_payment/md-Payoneer.svg';
import GMIcon from 'images/logo_payment/gwalletk_s32.svg';
import LianIcon from 'images/logo_payment/lian-logo.svg';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import { Link } from 'react-router-dom';
import {
  ContextMenuWrapper,
  GlobalStyle,
  NoWrap,
  Wrapper,
  WrapperModal,
} from './styled';
import { GMButton } from 'components/Button';
import { useSelector } from 'react-redux';

export const getIcon = (type) => {
  switch (type) {
    case 0:
      return <img className="svg" src={PPIcon} />;
    case 1:
      return <img className="svg" src={PYIcon} />;
    case 4:
      return <img className="svg lian" style={{ width: '46px' }} src={LianIcon} />;
    default:
      return <img className="svg" src={GMIcon} />;
  }
};
const paymentName = ['PayPal', 'Payoneer', 'GWallet', 'GWallet', 'LianLian'];
export const primaryColumns = [
  {
    title: 'Payment Method',
    dataIndex: 'numberOfOrders',
    align: 'left',
    key: 'numberOfOrders',
    render: (_d, item) => {
      let account = item.email;
      account = account || '';
      const dsp =
        account.length > 16 ? `${account}`.slice(0, 15) + '...' : account;
      return (
        // <p>{getIcon(item.type)} {account}</p>
        <p>
          {getIcon(item.type)}
          {account.length > 16 ? (
            <Tooltip title={<NoWrap>{account}</NoWrap>} placement="topLeft">
              {dsp}
            </Tooltip>
          ) : (
            account
          )}
        </p>
      );
    },
  },
  {
    title: 'Billing Info',
    dataIndex: 'quantity',
    align: 'left',
    key: 'quantity',
    render: (_d, item) => {
      const country = item?.countries?.find((e) => {
        return e.id * 1 === item?.billingInfor?.countryId * 1;
      })?.countryName;
      const addressInfo = [];
      item?.billingInfor?.company &&
        addressInfo.push(item?.billingInfor?.company);
      item?.billingInfor?.streetAddress &&
        addressInfo.push(item?.billingInfor?.streetAddress);
      item?.billingInfor?.extendedAddress &&
        addressInfo.push(item?.billingInfor?.extendedAddress);
      item?.billingInfor?.city && addressInfo.push(item?.billingInfor?.city);
      item?.billingInfor?.state && addressInfo.push(item?.billingInfor?.state);
      item?.billingInfor?.postalcode &&
        addressInfo.push(item?.billingInfor?.postalcode);
      country && addressInfo.push(country);

      if (!item?.billingInfor || addressInfo.join(', ').trim() === '') {
        return <span className="missing-info">Missing billing info</span>
      }

      return (
        <>
          <p className="title">
            {item?.billingInfor?.firstName} {item?.billingInfor?.lastName}
          </p>
          <p>{addressInfo.join(', ')}</p>
        </>
      );
    },
  },
  {
    title: 'Created date',
    dataIndex: 'numberOfItems',
    key: 'numberOfItems',
    align: 'left',
    render: (_d, item) => {
      return (
        <span>{getMomentInstance(item?.time * 1000, 0, true, false)}</span>
      );
    },
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    align: 'right',
    render: () => {
      return <MoreAction className="dummyaction" />;
    },
  },
];

export const getColumnsWithActions = ({
  onUpdate = dummyFunction,
  onDelete = dummyFunction,
  onSetPrimary = dummyFunction,
}) => {
  const tmp = [...primaryColumns];
  tmp[primaryColumns.length - 1] = {
    title: '',
    dataIndex: 'actions',
    align: 'right',
    key: 'actions',
    render: (_d, item) => {
      const payId = item.id;
      const billingId = item?.billingInfor?.id;
      const billing_method = paymentName[item.type];
      const account = item.type !== 1 ? item.email : item.content;

      return (
        <WrapperModal>
          <Dropdown
            overlay={
              <ContextMenuWrapper>
                <a
                  href=""
                  onClick={(evt) =>
                    onSetPrimary(evt, {
                      item,
                      payId,
                      billingId,
                      altParams: {
                        billing_method,
                        account,
                      },
                    })
                  }
                >
                  Set as Primary
                </a>
                {(!item?.billingInfor?.firstName ||
                  !item?.billingInfor?.lastName ||
                  !item?.billingInfor?.city ||
                  !item?.billingInfor?.state ||
                  !item?.billingInfor?.postalcode ||
                  !item?.billingInfor?.countryId ||
                  item?.billingInfor?.paymentMethod === 3 ||
                  !item?.billingInfor?.streetAddress) && (
                    //Always allow GM Wallet update
                    <a href="" onClick={(evt) => onUpdate(evt, item)}>
                      Update billing method
                    </a>
                  )}
                {item?.type === 3 ? (
                  ''
                ) : (
                  //Except GM wallet can't be delete
                  <a
                    href=""
                    onClick={(evt) =>
                      onDelete(evt, {
                        payId,
                        billingId,
                      })
                    }
                  >
                    Delete billing method
                  </a>
                )}
              </ContextMenuWrapper>
            }
            trigger={['click']}
          >
            <MoreAction className="context-menu" />
          </Dropdown>
        </WrapperModal>
      );
    },
  };
  return tmp;
};
