import { GMButton } from 'components/Button';
import styled from 'styled-components';

export const Button = styled(GMButton)``;
export const Wrapper = styled.div`
  .login-button {
    width: 480px;
    max-width: 100%;
    margin: 12px 0 12px 0;
  }
  .input-field {
    width: 480px;
    max-width: 100%;
  }
  .name-field {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #344054;
  }
  .register {
    .forgot-password-social {
      color: #169dd8;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .policy {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #667085;
  }
  .al {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Gray/600 */

    color: #475467;
  }
  .footer-login {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #169dd8;
    margin-left:2px
  }
`;
