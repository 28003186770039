import { takeLatest, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { create } from "apis/gift_message";
import { actionType as TYPE } from "./actions";

function* createGiftMessageSaga(action) {
  try {
    const { params } = action;
    const response = yield call(create, params);

    if (response.code === "success") {
      yield all([
        put({ type: TYPE.GIFTMESSAGE_CREATE.SUCCESS, response }),
        put(replace("/settings/gift-message")),
      ]);
    } else {
      yield put({ type: TYPE.GIFTMESSAGE_CREATE.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.GIFTMESSAGE_CREATE.ERROR, error })]);
  }
}

function* createGiftMessageWatcher() {
  yield takeLatest(TYPE.GIFTMESSAGE_CREATE.REQUEST, createGiftMessageSaga);
}

export default createGiftMessageWatcher;
