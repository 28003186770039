import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Button, Table } from 'antd';
import getMomentInstance from 'utils/moment';
import { getDetail } from './actions';
import {
  getOptionLabel,
  import_order_queue_status,
  import_progress_status,
} from 'utils/options';
import { Link } from 'react-router-dom';
import BackToIcon from 'images/chevron-down.svg';
import {
  getPlatformForImportOrderProgress,
  generateOrderCode,
} from 'utils/helper';
import RenderRawOrderDataModal from './components/RenderRawOrderDataModal';
import { Wrapper } from './styled';
class Detail extends Component {
  componentDidMount() {
    this.props.getDetail(this.props.match.params.id);
  }

  render() {
    const { detail, pageName, loadingDetail } = this.props;
    const columns = [
      {
        title: '#',
        dataIndex: 'id',
        align: 'center',
        key: 'id',
        render: (text, record, i) => `#${i + 1}`,
      },
      {
        title: 'REFERENCE ID',
        dataIndex: 'referenceId',
        align: 'center',
        key: 'referenceId',
        render: (text, record, i) => `${text}`,
      },
      {
        title: 'GEARMENT ORDER ID',
        dataIndex: 'gearmentOrderId',
        align: 'center',
        key: 'gearmentOrderId',
        render: (text) => `TW-${generateOrderCode(`${text}`, 10)}`,
      },
      {
        title: 'DATA ORDER',
        dataIndex: 'rawData',
        align: 'center',
        key: 'rawData',
        render: (text, record, i) => <RenderRawOrderDataModal rawData={text} />,
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        align: 'center',
        key: 'status',
        render: (text) => {
          return getOptionLabel(import_order_queue_status, text);
        },
      },
      {
        title: 'LOG',
        dataIndex: 'response',
        align: 'center',
        key: 'response',
        render: (text) => `${text?.message || ''}`,
      },
    ];
    const id = this.props.match.params.id;
    return (
      <Wrapper
        pageName={pageName}
        breadcrumDetails={{
          [id]: id && `Detail #${id || ''}`,
        }}
      >
        <Spin spinning={loadingDetail}>
          <div>
            <div>
              <div className="p-2">
                <table className="table table-borderless table-sm">
                  <tbody>
                    <tr>
                      <td style={{ width: 200 }} className="px-4">
                        Store:
                      </td>
                      <td className="px-4">
                        {detail?.storeIntegration?.showName || 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 200 }} className="px-4">
                        Platform:
                      </td>
                      <td className="px-4">
                        {detail.type
                          ? getPlatformForImportOrderProgress(detail.type)
                          : 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 200 }} className="px-4">
                        Status:
                      </td>
                      <td className="px-4">
                        {getOptionLabel(import_progress_status, detail.status)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 200 }} className="px-4">
                        Request time:
                      </td>
                      <td className="px-4">
                        {detail.createdAt
                          ? getMomentInstance(detail.createdAt, null, true)
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 200 }} className="px-4">
                        Completed Time
                      </td>
                      <td className="px-4">
                        {detail.finishedAt
                          ? getMomentInstance(null, detail.finishedAt, true)
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 200 }} className="px-4">
                        File import
                      </td>
                      <td className="px-4">
                        <a
                          href={detail.downloadLink || ''}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          File import
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: 200 }} className="px-4">
                        Logs
                      </td>
                      <td className="px-4">
                        <tr>
                          {' '}
                          {detail?.log?.msg || ''}{' '}
                          {detail?.log?.list_order && (
                            <>
                              {detail.log.list_order.split(',').map((id) =>
                                id ? (
                                  <>
                                    <Link to={`/orders/${id}`}>
                                      {`TW-${generateOrderCode(`${id}`, 10)}`}
                                    </Link>
                                    &nbsp;&nbsp;
                                  </>
                                ) : (
                                  ''
                                )
                              )}
                            </>
                          )}
                        </tr>
                        <tr>
                          {detail?.log?.order_existed && (
                            <>
                              Orders already exist:{' '}
                              {detail.log.order_existed.split(',').map((id) =>
                                id ? (
                                  <>
                                    <Link to={`/orders/${id}`}>
                                      {`TW-${generateOrderCode(`${id}`, 10)}`}
                                    </Link>
                                    &nbsp;&nbsp;
                                  </>
                                ) : (
                                  ''
                                )
                              )}
                            </>
                          )}
                        </tr>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {Array.isArray(detail?.importOrderQueues) &&
                  detail?.importOrderQueues?.length > 0 && (
                    <Table
                      rowKey="id"
                      columns={columns}
                      dataSource={detail.importOrderQueues}
                      pagination={false}
                      scroll={{ x: 1500 }}
                    />
                  )}
              </div>
            </div>
          </div>
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    loadingDetail: state.order.import_progress_detail.detailImportOrder.loading,
    detail: state.order.import_progress_detail.detailImportOrder.data,
  }),
  (dispatch) => ({
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
  })
)(Detail);
