import React, { Component } from "react";
import { connect } from "react-redux";
import {
  change,
  FieldArray,
  formValueSelector,
  reduxForm,
  arrayRemoveAll,
  arrayInsert,
} from "redux-form";
import { GMButton } from "components/Button";
import FieldUploadImageLibrary from "../components/FieldUploadImageLibrary";
import { Modal } from "antd";
import { getListImages, archiveImages } from "../actions";
import ListUploadedImages from "../components/ListUploadedImages";
import { uploadArtwork } from "apis/image_design";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { addMarginTopToCheckBoxOfAntdTable } from "utils/helper";
const { confirm } = Modal;
const validate = () => {
  let errors = {};
  return errors;
};
class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showListImage: false,
      selectedRowKeys: [],
    };
  }

  componentDidMount() {
    this.props.fetchData({});
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.images) !== JSON.stringify(this.props.images)
    ) {
      const newSelectedRowKeys = this.state.selectedRowKeys.filter((id) =>
        (this.props?.images?.data || []).find((item) => parseInt(item.id) === parseInt(id))
      );
      this.setState({ selectedRowKeys: newSelectedRowKeys });
      window.scroll(0, 0);
    }
    addMarginTopToCheckBoxOfAntdTable('ant-table-selection', this.state.selectedRowKeys);
  }

  setShowListImage = (status) => {
    this.setState({ showListImage: status });
  };

  render() {
    return (
      <div className="fullwidth">
        <form>
          <div className="mb-3">
            <FieldArray
              name="listImages"
              listImages={this.props.listImages}
              requestName="images[]"
              component={FieldUploadImageLibrary}
              method="POST"
              showUploadList={false}
              cusId={this.props.profile.id}
              multiple={true}
              typeUpload="upload-image-library"
              changeFieldValue={this.props.changeFieldValue}
              maximumFile={200}
              setShowListImage={this.setShowListImage}
              showListImage={this.state.showListImage}
              insertItem={this.props.insertItem}
            />
            {this.state.showListImage &&
              this.props.listImages.length > 0 && (
                <ListUploadedImages
                  uploadImage={this.props.uploadImage}
                  queuedData={this.props.listImages.filter(
                    (item) =>
                      item == null || (item && parseInt(item.status) === 0)
                  )}
                  data={this.props.listImages.filter(
                    (item) => item !== null
                  )}
                />
              )}
          </div>
        </form>
        <GMButton
          className="cta-back"
          onClick={() => {
            this.props.removeAllItem("listImages");
            this.props.fetchData({ page: 1, perPage: 50 });
            // eslint-disable-next-line no-unused-expressions
            this.props?.closeModal();
          }}
        >
          Back to Design Library
        </GMButton>
      </div>
    );
  }
}
const selector = formValueSelector("FormUploadImage");
const mapStateToProps = (state) => ({
  formCreateBulkData: state.form.FormUploadImage?.values || {},
  listImages: selector(state, "listImages") || [],
  images: state.image_library.list.listImages,
  profile: state.auth.info.profile,
  archiveImageLoading: state.image_library.list.archive.loading,
  initialValues: {
    listImages: [],
  },
});
const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change("FormUploadImage", field, value));
  },
  removeAllItem: (field) => {
    dispatch(arrayRemoveAll("FormUploadImage", field));
  },
  insertItem: (field, value, index) => {
    dispatch(arrayInsert("FormUploadImage", field, index, value));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "FormUploadImage",
    enableReinitialize: true,
    validate,
  })(UploadForm)
);
