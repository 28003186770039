import React, { Component } from "react";

import { connect } from "react-redux";
import { Field, reduxForm, submit } from "redux-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSearch } from "@fortawesome/free-solid-svg-icons";
import validate from "./validate";
import {
  renderRangeDate,
  renderSelectAnt,
} from "utils/Form";
import {
  options_inkgo_type,
  options_inkgo_group,
} from "utils/options";
import {Card, Tooltip } from "antd";
import reload from "images/reload.svg";

class index extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Card>
          <div className="row">
            <div className="col-md-4 col-lg-4 col-xl-2 mb-2">
              <Field
                name="group"
                component={renderSelectAnt}
                placeholder="-- Group --"
                options={options_inkgo_group}
              />
            </div>
            <div className="col-md-4 col-lg-4 col-xl-2 mb-2">
              <Field
                name="type"
                component={renderSelectAnt}
                placeholder="-- Type --"
                allowClear
                options={options_inkgo_type}
              />
            </div>
            <Field
              name="date_range"
              component={renderRangeDate}
              classContainer="col-md-4 col-lg-4 col-xl-2 mb-2"
            />
            <div className="col-md-4 col-lg-4 col-xl-2 mb-2">
              <button type="submit" className="btn btn-primary btn-block">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>

            <div className="col-md-4 col-lg-4 col-xl-1 mb-2">
              <button type="submit" className="btn btn-success btn-block">
                <FontAwesomeIcon icon={faDownload} /> Export
              </button>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-1 mb-2 d-flex justify-content-center">
              <Tooltip title="Reset">
                <button
                  type="submit"
                  className="btn btn-link"
                  onClick={() => {
                    this.props.reset("formFilterReportInkgo");
                    this.props.dispatch(submit("formFilterReportInkgo"));
                  }}
                >
                  <img src={reload} height={23} alt="reload-icon"/>
                </button>
              </Tooltip>
            </div>
          </div>
        </Card>
      </form>
    );
  }
}

index = reduxForm({
  form: "formFilterReportInkgo",
  validate,
  enableReinitialize: true,
})(index);

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
