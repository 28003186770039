export const actionType = {
  VERIFY_EMAIL: {
    REQUEST: "AUTH.VERIFY_EMAIL.REQUEST",
    SUCCESS: "AUTH.VERIFY_EMAIL.SUCCESS",
    ERROR: "AUTH.VERIFY_EMAIL.ERROR",
  },
  VERIFY_TOKEN_EMAIL: {
    REQUEST: "AUTH.VERIFY_TOKEN_EMAIL.REQUEST",
    SUCCESS: "AUTH.VERIFY_TOKEN_EMAIL.SUCCESS",
    ERROR: "AUTH.VERIFY_TOKEN_EMAIL.ERROR",
  },
};


//Resend email verify account
export function verifyEmail(data) {
  return {
    type: actionType.VERIFY_EMAIL.REQUEST,
    data,
  };
}


//Verify token after click email verify account
export function verifyTokenEmail(data) {
  return {
    type: actionType.VERIFY_TOKEN_EMAIL.REQUEST,
    data,
  };
}
