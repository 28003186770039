import React from "react";
import { deleteBillingPaymentAccount } from "apis/billing_payment_account";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setLoading, refetchBillingPaymentAccount } from "../../../actions";
import { Modal, notification, Tooltip } from "antd";
import CloseIcon from "images/deleteIcon.svg";

const { confirm } = Modal;
function DeleteButton({ billingPaymentMethodId = 0 }) {
  const dispatch = useDispatch();
  const handleOnDeletePaymentMethod = () => {
    confirm({
      title: "Do you want to delete this payment method?",
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        dispatch(setLoading(true));
        deleteBillingPaymentAccount(billingPaymentMethodId)
          .then((response) => {
            dispatch(setLoading(false));
            if (response?.code === "success") {
              dispatch(refetchBillingPaymentAccount(true));
              notification.success({
                message:
                  response?.clientMsg ||
                  `Delete payment method #${billingPaymentMethodId} successfully.`,
                duration: 5,
              });
            } else {
              notification.warn({
                message:
                  response?.[0]?.clientMsg ||
                  `Delete payment method #${billingPaymentMethodId} failed.`,
                duration: 5,
              });
            }
          })
          .catch((error) => {
            dispatch(setLoading(false));
          });
      },
      onCancel() {},
    });
  };
  return (
    <Tooltip title={"Delete"}>
      <img
        onClick={handleOnDeletePaymentMethod}
        src={CloseIcon}
        style={{ width: "20px" }}
        alt="Close icon"
        className="cursor-pointer"
      />
    </Tooltip>
  );
}

export default React.memo(DeleteButton);