const validate = values => {
    let errors = {};
    if(!values.title || values.title.replace(/\s+/g, "").length === 0){
        errors.title = true;
    }
    if(!values.slug || values.slug.replace(/\s+/g, "").length === 0){
        errors.slug = true;
    }
    return errors
};
export default validate;