import React from "react";
import ListInvoice from "scenes/Invoice/scenes/List";
import icon from "images/invoice.svg";

export default [
  {
    key: "invoices",
    name: "Invoices",
    component: ListInvoice,
    path: "/invoices",
    icon: <img src={icon} height={21}  alt="icon"/>,
    template: "default",
    isPublic: false,
  }
];
