import {
  BASE_WHITE,
  ERROR_500,
  ERROR_700,
  GRAY_100,
  GRAY_400,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_500,
  PRIMARY_600,
} from 'components/colors';
import styled from 'styled-components';

export default styled.div``;

export const ImageUploadWrapper = styled.div`
  /* .upload-success {
    border: 1px dashed #eaecf0;
    svg {
      stroke: ${GRAY_400};
      margin-bottom: 110px;
    }
  } */

  .upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    width: 672px;
    max-width: 100%;
    max-height: 100vh;
    /* Base/White */

    background: ${BASE_WHITE};
    /* Gray/200 */

    border: 1px dashed #eaecf0;
    border-radius: 12px;
  }

  .text-upload {
    font-size: 14px;
  }
  .click-to {
    color: ${PRIMARY_500};
  }
`;
