export const actionType = {
    UPDATE: {
        REQUEST: 'USERS.CHANGE_EMAIL.REQUEST',
        SUCCESS: 'USERS.CHANGE_EMAIL.SUCCESS',
        ERROR: 'USERS.CHANGE_EMAIL.ERROR',
    },
}
export const update = data => ({
    type: actionType.UPDATE.REQUEST,
    data
})