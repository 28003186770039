import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, submit } from 'redux-form';
import { renderSelectAnt } from 'utils/Form';
import { getStoreTypeName } from 'utils/helper';
import { Wrapper } from './styled';
import { debounce } from 'lodash';
import { issue_solution_options } from 'utils/options';

class index extends Component {
  state = {
    advance_search: false,
  };
  onCloseAdvancedSearch = () => {
    this.setState({
      advance_search: false,
    });
  };
  onOpenAdvancedSearch = () => {
    this.setState({
      advance_search: true,
    });
  };

  render() {
    const { stores, dispatch } = this.props;
    const debounceSubmit = debounce((evt, value = '') => {
      dispatch(submit('paymentFormFilter'));
    }, 100);
    return (
      <Wrapper>
        <Field
          name="storeId"
          component={renderSelectAnt}
          className="platform"
          placeholder="Choose store"
          options={[
            {
              value: 0,
              label: <span>All store</span>,
            },
            ...stores?.data.map((store) => ({
              value: store.id,
              label:
                `[${getStoreTypeName(store.backendType)}] ` +
                (store.showName || `#${store.id}`),
            })),
          ]}
          onChange={debounceSubmit}
        />
        <Field
          name="solution"
          component={renderSelectAnt}
          className="platform"
          placeholder="Solutions"
          options={[
            {
              value: '0',
              label: <span>All solutions</span>,
            },
            ...issue_solution_options,
          ]}
          onChange={debounceSubmit}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
