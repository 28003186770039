import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm, submit } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validate from './validate';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import renderInputSearch from 'utils/Form/renderInputSearch';
import { Card, Drawer } from 'antd';
import AdvancedFilter from './AdvancedFilter';
import CloseIcon from 'images/x2.svg';
import SearchIcon from 'images/search.svg';
import { ButtonGM } from 'components/ButtonGM/styled';
import { GMTextbox } from 'components/InputFields';
import { ReactComponent as SearchIcon2 } from 'images/icons/search-md.svg';
import { ReactComponent as ClearIcon } from 'images/icons/x-close.svg';
import { ReactComponent as FilterIcon } from 'images/icons/filter-lines.svg';
import { debounce } from 'lodash';
class index extends Component {
  state = {
    advance_search: false,
  };
  onCloseAdvancedSearch = () => {
    this.setState({
      advance_search: false,
    });
  };
  onOpenAdvancedSearch = () => {
    this.setState({
      advance_search: true,
    });
  };
  debounceAutoSubmit = debounce((evt, value = '') => {
    this.props.dispatch(submit('formFilterTransaction'));
  }, 700);
  render() {
    const {
      handleSubmit,
      handleAdvancedSubmit,
      initialValues,
      transactions,
      searchValue,
    } = this.props;
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="filter-section">
            <GMTextbox
              name="name"
              className="id-search"
              placeholder="Search"
              iconBefore={<SearchIcon2 />}
              iconAfter={
                searchValue?.length > 0 && (
                  <ClearIcon
                    className="clear"
                    onClick={() => {
                      this.props.dispatch(change('formFilterTransaction', 'name', ''));
                      this.debounceAutoSubmit();
                    }}
                  />
                )}
              onChange={this.debounceAutoSubmit}
            />
            <div
              className="filter-button"
              onClick={this.onOpenAdvancedSearch}
            >
              <FilterIcon className="filter" />
              Filters
            </div>
          </div>
        </form>
        <Drawer
          title={<b className="Filter">Filter</b>}
          className="custom-filter"
          placement="right"
          closable={true}
          onClose={this.onCloseAdvancedSearch}
          visible={this.state.advance_search}
          closeIcon={
            <img src={CloseIcon} style={{ width: '20px' }} alt="close-icon" />
          }
          width={this.props.is_mobile ? '80%' : '480px'}
        >
          <AdvancedFilter
            onCloseAdvancedSearch={this.onCloseAdvancedSearch}
            onSubmit={handleAdvancedSubmit}
            initialValues={initialValues}
            transactions={transactions}
          />
        </Drawer>
      </>
    );
  }
}

index = reduxForm({
  form: 'formFilterTransaction',
  validate,
  enableReinitialize: true,
})(index);
const selector = formValueSelector('formFilterTransaction');
export default connect(state => ({
  searchValue: selector(state, 'name'),
}))(index);
