import React from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import validate from './validate'
import { renderInputText, renderTextareaAnt, renderSwitchAnt, renderRadioAnt } from 'utils/Form'
import { GMButton } from 'components/Button';

let formGoogleDataFeedGoogle = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 800, margin: '0 auto' }}>
      <div className="form-group">
        <label className="col-form-label">Form name  </label>
        <Field
          name="name"
          component={renderInputText}
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Google Sheet ID  </label>
        <Field
          name="googleSheetId"
          component={renderInputText}
        />
        <div className="row">
          <div className="col-sm-6">
            Sheet name is blank then use sheet default.
            <a href={"https://account-staging.gearment.com/images/google_sheet_id.png"} target="_blank" rel="noopener noreferrer">
              Get sheet ID
            </a>
          </div>
          <div className="col-sm-6">
            URL GG Shee.
            <a href="https://account-staging.gearment.com/document/feed_google_template.xlsx" download target="_blank" rel="noopener noreferrer">
              Templates Feed Google
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Field
                name="default"
                component={renderSwitchAnt}
              />
              <label className="ml-3 col-form-label">Mark as default</label>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Field
                name="status"
                component={renderSwitchAnt}
              />
              <label className="ml-3 col-form-label">Active form</label>
            </div>
          </div>
        </div>
        <div className="col-12">
          <h5>Form Settings</h5>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Brand Feed ( Shop Name )  </label>
            <Field
              name="googleBrand"
              component={renderInputText}
              placeholder="Brand on Google"
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Gender  </label>
            <Field
              name="googleGender"
              component={renderInputText}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Domain Store  </label>
            <Field
              name="googleStoreDomain"
              component={renderInputText}
              placeholder="https://domainstore.com"
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Age Group</label>
            <Field
              name="googleAgeGroup"
              component={renderInputText}
              placeholder="New Born"
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Google Product Category  </label>
            <Field
              name="googleProductCategory"
              component={renderInputText}
              placeholder="Apparel & Accessories > Clothing > Shirts & Tops"
            />
            <a href="https://www.google.com/basepages/producttype/taxonomy.en-US.txt" target="_blank" rel="noopener noreferrer">Link Google Product category</a>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="col-form-label">Append Color/Size for Title (Yes/No)</label>
            <Field
              name="googleColorSize"
              component={renderRadioAnt}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
              ]}
            />
          </div>
          <div className="form-group">
            <label className="col-form-label">Identifier exists</label>
            <Field
              name="googleIdentifierExists"
              component={renderRadioAnt}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }]
              }
            />
          </div>
        </div>
      </div>

      <div className="form-group">
        <label className="col-form-label">GTIN lists</label>
        <Field
          name="gtin"
          component={renderTextareaAnt}
        />
      </div>

      <div className="text-right form-footer">
        <GMButton type="submit">Save</GMButton>
      </div>
    </form>
  );
};

formGoogleDataFeedGoogle.propTypes = {

};

formGoogleDataFeedGoogle = reduxForm({
  form: 'formGoogleDataFeedGoogle',
  enableReinitialize: true,
  validate
})(formGoogleDataFeedGoogle)
formGoogleDataFeedGoogle = connect(
  state => {
    return {
    }
  }
)(formGoogleDataFeedGoogle);
export default formGoogleDataFeedGoogle;