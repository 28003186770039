import React from 'react';
import { ReactComponent as IconHome } from 'images/icons/menu/home.svg';
import { ReactComponent as IconOrder } from 'images/icons/menu/shopping-cart.svg';
import { ReactComponent as IconProduct } from 'images/icons/menu/t-shirt.svg';
import { ReactComponent as IconLibrary } from 'images/icons/menu/palette.svg';
import { ReactComponent as IconTransaction } from 'images/icons/menu/bar-chart.svg';
import { ReactComponent as IconLogs } from 'images/icons/menu/log.svg';
import { ReactComponent as IconSetting } from 'images/icons/menu/setting.svg';
import { ReactComponent as IconSupport } from 'images/icons/menu/help-circle.svg';
import { ReactComponent as IconStore } from 'images/icons/menu/store.svg';
import { ReactComponent as IconBilling } from 'images/icons/menu/wallet.svg';
// virtualPath : obj
//   key: info obj
//   value: if any: link info

export default [
  {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    icon: <IconHome />,
    isPublic: true,
    isPrimary: true,
  },

  {
    key: 'orders',
    name: 'Orders',
    path: '/orders',
    target: '/orders/list',
    icon: <IconOrder />,
    isPrimary: true,
    children: [
      {
        key: 'orders.list',
        name: 'All orders',
        path: '/orders/list',
        isPublic: true,
      },
      {
        key: 'orders.risk',
        name: 'Order at risk',
        path: '/orders/risk',
        isPublic: true,
      },
      // {
      //   key: 'orders.import',
      //   name: 'Import orders',
      //   path: '/orders/import',
      //   isPublic: true,
      //   subInfo: [
      //     {
      //       key: 'logs.import-orders.:id',
      //       name: 'Import orders logs detail',
      //       path: '/logs/import-orders/:id',
      //     },
      //   ],
      // },
      {
        key: 'orders.order-issues',
        name: 'Issues',
        path: '/orders/order-issues',
        isPublic: true,
        subInfo: [
          {
            key: 'orders.order-issues.:id',
            name: 'View Details',
            path: '/orders/order-issues/:id',
            subInfo: [
              {
                key: 'orders.order-issues.:id.edit',
                name: 'Edit Issue',
                path: '/orders/order-issues/:id/edit',
                isPublic: true,
              },
            ],
          },
          {
            key: 'orders.order-issues.create',
            name: 'Create Issue',
            path: '/orders/order-issues/create',
            isPublic: true,
          },
        ],
      },
      {
        key: 'orders.stock-status',
        name: 'Inventory',
        path: '/orders/stock-status',
        icon: null,
        isPublic: true,
      },
      {
        key: 'orders.tracking',
        name: 'Order\'s tracking',
        path: '/orders/tracking',
        icon: null,
        isPublic: true,
      },
    ],
    subInfo: [
      {
        key: 'orders.deleted-order',
        name: 'Archived Orders',
        path: '/orders/deleted-order',
        isPublic: true,
      },
      {
        key: 'orders.make-payment',
        name: 'Make payment',
        path: '/orders/make-payment',
        isPublic: true,
      },
      {
        key: 'orders.match-product',
        name: 'Match Product',
        path: '/orders/match-product',
      },
      {
        key: 'orders.create-label-order',
        name: 'Order label',
        path: '/orders/create-label-order',
        virtualPath: ['orders.list', 'orders.create-label-order'],
      },
      {
        key: 'orders.create',
        name: 'Create new order',
        path: '/orders/create',
        virtualPath: ['orders.list', 'orders.create'],
      },
      {
        key: 'orders.:id',
        name: 'Detail',
        path: '/orders/:id',
        subInfo: [
          // {
          //   key: 'orders.:id',
          //   name: 'Detail',
          //   path: '/orders/:id',
          // },
          {
            key: 'orders.:id.convert-order-label',
            name: 'Convert to label order',
            path: '/orders/:id/convert-order-label',
          },
          {
            key: 'orders.:id.duplicate',
            name: 'Duplicate FBA order',
            path: '/orders/:id/duplicate',
          },
        ],
      },
    ],
  },
  {
    key: 'products',
    name: 'My products',
    path: '/products',
    target: '/products/list',
    icon: <IconProduct />,
    isPrimary: true,
    children: [
      {
        key: 'products.list',
        name: 'All products',
        path: '/products/list',
        // virtualPath: ['products.list'],
        isPublic: true,
      },
      {
        key: 'products.mockups',
        name: 'Mockups',
        path: '/products/mockups',
        isPublic: true,
        subInfo: [
          {
            key: 'products.mockups.:id',
            name: 'Mockup detail',
            path: '/products/mockups/:id',
            subInfo: [
              {
                key: 'products.mockups.:id.edit',
                name: 'Edit',
                path: '/products/mockups/:id/edit',
              },
            ],
          },
          {
            key: 'products.mockups.create',
            name: 'Mockup create',
            path: '/products/mockups/create',
          },
        ],
      },
      {
        key: 'products.data-feed',
        name: 'Data feed configuration',
        path: '/products/data-feed',
        isPublic: true,
        subInfo: [
          {
            hkey: 'products.data-feed.feed-form-google',
            name: 'Export feed form Google',
            target: '/products/data-feed/feed-form-google',
          },
          {
            hkey: 'products.data-feed.feed-form-amazon',
            name: 'Export feed form amazon',
            target: '/products/data-feed/feed-form-amazon',
          },
          {
            hkey: 'products.data-feed.custom-feed-amazon',
            name: 'Export feed custom Amazon',
            target: '/products/data-feed/custom-feed-amazon',
          },
          {
            hkey: 'products.data-feed.amazon',
            name: 'Amazon',
            target: '/products/data-feed/amazon',
          },
          {
            hkey: 'products.data-feed.google',
            name: 'Google',
            target: '/products/data-feed/google',
          },
          {
            hkey: 'products.data-feed.:id',
            name: '',
            path: '/products/data-feed/:id',
            ignoreSub: true,
            subInfo: [
              {
                hkey: 'products.data-feed.:id.amazon',
                name: 'Amazon',
                path: '/products/data-feed/:id/amazon',
              },
            ],
          },
          {
            hkey: 'products.data-feed.:id',
            name: '',
            path: '/products/data-feed/:id',
            ignoreSub: true,
            subInfo: [
              {
                hkey: 'products.data-feed.:id.google',
                name: 'Google',
                path: '/products/data-feed/:id/google',
              },
            ],
          },
        ],
      },
    ],
    subInfo: [
      {
        key: 'products.create',
        name: 'Create product',
        path: '/products/create',
        isPublic: true,
        virtualPath: ['products.list', 'products.create'],
        subInfo: [
          {
            key: 'products.create.preview',
            name: 'Preview',
            path: '/products/create/preview',
          },
        ],
      },
      {
        key: 'products.:id',
        name: 'View details',
        path: '/products/:id/edit',
        subInfo: [
          {
            key: 'products.:id.edit',
            name: 'Edit',
            path: '/products/:id/edit',
            virtualPath: ['products.list', 'products.:id', 'products.:id.edit'],
            isPublic: true,
          },
          {
            key: 'products.:id.clone',
            name: 'Clone product',
            path: '/products/:id/clone',
            isPublic: true,
          },
          {
            key: 'products.:id.bulk',
            name: 'Bulk product',
            path: '/products/:id/bulk',
            isPublic: true,
          },
        ],
      },
    ],
  },
  {
    key: 'image-library',
    name: 'Design library',
    path: '/image-library',
    icon: <IconLibrary />,
    isPublic: true,
    isPrimary: true,
  },
  {
    key: 'stores',
    name: 'Stores',
    path: '/stores',
    isPublic: true,
    permissions: ['createStorePermission'],
    icon: <IconStore />,
    isPrimary: true,
  },
  {
    key: 'billing',
    name: 'Billing',
    path: '/billing/payment',
    icon: <IconBilling />,
    isPublic: true,
    isPrimary: true,
    children: [
      {
        key: 'billing.payment',
        name: 'Payments',
        path: '/billing/payment',
        subInfo: [
          {
            key: 'billing.payment.:id',
            name: 'Payment',
            path: '/billing/transactions/:id/detail',
          },
        ],
      },
      {
        key: 'billing.method',
        name: 'Billing methods',
        path: '/billing/method',
        subInfo: [
          {
            key: 'billing.Method.:id',
            name: 'Method',
            path: '/billing/Method/:id',
          },
          {
            key: 'billing.method.new',
            name: 'Add new billing method',
            updateName: 'Update billing method',
            path: '/billing/method/new',
          },
        ],
      },
      {
        key: 'billing.g-wallet',
        name: 'G-Wallet',
        path: '/billing/g-wallet',
      },
      {
        key: 'billing.legal-info',
        name: 'Legal info',
        path: '/billing/legal-info',
      },
      {
        key: 'billing.return-address',
        name: 'Return address',
        path: '/billing/return-address',
        subInfo: [
          {
            key: 'billing.return-address.create',
            name: 'Add new return address',
            path: '/billing/return-address/create',
          },
          {
            key: 'billing.return-address.:id',
            name: 'Create return address',
            path: '/billing/return-address/:id/edit',
            subInfo: [
              {
                key: 'billing.return-address.:id.edit',
                name: 'Edit return address',
                path: '/billing/return-address/:id/edit',
              },
            ],
          },
        ],
      },
      // {
      //   key: 'billing.indemnification',
      //   name: 'Indemnification Agreement',
      //   path: '/billing/indemnification',
      //   subInfo: [
      //     {
      //       key: 'billing.indemnification.create',
      //       name: 'Add new return address',
      //       path: '/billing/return-address/create',
      //     },
      //     {
      //       key: 'billing.indemnification.preview',
      //       name: '',
      //       path: '/billing/indemnification/preview',
      //     },
      //   ],
      // },
    ],
    subInfo: [
      {
        key: 'billing.transactions',
        name: 'Transactions',
        path: '/billing/payment',
        subInfo: [
          {
            key: 'billing.transactions.:id',
            name: 'Transactions',
            path: '/billing/transactions/:id/detail',
            subInfo: [
              {
                key: 'billing.transactions.:id.detail',
                name: 'Detail',
                path: '/billing/transactions/:id/detail',
                virtualPath: [
                  'billing',
                  'billing.payment',
                  'billing.payment.:id',
                ],
              },
              {
                key: 'billing.transactions.:id.invoice',
                name: 'Invoice',
                path: '/billing/transactions/:id/invoice',
                virtualPath: [
                  'billing',
                  'billing.payment',
                  'billing.payment.:id',
                  'billing.transactions.:id.invoice',
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'analytics',
    name: 'Analytics',
    path: '/analytics',
    target: '/analytics',
    icon: <IconTransaction />,
    isPrimary: true,
    isPublic: true,
    // children: [
    //   {
    //     key: 'statistics.reports-dashboard',
    //     name: 'Dashboard',
    //     path: '/statistics/reports-dashboard',
    //     isPublic: false,
    //     permissions: ['viewSettingPermission'],
    //   },
    //   {
    //     key: 'statistics.account-reports',
    //     name: 'Staff account',
    //     path: '/statistics/account-reports',
    //     isPublic: false,
    //     permissions: ['viewSettingPermission'],
    //   },
    //   {
    //     key: 'statistics.report-product-sell',
    //     name: 'Product sales',
    //     path: '/statistics/report-product-sell',
    //     icon: null,
    //     isPublic: true,
    //   },
    //   // {
    //   //   key: 'statistics.shipping',
    //   //   name: 'Shipping',
    //   //   path: '/statistics/shipping',
    //   //   isPublic: true,
    //   // },
    //   {
    //     key: 'statistics.tracking',
    //     name: 'Tracking',
    //     path: '/statistics/tracking',
    //     icon: null,
    //     template: 'default',
    //     isPublic: true,
    //   },
    // ],
  },
  {
    key: 'logs',
    name: 'Logs',
    path: '/logs',
    target: '/logs/sync-orders',
    icon: <IconLogs className="iconLog" />,
    isPrimary: true,
    isPublic: true,
    children: [
      {
        key: 'logs.sync-orders',
        name: 'Sync order',
        path: '/logs/sync-orders',
        isPublic: false,
        permissions: ['viewSettingPermission'],
        subInfo: [
          {
            key: 'logs.sync-orders.:id',
            name: 'Sync order log detail',
            path: '/logs/sync-orders/:id',
          },
        ],
      },
      {
        key: 'logs.sync-tracking',
        name: 'Sync tracking',
        path: '/logs/sync-tracking',
        isPublic: false,
        permissions: ['viewSettingPermission'],
        subInfo: [
          {
            key: 'logs.sync-tracking.:id',
            name: 'Sync order log detail',
            path: '/logs/sync-tracking/:id',
          },
        ],
      },
      {
        key: 'logs.import-orders',
        name: 'Import orders',
        path: '/logs/import-orders',
        icon: null,
        isPublic: true,
        subInfo: [
          {
            key: 'logs.import-orders.:id',
            name: 'Import orders logs detail',
            path: '/logs/import-orders/:id',
          },
        ],
      },
      {
        key: 'logs.export-orders',
        name: 'Export orders',
        path: '/logs/export-orders',
        icon: null,
        isPublic: true,
        // subInfo: [
        //   {
        //     key: 'logs.export-orders.:id',
        //     name: 'Export orders logs detail',
        //     path: '/logs/export-orders/:id',
        //   },
        // ],
      },
      {
        key: 'logs.sync-product',
        name: 'Sync product',
        path: '/logs/sync-product',
        icon: null,
        isPublic: true,
        subInfo: [
          {
            key: 'logs.sync-product.:id',
            name: 'Sync product logs detail',
            path: '/logs/sync-product/:id',
          },
        ],
      },
      {
        key: 'logs.feed-product',
        name: 'Feed product',
        path: '/logs/feed-product',
        icon: null,
        isPublic: true,
        subInfo: [
          {
            key: 'logs.feed-product.:id',
            name: 'Feed product logs detail',
            path: '/logs/feed-product/:id',
          },
        ],
      },
    ],
  },
  {
    key: 'settings',
    name: 'Settings',
    path: '/settings/general',
    icon: <IconSetting />,
    isPublic: false,
    isPrimary: true,
    children: [
      {
        key: 'settings.general',
        name: 'General',
        path: '/settings/general',
      },
      {
        key: 'settings.profile',
        name: 'Profile',
        path: '/settings/profile',
      },
      {
        key: 'settings.webhooks',
        name: 'Webhook',
        path: '/settings/webhooks/list',
        virtualPath: ['settings', 'settings.webhooks'],
        subInfo: [
          {
            key: 'settings.webhooks.list',
            name: 'Webhooks',
            path: '/settings/webhooks/list',
            virtualPath: ['settings', 'settings.webhooks.list'],
          },
          {
            key: 'settings.webhooks.create',
            name: 'Create webhook',
            path: '/settings/webhooks/create',
            virtualPath: [
              'settings',
              'settings.webhooks',
              'settings.webhooks.create',
            ],
          },
          {
            key: 'settings.webhooks.:id',
            name: 'Edit webhook',
            path: '/settings/webhooks/:id/edit',
            // virtualPath: ['settings', 'settings.webhooks', 'settings.webhooks.edit'],
            subInfo: [
              {
                key: 'settings.webhooks.:id.edit',
                name: 'Edit webhook',
                path: '/settings/webhooks/:id/edit',
                virtualPath: [
                  'settings',
                  'settings.webhooks',
                  'settings.webhooks.:id',
                ],
              },
            ],
          },
        ],
      },
      {
        key: 'settings.api-information',
        name: 'API Information',
        path: '/settings/api-information',
        // virtualPath: ['settings', 'settings.webhooks', 'settings.webhooks.edit'],
      },
      {
        key: 'settings.shipping-delivery',
        name: 'Shipping & delivery',
        path: '/settings/shipping-delivery',
      },
      {
        key: 'settings.staff-accounts',
        name: 'Staff accounts',
        path: '/settings/staff-accounts',
        subInfo: [
          {
            key: 'settings.staff-accounts.reports',
            name: 'Report staff account',
            path: '/settings/staff-accounts/reports',
          },
          {
            key: 'settings.staff-accounts.create',
            name: 'Invite staff account',
            path: '/settings/staff-accounts/create',
          },
          {
            key: 'settings.staff-accounts.:id',
            name: 'Edit staff',
            path: '/settings/staff-accounts/:id/edit',
            subInfo: [
              {
                key: 'settings.staff-accounts.:id.edit',
                name: 'Edit permission',
                path: '/settings/staff-accounts/:id/edit',
              },
            ],
          },
        ],
      },
      {
        key: 'settings.gift-message',
        name: 'Gift message',
        path: '/settings/gift-message',
        subInfo: [
          {
            key: 'settings.gift-message.list',
            name: 'List',
            path: '/settings/gift-message/list',
          },
          {
            key: 'settings.gift-message.create',
            name: 'Create',
            path: '/settings/gift-message/create',
          },
          {
            key: 'settings.gift-message.:id',
            name: 'List',
            path: '/settings/gift-message/:id/edit',
            subInfo: [
              {
                key: 'settings.gift-message.:id.edit',
                name: 'Edit',
                path: '/settings/gift-message/:id/edit',
              },
            ],
          },
        ],
      },
      {
        key: 'settings.config-buy-tracking',
        name: 'Config buy tracking',
        path: '/settings/config-buy-tracking',
        subInfo: [
          {
            key: 'settings.config-buy-tracking',
            name: 'Config buy tracking',
            path: '/settings/config-buy-tracking',
          },
        ],
      },
    ],
    subInfo: [
      {
        key: 'settings.billing.method',
        name: 'Billing Methods',
        path: '/billing/method',
        subInfo: [
          {
            key: 'settings.billing.method.new',
            name: 'New Billing Method',
            path: '/settings/billing/new',
          },
        ],
      },
      {
        key: 'settings.payment-method',
        name: 'Payment Method',
        path: '/settings/payment-method',
      },
      {
        key: 'settings.billing.legal-info',
        name: 'Legal info',
        path: '/billing/legal-info',
      },
    ],
  },
  {
    key: 'help',
    name: 'Help',
    path: '/help',
    icon: <IconSupport />,
    isPublic: true,
    isPrimary: true,
  },
  {
    hkey: 'products.data-feed',
    name: 'Data feed',
    target: '/data-feed/list',
    isPublic: false,
    isPrimary: false,
    subInfo: [
      {
        hkey: 'data-feed.amazon',
        name: 'Amazon',
        target: '/data-feed/amazon',
      },
      {
        hkey: 'data-feed.Google',
        name: 'google',
        target: '/data-feed/Google',
      },
      {
        hkey: 'data-feed.:id',
        name: 'Amazon',
        path: '/data-feed/:id/amazon',
        subInfo: [
          {
            hkey: 'data-feed.:id.amazon',
            name: 'Amazon',
            path: '/data-feed/:id/amazon',
          },
        ],
      },
      {
        hkey: 'data-feed.:id',
        name: 'Google',
        path: '/data-feed/:id/google',
        subInfo: [
          {
            hkey: 'data-feed.:id.google',
            name: 'Google',
            path: '/data-feed/:id/google',
          },
        ],
      },
    ],
  },
  {
    hkey: 'notification',
    name: 'Notification',
    path: '/notification',
    subInfo: [
      {
        key: 'notification.:id',
        name: 'Notification',
        path: '/notification/:id',
      },
    ],
  },
];
