import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { CounterWrapper } from "./style";

window.TARGET_TIME = moment("09/09/2023 00:00:00", "DD/MM/YYYY hh:mm:ss");
// window.TARGET_TIME = TARGET_TIME;
window.moment = moment;
let timerId = -1;
const timr = (cb) => {
  const now = moment();
  let remainSeconds = window.TARGET_TIME.diff(now, "seconds");
  const remainDays = Math.floor(remainSeconds / 86400);
  remainSeconds = remainSeconds % 86400;
  const remainHours = Math.floor(remainSeconds / 3600);
  remainSeconds = remainSeconds % 3600;
  const remainMinutes = Math.floor(remainSeconds / 60);
  remainSeconds = remainSeconds % 60;
  cb({
    day: (remainDays + "").padStart(2, "0"),
    hour: (remainHours + "").padStart(2, "0"),
    minute: (remainMinutes + "").padStart(2, "0"),
    second: (remainSeconds + "").padStart(2, "0")
  });
  timerId = setTimeout(() => {
    timr(cb);
  }, 1000);
};
export default memo(() => {
  const [time, setTime] = useState({
    day: "00",
    hour: "00",
    minute: "00",
    second: "00"
  });
  useEffect(() => {
    timr(setTime);
    return () => clearTimeout(timerId);
  }, []);
  const { day, hour, minute, second } = time;
  const sDay = day.split("");
  const sHour = hour.split("");
  const sMinute = minute.split("");
  const sSecond = second.split("");
  if (day * 1 <= 0 && hour * 1 <= 0 && minute * 1 <= 0 && second * 1 <= 0) {
    return null;
  }
  const fSDay = day * 1 > 1;
  const fSHour = fSDay || hour * 1 > 1;
  const fSMinute = fSHour || minute * 1 > 1;
  const fSSecond = fSMinute || second * 1 > 1;
  return (
    <CounterWrapper>
      <div className="timer-container">
        <div className="timer-block">
          <div className="timer-block-numbers">
            <div className="timer-number">{sDay[0]}</div>
            <div className="timer-number">{sDay[1]}</div>
          </div>
          Day{fSDay ? "s" : ""}
        </div>
        <div className="timer-separator"></div>
        <div className="timer-block">
          <div className="timer-block-numbers">
            <div className="timer-number">{sHour[0]}</div>
            <div className="timer-number">{sHour[1]}</div>
          </div>
          Hour{fSHour ? "s" : ""}
        </div>
        <div className="timer-separator"></div>
        <div className="timer-block">
          <div className="timer-block-numbers">
            <div className="timer-number">{sMinute[0]}</div>
            <div className="timer-number">{sMinute[1]}</div>
          </div>
          Minute{fSMinute ? "s" : ""}
        </div>
        <div className="timer-separator"></div>
        <div className="timer-block">
          <div className="timer-block-numbers">
            <div className="timer-number">{sSecond[0]}</div>
            <div className="timer-number">{sSecond[1]}</div>
          </div>
          Second{fSSecond ? "s" : ""}
        </div>
      </div>
    </CounterWrapper>
  );
});
