export const validate = (values) => {
    const errors = { listIssues: [] };
    (values?.listIssues || []).forEach((issue, inx) => {
        if (!issue?.fulfillmentId) {
            errors.listIssues[inx] = errors.listIssues[inx] || {};
            errors.listIssues[inx].fulfillmentId = 'Please enter order name.'
        }
        if (!issue?.category) {
            errors.listIssues[inx] = errors.listIssues[inx] || {};
            errors.listIssues[inx].category = 'Please select issue type.'
        }
        if (!issue?.itemEffect || issue?.itemEffect <= 0) {
            errors.listIssues[inx] = errors.listIssues[inx] || {};
            errors.listIssues[inx].itemEffect = 'Value must be equal or greater than 1.'
        }
        if (!issue?.solution) {
            errors.listIssues[inx] = errors.listIssues[inx] || {};
            errors.listIssues[inx].solution = 'Please choose solution.'
        }
    });
    return errors;
};

export const validateUpdate = (values) => {
    const errors = {};
    if (!values?.issueItemAffected || values?.issueItemAffected <= 0) {
        errors.issueItemAffected = 'Value must be equal or greater than 1.'
    }
    return errors;
};

export default validate;