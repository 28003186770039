import {
    actionType as TYPE
} from './actions'

const initialState = {
    creating: false,
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case TYPE.CREATE.REQUEST:
            return {
                ...state,
                creating: true,
            }
        case TYPE.CREATE.SUCCESS:
            return {
                ...state,
                creating: false,
            }
        case TYPE.CREATE.ERROR:
            return {
                ...state,
                creating: false
            }
        default:
            return state
    }
}

export default reducer