import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { verifyEmail, verifyTokenEmail } from './actions';
import Form from './components/form';
import GMHelmet from 'components/GMHelmet';
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyEmailToken: this.props.match.params.token,

    };
  }

  componentDidMount() {
    this.props.verifyTokenEmail({
      verifyEmailToken: this.props.match.params.token,
    });
  }
  render() {
    const {
      loading,
      confirmEmail,
      success,
      loadingVerify,
      responseError,
    } = this.props;
    return (
      <>
        <GMHelmet />
        <div className="bg-white w-100">
          <Spin spinning={loading}>
            <Form
              loading={loading}
              confirmEmail={confirmEmail}
              responseError={responseError}
              successResendEmail={success}
              loadingVerify={loadingVerify}
              resendData={this.state.verifyEmailToken}

            />
          </Spin>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.auth.verifyEmail.loading,
    confirmEmail: state.auth.verifyEmail.confirmEmail,
    success: state.auth.verifyEmail.success,
    loadingVerify: state.auth.verifyEmail.loadingResentEmail,
    responseError: state.auth.verifyEmail.responseError,
  }),
  (dispatch) => ({
    verifyEmail: (data) => {
      dispatch(verifyEmail(data));
    },
    verifyTokenEmail: (data) => {
      dispatch(verifyTokenEmail(data));
    },
  })
)(Index);
