import {
  BASE_WHITE,
  ERROR_500,
  ERROR_700,
  GRAY_100,
  GRAY_400,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_200,
  PRIMARY_25,
  PRIMARY_300,
  PRIMARY_50,
  PRIMARY_500,
  PRIMARY_600,
  PRIMARY_700,
} from 'components/colors';
import { BREAKPOINTS } from 'components/const';
import styled from 'styled-components';

export default styled.div`
  .processing-fee {
    svg {
      width: 16px;
      height: 16px;
      stroke: ${GRAY_500};
    }
  }
  .new-account {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      stroke: ${PRIMARY_500};
      margin-right: 4px;
      color: ${PRIMARY_600};
      font-weight: 600;
      font-size: 16px;
    }
    .button-new-account {
      padding: 10px 18px;
      gap: 8px;
      width: 173px;
      height: 44px;
      max-width: 100%;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      color: ${GRAY_500};
      background: transparent;
      display: flex;
      align-items: center;
      padding: 8px;
      svg {
        stroke: ${GRAY_500};
      }
      &:hover {
        color: ${PRIMARY_500};
        svg {
          stroke: ${PRIMARY_500};
        }
      }
    }
  }
  .group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 44px;
  }
  .input {
    span.icon-before {
      color: black;
      font-size: 14px;
    }
    &.has-icon-before input {
      padding-left: 24px;
    }
  }
  .wallet-balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;

    /* width: 672px; */
    height: 60px;
    background: #f2f4f7;
    border-radius: 12px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    /* max-width: 100%; */
  }
  .instant {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: ${GRAY_700};
    /* width: 100%; */
  }
  .payment-method {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 8px;
    gap: 8px;
    width: 153.67px;
    height: 52px;
    background: ${GRAY_100};
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    &.checked {
      border: 1px solid #169dd8;
    }
  }
  .payment-instant {
    /* width: 672px;
    max-width: 100%; */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }

  .payment-method-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    /* max-width: 100%; */
    display: none;
  }
  .payment-input {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    width: 180px;
    max-width: 100%;
    @media (min-width: ${BREAKPOINTS.lg}) {
      width: 328px;
    }
  }
  .payment-input-amount {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    width: 180px;
    max-width: 100%;
    @media (min-width: ${BREAKPOINTS.lg}) {
      width: 328px;
    }
  }
  .button {
    /* width: 173px; */
    height: 44px;
    /* max-width: 100%; */
  }

  .paypal-account {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    width: 200px;
    max-width: 100%;
    @media (min-width: ${BREAKPOINTS.lg}) {
      width: 483px;
    }
  }

  .amount-3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .amount {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;
    height: 22px;

    background: #f2f4f7;
    mix-blend-mode: multiply;
    border-radius: 16px;
    font-size: 12px;
  }
  .next-button {
    width: 370px;
    max-width: 100%;
    margin-top: 12px;
    @media (min-width: ${BREAKPOINTS.lg}) {
      width: 672px;
    }
  }
  .upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    width: 370px;
    max-width: 100%;
    max-height: 100vh;
    background: ${BASE_WHITE};
    border: 1px dashed #eaecf0;
    border-radius: 12px;
    @media (min-width: ${BREAKPOINTS.lg}) {
      width: 672px;
    }
  }

  .upload-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    width: 370px;
    max-width: 100%;
    max-height: 100vh;
    background: #ffffff;
    border-radius: 12px;
    @media (min-width: ${BREAKPOINTS.lg}) {
      width: 672px;
    }
  }
  .upload-success {
    border: 1px dashed #eaecf0;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    padding: 8px;
    height: 100px;
  }

  .text-upload {
    font-size: 14px;
  }
  .click-to {
    color: ${PRIMARY_500};
  }
  .input#3 {
    box-sizing: border-box;

    width: 20px;
    height: 20px;
    background: #ffffff;

    border: 1px solid #d0d5dd;
    border-radius: 10px;
  }
  .policy {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 16px;
    color: #27272a;
    .term {
      color: ${PRIMARY_500};
    }
  }
  .preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;
    /* width: 672px;
    max-width: 100%; */
    background: #f2f4f7;
    border-radius: 8px;
    .item {
      display: flex;
      justify-content: space-between;
      .value {
        color: #27272a;
      }
    }
    .total {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      text-align: right;
      color: #101828;
    }
  }
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    color: #101828;
    /* width: 720px; */
  }
  .back {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    color: #475467;
    svg {
      width: 20px;
      height: 20px;
      stroke: ${GRAY_600};
      margin-right: 12px;
    }
  }
  .upload-success {
    border: 1px dashed #eaecf0;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    padding: 8px;
    height: 100px;
  }
  .validate-style {
    margin-top: 6px;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${ERROR_500};
    margin-top: 20px;
  }
  .noti-margin {
    line-height: 20px;
    /* max-width: 100%;
    width: 672px; */
    margin-top: 24px;
    margin-bottom: 24px;

    .title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 4px;
      color: ${ERROR_700};
    }
    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${ERROR_700};
    }
  }
  .noti {
    line-height: 20px;
    /* max-width: 100%;
    width: 672px; */
    margin-top: 24px;
    margin-bottom: 24px;
    background: ${PRIMARY_25};
    border: 1px solid ${PRIMARY_200};
    border-radius: 12px;
    word-wrap: break-word;
    .title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 4px;
      color: ${PRIMARY_700};
    }
    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${PRIMARY_700};
    }
    svg {
      stroke: ${PRIMARY_600} !important;
    }
  }
  .processing {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 16px;
    background: ${GRAY_100};
    border-radius: 8px;
    .text {
      font-weight: 400;
      font-size: 16px;
      color: ${GRAY_400};
    }
    .num {
      font-weight: 400;
      font-size: 16px;
      color: ${GRAY_900};
    }
  }
  .term {
    font-weight: 400;
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 24px;
    color: ${GRAY_600};
  }
  /* .noti-warning {
    width: 672px;
  } */
`;

export const ImageUploadWrapper = styled.div`
  &.uploaded {
    .ant-upload.ant-upload-select-picture-card {
      border: none;
      background-color: transparent;
    }
  }
  .upload-success {
    border: 1px dashed #eaecf0;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    padding: 8px;
    height: 100px;
  }
`;

export const Notify = styled.div`
  .notify {
    width: 672px;
  }
`;
