import { combineReducers } from 'redux';
import list from './scenes/List/reducer';
import edit from './scenes/Edit/reducer';
import create from './scenes/Create/reducer';
import bulk from './scenes/Bulk/reducer';
import clone from './scenes/Clone/reducer';
import sync from './scenes/Sync/reducer';
import preview from './scenes/Preview/reducer';
import export_product from './scenes/ExportFeedProduct/reducer';
import export_product_detail from './scenes/ExportFeedProductDetail/reducer';
import feed_form_amazon from "./scenes/FeedAmazonData/reducer";
import feed_form_google from "./scenes/FeedGoogleData/reducer";
import feed_custom_amazon from "./scenes/FeedCustomAamazon/reducer";
import DataFeedConfigurationReducer from "./scenes/DataFeedConfiguration/reducer";
const reducer = combineReducers({
    list,
    create,
    edit,
    bulk,
    clone,
    sync,
    preview,
    export_product,
    export_product_detail,
    feed_form_amazon,
    feed_form_google,
    feed_custom_amazon,
    DataFeedConfigurationReducer,
})

export default reducer