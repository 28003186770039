import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { register } from '../Register/actions';
import Form from './components/form';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: '',
      registerType: '',
      email: '',
      name: '',
    };
  }
  handleOnSubmitRegister = (values) => {
    this.props.register({
      ...values,
      registerType: this.state.registerType,
    });
  };

  componentDidMount() {
    let state = { ...this.props.history.location.state };
    let { accessToken = '', loginType = '', email = '', name = '' } = state;
    if (!accessToken || !loginType || !email || !name) {
      this.props.history.push('/auth/login');
    } else {
      this.setState({
        accessToken,
        registerType: loginType,
        email,
        name,
      });
    }
  }

  render() {
    const { loading } = this.props;
    return (
      <div className="bg-white w-100">
        <Spin spinning={loading}>
          <Form
            initialValues={{
              email: this.state.email,
              firstName: this.state.name,
              lastName: '',
            }}
            loginType={this.state.registerType}
            onSubmit={this.handleOnSubmitRegister}
          />
        </Spin>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.auth.register.register.loading,
  }),
  (dispatch) => ({
    register: (post) => {
      dispatch(register(post));
    },
  })
)(Index);
