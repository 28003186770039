import React from 'react';
import CollectionList from './scenes/List'
import CollectionCreate from './scenes/Create'
import CollectionEdit from './scenes/Edit'
import icon from 'images/discount.svg'

export default [
    {
        key: 'customer-collection',
        name: 'Collections',
        component: CollectionList,
        path: '/customer-collection',
        template: 'default',  
        icon: <img src={icon} height={21} alt="icon"/>,

    },
    {
        key: 'customer-collection.create',
        name: 'Collections',
        component: CollectionCreate,
        path: '/customer-collection/create',
        template: 'default',  
    },
    {
        key: 'customer-collection.edit',
        name: 'Collections',
        component: CollectionEdit,
        path: '/customer-collection/:id/edit',
        template: 'default',  
    },
]