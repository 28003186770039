import React from "react";
import { Collapse, Col, Affix } from "antd";
import {
  Field,
  reduxForm,
  submit,
  change,
} from "redux-form";
import { connect } from "react-redux";
import {
  option_status_tracking,
  option_platform_tracking,
} from "utils/options";
import FieldCustomRadioGroup from "utils/fields/FieldCustomRadioGroup";
import { renderInputText } from "utils/Form";
import RenderTagFilter from "utils/Form/RenderTagFilter";
import RenderAdvancedFilterButtons from "utils/Form/RenderAdvancedFilterButtons";
import { clearFilter } from "utils/helper";
import { FilterWrapper } from "scenes/Orders/scenes/List/styled";
import GMRow from "components/GMLayout/GMRow";

const { Panel } = Collapse;
const paramsName = {
  status: "Status",
  name: "Tracking Number",
  carrierName: "Carrier Name"
};
const paramsValueAlias = {
  status: {
    "unknown": "unknown",
    "pre_transit": "pre_transit",
    "in_transit": "in_transit",
    "out_of_delivery": "out_of_delivery",
    "failure": "failure",
    "delivered": "delivered",
    "all": "all"
  },
  carrierName: {
    "DHLGlobalMail": "DHLGlobalMail",
    "DHLGlobalmailInternational": "DHLGlobalmailInternational",
    "USPS": "USPS"
  }
};
const AdvancedFilter = (props) => {
  const { handleSubmit, trackings, changeFieldValue} = props;
  return (
    <FilterWrapper>
      <form onSubmit={handleSubmit}>
        <RenderTagFilter
          params={trackings.params}
          paramsName={paramsName}
          paramsValueAlias={paramsValueAlias}
        />
        <div className="p-3">
          <Collapse
            defaultActiveKey={["1", "3", "4", "5"]}
            bordered={true}
            ghost
            expandIconPosition="right"
          >
            <Panel
              header={<b className="header-filter">Tracking number</b>}
              key="1"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="name"
                    component={renderInputText}
                    size="large"
                    placeholder="Search tracking number"
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Carrier</b>} key="3">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="carrierName"
                    component={FieldCustomRadioGroup}
                    placeholder="All carrier"
                    options={option_platform_tracking}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel
              header={<b className="header-filter">Tracking status</b>}
              key="4"
            >
              <GMRow>
                <Col span={24}>
                  <Field
                    name="status"
                    component={FieldCustomRadioGroup}
                    placeholder="Tracking status"
                    options={option_status_tracking}
                  />
                </Col>
              </GMRow>
            </Panel>
            <Panel header={<b className="header-filter">Order by</b>} key="5">
              <GMRow>
                <Col span={24}>
                  <Field
                    name="sortType"
                    component={FieldCustomRadioGroup}
                    placeholder="Order by"
                    options={[
                      {
                        value: "asc",
                        label: <span>Ascending</span>,
                      },
                      {
                        value: "desc",
                        label: <span>Descending</span>,
                      },
                    ]}
                  />
                </Col>
              </GMRow>
            </Panel>
          </Collapse>
        </div>
      </form>
      <Affix offsetBottom={0}>
        <RenderAdvancedFilterButtons
          handleOnClearFilters={() => {
            clearFilter(changeFieldValue, {
              sortBy: "id",
              sortType: "desc",
              status: "all",
              carrierName: "all",
              name: ""
            });
            setTimeout(() => props.dispatch(submit("AdvancedFilterTracking")), 1000);
          }}
          handleOnSubmit={() => {
            props.dispatch(submit("AdvancedFilterTracking"));
            props.onCloseAdvancedSearch();
          }}
        />
      </Affix>
    </FilterWrapper>
  );
};

const AdvancedFilterReduxForm = reduxForm({
  form: "AdvancedFilterTracking",
  enableReinitialize: true,
  //validate,
})(AdvancedFilter);

const mapStateToProps = (state) => ({
  AdvancedFilterTracking: state.form.AdvancedFilterTracking?.values || {},
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change("AdvancedFilterTracking", field, value));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedFilterReduxForm);