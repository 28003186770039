import React, { useState } from "react";
import { Modal, Table, Tag } from "antd";
import getMomentInstance from "utils/moment";
import { Link } from "react-router-dom";
import { listDuplicatedOrder } from "apis/orders";
function RenderDuplicatedOrders ({ orderId = 0 }){
  const [listDuplicatedOrders, setListDuplicatedOrders] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOnShowListDuplicatedOrders = () => {
    setVisible(true);
    setLoading(true);
    listDuplicatedOrder(orderId)
      .then(({ code, data }) => {
        setLoading(false);
        if (code === "success") {
          setListDuplicatedOrders(data|| []);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const columns = [
    {
      title: <b>ORDER NAME</b>,
      dataIndex: "orderDupId",
      key: "orderDupId",
      render: (text, record) => {
        return (
          <Link to={`/orders/${text}`} target="_blank">
            {record.orderDupName}
          </Link>
        );
      },
    },
    {
      title: <b>CREATE TIME</b>,
      dataIndex: "createdAt",
      key: "createdAt ",
      render: (text, record) => {
        return getMomentInstance(text, null, true);
      },
    },
  ];
  return null;
  return (
    <>
      <Tag
        color="#f50"
        className="cursor-pointer"
        onClick={() => handleOnShowListDuplicatedOrders()}
      >
        List duplicated order
      </Tag>
      <Modal
        footer={null}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={800}
      >
        <Table
          pagination={false}
          rowKey="id"
          columns={columns}
          loading={loading}
          dataSource={listDuplicatedOrders}
        />
      </Modal>
    </>
  );
};
export default React.memo(RenderDuplicatedOrders);