import React from "react";
import { Field } from "redux-form";
import { Divider, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import renderInputText from "./renderInputText";
import renderSelectAnt from "./renderSelectAnt";
import { options_condition, options_condition_field } from "utils/options";
const renderConditions = ({ fields, meta: { error } }) => {
  return (
    <div>
      <ul className="list-unstyled mb-0">
        {fields.map((condition, index) => (
          <li key={index}>
            <div className="row pb-3">
              <div className="col-12">
                <Divider className="mt-0 mb-3" />
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <div className="w-100">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <Field
                            name={`${condition}.field`}
                            component={renderSelectAnt}
                            options={options_condition_field}
                            placeholder="-- Select Product Title --"
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <Field
                            name={`${condition}.operator`}
                            component={renderSelectAnt}
                            options={options_condition}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <Field
                          name={`${condition}.value`}
                          type="text"
                          placeholder="Value"
                          component={renderInputText}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => fields.splice(index, 1)}
                      type="button"
                      className="ml-2 btn btn-secondary"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <Divider className="my-0" />
      <Button
        type="link"
        className={classNames("d-flex align-items-center", {
          "text-info": !error,
          "text-danger": error,
        })}
        onClick={() => fields.push({ field: "title", operator: "equals" })}
      >
        <PlusCircleOutlined style={{ fontSize: 20 }} /> Add another condition
      </Button>
    </div>
  );
};

export default renderConditions;
