import styled from "styled-components";
import PageContainer from "components/PageContainer";
import {
  BASE_WHITE, GRAY_200, GRAY_600, GRAY_900, PRIMARY_500, SHADOW_SM_0_06, SHADOW_SM_0_1, WARNING_25, WARNING_300, WARNING_600, WARNING_700,
} from "components/colors";

export const Wrapper = styled(PageContainer)`
  .inner-wrapper {
    width: 100%;
    display: flex;
    gap: 24px;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 24px;
    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: ${GRAY_900};
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      span {
        font-weight: 600;
      }
      .sub {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${GRAY_600};
      }
    }
  }
  table td {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_600};
    max-width: 150px;
    .bolder {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_900};
    }
  }
  table tr {
    &:last-child td {
      border: none;
    }
  }
  .block {
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_200};
    box-shadow: 0px 1px 3px ${SHADOW_SM_0_1}, 0px 1px 2px ${SHADOW_SM_0_06};
    border-radius: 16px;
    width: 100%;
    overflow: hidden;
    padding: 24px;
    padding-bottom: 0;
    .billing-visual {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        line-height: 20px;
      }
    }
    .sum {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid ${GRAY_200};
      border-bottom: 1px solid ${GRAY_200};
      height: 62px;
      margin-bottom: 16px;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${GRAY_600};
      }
      .value {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: ${PRIMARY_500};
      }
    }
    button {
      width: 100%;
    }
  }
  .col {
    &-left {
      width: calc(100% - 400px - 24px);
    }
    &-right {
      width: 400px;
      .block {
        padding-bottom: 24px;
        .warning {
          margin-top: 12px;
          margin-bottom: 0;

          &.nogap {
            margin: 0;
          }
        }
      }
    }
    &-left, &-right {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
  .warning {
    background: ${WARNING_25};
    border: 1px solid ${WARNING_300};
    border-radius: 12px;
    padding: 18px;
    padding-left: 48px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${WARNING_700};

    position: relative;

    margin-bottom: 20px;

    a {
      color: ${PRIMARY_500};
      font-weight: 600;
    }

    svg {
      stroke: ${WARNING_600};
      position: absolute;
      top: 18px;
      left: 18px;
      width: 18px;
      height: 18px;
    }
  }
`;
