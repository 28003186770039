import { actionType as TYPE } from "./actions";
import { notification } from "antd";
const initialState = {
  loading: false,
  data: [],
  previewData: {},
};

function CloneCampaignReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.PREVIEW_CLONE.REQUEST:
      return {
        ...state,
        loading: true,
        requestData: action.dataToPreviewCampaign,
      };
    case TYPE.PREVIEW_CLONE.SUCCESS:
      return {
        ...state,
        loading: false,
        previewData: action.data,
      };
    case TYPE.PREVIEW_CLONE.ERROR:
      notification.warn({
        message: action?.error?.[0]?.["msg"] || "Preview product failed",
      });
      return {
        ...state,
        loading: false,
      };
    case TYPE.LIST_PRODUCT_TO_CLONE.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.LIST_PRODUCT_TO_CLONE.SUCCESS:
      return {
        ...state,
        data:
          action.data && action.data.productCampaigns
            ? action.data.productCampaigns
            : [],
        loading: false,
      };
    case TYPE.LIST_PRODUCT_TO_CLONE.ERROR:
      notification.warn({ message: "Can't use this product for duplicate" });
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default CloneCampaignReducer;
