import { isInteger } from 'lodash';
const validate = (values) => {
  const errors = {};
  if (
    !values.cusAddress ||
    values.cusAddress.replace(/\s+/g, '').length === 0
  ) {
    errors.cusAddress = 'Please enter address 1.';
  }
  if (values?.iossNumber && !values?.iossNumberTotal) {
    errors.iossNumberTotal = 'Please enter order value';
  }
  if (values?.buyerTaxNumber && !values?.buyerTaxAmount) {
    errors.buyerTaxAmount = 'Please enter order tax value';
  }
  if (values?.cusProvince) {
    errors.state = 'Please enter state/province.';
  }
  if (
    parseInt(values.cusCountry) === 231 &&
    (!values.cusProvince || values.cusProvince.replace(/\s+/g, '').length === 0)
  ) {
    errors.cusProvince = 'Please enter state/province.';
  }

  if (!values?.cusName) {
    errors.cusName = 'Please enter the full name';
  }
  if (!values.cusCity || values.cusCity.replace(/\s+/g, '').length === 0) {
    errors.cusCity = 'Please enter city.';
  }
  if (
    !values.cusPostalCode ||
    values.cusPostalCode.replace(/\s+/g, '').length === 0
  ) {
    errors.cusPostalCode = 'Please enter zip code/postal code.';
  }
  if (
    isInteger(parseInt(values.cusCountry)) &&
    parseInt(values.cusCountry) === 231
  ) {
    if (
      !values.cusProvince ||
      values.cusProvince.replace(/\s+/g, '').length === 0
    ) {
      errors.cusProvince = 'Please enter state/province.';
    }
  }

  return errors;
};

export default validate;
