import {
    takeLatest,
    call,
    put,
    all
} from 'redux-saga/effects'

import {
    actionType as TYPE
} from './actions'

import {getInvoice} from 'apis/orders'
import {replace} from "connected-react-router";


function* getInvoiceSaga(action){
    const {id} = action;
    try {
        const response = yield call(getInvoice, id)
        if(response.code === 'success'){
            yield all([
                put({ type: TYPE.INVOICE.SUCCESS, ...response }),
            ])
        }else{
            yield all([
                yield put({ type: TYPE.INVOICE.ERROR, error: {message: 'Not found invoice'} }),
                yield put(replace(`/orders`))
            ])
        }
    } catch(error){
        yield all([
            yield put({ type: TYPE.INVOICE.ERROR, error })
        ])
    }
}

function* watcher(){
    yield takeLatest(TYPE.INVOICE.REQUEST, getInvoiceSaga);

}

export default watcher