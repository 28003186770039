import { GRAY_300, GRAY_400, GRAY_500, SHADOW_XS } from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  svg {
    width: 20px;
    height: 20px;
    stroke: ${GRAY_500};
  }
`;

export const FilterWrapper = styled.div`
  /* float: right; */
  .id-search {
    width: 100%;
    max-width: 320px;
  }
  input {
    font-size: 14px;
  }
  .icon-after {
    cursor: pointer;
  }
  .input span.icon-after svg.clear {
    stroke: ${GRAY_400};
  }
  .date-range {
    button {
      height: 44px;
      line-height: 44px;
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      border-radius: 8px;
      padding: 0 16px;
      span {
        line-height: 44px;
        float: left;
      }
    }
    svg {
      margin-top: -5px;
    }
  }
`;
