export const actionType = {
  LIST_ARCHIVED_ORDER: {
    REQUEST: "ARCHIVED_ORDER.LIST.REQUEST",
    SUCCESS: "ARCHIVED_ORDER.LIST.SUCCESS",
    ERROR: "ARCHIVED_ORDER.LIST.ERROR",
  },
  DELETE_ARCHIVED_ORDER: {
    REQUEST: "ARCHIVED_ORDER.DELETE.REQUEST",
    SUCCESS: "ARCHIVED_ORDER.DELETE.SUCCESS",
    ERROR: "ARCHIVED_ORDER.DELETE.ERROR",
  },
  UNARCHIVE_ARCHIVED_ORDER: {
    REQUEST: "ARCHIVED_ORDER.UNARCHIVE.REQUEST",
    SUCCESS: "ARCHIVED_ORDER.UNARCHIVE.SUCCESS",
    ERROR: "ARCHIVED_ORDER.UNARCHIVE.ERROR",
  },
  COUNT_ORDER_ACHIVED: {
    REQUEST: "ORDER_ACHIVED.COUNT.REQUEST",
    SUCCESS: "ORDER_ACHIVED.COUNT.SUCCESS",
    ERROR: "ORDER_ACHIVED.COUNT.FAIL",
  },
  EXPORT: {
    REQUEST: 'ORDER_ARCHIVE.EXPORT.REQUEST',
    SUCCESS: 'ORDER_ARCHIVE.EXPORT.SUCCESS',
    ERROR: 'ORDER_ARCHIVE.EXPORT.ERROR',
  },
};

export function getListOrderAchived(params) {
  return {
    type: actionType.LIST_ARCHIVED_ORDER.REQUEST,
    params,
  };
}

export function deleteOrder(data) {
  return {
    type: actionType.DELETE_ARCHIVED_ORDER.REQUEST,
    data,
  };
}

export function unarchivedOrder(data) {
  return {
    type: actionType.UNARCHIVE_ARCHIVED_ORDER.REQUEST,
    data,
  };
}

export function getcountOrderAchived(params) {
  return {
    type: actionType.COUNT_ORDER_ACHIVED.REQUEST,
    params,
  };
}

export function exportArchivedOrders(params){
  return {
      type: actionType.EXPORT.REQUEST,
      params
  }
}