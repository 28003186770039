export const actionType = {
  LIST: {
    REQUEST: 'ORDER.MANUAL_PAYMENT.LIST.REQUEST',
    SUCCESS: 'ORDER.MANUAL_PAYMENT.LIST.SUCCESS',
    ERROR: 'ORDER.MANUAL_PAYMENT.LIST.ERROR',
  },
  DETAIL: {
    REQUEST: 'ORDER.MANUAL_PAYMENT.DETAIL.REQUEST',
    SUCCESS: 'ORDER.MANUAL_PAYMENT.DETAIL.SUCCESS',
    ERROR: 'ORDER.MANUAL_PAYMENT.DETAIL.ERROR',
  },
  LIST_TOPUP: {
    REQUEST: 'PAYMENT.LIST_TOPUP.REQUEST',
    SUCCESS: 'PAYMENT.LIST_TOPUP.SUCCESS',
    ERROR: 'PAYMENT.LIST_TOPUP.ERROR',
  },
};

export function getList(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}

export function getListTopup(params) {
  return {
    type: actionType.LIST_TOPUP.REQUEST,
    params,
  };
}

export function getDetail(id) {
  return {
    type: actionType.DETAIL.REQUEST,
    id,
  };
}
