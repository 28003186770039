import { 
    takeLatest, 
    call, 
    put, 
    all 
  } from 'redux-saga/effects'
  import { replace } from 'connected-react-router'
  import {
      actionType as TYPE
  } from './actions'
  
  import axios from 'utils/request'
  
  function createAPI(params) {
      return axios({
          method: 'POST',
          data: params,
          url: `/customer-collections`
      })
  }
  
  function* createSaga(action) {
      try {
          const { params } = action
          const response = yield call(createAPI, params)
          
          if(response.code === 'success'){
              yield all([
                  put({type: TYPE.CREATE.SUCCESS, response}),
                  put(replace('/customer_collection'))
              ])
          }else{
            yield put({type: TYPE.CREATE.ERROR, response})
          }
      } catch (error) {
          yield all([
              put({type: TYPE.CREATE.ERROR, error})
          ])
      }
  }
  
  function* watcher() {
      yield all([
          takeLatest(TYPE.CREATE.REQUEST, createSaga),
      ])
  }
  
  export default watcher