import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import ui from './ui/reducer';
import auth from './scenes/Auth/reducer';
import user from './scenes/Users/reducer';
import campaign from './scenes/Campaigns/reducer';
import mockup from './scenes/Mockups/reducer';
import promotion from './scenes/Promotions/reducer';
import collection from './scenes/Collections/reducer';
import sync_product from './scenes/SyncProducts/reducer';
import order from './scenes/Orders/reducer';
import order_tracking from './scenes/OrderTracking/reducer';
import transaction from './scenes/Transactions/reducer';
import data_feed from './scenes/DataFeed/reducer';
import notification from './scenes/Notifications/reducer';
import notification_shipping from './scenes/NotificationsShipping/reducer';
import store from './scenes/Stores/reducer';
import setting from './scenes/Settings/reducer';
import analytic from './scenes/Analytics/reducer';
import general from './scenes/General/reducer';
import webhooks from './scenes/Webhooks/reducer';
import userlabel from './scenes/Userlabel/reducer';
import gift_message from './scenes/GiftMessage/reducer';
import image_library from './scenes/ImageLibrary/reducer';
import profit from './scenes/Profits/reducer';
import invoice from './scenes/Invoice/reducer';
import shipping from './scenes/Shipping/reducer';
import apiCre from './scenes/ApiCredential/reducer';
import topup from './scenes/TopUp/reducer';
import billingPaymentAccount from './scenes/BillingPaymentAccount/reducer';
import logs from './scenes/Logs/reducer';
import dashboard from './scenes/Dashboard/reducer';
import config_buy_tracking from './scenes/ConfigBuyTracking/reducer';
import indemnification from './scenes/Indemnification/reducer';


const Reducer = (history) =>
  combineReducers({
    form: formReducer,
    router: connectRouter(history),
    ui,
    auth,
    user,
    campaign,
    mockup,
    promotion,
    collection,
    sync_product,
    order,
    profit,
    order_tracking,
    store,
    transaction,
    data_feed,
    setting,
    analytic,
    shipping,
    notification,
    notification_shipping,
    webhooks,
    userlabel,
    gift_message,
    image_library,
    invoice,
    billingPaymentAccount,
    general,
    apiCre,
    topup,
    logs,
    dashboard,
    config_buy_tracking,
    indemnification
  });

export default Reducer;
