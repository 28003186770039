import axios from "axios";
import queryString from "query-string";
import { API_TOKEN_KEY, WS_API } from "../web.config";
import { getCookie, getClientIp } from "./helper";

const instance = axios.create({
  baseURL: WS_API,
  timeout: 1500000,
  // crossDomain: true,
  // withCredentials: true,
  validateStatus: (status) => {
    return true; // I'm always returning true, you may want to do it depending on the status received
  },
});
instance.interceptors.request.use(
  function (config) {
    if (getCookie(API_TOKEN_KEY) /* auth cookie */) {
      config.headers.common["Authorization"] = `Bearer ${getCookie(API_TOKEN_KEY)}`;
    }
    config.headers['FE-Client-Ip-Address'] = getClientIp();
    // config.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000'
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default ({
  method,
  data = {},
  headers = {},
  is_json_content_type = false,
  ...options
}) => {
  let options_format = {
    method,
    ...options,
  };
  if (
    method.toLowerCase() === "post" ||
    method.toLowerCase() === "put" ||
    is_json_content_type
  ) {
    options_format.headers = {
      "Content-Type": "application/json",
      ...headers,
    };
    options_format.data = data;
  }
  if (method.toLowerCase() === "get" || method.toLowerCase() === "delete") {
    options_format.headers = {
      ...headers,
    };
  } else if (method.toLowerCase() === "post" && !is_json_content_type) {
    options_format.headers = {
      "Content-Type": "multipart/form-data",
      ...headers,
    };
    options_format.data = new FormData();
    for (let key in data) {
      options_format.data.append(key, data[key]);
    }
  } else if (method.toLowerCase() === "put" && !is_json_content_type) {
    options_format.headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      ...headers,
    };
    options_format.data = queryString.stringify(data);
  }
  const promise = new Promise((resolve, reject) => {
    instance(options_format)
      .then((response) => resolve(response.data))
      .catch((error) => {
        if (options_format.url === "/auth/login") {
          resolve({
            code: "timeout",
          });
        }
        throw error;
      });
  });
  return promise;
};
