import { isInteger } from 'lodash';

const validate = (values) => {
  const errors = {};
  if (!values.listOrderItemsToMatch || !values.listOrderItemsToMatch.length) {
    errors.listOrderItemsToMatch = { _error: true };
  } else {
    const conditionArrayErrors = [];
    for (let index = 0; index < values.listOrderItemsToMatch.length; index++) {
      const element = values.listOrderItemsToMatch[index];
      const element1 = values.listOrdersToMatch[index];
      const order = (values?.listOrdersToMatch || []).find(
        (order) => parseInt(order.id) === parseInt(element.orderId)
      );
      const conditionErrors = {};
      if (parseInt(element.expId) === 0) {
        conditionErrors.expId = 'Please select a product.';
      }
      if (parseInt(element.color) === 0) {
        conditionErrors.color = 'Please select a color.';
      }
      if (parseInt(element.size) === 0) {
        conditionErrors.size = 'Please select a size.';
      }
      if (!element.printSide2) {
        conditionErrors.printSide2 = 'Please select a placement.';
      }

      //Quantity
      const prodId = parseInt(element?.expId);
      const quantity = parseInt(element.quantity);

      if (element1?.platformId === 9) {
        if (quantity <= 3000 && quantity >= 1) {
          if (prodId === 149 || prodId === 150 || prodId === 63) {
            if ((prodId === 149 || prodId === 150) && quantity % 9 !== 0) {
              conditionErrors.quantity =
                'Please enter a number between 9 and 2997 that is divisible by 9.';
            } else if (prodId === 63 && quantity % 15 !== 0) {
              conditionErrors.quantity =
                'Please enter a number between 15 and 3000 that is divisible by 15.';
            }
          } else if (quantity % 30 !== 0) {
            conditionErrors.quantity =
              'Please enter a number between 30 and 3000 that is divisible by 30.';
          }
        } else {
          conditionErrors.quantity =
            'Please enter a number less than or equal to 3000.';
        }
      } else if (
        !quantity ||
        isNaN(quantity) ||
        quantity < 1 ||
        quantity > 1000
      ) {
        conditionErrors.quantity = 'Please enter a number between 1 and 100.';
      }
      //
      if (!element.frontDesignUrl && !element.backDesignUrl) {
        conditionErrors.frontDesignUrl = 'Please upload design.';
      }
      conditionArrayErrors[index] = conditionErrors;
    }
    if (conditionArrayErrors.length) {
      errors.listOrderItemsToMatch = conditionArrayErrors;
    }
    // check the same type of product type
    values.listOrderItemsToMatch = values?.listOrderItemsToMatch.map((v) => {
      if (!v?.productType) {
        v.productType = v?.exProduct?.type;
      }
      return v;
    });
    const orderGroup = {};
    (values?.listOrderItemsToMatch || []).forEach((v, i) => {
      orderGroup[v.orderName] = orderGroup[v.orderName] || {};
      orderGroup[v.orderName][v?.productType] = 'x';
    });

    // check chỉ với đơn label
    (values?.listOrderItemsToMatch || []).forEach((v, i) => {
      const oNameObj = orderGroup[v.orderName];
      const count = Object.keys(oNameObj || {}).length;
      const order = (values.listOrdersToMatch || []).find(
        (o) => o.id === v.orderId
      );
      const shippingMethod = !order?.orderBarcodeFiles?.[0]?.barcodeUrl
        ? order?.shippingMethod
        : 4;
      if (
        order &&
        order?.shippingLabel &&
        [4, 5].indexOf(parseInt(shippingMethod)) !== -1
      ) {
        if (count > 1) {
          errors.listOrderItemsToMatch[i].expId =
            'The orders must be the same type of product';
        }
      }
    });
  }
  return errors;
};

export default validate;
