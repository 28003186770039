import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { renderInputPassword, renderInputText } from "utils/Form";
import { isValidEmail } from "utils/helper";
const validate = (values) => {
  const errors = {};
  if (!values.newEmail) {
    errors.newEmail = "Please enter your new email";
  } else if (!isValidEmail(values.newEmail)) {
    errors.newEmail = "Your new email is invalid";
  }
  if (!values.email) {
    errors.email = "Please enter your current email";
  } else if (!isValidEmail(values.email)) {
    errors.email = "Your current email is invalid";
  }
  if (!values.password) {
    errors.password = "Please enter your password.";
  }
  return errors;
};

const FormChangeEmail = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label className="font-weight-bold text-dark">New Email</label>
        <Field
          name="newEmail"
          component={renderInputText}
          placeholder="New Email"
        />
      </div>
      <div className="form-group">
        <label className="font-weight-bold text-dark">Email</label>
        <Field name="email" component={renderInputText} placeholder="Email" />
      </div>
      <div className="form-group">
        <label className="font-weight-bold text-dark">Password</label>
        <Field
          name="password"
          component={renderInputPassword}
          placeholder="Passsword"
        />
      </div>
      <div className="form-group text-right">
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </div>
    </form>
  );
};

FormChangeEmail.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change("FormChangeEmail", field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "FormChangeEmail",
    validate,
    enableReinitialize: true,
  })(FormChangeEmail)
);
