import React, { Fragment } from 'react';
import { withSize } from 'react-sizeme'
import { ResponsiveLine } from '@nivo/line'
import { Space, Empty } from 'antd';

// import { digits } from '@components/utils'

const index = props => {
    const { loading, data, title, size: { width }, colors={"scheme":"nivo"} } = props;
    return (
        <Fragment>
            {data.length === 0 && !loading && <Empty/>}
            {data.length > 0 && <div>
                <div className="text-center mb-3">
                    {title && <h5 className="mb-3"><b>{title}</b></h5>}
                    {data.map(({id, total, color })=>(
                        <Space key={id} className="mx-2"><span className="d-block" style={{width: 40, height: 15, border: `3px solid ${color}`}}></span>{ id }</Space>
                    ))}
                </div>
                <div style={{overflowX: 'auto'}}>
                    <div style={{width: '100%',height: 400}}>
                        <ResponsiveLine
                            width={ data.length > 0 && data[0].data.length * 50 > width ? data[0].data.length * 50 : (width - 50)}
                            data={data}
                            margin={{
                                "top": 50,
                                "right": 50,
                                "bottom": ( (data.length > 0 &&data[0].data.length > 7) || width < 768) ? 100 : 50,
                                "left": 50
                            }}
                            colors={colors}
                            dotSize={10}
                            axisBottom={{
                                "orient": "bottom",
                                "tickSize": 5,
                                "tickPadding": 5,
                                "tickRotation": ((data.length > 0 &&data[0].data.length > 7) || width < 768) ? -45 : 0,
                                "legendOffset": 36,
                                "legendPosition": "middle"
                            }}
                            enableSlices="x"
                            pointBorderWidth={2}
                            useMesh={true}
                        />
                    </div>
                </div>
            </div>}
        </Fragment>
    );
};

index.propTypes = {
    
};

export default withSize()(index);
