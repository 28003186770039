import React from "react";

import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import FieldSelect from "utils/fields/FieldSelect";
import { options_delivery, options_active_webhooks } from "utils/options";
import { renderInputText } from "utils/Form";
import validate from "./validate";
const FormCreatePromotions = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      {/* <div className="cursor-pointer d-flex justify-content-end mb-1">
        <img
          style={{ right: 10, bottom: 10 }}
          onClick={openTourPromotion}
          src={question}
          height={35}
        />
      </div> */}
      <div className="mb-3 card p-3">
        <div className="card-body">
          <div className="form-group tour1Promotion">
            <h5 className="font-weight-bold">
              Promotion Name 
            </h5>
            <Field
              name="name_promotions"
              component={renderInputText}
              maxLength={"50"}
            />
          </div>
          <div className="form-group tour2Promotion">
            <h5 className="font-weight-bold">
              Variants 
            </h5>
            <Field
              name="url_webhooks"
              component={renderInputText}
              maxLength={"50"}
            />
          </div>
          <div className="form-group tour3Promotion">
            <h5 className="font-weight-bold">Status</h5>
            <div className="d-flex align-items-center">
              <Field
                name="status_webhooks"
                component={FieldSelect}
                options={options_active_webhooks}
                size="large"
                defaultValue={options_active_webhooks[0]["value"]}
                className="w-100 line-height-50px"
              />
            </div>
          </div>
          <div className="text-center d-flex align-items-center justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ width: 150 }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

FormCreatePromotions.propTypes = {};

const selector = formValueSelector("FormCreatePromotions");
const mapStateToProps = (state) => ({
  topic: selector(state, "topic") || "",
  formCreateWebhooks: state.form.formCreateWebhooks,
  name_webhooks: selector(state, "name_webhooks") || "",
  url_webhooks: selector(state, "url_webhooks") || "",
  topic_webhooks:
    selector(state, "topic_webhooks") || options_delivery[0]["value"],
  status_webhooks:
    selector(state, "status_webhooks") || options_active_webhooks[0]["value"],
});
const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change("FormCreatePromotions", field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "FormCreatePromotions",
    enableReinitialize: true,
    validate,
  })(FormCreatePromotions)
);
