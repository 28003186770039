import { GRAY_600 } from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .tip {
    font-size: 12px;
    color: ${GRAY_600};
    margin-top: 2px;
}
`;
