import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Button } from "antd";
import { getDetail, update } from "./actions";
import Form from "./components/form";
import BackToIcon from "../../../../images/chevron-down.svg";

class Edit extends Component {
  componentDidMount() {
    this.props.getDetail(this.props.match.params.id);
  }

  handleSubmit = ({ id, status, conditionMatch, name, style }) => {
    let params = {
      status: status ? 1 : 0,
      name,
      style,
    };
    for (let index = 0; index < conditionMatch.length; index++) {
      const element = conditionMatch[index];
      for (let key in element) {
        params[`conditionMatch[${index}][${key}]`] = element[key];
      }
    }
    this.props.update(id, params);
  };
  render() {
    const { updating, detail } = this.props;
    return (
      <div>
        <div className="container-fluid">
          <Button
            className="BackToButton d-flex align-items-center"
            onClick={() => {
              this.props.history.push("/customer-collection");
            }}
          >
            <img src={BackToIcon} alt="back-icon"/>
            <p className="m-0 ml-2">Back to Collection</p>
          </Button>
          <div className="d-flex justify-content-between align-items-center my-3">
            <h1 className="h3 mb-0 font-weight-bold">Update Collection</h1>
          </div>
          <Spin spinning={updating || detail.loading}>
            <div className="card">
              <div className="card-body">
                <Form
                  initialValues={detail.data}
                  onSubmit={this.handleSubmit}
                />
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    updating: state.collection.edit.updating,
    detail: state.collection.edit.detail,
  }),
  (dispatch) => ({
    update: (id, params) => {
      dispatch(update(id, params));
    },
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
  })
)(Edit);
