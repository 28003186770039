import { combineReducers } from 'redux'
import list from './scenes/List/reducer'
import edit from './scenes/Edit/reducer'
// import create from './scenes/Create/reducer'
// import del from './scenes/Delete/reducer'

const reducer = combineReducers({
    list,
    edit,
    // create,
    // del,
})

export default reducer