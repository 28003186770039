import axios from 'utils/request';

const prefix = '/top-up';

export const requestTopUp = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}`,
    is_json_content_type: true,
  });
};

export const getListTopUp = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}`,
    is_json_content_type: true,
  });
};
