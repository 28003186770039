import { actionType as TYPE } from "./actions";

const initialState = {
  detail: {
    loading: false,
    data: {},
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.DETAIL.REQUEST:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          loading: true,
        },
      };
    case TYPE.DETAIL.SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: action.data.scheduleCampaign,
          loading: false,
        },
      };
    case TYPE.DETAIL.ERROR:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
