import React from "react";
import { Collapse, Affix } from "antd";
import {
  Field,
  reduxForm,
  submit,
  formValueSelector,
  change,
} from "redux-form";
import { connect } from "react-redux";
import { renderRangeDate } from "utils/Form";
import RenderAdvancedFilterButtons from "utils/Form/RenderAdvancedFilterButtons";
import RenderTimeOptions from "utils/Form/RenderTimeOptions";
import { clearFilter } from "utils/helper";
import RenderTagFilter from "utils/Form/RenderTagFilter";
import getMomentInstance from "utils/moment";
const { Panel } = Collapse;
const paramsName = {
  createdAtFrom: "From Date",
  createdAtTo: "To Date",
};
const defaultValues = {
  createdAtFrom: null,
  createdAtTo: null,
};

let paramsValueAlias = {}
const AdvancedFilter = ({ handleSubmit, changeFieldValue, orderReportDashboardData, date_range, ...props}) => {
  const submitFilters = (isClosedAdvanedFileter = true) => {
    props.dispatch(submit("AdvancedFilterReportDashboard"));
    if(isClosedAdvanedFileter) props.onCloseAdvancedSearch();
  };
  return (
    <div>
       <RenderTagFilter
        params={orderReportDashboardData.params}
        paramsName={paramsName}
        paramsValueAlias={paramsValueAlias}
        defaultValues={defaultValues}
        changeFieldValue={changeFieldValue}
        isAllowedToClearFilter={true}
        submitFilters={submitFilters}
        date_range={date_range}
      />
      <form onSubmit={handleSubmit}>
        <div className="p-3">
          <Collapse
            defaultActiveKey={["1", "3", "4", "5"]}
            bordered={true}
            ghost
            expandIconPosition="right"
          >
            <Panel header={<b className="header-filter">Time</b>} key="1">
              <RenderTimeOptions changeFieldValue={changeFieldValue}/>
              <div className="row">
                <Field
                  name="date_range"
                  component={renderRangeDate}
                  className="h-50px w-100"
                  dateTimeFormat="YYYY/MM/DD"
                  showTime={false}
                  classContainer="col-6 mb-2"
                  startPlaceholder="From date"
                  endPlaceholder="To date"
                />
              </div>
            </Panel>
          </Collapse>
        </div>
      </form>
      <Affix offsetBottom={0}>
        <RenderAdvancedFilterButtons
          handleOnClearFilters={() => {
            clearFilter(changeFieldValue, {
              date_range: [getMomentInstance(null, 0, false, false, null, null, null, null, 7, 'days'),
              getMomentInstance()]
            })
            setTimeout(() => props.dispatch(submit("AdvancedFilterReportDashboard")), 1000)
          }}
          handleOnSubmit={submitFilters}
        />
      </Affix>
    </div>
  );
};
const selector = formValueSelector("AdvancedFilterReportDashboard");

const AdvancedFilterReduxForm = reduxForm({
  form: "AdvancedFilterReportDashboard",
  enableReinitialize: true,
  //validate,
})(AdvancedFilter);

const mapStateToProps = (state) => ({
  productId: selector(state, "productId"),
  colorId: selector(state, "colorId"),
  date_range: selector(state, "date_range") || null,
  AdvancedFilterReportDashboard:
    state.form.AdvancedFilterReportDashboard?.values || {},
});
const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change("AdvancedFilterReportDashboard", field, value));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedFilterReduxForm);