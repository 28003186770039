const validate = (values) => {
  const errors = {};
  if (!values.name || values.name.replace(/\s+/g, '').length === 0) {
    errors.name = 'Please enter your name';
  }
  if (!values.address || values.address.replace(/\s+/g, '').length === 0) {
    errors.address = 'Please enter your address';
  }
  if (
    !values.printNameAndTitle ||
    values.printNameAndTitle.replace(/\s+/g, '').length === 0
  ) {
    errors.printNameAndTitle = 'Please enter your print name and title';
  }
  if (!values.signatureUrl) {
    errors.signatureUrl = 'Please provide your authorized signature';
  }
  return errors;
};

export default validate;
