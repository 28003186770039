import React, { useCallback, useEffect, useState } from "react";
import { Field } from "redux-form";
import MasterCard from "images/master-card.png";
import braintree from "braintree-web";
import { Spin } from "antd";
import { getBraintreeToken } from "apis/billing_payment_account";
import {
  renderInputText,
  renderNumberFormat,
  RenderCreditCardNumber,
} from "utils/Form";

import ProtectedIcon from "images/security-checked--v1.png";
function CreditCardMethod({ changeFieldValue, nonce, submitForm }) {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  useEffect(() => {
    if (token) {
      braintree.client.create({ authorization: token }, function (
        createErr,
        clientInstance
      ) {
        document
          .getElementById("addPaymentMethodBtn")
          .addEventListener(
            "click",
            handleOnCheckCreditCard.bind(null, clientInstance)
          );
      });
    }
    return () => {
      if (document.getElementById("addPaymentMethodBtn")) {
        document
          .getElementById("addPaymentMethodBtn")
          .removeEventListener("click", handleOnCheckCreditCard);
      }
    };
  }, [token]);

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);
    getBraintreeToken()
      .then((response) => {
        if (isSubscribed) {
          setLoading(false);
          if (response?.code === "success" && response?.data?.token) {
            setToken(response?.data?.token);
          }
        }
      })
      .catch((error) => {
        if (isSubscribed) {
          setLoading(false);
        }
      });

    return () => (isSubscribed = false);
  }, []);

  const handleOnCheckCreditCard = useCallback((clientInstance, event) => {
    event.preventDefault();
    const addPaymentMethodBtn = document.getElementById("addPaymentMethodBtn");
    const cardNumberInput = document.getElementById("card-number");
    const cardholderNameInput = document.getElementById("cardholder-name");
    const expirationDateInput = document.getElementById("expiration-date");
    const cvvInput = document.getElementById("cvv");
    addPaymentMethodBtn.setAttribute("disabled", true);

    const data = {
      creditCard: {
        endpoint: "payment_methods/credit_cards",
        method: "post",
        data: {
          creditCard: {
            number: cardNumberInput.value.trueValue,
            expirationDate: expirationDateInput?.value?.formattedValue,
            cvv: cvvInput.value.trueValue,
            cardholderName: cardholderNameInput.value,
          },
        },
        options: {
          validate: false,
        },
      },
    };

    // Warning: For a merchant to be eligible for the easiest level of PCI compliance (SAQ A),
    // payment fields cannot be hosted on your checkout page.
    // For an alternative to the following, use Hosted Fields.
    clientInstance.request(
      {
        endpoint: "payment_methods/credit_cards",
        method: "post",
        data: data,
      },
      function (requestErr, response) {
        // More detailed example of handling API errors: https://codepen.io/braintree/pen/MbwjdM
        if (requestErr) {
          throw new Error(requestErr);
        }
        changeFieldValue("nonce", response.creditCards[0].nonce);
        addPaymentMethodBtn.removeAttribute("disabled");
        //addPaymentMethodBtn.click();
      }
    );
  }, []);
  return (
    <Spin spinning={loading}>
      <div className="row">
        <div className="col-xl-7">
          <p style={{ color: "#0c8814" }}>
            <img
              src={ProtectedIcon}
              alt="protected"
              className="img-fluid mr-1"
            />
            <span>
              We do not store your card or billing method details. We use
              industry-standard encryption to protect your personal information
            </span>
            .
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-xl-5">
          <div className="form-group">
            <label className="col-form-label">Card number</label>
            <Field
              id="card-number"
              name="cardNumber"
              component={RenderCreditCardNumber}
              isCardNumberInput={true}
              maxLength={"50"}
              placeholder="xxxx-xxxx-xxxx-xxxx"
            />
          </div>
          <div className="form-group">
            <label className="col-form-label">Cardholder name</label>
            <Field
              id="cardholder-name"
              name="cardholderName"
              component={renderInputText}
              maxLength={"50"}
              placeholder="Cardholder Name"
            />
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label className="col-form-label">Expiry date</label>
                <Field
                  id="expiration-date"
                  name="expirationDate"
                  component={renderNumberFormat}
                  maxLength={"50"}
                  placeholder="MM/YYYY"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label className="col-form-label">CVV</label>
                <Field
                  id="cvv"
                  name="cvv"
                  component={RenderCreditCardNumber}
                  placeholder="3 digits"
                  maxLength={"50"}

                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-7 d-flex align-items-center">
          <img src={MasterCard} className="img-fluid" alt="master card" />
        </div>
      </div>
    </Spin>
  );
}

export default React.memo(CreditCardMethod);
