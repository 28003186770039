import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Tabs, Table, Modal, Pagination, Tooltip } from 'antd';
import { getList, deleteIssue, getStatistic } from './actions';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { getList as getListStore } from 'scenes/Stores/scenes/List/actions';
import FormFilter from './components/FormFilter';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  issue_solution_options,
  options_order_issue_status,
} from 'utils/options';
import getMomentInstance, { getMomentInstance2 } from 'utils/moment';
import subOrderIcon from 'images/sub-order.png';
import icon_duplicate from 'assets/images/ico-duplicate.png';
import { addMarginTopToCheckBoxOfAntdTable } from 'utils/helper';
import DotLoadingIcon from 'images/dots-loading.gif';
import Filter from 'components/Filter';
import { GMButton } from 'components/Button';
import { Wrapper } from './styled';
import { change } from 'redux-form';
import ShowMoreText from 'react-show-more-text';
import { isEqual } from 'lodash';
import { replaceIcon } from 'utils/Form/RenderOrderPlatformInfo';
import RenderOrderStatus from 'utils/Form/RenderOrderStatus';
import { GMBadge } from 'components/Badge';
import classNames from 'classnames';

const { confirm } = Modal;
class Issue extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      selected: null,
      selectedRowKeys: [],
      initialFilterValues: {
        sortBy: 'id',
        sortType: 'desc',
        status: '-1',
        storeId: '0',
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initialFilterValues } = this.state;
    this.fetchData(initialFilterValues);
    this.props.listAllStore();
    this.props.getStatistic({});
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.orders) !== JSON.stringify(this.props.orders)
    ) {
      const newSelectedRowKeys = this.state.selectedRowKeys.filter((id) =>
        (this.props?.issues?.data || []).find((item) => item.id === id)
      );
      this.setState({ selectedRowKeys: newSelectedRowKeys });
    }
    if (
      !isEqual(prevState.initialFilterValues, this.state.initialFilterValues)
    ) {
      this.fetchData(this.state.initialFilterValues);
    }
    addMarginTopToCheckBoxOfAntdTable(
      'ant-table-selection',
      this.state.selectedRowKeys
    );
  }

  fetchData = ({ page = 1, perPage = 20, ...options }) => {
    let params = {
      page: page || 1,
      perPage,
      ...options,
    };
    const saveParams = { ...params };

    delete saveParams.page;
    if (
      this.state.initialFilterValues?.from &&
      this.state.initialFilterValues?.to
    ) {
      this.props.dispatch(
        change('paymentFormFilter', 'date_range', [
          getMomentInstance2({
            unixDateTime: this.state.initialFilterValues?.from,
          }).startOf('day'),
          getMomentInstance2({
            unixDateTime: this.state.initialFilterValues?.to,
          }).startOf('day'),
        ])
      );
      delete saveParams.from;
      delete saveParams.to;
    }
    if (
      `${window.location.pathname + window.location.search}` !==
      window.location.pathname + '?' + queryString.stringify(saveParams)
    ) {
      this.props.history.replace(
        window.location.pathname + '?' + queryString.stringify(saveParams)
      );
    }
    if (params.solution === '0') {
      delete params.solution;
    }
    this.props.getList(params);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  deleteIssue = (ids) => {
    const { deleteIssue } = this.props;
    confirm({
      title: 'Do you want to delete these issues?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        deleteIssue({ ids: ids });
      },
      onCancel() {},
    });
  };
  handleChangeTab = (activeKey) => {
    const { issues } = this.props;
    switch (parseInt(activeKey)) {
      case 1:
        this.fetchData({ page: 1, perPage: 20 });
        break;
      case 2:
        this.fetchData({ ...issues.params, status: 0, page: 1, perPage: 20 });
        break;
      case 3:
        this.fetchData({ ...issues.params, status: 1, page: 1, perPage: 20 });
        break;
      case 4:
        this.fetchData({ ...issues.params, status: 2, page: 1, perPage: 20 });
        break;
      case 5:
        this.fetchData({ ...issues.params, status: 3, page: 1, perPage: 20 });
        break;
      case 6:
        this.fetchData({ sortBy: 'id', sortType: 'desc' });
        break;
      default:
      // nothing to do
    }
  };
  getActiveTag = (status) => {
    switch (parseInt(status)) {
      case 0:
        return '2';
      case 1:
        return '3';
      case 2:
        return '4';
      case 3:
        return '5';
      default:
        return '1';
    }
  };
  render() {
    const {
      issues,
      pageName,
      deleteIssueLoading,
      stores,
      statistic,
    } = this.props;

    const { selectedRowKeys, initialFilterValues } = this.state;
    const columns = [
      {
        title: 'Issue ID',
        dataIndex: 'id',
        align: 'left',
        width: 100,
        key: 'id',
        render: (id, row) => {
          return row?.id ? (
            <Link to={`/orders/order-issues/${row?.id}`}>{row?.id}</Link>
          ) : (
            'N/A'
          );
        },
      },
      {
        title: 'Store',
        key: 'store',
        width: 80,
        align: 'left',
        render: (_v, row) => {
          return (
            <Tooltip title={row.orderData?.storeIntegration?.showName || 'N/A'}>
              {' '}
              <div className="icon-wrapper">
                {row.orderData?.platform?.icon ? (
                  <img
                    src={replaceIcon(row.orderData.platform.icon)}
                    style={{ width: '25px' }}
                    alt=""
                  />
                ) : (
                  <img src={icon_duplicate} style={{ width: '25px' }} alt="" />
                )}
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: 'Order Name',
        align: 'left',
        width: 180,
        key: 'orderData',
        render: (value, row) => (
          <div>
            <Link to={`/orders/${row?.orderData?.id}`}>
              {row?.orderData?.name || `#${row?.orderData?.id}`}
            </Link>
            {row?.orderData?.onlineReference && (
              <p>Reference: {row?.orderData?.onlineReference}</p>
            )}
          </div>
        ),
      },
      {
        title: 'Order status',
        align: 'left',
        width: 120,
        key: 'order_status',
        render: (_v, row) => {
          const order = row.orderData;
          return <RenderOrderStatus order={order} />;
        },
      },
      {
        title: 'Type',
        align: 'left',
        width: 120,
        key: 'type',
        render: (_v, row) => {
          return row?.orderIssueCategoryData?.name;
        },
      },
      {
        title: 'Effected item',
        align: 'center',
        width: 120,
        key: 'effected_item',
        dataIndex: 'issueItemAffected',
      },
      {
        title: 'Solution',
        align: 'left',
        width: 80,
        key: 'solution',
        render: (_v, row) => (
          <b>
            {
              issue_solution_options.find(
                (item) => item.value === row?.solution
              )?.label
            }
          </b>
        ),
      },
      {
        title: 'Issue status',
        dataIndex: 'status',
        align: 'center',
        width: 120,
        key: 'issue_status',
        render: (v, row) => {
          const stObj = options_order_issue_status.find(
            (item) => item.value === v
          );
          if (!stObj) {
            return 'N/A';
          }
          return (
            <GMBadge
              noDot
              className={classNames('badge', stObj.type_color.toLowerCase())}
            >
              {stObj.text_label}
            </GMBadge>
          );
        },
        // render: (text) =>
        //   getOptionLabel(options_order_issue_status, text, 0, undefined, true),
      },
      {
        title: 'Note from support',
        dataIndex: 'note',
        align: 'left',
        key: 'note',
        render: (text) => {
          return (
            <ShowMoreText
              /* Default options */
              lines={3}
              more="Show more"
              less="Show less"
              className="content-css"
              anchorClass="show-more-less-clickable"
              expanded={false}
              truncatedEndingComponent={'... '}
            >
              {text}
            </ShowMoreText>
          );
        },
      },
      {
        title: 'Created date',
        align: 'left',
        key: 'issueItemAffected',
        width: 120,
        render: (_v, row) => {
          return (
            <span>
              {row?.createdAt
                ? getMomentInstance(row?.createdAt || '', null, true)
                : 'N/A'}
            </span>
          );
        },
      },
      {
        title: 'Last Update',
        dataIndex: 'updatedAt',
        align: 'left',
        key: 'updatedAt',
        width: 120,
        render: (text) => (
          <span>{getMomentInstance(text, null, true) || 'N/A'}</span>
        ),
      },
    ];
    const columns2 = [
      {
        title: null,
        dataIndex: 'name',
        align: 'left',
        key: 'name',
        render: () => null,
      },
      {
        title: null,
        dataIndex: 'name',
        align: 'left',
        key: 'name',
        render: () => {
          return (
            <img
              className="img-fluid"
              src={subOrderIcon}
              style={{ width: '30px' }}
              alt={'suborder-icon'}
            />
          );
        },
      },
      {
        title: null,
        dataIndex: 'name',
        align: 'left',
        key: 'name',
        render: (value, record) => <div>{value || `#${record?.id}`}</div>,
      },
      {
        title: null,
        dataIndex: 'name',
        align: 'left',
        key: 'name',
        render: () => null,
      },

      {
        title: null,
        dataIndex: 'name',
        align: 'left',
        key: 'name',
        render: () => null,
      },

      {
        title: null,
        dataIndex: 'name',
        align: 'left',
        key: 'name',
        render: () => null,
      },
    ];
    return (
      <>
        <Wrapper
          pageName={pageName}
          ctas={
            <GMButton
              className="cta-upload"
              onClick={() => {
                this.props.history.push('/orders/order-issues/create');
                sessionStorage.removeItem('listOrderIdToCreateIssue');
              }}
            >
              <PlusIcon className="plusicon" />
              Create issues
            </GMButton>
          }
          filter={
            <Filter
              placeholder="Search by order name"
              className="filter-form"
              showFilter
              maxLength={50}
              onSubmit={(values) => {
                const value2Set = {
                  ...this.state.initialFilterValues,
                  keyword: (values.search || '').trim(),
                  storeId: (values.storeId || '').trim(),
                };
                if (values?.date_range?.[0] && values?.date_range?.[1]) {
                  value2Set.from = values?.date_range?.[0]
                    ?.startOf('day')
                    ?.unix();
                  value2Set.to = values?.date_range?.[1].endOf('day')?.unix();
                } else {
                  value2Set.from = value2Set.to = undefined;
                }
                value2Set.solution = values.solution;
                this.setState({ initialFilterValues: value2Set });
              }}
              afterAddon={
                <FormFilter
                  initialValues={initialFilterValues}
                  listStores={stores.data}
                  history={this.props.history}
                  // handleAdvancedSubmit={this.fetchData}
                  stores={stores}
                />
              }
            />
          }
        >
          <div>
            <div>
              <Tabs
                defaultActiveKey={this.getActiveTag(issues?.params?.status)}
                activeKey={this.getActiveTag(issues?.params?.status)}
                onChange={this.handleChangeTab}
                className="tabs"
              >
                <Tabs.TabPane
                  tab={
                    statistic.loading ? (
                      <>
                        <span className="mr-2">All Issue</span>{' '}
                        <img src={DotLoadingIcon} width={20} alt="loading" />
                      </>
                    ) : (
                      `All Issue (${statistic.data.Total || 0})`
                    )
                  }
                  key="1"
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    statistic.loading ? (
                      <>
                        <span className="mr-2">Opening</span>{' '}
                        <img src={DotLoadingIcon} width={20} alt="loading" />
                      </>
                    ) : (
                      `Opening (${statistic.data.Pending || 0})`
                    )
                  }
                  key="2"
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    statistic.loading ? (
                      <>
                        <span className="mr-2">Processing</span>{' '}
                        <img src={DotLoadingIcon} width={20} alt="loading" />
                      </>
                    ) : (
                      `Processing (${statistic.data.Processing || 0})`
                    )
                  }
                  key="3"
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    statistic.loading ? (
                      <>
                        <span className="mr-2">On-hold</span>{' '}
                        <img src={DotLoadingIcon} width={20} alt="loading" />
                      </>
                    ) : (
                      `On-hold (${statistic.data.onHold || 0})`
                    )
                  }
                  key="4"
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    statistic.loading ? (
                      <>
                        <span className="mr-2">Completed</span>{' '}
                        <img src={DotLoadingIcon} width={20} alt="loading" />
                      </>
                    ) : (
                      `Completed (${statistic.data.Completed || 0})`
                    )
                  }
                  key="5"
                ></Tabs.TabPane>
              </Tabs>
              <div className="custom-header-sticky issue-table">
                <Table
                  rowKey="id"
                  // rowSelection={rowSelection}
                  sticky
                  columns={columns}
                  className="table"
                  dataSource={issues.data}
                  loading={
                    issues.loading || deleteIssueLoading || stores.loading
                  }
                  rowExpandable={true}
                  scroll={{ x: 1000 }}
                  pagination={
                    !this.props.is_mobile
                      ? {
                          showTotal: (total) => `Total ${total} items`,
                          total: issues.total,
                          pageSize: 10,
                          current: issues.params.page,
                          showSizeChanger: false,
                          onShowSizeChange: true,
                          onChange: (page, perPage) =>
                            this.fetchData({ ...issues.params, page, perPage }),
                        }
                      : false
                  }
                />
              </div>
              {this.props.is_mobile && (
                <div className="d-flex justify-content-end align-items-center py-3 px-3">
                  <div>
                    <Pagination
                      size="small"
                      showSizeChanger
                      className="pagination"
                      total={issues.total}
                      defaultCurrent={issues?.params?.page || 1}
                      pageSize={10}
                      onChange={(page, perPage) => {
                        this.fetchData({
                          ...issues.params,
                          page:
                            page === Number.NEGATIVE_INFINITY
                              ? 1
                              : page === 0
                              ? 1
                              : page,
                          perPage,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Wrapper>
      </>
    );
  }
}

export default connect(
  (state) => ({
    issues: state.order.issue.issues,
    statistic: state.order.issue.statistic,
    deleteIssueLoading: state.order.issue.deleteIssue.loading,
    stores: state.store.list.stores,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
    deleteIssue: (ids) => {
      dispatch(deleteIssue(ids));
    },
    listAllStore: () => {
      dispatch(getListStore({}));
    },
    getStatistic: (params) => {
      dispatch(getStatistic(params));
    },

    dispatch,
  })
)(Issue);
