import { actionType as TYPE } from "./actions";
const initialState = {
  notificationShipping: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  notificationShippingRead: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  processing: false,
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST_NOTIFY_SHIPPING.REQUEST:
      return {
        ...state,
        notificationShipping: {
          ...initialState.notificationShipping,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_NOTIFY_SHIPPING.SUCCESS:
      return {
        ...state,
        notificationShipping: {
          ...state.notificationShipping,
          loading: false,
          data: action.data.notifications,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST_NOTIFY_SHIPPING.ERROR:
      return {
        ...state,
        notificationShipping: {
          ...state.notificationShipping,
          loading: false,
        },
      };
    // case TYPE.READ.REQUEST:
    //   return {
    //     ...state,
    //     notificationShippingRead: {
    //       ...initialState.notificationShippingRead,
    //       params: action.params,
    //       loading: true,
    //     },
    //   };
    // case TYPE.READ.SUCCESS:
    //   return {
    //     ...state,
    //     notificationShippingRead: {
    //       ...state.notificationShippingRead,
    //       loading: false,
    //       data: action?.data?.notificationShipping || [],
    //       total:
    //         action?.data?.pagination?.total ||
    //         (action?.data?.notificationShipping || []).length,
    //     },
    //   };
    // case TYPE.READ.ERROR:
    //   return {
    //     ...state,
    //     notificationShippingRead: {
    //       ...state.notificationShippingRead,
    //       loading: false,
    //     },
    //   };

    // case TYPE.MARK_AS_READ.REQUEST:
    //   return {
    //     ...state,
    //     processing: true,
    //   };
    // case TYPE.MARK_AS_READ.SUCCESS:
    //   notification.success({
    //     message: "Mark all notificationShipping as read successfully.",
    //     duration: 5,
    //   });
    //   return {
    //     ...state,
    //     processing: false,
    //   };
    // case TYPE.MARK_AS_READ.ERROR:
    //   return {
    //     ...state,
    //     processing: false,
    //   };
    default:
      return state;
  }
}

export default ListReducer;
