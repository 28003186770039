import axios from 'utils/request';

const prefix = '/billing-payment-account';

export const list = () => {
  return axios({
    method: 'GET',
    url: `${prefix}?with[]=paymentAccount&with[]=billingInfor&with[]=billingInfor.country`,
  });
};

export const setPrimaryBillingPaymentAccount = (id) => {
  return axios({
    method: 'PUT',
    url: `${prefix}/${id}/primary`,
  });
};

export const setPrimary = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/primary`,
    is_json_content_type: true,
  });
};

export const remove = (data) => {
  return axios({
    method: 'DELETE',
    data,
    url: `${prefix}`,
    is_json_content_type: true,
  });
};

export const loadPrimaryBillingPaymentAccount = () => {
  return axios({
    method: 'GET',
    url: `${prefix}/primary`,
  });
};

export const loadListBillingPaymentAccount = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}`,
  });
};

export const deleteBillingPaymentAccount = (id) => {
  return axios({
    method: 'DELETE',
    url: `${prefix}/${id}`,
  });
};

export const create = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}`,
    is_json_content_type: true,
  });
};

export const detailBillingMethod = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/${id}`,
    is_json_content_type: true,
  });
};

export const update = (data) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}`,
    is_json_content_type: true,
  });
};

export const getBraintreeToken = () => {
  return axios({
    method: 'GET',
    url: '/credit-card/token',
  });
};

export const getBraintreeCardInfo = (id) => {
  return axios({
    method: 'GET',
    url: `/credit-card/${id}`,
  });
};

export const logBillingMethod = () => {
  return axios({
    method: 'GET',
    url: `logs/billing?with[]=customer`,
  });
};
