import React, { useState } from 'react';
import { Modal, notification, Button, Spin, Upload } from 'antd';
import { API_TOKEN_KEY, S3_CDN } from '../../web.config';
import { createFileName, createFolderName, getCookie } from '../helper';
import getMomentInstance from '../moment';
import { getPresignedUrlS3PDF } from '../../apis/image_design';
import { updateShippingLabel } from '../../apis/orders';
import axios from 'axios';
import classNames from 'classnames';
function RenderUpdateLabelForLabelOrder({ cusId = 0, orderId = 0 }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateable, setUpdateable] = useState(true);
  const [value, setValue] = useState('');
  const uploadProps = {
    accept: '.pdf',
    listType: 'picture-card',
    showUploadList: false,
    multiple: false,
    headers: {
      // common: {
      Authorization: `Bearer ${getCookie(API_TOKEN_KEY) /* auth cookie */}`,
      // }
    },
    beforeUpload: (file) => {
      if (file.type !== 'application/pdf') {
        notification.warn({
          className: 'notic1',
          message: `${file.name} is not a pdf file.`,
        });
      }
      if (file.size > 2e8) {
        notification.warn({
          className: 'notic1',
          message: `${file.name} is larger than 200MB.`,
        });
      }
      return file.type === 'application/pdf' && file.size <= 2e8;
    },
    customRequest: async ({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    }) => {
      const folderName = createFolderName(cusId);
      const extension = file.name.split('.').pop();
      const fileName = createFileName('pdf', 'barcode', cusId);
      const originalName = file.name.replace(`.${extension}`, '');
      const fileType = `application/pdf`;
      const keyToCreatePresignedUrl = `${'tmp'}/${folderName}/${getMomentInstance(
        null,
        0,
        false,
        true
      )}/${fileName}`;
      const response = await getPresignedUrlS3PDF(keyToCreatePresignedUrl);
      if (response && response.code === 'success') {
        axios
          .put(response.data.url, file, {
            headers: {
              'Content-Type': fileType,
            },
          })
          .then((response) => {
            if (parseInt(response.status) === 200) {
              onSuccess(
                {
                  code: 'success',
                  url: `${S3_CDN}/${keyToCreatePresignedUrl}`,
                  folderName: folderName,
                  fileName: fileName,
                  fileType,
                  extension,
                  originalName,
                },
                file
              );
            } else {
              onError();
            }
          });
      }
    },
    onChange: (info) => {
      const { status, response } = info.file;
      if (status === 'uploading') {
        setLoading(true);
      }
      if (status === 'done' && response.code === 'success') {
        updateShippingLabel(orderId, response.url).then((response2) => {
          if (response2.code === 'success') {
            setUpdateable(false);
            setVisible(false);
            setValue(response.url);
            notification.success({
              className: 'notic2',
              message: `${info.file.name} file uploaded successfully.`,
            });
          } else {
            notification.error({
              className: 'notic1',
              message: `${info.file.name} file upload failed.`,
            });
          }
          setLoading(false);
        });
      } else if (status === 'error') {
        notification.error({
          className: 'notic1',
          message: `${info.file.name} file upload failed.`,
        });
        setLoading(false);
      }
    },
  };
  return (
    <>
      {updateable && (
        <p
          className="text-danger cursor-pointer mt-0"
          onClick={() => setVisible(true)}
        >
          Upload shipping label
        </p>
      )}
      <Modal
        centered
        footer={null}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={800}
        title={
          <h4 className="font-weight-bold text-center">
            Upload shipping label
          </h4>
        }
      >
        <Spin spinning={loading}>
          <div className={classNames('image-design-upload barcode')}>
            <Upload {...uploadProps}>
              <Button type="primary">Choose file</Button>
            </Upload>
            <p className={classNames('text-danger font-italic')}>
              *Notice: Please upload vertical shipping labels with the size of
              4x6 inch.
              <a
                href="https://account.gearment.com/public/docs/label_example.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Label example
              </a>
            </p>
            {value && (
              <p>
                <a href={value} target={'_blank'} rel="noopener noreferrer">
                  {'Link file barcode'}
                </a>
              </p>
            )}
          </div>
        </Spin>
      </Modal>
    </>
  );
}

export default React.memo(RenderUpdateLabelForLabelOrder);
