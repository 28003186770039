export const defaultPageDescription = 'Gearment app integrates with top ecommerce platforms, streamlining fulfillment process. Connect your store, and start your print on demand journey today.';
export const defaultTitle = 'One-Stop Print On Demand Solution | Gearment App';
export const postfix = ' | Gearment App';
export const metaInfo = [
  {
    "url": /\/auth\/login(\/?\?*)?/,
    "title": "Login",
    "description": defaultPageDescription,
  },
  {
    "url": /\/auth\/register(\/?\?*)?/,
    "title": "Sign up",
    "description": defaultPageDescription,
  },
  {
    "url": /\/auth\/forgot(\/?\?*)?/,
    "title": "Forgot password",
    "description": defaultPageDescription,
  },
  {
    "url": /\/profile(\/?\?*)?/,
    "title": "Profile",
    "description": "",
  },
  {
    "url": /\/change-password(\/?\?*)?/,
    "title": "Update password",
    "description": "",
  },
  {
    "url": /\/notification(\/?\?*)?/,
    "title": "Notifications",
    "description": "",
  },
  {
    "url": /\/dashboard(\/?\?*)?/,
    "title": "Dashboard",
    "description": "",
  },
  {
    "url": /\/orders\/make-payment(\/?\?*)?/,
    "title": "Make payment",
    "description": "",
  },
  {
    "url": /\/orders\/match-product(\/?\?*)?/,
    "title": "Match product",
    "description": "",
  },
  {
    "url": /\/orders\/list(\/?\?*)?/,
    "title": "All orders",
    "description": "",
  },
  {
    "url": /\/orders\/create(\/?\?*)?/,
    "title": "Create manual order",
    "description": "",
  },
  {
    "url": /\/orders\/create-label-order(\/?\?*)?/,
    "title": "Create label order",
    "description": "",
  },
  {
    "url": /\/orders\/sync(\/?\?*)?/,
    "title": "Sync order",
    "description": "",
  },
  {
    "url": /\/orders\/import(\/?\?*)?/,
    "title": "Import order",
    "description": "",
  },
  {
    "url": /\/orders\/risk(\/?\?*)?/,
    "title": "Orders at risk",
    "description": "",
  },
  {
    "url": /\/orders\/order-issues(\/?\?*)?/,
    "title": "Issues",
    "description": "",
  },
  {
    "url": /\/orders\/order-issues\/create(\/?\?*)?/,
    "title": "Create Issue",
    "description": "",
  },
  {
    "url": /\/products\/\d+\/bulk(\/?\?*)?/,
    "title": "Bulk product",
    "description": "",
  },
  {
    "url": /\/products\/list(\/?\?*)?/,
    "title": "All products",
    "description": "",
  },
  {
    "url": /\/products\/\d+\/clone(\/?\?*)?/,
    "title": "Clone product",
    "description": "",
  },
  {
    "url": /\/products\/create(\/?\?*)?/,
    "title": "Create product",
    "description": "",
  },
  {
    "url": /\/products\/mockups(\/?\?*)?/,
    "title": "Mockups",
    "description": "",
  },
  {
    "url": /\/products\/mockups\/create(\/?\?*)?/,
    "title": "Create Mockup",
    "description": "",
  },
  {
    "url": /\/image-library(\/?\?*)?/,
    "title": "Design Library",
    "description": "",
  },
  {
    "url": /\/stores(\/?\?*)?/,
    "title": "Stores",
    "description": "",
  },
  {
    "url": /\/billing\/top up(\/?\?*)?/,
    "title": "Top up",
    "description": "",
  },
  {
    "url": /\/billing\/payment(\/?\?*)?/,
    "title": "Payments",
    "description": "",
  },
  {
    "url": /\/billing\/method(\/?\?*)?/,
    "title": "Billing methods",
    "description": "",
  },
  {
    "url": /\/billing\/method\/new(\/?\?*)?/,
    "title": "Add billing method",
    "description": "",
  },
  {
    "url": /\/billing\/method\/new\/?\?.*?update=\d+.*?$/,
    "title": "Update billing method",
    "description": "",
  },
  {
    "url": /\/billing\/legal-info(\/?\?*)?/,
    "title": "Legal info",
    "description": "",
  },
  {
    "url": /\/billing\/return-address(\/?\?*)?/,
    "title": "Return address",
    "description": "",
  },
  {
    "url": /\/billing\/return-address\/create(\/?\?*)?/,
    "title": "Add return address",
    "description": "",
  },
  {
    "url": /\/statistics\/reports-dashboard(\/?\?*)?/,
    "title": "Dashboard",
    "description": "",
  },
  {
    "url": /\/statistics\/account-reports(\/?\?*)?/,
    "title": "Staff account",
    "description": "",
  },
  {
    "url": /\/statistics\/report-product-sell(\/?\?*)?/,
    "title": "Product sales",
    "description": "",
  },
  {
    "url": /\/statistics\/shipping(\/?\?*)?/,
    "title": "Shipping",
    "description": "",
  },
  {
    "url": /\/statistics\/stock-status(\/?\?*)?/,
    "title": "Inventory",
    "description": "",
  },
  {
    "url": /\/statistics\/tracking(\/?\?*)?/,
    "title": "Tracking",
    "description": "",
  },
  {
    "url": /\/logs\/export-orders(\/?\?*)?/,
    "title": "Export orders",
    "description": "",
  },
  {
    "url": /\/logs\/sync-orders(\/?\?*)?/,
    "title": "Sync order",
    "description": "",
  },
  {
    "url": /\/logs\/sync-tracking(\/?\?*)?/,
    "title": "Sync tracking",
    "description": "",
  },
  {
    "url": /\/logs\/import-orders(\/?\?*)?/,
    "title": "Import orders",
    "description": "",
  },
  {
    "url": /\/logs\/sync-product(\/?\?*)?/,
    "title": "Sync product",
    "description": "",
  },
  {
    "url": /\/logs\/feed-product(\/?\?*)?/,
    "title": "Feed product",
    "description": "",
  },
  {
    "url": /\/settings\/general(\/?\?*)?/,
    "title": "General",
    "description": "",
  },
  {
    "url": /\/settings\/profile(\/?\?*)?/,
    "title": "Profile",
    "description": "",
  },
  {
    "url": /\/settings\/accounts(\/?\?*)?/,
    "title": "Staff account",
    "description": "",
  },
  {
    "url": /\/settings\/accounts\/create(\/?\?*)?/,
    "title": "Invite staff account",
    "description": "",
  },
  {
    "url": /\/settings\/accounts\/\d+\/edit\/?(\/?\?*)?/,
    "title": "Edit staff account",
    "description": "",
  },
  {
    "url": /\/settings\/api-information(\/?\?*)?/,
    "title": "Integrations",
    "description": "",
  },
  {
    "url": /\/settings\/gift-message\/create(\/?\?*)?/,
    "title": "Create gift message",
    "description": "",
  },
  {
    "url": /\/data-feed\/list\/?\?.*?platformId=3.*?$/,
    "title": "Data feed Amazon",
    "description": "",
  },
  {
    "url": /\/data-feed\/amazon(\/?\?*)?/,
    "title": "Add data feed Amazon",
    "description": "",
  },
  {
    "url": /\/data-feed\/list\/?\?.*?platformId=4.*?$/,
    "title": "Data feed Google",
    "description": "",
  },
  {
    "url": /\/data-feed\/google(\/?\?*)?/,
    "title": "Add data feed Google",
    "description": "",
  },
  {
    "url": /\/webhooks\/list(\/?\?*)?/,
    "title": "Webhooks",
    "description": "",
  },
  {
    "url": /\/webhooks\/create(\/?\?*)?/,
    "title": "Add webhooks",
    "description": "",
  },
  {
    "url": /\/settings\/shipping-delivery(\/?\?*)?/,
    "title": "Shipping and delivery",
    "description": "",
  },
  {
    "url": /\/settings\/gift-message(\/?\?*)?/,
    "title": "Gift message",
    "description": "",
  },
  {
    "url": /\/settings\/config-buy-tracking(\/?\?*)?/,
    "title": "Config buy tracking",
    "description": "",
  },
  {
    "url": /\/settings(\/?\?*)?/,
    "title": "Settings",
    "description": "",
  },
  {
    "url": /\/help(\/?\?*)?/,
    "title": "Helpdesk",
    "description": "",
  },

  {
    "url": /\/billing\/transactions\/\d+\/invoice(\/?\?*)?/,
    "title": "Invoices",
    "description": "",
  },

  {
    "url": /\/data-feed\/\d+\/amazon(\/?\?*)?/,
    "title": "Edit data feed Amazon",
    "description": "",
  },

  {
    "url": /\/data-feed\/\d+\/google(\/?\?*)?/,
    "title": "Edit data feed Google",
    "description": "",
  },
  {
    "url": /\/orders\/\d+(\/?\?*)?/,
    "title": "Order detail",
    "description": '',
  },
  {
    "url": /\/errors\/404/,
    "title": "Page not found | 404",
    "description": '',
  },
];
