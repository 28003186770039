import {
    actionType as TYPE
} from './actions'

const initialState = {
    reportManualSyncTracking: {
        loading: false,
        data: {}
    },
   
}

function DetailReducer(state = initialState, action){
    switch(action.type) {
        case TYPE.DETAIL.REQUEST:
            return {
                ...state,
                reportManualSyncTracking: {
                    ...initialState.reportManualSyncTracking,
                    loading: true
                }
            }
        case TYPE.DETAIL.SUCCESS:
            return {
                ...state,
                reportManualSyncTracking: {
                    ...state.reportManualSyncTracking,
                    data: action.data && action.data.scheduleTracking ? action.data.scheduleTracking : {},
                    loading: false,
                },
            }
        case TYPE.DETAIL.ERROR:
            return {
                ...state,
                reportManualSyncTracking: {
                    ...state.reportManualSyncTracking,
                    loading: false
                }
            }
        default:
            return state
    }
}

export default DetailReducer