import styled, { createGlobalStyle } from 'styled-components';

export const WrapperModal = styled.div`

`;
export const GlobalStyle = createGlobalStyle`
  .ant-modal-content {
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    border-radius: 8px;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }`
