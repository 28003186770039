import React, { useState, useEffect } from 'react'
import { Input, Spin } from 'antd';
import classNames from 'classnames'
import { checkOrderForCreatingIssue } from "apis/order-issues";
function RenderFullFilmentId({
  input,
  prefix,
  suffix,
  addonBefore,
  addonAfter,
  className,
  placeholder,
  size,
  meta: { submitFailed, error, invalid, touched },
  disabled,
  defaultValue,
  changeField,
  listIssues
}) {
  const [loading, setLoading] = useState(false);
  const [_error, setError] = useState('');
  useEffect(() => {
    setError("");
  }, [input.value]);
  return (
    <>
      <Spin spinning={loading}>
        <input
          allowClear
          disabled={disabled}
          value={input.value || defaultValue}
          onChange={(e) => input.onChange(e.target.value)}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onBlur={() => {
            if (input.value && input.value.toString().length > 4) {
              setLoading(true)
              checkOrderForCreatingIssue({ orders: [input.value?.trim()] })
                .then((response) => {
                  setLoading(false)
                  if (response?.data?.errors?.length > 0) {
                    setError(response?.data?.errors?.[0]?.clientMsg)
                  }
                })
                .catch(error => {
                  setLoading(false)
                });
            }
          }}
        />
      </Spin>
      {invalid && <hr className="border-danger m-0 position-absolute" style={{ width: '100%', left: '0%', bottom: '0' }} />}
      {touched && submitFailed && error && <span className="invalid-feedback 1">{error}</span>}
      {_error && <span className="invalid-feedback 2">{_error}</span>}
    </>
  )
};

export default React.memo(RenderFullFilmentId);