import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import validate from './validate';
import {
  renderSwitchAnt,
  renderSelectAnt,
  renderCheckboxGroup,
} from 'utils/Form';
import { GMTextbox } from 'components/InputFields';
import { Wrapper } from '../styled';
import { GMButton } from 'components/Button';
import { WrapperUpdateButton } from '../../styled';
import { ReactComponent as EtsyIcon } from 'images/Logo_store/full/etsy.svg';

let index = ({
  handleSubmit,
  onCancel,
  accounts,
  currAction,
  profile,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit} className="mx-2">
      <WrapperUpdateButton>
        <div className="text-left my-3">
          <EtsyIcon />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Store name</b>
          </label>
          <GMTextbox name="showName" showError />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Url store</b>
          </label>
          <GMTextbox name="url" showError />

          <span className="text-muted">
            Ex: https://etsy.com/shop/yourshopname
          </span>
        </div>
        {profile.primaryId === 0 && (
          <div className="form-group">
            <label className="col-form-label">
              <b>Staff Account</b>
            </label>
            <Wrapper>
              <Field
                name="staffId"
                component={renderSelectAnt}
                placeholder="-- Select staff --"
                mode={'multiple'}
                options={accounts.map((item) => ({
                  value: item.id.toString(),
                  label: item.name,
                }))}
              />
            </Wrapper>
          </div>
        )}
        <div className="form-group">
          <div className="d-flex align-items-center">
            <GMTextbox name="autoSync" component={renderSwitchAnt} showError />
            <label htmlFor="store_auto_sync" className="ml-3 col-form-label">
              Automatic synchronization when new orders arrive.{' '}
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="d-flex align-items-center">
            <GMTextbox name="status" component={renderSwitchAnt} showError />
            <label htmlFor="store_active" className="ml-3 col-form-label">
              Active store
            </label>
          </div>
        </div>
        {currAction === 'create' && (
          <div className="form-group">
            <div className="d-flex align-items-center">
              <Field
                name="checkboxTerm"
                component={renderCheckboxGroup}
                colSpan={24}
                options={[
                  {
                    label:
                      "The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.",
                    value: 1,
                  },
                ]}
              />
            </div>
          </div>
        )}
        <div>
          <GMButton className="saveBtn" size="lg">
            {currAction === 'create' ? 'Connect store' : 'Update store'}
          </GMButton>
        </div>
      </WrapperUpdateButton>
    </form>
  );
};

index = reduxForm({
  form: 'formEtsyStore',
  enableReinitialize: true,
  validate,
})(index);
const selector = formValueSelector('formEtsyStore');
export default connect((state) => ({
  checkboxTerm: selector(state, 'checkboxTerm') || null,
}))(index);
