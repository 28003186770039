import React, { useState, useEffect } from "react";
import { Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
export default ({
  params = {},
  paramsName = {},
  paramsValueAlias = {},
  defaultValues = null,
  changeFieldValue = null,
  date_range = null,
  submitFilters = null,
  arrExcludedProperty = [
    "page",
    "perPage",
    "sortBy",
    "sortType",
    "typeDate",
    "with[]"
  ],
  isAllowedToClearFilter = false
}) => {
  const [listFilters, setListFilters] = useState([]);
  // useEffect(() => {
  //   if (params) {
  //     const listParamKeys = Object.keys(params);
  //     const listFiltersTemp = [];
  //     for (let index = 0; index < listParamKeys.length; index++) {
  //       if (arrExcludedProperty.includes(listParamKeys[index])) continue;
  //       const paramValue = params?.[listParamKeys?.[index]];
  //       const paramName = paramsName?.[listParamKeys?.[index]] || listParamKeys?.[index];
  //       const fnc = paramsValueAlias?.[listParamKeys[index]]?.find;
  //       let paramAliasLabel = '';
  //       try {
  //         paramAliasLabel = typeof fnc === 'function' ? fnc(obj => `${obj?.value}` === `${paramValue}`)?.label : '';
  //       } catch (err) { console.log(err) }

  //       if (Array.isArray(paramValue)) {
  //         (paramValue).forEach(element => {
  //           const temp = {};
  //           temp[listParamKeys[index]] = {
  //             paramName,
  //             paramValue: paramsValueAlias?.[listParamKeys?.[index]]?.[element] || element,
  //             paramValue2: element
  //           }
  //           listFiltersTemp.push(temp);
  //         });
  //       } else {
  //         const temp = {};
  //         if (!paramAliasLabel) {
  //           temp[listParamKeys[index]] = {
  //             paramName,
  //             paramValue: paramsValueAlias?.[listParamKeys?.[index]]?.[paramValue] || paramValue,
  //             paramValue2: paramsValueAlias?.[listParamKeys?.[index]]?.[paramValue] || paramValue
  //           }
  //         } else {
  //           temp[listParamKeys[index]] = {
  //             paramName,
  //             paramValue: paramAliasLabel,
  //             paramValue2: paramsValueAlias?.[listParamKeys?.[index]]?.[paramValue] || paramValue
  //           }
  //         }
  //         listFiltersTemp.push(temp);
  //       }
  //     }
  //     setListFilters(listFiltersTemp);
  //   }
  // }, [JSON.stringify(params), JSON.stringify(paramsValueAlias)]);

  const clearFilter = (key, value) => {
    if (changeFieldValue && defaultValues && typeof defaultValues[key] != 'undefined' && submitFilters) {
      if (Array.isArray(params[key])) {
        changeFieldValue(key, params[key].filter(item => item !== value));
      } else if (key.toLowerCase().indexOf('from') > -1 && date_range) {
        changeFieldValue('date_range', [null, date_range[1]]);
      } else if (key.toLowerCase().indexOf('to') > 1 && date_range) {
        changeFieldValue('date_range', [date_range[0], null]);
      } else {
        changeFieldValue(key, defaultValues[key]);
      }
      setTimeout(() => {
        submitFilters(false);
      }, 200);
    }
  }
  return null;
  return (
    <>{(listFilters.length > 0) ? <div style={{ backgroundColor: "#ebebeb" }} className="p-3">
      <div className="d-flex justify-content-between">
        <p className="font-weight-bold font-size-16">Applied Filter</p>
      </div>
      {listFilters.map((item) => {
        const val = item?.[Object.keys(item)?.[0]]?.['paramValue'];
        if (!val) {
          return null;
        }
        return (
          <Tag
            color="#fff"
            className="border text-muted mr-2 mb-2 font-size-14 px-2 py-1"
            key={Object.keys(item)?.[0] + "-" + item?.[Object.keys(item)?.[0]]?.['paramValue']}
          >
            {item?.[Object.keys(item)?.[0]]?.['paramName']}: {" "}
            {typeof val === 'object' ? val?.label : val}
            {isAllowedToClearFilter && <CloseOutlined onClick={() => { clearFilter(Object.keys(item)[0], item?.[Object.keys(item)?.[0]]?.['paramValue2']) }} className="cursor-pointer ml-2" style={{ verticalAlign: 2, fontSize: 11, color: '#c8c8c8' }} />}
          </Tag>
        );
      })}
    </div> : null}</>
  );
};