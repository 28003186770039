import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "./components/form";
import { Button, Spin } from "antd";
import BackToIcon from "images/chevron-down.svg";
import { getDetailWebhooks, updateWebhooks } from "./actions";
import { options_delivery, options_active_webhooks } from "utils/options";

export class index extends Component {

  componentDidMount() {
    this.props.getDetailWebhooks(this.props.match.params.id);
  }
  onEditFormSubmit = (values) => {
    let dataToUpdate = {
      name: values.name_webhooks ? values.name_webhooks : "",
      url: values.url_webhooks ? values.url_webhooks : "",
      status: values.webhooks_status
        ? values.webhooks_status
        : options_active_webhooks[0]["value"],
      topic: values.webhooks_topic
        ? values.webhooks_topic
        : options_delivery[0]["value"],
    };
    this.props.updateWebhooks(this.props.match.params.id, dataToUpdate);
  };

  render() {
    const { detailWebhooks, updateWebhooksLoading, pageName } = this.props;
    const initialValues = {
      name_webhooks: detailWebhooks.data.name || "",
      webhooks_topic: detailWebhooks.data.topic || options_delivery[0]["value"],
      url_webhooks: detailWebhooks.data.url || "",
      webhooks_status:
        detailWebhooks.data.status || options_active_webhooks[0]["value"],
    };
    return (
      <div className="container-fluid">
        <Button
          className="BackToButton d-flex align-items-center"
          onClick={() => {
            this.props.history.push("/settings/webhooks/list");
          }}
        >
          <img src={BackToIcon} alt="back-icon"/>
          <p className="m-0 ml-2">Back to Webhooks</p>
        </Button>
        <div className="d-flex justify-content-between align-items-center my-3">
          <h1 className="h3 mb-0 font-weight-bold">{pageName}</h1>
        </div>
        <Spin spinning={detailWebhooks.loading || updateWebhooksLoading}>
          <div className="card">
            <div className="card-body">
              <Form
                initialValues={initialValues}
                onSubmit={this.onEditFormSubmit}
              ></Form>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  updateWebhooksLoading: state.webhooks.edit.update.loading,
  detailWebhooks: state.webhooks.edit.detail,
});

const mapDispatchToProps = (dispatch) => ({
  getDetailWebhooks: (id) => {
    dispatch(getDetailWebhooks(id));
  },
  updateWebhooks: (id, dataToUpdate) => {
    dispatch(updateWebhooks(id, dataToUpdate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
