import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import FieldText from "utils/fields/FieldText";
import { renderSelectAnt } from "utils/Form";
import {renderRadioAnt} from 'utils/Form';
import { listDefaultShippingServices } from "utils/options";
const index = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <b>Domestic (U.S.) Shipping</b>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <div className="form-group">
            <label className="col-form-label">Less than 15oz. </label>
          </div>
        </div>
        <div className="col-sm-10">
          <div className="form-group">
            <Field
              name="domesticUnder16oz"
              component={renderSelectAnt}
              options={[
                {
                  label: `USPS`,
                  value: `USPS`,
                },
                {
                  label: `DHL Parcels Expedited Domestic`,
                  value: `DHLE`,
                },
              ]}
            />{" "}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group">
            <label className="col-form-label">15oz. - 60lb.</label>
          </div>
        </div>
        <div className="col-sm-10">
          <div className="form-group">
            <Field
              name="domesticOver16oz"
              component={renderSelectAnt}
              options={[
                {
                  label: `USPS`,
                  value: `USPS`,
                },
                {
                  label: `DHL Parcels Plus Expedited Domestic`,
                  value: `DHLPE`,
                },
              ]}
            />{" "}
          </div>
        </div>
        <div className="col-sm-12 mb-4 mt-5">
          <b>International Shipping</b>
        </div>
        <div className="col-sm-2">
          <div className="form-group">
            <label className="col-form-label">Less than 15oz.</label>
          </div>
        </div>
        <div className="col-sm-10">
          <div className="form-group">
            <Field
              name="intlUnder16oz"
              component={FieldText}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group">
            <label className="col-form-label">15oz. - 60lb.</label>
          </div>
        </div>
        <div className="col-sm-10">
          <div className="form-group">
            <Field
              name="intlOver16oz"
              component={FieldText}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-sm-12 mb-4 mt-5">
          <b>Default Shipping Service</b>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <Field
              name="defaultShippingService"
              component={renderRadioAnt}
              options={listDefaultShippingServices}
            />
          </div>
        </div>
      </div>
      <div className="text-center">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ width: 300 }}
        >
          Save
        </button>
      </div>
    </form>
  );
};

const formShipping = reduxForm({
  form: "formShipping",
  enableReinitialize: true,
})(index);
export default connect()(formShipping);
