import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GMButton } from 'components/Button';
import { GMTextbox } from 'components/InputFields';
import { change, reduxForm } from 'redux-form';
import { Wrapper } from './styled';
import { ReactComponent as CopyIcon } from 'images/icons/copy-06.svg';
import FlipCard from 'components/Animation/FlipCard';
import { ReactComponent as CheckIcon } from 'images/icons/check.svg';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showApiKey: false,
      showApiSignature: false,
      copied: false,
    };
  }

  showApiKeyFunc = () => {
    this.setState({ showApiKey: !this.state.showApiKey });
  };
  showApiSignatureFunc = () => {
    this.setState({ showApiSignature: !this.state.showApiSignature });
  };
  clickHandler = (value) => {
    setTimeout(() => {
      this.setState({ copied: true });
    }, 500);
  };

  render() {
    const {
      apiKey,
      deleteApiKey,
      createApiKey,
      handleSubmit,
      value,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        {!apiKey.data.signature ? (
          <div>
            <p className="mt-3">You don't have API credentials</p>
            <GMButton onClick={createApiKey}>Request API key</GMButton>
          </div>
        ) : (
          <Wrapper>
            <div className="apiKey">
              <div className="title">API key:</div>
              <div className="d-flex row col-10">
                <div className="col-6 m-0 p-0">
                  <GMTextbox
                    name="key"
                    maxLength="50"
                    placeholder="Key"
                    type="password"
                    disabled={true}
                  />
                </div>
                <div className="col-4 m-0 p-0">
                  <FlipCard
                    className="flip-card copy mr-2"
                    onClick={() => {
                      navigator.clipboard.writeText(value?.data?.key);
                    }}
                    animation={!1}
                    front={
                      <GMButton
                        color={'SecondaryGray'}
                        size="lg"
                        className="copy-btn"
                      >
                        <CopyIcon className="copy mr-1" />
                        Copy
                      </GMButton>
                    }
                    back={
                      <GMButton
                        color={'SecondaryGray'}
                        size="lg"
                        className="copied-btn"
                      >
                        <CheckIcon className="copied" /> Copied
                      </GMButton>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="apiKey">
              <div className="title">API signature:</div>
              <div className="d-flex row col-10">
                <div className="col-6 m-0 p-0">
                  <GMTextbox
                    name="signature"
                    placeholder="Signature"
                    maxLength="50"
                    type="password"
                    disabled={true}
                  />
                </div>{' '}
                <div className="col-4 m-0 p-0">
                  <FlipCard
                    className="flip-card copy mr-2"
                    onClick={() => {
                      navigator.clipboard.writeText(value?.data?.signature);
                    }}
                    animation={!1}
                    front={
                      <GMButton
                        color={'SecondaryGray'}
                        size="lg"
                        className="copy-btn"
                      >
                        <CopyIcon className="copy mr-1" />
                        Copy
                      </GMButton>
                    }
                    back={
                      <GMButton
                        color={'SecondaryGray'}
                        size="lg"
                        className="copied-btn"
                      >
                        <CheckIcon className="copied" /> Copied
                      </GMButton>
                    }
                  />
                </div>
              </div>
            </div>

            <GMButton type="button" onClick={deleteApiKey} size="lg">
              Remove API key
            </GMButton>
          </Wrapper>
        )}
      </form>
    );
  }
}

export default connect(
  (state) => ({
    profile: state.auth.info.profile,
  }),
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change('FormApiInformation', field, value));
    },
    dispatch,
  })
)(
  reduxForm({
    form: 'FormApiInformation',
    enableReinitialize: true,
  })(Index)
);
