const validate = (values) => {
    const errors = {};
    if (!values.paymentId) {
      errors.paymentId = 'Please enter transaction id';
    }
    if (!values.amount) {
      errors.amount = 'Please enter the amount';
    }
    if (values.amount <= 0) {
      errors.amount = 'Please type in a number that’s larger than 0';
    }
    if (!errors.amount && values.amount > 99999.99) {
      errors.amount = 'Maximum amount 99,999.99';
    }
    if (!errors.amount && !`${values.amount}`.match(/^\d{0,5}(\.?\d{0,2})?$/)) {
      errors.amount = 'Decimal out of range';
    }
    //Instant
    if (values.amountPaypal < 10) {
      errors.amountPaypal =
        'Please type in a number that’s greater than or equal to 10 ';
    }
    if (values.amountPaypal > 99999.99) {
      errors.amountPaypal = 'Maximum amount 99,999.99';
    }
    if (!`${values.amountPaypal}`.match(/^\d{0,5}(\.?\d{0,2})?$/)) {
      errors.amountPaypal = 'Decimal out of range';
    }
  
    if (!values.image) {
      errors.image = 'Please upload an image';
    }
    if (!values.payId) {
      errors.payId = 'Please select Paypal account';
    }
    return errors;
  };
  export default validate;
