import React from 'react';
import Wrapper from './styled';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { options_backend_type } from 'utils/options';
import renderSelectStore from '../renderSelectStore';
import { GMButton } from 'components/Button';
import queryString from 'query-string';
import { Button, Tooltip } from 'antd';
import { setCookie } from 'utils/helper';
import { SHOPIFY_INTEGRATE } from 'web.config';

const Form = (props) => {
  const { handleSubmit, _type } = props;
  const handleNavigateToShopify = () => {
    setCookie(
      SHOPIFY_INTEGRATE,
      `GM_APP_${Math.floor(100000000 + Math.random() * 900000000)}`
    );
    localStorage.setItem("SHOPIFY_INTEGRATE", 'SHOPIFY_INTEGRATE');
    window.open('https://apps.shopify.com/gearment-print-on-demand-solution');
  };
  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <div>
          <Field
            name="type"
            defaultValue="amazon"
            component={renderSelectStore}
            options={options_backend_type.map((item) => ({
              ...item,
              // disabled: item.value === 'ebay',
            }))}
          />
        </div>
        {_type && (
          <div className="next-step">
            {_type === 'gearment-api' ? (
              <Button size="lg" className="api">
                <a
                  href="https://api.gearment.com/"
                  target="blank"
                  style={{ color: 'white' }}
                >
                  View API docs
                </a>
              </Button>
            ) : _type === 'shopify' ? (
              <GMButton
                size="lg"
                className="next-button"
                type="button"
                onClick={handleNavigateToShopify}
              >
                Connect store
              </GMButton>
            ) : (
              <GMButton type="submit" size="lg" className="next-button">
                Next
              </GMButton>
            )}
          </div>
        )}
      </form>
    </Wrapper>
  );
};

const Step1CreateStore = reduxForm({
  form: 'Step1CreateStore',
  enableReinitialize: true,
})(Form);
const selector = formValueSelector('Step1CreateStore');

const mapStateToProps = (state) => {
  const qstr = queryString.parse(window?.location?.search);
  return {
    _type: selector(state, 'type'),
    initialValues: {
      type: qstr?.type || 'amazon',
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change('Step1CreateStore', field, value));
  },
  dispatch,
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(Step1CreateStore)
);
