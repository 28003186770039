import { 
    actionType as TYPE
} from './actions'

const initialState = {
    data_feeds: {
        data: [],
        loading: false,
        params: {},
        total: 0,
        default: {}
    },
   
}

function FeedFormAmazonReducer(state = initialState, action){
    switch(action.type) {
        case TYPE.LIST.REQUEST:
            return {
                ...state,
                data_feeds: {
                    ...initialState.data_feeds,
                    params: action.params,
                    loading: true
                }              
            }
        case TYPE.LIST.SUCCESS:
            return {
                ...state,
                data_feeds: {
                    ...state.data_feeds,
                    loading: false,
                    data: action?.data?.feedForms || [],
                    total: action?.data?.pagination?.total || (action?.data?.feedForms || []).length,
                    default: action?.data?.defaultFeedForm || {}
                },
            }
        case TYPE.LIST.ERROR:
            return {
                ...state,
                data_feeds: {
                    ...state.data_feeds,
                    loading: false
                }  
            }
        default:
            return state
    }
}

export default FeedFormAmazonReducer