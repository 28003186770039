import React from "react";
import { Link } from "react-router-dom";
import help from "images/support.svg";
export default (props) => {
  return (
    <div className="dashboard-section guideline-section px-4 py-3 mb-4">
      <div className="d-flex justify-content-center">
        <img src={help} alt="Help" width="150px" />{" "}
      </div>
      <div>
        <div
          className="text-center"
          style={{ paddingTop: "30px", paddingBottom: "20px" }}
        >
          <h3 className="text-muted">Have you checked our Help Center?</h3>
        </div>
        <div className="text-center text-muted">
            <p>
              You'll find answers to many questions in our FAQ section and video
              tutorial.
            </p>
        </div>

        <div className="d-flex justify-content-center">
          <Link
            to={{
              pathname:
                "https://help.gearment.com/en-us/",
            }}
            target="_blank"
            className="btn btn-primary"
            style={{ borderRadius: "5px" }}
          >
            Helpdesk
          </Link>
        </div>
      </div>
    </div>
  );
};
