import { BASE_WHITE } from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .block-desc {
    display: flex;
    flex-direction: row;
    padding: 24px;
    background: ${BASE_WHITE};
    border-radius: 16px;
    margin-top: 24px;
  }

  .main-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    cursor: text;
    border-radius: 8px;
    height: 44px;
  }
  .tox-tinymce{
    border-radius: 8px;
  }
`;
