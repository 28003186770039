import { lianlianCallback, payoneerCallback } from "apis/payment_menthod";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";
import PageContainer from "components/PageContainer";

export default (props) => {
  const { code, state } = queryString.parse(window.location.search);
  const history = useHistory();
  const dataProcessing = async () => {
    // LianLian
    if (history?.location?.pathname?.indexOf("lianlian-callback") !== -1 && code) {
      const callbackResponse = await lianlianCallback({ code, state });
      if (Array.isArray(callbackResponse)) {
        const errorItem = callbackResponse.find(
          (i) =>
            i?.code?.indexOf("error") !== -1 ||
            i?.code?.indexOf("failed") !== -1 ||
            i?.code?.indexOf("warning") !== -1
        );
        if (errorItem) {
          history.replace("/billing/method", { callbackResponse });
        }
      } else {
        history.replace(
          "/billing/method/new?" +
            new URLSearchParams({ code: "lianlian" }).toString()
        );
      }
      return;
    }
    // payoneer
    if (code) {
      const callbackResponse = await payoneerCallback({ code, state });
      if (Array.isArray(callbackResponse)) {
        const errorItem = callbackResponse.find(
          (i) =>
            i?.code?.indexOf("error") !== -1 ||
            i?.code?.indexOf("warning") !== -1
        );
        if (errorItem) {
          history.replace("/billing/method", { callbackResponse });
        }
      } else {
        history.replace(
          "/billing/method/new?" +
            new URLSearchParams(callbackResponse?.data).toString()
        );
      }
    }
  };
  useEffect(() => {
    dataProcessing();
  }, []);

  return (
    <Spin spinning>
      <PageContainer noBreadcrumb noContainer />
    </Spin>
  );
};
