import React, { useState } from "react";
import { listValidImportFile } from "utils/options";
import { Upload } from "antd";
import classNames from "classnames";
import { API_TOKEN_KEY } from "web.config";
import { getCookie } from "utils/helper";
import { ReactComponent as UploadIcon } from "images/icons/upload-cloud.svg";
import { BLUE_100, BLUE_50, BLUE_500, GRAY_100, GRAY_50, GRAY_600 } from "components/colors";
import { GlowingSVG } from "components/SVG";
import { GMButton } from "components/Button";
import { connect, useDispatch } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { FileProgress } from "../../styled";
import { ReactComponent as FileIcon } from 'images/icons/file-04.svg';
import { ReactComponent as TrashIcon } from 'images/icons/trash-01.svg';

const kb = 1024;

const Renderer = ({
  input,
  linkFileExample,
  uploadFileRef,
  url = "",
  paramName = "",
  data = {},
  meta: { asyncValidating, error, submitFailed },
  fileToImport,
  uploadProgress,
  setUploadProgress,
  ...props
}) => {
  const [uploadError, setUploadError] = useState();
  const dispatch = useDispatch();
  const propsUpload = {
    name: paramName,
    data: data,
    action: url,
    headers: {
      Authorization: `Bearer ${getCookie(API_TOKEN_KEY) /* auth cookie */}`,
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: (info) => {
      setUploadError(undefined);
      if (!listValidImportFile.includes(info.file.type || "")) {
        setUploadError(`${info.file.name} is not a valid file.`);
        return false;
      }
      if (info.file.size > 5e6) {
        setUploadError(`${info.file.name} is greater than 5MB.`);
        return false;
      }
      dispatch(change('FormImportOrder', 'fileToImport', info.file));
      setUploadProgress(0);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    fileList: fileToImport ? [fileToImport] : [],
    ...props
  };
  const fileInfo = (() => {
    if (!fileToImport) {
      return {};
    }
    const rs = {};
    rs.name = fileToImport.name;
    if (fileToImport.size > kb * kb) {
      rs.size = (fileToImport.size / (kb * kb)).toFixed(2);
      rs.unit = 'MB';
    } else {
      rs.size = (fileToImport.size / kb).toFixed(2);
      rs.unit = 'KB';
    }
    return rs;
  })()
  return (
    <div
      className={classNames("w-100 importOrderFile", {
        "async-validating": asyncValidating,
      })}
    >
      <div className="d-flex">
        {!fileToImport ? (
          <Upload.Dragger {...propsUpload}>
            <p className="ant-upload-drag-icon">
              <GlowingSVG
                backgroundColor={GRAY_100}
                borderColor={GRAY_50}
                svgFillColor={GRAY_600}
              >
                <UploadIcon />
              </GlowingSVG>
            </p>
            <p className="ant-upload-text">Drop file to upload or</p>
            <p className="ant-upload-text">
              (<b>200 rows</b> maximum size)
            </p>
            <p className="upload-button">
              <GMButton color="SecondaryGray" type="button">
                Browse file
              </GMButton>
            </p>
          </Upload.Dragger>
        ) : (
          <FileProgress>
            <div className="icon">
              <GlowingSVG
                className="glowing-svg-file"
                backgroundColor={BLUE_100}
                borderColor={BLUE_50}
                svgStrokeColor={BLUE_500}
              >
                <FileIcon />
              </GlowingSVG>
            </div>
            <div className="info">
              <div className="filename">
                {fileInfo?.name}
              </div>
              <div className="filesize">{fileInfo.size} {fileInfo.unit}</div>
              {uploadProgress ? (
                <div className="progress">
                  <div className="progress-barwrapper">
                    <div className="progress-bar" style={{ width: (uploadProgress * 100) + '%' }}></div>
                  </div>
                  <div className="progress-text">
                    {uploadProgress === 1 ? 'Done' : ((uploadProgress || 0) * 100).toFixed(0) + '%'}
                  </div>
                </div>
              ) : null}
            </div>
            <TrashIcon className="remove" onClick={evt => {
              dispatch(change('FormImportOrder', 'fileToImport', undefined));
              return false;
            }} />
          </FileProgress>
        )}
      </div>
      {(submitFailed && error || uploadError) && (
        <span className="invalid-feedback d-block">{error || uploadError}</span>
      )}
    </div>
  );
};

const selector = formValueSelector("FormImportOrder");

export default connect((state) => ({
  fileToImport: selector(state, "fileToImport"),
}))(Renderer);