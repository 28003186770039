import React, { useState, useEffect} from "react";
import { FieldArray } from "redux-form";
import FieldAdditionImages from "utils/fields/FieldAdditionImages";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {isValidUrl} from 'utils/helper';
import {Modal, Spin, notification} from "antd";
import {updateListAdditionalImage} from "apis/campaign";
const validate = (values) => {
    const errors = {};

    if(values.listAdditionalImages && values.listAdditionalImages.length > 0){
        const listAdditionalImagesError = []
        for (let index = 0; index < values.listAdditionalImages.length; index++) {
            const element = values.listAdditionalImages[index];
            const urlErrors = {}
            if(element.url && !isValidUrl(element.url)){
                urlErrors.url = 'Url is invalid'
                listAdditionalImagesError[index] = urlErrors
            }

        }
        if (listAdditionalImagesError.length) {
            errors.listAdditionalImages = listAdditionalImagesError
        }
    }
    return errors;
};
let RenderAddtionalImages = ({ listAdditionalImages = [], campaign = {}, productCampaign = {}, numOfAdditionalImage = 0, name="", ...props }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currNumOfAdditionalImage, setCurrNumOfAdditionalImages] = useState(0);
    useEffect(() => {
        setCurrNumOfAdditionalImages(numOfAdditionalImage);
    }, [numOfAdditionalImage])
    const handleOnUpdateAdditionalImages = () => {
        let values = props?.state?.form[name]?.values || {};
        if(values){
            const urls = [];
            if(values.listAdditionalImages){
                for(let i = 0; i < values.listAdditionalImages.length; i++){
                    if(values.listAdditionalImages[i]['url']){
                        urls.push(values.listAdditionalImages[i]['url']);
                    }
                }
                if(urls.length > 0){
                    const dataToUpdate = {
                        camId: campaign?.id,
                        urls
                    }
                    setLoading(true)
                    updateListAdditionalImage(dataToUpdate, productCampaign?.id)
                    .then(result => {
                        setLoading(false);
                        if(result.code === 'success'){
                            notification.success({
                                message: 'Update list of additional images successfully.',
                                duration: 5
                            });
                            setCurrNumOfAdditionalImages(result?.data?.count || 0);
                        }else{
                            notification.warn({
                                message: result[0] && result[0]['msg'] ? result[0]['msg'] : 'Update list of additional images failed.',
                                duration: 5
                            });
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                    })
                }else{
                    notification.warn({message: 'Please enter image url', duration: 5});
                }
            }
        }
    }
    return (
      <form>
      <p className="text-primary cursor-pointer" onClick={() => {setVisible(true)}}>Addtional Images ({currNumOfAdditionalImage}/5)</p>
      
      <Modal
          title={<h4>{`Additional Images: ${productCampaign?.exProduct?.brand}`}</h4>}
          visible={visible}
          width={1500}
          onCancel={() => {
            setVisible(false)
          }}
          onOk={handleOnUpdateAdditionalImages}
          cancelText={"Close"}
        >
          <Spin spinning={loading}>
            <FieldArray
                    data={listAdditionalImages}
                    name={`listAdditionalImages`}
                    component={FieldAdditionImages}
                />
          </Spin>
        </Modal>
      </form >
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        form: ownProps.name,
        state: state
        // other props...
    }
}

export default React.memo(compose(
    connect(mapStateToProps),
    reduxForm({
        enableReinitialize: true,
        validate
    })
)(RenderAddtionalImages));
