import React, { Component } from 'react';
import { Upload, Spin } from 'antd';
import { S3_CDN } from 'web.config'
import classNames from 'classnames';
import { createFileName, createFolderName, getSizeImage, resizeImage } from "utils/helper";
import { uploading_message } from "utils/options";
import axios from "axios";
import { getPresignedUrlS3 } from "apis/image_design"
import getMomentInstance from "utils/moment";
import { notification } from "antd";
import { uploadArtwork } from "apis/image_design";
import slugify from "utils/slugify"
import { GlowingSVG } from 'components/SVG';
import { ReactComponent as UploadIcon } from 'images/icons/upload-cloud-02.svg';
import { GRAY_100, GRAY_50, GRAY_600 } from 'components/colors';
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      listImageId: [],
    };
  }

  render() {
    const {
      changeFieldValue,
      maximumFile,
      setShowListImage,
      requestName,
      currStore = {},
      multiple = false,
      showUploadList,
      cusId = 0,
      prefixName = 'artwork',
      customPrefix = 'tmp'
    } = this.props;
    const props = {
      accept: ".png",
      listType: "picture",
      showUploadList: showUploadList,
      name: requestName,
      multiple: multiple ? true : false,
      customRequest: async (
        {
          file,
          onError,
          onSuccess        }
      ) => {
        const folderName = createFolderName(cusId);
        const extension = file.name.split('.').pop();
        const fileName = createFileName(extension || 'png', prefixName, cusId);
        const originalName = file.name.replace(`.${extension}`, '');
        const fileType = `image/${extension}`;
        const keyToCreatePresignedUrl = `${customPrefix}/${folderName}/${getMomentInstance(null, 0, false, true)}/${fileName}`;
        const response = await getPresignedUrlS3(keyToCreatePresignedUrl, currStore, fileType);
        if (response && response.code === 'success') {
          axios.put(response.data.url, file, {
            headers: {
              'Content-Type': fileType
            }
          }).then(response => {
            if (parseInt(response.status) === 200) {
              onSuccess({
                code: 'success',
                url: `${S3_CDN}/${keyToCreatePresignedUrl}`,
                folderName: folderName,
                fileName: fileName,
                fileType: fileType,
                extension: extension,
                originalName: originalName
              }, file);
            } else {
              onError();
            }
          });
        }
      },
      beforeUpload: (file, fileList) => {
        if (file.type !== 'image/png') {
          notification.error({
            className: "notic1",
            message: (`${file.name} is not a image file.`)
          });
          return false;
        }
        if (fileList.length <= maximumFile) {
          setShowListImage(true);
          if (file.size <= 2e8) {
            if (fileList[0] && fileList[0]['uid'] == file.uid) {
              const newListImageId = {};
              for (let index = 0; index <= fileList.length - 1; index++) {
                if (fileList[index]['size'] <= 2e8) {
                  newListImageId[fileList[index]['uid']] = index + 1;
                  const extension = file.name.split('.').pop();
                  const originalName = file.name.replace(`.${extension}`, '');
                  changeFieldValue(`listImages.${index + 1}`, {
                    imageTitle: originalName,
                    fileName: `${originalName}.${extension}`,
                    imageLink: '',
                    urlPath: `/${slugify(originalName)}`,
                    status: 0
                  })
                }
              }
              this.setState({ listImageId: newListImageId });
            }
          } else {
            notification.warn({
              className: "notic1",
              message: (`${file.name} is larger than 200MB.`)
            });
            return false;
          }
        } else {
          notification.error({
            className: "notic1",
            message: (`The maximum number of files upload: ${maximumFile} files`)
          });
          return false;
        }
        return true;
      },
      onChange: (info) => {
        const { status, response, uid, originFileObj } = info.file;
        if (status === 'uploading') {
          this.setState({ loading: true })
        }
        if (status === "done" && response.code === 'success') {
          getSizeImage(response.url, async (width, height) => {
            const resizedImage = await resizeImage(originFileObj, 650, Math.round((650 * height) / width), 'file', response.extension.toUpperCase());
            const keyToCreatePresignedUrl2 = `${customPrefix}/${response.folderName}/${getMomentInstance(null, 0, false, true)}/thumbnail/${response.fileName}`;
            const response2 = await getPresignedUrlS3(keyToCreatePresignedUrl2, currStore, response.fileType);
            if (response2 && response2.code === 'success') {
              axios.put(response2.data.url, resizedImage, {
                headers: {
                  'Content-Type': response.fileType
                }
              }).then(response3 => {
                if (parseInt(response3.status) === 200) {
                  const currItemImage = {
                    imageTitle: `${response.originalName}.${response.extension}`,
                    fileName: `${response.originalName}.${response.extension}`,
                    imageLink: `${S3_CDN}/${keyToCreatePresignedUrl2}`,
                    status: 0
                  };
                  uploadArtwork(`${S3_CDN}/${keyToCreatePresignedUrl2}`, `${response.originalName}.${response.extension}`).then(response4 => {
                    if (response4.code === 'success') {
                      changeFieldValue(`listImages.${this.state.listImageId[uid]}`, {
                        ...currItemImage,
                        status: 1
                      })
                    } else {
                      changeFieldValue(`listImages.${this.state.listImageId[uid]}`, {
                        ...currItemImage,
                        status: 2,
                        error: Array.isArray(response4) && response4[0] && response4[0]['clientMsg'] ? response4[0]['clientMsg'] : ''
                      })
                    }

                    this.setState({ loading: false })
                  });
                  changeFieldValue(`listImages.${this.state.listImageId[uid]}`, currItemImage)
                } else {
                  notification.error({
                    className: "notic1",
                    message: `${info.file.name} file upload failed.`
                  });
                  this.setState({ loading: false });
                }
              });
            }
          })
        }
        if (status === "error") {
          notification.warn({
            className: "notic1",
            message: (`${info.file.name} file upload failed.`)
          });
          this.setState({ loading: false })
        }
      },
    };
    return (
      <div className={classNames('w-100 upload-w-100', this.props.classNameContainer)}>
        <Spin spinning={this.state.loading} tip={uploading_message}>
          <div className={classNames({ 'd-block': (this.props.showListImage && this.props.listImages.length > 0) })}>
            <Upload.Dragger {...props} className={classNames('upload', props.className)}>
              <p className="ant-upload-drag-icon">
                {/* <UploadOutlined /> */}
                <GlowingSVG
                  backgroundColor={GRAY_100}
                  borderColor={GRAY_50}
                  svgStrokeColor={GRAY_600}
                >
                  <UploadIcon />
                </GlowingSVG>
              </p>
              <div className="text-upload">
                <b className="click-to">Click to upload</b> or drag and drop
                <br />
                Only <b className="text-muted">PNG</b> is supported up
                to a max file size of <b className="text-muted">200MB</b>
              </div>
            </Upload.Dragger>
          </div>
        </Spin>
      </div>
    );
  }
};

export default index;