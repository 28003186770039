const validate = (values) => {
  const errors = {};

  if (!values.imgLogo) {
    errors.imgLogo = "Please upload your image at here.";
  }
  if (!values.message || values.message.replace(/\s+/g, "").length === 0) {
    errors.message = "Please enter your message at here.";
  }else if(values.message.length > 350){
      errors.message = "The maximum of message length is 350. Please check again."
  }
  return errors;
};
export default validate;
