import { BASE_WHITE, GRAY_200 } from "components/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${BASE_WHITE};
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${GRAY_200};
`;
