import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthLogin from './scenes/Login';
import Register from './scenes/Register';
import Forgot from './scenes/Forgot';
import ResetPassword from './scenes/ResetPassword';
import AuthLogout from './scenes/Logout';
import SocialRegister from './scenes/SocialRegister';
import UpdateInformation from './scenes/UpdateInformation';
import VerifyEmail from './scenes/VerifyEmail';
import VerifyStaffAccount from './scenes/VerifyStaffAccount';

export default [
  {
    key: 'auth',
    name: 'Auth',
    component: () => <Redirect exact to="/auth/login" />,
    path: '/auth',
    hide: true,
    template: 'auth',
    children: [
      {
        key: 'auth.login',
        name: 'Login',
        component: AuthLogin,
        path: '/auth/login',
        hide: true,
        is_public: true,
        template: 'auth',
      },
      // {
      //   key: 'auth.login',
      //   name: 'Login',
      //   component: AuthLogin,
      //   path: '/stores/callback_shopify',
      //   hide: true,
      //   is_public: true,
      //   template: 'auth',
      // },
      {
        key: 'auth.logout',
        name: 'Logout',
        component: AuthLogout,
        path: '/auth/logout',
        hide: true,
        is_public: true,
        template: 'auth',
      },
      {
        key: 'auth.register',
        name: 'Register',
        component: Register,
        path: '/auth/register',
        hide: true,
        is_public: true,
        template: 'auth',
      },
      {
        key: 'auth.forgot',
        name: 'Forgot Password',
        component: Forgot,
        path: '/auth/forgot',
        hide: true,
        is_public: true,
        template: 'auth',
      },
      {
        key: 'auth.change-password',
        name: 'Change password',
        component: ResetPassword,
        path: '/auth/change-password/:token',
        hide: true,
        is_public: true,
        template: 'auth',
      },
      {
        key: 'auth.verify-staff-account',
        name: 'Verify satff account',
        component: VerifyStaffAccount,
        path: '/auth/verify-email-staff/:token',
        hide: true,
        is_public: true,
        template: 'auth',
      },
      {
        key: 'auth.verify-email',
        name: 'Verify email',
        component: VerifyEmail,
        path: '/auth/verify-email/:token',
        hide: true,
        is_public: true,
        template: 'auth',
      },
      {
        key: 'auth.google.register',
        name: 'Register',
        component: SocialRegister,
        path: '/auth/google-register',
        hide: true,
        is_public: true,
        template: 'auth',
      },
      {
        key: 'auth.facebook.register',
        name: 'Register',
        component: SocialRegister,
        path: '/auth/facebook-register',
        hide: true,
        is_public: true,
        template: 'auth',
      },
      {
        key: 'auth.update.information',
        name: 'Update information',
        component: UpdateInformation,
        path: '/auth/update-information',
        hide: true,
        is_public: true,
        template: 'auth',
      },
    ],
  },
];
