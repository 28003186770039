import {
  ERROR_25, ERROR_300, ERROR_600, ERROR_700, GRAY_400
} from "components/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 480px;
  .logo {
    margin-top: 60px;
  }
  .header, .form-group {
    margin-top: 24px;
  }
  button.cta-changepss {
    width: 100%;
  }
  
  .error-list {
    background: ${ERROR_25};
    border: 1px solid ${ERROR_300};
    border-radius: 12px;
    padding: 16px 18px;
    width: 100%;
    list-style: none;
    li {
      position: relative;
      padding-left: 34px;
      span {
        display: block;
        &.error {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: ${ERROR_700};
        }
        &.detail {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${ERROR_700};
        }
      }
    }
    svg {
      stroke: ${ERROR_600};
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  
  .help-tooltip {
    width: 16px;
    height: 16px;
    stroke: ${GRAY_400};
    margin-left: 6px;
    display: inline-block;
  }
`;
