import { notification } from "antd";
import {
  WEBHOOKS_UPDATE_REQUEST,
  WEBHOOKS_UPDATE_FAIL,
  WEBHOOKS_UPDATE_SUCCESS,
  WEBHOOKS_DETAIL_REQUEST,
  WEBHOOKS_DETAIL_FAIL,
  WEBHOOKS_DETAIL_SUCCESS,
} from "./constants";
const initialState = {
  update: {
    loading: false,
  },
  detail: {
    loading: false,
    data: {},
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case WEBHOOKS_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          loading: true,
        },
      };
    case WEBHOOKS_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...initialState.detail,
          data: action.data.webhook,
          loading: false,
        },
      };
    case WEBHOOKS_DETAIL_FAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
        },
      };

    case WEBHOOKS_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      };
    case WEBHOOKS_UPDATE_SUCCESS:
      notification.success({
        message: action?.clientMsg || "Update webhook successfully.",
        duration: 5
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
        },
      };
    case WEBHOOKS_UPDATE_FAIL:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Update webhook failed.',
        duration: 5
      });
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
