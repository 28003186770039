import axios from 'utils/request';

const prefix = '/terms-agreement';

export const getPreviewIndemnication = (data) => {
  return axios({
    method: 'POST',
    url: `${prefix}/preview`,
    data,
  });
};
export const createIndemnication = (data) => {
  return axios({
    method: 'POST',
    url: `${prefix}`,
    data,
  });
};

export const viewIndem = () => {
  return axios({
    method: 'GET',
    url: `${prefix}`,
  });
};

export const getTemplate = () => {
  return axios({
    method: 'GET',
    url: `${prefix}/template `,
  });
};
