const validate = values => {
    let errors = {};
    if(!values.name_webhooks){
        errors.name_webhooks = true;
    }
    if(!values.url_webhooks){
        errors.url_webhooks = true;
    }
    return errors
};
export default validate;