export const actionType = {
  VIEW_INDEMNICATION: {
      REQUEST: 'VIEW_INDEMNICATION.REQUEST',
      SUCCESS: 'VIEW_INDEMNICATION.SUCCESS',
      ERROR: 'VIEW_INDEMNICATION.ERROR',
    },
    VIEW_TEMPLATE: {
      REQUEST: 'VIEW_TEMPLATE.REQUEST',
      SUCCESS: 'VIEW_TEMPLATE.SUCCESS',
      ERROR: 'VIEW_TEMPLATE.ERROR',
    },
    CREATE_INDEMNICATION: {
      REQUEST: 'CREATE_INDEMNICATION.REQUEST',
      SUCCESS: 'CREATE_INDEMNICATION.SUCCESS',
      ERROR: 'CREATE_INDEMNICATION.ERROR',
    },
  };
  
  export function getViewFormIndemnicationAction() {
    return {
      type: actionType.VIEW_INDEMNICATION.REQUEST,
    };
  }
  export function createIndemnicationAction(data) {
    return {
      type: actionType.CREATE_INDEMNICATION.REQUEST,
      data
    };
  }
  export function getTemplateAction() {
    return {
      type: actionType.VIEW_TEMPLATE.REQUEST,
    };
  }
  