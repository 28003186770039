import NotificationList from 'scenes/Notifications/scenes/List'
import Detail from 'scenes/Notifications/scenes/Detail'

export default [
    {   
        key: 'notify',
        name: 'Notifications',
        component: NotificationList,
        path: '/notification', 
        icon: null,    
        template: 'default', 
        isPublic: true                 
    },   
    {
        key: 'notify.detail',
        name: 'Notification',
        component: Detail,
        path: '/notification/:id',
        icon: null,
        template: 'default',  
        isPublic: true
    }
]