import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import { ORDER_DETAIL_REQUEST } from "../Detail/constants";
import {
  getListRemovedOrderItem,
  rollbackRemovedOrderItem,
  removeOrderItem,
} from "apis/order_item";

function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListRemovedOrderItem, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST_REMOVED_ORDER_ITEM.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_REMOVED_ORDER_ITEM.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_REMOVED_ORDER_ITEM.ERROR, error })]);
  }
}

function* removeOrderItemSaga(action) {
  const { data } = action;
  try {
    const response = yield call(removeOrderItem, data);
    if (response.code === "success") {
      yield put({ type: TYPE.REMOVE.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.REMOVE.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.REMOVE.ERROR, error })]);
  }
}

function* rollbackRemovedOrderItemSaga(action) {
  const { data, params } = action;
  try {
    const response = yield call(rollbackRemovedOrderItem, data);
    yield all([
      put({ type: TYPE.ROLLBACK.SUCCESS, errors: response }),
    ]);
    if(params){
      yield  put({
          type: ORDER_DETAIL_REQUEST,
          id: data.orderId
        });
      
    }else{
      yield put({

      });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.ROLLBACK.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.LIST_REMOVED_ORDER_ITEM.REQUEST, getListSaga);
  yield takeLatest(TYPE.REMOVE.REQUEST, removeOrderItemSaga);
  yield takeLatest(TYPE.ROLLBACK.REQUEST, rollbackRemovedOrderItemSaga);
}

export default watcher;
