import { isValidUrl } from "utils/helper";
const validate = (values) => {
  let errors = {};
  if (!values.showName || values.showName.replace(/\s+/g, "").length === 0) {
    errors.showName = "This field cannot be left blank";
  }
  if (!values.url) {
    errors.url = "This field cannot be left blank";
  } else if (
    !values.url.includes("https://stores.ebay.com/") ||
    !isValidUrl(values.url)
  ) {
    errors.url = "The format of url is invalid.";
  }
  // if (!values.amazonMarketPlaceId) {
  //   errors.amazonMarketPlaceId = "This field cannot be left blank";
  // }
  return errors;
};
export default validate;
