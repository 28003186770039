import { Modal } from 'antd';
import {
  BASE_WHITE,
  GRAY_300,
  GRAY_500,
  GRAY_900,
  PRIMARY_500,
  PRIMARY_600,
} from 'components/colors';
import { BREAKPOINTS } from 'components/const';
import styled, { createGlobalStyle } from 'styled-components';

export const Wrapper = styled.div`
  .placeholder-img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
  }

  .position-relative {
    position: relative;
    .placeholder-img {
      background-color: ${GRAY_300};
      opacity: 0.8;
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      margin: 0;
    }
    svg {
      stroke: ${GRAY_900};
      position: relative;
      top: 0;
      left: 0;
    }
    &:hover {
      .placeholder-img {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .upload-icon {
    color: #ccc;
    font-size: 80px;
  }
`;
export const GlobalStyle = createGlobalStyle`
  .block-img {
    position: relative;
    .icon {
      display: none;
      position: absolute;
      top: 12px;
      right: 0;
      z-index: 1;
      svg {
        stroke: ${GRAY_500};
      }
    }
    &:hover {
      .icon {
        display: block;
        cursor: pointer;
      }
    }
  }
  
  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-header{
    border-radius: 16px;
    border-bottom: none;
    padding-bottom: 0px;
  }
  .ant-modal-title{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
  }
  .ant-modal-body{
    //padding-top:0px;
    //padding-bottom: 0px;
  }
  .name-img{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    
    display: flex;
    align-items: center;
    
    /* Gray/900 */
    
    color: #101828;
    margin-bottom: 28px;
    margin-top: 12px;
    margin-left: 2px;
    display: inline-block;
    width: 203px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .modal-upload{
    max-height: 500px;
    overflow: auto;
    @media (min-width: ${BREAKPOINTS.md}) {
      max-height: 350px;
    }
  }

`;
export const ImageUploadWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  &.uploaded {
    .ant-upload.ant-upload-select-picture-card {
      border: none;
      background-color: transparent;
    }
  }
  .upload-success {
    border: 1px dashed #eaecf0;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    padding: 8px;
    height: 100px;
  }
  .upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    width: 950px;
    max-width: 100%;
    max-height: 100vh;

    /* Base/White */

    background: ${BASE_WHITE};
    /* Gray/200 */

    border: 1px dashed #eaecf0;
    border-radius: 12px;
  }

  .upload-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    max-width: 100%;
    max-height: 100vh;
    background: #ffffff;
    border-radius: 12px;
  }
  .upload-success {
    border: 1px dashed #eaecf0;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    padding: 8px;
    height: 100px;
  }
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }
  .text-upload {
    font-size: 14px;
  }
  .click-to {
    color: ${PRIMARY_500};
  }
`;

export const ExtendModal = styled(Modal)`
  .loadmore .cursor-pointer {
    &:hover {
      color: ${PRIMARY_600};
    }
  }
`;
