import React from 'react'
import classNames from 'classnames'
const renderTextarea = ({
  input,
  className,
  showLength,
  meta: { asyncValidating, touched, error, submitFailed },
  ...props
}) => { 
    return (
      <div className={asyncValidating ? 'async-validating' : ''}>
        <textarea {...input} {...props} className={classNames('form-control', className, { error : ' is-invalid'})}/>
        { showLength &&<span className="small text-muted border" style={{ position: 'absolute', right: 25, bottom: 0, padding: '0 2px'}}>{input.value.length}</span> }
        {(error && submitFailed) && <span className="invalid-feedback">{error}</span>}
      </div>
    )
}

export default React.memo(renderTextarea);