import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  change,
  formValueSelector,
  reduxForm,
  submit,
} from 'redux-form';
import validate from './validate';
import { Drawer } from 'antd';
import AdvancedFilter from './AdvancedFilter';
import CloseIcon from 'images/x2.svg';
import { ReactComponent as SearchIcon2 } from 'images/icons/search-md.svg';
import { ReactComponent as ClearIcon } from 'images/icons/x-close.svg';
import { ReactComponent as FilterIcon } from 'images/icons/filter-lines.svg';
import { ReactComponent as HelpIcon } from 'images/icons/info-circle.svg';
import { GMTextbox } from 'components/InputFields';
import { Tooltip } from 'antd';
import { debounce } from 'lodash';
class index extends Component {
  state = {
    advance_search: false,
  };
  onCloseAdvancedSearch = () => {
    this.setState({
      advance_search: false,
    });
  };
  onOpenAdvancedSearch = () => {
    this.setState({
      advance_search: true,
    });
  };
  debounceAutoSubmit = debounce((value = '') => {
    this.props.dispatch(submit('formFilterOrderList'));
  }, 700);

  render() {
    const {
      handleSubmit,
      stores,
      exproducts,
      changeFieldValue,
      productId,
      colorId,
      handleAdvancedSubmit,
      initialValues,
      formFilterOrderListValues,
    } = this.props;
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="filter-section">
            <GMTextbox
              name="orderName"
              className="id-search"
              placeholder="Search by keyword"
              iconBefore={<SearchIcon2 />}
              iconAfter={(
                <>
                  {formFilterOrderListValues?.orderName?.length > 0 ? (
                    <ClearIcon
                      className="clear"
                      onClick={() => {
                        this.props.dispatch(change('formFilterOrderList', 'orderName', ''));
                        this.debounceAutoSubmit();
                      }}
                    />
                  ) : null}
                  <Tooltip title="Search by reference ID, order name/ID.">
                    <HelpIcon className="search-tooltip" />
                  </Tooltip>
                </>
              )}
              onChange={this.debounceAutoSubmit}
            />
            <div
              className="filter-button"
              onClick={this.onOpenAdvancedSearch}
            >
              <FilterIcon className="filter" />
              Filters
            </div>
          </div>
        </form>
        <Drawer
          title={<b className="Filter">Filter</b>}
          className="custom-filter"
          placement="right"
          closable={true}
          onClose={this.onCloseAdvancedSearch}
          visible={this.state.advance_search}
          closeIcon={
            <img src={CloseIcon} style={{ width: '20px' }} alt="Close icon" />
          }
          width={this.props.is_mobile ? '80%' : '480px'}
        >
          <AdvancedFilter
            onCloseAdvancedSearch={this.onCloseAdvancedSearch}
            onSubmit={handleAdvancedSubmit}
            stores={stores}
            exproducts={exproducts}
            changeFieldValue={changeFieldValue}
            productId={productId}
            initialValues={initialValues}
            colorId={colorId}
          />
        </Drawer>
      </>
    );
  }
}
const selector = formValueSelector('formFilterOrderList');
index = reduxForm({
  form: 'formFilterOrderList',
  validate,
  enableReinitialize: true,
})(index);

const mapStateToProps = (state) => ({
  productId: selector(state, 'productId'),
  colorId: selector(state, 'colorId'),
  formFilterOrderListValues: state?.form?.formFilterOrderList?.values,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeFieldValue: function (field, value) {
      dispatch(change('formFilterOrderList', field, value));
    },
  };
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(index));
