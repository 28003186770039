import { all } from "redux-saga/effects";
import ListSaga from "./scenes/List/saga";

// import EditSaga from './scenes/Edit/saga'
import AmazonSaga from "./scenes/Amazon/saga";
import AmazonEditSaga from "./scenes/AmazonEdit/saga";
import GoogleSaga from "./scenes/Google/saga";
import GoogleEditSaga from "./scenes/GoogleEdit/saga";

// import DeleteSaga from './scenes/Delete/saga'

export default function* () {
  yield all([
    ListSaga(),
    // EditSaga(),
    AmazonSaga(),
    AmazonEditSaga(),
    GoogleSaga(),
    GoogleEditSaga(),
    // DeleteSaga(),
  ]);
}
