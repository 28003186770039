export const actionType = {
    LIST: {
        REQUEST: 'NOTIFICATION.LIST.LIST.REQUEST',
        SUCCESS: 'NOTIFICATION.LIST.LIST.SUCCESS',
        ERROR: 'NOTIFICATION.LIST.LIST.ERROR',
    },
    READ: {
        REQUEST: 'NOTIFICATION.READ.REQUEST',
        SUCCESS: 'NOTIFICATION.READ.SUCCESS',
        ERROR: 'NOTIFICATION.READ.ERROR',
    },
    MARK_AS_READ: {
        REQUEST: 'NOTIFICATION.LIST.MARK_AS_READ.REQUEST',
        SUCCESS: 'NOTIFICATION.LIST.MARK_AS_READ.SUCCESS',
        ERROR: 'NOTIFICATION.LIST.MARK_AS_READ.ERROR',
    },
};

export function getList(params) {
    return {
        type: actionType.LIST.REQUEST,
        params
    }
}
export function getListNotiRead(params) {
    return {
        type: actionType.READ.REQUEST,
        params
    }
}
export function markAsRead() {
    return {
        type: actionType.MARK_AS_READ.REQUEST,
    }
}