import { combineReducers } from "redux";
import list from "./scenes/List/reducer";
import edit from "./scenes/Edit/reducer";
import create from "./scenes/Create/reducer";
import schedule_report from "./scenes/ScheduleReport/reducer";
import schedule_report_detail from "../Stores/scenes/ScheduelReportDetail/reducer";
const reducer = combineReducers({
  list,
  edit,
  create,
  schedule_report,
  schedule_report_detail,
});

export default reducer;
