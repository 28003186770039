import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { resetPassword, verifyToken } from './actions';
import Form from './components/form';
import GMHelmet from 'components/GMHelmet';

class Index extends Component {
  componentDidMount() {
    this.props.verifyToken({
      resetPasswordToken: this.props.match.params.token,
    });
  }

  handleOnSubmitPassWord = (values) => {
    this.props.resetPassword({
      ...values,
      resetPasswordToken: this.props.match.params.token,
    });
  };
  render() {
    const { loading, tokenValid } = this.props;
    return (
      <>
        <GMHelmet />
        <div className="bg-white w-100">
          <Spin spinning={loading}>
            <Form
              tokenValid={tokenValid}
              verifyTokenloading={loading}
              onSubmit={this.handleOnSubmitPassWord}
            />
          </Spin>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.auth.resetPassword.loading,
    tokenValid: state.auth.resetPassword.verifiedToken.tokenValid,
  }),
  (dispatch) => ({
    resetPassword: (data) => {
      dispatch(resetPassword(data));
    },
    verifyToken: (data) => {
      dispatch(verifyToken(data));
    },
  })
)(Index);
