import React from "react";
import { Redirect } from "react-router-dom";

import Auth from "scenes/Auth/routers";

import Errors from "scenes/Errors/routers";

import Users from "scenes/Users/routers";

import Mockups from "scenes/Mockups/routers";

import Campaigns from "scenes/Campaigns/routers";

import Collections from "scenes/Collections/routers";

import SyncProducts from "scenes/SyncProducts/routers";

import Promotions from "scenes/Promotions/routers";

import Analytics from "scenes/Analytics/routers";

import Orders from "scenes/Orders/routers";
import Profits from "scenes/Profits/routers";
import Stores from "scenes/Stores/routers";
import Transactions from "scenes/Transactions/routers";
import DataFeeds from "scenes/DataFeed/routers";
import Settings from "scenes/Settings/routers";
import Notifications from "scenes/Notifications/routers";
import Webhooks from "scenes/Webhooks/routers";
import ConfiguationGiftMessage from "scenes/ConfiguationGiftMessage/routers";
import Userlabel from "scenes/Userlabel/routers";
import GiftMessage from "scenes/GiftMessage/routers";
import ImageLibrary from "scenes/ImageLibrary/router";
import Invoice from "scenes/Invoice/routers";
import Dashboard from "scenes/Dashboard/router";
import BillingPaymentAccount from "scenes/BillingPaymentAccount/router";
import Help from "scenes/Help/router";
import NotificationsShipping from "scenes/NotificationsShipping/routers";
import LegalInfor from "scenes/LegalInfo/routers";
import Shipping from "scenes/Shipping/routers";
import General from "scenes/General/routers";
import ApiCredential from "scenes/ApiCredential/routers";
import Logs from "scenes/Logs/routers";
import ConfigBuyTracking from "scenes/ConfigBuyTracking/routers";
import Indemnification from "scenes/Indemnification/routers";
import TopUp from "scenes/TopUp/routers";




export default [
  ...Auth,
  ...Errors,
  ...Users,
  ...Dashboard,
  ...Mockups,
  ...Campaigns,
  ...ImageLibrary,
  ...Collections,
  ...SyncProducts,
  ...Analytics,
  ...Promotions,
  ...Orders,
  ...Profits,
  ...Stores,
  ...Transactions,
  ...DataFeeds,
  ...Settings,
  ...Notifications,
  ...Webhooks,
  ...ConfiguationGiftMessage,
  ...Userlabel,
  ...GiftMessage,
  ...Invoice,
  ...NotificationsShipping,
  ...LegalInfor,
  ...BillingPaymentAccount,
  ...Help,
  ...Shipping,
  ...General,
  ...ApiCredential,
  ...Logs,
  ...ConfigBuyTracking,
  ...TopUp,
  ...Indemnification,
  {
    key: "campaigns",
    name: "Campaign",
    component: () => <Redirect exact to="/dashboard" />,
    path: "/",
    exact: true,
    hide: true,
    icon: null,
    template: "default",
  },
];
