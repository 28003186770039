import React from 'react';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import validate from './validate';
import { renderRadioAnt } from 'utils/Form';
import renderCheckboxGroup from 'utils/Form/renderCheckboxGroup';
import { Alert, Tooltip } from 'antd';
import { GMTextbox } from 'components/InputFields';
import { GMButton } from 'components/Button';
import { useEffect } from 'react';
import { ReactComponent as Icon } from 'images/icons/alert-circle.svg';
import { Wrapper } from './styled';

let Index = ({
  handleSubmit,
  handleOnChangePermission = null,
  changeFieldValue,
  viewFeedPermission,
  fullPermission,
}) => {
  useEffect(() => {
    if (fullPermission?.[0] + '' === '1') {
      changeFieldValue('createFeedPermission', [1]);
    } else if (
      viewFeedPermission?.[0] !== 0 ||
      viewFeedPermission?.[0] === undefined
    ) {
      changeFieldValue('createFeedPermission', 0);
    }
  }, [changeFieldValue, fullPermission, viewFeedPermission]);
  return (
    <Wrapper>
      {' '}
      <form onSubmit={handleSubmit}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <GMTextbox label="Email" name="email" showError disabled />
              </div>
              <div className="form-group">
                <Field
                  name="status"
                  component={renderRadioAnt}
                  onChooseFullPermisison={handleOnChangePermission}
                  options={[
                    {
                      label: `Active`,
                      value: 1,
                    },
                    {
                      label: `Deactive`,
                      value: 2,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="col-md-6 gp2">
              <div>
                <Field
                  name="fullPermission"
                  component={renderCheckboxGroup}
                  onChooseFullPermisison={handleOnChangePermission}
                  colSpan={24}
                  options={[
                    {
                      label: `This staff account will have full permissions`,
                      value: 1,
                    },
                  ]}
                />
              </div>
              <p className="font-weight-bold">Edit permissions</p>
              <div>
                <p className="font-weight-bold">Product</p>
                <div>
                  <Field
                    name="createProductPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `Create product`,
                        value: 1,
                      },
                    ]}
                  />
                  <Field
                    name="syncProductPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `Sync products`,
                        value: 1,
                      },
                    ]}
                  />
                </div>
              </div>
              <div>
                <p className="font-weight-bold">Order</p>
                <div>
                  <Field
                    name="createOrderPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `Create orders (Manual/Label orders)`,
                        value: 1,
                      },
                    ]}
                  />
                  <Field
                    name="deleteOrderPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `Delete orders`,
                        value: 1,
                      },
                    ]}
                  />
                  <Field
                    name="approveOrderPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `Approve orders`,
                        value: 1,
                      },
                    ]}
                  />
                  <Field
                    name="matchOrderPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `Match orders`,
                        value: 1,
                      },
                    ]}
                  />
                  <Field
                    name="syncOrderPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `Sync orders`,
                        value: 1,
                      },
                    ]}
                  />
                  <Field
                    name="importOrderPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `Import orders`,
                        value: 1,
                      },
                    ]}
                  />
                  <Field
                    name="cancelOrderPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `Cancel orders`,
                        value: 1,
                      },
                    ]}
                  />
                  <Field
                    name="viewAllOrderPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: (
                          <div>
                            View all orders
                            <Tooltip
                              title={
                                <div>
                                  - If granted, Staff can view both orders which
                                  not belong to any store and orders belong to
                                  the Staff's assgined store.
                                  <br />- If not granted, Staff can only view
                                  orders belong to the Staff's assigned store
                                </div>
                              }
                            >
                              <Icon />
                            </Tooltip>
                          </div>
                        ),
                        value: 1,
                      },
                    ]}
                  />
                </div>
              </div>
              <div>
                <p className="font-weight-bold">Transactions</p>
                <div>
                  <Field
                    name="viewTransactionPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `View`,
                        value: 1,
                      },
                    ]}
                  />
                </div>
              </div>
              <div>
                <p className="font-weight-bold">Setting</p>
                <div>
                  <Field
                    name="viewSettingPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: (
                          <div>
                            View
                            <Tooltip
                              title={
                                <div>
                                  The payment setting is only available for the
                                  primary account.
                                </div>
                              }
                            >
                              <Icon />
                            </Tooltip>
                          </div>
                        ),
                        value: 1,
                      },
                    ]}
                  />
                </div>
              </div>
              <div>
                <p className="font-weight-bold">Store</p>
                <div>
                  <Field
                    name="createStorePermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `Add Store`,
                        value: 1,
                      },
                    ]}
                  />
                </div>
              </div>
              <div>
                <p className="font-weight-bold">Feed Configuration</p>
                <div>
                  <Field
                    name="viewFeedPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: `View feed`,
                        value: 1,
                      },
                    ]}
                  />
                  {viewFeedPermission?.[0] === 1 && (
                    <Field
                      name="createFeedPermission"
                      component={renderCheckboxGroup}
                      onChooseFullPermisison={handleOnChangePermission}
                      colSpan={24}
                      options={[
                        {
                          label: `Add feed`,
                          value: 1,
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
              <div>
                <p className="font-weight-bold">Payment</p>
                <div>
                  <Field
                    name="paymentOrderPermission"
                    component={renderCheckboxGroup}
                    onChooseFullPermisison={handleOnChangePermission}
                    colSpan={24}
                    options={[
                      {
                        label: (
                          <div>
                            Make payment
                            <Tooltip
                              title={
                                <div>
                                  If granted, a staff member can process payment
                                  for created orders, which will automatically
                                  update the order status from ‘Unapproved' to
                                  'Approved' and from 'Unlocked' to 'Locked.
                                </div>
                              }
                            >
                              <Icon />
                            </Tooltip>
                          </div>
                        ),
                        value: 1,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-3 cta-container clearfix">
            <GMButton
              type="submit"
              className="btn btn-primary mt-2 font-size-16 Rectangle-btn"
            >
              Update
            </GMButton>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

Index = reduxForm({
  form: 'formEditAccount',
  enableReinitialize: true,
  validate,
})(Index);
const selector = formValueSelector('formEditAccount');

export default connect(
  (state) => ({
    viewFeedPermission: selector(state, 'viewFeedPermission'),
    fullPermission: selector(state, 'fullPermission'),
  }),
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change('formEditAccount', field, value));
    },
  })
)(Index);
