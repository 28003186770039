import React, { useEffect, useState } from "react";
import { ContextMenuWrapper2, SVGWrapper } from "../styled";
import { Dropdown, notification } from "antd";
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import { getDetail } from 'apis/orders';
import { memo } from "react";
import { connect } from "react-redux";
import { fncCondRemap, invalidActionNotify } from "scenes/Orders/utils";

const Menu = ({ record, actions, profile }) => {
  const [item, setItem] = useState(record);
  const [itemActions, setItemActions] = useState(actions);

  // const actions = fncCondRemap(record, orderItemActions(record), () => notification.warning({ message: invalidActionNotify, duration: 5 }));
  useEffect(() => {
    setItemActions(fncCondRemap([record], actions, () => notification.warning({ message: invalidActionNotify, duration: 5 })));
  }, [record, actions])
  return (
    <Dropdown
      placement="topLeft"
      overlay={
        <ContextMenuWrapper2>
          {item?.platformId && item?.platformId > 0 && (
            <div>
              {item.paymentStatus === 0 && profile?.primaryId === 0 && (<span
                className="itemcta"
                onClick={itemActions?.paymentOrder}
              >
                Make payment
              </span>
              )}
              {parseInt(item?.isApproved) === 0 &&
                parseInt(item?.orderMatch?.isImport) === 1 &&
                parseInt(item?.transactionId) === 0 && (
                  <span
                    className="itemcta"
                    onClick={itemActions?.approveOrder}
                  >
                    Approve order
                  </span>
                )}
              {parseInt(item?.locked) === 0 &&
                parseInt(item?.isApproved) === 1 &&
                parseInt(item?.paymentStatus) === 0 &&
                parseInt(item?.transactionId) === 0 && (
                  <span
                    onClick={itemActions?.unapproveOrder}
                    className="itemcta"
                  >
                    Unapprove order
                  </span>
                )}

              {parseInt(item?.paymentStatus) === 1 &&
                parseInt(item?.locked) === 1 && (
                  <span onClick={itemActions?.viewInvoice} className="itemcta">View invoice</span>
                )}
              {parseInt(item?.locked) === 0 &&
                parseInt(item?.verifiedAddress) === 1 &&
                parseInt(item?.transactionId) !== 0 &&
                parseInt(item?.paymentStatus) === 0 &&
                parseInt(item?.orderMatch?.isImport) !== 2 &&
                parseInt(item?.isApproved) === 0 &&
                parseInt(item?.unlockedAt) !== 0 && (
                  <span onClick={itemActions?.lockOrder} className="itemcta">Lock order</span>
                )}
              {item?.hasNotCompletedIssue === false &&
                parseInt(item?.transactionId) !== 0 && (
                  <span onClick={itemActions?.createIssue} className="itemcta">Create issue</span>
                )}

              {parseInt(item?.platformId) === 9 ||
                parseInt(item?.platformId) !== 17 ? (
                <span onClick={itemActions?.duplicateOrder} className="itemcta">
                  Duplicate order
                </span>
              ) : (
                ''
              )}
            </div>
          )}
        </ContextMenuWrapper2>
      }
      className="dropdown"
      trigger={['click']}
    >
      <SVGWrapper>
        <MoreAction />
      </SVGWrapper>
    </Dropdown>
  );
}
export default memo(connect(
  (state) => ({
    profile: state?.auth?.info?.profile,
  }),
)(Menu));
