export const AUTH = {
    verify: {
        request: 'AUTH_VERIFY_REQUEST',
        success: 'AUTH_VERIFY_SUCCESS',
        error: 'AUTH_VERIFY_ERROR',
    },
    SYSTEM_SETTING: {
        REQUEST: 'SYSTEM_SETTING.REQUEST',
        SUCCESS: 'SYSTEM.SETTING.RESPONSE',
        ERROR: 'SYSTEM.SETTING.ERROR'
    }
};
export function verify(){
    return {
        type: AUTH.verify.request
    }
}
export function getSystemSetting(){
    return {
        type: AUTH.SYSTEM_SETTING.REQUEST
    }
}
