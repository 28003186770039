import React, { useState, useEffect } from "react";
import { Collapse, Spin } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import axios from "axios";
import { TipsWrapper } from "scenes/Dashboard/styled";
import NoImg from "images/no-image.jpg";
import getMomentInstance from "utils/moment";
import { ReactComponent as NextIcon } from "images/icons/arrow-narrow-right.svg";

const { Panel } = Collapse;

const tips = [
  {
    link: 'https://gearment.com/blog/top-5-best-selling-print-on-demand-product-to-sell/',
    thumbnailUrl: 'https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img/https://gearment.com/wp-content/uploads/2021/10/gearment-top-best-selling-products-to-sell.png',
    title: 'Top 5 Best Selling Print On Demand Products To Sell',
    date: 'Oct 20, 2021',
  },
  {
    link: 'https://gearment.com/blog/printing-techniques-direct-to-garment-and-sublimation-printing/',
    thumbnailUrl: 'https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img/https://gearment.com/wp-content/uploads/2021/11/printing-tech.jpg',
    title: 'Printing Techniques: Direct to garment And Sublimation Printing',
    date: 'Nov 2, 2021',
  },
  {
    link: 'https://gearment.com/blog/amazon-fba-vs-fbm-which-option-is-the-best-for-you/',
    thumbnailUrl: 'https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img/https://gearment.com/wp-content/uploads/2021/12/fulfilled-by-merchant-amazon23.png',
    title: 'Amazon FBA vs FBM: Which Option Is The Best For You?',
    date: 'Dec 17, 2021',
  },
  {
    link: 'https://gearment.com/blog/amazon-fba-fees-everything-you-need-to-know/',
    thumbnailUrl: 'https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img/https://gearment.com/wp-content/uploads/2021/11/multi-ethnic-brokers-analysing-financial-statistic-2021-12-09-02-46-05-utc-scaled.jpg',
    title: 'Fulfillment by Amazon FBA Fees: Everything You Need To Know',
    date: 'Nov 18, 2021',
  },
  {
    link: 'https://gearment.com/blog/profitable-selling-tips-on-ebay-you-should/',
    thumbnailUrl: 'https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img/https://gearment.com/wp-content/uploads/2022/08/image-162-1.jpg',
    title: 'Profitable Selling Tips On eBay You Should',
    date: 'Jul 13, 2022',
  },
];

function Index() {
  // const [tips, setTips] = useState([]);
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get(
  //       "https://blog.gearment.com/wp-json/wp/v2/posts?categories=61&order=desc&orderby=id&per_page=5&offset=0"
  //     )
  //     .then((res) => {
  //       setLoading(false);
  //       if (Array.isArray(res.data)) {
  //         setTips(res.data);
  //       }
  //       console.log(res.data)
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  //   return () => { };
  // }, []);
  return (
    // <Spin spinning={loading}>
    <TipsWrapper>
      <h3 className="title">
        Gearment tips
        <a
          target="_blank"
          className="Skip-the-guide"
          href="https://blog.gearment.com/en/category/tips/"
          rel="noopener noreferrer"
        >
          View more <NextIcon />
        </a>
      </h3>
      <div className="inner-wrapper">
        {tips.map((tip, index) => (
          <a className="item" key={'tips_' + index} href={tip?.link} target="_blank">
            <div className="thumbnail" style={{backgroundImage: `url(${tip?.thumbnailUrl || NoImg})`}}>&nbsp;</div>
            <div className="content">
              <span className="date">
                {/* {getMomentInstance(tip?.date).format('DD MMM YYYY')} */}
                {tip?.date}
              </span>
              <span className="title">{tip?.title}</span>
            </div>
          </a>
        ))}
      </div>
    </TipsWrapper>
    // </Spin>
  );
};
export default React.memo(Index);