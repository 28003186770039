/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import {
  Field,
  reduxForm,
  FieldArray,
  change,
  formValueSelector,
} from 'redux-form';
import { Affix, Tooltip } from 'antd';
import { connect } from 'react-redux';
import validate from './validate';
import { renderRadioAnt, renderSelectAnt, renderTextarea } from 'utils/Form';
import renderCreateOrderItems from 'utils/Form/components/RenderCreateOrderItems';
import { GMSelect, GMTextbox } from 'components/InputFields';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import GMNotify from 'components/Notification';
import { ReactComponent as Infor } from 'images/icons/info-circle.svg';
import { COUNTRY_AVALIBLE_CANVAS } from 'constants/common';
import { renderCheckboxGroup } from 'utils/Form';
import GMStateSelector from 'components/GMStateSelector';
import { shippingServive } from './utils';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepCount: 1,
      isTourOpen: false,
      clickDropdown: false,
      countClick: 0,
      checked: false,
      listCountryAvalibleCanvasIt: false,
      avalibleGiftMessage: false,
      checkCanvas: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      address01,
      state,
      country,
      city,
      address02,
      listOrderItems,
    } = this.props;
    let checkCanvas = false;
    let listCountryAvalibleCanvasIt = false;

    listOrderItems?.forEach((elem) => {
      if (elem?.type === 12) {
        checkCanvas = true;
        return false;
      }
    });
    COUNTRY_AVALIBLE_CANVAS?.forEach((elem) => {
      if (elem.id === country) {
        listCountryAvalibleCanvasIt = true;
        return false;
      }
    });

    const [dV, list] = shippingServive({
      country,
      state,
      city,
      checkCanvas,
      listCountryAvalibleCanvasIt,
      address01,
      address02,
    });
    if (
      (address01 && checkCanvas &&
        list.findIndex(
          (i) => i?.value + '' === this.props.shippingMethod + ''
        ) === -1)
    ) {
      this.props.changeFieldValue('shippingMethod', dV + '');
    } else if (prevProps.state !== state) {
      this.props.changeFieldValue('shippingMethod', list[0]?.value + '');
    }
  }

  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    });
  };
  componentDidUpdate(prevProps) {
    const {
      address01,
      state,
      country,
      city,
      address02,
      listOrderItems,
    } = this.props;
    let checkCanvas = false;
    let listCountryAvalibleCanvasIt = false;

    listOrderItems?.forEach((elem) => {
      if (elem?.type === 12) {
        checkCanvas = true;
        return false;
      }
    });
    COUNTRY_AVALIBLE_CANVAS?.forEach((elem) => {
      if (elem.id === country) {
        listCountryAvalibleCanvasIt = true;
        return false;
      }
    });

    const [dV, list] = shippingServive({
      country,
      state,
      city,
      checkCanvas,
      listCountryAvalibleCanvasIt,
      address01,
      address02,
    });
    if (
      address01 &&
      checkCanvas &&
      list.findIndex(
        (i) => i?.value + '' === this.props.shippingMethod + ''
      ) === -1
    ) {
      this.props.changeFieldValue('shippingMethod', dV + '');
    } else if (prevProps.state !== state) {
      this.props.changeFieldValue('shippingMethod', list[0]?.value + '');
    }
  }
  render() {
    const {
      handleSubmit,
      stores,
      countries,
      exproducts,
      profile,
      address01,
      state,
      country,
      city,
      address02,
      listOrderItems,
    } = this.props;
    let checkCanvas = false;
    let listCountryAvalibleCanvasIt = false;

    listOrderItems?.forEach((elem) => {
      if (elem?.type === 12) {
        checkCanvas = true;
        return false;
      }
    });
    COUNTRY_AVALIBLE_CANVAS?.forEach((elem) => {
      if (elem.id === country) {
        listCountryAvalibleCanvasIt = true;
        return false;
      }
    });

    const onChangeStore = (value) => {
      const store = stores.find((store) => store.id == value);
      if (store?.hasGiftMessage === true) {
        this.setState({ avalibleGiftMessage: true });
      } else {
        this.setState({ avalibleGiftMessage: false });
      }
    };

    const [dV, list, errorNotification] = shippingServive({
      country,
      state,
      city,
      checkCanvas,
      listCountryAvalibleCanvasIt,
      address01,
      address02,
    });

    return (
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-12 product-infor">
              <div className="form-group">
                <FieldArray
                  className="order-array"
                  exproducts={exproducts}
                  name="listOrderItems"
                  cusId={profile.id}
                  component={renderCreateOrderItems}
                  data={this.props.listOrderItems}
                  changeField={this.props.changeFieldValue}
                  showError
                />
              </div>
            </div>
            <div className="col-12 row customer-infor mx-0">
              <div className="col-12 mb-3">
                <b>Customer information</b>
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">Full name</label>
                <GMTextbox
                  name="fullName"
                  maxLength={'50'}
                  placeholder="Enter Full name"
                  showError
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  Phone number <span className="text-muted">(Optional)</span>
                </label>
                <GMTextbox
                  name="phoneNumber"
                  maxLength={'30'}
                  placeholder="Enter phone number"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  Email <span className="text-muted">(Optional)</span>
                </label>
                <GMTextbox
                  name="email"
                  maxLength={'50'}
                  placeholder="Enter Email"
                  showError
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  Reference id <span className="text-muted">(Optional)</span>
                </label>
                <GMTextbox
                  name="orderId"
                  maxLength={'50'}
                  placeholder="Enter reference id"
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  Store <span className="text-muted">(Optional)</span>
                </label>
                <GMTextbox
                  name="storeId"
                  component={renderSelectAnt}
                  colSpan={24}
                  placeholder="-- Select store --"
                  onChange={onChangeStore}
                  options={[
                    {
                      value: '0',
                      label: (
                        <div style={{ color: 'rgb(117, 117, 117)' }}>
                          -- Select store --
                        </div>
                      ),
                    },
                    ...stores.map((store) => ({
                      label: (
                        <p className={'my-0'}>
                          {(store?.backendType || '')?.toUpperCase() +
                            ' - ' +
                            (store.showName || '') +
                            (store?.hasGiftMessage === true
                              ? ' (*) Has gift message'
                              : '')}
                        </p>
                      ),
                      value: store.id,
                    })),
                  ]}
                />
              </div>
              <div className="form-group col-md-6">
                <Field
                  name="skipOrder"
                  component={renderCheckboxGroup}
                  colSpan={24}
                  options={[
                    {
                      label: (
                        <p className="mb-0">
                          Skip any current orders that have the same order
                          number
                        </p>
                      ),
                      value: 1,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="col-12 row customer-infor mx-0">
              <div className="col-12 mb-3">
                <b>Address information</b>
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">Address 1</label>
                <GMTextbox
                  name="address01"
                  placeholder="Enter address 1"
                  showError
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  Address 2 <span className="text-muted">(Optional)</span>
                </label>
                <GMTextbox
                  name="address02"
                  maxLength={'50'}
                  placeholder="Enter address 2"
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">State/Province</label>
                {this.props.country === '231' ? (
                  <GMStateSelector
                    name="state"
                    placeholder="Enter state/province"
                    country={
                      this.props.country + '' === '231'
                        ? 'us'
                        : countries?.find(
                            (c) => c?.id + '' === this.props.country + ''
                          )?.countryIsoCode
                    }
                    showError
                  />
                ) : (
                  <GMTextbox
                    name="state"
                    placeholder="Enter state/province"
                    showError
                  />
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">City</label>
                <GMTextbox
                  name="city"
                  maxLength={'50'}
                  placeholder="Enter city"
                  showError
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">Country</label>
                <GMSelect
                  name="country"
                  placeholder="Select a country"
                  onChange={() => {
                    this.props.dispatch(change('FormCreateOrder', 'state', ''));
                  }}
                  options={[
                    {
                      value: '231',
                      label: <div>United States</div>,
                    },
                    ...countries.map((item) => ({
                      value: `${item.id}`,
                      label: item.countryName,
                    })),
                  ]}
                  showError
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">Zip code/Postal code</label>
                <GMTextbox
                  name="zipcode"
                  maxLength={'50'}
                  placeholder="Enter zip code/postal code"
                  showError
                />{' '}
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  IOSS number <span className="text-muted">(Optional)</span>
                  <Tooltip
                    title={
                      <div>
                        Provide your IOSS number and the value of the order
                        generated on your e-commerce platform to avoid
                        additional tax charges on this order.{' '}
                        <a href="https://help.gearment.com/en-us/article/sales-tax-rtp0rj/">
                          Click here
                        </a>{' '}
                        for more details.
                      </div>
                    }
                    placement="right"
                  >
                    <Infor
                      className="inforIcon ml-1"
                      style={{
                        stroke: '#98A2B3',
                        width: '15px',
                        height: '15px',
                      }}
                    />
                  </Tooltip>
                </label>
                <GMTextbox
                  name="iossNumber"
                  maxLength={'50'}
                  placeholder="Enter IOSS number"
                  showError
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">Order value (€)</label>
                <GMTextbox
                  name="orderValue"
                  maxLength={'50'}
                  placeholder="Enter order value (€)"
                  showError
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  Tax number <span className="text-muted">(Optional)</span>
                  <Tooltip
                    title={
                      <div>
                        Provide your tax number number and the value of the
                        order generated on your e-commerce platform to avoid
                        additional tax charges on this order.{' '}
                        <a href="https://help.gearment.com/en-us/article/sales-tax-rtp0rj/">
                          Click here
                        </a>{' '}
                        for more details.
                      </div>
                    }
                    placement="right"
                  >
                    <Infor
                      className="inforIcon ml-1"
                      style={{
                        stroke: '#98A2B3',
                        width: '15px',
                        height: '15px',
                      }}
                    />
                  </Tooltip>
                </label>
                <GMTextbox
                  name="taxNumber"
                  maxLength={'50'}
                  placeholder="Enter tax number"
                  showError
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">Order tax value ($)</label>
                <GMTextbox
                  name="orderTaxValue"
                  maxLength={'50'}
                  placeholder="Enter order tax value ($)"
                  showError
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  Gift message{' '}
                  {this.state.avalibleGiftMessage === true ? (
                    <span className="text-danger">(*) Has gift message</span>
                  ) : (
                    <span className="text-muted">(Optional)</span>
                  )}
                </label>
                <GMTextbox
                  name="orderNote"
                  component={renderTextarea}
                  placeholder="Enter gift message"
                  rows={10}
                  maxLength={'350'}
                />
              </div>
              {address01 && (
                <div className="form-group col-12">
                  <label className="col-form-label">Shipping service</label>
                  {errorNotification || (
                    <GMTextbox
                      className="shippingMethod"
                      name="shippingMethod"
                      component={renderRadioAnt}
                      // defaultValue={dV}
                      options={list}
                    />
                  )}
                </div>
              )}
            </div>
            <Affix offsetBottom={0} className="footer-order">
              <div
                className="d-flex position-fixed justify-content-end bg-white py-3 px-5 text-white"
                style={{
                  bottom: '0',
                  left: '0',
                  width: '100%',
                }}
              >
                <GMButton
                  className=" mr-3"
                  color={'SecondaryGray'}
                  onClick={() => {
                    this.props.history.push('/orders/list');
                  }}
                  type="button"
                >
                  Cancel
                </GMButton>
                <GMButton>Submit</GMButton>
              </div>
            </Affix>
          </div>
        </form>
      </Wrapper>
    );
  }
}

const selector = formValueSelector('FormCreateOrder');

export default connect(
  (state) => ({
    listOrderItems: selector(state, 'listOrderItems') || [],
    address02: selector(state, 'address02') || '',
    address01: selector(state, 'address01') || '',
    zipcode: selector(state, 'zipcode') || '',
    state: selector(state, 'state') || '',
    city: selector(state, 'city') || '',
    countryCode: selector(state, 'countryCode') || '',
    country: selector(state, 'country') || '',
    skipOrder: selector(state, 'skipOrder') || '',
    _store: selector(state, 'storeId'),
    shippingMethod: selector(state, 'shippingMethod'),
  }),
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change('FormCreateOrder', field, value));
    },
    shouldValidate: () => true,
    dispatch,
  })
)(
  reduxForm({
    form: 'FormCreateOrder',
    enableReinitialize: true,
    validate,
  })(index)
);

// Domestic delivery (US)
// Standard shipping: 7 - 10 business days delivery
// Ground shipping: 3 - 5 business days delivery
// Fast shipping: 2 - 3 business days delivery

// note: 2 country code này đc xem là domestic : ['us',', 'vi'] (United State, Virgin Islands)

// International delivery
// Standard shipping: 14 - 21 business days delivery

// Some orders require to be shipped with a particular shipping service
// 3 bang HI/AK/PR chỉ được chọn Standard hoặc Fast-ship

// Orders Delivered To APO/FPO/DPO/Military Addresses (US only)
// Chỉ được chọn Ground hoặc Fast-ship

// Thông tin về địa chỉ quân sự (Seller sẽ nhập ở phần City, State, Zipcode):

// City

// APO is used for Army and Air Force installations.

// FPO is used for ships and Navy installations.

// DPO is associated with U.S. embassies overseas.

// State:

// AA for Armed Forces Americas

// AE for Armed Forces Europe, Armed Forces Middle East, and Armed Forces Africa

// AP for Armed Forces Pacific

// Zipcode:

// Zipcode 5 kí tự, zipcode sẽ bắt đầu bằng số 0 hoặc 9.

// Eg, CPT John Doe, Unit 45013 Box 2666, USAG J, APO AP 96338
