const validate = (values) => {
    const errors = {};
    if(!values?.name || values?.name?.replace(/\s+/g, "").length === 0){
        errors.name = "Please enter feed form name";
    }
    if(!values?.googleSheetId || values?.googleSheetId?.replace(/\s+/g, "").length === 0){
        errors.googleSheetId = "Please enter google sheet ID";
    }
    if(!values?.googleBrand || values?.googleBrand?.replace(/\s+/g, "").length === 0){
        errors.googleBrand = "Please enter Google Brand";
    }
    if(!values?.googleGender || values?.googleGender?.replace(/\s+/g, "").length === 0){
        errors.googleGender = "Please enter Google Gender";
    }
    if(!values?.googleBrand || values?.googleBrand?.replace(/\s+/g, "").length === 0){
        errors.googleBrand = "Please enter Google Brand";
    }
    if(!values?.googleGender || values?.googleGender?.replace(/\s+/g, "").length === 0){
        errors.googleGender = "Please enter Google Gender";
    }
    if(!values?.googleStoreDomain || values?.googleStoreDomain?.replace(/\s+/g, "").length === 0){
        errors.googleStoreDomain = "Please enter Google Store Domain";
    }
    if(!values?.googleProductCategory || values?.googleProductCategory?.replace(/\s+/g, "").length === 0){
        errors.googleProductCategory = "Please enter Google Product Category";
    }
    return errors;
};

export default validate;