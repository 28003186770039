import { Col } from 'antd';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import RenderRatingIssue from 'utils/Form/RenderRatingIssue';
import {
  getOptionLabel,
  issue_solution_options,
  methodShipping,
  options_platform_type_issue,
  priorityIssue,
} from 'utils/options';
import { Wrapper } from './styled';
import GMRow from 'components/GMLayout/GMRow';

function FormDetailIssue({ detailIssue }) {
  const { id } = useParams();
  return (
    <Wrapper>
      <div className="d-flex row justify-content-between px-3">
        <Col>
          <GMRow>
            <div className="title">Order name</div>
            <div>
              <Link to={`/orders/${detailIssue?.orderData?.id}`}>
                {detailIssue?.orderData?.name}
              </Link>
            </div>
          </GMRow>
          <GMRow>
            <div className="title">Shipping service</div>
            <div>
              {getOptionLabel(
                methodShipping,
                detailIssue?.orderData?.shippingMethod
              )}
            </div>
          </GMRow>
          <GMRow>
            <div className="title">Priority</div>
            <div>
              {getOptionLabel(priorityIssue, detailIssue?.priority)}
            </div>
          </GMRow>
          <GMRow>
            <div className="title">Effected item</div>
            <div>
              {detailIssue?.issueItemAffected}
            </div>
          </GMRow>
        </Col>
        <Col>
          <GMRow>
            <div className="title">Order platform</div>
            <div>
              {getOptionLabel(
                options_platform_type_issue,
                detailIssue?.orderData?.platformId === 1 &&
                  detailIssue?.orderData?.insertMethod === 0
                  ? 'import'
                  : detailIssue?.orderData?.platformId
              )}
            </div>
          </GMRow>
          <GMRow>
            <div className="title">Total price</div>
            <div>
              <b>${detailIssue?.orderData?.total}</b>
            </div>
          </GMRow>
          <GMRow>
            <div className="title">Type</div>
            <div>{detailIssue?.orderIssueCategoryData?.name}</div>
          </GMRow>
          <GMRow>
            <div className="title">Solution</div>
            <div>{issue_solution_options.find(item => item.value === detailIssue?.solution)?.label}</div>
          </GMRow>
        </Col>
      </div>
      <div>
        <GMRow>
          <div className="title">Issue description</div>
          <div className="title-2">{detailIssue?.detail || 'N/A'}</div>
        </GMRow>
        <GMRow>
          <div className="title">Link</div>
          <div className="title-2">
            {(detailIssue?.link || []).filter(l => l)?.map((item, index) => (
              <>{item} < br /></>
            )) || detailIssue?.link}
            {(detailIssue?.link || []).filter(l => l)?.join('')?.length <= 0 ? 'N/A' : ''}
          </div>
        </GMRow>
        <GMRow>
          <div className="title">Note from support</div>
          <div>{detailIssue?.note || '--'}</div>
        </GMRow>
        {detailIssue?.status === 3 && (
          <GMRow>
            <div className="title">Rate support</div>
            <div>
              <RenderRatingIssue issueId={id} name={`ratingOrderForm${id}`} />
            </div>
          </GMRow>
        )}
      </div>
    </Wrapper>
  );
}

export default React.memo(FormDetailIssue);
