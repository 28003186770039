import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Spin, Button, Table } from 'antd';
import BackToIcon from 'images/chevron-down.svg';
import nophotoImg from 'images/noPhoto.png';
import { getDetail } from './actions';
import {
  getOptionLabel,
  options_schedule_campaign_status,
  options_platform_type,
  options_sync_product_status,
} from 'utils/options';
import getMomentInstance from 'utils/moment';
import { Wrapper } from './styled';

class Edit extends Component {
  componentDidMount() {
    this.props.getDetail(this.props.match.params.id);
  }

  render() {
    const { detail, pageName } = this.props;
    const columns = [
      {
        title: <b>#</b>,
        dataIndex: 'camName',
        key: 'camName',
        render: (value, record, i) => <span>#{i + 1}</span>,
      },
      {
        title: <b>IMAGES</b>,
        dataIndex: 'designLink',
        align: 'center',
        key: 'designLink',
        render: (record) => {
          return record ? (
            <img width="100" src={record} alt="" />
          ) : (
            <img src={nophotoImg} width="100" alt="nophoto-icon" />
          );
        },
      },
      {
        title: <b>PRODUCT NAME</b>,
        dataIndex: 'camName',
        key: 'camName',
      },
      {
        title: <b>STATUS</b>,
        key: 'status',
        align: 'center',
        dataIndex: 'status',
        render: (value) => getOptionLabel(options_sync_product_status, value),
      },
      {
        title: <b>LOG DETAIL</b>,
        dataIndex: 'logContent',
        align: 'center',
        key: 'logContent',
        render: (value, record) => {
          return parseInt(record?.status) !== 0 &&
            parseInt(record?.status) !== 0 &&
            parseInt(record?.status) !== 3 ? (
            <div className="d-flex flex-column justify-content-start ml-5">
              <div className="d-flex">
                <b className="mr-1">Product Online Id:</b>
                {value?.product_online_id || ''}
              </div>
              <div className="d-flex">
                <b className="mr-1">Status:</b>
                {value?.status || ''}
              </div>
            </div>
          ) : (
            'N/A'
          );
        },
      },
    ];
    const id = this.props.match.params.id
    return (
      <Wrapper
        pageName={pageName}
        breadcrumDetails={{
          [id]: id && `Detail #${id || ''}`,
        }}
      >
        <Spin spinning={detail.loading}>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="row form-group">
                <div className="col-6 col-md-2 mb-2 mb-sm-0">
                  <b>Store: </b>
                </div>
                <div className="col-6 col-md-10 mb-2 mb-sm-0">
                  {detail.data.storeIntegration &&
                    detail.data.storeIntegration.showName}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-6 col-md-2 mb-2 mb-sm-0">
                  <b>Status: </b>
                </div>
                <div className="col-6 col-md-4 mb-2 mb-sm-0">
                  {getOptionLabel(
                    options_schedule_campaign_status,
                    detail.data.status
                  )}
                </div>
                <div className="col-6 col-md-2 mb-2 mb-sm-0">
                  <b>Platform: </b>
                </div>
                <div className="col-6 col-md-4 mb-2 mb-sm-0">
                  {getOptionLabel(
                    options_platform_type,
                    detail.data.platformType
                  )}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-6 col-md-2 mb-2 mb-sm-0">
                  <b>Request time: </b>
                </div>
                <div className="col-6 col-md-4 mb-2 mb-sm-0">
                  {getMomentInstance(null, detail.data.requestedAt, true)}
                </div>

                <div className="col-6 col-md-2 mb-2 mb-sm-0">
                  <b>Complete time: </b>
                </div>
                <div className="col-6 col-md-4 mb-2 mb-sm-0">
                  {detail.data.completedAt
                    ? getMomentInstance(null, detail.data.completedAt, true)
                    : 'N/A'}
                </div>
              </div>
              <div className="row form-group">
                <div className="col-6 col-md-2 mb-2 mb-sm-0">
                  <b>List product: </b>
                </div>
              </div>
              <Table
                rowKey="id"
                columns={columns}
                scroll={{ x: 1000 }}
                dataSource={detail.data.scheduleData}
                pagination={false}
              />
            </div>
          </div>
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    detail: state.sync_product.edit.detail,
  }),
  (dispatch) => ({
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
  })
)(Edit);
