import React from "react";
import icon from "images/dollar2.svg";
import LegalInfor from "./scenes/LegalInfor";

export default [
  {
    key: "billings.legal-info",
    name: "Legal Info",
    component: LegalInfor,
    path: "/billings/legal-info",
    group: "settings",
    template: "default",
    icon: <img src={icon} height={21} alt="icon" />,
    isPublic: false,
    // permissions: ["viewSettingPermission"],
  },
  {
    key: "billings.legal-info",
    name: "Legal Info",
    component: LegalInfor,
    path: "/billing/legal-info",
    group: "settings",
    template: "default",
    icon: <img src={icon} height={21} alt="icon" />,
    isPublic: false,
    // permissions: ["viewSettingPermission"],
  },
  // {
  //   key: "legal-info",
  //   name: "Legal Info",
  //   component: LegalInfor,
  //   path: "/legal-info",
  //   group: "settings",
  //   template: "default",
  //   icon: <img src={icon} height={21} alt="icon" />,
  //   isPublic: false,
  //   // permissions: ["viewSettingPermission"],
  // },
];
