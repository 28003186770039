import { actionType as TYPE } from "./actions";
import { notification } from "antd";

const initialState = {
  userlableList: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  delete: {
    loading: false,
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST_USERLABEL.REQUEST:
      return {
        ...state,
        userlableList: {
          ...state.userlableList,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_USERLABEL.SUCCESS:
      return {
        ...state,
        userlableList: {
          ...state.userlableList,
          loading: false,
          data: action.data.userLabels,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST_USERLABEL.ERROR:
      return {
        ...state,
        userlableList: {
          ...state.userlableList,
          loading: false,
        },
      };
    //Delete userlabel
    case TYPE.USERLABEL_DELETE.REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
        },
      };
    case TYPE.USERLABEL_DELETE.SUCCESS:
      notification.success({
        message: "Delete return address successfully.",
        duration: 5,
      });
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
      };
    case TYPE.USERLABEL_DELETE.ERROR:
      notification.error({
        message: "Delete return address failed.",
        duration: 5,
      });
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
