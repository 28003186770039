import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Modal } from 'antd';
import { previewCampaign } from './actions';
import Form from './components/form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { listProductTypeNotShowedSize } from 'utils/options';
import { Wrapper } from './styled';
const { confirm } = Modal;
class Create extends Component {
  state = {
    initialValues: null,
  };
  componentDidMount() {
    const temp = localStorage.getItem('CreateCampaignForm3')
      ? JSON.parse(localStorage.getItem('CreateCampaignForm3'))
      : null;
    if (Array.isArray(temp?.products) && temp.products.length > 0) {
      // confirm({
      //   title: 'You have a product in progress. Do you want to continue?',
      //   icon: <ExclamationCircleOutlined />,
      //   content: "You won't be able to revert this!",
      //   onOk() {},
      //   onCancel() {
      //     //localStorage.removeItem('CreateCampaignForm3');
      //     window.location.reload();
      //   },
      // });
      this.setState({
        initialValues: temp,
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.CreateCampaignFormData) !==
      JSON.stringify(prevProps.CreateCampaignFormData)
    ) {
      localStorage.setItem(
        'CreateCampaignForm3',
        JSON.stringify(this.props.CreateCampaignFormData)
      );
    }
  }
  handleSubmit = (values) => {
    let listProducts = [];
    if (values.products) {
      listProducts = values.products.map((product) => {
        const listColors = product.colors.map((item) => item.id);
        let listSizes = [];
        if (listProductTypeNotShowedSize.includes(parseInt(product?.type))) {
          if (parseInt(product?.type) === 3) {
            const typeProduct = product.typeProduct.find(
              (item) => parseInt(item.id) === parseInt(product.typePrint)
            );
            if (Array.isArray(typeProduct?.sizePrice)) {
              listSizes = typeProduct?.sizePrice.map(
                (typeProductItem) => typeProductItem.id
              );
            }
          } else {
            listSizes = product?.optionSizes?.map(
              (optionSize) => optionSize.id
            );
          }
        } else {
          listSizes = product.sizes.map((item) => item.id);
        }
        return {
          productId: product.id,
          imageFrontArtwork: product.frontImage || '',
          imageBackArtwork: product.backImage || '',
          colors: listColors,
          colorActive: product.colorActive.id,
          sizes: listSizes,
          sizeActive: product.sizeActive.id,
          mockupId: 0,
          infoDesignFront: {
            frameLeft: product.boxFrontLeft || 0,
            frameTop: product.boxFrontTop || 0,
            frameWidth: product.boxFrontWidth || 0,
            frameHeight: product.boxFrontHeight || 0,
            designLeft: product.positionFront.x2,
            designTop: product.positionFront.y2,
            designWidth: product.sizeFront.w2,
            designHeight: product.sizeFront.h2,
          },
          infoDesignBack: {
            frameLeft: product.boxBackLeft || 0,
            frameTop: product.boxBackTop || 0,
            frameWidth: product.boxBackWidth || 0,
            frameHeight: product.boxBackHeight || 0,
            designLeft: product.positionBack.x2,
            designTop: product.positionBack.y2,
            designWidth: product.sizeBack.w2,
            designHeight: product.sizeBack.h2,
          },
          shortProductType: product.shortProductType || '',
          price: product.price || 0,
          comparePrice: product.comparePrice || 0,
          typePrint: product.typePrint,
          customPrice: product.customPrice,
          typeProductKey: product.typeProductKey
            ? product.typeProductKey
            : product.typeProduct &&
              product.typeProduct[0] &&
              product.typeProduct[0]['key']
            ? product.typeProduct[0]['key']
            : '',
        };
      });
    }
    const dataToCreateCampaign = {
      name: values?.title,
      description: values.description || '',
      group: values.group || 1,
      appendProductType: values.appendProductType ? 1 : 0,
      tags: values.tags || [],
      products: listProducts,
    };
    this.props.previewCampaign(dataToCreateCampaign);
  };
  render() {
    const { PreviewCampaignLoading, pageName } = this.props;
    return (
      <Wrapper pageName={pageName}>
        <Spin spinning={PreviewCampaignLoading}>
          <Form
            onSubmit={this.handleSubmit}
            history={this.props.history}
            initialValues={
              this.state.initialValues || {
                group: '1',
                tags: [],
                products: [], 
                title: '',
              }
            }
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    PreviewCampaignLoading: state.campaign.create.loading,
    CreateCampaignFormData: state.form.CreateCampaignForm3?.values,
  }),
  (dispatch) => ({
    previewCampaign: (product) => {
      dispatch(previewCampaign(product));
    },
  })
)(Create);
