const validate = (values) => {
  const errors = {};
  if (!values.fileToImport) {
    errors.fileToImport = "Please upload a invalid file to import order.";
  }
  // if (values.storeId + '' === '0') {
  //   errors.storeId = "Please select a store.";
  // }
  // if (values.typeFile + '' === '-1') {
  //   errors.typeFile = "Please select file type.";
  // }
  return errors;
};

export default validate;
