import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  actionType as TYPE,
  loadListAccountAction,
  loadPrimaryAccountAction,
} from './actions';
import {
  remove,
  update,
  create,
  setPrimary,
  loadListBillingPaymentAccount,
  loadPrimaryBillingPaymentAccount,
  logBillingMethod,
  detailBillingMethod,
} from 'apis/billing_payment_account';
import { setLoading } from 'scenes/BillingPaymentAccount/actions';
import { push } from 'connected-react-router';

function* saveBillingMethodSaga(action) {
  const { payload } = action;
  try {
    yield put(setLoading(true));
    const response = yield call(create, payload);
    if (response.code === 'success') {
      yield put({ type: TYPE.SAVE_BILLING_METHOD.SUCCESS, response });
      yield put({ type: TYPE.LOAD_LOG_BILLING_METHOD.REQUEST, response });
    } else {
      yield put({ type: TYPE.SAVE_BILLING_METHOD.FAILED, error: response });
    }
  } catch (error) {
    yield put({ type: TYPE.SAVE_BILLING_METHOD.FAILED, error });
  } finally {
    yield all([put(setLoading(false)), put(push('/billing/method'))]);
  }
}
//Get detail billing method
function* getDetailBillingMethodSaga(action) {
  const { id } = action;
  try {
    const response = yield call(detailBillingMethod, id);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.DETAIL_BILLING_METHOD.SUCCESS, ...response }),
      ]);
    } else {
      yield put({ type: TYPE.DETAIL_BILLING_METHOD.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.DETAIL_BILLING_METHOD.ERROR, error })]);
  }
}

function* updateBillingMethodSaga(action) {
  const { payload } = action;
  try {
    yield put(setLoading(true));
    const response = yield call(update, payload);
    if (response.code === 'success') {
      yield put({ type: TYPE.UPDATE_BILLING_METHOD.SUCCESS, response });
    } else {
      yield put({ type: TYPE.UPDATE_BILLING_METHOD.FAILED, error: response });
    }
  } catch (error) {
    yield put({ type: TYPE.UPDATE_BILLING_METHOD.FAILED, error });
  } finally {
    yield all([put(setLoading(false)), put(push('/billing/method'))]);
  }
}

function* loadPrimaryBillingMethodSaga(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(loadPrimaryBillingPaymentAccount);
    if (response.code === 'success') {
      yield put({
        type: TYPE.LOAD_PRIMARY_BILLING_METHOD.SUCCESS,
        payload: response?.data,
      });
      yield put({ type: TYPE.LOAD_LOG_BILLING_METHOD.REQUEST, response });
    } else {
      yield put({
        type: TYPE.LOAD_PRIMARY_BILLING_METHOD.FAILED,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: TYPE.LOAD_PRIMARY_BILLING_METHOD.FAILED,
      payload: error,
    });
  } finally {
    yield put(setLoading(false));
  }
}

function* loadListBillingMethodSaga(action) {
  const { payload } = action;
  try {
    yield put(setLoading(true));
    const response = yield call(loadListBillingPaymentAccount, payload);
    if (response.code === 'success') {
      yield put({
        type: TYPE.LOAD_LIST_BILLING_METHOD.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: TYPE.LOAD_LIST_BILLING_METHOD.FAILED,
        payload: response,
      });
    }
  } catch (error) {
    yield put({ type: TYPE.LOAD_LIST_BILLING_METHOD.FAILED, payload: error });
  } finally {
    yield put(setLoading(false));
  }
}

function* loadLogBillingMethodSaga(action) {
  const { payload } = action;
  try {
    yield put(setLoading(true));
    const response = yield call(logBillingMethod, payload);
    if (response.code === 'success') {
      yield put({
        type: TYPE.LOAD_LOG_BILLING_METHOD.SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: TYPE.LOAD_LOG_BILLING_METHOD.FAILED,
        payload: response,
      });
    }
  } catch (error) {
    yield put({ type: TYPE.LOAD_LOG_BILLING_METHOD.FAILED, payload: error });
  } finally {
    yield put(setLoading(false));
  }
}

function* setPrimarySaga(action) {
  const {
    payload: { payId, billingId, altParams },
  } = action;
  try {
    yield put(setLoading(true));
    const response = yield call(setPrimary, { payId, billingId });
    if (response.code === 'success') {
      yield all([
        yield put({ type: TYPE.SET_PRIMARY.SUCCESS, payload: altParams }),
        yield put(loadListAccountAction()),
        yield put(loadPrimaryAccountAction()),
      ]);
      yield put({ type: TYPE.LOAD_LOG_BILLING_METHOD.REQUEST, response });
    } else {
      yield put({ type: TYPE.SET_PRIMARY.FAILED, payload: altParams });
    }
  } catch (error) {
    yield put({ type: TYPE.SET_PRIMARY.FAILED, payload: altParams });
  } finally {
    yield put(setLoading(false));
  }
}

function* deletePMSaga(action) {
  const {
    payload: { payId, billingId },
  } = action;
  try {
    yield put(setLoading(true));
    const response = yield call(remove, { payId, billingId });
    if (response.code === 'success') {
      yield all([
        yield put({ type: TYPE.DELETE.SUCCESS, payload: response }),
        yield put(loadListAccountAction()),
        yield put(loadPrimaryAccountAction()),
      ]);
      yield put({ type: TYPE.LOAD_LOG_BILLING_METHOD.REQUEST, response });
    } else {
      yield put({ type: TYPE.DELETE.FAILED, payload: response });
    }
  } catch (error) {
    yield put({ type: TYPE.DELETE.FAILED, payload: error });
  } finally {
    yield put(setLoading(false));
  }
}

function* watcher() {
  yield takeLatest(TYPE.SAVE_BILLING_METHOD.REQUEST, saveBillingMethodSaga);
  yield takeLatest(TYPE.UPDATE_BILLING_METHOD.REQUEST, updateBillingMethodSaga);
  yield takeLatest(
    TYPE.LOAD_PRIMARY_BILLING_METHOD.REQUEST,
    loadPrimaryBillingMethodSaga
  );
  yield takeLatest(
    TYPE.LOAD_LIST_BILLING_METHOD.REQUEST,
    loadListBillingMethodSaga
  );
  yield takeLatest(
    TYPE.LOAD_LOG_BILLING_METHOD.REQUEST,
    loadLogBillingMethodSaga
  );
  yield takeLatest(TYPE.SET_PRIMARY.REQUEST, setPrimarySaga);
  yield takeLatest(TYPE.DELETE.REQUEST, deletePMSaga);
  yield takeLatest(TYPE.DETAIL_BILLING_METHOD.REQUEST, getDetailBillingMethodSaga);

}

export default watcher;
