import Edit from "./scenes/Edit";

export default [
  {
    key: "api",
    name: "API Information",
    component: Edit,
    path: "/api",
    icon: null,
    template: "default",
    isPublic: true,
  },
  {
    key: "api",
    name: "API Information",
    component: Edit,
    path: "/settings/api-information",
    icon: null,
    template: "default",
    isPublic: true,
  },
];
