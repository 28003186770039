import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getList } from './actions';
import queryString from 'query-string';
import { Wrapper } from './styled';
import Breadcrumb from 'components/Breadcrumb';
import Tabs from './components/Tabs';
class List extends Component {
  constructor(props) {
    super(props);
    const { createdAtFrom, createdAtTo, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      showDetail: false,
      selected: null,
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        type: '0',
        ...query_params,
      },
    };
  }
  handleShowDetail = (selected) => {
    this.setState({
      showDetail: true,
      selected,
    });
  };

  render() {
    const { pageName } = this.props;
    return (
      <Wrapper>
        <div>
          <Breadcrumb />
          <h2 className="header">{pageName}</h2>
          <div className="main-content">
            <Tabs />
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    listOrderPayment: state.order.manual_payment.listOrderPayment,
    profile: state.auth.info.profile,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
  })
)(List);
