import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Field, change, submit } from "redux-form";
import SelectGeneric from "utils/Form/Search/SelectGeneric";

export default ({ values = {} }) => {
  const dispatch = useDispatch();
  const commonAttr = {
    component: SelectGeneric,
    showSearch: !1,
    noStyle: true,
    no1stValue: true
  };
  return (
    <>
      <div className="ml-2">
        <Field
          name={`method`}
          classNameContainer="select-method"
          placeholder="Method"
          options={[
            { value: "0", label: "All top up method" },
            { value: "1", label: "Payoneer" },
            { value: "2", label: "Pingpong" },
            { value: "-1", label: "Paypal" },
            { value: "5", label: "Lianlian" }
          ]}
          onChange={(v) => {
            dispatch(change("paymentFormFilter", "method", v));
            // work-around
            setTimeout(() => {
              dispatch(submit("paymentFormFilter"));
            }, 0);
          }}
          {...commonAttr}
        />
      </div>
      {values?.method === "-1" ? (
        <div className="ml-2">
          <Field
            name={`methodType`}
            classNameContainer="select-method"
            placeholder="Type"
            options={[
              { value: "0", label: "All top up request" },
              { value: "1", label: "Request" },
              { value: "2", label: "Instant" }
            ]}
            onChange={(v) => {
              dispatch(change("paymentFormFilter", "methodType", v));
              // work-around
              setTimeout(() => {
                dispatch(submit("paymentFormFilter"));
              }, 0);
            }}
            {...commonAttr}
          />
        </div>
      ) : null}
      <div className="ml-2">
        <Field
          name={`status`}
          classNameContainer="select-status"
          placeholder="Status"
          options={[
            { value: "-1", label: "All top up status" },
            { value: "0", label: "Pending" },
            { value: "1", label: "Completed" },
            { value: "2", label: "Rejected" }
          ]}
          onChange={(v) => {
            dispatch(change("paymentFormFilter", "status", v));
            // work-around
            setTimeout(() => {
              dispatch(submit("paymentFormFilter"));
            }, 0);
          }}
          {...commonAttr}
        />
      </div>
    </>
  );
};
