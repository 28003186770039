import {
    DATA_FEED_AMAZON_CREATE_REQUEST,
    DATA_FEED_AMAZON_CREATE_FAIL,
    DATA_FEED_AMAZON_CREATE_SUCCESS
} from './constants'
import { notification } from 'antd'
const initialState = {
    loading: false
}

function PostCreateReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_FEED_AMAZON_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case DATA_FEED_AMAZON_CREATE_SUCCESS:
            notification.success({
                message: "Create Feed Form Amazon successfully.",
                duration: 5
            })
            return {
                ...state,
                loading: false,
            }
        case DATA_FEED_AMAZON_CREATE_FAIL:
            notification.error({
                message: action?.error?.[0]?.["clientMsg"] || "Create Feed Form Amazon failed.",
                duration: 5
            })
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export default PostCreateReducer