import { takeLatest, call, put, all } from 'redux-saga/effects';

import { actionType as TYPE } from './actions';
import { actionType as TYPE1 } from '../../../Orders/scenes/CreateLableOrder/actions';
import { actionType as TYPE2 } from '../../../Orders/scenes/MatchProduct/actions';
import { actionType as TYPE3 } from '../../../Orders/scenes/DuplicateOrderFBA/actions';
import { actionType as TYPE4 } from '../../../Orders/scenes/ConvertOrderLabel/actions';
import { DUPLICATE_ORDER_REQUEST } from '../../../Orders/scenes/Detail/constants';

import { create, checkVariantAtRisk } from 'apis/orders';
import { getListDesigns } from 'apis/ex_products';
import { replace } from 'connected-react-router';
import { Modal } from 'antd';

const { confirm } = Modal;

function* createOrderSaga(action) {
  const { data } = action;
  try {
    const response = yield call(create, data);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CREATE_ORDER.SUCCESS, ...response }),
        put(replace('/orders/list')),
      ]);
    } else {
      if (response[0].code === 'invalid_address') {
        yield put({ type: TYPE.CREATE_ORDER.ERROR, error: response });
      } else {
        yield all([
          put({ type: TYPE.CREATE_ORDER.ERROR, error: response }),
          put(replace('/orders/list')),
        ]);
      }
    }
  } catch (error) {
    yield all([
      put({ type: TYPE.CREATE_ORDER.ERROR, error }),
      put(replace('/orders/list')),
    ]);
  }
}

function* listDesignSaga(action) {
  try {
    const response = yield call(getListDesigns);
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_DESIGN.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_DESIGN.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_DESIGN.ERROR, error })]);
  }
}

function* checkVariantRiskSaga(action) {
  const {
    data: { data, submitData, form, cb, matchProductFnc },
  } = action;
  try {
    let risks = [];
    let response = null;
    for (let i = 0; i < Math.ceil(data?.data?.length / 5); i++) {
      response = yield call(checkVariantAtRisk, { data: [...data?.data]?.splice(i * 5, 5) });
      risks = [...risks, ...(response?.data?.risks || [])];
    }
    if (response.code === 'success') {
      if (risks?.length > 0) {
        yield all([
          put({ type: TYPE.CHECK_VARIANT_RISK.SUCCESS, data: { risks } }),
          put({ type: TYPE.OPEN_VARIANT_RISK }),
        ]);
      } else {
        if (form === 'create_order') {
          yield all([
            put({
              type: TYPE.CREATE_ORDER.REQUEST,
              data: submitData,
            }),
            put({ type: TYPE.CHECK_VARIANT_RISK.SUCCESS, data: { risks } }),
          ]);
        }
        if (form === 'create_fba_order') {
          yield all([
            put({ type: TYPE1.CREATE_LABEL_ORDER.REQUEST, data: submitData }),
          ]);
        }
        if (form === 'match_product' && typeof matchProductFnc === 'function') {
          matchProductFnc();
          // yield put(saveMatchProduct({ data: submitData }));
        }
        if (form === 'duplicate_order') {
          yield all([
            put({ type: TYPE3.DUPLICATE_FBA_ORDER.REQUEST, data: submitData, cb }),
          ]);
        }
        if (form === 'duplicate_order_manual') {
          yield all([
            put({ type: DUPLICATE_ORDER_REQUEST, data: { id: submitData }, cb }),
          ]);
        }
        if (form === 'convert_order') {
          yield all([
            put({ type: TYPE4.CONVERT_ORDER.REQUEST, data: submitData }),
          ]);
        }
      }
    } else {
      yield put({ type: TYPE.CHECK_VARIANT_RISK.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.CHECK_VARIANT_RISK.ERROR, error })]);
  }
  // finally {
  //   if (typeof cb === 'function') {
  //     cb();
  //   }
  // }
}

function* watcher() {
  yield takeLatest(TYPE.CREATE_ORDER.REQUEST, createOrderSaga);
  yield takeLatest(TYPE.LIST_DESIGN.REQUEST, listDesignSaga);
  yield takeLatest(TYPE.CHECK_VARIANT_RISK.REQUEST, checkVariantRiskSaga);
}

export default watcher;
