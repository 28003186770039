import React, { useState } from 'react';
import { PictureFilled } from '@ant-design/icons';
import { Spin, Modal, Empty, Tooltip, Input, notification, Button } from 'antd';
import { search, archive } from 'apis/image_design';
import RenderImageDesignUpload3 from 'utils/Form/renderImageDesignUpload3';
import classNames from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ReactComponent as TrashIcon } from 'images/icons/trash-01.svg';
import { change } from 'redux-form';
import { useDispatch } from 'react-redux';

const { confirm } = Modal;
const { Search } = Input;
export const RenderImageDesign = ({
  changeDesignFront,
  changeDesignBack,
  changeDesignFrontId,
  changeDesignBackId,
  input,
  cusId = 0,
  meta,
  buttonTitle = 'Upload your image',
  isCreatingOrder = false,
  isOnlyUpload = false,
  isButtonPlaceholder = false,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [original, setOriginal] = useState('');
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState({});
  const [pagination, setPagination] = useState({
    total: 0,
    perPage: 16,
    currentPage: 1,
  });
  const dispatch = useDispatch();

  const handleShowModal = (show) => {
    setVisible(show);
    setImages([]);
    if (show) {
      searchImage(original);
    }
  };

  const searchImage = (q, is_more) => {
    setOriginal(q);
    setLoading(true);
    search({
      original: q,
      page: is_more ? (pagination?.currentPage || 1) + 1 : 1,
      perPage: 18,
    })
      .then(({ code, data }) => {
        setLoading(false);
        if (code === 'success') {
          setImages(
            is_more ? [...images, ...data.imageDesign] : data.imageDesign
          );
          setPagination(data.pagination);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const archiveDesign = (id) => {
    if (!id) {
      notification.warn({
        message: 'Please choose image to archive',
        duration: 5,
      });
    }
    setLoading(true);
    archive({
      id,
    })
      .then((result) => {
        setLoading(false);
        if (result[0] && result[0]['code'] === 'archive_image_success') {
          notification.success({
            message: result[0]['clientMsg'] || 'Archive image successfully.',
            duration: 5,
          });
          searchImage('');
        } else {
          notification.warn({
            message: result[0]['clientMsg'] || 'Archive image failed',
            duration: 5,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="m-0 p-0" style={{ height: '100px' }}>
      <div
        className={classNames('px-2 rounded border-0 cursor-pointer mb-5', {
          'border-active': visible,
        })}
        style={{ borderStyle: 'dotted' }}
        onClick={() => handleShowModal(true)}
      >
        {input?.value ? (
          <div>
            <img
              className="img-fluid"
              src={input?.value}
              alt={`design`}
              style={
                isCreatingOrder ? { maxWidth: '70px', height: '70px' } : {}
              }
            />
            {/* <div className="py-3">
              <TrashIcon style={{ stroke: '#475467' }} onClick={handleRemove} />
            </div> */}
          </div>
        ) : isButtonPlaceholder ? (
          <Button className="btn btn-primary  d-flex align-items-center ">
            {buttonTitle}
          </Button>
        ) : (
          <PictureFilled style={{ fontSize: 80, color: '#ccc' }} />
        )}
      </div>
      {meta && (
        <span
          className={classNames('invalid-feedback mt-5', {
            invisible: !meta?.error || !meta?.submitFailed,
          })}
        >
          {meta?.submitFailed && meta?.error
            ? meta?.error
            : 'Please upload design.'}
        </span>
      )}
      <Modal
        visible={visible}
        title="Image library"
        onCancel={() => handleShowModal(false)}
        footer={false}
        width={1000}
        bodyStyle={{ padding: '10px 10px 0px 10px' }}
      >
        <Spin spinning={loading}>
          <Search
            placeholder="Search image by original name"
            onSearch={(value) => searchImage(value)}
            style={{ width: 300 }}
            className="mb-3 mx-1"
            allowClear
          />
          <div className="container-fluid">
            <div
              style={{ maxHeight: 500, overflow: 'auto' }}
              className="pt-2 border-top border-bottom mb-2 row"
            >
              {images.map((image) => (
                <Tooltip title={image.original} key={image.id}>
                  <div className="col-6 col-sm-3">
                    <img
                      style={{
                        height: 120,
                        objectFit: 'contain',
                        width: '100%',
                      }}
                      className={classNames(
                        'px-1 border-hover mx-1 mb-2 img-fluid',
                        {
                          'border-active':
                            parseInt(image.id) === parseInt(selectedImage.id),
                        }
                      )}
                      src={image.imageThumbnailUrl}
                      onClick={() => {
                        setSelectedImage(image);
                      }}
                      alt=""
                    />
                  </div>
                </Tooltip>
              ))}
              {!loading && images.length === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </div>
          {pagination?.total && pagination.total !== images.length ? (
            <div className="text-center">
              <button
                onClick={() => searchImage(original, true)}
                type="button"
                className="btn-sm btn btn-outline-secondary"
              >
                Loading more
              </button>
            </div>
          ) : null}
          <hr className="mt-2" />
          <div className="row">
            <div className="col-sm-4 mb-2">
              <RenderImageDesignUpload3
                showUploadList={false}
                className="text-center"
                isButtonPlaceholder={true}
                imgWidth="100%"
                typeUpload="upload-design-image"
                reloadImageDesignList={() => {
                  searchImage('');
                }}
                cusId={cusId}
              />
            </div>
            <div className="col-sm-8 text-right">
              {!isOnlyUpload && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary mx-1 mb-2"
                    onClick={() => {
                      if (!selectedImage.id) {
                        notification.warn({
                          message: 'Please choose your design.',
                          duration: 5,
                        });
                        return;
                      }
                      if (
                        typeof changeDesignFront === 'function' &&
                        typeof changeDesignFrontId === 'function'
                      ) {
                        changeDesignFront(selectedImage.imageThumbnailUrl);
                        changeDesignFrontId(selectedImage.id);
                      }
                    }}
                  >
                    Apply design for front side
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-1 mb-2"
                    onClick={() => {
                      if (!selectedImage.id) {
                        notification.warn({
                          message: 'Please choose your design.',
                          duration: 5,
                        });
                        return;
                      }
                      if (
                        typeof changeDesignBack === 'function' &&
                        typeof changeDesignBackId === 'function'
                      ) {
                        changeDesignBack(selectedImage.imageThumbnailUrl);
                        changeDesignBackId(selectedImage.id);
                      }
                    }}
                  >
                    Apply design for back side
                  </button>
                </>
              )}
              <button
                type="button"
                className="btn btn-primary mx-1 mb-2"
                onClick={() => {
                  if (!selectedImage.id) {
                    notification.warn({
                      message: 'Please choose your design.',
                      duration: 5,
                    });
                    return;
                  }
                  confirm({
                    title: 'Do you want to archive this design?',
                    icon: <ExclamationCircleOutlined />,
                    content: "You won't be able to revert this!",
                    onOk() {
                      archiveDesign(selectedImage.id || 0);
                    },
                    onCancel() {},
                  });
                }}
              >
                Archive design
              </button>
              <button
                onClick={() => handleShowModal(false)}
                type="button"
                className="btn btn-danger mx-1 mb-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};
