import React from 'react';
import { GMBadge } from 'components/Badge';
import {
  ERROR_50,
  ERROR_600,
  GRAY_200,
  GRAY_50,
  GRAY_600,
  PRIMARY_50,
  PRIMARY_600,
  PURPLE_50,
  PURPLE_600,
  SUCCESS_50,
  SUCCESS_600,
} from 'components/colors';
import { Tooltip } from 'antd';

export const ORDER_STATUS_COLORS = {
  0: {
    color: GRAY_600,
    border: GRAY_200,
    backgroundColor: GRAY_50,
  },
  13: {
    color: PRIMARY_600,
    border: PRIMARY_600,
    backgroundColor: PRIMARY_50,
  },
  1: {
    color: PRIMARY_600,
    border: PRIMARY_600,
    backgroundColor: PRIMARY_50,
  },
  19: {
    color: PURPLE_600,
    border: PURPLE_600,
    backgroundColor: PURPLE_50,
  },
  2: {
    color: SUCCESS_600,
    border: SUCCESS_600,
    backgroundColor: SUCCESS_50,
  },
  3: {
    color: ERROR_600,
    border: ERROR_600,
    backgroundColor: ERROR_50,
  },
};
const Text = {
  0: 'Pending',
  1: 'Processing',
  13: 'In-production',
  19: 'On hold',
  2: 'Completed',
  3: 'Canceled',
};

function RenderOrderStatus({ order = {}, isShowError = true }) {
  let styled = ORDER_STATUS_COLORS?.[order?.status];

  return (
    <div>
      {order?.status === 19 ? (
        <Tooltip title={order?.onHoldReason?.description}>
          {' '}
          <GMBadge noDot={true} className="badge" {...styled}>
            {Text?.[order?.status]}
          </GMBadge>
        </Tooltip>
      ) : (
        <GMBadge noDot={true} className="badge" {...styled}>
          {Text?.[order?.status]}
        </GMBadge>
      )}
    </div>
  );
}

export default React.memo(RenderOrderStatus);
