import { GRAY_300, GRAY_700 } from "components/colors";
import PageContainer from "components/PageContainer";
import styled from "styled-components";

export const Wrapper = styled(PageContainer)`
  .formgroup {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 24px;
  }  
  .social-card {
    display: inline-block;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    max-width: 240px;
    border: 1px solid ${GRAY_300};
    padding: 20px;
    margin: 10px;
    span.email{
      display: inline-block;
      width: 100%;
      padding-top: 10px;
      font-weight: bold;
    }
  }
  .row.social {
    padding-left: 5px;
  }
  label.headerAcc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_700};
    margin-bottom: 8px;
  }
  .ant-picker-large {
    border-radius: 8px;
    width: 100%;
  }
  .gmtextbox,
  .genderblock {
    margin-bottom: 16px;
    .ant-input-affix-wrapper-lg {
      padding: 0 12px;
    }
  }
  .ant-input-lg {
    &:focus {
      border: none;
    }
  }
  .saveBtnGen {
    min-width: 100px;
    float: right;
  }
  .sub-section {
    border-radius: 12px;
  }
`;