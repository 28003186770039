export const CREATE_ISSUES_REQUEST = 'CREATE_ISSUES_REQUEST';
export const UDPATE_ISSUES_REQUEST = 'UDPATE_ISSUES_REQUEST';
export const CREATE_ISSUES_SUCCESS = 'CREATE_ISSUES_SUCCESS';

export const CREATE_ISSUES_FAIL = 'CREATE_ISSUES_FAIL';
export const UPDATE_ISSUES_SUCCESS = 'UPDATE_ISSUES_SUCCESS';
export const UPDATE_ISSUES_FAIL = 'UPDATE_ISSUES_FAIL';

export const LIST_ISSUE_CATEGORY_REQUEST = 'LIST_ISSUE_CATEGORY_REQUEST';
export const LIST_ISSUE_CATEGORY_SUCCESS = 'LIST_ISSUE_CATEGORY_SUCCESS';
export const LIST_ISSUE_CATEGORY_FAIL = 'LIST_ISSUE_CATEGORY_FAIL';

export const LIST_ORDER_TO_CREATE_ISSUE_REQUEST = "LIST_ORDER_TO_CREATE_ISSUE_REQUEST";
export const LIST_ORDER_TO_CREATE_ISSUE_SUCCESS = "LIST_ORDER_TO_CREATE_ISSUE_SUCCESS";
export const LIST_ORDER_TO_CREATE_ISSUE_FAIL = "LIST_ORDER_TO_CREATE_ISSUE_FAIL";

export const LIST_ORDER_SET_LOADING = "LIST_ORDER_SET_LOADING";
