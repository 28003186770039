import { 
  takeLatest, 
  call, 
  put, 
  all 
} from 'redux-saga/effects'
import { 
  ORDER_TRACKING_LIST_REQUEST,
  ORDER_TRACKING_LIST_SUCCESS,
  ORDER_TRACKING_LIST_FAIL
} from './constants'

import axios from 'utils/request'

function getOrderTrackingListAPI(options){
    return axios({
      method: 'POST',
      data: options,
      url: `/order_tracking`
    })
}

function* getOrderTrackingListSaga(action){
  const { options } = action
  try {
    const response = yield call(getOrderTrackingListAPI, options)
    if(response.code === 'success'){
      yield put({ type: ORDER_TRACKING_LIST_SUCCESS, response: response.data })    
    }else{
        yield put({ type: ORDER_TRACKING_LIST_FAIL, response })
    }      
  } catch(error){
    yield all([
      yield put({ type: ORDER_TRACKING_LIST_FAIL, error })
    ])
  }
}

function* getOrderTrackingListWatcher(){
  yield takeLatest(ORDER_TRACKING_LIST_REQUEST, getOrderTrackingListSaga)
}

export default getOrderTrackingListWatcher