import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin, Button, Tooltip } from 'antd';
import getMomentInstance from 'utils/moment';
import { getDetail } from './actions';
import {
  getOptionLabel,
  manual_detail_status,
  manual_type_status,
  partial_detail_status,
} from 'utils/options';
import { Wrapper } from 'scenes/general.styled';
import { GMButton } from 'components/Button';
import ModalTopUp from 'components/ModalTopUp';
import { paymentOrder } from '../List/actions';
import money from 'utils/money';
import { ReactComponent as Icon } from 'images/icons/alert-circle.svg';

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
    };
  }
  componentDidMount() {
    this.props.getDetail(this.props.match.params.id);
  }

  openTopup = () => {
    this.setState({ isOpenModal: true });
  };
  payment = () => {
    this.props.paymentOrder({
      orders:
        (this.props.detail?.log &&
          JSON.parse(this.props.detail?.log)
            ?.list_order_unpaid?.slice(0, -1)
            .split(',')
            .map((item) => item.replace('TW-000', ''))
            .filter((item) => item)) ||
        [],
    });
  };
  // orders: [5851054, 5851063];
  // 0: 5851054;
  // 1: 5851063;

  render() {
    const { detail, pageName, loadingDetail } = this.props;
    let logPayment = detail?.log && JSON.parse(detail?.log);

    let b = detail?.listOrder
      ?.split(',')
      .map((item) => 'TW-' + item.padStart(10, '0'))
      .join(', ');
    return (
      <Wrapper
        pageName={`${pageName} #${this.props.match.params.id || ''}`}
        breadcrumDetails={{
          [this.props.match.params.id]:
            this.props.match.params.id &&
            `#${this.props.match.params.id || ''}`,
        }}
      >
        <Spin spinning={loadingDetail}>
          <div className="table-responsive">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td style={{ width: 200 }} className="px-4 font-weight-bold">
                    List orders request
                  </td>
                  <td className="px-4">{b}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className="px-4 font-weight-bold">
                    Type
                  </td>
                  <p className="ml-4">
                    {getOptionLabel(manual_type_status, detail?.paymentType)}
                  </p>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className="px-4 font-weight-bold">
                    Status
                  </td>
                  <td className="px-4">
                    {getOptionLabel(manual_detail_status, detail?.status)}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className="px-4 font-weight-bold">
                    Payment
                  </td>
                  <td className="px-4">
                    {' '}
                    {getOptionLabel(
                      partial_detail_status,
                      detail?.paymentPartial
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className="px-4 font-weight-bold">
                    Transaction code{' '}
                    <Tooltip title={<div>For paid orders only</div>}>
                      <Icon style={{ stroke: '#475467', width: '13.3px' }} />
                    </Tooltip>
                  </td>{' '}
                  <td
                    className="px-4"
                    style={{
                      color: '#117BA9',
                    }}
                  >
                    {detail?.transactionData?.name}
                  </td>
                </tr>
                <tr
                  style={{ borderBottom: '1px solid #EAECF0', width: '100%' }}
                >
                  <td
                    style={{ width: 200 }}
                    className="px-4 pb-4 font-weight-bold"
                  >
                    Content
                  </td>{' '}
                  <td className="px-4">{logPayment?.msg}</td>
                </tr>
                {logPayment?.list_order_unpaid && (
                  <>
                    <tr>
                      <td
                        style={{ width: 200 }}
                        className="px-4 font-weight-bold pt-4"
                      >
                        List of unpaid orders
                      </td>
                      <td className="px-4 pt-4">
                        {logPayment?.list_order_unpaid.slice(0, -2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: 200 }}
                        className="px-4 font-weight-bold"
                      >
                        Reason
                      </td>
                      <td className="px-4">
                        {logPayment?.order_payments_list && (
                          <div>
                            <div
                              style={{
                                color: '#D92D20',
                              }}
                            >
                              Insufficient balance.
                            </div>
                            <span
                              onClick={this.openTopup}
                              style={{
                                textDecoration: 'underline',
                                color: '#117BA9',
                                cursor: 'pointer',
                              }}
                            >
                              Top up
                            </span>{' '}
                            your G-Wallet now.
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: 200 }}
                        className="px-4 font-weight-bold"
                      >
                        Amount
                      </td>
                      <td className="px-4">
                        <b>{money(logPayment?.unpaid_amount)}</b>
                      </td>
                    </tr>
                    {logPayment?.list_order_unpaid && (
                      <tr
                        style={{
                          borderBottom: '1px solid #EAECF0',
                          width: '100%',
                        }}
                      >
                        <td style={{ width: 200 }} className="px-4 pb-4">
                          <GMButton onClick={this.payment}>
                            Retry Payment
                          </GMButton>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td
                        style={{ width: 200 }}
                        className="px-4 font-weight-bold pt-4"
                      >
                        List of paid orders
                      </td>
                      <td className="px-4 pt-4">
                        {logPayment?.order_payments_list.slice(0, -2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{ width: 200 }}
                        className="px-4 font-weight-bold"
                      >
                        Transaction ID
                      </td>
                      <td className="px-4">{logPayment?.transaction_id}</td>
                    </tr>
                    <tr
                      style={{
                        borderBottom: '1px solid #EAECF0',
                        width: '100%',
                      }}
                    >
                      <td
                        style={{ width: 200 }}
                        className="px-4 font-weight-bold pb-4"
                      >
                        Amount
                      </td>
                      <td className="px-4">
                        <b>{money(logPayment?.amount)}</b>
                      </td>
                    </tr>
                  </>
                )}

                <tr>
                  <td
                    style={{ width: 200 }}
                    className="px-4 font-weight-bold pt-4"
                  >
                    Request time
                  </td>
                  <td className="px-4 pt-4">
                    {detail?.startTime
                      ? getMomentInstance(null, detail?.startTime, true)
                      : 'N/A'}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 200 }} className="px-4 font-weight-bold">
                    Completed time
                  </td>
                  <td className="px-4">
                    {detail.endTime
                      ? getMomentInstance(null, detail?.endTime, true)
                      : 'N/A'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Spin>
        <ModalTopUp
          isOpenModal={this.state.isOpenModal}
          closeModal={() => this.setState({ isOpenModal: false })}
        />
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    loadingDetail: state.order.manual_payment_detail.detailManual.loading,
    detail: state.order.manual_payment_detail.detailManual.data,
  }),
  (dispatch) => ({
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
    paymentOrder: (id) => {
      dispatch(paymentOrder(id));
    },
  })
)(Detail);
