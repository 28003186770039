import React from "react";
import { Link } from "react-router-dom";

export default (props) => {
  return (
    <div className="dashboard-section guideline-section  py-3 mb-4 h-100">
      <div className="d-flex justify-content-center">
        <h4 className="mb-3">Open Issue</h4>
      </div>
      <div>
        <div className="text-center text-muted">
          <p>If your order has any problems, you can create issue.</p>
        </div>
        <div className="d-flex justify-content-center">
          <Link
            to="/orders/order-issues/create"
            className="btn btn-primary"
            style={{ borderRadius: "5px" }}
          >
            Create Issue
          </Link>
        </div>
      </div>
    </div>
  );
};
