import React, { Component } from 'react';
import { Checkbox, Col } from 'antd';
import classNames from 'classnames';
import GMRow from 'components/GMLayout/GMRow';
class FieldCheckboxGroup extends Component {
    handleOnChange = (checkedList)=> {
        if(this.props.input.onChange){
            this.props.input.onChange(checkedList);
        }
    }
    render() {
        const {
            options,
            colSpan,
            classNameContainer='',
            input,
            meta: { asyncValidating, error },
            classNameCustom
        } = this.props
        return (
            <div className={classNames(classNameContainer, {'async-validating' : asyncValidating})}>
                <Checkbox.Group value={input.value || []} style={{ width: '100%' }} onChange={this.handleOnChange}>
                    <GMRow>
                        {options.map(item=>(<Col key={item.value} span={colSpan || 12}>
                            <Checkbox onClick ={() => {
                                if(item.onRemove && (input.value || []).includes(item.value)){
                                    item.onRemove();
                                }else if(item.onChoose){
                                    item.onChoose();
                                }
                            }} className={classNames("d-flex align-items-center mb-2 ant-checkbox-wrapper-lg", (classNameCustom || ""))} value={item.value} >{item.label}</Checkbox>
                        </Col>))}
                    </GMRow>
                </Checkbox.Group>
                {error && <span className="invalid-feedback d-block">{error}</span>}
            </div>
        );
    }
}

export default FieldCheckboxGroup;