import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import {
  getList,
  getProfitTransaction,
  getTransactionToQuickView,
} from "apis/transactions";

function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getList, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}
function* getListTransactionQuickViewSaga(action) {
  const { id } = action;

  try {
    const response = yield call(getTransactionToQuickView, id);
    if (response.code === "success") {
      yield put({ type: TYPE.LIST_QUICKVIEW.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_QUICKVIEW.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_QUICKVIEW.ERROR, error })]);
  }
}

function* exportTransactionSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getList, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.EXPORT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.EXPORT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.EXPORT.ERROR, error })]);
  }
}

function* getProfitTransactionSaga() {
  try {
    const response = yield call(getProfitTransaction);
    if (response.code === "success") {
      yield put({ type: TYPE.GET_PROFIT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.GET_PROFIT.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.GET_PROFIT.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.EXPORT.REQUEST, exportTransactionSaga);
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga);
  yield takeLatest(TYPE.GET_PROFIT.REQUEST, getProfitTransactionSaga);
  yield takeLatest(
    TYPE.LIST_QUICKVIEW.REQUEST,
    getListTransactionQuickViewSaga
  );
}

export default watcher;
