export const actionType = {
    CREATE_LABEL_ORDER: {
        REQUEST: 'ORDER.CREATE_LABEL.REQUEST',
        SUCCESS: 'ORDER.CREATE_LABEL.SUCCESS',
        ERROR: 'ORDER.CREATE_LABEL.ERROR',
    },
    CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED: {
        REQUEST: 'ORDER.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.REQUEST',
        SUCCESS: 'ORDER.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.SUCCESS',
        ERROR: 'ORDER.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.ERROR',
    }
};

export function createLableOrder(data){
    return {
        type: actionType.CREATE_LABEL_ORDER.REQUEST,
        data
    }
}

export function checkIfUploadingShippingLabelIsRequired(){
    return {
        type: actionType.CHECK_IF_UPLOADING_SHIPPING_LABEL_IS_REQUIRED.REQUEST
    }
}

