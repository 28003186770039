import { takeLatest, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { getDetail, update } from "apis/gift_message";
import { actionType as TYPE } from "./actions";

function* detailSaga(action) {
  try {
    const { id } = action;
    const response = yield call(getDetail, id);
    if (response.code === "success" && response.data) {
      yield put({ type: TYPE.GIFTMESSAGE_DETAIL.SUCCESS, ...response });
    } else {
      yield all([
        yield put({
          type: TYPE.GIFTMESSAGE_DETAIL.ERROR,
          error: { message: "Not found gift message" },
        }),
        yield put(replace(`/settings/gift-message`)),
      ]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.GIFTMESSAGE_DETAIL.ERROR, error })]);
  }
}
function* updateSaga(action) {
  try {
    const { id, dataToUpdate } = action;
    const response = yield call(update, id, dataToUpdate);

    if (response.code === "success") {
      yield all([put({ type: TYPE.GIFTMESSAGE_UPDATE.SUCCESS, response })]);
    } else {
      yield put({ type: TYPE.GIFTMESSAGE_UPDATE.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.GIFTMESSAGE_UPDATE.ERROR, error })]);
  }
}

function* watcher() {
  yield all([
    takeLatest(TYPE.GIFTMESSAGE_UPDATE.REQUEST, updateSaga),
    takeLatest(TYPE.GIFTMESSAGE_DETAIL.REQUEST, detailSaga),
  ]);
}

export default watcher;
