import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin,Button } from "antd";
import { create } from "./actions";
import Form from "./components/form";
import BackToIcon from "../../../../images/chevron-down.svg";

class Create extends Component {

  handleSubmit = ({ status, conditionMatch, ...values }) => {
    let params = {
      ...values,
      status: status ? 1 : 0,
    };
    for (let index = 0; index < conditionMatch.length; index++) {
      const element = conditionMatch[index];
      for (let key in element) {
        params[`conditionMatch[${index}][${key}]`] = element[key];
      }
    }
    this.props.create(params);
  };
  render() {
    const { creating, pageName} = this.props;
    return (
      <div>
        <div className="container-fluid">
          <Button
            className="BackToButton d-flex align-items-center"
            onClick={() => {
              this.props.history.push("/customer-collection");
            }}
          >
            <img src={BackToIcon} alt="back-icon"/>
            <p className="m-0 ml-2">Back to Collection</p>
          </Button>
          <div className="d-flex justify-content-between align-items-center my-3">
            <h1 className="h3 mb-0 font-weight-bold">{pageName}</h1>
          </div>
          <Spin spinning={creating}>
            <div className="card">
              <div className="card-body">
                <Form onSubmit={this.handleSubmit} />
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    creating: state.collection.create.creating,
  }),
  (dispatch) => ({
    create: (params) => {
      dispatch(create(params));
    },
  })
)(Create);
