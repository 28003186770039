import { GRAY_300, SHADOW_XS } from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
  }
  .shopbase {
    .ant-input-affix-wrapper {
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      border-radius: 8px 0 0 8px;
    }
    .ant-input-group-addon {
      border-radius: 0 8px 8px 0;
    }
  }
`;
