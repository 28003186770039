import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Spin } from "antd";
import money from "utils/money";

export class index extends Component {
  render() {
    const { typeActive, onChangeType, profitTransaction } = this.props;
    return (
      <Spin spinning={profitTransaction.loading}>
        <div className="bg-light mb-3 p-3">
          <div className="container-fluid">
            <div className="row px-0">
              <div className="col-sm-4 text-center">
                <div
                  onClick={() => onChangeType()}
                  className={classNames("p-3 cursor-pointer", {
                    "bg-white": parseInt(typeActive) !== 1 && parseInt(typeActive) !== 2,
                  })}
                >
                  <p className="mb-0">ALL</p>
                  <p
                    className={classNames("h3 mb-0", {
                      "text-info": parseInt(typeActive) === 0,
                    })}
                  >
                    {money(
                      Math.round(
                        profitTransaction.data.total
                          ? (profitTransaction.data.total * 100) / 100
                          : 0
                      )
                    )}
                  </p>
                  <p>
                    {Math.ceil(
                      profitTransaction.data.total_count
                        ? profitTransaction.data.total_count
                        : 0
                    )}{" "}
                    transactions
                  </p>
                </div>
              </div>
              <div
                onClick={() => onChangeType(1)}
                className="col-sm-4 text-center"
              >
                <div
                  className={classNames("p-3 cursor-pointer", {
                    "bg-white": parseInt(typeActive) === 1,
                  })}
                >
                  <p className="mb-0">Payment</p>

                  <p
                    className={classNames("h3 mb-0", {
                      "text-info": parseInt(typeActive) === 1,
                    })}
                  >
                    {money(
                      Math.ceil(
                        profitTransaction.data.payment
                          ? profitTransaction.data.payment
                          : 0
                      )
                    )}
                  </p>
                  <p>
                    {Math.ceil(
                      profitTransaction.data.payment_count
                        ? profitTransaction.data.payment_count
                        : 0
                    )}{" "}
                    transactions
                  </p>
                </div>
              </div>
              <div
                onClick={() => onChangeType(2)}
                className="col-sm-4 text-center"
              >
                <div
                  className={classNames("p-3 cursor-pointer", {
                    "bg-white": parseInt(typeActive) === 2,
                  })}
                >
                  <p className="mb-0">Deposit / Profit</p>

                  <p
                    className={classNames("h3 mb-0", {
                      "text-info": parseInt(typeActive) === 2,
                    })}
                  >
                    {money(
                      Math.ceil(
                        profitTransaction.data.deposit
                          ? profitTransaction.data.deposit
                          : 0
                      )
                    )}
                  </p>
                  <p>
                    {Math.ceil(
                      profitTransaction.data.deposit_count
                        ? profitTransaction.data.deposit_count
                        : 0
                    )}{" "}
                    transactions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
