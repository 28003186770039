import React, { Component } from 'react';
import moment from 'moment'
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

class renderRangeDateTimePicker extends Component {
  disabledDate =(current)=> {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }
  disabledRangeTime =(_, type)=> {
    if (type === 'start') {
      return {
        disabledHours: () => range(0, 60).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    }
    return {
      disabledHours: () => range(0, 60).splice(20, 4),
      disabledMinutes: () => range(0, 31),
      disabledSeconds: () => [55, 56],
    };
  }
  render() {
    const { input, placeholder, size, meta: {invalid, error } } = this.props
    return (
      <div className="position-relative">
        <RangePicker
          size={size || "large"}
          disabledDate={this.disabledDate}
          value={input.value}
          showTime={{
            format: 'HH:mm',
            hideDisabledOptions: true,
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
          }}
          format="YYYY-MM-DD HH:mm:ss"
          style={{width: '100%'}}
          placeholder={placeholder}
          onChange={(v)=>input.onChange(v)}//[v[0].format(valueFormat||'YYYY-MM-DD HH:mm:ss'), v[1].format(valueFormat||'YYYY-MM-DD HH:mm:ss')])}
        />
        {invalid&&<hr className="border-danger m-0 position-absolute" style={{width: '90%', left: '5%', top: 39}}/>}
        {error && <span className="invalid-feedback">{error}</span>}
      </div>
    );
  }
}

export default renderRangeDateTimePicker;