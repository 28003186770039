export const NO_COLOR = 'transparent';
export const BASE_WHITE = '#FFFFFF';
export const BASE_BLACK = '#000000';
export const GRAY_25 = '#FCFCFD';
export const GRAY_50 = '#F9FAFB';
export const GRAY_100 = '#F2F4F7';
export const GRAY_200 = '#EAECF0';
export const GRAY_300 = '#D0D5DD';
export const GRAY_400 = '#98A2B3';
export const GRAY_500 = '#667085';
export const GRAY_600 = '#475467';
export const GRAY_700 = '#344054';
export const GRAY_800 = '#1D2939';
export const GRAY_900 = '#101828';
//BrowN
export const BROWN_500 = '#8A5D3C';
//
export const PRIMARY_25 = '#F5F9FD';
export const PRIMARY_50 = '#EFF9FD';
export const PRIMARY_100 = '#C1E7F8';
export const PRIMARY_200 = '#93D6F3';
export const PRIMARY_300 = '#64C5EF';
export const PRIMARY_400 = '#36B3EA';
export const PRIMARY_500 = '#169DD8';
export const PRIMARY_600 = '#117BA9';
export const PRIMARY_700 = '#0C597B';
export const PRIMARY_800 = '#07384D';
export const PRIMARY_900 = '#03161E';
export const ERROR_25 = '#FFFBFA';
export const ERROR_50 = '#FEF3F2';
export const ERROR_100 = '#FEE4E2';
export const ERROR_200 = '#FECDCA';
export const ERROR_300 = '#FDA29B';
export const ERROR_400 = '#F97066';
export const ERROR_500 = '#F04438';
export const ERROR_600 = '#D92D20';
export const ERROR_700 = '#B42318';
export const ERROR_800 = '#912018';
export const ERROR_900 = '#7A271A';
export const WARNING_25 = '#FFFCF5';
export const WARNING_50 = '#FFFAEB';
export const WARNING_100 = '#FEF0C7';
export const WARNING_200 = '#FEDF89';
export const WARNING_300 = '#FEC84B';
export const WARNING_400 = '#FDB022';
export const WARNING_500 = '#F79009';
export const WARNING_600 = '#DC6803';
export const WARNING_700 = '#B54708';
export const WARNING_800 = '#93370D';
export const WARNING_900 = '#7A2E0E';
export const SUCCESS_25 = '#F6FEF9';
export const SUCCESS_50 = '#ECFDF3';
export const SUCCESS_100 = '#D1FADF';
export const SUCCESS_200 = '#A6F4C5';
export const SUCCESS_300 = '#6CE9A6';
export const SUCCESS_400 = '#32D583';
export const SUCCESS_500 = '#12B76A';
export const SUCCESS_600 = '#039855';
export const SUCCESS_700 = '#027A48';
export const SUCCESS_800 = '#05603A';
export const SUCCESS_900 = '#054F31';
export const GRAY_BLUE_25 = '#FCFCFD';
export const GRAY_BLUE_50 = '#F8F9FC';
export const GRAY_BLUE_100 = '#EAECF5';
export const GRAY_BLUE_200 = '#D5D9EB';
export const GRAY_BLUE_300 = '#B3B8DB';
export const GRAY_BLUE_400 = '#717BBC';
export const GRAY_BLUE_500 = '#4E5BA6';
export const GRAY_BLUE_600 = '#3E4784';
export const GRAY_BLUE_700 = '#363F72';
export const GRAY_BLUE_800 = '#293056';
export const GRAY_BLUE_900 = '#101323';
export const GRAY_COOL_25 = '#FCFCFD';
export const GRAY_COOL_50 = '#F9F9FB';
export const GRAY_COOL_100 = '#EFF1F5';
export const GRAY_COOL_200 = '#DCDFEA';
export const GRAY_COOL_300 = '#B9C0D4';
export const GRAY_COOL_400 = '#7D89B0';
export const GRAY_COOL_500 = '#5D6B98';
export const GRAY_COOL_600 = '#4A5578';
export const GRAY_COOL_700 = '#404968';
export const GRAY_COOL_800 = '#30374F';
export const GRAY_COOL_900 = '#111322';
export const GRAY_MODERN_25 = '#FCFCFD';
export const GRAY_MODERN_50 = '#F8FAFC';
export const GRAY_MODERN_100 = '#EEF2F6';
export const GRAY_MODERN_200 = '#E3E8EF';
export const GRAY_MODERN_300 = '#CDD5DF';
export const GRAY_MODERN_400 = '#9AA4B2';
export const GRAY_MODERN_500 = '#697586';
export const GRAY_MODERN_600 = '#4B5565';
export const GRAY_MODERN_700 = '#364152';
export const GRAY_MODERN_800 = '#202939';
export const GRAY_MODERN_900 = '#121926';
export const GRAY_NEUTRAL_25 = '#FCFCFD';
export const GRAY_NEUTRAL_50 = '#F9FAFB';
export const GRAY_NEUTRAL_100 = '#F3F4F6';
export const GRAY_NEUTRAL_200 = '#E5E7EB';
export const GRAY_NEUTRAL_300 = '#D2D6DB';
export const GRAY_NEUTRAL_400 = '#9DA4AE';
export const GRAY_NEUTRAL_500 = '#6C737F';
export const GRAY_NEUTRAL_600 = '#4D5761';
export const GRAY_NEUTRAL_700 = '#384250';
export const GRAY_NEUTRAL_800 = '#1F2A37';
export const GRAY_NEUTRAL_900 = '#111927';
export const GRAY_IRON_25 = '#FCFCFC';
export const GRAY_IRON_50 = '#FAFAFA';
export const GRAY_IRON_100 = '#F4F4F5';
export const GRAY_IRON_200 = '#E4E4E7';
export const GRAY_IRON_300 = '#D1D1D6';
export const GRAY_IRON_400 = '#A0A0AB';
export const GRAY_IRON_500 = '#70707B';
export const GRAY_IRON_600 = '#51525C';
export const GRAY_IRON_700 = '#3F3F46';
export const GRAY_IRON_800 = '#26272B';
export const GRAY_IRON_900 = '#18181B';
export const GRAY_TRUE_25 = '#FCFCFC';
export const GRAY_TRUE_50 = '#FAFAFA';
export const GRAY_TRUE_100 = '#F5F5F5';
export const GRAY_TRUE_200 = '#E5E5E5';
export const GRAY_TRUE_300 = '#D6D6D6';
export const GRAY_TRUE_400 = '#A3A3A3';
export const GRAY_TRUE_500 = '#737373';
export const GRAY_TRUE_600 = '#525252';
export const GRAY_TRUE_700 = '#424242';
export const GRAY_TRUE_800 = '#292929';
export const GRAY_TRUE_900 = '#141414';
export const GRAY_WARM_25 = '#FDFDFC';
export const GRAY_WARM_50 = '#FAFAF9';
export const GRAY_WARM_100 = '#F5F5F4';
export const GRAY_WARM_200 = '#E7E5E4';
export const GRAY_WARM_300 = '#D7D3D0';
export const GRAY_WARM_400 = '#A9A29D';
export const GRAY_WARM_500 = '#79716B';
export const GRAY_WARM_600 = '#57534E';
export const GRAY_WARM_700 = '#44403C';
export const GRAY_WARM_800 = '#292524';
export const GRAY_WARM_900 = '#1C1917';
export const MOSS_25 = '#FAFDF7';
export const MOSS_50 = '#F5FBEE';
export const MOSS_100 = '#E6F4D7';
export const MOSS_200 = '#CEEAB0';
export const MOSS_300 = '#ACDC79';
export const MOSS_400 = '#86CB3C';
export const MOSS_500 = '#669F2A';
export const MOSS_600 = '#4F7A21';
export const MOSS_700 = '#3F621A';
export const MOSS_800 = '#335015';
export const MOSS_900 = '#2B4212';
export const GREEN_LIGHT_25 = '#FAFEF5';
export const GREEN_LIGHT_50 = '#F3FEE7';
export const GREEN_LIGHT_100 = '#E4FBCC';
export const GREEN_LIGHT_200 = '#D0F8AB';
export const GREEN_LIGHT_300 = '#A6EF67';
export const GREEN_LIGHT_400 = '#85E13A';
export const GREEN_LIGHT_500 = '#66C61C';
export const GREEN_LIGHT_600 = '#4CA30D';
export const GREEN_LIGHT_700 = '#3B7C0F';
export const GREEN_LIGHT_800 = '#326212';
export const GREEN_LIGHT_900 = '#2B5314';
export const GREEN_25 = '#F6FEF9';
export const GREEN_50 = '#EDFCF2';
export const GREEN_100 = '#D3F8DF';
export const GREEN_200 = '#AAF0C4';
export const GREEN_300 = '#73E2A3';
export const GREEN_400 = '#3CCB7F';
export const GREEN_500 = '#16B364';
export const GREEN_600 = '#099250';
export const GREEN_700 = '#087443';
export const GREEN_800 = '#095C37';
export const GREEN_900 = '#084C2E';
export const TEAL_25 = '#F6FEFC';
export const TEAL_50 = '#F0FDF9';
export const TEAL_100 = '#CCFBEF';
export const TEAL_200 = '#99F6E0';
export const TEAL_300 = '#5FE9D0';
export const TEAL_400 = '#2ED3B7';
export const TEAL_500 = '#15B79E';
export const TEAL_600 = '#0E9384';
export const TEAL_700 = '#107569';
export const TEAL_800 = '#125D56';
export const TEAL_900 = '#134E48';
export const CYAN_25 = '#F5FEFF';
export const CYAN_50 = '#ECFDFF';
export const CYAN_100 = '#CFF9FE';
export const CYAN_200 = '#A5F0FC';
export const CYAN_300 = '#67E3F9';
export const CYAN_400 = '#22CCEE';
export const CYAN_500 = '#06AED4';
export const CYAN_600 = '#088AB2';
export const CYAN_700 = '#0E7090';
export const CYAN_800 = '#155B75';
export const CYAN_900 = '#164C63';
export const BLUE_LIGHT_25 = '#F5FBFF';
export const BLUE_LIGHT_50 = '#F0F9FF';
export const BLUE_LIGHT_100 = '#E0F2FE';
export const BLUE_LIGHT_200 = '#B9E6FE';
export const BLUE_LIGHT_300 = '#7CD4FD';
export const BLUE_LIGHT_400 = '#36BFFA';
export const BLUE_LIGHT_500 = '#0BA5EC';
export const BLUE_LIGHT_600 = '#0086C9';
export const BLUE_LIGHT_700 = '#026AA2';
export const BLUE_LIGHT_800 = '#065986';
export const BLUE_LIGHT_900 = '#0B4A6F';
export const BLUE_25 = '#F5FAFF';
export const BLUE_50 = '#EFF8FF';
export const BLUE_100 = '#D1E9FF';
export const BLUE_200 = '#B2DDFF';
export const BLUE_300 = '#84CAFF';
export const BLUE_400 = '#53B1FD';
export const BLUE_500 = '#2E90FA';
export const BLUE_600 = '#1570EF';
export const BLUE_700 = '#175CD3';
export const BLUE_800 = '#1849A9';
export const BLUE_900 = '#194185';
export const BLUE_DARK_25 = '#F5F8FF';
export const BLUE_DARK_50 = '#EFF4FF';
export const BLUE_DARK_100 = '#D1E0FF';
export const BLUE_DARK_200 = '#B2CCFF';
export const BLUE_DARK_300 = '#84ADFF';
export const BLUE_DARK_400 = '#528BFF';
export const BLUE_DARK_500 = '#2970FF';
export const BLUE_DARK_600 = '#155EEF';
export const BLUE_DARK_700 = '#004EEB';
export const BLUE_DARK_800 = '#0040C1';
export const BLUE_DARK_900 = '#00359E';
export const INDIGO_25 = '#F5F8FF';
export const INDIGO_50 = '#EEF4FF';
export const INDIGO_100 = '#E0EAFF';
export const INDIGO_200 = '#C7D7FE';
export const INDIGO_300 = '#A4BCFD';
export const INDIGO_400 = '#8098F9';
export const INDIGO_500 = '#6172F3';
export const INDIGO_600 = '#444CE7';
export const INDIGO_700 = '#3538CD';
export const INDIGO_800 = '#2D31A6';
export const INDIGO_900 = '#2D3282';
export const VIOLET_25 = '#FBFAFF';
export const VIOLET_50 = '#F5F3FF';
export const VIOLET_100 = '#ECE9FE';
export const VIOLET_200 = '#DDD6FE';
export const VIOLET_300 = '#C3B5FD';
export const VIOLET_400 = '#A48AFB';
export const VIOLET_500 = '#875BF7';
export const VIOLET_600 = '#7839EE';
export const VIOLET_700 = '#6927DA';
export const VIOLET_800 = '#5720B7';
export const VIOLET_900 = '#491C96';
export const PURPLE_25 = '#FAFAFF';
export const PURPLE_50 = '#F4F3FF';
export const PURPLE_100 = '#EBE9FE';
export const PURPLE_200 = '#D9D6FE';
export const PURPLE_300 = '#BDB4FE';
export const PURPLE_400 = '#9B8AFB';
export const PURPLE_500 = '#7A5AF8';
export const PURPLE_600 = '#6938EF';
export const PURPLE_700 = '#5925DC';
export const PURPLE_800 = '#4A1FB8';
export const PURPLE_900 = '#3E1C96';
export const FUCHSIA_25 = '#FEFAFF';
export const FUCHSIA_50 = '#FDF4FF';
export const FUCHSIA_100 = '#FBE8FF';
export const FUCHSIA_200 = '#F6D0FE';
export const FUCHSIA_300 = '#EEAAFD';
export const FUCHSIA_400 = '#E478FA';
export const FUCHSIA_500 = '#D444F1';
export const FUCHSIA_600 = '#BA24D5';
export const FUCHSIA_700 = '#9F1AB1';
export const FUCHSIA_800 = '#821890';
export const FUCHSIA_900 = '#6F1877';
export const PINK_25 = '#FEF6FB';
export const PINK_50 = '#FDF2FA';
export const PINK_100 = '#FCE7F6';
export const PINK_200 = '#FCCEEE';
export const PINK_300 = '#FAA7E0';
export const PINK_400 = '#F670C7';
export const PINK_500 = '#EE46BC';
export const PINK_600 = '#DD2590';
export const PINK_700 = '#C11574';
export const PINK_800 = '#9E165F';
export const PINK_900 = '#851651';
export const ROSE_25 = '#FFF5F6';
export const ROSE_50 = '#FFF1F3';
export const ROSE_100 = '#FFE4E8';
export const ROSE_200 = '#FECDD6';
export const ROSE_300 = '#FEA3B4';
export const ROSE_400 = '#FD6F8E';
export const ROSE_500 = '#F63D68';
export const ROSE_600 = '#E31B54';
export const ROSE_700 = '#C01048';
export const ROSE_800 = '#A11043';
export const ROSE_900 = '#89123E';
export const ORANGE_DARK_25 = '#FFF9F5';
export const ORANGE_DARK_50 = '#FFF4ED';
export const ORANGE_DARK_100 = '#FFE6D5';
export const ORANGE_DARK_200 = '#FFD6AE';
export const ORANGE_DARK_300 = '#FF9C66';
export const ORANGE_DARK_400 = '#FF692E';
export const ORANGE_DARK_500 = '#FF4405';
export const ORANGE_DARK_600 = '#E62E05';
export const ORANGE_DARK_700 = '#BC1B06';
export const ORANGE_DARK_800 = '#97180C';
export const ORANGE_DARK_900 = '#771A0D';
export const ORANGE_25 = '#FEFAF5';
export const ORANGE_50 = '#FEF6EE';
export const ORANGE_100 = '#FDEAD7';
export const ORANGE_200 = '#F9DBAF';
export const ORANGE_300 = '#F7B27A';
export const ORANGE_400 = '#F38744';
export const ORANGE_500 = '#EF6820';
export const ORANGE_600 = '#E04F16';
export const ORANGE_700 = '#B93815';
export const ORANGE_800 = '#932F19';
export const ORANGE_900 = '#772917';
export const YELLOW_25 = '#FEFDF0';
export const YELLOW_50 = '#FEFBE8';
export const YELLOW_100 = '#FEF7C3';
export const YELLOW_200 = '#FEEE95';
export const YELLOW_300 = '#FDE272';
export const YELLOW_400 = '#FAC515';
export const YELLOW_500 = '#EAAA08';
export const YELLOW_600 = '#CA8504';
export const YELLOW_700 = '#A15C07';
export const YELLOW_800 = '#854A0E';
export const YELLOW_900 = '#713B12';

export const SHADOW_XS = 'rgba(16, 24, 40, 0.05)';
export const SHADOW_SM_0_1 = 'rgba(16, 24, 40, 0.1)';
export const SHADOW_SM_0_06 = 'rgba(16, 24, 40, 0.06)';
export const SHADOW_LG_0_08 = 'rgba(16, 24, 40, 0.08)';
export const SHADOW_LG_0_03 = 'rgba(16, 24, 40, 0.03)';
