/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { GlobalStyle, WrapperAddBilling, WrapperModal } from './styled';
import { GMButton } from 'components/Button';
import { useState } from 'react';
import classNames from 'classnames';
import {
  cancelPayoneer,
  getListPayoneer,
  loginPayoneer,
  redirectPaypal,
  redirectLianLian,
  registerPayoneer,
} from 'apis/payment_menthod';
import { Modal, notification, Spin } from 'antd';
import queryString from 'query-string';
import InfoForm, { billingInfoForm } from './InfoForm';
import { getListCustomerConfig } from 'scenes/LegalInfo/scenes/LegalInfor/actions';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as PaypalIcon } from 'images/logo_payment/paypal_sz32.svg';
import { ReactComponent as PayoneerIcon } from 'images/logo_payment/payoneer_sz32.svg';
import { ReactComponent as LianLogoIcon } from 'images/logo_payment/lian-logo.svg';
import DiscardBillingMethodConfirmModal from './DiscardBillingMethodConfirmModal';
import { push } from 'connected-react-router';
import {
  deleteBillingMethodAction,
  loadListAccountAction,
  loadPrimaryAccountAction,
} from 'scenes/BillingPaymentAccount/actions';
import { MSG_REMOVE_PM_FAIL, MSG_REMOVE_PM_SUCCESS } from 'scenes/BillingPaymentAccount/const';

export default ({ pageName, isOpenModal, closeModal = () => { }, altActions = {} }) => {
  const [activePaymentMethod, setActivePaymentMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payoneerAccount, setPayoneerAccount] = useState(null);
  const [cancelRemovePayoneerAction, setCancelRemovePayoneerAction] = useState(
    null
  );
  const [formValues, setFormValues] = useState({});

  const listCustomerConfig = useSelector(
    (s) => s.setting?.general?.listCustomerConfig
  );
  const primaryBillingPaymentAccount = useSelector(
    (s) => s.billingPaymentAccount?.primary
  );

  const listBillingPaymentAccount = useSelector(
    (s) => s.billingPaymentAccount?.list
  );
  const allBillings = [primaryBillingPaymentAccount?.billing, ...(listBillingPaymentAccount?.billings || [])];
  const accLianLian = allBillings.find(b => b?.type === 4);
  const accPayoneer = allBillings.find(b => b?.type === 1);

  const dataDetailBillingMethodLoading = useSelector(
    (s) => s.billingPaymentAccount?.detailBillingMethod?.loading
  );
  const dataDetailBillingMethod = useSelector(
    (s) => s.billingPaymentAccount?.detailBillingMethod?.data
  );

  const reduxFormValues = useSelector((s) => s.form?.[billingInfoForm]?.values);
  const countries = useSelector((s) => s.user.profile.listCountry);
  const currentValues = useSelector((s) => s.form?.form_billingInfo?.values);
  const dispatch = useDispatch();

  const params = queryString.parse(window.location.search);

  useEffect(() => {
    getListPayoneer()
      .then((response) => {
        setLoading(true);
        if (response?.code === 'success') {
          setPayoneerAccount(response?.data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    if (Object.keys(listCustomerConfig?.data || {}).length <= 0) {
      dispatch(getListCustomerConfig());
    }
    if (!primaryBillingPaymentAccount) {
      dispatch(loadPrimaryAccountAction());
    }
    if ((listBillingPaymentAccount?.billings?.length || 0) <= 0) {
      dispatch(loadListAccountAction());
    }
  }, []);
  useEffect(() => {
    if (!dataDetailBillingMethod) {
      return;
    }
    const init = {
      company: dataDetailBillingMethod?.billingInfor?.company || '',
      firstName: dataDetailBillingMethod?.billingInfor?.firstName || '',
      lastName: dataDetailBillingMethod?.billingInfor?.lastName || '',
      phone: dataDetailBillingMethod?.billingInfor?.phone || '',
      address: dataDetailBillingMethod?.billingInfor?.streetAddress || '',
      address2: dataDetailBillingMethod?.billingInfor?.extendedAddress || '',
      city: dataDetailBillingMethod?.billingInfor?.city || '',
      province: dataDetailBillingMethod?.billingInfor?.state || '',
      postalCode: dataDetailBillingMethod?.billingInfor?.postalcode || '',
      paymentMethod: dataDetailBillingMethod?.billingInfor?.paymentMethod || '',
      payId: dataDetailBillingMethod?.billingInfor?.payId || '',
      country:
        countries?.data.find(
          (e) =>
            e.id === (dataDetailBillingMethod?.billingInfor?.countryId || '')
        )?.id || '',
    };
    const tmp1 = Object.fromEntries(Object.entries(init || {}).filter(([_, v]) => v !== ''));
    const tmp2 = Object.fromEntries(Object.entries(reduxFormValues || {}).filter(([_, v]) => v !== ''));
    setFormValues({ ...tmp1, ...tmp2 });
  }, [dataDetailBillingMethod, reduxFormValues]);

  const setPrefillValue = () => {
    const countryId = countries?.data.find(
      (e) =>
        e.countryIsoCode ===
        (listCustomerConfig?.data?.invoice_country_name?.value || '')
    )?.id;
    const formVal2Set = {
      ...currentValues,
      firstName: listCustomerConfig?.data?.invoice_first_name?.value || '',
      lastName: listCustomerConfig?.data?.invoice_last_name?.value || '',
      phone: listCustomerConfig?.data?.invoice_phone?.value || '',
      address: listCustomerConfig?.data?.invoice_address?.value || '',
      address2: listCustomerConfig?.data?.invoiceextended_address?.value || '',
      city: listCustomerConfig?.data?.invoice_city?.value || '',
      province: listCustomerConfig?.data?.invoice_state?.value || '',
      postalCode: listCustomerConfig?.data?.invoice_postalcode?.value || '',
      country:
        countryId ? countryId + '' : null,
      ts: new Date().getTime(),
    };
    setFormValues({ ...formVal2Set });
  };

  const activeItem = (aType = null) => {
    if (aType === activePaymentMethod) {
      setActivePaymentMethod(null);
    } else {
      setActivePaymentMethod(aType);
    }
  };

  const handleOnAddPaypalAccount = () => {
    setLoading(true);
    redirectPaypal()
      .then((response) => {
        setLoading(false);
        if (response?.code === 'success' && response?.data?.url) {
          notification.success({
            message: 'Please wait for a minute ...',
          });
          window.location.href = response?.data?.url;
        } else {
          setLoading(false);
          notification.warn({
            message:
              response?.[0]?.clientMsg || 'Register Paypal account failed.',
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        notification.warn({
          message: error?.clientMsg || 'Register Paypal account failed.',
        });
      });
  };

  const handleOnAddLianAccount = () => {
    setLoading(true);
    redirectLianLian()
      .then((response) => {
        setLoading(false);
        if (response?.code === 'success' && response?.data) {
          notification.success({
            message: 'Please wait for a minute ...',
          });
          window.location.href = response?.data;
        } else {
          // The maximum number of accounts that can be linked is 5
          // Invalid payment account number
          // invalid_number
          setLoading(false);
          if (response?.[0]?.code === 'invalid_number') {
            return notification.warn({
              message: 'The maximum number of accounts that can be linked is 1',
            });
          }
          notification.warn({
            message:
              response?.[0]?.clientMsg || 'Register LianLian account failed.',
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        notification.warn({
          message: error?.clientMsg || 'Register LianLian account failed.',
        });
      });
  };
  const handleOnLoginPayoneer = () => {
    setLoading(true);
    loginPayoneer()
      .then((response) => {
        if (response?.code === 'success' && response?.data?.login_link) {
          notification.success({
            message: 'Please wait for a minute ...',
          });
          window.location.href = response?.data?.login_link;
        } else {
          setLoading(false);
          notification.warn({
            message: response?.[0]?.clientMsg || 'Login Payoneer failed.',
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        notification.warn({
          message: error?.clientMsg || 'Login Payoneer failed.',
        });
      });
    return false;
  };
  const handleOnRegisterPayoneer = () => {
    setLoading(true);
    registerPayoneer()
      .then((response) => {
        if (response?.code === 'success' && response?.data?.registration_link) {
          notification.success({
            message: 'Please wait for a minute ...',
          });
          window.location.href = response?.data?.registration_link;
        } else {
          setLoading(false);
          notification.warn({
            message:
              response?.[0]?.clientMsg || 'Register Payoneer account failed.',
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        notification.warn({
          message: error?.clientMsg || 'Register Payoneer account failed.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDisconnectPayoneerAccount = () => {
    cancelPayoneer()
      .then((response) => {
        setLoading(false);
        if (response?.code === 'success') {
          setPayoneerAccount(null);
          notification.success({
            message: MSG_REMOVE_PM_SUCCESS,
          });
        } else {
          notification.warn({
            message: MSG_REMOVE_PM_FAIL,
          });
        }
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        dispatch(push('/billing/method'));
        dispatch(loadListAccountAction());
      });
  };

  const handleDisconnectLianAccount = () => {
    dispatch(deleteBillingMethodAction({
      payId: accLianLian?.id,
    }));
  };
  const removePayoneerDialog = cancelRemovePayoneerAction && (
    <DiscardBillingMethodConfirmModal
      onOk={() => {
        cancelRemovePayoneerAction();
        setCancelRemovePayoneerAction(null);
      }}
      onCancel={() => setCancelRemovePayoneerAction(null)}
    />
  );
  if (Object?.keys(params)?.length > 0) {
    return (
      <Spin spinning={dataDetailBillingMethodLoading}>
        <InfoForm
          altParams={params}
          pageName={pageName}
          initialValues={formValues}
          setPrefillValue={setPrefillValue}
          handleDisconnectPayoneerAccount={() =>
            setCancelRemovePayoneerAction(() => handleDisconnectPayoneerAccount)
          }
        />
        {removePayoneerDialog}
      </Spin>
    );
  }

  const newGMBillingInfo = () => {
    if (
      primaryBillingPaymentAccount?.billing?.type === 3 ||
      listBillingPaymentAccount?.billings?.find((item) => item?.type === 3)
    ) {
      return notification.warn({
        message:
          ' You’ve already added this billing method. To add a new one, simply remove an existing method.',
        duration: 5,
      });
    }
    dispatch(push('/billing/method/new?code=gwallet'));
  };

  const onCancelGWallet = () => {
    activeItem(null);
  };

  const handleOk = () => {
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <WrapperModal>
      <Modal
        centered
        title={<b>Add new billing method</b>}
        visible={isOpenModal}
        onOk={handleOk}
        onCancel={handleCancel}
        className="topup-modal"
        footer={false}
        maskClosable={false}
        destroyOnClose
      // {...props_modal}
      >
        <Spin spinning={loading}>
          <WrapperAddBilling>
            <div className="pmlist">
              <div
                className={classNames('addpm', {
                  active: activePaymentMethod === 'paypal',
                })}
              >
                <div
                  className="inner-wrapper"
                  tabIndex="0"
                  onClick={() => activeItem('paypal')}
                >
                  <PaypalIcon />
                </div>
                <div className="toggle-section">
                  <p className="description">
                    Press Add PayPal button below to authorize your account.
                  </p>
                  <GMButton
                    size="xl"
                    type="button"
                    onClick={handleOnAddPaypalAccount}
                  >
                    Add Paypal
                  </GMButton>
                </div>
              </div>
              <div
                className={classNames('addpm', {
                  active: activePaymentMethod === 'payoneer',
                })}
              >
                <div
                  className="inner-wrapper"
                  tabIndex="0"
                  onClick={() => activeItem('payoneer')}
                >
                  <PayoneerIcon />
                </div>
                <div className="toggle-section">
                  <p className="description">
                    If you prefer Payoneer, this is fine with us! If you add
                    both, we will use Payoneer as the default option.
                  </p>
                  {!(payoneerAccount && accPayoneer) ? (
                    <>
                      <p className="description bold">
                        Link Payoneer account (for international accounts)
                      </p>
                      <div className="buttons">
                        <GMButton
                          type="button"
                          color="SecondaryGray"
                          onClick={handleOnLoginPayoneer}
                        >
                          Login Payoneer
                        </GMButton>
                        <GMButton
                          type="button"
                          onClick={handleOnRegisterPayoneer}
                        >
                          Register Payoneer
                        </GMButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="description bold">
                        Your account is linked to Payoneer
                      </p>
                      <div className="buttons">
                        <GMButton
                          type="button"
                          color="PrimaryError"
                          onClick={() =>
                            setCancelRemovePayoneerAction(
                              () => handleDisconnectPayoneerAccount
                            )
                          }
                        >
                          Remove account
                        </GMButton>
                      </div>
                    </>
                  )}
                  <p className="description bottom">
                    0% fee for payment to Gearment via Payoneer
                  </p>
                  {!payoneerAccount ? (
                    <p className="description warning">
                      Please link your Payoneer account to Gearment before
                      creating your paymnet method.
                    </p>
                  ) : null}
                </div>
              </div>
              <div
                className={classNames('addpm lian', {
                  active: activePaymentMethod === 'lian',
                })}
              >
                <div
                  className="inner-wrapper"
                  tabIndex="0"
                  onClick={() => activeItem('lian')}
                >
                  <LianLogoIcon />
                </div>
                <div className="toggle-section">
                  <p className="description">
                    Press Add LianLian button below to authorize your account.
                  </p>
                  {!accLianLian ? (
                    <GMButton
                      size="xl"
                      type="button"
                      onClick={handleOnAddLianAccount}
                    >
                      Add LianLian
                    </GMButton>
                  ) : (
                    <GMButton
                      type="button"
                      color="PrimaryError"
                      onClick={() =>
                        setCancelRemovePayoneerAction(
                          () => handleDisconnectLianAccount
                        )
                      }
                    >
                      Remove account
                    </GMButton>
                  )}
                </div>
              </div>
            </div>
            {activePaymentMethod === 'gwallet' ? (
              <div className="cta-blk">
                <div className="clearfix">
                  <GMButton
                    color="SecondaryGray"
                    onClick={onCancelGWallet}
                    type="button"
                  >
                    Cancel
                  </GMButton>
                  <GMButton onClick={newGMBillingInfo}>Next</GMButton>
                </div>
              </div>
            ) : null}
          </WrapperAddBilling>
          {removePayoneerDialog}
        </Spin>
      </Modal>
      <GlobalStyle />
    </WrapperModal>
  );
};
