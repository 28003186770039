export const actionType = {
    LIST: {
        REQUEST: 'INVOICES.LIST.REQUEST',
        SUCCESS: 'INVOICES.LIST.SUCCESS',
        ERROR: 'INVOICES.LIST.ERROR',
    },
    EXPORT_ORDERS: {
        REQUEST: 'INVOICES.EXPORT_ORDERS.REQUEST',
        SUCCESS: 'INVOICES.EXPORT_ORDERS.SUCCESS',
        ERROR: 'INVOICES.EXPORT_ORDERS.ERROR',
    },
    EXPORT_INVOICE: {
        REQUEST: 'INVOICES.EXPORT_INVOICE.REQUEST',
        SUCCESS: 'INVOICES.EXPORT_INVOICE.SUCCESS',
        ERROR: 'INVOICES.EXPORT_INVOICE.ERROR',
    },
};

export function getList(params) {
    return {
        type: actionType.LIST.REQUEST,
        params
    }
}

export function exportOrders(id){
    return {
        type: actionType.EXPORT_ORDERS.REQUEST,
        id
    }
}

export function exportInvoice(id){
    return {
        type: actionType.EXPORT_INVOICE.REQUEST,
        id
    }
}