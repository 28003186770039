import { takeLatest, call, put, all } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import {
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  ORDER_UPDATE_ADDRESS_REQUEST,
  ORDER_UPDATE_ADDRESS_SUCCESS,
  ORDER_UPDATE_ADDRESS_FAIL,
  UPDATE_GIFT_MESSAGE_REQUEST,
  UPDATE_GIFT_MESSAGE_SUCCESS,
  UPDATE_GIFT_MESSAGE_FAIL,
  UPDATE_SHIPPING_SERVICE_REQUEST,
  UPDATE_SHIPPING_SERVICE_SUCCESS,
  UPDATE_SHIPPING_SERVICE_FAIL,
  DUPLICATE_ORDER_REQUEST,
  DUPLICATE_ORDER_SUCCESS,
  DUPLICATE_ORDER_FAIL,
  LOCK_ORDER_SUCCESS,
  LOCK_ORDER_FAIL,
  LOCK_ORDER_REQUEST,
  //
  LIST_DUPLICATED_ORDER_REQUEST,
  LIST_DUPLICATED_ORDER_SUCCESS,
  LIST_DUPLICATED_ORDER_FAIL,
  //
  MARK_AS_VALID_ADDRESS_REQUEST,
  MARK_AS_VALID_ADDRESS_SUCCESS,
  MARK_AS_VALID_ADDRESS_FAIL,
  //Cancel order
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
} from './constants';

import {
  getDetail,
  updateAddress,
  updateGiftMessage,
  updateShippingService,
  duplicateOrder,
  lockOrder,
  listDuplicatedOrder,
  markAsValidAddress,
  cancelOrder,
} from 'apis/orders';
import { actionType as TYPE } from 'scenes/Orders/scenes/List/actions';

function* getOrderDetailSaga(action) {
  const { id } = action;
  try {
    const response = yield call(getDetail, id);
    if (response.code === 'success') {
      yield all([put({ type: ORDER_DETAIL_SUCCESS, ...response })]);
    } else {
      yield all([
        put({ type: ORDER_DETAIL_FAIL, error: { message: 'Not found order' } }),
        put(replace(`/orders/list`)),
      ]);
    }
  } catch (error) {
    yield all([yield put({ type: ORDER_DETAIL_FAIL, error })]);
  }
}

function* updateAddressSaga(action) {
  const { id, params } = action;
  try {
    const response = yield call(updateAddress, id, params);
    if (response.code === 'success') {
      yield all([
        put({ type: ORDER_UPDATE_ADDRESS_SUCCESS, ...response }),
        put({
          type: ORDER_DETAIL_REQUEST,
          id,
        }),
      ]);
    } else {
      yield put({ type: ORDER_UPDATE_ADDRESS_FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: ORDER_UPDATE_ADDRESS_FAIL, error })]);
  }
}

function* updateGiftMessageSaga(action) {
  const { id, data } = action;
  try {
    const response = yield call(updateGiftMessage, data);
    if (response.code === 'success') {
      yield all([
        put({ type: UPDATE_GIFT_MESSAGE_SUCCESS, ...response }),
        put({
          type: ORDER_DETAIL_REQUEST,
          id,
        }),
      ]);
    } else {
      yield put({ type: UPDATE_GIFT_MESSAGE_FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: UPDATE_GIFT_MESSAGE_FAIL, error })]);
  }
}

function* updateShippingServiceSaga(action) {
  const { id, data } = action;
  try {
    const response = yield call(updateShippingService, id, data);
    if (response.code === 'success') {
      yield all([
        put({ type: UPDATE_SHIPPING_SERVICE_SUCCESS, ...response }),
        put({
          type: ORDER_DETAIL_REQUEST,
          id,
        }),
      ]);
    } else {
      yield put({ type: UPDATE_SHIPPING_SERVICE_FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: UPDATE_SHIPPING_SERVICE_FAIL, error })]);
  }
}

function* duplicateOrderSaga(action) {
  const { data } = action;
  try {
    const response = yield call(duplicateOrder, data);
    if (response.code === 'success') {
      yield all([
        put({ type: DUPLICATE_ORDER_SUCCESS, ...response }),
        put(replace('/orders/list')),
      ]);
    } else {
      yield put({ type: DUPLICATE_ORDER_FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: DUPLICATE_ORDER_FAIL, error })]);
  }
}

function* lockOrderSaga(action) {
  const { id } = action;
  try {
    const response = yield call(lockOrder, id);
    if (response.code === 'success') {
      yield all([
        put({ type: LOCK_ORDER_SUCCESS, ...response }),
        put({
          type: ORDER_DETAIL_REQUEST,
          id,
        }),
      ]);
    } else {
      yield put({ type: LOCK_ORDER_FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: LOCK_ORDER_FAIL, error })]);
  }
}
function* listDuplicatedOrderSaga(action) {
  const { id } = action;
  try {
    const response = yield call(listDuplicatedOrder, id);
    if (response.code === 'success') {
      yield all([put({ type: LIST_DUPLICATED_ORDER_SUCCESS, ...response })]);
    } else {
      yield put({ type: LIST_DUPLICATED_ORDER_FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: LIST_DUPLICATED_ORDER_FAIL, error })]);
  }
}
//Cancel order
function* cancelOrderSaga(action) {
  const { data } = action;
  try {
    const response = yield call(cancelOrder, data);
    if (response.code === 'success') {
      yield all([
        put({ type: CANCEL_ORDER_SUCCESS, ...response }),
        put(replace(`/orders/list`)),
      ]);
    } else {
      yield put({ type: CANCEL_ORDER_FAIL, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: CANCEL_ORDER_FAIL, error })]);
  }
}

function* markAsValidAddressSaga(action) {
  const { id } = action;
  let orderId = parseInt(Object.values(id)[0]);

  try {
    const response = yield call(markAsValidAddress, id);
    let orderId = parseInt(id.order);

    if (response.code === 'success') {
      yield all([
        put({ type: MARK_AS_VALID_ADDRESS_SUCCESS, ...response }),
        put({
          type: ORDER_DETAIL_REQUEST,
          id: orderId,
        }),
      ]);
    } else {
      yield all([put({ type: MARK_AS_VALID_ADDRESS_FAIL, error: response })]);
    }
  } catch (error) {
    yield all([yield put({ type: MARK_AS_VALID_ADDRESS_FAIL, error })]);
  }
}
function* getOrderDetailWatcher() {
  yield takeLatest(ORDER_DETAIL_REQUEST, getOrderDetailSaga);
  yield takeLatest(ORDER_UPDATE_ADDRESS_REQUEST, updateAddressSaga);
  yield takeLatest(UPDATE_GIFT_MESSAGE_REQUEST, updateGiftMessageSaga);
  yield takeLatest(UPDATE_SHIPPING_SERVICE_REQUEST, updateShippingServiceSaga);
  yield takeLatest(DUPLICATE_ORDER_REQUEST, duplicateOrderSaga);
  yield takeLatest(LOCK_ORDER_REQUEST, lockOrderSaga);
  yield takeLatest(LIST_DUPLICATED_ORDER_REQUEST, listDuplicatedOrderSaga);
  yield takeLatest(MARK_AS_VALID_ADDRESS_REQUEST, markAsValidAddressSaga);
  yield takeLatest(CANCEL_ORDER_REQUEST, cancelOrderSaga);
}

export default getOrderDetailWatcher;
