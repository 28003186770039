import React, {useState, useEffect} from "react"
import {Table, Tag} from "antd";
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames';
import RenderAdditionalImages from "./RenderAdditionalImages";
export const RenderPreviewCampaign2 = ({data=[], campaign = {}}) => {
    const [listProductCampaigns, setListProductCampaigns] = useState([]);
    const onChooseColor = (productCampaignId, variantIndex) => {
        let temp = [...listProductCampaigns];
        for(let index = 0; index < temp.length; index ++){
            if(temp[index]['id'] === productCampaignId){
                for(let index2 = 0; index2 < (temp[index]['variants'] || []).length; index2++){
                    temp[index]['variants'][index2]['isActive'] = index2 === variantIndex ? 1 : 0;
                }
                break;
            }
        }
        setListProductCampaigns(temp);
    }
    useEffect(() => {
        for(let index = 0; index < data.length; index ++){
            if(data[index]['variants']){
                for(let index2 = 0; index2 < (data[index]['variants'] || []).length; index2++){
                    data[index]['variants'][index2]['isActive'] = parseInt(data[index]['variants'][index2]['colorId']) === parseInt(data[index]['defaultColor']) ? 1 : 0;
                }
            }
        }
        setListProductCampaigns(data);
    }, [data]);
    const columns= [
        {
            title: <b>NAME</b>,
            dataIndex: "variants",
            align: "center",
            width: "15%",
            key: "variants",
            render: (variants) => {
                if(variants[0]){
                    return variants[0]?.exBrandName || ''
                }
                return '';
            }
        },
        {
            title: <b>COLOR</b>,
            dataIndex: "variants",
            width: "30%",
            align: "center",
            key: "variants",
            render: (variants, record, i) => {
                return (variants || []).map((variant, variantIndex)=> (
                    <Tag
                    className={classNames("mb-2 cursor-pointer", {"border-secondary text-dark": parseInt(variant?.colorId) === 12})}
                      color={`#${variant.colorHex}`}
                      key={variant.colorId}
                      onClick={() => {onChooseColor(listProductCampaigns[i]['id'], variantIndex)}}
                    >
                      <span className={classNames({"invisible": parseInt(variant['isActive']) === 0, "visible": parseInt(variant['isActive']) === 1})}><FontAwesomeIcon icon={faCheck}/></span>
                    </Tag>
                ))
            }
        },
        {
            title: <b>SIZE</b>,
            dataIndex: "variants",
            align: "center",
            width: "10%",
            key: "variants",
            render: (variants) => {
                if(variants[0]){
                    return variants[0]?.size || 'ALL'
                }
                return '';
            }
        },
        {
            title: <b>IMAGE</b>,
            dataIndex: "variants",
            align: "center",
            width: "40%",
            key: "variants",
            render: (variants) => {
                for(let index = 0; index < variants.length; index ++){
                    if(variants[index]['isActive'] === 1){
                        return (
                            <div>
                                {variants[index]?.imageUrl && <img className="img-fluid mr-1" width="250" src={variants[index]?.imageUrl} alt="front-design"/>}
                                {variants[index]?.imageOtherUrl && <img className="img-fluid" width="250" src={variants[index]?.imageOtherUrl} alt="back-design"/>}
                            </div>
                        )
                    }
                }
                return '';
            }
        },
        {
            title: <b></b>,
            align: "left",
            dataIndex: "productAdditionImages",
            width: "10%",
            key: "productAdditionImages",
            render: (productAdditionImages, record, i) => {
                const temp = (productAdditionImages &&
                    Array.isArray(productAdditionImages) ?
                        productAdditionImages : []).map((item, index) => ({id: index + 1, url: item.url}));
                const numOfAdditionalImage = temp.length;
                for(let i = temp.length + 1; i <= 5; i++){
                    temp.push({
                        id: i,
                        url: ''
                    });
                }
                return <div><RenderAdditionalImages name={`FormUpdateAdditionalImages${campaign?.id || 0}${record?.id || 0}`} numOfAdditionalImage={numOfAdditionalImage} initialValues={{"listAdditionalImages": temp}} listAdditionalImages={temp} campaign={campaign || {}} productCampaign={record}/></div>
            }
        },
    ];
    return (
        <div>
            <Table
                rowKey="id"
                scroll={{ x: 1000 }}
                columns={columns}
                dataSource={listProductCampaigns}
                loading={false}
                pagination={false}
            />
        </div>
    );
}