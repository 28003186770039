import { takeLatest, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import {
  DATA_FEED_AMAZON_CREATE_REQUEST,
  DATA_FEED_AMAZON_CREATE_SUCCESS,
  DATA_FEED_AMAZON_CREATE_FAIL,
} from "./constants";
import { createAmazon } from "apis/feed_forms";

function* createDataFeedAmazonSaga(action) {
  try {
    const { data_feed } = action;
    const response = yield call(createAmazon, data_feed);

    if (response.code === "success") {
      yield all([
        put({ type: DATA_FEED_AMAZON_CREATE_SUCCESS, response }),
        put(replace("/settings/general", { activeTag: "6" })),
      ]);
    } else {
      yield put({ type: DATA_FEED_AMAZON_CREATE_FAIL, response });
    }
  } catch (error) {
    yield all([put({ type: DATA_FEED_AMAZON_CREATE_FAIL, error })]);
  }
}

function* createDataFeedAmazonWatcher() {
  yield all([
    takeLatest(DATA_FEED_AMAZON_CREATE_REQUEST, createDataFeedAmazonSaga),
  ]);
}

export default createDataFeedAmazonWatcher;
