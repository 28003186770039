import { takeLatest, call, put, all } from "redux-saga/effects";
import { actionType as TYPE } from "./actions";
import { getDetail } from "apis/notifications";
import { replace } from "connected-react-router";
import { actionType } from "../List/actions";

function* detailNotificationSaga(action) {
  try {
    const { id } = action;
    const response = yield call(getDetail, id);
    if (response.code === "success" && response.data.notification) {
      yield put({ type: TYPE.DETAIL.SUCCESS, ...response });
    } else {
      yield all([
        yield put({ type: actionType.LIST.REQUEST, error: {message: 'Not found notify'} }),
        yield put(replace(`/notification`)),
      ]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.DETAIL.ERROR, error })]);
  }
}

function* detailNotificationWatcher() {
  yield all([takeLatest(TYPE.DETAIL.REQUEST, detailNotificationSaga)]);
}

export default detailNotificationWatcher;
