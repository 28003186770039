import React, { useEffect } from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, notification } from "antd";
import { list_payment_method } from "utils/options";
import SelectPaymentMethod from "utils/Form/Search/SelectPaymentMethod";
import {
  refetchBillingPaymentAccount,
  setLoading,
} from "scenes/BillingPaymentAccount/actions";
import { create } from "apis/billing_payment_account";
import SelectPaymentAccount from "./components/SelectPaymentAccount";
import { renderCheckboxGroup, renderInputText, renderRadioAnt } from "utils/Form";
import SelectCountries from "utils/Form/Search/SelectCountries";
import SelectBillingInfor from "utils/Form/Search/SelectBillingInfor";
const validate = (values) => {
  const errors = {};
  if (parseInt(values?.billingInfoType) === 1) {
    if (parseInt(values?.billingInforId) === 0) {
      if (
        !values.firstName ||
        values.firstName.replace(/\s+/g, "").length === 0
      ) {
        errors.firstName = "Please enter first name.";
      }
      if (
        !values.lastName ||
        values.lastName.replace(/\s+/g, "").length === 0
      ) {
        errors.lastName = "Please enter last name.";
      }
      if (!values.address || values.address.replace(/\s+/g, "").length === 0) {
        errors.address = "Please enter address 1.";
      }
      if (!values.city || values.city.replace(/\s+/g, "").length === 0) {
        errors.city = "Please enter city.";
      }
      if (
        !values.province ||
        values.province.replace(/\s+/g, "").length === 0
      ) {
        errors.province = "Please enter state/province.";
      }
      if (
        !values.postalCode ||
        values.postalCode.replace(/\s+/g, "").length === 0
      ) {
        errors.postalCode = "Please enter zip code/postal code.";
      }
    } else if (parseInt(values?.billingInforId) === -1) {
      errors.billingInforId =
        "Please choose a billing infor that you've used before or add new billing info.";
    }
  }
  if (parseInt(values?.paymentMethod) === 2) {
    if (
      !values.cardNumber ||
      !values.cardNumber.trueValue ||
      values.cardNumber.trueValue.replace(/\s+/g, "").length === 0
    ) {
      errors.cardNumber = "Please enter card number.";
    }
    if (
      !values.cardholderName ||
      values.cardholderName.replace(/\s+/g, "").length === 0
    ) {
      errors.cardholderName = "Please enter cardholder name.";
    }
    if (
      !values.expirationDate ||
      !values.expirationDate.formattedValue ||
      values.expirationDate.formattedValue.replace(/\s+/g, "").length === 0
    ) {
      errors.expirationDate = "Please enter expiration date.";
    }
    if (!values.cvv || values.cvv.trueValue.replace(/\s+/g, "").length === 0) {
      errors.cvv = "Please enter cvv.";
    }
  }
  if (parseInt(values?.paymentMethod) !== 3 && !values?.paymentAccountId) {
    if (parseInt(values?.paymentMethod) === 1) {
      errors.paymentAccountId =
        "Please link your Payoneer account to Gearment before creating your payment method.";
    }
    if (parseInt(values?.paymentMethod) === 0) {
      errors.paymentAccountId =
        "Please choose a Paypal account before creating your payment method.";
    }
  }
  return errors;
};
function BillingPaymentAccountForm({
  handleSubmit,
  paymentMethod,
  billingInfoType,
  billingInforId,
  changeFieldValue,
  FormBillingPaymentAccountValues,
  nonce,
  ...props
}) {
  const dispatch = useDispatch();
  const isRefetching = useSelector(
    (state) => state.billingPaymentAccount.isRefetching
  );
  const handleOnCreateBillingInfor = (values) => {
    if (parseInt(values?.paymentMethod) === 2 && !values?.nonce) return;
    dispatch(setLoading(true));
  };
  useEffect(() => {
    if (parseInt(billingInfoType) === 2) {
      changeFieldValue("billingInforId", -1);
    }
  }, [billingInfoType]);
  useEffect(() => {
    changeFieldValue("paymentAccountId", 0);
  }, [paymentMethod]);
  return (
    <div className="bg-white">
      <h5>Billing Settings</h5>
      <form id="cardForm">
        <div className="form-group mb-2">
          <Field
            name="paymentMethod"
            component={SelectPaymentMethod}
            options={list_payment_method}
          />
          <h5>
            Method:{" "}
            {
              list_payment_method.find(
                (method) => method.value === parseInt(paymentMethod)
              )?.title
            }
          </h5>
        </div>
        <div className="p-4 card">
          {" "}
          <div className="form-group mb-2">
            <label className=" h5 mb-4" style={{ fontWeight: "500" }}>
              Billing Info
            </label>
            <Field
              name="billingInfoType"
              component={renderRadioAnt}
              classNameItem={""}
              colSpan={12}
              options={[
                {
                  value: 1,
                  label: "Add manually",
                },
                {
                  value: 2,
                  label: "Use my legal info",
                },
              ]}
            />
          </div>
          <div className="mt-2">
            {parseInt(billingInfoType) === 1 && (
              <div className="form-group mb-2">
                <Field
                  name="billingInforId"
                  component={SelectBillingInfor}
                  isRefetching={isRefetching}
                />
              </div>
            )}
            {parseInt(billingInfoType) === 1 && parseInt(billingInforId) === 0 && (
              <div className="container-fluid p-0 mb-2">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">First name</label>
                      <Field
                        name="firstName"
                        component={renderInputText}
                        maxLength={"50"}
                        placeholder="First name"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Last name</label>
                      <Field
                        name="lastName"
                        component={renderInputText}
                        maxLength={"50"}
                        placeholder="Last name"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Street Address</label>
                      <Field
                        name="address"
                        component={renderInputText}
                        maxLength={"50"}
                        placeholder="Street Address"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Extened Address</label>
                      <Field
                        name="address2"
                        component={renderInputText}
                        maxLength={"50"}
                        placeholder="Extended Address"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">City</label>
                      <Field
                        name="city"
                        component={renderInputText}
                        maxLength={"50"}
                        placeholder="City"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Province / State</label>
                      <Field
                        name="province"
                        component={renderInputText}
                        maxLength={"50"}
                        placeholder="Province / State"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Zip code/Postal code</label>
                      <Field
                        name="postalCode"
                        component={renderInputText}
                        maxLength={"50"}
                        placeholder="Enter zip code/postal code"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Country</label>
                      <Field
                        name="country"
                        component={SelectCountries}
                        maxLength={"50"}
                        placeholder="Country"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="form-group mb-1 mt-3 p-4 card">
          <label className=" h5 mb-4" style={{ fontWeight: "500" }}>
            Billing Method
          </label>
          <Field
            name="paymentAccountId"
            component={SelectPaymentAccount}
            submitForm={handleSubmit(handleOnCreateBillingInfor)}
            nonce={nonce}
            changeFieldValue={changeFieldValue}
            paymentMethod={paymentMethod}
          />
        </div>
        <div className="form-group mb-1 mt-3">
          <Field
            name="isPrimary"
            component={renderCheckboxGroup}
            colSpan={24}
            options={[
              {
                value: 1,
                label: (
                  <span className="font-size-16">
                    Set as account primary payment method
                  </span>
                ),
              },
            ]}
          />
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <Button
              type="primary"
              className="search-button w-100 font-weight-bold"
              size="large"
              onClick={handleSubmit(handleOnCreateBillingInfor)}
              id="addPaymentMethodBtn"
            >
              ADD BILLING METHOD
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

const selector = formValueSelector("FormBillingPaymentAccount");
export default React.memo(
  connect(
    (state) => ({
      paymentMethod: selector(state, "paymentMethod"),
      billingInfoType: selector(state, "billingInfoType"),
      billingInforId: selector(state, "billingInforId"),
      FormBillingPaymentAccountValues:
        state.form?.FormBillingPaymentAccount?.values || {},
      nonce: selector(state, "nonce"),
    }),
    (dispatch) => ({
      changeFieldValue: function (field, value) {
        dispatch(change("FormBillingPaymentAccount", field, value));
      },
    })
  )(
    reduxForm({
      form: "FormBillingPaymentAccount",
      enableReinitialize: true,
      validate,
    })(BillingPaymentAccountForm)
  )
);
