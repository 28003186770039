import { BLUE_400, GRAY_600, PRIMARY_500 } from 'components/colors';
import styled from 'styled-components';
export const Wrapper = styled.div`
  .apiKey {
    padding-bottom: 32px;
    .copy-btn {
      margin-left: 32px;
      color: ${GRAY_600};
      flex-wrap: wrap;
    }
    .copied-btn {
      margin-left: 32px;
      color: ${BLUE_400};
      flex-wrap: wrap;
      border-color: ${BLUE_400};
    }
    .flip-card {
      /* display: inline-block;
      width: 20px;
      height: 20px; */
    }
    svg {
     
      &.copied {
        stroke: ${BLUE_400};
        width: 20px;
        height: 20px;
        cursor: default;
      }
    }
  }
  .title {
    color: ${GRAY_600};
    font-weight: 600;
  }
`;
