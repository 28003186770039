import React, { useState} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Drawer } from "antd";
import AdvancedFilter from "./AdvancedFilter";
import CloseIcon from "images/x2.svg";
function FormReportAccountDetail({
  handleAdvancedSubmit,
  changeFieldValue,
  onClearFilter,
  initialValues,
  is_mobile,
  detailReportData,
  ...props
}) {
  const [advancdSearch, setAdvancedSearch] = useState(false);

  const onCloseAdvancedSearch = () => {
    setAdvancedSearch(false);
  };
  const onOpenAdvancedSearch = () => {
    setAdvancedSearch(true);
  };
  return (
    <>
      <div className="h-100">
        <div className="row justify-content-end align-items-center">
          <div className="col-12 col-md-4 col-lg-3">
            <p className="More-Filter m-0 text-md-right" onClick={onOpenAdvancedSearch}>
              More Filter
            </p>
          </div>
          <div className="col-12 col-md-3 my-3 my-md-0">
            <button
              type="button"
              className="btn btn-primary Rectangle-817 w-100"
              onClick={() => {
                props.handleOnExport();
              }}
            >
              <FontAwesomeIcon className="mr-2" icon={faDownload} /> EXPORT
            </button>
          </div>
        </div>
      </div>
      <Drawer
        title={<b className="Filter">Filter</b>}
        className="custom-filter"
        placement="right"
        closable={true}
        onClose={onCloseAdvancedSearch}
        visible={advancdSearch}
        closeIcon={<img src={CloseIcon} style={{ width: "20px" }}  alt="close icon"/>}
        width={is_mobile ? "80%" : "480px"}
      >
        <AdvancedFilter
          onCloseAdvancedSearch={onCloseAdvancedSearch}
          onSubmit={handleAdvancedSubmit}
          changeFieldValue={changeFieldValue}
          initialValues={initialValues}
          onClearFilter={onClearFilter}
          detailReportData={detailReportData}
        />
      </Drawer>
    </>
  );
}

FormReportAccountDetail.propTypes = {};
export default FormReportAccountDetail;