import { all } from 'redux-saga/effects'
import ProfileSaga from './scenes/Profile/saga'
import ChangePasswordSaga from './scenes/ChangePassword/saga'
import ChangeEmail from './scenes/ChangeEmail/saga'
import ResetPassword from './scenes/ResetPassword/saga'
export default function* (){
  yield all([
    ProfileSaga(),
    ChangePasswordSaga(),
    ChangeEmail(),
    ResetPassword(),
  ])
}