export const actionType = {
  DETAIL_TRANSACTION: {
    REQUEST: "TRANSACTIONS.DETAIL_TRANSACTION.REQUEST",
    SUCCESS: "TRANSACTIONS.DETAIL_TRANSACTION.SUCCESS",
    ERROR: "TRANSACTIONS.DETAIL_TRANSACTION.ERROR",
  },
};

export function getDetail(id) {
  return {
    type: actionType.DETAIL_TRANSACTION.REQUEST,
    id,
  };
}
