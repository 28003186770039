import {
    actionType as TYPE
} from './actions'
import {notification} from "antd";
const initialState = {
    creating: false,
    exproducts: {
        loading: false,
        data: [],
        data_obj: {}
    }
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case TYPE.CREATE.REQUEST:
            return {
                ...state,
                creating: true,
            }
        case TYPE.CREATE.SUCCESS:
            notification.success({className: "notic2", message: 'Create mockup successfully.', duration: 5});
            return {
                ...state,
                creating: false,
            }
        case TYPE.CREATE.ERROR:
            notification.warn({
                className: "notic1",
                message: action.error && action.error[0] && action.error[0]['msg'] ? action.error[0]['msg'] : 'Create mockup failed.',
                duration: 5
            });
            return {
                ...state,
                creating: false
            }

        case TYPE.LIST_EXPRODUCT.REQUEST:
            return {
                ...state,
                exproducts: {
                    ...initialState.exproducts,
                    loading: true
                },
            }
        case TYPE.LIST_EXPRODUCT.SUCCESS:
            let exproducts_obj = {}
            const exproducts = action.data.exProducts.map(row=>{
                exproducts_obj[row.id] = row
                return {...row, value: row.id, label: row.name}
            })
            return {
                ...state,
                exproducts: {
                    ...state.exproducts,
                    loading: false,
                    data: exproducts,
                    data_obj: exproducts_obj
                },
            }
        case TYPE.LIST_EXPRODUCT.ERROR:
            return {
                ...state,
                exproducts: {
                    ...state.exproducts,
                    loading: false
                },
            }
        default:
            return state
    }
}

export default reducer