import React, { useState, useEffect } from "react";
import { Button, Spin, Comment, Avatar } from "antd";
import BackToIcon from "images/chevron-down.svg";
import { detailIssue, createMessage } from "apis/order-issues";
import ListMessages from "./components/ListMessage";
import MessageEditor from "./components/MessageEditor";
import CustomerIcon from "images/customer.png";
function DetailIssue({ pageName, ...props }) {
  const [loading, setLoading] = useState(false);
  const [createMessageLoading, setCreateMessageLoading] = useState(false);
  const [issue, setIssue] = useState(null);
  const [newMessages, setNewMessage] = useState(null)
  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);
    detailIssue(props.match.params.id)
      .then(({ code, data }) => {
        if (!isSubscribed) return;
        setLoading(false);
        if (code === "success") {
          setIssue(data?.issue);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    return () => {
      isSubscribed = false;
    };
  }, []);

  const handleOnSendMessage = (message = "") => {
    setCreateMessageLoading(true);
    createMessage(props.match.params.id, {
      content: message,
    })
      .then(({ code, data }) => {
        setCreateMessageLoading(false);
        if (code === "success") {
          setNewMessage(data);
        }
      })
      .catch((error) => {
        setCreateMessageLoading(false);
      });
  };
  return (
    <>
      <div className="Rectangle-848 px-3 py-1">
        <div className="Orders p-2">
          {pageName} {`#${props.match.params.id}`}
        </div>
      </div>
      <div className="m-3">
        <Button
          className="BackToButton d-flex align-items-center my-4"
          onClick={() => {
            props.history.push("/orders/order-issues");
          }}
        >
          <img src={BackToIcon} alt="back-icon" />
          <p className="m-0 ml-2">Back to Issues</p>
        </Button>
        <Spin spinning={loading}>
          <div className="card">
            <div className="card-body">
              <p className="font-size-16">{issue?.noteCustomer || ""}</p>
              <ListMessages issueId={props.match.params.id} newMessages={newMessages}/>
              <Comment
                avatar={<Avatar src={CustomerIcon} alt="Customer" />}
                content={
                  <MessageEditor
                    onChange={() => {}}
                    onSubmit={handleOnSendMessage}
                    submitting={createMessageLoading}
                  />
                }
              />
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
}

export default DetailIssue;