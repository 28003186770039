import React from 'react';
import AuthLogin from 'scenes/Auth/scenes/Login';
import ListStore from 'scenes/Stores/scenes/List';

export default ({ logged, ...props }) => {
  if (logged) {
    return <ListStore {...props} />;
  } else {
    return <AuthLogin {...props} />;
  }
};
