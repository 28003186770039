import { actionType as TYPE } from './actions';
import { notification } from 'antd';

const initialState = {
  listCustomerConfig: {
    data: {},
    loading: false,
  },
  updateInvoiceConfigs: {
    loading: false,
  },
  updateReturnAddress: {
    loading: false,
  },
  defaultGateway: {
    loading: false,
    data: {},
  },
  authorization: {
    data: {},
    loading: false,
  },
};

function ApiKeyReducer(state = initialState, action) {
  switch (action.type) {
    //Default gateway
    case TYPE.DEFAULT_GATEWAY_SET.REQUEST:
      return {
        ...state,
        defaultGateway: {
          ...state.defaultGateway,
          loading: true,
        },
      };
    case TYPE.DEFAULT_GATEWAY_SET.SUCCESS:
      return {
        ...state,
        defaultGateway: {
          ...state.defaultGateway,
          loading: false,
          data: action.response.data,
        },
      };
    case TYPE.DEFAULT_GATEWAY_SET.ERROR:
      return {
        ...state,
        defaultGateway: {
          ...state.defaultGateway,
          loading: false,
        },
      };
    //Get list customer config key
    case TYPE.LIST_CUSTOMER_CONFIG.REQUEST:
      return {
        ...state,
        listCustomerConfig: {
          ...initialState.listCustomerConfig,
          loading: true,
        },
      };
    case TYPE.LIST_CUSTOMER_CONFIG.SUCCESS:
      const arrTemp = [];
      if (action.data.configs && Array.isArray(action.data.configs)) {
        for (let index = 0; index < action.data.configs.length; index++) {
          arrTemp[action.data.configs[index]['key']] =
            action.data.configs[index];
        }
      }
      return {
        ...state,
        listCustomerConfig: {
          ...state.listCustomerConfig,
          loading: false,
          data: arrTemp,
        },
      };
    case TYPE.LIST_CUSTOMER_CONFIG.ERROR:
      return {
        ...state,
        listCustomerConfig: {
          ...state.listCustomerConfig,
          loading: false,
        },
      };
    //Update Invoice configs
    case TYPE.UPDATE_INVOICE_CONFIG.REQUEST:
      return {
        ...state,
        updateInvoiceConfigs: {
          ...state.updateInvoiceConfigs,
          loading: true,
        },
      };
    case TYPE.UPDATE_INVOICE_CONFIG.SUCCESS:
      notification.success({
        message: action?.clientMsg,
        duration: 5,
      });
      return {
        ...state,
        updateInvoiceConfigs: {
          ...state.updateInvoiceConfigs,
          loading: false,
        },
      };
    case TYPE.UPDATE_INVOICE_CONFIG.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Update Invoice configs failed.',
        duration: 5,
      });
      return {
        ...state,
        updateInvoiceConfigs: {
          ...state.updateInvoiceConfigs,
          loading: false,
        },
      };
    //Update return address
    case TYPE.UPDATE_RETURN_ADDRESS.REQUEST:
      return {
        ...state,
        updateReturnAddress: {
          ...state.updateReturnAddress,
          loading: true,
        },
      };
    case TYPE.UPDATE_RETURN_ADDRESS.SUCCESS:
      notification.success({
        message: 'Update return address successfully.',
        duration: 5,
      });
      return {
        ...state,
        updateReturnAddress: {
          ...state.updateReturnAddress,
          loading: false,
        },
      };
    case TYPE.UPDATE_RETURN_ADDRESS.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Update return address failed.',
        duration: 5,
      });
      return {
        ...state,
        updateReturnAddress: {
          ...state.updateReturnAddress,
          loading: false,
        },
      };
    //Authorization
    case TYPE.AUTHORIZATION.REQUEST:
      return {
        ...state,
        authorization: {
          ...state.authorization,
          loading: true,
        },
      };
    case TYPE.AUTHORIZATION.SUCCESS:
      return {
        ...state,
        authorization: {
          ...state.authorization,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.AUTHORIZATION.ERROR:
      return {
        ...state,
        authorization: {
          ...state.authorization,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ApiKeyReducer;
