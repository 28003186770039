import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import validate from './validate';
import { renderSwitchAnt, renderSelectAnt, renderInputText } from 'utils/Form';
import shopify_logo from 'assets/images/shopify_logo.png';
import { Wrapper } from '../styled';
import { GMButton } from 'components/Button';
import { WrapperUpdateButton } from '../../styled';
import { ReactComponent as ShopifyIcon } from 'images/Logo_store/full/shopify.svg';
import { GMTextbox } from 'components/InputFields';

let index = ({
  handleSubmit,
  onCancel,
  accounts,
  currAction,
  profile,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit} className="mx-2">
      <WrapperUpdateButton>
        <div className="text-left my-3">
          <ShopifyIcon />
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Store name</b>
          </label>
          <GMTextbox name="showName"  showError/>
        </div>
        <div className="form-group">
          <label className="col-form-label">
            <b>Merchant</b>
          </label>
          <GMTextbox
            name="url"
            // addonAfter=".myshopify.com"
            disabled
            showError
          />
        </div>
        {parseInt(profile.primaryId) === 0 && (
          <div className="form-group">
            <label className="col-form-label">
              <b>Staff account</b>
            </label>
            <Wrapper>
              <Field
                name="staffId"
                component={renderSelectAnt}
                placeholder="-- Select staff --"
                mode={'multiple'}
                options={accounts.map((item) => ({
                  value: item.id.toString(),
                  label: item.name,
                }))}
              />
            </Wrapper>
          </div>
        )}
        <div className="form-group">
          <div className="d-flex align-items-center">
            <Field name="autoSync" component={renderSwitchAnt} />
            <label htmlFor="store_auto_sync" className="ml-3 col-form-label">
              Automatic synchronization when new orders arrive.{' '}
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="d-flex align-items-center">
            <Field name="status" component={renderSwitchAnt} />
            <label htmlFor="store_active" className="ml-3 col-form-label">
              Active store
            </label>
          </div>
        </div>
        <div>
          <GMButton className="saveBtn" size="lg">
            {currAction === 'create' ? 'Connect store' : 'Update store'}
          </GMButton>
        </div>
      </WrapperUpdateButton>
    </form>
  );
};

index = reduxForm({
  form: 'formShopifyStore',
  enableReinitialize: true,
  validate,
})(index);
export default connect()(index);
