import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Divider, Table } from 'antd';
import SelectProductsColor from './Search/SelectProductsColor';
import SelectProductsSize from './Search/SelectProductsSize';
import SelectProduct from './Search/SelectProduct';

export default class renderOderItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      flips: {},
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { fields, data, changeField, exproducts } = this.props;
    const { selectedRowKeys } = this.state;
    const columns = [
      {
        title: 'PRODUCT NAME',
        dataIndex: 'brand',
        align: 'center',
        width: 350,
        key: 'brand',
        render: (text, record, i) => {
          return (
            <div>
              <Field
                name={`${fields.name}[${i}].productId`}
                component={SelectProduct}
                data={exproducts}
                inputProps={{ className: 'mb-4' }}
                onChoose={(id, data) => {
                  const listSidePrint = [];
                  if (data?.flip !== null && typeof data?.flip === 'object') {
                    Object.keys(data?.flip).forEach((item) => {
                      listSidePrint.push({
                        value: item,
                        label: data.flip[item],
                      });
                    });
                  }
                  if (data.whole) {
                    Object.keys(data.whole).forEach((item) => {
                      listSidePrint.push({
                        value: item,
                        label: data.whole[item],
                      });
                    });
                  }

                  if (data.pocket) {
                    Object.keys(data.pocket).forEach((item) => {
                      listSidePrint.push({
                        value: item,
                        label: data.pocket[item],
                      });
                    });
                  }
                  this.setState((state, props) => {
                    return {
                      // colors: { ...state.colors, [i]: data.optionColor.map(item=> ({...item, value: item.id, color: item.value, label: item.name}))},
                      flips: {
                        ...state.flips,
                        [i]: listSidePrint,
                      },
                    };
                  });
                  if (typeof changeField === 'function') {
                    changeField(`${fields.name}[${i}].colorId`, 0);
                    changeField(`${fields.name}[${i}].colorHex`, null);
                    changeField(`${fields.name}[${i}].sidePrint`, null);
                    changeField(`${fields.name}[${i}].designBack`, null);
                    changeField(`${fields.name}[${i}].designFront`, null);
                    changeField(`${fields.name}[${i}].designBackId`, null);
                    changeField(`${fields.name}[${i}].designFrontId`, null);
                    changeField(
                      `${fields.name}[${i}].basePrice`,
                      data.basePrice
                    );
                    changeField(
                      `${fields.name}[${i}].mockup`,
                      data.frontImg || ''
                    );
                  }
                }}
                placeholder="-- Select Product --"
              />
              <Field
                name={`${fields.name}[${i}].colorId`}
                component={SelectProductsColor}
                expId={fields.get(i).productId}
                inputProps={{ className: 'mb-4' }}
                placeholder="-- Select color --"
                onChoose={(id, data) => {
                  if (typeof changeField === 'function') {
                    changeField(
                      `${fields.name}[${i}].colorHex`,
                      `#${data.value2}`
                    );
                    changeField(`${fields.name}[${i}].sizeId`, 0);
                  }
                }}
              />
              <Field
                name={`${fields.name}[${i}].sizeId`}
                component={SelectProductsSize}
                expId={fields.get(i).productId}
                inputProps={{ className: 'mb-4' }}
                colorId={fields.get(i).colorId}
                placeholder="-- Select size --"
              />
            </div>
          );
        },
      },
    ];
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [],
    };
    return (
      <div>
        <Table
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          key={`productId`}
          scroll={{ x: 1000 }}
        />
        <Divider className="my-0" />
      </div>
    );
  }
}
