import React, { useState } from "react";
import { Modal, notification, Spin, Divider } from "antd";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { listTypeToExportCampaign } from "utils/options";
import { renderRadioAnt } from "utils/Form";
import renderCheckboxGroup from "./renderCheckboxGroup";
import FieldText from "utils/fields/FieldText";
import { exportCampaigns } from "apis/campaign";
import { isValidEmail } from "utils/helper";
import FieldCheckboxGroup from "utils/fields/FieldCheckboxGroup";
import { GMModal } from "components/Modal/styled.modal";
const validate = (values) => {
  const errors = {};
  if (values.email && !isValidEmail(values.email)) {
    errors.email = "Your email is invalid. Please check again.";
  }
  return errors;
};

const RenderExportCampaigns = ({
  listSelectedCampaigns = [],
  changeEmail,
  fileType,
  email,
  isExportCombine,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOnExportCampaigns = () => {
    setLoading(true);
    exportCampaigns({
      campaigns: listSelectedCampaigns,
      email,
      fileType,
      isExportCombine:
        Array.isArray(isExportCombine) && isExportCombine.includes(1) ? 1 : 0,
      isChangeEmail:
        Array.isArray(changeEmail) && changeEmail.includes(1) ? 1 : 0,
    })
      .then(({ code, data, clientMsg }) => {
        setLoading(false);
        if (code === "success") {
          notification.success({
            message: clientMsg,
            duration: 5,
          });
          if (data.downloadFileLink) {
            window.open(data.downloadFileLink);
          }
        } else {
          notification.success({
            message: "Export campaigns failed.",
            duration: 5,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <div>
      <span
        onClick={() => {
          if (
            !Array.isArray(listSelectedCampaigns) ||
            listSelectedCampaigns.length === 0
          ) {
            notification.warn({
              message: "Please choose campaigns to export.",
              duration: 5,
            });
            return;
          }
          setVisible(true);
        }}
      >
        Export products
      </span>
      <GMModal
        visible={visible}
        title={<b className="h5">Export Campaigns</b>}
        onCancel={() => setVisible(false)}
        width={1000}
        okText={"Export"}
        onOk={handleOnExportCampaigns}
        bodyStyle={{ padding: "10px 10px 0px 10px" }}
      >
        <Spin spinning={loading}>
          <div className={"p-2"}>
            <form>
              <div className="form-group mb-1">
                <label className="col-form-label font-weight-bold h4">
                  Export Type
                </label>
              </div>
              <div className="form-group mb-1">
                <Field
                  name="fileType"
                  component={renderRadioAnt}
                  classNameItem={"d-block mb-3"}
                  colSpan={24}
                  options={listTypeToExportCampaign}
                />
              </div>
              {parseInt(fileType) === 1 && (
                <div className="form-group">
                  <Field
                    name="isExportCombine"
                    component={FieldCheckboxGroup}
                    options={[
                      {
                        label: (
                          <b>
                            Wordpress: Products that are split type will be
                            converted to combine
                          </b>
                        ),
                        value: 0,
                      },
                    ]}
                    colSpan={24}
                  />
                </div>
              )}
              <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
              <div className="form-group mb-1">
                <Field
                  name="changeEmail"
                  component={renderCheckboxGroup}
                  classNameItem={"d-block mb-3"}
                  colSpan={24}
                  options={[
                    {
                      value: 1,
                      label: <b>Change email address to receive file export</b>,
                    },
                  ]}
                />
              </div>
              {Array.isArray(changeEmail) && changeEmail.includes(1) && (
                <div className="form-group">
                  <label className="col-form-label font-weight-bold">
                    Email to recieve export
                  </label>
                  <Field name="email" component={FieldText} />
                </div>
              )}
            </form>
          </div>
        </Spin>
      </GMModal>
    </div>
  );
};
const selector = formValueSelector("ExportCampaignsForm");
export default React.memo(connect((state) => ({
  changeEmail: selector(state, "changeEmail") || [0],
  fileType: selector(state, "fileType") || 0,
  email: selector(state, "email") || "",
  isExportCombine: selector(state, "isExportCombine") || [0],
  initialValues: {
    fileType: 0,
    email: "",
    changeEmail: [],
    isExportCombine: [1],
  },
}))(
  reduxForm({
    form: "ExportCampaignsForm",
    validate,
  })(RenderExportCampaigns)
));
