import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FieldCheckboxGroup from 'utils/fields/FieldCheckboxGroup';
import {
  change,
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
  arrayRemoveAll,
  arrayInsert,
} from 'redux-form';
import { options_bulk } from 'utils/options';
import FieldUploadDragger from 'utils/fields/FieldUploadDragger';
import Sidebar from './components/Sidebar';
import FieldText from 'utils/fields/FieldText';
import RenderListBulkImage from 'utils/Form/RenderListBulkImage';
import { getDetailBulk } from './actions';
import classNames from 'classnames';
import { Spin, Card, Button } from 'antd';
import { createBulk2 } from 'apis/campaign';
import BackToIcon from '../../../../images/chevron-down.svg';
import { uploading_message } from 'utils/options';
import { notification } from 'antd';
import { GMTextbox } from 'components/InputFields';
import { GMButton } from 'components/Button';
import { Wrapper } from './styled';
const validate = (values) => {
  let errors = {};
  if (!values.listImages2 || !values.listImages2.length) {
    errors.listImages2 = true;
  } else {
    const arrErrors = [];
    for (let index = 0; index < values.listImages2.length; index++) {
      if (values.listImages2[index] == null) continue;
      const currError = {};
      if (
        values.listImages2[index]['campaignTitle'] === undefined ||
        values.listImages2[index].campaignTitle.length === 0
      ) {
        currError.campaignTitle = 'Please enter product name';
      }
      if (
        values.listImages2[index].urlPath === undefined ||
        values.listImages2[index].urlPath.length === 0
      ) {
        currError.urlPath = 'Please enter url path of product';
      }
      arrErrors[index] = currError;
    }
    if (arrErrors.length) {
      errors.listImages2 = arrErrors;
    }
  }
  return errors;
};
class CreateBulkForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showListImage: true,
      loading: false,
    };
  }

  setShowListImage = (status) => {
    this.setState({ showListImage: status });
  };

  componentDidMount() {
    this.props.getDetailBulk(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.listImages) !==
      JSON.stringify(this.props.listImages)
    ) {
      const temp = this.props.listImages.filter((image) => image != null);
      this.props.changeFieldValue('listImages2', temp);
    }
  }

  rebulkCampaign = (item, index) => {
    this.props.changeFieldValue(`listImages2.${index}`, {
      ...item,
      loading: true,
      status: 0,
    });
    const dataToBulkCampaign = {
      prefix: this.props.bulk_options.includes(options_bulk[2].value)
        ? this.props.prefix
        : '',
      autoGenerate: this.props.bulk_options.includes(options_bulk[0].value)
        ? 'yes'
        : 'no',
      autoSuffix: this.props.bulk_options.includes(options_bulk[1].value)
        ? 'yes'
        : 'no',
      includePrefix: this.props.bulk_options.includes(options_bulk[2].value)
        ? 'yes'
        : 'no',
      isKeepCoordinatesRenderImage: 'yes',
      s3Link: [`${item.imageLink}?name=${item.campaignTitle}`],
    };
    createBulk2(dataToBulkCampaign, this.props.match.params.id).then(
      (response) => {
        if (response.code === 'success') {
          this.props.changeFieldValue(`listImages2.${index}`, {
            ...item,
            loading: false,
            status: 1,
            error: '',
          });
        } else {
          this.props.changeFieldValue(`listImages2.${index}`, {
            ...item,
            loading: false,
            status: 2,
            error:
              Array.isArray(response) && response[0] && response[0]['clientMsg']
                ? response[0]['clientMsg']
                : '',
          });
        }
      }
    );
  };

  handleOnSubmit = () => {
    if ((this.props.listImages2 || []).length === 0) {
      notification.warn({
        message: 'Please upload images to bulk product',
        duration: 5,
      });
    }
    for (let index = 0; index < this.props.listImages2.length; index++) {
      this.rebulkCampaign(this.props.listImages2[index], index);
    }
  };

  render() {
    return (
      <Wrapper pageName={this.props.pageName}>
        <Spin
          spinning={this.props.detailBulk.loading || this.state.loading}
          tip={uploading_message}
        >
          <div className="custom-background">
            <h4>Upload design files</h4>
            <div className="row">
              <div
                className={classNames(
                  {
                    'col-md-12':
                      this.props.listImages.length > 0 &&
                      this.state.showListImage,
                  },
                  {
                    'col-md-8':
                      this.props.listImages.length === 0 ||
                      !this.state.showListImage,
                  }
                )}
              >
                {this.props.listImages.length === 0 &&
                  !this.state.showListImage && (
                    <div>
                      <p className="mb-2">
                        Every file you upload will be added as its own duplicate
                        product. The file name will become the product title and
                        URL path.
                      </p>
                      <p style={{ color: 'red' }} className="mt-0 mb-2">
                        The maximum number of files upload: 200 files. The
                        maximum file size you can upload and convert is limited:
                        30 MB
                      </p>
                    </div>
                  )}
                {this.props.listImages.length > 0 && this.state.showListImage && (
                  <div>
                    <p className="mb-2">
                      Every file you upload will be added as its own duplicate
                      product. The file name will become the product title and
                      URL path.
                    </p>
                    <div className="d-flex justify-content-between">
                      <p className="font-weight-bold">
                        <span className="text-dark">
                          Pending files:{' '}
                          {
                            this.props.listImages2.filter(
                              (item) =>
                                item == null ||
                                (item && parseInt(item.status) === 0)
                            ).length
                          }
                        </span>{' '}
                        -{' '}
                        <span className="text-danger">
                          Failed files:{' '}
                          {
                            this.props.listImages2.filter(
                              (item) => item && parseInt(item.status) === 2
                            ).length
                          }
                        </span>{' '}
                        -{' '}
                        <span className="text-success">
                          Success files:{' '}
                          {
                            this.props.listImages2.filter(
                              (item) => item && parseInt(item.status) === 1
                            ).length
                          }
                        </span>{' '}
                        -{' '}
                        <span className="text-primary">
                          Total files:{' '}
                          {
                            this.props.listImages2.filter(
                              (item) => item !== null
                            ).length
                          }
                        </span>
                      </p>
                      <p
                        className="text-primary cursor-pointer font-weight-bold text-danger font-size-16"
                        onClick={() => {
                          this.setState({ showListImage: false });
                          this.props.removeAllItem('listImages');
                        }}
                      >
                        Back to upload
                      </p>
                    </div>
                  </div>
                )}
                <form>
                  <div
                    className={classNames({
                      'd-none':
                        this.props.listImages.length > 0 &&
                        this.state.showListImage,
                    })}
                  >
                    <div>
                      <p className="mb-2">
                        Every file you upload will be added as its own duplicate
                        product. The file name will become the product title and
                        URL path.
                      </p>
                      <p style={{ color: 'red' }} className="mt-0 mb-2">
                        The maximum number of files upload: 200 files. The
                        maximum file size you can upload and convert is limited:
                        200 MB
                      </p>
                    </div>
                    <div className="mb-2">
                      <Field
                        name="bulk_options"
                        component={FieldCheckboxGroup}
                        options={options_bulk}
                        colSpan={24}
                      />
                    </div>
                    {this.props.bulk_options &&
                      this.props.bulk_options.includes(
                        options_bulk[2]['value']
                      ) && (
                        <div className="mb-3">
                          <h5>Prefix</h5>
                          <GMTextbox name="prefix" placeholder="Prefix" />
                        </div>
                      )}
                  </div>
                  <div className="mb-3">
                    <FieldArray
                      name="listImages"
                      loading={this.state.loading}
                      setLoading={(value) => {
                        this.setState(value);
                      }}
                      listImages={this.props.listImages}
                      component={FieldUploadDragger}
                      campaignId={this.props.match.params.id}
                      showUploadList={false}
                      cusId={this.props.profile.id}
                      multiple={true}
                      changeFieldValue={this.props.changeFieldValue}
                      maximumFile={200}
                      typeUpload={'upload-artwork-to-bulk'}
                      setShowListImage={this.setShowListImage}
                      showListImage={this.state.showListImage}
                      insertItem={this.props.insertItem}
                      data={{
                        prefix: this.props.bulk_options.includes(
                          options_bulk[2].value
                        )
                          ? this.props.prefix
                          : '',
                        autoGenerate: this.props.bulk_options.includes(
                          options_bulk[0].value
                        )
                          ? 'yes'
                          : 'no',
                        autoSuffix: this.props.bulk_options.includes(
                          options_bulk[1].value
                        )
                          ? 'yes'
                          : 'no',
                        includePrefix: this.props.bulk_options.includes(
                          options_bulk[2].value
                        )
                          ? 'yes'
                          : 'no',
                      }}
                    />
                  </div>

                  {this.state.showListImage &&
                    this.props.listImages.length > 0 && (
                      <FieldArray
                        name={'listImages2'}
                        component={RenderListBulkImage}
                        rebulkCampaign={this.rebulkCampaign}
                        queuedData={this.props.listImages.filter(
                          (item) =>
                            item == null ||
                            (item && parseInt(item.status) === 0)
                        )}
                        data={this.props.listImages2}
                      />
                    )}
                  {this.state.showListImage &&
                    this.props.listImages.length > 0 && (
                      <div className="row mt-3">
                        <div className="col-md-8 mb-3 mb-sm-0">
                          <p className="font-italic font-size-12 text-center">
                            By creating a product, you agree to our Terms and
                            certify that no content in this product infringes
                            any third-party intellectual property rights.
                          </p>
                          <GMButton
                            type="button"
                            className="create-discount-button w-100"
                            size="lg"
                            onClick={() => {
                              this.handleOnSubmit();
                            }}
                          >
                            Bulk create products
                          </GMButton>
                        </div>
                        <div className="col-md-4">
                          <div className="seller-help">
                            <h2 className="helpTitle">Help</h2>
                            <div className="seller-help-box">
                              <h3 className="mTitle">
                                Product title and URL path
                              </h3>
                              <p>
                                You may edit the product title and URL paths for
                                each product. Make title concise and engaging to
                                increase sales.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </form>
                {(!this.state.showListImage ||
                  this.props.listImages.length === 0) && (
                    <div className="container-fluid">
                      <div className="row">
                        <div
                          className="col-12 col-sm-2 p-2"
                          style={{ border: '1px solid #ccc' }}
                        >
                          <img
                            className="img-fluid"
                            src={this.props.detailBulk.data.imageLink || ''}
                            alt=""
                          />
                        </div>
                        <div className="text-left col-12 col-sm-4">
                          <h6 className="mb-1">Product to duplicate</h6>
                          <p className="mb-1">
                            {this.props.detailBulk.data.camName || ''}
                          </p>
                          <Link
                            to={`/products/${this.props.detailBulk.data.camId || ''
                              }/edit`}
                          >
                            View detail
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div
                className={classNames(
                  {
                    'd-none':
                      this.state.showListImage &&
                      this.props.listImages.length > 0,
                  },
                  {
                    'col-md-4':
                      !this.state.showListImage ||
                      this.props.listImages.length === 0,
                  }
                )}
              >
                <Sidebar />
              </div>
            </div>
          </div>
        </Spin>
      </Wrapper>
    );
  }
}
CreateBulkForm.propTypes = {};
const selector = formValueSelector('FormCreateBulk');
const mapStateToProps = (state) => ({
  formCreateBulkData: state.form.FormCreateBulk?.values,
  bulk_options: selector(state, 'bulk_options') || [],
  listImages: selector(state, 'listImages') || [],
  listImages2: selector(state, 'listImages2') || [],
  detailBulk: state.campaign.bulk.detail,
  prefix: selector(state, 'prefix') || '',
  profile: state.auth.info.profile,
  initialValues: {
    bulk_options: options_bulk.map((item) => item.value),
    prefix: '',
    listImages: [],
    listImages2: [],
  },
});
const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: function (field, value) {
    dispatch(change('FormCreateBulk', field, value));
  },
  getDetailBulk: (id) => {
    dispatch(getDetailBulk(id));
  },
  removeAllItem: (field) => {
    dispatch(arrayRemoveAll('FormCreateBulk2', field));
  },
  insertItem: (field, value, index) => {
    dispatch(arrayInsert('FormCreateBulk2', field, index, value));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'FormCreateBulk',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
  })(CreateBulkForm)
);
