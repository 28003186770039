import { notification } from "antd";
import { actionType as TYPE } from "./actions";
import parse from "html-react-parser";

const initialState = {
  listShipping: {
    data: {},
    loading: false,
  },
  listHistoryShipping: {
    data: {},
    loading: false,
  },
  updateShipping: {
    loading: false,
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    //Update Shipping
    case TYPE.UPDATE_SHIPPING.REQUEST:
      return {
        ...state,
        updateShipping: {
          ...state.updateShipping,
          loading: true,
        },
      };
    case TYPE.UPDATE_SHIPPING.SUCCESS:
      notification.success({
        message:
          action.response && action.response.clientMsg
            ? parse("<div>" + action.response.clientMsg + "</div>")
            : "Update default shipping preferences successfully.",
        duration: 5,
      });
      return {
        ...state,
        updateShipping: {
          ...state.updateShipping,
          loading: false,
        },
      };
    case TYPE.UPDATE_SHIPPING.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Update default shipping preferences failed.",
        duration: 5,
      });
      return {
        ...state,
        updateShipping: {
          ...state.updateShipping,
          loading: false,
        },
      };
    //List shipping
    case TYPE.LIST_SHIPPING.REQUEST:
      return {
        ...state,
        listShipping: {
          ...state.listShipping,
          loading: true,
        },
      };
    case TYPE.LIST_SHIPPING.SUCCESS:
      return {
        ...state,
        listShipping: {
          ...state.listShipping,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.LIST_SHIPPING.ERROR:
      return {
        ...state,
        listShipping: {
          ...state.listShipping,
          loading: false,
        },
      };
    //List history shipping
    case TYPE.HISTORY_SHIPPING.REQUEST:
      return {
        ...state,
        listHistoryShipping: {
          ...state.listHistoryShipping,
          loading: true,
        },
      };
    case TYPE.HISTORY_SHIPPING.SUCCESS:
      return {
        ...state,
        listHistoryShipping: {
          ...state.listHistoryShipping,
          loading: false,
          data: action.data,
        },
      };
    case TYPE.HISTORY_SHIPPING.ERROR:
      return {
        ...state,
        listHistoryShipping: {
          ...state.listHistoryShipping,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
