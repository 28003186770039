import React from 'react';
import RadioStyle from './styled';
import { Radio, Tooltip } from 'antd';
import classNames from 'classnames';
const renderSelectStore = ({
  input,
  options,
  meta: { asyncValidating, touched, error },
  size,
  vertical,
  classNameItem = '',
}) => {
  return (
    <RadioStyle>
      <Radio.Group
        size={size || 'default'}
        onChange={(e) => input.onChange(e.target.value)}
        value={input.value}
      >
        {options.map((item) => (
          <Radio
            className={classNames('payment-method', {
              checked: item.value === input.value,
            })}
            key={item.value}
            value={item.value}
            disabled={item.disabled}
          >
              {item.label}
              {item.note && (
                <p className="my-0">
                  <i>{item.note}</i>
                </p>
              )}
          </Radio>
        ))}
      </Radio.Group>
      {error && <span className="invalid-feedback d-block">{error}</span>}
    </RadioStyle>
  );
};

export default renderSelectStore;
