import React, { Component } from "react";
export default class index extends Component {
  render() {
    return (
      <div className="w-100 d-flex flex-column justify-content-center align-items-center font-size-16">
        {this.props.children}
      </div>
    );
  }
}
