import { 
    MOCKUP_DELETE_REQUEST
 } from './constants'

 export function destroy(id, from = 'list'){
    return {
        type: MOCKUP_DELETE_REQUEST,
        id,
        from
    }
}