import React from 'react';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import { Result } from 'antd';
import { Link } from 'react-router-dom';

const Forbidden = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you do not have permission to access this page. Please contact the person who invited you for more information."
      extra={
        <Link to="/dashboard">
          <GMButton size="xl">Back Home</GMButton>
        </Link>
      }
    />
    // <Wrapper>
    //   <div className="error">403 error</div>
    //   <div className="header">Access Denied</div>
    //   <div className="desc">
    //     Sorry, you do not have permission to access this page. Please contact
    //     the person who invited you for more information.
    //   </div>
    //   <div>
    //     <GMButton color={'SecondaryGray'} size="xl" className="mr-3">
    //       Go back
    //     </GMButton>
    //     <GMButton size="xl">Return to dashboard</GMButton>
    //   </div>
    // </Wrapper>
  );
};

export default Forbidden;
