import {
  ERROR_25,
  ERROR_300,
  BASE_WHITE,
  WARNING_25,
  WARNING_300,
  GRAY_300,
  GRAY_700,
  SUCCESS_300,
  SUCCESS_700,
  SUCCESS_50,
} from 'components/colors';

const TYPES = {
  infor: `
  background: ${WARNING_25};  
  border: 1px solid ${WARNING_300};
  border-radius: 12px;
  max-width:100%;
  &.noColor {
    background-color: transparent;
  }
  .notify{
    color: #B42318;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
    `,
  warning: `
  background: ${ERROR_25};  
  border: 1px solid ${ERROR_300};
  border-radius: 12px;
  max-width:100%;
  &.noColor {
    background-color: transparent;
  }
  .notify{
    color: #B42318;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
    `,
  desc: `
    background: ${BASE_WHITE};  
    border: 1px solid ${GRAY_300};
    border-radius: 12px;
    max-width:100%;
    &.noColor {
      background-color: transparent;
    }
    .notify{
      color: ${GRAY_700};
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
      .desc {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
      `,
  success: `
      background: ${BASE_WHITE};  
      border: 1px solid ${SUCCESS_300};
      border-radius: 12px;
      max-width:100%;
      background-color: ${SUCCESS_50};
      .notify{
        color: ${SUCCESS_700};
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        .desc {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
        `,
};
TYPES.default = TYPES.infor;

export default TYPES;
