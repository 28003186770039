// eslint-disable-next-line no-unused-vars
import { notification } from 'antd';
import { actionType as TYPE } from './actions';

const initialState = {
  sync_trackings: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  import_progress: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  log_export_order: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  schedule_tasks: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  sync_products: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  export_product: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  logs_webhook: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
  logs_order: {
    data: [],
    loading: false,
    params: {},
    total: 0,
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        sync_trackings: {
          ...initialState.sync_trackings,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        sync_trackings: {
          ...state.sync_trackings,
          loading: false,
          data: action.data.scheduleTrackings,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        sync_trackings: {
          ...state.sync_trackings,
          loading: false,
        },
      };
    //Import Orders
    case TYPE.LIST_SYNC_IMPORT_ORDER.REQUEST:
      return {
        ...state,
        import_progress: {
          ...initialState.import_progress,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_SYNC_IMPORT_ORDER.SUCCESS:
      return {
        ...state,
        import_progress: {
          ...state.import_progress,
          loading: false,
          data: action.data.importBackgrounds,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST_SYNC_IMPORT_ORDER.ERROR:
      return {
        ...state,
        import_progress: {
          ...state.import_progress,
          loading: false,
        },
      };

    //Sync Order Logs
    case TYPE.LIST_SYNC_ORDER.REQUEST:
      return {
        ...state,
        schedule_tasks: {
          ...initialState.schedule_tasks,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_SYNC_ORDER.SUCCESS:
      return {
        ...state,
        schedule_tasks: {
          ...state.schedule_tasks,
          loading: false,
          data:
            action.data && action.data.scheduleTasks
              ? action.data.scheduleTasks
              : [],
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST_SYNC_ORDER.ERROR:
      return {
        ...state,
        schedule_tasks: {
          ...state.schedule_tasks,
          loading: false,
        },
      };
    //Export Order Logs
    case TYPE.LIST_LOGS_EXPORT_ORDER.REQUEST:
      return {
        ...state,
        log_export_order: {
          ...initialState.log_export_order,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_LOGS_EXPORT_ORDER.SUCCESS:
      return {
        ...state,
        log_export_order: {
          ...state.log_export_order,
          loading: false,
          data: action?.payload,
          total: action?.payload?.pagination?.total || 0,
        },
      };
    case TYPE.LIST_LOGS_EXPORT_ORDER.ERROR:
      return {
        ...state,
        log_export_order: {
          ...state.log_export_order,
          loading: false,
        },
      };

    //Webhook Logs
    case TYPE.LIST_LOGS_WEBHOOK.REQUEST:
      return {
        ...state,
        logs_webhook: {
          ...initialState.logs_webhook,
          loading: true,
        },
      };
    case TYPE.LIST_LOGS_WEBHOOK.SUCCESS:
      return {
        ...state,
        logs_webhook: {
          ...state.logs_webhook,
          loading: false,
          data: action?.payload?.logWebhooks,
          total: action?.logWebhooks?.pagination?.total || 0,
        },
      };
    case TYPE.LIST_LOGS_WEBHOOK.ERROR:
      return {
        ...state,
        logs_webhook: {
          ...state.logs_webhook,
          loading: false,
        },
      };
    //Order Logs
    case TYPE.LIST_LOGS_ORDER.REQUEST:
      return {
        ...state,
        logs_order: {
          ...initialState.logs_order,
          loading: true,
        },
      };
    case TYPE.LIST_LOGS_ORDER.SUCCESS:
      return {
        ...state,
        logs_order: {
          ...state.logs_order,
          loading: false,
          data: action?.payload?.logs,
          total: action?.logs?.pagination?.total || 0,
        },
      };
    case TYPE.LIST_LOGS_ORDER.ERROR:
      return {
        ...state,
        logs_order: {
          ...state.logs_order,
          loading: false,
        },
      };
    //Sync product
    case TYPE.LIST_LOG_SYNC_PRODUCT.REQUEST:
      return {
        ...state,
        sync_products: {
          ...initialState.sync_products,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST_LOG_SYNC_PRODUCT.SUCCESS:
      return {
        ...state,
        sync_products: {
          ...state.sync_products,
          loading: false,
          data: action.data.scheduleCampaigns,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST_LOG_SYNC_PRODUCT.ERROR:
      return {
        ...state,
        sync_products: {
          ...state.sync_products,
          loading: false,
        },
      };
    //Feed product
    case TYPE.LOG_FEED_PRODUCT.REQUEST:
      return {
        ...state,
        export_product: {
          ...initialState.export_product,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LOG_FEED_PRODUCT.SUCCESS:
      return {
        ...state,
        export_product: {
          ...state.export_product,
          loading: false,
          data: action.data.exportBackground,
          total: action.data.pagination.total,
        },
      };
    case TYPE.LOG_FEED_PRODUCT.ERROR:
      return {
        ...state,
        export_product: {
          ...state.export_product,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
