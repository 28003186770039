import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tag } from 'antd'
import React from 'react'
import classNames from 'classnames'

export const RenderMultipleProductColors = ({ input, meta, options , onChoose}) => {
    const handleOnClick = (color) => {
        const selectedColors = input.value || [];
        let temp = [];
        if((selectedColors).includes(`${color.id}`)){
            input.onChange(selectedColors.filter(item => parseInt(item) !== parseInt(color.id)));
            temp =[...selectedColors.filter(item => parseInt(item) !== parseInt(color.id))]
        }else{
            input.onChange([...selectedColors, `${color.id}`]);
            temp = [...[...selectedColors, `${color.id}`]]
        }
        if(onChoose){
            onChoose(temp);
        }
    }
    return (
        <div>
            {options.map(color=>(
                <Tag onClick={() => {handleOnClick(color)}} className={classNames("mb-2 cursor-pointer", {"border border-dark": parseInt(color.id) === 12})} color={`#${color.value}`} 
                key={color.id}><span className={classNames({'invisible': !(input.value || []).includes(`${color.id}`)})}><FontAwesomeIcon icon={faCheck} style={{color : parseInt(color.id) === 12 ? '#333' : '#fff'}}/></span></Tag>
            ))}
            {meta?.error && <span className="invalid-feedback d-block">{meta?.error}</span>}
        </div>
    )
}
