import React, { useState } from "react";
import { Modal } from "antd";
import {
  getOptionLabel,
  options_transaction_type,
  options_payment_method,
  options_payment_status,
} from "utils/options";
import money from "utils/money";
import getMomentInstance from "utils/moment";
function RenderQuickViewTransaction({ transaction = {} }) {
  const [visible, setVisible] = useState(false);
  const onShowDetailModal = () => {
    setVisible(true);
  };
  return (
    <>
      <div>
        <span
          className="btn btn-link"
          onClick={() => onShowDetailModal()}
        >
          <u>Quick View</u>
        </span>
      </div>
      <Modal
        width={600}
        footer={null}
        title={
          <div className="d-flex align-items-center">
            <b className="h4 mb-0">Transaction detail #{transaction?.name}</b>
          </div>
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: 0 }}
      >
        <div className="pb-1">
          <table className="table table-borderless table-sm">
            <tbody>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Transaction Code:
                </td>
                <td className="px-4">{transaction?.name}</td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Status:
                </td>
                <td className="px-4">
                  {getOptionLabel(
                    options_payment_status,
                    transaction?.status,
                    false
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Type:
                </td>
                <td className="px-4">
                  {getOptionLabel(
                    options_transaction_type,
                    transaction?.type,
                    false
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Payment method:
                </td>
                <td className="px-4">
                  {getOptionLabel(
                    options_payment_method,
                    transaction?.paymentMethod,
                    false,
                    transaction?.paymentMethod
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Amounnt
                </td>
                <td className="px-4">{money(transaction?.amount)}</td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Balance after
                </td>
                <td className="px-4">{money(transaction?.balanceAfter)}</td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Balance before
                </td>
                <td className="px-4">{money(transaction?.balanceBefore)}</td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Description{" "}
                </td>
                <td className="px-4" dangerouslySetInnerHTML={{
                  __html: (transaction?.content || "").replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}></td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  Date
                </td>
                <td className="px-4">
                  {getMomentInstance(transaction?.date, 0, true)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};
export default React.memo(RenderQuickViewTransaction);
