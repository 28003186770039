import { takeLatest, call, put, all, select } from "redux-saga/effects";

import { actionType as TYPE } from "./actions";
import {
  getList,
  deleteIssue,
  getStatistic,
  detailIssue,
} from "apis/order-issues";

function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getList, params);
    if (response.code === "success") {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}

function* getStatisticSaga(action) {
  try {
    const { params } = action;
    const response = yield call(getStatistic, params);
    if (response.code === "success") {
      yield put({ type: TYPE.STATISTIC.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.STATISTIC.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.COUNT_ORDER.ERROR, error })]);
  }
}

function* deleteIssueSaga(action) {
  const { params } = action;
  try {
    const response = yield call(deleteIssue, params);
    const { order } = yield select();
    if (response.code === "success") {
      yield all([
        put({ type: TYPE.LIST.REQUEST, params: order.issue.issues.params }),
        put({ type: TYPE.DELETE_ISSUE.SUCCESS, ...response }),
      ]);
    } else {
      yield put({ type: TYPE.DELETE_ISSUE.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.DELETE_ISSUE.ERROR, error })]);
  }
}
//Detail issue

function* detailIssueSaga(action) {
  try {
    const { id } = action;
    const response = yield call(detailIssue, id);

    if (response.code === "success") {
      yield all([put({ type: TYPE.DETAIL_ISSUE.SUCCESS, response })]);
    } else {
      yield put({ type: TYPE.DETAIL_ISSUE.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.DETAIL_ISSUE.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga);
  yield takeLatest(TYPE.DELETE_ISSUE.REQUEST, deleteIssueSaga);
  yield takeLatest(TYPE.STATISTIC.REQUEST, getStatisticSaga);
  yield takeLatest(TYPE.DETAIL_ISSUE.REQUEST, detailIssueSaga);
}

export default watcher;
