import {
  BASE_WHITE,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_500,
} from 'components/colors';
import PageContainer from 'components/PageContainer';
import styled from 'styled-components';

export const Wrapper = styled.div``;
