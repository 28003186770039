import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';
import {
  getList,
  create,
  update,
  getDetail,
  getListReport,
  exportOrders,
  detailReport,
  exportAccountReportDetail,
  listReportOrdersDashboard,
  exportAccountReport,
  deleteStaffAccount,
  getListInvitationStaff,
  deleteInvitationAccount,
  resendInvitationAccount,
} from 'apis/accounts';
import { replace } from 'connected-react-router';

function* getListSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getList, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_ACCOUNT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_ACCOUNT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_ACCOUNT.ERROR, error })]);
  }
}

//GEt list invitaion staff
function* getListInvitationStaffSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListInvitationStaff, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_INVITATION_ACCOUNT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_INVITATION_ACCOUNT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_INVITATION_ACCOUNT.ERROR, error })]);
  }
}
//List report order
function* getListReportDashboardSaga(action) {
  const { params } = action;
  try {
    const response = yield call(listReportOrdersDashboard, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.REPORT_DASHBOARD.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.REPORT_DASHBOARD.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.REPORT_DASHBOARD.ERROR, error })]);
  }
}
//
function* getListReportSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListReport, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_REPORT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_REPORT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_REPORT.ERROR, error })]);
  }
}

function* exportOrdersSaga(action) {
  const { id, params } = action;
  try {
    const response = yield call(exportOrders, id, params);
    if (response.code === 'success') {
      yield put({ type: TYPE.EXPORT_ORDER.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.EXPORT_ORDER.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.EXPORT_ORDER.ERROR, error })]);
  }
}

function* detailReportSaga(action) {
  const { id, params } = action;
  try {
    const response = yield call(detailReport, id, params);
    if (response.code === 'success') {
      yield put({ type: TYPE.DETAIL_REPORT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.DETAIL_REPORT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.DETAIL_REPORT.ERROR, error })]);
  }
}

function* detailAccountSaga(action) {
  const { id } = action;
  try {
    const response = yield call(getDetail, id);
    if (response.code === 'success') {
      yield put({ type: TYPE.DETAIL_ACCOUNT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.DETAIL_ACCOUNT.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.DETAIL_ACCOUNT.ERROR, error })]);
  }
}

function* updateAccountSaga(action) {
  const { id, dataToUpdate } = action;
  try {
    const response = yield call(update, id, dataToUpdate);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.UPDATE_ACCOUNT.SUCCESS, ...response }),
        put(replace('/settings/staff-accounts')),
      ]);
    } else {
      yield put({ type: TYPE.UPDATE_ACCOUNT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.UPDATE_ACCOUNT.ERROR, error })]);
  }
}
//Delete staff account or remove invite
function* removeAccountSaga(action) {
  const { id, cb } = action;
  try {
    const response = yield call(deleteStaffAccount, id);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.DELETE_ACCOUNT.SUCCESS, ...response }),
        put({
          type: TYPE.LIST_ACCOUNT.REQUEST,
          params: { page: 1, perPage:10 },
        }),
      ]);
      if (typeof cb === 'function') {
        cb();
      }
    } else {
      yield put({ type: TYPE.DELETE_ACCOUNT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.DELETE_ACCOUNT.ERROR, error })]);
  }
}
//Delete staff account or remove invite
function* removeInvitationAccountSaga(action) {
  const { id, cb } = action;
  try {
    const response = yield call(deleteInvitationAccount, id);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.DELETE_INVITATION_ACCOUNT.SUCCESS, ...response }),
        put({
          type: TYPE.LIST_INVITATION_ACCOUNT.REQUEST,
          params: { page: 1, perPage: 10 },
        }),
      ]);
      if (typeof cb === 'function') {
        cb();
      }
    } else {
      yield put({
        type: TYPE.DELETE_INVITATION_ACCOUNT.ERROR,
        error: response,
      });
    }
  } catch (error) {
    yield all([
      yield put({ type: TYPE.DELETE_INVITATION_ACCOUNT.ERROR, error }),
    ]);
  }
}

function* createAccountSaga(action) {
  const { dataToCreate } = action;
  try {
    const response = yield call(create, dataToCreate);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CREATE_ACCOUNT.SUCCESS, ...response }),
        put(replace('/settings/staff-accounts')),
      ]);
    } else {
      yield put({ type: TYPE.CREATE_ACCOUNT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.CREATE_ACCOUNT.ERROR, error })]);
  }
}
//Resend invitation
function* resendInvitationAccountSaga(action) {
  const { data } = action;
  try {
    const response = yield call(resendInvitationAccount, data);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.RESEND_INVITAION.SUCCESS, ...response }),
        put({
          type: TYPE.LIST_INVITATION_ACCOUNT.REQUEST,
          params: { page: 1, perPage: 10 },
        }),
      ]);
    } else {
      yield put({ type: TYPE.RESEND_INVITAION.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.RESEND_INVITAION.ERROR, error })]);
  }
}

function* exportDetailReportSaga(action) {
  const { id, params } = action;
  try {
    const response = yield call(exportAccountReportDetail, id, params);
    if (response.code === 'success') {
      yield put({
        type: TYPE.EXPORT_DETAIL_REPORT_ACCOUNT.SUCCESS,
        ...response,
      });
    } else {
      yield put({
        type: TYPE.EXPORT_DETAIL_REPORT_ACCOUNT.ERROR,
        error: response,
      });
    }
  } catch (error) {
    yield all([
      yield put({ type: TYPE.EXPORT_DETAIL_REPORT_ACCOUNT.ERROR, error }),
    ]);
  }
}
function* exportReportSaga(action) {
  const { params } = action;
  try {
    const response = yield call(exportAccountReport, params);
    if (response.code === 'success') {
      yield put({ type: TYPE.EXPORT_REPORT_ACCOUNT.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.EXPORT_REPORT_ACCOUNT.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.EXPORT_REPORT_ACCOUNT.ERROR, error })]);
  }
}
function* watcher() {
  yield takeLatest(TYPE.LIST_ACCOUNT.REQUEST, getListSaga);
  yield takeLatest(TYPE.UPDATE_ACCOUNT.REQUEST, updateAccountSaga);
  yield takeLatest(TYPE.CREATE_ACCOUNT.REQUEST, createAccountSaga);
  yield takeLatest(TYPE.DETAIL_ACCOUNT.REQUEST, detailAccountSaga);
  yield takeLatest(TYPE.LIST_REPORT.REQUEST, getListReportSaga);
  yield takeLatest(TYPE.EXPORT_ORDER.REQUEST, exportOrdersSaga);
  yield takeLatest(TYPE.DETAIL_REPORT.REQUEST, detailReportSaga);
  yield takeLatest(TYPE.DELETE_ACCOUNT.REQUEST, removeAccountSaga);
  yield takeLatest(TYPE.REPORT_DASHBOARD.REQUEST, getListReportDashboardSaga);
  yield takeLatest(TYPE.EXPORT_REPORT_ACCOUNT.REQUEST, exportReportSaga);
  yield takeLatest(
    TYPE.EXPORT_DETAIL_REPORT_ACCOUNT.REQUEST,
    exportDetailReportSaga
  );
  yield takeLatest(
    TYPE.LIST_INVITATION_ACCOUNT.REQUEST,
    getListInvitationStaffSaga
  );
  yield takeLatest(
    TYPE.DELETE_INVITATION_ACCOUNT.REQUEST,
    removeInvitationAccountSaga
  );
  yield takeLatest(TYPE.RESEND_INVITAION.REQUEST, resendInvitationAccountSaga);
}

export default watcher;
