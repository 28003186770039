import React from 'react';

import { Link } from 'react-router-dom';
import { Dropdown, Tooltip } from 'antd';
import getMomentInstance from 'utils/moment';
import {
  getOptionLabel,
  options_active,
  options_datafeed_platform_type,
} from 'utils/options';
import { ReactComponent as EditIcon } from 'images/icons/edit-01.svg';
import { MoreActionStore } from 'scenes/Campaigns/scenes/List/styled';
import { ContextMenuWrapper } from 'scenes/BillingPaymentAccount/v2/styled';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import { ConfirmModalInnerWrapper } from 'components/Modal/ConfirmDialog/styled';
export default [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    key: 'id',
    render: (text, record) => (
      <Link
        to={`/products/data-feed/${record.id}/${
          parseInt(record.platformId) === 3 ? 'amazon' : 'google'
        }`}
        className="mr-1"
      >
        #{text}
      </Link>
    ),
  },
  {
    title: 'Form name',
    dataIndex: 'name',
    align: 'center',
    key: 'name',
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'Platform',
    dataIndex: 'platformId',
    align: 'center',
    key: 'platformId',
    render: (value) => {
      return (
        options_datafeed_platform_type?.find((o) => o.value + '' === value + '')
          ?.short_label || 'N/A'
      );
    },
  },
  {
    title: 'Form default',
    dataIndex: 'default',
    align: 'center',
    key: 'default',
    render: (value) => (value ? 'Yes' : 'No'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    key: 'status',
    render: (value) => getOptionLabel(options_active, value),
  },
  {
    title: 'Created time',
    dataIndex: 'createdAt',
    align: 'center',
    key: 'createdAt',
    render: (text, record) => getMomentInstance(text, 0, true),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (_, record) => (
      <div>
        <MoreActionStore>
          <Dropdown
            placement="topLeft"
            overlay={
              <ContextMenuWrapper className="p-3">
                <Link
                  to={`/products/data-feed/${record.id}/${
                    parseInt(record.platformId) === 3 ? 'amazon' : 'google'
                  }`}
                  className="cursor-pointer"
                >
                  Edit
                </Link>
              </ContextMenuWrapper>
            }
            trigger={['click']}
          >
            <MoreAction className="context-menu" />
          </Dropdown>
        </MoreActionStore>
      </div>
    ),
  },
];
