import React, { Component } from "react";
import { reduxForm, Field, FieldArray, change } from "redux-form";
import { connect } from "react-redux";
import validate from "./validate";
import { renderSelectAnt } from "utils/Form";
import { withRouter } from "react-router-dom";
import RenderListCampaignsToFeedFormAmazon from "utils/Form/RenderListCampaignsToFeedFormAmazon";
import { renderCheckboxGroup } from "utils/Form";
import { GMButton } from "components/Button";
class index extends Component {
  render() {
    const {
      listFeedForms,
      campaigns,
      handleSubmit,
      listCampaignsToExport,
      changeFieldValue,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="p-2">
          <Field name="campaigns" type="hidden" component="input" />
          <div className="form-group mb-3 col-md-4 px-0">
            <label className="col-form-label font-weight-bold">Feed form</label>
            <Field
              name="feedFormId"
              component={renderSelectAnt}
              options={[
                { value: "0", label: "-- Select Feed Form --" },
                ...listFeedForms.map((item) => ({
                  value: item.id,
                  label: `Feed Form #${item.id} ${item.name}`,
                })),
              ]}
            />
          </div>
          <div className="form-group mb-1">
            <Field
              name="isCombine"
              component={renderCheckboxGroup}
              classNameItem={"d-block mb-3"}
              colSpan={24}
              options={[
                {
                  value: 1,
                  label: (
                    <b className="text-danger">
                      Product link: Products that are split type will be
                      converted to combine.
                    </b>
                  ),
                },
              ]}
            />
          </div>
          <div className="form-group">
            <FieldArray
              name={"listCampaignsToExport"}
              component={RenderListCampaignsToFeedFormAmazon}
              campaigns={campaigns}
              changeFieldValue={changeFieldValue}
              listCampaignsToExport={listCampaignsToExport}
            />
          </div>
          <div className="ctas">
            <GMButton>
              Export Feed Form Google
            </GMButton>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({}),
    (dispatch) => ({
      changeFieldValue: function (field, value) {
        dispatch(change("FormFeedGoogle", field, value));
      },
    })
  )(
    reduxForm({
      form: "FormFeedGoogle",
      enableReinitialize: true,
      validate,
    })(index)
  )
);
