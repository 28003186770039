import styled from "styled-components";
import PageContainer from "components/PageContainer";
import { BASE_WHITE, GRAY_300, GRAY_400, GRAY_50, GRAY_700, GRAY_900, PRIMARY_100, PRIMARY_300, SHADOW_XS } from "components/colors";

export const Wrapper = styled(PageContainer)`
  .file-type-section .file-type-item {
    border-radius: 8px;
  }
  textarea,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-affix-wrapper {
    height: 44px;
    line-height: 44px;
    padding: 0 14px;
    outline: none;
    width: 100%;

    overflow: hidden;
    
    color: ${GRAY_900};
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.ant-input {
      border-radius: 8px!important;
    }
    &:placeholder  {
      color: ${GRAY_400};
    }
    &:focus-visible,
    &:focus {
      outline: none;
      border: 1px solid ${PRIMARY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    }
    &:disabled {
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      stroke: ${GRAY_700};
    }
  }
  button {
    display: flex;
    align-items: center;
    white-space: nowrap;
    svg {
      width: 20px;
      margin-right: 8px;
    }
  }
  .add-form {
    display: flex;
    align-items: center;
    gap: 12px;
    .position-relative {
      width: 100%;
    }
  }
  .ctas {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
  }
`;
