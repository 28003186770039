import React, { Component } from 'react';
import { useDispatch } from 'react-redux';
import { Field, submit } from 'redux-form';
import * as Scroll from 'react-scroll';
import { renderSelectAnt } from 'utils/Form';
import { option_platform_stores, option_status_stores } from 'utils/options';
import { Wrapper } from './styled';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
const scroll = Scroll.animateScroll;

class FormFilter extends Component {
  state = {
    advance_search: false,
  };
  onCloseAdvancedSearch = () => {
    this.setState({
      advance_search: false,
    });
  };
  onOpenAdvancedSearchose = () => {
    this.setState({
      advance_search: true,
    });
  };

  render() {
    const { dispatch } = this.props;
    const debounceSubmit = debounce((evt, value = '') => {
      dispatch(submit('paymentFormFilter'));
    }, 50);
    return (
      <Wrapper>
        <Field
          className="platform"
          name="backendType"
          component={renderSelectAnt}
          placeholder="Select platform"
          options={option_platform_stores}
          onChange={debounceSubmit}
        />
        <Field
          name="status"
          className="platform"
          component={renderSelectAnt}
          placeholder="Status"
          options={option_status_stores}
          onChange={debounceSubmit}

        />
      </Wrapper>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

export default connect(undefined, mapDispatchToProps)(FormFilter);
