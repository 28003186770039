import { PROMOTIONS_UPDATE_REQUEST, PROMOTIONS_DETAIL_REQUEST } from "./constants";

export function updatePromotions(id, dataToUpdate) {
  return {
    type: PROMOTIONS_UPDATE_REQUEST,
    id,
    dataToUpdate,
  };
}
export function getDetailPromotions(id) {
  return {
    type: PROMOTIONS_DETAIL_REQUEST,
    id,
  };
}
