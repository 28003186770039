import React, { Component } from 'react';
import { Drawer, Button } from 'antd';
import IframeBuilder from './IframeBuilder';


class HtmlEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false
        };     
    }  
    showBuilder = () => {
        this.setState({
            visible: true,
        });
    };
    
    closeBuilder = () => {
        this.setState({
            visible: false,
        });
    };  
    handleChange = (value) =>{
        this.setState({ visible: false });
        this.props.input.onChange(value);
    }
    render() {
        const {
            input,
            meta: { error },
            builderType,
        } = this.props
        return (
            <div>
                <Button type="primary" onClick={this.showBuilder}>
                    Content Builder
                </Button>
                {error && <p className="text-danger">{error}</p>}
                <Drawer
                    placement="right"
                    className="custom-filter draw-content-builder"
                    closable={false}
                    width="100%"
                    destroyOnClose={true}
                    visible={this.state.visible}
                >
                    <IframeBuilder builderType={builderType || 'page'} onSave={this.handleChange} onCancel={this.closeBuilder} value={input.value}/>
                </Drawer>
            </div>
        )
    }
}

export default HtmlEditor