import {
  PRIMARY_500,
  BASE_WHITE,
  ERROR_100,
  ERROR_600,
  GRAY_200,
  GRAY_50,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_600,
  SUCCESS_100,
  SUCCESS_600} from 'components/colors';
import PageContainer from 'components/PageContainer';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .ant-modal-content {
    background: ${BASE_WHITE};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }
  .ant-modal-header{
    border-radius: 16px;
    border-bottom:none;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

export const Wrapper = styled(PageContainer)`
  padding: 32px;
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 16px 8px;
  }
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 16px 8px;
  }

  svg.plusicon {
    width: 24px;
    padding-right: 10px;
  }
  .issue-table table {
    thead th {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      border: none;
      height: 44px;
      vertical-align: middle;
      background-color: ${GRAY_50};
      border-bottom: 1px solid ${GRAY_200};
    }
    tbody td {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      border-bottom: 1px solid ${GRAY_200};
      border-top: none;
      vertical-align: top;
      padding: 16px 8px;
      padding-left: 8px !important;
      padding-right: 8px !important;
      b {
        font-weight: 500;
      }
      .show-more-less-clickable {
        color: ${GRAY_500};
      }
      .icon-wrapper {
        width: 40px;
        height: 40px;
        background: ${PRIMARY_100};
        border-radius: 20px;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }
    .icon-wrapper {
      width: 40px;
      height: 40px;
      background: ${PRIMARY_100};
      border-radius: 20px;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .ant-tag,
    .tag {
      height: 22px;
      border: none;
      border-radius: 16px;
      line-height: 150%;
      padding: 2px 8px;
    }
    .shipping {
      display: flex;
      align-items: center;
      gap: 12px;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
      &.secondary span {
        background-color: ${PRIMARY_100};
        stroke: ${PRIMARY_600};
      }
      &.red span {
        background-color: ${ERROR_100};
        stroke: ${ERROR_600};
      }
      &.success span {
        background-color: ${SUCCESS_100};
        stroke: ${SUCCESS_600};
      }
      &.dark span {
        background-color: ${GRAY_700};
        stroke: ${BASE_WHITE};
      }
    }
    .price {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_900};
    }
  }
  h2.header {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: ${GRAY_900};
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .tabs {
    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      background-color: transparent;
      color: ${PRIMARY_500}!important;
      border-bottom: 4px solid ${PRIMARY_500};
    }
    .ant-tabs-tab .ant-tabs-tab-btn {
      font-weight: 500;
      &:hover {
        color: ${PRIMARY_500};
      }
    }
    .ant-tabs-tab {
      &,
      &-btn,
      & &-btn,
      &-active,
      &-active &-btn {
        /* padding: 0;
        margin-right: 0;
        margin-right: 20px; */
        align-items: unset;
      }
      &-btn,
      & &-btn {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: ${GRAY_500};
        align-items: unset;
      }
      &-btn,
      &-active &-btn,
      & &-btn {
        height: 36px;
        margin-bottom: -1px;
        padding: 0 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .tab-transaction {
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: ${GRAY_900};
        margin-top: 20px;
        span {
          display: block;
          float: left;
          line-height: 44px;
        }
        &:after {
          content: '';
          display: table;
          clear: both;
        }
      }
    }
    .ant-table-wrapper {
      margin-top: 32px;
    }
    .tab-transaction {
      padding: 0 8px;
    }
  }

  .more-action {
    svg {
      width: 20px;
      height: 20px;
      stroke: ${GRAY_600};
      margin-right: 10px;
    }
  }
  .connect-icon {
    svg {
      width: 20px;
      height: 20px;
      stroke: ${BASE_WHITE};
      margin-right: 10px;
    }
  }
`;

export const WrapperIcon = styled.div`
  svg {
    width: 20px;
    height: 20px;
    stroke: ${GRAY_600};
    margin-right: 10px;
  }
`;

export const MoreActionStore = styled.div`
  .pointer {
    cursor: pointer;
  }
`;
