import React from "react";
import List from "./scences/list";
import icon from "images/image.svg";
export default [
  {
    key: "image-library",
    name: "Design Library",
    component: List,
    path: "/image-library",
    group: "image_library",
    icon: <img src={icon} height={21} alt="Icon" />,
    template: "default",
    isPublic: true,
  },
];
