import styled from "styled-components";
import PageContainer from "components/PageContainer";
import { BASE_WHITE } from "components/colors";

export const Wrapper = styled(PageContainer)`
button.addnewbtn {
  display: flex;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    stroke: ${BASE_WHITE};
    margin-right: 12px;
  }
}
`;
