import { takeLatest, call, put, all } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { actionType as TYPE } from "./actions";

import { createCampaign, previewCampaign } from "apis/campaign";

function* previewCampaignSaga(action) {
  try {
    const { dataToPreviewCampaign } = action;
    const response = yield call(previewCampaign, dataToPreviewCampaign);

    if (response.code === "success") {
      yield all([
        put({ type: TYPE.PREVIEW.SUCCESS, ...response }),
        put(replace("/products/create/preview")),
      ]);
    } else {
      yield put({ type: TYPE.PREVIEW.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.PREVIEW.ERROR, error })]);
  }
}

function* createCampaignSaga(action) {
  try {
    const { dataToCreateCampaign } = action;
    const response = yield call(createCampaign, dataToCreateCampaign);

    if (response.code === "success") {
      yield all([
        put({ type: TYPE.CREATE.SUCCESS, ...response }),
        put(replace("/products/list")),
      ]);
    } else {
      yield put({ type: TYPE.CREATE.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.CREATE.ERROR, error })]);
  }
}

function* createCampaignWatcher() {
  yield all([
    takeLatest(TYPE.CREATE.REQUEST, createCampaignSaga),
    takeLatest(TYPE.PREVIEW.REQUEST, previewCampaignSaga),
  ]);
}

export default createCampaignWatcher;
