import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';
import { requestTopUp } from 'apis/top_up';
import { topUpPaypal } from 'apis/payment_menthod';
import { getListPaypal } from 'apis/payment_menthod';
import { replace } from 'connected-react-router';

function* requestTopUpSaga(action) {
  const { data } = action;
  try {
    const response = yield call(requestTopUp, data);
    if (response.code === 'success') {
      yield all([
        put({
          type: TYPE.REQUEST_TOPUP.SUCCESS,
          response: { ...response, check: data.check },
        }),
      ]);
    } else {
      yield put({ type: TYPE.REQUEST_TOPUP.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.REQUEST_TOPUP.ERROR, error })]);
  }
}

function* topUpPaypalSaga(action) {
  const { data } = action;
  try {
    const response = yield call(topUpPaypal, data);
    if (response.code === 'success') {
      yield all([
        put({
          type: TYPE.REQUEST_TOPUP_PAYPAL.SUCCESS,
          response: { ...response, check: data.check },
        }),
      ]);
    } else {
      yield put({ type: TYPE.REQUEST_TOPUP_PAYPAL.ERROR, error: response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.REQUEST_TOPUP_PAYPAL.ERROR, error })]);
  }
}


function* getListPaypalSaga(action) {
  const { params } = action;
  try {
    const response = yield call(getListPaypal, {
      ...params,
    });
    if (response.code === "success") {
      yield put({ type: TYPE.LIST_PAYPAL.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_PAYPAL.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_PAYPAL.ERROR, error })]);
  }
}
function* watcher() {
  yield takeLatest(TYPE.REQUEST_TOPUP.REQUEST, requestTopUpSaga);
  yield takeLatest(TYPE.REQUEST_TOPUP_PAYPAL.REQUEST, topUpPaypalSaga);
  yield takeLatest(TYPE.LIST_PAYPAL.REQUEST, getListPaypalSaga);

}

export default watcher;
