import React from 'react';
import GMNotify from './styled';
import { ReactComponent as Icon } from 'images/icons/alert-circle.svg';
import classNames from 'classnames';
import { ReactComponent as CheckIcon } from 'images/icons/check-circle.svg';

export default ({ className, type, title, desc, noColor = false, customIcon = null }) => {
  return (
    <div className={className}>
      <GMNotify type={type} className={classNames('notify-inner-wrapper', { noColor })}>
        <div className="d-flex columns notify">
          <div>
            {customIcon ? (<div className="custom-icon">{customIcon}</div>) : (type === 'warning' ? (
              <Icon className="bullet" />
            ) : type === 'infor' ? (
              <div className="icon">
                <Icon />
              </div>
            ) : type === 'success' ? (
              <div className="success">
                <CheckIcon />
              </div>
            ) : (
              <div className="desc">
                <Icon />
              </div>
            ))}
          </div>
          <div>
            <div className="title">{title}</div>
            <div className="desc">{desc}</div>
          </div>
        </div>
      </GMNotify>
    </div>
  );
};
