import {
    actionType as TYPE
} from './actions'
import {notification} from "antd";

const initialState = {
    updating: false
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case TYPE.UPDATE.REQUEST:
            return {
                ...state,
                updating: true,
            }
        case TYPE.UPDATE.SUCCESS:
            notification.success({message: 'Change email successfully.', duration: 5})
            return {
                ...state, updating: false
            }
        case TYPE.UPDATE.ERROR:
            notification.warn({
                message: action.error && action.error[0] && action.error[0]['clientMsg'] ? action.error[0]['clientMsg'] : 'Change email failed.',
                duration: 5
            });
            return {
                ...state,
                updating: false
            }
        default:
            return state
    }
}

export default reducer