import React, { memo } from "react";
import { MenuAffix, MenuMask, MenuPlaceHolder, Wrapper } from "./GMMenu.styled";
import logo from "images/gmbeta.svg";
import logosm from "images/mgmbeta.svg";
import Menu from "./GMMenu.lib";
import { useState } from "react";
import { getStore, setStore } from "utils/helper";
import { ReactComponent as RightIcon } from 'images/icons/chevron-right.svg';
import { ReactComponent as LeftIcon } from 'images/icons/chevron-left.svg';
import classNames from "classnames";
import { GlobalStyle } from "./styled";
import { useEffect } from "react";

export const IS_LEFT_SIDEBAR_OPEN = 'is_left_sidebar_open';

export default memo(({ isMobile, handleToggleSidebar, ...rest }) => {
  const [isToggled, setToggleMenu] = useState(getStore(IS_LEFT_SIDEBAR_OPEN));
  const mobileMenuToggle = rest?.ui?.open_left_sidebar;
  window.handleToggleSidebar = handleToggleSidebar;
  const toggleMenu = (evt) => {
    setToggleMenu(cV => {
      setStore(IS_LEFT_SIDEBAR_OPEN, !cV);
      return !cV;
    });
  };
  useEffect(() => {
    if (isMobile === undefined) {
      return;
    }
    if (isMobile) {
      setToggleMenu(!isMobile);
    } else {
      setToggleMenu(getStore(IS_LEFT_SIDEBAR_OPEN));
    }
  }, [isMobile]);
  const expandedMenuPlaceHolder = (
    <MenuPlaceHolder className={classNames({ toggle: isToggled })}>&nbsp;</MenuPlaceHolder>
  );
  return (
    <div>
      {isMobile ? mobileMenuToggle && <MenuMask onClick={handleToggleSidebar}>&nbsp;</MenuMask> : expandedMenuPlaceHolder}
      <MenuAffix offsetTop={0} className={classNames({
        mobile: isMobile,
        open: mobileMenuToggle,
      })}>
        <Wrapper className={classNames({
          toggle: isToggled,
        })}>
          <div className="menu-header">
            <div className="logo-wrapper">
              <a href="/" className="home">
                {isToggled ? (
                  <img
                    className="img-fluid collapsed"
                    src={logosm}
                    alt="logo"
                    height="42px"
                    width="42px"
                  />
                ) : (
                  <img
                    className="img-fluid expanded"
                    src={logo}
                    alt="logo"
                    height="42px"
                    width="200px"
                  />
                )}
              </a>
            </div>
          </div>
          <div className={classNames("menu-content", {
            toggle: isToggled,
          })}>
            <Menu />
          </div>
          <div
            className={classNames("menu-minimize", {
              toggle: isToggled,
            })}
            onClick={toggleMenu}
          >
            <LeftIcon />
          </div>
        </Wrapper>
        <GlobalStyle />
      </MenuAffix>
    </div>
  );
});
