import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { list } from "apis/billing_infor";
import plusCircle from "images/plus-circle2.svg";
const { Option } = Select;

function SelectBillingInfor({
  input,
  mode = "default",
  meta: { error, invalid, touched, submitFailed },
  isRefetching,
  onChoose,
  size,
  inputProps = {},
}) {
  const [options, setOptions] = useState([
    {
      value: -1,
      label: (
       <span>--- Select a Billing Info that you've used before ---</span>
      ),
    },
    {
      value: 0,
      label: (
        <div className="text-primary d-flex align-items-center justify-content-start">
          <p className="my-0 mr-2 ml-0">
            <img
              src={plusCircle}
              className="img-fluid"
              style={{ width: "18px" }}
              alt="plus-icon"
            />
          </p>
          <p className="m-0">Add new billing info</p>
        </div>
      ),
    }
  ]);
  const [loading, setLoading] = useState(false);
  const fetchBillingInfors = () => {
    setLoading(true);
    list()
      .then(({ code, data }) => {
        setLoading(false);
        if (code === "success") {
          setOptions([
            {
              value: -1,
              label: (
               <span>--- Select a Billing Info that you've used before ---</span>
              ),
            },
            {
              value: 0,
              label: (
                <div className="text-primary d-flex align-items-center justify-content-start">
                  <p className="my-0 mr-2 ml-0">
                    <img
                      src={plusCircle}
                      className="img-fluid"
                      style={{ width: "18px" }}
                      alt="plus-icon"
                    />
                  </p>
                  <p className="m-0">Add new billing info</p>
                </div>
              ),
            },
            ...(data?.billingInfors || []).map((item) => ({
              ...item,
              value: item.id,
              label: (
                <span>{`${item?.firstName} ${item?.lastName}, 
                ${item?.streetAddress}, ${item?.city}, ${item?.country?.countryName}, ${item?.postalcode}`}</span>
              ),
            })),
          ]);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchBillingInfors();
  }, []);
  useEffect(() => {
    if (isRefetching === true) {
      fetchBillingInfors();
    }
  }, [isRefetching]);
  const onSelect = (data) => {
    if (mode === "default" && typeof onChoose === "function") {
      onChoose(
        data,
        options.find((item) => parseInt(item.value) === parseInt(data))
      );
    }
    input.onChange(
      data === undefined ? (mode === "default" ? null : []) : data
    );
  };
  const value = mode === "default" ? input.value.toString() : input.value;
  return (
    <div className="position-relative">
      <Select
        loading={loading}
        showSearch
        mode={mode}
        value={value || (mode === "default" ? undefined : [])}
        style={{ width: "100%" }}
        size={size || "large"}
        onChange={onSelect}
        optionFilterProp="children"
        {...inputProps}
      >
        {options.map((item) => (
          <Option key={item.value}>{item.label}</Option>
        ))}
      </Select>
      {loading && <span className="text-primary">Loading data ...</span>}
      {(error && submitFailed) && <span className="invalid-feedback font-size-14">{error}</span>}
    </div>
  );
}
export default React.memo(SelectBillingInfor);