/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import getMomentInstance, { getMomentInstance2 } from 'utils/moment';
import { Button, Spin, Table } from 'antd';
import { Link } from 'react-router-dom';
import { getDetail } from './actions';
import money from 'utils/money';
import queryString from 'query-string';
import BackToIcon from 'images/chevron-down.svg';
import { getOptionLabel, options_payment_status_detail } from 'utils/options';
import { Wrapper } from 'scenes/general.styled';
import { GMButton } from 'components/Button';
import GMHelmet from 'components/GMHelmet';
import { postfix } from 'utils/options';
class Detail extends Component {
  componentDidMount() {
    this.props.getDetail(this.props.match.params.id);
  }
  viewInvoiceTransaction = () => {
    this.props.history.push(
      `/billing/transactions/${this.props.match.params.id}/invoice`
    );
  };
  render() {
    const { detail, pageName, loadingDetail } = this.props;
    const columns = [
      {
        title: <b>Order name</b>,
        dataIndex: 'name',
        align: 'center',
        key: 'name',
        render: (value, record) => (
          <div>
            <Link to={`/orders/${record.id}`}>{value || `${record.id}`}</Link>
            {record.onlineReference && (
              <p>Reference: {record.onlineReference}</p>
            )}
          </div>
        ),
      },
      {
        title: <b>Shipping fee</b>,
        dataIndex: 'shippingFee',
        key: 'shippingFee',
        render: (value) => <div>{money(value)}</div>,
      },
      {
        title: <b>Tax</b>,
        key: 'totalVat',
        align: 'center',
        dataIndex: 'totalVat',
        render: (value) => <div>{money(value)}</div>,
      },
      {
        title: <b>Amount </b>,
        dataIndex: 'subTotal',
        align: 'center',
        key: 'subTotal',
        render: (value) => <div>{money(value)}</div>,
      },
      {
        title: <b>Total</b>,
        key: 'total',
        align: 'center',
        dataIndex: 'total',
        render: (value) => <div>{money(value)}</div>,
      },
      {
        title: <b>Profit</b>,
        dataIndex: 'profit',
        align: 'center',
        key: 'profit',
        render: (value) => <div>{money(value)}</div>,
      },
      {
        title: <b>Order created date</b>,
        dataIndex: 'createdAt',
        align: 'center',
        key: 'createdAt',
        render: (text) =>
          getMomentInstance2({ dateTime: text, isDateTimFormat: true }),
      },
    ];
    const modCol = [];
    // detect order
    columns.forEach((col) => {
      modCol.push(col);
      if (col.key === 'shippingFee') {
        // ...and had extrafee
        // column list?
        const cs = {};
        detail.orders?.forEach((o) => {
          o?.orderExtraFee?.forEach((f) => {
            if (f?.amount > 0) {
              cs[f?.extraTypeName] = (
                {
                title: <b>{f?.extraTypeName}</b>,
                align: "left",
                key: 'cs_' + f?.id,
                render: (row) => money(row?.orderExtraFee?.find(sf => sf.extraTypeName === f.extraTypeName)?.amount || 0),
              });
            }
          });
        });
        modCol.push(...Object.values(cs));
      }
    });
    return (
      <Wrapper
        pageName={`${pageName} #${this.props.match.params.id} detail`}
        breadcrumDetails={{
          [this.props.match.params.id]: this.props.match.params.id && `#${this.props.match.params.id || ''}`,
        }}
      >
        {this.props.match.params.id ? (
          <GMHelmet
            overrideTitle={`Payment #${this.props.match.params.id || ''}` + postfix}
          />
        ) : null}
        <Spin spinning={loadingDetail}>
          <table className="table table-borderless table-sm">
            <tbody>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  <b> Transaction Code</b>
                </td>
                <td className="px-4">
                  {detail.name}
                  {detail.type === 'Payout' && (
                    <Link
                      to={`/billing/transactions/${this.props.match.params.id}/invoice`}
                      className="text-primary mr-1"
                    >
                      &nbsp;(View invoice)
                    </Link>
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  <b> Status</b>
                </td>
                <td className="px-4">
                  {getOptionLabel(
                    options_payment_status_detail,
                    detail.status, false
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  <b>Type</b>
                </td>
                <td className="px-4">{detail.type}</td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  <b>Sub-Type</b>
                </td>
                <td className="px-4">{detail.subType}</td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  <b>Payment method</b>
                </td>
                <td className="px-4">{detail.paymentMethod}</td>
              </tr>

              {detail.paymentMethod === 'Paypal' ? (
                <>
                  <tr>
                    <td style={{ width: 200 }} className="px-4">
                      <b>Paypal's transaction fee</b>
                    </td>
                    <td className="px-4">
                      {money(detail.transactionFee?.fee || 0)}
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td style={{ width: 200 }} className="px-4">
                      <b>Balance before</b>
                    </td>
                    <td className="px-4">{money(detail.balanceBefore)}</td>
                  </tr>
                  <tr>
                    <td style={{ width: 200 }} className="px-4">
                      <b>Balance after</b>
                    </td>
                    <td className="px-4">{money(detail.balanceAfter)}</td>
                  </tr>
                </>
              )}
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  <b>Total charge</b>
                </td>
                <td className="px-4">{money(detail.amount)}</td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  <b>Description</b>
                </td>
                <td className="px-4">{detail.content}</td>
              </tr>
              <tr>
                <td style={{ width: 200 }} className="px-4">
                  <b>Payment date</b>
                </td>
                <td className="px-4">
                  {getMomentInstance2({ unixDateTime: detail.createdAt, isDateTimFormat: true })}
                </td>
              </tr>
            </tbody>
          </table>
          {detail?.orders &&
            Array.isArray(detail.orders) &&
            detail.orders.length > 0 && (
              <div>
                <Table
                  rowKey="id"
                  columns={modCol}
                  scroll={{ x: 1000 }}
                  dataSource={detail.orders}
                  pagination={false}
                />
              </div>
            )}
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    loadingDetail: state.transaction.detail.transactionDetail.loading,
    detail: state.transaction.detail.transactionDetail.data,
  }),
  (dispatch) => ({
    getDetail: (id) => {
      dispatch(getDetail(id));
    },
  })
)(Detail);
