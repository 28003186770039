import { isValidEmail } from "utils/helper";
const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter your email";
  } else if (!isValidEmail(values.email)) {
    errors.email = "Invalid email";
  }
  if (!values.password || values.password.replace(/\s+/g, "").length === 0) {
    errors.password = "Please enter your password";
  }
  return errors;
};

export default validate;
