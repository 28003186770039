import styled from 'styled-components';
import {
  BROWN_500,
  ERROR_600,
  ERROR_700,
  GRAY_50,
  GRAY_600,
  PRIMARY_50,
  PRIMARY_500,
  PRIMARY_600,
  SUCCESS_50,
  SUCCESS_600,
  SUCCESS_700,
  WARNING_50,
  WARNING_500,
  WARNING_600,
} from 'components/colors';
import { badgeColors } from './colors';

export default styled.div`
  display: inline-block;
  /* width: 58px; */
  height: 22px;
  line-height: 22px;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 16px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  ${(p) => {
    const defaultTypeSetting = `
      border-color: ${p.border || PRIMARY_500};
      color: ${p.color || PRIMARY_600};
      background-color: ${p.backgroundColor || 'transparent'};
    `;
    const colorTypeSetting = badgeColors(p?.type);
    return colorTypeSetting || defaultTypeSetting;
  }}
  padding-right: 8px;
  padding-left: 8px;
  position: relative;

  box-sizing: content-box;
  ${({ noDot = false, color }) =>
    !noDot &&
    `
    padding-left: 18px;
    &::before {
      content: " ";
      font-size: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${color || PRIMARY_600};
      position: absolute;
      left: 7px;
      top: 50%;
      margin-top: -3px;
    }
  `};
  & {

    &.pre-transit {
      color: ${BROWN_500};
      border-color: ${BROWN_500};
      svg {
        stroke: ${BROWN_500};
      }
    }
    &.in-transit {
      color: ${PRIMARY_600};
      border-color: ${PRIMARY_600};
      svg {
        stroke: ${PRIMARY_600};
      }
    }
    &.delivered {
      color: ${SUCCESS_700};
      border-color: ${SUCCESS_600};
      svg {
        stroke: ${SUCCESS_600};
      }
    }
    &.failed-delivery {
      color: ${ERROR_700};
      border-color: ${ERROR_600};
      svg {
        stroke: ${ERROR_600};
      }
    }
    &.pre-transit,
    &.in-transit,
    &.delivered,
    &.failed-delivery {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-top: 8px;
      svg {
        width: 12px;
        margin-right: 4px;
      }
    }
    &.default {
      color: ${WARNING_600};
      border-color: ${WARNING_600};
      background-color: ${WARNING_50};
    }
    &.processing {
      color: ${PRIMARY_600};
      border-color: ${PRIMARY_600};
      background-color: ${PRIMARY_50};
    }
    &.warning {
      color: ${GRAY_600};
      border-color: ${GRAY_600};
      background-color: ${GRAY_50};
    }
    &.success {
      color: ${SUCCESS_600};
      border-color: ${SUCCESS_600};
      background-color: ${SUCCESS_50};
    }
  }
`;
