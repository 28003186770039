import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  change,
  submit,
} from 'redux-form';
import { Modal } from 'antd';
import { renderRadioAnt } from 'utils/Form';
import { options_product_grouping } from 'utils/options';
import { RenderTinyMCE } from 'utils/Form/RenderTinyMCE';
import SelectProductTag from 'utils/Form/Search/SelectProductTag';
import validate from './validate';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import { GMTextbox } from 'components/InputFields';
import { RenderProducts } from 'utils/Form/components/RenderProduct';
import { push } from 'connected-react-router';
const { confirm } = Modal;
const EditCampaignForm = ({
  loading,
  profile,
  handleSubmit,
  frontImageSize,
  backImageSize,
  changeFieldValue,
  products,
  appendProductType,
  variants,
  frontImage,
  backImage,
  title,
  dispatch,
}) => {
  useEffect(() => {
    const total_variants = products.reduce((acc, cur, i) => {
      return acc + (cur?.sizes?.length || 0) * (cur?.colors?.length || 0);
    }, 0);
    changeFieldValue('variants', total_variants);
    if (total_variants > 100) {
      changeFieldValue('group', '2');
    }
    return () => {};
  }, [products]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit} className="text-dark create-campaign">
        <div>
          <div className="col-md-12">
            <FieldArray
              products={products}
              loading={loading}
              isCloneCampaign={true}
              name={`products`}
              component={RenderProducts}
              profile={profile}
              changeField={(field, value) => changeFieldValue(field, value)}
              frontImage={frontImage}
              backImage={backImage}
              appendProductType={appendProductType}
              variants={variants}
              openDefault={false}
              frontImageSize={frontImageSize}
              backImageSize={backImageSize}
            />
          </div>

          <div className="block-desc">
            <div className="col-md-6 mb-3">
              <div className="form-group productname">
                <label className="font-weight-bold text-dark">
                  Product name <span className="text-danger"></span>
                </label>
                <GMTextbox
                  name="title"
                  placeholder="Product name"
                  maxLength={100}
                />
              </div>

              <div className="form-group group">
                <label className="font-weight-bold text-dark align-items-center">
                  Product grouping
                </label>
                <div>
                  <Field
                    name="group"
                    component={renderRadioAnt}
                    options={options_product_grouping.map((item) => ({
                      ...item,
                      disabled: parseInt(item.value) === 1 && variants > 100,
                    }))}
                    vertical={true}
                  />
                </div>
              </div>
              <div className="form-group listtags">
                <label className="font-weight-bold text-dark">List tags</label>
                <Field
                  name="tags"
                  component={SelectProductTag}
                  inputProps={{
                    placeholder: 'Add a tag',
                    dropdownClassName: 'hide-empty',
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 mb-3 ">
              <div className="form-group description">
                <label className="font-weight-bold text-dark">
                  Product description
                </label>
                <Field
                  name="description"
                  component={RenderTinyMCE}
                  height={300}
                  className="tox-tinymce"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex row justify-content-end mt-4 mr-1">
          <div>
            <GMButton
              size="lg"
              className="mr-2"
              type="button"
              color={'SecondaryGray'}
              onClick={() => {
                dispatch(push('/products/list'));
              }}
            >
              Cancel
            </GMButton>
          </div>
          <div>
            <GMButton
              type="primary"
              size="lg"
              onClick={() => {
                dispatch(submit('EditCampaignForm'));
              }}
              disabled={!title}
            >
              Update products
            </GMButton>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

EditCampaignForm.propTypes = {};
const selector = formValueSelector('EditCampaignForm');
export default connect(
  (state) => ({
    title: selector(state, 'title') || '',
    numberDate: selector(state, 'numberDate') || 0,
    frontImage: selector(state, 'frontImage') || '',
    backImage: selector(state, 'backImage') || '',
    products: selector(state, 'products') || [],
    appendProductType: selector(state, 'appendProductType') || 0,
    variants: selector(state, 'variants') || 0,
    formValue: state.form.EditCampaignForm?.values,
    frontImageSize: selector(state, 'frontImageSize'),
    backImageSize: selector(state, 'backImageSize'),
  }),
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change('EditCampaignForm', field, value));
    },
    dispatch,
  })
)(
  reduxForm({
    form: 'EditCampaignForm',
    enableReinitialize: true,
    validate,
  })(EditCampaignForm)
);
