import React from "react";
import { Input } from "antd";
import classNames from "classnames";
import NumberFormat from "react-number-format";
const renderNumberFormat = ({
  input,
  meta: { error, invalid, touched, submitFailed },
  classNameContainer = "",
}) => {
  function limit(val, max) {
    if (val.length === 1 && val[0] > max[0]) {
      val = "0" + val;
    }

    if (val.length === 2) {
      if (Number(val) === 0) {
        val = "01";
        //this can happen when user paste number
      } else if (val > max) {
        val = max;
      }
    }

    return val;
  }

  function cardExpiry(val) {
    let month = limit(val.substring(0, 2), "12");
    let year = val.substring(2, 6);

    return month + (year.length ? "/" + year : "");
  }
  const onChange = (value) => {
    input.onChange(value);
  };
  return (
    <div
      className={classNames(
        "position-relative w-100 optionShip ",
        classNameContainer
      )}
    >
      <NumberFormat
        value={input.value.value}
        format={cardExpiry}
        className={classNames(
          "ant-input-affix-wrapper ant-input-affix-wrapper-lg"
        )}
        placeholder="MM/YYYY"
        mask={["M", "M", "Y", "Y", "Y", "Y"]}
        onValueChange={onChange}
      />
      {(error && submitFailed) && (
        <span className="invalid-feedback">{error}</span>
      )}
    </div>
  );
};

export default renderNumberFormat;
