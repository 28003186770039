import React, { memo, useEffect, useState } from 'react';
import { Spin, Table, Tooltip } from 'antd';
import Filter from 'components/Filter';
import ColumnRenderer from './Transaction.column';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { getList } from './actions';
import { useHistory } from 'react-router-dom';
import { getMomentInstance2 } from 'utils/moment';
import { change } from 'redux-form';
import { getListPaypal } from 'scenes/PaymentMenthod/scenes/List/actions';
import { ReactComponent as HelpIcon } from 'images/icons/help-circle.svg';
import AltFilter from './Transastion.filter.alt';

export default memo((props) => {
  const queryParams = queryString.parse(window.location.search);
  const [filterValue, setFilterValue] = useState({
    sortBy: 'id',
    sortType: 'desc',
    type: queryParams?.type ? queryParams.type + '' : '0',
    ...queryParams,
  });
  const transactionInfo = useSelector(
    (state) => state.transaction?.list?.transactions
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const fetchData = ({ page = 1, perPage = 20, ...options }) => {
    let params = {
      page: page || 1,
      perPage,
      tab: 'transaction',
      ...options,
    };
    const saveParams = { ...params };

    delete saveParams.name;
    delete saveParams.method;
    delete saveParams.page;
    delete saveParams.tab;
    if (filterValue?.from && filterValue?.to) {
      dispatch(
        change('paymentFormFilter', 'date_range', [
          getMomentInstance2({ unixDateTime: filterValue?.from }).startOf(
            'day'
          ),
          getMomentInstance2({ unixDateTime: filterValue?.to }).startOf('day'),
        ])
      );
      delete saveParams.from;
      delete saveParams.to;
    }
    if (params.type === '0') {
      delete params.type;
    }
    delete params.name;
    if (
      `${window.location.pathname + window.location.search}` !==
      window.location.pathname + '?' + queryString.stringify(saveParams)
    ) {
      history.replace(
        window.location.pathname + '?' + queryString.stringify(saveParams)
      );
    }
    dispatch(getList(params));
  };
  useEffect(() => {
    fetchData(filterValue);
  }, [filterValue]);

  useEffect(() => {
    dispatch(change("paymentFormFilter", "type", filterValue.type));
    getListPaypal();
  }, []);
  return (
    <Spin spinning={transactionInfo?.loading}>
      <div className="tab-transaction">
        <div className="title">
          <Filter
            placeholder="Search by keyword"
            showFilter
            onSubmit={(values) => {
              setFilterValue((oldState) => {
                const value2Set = {
                  ...oldState,
                  ...values,
                  name: (values.search || '').trim(),
                };
                if (values?.date_range?.[0] && values?.date_range?.[1]) {
                  value2Set.from = values?.date_range?.[0]
                    ?.startOf('day')
                    ?.unix();
                  value2Set.to = values?.date_range?.[1].endOf('day')?.unix();
                } else {
                  value2Set.from = value2Set.to = undefined;
                }
                return value2Set;
              });
            }}
            filterCustomInputs={<AltFilter />}
            tooltip={
              <Tooltip title="Search by Order name/Order ID, or Transaction ID.">
                <HelpIcon className="search-tooltip" />
              </Tooltip>
            }
          />
        </div>
        <Table
          columns={ColumnRenderer()}
          dataSource={transactionInfo.data}
          scroll={{ x: 1000 }}
          pagination={
            !props.is_mobile
              ? {
                showTotal: (total) => `Total ${total} items`,
                total: transactionInfo.total,
                pageSize: 20,
                current: transactionInfo.params.page,
                showSizeChanger: false,
                onChange: (page, perPage) =>
                  fetchData({
                    ...transactionInfo.params,
                    page,
                    perPage,
                  }),
              }
              : false
          }
        />
      </div>
    </Spin>
  );
});
