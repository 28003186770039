import React from 'react';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import RenderAuthenticationSlider from 'utils/Form/RenderAuthenticationSlider';
import validate from './validate';
import { GMCopyright, GMTermPrivacy } from 'info';
import { Wrapper } from './styled';
import { ReactComponent as Key } from 'images/icons/key-01.svg';
import { ReactComponent as ArrowLeft } from 'images/icons/arrow-left.svg';
import { ReactComponent as AlertCircle } from 'images/icons/alert-circle.svg';
import { GMTextbox } from 'components/InputFields';
import { GMButton } from 'components/Button';
import { useSelector } from 'react-redux';
import SendForgotPasswordSuccess from './SendForgotPasswordSuccess';

const FormForgetPassword = (props) => {
  const { handleSubmit } = props;
  const serverErrors = useSelector(
    (state) => state.auth.forgetPassword.serverError
  );
  const serverSuccessData = useSelector(
    (state) => state.auth.forgetPassword.serverSuccessData
  );
  const errorMessage =
    serverErrors && serverErrors.length > 0
      ? serverErrors.map((error) => {
          switch (error?.code) {
            case 'invalid_param':
            case 'invalid_email':
              return (
                <li>
                  <AlertCircle />
                  <span className="error">Invalid email</span>
                  <span className="detail">{serverErrors[0]?.msg}</span>
                </li>
              );
            default:
              return (
                <li>
                  <AlertCircle />
                  <span className="detail">{error.msg}</span>
                </li>
              );
          }
        })
      : null;
  const form = (
    <form onSubmit={handleSubmit}>
      <div>
        <div className="text-center">
          <div className="icon">
            <Key />
          </div>
        </div>
        <h3 className="text-center form-header">Forgot password?</h3>
        <p className="form-message">
          No worries, we’ll send you reset instructions
        </p>
      </div>
      {errorMessage ? <ul className="error-list">{errorMessage}</ul> : null}
      <div className="form-content">
        <div className="form-group">
          <GMTextbox
            name="email"
            placeholder="Email"
            label="Email"
            maxLength={256}
            className="email"
            showError
          />
        </div>
        <div className="mt-4 cta">
          <GMButton type="submit" className="w-100">
            Reset password
          </GMButton>
        </div>
        <div className="text-center mb-3 fontSize-child">
          <Link to="/auth/login" className="back-cta">
            <ArrowLeft />
            Back to log in
          </Link>
        </div>
      </div>
    </form>
  );
  return (
    <Wrapper className="container-fluid">
      <div className="row align-items-stretch justify-content-center">
        <div className="col-md-6">
          <div className="col-12 col-md-8 col-xl-6 m-auto min-vh-100 d-flex px-0 left-form form-container">
            {!serverSuccessData ? form : <SendForgotPasswordSuccess />}
            {GMTermPrivacy}
            {GMCopyright}
          </div>
        </div>
        <div className="col-md-6 right-authentication-section">
          <div className="min-vh-100 d-flex align-items-center justify-content-center">
            <RenderAuthenticationSlider />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default reduxForm({
  form: 'formForgetPassword',
  enableReinitialize: true,
  validate,
})(FormForgetPassword);
