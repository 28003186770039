import React from 'react';
import { Link } from 'react-router-dom';
import RenderOrderPlatformInfo from 'utils/Form/RenderOrderPlatformInfo';
import RenderOrderStatus from 'utils/Form/RenderOrderStatus';
import RenderTotalOrder from 'utils/Form/RenderTotalOrder';
import RenderTrackingInfo from 'utils/Form/RenderTrackingInfo';
import RenderUpdateLabelForLabelOrder from 'utils/Form/RenderUpdateLabelForLabelOrder';
import getMomentInstance, { getMomentInstance2 } from 'utils/moment';
import { getOptionLabel, options_payment_status } from 'utils/options';

import { ReactComponent as I1Icon } from 'images/icons/marker-pin-01.svg';
import { ReactComponent as I2Icon } from 'images/icons/check-circle.svg';
import { ReactComponent as I3Icon } from 'images/icons/container.svg';
import { ReactComponent as I4Icon } from 'images/icons/lock-01.svg';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import moment from 'moment';
import TableItemMenu from './TableItemMenu';
import { GMBadge } from 'components/Badge';
import { GRAY_200, GRAY_50, GRAY_600, PRIMARY_100, PRIMARY_50, PRIMARY_700 } from 'components/colors';

const paymentStatusMapping = {
  processing: {
    border: PRIMARY_100,
    color: PRIMARY_700,
    backgroundColor: PRIMARY_50,
  },
  _none: {
    border: GRAY_200,
    color: GRAY_600,
    backgroundColor: GRAY_50,
  }
}

export const columns = ({ listActions, rowSelection, profile, state, orderItemActions }) => {
  return ([
    {
      title: 'Order name',
      dataIndex: 'name',
      width: 180,
      key: 'name',
      fixed: 'left',
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (value, record) => (
        <div className="d-flex flex-column align-items-start">
          <div>
            <Link to={`/orders/${record.id}`}>{value || `#${record.id}`}</Link>
            <div className="tags">
              <Tooltip
                title={
                  record?.verifiedAddress
                    ? 'Verified Address'
                    : 'Unverified Address'
                }
              >
                <I1Icon
                  className={classNames({ active: record?.verifiedAddress })}
                />
              </Tooltip>
              <Tooltip
                title={
                  parseInt(record?.orderMatch?.isImport) !== 2
                    ? 'Matched Product'
                    : 'Unmatched Product'
                }
              >
                <I3Icon
                  className={classNames({
                    active: parseInt(record?.orderMatch?.isImport) !== 2,
                  })}
                />
              </Tooltip>
              <Tooltip title={record?.isApproved ? 'Approved' : 'Unapproved'}>
                <I2Icon className={classNames({ active: record?.isApproved })} />
              </Tooltip>
              <Tooltip title={!record?.locked ? 'Unlocked' : 'Locked'}>
                <I4Icon className={classNames({ active: record?.locked })} />
              </Tooltip>
            </div>
          </div>
          {parseInt(record?.platformId) === 17 &&
            parseInt(record?.status) === 1 &&
            parseInt(record?.customStatus) === 12 &&
            !record?.orderBarcodeFiles?.[0]?.barcodePath && (
              <RenderUpdateLabelForLabelOrder
                cusId={profile?.id}
                orderId={record?.id}
              />
            )}
        </div>
      ),
    },
    {
      title: 'Ref ID',
      dataIndex: 'platform',
      align: 'left',
      width: 120,
      key: 'platform',
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (value, record) => {
        return (
          <div>
            <div>{record?.onlineId || ''}</div>
            <div>{record?.onlineName ? `Order #${record?.onlineName}` : ''}</div>
            <div>{record?.onlineReference || ''}</div>
          </div>
        );
      },
    },
    {
      title: 'Store',
      dataIndex: 'platform',
      align: 'left',
      key: 'platform',
      width: 90,
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (value, record) => {
        return <RenderOrderPlatformInfo order={record} isOnlyShowIcon={true} />;
      },
    },
    {
      title: 'Customer name',
      dataIndex: 'cusName',
      key: 'customer_name',
      width: 200,
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (text) => {
        return text;
      },
    },
    {
      title: 'Fulfill status',
      dataIndex: 'status',
      width: 120,
      key: 'status',
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (text, record) => <RenderOrderStatus order={record} />,
    },
    {
      title: 'Tracking status',
      dataIndex: 'status',
      key: 'status',
      width: 220,
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (text, record) => {
        //trackingWorkings in order api
        return <RenderTrackingInfo order={record} />;
      },
    },
    {
      title: 'Payment',
      dataIndex: 'paymentStatus',
      width: 100,
      key: 'paymentStatus',
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (v, record) => {
        if (typeof v * 1 !== 'number' && v?.length > 1) {
          const ts = (v || '');
          const style = paymentStatusMapping[v] ? paymentStatusMapping[v] : paymentStatusMapping._none;
          return <GMBadge
            noDot={true}
            className="badge"
            {...style}
          >
            {ts.charAt(0).toUpperCase() + ts.slice(1)}
          </GMBadge>
        }
        return getOptionLabel(options_payment_status, v, false);;
      }
    },
    // {
    //   title: RATE SUPPORT,
    //   dataIndex: "id",
    //   width: 150,
    //   key: "id",
    //   shouldCellUpdate: (record, prevRecord) => JSON.stringify(record) !== JSON.stringify(prevRecord),
    //   render: (text, record) => <RenderRatingOrder orderRating={record?.orderRating} orderId={text} cusId={profile.id} name={`ratingOrderForm${text}`}/>
    // },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      key: 'createdAt ',
      width: 120,
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (data, record) =>
        data
          ? getMomentInstance(
            null,
            moment(data, 'YYYY-MM-DDTHH:mm:ssZ').unix(),
            true
          )
          : 'N/A',
    },
    {
      title: 'Approved date',
      dataIndex: 'approvedAt',
      key: 'approvedAt ',
      width: 120,
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (text, record) =>
        text ? getMomentInstance(undefined, text, true) : 'N/A',
    },
    {
      title: 'Payment date',
      dataIndex: 'paymentDateTime',
      key: 'paymentDateTime',
      width: 120,
      // shouldCellUpdate: (record, prevRecord) =>
      //   JSON.stringify(record) !== JSON.stringify(prevRecord),
      // render: (text, record) =>
      //   text ? getMomentInstance(undefined, text, true) : 'N/A',
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (data, record) =>
        record?.transaction?.createdAt
          ? getMomentInstance2({ unixDateTime: record?.transaction?.createdAt, isDateTimFormat: true })
          : 'N/A',
    },
    {
      title: 'Qty',
      dataIndex: 'orderExtra',
      key: 'orderExtra',
      width: 100,
      align: 'right',
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (value) => value?.itemsQuantity || 0,
    },
    // {
    //   title: QUANTITY,
    //   width: 100,
    //   dataIndex: "orderExtra",
    //   align: "start",
    //   key: "orderExtra",
    //   shouldCellUpdate: (record, prevRecord) =>
    //     JSON.stringify(record) !== JSON.stringify(prevRecord),
    //   render: (value) => value?.itemsQuantity || 0,
    // },
    {
      title: 'Total',
      width: 100,
      dataIndex: 'total',
      align: 'right',
      key: 'total',
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (text, record) => <RenderTotalOrder order={record} />,
    },
    {
      title: '',
      width: 40,
      dataIndex: 'kebab',
      align: 'right',
      key: 'total',
      fixed: 'right',
      shouldCellUpdate: (record, prevRecord) =>
        JSON.stringify(record) !== JSON.stringify(prevRecord),
      render: (text, record) => {
        if (!(record?.platformId && record?.platformId > 0)) {
          return null;
        }
        const actions = orderItemActions(record);

        return (
          <TableItemMenu
            record={record}
            actions={actions}
          />
        );
      },
    },
  ])
};
