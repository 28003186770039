import { GRAY_300, GRAY_400, GRAY_500, SHADOW_XS } from 'components/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: row;
  align-items: center;

  .platform {
    .ant-select-selector {
      height: 440px;
      width: 170px;
      max-width: 100%;
      max-height: 100%;
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
      border-radius: 8px;
      margin-left: 10px;
    }
    .ant-select-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-select-selection-placeholder {
      color: ${GRAY_500};
    }
    .ant-select-arrow {
      font-size: 1px;
    }
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
