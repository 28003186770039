import { notification } from 'antd';
import { LOGOUT } from './actions';
import { API_TOKEN_KEY } from 'web.config';
import { removeCookie } from 'utils/helper';
const initialState = {
  loading: false,
  success: false,
};

function LogoutReducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT.request:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case LOGOUT.success:
      removeCookie(API_TOKEN_KEY) /* auth cookie */;
      localStorage.removeItem('isCheckConnectSuccessful')
      return {
        ...state,
        loading: false,
        success: true,
      };
    case LOGOUT.error:
      notification.error({
        message:
          action.error && action.error[0] && action.error[0]['clientMsg']
            ? action.error[0]['clientMsg']
            : '',
      });
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
}

export default LogoutReducer;
