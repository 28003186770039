import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Modal, Tooltip, Result, Button } from "antd";
import { Link } from "react-router-dom";
import money from "utils/money";
export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
      selected: null,
    };
  }
  render() {
    const { statisticProfit, user } = this.props;
    const handleShowDetail = (selected) => {
      this.setState({
        showDetail: true,
        selected,
      });
    };
    return (
      <div className="bg-light mb-3 p-3">
        <Spin spinning={statisticProfit.loading}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4 text-center">
                <div className="p-3">
                  <p className="h1 mb-0">
                    {statisticProfit.numberOfOrders || 0}
                  </p>
                  <p className="mb-0">TOTAL ORDER</p>
                  <div>
                    <Link to={`/orders`} className="btn btn-success mr-1">
                      View all orders
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 text-center">
                <div className="p-3">
                  <p className="h1 mb-0">{money(user.profit || 0)}</p>
                  <p className="mb-0">PROFITS AVAILABLE</p>
                  <div>
                    <Tooltip
                      title="Payout request are available"
                      placement="bottom"
                    >
                      <button
                        type="button"
                        className="btn btn-success mr-1"
                        onClick={() => handleShowDetail()}
                      >
                        Request Payout
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 text-center">
                <div className="p-3">
                  <p className="h1 mb-0">
                    {money(statisticProfit.totalProfit || 0)}
                  </p>
                  <p className="mb-0">EXPECTED PROFIT</p>
                </div>
              </div>
            </div>
          </div>
        </Spin>
        <Modal
          width={600}
          footer={null}
          visible={this.state.showDetail}
          onCancel={() => this.setState({ showDetail: false })}
          bodyStyle={{ padding: 0 }}
        >
          {" "}
          <Result
            title="Your operation has been executed"
            extra={
              <Button type="primary" key="console">
                Go Console
              </Button>
            }
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
