import React from "react";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
} from "redux-form";
import FieldSelect from "utils/fields/FieldSelect";
import { options_delivery, options_active_webhooks } from "utils/options";
import { renderInputText } from "utils/Form";
import validate from "./validate";
const formEditWebhooks = (props) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <div className="form-group">
          <h5 className="font-weight-bold">
            Webhooks name
          </h5>
          <Field
            name="name_webhooks"
            component={renderInputText}
            maxLength={"50"}
          />
        </div>
        <div className="form-group">
          <h5 className="font-weight-bold">
            Delivery URL
          </h5>
          <Field
            name="url_webhooks"
            component={renderInputText}
            maxLength={"50"}
          />
        </div>

        <div className="form-group">
          <h5 className="font-weight-bold">Topic</h5>
          <Field
            name="topic_webhooks"
            component={FieldSelect}
            options={options_delivery}
            size="large"
            defaultValue={options_delivery[0]["value"]}
            className="w-100 line-height-50px"
          />
        </div>
        <div className="form-group">
          <h5 className="font-weight-bold">Status</h5>
          <div className="d-flex align-items-center">
            <Field
              name="status_webhooks"
              component={FieldSelect}
              options={options_active_webhooks}
              size="large"
              defaultValue={options_active_webhooks[0]["value"]}
              className="w-100 line-height-50px"
            />
          </div>
        </div>
      </div>
      <div className="text-center d-flex align-items-center justify-content-end">
        <button
          type="submit"
          className="btn btn-primary"
          style={{ width: 150 ,borderRadius: "5px"}}>
          Save
        </button>
      </div>
    </form>
  );
};

formEditWebhooks.propTypes = {};

const formEditWebhookForm = reduxForm({
  form: "formEditWebhooks",
  enableReinitialize: true,
  destroyOnUnmount: true,
  validate,
})(formEditWebhooks);
export default connect((state) => ({}))(formEditWebhookForm);
