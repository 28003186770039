import React, { Component } from "react";
import { connect } from "react-redux";
import { Pagination, Table } from "antd";
import { getList } from "./actions";
import queryString from "query-string";
import FormFilter from "./components/FormFilter";
import getMomentInstance from "utils/moment";
import { Wrapper } from "scenes/general.styled";

class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        sortBy: "id",
        sortType: "desc",
        status: "all",
        carrierName: "all",
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (params["status"] === "all") delete params["status"];
    if (params["carrierName"] === "all") delete params["carrierName"];
    if (!params['name']) delete params['name']
    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.getList({ ...params });
  };
  render() {
    const { trackings, pageName } = this.props;
    const { initial_filter_values } = this.state;
    const columns = [
      {
        title: <b>ORDER CODE</b>,
        dataIndex: "orderName",
        align: "left",
        key: "orderName",
      },
      {
        title: <b>TRACKING</b>,
        dataIndex: "trackingCode",
        align: "center",
        key: "trackingCode",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>CARRIER</b>,
        dataIndex: "carrierName",
        align: "center",
        key: "carrierName",
        render: (text) => <span>{text}</span>,
      },

      {
        title: <b>SERVICE</b>,
        dataIndex: "serviceName",
        align: "center",
        key: "serviceName",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>TIME</b>,
        dataIndex: "createdAt",
        align: "center",
        key: "createdAt",
        render: (text) => {
          return getMomentInstance(text, null, true);
        },
      },
      {
        title: <b>STATUS</b>,
        dataIndex: "status",
        align: "center",
        key: "status",
      },
    ];
    return (
      <Wrapper
        pageName={pageName}
        noContainer
      >
        <div className="filter only-filter">
          <FormFilter
            initialValues={initial_filter_values}
            trackings={trackings}
            onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
            handleAdvancedSubmit={this.fetchData}
            is_mobile={this.props.is_mobile}
          />
        </div>
        <div className="main-content">
          <Table
            rowKey="id"
            columns={columns}
            dataSource={trackings.data}
            loading={trackings.loading}
            scroll={{ x: 1000 }}
            pagination={!this.props.is_mobile ? {
              showTotal: (total) => `Total ${total} items`,
              total: trackings.total,
              pageSize: parseInt(
                trackings.params.perPage > trackings.total
                  ? trackings.total
                  : trackings.params.perPage
              ),
              current: trackings.params.page,
              showSizeChanger: true,
              onChange: (page, perPage) =>
                this.fetchData({ ...trackings.params, page, perPage }),
            } : false}
          />
          {this.props.is_mobile && <div className="d-flex justify-content-end align-items-center py-3 px-3">
            <div>
              <Pagination
                size="small"
                showSizeChanger
                className="pagination"
                total={trackings.total}
                defaultCurrent={trackings?.params?.page || 1}
                pageSize={trackings?.params?.perPage || 0}
                onChange={(page, perPage) =>
                  this.fetchData({ ...trackings.params, page: page === Number.NEGATIVE_INFINITY ? 1 : (page === 0 ? 1 : page), perPage })
                }
              />
            </div>
          </div>}
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    trackings: state.order.tracking.trackings,
    formFilterTracking: state.form.formFilterTracking?.values || {},
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(getList(options));
    },
  })
)(List);
