import List from "./scenes/List";
import Edit from "./scenes/Edit";
import Create from "./scenes/Create";

export default [
  {
    key: "gift_message",
    name: "Gift message",
    component: List,
    path: "/settings/gift-message",
    icon: null,
    template: "default",
    isPublic: true
  },
  {
    key: "gift_message.create",
    name: "Create gift message",
    component: Create,
    path: "/settings/gift-message/create",
    icon: null,
    template: "default",
    isPublic: true
  },
  {
    key: "gift_message.detail",
    name: "Edit gift message",
    component: Edit,
    path: "/settings/gift-message/:id/edit",
    icon: null,
    template: "default",
    isPublic: true
  },
];
