import { takeLatest, call, put, all } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import {
  ISSUE_DETAIL_REQUEST,
  ISSUE_DETAIL_SUCCESS,
  ISSUE_DETAIL_FAIL,
} from './constants';

import { getDetailIssue } from 'apis/order-issues';

function* getIssueDetailSaga(action) {
  const { id } = action;
  try {
    const response = yield call(getDetailIssue, id);
    if (response.code === 'success') {
      yield all([put({ type: ISSUE_DETAIL_SUCCESS, ...response })]);
    } else {
      yield all([
        put({ type: ISSUE_DETAIL_FAIL, error: { message: 'Not found order' } }),
        put(replace(`/orders`)),
      ]);
    }
  } catch (error) {
    yield all([yield put({ type: ISSUE_DETAIL_FAIL, error })]);
  }
}

function* getOrderDetailWatcher() {
  yield takeLatest(ISSUE_DETAIL_REQUEST, getIssueDetailSaga);
}

export default getOrderDetailWatcher;
