import { UI } from "./actions";

const initialState = {
  template: "",
  menu: null,
  id: null,
  open_left_sidebar: false,
  full_screen: localStorage.getItem("full_screen") === "true",
  theme: "dark",
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case UI.set_template:
      return {
        ...state,
        template: action.template,
      };
    case UI.set_extra_menu:
      return {
        ...state,
        menu: action.menu,
        id: action.id,
      };
    case UI.OPEN_LEFT_SIDEBAR:
      return {
        ...state,
        open_left_sidebar: action.is_open,
      };
    case UI.TOGGLE_FULL_SCREEN:
      localStorage.setItem("full_screen", !state.full_screen);
      return {
        ...state,
        full_screen: !state.full_screen,
      };
    case UI.set_theme:
      return {
        ...state,
        theme: action.theme,
      };
    default:
      return state;
  }
}

export default reducer;
