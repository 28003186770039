import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag, notification, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Modal from 'antd/lib/modal/Modal';
import { WrapperPickerColor } from './styled';
import { ReactComponent as Addmore } from 'images/icons/edit-02.svg';

export const RenderProductColors = ({
  input,
  active,
  product,
  options,
  changeColorActive,
}) => {
  const [showPickColor, setShowPickColor] = useState(false);
  const [colorSelected, setColorSelected] = useState([]);
  const [colorActive, setColorActive] = useState('');

  useEffect(() => {
    if (showPickColor) {
      setColorSelected([...(input.value || [])]);
      setColorActive(`#${active?.value}`);
    }
    return () => {};
  }, [showPickColor]);

  const handleSelectColor = (color) => {
    if (
      colorSelected.find((item) => parseInt(item.id) === parseInt(color.id))
    ) {
      setColorSelected([
        ...colorSelected.filter(
          (item) => parseInt(item.id) !== parseInt(color.id)
        ),
      ]);
    } else {
      setColorSelected([...colorSelected, color]);
    }
  };
  const selected = input.value || [];

  return (
    <WrapperPickerColor>
      <Tag
        className="cursor-pointer text-center add-more text-center"
        onClick={() => setShowPickColor(true)}
      >
        <Addmore />
      </Tag>
      {selected?.map((color) => (
        <Tooltip title={color?.name}>
          <Tag
            onClick={() => changeColorActive(color)}
            className={classNames('mb-2 cursor-pointer tag-color-primary', {
              'border-secondary text-dark': parseInt(color?.id) === 12,
            })}
            color={`#${color.value}`}
            key={color.id}
          >
            {parseInt(color.id) === parseInt(active?.id) ? (
              <span
                className={classNames({
                  invisible: parseInt(color.id) !== parseInt(active?.id),
                })}
              >
                <FontAwesomeIcon icon={faCheck} />
              </span>
            ) : (
              <span
                className={classNames({
                  invisible: parseInt(color.id) !== parseInt(active?.id),
                })}
              >
                <FontAwesomeIcon icon={faCheck} />
              </span>
            )}
          </Tag>
        </Tooltip>
      ))}

      <Modal
        visible={showPickColor}
        title={`Select colors for ${product.brand} - ${product.name}`}
        onCancel={() => setShowPickColor(false)}
        onOk={() => {
          if (colorSelected.length > 0) {
            setShowPickColor(false);
            input.onChange(colorSelected);
            if (
              !colorSelected.find(
                (color) => parseInt(color.id) === parseInt(active?.id)
              )
            ) {
              changeColorActive(colorSelected[0]);
            }
          } else {
            notification.warn({
              message: 'Please choose colors for product',
            });
          }
        }}
      >
        <div className="row">
          <div className="col-md-6 text-center">
            <img
              style={{ backgroundColor: colorActive }}
              className="w-100"
              src={product.frontImg}
              alt=""
            />
          </div>
          <div className="col-md-6">
            {options?.map((color) => (
              <Tooltip title={color?.name}>
                <Tag
                  onClick={() => handleSelectColor(color)}
                  onMouseOver={() => setColorActive(`#${color.value}`)}
                  className={classNames('mb-2 cursor-pointer', {
                    'border-secondary text-dark': parseInt(color?.id) === 12,
                  })}
                  color={`#${color.value}`}
                  key={color.id}
                >
                  <span
                    className={classNames({
                      invisible: !colorSelected.find(
                        (item) => parseInt(item.id) === parseInt(color.id)
                      ),
                    })}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Tag>
              </Tooltip>
            ))}
          </div>
        </div>
      </Modal>
    </WrapperPickerColor>
  );
};
export default React.memo(RenderProductColors);
