import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import FormRatingStar from 'utils/Form/FormRatingStar';
import { Spin, notification } from 'antd';
import { compose } from 'redux';
import { updateRating, detailIssue } from 'apis/order-issues';
const validate = (values) => {
  const errors = {};

  return errors;
};

const RenderRatingIssue = ({
  issueId = 0,
  changeFieldValue = null,
  name = '',
  state = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [currIssue, setCurrIssue] = useState({});
  useEffect(() => {
    setLoading(true);
    detailIssue(issueId)
      .then((response) => {
        setLoading(false);
        if (response.code === 'success' && response?.data?.issue) {
          setCurrIssue(response?.data?.issue);
          changeFieldValue(name, 'rating', response?.data?.issue?.rating);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);
  const handleOnRatting = (rating) => {
    setLoading(true);
    updateRating(currIssue.id, {
      rating: rating,
      contentFeedback: '',
    })
      .then((response) => {
        setLoading(false);
        setCurrIssue({
          ...currIssue,
          rating: rating,
        });
        if (response.code === 'success') {
          notification.success({
            message: response?.clientMsg || 'Rating issue successfully.',
            duration: 5,
          });
        } else {
          notification.warn({
            message: response?.[0]?.['clientMsg'] || 'Rating issue failed.',
            duration: 5,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Spin spinning={loading}>
      <form>
        <div className="form-group">
          <Field
            name="rating"
            component={FormRatingStar}
            classNameContainer={'ratingOrder'}
            handleOnRatting={handleOnRatting}
            height="400px"
            defaultValue={0}
          />
        </div>
      </form>
    </Spin>
  );
};

export default React.memo(
  compose(
    connect(
      (state, props) => ({
        initialValues: {
          rating: 1,
        },
        state: state,
        form: props.name,
      }),
      (dispatch) => ({
        changeFieldValue: function (name, field, value) {
          dispatch(change(name, field, value));
        },
      })
    ),
    reduxForm({
      enableReinitialize: true,
      destroyOnUnmount: true,
      initialValues: true,
      validate,
    })
  )(RenderRatingIssue)
);
