import axios from 'utils/request';

const prefix = '/store-integrations';

export const getEbayLink = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/redirect-ebay`,
  });
};

export const getAmazonLink = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/redirect-amazon`,
  });
};

export const createShopBaseStore = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/shopbase`,
    is_json_content_type: true,
  });
};
export const createEbayStore = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/ebay`,
    is_json_content_type: true,
  });
};
export const createEtsyStore = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/etsy`,
    is_json_content_type: true,
  });
};
export const createAmazonStore = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/amazon`,
    is_json_content_type: true,
  });
};
export const editEbay = (data, id) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/ebay/${id}`,
    is_json_content_type: true,
  });
};
export const editEtsy = (data, id) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/etsy/${id}`,
    is_json_content_type: true,
  });
};
export const editShopbase = (data, id) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/shopbase/${id}`,
    is_json_content_type: true,
  });
};
export const editShopbaseNormal = (data, id) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/shopbase/normal/${id}`,
    is_json_content_type: true,
  });
};
export const editShopbaseToken = (data, id) => {
  return axios({
    method: 'PUT',
    data,
    url: `${prefix}/shopbase/${id}/token`,
    is_json_content_type: true,
  });
};
export const getShopbaseLink = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/redirect-shopbase`,
  });
};
export const getEtsyLink = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/redirect-etsy`,
  });
};

export const getShopifyLink = (data) => {
  return axios({
    method: 'POST',
    data: {
      ...data,
      isCallBack: 0,
    },
    url: `${prefix}/shopify`,
    is_json_content_type: true,
  });
};

//Step to connect store Shopify
//1: Check connect with Shopify
export const checkConnectWithShopify = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/shopify/infor`,
    is_json_content_type: true,
  });
};
//2: Get URL from link store Shopify
export const getUrlFromShopify = (data) => {
  return axios({
    method: 'POST',
    data,
    url: `${prefix}/shopify/infor`,
    is_json_content_type: true,
  });
};
//3:
//3.1 If login Gearment App + Shopify must use this API:
export const connectStoreShopifyCase1 = () => {
  return axios({
    method: 'POST',
    url: `${prefix}/shopify/check`,
    is_json_content_type: true,
  });
};

export const createShopifyStore = (data) => {
  return axios({
    method: 'POST',
    data: {
      ...data,
      isCallBack: 1,
    },
    url: `${prefix}/shopify`,
    is_json_content_type: true,
  });
};
export const getLinkStoreShopbaseToken = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/redirect-shopbase-token`,
    is_json_content_type: true,
  });
};

export const getLinkStoreShopbaseMerchant = (params = {}) => {
  return axios({
    method: 'GET',
    params,
    url: `${prefix}/redirect-shopbase-edit`,
    is_json_content_type: true,
  });
};

export const getShopifyLinkForUpdatingToken = (id) => {
  return axios({
    method: 'GET',
    url: `${prefix}/redirect-token-shopify/${id}`,
  });
};

export const updateShopifyToken = (data) => {
  return axios({
    method: 'POST',
    url: `${prefix}/update-token-shopify`,
    data,
    is_json_content_type: true,
  });
};
//Update token Amazon store
export const getAmazonLinkForUpdatingToken = () => {
  return axios({
    method: 'GET',
    url: `${prefix}/redirect-amazon-re-auth`,
  });
};

export const updateAmazonToken = (id,data) => {
  return axios({
    method: 'PUT',
    url: `${prefix}/amazon-re-auth/${id}`,
    is_json_content_type: true,
    data
  });
};
//Update token for Etsy store
export const updateTokenEtsyStore = () => {
  return axios({
    method: 'GET',
    url: `${prefix}/redirect-etsy-token`,
    is_json_content_type: true,
  });
};
export const updateEtsyToken = (id, data) => {
  return axios({
    method: 'PUT',
    id,
    data,
    url: `${prefix}/etsy/${id}/token`,
    is_json_content_type: true,
  });
};

export const updateShopifyStore = (id, data) => {
  return axios({
    method: 'PUT',
    url: `${prefix}/shopify/${id}`,
    data,
    is_json_content_type: true,
  });
};
