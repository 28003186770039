export const actionType = {
  REDIRECT_PAYPAL: {
    REQUEST: "REDIRECT_PAYPAL.REQUEST",
    SUCCESS: "REDIRECT_PAYPAL.SUCCESS",
    ERROR: "REDIRECT_PAYPAL.ERROR",
  },
  DEFAULT_PAYPAL_METHOD: {
    REQUEST: "DEFAULT_PAYPAL_METHOD.REQUEST",
    SUCCESS: "DEFAULT_PAYPAL_METHOD.SUCCESS",
    ERROR: "DEFAULT_PAYPAL_METHOD.ERROR",
  },
  DEFAULT_GATEWAY: {
    REQUEST: "DEFAULT_GATEWAY.REQUEST",
    SUCCESS: "DEFAULT_GATEWAY.SUCCESS",
    ERROR: "DEFAULT_GATEWAY.ERROR",
  },
  CANCEL_PAYPAL: {
    REQUEST: "CANCEL_PAYPAL.REQUEST",
    SUCCESS: "CANCEL_PAYPAL.SUCCESS",
    ERROR: "CANCEL_PAYPAL.ERROR",
  },
  DELETE_PAYPAL: {
    REQUEST: "DELETE_PAYPAL.REQUEST",
    SUCCESS: "DELETE_PAYPAL.SUCCESS",
    ERROR: "DELETE_PAYPAL.ERROR",
  },
  ADD_PAYPAL: {
    REQUEST: "ADD_PAYPAL.REQUEST",
    SUCCESS: "ADD_PAYPAL.SUCCESS",
    ERROR: "ADD_PAYPAL.ERROR",
  },
  LOGIN_PAYONEER: {
    REQUEST: "LOGIN_PAYONEER.REQUEST",
    SUCCESS: "LOGIN_PAYONEER.SUCCESS",
    ERROR: "LOGIN_PAYONEER.ERROR",
  },
  REGISTER_PAYONEER: {
    REQUEST: "REGISTER_PAYONEER.REQUEST",
    SUCCESS: "REGISTER_PAYONEER.SUCCESS",
    ERROR: "REGISTER_PAYONEER.ERROR",
  },
  DETAIL_PAYONEER: {
    REQUEST: "DETAIL_PAYONEER.REQUEST",
    SUCCESS: "DETAIL_PAYONEER.SUCCESS",
    ERROR: "DETAIL_PAYONEER.ERROR",
  },
  LIST_PAYPAL: {
    REQUEST: "LIST_PAYPAL.REQUEST",
    SUCCESS: "LIST_PAYPAL.SUCCESS",
    ERROR: "LIST_PAYPAL.ERROR",
  },
  LIST_PAYONEER: {
    REQUEST: "LIST_PAYONEER.REQUEST",
    SUCCESS: "LIST_PAYONEER.SUCCESS",
    ERROR: "LIST_PAYONEER.ERROR",
  },
  CANCEL_PAYONEER: {
    REQUEST: "CANCEL_PAYONEER.REQUEST",
    SUCCESS: "CANCEL_PAYONEER.SUCCESS",
    ERROR: "CANCEL_PAYONEER.ERROR",
  },
};
export function getListPaypal(params) {
  return {
    type: actionType.LIST_PAYPAL.REQUEST,
    params,
  };
}
export function setDefaultPaymentMethod(params) {
  return {
    type: actionType.DEFAULT_PAYPAL_METHOD.REQUEST,
    params,
  };
}
export function setDefaultGateway() {
  return {
    type: actionType.DEFAULT_GATEWAY.REQUEST,
  };
}
export function getListPayoneer(params) {
  return {
    type: actionType.LIST_PAYONEER.REQUEST,
    params,
  };
}
export function redirectPaypal() {
  return {
    type: actionType.REDIRECT_PAYPAL.REQUEST,
  };
}
export function getCancelPaypal() {
  return {
    type: actionType.CANCEL_PAYPAL.REQUEST,
  };
}
export function getProfitTransaction() {
  return {
    type: actionType.GET_PROFIT.REQUEST,
  };
}

export function getAddPaypal(params) {
  return {
    type: actionType.ADD_PAYPAL.REQUEST,
    params,
  };
}
export function getLoginPayoneer(params) {
  return {
    type: actionType.LOGIN_PAYONEER.REQUEST,
    params,
  };
}
export function getRegisterPayoneer(params) {
  return {
    type: actionType.REGISTER_PAYONEER.REQUEST,
    params,
  };
}
export function getDetailPayoneer(params) {
  return {
    type: actionType.DETAIL_PAYONEER.REQUEST,
    params,
  };
}
export function getCancelPayoneer() {
  return {
    type: actionType.CANCEL_PAYONEER.REQUEST,
  };
}
export function getDeletePaypal(id) {
  return {
    type: actionType.DELETE_PAYPAL.REQUEST,
    id,
  };
}
