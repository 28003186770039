import React, { Component } from 'react';
import Cookies from 'js-cookie';
import UserDropdown from './UserDropdown';
import NotificationDropdown from './NotificationDropdown';
import BtnCreateProduct from './BtnCreateProduct';
import { Wrapper } from '../styled';
class Navbar extends Component {
  handleChange = (lang) => {
    this.props.i18n.changeLanguage(lang);
    Cookies.set('lang', lang, { expires: 365 });
  };
  render() {
    const { is_mobile } = this.props;
    return (
      <Wrapper className="row align-items-center justify-content-end">
        <div className="flex-grow-1 d-none d-lg-block">{/* <Search /> */}</div>
        <div className=" d-flex justify-content-end align-items-center mr-3">
          <div>
            {/* <BtnCreateProduct /> */}
          </div>
          <div className="ant-dropdown-trigger btn btn-bell d-none d-md-block">
            <NotificationDropdown />
          </div>
          <div>
            <UserDropdown is_mobile={is_mobile} />
          </div>
        </div>
      </Wrapper>
    );
  }
}
export default (Navbar);
