import React, { Component } from "react";

import { connect } from "react-redux";
import { Button, Spin, notification } from "antd";
import BackToIcon from "images/chevron-down.svg";
import { getList as listFeedForms } from "../../../DataFeed/scenes/List/actions";
import { exportFeedFormGoogle } from "./actions";
import { listCampaignsToExport } from "../FeedAmazonData/actions";
import Form from "./components/FormFeedGoogle";
import { Wrapper } from "scenes/general.styled";
const listCampaignIdToExportFeedFormGoogle = localStorage.getItem(
  "listCampaignIdToExportFeedFormGoogle"
)
  ? JSON.parse(localStorage.getItem("listCampaignIdToExportFeedFormGoogle"))
  : [];
class index extends Component {
  componentDidMount() {
    let state = { ...this.props.history.location.state };
    let { listCampaignId = [] } = state;
    if (
      listCampaignId &&
      Array.isArray(listCampaignId) &&
      listCampaignId.length > 0
    ) {
      delete state.listCampaignId;
      localStorage.setItem(
        "listCampaignIdToExportFeedFormGoogle",
        JSON.stringify(listCampaignId)
      );
      this.props.history.replace({ ...this.props.history.location, state });
    } else {
      listCampaignId = listCampaignIdToExportFeedFormGoogle;
    }

    if (listCampaignId.length > 0) {
      this.props.listCampaignsToExport(listCampaignId);
    } else {
      notification.warn({
        message: "Please choose campaigns to export data feed Google.",
        duration: 5,
      });
    }
    this.props.listFeedForms({
      platformId: [4],
      status: 1,
    });
  }

  handleOnSubmit = (values) => {
    if (values?.listCampaignsToExport && values.listCampaignsToExport[0]) {
      const dataToExport = {
        colors: values.listCampaignsToExport[0]["colors"] || [],
        sizes: values.listCampaignsToExport[0]["sizes"] || [],
        products: values.listCampaignsToExport[0]["products"] || [],
        feedFormId: values.feedFormId || 0,
        campaigns: values.campaigns || [],
        isCombine: values.isCombine ? 1 : 0,
      };
      if (dataToExport.sizes.length === 0) {
        notification.warn({
          message: "Please select a size. to export",
          duration: 5,
        });
        return;
      }
      this.props.exportFeedFormGoogle(dataToExport);
    }
  };

  render() {
    const {
      pageName,
      data_feeds,
      campaigns,
      FormFeedGoogleForm,
      exportFeedFormGoogleLoading,
    } = this.props;
    return (
      <Wrapper
        pageName={pageName}
      >
        <Spin
          spinning={
            data_feeds.loading ||
            campaigns.loading ||
            exportFeedFormGoogleLoading
          }
        >
          <Form
            onSubmit={this.handleOnSubmit}
            listFeedForms={data_feeds.data}
            campaigns={campaigns.data}
            listCampaignsToExport={
              FormFeedGoogleForm?.listCampaignsToExport || []
            }
            initialValues={{
              feedFormId: `${data_feeds?.default?.id || 0}`,
              listCampaignsToExport: [],
              campaigns: campaigns.data
                ? campaigns.data.map((item) => item.id)
                : [],
            }}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    data_feeds: state.data_feed.list.data_feeds,
    campaigns: state.campaign.feed_form_amazon.listCampaignsToExport,
    exportFeedFormGoogleLoading:
      state.campaign.feed_form_google.exportFeedFormGoogle.loading,
    FormFeedGoogleForm: state.form?.FormFeedGoogle?.values || {},
  }),
  (dispatch) => ({
    listFeedForms: (params) => {
      dispatch(listFeedForms(params));
    },
    listCampaignsToExport: (params) => {
      dispatch(listCampaignsToExport(params));
    },
    exportFeedFormGoogle: (data) => {
      dispatch(exportFeedFormGoogle(data));
    },
  })
)(index);