import {
  BASE_WHITE,
  ERROR_500,
  GRAY_300,
  GRAY_50,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_300,
  SHADOW_XS,
} from "components/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  label, span, textarea {
    display: block;
  }
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 4px;
    color: ${GRAY_900};
    &.input {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_700};
    }
  }
  textarea {
    width: 100%;
    height: 128px;
    padding: 0;
    margin: 0;
    padding: 0 14px;
    padding-top: 12px;
    
    outline: none;
    color: ${GRAY_900};
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    line-height: 1.5;
    border-radius: 8px;
    &:focus-visible,
    &:focus {
      outline: none;
      border: 1px solid ${PRIMARY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS}, 0px 0px 0px 4px ${PRIMARY_100};
    }
    &:disabled {
      background: ${GRAY_50};
      border: 1px solid ${GRAY_300};
      box-shadow: 0px 1px 2px ${SHADOW_XS};
    }
  }
  .cta {
    margin-top: 16px;
    text-align: right;
  }

  .msg {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
    color: ${GRAY_600};
    &.error {
      color: ${ERROR_500};
    }
  }
`;
