/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FieldArray } from "redux-form";
import renderFulfillmentId from "./renderFulfillmentId";
import renderLink from "./renderLink";
import { issue_solution_options, issue_priority_options } from "utils/options";
import FieldNumber from "../fields/FieldNumber";
import { GMSelect, GMTextbox } from "components/InputFields";

const renderIssues = ({
  fields,
  listIssueCategory,
  changeFieldValue,
  listIssues,
}) => {
  return (
    <div>
      {fields.map((field, index) => (
        <div key={index} className="issue-item">
          <div className="title">
            <h4>
              Issue {index + 1}
            </h4>
            {fields.length > 1 ? (
              <span
                href="#"
                className="cta-remove"
                onClick={(evt) => {
                  evt.preventDefault();
                  fields.splice(index, 1);
                }}
              >
                Remove
              </span>
            ) : null}
          </div>
          <div className="container-fluid">
            <div className="row gap24 t24">
              <div className="fixed-cell-50p">
                <GMTextbox
                  name={`${field}.fulfillmentId`}
                  label="Order name"
                  placeholder="Order name"
                  component={renderFulfillmentId}
                  listIssues={listIssues}
                  changeField={(name, id) => {
                    changeFieldValue(`${field}.fulfillmentId`, name);
                    changeFieldValue(`${field}.orderId`, id);
                  }}
                />
              </div>
              <div className="fixed-cell-50p">
                <GMTextbox
                  disabled
                  label="Order Id"
                  name={`${field}.orderId`}
                />
              </div>
            </div>
            <div className="row gap24 t24">
              <div className="fixed-cell-25p">
                <GMSelect
                  name={`${field}.category`}
                  label="Issue type"
                  options={[
                    ...listIssueCategory.map((item) => ({
                      label: item.name,
                      value: item.id,
                    })),
                  ]}
                  showError
                  placeholder={"Issue type"}
                />
              </div>
              <div className="fixed-cell-25p">
                <GMTextbox
                  label="Effected item"
                  name={`${field}.itemEffect`}
                  placeholder={"Effected item"}
                  component={FieldNumber}
                  forGMInput
                  min={1}
                  max={99999}
                />
              </div>
              <div className="fixed-cell-25p">
                <GMSelect
                  label="Priority"
                  name={`${field}.priority`}
                  options={issue_priority_options}
                  placeholder={"Priority"}
                />
              </div>
              <div className="fixed-cell-25p">
                <GMSelect
                  label="Solution"
                  name={`${field}.solution`}
                  showError
                  options={issue_solution_options}
                  placeholder={"Solution"}
                />
              </div>
            </div>
            <div className="row t24 fz0">
              <GMTextbox
                label="Issue description"
                name={`${field}.detail`}
                textarea
                min={1}
                maxLength={500}
              />
            </div>

            <div className="row links t24">
              <label className="col-form-label">Link</label>
              <div className="link input-group">
                <FieldArray name={`${field}.links`} component={renderLink} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default renderIssues;
