import styled, { createGlobalStyle } from 'styled-components';

export const Wrapper = styled.div``;

export const GlobalStyle = createGlobalStyle`
 .ant-modal-header,
  .ant-modal-content {
    border-radius: 16px;
    border-bottom: none;

  }
`;
