import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Spin } from "antd";
import { listReport, exportOrders, exportReport } from "../../actions";
import money from "utils/money";
import FormFilter from "./components/FormFilter";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import getMomentInstance from "utils/moment";
import { OuterWrapper } from "./styled";
class List extends Component {
  constructor(props) {
    super(props);
    const { fromDate, toDate, ...query_params } = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        ...query_params,
        date_range: [
          fromDate ? getMomentInstance(fromDate) : null,
          toDate ? getMomentInstance(toDate) : null,
        ],
      },
    };
  }
  componentDidMount() {
    this.props.listReport(this.getParams());
  }

  handleOnExportReport = () => {
    this.props.exportReport({ ...this.getParams() });
  };
  handleOnExportOrders = (id) => {
    this.props.exportOrders(id, { ...this.getParams(), export: 1 });
  };

  getParams = () => {
    const params = {};
    const { date_range = null } = this.props.FormFilterListReportData;
    if (date_range) {
      params.fromDate = date_range[0] && date_range[0].format("YYYY-MM-DD");
      params.toDate = date_range[1] && date_range[1].format("YYYY-MM-DD");
    }
    if (!params['fromDate']) delete params['fromDate'];
    if (!params['toDate']) delete params['toDate'];
    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    return params;
  };

  fetchData = ({ date_range, ...options }) => {
    const params = {};
    if (date_range) {
      params.fromDate = date_range[0] && date_range[0].format("YYYY-MM-DD");
      params.toDate = date_range[1] && date_range[1].format("YYYY-MM-DD");
    }
    if (!params['fromDate']) delete params['fromDate'];
    if (!params['toDate']) delete params['toDate'];
    this.props.history.replace(
      window.location.pathname + "?" + queryString.stringify(params)
    );
    this.props.listReport({
      ...params,
    });
  };

  render() {
    const {
      listReportData,
      pageName,
      exportOrdersLoading,
      exportReportLoading,
    } = this.props;
    const columns = [
      {
        title: <b>#</b>,
        align: "center",
        dataIndex: "accounts_id",
        key: "accounts_id",
        render: (text) => (
          <Link to={`/setting/accounts/${text}/report`} className="mr-1">
            #{text}
          </Link>
        ),
      },
      {
        title: <b>NAME</b>,
        dataIndex: "accounts_name",
        align: "center",
        key: "accounts_name",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>ORDERS</b>,
        dataIndex: "accounts_order_count",
        align: "center",
        key: "accounts_order_count",
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>AMOUNT</b>,
        dataIndex: "order_revenue",
        align: "center",
        key: "order_revenue",
        render: (text) => text ? money(text.toFixed(2)) : text,
      },
      {
        title: <b>ACTION</b>,
        key: "action",
        align: "center",
        render: (text, record) => (
          <div className="ctarow">
            <Link
              to={`/setting/accounts/${record.accounts_id}/report`}
              className="btn btn-secondary mr-1"
              style={{ borderRadius: "5px" }}
            >
              Detail
            </Link>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                this.handleOnExportOrders(record.accounts_id);
              }}
              style={{ borderRadius: "5px" }}

            >
              <FontAwesomeIcon className="mr-2" icon={faDownload} />
              Orders
            </button>
          </div>
        ),
      },
    ];

    return (
      <OuterWrapper
        pageName={pageName}
        filter={
          <FormFilter
            handleAdvancedSubmit={this.fetchData}
            onExportReport={this.handleOnExportReport}
            initialValues={this.state.initial_filter_values}
            is_mobile={this.props.is_mobile}
            listReportData={listReportData}
          />
        }
      >
        <Spin spinning={exportOrdersLoading || exportReportLoading}>
          <Table
            rowKey="accounts_id"
            columns={columns}
            dataSource={listReportData.data}
            loading={listReportData.loading}
            scroll={{ x: 1000 }}
            pagination={false}
          />
        </Spin>
      </OuterWrapper>
    );
  }
}

export default connect(
  (state) => ({
    listReportData: state.setting.account.listReport,
    exportOrdersLoading: state.setting.account.exportOrders.loading,
    exportReportLoading: state.setting.account.exportReportAccount.loading,
    FormFilterListReportData: state.form.formFilterReport?.values || {},
  }),
  (dispatch) => ({
    listReport: (params) => {
      dispatch(listReport(params));
    },
    exportOrders: (staffId, params) => {
      dispatch(exportOrders(staffId, params));
    },
    exportReport: (params) => {
      dispatch(exportReport(params));
    },
  })
)(List);
