export const actionType = {
  LIST: {
    REQUEST: "WEBHOOKS.LIST.LIST.REQUEST",
    SUCCESS: "WEBHOOKS.LIST.LIST.SUCCESS",
    ERROR: "WEBHOOKS.LIST.LIST.ERROR",
  },
  DELETE: {
    REQUEST: "WEBHOOKS.DELETE.REQUEST",
    SUCCESS: "WEBHOOKS.DELETE.SUCCESS",
    ERROR: "WEBHOOKS.DELETE.ERROR",
  },
};

export function getList(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}
export function deleteWebhooks(id) {
  return {
    type: actionType.DELETE.REQUEST,
    id,
  };
}
