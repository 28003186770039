import React, { useState } from 'react';
import { Modal, Table, Tooltip } from 'antd';
import { listTrackings as getListTrackings } from 'apis/orders';
import getMomentInstance from 'utils/moment';
import { ReactComponent as TruckIcon } from 'images/icons/truck.svg';
import UPSLogo from 'images/icons/services/ups.svg';
import USPSLogo from 'images/icons/services/usps.svg';
import DHLLogo from 'images/icons/services/dhl.svg';
import FEDEXLogo from 'images/icons/services/fedex.svg';
import { ReactComponent as CopyIcon } from 'images/icons/copy-03.svg';
import { ReactComponent as WHIcon } from 'images/icons/warehouse.svg';
import { ReactComponent as ChevdownIcon } from 'images/icons/chevron-down.svg';
import {
  ColTracking,
  ModalTableWrapper,
  ShippingStatus,
  TagNewTracking,
} from 'scenes/Orders/scenes/List/styled';
import { shorten3dots } from 'utils/helper';
import { GMBadge } from 'components/Badge';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from 'images/icons/x-close.svg';
import { ReactComponent as Truck2Icon } from 'images/icons/truck.svg';
import { ReactComponent as RefreshIcon } from 'images/icons/refresh-cw-02.svg';
import { ReactComponent as CheckIcon } from 'images/icons/check.svg';
import {
  NO_COLOR,
  WARNING_100,
  WARNING_50,
  WARNING_500,
  WARNING_600,
  WARNING_700,
} from 'components/colors';
import FlipCard from 'components/Animation/FlipCard';

const logoList = {
  ups: <img src={UPSLogo} />,
  usps: <img src={USPSLogo} />,
  dhl: <img src={DHLLogo} />,
  fedex: <img src={FEDEXLogo} />,
  warehouse: <WHIcon className="warehouse" />,
  else: <TruckIcon className="shipping" />,
};

const getTypeOfService = (fullName, method, order) => {
  if (
    fullName?.toLowerCase()?.indexOf('dhl') > -1 ||
    fullName?.toLowerCase()?.indexOf('dhlglobalmailinternational') > -1 ||
    fullName?.toLowerCase()?.indexOf('dhlglobalmail') > -1
  ) {
    return 'dhl';
  }
  if (fullName?.toLowerCase()?.indexOf('usps') > -1) {
    return 'usps';
  }
  if (
    fullName?.toLowerCase()?.indexOf('fedex') > -1 ||
    fullName?.toLowerCase()?.indexOf('fedexcrossborder') > -1
  ) {
    return 'fedex';
  }
  if (fullName?.toLowerCase()?.indexOf('ups') > -1) {
    return 'ups';
  }
  if (!order?.orderBarcodeFiles?.[0]?.barcodeUrl) {
    return 'else';
  }
  return 'warehouse';
};

export const TRACKING_STATUS = {
  'pre-transit': {
    icon: <RefreshIcon />,
    className: 'pre-transit',
  },
  'in transit': {
    icon: <Truck2Icon />,
    className: 'in-transit',
  },
  delivered: {
    icon: <CheckIcon />,
    className: 'delivered',
  },
  'failed delivery': {
    icon: <CloseIcon />,
    className: 'failed-delivery',
  },
  'N/A (dhlecs - 238)': {
    className: 'N/A',
  },
};

export const getLabel = (order) => {
  return order?.shippingLabel || 'Pickup at warehouse';
};
export const detectShippingLabel = (order) => {
  const shippingMethod = !order?.orderBarcodeFiles?.[0]?.barcodeUrl
    ? order?.shippingMethod
    : 4;
  return (
    order &&
    order?.shippingLabel &&
    [4, 5].indexOf(parseInt(shippingMethod)) !== -1
  );
};

function RenderTrackingInfo({ order = {} }) {
  const [listTrackings, setListTrackings] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const getIcon = (order) => {
    const shippingMethod = !order?.orderBarcodeFiles?.[0]?.barcodeUrl
      ? order?.shippingMethod
      : 4;
    switch (parseInt(shippingMethod)) {
      case 0:
        return <span>Standard</span>;
      case 1:
        return <span>Fastship</span>;
      case 2:
        return <span>Ground</span>;
      case 3:
        return <span>FBA Amazon</span>;
      case 5:
      case 4:
        return (
          <div className="label-info">
            <span>{getLabel(order)}</span>
            {order?.orderBarcodeFiles?.[0]?.barcodeUrl && (
              <a
                href={
                  order?.orderBarcodeFiles?.[
                    order?.orderBarcodeFiles?.length - 1
                  ]?.barcodeUrl
                }
                target="_blank"
              >
                Shipping label
              </a>
            )}
          </div>
        );
      default:
        return <span>N/A</span>;
    }
  };
  const handleOnShowListTrackings = () => {
    setVisible(true);
    setLoading(true);
    getListTrackings(order?.id || 0)
      .then(({ code, data }) => {
        setLoading(false);
        if (code === 'success') {
          setListTrackings(data?.listTrackings || []);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const columns = [
    {
      title: <b>Tracking number</b>,
      dataIndex: 'trackingCode',
      key: 'trackingCode',
      render: (value, record, i) => {
        return (
          <ColTracking>
            <div className="tracking-info">
              <div className="shipping-group">
                <div className="icon">
                  {
                    logoList[
                      getTypeOfService(
                        record?.ship_carrier?.toLowerCase(),
                        parseInt(record?.shippingMethod),
                        record
                      )
                    ]
                  }
                </div>
                {record.ship_tracking_link ? (
                  <>
                    <div className="info-group">
                      <div className="type">
                        {getIcon(order)}
                        {i === 0 && (
                          <TagNewTracking className="tag-new-tracking">
                            New
                          </TagNewTracking>
                        )}
                        <Tooltip title="Tracking has not been updated in the last 7 days">
                          {record?.trackingShipment.no_update === 1 && (
                            <GMBadge
                              color={WARNING_500}
                              noDot={true}
                              border={NO_COLOR}
                              backgroundColor={WARNING_50}
                              className="badge"
                            >
                              No update
                            </GMBadge>
                          )}
                        </Tooltip>
                      </div>
                      <div className="tracking-code">
                        {/* <CopyIcon
                          className="copy"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              record?.ship_tracking_link
                            );
                          }}
                        /> */}
                        <FlipCard
                          className="flip-card copy"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              record?.ship_trackingcode
                            );
                          }}
                          front={<CopyIcon className="copy" />}
                          back={<CheckIcon className="copied" />}
                        />
                        <a
                          href={record?.ship_tracking_link || ''}
                          target={'_blank'}
                          rel="noopener noreferrer"
                        >
                          {record?.ship_trackingcode}
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  getIcon(order)
                )}
              </div>
            </div>
          </ColTracking>
        );
      },
    },
    {
      title: <b>Product type</b>,
      dataIndex: 'typeProduct',
      key: 'typeProduct',
    },
    {
      title: <b>Service</b>,
      dataIndex: 'ship_service',
      key: 'ship_service',
    },
    {
      title: <b>Created time</b>,
      dataIndex: 'time_created',
      key: 'time_created',
      render: (value, record) => {
        return (
          <div>
            {record.trackingShipment.trk_created_at > 0
              ? getMomentInstance(
                  null,
                  record.trackingShipment.trk_created_at,
                  true
                )
              : 'N/A'}
          </div>
        );
      },
    },
    {
      title: <b>Updated time</b>,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (value, record) => {
        return (
          <div>
            {record.trackingShipment.trk_updated_at > 0
              ? getMomentInstance(
                  null,
                  record.trackingShipment.trk_updated_at,
                  true
                )
              : 'N/A'}
          </div>
        );
      },
    },
    {
      title: <b>Status</b>,
      dataIndex: 'status',
      key: 'status',
      render: (value, record) => {
        if (record?.trackingShipment && record?.trackingShipment?.last_status) {
          return (
            <>
              {!record?.trackingShipment?.last_status.includes('N/A') ? (
                <ShippingStatus>
                  <GMBadge
                    noDot
                    className={classNames(
                      'badge',
                      TRACKING_STATUS[
                        record?.trackingShipment?.last_status?.toLowerCase()
                      ]?.className
                    )}
                  >
                    {
                      TRACKING_STATUS[
                        record?.trackingShipment?.last_status?.toLowerCase()
                      ]?.icon
                    }{' '}
                    {record?.trackingShipment?.last_status}
                  </GMBadge>
                </ShippingStatus>
              ) : (
                'N/A'
              )}
            </>
          );
        }
        return null;
      },
    },
  ];

  // test
  // order.trackingShipmentStatus = 'In Transit';
  return (
    <ColTracking>
      <div className="tracking-info">
        <div className="shipping-group">
          <div className="icon">
            {
              logoList[
                getTypeOfService(
                  order?.trackingWorkings?.[0]?.ship_carrier?.toLowerCase(),
                  parseInt(order?.shippingMethod),
                  order
                )
              ]
            }
          </div>
          <div className="info-group">
            {order?.trackingWorkings?.[0]?.ship_tracking_link ? (
              <>
                <div className="type">
                  {getIcon(order)}
                  {(order?.trackingWorkings || []).length > 1 &&
                    order?.trackingWorkings?.[0]?.ship_tracking_link && (
                      <TagNewTracking className="tag-new-tracking">
                        New
                      </TagNewTracking>
                    )}
                </div>
                <div className="tracking-code">
                  <FlipCard
                    className="flip-card"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        order?.trackingWorkings?.[0]?.ship_trackingcode
                      );
                    }}
                    front={<CopyIcon className="copy" />}
                    back={<CheckIcon className="copied" />}
                  />
                  <Tooltip
                    title={order?.trackingWorkings?.[0]?.ship_trackingcode}
                  >
                    <a
                      href={
                        order?.trackingWorkings?.[0]?.ship_tracking_link || ''
                      }
                      target={'_blank'}
                      rel="noopener noreferrer"
                    >
                      {shorten3dots(
                        order?.trackingWorkings?.[0]?.ship_trackingcode
                      )}
                    </a>
                  </Tooltip>
                  <ChevdownIcon
                    className="chevdown"
                    onClick={handleOnShowListTrackings}
                  />
                </div>
              </>
            ) : (
              getIcon(order)
            )}
            {/* 
              Pre-transit
              In transit
              Delivered
              Failed Delivery 
            */}
            {order?.trackingWorkings?.[0] &&
            order?.trackingWorkings?.[0]?.trackingShipment?.last_status
              .toLowerCase()
              ?.indexOf('n/a') === -1 ? (
              <ShippingStatus>
                <GMBadge
                  noDot
                  className={classNames(
                    'badge',
                    TRACKING_STATUS[
                      order?.trackingWorkings?.[0]?.trackingShipment?.last_status?.toLowerCase()
                    ]?.className
                  )}
                >
                  {
                    TRACKING_STATUS[
                      order?.trackingWorkings?.[0]?.trackingShipment?.last_status?.toLowerCase()
                    ]?.icon
                  }{' '}
                  {order?.trackingWorkings?.[0]?.trackingShipment?.last_status}
                </GMBadge>
              </ShippingStatus>
            ) : null}
            <Tooltip title="Tracking has not been updated in the last 7 days">
              {' '}
              {order?.trackingWorkings?.[0]?.trackingShipment.no_update ===
                1 && (
                <GMBadge
                  color={WARNING_600}
                  noDot={true}
                  border={WARNING_600}
                  backgroundColor={WARNING_50}
                  className="badge mt-2"
                  style={{ width: '62px' }}
                >
                  No update
                </GMBadge>
              )}
            </Tooltip>
          </div>
        </div>

        <Modal
          footer={null}
          visible={visible}
          onCancel={() => setVisible(false)}
          width={1200}
        >
          <ModalTableWrapper>
            <Table
              rowKey="id"
              scroll={{ x: 1000 }}
              columns={columns}
              dataSource={order?.trackingWorkings}
              loading={loading}
              pagination={false}
            />
          </ModalTableWrapper>
        </Modal>
      </div>
    </ColTracking>
  );
}
export default React.memo(RenderTrackingInfo);
