import { actionType as TYPE } from "./actions";

const initialState = {
  list: [],
  loading: false,
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.AT_RISK.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.AT_RISK.SUCCESS:
      return {
        ...state,
        list: action?.data?.orders || [],
        loading: false,
      };
    case TYPE.AT_RISK.ERROR:
      return {
        ...state,
        list: [],
        loading: false,
      };
    default:
      return state;
  }
}

export default ListReducer;
