import {
  ERROR_25,
  ERROR_300,
  ERROR_600,
  ERROR_700,
  GRAY_600,
  GRAY_900,
  PRIMARY_100,
  PRIMARY_50,
  PRIMARY_500,
  PRIMARY_600
} from "components/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
  color: ${GRAY_600};
  .icon {
    background: ${PRIMARY_100};
    border: 10px solid ${PRIMARY_50};
    border-radius: 50%;
    width: 66px;
    height: 66px;
    line-height: 66px;
    display: inline-block;
    text-align: center;
    line-height: 38px;
    svg {
      width: 28px;
      height: 28px;
      line-height: 1;
      stroke: ${PRIMARY_600};
      vertical-align: middle;
    }
  }
  .form-message {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 0;
    margin-top: 12px;
    span {
      font-weight: 500;
    }
  }
  .error-list {
    background: ${ERROR_25};
    border: 1px solid ${ERROR_300};
    border-radius: 12px;
    padding: 16px 18px;
    width: 100%;
    list-style: none;
    li {
      position: relative;
      padding-left: 34px;
      span {
        display: block;
        &.error {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: ${ERROR_700};
        }
        &.detail {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${ERROR_700};
        }
      }
    }
    svg {
      stroke: ${ERROR_600};
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .email {
    width: 100%;
  }
  .form-container {
    padding-top: 96px;
    form {
      width: 100%;
    }
  }
  .left-form {
    width: 480px;
    max-width: 100%;
    justify-content: normal;
  }
  .form {
    &-group {
      margin-bottom: 0;
    }
    &-header {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: ${GRAY_900};

      margin-top: 24px;
    }
    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: ${GRAY_600};
      margin-top: 12px;
    }
    &-content {
      margin-top: 32px;
    }
    &-resend-content {
      margin-top: 32px;
      text-align: center;
      a {
        cursor: pointer;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${PRIMARY_500};
      }
    }
  }
  button.cta {
    margin-top: 24px;
  }
  a.back-cta {
    display: inline-block;
    margin-top: 32px;
    color: ${GRAY_600};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    svg {
      width: 20px;
      height: 20px;
      stroke: ${GRAY_600};
      margin-right: 12px;
    }
  }
  .term-privacy {
    position: absolute;
    bottom: 55px;
    left: 0;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #475467;
    a {
      color: ${PRIMARY_500};
    }
  }
`;