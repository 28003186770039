import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';
import { getList } from 'apis/order_payment';
import { getListTopUp } from 'apis/top_up';

function* getListSaga(action) {
  const { params } = action;
  try {
    let response = yield call(getList, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST.ERROR, error })]);
  }
}

function* getListTopUpSaga(action) {
  const { params } = action;
  try {
    let response = yield call(getListTopUp, {
      ...params,
    });
    if (response.code === 'success') {
      yield put({ type: TYPE.LIST_TOPUP.SUCCESS, ...response });
    } else {
      yield put({ type: TYPE.LIST_TOPUP.ERROR, response });
    }
  } catch (error) {
    yield all([yield put({ type: TYPE.LIST_TOPUP.ERROR, error })]);
  }
}

function* watcher() {
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga);
  yield takeLatest(TYPE.LIST_TOPUP.REQUEST, getListTopUpSaga);
}

export default watcher;
