import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Table,
  Popconfirm,
  Spin,
  Tooltip,
  Pagination,
  Dropdown,
} from 'antd';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { getMomentInstance2 } from 'utils/moment';
import { getList, deleteStore, listStoreStatus } from './actions';
import {
  updateStore,
  detailStore,
  editShopBaseStore,
  editEbayStore,
  editEtsyStore,
  getLinkShopbaseToken,
  getLinkShopbaseMerchant,
  editStoreShopbaseToken,
  editStoreShopbaseNormal,
  getShopifyLinkForUpdatingToken,
  updateShopifyToken,
  updateShopifyStore,
  getLinkUpdateTokenEtsy,
  updateEtsyToken,
  updateAmazonToken,
  getLinkUpdateTokenAmazon,
} from '../Edit/actions';
import {
  createStore,
  getEbayLink,
  getEtsyLink,
  getShopBaseLink,
  createEbayStore,
  createEtsyStore,
  createShopBaseStore,
  getShopifyLink,
  getAmazonLink,
  createAmazonStore,
  checkConnectWithShopifyAction,
  getUrlShopifyAction,
  connectToShopifyAction,
} from '../Create/actions';
import { listAccount } from 'scenes/Settings/scenes/Account/actions';
import FormShopify from './components/FormShopify';
import FormAmazon from './components/FormAmazon';
import FormWordpress from './components/FormWordpress';
import FormShopbase from './components/FormShopbase';
import FormOrderDesk from './components/FormOrderDesk';
import FormEbay from './components/FormEbay';
import FormEtsy from './components/FormEtsy';
import FormDefault from './components/FormDefault';
import FormFilter from './components/FormFilter';
import {
  listPlatformKeyToDisableAction,
  list_store_error,
} from 'utils/options';
import {
  getOptionLabel,
  options_backend_type,
  options_active,
} from 'utils/options';
import { reset } from 'redux-form';
import { checkFunctionalPermission, setCookie } from 'utils/helper';
import RenderCopyWordpressWebhook from 'utils/Form/RenderCopyWordpressWebhook';
import RenderUrlStore from 'utils/Form/RenderUrlStore';
import { ReactComponent as ArrowLeft } from 'images/icons/arrow-left.svg';

import { Wrapper, GlobalStyle, WrapperIconTooltip } from './styled';
import { GMButton } from 'components/Button';

import { ReactComponent as LinkIcon } from 'images/icons/link-03.svg';
import { ReactComponent as MoreAction } from 'images/icons/dots-vertical.svg';
import Filter from 'components/Filter';
import Step1CreateStore from './components/Step1CreateStore';
import GMNotify from 'components/Notification';
import { MoreActionStore } from 'scenes/Campaigns/scenes/List/styled';
import { ContextMenuWrapper } from 'scenes/BillingPaymentAccount/v2/styled';
import { EBAY_INTEGRATE } from 'web.config';

class List extends Component {
  constructor(props) {
    super(props);
    const { createdAtFrom, createdAtTo, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      visible: true,
      storeType: null,
      storeName: null,
      storeFormName: null,
      storeId: null,
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        backendType: 'all',
        status: 2,
        ...query_params,
      },
      currAction: '',
      currStoreId: 0,
      currInitialValue: {},
      dataToUpdateOrCreate: {},
      onGoingMethod: '',
      stepCreateStore: 1,
      redirectFromDashboard: false,
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    this.props.listStoreStatus();
    //Update token for Etsy store
    if (
      window.location.href.includes('etsy_callback_token') &&
      params.code &&
      params.state
    ) {
      const getIdUpdateEtsyToken = JSON.parse(
        localStorage.getItem('idEtsyToken')
      );
      const updateEtsyToken = JSON.stringify({
        code: params.code,
        state: params.state,
        error: params?.error,
        error_description: params?.error_description,
      });
      this.props.updateEtsyToken(getIdUpdateEtsyToken?.id, updateEtsyToken);
    }
    //Update token for Amz store
    if (
      window.location.href.includes('callback_amazon') &&
      params.state.includes('reauth')
    ) {
      const getIdUpdateAmazonToken = JSON.parse(
        localStorage.getItem('idAmazonToken')
      );
      const updateAmazonToken = JSON.stringify({
        code: params.spapi_oauth_code,
        sellingPartnerId: params.selling_partner_id,
      });
      this.props.updateAmazonToken(
        getIdUpdateAmazonToken?.id,
        updateAmazonToken
      );
    }
    //Callback shopify step 2.1:
    if (
      window.location.href.includes('callback_shopify') &&
      params.hmac &&
      params.shop &&
      params.session
    ) {
      this.props.checkConnectWithShopifyAction({
        hmac: params.hmac,
        shop: params.shop,
      });
    }

    //Callback shopify step 2.2:
    if (
      window.location.href.includes('callback_shopify') &&
      params.hmac &&
      params.shop &&
      params.code
    ) {
      this.props.getUrlShopifyAction({
        hmac: params.hmac,
        shop: params.shop,
        code: params.code,
        host: params.host,
        timestamp: params.timestamp,
      });
    }

    if (params?.type) {
      this.setState({ storeType: params?.type });
      this.setState({ stepCreateStore: 2 });
      this.setState({ currAction: 'create' });
      this.setState({ redirectFromDashboard: true });
    }
    //Create amazon
    if (
      window.location.href.includes('callback_amazon') &&
      localStorage.getItem('dataAmazon') &&
      params.spapi_oauth_code &&
      params.selling_partner_id
    ) {
      const getDataAmazon = JSON.parse(localStorage.getItem('dataAmazon'));
      if (getDataAmazon && Object.keys(getDataAmazon).length) {
        this.props.createAmazonStore({
          ...getDataAmazon,
          code: params.spapi_oauth_code,
          sellingPartnerId: params.selling_partner_id,
        });
      }
      localStorage.removeItem('dataAmazon');
    }
    //Create Ebay
    if (
      typeof window?.location?.href == 'string' &&
      window.location.href.includes('callback_ebay') &&
      localStorage.getItem('dataEbay') &&
      params.username
    ) {
      const getDataEbay = JSON.parse(localStorage.getItem('dataEbay'));
      if (getDataEbay && Object.keys(getDataEbay).length) {
        this.props.createEbayStore({
          ...getDataEbay,
          username: params.username,
        });
      }
      localStorage.removeItem('dataEbay');
    }

    //Create etsy store
    if (
      typeof window?.location?.href == 'string' &&
      window.location.href.includes('etsy_callback_add') &&
      localStorage.getItem('dataEtsy') &&
      params.code &&
      params.state
    ) {
      const getDataEtsy = JSON.parse(localStorage.getItem('dataEtsy'));
      if (getDataEtsy && Object.keys(getDataEtsy).length) {
        this.props.createEtsyStore({
          ...getDataEtsy,
          code: params.code,
          state: params.state,
          error: params.error,
          error_description: params.error_description,
        });
        localStorage.removeItem('dataEtsy');
      }
    }

    //Create shopbase
    if (
      typeof window?.location?.href == 'string' &&
      window.location.href.includes('callback_shopbase') &&
      localStorage.getItem('dataShopbase') &&
      params.code &&
      params.hmac &&
      params.timestamp &&
      params.shop
    ) {
      const getDataShopbase = JSON.parse(localStorage.getItem('dataShopbase'));
      if (getDataShopbase && Object.keys(getDataShopbase).length) {
        this.props.createShopbaseStore({
          ...getDataShopbase,
          code: params.code,
          hmac: params.hmac,
          shop: params.shop,
          timestamp: params.timestamp,
        });
      }
      localStorage.removeItem('dataShopbase');
    }
    //Update shopbase by merchant
    if (
      typeof window?.location?.href == 'string' &&
      window.location.href.includes('callback_shopbase_edit') &&
      params.host &&
      params.code &&
      params.hmac &&
      params.timestamp &&
      params.shop
    ) {
      const getDataShopbaseUpdateMerchant = JSON.parse(
        localStorage.getItem('dataUpdateShopbaseMerchant')
      );
      const updateShopbase = JSON.stringify({
        ...getDataShopbaseUpdateMerchant,
        code: params.code,
        hmac: params.hmac,
        shop: params.shop,
        timestamp: params.timestamp,
        host: params.host,
      });
      this.props.editShopBaseStore(
        getDataShopbaseUpdateMerchant.id,
        updateShopbase
      );
      localStorage.removeItem('dataUpdateShopbaseMerchant');
    }
    //Update shopbase by Token
    if (
      typeof window?.location?.href == 'string' &&
      window.location.href.includes('callback_shopbase_update_scope') &&
      params.code &&
      params.hmac &&
      params.timestamp &&
      params.shop
    ) {
      const getIdUpdateShopbaseToken = JSON.parse(
        localStorage.getItem('idShopbaseToken')
      );
      const updateShopbaseToken = JSON.stringify({
        code: params.code,
        hmac: params.hmac,
        shop: params.shop,
        timestamp: params.timestamp,
      });
      this.props.editStoreShopbaseToken(
        getIdUpdateShopbaseToken.id,
        updateShopbaseToken
      );
      localStorage.removeItem('idShopbaseToken');
      localStorage.removeItem('onGoingMethod');
    }

    //Update shopify token
    if (
      typeof window?.location?.href == 'string' &&
      window.location.href.includes('callback_token_shopify') &&
      params.host &&
      params.code &&
      params.hmac &&
      params.timestamp &&
      params.shop
    ) {
      this.props.updateShopifyToken({
        code: params.code,
        hmac: params.hmac,
        shop: params.shop,
        timestamp: params.timestamp,
        host: params.host,
      });
    }

    //Edit shopify store

    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
    this.props.listAccount();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const params = queryString.parse(this.props.location.search);
    window.scroll(0, 0);
    //Get link ebay
    if (this.props.linkEbay) {
      localStorage.setItem(
        'dataEbay',
        JSON.stringify({
          ...this.state.dataToUpdateOrCreate,
          sessionId: this.props.linkEbay.sessionID,
        })
      );
    }
    if (this.props.linkEbay?.url?.length > 0) {
      window.location.replace(this.props.linkEbay.url);
    }
    //Get link etsy for create
    if (this.props.linkEtsy) {
      localStorage.setItem(
        'dataEtsy',
        JSON.stringify({
          ...this.state.dataToUpdateOrCreate,
          token: {
            oauth_token: this.props.linkEtsy?.token?.oauth_token,
            oauth_token_secret: this.props.linkEtsy?.token?.oauth_token_secret,
          },
        })
      );
    }
    if (this.props.linkEtsy?.url?.length > 0) {
      window.location.replace(this.props.linkEtsy.url);
    }
    //Get link etsy for update token
    if (this.props.getLinkEstyToken?.url?.length > 0) {
      localStorage.setItem(
        'idEtsyToken',
        JSON.stringify({
          id: this.state.storeId,
        })
      );
      window.location.replace(this.props.getLinkEstyToken?.url);
    }

    //Get link amazon for update token
    if (this.props.getLinkAmazonToken) {
      localStorage.setItem(
        'idAmazonToken',
        JSON.stringify({
          id: this.state.storeId,
        })
      );
      window.location.replace(this.props.getLinkAmazonToken?.data);
    }

    //Get link shopbase
    if (this.props.linkShopbase) {
      localStorage.setItem(
        'dataShopbase',
        JSON.stringify({
          ...this.state.dataToUpdateOrCreate,
        })
      );
    }
    if (this.props.linkShopbase?.url?.length > 0) {
      window.location.replace(this.props.linkShopbase.url);
    }
    //Get link shopbase merchant
    if (this.props.linkShopbaseMerchant) {
      localStorage.setItem(
        'dataUpdateShopbaseMerchant',
        JSON.stringify({
          ...this.state.dataToUpdateOrCreate,
          id: this.state.storeId,
        })
      );
      localStorage.setItem('onGoingMethod', 'update');
    }
    if (this.props.linkShopbaseMerchant?.url?.length > 0) {
      window.location.replace(this.props.linkShopbaseMerchant.url);
    }
    //Update Shopbase (Token)
    if (this.props.linkShopbaseToken) {
      localStorage.setItem(
        'idShopbaseToken',
        JSON.stringify({
          id: this.state.storeId,
        })
      );
      localStorage.setItem('onGoingMethod', 'updateToken');
    }
    if (this.props.linkShopbaseToken?.url?.length > 0) {
      window.location.replace(this.props.linkShopbaseToken.url);
    }
    // Redirect when api step 1 successfully
    if (this.props.linkShopify?.url) {
      window.location.replace(this.props.linkShopify.url);
    }

    //Redirect when api step 2 successfully
    if (this.props.linkUrlShopify.url && this.props.linkUrlShopify.url !== '') {
      window.location.replace(this.props.linkUrlShopify.url);
    }

    //Shopify step 3:
    const isGetUrlSuccessful = localStorage.getItem('isGetUrlSuccessful');
    if (isGetUrlSuccessful === 'true') {
      this.props.connectToShopifyAction();
    }
    //Create Amazon
    if (this.props.linkAmazon?.data) {
      localStorage.setItem(
        'dataAmazon',
        JSON.stringify({
          ...this.state.dataToUpdateOrCreate,
        })
      );
      window.location.replace(this.props.linkAmazon.data);
    }

    //Update shopify
    if (this.props._updateShopifyStore.data?.url) {
      localStorage.setItem(
        'dataUpdateShopify',
        JSON.stringify({
          ...this.state.dataToUpdateOrCreate,
        })
      );
      window.location.replace(this.props._updateShopifyStore.data.url);
    }
    if (this.props.shopifyLinkForUpdatingToken?.data?.url) {
      window.location.replace(this.props.shopifyLinkForUpdatingToken.data.url);
    }
    if (
      JSON.stringify(prevProps.detailStoreData) !==
      JSON.stringify(this.props.detailStoreData)
    ) {
      this.setState({
        currInitialValue: {
          name: this.props.detailStoreData.name || '',
          showName: this.props.detailStoreData.showName || '',
          url: this.props.detailStoreData.url || '',
          status: this.props.detailStoreData.status === 1,
          staffId: this.props.detailStoreData.staffId
            ? this.props.detailStoreData.staffId.toString().split(',')
            : [],
          amazonMarketPlaceId:
            this.state.storeType === 'ebay'
              ? this.props.detailStoreData.amazonMarketPlaceId
                ? this.props.detailStoreData.amazonMarketPlaceId.toString()
                : ''
              : this.props.detailStoreData.amazonMarketPlaceId
              ? this.props.detailStoreData.amazonMarketPlaceId
                  .toString()
                  .split(',')
              : [],
          autoSync: this.props.detailStoreData.autoSync === 1,
          authToken: this.props.detailStoreData.authToken || '',
          storeApiKey: this.props.detailStoreData.storeApiKey || '',
          storeSecretKey: this.props.detailStoreData.storeSecretKey || '',
          folderId: this.props.detailStoreData.folderId || '',
        },
      });
    }
  }
  fetchData = ({ date_range, page = 1, perPage = 50, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };
    if (params['backendType'] === 'all') delete params['backendType'];
    if (parseInt(params['status']) === 2) delete params['status'];
    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getList({ ...params, 'with[]': 'lastScheduleTask' });
  };

  deleteStore = (id) => {
    this.props.deleteStore(id);
  };

  showFormCreateStore = (type, name, formName) => {
    this.setState({
      storeType: type ? type : null,
      currAction: 'create',
      storeFormName: formName,
      storeName: name,
      currInitialValue: {
        amazonMarketPlaceId: '0',
      },
    });
  };
  showFormUpdateStore = (backendType, id) => {
    this.setState({
      storeType: backendType,
      storeId: id,
      currAction: 'update',
      stepCreateStore: 2,
    });
    this.props.detailStore(id);
    this.props.listAccount();
  };
  //Handle get link shopbase update token
  handleUpdateShopbaseToken = (id) => {
    this.setState({
      storeId: id,
    });
    this.props.getLinkShopbaseToken({ id: id });
  };

  //Handle get link update token Etsy

  getEtsyLinkForUpdatingToken = (id) => {
    this.setState({
      storeId: id,
    });
    this.props.getLinkUpdateTokenEtsy();
  };

  //Handle get link update token Etsy

  getAmazonLinkForUpdatingToken = (id) => {
    this.setState({
      storeId: id,
    });
    this.props.getLinkUpdateTokenAmazon();
  };

  //
  hideForm = () => {
    this.props.resetForm(this.state.storeFormName);
    this.setState({ storeType: null, storeFormName: null });
    this.setState({ stepCreateStore: 1 });
  };
  updateOrCreateStore = (values) => {
    if (!this.state.storeType) return;
    const dataToUpdateOrCreate = {
      name: values.name ? values.name.trim() : '',
      showName: values.showName ? values.showName.trim() : '',
      url: values.url || '',
      status: values.status === true ? 1 : 0,
      staffId: values.staffId ? values.staffId : [],
      amazonMarketPlaceId: values.amazonMarketPlaceId
        ? values.amazonMarketPlaceId
        : 0,
      ebayMarketplaceId: values.amazonMarketPlaceId
        ? values.amazonMarketPlaceId
        : 0,
      autoSync: values.autoSync === true ? 1 : 0,
      authToken: values.authToken ? values.authToken.trim() : '',
      storeApiKey: values.storeApiKey ? values.storeApiKey.trim() : '',
      storeSecretKey: values.storeSecretKey ? values.storeSecretKey.trim() : '',
      folderId: values.folderId ? values.folderId.trim() : '',
      backendType: this.state.storeType,
    };
    const dataToUpdateEbay = {
      name: values.name || '',
      showName: values.showName || '',
      url: values.url || '',
      status: values.status === true ? 1 : 0,
      staffId: values.staffId ? values.staffId : [],
      ebayMarketplaceId: values.amazonMarketPlaceId
        ? values.amazonMarketPlaceId
        : [],
      autoSync: values.autoSync === true ? 1 : 0,
    };
    const dataToUpdateEtsy = {
      showName: values.showName || '',
      url: values.url || '',
      status: values.status === true ? 1 : 0,
      staffId: values.staffId ? values.staffId : [],
      autoSync: values.autoSync === true ? 1 : 0,
    };
    const dataToUpdateShopbase = {
      name: values.name || '',
      showName: values.showName || '',
      url: values.url || '',
      status: values.status === true ? 1 : 0,
      staffId: values.staffId ? values.staffId : [],
      autoSync: values.autoSync === true ? 1 : 0,
    };
    this.setState({ dataToUpdateOrCreate: dataToUpdateOrCreate });
    if (this.state.currAction === 'update' && this.state.storeId) {
      let called = false;
      if (this.state.storeType === 'ebay') {
        called = true;
        this.props.editEbayStore(this.state.storeId, dataToUpdateEbay);
      }
      if (this.state.storeType === 'etsy') {
        called = true;
        this.props.editEtsyStore(this.state.storeId, dataToUpdateEtsy);
      }
      if (this.state.storeType === 'shopify') {
        called = true;
        this.props.updateShopifyStore(this.state.storeId, dataToUpdateOrCreate);
      }
      if (this.state.storeType === 'shopbase') {
        called = true;
        let callledUpdateStoreShopbase = false;
        let nameUpdate = dataToUpdateShopbase.name;
        let nameDetail = this.props.detailStoreData.name;
        if (nameDetail !== nameUpdate) {
          callledUpdateStoreShopbase = true;
          this.setState({ onGoingMethod: 'update' });
          this.props.getLinkShopbaseMerchant({
            id: this.state.storeId,
            name: dataToUpdateShopbase.name,
            showName: dataToUpdateShopbase.showName,
            staffId: dataToUpdateShopbase.staffId,
            status: dataToUpdateShopbase.status,
            autoSync: dataToUpdateShopbase.autoSync,
          });
        }
        callledUpdateStoreShopbase ||
          this.props.editStoreShopbaseNormal(
            this.state.storeId,
            dataToUpdateOrCreate
          );
      }
      called ||
        this.props.updateStore(this.state.storeId, dataToUpdateOrCreate);
      this.setState({ storeType: null });
    } else if (this.state.currAction === 'create') {
      if (
        this.state.storeType === 'wordpress' ||
        this.state.storeType === 'orderdesk' ||
        this.state.storeType === 'store_default'
      )
        this.props.createStore(dataToUpdateOrCreate);
      if (this.state.storeType === 'ebay') {
        this.props.getEbayLink({
          ebayMarketplaceId: dataToUpdateOrCreate.amazonMarketPlaceId,
          url: dataToUpdateOrCreate.url,
        });
        setCookie(
          EBAY_INTEGRATE,
          `GM_APP_EBAY_${Math.floor(100000000 + Math.random() * 900000000)}`
        );
        localStorage.setItem('EBAY_INTEGRATE', 'EBAY_INTEGRATE');
      }
      if (this.state.storeType === 'etsy') {
        this.props.getEtsyLink({ url: dataToUpdateOrCreate.url });
      }
      if (this.state.storeType === 'shopbase') {
        this.props.getShopBaseLink({
          name: dataToUpdateOrCreate.name,
        });
      }
      if (this.state.storeType === 'shopify') {
        this.props.getShopifyLink(dataToUpdateOrCreate);
      }
      if (this.state.storeType === 'amazon') {
        this.props.getAmazonLink(dataToUpdateOrCreate);
      }
      this.setState({ storeType: null });
    }

    this.props.resetForm(this.state.storeFormName);
    this.setState({ stepCreateStore: 1 });
  };
  handleOnSubmitFilter = (values) => {
    const params = {};
    if (values.keyword) {
      params['keyword'] = values.keyword;
    }
    if (values.status) {
      params['status'] = values.status;
    }
    if (values.sortType) {
      params['sortType'] = values.sortType;
    }
    if (values.backendType) {
      params['backendType'] = values.backendType;
    }
    if (values.sortBy) {
      params['sortBy'] = values.sortBy;
    }
    this.fetchData(params);
  };

  render() {
    const {
      stores,
      pageName,
      updateStoreLoading,
      updateStoreEbayLoading,
      updateStoreEtsyLoading,
      updateStoreShopbaseLoading,
      updateStoreShopbaseTokenLoading,
      updateStoreShopbaseNormalLoading,
      getLinkEstyTokenLoading,
      createStoreLoading,
      detailStoreLoading,
      deleteStoreLoading,
      linkEbayLoading,
      linkEtsyLoading,
      linkShopbaseLoading,
      linkShopifyLoading,
      connectStoreShopifyLoading,
      shopifyLinkForUpdatingToken,
      _updateShopifyToken,
      accounts,
      _updateShopifyStore,
      profile,
      storeStatus,
      getLinkAmazonTokenLoading,
      updateAmazonTokenLoading,
      createAmazonStoreLoading,
    } = this.props;

    const {
      storeType,
      initial_filter_values,
      currAction,
      stepCreateStore,
    } = this.state;

    const columns = [
      {
        title: <b>ID</b>,
        dataIndex: 'id',
        align: 'left',
        key: 'id',
        width: '10%',
        render: (text) => <span>{text}</span>,
      },
      {
        title: <b>Store name</b>,
        dataIndex: 'showName',
        align: 'left',
        key: 'showName',
        render: (text, record) => {
          const listManagerId = record?.staffId
            ? record?.staffId.split(',')
            : [];
          const listManagers = this.props.accounts.data.filter((account) =>
            listManagerId.includes(`${account.id}`)
          );

          return (
            <div>
              <span>{text}</span>
              {listManagers.length !== 0 ? (
                <WrapperIconTooltip className="d-flex row">
                  <div>
                    <Tooltip
                      placement="topRight"
                      title={
                        <div>
                          {listManagers.map((manager, index) => (
                            <p key={index}>{manager.name}</p>
                          ))}
                        </div>
                      }
                    >
                      <div className="d-flex row ml-3">
                        Total staff:
                        {listManagers
                          .filter((_, index) => index < 1)
                          .map((_, index) => (
                            <p
                              key={index}
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                              className="ml-1"
                            >
                              {listManagers.length < 10
                                ? '0' + listManagers.length
                                : ''}
                            </p>
                          ))}
                      </div>
                    </Tooltip>
                  </div>
                </WrapperIconTooltip>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
      {
        title: <b>Merchant</b>,
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        render: (text, record) => (
          <>
            <p>{text}</p>
            <RenderUrlStore store={record} />
            {record?.backendType === 'wordpress' && (
              <RenderCopyWordpressWebhook store={record} />
            )}
          </>
        ),
      },
      {
        title: <b>Sync status</b>,
        align: 'center',
        width: '10%',
        render: (record) => {
          return (
            <div>
              {parseInt(record.isDefault) === 1 && <div>Default</div>}
              <div>
                Auto sync:{' '}
                {parseInt(record.autoSync) === 1 ? (
                  <span className="text-success">Yes</span>
                ) : (
                  <span className="text-danger">No</span>
                )}
              </div>
              <div>
                {record.backendType === 'shopbase' && (
                  <Link
                    onClick={() => this.handleUpdateShopbaseToken(record.id)}
                  >
                    Update token
                  </Link>
                )}
                {record.backendType === 'shopify' && (
                  <Link
                    onClick={() =>
                      this.props.getShopifyLinkForUpdatingToken(record.id)
                    }
                  >
                    Update token
                  </Link>
                )}
                {record.backendType === 'amazon' && (
                  <Link
                    onClick={() =>
                      this.getAmazonLinkForUpdatingToken(record.id)
                    }
                  >
                    Update token
                  </Link>
                )}
                {record.backendType === 'etsy' && (
                  <Link
                    onClick={() => this.getEtsyLinkForUpdatingToken(record.id)}
                  >
                    Update token
                  </Link>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: <b>Platform</b>,
        dataIndex: 'backendType',
        align: 'center',
        key: 'backendType',
        width: '10%',
        render: (value) =>
          getOptionLabel(options_backend_type, value, false, 'N/A'),
      },
      {
        title: <b>Created time</b>,
        dataIndex: 'createdAt',
        align: 'center',
        key: 'createdAt',
        render: (text) =>
          getMomentInstance2({ unixDateTime: text, isDateTimFormat: true }),
      },

      {
        title: <b>Status</b>,
        dataIndex: 'status',
        align: 'center',
        key: 'status',
        render: (value) => getOptionLabel(options_active, value),
      },
      // {
      //   title: <b>Link</b>,
      //   dataIndex: "url",
      //   align: "center",
      //   key: "url",
      // },
      {
        title: <b></b>,
        key: 'action',
        align: 'right',
        render: (value, row) => {
          return !listPlatformKeyToDisableAction.includes(
            value.backendType || ''
          ) ? (
            <MoreActionStore>
              <Dropdown
                placement="topLeft"
                overlay={
                  <ContextMenuWrapper className="p-3">
                    <div
                      onClick={() => {
                        this.showFormUpdateStore(value.backendType, value.id);
                      }}
                      className="cursor-pointer"
                    >
                      Edit
                    </div>
                    <div className="delete cursor-pointer">
                      {checkFunctionalPermission(
                        profile,
                        'deleteStorePermission'
                      ) && (
                        <Popconfirm
                          title="Are you sure to delete this record?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => this.props.deleteStore(value.id)}
                          placement="bottom"
                        >
                          Delete
                        </Popconfirm>
                      )}
                    </div>
                    {row.url && (
                      <a
                        href={row.url}
                        target="_blank"
                        className="goto-shop cursor-pointer"
                      >
                        Go to shop
                      </a>
                    )}
                  </ContextMenuWrapper>
                }
                trigger={['click']}
              >
                <MoreAction className="context-menu" />
              </Dropdown>
            </MoreActionStore>
          ) : (
            'No action'
          );
        },
      },
    ];
    const modal_prop = {
      width: 800,
    };
    //Handle create modal
    const submitHandler = (values) => {
      this.setState({ stepCreateStore: 2, storeType: values?.type || null });
    };
    const backPrevForm = () => {
      this.setState({ stepCreateStore: 1 });
    };
    return (
      <>
        <div>
          {storeStatus?.data.length > 0 && (
            <div
              className="py-3"
              style={{ paddingLeft: '32px', paddingRight: '32px' }}
            >
              <GMNotify
                type="infor"
                title={<div className="title">Notification</div>}
                desc={
                  <div>
                    {storeStatus?.data.map((notification, index) => (
                      <div key={index}>
                        <p className="mb-0">
                          Store: {notification.showName}
                          <span className="ml-1">({notification.url})</span> -
                          Error code: {notification.expiredAt}{' '}
                          {list_store_error[notification.expiredAt]}
                        </p>
                        <p>Please check these stores!</p>
                      </div>
                    ))}
                  </div>
                }
              />
            </div>
          )}
          <Wrapper
            pageName={pageName}
            ctas={
              checkFunctionalPermission(profile, 'createStorePermission') && (
                <div className="connect-icon">
                  <GMButton type="button" onClick={this.showFormCreateStore}>
                    <LinkIcon />
                    Connect store
                  </GMButton>
                </div>
              )
            }
            filter={
              <Filter
                placeholder="Search by store name"
                className="filter-form"
                onSubmit={(values) => {
                  this.fetchData({
                    keyword: (values.search || '').trim(),
                    backendType: (values.backendType || '').trim(),
                    status: (values.status || '').trim(),
                    page: 1,
                  });
                }}
                afterAddon={
                  <FormFilter
                    profile={profile}
                    initialValues={initial_filter_values}
                    listStoreStatus={this.props.storeStatus.data}
                    handleAdvancedSubmit={this.fetchData}
                    stores={stores}
                    is_mobile={this.props.is_mobile}
                  />
                }
              />
            }
          >
            <Table
              className="mr-3 ml-3"
              rowKey="id"
              sticky
              columns={columns}
              dataSource={stores.data}
              loading={
                stores.loading ||
                accounts.loading ||
                deleteStoreLoading ||
                updateStoreLoading ||
                createStoreLoading ||
                updateStoreEbayLoading ||
                updateStoreEtsyLoading ||
                updateStoreShopbaseLoading ||
                linkEbayLoading ||
                linkEtsyLoading ||
                linkShopbaseLoading ||
                linkShopifyLoading ||
                connectStoreShopifyLoading ||
                updateStoreShopbaseTokenLoading ||
                updateStoreShopbaseNormalLoading ||
                _updateShopifyToken.loading ||
                _updateShopifyStore.loading ||
                shopifyLinkForUpdatingToken.loading ||
                getLinkEstyTokenLoading ||
                getLinkAmazonTokenLoading ||
                updateAmazonTokenLoading ||
                createAmazonStoreLoading
              }
              scroll={{ x: 1000 }}
              pagination={
                !this.props.is_mobile
                  ? {
                      showTotal: (total) => `Total ${total} items`,
                      total: stores.total,
                      pageSize: 20,
                      current: stores.params.page,
                      showSizeChanger: false,
                      onChange: (page, perPage) =>
                        this.fetchData({ ...stores.params, page, perPage }),
                    }
                  : false
              }
            />
            {this.props.is_mobile && (
              <div className="d-flex justify-content-end align-items-center">
                <div>
                  <Pagination
                    size="small"
                    showSizeChanger={false}
                    className="pagination"
                    total={stores.total}
                    defaultCurrent={stores?.params?.page || 1}
                    pageSize={20}
                    onChange={(page, perPage) =>
                      this.fetchData({
                        ...stores.params,
                        page:
                          page === Number.NEGATIVE_INFINITY
                            ? 1
                            : page === 0
                            ? 1
                            : page,
                        perPage,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </Wrapper>
        </div>

        <Modal
          centered
          title={
            <div>
              {currAction === 'create' && stepCreateStore === 2 ? (
                <div className="back cursor-pointer">
                  <div onClick={backPrevForm}>
                    <ArrowLeft />
                    Back
                  </div>
                </div>
              ) : (
                ''
              )}
              {currAction === 'update' ? (
                <div className="title">Update store</div>
              ) : (
                <div className="title">Connect new store</div>
              )}
            </div>
          }
          style={{ padding: '35px' }}
          footer={null}
          visible={!!storeType}
          onCancel={() =>
            this.setState({ storeType: null, stepCreateStore: 1 })
          }
          closeModal={this.hideForm}
          destroyOnClose={true}
          maskClosable={false}
          {...modal_prop}
        >
          <Spin
            spinning={
              detailStoreLoading ||
              updateStoreLoading ||
              updateStoreEbayLoading ||
              updateStoreEtsyLoading ||
              updateStoreShopbaseLoading ||
              createStoreLoading ||
              linkEbayLoading ||
              linkEtsyLoading ||
              linkShopbaseLoading ||
              accounts.loading
            }
          >
            {stepCreateStore === 1 && currAction === 'create' && (
              <Step1CreateStore
                onSubmit={submitHandler}
                storeType={storeType}
              />
            )}
            {currAction === 'update' || stepCreateStore === 2 ? (
              <>
                {storeType === 'amazon' && (
                  <FormAmazon
                    key="amazon"
                    profile={profile}
                    currAction={this.state.currAction}
                    onSubmit={this.updateOrCreateStore}
                    accounts={accounts.data}
                    initialValues={this.state.currInitialValue}
                    onCancel={this.hideForm}
                  />
                )}
                {storeType === 'shopbase' && (
                  <FormShopbase
                    key="shopbase"
                    profile={profile}
                    currAction={this.state.currAction}
                    onSubmit={this.updateOrCreateStore}
                    accounts={accounts.data}
                    initialValues={this.state.currInitialValue}
                    onCancel={this.hideForm}
                  />
                )}
                {storeType === 'orderdesk' && (
                  <FormOrderDesk
                    key="orderdesk"
                    profile={profile}
                    currAction={this.state.currAction}
                    onSubmit={this.updateOrCreateStore}
                    accounts={accounts.data}
                    initialValues={this.state.currInitialValue}
                    onCancel={this.hideForm}
                  />
                )}
                {storeType === 'wordpress' && (
                  <FormWordpress
                    key="wordpress"
                    profile={profile}
                    currAction={this.state.currAction}
                    onSubmit={this.updateOrCreateStore}
                    accounts={accounts.data}
                    initialValues={this.state.currInitialValue}
                    onCancel={this.hideForm}
                  />
                )}
                {storeType === 'shopify' && (
                  <FormShopify
                    key="shopify"
                    profile={profile}
                    currAction={this.state.currAction}
                    onSubmit={this.updateOrCreateStore}
                    accounts={accounts.data}
                    initialValues={this.state.currInitialValue}
                    onCancel={this.hideForm}
                  />
                )}
                {storeType === 'etsy' && (
                  <FormEtsy
                    key="etsy"
                    profile={profile}
                    currAction={this.state.currAction}
                    onSubmit={this.updateOrCreateStore}
                    accounts={accounts.data}
                    initialValues={this.state.currInitialValue}
                    onCancel={this.hideForm}
                  />
                )}
                {storeType === 'ebay' && (
                  <FormEbay
                    key="ebay"
                    profile={profile}
                    currAction={this.state.currAction}
                    onSubmit={this.updateOrCreateStore}
                    accounts={accounts.data}
                    initialValues={this.state.currInitialValue}
                    onCancel={this.hideForm}
                  />
                )}
                {storeType === 'store_default' && (
                  <FormDefault
                    key="store_default"
                    profile={profile}
                    currAction={this.state.currAction}
                    onSubmit={this.updateOrCreateStore}
                    accounts={accounts.data}
                    initialValues={this.state.currInitialValue}
                    onCancel={this.hideForm}
                  />
                )}
              </>
            ) : (
              ''
            )}
          </Spin>
        </Modal>
        <GlobalStyle />
      </>
    );
  }
}
export default React.memo(
  connect(
    (state) => ({
      stores: state.store.list.stores,
      detailStoreData: state.store.edit.detail.data,
      updateStoreLoading: state.store.edit.edit.loading,
      updateStoreEbayLoading: state.store.edit.editStoreEbay.loading,
      updateStoreEtsyLoading: state.store.edit.editStoreEtsy.loading,
      updateStoreShopbaseLoading: state.store.edit.editStoreShopbase.loading,
      updateStoreShopbaseTokenLoading:
        state.store.edit.editShopbaseToken.loading,
      updateStoreShopbaseNormalLoading:
        state.store.edit.editShopbaseNormal.loading,
      detailStoreLoading: state.store.edit.detail.loading,
      createStoreLoading: state.store.create.loading,
      shopifyLinkForUpdatingToken:
        state.store.edit.getShopifyLinkForUpdatingToken,
      _updateShopifyToken: state.store.edit.updateShopifyToken,
      _updateShopifyStore: state.store.edit.updateShopifyStore,

      linkEbay: state.store.create.getLinkEbay.data,
      linkEbayLoading: state.store.create.getLinkEbay.loading,
      linkEtsy: state.store.create.getEtsyLink.data,
      linkEtsyLoading: state.store.create.getEtsyLink.loading,
      getLinkEstyToken: state.store.edit.getLinkEstyToken.data,
      getLinkEstyTokenLoading: state.store.edit.getLinkEstyToken.loading,
      linkAmazon: state.store.create.getAmazonLink.data,
      createAmazonStoreLoading: state.store.create.createAmazonStore.loading,

      linkShopbaseLoading: state.store.create.getLinkShopbase.loading,
      linkShopbase: state.store.create.getLinkShopbase.data,
      linkShopbaseToken: state.store.edit.getLinkShopbaseToken.data,
      linkShopbaseMerchant: state.store.edit.getLinkShopbaseMerchant.data,

      accounts: state.setting.account.accounts,
      storeStatus: state.store.list.listStoreStatus,
      deleteStoreLoading: state.store.list.delete.loading,
      profile: state.auth.info.profile,
      formFilterOrder: state.form.formFilterStore,

      //3 steps to connect Shopify
      linkShopifyLoading: state.store.create.getShopifyLink.loading,
      linkShopify: state.store.create.getShopifyLink.data,
      linkUrlShopify: state.store.create.checkConnectWithShopify.data,
      connectStoreShopify: state.store.create.connectStoreShopify.data,
      connectStoreShopifyLoading:
        state.store.create.connectStoreShopify.loading,

      //Update token Amazon store
      getLinkAmazonToken: state.store.edit.getLinkAmazonToken.data,
      getLinkAmazonTokenLoading: state.store.edit.getLinkAmazonToken.loading,
      updateAmazonToken: state.store.edit.updateAmazonToken.data,
      updateAmazonTokenLoading: state.store.edit.updateAmazonToken.loading, 

   
    }),
    (dispatch) => ({
      getList: (options) => {
        dispatch(getList(options));
      },
      deleteStore: (id) => {
        dispatch(deleteStore(id));
      },
      detailStore: (id) => {
        dispatch(detailStore(id));
      },
      editEbayStore: (id, dataToUpdate) => {
        dispatch(editEbayStore(dataToUpdate, id));
      },
      editEtsyStore: (id, dataToUpdate) => {
        dispatch(editEtsyStore(dataToUpdate, id));
      },
      editShopBaseStore: (id, dataToUpdate) => {
        dispatch(editShopBaseStore(dataToUpdate, id));
      },
      editStoreShopbaseToken: (id, dataToUpdate) => {
        dispatch(editStoreShopbaseToken(dataToUpdate, id));
      },
      editStoreShopbaseNormal: (id, dataToUpdate) => {
        dispatch(editStoreShopbaseNormal(dataToUpdate, id));
      },
      updateStore: (id, dataToUpdate) => {
        dispatch(updateStore(dataToUpdate, id));
      },
      createStore: (dataToCreate) => {
        dispatch(createStore(dataToCreate));
      },
      createEbayStore: (dataToCreate) => {
        dispatch(createEbayStore(dataToCreate));
      },
      createAmazonStore: (dataToCreate) => {
        dispatch(createAmazonStore(dataToCreate));
      },
      createShopbaseStore: (dataToCreate) => {
        dispatch(createShopBaseStore(dataToCreate));
      },
      createEtsyStore: (dataToCreate) => {
        dispatch(createEtsyStore(dataToCreate));
      },

      getEbayLink: (params) => {
        dispatch(getEbayLink(params));
      },
      getEtsyLink: (params) => {
        dispatch(getEtsyLink(params));
      },
      getAmazonLink: (params) => {
        dispatch(getAmazonLink(params));
      },
      getShopBaseLink: (params) => {
        dispatch(getShopBaseLink(params));
      },
      getLinkShopbaseToken: (params) => {
        dispatch(getLinkShopbaseToken(params));
      },
      getLinkShopbaseMerchant: (params) => {
        dispatch(getLinkShopbaseMerchant(params));
      },
      listAccount: () => {
        dispatch(listAccount({}));
      },
      listStoreStatus: () => {
        dispatch(listStoreStatus());
      },
      getShopifyLink: (data) => {
        dispatch(getShopifyLink(data));
      },
      getShopifyLinkForUpdatingToken: (id) => {
        dispatch(getShopifyLinkForUpdatingToken(id));
      },
      updateShopifyToken: (data) => {
        dispatch(updateShopifyToken(data));
      },
      updateShopifyStore: (id, data) => {
        dispatch(updateShopifyStore(id, data));
      },
      resetForm: (formName) => {
        dispatch(reset(formName));
      },
      //Step to connect Shopify
      checkConnectWithShopifyAction: (params) => {
        dispatch(checkConnectWithShopifyAction(params));
      },
      getUrlShopifyAction: (data) => {
        dispatch(getUrlShopifyAction(data));
      },
      connectToShopifyAction: () => {
        dispatch(connectToShopifyAction());
      },
      //Update token Etsy
      getLinkUpdateTokenEtsy: () => {
        dispatch(getLinkUpdateTokenEtsy());
      },
      updateEtsyToken: (id, data) => {
        dispatch(updateEtsyToken(id, data));
      },
      //Update token Amazon
      getLinkUpdateTokenAmazon: () => {
        dispatch(getLinkUpdateTokenAmazon());
      },
      updateAmazonToken: (id, data) => {
        dispatch(updateAmazonToken(id, data));
      },
    })
  )(List)
);
