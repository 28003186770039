import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actionType as TYPE } from './actions';

import { AUTH, verify } from 'scenes/Auth/actions';
import { replace } from 'connected-react-router';
import {
  updateProfile,
  removeAvatar,
  deleteAccount,
  connectToSocial,
  disconnectSocial,
} from 'apis/user';
import { getList } from 'apis/countries';

function* updateSaga(action) {
  try {
    let { params } = action;
    const response = yield call(updateProfile, params);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.UPDATE_PROFILE.SUCCESS, response }),
        put(verify()),
      ]);
      if (params['from'] === 'update-information') {
        yield put(replace('/products/list'));
      }
    } else {
      yield put({ type: TYPE.UPDATE_PROFILE.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.UPDATE_PROFILE.ERROR, error })]);
  }
}

function* removeAvatarSaga(action) {
  try {
    let { params } = action;
    const response = yield call(removeAvatar, params);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.REMOVE_AVATAR.SUCCESS, response }),
        put({ type: AUTH.verify.request }),
      ]);
    } else {
      yield put({ type: TYPE.REMOVE_AVATAR.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.REMOVE_AVATAR.ERROR, error })]);
  }
}

function* listCountrySaga(action) {
  try {
    const response = yield call(getList);
    if (response.code === 'success') {
      yield all([put({ type: TYPE.LIST_COUNTRY.SUCCESS, ...response })]);
    } else {
      yield put({ type: TYPE.LIST_COUNTRY.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.LIST_COUNTRY.ERROR, error })]);
  }
}
function* deleteAccountSaga(action) {
  try {
    const response = yield call(deleteAccount);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.DELETE_ACCOUNT.SUCCESS, response }),
        put(replace('/auth/logout')),
      ]);
    } else {
      yield put({ type: TYPE.DELETE_ACCOUNT.ERROR, error: response });
    }
  } catch (error) {
    yield all([put({ type: TYPE.DELETE_ACCOUNT.ERROR, error })]);
  }
}

function* connectSocialSaga(action) {
  try {
    let { data } = action;
    const response = yield call(connectToSocial, data);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.CONNECT_SOCIAL_ACCOUNT.SUCCESS, response }),
        put(verify()),
      ]);
    } else {
      yield put({ type: TYPE.CONNECT_SOCIAL_ACCOUNT.ERROR, error: response });
      yield put(verify());
    }
  } catch (error) {
    yield all([put({ type: TYPE.CONNECT_SOCIAL_ACCOUNT.ERROR, error })]);
  }
}

function* disconnectSocialSaga(action) {
  let { data } = action;
  try {
    const response = yield call(disconnectSocial, data);
    if (response.code === 'success') {
      yield all([
        put({ type: TYPE.DISCONNECT_SOCIAL_ACCOUNT.SUCCESS, response }),
        put(verify()),
      ]);
    } else {
      yield all([
        put({ type: TYPE.DISCONNECT_SOCIAL_ACCOUNT.ERROR, response }),
        put(verify()),
      ]);
    }
  } catch (error) {
    yield all([put({ type: TYPE.DISCONNECT_SOCIAL_ACCOUNT.ERROR, error })]);
  }
}

function* watcher() {
  yield all([
    takeLatest(TYPE.UPDATE_PROFILE.REQUEST, updateSaga),
    takeLatest(TYPE.REMOVE_AVATAR.REQUEST, removeAvatarSaga),
    takeLatest(TYPE.LIST_COUNTRY.REQUEST, listCountrySaga),
    takeLatest(TYPE.DELETE_ACCOUNT.REQUEST, deleteAccountSaga),
    takeLatest(TYPE.CONNECT_SOCIAL_ACCOUNT.REQUEST, connectSocialSaga),
    takeLatest(TYPE.DISCONNECT_SOCIAL_ACCOUNT.REQUEST, disconnectSocialSaga),
  ]);
}

export default watcher;
