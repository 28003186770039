import React, { Component } from 'react';
import { Upload, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import axios from 'axios';
import { getPresignedUrlS3 } from 'apis/image_design';
import { API_TOKEN_KEY, S3_CDN } from 'web.config';
import { uploading_message } from 'utils/options';
import getMomentInstance from 'utils/moment';
import { GlowingSVG } from 'components/SVG';
import { GRAY_100, GRAY_50, GRAY_600 } from 'components/colors';
import { ReactComponent as UploadIcon } from 'images/icons/upload-cloud-02.svg';
import { ReactComponent as DeleteIcon } from 'images/icons/x-close.svg';
import classNames from 'classnames';
import { ImageUploadWrapper } from './styled';
import {
  createFileName,
  createFolderName,
  getCookie,
  getSizeImage,
  resizeImage,
  setCookie,
} from 'utils/helper';

class uploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      currStore = 0,
      changeField2 = null,
      changeField3 = null,
      changeField4 = null,
      data = {},
      paramName = 'file',
      prefix = '',
      url,
      multiple,
      width,
      height,
      typeUpload = 'avatar',
      meta: { error, submitFailed },
      hideResize,
      input,
      showUploadList,
      cusId = 0,
      prefixName = 'artwork',
      customPrefix = 'tmp',
      showRemoveIcon = false,
      handleOnRemove = null,
    } = this.props;
    const props = {
      accept: '.png,.jpg,.jpeg',
      listType: 'picture-card',
      showUploadList: showUploadList,
      name: paramName,
      multiple: multiple ? true : false,
      action: url,
      headers: {
        // common: {
        Authorization: `Bearer ${getCookie(API_TOKEN_KEY)}`,
        storeid: currStore,
        // }
      },
      data,
      beforeUpload: (file) => {
        if (
          file.type !== 'image/png' &&
          file.type !== 'image/jpg' &&
          file.type !== 'image/jpeg'
        ) {
          notification.warn({
            className: 'notic1',
            message: `${file.name} is not a png or jpg file.`,
          });
        }
        if (file.size > 10485760) {
          notification.warn({
            className: 'notic1',
            message: `${file.name} is larger than 10MB.`,
          });
        }
        return (
          (file.type === 'image/png' && file.size < 10485760) ||
          (file.type === 'image/jpg' && file.size < 10485760) ||
          (file.type === 'image/jpeg' && file.size < 10485760)
        );
      },
      customRequest: async ({ file, onError, onSuccess }) => {
        const folderName = createFolderName(cusId);
        const extension = file.name.split('.').pop();
        const fileName = createFileName(extension || 'png', prefixName, cusId);
        const fileType = `image/${extension}`;
        const keyToCreatePresignedUrl = `${customPrefix}/${folderName}/${getMomentInstance(
          null,
          0,
          false,
          true
        )}/${fileName}`;
        const response = await getPresignedUrlS3(
          keyToCreatePresignedUrl,
          currStore
        );
        if (response && response.code === 'success') {
          axios
            .put(response.data.url, file, {
              headers: {
                'Content-Type': fileType,
              },
            })
            .then((response) => {
              if (parseInt(response.status) === 200) {
                onSuccess(
                  {
                    code: 'success',
                    url: `${S3_CDN}/${keyToCreatePresignedUrl}`,
                    folderName: folderName,
                    fileName: fileName,
                    fileType: fileType,
                    extension: extension,
                  },
                  file
                );
              } else {
                onError();
              }
            });
        }
      },
      onChange: (info) => {
        const { status, response, originFileObj } = info.file;
        if (status === 'uploading') {
          this.setState({ loading: true });
        }
        if (status === 'done' && response.code === 'success') {
          getSizeImage(response.url, async (width, height) => {
            const resizedImage = await resizeImage(
              originFileObj,
              650,
              Math.round((650 * height) / width),
              'file',
              response.extension.toUpperCase()
            );
            const keyToCreatePresignedUrl2 = `${customPrefix}/${
              response.folderName
            }/${getMomentInstance(null, 0, false, true)}/thumbnail/${
              response.fileName
            }`;
            const response2 = await getPresignedUrlS3(
              keyToCreatePresignedUrl2,
              currStore
            );
            if (response2 && response2.code === 'success') {
              axios
                .put(response2.data.url, resizedImage, {
                  headers: {
                    'Content-Type': response.fileType,
                  },
                })
                .then((response3) => {
                  if (parseInt(response3.status) === 200) {
                    input.onChange(`${S3_CDN}/${keyToCreatePresignedUrl2}`);
                    if (
                      typeUpload === 'upload-artwork' &&
                      changeField2 &&
                      changeField3 &&
                      changeField4
                    ) {
                      getSizeImage(
                        `${S3_CDN}/${keyToCreatePresignedUrl2}`,
                        (width2, height2) => {
                          changeField2({
                            w: width2,
                            h: height2,
                          });
                          changeField3(0);
                          changeField4(
                            {
                              w: width2,
                              h: height2,
                            },
                            `${S3_CDN}/${keyToCreatePresignedUrl2}`
                          );
                        }
                      );
                    }
                  } else {
                    notification.error({
                      className: 'notic1',
                      message: `${info.file.name} file upload failed.`,
                    });
                  }
                  this.setState({ loading: false });
                });
            }
          });
        } else if (status === 'error') {
          notification.error({
            className: 'notic1',
            message: `${info.file.name} file upload failed.`,
          });
          // message.error(`${info.file.name} file upload failed.`);
          this.setState({ loading: false });
        }
      },
    };

    return (
      <ImageUploadWrapper
        className={classNames('w-100', { uploaded: input.value })}
      >
        <Upload {...props}>
          <Spin spinning={this.state.loading} tip={uploading_message}>
            {input.value ? (
              <div className="upload-success">
                <img
                  src={prefix + input.value}
                  style={{ maxWidth: '400px', maxHeight: '150px' }}
                  alt=""
                  className="p-2"
                />

                <DeleteIcon />
              </div>
            ) : (
              <div className="upload">
                <div>
                  <GlowingSVG
                    backgroundColor={GRAY_100}
                    borderColor={GRAY_50}
                    svgStrokeColor={GRAY_600}
                  >
                    <UploadIcon />
                  </GlowingSVG>
                  <div className="text-upload">
                    <b className="click-to">Click to upload</b> or drag and drop
                    <br />
                    Only <b className="text-muted">PNG, JPG</b> are supported up
                    to a max file size of <b className="text-muted">10MB</b>
                  </div>
                </div>
              </div>
            )}
          </Spin>
        </Upload>
        <div>
          {error && submitFailed && (
            <span className="validate-style">{error}</span>
          )}
        </div>
        {width && height && !hideResize && (
          <span className="d-block mb-2">{`Hình ảnh sẽ được resize về kích thước ${width} x ${height}`}</span>
        )}
      </ImageUploadWrapper>
    );
  }
}

export default uploadImage;
