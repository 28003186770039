import React, { useState, useEffect } from 'react';
// import PaypalLogo from "images/small-paypal.png";
import PaypalLogo from 'images/Logo_store/paypal.svg';
import { Table, Spin, notification } from 'antd';
import { getListPaypal } from 'apis/payment_menthod';
import { useDispatch, useSelector } from 'react-redux';
import AddPaypalButton from './AddPaypalButton';
import RemovePaypalAccount from './RemovePaypalAccount';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { getPaypalInfoFromToken, cancelPaypal } from 'apis/payment_menthod';
import { setLoading as setLoadingPage } from 'scenes/BillingPaymentAccount/actions';
import { hidePartialOfEmail } from 'utils/helper';
import classNames from 'classnames';
import { Wrapper } from './PaypalMethod.styled';
function PaypalMethod({ input, meta: { error }, changeFieldValue }) {
  const [loading, setLoading] = useState(false);
  const [listPaypalAccount, setListPaypalAccount] = useState(null);
  const history = useHistory();
  const useInfo = useSelector((state) => state.auth.info?.profile);
  const getPaypalAccounts = () => {
    setLoading(true);
    getListPaypal({
      sortBy: 'pay_id',
      sortType: 'desc',
    })
      .then((response) => {
        if (response?.code === 'success') {
          input.onChange(response?.data?.paypals?.[0]?.id || 0);
          setListPaypalAccount(response?.data?.paypals);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (
      parseInt(params?.isPaypalStatus) === 1 &&
      params?.code &&
      params?.token
    ) {
      const dataToAddPaypal = {
        token: params.token,
        payerId: '',
        code: params.code,
      };
      delete params['token'];
      delete params['code'];
      history.replace({
        search: queryString.stringify(params),
      });
      setLoading(true);
      changeFieldValue('paymentMethod', 0);
      getPaypalInfoFromToken(dataToAddPaypal)
        .then((response) => {
          if (response?.code === 'success') {
            notification.success({
              message:
                response?.clientMsg ||
                'Your Paypal account has been connected successfully.',
            });
          } else {
            notification.warn({
              message:
                response?.[0]?.clientMsg ||
                'Can not connect to your Paypal account.',
            });
          }
        })
        .catch((error) => {
          setLoading(false);
        })
        .finally(() => {
          getPaypalAccounts();
        });
    } else if (
      parseInt(params?.isPaypalStatus) === 0 &&
      params?.code &&
      params?.token
    ) {
      const dataToCancelPaypal = {
        token: params.token,
        payerId: '',
        code: params.code,
      };
      delete params['token'];
      delete params['code'];
      history.replace({
        search: queryString.stringify(params),
      });
      setLoading(true);
      changeFieldValue('paymentMethod', 0);
      cancelPaypal(dataToCancelPaypal)
        .then((response) => {
          if (response?.code === 'success') {
            notification.success({
              message:
                response?.clientMsg ||
                'You canceled your Paypal account to Garment successfully.',
            });
          } else {
            notification.warn({
              message:
                response?.[0]?.clientMsg ||
                'You canceled your Paypal account to Garment successfully.',
            });
          }
        })
        .catch((error) => {
          setLoading(!true);
        })
        .finally(() => {
          getPaypalAccounts();
        });
    } else {
      getPaypalAccounts();
    }
  }, [window?.location?.search]);

  const columns = [
    {
      title: <b>EMAIL</b>,
      align: 'center',
      dataIndex: 'email',
      key: 'email',
      render: (text) => hidePartialOfEmail(text),
    },
    {
      title: <b>PAYPAL ID</b>,
      align: 'center',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: <b>ACTION</b>,
      align: 'center',
      render: (record) => (
        <div>
          <button
            className="btn btn-link ml-2"
            type="button"
            onClick={() => {
              input.onChange(record?.id || 0);
            }}
          >
            Set active
          </button>
          <RemovePaypalAccount
            accountId={record.id || 0}
            getPaypalAccounts={getPaypalAccounts}
          />
        </div>
      ),
    },
  ];
  return (
    <Spin spinning={loading}>
      <Wrapper
        className={classNames('rounded paypal-wallet-card', {
          'mass-payment': useInfo.ableTopupPaypal,
          'text-center px-2 py-3 px-md-5': !useInfo.ableTopupPaypal,
        })}
      >
        <>
          <img
            className="img-fluid mb-3 paypal"
            alt="Paypal"
            src={PaypalLogo}
          />
          <Table
            rowKey="id"
            columns={columns}
            rowClassName={(record) => {
              if (parseInt(record?.id) === parseInt(input?.value)) {
                return 'ant-table-row-selected';
              }
              return '';
            }}
            scroll={{ x: 1000 }}
            dataSource={listPaypalAccount}
            pagination={false}
          />
          <AddPaypalButton
            disabled={(listPaypalAccount?.length || 0) >= 5}
            getPaypalAccounts={getPaypalAccounts}
          />
          {error && (
            <span className="invalid-feedback font-size-14">{error}</span>
          )}
        </>
      </Wrapper>
    </Spin>
  );
}

export default React.memo(PaypalMethod);
