import React, { useState, useEffect } from "react";
import { getBraintreeCardInfo } from "apis/billing_payment_account";
import VisaLogo from "images/visa-logo.svg";
import MasterCardLogo from "images/master-card-logo.svg";
import MaestroLogo from "images/maestro-logo.png";
import { Spin } from "antd";
function AccountInfor({ cardId = "" }) {
  const [loading, setLoading] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);

  useEffect(() => {
    if (cardId) {
      setLoading(true);
      getBraintreeCardInfo(cardId)
        .then((response) => {
          setLoading(false);
          if (response?.code === "success") {
            setCardInfo(response?.data);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [cardId]);
  return (
    <>
      {loading ? (
        <Spin spinning={loading}></Spin>
      ) : (
        <div className="row d-flex justify-content-start align-items-center">
          <div className="col mr-3">
            {cardInfo?.cardType === 'Visa' && <img
              className="img-fluid"
              style={{ width: 100 }}
              src={VisaLogo}
              alt="visa-logo"
            />}
            {cardInfo?.cardType === 'Mastercard' && <img
              className="img-fluid"
              style={{ width: 100 }}
              src={MasterCardLogo}
              alt="master-card-logo"
            />}
            {cardInfo?.cardType === 'Maestro' && <img
              className="img-fluid"
              style={{ width: 100 }}
              src={MaestroLogo}
              alt="master-card-logo"
            />}
          </div>
          <div className="col">
            <div className="font-size-16 text-muted">{cardInfo?.cardNumber}</div>
            <div className="font-size-12 text-muted">Expires {cardInfo?.expirationDate}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(AccountInfor);