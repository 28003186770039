import {
  UDPATE_ISSUES_REQUEST,
  CREATE_ISSUES_REQUEST,
  LIST_ISSUE_CATEGORY_REQUEST,
  LIST_ORDER_SET_LOADING,
  LIST_ORDER_TO_CREATE_ISSUE_REQUEST,
} from "./constants";

export function createIssue(data) {
  return {
    type: CREATE_ISSUES_REQUEST,
    data,
  };
}

export function udpateIssueAction(data) {
  return {
    type: UDPATE_ISSUES_REQUEST,
    data,
  };
}

export function listIssueCategory() {
  return {
    type: LIST_ISSUE_CATEGORY_REQUEST,
  };
}

export function listOrderToCreateIssue(data) {
  return {
    type: LIST_ORDER_TO_CREATE_ISSUE_REQUEST,
    data,
  };
}

export function setLoadingAction(isLoading) {
  return {
    type: LIST_ORDER_SET_LOADING,
    payload: isLoading,
  };
}
