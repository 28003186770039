import { notification } from "antd";
import { actionType as TYPE } from "./actions";

const initialState = {
  updateAutoCharge: {
    loading: false,
  },
  listCustomerConfig: {
    data: {},
    loading: false,
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    //Get list customer config key
    case TYPE.LIST_CUSTOMER_CONFIG.REQUEST:
      return {
        ...state,
        listCustomerConfig: {
          ...initialState.listCustomerConfig,
          loading: true,
        },
      };
    case TYPE.LIST_CUSTOMER_CONFIG.SUCCESS:
      const arrTemp = [];
      if (action.data.configs && Array.isArray(action.data.configs)) {
        for (let index = 0; index < action.data.configs.length; index++) {
          arrTemp[action.data.configs[index]["key"]] =
            action.data.configs[index];
        }
      }
      return {
        ...state,
        listCustomerConfig: {
          ...state.listCustomerConfig,
          loading: false,
          data: arrTemp,
        },
      };
    case TYPE.LIST_CUSTOMER_CONFIG.ERROR:
      return {
        ...state,
        listCustomerConfig: {
          ...state.listCustomerConfig,
          loading: false,
        },
      };
    case TYPE.UPDATE_AUTO_CHARGE.REQUEST:
      return {
        ...state,
        updateAutoCharge: {
          ...state.updateAutoCharge,
          loading: true,
        },
      };
    case TYPE.UPDATE_AUTO_CHARGE.SUCCESS:
      notification.success({
        message: "Update auto charge successfully.",
        duration: 5,
      });
      return {
        ...state,
        updateAutoCharge: {
          ...state.updateAutoCharge,
          loading: false,
        },
      };
    case TYPE.UPDATE_AUTO_CHARGE.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]["msg"]
            ? action.error[0]["msg"]
            : "Update auto charge failed.",
        duration: 5,
      });
      return {
        ...state,
        updateAutoCharge: {
          ...state.updateAutoCharge,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default reducer;
