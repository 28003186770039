import { takeLatest, call, put, select, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
  MOCKUP_DELETE_REQUEST,
  MOCKUP_DELETE_SUCCESS,
  MOCKUP_DELETE_FAIL,
} from "./constants";
import { actionType } from "../List/actions";
import { deleteMockupAPI } from "apis/mockup";

function* deleteMockupSaga(action) {  
  const { id, from } = action;
  try {
    const response = yield call(deleteMockupAPI, id);
    const { mockup } = yield select();
      yield all([
        put({ type: MOCKUP_DELETE_SUCCESS, errors: response }),
        put(
          from === "list"
            ? { type: actionType.LIST.REQUEST, params: mockup.list.mockups.params }
            : push("/products/mockups")
        ),
      ]);
  } catch (error) {
    yield all([put({ type: MOCKUP_DELETE_FAIL, error })]);
  }
}

function* deleteMockupWatcher() {
  yield takeLatest(MOCKUP_DELETE_REQUEST, deleteMockupSaga);
}

export default deleteMockupWatcher;
