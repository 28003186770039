import { actionType as TYPE } from './actions';
import { notification } from 'antd';

const initialState = {
  list: {
    data: [],
    orderProfit: {},
    loading: false,
    params: {},
    total: 0,
  },
  delete: {
    loading: false,
    errors: [],
  },
  archive: {
    loading: false,
    errors: [],
  },
  unarchive: {
    loading: false,
    errors: [],
  },
  preview: {
    loading: false,
    errors: [],
  },
  sync: {
    loading: false,
    errors: [],
  },
  listColor: {
    loading: false,
    data: [],
  },
  listProductsByCampaign: {
    loading: false,
    data: [],
    params: {},
  },
  count: {
    loading: false,
    data: {},
  },
  preview_modal: {
    loading: false,
    data: '',
  },
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        list: {
          ...initialState.list,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.data.campaigns,
          orderProfit: action.data.orderProfit || {},
          total: action.data.pagination.total,
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
        },
      };
    case TYPE.ARCHIVE.REQUEST:
      return {
        ...state,
        archive: {
          ...initialState.archive,
          loading: true,
        },
      };
    case TYPE.ARCHIVE.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'archive_campaign_success') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        archive: {
          ...state.archive,
          loading: false,
          errors: action.error ? action.error : [],
        },
      };
    case TYPE.ARCHIVE.ERROR:
      notification.warning({ message: 'Archive products failed', duration: 5 });
      return {
        ...state,
        archive: {
          ...state.archive,
          loading: false,
        },
      };
    case TYPE.UNARCHIVE.REQUEST:
      return {
        ...state,
        unarchive: {
          ...initialState.unarchive,
          loading: true,
        },
      };
    case TYPE.UNARCHIVE.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'archive_order_successfully') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        unarchive: {
          ...state.unarchive,
          loading: false,
          errors: action.error ? action.error : [],
        },
      };
    case TYPE.UNARCHIVE.ERROR:
      notification.warning({
        message: 'Unarchive products failed',
        duration: 5,
      });
      return {
        ...state,
        unarchive: {
          ...state.unarchive,
          loading: false,
        },
      };
    case TYPE.PREVIEW.REQUEST:
      return {
        ...state,
        preview: {
          ...initialState.preview,
          loading: true,
        },
      };
    case TYPE.PREVIEW.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.data.campaigns,
          orderProfit: action.data.orderProfit || {},
          total: action.data.pagination.total,
        },
      };
    case TYPE.PREVIEW.ERROR:
      notification.warning({
        message: 'Preview products failed',
        duration: 5,
      });
      return {
        ...state,
        preview: {
          ...state.preview,
          loading: false,
        },
      };
    case TYPE.SYNC.REQUEST:
      return {
        ...state,
        sync: {
          ...state.sync,
          loading: true,
        },
      };
    case TYPE.SYNC.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'archive_order_successfully') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        sync: {
          ...state.sync,
          loading: false,
          errors: action.error ? action.error : [],
        },
      };
    case TYPE.SYNC.ERROR:
      notification.warning({ message: 'Sync products failed', duration: 5 });
      return {
        ...state,
        sync: {
          ...state.sync,
          loading: false,
        },
      };
    case TYPE.DELETE.REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
        },
      };
    case TYPE.DELETE.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'delete_campaign_success') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          errors: action.error ? action.error : [],
        },
      };
    case TYPE.DELETE.ERROR:
      notification.warning({ message: 'Delete products failed', duration: 5 });
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
        },
      };
    case TYPE.LIST_COLOR.REQUEST:
      return {
        ...state,
        listColor: {
          ...state.listColor,
          loading: true,
        },
      };
    case TYPE.LIST_COLOR.SUCCESS:
      return {
        ...state,
        listColor: {
          ...state.listColor,
          data:
            action.data && action.data.optionColors
              ? action.data.optionColors
              : [],
          loading: false,
        },
      };
    case TYPE.LIST_COLOR.ERROR:
      return {
        ...state,
        listColor: {
          ...state.listColor,
          loading: false,
        },
      };
    case TYPE.LIST_PRODUCT_BY_CAMPAIGN.REQUEST:
      return {
        ...state,
        listProductsByCampaign: {
          ...state.listProductsByCampaign,
          loading: true,
        },
      };
    case TYPE.LIST_PRODUCT_BY_CAMPAIGN.SUCCESS:
      return {
        ...state,
        listProductsByCampaign: {
          ...state.listProductsByCampaign,
          loading: false,
          data: action.data.productCampaigns
            ? action.data.productCampaigns
            : [],
        },
      };
    case TYPE.LIST_PRODUCT_BY_CAMPAIGN.ERROR:
      return {
        ...state,
        listProductsByCampaign: {
          ...state.listProductsByCampaign,
          loading: false,
        },
      };

    //Preview image in modal product list
    case TYPE.MODAL_PREVIEW_PRODUCT.REQUEST:
      return {
        ...state,
        preview_modal: {
          ...state.preview_modal,
          loading: true,
        },
      };
    case TYPE.MODAL_PREVIEW_PRODUCT.SUCCESS:
      return {
        ...state,
        preview_modal: {
          ...state.preview_modal,
          loading: false,
          data: action.data
            ? action.data
            : [],
        },
      };
    case TYPE.MODAL_PREVIEW_PRODUCT.ERROR:
      return {
        ...state,
        preview_modal: {
          ...state.preview_modal,
          loading: false,
        },
      };
    //

    case TYPE.COUNT.REQUEST:
      return {
        ...state,
        count: {
          ...state.count,
          loading: true,
        },
      };
    case TYPE.COUNT.SUCCESS:
      return {
        ...state,
        count: {
          ...state.count,
          loading: false,
          data: action?.data || {},
        },
      };
    case TYPE.COUNT.ERROR:
      return {
        ...state,
        count: {
          ...state.count,
          loading: false,
        },
      };
    default:
      return state;
  }
}

export default ListReducer;
