import {
    actionType as TYPE
} from './actions'

const initialState = {
    updating: false,
    detail: {
        loading: false,
        data: {}
    }
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case TYPE.DETAIL.REQUEST:
            return {
                ...state,
                detail: {
                    ...initialState.detail,
                    loading: true
                }
            }
        case TYPE.DETAIL.SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    data: action.data.customerCollection,
                    loading: false
                }
            }
        case TYPE.DETAIL.ERROR:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false
                }
            }

        case TYPE.UPDATE.REQUEST:
            return {
                ...state,
                updating: true,
            }
        case TYPE.UPDATE.SUCCESS:
            return {
                ...state,
                updating: false,
            }
        case TYPE.UPDATE.ERROR:
            return {
                ...state,
                updating: false
            }
        default:
            return state
    }
}

export default reducer