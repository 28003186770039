import { GMModal } from "components/Modal/styled.modal";
import { GRAY_200 } from "components/colors";
import styled from "styled-components";

export const Wrapper = styled(GMModal)`
  .ant-modal-header {
    padding-top: 24px;
  }
  .ant-modal-close {
    padding-top: 8px;
  }
  .ant-modal-body {
    padding-top: 0;
  }
`;

export const BallanceBox = styled.div`
  padding: 12px;
  width: 100%;
  border: 1px solid ${GRAY_200}; 
  border-radius: 4px;
  padding-top: 20px;

  .label {
    line-height: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .balance {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.5;
  }
`;

export const AmountBox = styled.div`
  width: 100%;
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
`;

export const Footer = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
`;
