import { Modal } from 'antd';
import { BASE_WHITE, GRAY_200, GRAY_600, GRAY_900, PRIMARY_500 } from 'components/colors';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .ant-modal-content {
    background: ${BASE_WHITE};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }
  .ant-modal.topup-modal {
    max-width: 100%;
    .ant-modal-body {
      padding: 24px;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
      display: flex;
      justify-content: center;
      padding: 24px;
      gap: 24px;
      left: 3120px;
      top: 20px;
    }
    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 0px;
      border-radius: 16px;

    }
    .ant-modal-title {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
    }
  }

  .cta-back {
    float: right;
  }

`;

export const StyledModal = styled(Modal)`
  .upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    max-height: 100vh;
    background: ${BASE_WHITE};
    border: 1px dashed ${GRAY_200};
    border-radius: 12px;
  }
  .upload-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    width: 477px;
    max-width: 100%;
    max-height: 100vh;
    background: ${BASE_WHITE};
    border: 1px dashed ${GRAY_200};
    border-radius: 12px;
    margin-top: 36px;
    margin-bottom: 24px;
  }
  .upload-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
    gap: 4px;
    width: 477px;
    max-width: 100%;
    background: ${BASE_WHITE};
    border-radius: 12px;
    margin-top: 36px;
    margin-bottom: 24px;
  }
  .click-to {
    color: ${PRIMARY_500};
  }
  .fullwidth {
    width: 100%;
  }
`;
