import React from "react";
import { loginPayoneer } from "apis/payment_menthod";
import { useDispatch } from "react-redux";
import { setLoading } from "scenes/BillingPaymentAccount/actions";
import { notification } from "antd";
function LoginPayoneerButton(props) {
  const dispatch = useDispatch();
  const handleOnLoginPayoneer = () => {
    dispatch(setLoading(true));
    loginPayoneer()
      .then((response) => {
        dispatch(setLoading(false));
        if (response?.code === "success" && response?.data?.login_link) {
          notification.success({
            message: "Please wait for a minute ...",
          });
          window.location.href = response?.data?.login_link;
        } else {
          notification.warn({
            message: response?.[0]?.clientMsg || "Login Payoneer failed.",
          });
        }
      })
      .catch((error) => {
        dispatch(setLoading(false));
      });
  };
  return (
    <button
      style={{ backgroundColor: "#465465" }}
      type="button"
      className="text-white rounded btn btn-primary mr-3"
      onClick={handleOnLoginPayoneer}
    >
      Login Payoneer
    </button>
  );
}

export default React.memo(LoginPayoneerButton);