import React, { useState } from 'react';
import { Spin, Card, Tag } from 'antd';
import { listProductByCampaign, previewListCampaign } from 'apis/campaign';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import money from 'utils/money';
import { FileSearchOutlined } from '@ant-design/icons';
import noImg from 'images/noImg.png';
import { GMModal } from 'components/Modal/styled.modal';

const DOMAIN_GEAFLARE = 'https://cdn.geaflare.com';

function RenderPreviewCampaign({ campaignId = 0, campaignName = '' }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listProductCampaigns, setListProductCampaigns] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);

  const showPreview = () => {
    setVisible(true);
    setLoading(true);
    listProductByCampaign(campaignId)
      .then(({ code, data }) => {
        setLoading(false);
        if (code === 'success' && data?.productCampaigns) {
          for (
            let index = 0;
            index < (data?.productCampaigns || []).length;
            index++
          ) {
            if (data.productCampaigns[index]['variants']) {
              for (
                let index2 = 0;
                index2 <
                (data.productCampaigns[index]['variants'] || []).length;
                index2++
              ) {
                data.productCampaigns[index]['variants'][index2]['isActive'] =
                  parseInt(data.productCampaigns[index]['defaultColor']) ===
                  parseInt(
                    data.productCampaigns[index]['variants'][index2]['colorId']
                  )
                    ? 1
                    : 0;
              }
            }
            data.productCampaigns[index]['sizeActive'] = (
              data?.productCampaigns?.[index]?.['sizes'] || []
            ).find(
              (sizeItem) =>
                parseInt(sizeItem?.id) ===
                parseInt(data?.productCampaigns[index]['defaultSize'])
            );
          }
          setListProductCampaigns(data?.productCampaigns || []);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    //
    previewListCampaign(campaignId)
      .then(({ code, data }) => {
        setLoading(false);
        if (code === 'success' && data) {
          setImagePreview(data || []);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const onChooseColor = (productCampaignId, variantIndex) => {
    let temp = [...listProductCampaigns];
    for (let index = 0; index < temp.length; index++) {
      if (parseInt(temp[index]['id']) === parseInt(productCampaignId)) {
        for (
          let index2 = 0;
          index2 < (temp[index]['variants'] || []).length;
          index2++
        ) {
          temp[index]['variants'][index2]['isActive'] =
            index2 === variantIndex ? 1 : 0;
        }
        break;
      }
    }
    setListProductCampaigns(temp);
  };
  const onChooseSize = (productCampaignId, size) => {
    let temp = [...listProductCampaigns];
    for (let index = 0; index < temp.length; index++) {
      if (parseInt(temp[index]['id']) === parseInt(productCampaignId)) {
        temp[index]['sizeActive'] = size;
      } else {
        temp[index]['sizeActive'] = null;
      }
    }
    setListProductCampaigns(temp);
  };
  return (
    <>
      <div
        style={{ fontSize: '20px' }}
        className="cursor-pointer mb-2"
        title="Preview campaign"
        onClick={showPreview}
      >
        <FileSearchOutlined />
      </div>
      <GMModal
        title={<h4>{campaignName}</h4>}
        visible={visible}
        width={1500}
        onCancel={() => {
          setVisible(false);
        }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText={'Close'}
      >
        <Spin spinning={loading}>
          {listProductCampaigns.map((productCampaign, campaignIndex) => {
            let imageUrl = '';
            let imageOtherUrl = '';
            let colorHex = '';
            for (
              let index = 0;
              index < (productCampaign.variants || []).length;
              index++
            ) {
              if (productCampaign.variants[index]['isActive'] === 1) {
                imageUrl = productCampaign.variants[index]?.imageUrl || '';
                imageOtherUrl =
                  productCampaign.variants[index]?.imageOtherUrl || '';
                colorHex = productCampaign?.variants[index]?.colorHex || '';
                break;
              }
            }
            return (
              <Card bordered={true} className="mt-2" key={campaignIndex}>
                <div className="row">
                  <div className="col-md-6">
                    <Card hoverable={false} bordered={false}>
                      <div className="d-flex justify-content-start align-items-center">
                        <Card
                          className="d-flex align-items-center m-2"
                          bodyStyle={{ padding: '10px', width: '100%' }}
                        >
                          {imageUrl ? (
                            <img
                              className="img-fluid"
                              width="250"
                              src={imageUrl?.match(/^http(s)?:\/\/.*?\//gi) ? imageUrl : DOMAIN_GEAFLARE + imageUrl}
                              alt=""
                            />
                          ) : (
                            <img
                              className="img-fluid"
                              style={{
                                backgroundColor: `#${colorHex}`,
                                width: '250px',
                              }}
                              src={
                                productCampaign?.exProduct?.frontImg || noImg
                              }
                              alt=""
                            />
                          )}
                        </Card>
                        {((
                          productCampaign?.exProduct?.printLocation || ''
                        ).indexOf('back') > -1 ||
                          (
                            productCampaign?.exProduct?.printLocation || ''
                          ).indexOf('both') > -1) && (
                          <Card
                            className=" d-flex align-items-center m-2"
                            bodyStyle={{ padding: '10px', width: '100%' }}
                          >
                            {imageOtherUrl ? (
                              <img
                                className="img-fluid"
                                width="250"
                                src={imageOtherUrl?.match(/^http(s)?:\/\/.*?\//gi) ? imageOtherUrl : DOMAIN_GEAFLARE + imageOtherUrl}
                                alt=""
                              />
                            ) : (
                              <img
                                className="img-fluid"
                                style={{
                                  backgroundColor: `#${colorHex}`,
                                  width: '250px',
                                }}
                                src={
                                  productCampaign?.exProduct?.backImg || noImg
                                }
                                alt=""
                              />
                            )}
                          </Card>
                        )}
                      </div>
                    </Card>
                  </div>
                  <div className="col-md-6">
                    <Card hoverable={false} bordered={false}>
                      <div style={{ lineHeight: '2' }}>
                        <div className="row">
                          <div className="col">
                            {' '}
                            <b>Product</b>
                          </div>
                          <div className="col">
                            {' '}
                            {productCampaign?.variants?.[0]?.exBrandName ||
                              'No name'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <b>Price</b>
                          </div>
                          <div className="col">
                            {' '}
                            {money(
                              Array.isArray(productCampaign?.customPrice) &&
                                productCampaign?.customPrice.length > 0
                                ? productCampaign.customPrice.find(
                                    (customPriceItem) =>
                                      parseInt(customPriceItem.id) ===
                                      parseInt(productCampaign.sizeActive?.id)
                                  )?.price
                                : productCampaign?.comparePrice || 0
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <b>Product colors</b>
                          </div>
                          <div className="col">
                            {productCampaign.variants.map(
                              (variant, variantIndex) => (
                                <Tag
                                  key={variantIndex}
                                  className={classNames('mb-2', {
                                    'text-dark border-secondary':
                                      parseInt(variant?.colorId) === 12,
                                  })}
                                  onClick={() => {
                                    onChooseColor(
                                      listProductCampaigns[campaignIndex]['id'],
                                      variantIndex
                                    );
                                  }}
                                  color={`#${variant?.colorHex || 'No color'}`}
                                >
                                  <span
                                    className={classNames({
                                      invisible: variant['isActive'] === 0,
                                      visible: variant['isActive'] === 1,
                                    })}
                                  >
                                    <FontAwesomeIcon icon={faCheck} />
                                  </span>
                                </Tag>
                              )
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            {' '}
                            <b>Product sizes</b>
                          </div>
                          <div className="col">
                            {Array.isArray(productCampaign.sizes) &&
                            productCampaign.sizes.length > 0
                              ? productCampaign.sizes.map((size) => (
                                  <Tag
                                    key={size.id}
                                    className="mb-2 text-dark cursor-pointer"
                                    color={`#ccc`}
                                    // onClick={() => {
                                    //   onChooseSize(
                                    //     listProductCampaigns[campaignIndex][
                                    //       'id'
                                    //     ],
                                    //     size
                                    //   );
                                    // }}
                                  >
                                    <span>{size?.nameSize || ''}</span>
                                    <span
                                      className={classNames({
                                        invisible:
                                          parseInt(
                                            productCampaign?.sizeActive?.id
                                          ) !== parseInt(size.id),
                                      })}
                                    >
                                      {/* <FontAwesomeIcon icon={faCheck} /> */}
                                    </span>
                                  </Tag>
                                ))
                              : 'All sizes'}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Card>
            );
          })}
        </Spin>
      </GMModal>
    </>
  );
}
export default React.memo(RenderPreviewCampaign);
