import { isValidPassword } from "utils/helper";

const validate = (values) => {
    const errors = {};
    if (!values.password || values.password.replace(/\s+/g, "").length === 0) {
        errors.password = 'Please enter your password.';
    } else if (values.password && !isValidPassword(values.password)) {
        errors.password = 'Invalid format';
    }
    if (!values.confirmPassword || values.confirmPassword.replace(/\s+/g, "").length === 0) {
        errors.confirmPassword = 'Please enter your confirm-password.';
    }
    if (values.confirmPassword !== values.password) {
        errors.confirmPassword = "Your password and confirm-password doesn't match."
    }
    return errors;
};

export default validate;