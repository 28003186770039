import axios from 'utils/request'

const prefix = '/schedule-tracking';

export const getList =(params={})=> {
    return axios({
        method: 'GET',
        params,
        url: `${prefix}?with[]=storeIntegration`
    })
}

export const getSyncProduct =(params={})=> {
    return axios({
        method: 'GET',
        params,
        url: `schedule-campaigns?with[]=storeIntegration`
    })
}

export const getDetail =(id)=> {
    return axios({
        method: 'GET',
        url: `${prefix}/${id}?with[]=storeIntegration`
    })
}