import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import { logout } from "./actions";
class Index extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    const { loading } = this.props;
    return (
      <div className="bg-white w-100">
        <Spin spinning={loading}>
          <div className="container-fluid">
            <div className="row min-vh-100 align-items-center justify-content-center"></div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.auth.logout.loading,
  }),
  (dispatch) => ({
    logout: () => {
      dispatch(logout());
    },
  })
)(Index);
