import React, { useState, useEffect } from "react";
import { Tag, Spin} from "antd";
import { getColors } from "apis/ex_products";
import classNames from "classnames";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SelectProductsTagColor({
  input,
  invalid,
  meta: { error, submitFailed },
  size,
  onChoose,
  expId,
  classNameContainer = "",
  setColors
}){
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (expId) {
      setLoading(true);
      getColors(expId)
        .then(({ code, data }) => {
          setLoading(false);
          if (code === "success") {
            setOptions(data.optionColors || []);
            if(typeof setColors === 'function' && Array.isArray(data.optionColors) && data.optionColors.length > 0) setColors(data.optionColors);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setOptions([]);
    }
  }, [expId]);
  const onSelect = (color) => {
    onChoose();
    input.onChange(color?.id || '0');
  };
  return (
    <div className={classNames("position-relative", classNameContainer)}>
      <Spin spinning={loading}>
      {options.map((color) => (
        <Tag
          onClick={() => onSelect(color)}
          className={classNames("cursor-pointer mb-2", {"text-dark border-secondary": parseInt(color?.id) === 12})}
          color={`#${color.value}`}
          key={color.id}
        >
          <span
            className={classNames({
              invisible: parseInt(input.value) !== parseInt(color.id),
            })}
          >
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </Tag>
      ))}
      </Spin>
      {invalid && (
        <hr
          className="border-danger m-0 position-absolute"
          style={{ width: "90%", left: "5%", top: size === "middle" ? 31 : 39 }}
        />
      )}
      {(error && submitFailed) && (
        <span className="invalid-feedback">{error}</span>
      )}
    </div>
  );
};
export default React.memo(SelectProductsTagColor);