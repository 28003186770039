import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { previewCloneCampaign } from './actions';
import { createCampaign } from '../Create/actions';
import { listProductToClone } from './actions';
import Form from './components/form';
import { detailCampaign } from '../Edit/actions';
import { listProductTypeNotShowedSize } from 'utils/options';
import { Wrapper } from './styled';

class Clone extends Component {
  state = {
    initialValues: null,
  };
  componentDidMount() {
    this.props.listProductToClone(this.props.match.params.id);
    this.props.detailCampaign(this.props.match.params.id);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.CloneFormData) !==
      JSON.stringify(prevProps.CloneFormData)
    ) {
      localStorage.setItem(
        'CloneCampaignForm',
        JSON.stringify(this.props.CloneFormData)
      );
    }
  }

  handleSubmit = (values) => {

    let listProducts = [];
    if (values.products) {
      listProducts = values.products.map((product) => {
        const listColors = product.colors.map((item) => item.id);
        let listSizes = [];
        if (listProductTypeNotShowedSize.includes(parseInt(product?.type))) {
          if (parseInt(product?.type) === 3) {
            const typeProduct = product.typeProduct.find(
              (item) => parseInt(item.id) === parseInt(product.typePrint)
            );
            if (Array.isArray(typeProduct?.sizePrice)) {
              listSizes = typeProduct?.sizePrice.map(
                (typeProductItem) => typeProductItem.id
              );
            }
          } else {
            listSizes = product?.optionSizes?.map(
              (optionSize) => optionSize.id
            );
          }
        } else {
          listSizes = product.sizes.map((item) => item.id);
        }
        return {
          productId: product.id,
          imageFrontArtwork: product.frontImage || '',
          imageBackArtwork: product.backImage || '',
          colors: listColors,
          colorActive: product.colorActive.id,
          sizes: listSizes,
          sizeActive: product.sizeActive.id,
          mockupId: 0,
          infoDesignFront: {
            frameLeft: product.boxFrontLeft || 0,
            frameTop: product.boxFrontTop || 0,
            frameWidth: product.boxFrontWidth || 0,
            frameHeight: product.boxFrontHeight || 0,
            designLeft: product.positionFront.x2,
            designTop: product.positionFront.y2,
            designWidth: product.sizeFront.w2,
            designHeight: product.sizeFront.h2,
          },
          infoDesignBack: {
            frameLeft: product.boxBackLeft || 0,
            frameTop: product.boxBackTop || 0,
            frameWidth: product.boxBackWidth || 0,
            frameHeight: product.boxBackHeight || 0,
            designLeft: product.positionBack.x2,
            designTop: product.positionBack.y2,
            designWidth: product.sizeBack.w2,
            designHeight: product.sizeBack.h2,
          },
          shortProductType: product.shortProductType || '',
          price: product.price || 0,
          comparePrice: product.comparePrice || 0,
          typePrint: product.typePrint,
          customPrice: product.customPrice,
          typeProductKey: product.typeProductKey
            ? product.typeProductKey
            : product.typeProduct &&
              product.typeProduct[0] &&
              product.typeProduct[0]['key']
            ? product.typeProduct[0]['key']
            : '',
        };
      });
    }
    const dataToCreateCampaign = {
      name: values?.title,
      description: values.description || '',
      group: values.group || 1,
      appendProductType: values.appendProductType ? 1 : 0,
      tags: values.tags || [],
      products: listProducts,
    };
    this.props.previewCloneCampaign(dataToCreateCampaign);
  };
  render() {
    const {
      listProductToCloneData,
      campaign,
      pageName,
      PreviewCampaignLoading,
    } = this.props;
    const listProductToClone = listProductToCloneData.data.map((item) => {
      const product = item.exProduct;
      if (product) {
        let currTypePrint = null;
        if (product['type'] === 'poster') {
          currTypePrint = (product['typeProduct'] || []).find(
            (currItem) => parseInt(currItem.id) === parseInt(item['typePrint'])
          );
          if (currTypePrint?.frontSizeSample) {
            product['frontSizeSample'] = currTypePrint?.frontSizeSample;
          }
          if (currTypePrint?.backSizeSample) {
            product['backSizeSample'] = currTypePrint?.backSizeSample;
          }
          product['boxFrontTop'] =
            currTypePrint?.coordinates?.boxFrontTop || product['boxFrontTop'];
          product['boxFrontLeft'] =
            currTypePrint?.coordinates?.boxFrontLeft || product['boxFrontLeft'];
          product['boxFrontWidth'] =
            currTypePrint?.coordinates?.boxFrontWidth ||
            product['boxFrontWidth'];
          product['boxFrontHeight'] =
            currTypePrint?.coordinates?.boxFrontHeight ||
            product['boxFrontHeight'];
          product['boxBackTop'] =
            currTypePrint?.coordinates?.boxBackTop || product['boxBackTop'];
          product['boxBackLeft'] =
            currTypePrint?.coordinates?.boxBackLeft || product['boxBackLeft'];
          product['boxBackWidth'] =
            currTypePrint?.coordinates?.boxBackWidth || product['boxBackWidth'];
          product['boxBackHeight'] =
            currTypePrint?.coordinates?.boxBackHeight ||
            product['boxBackHeight'];
        }
        const listCustomPrice = {};
        if (Array.isArray(product?.typeProduct?.[0]?.['sizePrice'])) {
          for (
            let sizePriceIndex = 0;
            sizePriceIndex < product.typeProduct[0]['sizePrice'].length;
            sizePriceIndex++
          ) {
            listCustomPrice[
              product.typeProduct[0]['sizePrice'][sizePriceIndex].id
            ] = {
              price: item?.['customPrice']?.[sizePriceIndex]?.price || 0,
              comparePrice:
                item?.['customPrice']?.[sizePriceIndex]?.comparePrice || 0,
            };
          }
        }
        const colors = (item?.variants || []).map((variant) => ({
          createdAt: null,
          deletedAt: null,
          id: variant.colorId,
          isDeleted: null,
          mockupSpecial: null,
          name: variant.colorName,
          status: null,
          updatedAt: null,
          value: variant.colorHex,
          valueSpecial: null,
        }));
        const colorActive = colors.find(
          (color) => parseInt(color.id) === parseInt(item.defaultColor)
        );
        const sizes = item.sizes.length > 0 ? item.sizes : product.optionSizes;
        const sizeActive =
          sizes.find(
            (size) => parseInt(size.id) === parseInt(item.defaultSize)
          ) ||
          product?.optionSizes?.[0] ||
          {};
        const mockupBox = document.getElementById('mockup-box')
          ? document.getElementById('mockup-box').getBoundingClientRect()
          : null;
        const mockupHeightFront =
          mockupBox && product.frontSizeSample
            ? (product.frontSizeSample.h * mockupBox.width) /
              product.frontSizeSample.w
            : 0;
        const mockupHeightBack =
          mockupBox && product.backSizeSample
            ? (product.backSizeSample.h * mockupBox.width) /
              product.backSizeSample.w
            : 0;
        return {
          ...product,
          expId: product.id,
          price: item.price || product.minPrice || 0,
          comparePrice: item.comparePrice || product.minPrice || 0,
          colors: colors,
          colorActive: colorActive || {},
          sizes: sizes,
          sizeActive: sizeActive || {},
          sizeFront: {
            w:
              item.frontWidth && mockupBox
                ? (item.frontWidth *
                    ((mockupBox.width * product.boxFrontWidth) / 100)) /
                  100
                : 0,
            h:
              item.frontHeight && mockupBox
                ? (item.frontHeight *
                    ((mockupHeightFront * product.boxFrontHeight) / 100)) /
                  100
                : 0,
            w2: item.frontWidth || 100,
            h2: item.frontHeight || 100,
          },
          positionFront: {
            x:
              item.leftFront && mockupBox
                ? (item.leftFront *
                    ((mockupBox.width * product.boxFrontLeft) / 100)) /
                  100
                : 0,
            y:
              item.topFront && mockupBox
                ? (item.topFront *
                    ((mockupHeightFront * product.boxFrontTop) / 100)) /
                  100
                : 0,
            x2: item.leftFront || 0,
            y2: item.topFront || 0,
          },
          frontImageSize: {
            w:
              item.frontWidth && mockupBox
                ? (item.frontWidth *
                    ((mockupBox.width * product.boxFrontWidth) / 100)) /
                  100
                : 100,
            h:
              item.frontHeight && mockupBox
                ? (item.frontHeight *
                    ((mockupHeightFront * product.boxFrontHeight) / 100)) /
                  100
                : 100,
          },
          // backImageSize: {
          //   w:
          //     item.widthBack && mockupBox
          //       ? (item.widthBack *
          //           ((mockupBox.width * product.boxBackWidth) / 100)) /
          //         100
          //       : 100,
          //   h:
          //     item.heightBack && mockupBox
          //       ? (item.heightBack *
          //           ((mockupHeightBack * product.boxBackHeight) / 100)) /
          //         100
          //       : 100,
          // },
          // frontImage: item?.designFront || '',
          // backImage: item?.designBack || '',
          sizeBack: {
            w:
              item.widthBack && mockupBox
                ? (item.widthBack *
                    ((mockupBox.width * product.boxBackWidth) / 100)) /
                  100
                : 0,
            h:
              item.heightBack && mockupBox
                ? (item.heightBack *
                    ((mockupHeightBack * product.boxBackHeight) / 100)) /
                  100
                : 0,
            w2: item.widthBack || 100,
            h2: item.heightBack || 100,
          },
          positionBack: {
            x:
              item.leftBack && mockupBox
                ? (item.leftBack *
                    ((mockupBox.width * product.boxBackLeft) / 100)) /
                  100
                : 0,
            y:
              item.topBack && mockupBox
                ? (item.topBack *
                    ((mockupHeightBack.Back * product.boxBackTop) / 100)) /
                  100
                : 0,
            x2: item.leftBack || 0,
            y2: item.topBack || 0,
          },
          typePrint: item.printType ? `${item.printType}` : '0',
          // frontImg:
          //   currTypePrint && currTypePrint['imageLink']
          //     ? currTypePrint['imageLink']
          //     : product.frontImg,
          // backImg:
          //   currTypePrint && currTypePrint['imageLink']
          //     ? currTypePrint['imageLink']
          //     : product.backImg,
          listSizeByDirection:
            product.typeProduct && product.typeProduct[0]['sizePrice']
              ? product.typeProduct[0]['sizePrice']
              : [],
          customPrice: listCustomPrice,
          ...(product.firstMockup ? { mockupId: product.firstMockup.id } : {}),
        };
      }
    });
    return (
      <Wrapper pageName={pageName}>
        <Spin
          spinning={
            PreviewCampaignLoading ||
            campaign.loading ||
            listProductToCloneData.loading
          }
        >
          <Form
            onSubmit={this.handleSubmit}
            history={this.props.history}
            loading={
              listProductToCloneData.loading &&
              listProductToClone.length === listProductToCloneData.data.length
            }
            initialValues={{
              appendProductType:
                parseInt(campaign.data.appendProductType) === 1,
              group: campaign.data.typeGroup
                ? campaign.data.typeGroup.toString()
                : '',
              tags: [],
              campaignId: this.props.match.params.id,
              products: listProductToClone,
              title: '',
              description: campaign?.data?.description || '',
            }}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    PreviewCampaignLoading: state.campaign.create.loading,
    CreateCampaignLoading: state.campaign.create.loading,
    campaign: state.campaign.edit.detail,
    CloneFormData: state.form.CloneCampaignForm?.values,
    profile: state.auth.info.profile,
    listProductToCloneData: state.campaign.clone,
  }),
  (dispatch) => ({
    createProduct: (product) => {
      dispatch(createCampaign(product));
    },
    listProductToClone: (id) => {
      dispatch(listProductToClone(id));
    },
    detailCampaign: (id) => {
      dispatch(detailCampaign(id));
    },
    previewCloneCampaign: (product) => {
      dispatch(previewCloneCampaign(product));
    },
  })
)(Clone);
