import { actionType as TYPE } from './actions';
import { notification } from 'antd';

const initialState = {
  orderPaymentData: {
    list: {},
    loading: false,
    defaultPaymentInfo: null,
  },
  orders: {
    data: [],
    config: {},
    loading: false,
    params: {},
    total: 0,
  },
  export: {
    loading: false,
    params: {},
  },
  profit: {
    loading: false,
    numberOfOrders: '...',
    totalProfit: '...',
  },
  archiveOrder: {
    loading: false,
    errors: [],
  },
  approveOrder: {
    loading: false,
    errors: [],
  },
  unapproveOrder: {
    loading: false,
    errors: [],
  },
  syncTracking: {
    loading: false,
    errors: [],
  },
  syncTrackingAmazon: {
    loading: false,
    errors: [],
  },
  verifyAddress: {
    loading: false,
    errors: [],
  },
  paymentOrder: {
    loading: false,
    errors: [],
  },
  countOrder: {
    loading: false,
    data: {},
  },
  foreceVerifiedDelivery: {
    loading: false,
    errors: [],
  },
  duplicateOrder: {
    loading: false,
    errors: [],
  },
  showErrorPayment: false,
};

function ListReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LIST.REQUEST:
      return {
        ...state,
        orders: {
          ...initialState.orders,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.LIST.SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          data: action?.data?.orders || [],
          total:
            action?.data?.pagination?.total ||
            (action?.data?.orders || []).length,
          config: action?.data?.config || {},
        },
      };
    case TYPE.LIST.UPDATE_ITEM:
      const { id, detail } = action?.payload || {};
      const orderList = state?.orders?.data || [];
      return {
        ...state,
        orders: {
          ...state.orders,
          data: [
            ...orderList.map((i) => {
              if (i.id === id) {
                i.orderItems = detail?.data?.order?.orderItems;
              }
              return i;
            }),
          ],
        },
      };
    case TYPE.LIST.ERROR:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
        },
      };

    case TYPE.GET_PROFIT.REQUEST:
      return {
        ...state,
        profit: {
          ...initialState.profit,
          loading: true,
        },
      };
    case TYPE.GET_PROFIT.SUCCESS:
      return {
        ...state,
        profit: {
          ...state.profit,
          loading: false,
          numberOfOrders: action.data.numberOfOrders,
          totalProfit: action.data.totalProfit,
        },
      };
    case TYPE.ARCHIVE_ORDER.REQUEST:
      return {
        ...state,
        archiveOrder: {
          ...state.archiveOrder,
          loading: true,
        },
      };
    case TYPE.ARCHIVE_ORDER.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'archive_order_successfully') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        archiveOrder: {
          ...state.archiveOrder,
          loading: false,
          errors: action.errors ? action.errors : [],
        },
      };
    case TYPE.ARCHIVE_ORDER.ERROR:
      notification.warning({ message: 'Archive order failed', duration: 5 });
      return {
        ...state,
        archiveOrder: {
          ...state.archiveOrder,
          loading: false,
        },
      };
    case TYPE.APPROVE_ORDER.REQUEST:
      return {
        ...state,
        approveOrder: {
          ...state.approveOrder,
          loading: true,
        },
      };
    case TYPE.APPROVE_ORDER.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'approve_order_successfully') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        approveOrder: {
          ...state.approveOrder,
          loading: false,
          errors: action.errors ? action.errors : [],
        },
      };
    case TYPE.APPROVE_ORDER.ERROR:
      notification.warning({ message: 'Approve order failed', duration: 5 });
      return {
        ...state,
        approveOrder: {
          ...state.approveOrder,
          loading: false,
        },
      };
    case TYPE.UNAPPROVE_ORDER.REQUEST:
      return {
        ...state,
        unapproveOrder: {
          ...state.unapproveOrder,
          loading: true,
        },
      };
    case TYPE.UNAPPROVE_ORDER.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'unapprove_order_successfully') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        unapproveOrder: {
          ...state.unapproveOrder,
          loading: false,
          errors: action.errors ? action.errors : [],
        },
      };
    case TYPE.UNAPPROVE_ORDER.ERROR:
      notification.warning({ message: 'Unapprove order failed', duration: 5 });
      return {
        ...state,
        unapproveOrder: {
          ...state.unapproveOrder,
          loading: false,
        },
      };
    case TYPE.VERIFY_ADDRESS.REQUEST:
      return {
        ...state,
        verifyAddress: {
          ...state.verifyAddress,
          loading: true,
        },
      };
    case TYPE.VERIFY_ADDRESS.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'verify_address_successfully') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        verifyAddress: {
          ...state.verifyAddress,
          loading: false,
          errors: action.errors ? action.errors : [],
        },
      };
    case TYPE.VERIFY_ADDRESS.ERROR:
      notification.warning({ message: 'Verify address failed', duration: 5 });
      return {
        ...state,
        verifyAddress: {
          ...state.verifyAddress,
          loading: false,
        },
      };
    case TYPE.SYNC_TRACKING.REQUEST:
      return {
        ...state,
        syncTracking: {
          ...state.syncTracking,
          loading: true,
        },
      };
    case TYPE.SYNC_TRACKING.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'sync_tracking_successfully') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        syncTracking: {
          ...state.syncTracking,
          loading: false,
          errors: action.errors ? action.errors : [],
        },
      };
    case TYPE.SYNC_TRACKING.ERROR:
      notification.warning({ message: 'Sync tracking failed', duration: 5 });
      return {
        ...state,
        syncTracking: {
          ...state.syncTracking,
          loading: false,
        },
      };
    case TYPE.SYNC_TRACKING_AMAZON.REQUEST:
      return {
        ...state,
        syncTrackingAmazon: {
          ...state.syncTrackingAmazon,
          loading: true,
        },
      };
    case TYPE.SYNC_TRACKING_AMAZON.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'sync_tracking_successfully') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        syncTrackingAmazon: {
          ...state.syncTrackingAmazon,
          loading: false,
          errors: action.errors ? action.errors : [],
        },
      };
    case TYPE.SYNC_TRACKING_AMAZON.ERROR:
      notification.warning({
        message: 'Sync tracking amazon failed',
        duration: 5,
      });
      return {
        ...state,
        syncTrackingAmazon: {
          ...state.syncTrackingAmazon,
          loading: false,
        },
      };
    case TYPE.PAYMENT_ORDER.REQUEST:
      return {
        ...state,
        paymentOrder: {
          ...state.paymentOrder,
          loading: true,
        },
      };
    case TYPE.PAYMENT_ORDER.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (action.errors[index].code === 'payment_order_successfully') {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      } else if (action?.errors?.code === 'success') {
        notification.success({
          message: action?.errors?.clientMsg,
          duration: 5,
        });
      }
      return {
        ...state,
        paymentOrder: {
          ...state.paymentOrder,
          loading: false,
          errors: action.errors ? action.errors : [],
        },
      };
    case TYPE.PAYMENT_ORDER.ERROR:
      notification.warning({ message: 'Make payment failed', duration: 5 });
      return {
        ...state,
        paymentOrder: {
          ...state.paymentOrder,
          loading: false,
        },
      };
    case TYPE.FORCE_VERIFIED_DELIVERY.REQUEST:
      return {
        ...state,
        foreceVerifiedDelivery: {
          ...state.foreceVerifiedDelivery,
          loading: true,
        },
      };
    case TYPE.FORCE_VERIFIED_DELIVERY.SUCCESS:
      if (action.errors && Array.isArray(action.errors)) {
        for (let index = 0; index < action.errors.length; index++) {
          if (
            action.errors[index].code === 'force_verify_delivery_successfully'
          ) {
            notification.success({
              message: action.errors[index].msg,
              duration: 5,
            });
          } else {
            notification.warn({
              message: action.errors[index].msg,
              duration: 5,
            });
          }
        }
      }
      return {
        ...state,
        foreceVerifiedDelivery: {
          ...state.foreceVerifiedDelivery,
          loading: false,
          errors: action.errors ? action.errors : [],
        },
      };
    case TYPE.FORCE_VERIFIED_DELIVERY.ERROR:
      notification.warning({
        message: 'Force verified delivery failed',
        duration: 5,
      });
      return {
        ...state,
        foreceVerifiedDelivery: {
          ...state.foreceVerifiedDelivery,
          loading: false,
        },
      };

    //Count order
    case TYPE.COUNT_ORDER.REQUEST:
      return {
        ...state,
        countOrder: {
          ...state.countOrder,
          loading: true,
        },
      };
    case TYPE.COUNT_ORDER.SUCCESS:
      const data = {};
      Object.values(action?.data || {}).forEach((item) => {
        data[item?.title] = item;
      });
      return {
        ...state,
        countOrder: {
          ...state.countOrder,
          loading: false,
          data,
        },
      };
    case TYPE.COUNT_ORDER.ERROR:
      return {
        ...state,
        countOrder: {
          ...state.countOrder,
          loading: false,
        },
      };
    case TYPE.EXPORT.REQUEST:
      return {
        ...state,
        export: {
          ...state.export,
          params: action.params,
          loading: true,
        },
      };
    case TYPE.EXPORT.SUCCESS:
      notification.success({
        message: action?.clientMsg || 'Export orders successfully',
        duration: 5,
      });
      if (action.data && action.data.linkToDownloadFile) {
        window.open(action.data.linkToDownloadFile);
      }
      return {
        ...state,
        export: {
          ...state.export,
          loading: false,
        },
      };
    case TYPE.EXPORT.ERROR:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Export orders failed.',
        duration: 5,
      });
      return {
        ...state,
        export: {
          ...state.export,
          loading: false,
        },
      };

    case TYPE.PAYMENT_ORDER_LIST.REQUEST:
      return {
        ...state,
        loading: true,
        orderPaymentData: {
          ...state.orderPaymentData,
          loading: true,
        },
      };
    case TYPE.PAYMENT_ORDER_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        orderPaymentData: {
          ...state.orderPaymentData,
          list: action.payload,
          loading: false,
        },
      };
    case TYPE.PAYMENT_ORDER_LIST.ERROR:
      if (action.payload) {
        notification.warn({
          message:
            action.payload?.[0] && action.payload?.[0]['clientMsg']
              ? action.payload?.[0]['clientMsg']
              : '',
          duration: 5,
        });
      }
      return {
        ...state,
        loading: false,
        orderPaymentData: {
          ...state.orderPaymentData,
          list: action.payload,
          error: action.payload,
          loading: false,
        },
      };

    //Handle modal make payment
    case TYPE.CLOSE_MODAL_WARNING_PAYMENT:
      return {
        ...state,
        showErrorPayment: false,
      };

    case TYPE.OPEN_MODAL_WARNING_PAYMENT:
      return {
        ...state,
        showErrorPayment: true,
      };
    default:
      return state;
  }
}

export default ListReducer;
