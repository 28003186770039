import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Tabs,
} from 'antd';
import {
  deleteAccount,
  listAccount,
  listInvatitionAccountAction,
} from '../../actions';
import queryString from 'query-string';
import { notification } from 'antd';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import Staff from './components/Staff';
import Invitation from './components/Invitation';
class List extends Component {
  constructor(props) {
    super(props);
    const query_params = queryString.parse(window.location.search);
    this.state = {
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        ...query_params,
      },
    };
  }
  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
  }
  fetchData = ({ page = 1, perPage = 10, ...options }) => {
    let params = {
      page,
      perPage,
      ...options,
    };

    this.props.history.replace(
      window.location.pathname + '?' + queryString.stringify(params)
    );
    this.props.getList(params);
    this.props.listInvatitionAccountAction(params);
  };
  render() {
    const {
      accounts,
      pageName,
      deleteLoading,
      loading,
      listInvatitionAccount,
      listInvatitionAccountLoading,
      deleteInvitationLoading,
    } = this.props;
    return (
      <Wrapper
        pageName={pageName}
        ctas={
          <>
            <GMButton
              type="button"
              color="SecondaryGray"
              onClick={() => {
                this.props.history.push('/settings/staff-accounts/reports');
              }}
            >
              View report
            </GMButton>
            <GMButton
              onClick={() => {
                if (accounts.total >= 20) {
                  notification.warn({
                    message: 'You only can add maximum of 20 staffs.',
                    duration: 5,
                  });
                } else {
                  this.props.history.push('/settings/staff-accounts/create');
                }
              }}
            >
              <PlusIcon />
              Invite others
            </GMButton>
          </>
        }
      >
        <div className="tabs">
          <Tabs destroyInactiveTabPane>
            <Tabs.TabPane tab="Staff account" key="staff">
              <Staff
                accounts={accounts ? accounts : []}
                pageName={pageName}
                deleteLoading={deleteLoading}
                loading={loading}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Sent invitation" key="invitation">
              <Invitation
                accounts={listInvatitionAccount ? listInvatitionAccount : []}
                pageName={pageName}
                deleteInvitationLoading={deleteInvitationLoading}
                loading={listInvatitionAccountLoading}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    accounts: state.setting.account.accounts,
    listInvatitionAccount: state.setting.account.listInvatitionAccount?.data,
    deleteLoading: state.setting.account.deleteAccount.loading,
    deleteInvitationLoading: state.setting.account.deleteInvitation.loading,
    loading: state.setting.account.accounts.loading,
    listInvatitionAccountLoading:
      state.setting.account.listInvatitionAccount.loading,
  }),
  (dispatch) => ({
    getList: (options) => {
      dispatch(listAccount(options));
    },
    listInvatitionAccountAction: (options) => {
      dispatch(listInvatitionAccountAction(options));
    },
    deleteAccount: (id) => {
      dispatch(deleteAccount(id));
    },
  })
)(List);
