import React, { Component } from "react";
import { Field } from "redux-form";
import { Table } from "antd";
import FieldCheckboxGroup from "utils/fields/FieldCheckboxGroup";
import { RenderMultipleProductColors } from "./RenderMultipleProductColor";
import SelectProductTag from "./Search/SelectProductTag";

export default class RenderListCampaignsToFeedFormAmazon extends Component {
  render() {
    const {
      fields,
      changeFieldValue,
      campaigns,
      listCampaignsToExport,
    } = this.props;
    const columns = [
      {
        title: <b>PRODUCTS</b>,
        dataIndex: "productCampaigns",
        width: "10%",
        key: "productCampaigns",
        render: (productCampaigns) =>
          campaigns.map((campaign) => (
            <p key={campaign.id}>
              <b>{campaign.name}</b>
            </p>
          )),
      },
      {
        title: <b>STYLE</b>,
        dataIndex: "productCampaigns",
        width: "30%",
        key: "productCampaigns",
        render: (productCampaigns, record, i) => {
          return (
            <div>
              <Field
                name={`${fields.name}[${i}].products`}
                component={FieldCheckboxGroup}
                classNameCustom={"mt-1dot5rem"}
                options={productCampaigns.map((productCampaign) => {
                  return {
                    value: `${productCampaign?.exProduct?.id}`,
                    onChoose: () => {
                      const selectedColor = productCampaign?.color
                        ? productCampaign.color.split(",")
                        : [];
                      if (
                        !listCampaignsToExport[i] ||
                        !listCampaignsToExport[i]?.colors ||
                        !listCampaignsToExport[i]?.colors[
                          `color_${productCampaign?.exProduct?.id}`
                        ] ||
                        listCampaignsToExport[i]?.colors[
                          `color_${productCampaign?.exProduct?.id}`
                        ].length === 0
                      ) {
                        changeFieldValue(
                          `${fields.name}[${i}].colors.[${"color_"}${
                            productCampaign?.exProduct?.id
                          }]`,
                          selectedColor[0] ? [selectedColor[0]] : []
                        );
                      }
                    },
                    onRemove: () => {
                      changeFieldValue(
                        `${fields.name}[${i}].colors.[${"color_"}${
                          productCampaign?.exProduct?.id
                        }]`,
                        []
                      );
                    },
                    label:
                      productCampaign.exProduct.name +
                      " " +
                      (listCampaignsToExport[i] &&
                      listCampaignsToExport[i]?.colors &&
                      listCampaignsToExport[i]?.colors[
                        `color_${productCampaign?.exProduct?.id}`
                      ] &&
                      listCampaignsToExport[i]?.colors[
                        `color_${productCampaign?.exProduct?.id}`
                      ].length > 0
                        ? `(${
                            listCampaignsToExport[i]?.colors[
                              `color_${productCampaign?.exProduct?.id}`
                            ].length
                          })`
                        : ""),
                  };
                })}
                colSpan={24}
              />
            </div>
          );
        },
      },
      {
        title: <b>COLOR</b>,
        dataIndex: "productCampaigns",
        width: "30%",
        key: "productCampaigns",
        render: (productCampaigns, record, i) => {
          return productCampaigns.map((productCampaign) => {
            const selectedColors = productCampaign.color.split(",");
            let listColorOptions =
              productCampaign?.exProduct?.optionColors || [];
            listColorOptions = listColorOptions.filter((colorOption) =>
              selectedColors.includes(`${colorOption.id}`)
            );
            return (
              <div key={`color-${productCampaign.id}`} className="mt-1dot7rem">
                <Field
                  name={`${fields.name}[${i}].colors.[${"color_"}${
                    productCampaign?.exProduct?.id
                  }]`}
                  component={RenderMultipleProductColors}
                  onChoose={(currListColor) => {
                    if (
                      Array.isArray(listCampaignsToExport?.[i]?.["products"])
                    ) {
                      if(currListColor.length === 0 && 
                      listCampaignsToExport?.[i]?.["products"].includes(`${productCampaign?.exProduct?.id}`)){
                        changeFieldValue(`${fields.name}[${i}].products`, 
                        listCampaignsToExport?.[i]?.["products"].filter(productId => parseInt(productId) !== parseInt(productCampaign?.exProduct?.id)));
                      }else{
                        changeFieldValue(`${fields.name}[${i}].products`, [
                          ...listCampaignsToExport?.[i]?.["products"], `${productCampaign?.exProduct?.id}`,
                        ]);
                      }
                    } else {
                      changeFieldValue(`${fields.name}[${i}].products`, [
                        `${productCampaign?.exProduct?.id}`,
                      ]);
                    }
                  }}
                  options={listColorOptions}
                />
              </div>
            );
          });
        },
      },
      {
        title: <b>SIZE</b>,
        dataIndex: "productCampaigns",
        width: "30%",
        key: "productCampaigns",
        render: (productCampaigns, record, i) => {
          return productCampaigns.map((productCampaign) => {
            let listSizeOptions = productCampaign?.exProduct?.optionSizes || [];
            return (
              <div key={`size-${productCampaign.id}`} className="mt-3">
                <Field
                  name={`${fields.name}[${i}].sizes.[${"size_"}${
                    productCampaign?.exProduct?.id
                  }]`}
                  component={SelectProductTag}
                  listOptions={[
                    ...listSizeOptions.map((sizeOption) => ({
                      value: sizeOption.value,
                      label: sizeOption.name,
                    })),
                  ]}
                  isProductTag={false}
                  placeholder="Select sizes"
                />
              </div>
            );
          });
        },
      },
    ];

    return (
      <div className="custom-vertical-align-antd-table">
        <Table
          bordered
          pagination={false}
          columns={columns}
          dataSource={campaigns[0] ? [campaigns[0]] : []}
          rowKey={`id`}
          scroll={{ x: 1000 }}
        />
      </div>
    );
  }
}
