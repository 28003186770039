import {
    takeLatest,
    call,
    put,
    all
} from 'redux-saga/effects'

import {
    actionType as TYPE
} from './actions'

import {getInvoiceTransaction} from 'apis/transactions'
import {replace} from "connected-react-router";


function* getInvoiceTransactionSaga(action){
    const {id} = action;
    try {
        const response = yield call(getInvoiceTransaction, id)
        if(response.code === 'success'){
            yield all([
                put({ type: TYPE.INVOICE_TRANSACTIONS.SUCCESS, ...response }),
            ])
        }else{
            yield all([
                yield put({ type: TYPE.INVOICE_TRANSACTIONS.ERROR, error: {message: 'Not found invoice transaction'} }),
                yield put(replace(`/transactions`))
            ])
        }
    } catch(error){
        yield all([
            yield put({ type: TYPE.INVOICE_TRANSACTIONS.ERROR, error })
        ])
    }
}

function* watcher(){
    yield takeLatest(TYPE.INVOICE_TRANSACTIONS.REQUEST, getInvoiceTransactionSaga);

}

export default watcher