import React, { Component } from 'react';
import { InputNumber } from 'antd';

class index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const { minimum, maximum, input, meta: { invalid, error } } = this.props
        return (
          <div className="position-relative">
            <InputNumber min={minimum} max={maximum} value={input.value} onChange={value=>input.onChange(value)} />
            {invalid&&<hr className="border-danger m-0 position-absolute" style={{width: '90%', left: '5%', top: 39}}/>}
          {error && <span className="invalid-feedback">{error}</span>}
          </div>
        );
    }
}

export default index;