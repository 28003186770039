import styled from "styled-components";

export const Wrapper = styled.div`
  ul.upload-list {
    padding: 0;
    margin: 0;
    margin-top: 16px;
    &, li {
      list-style: none;
      display: inline-block;
      width: 100%;
    }
    li {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      margin-bottom: 5px;
      width: 50%;
      float: left;
      div.img {
        width: 80px;
        height: 50px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
      }
      div.status {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        .ant-spin-container {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
        }
      }
    }
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
`;
