import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Spin, notification } from "antd";
import BackToIcon from "images/chevron-down.svg";
import { getList as listFeedForms } from "../../../DataFeed/scenes/List/actions";
import { exportFeedCustomAmazon } from "./actions";
import { listCampaignsToExport } from "../FeedAmazonData/actions";
import Form from "./components/FormFeedAmazon";
import { Wrapper } from "./styled";
const listCampaignIdToExportFeedCustomAmazon = localStorage.getItem(
  "listCampaignIdToExportFeedCustomAmazon"
)
  ? JSON.parse(localStorage.getItem("listCampaignIdToExportFeedCustomAmazon"))
  : [];
class index extends Component {
  componentDidMount() {
    let state = { ...this.props.history.location.state };
    let { listCampaignId = [] } = state;
    if (
      listCampaignId &&
      Array.isArray(listCampaignId) &&
      listCampaignId.length > 0
    ) {
      delete state.listCampaignId;
      localStorage.setItem(
        "listCampaignIdToExportFeedCustomAmazon",
        JSON.stringify(listCampaignId)
      );
      this.props.history.replace({ ...this.props.history.location, state });
    } else {
      listCampaignId = listCampaignIdToExportFeedCustomAmazon;
    }

    if (listCampaignId.length > 0) {
      this.props.listCampaignsToExport(listCampaignId);
    } else {
      notification.warn({
        message:
          "Please choose campaigns to export data feed custom Amazon.",
        duration: 5,
      });
    }
    this.props.listFeedForms({
      platformId: [3],
      status: 1,
    });
  }

  handleOnSubmit = (values) => {
    const dataToExport = {
      feedFormId: values.feedFormId || 0,
      campaigns: values.campaigns || [],
      isChangeEmail:
        Array.isArray(values?.changeEmail) && values?.changeEmail.includes(1)
          ? 1
          : 0,
      email: values.email || "",
    };
    this.props.exportFeedCustomAmazon(dataToExport);
  };

  render() {
    const {
      pageName,
      data_feeds,
      campaigns,
      FormFeedAmazonForm,
      exportFeedCustomAmazonLoading,
    } = this.props;
    return (
      <Wrapper
        pageName={pageName}
      >
        <Spin
          spinning={
            data_feeds.loading ||
            campaigns.loading ||
            exportFeedCustomAmazonLoading
          }
        >
          <Form
            onSubmit={this.handleOnSubmit}
            listFeedForms={data_feeds.data}
            listCampaignsToExport={
              FormFeedAmazonForm?.listCampaignsToExport || []
            }
            initialValues={{
              feedFormId: `${data_feeds?.default?.id || 0}`,
              campaigns: campaigns.data
                ? campaigns.data.map((item) => item.id)
                : [],
              listCampaigns: campaigns.data,
            }}
          />
        </Spin>
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    data_feeds: state.data_feed.list.data_feeds,
    campaigns: state.campaign.feed_form_amazon.listCampaignsToExport,
    exportFeedCustomAmazonLoading:
      state.campaign.feed_custom_amazon.exportFeedCustomAmazon.loading,
    FormFeedAmazonForm: state.form?.FormFeedAmazon?.values || {},
  }),
  (dispatch) => ({
    listFeedForms: (params) => {
      dispatch(listFeedForms(params));
    },
    listCampaignsToExport: (params) => {
      dispatch(listCampaignsToExport(params));
    },
    exportFeedCustomAmazon: (data) => {
      dispatch(exportFeedCustomAmazon(data));
    },
  })
)(index);