import { actionType as TYPE } from "./actions";

const initialState = {
    listCampaignToPreview: {
        loading: false,
        data: []
    }
};

function PreviewCampaignReducer(state = initialState, action) {
    switch (action.type) {
        case TYPE.LIST_CAMPAIGN_TO_PREVIEW.REQUEST:
            return {
                ...state,
                listCampaignToPreview: {
                    ...state.listCampaignToPreview,
                    loading: true
                }
            };
        case TYPE.LIST_CAMPAIGN_TO_PREVIEW.SUCCESS:
            return {
                ...state,
                listCampaignToPreview: {
                    ...state.listCampaignToPreview,
                    loading: false,
                    data: action.data && action.data.listCampaignsToPreview ? action.data.listCampaignsToPreview : []
                }
            };
        case TYPE.LIST_CAMPAIGN_TO_PREVIEW.ERROR:
            return {
                ...state,
                listCampaignToPreview: {
                    ...state.listCampaignToPreview,
                    loading: false
                }
            };
        default:
            return state;
    }
}

export default PreviewCampaignReducer;
