import React, { useEffect, useState, Fragment } from 'react';
import { getListDesigns, listProductCategory } from 'apis/ex_products';
import { Spin, Modal, Input, Tag, Tabs } from 'antd';
import classNames from 'classnames';
import { SearchOutlined } from '@ant-design/icons';
import money from 'utils/money';
import {
  CardProduct,
  CatagoryOption,
  Img,
  Wrapper,
  WrapperPickProduct,
} from './styled';
import Filter from 'components/Filter';
import { GMButton } from 'components/Button';
import noImg from 'images/noImg.png';

export const ALL_TYPE_ITEM_ID = 9999;
const ALL_ITEM = {
  id: ALL_TYPE_ITEM_ID,
  name: 'All products',
};

export const alts = (count, text, incCounter = true) => {
  const _c = count * 1;
  if (isNaN(_c)) {
    return incCounter ? `${count} ${text}` : `${text}`;
  }
  if (count > 1) {
    return incCounter ? `${count} ${text}s` : `${text}s`;
  }
  return incCounter ? `${count} ${text}` : `${text}`;
};

function PickProduct({ openDefault, onPick, element, curSelected = [] }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [selected, setSelected] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleOnSelectCategory = (category) => {
    let items2set = [];
    if (
      selectedCategories.findIndex(
        (currItem) => parseInt(currItem.id) === parseInt(category.id)
      ) > -1
    ) {
      items2set = [
        ...selectedCategories.filter(
          (currItem) => parseInt(currItem.id) !== parseInt(category.id)
        ),
      ];
    } else {
      items2set = [...selectedCategories, category];
    }
    if (
      category.id !== ALL_TYPE_ITEM_ID &&
      items2set.findIndex((i) => i.id === ALL_TYPE_ITEM_ID) > -1
    ) {
      setSelectedCategories(items2set.filter((i) => i.id !== ALL_TYPE_ITEM_ID));
    } else if (
      category.id === ALL_TYPE_ITEM_ID &&
      categories.findIndex((i) => i.id === ALL_TYPE_ITEM_ID) <= -1
    ) {
      setSelectedCategories([ALL_ITEM]);
    } else {
      setSelectedCategories(items2set.length > 0 ? items2set : [ALL_ITEM]);
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    if (show) {
      setLoading(true);
      getListDesigns()
        .then(({ code, data }) => {
          if (!isSubscribed) {
            return;
          }
          setLoading(false);
          if (code === 'success') {
            setProducts(data.exProducts);
          }
        })
        .catch((error) => {
          if (!isSubscribed) {
            return;
          }
          setLoading(false);
        });
      setCategoryLoading(true);
      listProductCategory()
        .then(({ code, data }) => {
          if (!isSubscribed) {
            return;
          }
          setCategoryLoading(false);
          if (code === 'success') {
            setCategories(data.categories);
            setSelectedCategories(
              [ALL_ITEM, ...data.categories].filter(
                (currCategory) => parseInt(currCategory.id) === ALL_TYPE_ITEM_ID
              )
            );
          }
        })
        .catch((error) => {
          if (!isSubscribed) {
            return;
          }
          setCategoryLoading(false);
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, [show]);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setSelected([...curSelected]);
    }
    return () => {
      isSubscribed = false;
    };
  }, [curSelected]);
  useEffect(() => {
    if (openDefault) {
      setShow(true);
    }
  }, [openDefault]);

  const handleSetSelected = (product) => {
    if (selected.find((item) => parseInt(item.id) === parseInt(product.id))) {
      setSelected([
        ...selected.filter(
          (item) => parseInt(item.id) !== parseInt(product.id)
        ),
      ]);
    } else {
      const listCustomPrice = {};
      if (product?.typeProduct && product.typeProduct[0]?.sizePrice) {
        for (
          let sizePriceIndex = 0;
          sizePriceIndex < product.typeProduct[0]['sizePrice'].length;
          sizePriceIndex++
        ) {
          listCustomPrice[
            product.typeProduct[0]['sizePrice'][sizePriceIndex].id
          ] = {
            price:
              product.typeProduct[0]['sizePrice'][sizePriceIndex].priceDefault,
            comparePrice:
              product.typeProduct[0]['sizePrice'][sizePriceIndex].priceDefault,
          };
        }
      }
      setSelected([
        ...selected,
        {
          ...product,
          expId: product.id,
          price: product.minPrice,
          comparePrice: product.minPrice,
          sizeFront: {
            w: 0,
            h: 0,
            w2: 100,
            h2: 100,
          },
          positionFront: {
            x: 0,
            y: 0,
            x2: 0,
            y2: 0,
          },
          sizeBack: {
            w: 0,
            h: 0,
            w2: 100,
            h2: 100,
          },
          positionBack: {
            x: 0,
            y: 0,
            x2: 0,
            y2: 0,
          },
          frontImage: '',
          backImage: '',
          frontImageSize: {
            w: 0,
            h: 0,
          },
          backImageSize: {
            w: 0,
            h: 0,
          },
          typePrint:
            product.typeProduct && product.typeProduct[0]['id']
              ? product.typeProduct[0]['id'].toString()
              : '0',
          frontImg:
            product.typeProduct && product.typeProduct[0]['imageLink']
              ? product.typeProduct && product.typeProduct[0]['imageLink']
              : product.frontImg,
          backImg:
            product.typeProduct && product.typeProduct[0]['imageLink']
              ? product.typeProduct && product.typeProduct[0]['imageLink']
              : product.backImg,
          listSizeByDirection: product?.typeProduct?.[0]?.['sizePrice'] || [],
          customPrice: listCustomPrice,
          ...(product.optionColors.length > 0
            ? {
              colors: [product.optionColors[0]],
              colorActive: product.optionColors[0],
            }
            : {}),
          ...(product.typeProduct &&
            product.typeProduct[0] &&
            product.typeProduct[0]['sizePrice']
            ? {
              sizes: product.typeProduct[0]['sizePrice'],
              sizeActive: product.typeProduct[0]['sizePrice'][0] || {},
            }
            : product.optionSizes.length > 0
              ? {
                sizes: product.optionSizes,
                sizeActive: product.optionSizes[0],
              }
              : {}),
          ...(product.firstMockup ? { mockupId: product.firstMockup.id } : {}),
        },
      ]);
    }
  };
  const handleDeselect = () => {
    setSelected([]);
  };
  return (
    <>
      <Wrapper>
        <span className="cursor-pointer mt-5" onClick={() => setShow(true)}>
          {element}
        </span>
        <Modal
          className="modal-catalog"
          visible={show}
          width={'100vw'}
          title={
            <div>
              <div>
                <h4 className="font-weight-bold p-0 m-0">
                  Select product(s) for your design
                </h4>
              </div>
              <div>
                <Input
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  style={{ width: 400 }}
                  prefix={<SearchOutlined />}
                  placeholder="Search name products"
                  className="my-3"
                />
              </div>
              <div className="d-flex w-100 mb-2 justify-content-between align-items-start">
                <div>
                  {[ALL_ITEM, ...categories].map((category) => {
                    const isCategorySelected =
                      selectedCategories.findIndex(
                        (currItem) =>
                          parseInt(currItem?.id) === parseInt(category.id)
                      ) > -1;
                    return (
                      <Tag
                        onClick={handleOnSelectCategory.bind(null, category)}
                        className={classNames(' defaultSelect cursor-pointer', {
                          catagorySelected: isCategorySelected,
                        })}
                        key={category.id}
                      >
                        {category.name}
                      </Tag>
                    );
                  })}
                </div>
                <div className="d-flex row m-0 align-items-center">
                  <div>
                    <b className="mr-1">
                      {(Array.isArray(selected) &&
                        selected.length > 0 &&
                        `(${selected.length})`) ||
                        0}
                    </b>
                    {alts(selected.length, 'selected product', false)}
                  </div>
                  <div className="ml-5">
                    <GMButton color={'SecondaryGray'} onClick={handleDeselect}>
                      Deselect
                    </GMButton>
                  </div>
                </div>
              </div>
            </div>
          }
          onCancel={() => setShow(false)}
          footer={null}
        >
          <CardProduct>
            <Spin spinning={loading || categoryLoading}>
              <div className="row main-card d-flex align-items-center">
                {products
                  .filter(
                    (item) =>
                      `${item.brand} - ${item.name}`
                        .toLocaleLowerCase()
                        .indexOf(keyword.toLocaleLowerCase()) > -1
                  )
                  .filter((item) => {
                    if (selectedCategories?.[0]?.id === ALL_TYPE_ITEM_ID) {
                      return true;
                    }
                    return selectedCategories.length > 0
                      ? selectedCategories.findIndex(
                        (currCategory) =>
                          parseInt(currCategory.id) === parseInt(item.type)
                      ) > -1
                      : true;
                  })
                  .map((item) => (
                    <div key={item.id}>
                      <div
                        onClick={() => handleSetSelected(item)}
                        className={classNames('card-product', {
                          'border-active': selected.find(
                            (s) => parseInt(s.id) === parseInt(item.id)
                          ),
                        })}
                      >
                        <Img
                          img={item?.avatar || noImg}
                        >
                          &nbsp;
                        </Img>
                        <div className="px-2 bottom-card">
                          {item.firstMockup && (
                            <div className="text-danger mb-1">Your mockup</div>
                          )}

                          <div className="title-product">{item.brand}</div>
                          <div className="color-price">
                            <b>{money(item.basePrice)}</b>
                          </div>
                          <div className="style-size">
                            <div className="mr-2">
                              {alts(item.optionSizes?.length, 'Size')}
                            </div>
                            <div>
                              {alts(item.optionColors?.length, 'Color')}{' '}
                            </div>
                          </div>
                          {/* <div className="mb-2">
                        {item.optionSizes.map((size, sizeIndex) => (
                          <Tag
                            className="mb-2"
                            color="#000"
                            key={
                              item.id +
                              "_" +
                              size.id +
                              "_" +
                              size.value +
                              "_" +
                              sizeIndex
                            }
                          >
                            {size.name}
                          </Tag>
                        ))}
                      </div>
                      <div className="mb-2">
                        {item.optionColors.map((color, colorIndex) => (
                          <Tag
                            className={classNames("mb-2", {"border border-dark": parseInt(color.id) === 12})}
                            color={`#${color.value}`}
                            key={
                              item.id +
                              "_" +
                              color.id +
                              "_" +
                              color.value +
                              "_" +
                              colorIndex
                            }
                          >
                            <span className="invisible">n</span>
                          </Tag>
                        ))}
                      </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="d-flex mt-3 row justify-content-end footer">
                <div className="mr-4">
                  <GMButton
                    size="lg"
                    onClick={() => setShow(false)}
                    color={'SecondaryGray'}
                  >
                    Cancel
                  </GMButton>
                </div>
                <div>
                  <GMButton
                    size="lg"
                    onClick={() => {
                      for (let index = 0; index < selected.length; index++) {
                        const element = selected[index];
                        if (
                          !curSelected.find(
                            (item) => parseInt(item.id) === parseInt(element.id)
                          )
                        ) {
                          onPick(element);
                        }
                      }
                      setShow(false);
                    }}
                  >
                    Next
                  </GMButton>
                </div>
              </div>
            </Spin>
          </CardProduct>
        </Modal>
      </Wrapper>
      <WrapperPickProduct />
    </>
  );
}
export default React.memo(PickProduct);
