import React, { Component } from 'react';
import { Upload, Spin, Button, notification } from 'antd';
import classNames from 'classnames';
import {
  createFolderName,
  createFileName,
  getSizeImage,
  resizeImage,
  getCookie,
} from 'utils/helper';
import { getPresignedUrlS3 } from 'apis/image_design';
import { PictureFilled, FilePdfOutlined } from '@ant-design/icons';
import { API_TOKEN_KEY } from 'web.config';
import axios from 'axios';
import getMomentInstance from 'moment';
import { S3_CDN } from 'web.config';
import { Wrapper } from './styled';
import { updateAvatar } from 'apis/user';
import { saveDesign, uploadBarcode } from 'apis/orders';
import { GRAY_100, GRAY_50, GRAY_600 } from 'components/colors';
import { GlowingSVG } from 'components/SVG';
import { ReactComponent as UploadIcon } from 'images/icons/upload-cloud-02.svg';

class RenderUploadBarcodeOrderLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      data = {},
      isFrontOrBack = null,
      changeField = null,
      changeField2 = null,
      changeField4 = null,
      linkFileTitle = 'Link file barcode',
      accept = '.png',
      showLinkFile = false,
      children,
      paramName = 'file',
      url = '',
      multiple,
      width,
      height,
      typeUpload = 'avatar',
      meta: { error, submitFailed },
      hideResize,
      input,
      showUploadList = false,
      cusId = 0,
      prefixName = 'artwork',
      customPrefix = 'tmp',
      dataToSaveDesign = {},
    } = this.props;
    const props = {
      accept: accept,
      listType: 'picture-card',
      showUploadList: showUploadList,
      name: paramName,
      multiple: multiple ? true : false,
      action: url,
      headers: {
        // common: {
        Authorization: `Bearer ${getCookie(API_TOKEN_KEY) /* auth cookie */}`,
        // }
      },
      data,
      beforeUpload: (file) => {
        if (typeUpload === 'barcode') {
          if (file.type !== 'application/pdf') {
            notification.warn({
              className: 'notic1',
              message: `${file.name} is not a pdf file.`,
            });
          }
          if (file.size > 2e8) {
            notification.warn({
              className: 'notic1',
              message: `${file.name} is larger than 200MB.`,
            });
          }
          return file.type === 'application/pdf' && file.size <= 2e8;
        } else {
          if (file.type !== 'image/png') {
            notification.warn({
              className: 'notic1',
              message: `${file.name} is not a png file.`,
            });
          }
          if (file.size > 2e8) {
            notification.warn({
              className: 'notic1',
              message: `${file.name} is larger than 200MB.`,
            });
          }
          return file.type === 'image/png' && file.size <= 2e8;
        }
      },
      customRequest: async ({ file, onError, onSuccess }) => {
        const folderName = createFolderName(cusId);
        const extension = file.name.split('.').pop();
        const originalName = file.name.replace(`.${extension}`, '');
        const fileName = createFileName(extension || 'png', prefixName, cusId);
        const fileType =
          (extension === 'pdf' ? 'application' : 'image') + `/${extension}`;
        const keyToCreatePresignedUrl = `${customPrefix}/${folderName}/${getMomentInstance(
          null,
          0,
          false,
          true
        )}/${fileName}`;
        const response = await getPresignedUrlS3(keyToCreatePresignedUrl);
        if (response && response.code === 'success') {
          axios
            .put(response.data.url, file, {
              headers: {
                'Content-Type': fileType,
              },
            })
            .then((response) => {
              if (parseInt(response.status) === 200) {
                onSuccess(
                  {
                    code: 'success',
                    url: `${S3_CDN}/${keyToCreatePresignedUrl}`,
                    folderName: folderName,
                    fileName: fileName,
                    fileType: fileType,
                    extension: extension,
                    originalName: originalName,
                  },
                  file
                );
              } else {
                onError();
              }
            });
        }
      },
      onChange: (info) => {
        const { status, response, originFileObj } = info.file;
        if (status === 'uploading') {
          this.setState({ loading: true });
        }
        if (status === 'done' && response.code === 'success') {
          if (typeUpload === 'barcode') {
            input.onChange(response.url);
            uploadBarcode(response.url, response.originalName).then(
              (response2) => {
                if (
                  response2.code === 'success' &&
                  response2?.data?.barcode?.id
                ) {
                  notification.success({
                    className: 'notic2',
                    message: `${info.file.name} file uploaded successfully.`,
                  });
                  changeField(response2.data.barcode.id);
                } else {
                  notification.error({
                    className: 'notic1',
                    message: `${info.file.name} file upload failed.`,
                  });
                }
                this.setState({ loading: false });
              }
            );
          } else {
            getSizeImage(response.url, async (width, height) => {
              const resizedImage = await resizeImage(
                originFileObj,
                650,
                Math.round((650 * height) / width),
                'file',
                response.extension.toUpperCase()
              );
              const keyToCreatePresignedUrl2 = `${customPrefix}/${
                response.folderName
              }/${getMomentInstance(null, 0, false, true)}/thumbnail/${
                response.fileName
              }`;
              const response2 = await getPresignedUrlS3(
                keyToCreatePresignedUrl2
              );
              if (response2 && response2.code === 'success') {
                axios
                  .put(response2.data.url, resizedImage, {
                    headers: {
                      'Content-Type': response.fileType,
                    },
                  })
                  .then((response3) => {
                    if (parseInt(response3.status) === 200) {
                      input.onChange(`${S3_CDN}/${keyToCreatePresignedUrl2}`);
                      if (typeUpload === 'upload-artwork') {
                        notification.success({
                          className: 'notic2',
                          message: `${info.file.name} file uploaded successfully.`,
                        });
                        getSizeImage(
                          `${S3_CDN}/${keyToCreatePresignedUrl2}`,
                          (width2, height2) => {
                            changeField2({
                              w: width2,
                              h: height2,
                            });
                            changeField4(
                              {
                                w: width2,
                                h: height2,
                              },
                              `${S3_CDN}/${keyToCreatePresignedUrl2}`
                            );
                          }
                        );
                        this.setState({ loading: false });
                      } else if (typeUpload === 'upload-match-product') {
                        changeField(
                          isFrontOrBack === 'Back'
                            ? `imageBackOriginal`
                            : `imageFrontOriginal`,
                          `${S3_CDN}/${keyToCreatePresignedUrl2}`
                        );
                        saveDesign({
                          ...dataToSaveDesign,
                          s3Url: `${S3_CDN}/${keyToCreatePresignedUrl2}`,
                        }).then((response4) => {
                          if (response4.code === 'success') {
                            notification.success({
                              className: 'notic2',
                              message: `${info.file.name} file uploaded successfully.`,
                            });
                          } else {
                            notification.error({
                              className: 'notic1',
                              message: `${info.file.name} file upload failed.`,
                            });
                          }
                          this.setState({ loading: false });
                        });
                      } else if (typeUpload === 'upload-avatar') {
                        input.onChange(`${S3_CDN}/${keyToCreatePresignedUrl2}`);
                        updateAvatar({
                          s3Url: `${S3_CDN}/${keyToCreatePresignedUrl2}`,
                        }).then((response4) => {
                          if (response4.code === 'success') {
                            notification.success({
                              className: 'notic2',
                              message: response4.clientMsg,
                            });
                          } else {
                            notification.error({
                              className: 'notic1',
                              message: `Update avatar failed.`,
                            });
                          }
                          this.setState({ loading: false });
                        });
                      } else {
                        notification.success({
                          className: 'notic2',
                          message: `${info.file.name} file uploaded successfully.`,
                        });
                      }
                    } else {
                      notification.error({
                        className: 'notic1',
                        message: `${info.file.name} file upload failed.`,
                      });
                    }
                    this.setState({ loading: false });
                  });
              } else {
                notification.error({
                  className: 'notic1',
                  message: `${info.file.name} file upload failed.`,
                });
                this.setState({ loading: false });
              }
            });
          }
        } else if (status === 'error') {
          notification.error({
            className: 'notic1',
            message: `${info.file.name} file upload failed.`,
          });
          this.setState({ loading: false });
        }
      },
    };
    return (
      <Wrapper className={classNames('w-100 upload-w-100')}>
        <Upload {...props}>
          <Spin
            spinning={this.state.loading}
            tip={"We're processing your image, please wait a moment"}
          >
            <div className="upload">
              <div className="upload-frame">
                <GlowingSVG
                  backgroundColor={GRAY_100}
                  borderColor={GRAY_50}
                  svgStrokeColor={GRAY_600}
                >
                  <UploadIcon />
                </GlowingSVG>
                <div className="text-upload">
                  <b className="click-to">Click to upload barcode</b>
                  <br />
                </div>
              </div>
            </div>
          </Spin>

          {children}
        </Upload>
        <p>
          {showLinkFile && input.value && (
            <a href={input.value} target={'_blank'} rel="noopener noreferrer">
              {linkFileTitle}
            </a>
          )}
        </p>
        {error && submitFailed && (
          <span className="invalid-feedback d-block">{error}</span>
        )}
        {width && height && !hideResize && (
          <span className="d-block mb-2">{`Hình ảnh sẽ được resize về kích thước ${width} x ${height}`}</span>
        )}
      </Wrapper>
    );
  }
}

export default RenderUploadBarcodeOrderLabel;
