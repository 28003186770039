import {
  BASE_WHITE,
  GRAY_200,
  GRAY_50,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_500,
  SHADOW_SM_0_06,
  SHADOW_SM_0_1,
} from 'components/colors';
import PageContainer from 'components/PageContainer';
import styled, { createGlobalStyle } from 'styled-components';

export const Wrapper = styled(PageContainer)`
  padding: 32px;
  .main-content {
    background-color: transparent;
    border: none;
    box-shadow: none;
    &.ant-table-wrapper {
      box-shadow: none;
    }
  }
  .flex-vertical-center {
    display: flex;
    align-items: center;
  }
  .ant-collapse-header {
    h5 {
      margin-bottom: 0;
    }
  }
  h2.header {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: ${GRAY_900};
    margin-top: 20px;
  }
  .container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .more-action {
    svg {
      width: 20px;
      height: 20px;
      stroke: ${GRAY_600};
      margin-right: 10px;
    }
  }
  .connect-icon {
    svg {
      width: 20px;
      height: 20px;
      stroke: ${BASE_WHITE};
      margin-right: 10px;
    }
  }
  .time-block {
    .time {
      font-weight: 400;
    }
  }
  .block-right {
    padding: 24px;
    gap: 16px;
    /* Base/White */

    background: #ffffff;
    /* Gray/200 */

    border: 1px solid #eaecf0;
    /* Shadow/sm */

    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 16px;
    margin-bottom: 24px;
    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .infor-block {
      padding-left: 24px;
      padding-right: 24px;
      .item {
        padding-top: 8px;
        .title {
          color: ${GRAY_500};
          font-weight: 500;
          font-size: 14px;
        }
        .content {
          color: ${GRAY_900};
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    .button-svg {
      svg {
        stroke: ${GRAY_700};
        width: 20px;
        height: 20px;
      }
    }
    .disable {
      pointer-events: none;
      filter: grayscale(1);
      svg {
        opacity: 0.3;
      }
    }
  }

  .shipping {
    border: 1px solid #eaecf0;
  }

  .main-content {
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_200};
    box-shadow: 0px 1px 3px ${SHADOW_SM_0_1}, 0px 1px 2px ${SHADOW_SM_0_06};
    border-radius: 16px;
    margin-top: 0px;
  }
  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
  }


  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 16px 8px;
  }

  .table table {
    thead th {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      border: none;
      height: 44px;
      vertical-align: middle;
      background-color: ${GRAY_50};
      border-bottom: 1px solid ${GRAY_200};
    }
    tbody td {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${GRAY_600};
      border-bottom: 1px solid ${GRAY_200};
      border-top: none;
      vertical-align: middle;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
  .edit-barcode-file {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
    p a {
      white-space: nowrap;
    }
  }
  .gmupload {
    &,
    & .ant-upload {
      margin: 0;
      padding: 0;
      background-color: transparent;
    }
  }
  .nobutton {
    border: none;
    background: none;
    font-size: 0;
    padding: 0;
    margin: 0;
    svg {
      stroke: ${GRAY_700};
      width: 20px;
      margin-bottom: 4px;
    }
  }
  td.colprice {
    text-align: right;
  }

  .image-design-upload .ant-upload-picture-card-wrapper {
    width: auto;
  }
  .l100p {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .ant-table-tbody > tr > td.fl,
  .ant-table tfoot > tr > td.fl {
    padding-top: 12px !important;
    line-height: 38px;
  }
`;
export const GlobalStyle = createGlobalStyle`
  .ant-modal-content{
    border-radius: 16px;
  }
  .ant-modal-header {
    border-radius: 16px;
  }
`;
