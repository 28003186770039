import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Input, Table, Tooltip } from 'antd';
import { renderInputText, renderSelectAnt } from 'utils/Form';
import money from 'utils/money';
import { Space, Modal } from 'antd';
import { renderTextarea } from 'utils/Form';
import { ReactComponent as SearchIcon2 } from 'images/icons/search-md.svg';
import { ReactComponent as TrashIcon } from 'images/icons/trash-01.svg';
import nophotoImg from 'images/noPhoto.png';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RenderPreviewOriginalImage from 'utils/Form/RenderPreviewOriginalImage';
import RenderProductInfoMatchProduct from 'utils/Form/RenderProductInfoMatchProduct';
import { getLabel } from 'utils/Form/RenderTrackingInfo';
import RenderViewMoreImage from 'utils/Form/RenderViewMoreImage';
import RenderPreviewDesign from 'utils/Form/renderPreviewDesign';
import SelectProductsSize from 'utils/Form/Search/SelectProductsSize';
import SelectProductsColor from 'utils/Form/Search/SelectProductsColor';
import SelectProduct from 'utils/Form/Search/SelectProduct';
import { Wrapper } from './styled';
import UploadImageCreateOrder from '../UploadImageCreateOrder';
const { confirm } = Modal;
export default class renderMatchProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listSidePrint: {},
      keyword: '',
      orderOnHold: false,
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.props.setSelectedOrderItems(selectedRowKeys);
  };

  onRemoveOrderItemMatchProduct = (orderItemId, orderId) => {
    const { removedOrderItemMatchProduct } = this.props;
    confirm({
      title: 'Do you want to delete this order item?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        removedOrderItemMatchProduct(orderItemId, orderId);
      },
      onCancel() {},
    });
  };

  onDeleteDesign = (orderItemId, sidePrint) => {
    const { deleteDesign } = this.props;
    confirm({
      title: 'Do you want to delete design of this order item?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        deleteDesign({
          orderItemIds: [orderItemId],
          sidePrint,
        });
      },
      onCancel() {},
    });
  };

  render() {
    const {
      fields,
      changeField,
      listOrderItems,
      listOrders,
      exproducts,
      profile,
      selectedOrderItems,
    } = this.props;
    const { listSidePrint } = this.state;
    const rowSelection = {
      selectedRowKeys: selectedOrderItems,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };
    const columns = [
      {
        title: <b style={{ fontSize: '20px' }}>ORDER PRODUCTS</b>,
        children: [
          {
            title: (
              <div>
                <b>Product name</b>
                <Input
                  value={this.state.keyword}
                  onChange={(e) => this.setState({ keyword: e.target.value })}
                  className="mr-3"
                  style={{ width: '100%' }}
                  prefix={<SearchIcon2 />}
                  placeholder="Search name products"
                />
              </div>
            ),
            dataIndex: 'content',
            width: 300,
            shouldCellUpdate: (record, prevRecord) =>
              JSON.stringify(record) !== JSON.stringify(prevRecord),
            key: 'content',
            render: (value, record) => {
              const order = listOrders.find(
                (item) => parseInt(item.id) === parseInt(record.orderId)
              );
              const label = getLabel(order);
              return (
                <div>
                  <RenderProductInfoMatchProduct
                    orderItem={record}
                    order={order}
                  />
                  <div
                    className="form-group"
                    style={{
                      borderTop: '1px solid #ccc',
                      maxWidth: '300px',
                    }}
                  >
                    {!label?.includes('Label') && (
                      <div className="mt-2 mb-1 w-100">
                        Shipping location:{' '}
                        {order?.shippingLocation === 0
                          ? 'Domestic'
                          : 'International'}
                      </div>
                    )}

                    <div className="mt-2 mb-1 w-100">
                      Shipping service: {label}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            title: <b>Color</b>,
            dataIndex: 'tmpContent',
            key: 'tmpContent',
            width: 100,
            align: 'center',
            shouldCellUpdate: (record, prevRecord) =>
              JSON.stringify(record) !== JSON.stringify(prevRecord),
            render: (value) => {
              return <div> {value ? value.color : ''}</div>;
            },
          },
          {
            title: <b>Size</b>,
            dataIndex: 'tmpContent',
            key: 'tmpContent',
            width: 100,
            align: 'center',
            shouldCellUpdate: (record, prevRecord) =>
              JSON.stringify(record) !== JSON.stringify(prevRecord),
            render: (value) => {
              return <div> {value ? value.size : ''}</div>;
            },
          },
          {
            title: <b>Image</b>,
            dataIndex: 'tmpContent',
            key: 'tmpContent',
            width: 180,
            shouldCellUpdate: (record, prevRecord) =>
              JSON.stringify(record) !== JSON.stringify(prevRecord),
            align: 'center',
            render: (value, record) => {
              return <RenderPreviewOriginalImage orderItem={record} />;
            },
          },
        ],
      },
      {
        title: <b style={{ fontSize: '20px' }}>SYSTEM PRODUCTS</b>,
        children: [
          {
            title: <b>Image sample</b>,
            dataIndex: 'sampleteImage',
            key: 'sampleteImage',
            shouldCellUpdate: (record, prevRecord) =>
              JSON.stringify(record) !== JSON.stringify(prevRecord),
            align: 'center',
            width: 170,
            render: (text, record) => {
              if (!text && record?.exProduct?.frontImg) {
                text = record.exProduct.frontImg;
              }
              const colorString = record.colorHex
                ? `${record.colorHex}`
                : record.hexColor
                ? `#${record.hexColor}`
                : 'transparent';
              return text ? (
                <div className="d-flex flex-column justify-content-cenrer">
                  <div>
                    <img
                      src={text}
                      width="100"
                      style={{ backgroundColor: colorString }}
                      alt=""
                    />
                  </div>
                  <div width="100">
                    <b>
                      {record?.productDisplayName ||
                        record?.exProduct?.brand ||
                        ''}
                    </b>
                  </div>
                </div>
              ) : (
                <img
                  src={nophotoImg}
                  width="100"
                  style={{
                    backgroundColor: 'transparent',
                  }}
                  alt="mockup"
                />
              );
            },
          },
          {
            title: <b>Product name</b>,
            dataIndex: 'expId',
            align: 'center',
            width: 300,
            key: 'expId',
            render: (text, record, i) => {
              const order = listOrders.filter(
                (item) => parseInt(item.id) === parseInt(record.orderId)
              );
              let orderIndex = listOrders.findIndex(
                (item) => parseInt(item.id) === parseInt(record.orderId)
              );
              let placements = [];
              record?.exProduct?.listSidePrint &&
                Object.keys(record?.exProduct?.listSidePrint).forEach(
                  (item) => {
                    placements.push({
                      value: item,
                      label: record?.exProduct?.listSidePrint?.[item],
                    });
                    if (placements.length > 0) {
                      placements.unshift({
                        value: 'normal',
                        label: 'Normal',
                      });
                    }
                  }
                );
              //Check available side print
              let sp = listSidePrint[record.id]
                ? listSidePrint[record.id]
                : placements;
              return (
                <div>
                  <Field
                    name={`${fields.name}[${i}].expId`}
                    component={SelectProduct}
                    data={exproducts}
                    classNameContainer={'mb-4'}
                    showError={true}
                    onChoose={(id, data) => {
                      const listSidePrint = [];
                      if (data?.listSidePrint) {
                        Object.keys(data?.listSidePrint).forEach((item) => {
                          listSidePrint.push({
                            value: item,
                            label: data.listSidePrint[item],
                          });
                        });
                      }
                      if (listSidePrint.length > 0) {
                        listSidePrint.unshift({
                          value: 'normal',
                          label: 'Normal',
                        });
                      }
                      this.setState((state) => {
                        return {
                          listSidePrint: {
                            ...state.listSidePrint,
                            ...{ [record.id]: listSidePrint },
                          },
                        };
                      });
                      if (typeof changeField === 'function') {
                        changeField(
                          `${fields.name}[${i}].productDisplay`,
                          data?.display
                        );
                        changeField(
                          `${fields.name}[${i}].productType`,
                          data?.type
                        );
                        changeField(
                          `${fields.name}[${i}].productDisplayName`,
                          data?.brand
                        );
                        changeField(`${fields.name}[${i}].size`, '0');
                        changeField(`${fields.name}[${i}].color`, '0');
                        changeField(`${fields.name}[${i}].colorHex`, null);
                        changeField(`${fields.name}[${i}].sidePrint`, null);
                        changeField(`${fields.name}[${i}].printSide2`, null);
                        changeField(`${fields.name}[${i}].designBack`, null);
                        changeField(`${fields.name}[${i}].designFront`, null);
                        changeField(`${fields.name}[${i}].designBackId`, null);
                        changeField(`${fields.name}[${i}].designFrontId`, null);
                        changeField(
                          `${fields.name}[${i}].basePrice`,
                          data?.basePrice || 0
                        );
                        changeField(
                          `${fields.name}[${i}].sampleteImage`,
                          data?.frontImg || ''
                        );
                        changeField(
                          `${fields.name}[${i}].exProduct.backImg`,
                          data?.backImg
                        );
                        changeField(
                          `${fields.name}[${i}].exProduct.frontImg`,
                          data?.frontImg
                        );
                        changeField(
                          `${fields.name}[${i}].exProduct.display`,
                          data?.display
                        );
                        changeField(
                          `${fields.name}[${i}].exProduct.printLocation`,
                          data?.printLocation
                        );
                      }
                    }}
                    placeholder="-- Select Product --"
                  />
                  <Field
                    name={`${fields.name}[${i}].color`}
                    component={SelectProductsColor}
                    expId={fields.get(i) ? fields.get(i).expId : '0'}
                    classNameContainer={'mb-4'}
                    placeholder="-- Select color --"
                    onChoose={(id, data) => {
                      if (typeof changeField === 'function' && data) {
                        changeField(
                          `${fields.name}[${i}].colorHex`,
                          `#${data.value2}`
                        );
                        changeField(`${fields.name}[${i}].size`, 0);
                      }
                    }}
                    showError={true}
                  />
                  <Field
                    name={`${fields.name}[${i}].size`}
                    component={SelectProductsSize}
                    expId={fields.get(i) ? fields.get(i).expId : '0'}
                    classNameContainer={'mb-4'}
                    colorId={fields.get(i) ? fields.get(i).color : '0'}
                    placeholder="-- Select size --"
                    onChoose={(id, data) => {
                      if (typeof changeField === 'function' && data) {
                        changeField(`${fields.name}[${i}].basePrice`,data?.priceShow);
                      }
                    }}
                    showError={true}
                  />
                  <Field
                    name={`${fields.name}[${i}].quantity`}
                    classNameContainer={'mb-4'}
                    component={renderInputText}
                    placeholder={'Quantity'}
                    showError={true}
                    type="number"
                    addonBefore="Quantity"
                    isNumberInputQuantity={true}
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  />
                  {/* Handle side print */}
                  {/* listSidePrint when choose new product, placement for product order to match */}
                  {/* {console.log(
                    listSidePrint[record.id],
                    'listSidePrint[record.id]?.length'
                  )} */}
                  {sp?.length > 1 && (
                    <Field
                      name={`${fields.name}[${i}].printSide2`}
                      classNameContainer={'mb-4'}
                      component={renderSelectAnt}
                      placeholder="-- Placements --"
                      options={listSidePrint[record.id] || placements}
                      onChange={(id) => {
                        if (id === 'whole') {
                          changeField(
                            `${fields.name}[${i}].printSide`,
                            'front'
                          );
                          changeField(`${fields.name}[${i}].designBack`, null);
                          changeField(
                            `${fields.name}[${i}].designBackId`,
                            null
                          );
                          changeField(
                            `${fields.name}[${i}].exProduct.backImg`,
                            null
                          );
                        } else {
                          changeField(
                            `${fields.name}[${i}].printSide`,
                            exproducts?.find(
                              (ep) => ep.id * 1 === record?.expId * 1
                            )?.printLocation
                          );
                        }
                      }}
                    />
                  )}
                  <div className="form-group mt-3 text-left">
                    <label className="col-form-label">Gift message:</label>
                    <Field
                      name={`listOrdersToMatch[${orderIndex}].giftMessage`}
                      component={renderTextarea}
                      rows={6}
                    />
                  </div>
                </div>
              );
            },
          },
          {
            title: <b>Price</b>,
            dataIndex: 'basePrice',
            key: 'basePrice',
            shouldCellUpdate: (record, prevRecord) =>
              JSON.stringify(record) !== JSON.stringify(prevRecord),
            width: 100,
            align: 'center',
            render: (text, r) => {
              return <b>{money(r.basePrice)}</b>;
            },
          },
          {
            title: <b>Design</b>,
            key: 'designFront',
            align: 'center',
            dataIndex: 'designFront',
            shouldCellUpdate: (record, prevRecord) =>
              JSON.stringify(record) !== JSON.stringify(prevRecord),
            width: 180,
            render: (text, record, i) => {
              const colorString = record.colorHex
                ? `#${record.colorHex}`
                : record.hexColor
                ? `#${record.hexColor}`
                : 'transparent';
              const printLocation =
                record?.exProduct?.printLocation
                  ?.split(',')
                  .filter((pL) => pL && pL) ||
                record?.printSide?.split(',').filter((pL) => pL && pL) ||
                [];
              const order = listOrders.filter(
                (item) => parseInt(item.id) === parseInt(record.orderId)
              );
              return (
                <div>
                  <Space className="d-flex flex-column w100p">
                    {printLocation.includes('front') ? (
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row justify-content-around mt-2 mb-2">
                          {record?.frontDesignUrl && (
                            <RenderViewMoreImage
                              imageUrl={record?.frontDesignUrl}
                              side={'front'}
                            />
                          )}
                          {/* Allow edit design when order on hold */}
                          <div>
                            <Field
                              name={`${fields.name}[${i}].frontDesignUrl`}
                              changeDesignFront={(value) => {
                                changeField(
                                  `${fields.name}[${i}].frontDesignUrl`,
                                  value
                                );
                              }}
                              changeDesignFrontId={(value) =>
                                changeField(
                                  `${fields.name}[${i}].designFrontId`,
                                  value
                                )
                              }
                              changeDesignBack={(value) => {
                                changeField(
                                  `${fields.name}[${i}].backDesignUrl`,
                                  value
                                );
                              }}
                              changeDesignBackId={(value) =>
                                changeField(
                                  `${fields.name}[${i}].designBackId`,
                                  value
                                )
                              }
                              orderId={record.orderId}
                              orderItemId={record.id}
                              typePrint={record.printType}
                              component={UploadImageCreateOrder}
                              printLocation={printLocation}
                              cusId={profile.id}
                              isCreatingOrder={true}
                            />
                          </div>
                        </div>
                        {record?.frontDesignUrl &&
                          (parseInt(record.productDisplay) === 1 ||
                            parseInt(record?.exProduct?.display) === 1) && (
                            <RenderPreviewDesign
                              colorHex={colorString}
                              orderItemId={record.id}
                              orderId={record.orderId}
                              exProductId={record.expId}
                              colorId={record.color}
                              sidePrint={'front'}
                              sizeId={record.size}
                              typePrint={record.printSide2}
                              canRender={
                                record?.exProduct?.productDisplay * 1 ||
                                record?.exProduct?.display * 1
                              }
                              productName={
                                record?.productDisplayName ||
                                record?.exProduct?.brand ||
                                ''
                              }
                            />
                          )}
                      </div>
                    ) : null}
                    {printLocation.includes('back') &&
                    record?.printSide2 !== 'whole' ? (
                      <div
                        className="d-flex flex-column pt-3"
                        style={{ borderTop: '1px solid #ccc' }}
                      >
                        <div className="d-flex flex-row justify-content-around mt-2 mb-2">
                          {record?.backDesignUrl && (
                            <RenderViewMoreImage
                              imageUrl={record?.backDesignUrl}
                              side={'back'}
                            />
                          )}
                          {/* Allow edit design when order on hold */}
                          <div>
                            <Field
                              name={`${fields.name}[${i}].backDesignUrl`}
                              changeDesignBack={(value) => {
                                changeField(
                                  `${fields.name}[${i}].backDesignUrl`,
                                  value
                                );
                              }}
                              changeDesignBackId={(value) =>
                                changeField(
                                  `${fields.name}[${i}].designBackId`,
                                  value
                                )
                              }
                              changeDesignFront={(value) => {
                                changeField(
                                  `${fields.name}[${i}].frontDesignUrl`,
                                  value
                                );
                              }}
                              changeDesignFrontId={(value) =>
                                changeField(
                                  `${fields.name}[${i}].designFrontId`,
                                  value
                                )
                              }
                              orderId={record.orderId}
                              orderItemId={record.id}
                              typePrint={record.printType}
                              printLocation={printLocation}
                              component={UploadImageCreateOrder}
                              cusId={profile.id}
                              isCreatingOrder={true}
                            />
                          </div>
                        </div>

                        {record?.backDesignUrl &&
                          (parseInt(record.productDisplay) === 1 ||
                            parseInt(record?.exProduct?.display) === 1) && (
                            <RenderPreviewDesign
                              colorHex={colorString}
                              orderItemId={record.id}
                              orderId={record.orderId}
                              exProductId={record.expId}
                              colorId={record.color}
                              sidePrint={'back'}
                              sizeId={record.size}
                              typePrint={record.printSide2}
                              canRender={
                                record?.exProduct?.productDisplay * 1 ||
                                record?.exProduct?.display * 1
                              }
                              productName={
                                record?.productDisplayName ||
                                record?.exProduct?.brand ||
                                ''
                              }
                            />
                          )}
                      </div>
                    ) : null}
                  </Space>
                </div>
              );
            },
          },
          {
            title: <b></b>,
            shouldCellUpdate: (record, prevRecord) =>
              JSON.stringify(record) !== JSON.stringify(prevRecord),
            dataIndex: 'address',
            width: 100,
            key: 'address',
            render: (text, record, i) => {
              return (
                <div>
                  <div
                    className="text-danger mb-1 text-center font-size-16 cursor-pointer"
                    onClick={() => {
                      this.onRemoveOrderItemMatchProduct(
                        record.id,
                        record.orderId,
                        i
                      );
                    }}
                  >
                    <Tooltip title="Delete">
                      <TrashIcon className="icon trash" />
                    </Tooltip>
                  </div>

                  {/* <div
                    className="text-primary text-center font-size-16 cursor-pointer"
                    onClick={() => {
                      this.props.saveOrderItem(record.id, i);
                    }}
                  >
                    <Tooltip title="Save">
                      <SaveIcon className="icon save" />
                    </Tooltip>
                  </div> */}
                </div>
              );
            },
          },
        ],
      },
    ];

    return (
      <Wrapper>
        <div className="custom-header-sticky">
          <Table
            bordered
            pagination={false}
            rowSelection={rowSelection}
            columns={columns}
            rowClassName={(record) => {
              if (
                (this.props.listOrderItemsSuccess || []).length <= 0 &&
                (this.props.listOrderItemsError || []).length <= 0
              ) {
                return '';
              }
              if (
                Array.isArray(this.props.listOrderItemsError) &&
                this.props.listOrderItemsError.includes(parseInt(record.id))
              ) {
                return 'bg-danger-custom';
              } else {
                return 'bg-success-custom';
              }
              // if (record?.altStatus === 'failed') {
              //   return 'bg-danger-custom';
              // }
              // if (record?.altStatus === 'success') {
              //   return 'bg-success-custom';
              // }
            }}
            dataSource={
              this.state.keyword && this.state.keyword.length >= 3
                ? listOrderItems.filter(
                    (orderItem) =>
                      orderItem?.exProduct &&
                      `${orderItem?.exProduct.brand} - ${orderItem.exProduct.name}`
                        .toLocaleLowerCase()
                        .indexOf(this.state.keyword.toLocaleLowerCase()) > -1
                  )
                : listOrderItems
            }
            rowKey={`id`}
            scroll={{ x: 1000 }}
            sticky
          />
        </div>
      </Wrapper>
    );
  }
}
