import { 
  takeLatest, 
  call, 
  put, 
  all,
  select
} from 'redux-saga/effects'
import { 
  actionType as TYPE
} from './actions'
import axios from 'utils/request'

function getListAPI(params){
    return axios({
      method: 'GET',
      params,
      url: `customer-collections`
    })
}

function* getListSaga(action){
  const { params } = action
  try {
    const response = yield call(getListAPI, params)
    if(response.code === 'success'){
      yield put({ type: TYPE.LIST.SUCCESS, ...response })    
    }else{
        yield put({ type: TYPE.LIST.ERROR, response })
    }      
  } catch(error){
    yield all([
      yield put({ type: TYPE.LIST.ERROR, error })
    ])
  }
}

function destroyAPI(id){
  return axios({
    method: 'DELETE',
    url: `customer-collections/${id}`
  })
}

function* destroySaga(action){
const { id } = action
try {
  const response = yield call(destroyAPI, id)
  if(response.code === 'success'){
    const state = yield select();
    const list_params = state.collection.list.collections.params
    yield all([
        put({type: TYPE.DESTROY.SUCCESS, response}),
        put({type: TYPE.LIST.REQUEST, params: list_params}),
    ]) 
  }else{
      yield put({ type: TYPE.DESTROY.ERROR, response })
  }      
} catch(error){
  yield all([
    yield put({ type: TYPE.DESTROY.ERROR, error })
  ])
}
}

function* watcher(){
  yield takeLatest(TYPE.LIST.REQUEST, getListSaga)
  yield takeLatest(TYPE.DESTROY.REQUEST, destroySaga)
}

export default watcher