import React, { useState, useEffect } from "react";
import getMomentInstance from "utils/moment";
import { Timeline, Spin } from "antd";
import { historyShipping } from "apis/general_settings";
import parse from "html-react-parser";
function RenderHistoryOfShippingService({ updateShippingLoading = false }) {
  const [loading, setLoading] = useState(false);
  const [listLogs, setListLogs] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    perPage: 10,
    currentPage: 1,
  });
  useEffect(() => {
    if (!updateShippingLoading) getLogs();
  }, [updateShippingLoading]);

  const getLogs = (isMore = false) => {
    setLoading(true);
    historyShipping({
      page: isMore ? (pagination?.currentPage || 1) + 1 : 1,
      perPage: 20,
    })
      .then(({ code, data }) => {
        setLoading(false);
        if (code === "success") {
          const temp = data?.logs || [];
          setListLogs(isMore ? [...listLogs, ...temp] : temp);
          setPagination(data.pagination);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Spin spinning={loading}>
      {listLogs?.map((log) => (
        <>
          <b>{getMomentInstance(log.logTime, 0, true)}</b>
          {parse("<div>" + log.logName + "</div>")}
          <br />
        </>
      ))}
      {(pagination.total !== listLogs.length) && (
        <div className="text-center">
          <button
            onClick={() => getLogs(true)}
            type="button"
            className="btn-sm btn btn-outline-secondary mb-3"
          >
            View more
          </button>
        </div>
      )}
    </Spin>
  );
};
export default React.memo(RenderHistoryOfShippingService)