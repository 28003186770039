import { 
    actionType as TYPE
} from './actions'
import {notification} from "antd";

const initialState = {
    listImages: {
        loading: false,
        params: {},
        data: [],
        total: 0
    },
    archive: {
        loading: false
    }
}

function ImageLibraryReducer(state = initialState, action) {
    switch (action.type) {
        case TYPE.LIST.REQUEST:
            return {
                ...state,
                listImages: {
                    ...state.listImages,
                    loading: true,
                    params: action.params
                }
            }  
        case TYPE.LIST.SUCCESS:
            return {
                ...state,
                listImages: {
                    ...state.listImages,
                    loading: false,
                    data: action?.data?.imageDesigns || [],
                    total: action?.data?.pagination?.total || (action?.data?.imageDesigns || []).length
                }
            }
        case TYPE.LIST.ERROR:
            return {
                ...state,
                listImages: {
                    ...state.listImages,
                    loading: false,
                }
            }   
        case TYPE.ARCHIVE.REQUEST:
            return {
                ...state,
                archive: {
                    ...state.archive,
                    loading: true
                }
            }  
        case TYPE.ARCHIVE.SUCCESS:
            if (action.errors && Array.isArray(action.errors)) {
                for (let index = 0; index < action.errors.length; index++) {
                  if (action.errors[index].code === "archive_image_success") {
                    notification.success({
                      message: action.errors[index].clientMsg,
                      duration: 5,
                    });
                  } else {
                    notification.warn({
                      message: action.errors[index].clientMsg,
                      duration: 5,
                    });
                  }
                }
              }
            return {
                ...state,
                archive: {
                    ...state.archive,
                    loading: false
                }
            } 
        case TYPE.ARCHIVE.ERROR:
            notification.warning({ message: "Archive image failed", duration: 5 });
            return {
                ...state,
                archive: {
                    ...state.archive,
                    loading: false
                }
            }            
        default:
            return state
    }
}

export default ImageLibraryReducer