export const actionType = {
  LIST: {
    REQUEST: "CAMPAIGN..LIST.REQUEST",
    SUCCESS: "CAMPAIGN.LIST.SUCCESS",
    ERROR: "CAMPAIGN.LIST.ERROR",
  },
  DELETE: {
    REQUEST: "CAMPAIGN.DELETE.REQUEST",
    SUCCESS: "CAMPAIGN.DELETE.SUCCESS",
    ERROR: "CAMPAIGN.DELETE.ERROR",
  },
  ARCHIVE: {
    REQUEST: "CAMPAIGN.ARCHIVE.REQUEST",
    SUCCESS: "CAMPAIGN.ARCHIVE.SUCCESS",
    ERROR: "CAMPAIGN.ARCHIVE.ERROR",
  },
  SYNC: {
    REQUEST: "CAMPAIGN.SYNC.REQUEST",
    SUCCESS: "CAMPAIGN.SYNC.SUCCESS",
    ERROR: "CAMPAIGN.SYNC.ERROR",
  },
  UNARCHIVE: {
    REQUEST: "CAMPAIGN.UNARCHIVE.REQUEST",
    SUCCESS: "CAMPAIGN.UNARCHIVE.SUCCESS",
    ERROR: "CAMPAIGN.UNARCHIVE.ERROR",
  },
  PREVIEW: {
    REQUEST: "CAMPAIGN.PREVIEW.REQUEST",
    SUCCESS: "CAMPAIGN.PREVIEW.SUCCESS",
    ERROR: "CAMPAIGN.PREVIEW.ERROR",
  },
  LIST_PRODUCT_BY_CAMPAIGN: {
    REQUEST: "CAMPAIGN.LIST_PRODUCT.REQUEST",
    SUCCESS: "CAMPAIGN.LIST_PRODUCT.SUCCESS",
    ERROR: "CAMPAIGN.LIST_PRODUCT.ERROR",
  },
  LIST_VARIANT_BY_CAMPAIGN: {
    REQUEST: "CAMPAIGN.LIST_VARIANT.REQUEST",
    SUCCESS: "CAMPAIGN.LIST_VARIANT.SUCCESS",
    ERROR: "CAMPAIGN.LIST_VARIANT.ERROR",
  },
  LIST_COLOR: {
    REQUEST: "CAMPAIGN.LIST_COLOR.REQUEST",
    SUCCESS: "CAMPAIGN.LIST_COLOR.SUCCESS",
    ERROR: "CAMPAIGN.LIST_COLOR.ERROR",
  },
  COUNT: {
    REQUEST: "CAMPAIGN.COUNT.REQUEST",
    SUCCESS: "CAMPAIGN.COUNT.SUCCESS",
    ERROR: "CAMPAIGN.COUNT.ERROR",
  },
  MODAL_PREVIEW_PRODUCT: {
    REQUEST: "MODAL_PREVIEW_PRODUCT.REQUEST",
    SUCCESS: "MODAL_PREVIEW_PRODUCT.SUCCESS",
    ERROR: "MODAL_PREVIEW_PRODUCT.ERROR",
  },
};

export function listCampaigns(params) {
  return {
    type: actionType.LIST.REQUEST,
    params,
  };
}

export function listColor(params, id) {
  return {
    type: actionType.LIST_COLOR.REQUEST,
    params,
    id,
  };
}
export function previewModalProduct( id) {
  return {
    type: actionType.MODAL_PREVIEW_PRODUCT.REQUEST,
    id,
  };
}

export function listProductByCampaign(campaignId, params, storeId) {
  return {
    type: actionType.LIST_PRODUCT_BY_CAMPAIGN.REQUEST,
    campaignId,
    params,
    storeId,
  };
}

export function listVariantsByCampaign(id, storeId) {
  return {
    type: actionType.LIST_VARIANT_BY_CAMPAIGN.REQUEST,
    id,
    storeId,
  };
}

export function deleteCampaigns(data, params) {
  return {
    type: actionType.DELETE.REQUEST,
    data,
    params,
  };
}

export function archiveCampaigns(data, params) {
  return {
    type: actionType.ARCHIVE.REQUEST,
    data,
    params,
  };
}

export function unarchiveCampaigns(data, params) {
  return {
    type: actionType.UNARCHIVE.REQUEST,
    data,
    params,
  };
}

export function previewCampaigns(data, params) {
  return {
    type: actionType.PREVIEW.REQUEST,
    data,
    params,
  };
}

export function syncCampaigns(data, params) {
  return {
    type: actionType.SYNC.REQUEST,
    data,
    params,
  };
}
export function countCampaigns() {
  return {
    type: actionType.COUNT.REQUEST,
  };
}
