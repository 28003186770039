import {
  STORE_EDIT_REQUEST,
  STORE_EDIT_SUCCESS,
  STORE_EDIT_FAIL,
  STORE_DETAIL_REQUEST,
  STORE_DETAIL_SUCCESS,
  STORE_DETAIL_FAIL,
  STORE_EDIT_EBAY_REQUEST,
  STORE_EDIT_EBAY_SUCCESS,
  STORE_EDIT_EBAY_FAIL,
  STORE_EDIT_ETSY_REQUEST,
  STORE_EDIT_ETSY_SUCCESS,
  STORE_EDIT_ETSY_FAIL,
  STORE_EDIT_SHOPBASE_REQUEST,
  STORE_EDIT_SHOPBASE_SUCCESS,
  STORE_EDIT_SHOPBASE_FAIL,
  STORE_EDIT_SHOPBASE_TOKEN_REQUEST,
  STORE_EDIT_SHOPBASE_TOKEN_SUCCESS,
  STORE_EDIT_SHOPBASE_TOKEN_FAIL,
  STORE_EDIT_SHOPBASE_NORMAL_REQUEST,
  STORE_EDIT_SHOPBASE_NORMAL_SUCCESS,
  STORE_EDIT_SHOPBASE_NORMAL_FAIL,
  LINK_SHOPBASE_TOKEN_REQUEST,
  LINK_SHOPBASE_TOKEN_SUCCESS,
  LINK_SHOPBASE_TOKEN_FAIL,
  LINK_SHOPBASE_MERCHANT_REQUEST,
  LINK_SHOPBASE_MERCHANT_SUCCESS,
  LINK_SHOPBASE_MERCHANT_FAIL,
  GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_REQUEST,
  GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_SUCCESS,
  GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_FAIL,
  UPDATE_SHOPIFY_TOKEN_REQUEST,
  UPDATE_SHOPIFY_TOKEN_SUCCESS,
  UPDATE_SHOPIFY_TOKEN_FAIL,
  UPDATE_SHOPIFY_STORE_REQUEST,
  UPDATE_SHOPIFY_STORE_SUCCESS,
  UPDATE_SHOPIFY_STORE_FAIL,
  LINK_ETSY_TOKEN_REQUEST,
  LINK_ETSY_TOKEN_SUCCESS,
  LINK_ETSY_TOKEN_FAIL,
  UPDATE_ETSY_TOKEN_REQUEST,
  UPDATE_ETSY_TOKEN_SUCCESS,
  UPDATE_ETSY_TOKEN_FAIL,
  LINK_AMAZON_TOKEN_REQUEST,
  LINK_AMAZON_TOKEN_SUCCESS,
  LINK_AMAZON_TOKEN_FAIL,
  UPDATE_AMAZON_TOKEN_REQUEST,
  UPDATE_AMAZON_TOKEN_SUCCESS,
  UPDATE_AMAZON_TOKEN_FAIL,
} from './constants';

import { notification } from 'antd';
const initialState = {
  detail: {
    loading: false,
    data: {},
  },
  edit: {
    loading: false,
  },
  editStoreEbay: {
    loading: false,
  },
  editStoreEtsy: {
    loading: false,
  },
  editStoreShopbase: {
    loading: false,
  },
  getLinkShopbaseToken: {
    loading: false,
  },
  getLinkEstyToken: {
    loading: false,
  },
  getLinkShopbaseMerchant: {
    loading: false,
  },
  editShopbaseToken: {
    loading: false,
  },
  editStoreEtsyToken: {
    loading: false,
  },
  editShopbaseNormal: {
    loading: false,
  },
  getShopifyLinkForUpdatingToken: {
    loading: false,
    data: {},
  },
  updateShopifyToken: {
    loading: false,
  },
  updateShopifyStore: {
    loading: false,
    data: {},
  },
  getLinkAmazonToken: {
    loading: false,
  },
  updateAmazonToken: {
    loading: false,
    data: {},
  },
};

function EditReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_EDIT_REQUEST:
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: true,
        },
      };
    case STORE_EDIT_SUCCESS:
      notification.success({
        message: action?.response?.clientMsg || 'Update store successfully.',
        duration: 5,
      });
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
        },
      };
    case STORE_EDIT_FAIL:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Update store failed.',
        duration: 5,
      });
      return {
        ...state,
        edit: {
          ...state.edit,
          loading: false,
        },
      };
    //Update store Ebay
    case STORE_EDIT_EBAY_REQUEST:
      return {
        ...state,
        editStoreEbay: {
          ...state.editStoreEbay,
          loading: true,
        },
      };
    case STORE_EDIT_EBAY_SUCCESS:
      notification.success({
        message: action?.response?.clientMsg || 'Update store successfully.',
        duration: 5,
      });
      return {
        ...state,
        editStoreEbay: {
          ...state.editStoreEbay,
          loading: false,
        },
      };
    case STORE_EDIT_EBAY_FAIL:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Update store failed.',
        duration: 5,
      });
      return {
        ...state,
        editStoreEbay: {
          ...state.editStoreEbay,
          loading: false,
        },
      };

    //Edit store Etsy
    case STORE_EDIT_ETSY_REQUEST:
      return {
        ...state,
        editEtsy: {
          ...state.editEtsy,
          loading: true,
        },
      };
    case STORE_EDIT_ETSY_SUCCESS:
      notification.success({
        message: action?.response?.clientMsg || 'Update store successfully.',
        duration: 5,
      });
      return {
        ...state,
        editEtsy: {
          ...state.editEtsy,
          loading: false,
        },
      };
    case STORE_EDIT_ETSY_FAIL:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Update store failed.',
        duration: 5,
      });
      return {
        ...state,
        editEtsy: {
          ...state.editEtsy,
          loading: false,
        },
      };
    //Get link esty token
    case LINK_ETSY_TOKEN_REQUEST:
      return {
        ...state,
        getLinkEstyToken: {
          ...initialState.getLinkEstyToken,
          loading: true,
        },
      };
    case LINK_ETSY_TOKEN_SUCCESS:
      return {
        ...state,
        getLinkEstyToken: {
          ...state.getLinkEstyToken,
          loading: false,
          data: action.data && action.data ? action.data : [],
        },
      };
    case LINK_ETSY_TOKEN_FAIL:
      return {
        ...state,
        getLinkEstyToken: {
          ...state.getLinkEstyToken,
          loading: false,
        },
      };
    //Update token Etsy
    case UPDATE_ETSY_TOKEN_REQUEST:
      return {
        ...state,
        editStoreEtsyToken: {
          ...state.editStoreEtsyToken,
          loading: true,
        },
      };
    case UPDATE_ETSY_TOKEN_SUCCESS:
      notification.success({
        message: action?.response?.clientMsg || 'Update store successfully.',
        duration: 5,
      });
      return {
        ...state,
        editStoreEtsyToken: {
          ...state.editStoreEtsyToken,
          loading: false,
        },
      };
    case UPDATE_ETSY_TOKEN_FAIL:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Update store failed.',
        duration: 5,
      });
      return {
        ...state,
        editStoreEtsyToken: {
          ...state.editStoreEtsyToken,
          loading: false,
        },
      };
    //Update store Shopbase
    case STORE_EDIT_SHOPBASE_REQUEST:
      return {
        ...state,
        editStoreShopbase: {
          ...state.editStoreShopbase,
          loading: true,
        },
      };
    case STORE_EDIT_SHOPBASE_SUCCESS:
      notification.success({
        message: action?.response?.clientMsg || 'Update store successfully.',
        duration: 5,
      });
      return {
        ...state,
        editStoreShopbase: {
          ...state.editStoreShopbase,
          loading: false,
        },
      };
    case STORE_EDIT_SHOPBASE_FAIL:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Update store failed.',
        duration: 5,
      });
      return {
        ...state,
        editStoreShopbase: {
          ...state.editStoreShopbase,
          loading: false,
        },
      };
    //Get link shopbase token
    case LINK_SHOPBASE_TOKEN_REQUEST:
      return {
        ...state,
        getLinkShopbaseToken: {
          ...initialState.getLinkShopbaseToken,
          params: action.params,
          loading: true,
        },
      };
    case LINK_SHOPBASE_TOKEN_SUCCESS:
      return {
        ...state,
        getLinkShopbaseToken: {
          ...state.getLinkShopbaseToken,
          loading: false,
          data: action.data && action.data ? action.data : [],
        },
      };
    case LINK_SHOPBASE_TOKEN_FAIL:
      notification.warn({
        message: action.error ? action.error : 'Update token failed',
        duration: 5,
      });
      return {
        ...state,
        getLinkShopbaseToken: {
          ...state.getLinkShopbaseToken,
          loading: false,
        },
      };
    //get link shopbase merchant
    case LINK_SHOPBASE_MERCHANT_REQUEST:
      return {
        ...state,
        getLinkShopbaseMerchant: {
          ...initialState.getLinkShopbaseMerchant,
          params: action.params,
          loading: true,
        },
      };
    case LINK_SHOPBASE_MERCHANT_SUCCESS:
      return {
        ...state,
        getLinkShopbaseMerchant: {
          ...state.getLinkShopbaseMerchant,
          loading: false,
          data: action.data && action.data ? action.data : [],
        },
      };
    case LINK_SHOPBASE_MERCHANT_FAIL:
      notification.warn({
        message: action.error ? action.error : 'Redirect failed',
        duration: 5,
      });
      return {
        ...state,
        getLinkShopbaseMerchant: {
          ...state.getLinkShopbaseMerchant,
          loading: false,
        },
      };
    //Edit shopbase by token
    case STORE_EDIT_SHOPBASE_TOKEN_REQUEST:
      return {
        ...state,
        editShopbaseToken: {
          ...state.editShopbaseToken,
          loading: true,
        },
      };
    case STORE_EDIT_SHOPBASE_TOKEN_SUCCESS:
      notification.success({
        message: action?.response?.clientMsg || 'Update store successfully.',
        duration: 5,
      });
      return {
        ...state,
        editShopbaseToken: {
          ...state.editShopbaseToken,
          loading: false,
        },
      };
    case STORE_EDIT_SHOPBASE_TOKEN_FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Update store failed.',
        duration: 5,
      });
      return {
        ...state,
        editShopbaseToken: {
          ...state.editShopbaseToken,
          loading: false,
        },
      };
    //Edit shopbase normal
    case STORE_EDIT_SHOPBASE_NORMAL_REQUEST:
      return {
        ...state,
        editShopbaseNormal: {
          ...state.editShopbaseNormal,
          loading: true,
        },
      };
    case STORE_EDIT_SHOPBASE_NORMAL_SUCCESS:
      notification.success({
        message: action?.response?.clientMsg || 'Update store successfully.',
        duration: 5,
      });
      return {
        ...state,
        editShopbaseNormal: {
          ...state.editShopbaseNormal,
          loading: false,
        },
      };
    case STORE_EDIT_SHOPBASE_NORMAL_FAIL:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Update store failed.',
        duration: 5,
      });
      return {
        ...state,
        editShopbaseNormal: {
          ...state.editShopbaseNormal,
          loading: false,
        },
      };
    //Detail store
    case STORE_DETAIL_REQUEST:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
        },
      };
    case STORE_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data:
            action.data && action.data.storeIntegration
              ? action.data.storeIntegration
              : {},
        },
      };
    case STORE_DETAIL_FAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
        },
      };
    case GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_REQUEST:
      return {
        ...state,
        getShopifyLinkForUpdatingToken: {
          ...state.getShopifyLinkForUpdatingToken,
          loading: true,
        },
      };
    case GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_SUCCESS:
      return {
        ...state,
        getShopifyLinkForUpdatingToken: {
          ...state.getShopifyLinkForUpdatingToken,
          data: action?.data || {},
          loading: false,
        },
      };
    case GET_SHOPIFY_LINK_FOR_UPDATING_TOKEN_FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Update token failed.',
        duration: 5,
      });
      return {
        ...state,
        getShopifyLinkForUpdatingToken: {
          ...state.getShopifyLinkForUpdatingToken,
          loading: false,
        },
      };
    case UPDATE_SHOPIFY_TOKEN_REQUEST:
      return {
        ...state,
        updateShopifyToken: {
          ...state.updateShopifyToken,
          loading: true,
        },
      };
    case UPDATE_SHOPIFY_TOKEN_SUCCESS:
      notification.success({
        message: 'Update token successfully.',
        duration: 5,
      });
      return {
        ...state,
        updateShopifyToken: {
          ...state.updateShopifyToken,
          loading: false,
        },
      };
    case UPDATE_SHOPIFY_TOKEN_FAIL:
      notification.warn({
        message:
          action.error[0] && action.error[0]['msg']
            ? action.error[0]['msg']
            : 'Update token failed.',
        duration: 5,
      });
      return {
        ...state,
        updateShopifyToken: {
          ...state.updateShopifyToken,
          loading: false,
        },
      };
    case UPDATE_SHOPIFY_STORE_REQUEST:
      return {
        ...state,
        updateShopifyStore: {
          ...state.updateShopifyStore,
          loading: true,
        },
      };
    case UPDATE_SHOPIFY_STORE_SUCCESS:
      if (!action?.data?.url) {
        notification.success({
          message: action?.response?.clientMsg || 'Update store successfully.',
          duration: 5,
        });
      }
      return {
        ...state,
        updateShopifyStore: {
          ...state.updateShopifyStore,
          data: action?.data || {},
          loading: false,
        },
      };
    case UPDATE_SHOPIFY_STORE_FAIL:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Update store failed.',
        duration: 5,
      });
      return {
        ...state,
        updateShopifyStore: {
          ...state.updateShopifyStore,
          loading: false,
        },
      };
    //Get link AMAZON token
    case LINK_AMAZON_TOKEN_REQUEST:
      return {
        ...state,
        getLinkAmazonToken: {
          ...initialState.getLinkAmazonToken,
          loading: true,
        },
      };
    case LINK_AMAZON_TOKEN_SUCCESS:
      return {
        ...state,
        getLinkAmazonToken: {
          ...state.getLinkAmazonToken,
          loading: false,
          data: action ? action : [],
        },
      };
    case LINK_AMAZON_TOKEN_FAIL:
      return {
        ...state,
        getLinkAmazonToken: {
          ...state.getLinkAmazonToken,
          loading: false,
        },
      };
    //Update token aMAZON
    case UPDATE_AMAZON_TOKEN_REQUEST:
      return {
        ...state,
        updateAmazonToken: {
          ...state.updateAmazonToken,
          loading: true,
        },
      };
    case UPDATE_AMAZON_TOKEN_SUCCESS:
      notification.success({
        message: action?.response?.clientMsg || 'Update token successfully.',
        duration: 5,
      });
      return {
        ...state,
        updateAmazonToken: {
          ...state.updateAmazonToken,
          loading: false,
        },
      };
    case UPDATE_AMAZON_TOKEN_FAIL:
      notification.warn({
        message: action?.error?.[0]?.['msg'] || 'Update store failed.',
        duration: 5,
      });
      return {
        ...state,
        updateAmazonToken: {
          ...state.updateAmazonToken,
          loading: false,
        },
      };

    default:
      return state;
  }
}

export default EditReducer;
