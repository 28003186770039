import axios from "utils/request";

const prefix = "/store-integrations";

export const getList = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}`,
  });
};

export const getListStore = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}/list-stores?with[]=giftMessage`,
  });
};

export const getListStoreUserlabel = (params = {}) => {
  return axios({
    method: "GET",
    params,
    url: `${prefix}/list-store-user-label`,
  });
};
export const create = (data) => {
  return axios({
    method: "POST",
    data,
    url: `${prefix}`,
    is_json_content_type: true,
  });
};
export const getDetail = (id) => {
  return axios({
    method: "GET",
    // params,
    url: `${prefix}/${id}?&with[]=customer`,
  });
};
export const getDetailShopbase = (id) => {
  return axios({
    method: "GET",
    // params,
    url: `${prefix}/ebay/${id}`,
  });
};
export const update = (data, id) => {
  return axios({
    method: "PUT",
    data,
    url: `${prefix}/${id}`,
    is_json_content_type: true,
  });
};

export const destroy = (id) => {
  return axios({
    method: "DELETE",
    url: `${prefix}/${id}`,
  });
};

export const findByCustomer = () => {
  return axios({
    method: "GET",
    url: `${prefix}/find-by-customer-account`,
  });
};

export const listStoreStatus = () => {
  return axios({
    method: "GET",
    url: `${prefix}/store-status`,
  });
};
