import React, { useState, useEffect } from "react";
import { Select } from "antd";
const { Option } = Select;

export default ({
                    input,
                    invalid,
                    meta,
                    isProductTag = true,
                    size,
                    inputProps = {},
                    placeholder= "",
                    listOptions = [],
                }) => {
    const [keyword, setKeyword] = useState("DefaultTag");
    const [options, setOptions] = useState([]);
    useEffect(() => {
       if(isProductTag)  setOptions([{ label: keyword, value: keyword }]);
    }, [keyword]);

    useEffect(() => {
        if (listOptions.length > options.length && listOptions.length > 0) {
            setOptions(listOptions);
        }
    }, [listOptions]);

    const onSelect = async (data) => {
        input.onChange(data || []);
    };
    return (
        <div className="position-relative">
            <Select
                mode="multiple"
                value={input.value || []}
                style={{ width: "100%" }}
                placeholder={placeholder}
                size={size || "large"}
                onChange={onSelect}
                onSearch={(k) => {
                    if (k.length > 2 && k !== null) setKeyword(k);
                }}
                filterOption={false}
                {...inputProps}
            >
                {options.map((item) => {
                    return (
                        <Option key={item.value} value={item.value}>
                            {item.label}
                        </Option>
                    );
                })}
            </Select>
            {invalid && (
                <hr
                    className="border-danger m-0 position-absolute"
                    style={{ width: "100%", left: "0%", bottom: "0%" }}
                />
            )}
            {meta?.error && <span className="invalid-feedback">{meta?.error}</span>}
        </div>
    );
};
