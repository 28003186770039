import {
  BASE_WHITE,
  ERROR_500,
  GRAY_100,
  GRAY_200,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  PRIMARY_500,
  SHADOW_XS,
  SUCCESS_25,
  SUCCESS_300,
  SUCCESS_600,
  SUCCESS_700,
} from 'components/colors';
import PageContainer from 'components/PageContainer';
import styled, { createGlobalStyle } from 'styled-components';

export const Wrapper = styled(PageContainer)`
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${GRAY_900};
    margin-bottom: 16px;
  }
  .ant-spin-nested-loading {
    width: 100%;
  }
  .pmlist {
    div.addpm {
      width: 100%;
      background-color: ${GRAY_100};
      border: 1px solid ${GRAY_100};
      border-radius: 8px;
      height: auto;
      margin: 0;
      margin-bottom: 16px;
      text-align: center;
      &.active,
      &:hover {
        border: 1px solid ${PRIMARY_500};
      }
      &.active {
        height: auto;
      }
      .inner-wrapper {
        text-align: center;
        vertical-align: middle;
        padding: 16px;
        cursor: pointer;
        &.nohover {
          cursor: default;
        }
        .info {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${GRAY_600};
          margin-top: 8px;
        }
      }

      .lian {
        svg {
        width: 165px;
      }
      }

      &.active .toggle-section {
        display: block;
      }
      .toggle-section {
        display: none;
        padding: 16px;
        padding-top: 0px;
      }
    }
    .description {
      margin-bottom: 16px;
      &.bold {
        font-weight: 600;
      }
      &.bold2 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: ${GRAY_900};
      }
      &.bottom {
        color: ${GRAY_600};
      }
      &.warning {
        color: ${ERROR_500};
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    div.addpm.lian {
      svg {
        width: 165px;
      }
    }
    .buttons {
      gap: 16px;
      margin-bottom: 16px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      button {
        display: flex;
        width: unset;
      }
    }
  }
  img.svg.lian {
    width: 46px;
  }
  .cta-blk {
    text-align: right;
    button {
      margin-left: 16px;
    }
  }

  .gmtextbox,
  .genderblock {
    margin-bottom: 16px;
    .ant-input-affix-wrapper-lg {
      padding: 0 12px;
    }
  }
  .ant-input-lg {
    &:focus {
      border: none;
    }
  }
 
  .msg {
    width: 100%;
    background: ${SUCCESS_25};
    border: 1px solid ${SUCCESS_300};
    border-radius: 12px;
    padding: 16px;
    position: relative;
    padding-left: 48px;
    svg {
      position: absolute;
      top: 16px;
      left: 16px;
      stroke: ${SUCCESS_600};
      width: 18px;
      height: 18px;
    }
    label,
    p {
      margin: 0;
      vertical-align: top;
    }
    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: ${SUCCESS_700};
      margin-bottom: 4px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${SUCCESS_700};
    }
  }
  .info-form {
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: ${GRAY_900};
      margin-bottom: 24px;
    }
    label.copy-legal {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: ${PRIMARY_500};
      cursor: pointer;
      margin-bottom: 24px;
      gap: 24px;
      svg {
        stroke: ${PRIMARY_500};
        width: 20px;
        height: 20px;
      }
    }
    .gmrow {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: row;
      gap: 24px;
      &.full {
        gap: unset;
        flex-wrap: wrap;
      }
      .gmtextbox {
        width: 100%;
      }
      .igrp {
        display: flex;
        &.primary {
          display: block;
          display: flex;
          width: 100%;

          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: ${GRAY_700};
        }
        .ant-checkbox-wrapper {
          font-size: 16px;
        }
        &.pdsc {
          display: block;
          display: flex;
          width: 100%;
          padding-left: 25px;
          font-size: 16px;
          span {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: ${GRAY_600};
          }
        }
      }
      &.gcol-3 .igrp {
        width: calc(100% / 3);
      }
      &.gcol-2 .igrp {
        width: 50%;
      }
      &.gcol-4 .igrp {
        width: 25%;
      }
    }
    .ctas {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
    }
  }
`;
export const WrapperAddBilling = styled.div`
  /* h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${GRAY_900};
    margin-bottom: 16px;
  } */
  .title {
    font-size: 16px;
    margin-bottom: 24px;
  }
  .pmlist {
    div.addpm {
      width: 100%;
      background-color: ${GRAY_100};
      border: 1px solid ${GRAY_100};
      border-radius: 8px;
      height: auto;
      margin: 0;
      margin-bottom: 16px;
      text-align: center;
      &.active,
      &:hover {
        border: 1px solid ${PRIMARY_500};
      }
      &.active {
        height: auto;
      }
      &.disabled {
        filter: grayscale(1);
        cursor: not-allowed;
        &.active,
        &:hover {
          border: 1px solid transparent;
        }
        .inner-wrapper {
          cursor: not-allowed;
        }
      }
      .inner-wrapper {
        text-align: center;
        vertical-align: middle;
        padding: 16px;
        cursor: pointer;
        &.nohover {
          cursor: default;
        }
        .info {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${GRAY_600};
          margin-top: 8px;
        }
      }

      &.active .toggle-section {
        display: block;
      }
      .toggle-section {
        display: none;
        padding: 16px;
        padding-top: 0px;
      }
      
      &.lian {
        svg {
          width: 165px;
        }
      }
    }
    .description {
      margin-bottom: 16px;
      &.bold {
        font-weight: 600;
      }
      &.bold2 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: ${GRAY_900};
      }
      &.bottom {
        color: ${GRAY_600};
      }
      &.warning {
        color: ${ERROR_500};
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .buttons {
      gap: 16px;
      margin-bottom: 16px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      button {
        display: flex;
        width: unset;
      }
    }
  }
  .cta-blk {
    text-align: right;
    button {
      margin-left: 16px;
    }
  }

  .gmtextbox,
  .genderblock {
    margin-bottom: 16px;
    .ant-input-affix-wrapper-lg {
      padding: 0 12px;
    }
  }
  .ant-input-lg {
    &:focus {
      border: none;
    }
  }

  .msg {
    width: 100%;
    background: ${SUCCESS_25};
    border: 1px solid ${SUCCESS_300};
    border-radius: 12px;
    padding: 16px;
    position: relative;
    padding-left: 48px;
    svg {
      position: absolute;
      top: 16px;
      left: 16px;
      stroke: ${SUCCESS_600};
      width: 18px;
      height: 18px;
    }
    label,
    p {
      margin: 0;
      vertical-align: top;
    }
    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: ${SUCCESS_700};
      margin-bottom: 4px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: ${SUCCESS_700};
    }
  }
  .info-form {
    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: ${GRAY_900};
      margin-bottom: 24px;
    }
    label.copy-legal {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: ${PRIMARY_500};
      cursor: pointer;
      margin-bottom: 24px;
      gap: 24px;
      svg {
        stroke: ${PRIMARY_500};
        width: 20px;
        height: 20px;
      }
    }
    .gmrow {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: row;
      gap: 24px;
      &.full {
        gap: unset;
        flex-wrap: wrap;
      }
      .gmtextbox {
        width: 100%;
      }
      .igrp {
        display: flex;
        &.primary {
          display: block;
          display: flex;
          width: 100%;

          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: ${GRAY_700};
        }
        .ant-checkbox-wrapper {
          font-size: 16px;
        }
        &.pdsc {
          display: block;
          display: flex;
          width: 100%;
          padding-left: 25px;
          font-size: 16px;
          span {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: ${GRAY_600};
          }
        }
      }
      &.gcol-3 .igrp {
        width: calc(100% / 3);
      }
      &.gcol-2 .igrp {
        width: 50%;
      }
      &.gcol-4 .igrp {
        width: 25%;
      }
    }
    .ctas {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
    }
  }
`;
export const GlobalStyle = createGlobalStyle`
  .ant-modal-content{
    border-radius: 16px;
  }
  .ant-modal-header{
    border-radius: 16px;
    border-bottom: none;
  }
  .ant-modal-title{
    font-weight: 600;
  }
`;

export const ConfirmModalInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  .icon .wrapper {
    display: flex;
    width: 40px;
    height: 40px;
    background: ${BASE_WHITE};
    border: 1px solid ${GRAY_200};
    box-shadow: 0px 1px 2px ${SHADOW_XS};
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    svg {
      stroke: ${GRAY_700};
    }
  }
  h4.header {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_700};
    margin-bottom: 4px;
  }
  .msg {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${GRAY_600};
  }
  .content {
    margin-bottom: 12px;
  }
`;
export const WrapperModal = styled.div``;
