const validate = (values) => {
    const errors = {};
    if(!values?.platformId || parseInt(values?.platformId) === 0){
        errors.platformId = "Please choose a platform";
    }
    if(!values?.storeId || parseInt(values?.storeId) === 0){
        errors.storeId = "Please choose a store";
    }
    if(!values?.date_range?.[0]){
        errors.date_range = "Please choose from date"
    }else if(!values?.date_range?.[1]){
        errors.date_range = "Please choose to date"
    }
    return errors;
};

export default validate;