import Dashboard from 'scenes/Dashboard'

export default [  
    {
        key: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        path: '/dashboard',
        group: 'dashboard',
        icon: null,
        template: 'default',  
        isPublic: true,
    }
]